import {IListResult} from '@src/app/interfaces/common.interface'
import {
  apiAddNewVan,
  apiDeleteItemVan,
  apiGetListFilterVan,
  apiGetListVan,
  putApiEditVanApi,
} from '@src/app/services/api/vanApi'
import {call, put, takeLatest} from 'redux-saga/effects'
import {alertError, alertSuccess, formatDate} from '../../../services/helper'
import {IVan} from '../models/VanModel'
import {
  addNewVanAction,
  editVanAction,
  EManageVanType,
  getListFilterVanSuccess,
  getVanAction,
  saveVanAction,
} from './ManagaVanAction'

// ================= VAN SAGA ====================== //
function* getListVanWatcher() {
  try {
    // const res: IVan[] = yield call(apiGetListVan)
    const res: IListResult = yield call(apiGetListVan)
    if (res) {
      const cloneData = [...res.data]
      const newArr: any = cloneData.map((el: IVan) => {
        return {
          ...el,
          statusFrom: formatDate(el?.statusFrom),
          statusTo: formatDate(el?.statusTo),
          updatedAt: formatDate(el?.updatedAt),
        }
      })
      if (newArr) yield put(saveVanAction(newArr))
      // yield put(saveVanAction(res.data))
    }
  } catch (error) {}
}

function* addNewVanWatcher({payload}: ReturnType<typeof addNewVanAction>) {
  try {
    const res: IVan = yield call(apiAddNewVan, payload.value)
    payload.onSuccess()
    alertSuccess('Save successfully !')
    yield put(getVanAction())
  } catch (error: any) {
    payload.onError()
    alertError(error ?? 'Add New Failed !')
  }
}

function* editVanWatcher({payload}: ReturnType<typeof editVanAction>) {
  try {
    const res: IVan[] = yield call(putApiEditVanApi, payload.value)
    alertSuccess('Successfully Edit Item !')
    yield put({type: EManageVanType.EDIT_ITEM_VAN_RD, payload: res})
    payload.onSuccess()
    yield put(getVanAction())
  } catch (error: any) {
    alertError(error ?? 'Edit Failed !')
    payload.onError()
  }
}

function* deleteVanItemTypeWatcher({payload}: any) {
  try {
    const res: IVan[] = yield call(apiDeleteItemVan, payload.id)
    yield put({
      type: EManageVanType.DELETE_ITEM_VAN_RD,
      payload: payload.id,
    })
    alertSuccess('Delete successful')
    payload.onSuccess()
  } catch (error: any) {
    payload.onError()
    alertError(error)
  }
}

// =============== Search - Sort ======================== //
function* getListFilterVanWatcher(payload: any) {
  try {
    const res: {data: IVan[]; total: number} = yield call(
      apiGetListFilterVan,
      payload.payload.page,
      payload.payload.pagesize,
      payload.payload.filter
    )
    if (res) {
      yield put(getListFilterVanSuccess(res))
    }
  } catch (error) {}
}

export default function* manageVanSaga() {
  yield takeLatest(EManageVanType.GET_LIST_VAN, getListVanWatcher)
  yield takeLatest(EManageVanType.ADD_NEW_VAN, addNewVanWatcher)
  yield takeLatest(EManageVanType.EDIT_VAN, editVanWatcher)
  yield takeLatest(EManageVanType.DELETE_ITEM_VAN, deleteVanItemTypeWatcher)
  yield takeLatest(EManageVanType.LIST_FILTER_VAN, getListFilterVanWatcher)
}
