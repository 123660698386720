import FormAddAndEdit from './components/FormAdHoc'

const AdHoc = () => {
  const onClose = () => {}

  return (
    <FormAddAndEdit
      data={undefined}
      mode={'add'}
      title={null}
      onClose={onClose}
      deleteType={null}
    />
  )
}

export default AdHoc
