import {toAbsoluteUrl} from '@metronic/helpers'

export const VERSION = '1.1 - 26 Oct 2023'
export const MAX_ITEM_PAGE = 10
export const GOOGLE_MAPS_APIKEY = 'AIzaSyBynAPmw9CtMqThVepI2Z2Doe_B-6HZlZ8'

export const COMMON_STATUS = {
  active: 'Active',
  inactive: 'Inactive',
  pending: 'Pending',
  onLeave: 'On Leave',
}

export const PROCESSING_TYPE = {
  breadProgram: 'Bread Program',
  product: 'Product',
  regular: 'Regular',
}

export const USER_ROLE = {
  systemAdmin: 'System Admin',
  admin: 'Admin',
  driver: 'Driver',
}

export const SETTINGS_TYPE = {
  beneficiaryAffiliation: 'beneficiary-affiliation',
  beneficiaryStatus: 'beneficiary-status',
  contactType: 'contact-type',
  corporateGroup: 'corporate-group',
  donorCategory: 'donor-category',
  donorStatus: 'donor-status',
  ngoCategory: 'ngo-category',
  programme: 'programme',
  reason: 'reason',
  region: 'region',
  restrictionType: 'restriction-type',
  sponsor: 'sponsor',
  targetBeneficiaryGroup: 'target-beneficiary-group',
  frequency: 'frequency',
}

export const SETTINGS_TITLE = {
  beneficiaryAffiliation: 'BENEFICIARY AFFILIATION',
  beneficiaryStatus: 'BENEFICIARY STATUS',
  contactType: 'CONTACT TYPE',
  corporateGroup: 'CORPORATE GROUP',
  donorCategory: 'DONOR CATEGORIES',
  donorStatus: 'DONOR STATUS',
  ngoCategory: 'NGO CATEGORY',
  programme: 'PROGRAMME',
  reason: 'REASONS',
  region: 'REGION',
  restrictionType: 'RESTRICTION TYPE',
  sponsor: 'SPONSOR',
  targetBeneficiaryGroup: 'TARGET BENEFICIARY GROUP',
  frequency: 'FREQUENCY',
}

export const ADD_FIELD_DONOR = {
  packing: 'Packing',
  deliveryNoteRequired: 'Delivery Note Required ?',
  donorStatusId: 'Donor Status',
}

export const ADD_FIELD_DONOR_ARR = [
  {id: 1, value: 'packing', label: 'Packing'},
  {id: 2, value: 'deliveryNoteRequired', label: 'Delivery Note Required ?'},
  {id: 3, value: 'donorStatusId', label: 'Donor Status'},
]

export const ADD_FIELD_BNE = {
  AvailableStorageType: 'Available Storage Type',
  RequestFoodType: 'Pending Request',
  BeneficiaryAffiliation: 'Beneficiary Affiliation',
  SelfPickUp: 'Self Pick-up',
  BeneficiaryStatus: 'Beneficiary Status',
}

export const ADD_FIELD_BNE_ARR = [
  {id: 1, value: 'AvailableStorageType', label: 'Available Storage Type'},

  {id: 3, value: 'BeneficiaryAffiliation', label: 'Beneficiary Affiliation'},
  {id: 5, value: 'BeneficiaryStatus', label: 'Beneficiary Status'},
  {id: 2, value: 'RequestFoodType', label: 'Pending Request'},
  {id: 4, value: 'SelfPickUp', label: 'Self Pick-Up'},
]

export const ADD_TEXT = {
  BreadProgram: 'Bread Program',
  Reporting: 'Reporting',
}

export const DayInWeek = [
  {
    id: 8,
    day: 'Sun',
    label: 'Sunday',
    isActive: false,
  },
  {
    id: 2,
    day: 'Mon',
    label: 'Monday',
    isActive: false,
  },
  {
    id: 3,
    day: 'Tue',
    label: 'Tuesday',
    isActive: false,
  },
  {
    id: 4,
    day: 'Wed',
    label: 'Wednesday',
    isActive: false,
  },
  {
    id: 5,
    day: 'Thu',
    label: 'Thursday',
    isActive: false,
  },
  {
    id: 6,
    day: 'Fri',
    label: 'Friday',
    isActive: false,
  },
  {
    id: 7,
    day: 'Sat',
    label: 'Saturday',
    isActive: false,
  },
]

export const months = [
  'January / 一月',
  'February / 二月',
  'March / 三月',
  'April / 四月',
  'May / 五月',
  'June / 六月',
  'July / 七月',
  'August / 八月',
  'September / 	九月',
  'October / 十月',
  'November / 十一月',
  'December / 十二月',
]

export const days = [
  'Sunday / 星期日',
  'Monday / 星期一',
  'Tuesday / 星期二',
  'Wednesday / 星期三',
  'Thursday / 星期四',
  'Friday / 星期五',
  'Saturday / 星期六',
]

export const Frequency = [
  {
    id: 0,
    value: 'none',
    label: 'Ad-Hoc',
  },
  {
    id: 1,
    value: 'daily',
    label: 'Daily',
  },
  {
    id: 3,
    value: 'month',
    label: 'Monthly',
  },
  {
    id: 2,
    value: 'week',
    label: 'Weekly',
  },
]

export const MealType = [
  {
    value: 'Breakfast',
    label: 'Breakfast',
  },
  {
    value: 'Lunch',
    label: 'Lunch',
  },
  {
    value: 'Dinner',
    label: 'Dinner',
  },
  {
    value: 'Snack',
    label: 'Snack',
  },
]

export const PreferredLanguage = [
  {
    value: 'Chinese',
    label: 'Chinese',
  },
  {
    value: 'English',
    label: 'English',
  },
]

export const AvailableStorageType = [
  {
    id: '1',
    value: 'Room Temperature',
    label: 'Room Temperature',
    isChecked: true,
  },
  {
    id: '2',
    value: 'Freezer',
    label: 'Freezer',
    isChecked: true,
  },
  {
    id: '3',
    value: 'Refrigerator',
    label: 'Refrigerator',
    isChecked: true,
  },
]

export const StorageType = [
  {
    id: '1',
    value: 'Room Temperature',
    label: 'Room Temperature',
    isChecked: true,
  },
  {
    id: '2',
    value: 'Freezer',
    label: 'Freezer',
    isChecked: true,
  },
  {
    id: '3',
    value: 'Refrigerator',
    label: 'Refrigerator',
    isChecked: true,
  },
]

export const columnSchedule = {
  allDonor: 'ALL DONORS',
  schedule: 'SCHEDULE',
  allBeneficiaries: 'ALL BENEFICIARIES',
  donorbranch: 'DONOR BRANCH',
  addHoc: 'AD-HOC DONOR',
  donorSchedule: 'DONOR SCHEDULE',
  beneSchedule: 'BENE SCHEDULE',
}

// TH DropOff ....................
export const imageDonorDropOff = {
  url: toAbsoluteUrl('/media/svg/foodlink/dropOffDonor.svg'),
}

export const imageBeneDropOff = {
  url: toAbsoluteUrl('/media/svg/foodlink/dropOffBene.svg'),
}

export const imageDonorPickUp = {
  url: toAbsoluteUrl('/media/svg/foodlink/pickUpDonor.svg'),
}

export const imageBenePickUp = {
  url: toAbsoluteUrl('/media/svg/foodlink/pickUpBene.svg'),
}

export const colsSearchClm = [
  'donor.nameCN',
  'donor.nameEN',
  'pickupLocation',
  // 'status',
  // 'parentStatus',
  'pickupTime',
  'pickupDate',
  'source',
]

export const colsSearchBene = [
  'address',
  'region.name',
  'recurence',
  'status',
  'coporateGroup.name',
  'updatedAt',
  'beneficiaryTargetGroupsData',
]

export const colsSearchDonor = [
  // 'foodCollectionAddressCN',
  // 'foodCollectionAddressEN',
  'coporateGroup',
  'recurence',
  'status',
  'coporateGroup.name',
  'updatedAt',
  // 'dayOfCollection',
]

export enum EAvailableLanguages {
  'cn' = 'cn',
  'en' = 'en',
}

export enum ISorting {
  ASC = 'ASC',
  DESC = 'DESC',
}
var lodash = require('lodash')

export const compareFnc = <T>(type: ISorting, arr: T[], key: keyof T) => {
  let sorted: T[]
  switch (type) {
    case ISorting.ASC:
      sorted = arr?.sort((a, b) => {
        return lodash.get(a, key)?.localeCompare(lodash.get(b, key), EAvailableLanguages.cn)
      })
      break
    default:
      sorted = arr?.sort((a, b) => {
        return lodash.get(b, key)?.localeCompare(lodash.get(a, key), EAvailableLanguages.cn)
      })
  }
  console.log('sorted.............', sorted)
  return sorted
}

export enum ADD_BREAD_PROGRAM_STEPS {
  CHOOSE_BENEFICIARY = 1,
  CHOOSE_DONOR = 2,
  FORM = 3,
}

export enum DATE_TYPE {
  MIN_DATE = 'MIN_DATE',
  MAX_DATE = 'MAX_DATE',
}
