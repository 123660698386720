import {IDatePayload} from '@pages/bread-program/models/BreadProgramModel'
import client from '.'

export const apiGetListBreadProgram = () => {
  return client.get(`/public/donor-branches`).then((res) => res)
}

export const apiGetScheduleBeneficiary = (beneficiaryId: string, donorBranchId: string) => {
  return client.get(
    `/public/beneficiary/${beneficiaryId}/donor-branch/${donorBranchId}/bread-program`
  )
}

export const apiAddBreadProgram = (breadProgramId: string, dates: IDatePayload[]) => {
  return client
    .post(`/public/bread-program/${breadProgramId}/active-date`, dates)
    .then((res) => res.data)
}
