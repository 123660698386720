import {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'

import {KTSVG} from '@metronic/helpers'
import CommonModal from '@src/app/common/Modal/CommonModal'
import {ADD_BREAD_PROGRAM_STEPS, DayInWeek, months} from '@src/app/constants/constant'
import {alertError, getDateItemsInMonth} from '@src/app/services/helper'
import {useSelector} from '@src/setup/redux/RootReducer'
import {IDateItem} from '../models/BreadProgramModel'
import {
  addNewBreadProgramAction,
  getExternalBreadProgramAction,
  setBreadProgramStoreAction,
} from '../redux/ManagaBreadProgramActions'
import DateItem from './DateItem'

interface IProps {
  setStep: (value: number) => void
}

const BreadProgramForm = ({setStep}: IProps) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const externalBreadProgram = useSelector((state) => state.BreadProgramManage.externalBreadProgram)
  const {selectedBeneficiary, selectedDonorBranch, selectedDate} = useSelector(
    (state) => state.BreadProgramManage
  )
  const [cancelModalVisible, setCancelModalVisible] = useState<boolean>(false)
  const [clearModalVisible, setClearModalVisible] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [listDates, setListDates] = useState<IDateItem[]>([])

  const goBack = () => {
    setStep(ADD_BREAD_PROGRAM_STEPS.CHOOSE_DONOR)
  }

  const getMonth = () => {
    const date = new Date(selectedDate)
    return months[date.getMonth()]
  }

  const handleChangeQuantity = (index: number, quantity: string) => {
    listDates[index].quantity = (quantity == '' ? undefined : quantity)
    setListDates([...listDates])
    if (errorMessage) setErrorMessage('')
  }

  const handleOpenCancelModal = () => {
    setCancelModalVisible(true)
  }

  const handleOkCancelModal = () => {
    goBack()
    handleCloseCancelModal()
  }

  const handleCloseCancelModal = () => {
    setCancelModalVisible(false)
  }

  const handleOpenClearModal = () => {
    setClearModalVisible(true)
  }

  const handleOkClearModal = () => {
    resetState()
    handleCloseClearModal()
  }

  const handleCloseClearModal = () => {
    setClearModalVisible(false)
  }

  const handleSubmit = () => {
    if (!externalBreadProgram) return alertError('Does not has form to submit!')

    if (externalBreadProgram?.id) {
      const dates = listDates
        .filter(({isDisabled}) => !isDisabled)
        .map(({date, quantity}) => ({date, quantity}))
      dispatch(
        addNewBreadProgramAction({
          breadProgramId: externalBreadProgram?.id,
          dates,
          onSuccess: () => {
            history.push('/bread-program/complete')
            dispatch(
              setBreadProgramStoreAction({
                selectedDate: '',
              })
            )
          },
        })
      )
    }
  }

  const resetState = () => {
    const listDatesReset = listDates.map((date) => {
      if (!date?.isDisabled) {
        return {...date, quantity: ''}
      } else {
        return {...date, quantity: date?.quantity}
      }
    })
    setListDates(listDatesReset)
    setErrorMessage('')

    console.log(listDatesReset)
  }

  useEffect(() => {
    if (selectedBeneficiary?.id && selectedDonorBranch?.id) {
      dispatch(
        getExternalBreadProgramAction({
          beneficiaryId: selectedBeneficiary.id,
          donorBranchId: selectedDonorBranch.id,
        })
      )
    }
  }, [selectedBeneficiary, selectedDonorBranch])

  useEffect(() => {
    if (selectedDate && externalBreadProgram) {
      const dates = getDateItemsInMonth(
        selectedDate,
        externalBreadProgram
      )
      setListDates(dates)
    } else {
      setListDates([])
    }
  }, [selectedDate, externalBreadProgram])

  return (
    <>
      <div className='card'>
        <div className='card-body py-8'>
          <u role='button' onClick={goBack}>
            <KTSVG path='/media/icons/duotune/arrows/arr074.svg' />
            Back / 返回
          </u>
          <h3 className='card-title fs-1 mb-10'>BREAD PROGRAM / 麵包回收計劃</h3>

          <div className='row align-items-center mb-2'>
            <label className='col-lg-3 fs-6'>Beneficiary / 受惠機構</label>
            <span className='col-lg-9 fw-bold fs-6'>
              {selectedBeneficiary?.nameCN || selectedBeneficiary?.nameEN}
            </span>
          </div>

          <div className='row align-items-center mb-2'>
            <label className='col-lg-3 fs-6'>Donor Name / 捐贈機構</label>
            <span className='col-lg-9 fw-bold fs-6'>
              {selectedDonorBranch?.donor?.nameCN || selectedDonorBranch?.donor?.nameEN}
            </span>
          </div>

          <div className='row align-items-center mb-2'>
            <label className='col-lg-3 fs-6'>Donor Branch Name / 取包店舖</label>
            <span className='col-lg-9 fw-bold fs-6'>{selectedDonorBranch?.name}</span>
          </div>

          <div className='row align-items-center mb-2'>
            <label className='col-lg-3 fs-6'>Branch Address (English) / 店舖地址 (英文)</label>
            <span className='col-lg-9 fw-bold fs-6'>{selectedDonorBranch?.addressEN}</span>
          </div>

          <div className='row align-items-center mb-2'>
            <label className='col-lg-3 fs-6'>Branch Address (Chinese) / 店舖地址 (中文)</label>
            <span className='col-lg-9 fw-bold fs-6'>{selectedDonorBranch?.addressCN}</span>
          </div>

          <div className='row align-items-center mb-2'>
            <label className='col-lg-3 fs-6'>Telephone / 店舖電話</label>
            <span className='col-lg-9 fw-bold fs-6'>{selectedDonorBranch?.phone}</span>
          </div>

          <div className='row align-items-center mb-2'>
            <label className='col-lg-3 fs-6'>Operation Days & Hours / 營業時間</label>
            <span className='col-lg-9 fw-bold fs-6'>{selectedDonorBranch?.operatingTime}</span>
          </div>

          <div className='row mb-6 align-items-center mb-2'>
            <label className='col-lg-3 fs-6'>Day(s) of Collection / 取包日子</label>
            <div className='col-lg-9 d-flex'>
              {DayInWeek.map((day) => (
                <span
                  key={day.id}
                  style={{border: '1px solid'}}
                  className={`p-2 me-3 ${
                    externalBreadProgram?.schedules?.includes(day.id) ? 'active-day' : ''
                  }`}
                >
                  {day.day}
                </span>
              ))}
            </div>
          </div>

          <div className='row align-items-center'>
            <label className='col-lg-3 col-form-label fw-bold fs-6'>Select Month / 選擇月份</label>
            <h2 className='col-lg-9 mb-0'>{getMonth()}</h2>
          </div>

          <table
            id='myTable'
            className='table table-row-bordered table-row-gray-300 align-middle gs-0 gy-4'
          >
            <thead>
              <tr className='fw-bolder texted'>
                <th className='min-w-140px col-xxl-2 col-md-2' />
                <th className='min-w-140px col-xxl-2 col-md-2 fs-6'>Date / 日期</th>
                <th className='min-w-140px col-xxl-2 col-md-2 fs-6'>Quantity / 數量</th>
              </tr>
            </thead>
            <tbody>
              {listDates.map((item, index) => (
                <DateItem
                  key={index}
                  index={index}
                  item={item}
                  onChangeQuantity={handleChangeQuantity}
                />
              ))}
            </tbody>
          </table>

          {errorMessage && (
            <div className='fv-plugins-message-container mt-2'>
              <div className='fv-help-block text-danger'>{errorMessage}</div>
            </div>
          )}

          <div className='d-flex justify-content-end'>
            <button
              type='button'
              className='btn btn-primary py-2 px-4 rounded-1'
              onClick={handleSubmit}
            >
              SUBMIT / 提交
            </button>
            <button
              type='button'
              className='btn btn-secondary py-2 px-4 rounded-1 mx-4'
              onClick={handleOpenClearModal}
            >
              CLEAR / 清除
            </button>
            <button
              type='button'
              className='btn btn-secondary py-2 px-4 rounded-1'
              onClick={handleOpenCancelModal}
            >
              CANCEL / 取消
            </button>
          </div>
        </div>
      </div>

      <CommonModal
        title='WARNING / 警告'
        body='This will cancel and return you to the previous screen \n 取消已輸入資料，並返回前一個畫面'
        okText='OKAY / 確定'
        cancelText='CANCEL / 取消'
        isOpen={cancelModalVisible}
        onOk={handleOkCancelModal}
        onClose={handleCloseCancelModal}
      />
      <CommonModal
        title='WARNING / 警告'
        body='This will clear all inputs for the month \n 所有已輸入的資料將會被清除'
        okText='OKAY / 確定'
        cancelText='CANCEL / 取消'
        isOpen={clearModalVisible}
        onOk={handleOkClearModal}
        onClose={handleCloseClearModal}
      />
    </>
  )
}

export default BreadProgramForm
