import {IContact} from '../models/ContactModel'

export enum EManageContactType {
  GET_LIST_CONTACT = '[manage Contact] GET_LIST_CONTACT',
  SAVE_CONTACT_RD = '[manage Contact] SAVE_CONTACT_RD',
  ADD_NEW_CONTACT = '[manage Contact] ADD_NEW_CONTACT',
  EDIT_CONTACT = '[manage Contact] EDIT_CONTACT',
  DELETE_ITEM_CONTACT = '[manage Contact] DELETE_ITEM_CONTACT',
  ADD_LIST_CONTACT = '[manage Contact] ADD_LIST_CONTACT',
  DELETE_ITEM_CONTACT_RD = '[manage Contact] DELETE_ITEM_CONTACT_RD',
  EDIT_ITEM_CONTACT_RD = '[manage Contact] EDIT_ITEM_CONTACT_RD',
  DELETE_DONOR_CONTACT = 'DELETE_DONOR_CONTACT',
  DELETE_BNF_ADDRESS = 'DELETE_BNF_ADDRESS',
  DELETE_BNF_GROUP = 'DELETE_BNF_GROUP',
  DELETE_BNF_CONTACT_ITEM = 'DELETE_BNF_CONTACT_ITEM',
  DELETE_BNF_FOOD_ITEM = 'DELETE_BNF_FOOD_ITEM',
  DELETE_BNF_RQ_FOOD_ITEM = 'DELETE_BNF_RQ_FOOD_ITEM',
  DELETE_DONOR_FOOD_ITEM = 'DELETE_DONOR_FOOD_ITEM',
}

// ======================== Contact =========================== //

export const getContactAction = () => ({
  type: EManageContactType.GET_LIST_CONTACT,
})

export const saveContactAction = (payload: IContact[]) => ({
  type: EManageContactType.SAVE_CONTACT_RD,
  payload,
})

export const addNewContactAction = (payload: {value: any; onSuccess: any; onError: any}) => ({
  type: EManageContactType.ADD_NEW_CONTACT,
  payload,
})

export const editContactAction = (payload: {value: any; onSuccess: any; onError: any}) => ({
  type: EManageContactType.EDIT_CONTACT,
  payload,
})

export const deleteContactItemAction = (payload: {id: any; onSuccess: any; onError: any}) => ({
  type: EManageContactType.DELETE_ITEM_CONTACT,
  payload,
})

export const deleteDonorContactAction = (payload: {id: any; onSuccess: any; onError: any}) => ({
  type: EManageContactType.DELETE_DONOR_CONTACT,
  payload,
})

export const deleteBeneficiariesDeleteTargetGroupAction = (payload: {
  id: any
  onSuccess: any
  onError: any
}) => ({
  type: EManageContactType.DELETE_BNF_GROUP,
  payload,
})

export const deleteBeneficiariesDeleteDeliveryAddressAction = (payload: {
  id: any
  onSuccess: any
  onError: any
}) => ({
  type: EManageContactType.DELETE_BNF_ADDRESS,
  payload,
})

export const deleteBeneficiariesContactItemAction = (payload: {
  id: any
  onSuccess: any
  onError: any
}) => ({
  type: EManageContactType.DELETE_BNF_CONTACT_ITEM,
  payload,
})

export const deletePreferFoodBeneficiariesAction = (payload: {
  id: any
  onSuccess: any
  onError: any
}) => ({
  type: EManageContactType.DELETE_BNF_FOOD_ITEM,
  payload,
})

export const deleteBeneficiariesRQFoodTypeItemAction = (payload: {
  id: any
  onSuccess: any
  onError: any
}) => ({
  type: EManageContactType.DELETE_BNF_RQ_FOOD_ITEM,
  payload,
})

export const deleteFoodTypeDonorAction = (payload: {id: any; onSuccess: any; onError: any}) => ({
  type: EManageContactType.DELETE_DONOR_FOOD_ITEM,
  payload,
})
