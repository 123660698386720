import {IUser} from '@pages/system-user/models/UserModel'
import React, {useState} from 'react'

type Props = {
  mode: 'add' | 'delete' | 'edit' | 'cancel' | 'optimise' | 'save' | 'warning' | null
  data?: IUser
  onClose: Function
  offAllModal: any
}

const PopUpCancel: React.FC<Props> = ({onClose, data, mode, offAllModal}) => {
  const [loading, setLoading] = useState(false)

  const onSaveYes = () => {
    offAllModal()
  }

  return (
    <>
      <div className='card-header justify-content-center mt-5 mb-5' style={{textAlign: 'center'}}>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>You haven't saved. Do you want to exit ?</h3>
        </div>
      </div>
      <div className='card-footer border-0 d-sm-flex justify-content-end p-sm-0 py-sm-6 px-sm-9'>
        <button className='btn btn-success p-3 mx-sm-4' onClick={() => onSaveYes()}>
          {loading ? (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please Wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          ) : (
            <>YES</>
          )}
        </button>

        <button
          className='btn btn-danger p-3 mx-sm-4'
          onClick={() => {
            onClose()
            const parentModal = document.getElementsByClassName('parent-modal')
            if (parentModal && parentModal.length) {
              parentModal[0].classList.remove('hidden')
            }
          }}
        >
          NO
        </button>
      </div>
    </>
  )
}

export default PopUpCancel
