import {AxiosResponse} from 'axios'
import {call, put, takeLatest} from 'redux-saga/effects'

import {IListResult} from '@src/app/interfaces/common.interface'
import {
  apiAddBreadProgram,
  apiGetListBreadProgram,
  apiGetScheduleBeneficiary,
} from '@src/app/services/api/breadProgramApi'
import {alertError, alertSuccess} from '@src/app/services/helper'
import {
  addNewBreadProgramAction,
  EBreadProgramType,
  getExternalBreadProgramAction,
  getScheduleSuccessAction,
  saveListBreadProgramAction,
} from './ManagaBreadProgramActions'

function* addBreadProgram({payload}: ReturnType<typeof addNewBreadProgramAction>) {
  try {
    const {breadProgramId, dates} = payload
    yield call(apiAddBreadProgram, breadProgramId, dates)
    payload.onSuccess()
    alertSuccess('Add Bread Program successfully !')
  } catch (error: any) {
    alertError(error ?? 'Add Bread Program failed')
    payload.onError()
  }
}

function* getListBreadProgram() {
  try {
    const res: IListResult = yield call(apiGetListBreadProgram)
    if (res) {
      yield put(saveListBreadProgramAction(res.data))
    }
  } catch (error: any) {}
}

function* getExternalBreadProgram({payload}: ReturnType<typeof getExternalBreadProgramAction>) {
  try {
    const res: AxiosResponse<any> = yield call(
      apiGetScheduleBeneficiary,
      payload.beneficiaryId,
      payload.donorBranchId
    )
    if (res.data) yield put(getScheduleSuccessAction(res.data))
  } catch (error: any) {
    yield put(getScheduleSuccessAction())
  }
}

export default function* manageBreadProgramSaga() {
  yield takeLatest(EBreadProgramType.ADD_BREAD_PROGRAM, addBreadProgram)
  yield takeLatest(EBreadProgramType.GET_EXTERNAL_BREAD_PROGRAM, getExternalBreadProgram)
  yield takeLatest(EBreadProgramType.GET_LIST_BREAD_PROGRAM, getListBreadProgram)
}
