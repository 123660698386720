import {Route, Switch} from 'react-router-dom'

import AdHocComplete from './AdHocComplete'
import AdHoc from './AdHoc'

function MainAdHoc() {
  return (
    <Switch>
      <Route path='/ad-hoc' exact={true} component={AdHoc} />
      <Route path='/ad-hoc/complete' exact={true} component={AdHocComplete} />
    </Switch>
  )
}

export default MainAdHoc
