import {useFormik} from 'formik'
import {useState} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'

type Props = {
  data?: any
  onClose: any
  type: any
  addContent: any
  onDeleteNote: (index: number, id?: string) => void
}

const profileDetailsSchema = Yup.object().shape({
  content: Yup.string().required('This field cannot be left blank.'),
})

const PopUpNotesForm: React.FC<Props> = ({data, onClose, type, addContent, onDeleteNote}) => {
  const initialValues = {
    content: data?.content ?? '',
  }
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [modeViewNote, setModeViewNote] = useState(true)
  const [contentNote, setContentNote] = useState('')
  const [listNotes, setListNotes] = useState<any>([])

  const formik = useFormik({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: (values) => {
      const value = {
        ...values,
        id: data?.id,
      }
      if (addContent) addContent(value)
      formik.setFieldValue('content', '')
    },
  })

  const openDetailNote = (data: any) => {
    setContentNote(data.content)
    setModeViewNote(!modeViewNote)
  }

  const handleDeleteNote = (index: number, idNote?: string) => {
    onDeleteNote(index, idNote)
  }

  const changeViewNote = () => {
    setModeViewNote(!modeViewNote)
  }

  const closeModalNote = () => {
    if (modeViewNote) {
      onClose()
    } else {
      changeViewNote()
    }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <>
        <div className='card-header border-0 justify-content-center'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>NOTES</h3>
          </div>
          <div
            style={{position: 'absolute', top: 25, right: 25, cursor: 'pointer'}}
            onClick={closeModalNote}
          >
            <i className='bi bi-x-lg'></i>
          </div>
        </div>

        <div>
          <div className='card-title ms-10 mb-5'>
            <h4 className='fw-bolder m-0 mb-3'>
              {type !== 'adhoc'
                ? data?.nameEN || data?.nameCN
                : data?.donor?.nameEN || data?.donor?.nameCN}
            </h4>
            <p className='fw-bolder m-0 text-muted'>
              {type !== 'adhoc'
                ? data?.foodCollectionAddressEN || data?.foodCollectionAddressCN
                : data?.pickupLocation}
            </p>
          </div>
        </div>

        {modeViewNote ? (
          <div className='row justify-content-center'>
            <div
              className=' col-lg-11 border border-gray-300 schedule-item mb-5 ps-5 pt-3'
              style={{height: 180, overflowX: 'hidden'}}
            >
              {data &&
                data.map((note: any, index: number) => {
                  return (
                    <div key={index} className='row'>
                      <div className='col-6' onClick={() => openDetailNote(note)}>
                        <p
                          className='fw-bolder'
                          style={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            fontSize: 15,
                            cursor: 'pointer',
                          }}
                        >
                          {note.content}
                        </p>
                      </div>
                      <div
                        className='col-6'
                        style={{paddingTop: 5, textAlign: 'end', paddingRight: 20}}
                      >
                        <i
                          className='bi bi-trash-fill ms-3 '
                          onClick={() => handleDeleteNote(index, note.id || undefined)}
                          style={{cursor: 'pointer', fontSize: 15}}
                        ></i>
                      </div>
                    </div>
                  )
                })}
            </div>
          </div>
        ) : (
          <div className='ps-20 pe-20 col-lg-12 fv-row'>
            <textarea
              rows={10}
              cols={50}
              value={contentNote}
              disabled
              className={`form-select form-select-solid form-select-lg fw-bold`}
            />
          </div>
        )}
        {modeViewNote && (
          <div id='kt_account_profile_details' className='collapse show'>
            <form onSubmit={formik.handleSubmit} noValidate className='form'>
              <div className='card-body border-bottom p-9'>
                <div className='row mb-6 justify-content-center'>
                  <div className='col-lg-12 fv-row'>
                    <textarea
                      rows={7}
                      cols={50}
                      placeholder={'Insert notes here .....'}
                      className={`form-select form-select-solid form-select-lg fw-bold`}
                      {...formik.getFieldProps('content')}
                    />
                  </div>
                </div>
              </div>
              <div className='card-footer border-0 d-sm-flex justify-content-center p-sm-0 py-sm-6 px-sm-9'>
                <button
                  type='submit'
                  className={`btn btn-secondary ${
                    formik.values.content === '' ? '' : 'btn-success'
                  } p-3 mx-sm-4`}
                  disabled={loading || formik.values.content === ''}
                >
                  {loading ? (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please Wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  ) : (
                    <>ADD TO NOTES </>
                  )}
                </button>
              </div>
            </form>
          </div>
        )}
      </>
    </div>
  )
}
export default PopUpNotesForm
