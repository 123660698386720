export const strokeData: any = {
  '3400': 5,
  '3401': 6,
  '3402': 6,
  '3403': 3,
  '3404': 3,
  '3405': 2,
  '3406': 6,
  '3407': 3,
  '3408': 3,
  '3409': 3,
  '340A': 4,
  '340B': 4,
  '340C': 5,
  '340D': 5,
  '340E': 5,
  '340F': 5,
  '3410': 6,
  '3411': 6,
  '3412': 6,
  '3413': 6,
  '3414': 6,
  '3415': 6,
  '3416': 7,
  '3417': 7,
  '3418': 7,
  '3419': 8,
  '341A': 8,
  '341B': 8,
  '341C': 9,
  '341D': 9,
  '341E': 9,
  '341F': 9,
  '3420': 9,
  '3421': 11,
  '3422': 11,
  '3423': 11,
  '3424': 12,
  '3425': 16,
  '3426': 19,
  '3427': 4,
  '3428': 8,
  '3429': 8,
  '342A': 6,
  '342B': 6,
  '342C': 7,
  '342D': 8,
  '342E': 13,
  '342F': 17,
  '3430': 5,
  '3431': 5,
  '3432': 5,
  '3433': 5,
  '3434': 5,
  '3435': 5,
  '3436': 5,
  '3437': 5,
  '3438': 6,
  '3439': 6,
  '343A': 6,
  '343B': 6,
  '343C': 6,
  '343D': 6,
  '343E': 6,
  '343F': 6,
  '3440': 6,
  '3441': 7,
  '3442': 7,
  '3443': 7,
  '3444': 7,
  '3445': 7,
  '3446': 7,
  '3447': 7,
  '3448': 7,
  '3449': 8,
  '344A': 8,
  '344B': 8,
  '344C': 8,
  '344D': 8,
  '344E': 8,
  '344F': 8,
  '3450': 8,
  '3451': 8,
  '3452': 8,
  '3453': 8,
  '3454': 8,
  '3455': 8,
  '3456': 8,
  '3457': 9,
  '3458': 9,
  '3459': 9,
  '345A': 9,
  '345B': 9,
  '345C': 9,
  '345D': 9,
  '345E': 9,
  '345F': 9,
  '3460': 9,
  '3461': 9,
  '3462': 9,
  '3463': 10,
  '3464': 11,
  '3465': 10,
  '3466': 10,
  '3467': 10,
  '3468': 10,
  '3469': 10,
  '346A': 10,
  '346B': 10,
  '346C': 10,
  '346D': 10,
  '346E': 11,
  '346F': 11,
  '3470': 11,
  '3471': 11,
  '3472': 11,
  '3473': 12,
  '3474': 12,
  '3475': 12,
  '3476': 12,
  '3477': 12,
  '3478': 12,
  '3479': 12,
  '347A': 12,
  '347B': 13,
  '347C': 13,
  '347D': 13,
  '347E': 13,
  '347F': 13,
  '3480': 13,
  '3481': 13,
  '3482': 14,
  '3483': 14,
  '3484': 14,
  '3485': 14,
  '3486': 14,
  '3487': 14,
  '3488': 14,
  '3489': 14,
  '348A': 14,
  '348B': 14,
  '348C': 14,
  '348D': 14,
  '348E': 14,
  '348F': 14,
  '3490': 14,
  '3491': 15,
  '3492': 15,
  '3493': 15,
  '3494': 15,
  '3495': 15,
  '3496': 15,
  '3497': 15,
  '3498': 15,
  '3499': 16,
  '349A': 16,
  '349B': 16,
  '349C': 16,
  '349D': 17,
  '349E': 17,
  '349F': 18,
  '34A0': 18,
  '34A1': 19,
  '34A2': 19,
  '34A3': 19,
  '34A4': 20,
  '34A5': 20,
  '34A6': 20,
  '34A7': 21,
  '34A8': 21,
  '34A9': 23,
  '34AA': 26,
  '34AB': 6,
  '34AC': 8,
  '34AD': 10,
  '34AE': 11,
  '34AF': 18,
  '34B0': 5,
  '34B1': 5,
  '34B2': 6,
  '34B3': 7,
  '34B4': 7,
  '34B5': 7,
  '34B6': 7,
  '34B7': 7,
  '34B8': 9,
  '34B9': 20,
  '34BA': 8,
  '34BB': 11,
  '34BC': 11,
  '34BD': 12,
  '34BE': 13,
  '34BF': 22,
  '34C0': 4,
  '34C1': 4,
  '34C2': 9,
  '34C3': 12,
  '34C4': 15,
  '34C5': 4,
  '34C6': 6,
  '34C7': 6,
  '34C8': 7,
  '34C9': 8,
  '34CA': 8,
  '34CB': 8,
  '34CC': 8,
  '34CD': 8,
  '34CE': 9,
  '34CF': 9,
  '34D0': 10,
  '34D1': 10,
  '34D2': 10,
  '34D3': 11,
  '34D4': 12,
  '34D5': 12,
  '34D6': 13,
  '34D7': 14,
  '34D8': 11,
  '34D9': 6,
  '34DA': 5,
  '34DB': 5,
  '34DC': 5,
  '34DD': 6,
  '34DE': 6,
  '34DF': 7,
  '34E0': 7,
  '34E1': 8,
  '34E2': 8,
  '34E3': 8,
  '34E4': 8,
  '34E5': 8,
  '34E6': 8,
  '34E7': 9,
  '34E8': 9,
  '34E9': 9,
  '34EA': 9,
  '34EB': 9,
  '34EC': 10,
  '34ED': 10,
  '34EE': 10,
  '34EF': 10,
  '34F0': 10,
  '34F1': 11,
  '34F2': 11,
  '34F3': 11,
  '34F4': 11,
  '34F5': 11,
  '34F6': 11,
  '34F7': 12,
  '34F8': 12,
  '34F9': 12,
  '34FA': 12,
  '34FB': 12,
  '34FC': 13,
  '34FD': 13,
  '34FE': 14,
  '34FF': 14,
  '3500': 14,
  '3501': 14,
  '3502': 14,
  '3503': 14,
  '3504': 14,
  '3505': 14,
  '3506': 14,
  '3507': 14,
  '3508': 14,
  '3509': 14,
  '350A': 15,
  '350B': 16,
  '350C': 16,
  '350D': 16,
  '350E': 17,
  '350F': 17,
  '3510': 18,
  '3511': 19,
  '3512': 20,
  '3513': 5,
  '3514': 6,
  '3515': 6,
  '3516': 6,
  '3517': 7,
  '3518': 7,
  '3519': 8,
  '351A': 8,
  '351B': 8,
  '351C': 9,
  '351D': 10,
  '351E': 10,
  '351F': 10,
  '3520': 11,
  '3521': 12,
  '3522': 14,
  '3523': 17,
  '3524': 17,
  '3525': 17,
  '3526': 17,
  '3527': 18,
  '3528': 11,
  '3529': 12,
  '352A': 13,
  '352B': 4,
  '352C': 8,
  '352D': 11,
  '352E': 21,
  '352F': 7,
  '3530': 7,
  '3531': 11,
  '3532': 13,
  '3533': 13,
  '3534': 13,
  '3535': 14,
  '3536': 26,
  '3537': 7,
  '3538': 12,
  '3539': 4,
  '353A': 6,
  '353B': 6,
  '353C': 13,
  '353D': 8,
  '353E': 3,
  '353F': 3,
  '3540': 7,
  '3541': 8,
  '3542': 6,
  '3543': 6,
  '3544': 6,
  '3545': 7,
  '3546': 7,
  '3547': 7,
  '3548': 8,
  '3549': 8,
  '354A': 9,
  '354B': 10,
  '354C': 10,
  '354D': 10,
  '354E': 12,
  '354F': 13,
  '3550': 14,
  '3551': 14,
  '3552': 15,
  '3553': 17,
  '3554': 24,
  '3555': 4,
  '3556': 10,
  '3557': 11,
  '3558': 11,
  '3559': 15,
  '355A': 4,
  '355B': 4,
  '355C': 6,
  '355D': 7,
  '355E': 8,
  '355F': 9,
  '3560': 12,
  '3561': 14,
  '3562': 14,
  '3563': 5,
  '3564': 5,
  '3565': 5,
  '3566': 6,
  '3567': 6,
  '3568': 6,
  '3569': 7,
  '356A': 7,
  '356B': 7,
  '356C': 7,
  '356D': 7,
  '356E': 7,
  '356F': 7,
  '3570': 7,
  '3571': 7,
  '3572': 7,
  '3573': 7,
  '3574': 7,
  '3575': 7,
  '3576': 7,
  '3577': 8,
  '3578': 8,
  '3579': 8,
  '357A': 8,
  '357B': 8,
  '357C': 8,
  '357D': 8,
  '357E': 8,
  '357F': 8,
  '3580': 9,
  '3581': 9,
  '3582': 9,
  '3583': 9,
  '3584': 9,
  '3585': 9,
  '3586': 9,
  '3587': 9,
  '3588': 9,
  '3589': 9,
  '358A': 9,
  '358B': 9,
  '358C': 9,
  '358D': 9,
  '358E': 9,
  '358F': 10,
  '3590': 10,
  '3591': 10,
  '3592': 10,
  '3593': 10,
  '3594': 10,
  '3595': 10,
  '3596': 10,
  '3597': 10,
  '3598': 10,
  '3599': 10,
  '359A': 10,
  '359B': 10,
  '359C': 10,
  '359D': 10,
  '359E': 10,
  '359F': 11,
  '35A0': 11,
  '35A1': 11,
  '35A2': 11,
  '35A3': 11,
  '35A4': 11,
  '35A5': 11,
  '35A6': 11,
  '35A7': 11,
  '35A8': 11,
  '35A9': 11,
  '35AA': 11,
  '35AB': 11,
  '35AC': 11,
  '35AD': 11,
  '35AE': 11,
  '35AF': 11,
  '35B0': 11,
  '35B1': 11,
  '35B2': 11,
  '35B3': 11,
  '35B4': 11,
  '35B5': 11,
  '35B6': 12,
  '35B7': 12,
  '35B8': 12,
  '35B9': 12,
  '35BA': 12,
  '35BB': 12,
  '35BC': 12,
  '35BD': 12,
  '35BE': 12,
  '35BF': 12,
  '35C0': 12,
  '35C1': 12,
  '35C2': 12,
  '35C3': 12,
  '35C4': 12,
  '35C5': 12,
  '35C6': 12,
  '35C7': 12,
  '35C8': 12,
  '35C9': 12,
  '35CA': 12,
  '35CB': 12,
  '35CC': 12,
  '35CD': 12,
  '35CE': 12,
  '35CF': 12,
  '35D0': 12,
  '35D1': 12,
  '35D2': 13,
  '35D3': 13,
  '35D4': 13,
  '35D5': 13,
  '35D6': 13,
  '35D7': 13,
  '35D8': 13,
  '35D9': 13,
  '35DA': 13,
  '35DB': 13,
  '35DC': 13,
  '35DD': 13,
  '35DE': 13,
  '35DF': 13,
  '35E0': 13,
  '35E1': 13,
  '35E2': 14,
  '35E3': 14,
  '35E4': 14,
  '35E5': 14,
  '35E6': 14,
  '35E7': 14,
  '35E8': 14,
  '35E9': 14,
  '35EA': 14,
  '35EB': 14,
  '35EC': 14,
  '35ED': 14,
  '35EE': 14,
  '35EF': 14,
  '35F0': 14,
  '35F1': 15,
  '35F2': 15,
  '35F3': 15,
  '35F4': 15,
  '35F5': 15,
  '35F6': 15,
  '35F7': 15,
  '35F8': 15,
  '35F9': 15,
  '35FA': 15,
  '35FB': 16,
  '35FC': 16,
  '35FD': 16,
  '35FE': 16,
  '35FF': 16,
  '3600': 16,
  '3601': 16,
  '3602': 16,
  '3603': 16,
  '3604': 16,
  '3605': 17,
  '3606': 17,
  '3607': 17,
  '3608': 17,
  '3609': 18,
  '360A': 18,
  '360B': 18,
  '360C': 18,
  '360D': 18,
  '360E': 18,
  '360F': 18,
  '3610': 19,
  '3611': 19,
  '3612': 19,
  '3613': 20,
  '3614': 20,
  '3615': 21,
  '3616': 21,
  '3617': 21,
  '3618': 22,
  '3619': 23,
  '361A': 23,
  '361B': 25,
  '361C': 26,
  '361D': 5,
  '361E': 5,
  '361F': 6,
  '3620': 8,
  '3621': 8,
  '3622': 9,
  '3623': 10,
  '3624': 14,
  '3625': 20,
  '3626': 5,
  '3627': 7,
  '3628': 7,
  '3629': 7,
  '362A': 7,
  '362B': 7,
  '362C': 7,
  '362D': 7,
  '362E': 7,
  '362F': 7,
  '3630': 7,
  '3631': 8,
  '3632': 8,
  '3633': 8,
  '3634': 8,
  '3635': 8,
  '3636': 9,
  '3637': 9,
  '3638': 9,
  '3639': 9,
  '363A': 9,
  '363B': 9,
  '363C': 9,
  '363D': 9,
  '363E': 9,
  '363F': 10,
  '3640': 10,
  '3641': 10,
  '3642': 10,
  '3643': 10,
  '3644': 10,
  '3645': 10,
  '3646': 10,
  '3647': 11,
  '3648': 11,
  '3649': 11,
  '364A': 11,
  '364B': 11,
  '364C': 11,
  '364D': 11,
  '364E': 12,
  '364F': 12,
  '3650': 12,
  '3651': 12,
  '3652': 12,
  '3653': 12,
  '3654': 12,
  '3655': 12,
  '3656': 12,
  '3657': 12,
  '3658': 12,
  '3659': 13,
  '365A': 13,
  '365B': 13,
  '365C': 13,
  '365D': 13,
  '365E': 13,
  '365F': 13,
  '3660': 14,
  '3661': 14,
  '3662': 14,
  '3663': 14,
  '3664': 14,
  '3665': 14,
  '3666': 14,
  '3667': 15,
  '3668': 15,
  '3669': 15,
  '366A': 15,
  '366B': 15,
  '366C': 15,
  '366D': 15,
  '366E': 15,
  '366F': 15,
  '3670': 16,
  '3671': 16,
  '3672': 16,
  '3673': 16,
  '3674': 16,
  '3675': 16,
  '3676': 16,
  '3677': 17,
  '3678': 17,
  '3679': 17,
  '367A': 17,
  '367B': 18,
  '367C': 18,
  '367D': 18,
  '367E': 19,
  '367F': 19,
  '3680': 20,
  '3681': 24,
  '3682': 25,
  '3683': 12,
  '3684': 15,
  '3685': 9,
  '3686': 9,
  '3687': 9,
  '3688': 5,
  '3689': 8,
  '368A': 12,
  '368B': 14,
  '368C': 14,
  '368D': 18,
  '368E': 5,
  '368F': 6,
  '3690': 6,
  '3691': 6,
  '3692': 7,
  '3693': 7,
  '3694': 8,
  '3695': 8,
  '3696': 8,
  '3697': 8,
  '3698': 8,
  '3699': 8,
  '369A': 9,
  '369B': 9,
  '369C': 11,
  '369D': 11,
  '369E': 11,
  '369F': 12,
  '36A0': 13,
  '36A1': 16,
  '36A2': 5,
  '36A3': 6,
  '36A4': 6,
  '36A5': 6,
  '36A6': 6,
  '36A7': 6,
  '36A8': 6,
  '36A9': 7,
  '36AA': 7,
  '36AB': 7,
  '36AC': 7,
  '36AD': 7,
  '36AE': 7,
  '36AF': 7,
  '36B0': 8,
  '36B1': 8,
  '36B2': 8,
  '36B3': 8,
  '36B4': 8,
  '36B5': 8,
  '36B6': 8,
  '36B7': 8,
  '36B8': 8,
  '36B9': 8,
  '36BA': 8,
  '36BB': 8,
  '36BC': 8,
  '36BD': 8,
  '36BE': 8,
  '36BF': 8,
  '36C0': 8,
  '36C1': 8,
  '36C2': 9,
  '36C3': 9,
  '36C4': 9,
  '36C5': 9,
  '36C6': 9,
  '36C7': 9,
  '36C8': 9,
  '36C9': 9,
  '36CA': 9,
  '36CB': 9,
  '36CC': 9,
  '36CD': 10,
  '36CE': 10,
  '36CF': 10,
  '36D0': 10,
  '36D1': 10,
  '36D2': 10,
  '36D3': 10,
  '36D4': 10,
  '36D5': 10,
  '36D6': 10,
  '36D7': 10,
  '36D8': 10,
  '36D9': 10,
  '36DA': 10,
  '36DB': 10,
  '36DC': 10,
  '36DD': 10,
  '36DE': 10,
  '36DF': 10,
  '36E0': 7,
  '36E1': 10,
  '36E2': 10,
  '36E3': 10,
  '36E4': 10,
  '36E5': 11,
  '36E6': 11,
  '36E7': 11,
  '36E8': 11,
  '36E9': 11,
  '36EA': 11,
  '36EB': 11,
  '36EC': 11,
  '36ED': 11,
  '36EE': 12,
  '36EF': 12,
  '36F0': 12,
  '36F1': 12,
  '36F2': 12,
  '36F3': 12,
  '36F4': 12,
  '36F5': 12,
  '36F6': 12,
  '36F7': 12,
  '36F8': 12,
  '36F9': 12,
  '36FA': 12,
  '36FB': 12,
  '36FC': 12,
  '36FD': 12,
  '36FE': 12,
  '36FF': 12,
  '3700': 12,
  '3701': 12,
  '3702': 12,
  '3703': 12,
  '3704': 12,
  '3705': 13,
  '3706': 13,
  '3707': 13,
  '3708': 13,
  '3709': 13,
  '370A': 13,
  '370B': 13,
  '370C': 13,
  '370D': 13,
  '370E': 13,
  '370F': 13,
  '3710': 13,
  '3711': 13,
  '3712': 13,
  '3713': 13,
  '3714': 13,
  '3715': 14,
  '3716': 14,
  '3717': 14,
  '3718': 14,
  '3719': 14,
  '371A': 14,
  '371B': 14,
  '371C': 14,
  '371D': 14,
  '371E': 14,
  '371F': 14,
  '3720': 14,
  '3721': 14,
  '3722': 14,
  '3723': 15,
  '3724': 15,
  '3725': 15,
  '3726': 15,
  '3727': 15,
  '3728': 15,
  '3729': 16,
  '372A': 16,
  '372B': 16,
  '372C': 16,
  '372D': 16,
  '372E': 17,
  '372F': 17,
  '3730': 18,
  '3731': 18,
  '3732': 19,
  '3733': 19,
  '3734': 19,
  '3735': 19,
  '3736': 20,
  '3737': 20,
  '3738': 20,
  '3739': 21,
  '373A': 22,
  '373B': 26,
  '373C': 26,
  '373D': 6,
  '373E': 7,
  '373F': 7,
  '3740': 8,
  '3741': 9,
  '3742': 10,
  '3743': 10,
  '3744': 12,
  '3745': 13,
  '3746': 15,
  '3747': 16,
  '3748': 25,
  '3749': 4,
  '374A': 5,
  '374B': 5,
  '374C': 6,
  '374D': 6,
  '374E': 7,
  '374F': 7,
  '3750': 7,
  '3751': 7,
  '3752': 8,
  '3753': 9,
  '3754': 9,
  '3755': 9,
  '3756': 9,
  '3757': 10,
  '3758': 10,
  '3759': 10,
  '375A': 10,
  '375B': 11,
  '375C': 11,
  '375D': 11,
  '375E': 11,
  '375F': 11,
  '3760': 11,
  '3761': 11,
  '3762': 12,
  '3763': 12,
  '3764': 13,
  '3765': 13,
  '3766': 13,
  '3767': 13,
  '3768': 13,
  '3769': 14,
  '376A': 14,
  '376B': 14,
  '376C': 14,
  '376D': 15,
  '376E': 15,
  '376F': 15,
  '3770': 18,
  '3771': 21,
  '3772': 26,
  '3773': 5,
  '3774': 7,
  '3775': 8,
  '3776': 10,
  '3777': 12,
  '3778': 10,
  '3779': 12,
  '377A': 14,
  '377B': 15,
  '377C': 6,
  '377D': 7,
  '377E': 8,
  '377F': 8,
  '3780': 9,
  '3781': 9,
  '3782': 10,
  '3783': 11,
  '3784': 11,
  '3785': 11,
  '3786': 11,
  '3787': 12,
  '3788': 12,
  '3789': 13,
  '378A': 13,
  '378B': 5,
  '378C': 6,
  '378D': 6,
  '378E': 7,
  '378F': 8,
  '3790': 8,
  '3791': 8,
  '3792': 9,
  '3793': 9,
  '3794': 9,
  '3795': 9,
  '3796': 9,
  '3797': 10,
  '3798': 11,
  '3799': 11,
  '379A': 12,
  '379B': 12,
  '379C': 14,
  '379D': 14,
  '379E': 14,
  '379F': 15,
  '37A0': 15,
  '37A1': 19,
  '37A2': 4,
  '37A3': 7,
  '37A4': 5,
  '37A5': 5,
  '37A6': 5,
  '37A7': 5,
  '37A8': 6,
  '37A9': 6,
  '37AA': 6,
  '37AB': 6,
  '37AC': 6,
  '37AD': 6,
  '37AE': 6,
  '37AF': 6,
  '37B0': 7,
  '37B1': 7,
  '37B2': 7,
  '37B3': 7,
  '37B4': 7,
  '37B5': 7,
  '37B6': 7,
  '37B7': 7,
  '37B8': 7,
  '37B9': 8,
  '37BA': 8,
  '37BB': 8,
  '37BC': 8,
  '37BD': 8,
  '37BE': 8,
  '37BF': 8,
  '37C0': 8,
  '37C1': 8,
  '37C2': 8,
  '37C3': 8,
  '37C4': 9,
  '37C5': 9,
  '37C6': 9,
  '37C7': 10,
  '37C8': 10,
  '37C9': 10,
  '37CA': 10,
  '37CB': 10,
  '37CC': 10,
  '37CD': 10,
  '37CE': 10,
  '37CF': 10,
  '37D0': 10,
  '37D1': 10,
  '37D2': 10,
  '37D3': 10,
  '37D4': 10,
  '37D5': 10,
  '37D6': 10,
  '37D7': 11,
  '37D8': 11,
  '37D9': 11,
  '37DA': 11,
  '37DB': 11,
  '37DC': 11,
  '37DD': 11,
  '37DE': 11,
  '37DF': 11,
  '37E0': 11,
  '37E1': 11,
  '37E2': 11,
  '37E3': 11,
  '37E4': 11,
  '37E5': 11,
  '37E6': 12,
  '37E7': 12,
  '37E8': 12,
  '37E9': 12,
  '37EA': 12,
  '37EB': 12,
  '37EC': 12,
  '37ED': 12,
  '37EE': 12,
  '37EF': 12,
  '37F0': 13,
  '37F1': 13,
  '37F2': 13,
  '37F3': 13,
  '37F4': 13,
  '37F5': 13,
  '37F6': 13,
  '37F7': 13,
  '37F8': 13,
  '37F9': 14,
  '37FA': 14,
  '37FB': 14,
  '37FC': 14,
  '37FD': 14,
  '37FE': 14,
  '37FF': 14,
  '3800': 14,
  '3801': 14,
  '3802': 14,
  '3803': 14,
  '3804': 14,
  '3805': 15,
  '3806': 15,
  '3807': 15,
  '3808': 15,
  '3809': 15,
  '380A': 15,
  '380B': 15,
  '380C': 15,
  '380D': 15,
  '380E': 15,
  '380F': 15,
  '3810': 15,
  '3811': 16,
  '3812': 16,
  '3813': 16,
  '3814': 16,
  '3815': 16,
  '3816': 16,
  '3817': 16,
  '3818': 17,
  '3819': 17,
  '381A': 17,
  '381B': 17,
  '381C': 17,
  '381D': 18,
  '381E': 18,
  '381F': 18,
  '3820': 19,
  '3821': 19,
  '3822': 19,
  '3823': 19,
  '3824': 20,
  '3825': 21,
  '3826': 21,
  '3827': 22,
  '3828': 28,
  '3829': 6,
  '382A': 4,
  '382B': 10,
  '382C': 10,
  '382D': 12,
  '382E': 15,
  '382F': 5,
  '3830': 8,
  '3831': 11,
  '3832': 5,
  '3833': 5,
  '3834': 6,
  '3835': 6,
  '3836': 6,
  '3837': 7,
  '3838': 7,
  '3839': 7,
  '383A': 7,
  '383B': 7,
  '383C': 7,
  '383D': 8,
  '383E': 8,
  '383F': 8,
  '3840': 8,
  '3841': 9,
  '3842': 9,
  '3843': 9,
  '3844': 9,
  '3845': 9,
  '3846': 9,
  '3847': 10,
  '3848': 10,
  '3849': 11,
  '384A': 11,
  '384B': 11,
  '384C': 11,
  '384D': 11,
  '384E': 11,
  '384F': 12,
  '3850': 12,
  '3851': 12,
  '3852': 12,
  '3853': 12,
  '3854': 12,
  '3855': 12,
  '3856': 12,
  '3857': 13,
  '3858': 13,
  '3859': 13,
  '385A': 13,
  '385B': 13,
  '385C': 14,
  '385D': 14,
  '385E': 14,
  '385F': 14,
  '3860': 15,
  '3861': 15,
  '3862': 16,
  '3863': 16,
  '3864': 16,
  '3865': 17,
  '3866': 17,
  '3867': 20,
  '3868': 20,
  '3869': 22,
  '386A': 22,
  '386B': 12,
  '386C': 12,
  '386D': 14,
  '386E': 16,
  '386F': 6,
  '3870': 6,
  '3871': 6,
  '3872': 7,
  '3873': 7,
  '3874': 8,
  '3875': 8,
  '3876': 8,
  '3877': 8,
  '3878': 8,
  '3879': 8,
  '387A': 8,
  '387B': 8,
  '387C': 9,
  '387D': 9,
  '387E': 9,
  '387F': 9,
  '3880': 9,
  '3881': 9,
  '3882': 9,
  '3883': 10,
  '3884': 10,
  '3885': 10,
  '3886': 10,
  '3887': 10,
  '3888': 11,
  '3889': 11,
  '388A': 11,
  '388B': 11,
  '388C': 11,
  '388D': 12,
  '388E': 12,
  '388F': 12,
  '3890': 12,
  '3891': 13,
  '3892': 14,
  '3893': 14,
  '3894': 14,
  '3895': 14,
  '3896': 15,
  '3897': 15,
  '3898': 15,
  '3899': 16,
  '389A': 16,
  '389B': 16,
  '389C': 16,
  '389D': 19,
  '389E': 20,
  '389F': 6,
  '38A0': 8,
  '38A1': 14,
  '38A2': 15,
  '38A3': 16,
  '38A4': 7,
  '38A5': 9,
  '38A6': 13,
  '38A7': 4,
  '38A8': 6,
  '38A9': 6,
  '38AA': 6,
  '38AB': 6,
  '38AC': 7,
  '38AD': 7,
  '38AE': 8,
  '38AF': 8,
  '38B0': 8,
  '38B1': 8,
  '38B2': 9,
  '38B3': 9,
  '38B4': 9,
  '38B5': 9,
  '38B6': 9,
  '38B7': 9,
  '38B8': 10,
  '38B9': 10,
  '38BA': 11,
  '38BB': 11,
  '38BC': 11,
  '38BD': 12,
  '38BE': 12,
  '38BF': 12,
  '38C0': 13,
  '38C1': 13,
  '38C2': 13,
  '38C3': 14,
  '38C4': 15,
  '38C5': 15,
  '38C6': 21,
  '38C7': 8,
  '38C8': 16,
  '38C9': 6,
  '38CA': 7,
  '38CB': 8,
  '38CC': 8,
  '38CD': 8,
  '38CE': 11,
  '38CF': 12,
  '38D0': 12,
  '38D1': 14,
  '38D2': 15,
  '38D3': 16,
  '38D4': 5,
  '38D5': 6,
  '38D6': 7,
  '38D7': 7,
  '38D8': 8,
  '38D9': 8,
  '38DA': 9,
  '38DB': 9,
  '38DC': 9,
  '38DD': 9,
  '38DE': 9,
  '38DF': 9,
  '38E0': 9,
  '38E1': 9,
  '38E2': 10,
  '38E3': 10,
  '38E4': 11,
  '38E5': 11,
  '38E6': 11,
  '38E7': 11,
  '38E8': 11,
  '38E9': 11,
  '38EA': 12,
  '38EB': 12,
  '38EC': 12,
  '38ED': 12,
  '38EE': 12,
  '38EF': 13,
  '38F0': 14,
  '38F1': 14,
  '38F2': 14,
  '38F3': 15,
  '38F4': 15,
  '38F5': 16,
  '38F6': 16,
  '38F7': 17,
  '38F8': 19,
  '38F9': 20,
  '38FA': 5,
  '38FB': 6,
  '38FC': 6,
  '38FD': 7,
  '38FE': 6,
  '38FF': 6,
  '3900': 7,
  '3901': 8,
  '3902': 8,
  '3903': 7,
  '3904': 7,
  '3905': 8,
  '3906': 7,
  '3907': 7,
  '3908': 7,
  '3909': 7,
  '390A': 7,
  '390B': 7,
  '390C': 8,
  '390D': 9,
  '390E': 9,
  '390F': 8,
  '3910': 8,
  '3911': 8,
  '3912': 8,
  '3913': 8,
  '3914': 8,
  '3915': 8,
  '3916': 8,
  '3917': 9,
  '3918': 8,
  '3919': 9,
  '391A': 9,
  '391B': 9,
  '391C': 9,
  '391D': 9,
  '391E': 9,
  '391F': 10,
  '3920': 10,
  '3921': 9,
  '3922': 9,
  '3923': 10,
  '3924': 9,
  '3925': 9,
  '3926': 9,
  '3927': 9,
  '3928': 9,
  '3929': 10,
  '392A': 10,
  '392B': 10,
  '392C': 9,
  '392D': 9,
  '392E': 11,
  '392F': 10,
  '3930': 11,
  '3931': 10,
  '3932': 11,
  '3933': 10,
  '3934': 10,
  '3935': 11,
  '3936': 10,
  '3937': 10,
  '3938': 10,
  '3939': 10,
  '393A': 9,
  '393B': 11,
  '393C': 10,
  '393D': 10,
  '393E': 11,
  '393F': 11,
  '3940': 11,
  '3941': 12,
  '3942': 11,
  '3943': 11,
  '3944': 11,
  '3945': 11,
  '3946': 11,
  '3947': 11,
  '3948': 12,
  '3949': 11,
  '394A': 11,
  '394B': 12,
  '394C': 11,
  '394D': 11,
  '394E': 12,
  '394F': 11,
  '3950': 12,
  '3951': 12,
  '3952': 11,
  '3953': 11,
  '3954': 11,
  '3955': 12,
  '3956': 12,
  '3957': 11,
  '3958': 11,
  '3959': 11,
  '395A': 12,
  '395B': 12,
  '395C': 12,
  '395D': 12,
  '395E': 12,
  '395F': 12,
  '3960': 12,
  '3961': 12,
  '3962': 12,
  '3963': 13,
  '3964': 13,
  '3965': 12,
  '3966': 13,
  '3967': 12,
  '3968': 13,
  '3969': 12,
  '396A': 12,
  '396B': 12,
  '396C': 13,
  '396D': 13,
  '396E': 13,
  '396F': 14,
  '3970': 13,
  '3971': 13,
  '3972': 14,
  '3973': 13,
  '3974': 13,
  '3975': 13,
  '3976': 14,
  '3977': 14,
  '3978': 14,
  '3979': 14,
  '397A': 13,
  '397B': 14,
  '397C': 14,
  '397D': 14,
  '397E': 14,
  '397F': 15,
  '3980': 14,
  '3981': 15,
  '3982': 15,
  '3983': 14,
  '3984': 15,
  '3985': 14,
  '3986': 14,
  '3987': 14,
  '3988': 14,
  '3989': 15,
  '398A': 15,
  '398B': 15,
  '398C': 16,
  '398D': 15,
  '398E': 15,
  '398F': 15,
  '3990': 15,
  '3991': 15,
  '3992': 15,
  '3993': 15,
  '3994': 16,
  '3995': 15,
  '3996': 15,
  '3997': 16,
  '3998': 17,
  '3999': 16,
  '399A': 18,
  '399B': 18,
  '399C': 17,
  '399D': 18,
  '399E': 18,
  '399F': 18,
  '39A0': 17,
  '39A1': 18,
  '39A2': 18,
  '39A3': 20,
  '39A4': 20,
  '39A5': 19,
  '39A6': 19,
  '39A7': 19,
  '39A8': 20,
  '39A9': 20,
  '39AA': 20,
  '39AB': 22,
  '39AC': 22,
  '39AD': 27,
  '39AE': 7,
  '39AF': 7,
  '39B0': 8,
  '39B1': 8,
  '39B2': 8,
  '39B3': 9,
  '39B4': 10,
  '39B5': 10,
  '39B6': 10,
  '39B7': 11,
  '39B8': 12,
  '39B9': 13,
  '39BA': 14,
  '39BB': 15,
  '39BC': 15,
  '39BD': 17,
  '39BE': 7,
  '39BF': 8,
  '39C0': 8,
  '39C1': 9,
  '39C2': 9,
  '39C3': 5,
  '39C4': 5,
  '39C5': 5,
  '39C6': 6,
  '39C7': 6,
  '39C8': 6,
  '39C9': 7,
  '39CA': 7,
  '39CB': 7,
  '39CC': 7,
  '39CD': 7,
  '39CE': 7,
  '39CF': 7,
  '39D0': 7,
  '39D1': 7,
  '39D2': 8,
  '39D3': 8,
  '39D4': 8,
  '39D5': 8,
  '39D6': 8,
  '39D7': 8,
  '39D8': 9,
  '39D9': 8,
  '39DA': 8,
  '39DB': 8,
  '39DC': 8,
  '39DD': 8,
  '39DE': 8,
  '39DF': 8,
  '39E0': 8,
  '39E1': 9,
  '39E2': 9,
  '39E3': 9,
  '39E4': 9,
  '39E5': 9,
  '39E6': 9,
  '39E7': 9,
  '39E8': 9,
  '39E9': 9,
  '39EA': 9,
  '39EB': 9,
  '39EC': 10,
  '39ED': 10,
  '39EE': 9,
  '39EF': 9,
  '39F0': 9,
  '39F1': 10,
  '39F2': 10,
  '39F3': 11,
  '39F4': 10,
  '39F5': 10,
  '39F6': 10,
  '39F7': 10,
  '39F8': 10,
  '39F9': 11,
  '39FA': 11,
  '39FB': 11,
  '39FC': 11,
  '39FD': 11,
  '39FE': 11,
  '39FF': 11,
  '3A00': 11,
  '3A01': 11,
  '3A02': 11,
  '3A03': 11,
  '3A04': 11,
  '3A05': 11,
  '3A06': 11,
  '3A07': 12,
  '3A08': 11,
  '3A09': 12,
  '3A0A': 12,
  '3A0B': 12,
  '3A0C': 13,
  '3A0D': 13,
  '3A0E': 12,
  '3A0F': 12,
  '3A10': 12,
  '3A11': 12,
  '3A12': 12,
  '3A13': 12,
  '3A14': 12,
  '3A15': 12,
  '3A16': 12,
  '3A17': 12,
  '3A18': 12,
  '3A19': 13,
  '3A1A': 13,
  '3A1B': 13,
  '3A1C': 13,
  '3A1D': 13,
  '3A1E': 13,
  '3A1F': 13,
  '3A20': 13,
  '3A21': 13,
  '3A22': 13,
  '3A23': 13,
  '3A24': 13,
  '3A25': 13,
  '3A26': 13,
  '3A27': 13,
  '3A28': 13,
  '3A29': 13,
  '3A2A': 13,
  '3A2B': 13,
  '3A2C': 13,
  '3A2D': 14,
  '3A2E': 14,
  '3A2F': 14,
  '3A30': 14,
  '3A31': 14,
  '3A32': 14,
  '3A33': 14,
  '3A34': 14,
  '3A35': 14,
  '3A36': 14,
  '3A37': 14,
  '3A38': 14,
  '3A39': 14,
  '3A3A': 14,
  '3A3B': 15,
  '3A3C': 15,
  '3A3D': 14,
  '3A3E': 14,
  '3A3F': 14,
  '3A40': 14,
  '3A41': 14,
  '3A42': 14,
  '3A43': 15,
  '3A44': 15,
  '3A45': 15,
  '3A46': 15,
  '3A47': 15,
  '3A48': 15,
  '3A49': 15,
  '3A4A': 15,
  '3A4B': 15,
  '3A4C': 15,
  '3A4D': 15,
  '3A4E': 15,
  '3A4F': 15,
  '3A50': 15,
  '3A51': 15,
  '3A52': 16,
  '3A53': 17,
  '3A54': 16,
  '3A55': 16,
  '3A56': 16,
  '3A57': 16,
  '3A58': 16,
  '3A59': 16,
  '3A5A': 17,
  '3A5B': 17,
  '3A5C': 17,
  '3A5D': 17,
  '3A5E': 17,
  '3A5F': 17,
  '3A60': 18,
  '3A61': 18,
  '3A62': 18,
  '3A63': 18,
  '3A64': 18,
  '3A65': 18,
  '3A66': 18,
  '3A67': 18,
  '3A68': 18,
  '3A69': 18,
  '3A6A': 18,
  '3A6B': 18,
  '3A6C': 19,
  '3A6D': 19,
  '3A6E': 20,
  '3A6F': 20,
  '3A70': 20,
  '3A71': 21,
  '3A72': 21,
  '3A73': 21,
  '3A74': 21,
  '3A75': 23,
  '3A76': 23,
  '3A77': 23,
  '3A78': 25,
  '3A79': 25,
  '3A7A': 8,
  '3A7B': 10,
  '3A7C': 10,
  '3A7D': 11,
  '3A7E': 12,
  '3A7F': 7,
  '3A80': 7,
  '3A81': 8,
  '3A82': 8,
  '3A83': 9,
  '3A84': 9,
  '3A85': 9,
  '3A86': 9,
  '3A87': 10,
  '3A88': 10,
  '3A89': 10,
  '3A8A': 11,
  '3A8B': 11,
  '3A8C': 11,
  '3A8D': 11,
  '3A8E': 11,
  '3A8F': 12,
  '3A90': 12,
  '3A91': 12,
  '3A92': 12,
  '3A93': 12,
  '3A94': 12,
  '3A95': 12,
  '3A96': 12,
  '3A97': 12,
  '3A98': 12,
  '3A99': 12,
  '3A9A': 12,
  '3A9B': 13,
  '3A9C': 13,
  '3A9D': 13,
  '3A9E': 13,
  '3A9F': 13,
  '3AA0': 14,
  '3AA1': 14,
  '3AA2': 14,
  '3AA3': 14,
  '3AA4': 15,
  '3AA5': 15,
  '3AA6': 15,
  '3AA7': 16,
  '3AA8': 16,
  '3AA9': 16,
  '3AAA': 16,
  '3AAB': 18,
  '3AAC': 18,
  '3AAD': 20,
  '3AAE': 21,
  '3AAF': 7,
  '3AB0': 10,
  '3AB1': 13,
  '3AB2': 6,
  '3AB3': 6,
  '3AB4': 8,
  '3AB5': 9,
  '3AB6': 10,
  '3AB7': 11,
  '3AB8': 12,
  '3AB9': 15,
  '3ABA': 17,
  '3ABB': 23,
  '3ABC': 9,
  '3ABD': 9,
  '3ABE': 10,
  '3ABF': 10,
  '3AC0': 12,
  '3AC1': 14,
  '3AC2': 15,
  '3AC3': 6,
  '3AC4': 8,
  '3AC5': 10,
  '3AC6': 9,
  '3AC7': 10,
  '3AC8': 10,
  '3AC9': 10,
  '3ACA': 11,
  '3ACB': 11,
  '3ACC': 11,
  '3ACD': 12,
  '3ACE': 15,
  '3ACF': 15,
  '3AD0': 6,
  '3AD1': 6,
  '3AD2': 7,
  '3AD3': 7,
  '3AD4': 7,
  '3AD5': 7,
  '3AD6': 7,
  '3AD7': 7,
  '3AD8': 8,
  '3AD9': 8,
  '3ADA': 8,
  '3ADB': 9,
  '3ADC': 9,
  '3ADD': 9,
  '3ADE': 9,
  '3ADF': 9,
  '3AE0': 9,
  '3AE1': 9,
  '3AE2': 9,
  '3AE3': 9,
  '3AE4': 9,
  '3AE5': 10,
  '3AE6': 10,
  '3AE7': 10,
  '3AE8': 10,
  '3AE9': 10,
  '3AEA': 10,
  '3AEB': 10,
  '3AEC': 10,
  '3AED': 10,
  '3AEE': 10,
  '3AEF': 11,
  '3AF0': 11,
  '3AF1': 11,
  '3AF2': 11,
  '3AF3': 11,
  '3AF4': 11,
  '3AF5': 12,
  '3AF6': 12,
  '3AF7': 12,
  '3AF8': 12,
  '3AF9': 12,
  '3AFA': 12,
  '3AFB': 12,
  '3AFC': 12,
  '3AFD': 12,
  '3AFE': 12,
  '3AFF': 12,
  '3B00': 12,
  '3B01': 13,
  '3B02': 13,
  '3B03': 13,
  '3B04': 13,
  '3B05': 13,
  '3B06': 13,
  '3B07': 13,
  '3B08': 13,
  '3B09': 13,
  '3B0A': 13,
  '3B0B': 13,
  '3B0C': 13,
  '3B0D': 14,
  '3B0E': 14,
  '3B0F': 14,
  '3B10': 14,
  '3B11': 15,
  '3B12': 15,
  '3B13': 15,
  '3B14': 15,
  '3B15': 15,
  '3B16': 15,
  '3B17': 16,
  '3B18': 16,
  '3B19': 16,
  '3B1A': 16,
  '3B1B': 16,
  '3B1C': 16,
  '3B1D': 16,
  '3B1E': 16,
  '3B1F': 16,
  '3B20': 17,
  '3B21': 17,
  '3B22': 17,
  '3B23': 18,
  '3B24': 18,
  '3B25': 18,
  '3B26': 18,
  '3B27': 18,
  '3B28': 18,
  '3B29': 19,
  '3B2A': 19,
  '3B2B': 20,
  '3B2C': 22,
  '3B2D': 22,
  '3B2E': 23,
  '3B2F': 25,
  '3B30': 6,
  '3B31': 16,
  '3B32': 17,
  '3B33': 8,
  '3B34': 10,
  '3B35': 10,
  '3B36': 11,
  '3B37': 11,
  '3B38': 12,
  '3B39': 12,
  '3B3A': 14,
  '3B3B': 14,
  '3B3C': 15,
  '3B3D': 15,
  '3B3E': 15,
  '3B3F': 16,
  '3B40': 17,
  '3B41': 6,
  '3B42': 7,
  '3B43': 7,
  '3B44': 7,
  '3B45': 7,
  '3B46': 7,
  '3B47': 8,
  '3B48': 8,
  '3B49': 8,
  '3B4A': 8,
  '3B4B': 8,
  '3B4C': 8,
  '3B4D': 8,
  '3B4E': 8,
  '3B4F': 8,
  '3B50': 8,
  '3B51': 9,
  '3B52': 9,
  '3B53': 9,
  '3B54': 9,
  '3B55': 9,
  '3B56': 9,
  '3B57': 9,
  '3B58': 10,
  '3B59': 10,
  '3B5A': 10,
  '3B5B': 10,
  '3B5C': 10,
  '3B5D': 10,
  '3B5E': 10,
  '3B5F': 10,
  '3B60': 10,
  '3B61': 10,
  '3B62': 10,
  '3B63': 10,
  '3B64': 10,
  '3B65': 10,
  '3B66': 10,
  '3B67': 10,
  '3B68': 11,
  '3B69': 11,
  '3B6A': 11,
  '3B6B': 11,
  '3B6C': 11,
  '3B6D': 11,
  '3B6E': 11,
  '3B6F': 11,
  '3B70': 11,
  '3B71': 11,
  '3B72': 11,
  '3B73': 11,
  '3B74': 11,
  '3B75': 11,
  '3B76': 12,
  '3B77': 11,
  '3B78': 12,
  '3B79': 12,
  '3B7A': 12,
  '3B7B': 12,
  '3B7C': 12,
  '3B7D': 12,
  '3B7E': 12,
  '3B7F': 12,
  '3B80': 12,
  '3B81': 12,
  '3B82': 12,
  '3B83': 12,
  '3B84': 12,
  '3B85': 12,
  '3B86': 12,
  '3B87': 12,
  '3B88': 12,
  '3B89': 12,
  '3B8A': 12,
  '3B8B': 13,
  '3B8C': 13,
  '3B8D': 13,
  '3B8E': 13,
  '3B8F': 13,
  '3B90': 13,
  '3B91': 13,
  '3B92': 13,
  '3B93': 13,
  '3B94': 13,
  '3B95': 13,
  '3B96': 13,
  '3B97': 13,
  '3B98': 13,
  '3B99': 13,
  '3B9A': 13,
  '3B9B': 13,
  '3B9C': 13,
  '3B9D': 13,
  '3B9E': 13,
  '3B9F': 13,
  '3BA0': 13,
  '3BA1': 13,
  '3BA2': 13,
  '3BA3': 13,
  '3BA4': 14,
  '3BA5': 14,
  '3BA6': 14,
  '3BA7': 14,
  '3BA8': 14,
  '3BA9': 14,
  '3BAA': 14,
  '3BAB': 14,
  '3BAC': 14,
  '3BAD': 14,
  '3BAE': 14,
  '3BAF': 14,
  '3BB0': 14,
  '3BB1': 14,
  '3BB2': 14,
  '3BB3': 14,
  '3BB4': 14,
  '3BB5': 14,
  '3BB6': 14,
  '3BB7': 14,
  '3BB8': 14,
  '3BB9': 14,
  '3BBA': 14,
  '3BBB': 14,
  '3BBC': 14,
  '3BBD': 14,
  '3BBE': 15,
  '3BBF': 15,
  '3BC0': 15,
  '3BC1': 15,
  '3BC2': 15,
  '3BC3': 15,
  '3BC4': 15,
  '3BC5': 15,
  '3BC6': 15,
  '3BC7': 15,
  '3BC8': 15,
  '3BC9': 15,
  '3BCA': 15,
  '3BCB': 15,
  '3BCC': 15,
  '3BCD': 15,
  '3BCE': 15,
  '3BCF': 15,
  '3BD0': 16,
  '3BD1': 15,
  '3BD2': 15,
  '3BD3': 16,
  '3BD4': 16,
  '3BD5': 16,
  '3BD6': 16,
  '3BD7': 16,
  '3BD8': 16,
  '3BD9': 16,
  '3BDA': 16,
  '3BDB': 16,
  '3BDC': 16,
  '3BDD': 16,
  '3BDE': 16,
  '3BDF': 16,
  '3BE0': 15,
  '3BE1': 16,
  '3BE2': 16,
  '3BE3': 16,
  '3BE4': 16,
  '3BE5': 16,
  '3BE6': 16,
  '3BE7': 16,
  '3BE8': 16,
  '3BE9': 16,
  '3BEA': 17,
  '3BEB': 17,
  '3BEC': 17,
  '3BED': 17,
  '3BEE': 17,
  '3BEF': 17,
  '3BF0': 17,
  '3BF1': 17,
  '3BF2': 17,
  '3BF3': 17,
  '3BF4': 17,
  '3BF5': 17,
  '3BF6': 17,
  '3BF7': 18,
  '3BF8': 18,
  '3BF9': 18,
  '3BFA': 18,
  '3BFB': 18,
  '3BFC': 18,
  '3BFD': 18,
  '3BFE': 19,
  '3BFF': 19,
  '3C00': 19,
  '3C01': 19,
  '3C02': 19,
  '3C03': 19,
  '3C04': 19,
  '3C05': 19,
  '3C06': 19,
  '3C07': 19,
  '3C08': 19,
  '3C09': 20,
  '3C0A': 20,
  '3C0B': 20,
  '3C0C': 20,
  '3C0D': 20,
  '3C0E': 20,
  '3C0F': 20,
  '3C10': 20,
  '3C11': 20,
  '3C12': 20,
  '3C13': 20,
  '3C14': 21,
  '3C15': 21,
  '3C16': 20,
  '3C17': 22,
  '3C18': 22,
  '3C19': 23,
  '3C1A': 23,
  '3C1B': 23,
  '3C1C': 23,
  '3C1D': 7,
  '3C1E': 7,
  '3C1F': 8,
  '3C20': 8,
  '3C21': 8,
  '3C22': 8,
  '3C23': 9,
  '3C24': 9,
  '3C25': 9,
  '3C26': 9,
  '3C27': 9,
  '3C28': 9,
  '3C29': 10,
  '3C2A': 10,
  '3C2B': 10,
  '3C2C': 10,
  '3C2D': 10,
  '3C2E': 11,
  '3C2F': 11,
  '3C30': 11,
  '3C31': 11,
  '3C32': 12,
  '3C33': 12,
  '3C34': 12,
  '3C35': 12,
  '3C36': 12,
  '3C37': 10,
  '3C38': 12,
  '3C39': 13,
  '3C3A': 13,
  '3C3B': 13,
  '3C3C': 13,
  '3C3D': 13,
  '3C3E': 13,
  '3C3F': 14,
  '3C40': 14,
  '3C41': 14,
  '3C42': 15,
  '3C43': 15,
  '3C44': 16,
  '3C45': 16,
  '3C46': 17,
  '3C47': 17,
  '3C48': 17,
  '3C49': 17,
  '3C4A': 19,
  '3C4B': 20,
  '3C4C': 22,
  '3C4D': 23,
  '3C4E': 25,
  '3C4F': 6,
  '3C50': 7,
  '3C51': 7,
  '3C52': 9,
  '3C53': 9,
  '3C54': 9,
  '3C55': 12,
  '3C56': 12,
  '3C57': 14,
  '3C58': 18,
  '3C59': 6,
  '3C5A': 8,
  '3C5B': 8,
  '3C5C': 8,
  '3C5D': 8,
  '3C5E': 9,
  '3C5F': 9,
  '3C60': 9,
  '3C61': 10,
  '3C62': 11,
  '3C63': 11,
  '3C64': 12,
  '3C65': 12,
  '3C66': 12,
  '3C67': 12,
  '3C68': 12,
  '3C69': 12,
  '3C6A': 13,
  '3C6B': 13,
  '3C6C': 13,
  '3C6D': 13,
  '3C6E': 13,
  '3C6F': 14,
  '3C70': 14,
  '3C71': 14,
  '3C72': 14,
  '3C73': 15,
  '3C74': 15,
  '3C75': 16,
  '3C76': 16,
  '3C77': 16,
  '3C78': 17,
  '3C79': 20,
  '3C7A': 20,
  '3C7B': 23,
  '3C7C': 7,
  '3C7D': 8,
  '3C7E': 10,
  '3C7F': 10,
  '3C80': 11,
  '3C81': 12,
  '3C82': 12,
  '3C83': 12,
  '3C84': 13,
  '3C85': 14,
  '3C86': 16,
  '3C87': 16,
  '3C88': 16,
  '3C89': 17,
  '3C8A': 18,
  '3C8B': 9,
  '3C8C': 6,
  '3C8D': 8,
  '3C8E': 8,
  '3C8F': 8,
  '3C90': 8,
  '3C91': 8,
  '3C92': 9,
  '3C93': 10,
  '3C94': 11,
  '3C95': 11,
  '3C96': 11,
  '3C97': 11,
  '3C98': 11,
  '3C99': 11,
  '3C9A': 11,
  '3C9B': 12,
  '3C9C': 12,
  '3C9D': 12,
  '3C9E': 12,
  '3C9F': 13,
  '3CA0': 13,
  '3CA1': 13,
  '3CA2': 13,
  '3CA3': 13,
  '3CA4': 14,
  '3CA5': 14,
  '3CA6': 14,
  '3CA7': 14,
  '3CA8': 14,
  '3CA9': 14,
  '3CAA': 16,
  '3CAB': 16,
  '3CAC': 16,
  '3CAD': 16,
  '3CAE': 16,
  '3CAF': 18,
  '3CB0': 18,
  '3CB1': 19,
  '3CB2': 26,
  '3CB3': 10,
  '3CB4': 8,
  '3CB5': 11,
  '3CB6': 15,
  '3CB7': 16,
  '3CB8': 4,
  '3CB9': 5,
  '3CBA': 5,
  '3CBB': 6,
  '3CBC': 5,
  '3CBD': 6,
  '3CBE': 7,
  '3CBF': 6,
  '3CC0': 7,
  '3CC1': 7,
  '3CC2': 7,
  '3CC3': 7,
  '3CC4': 7,
  '3CC5': 7,
  '3CC6': 7,
  '3CC7': 7,
  '3CC8': 7,
  '3CC9': 7,
  '3CCA': 7,
  '3CCB': 8,
  '3CCC': 8,
  '3CCD': 8,
  '3CCE': 8,
  '3CCF': 8,
  '3CD0': 8,
  '3CD1': 8,
  '3CD2': 8,
  '3CD3': 8,
  '3CD4': 8,
  '3CD5': 8,
  '3CD6': 9,
  '3CD7': 9,
  '3CD8': 9,
  '3CD9': 9,
  '3CDA': 9,
  '3CDB': 9,
  '3CDC': 9,
  '3CDD': 9,
  '3CDE': 9,
  '3CDF': 11,
  '3CE0': 9,
  '3CE1': 9,
  '3CE2': 9,
  '3CE3': 9,
  '3CE4': 10,
  '3CE5': 10,
  '3CE6': 10,
  '3CE7': 10,
  '3CE8': 10,
  '3CE9': 10,
  '3CEA': 10,
  '3CEB': 11,
  '3CEC': 10,
  '3CED': 10,
  '3CEE': 10,
  '3CEF': 10,
  '3CF0': 10,
  '3CF1': 10,
  '3CF2': 10,
  '3CF3': 10,
  '3CF4': 11,
  '3CF5': 11,
  '3CF6': 11,
  '3CF7': 11,
  '3CF8': 11,
  '3CF9': 11,
  '3CFA': 11,
  '3CFB': 11,
  '3CFC': 12,
  '3CFD': 11,
  '3CFE': 11,
  '3CFF': 11,
  '3D00': 11,
  '3D01': 11,
  '3D02': 11,
  '3D03': 11,
  '3D04': 11,
  '3D05': 12,
  '3D06': 11,
  '3D07': 12,
  '3D08': 11,
  '3D09': 11,
  '3D0A': 11,
  '3D0B': 11,
  '3D0C': 11,
  '3D0D': 11,
  '3D0E': 11,
  '3D0F': 12,
  '3D10': 12,
  '3D11': 12,
  '3D12': 12,
  '3D13': 12,
  '3D14': 12,
  '3D15': 12,
  '3D16': 12,
  '3D17': 12,
  '3D18': 12,
  '3D19': 12,
  '3D1A': 12,
  '3D1B': 12,
  '3D1C': 12,
  '3D1D': 12,
  '3D1E': 12,
  '3D1F': 12,
  '3D20': 12,
  '3D21': 12,
  '3D22': 12,
  '3D23': 12,
  '3D24': 12,
  '3D25': 13,
  '3D26': 13,
  '3D27': 13,
  '3D28': 13,
  '3D29': 13,
  '3D2A': 13,
  '3D2B': 13,
  '3D2C': 13,
  '3D2D': 13,
  '3D2E': 13,
  '3D2F': 13,
  '3D30': 13,
  '3D31': 13,
  '3D32': 13,
  '3D33': 13,
  '3D34': 13,
  '3D35': 13,
  '3D36': 13,
  '3D37': 13,
  '3D38': 13,
  '3D39': 13,
  '3D3A': 13,
  '3D3B': 13,
  '3D3C': 14,
  '3D3D': 14,
  '3D3E': 14,
  '3D3F': 14,
  '3D40': 14,
  '3D41': 14,
  '3D42': 14,
  '3D43': 14,
  '3D44': 14,
  '3D45': 14,
  '3D46': 14,
  '3D47': 14,
  '3D48': 14,
  '3D49': 14,
  '3D4A': 15,
  '3D4B': 15,
  '3D4C': 15,
  '3D4D': 15,
  '3D4E': 15,
  '3D4F': 15,
  '3D50': 15,
  '3D51': 15,
  '3D52': 15,
  '3D53': 15,
  '3D54': 15,
  '3D55': 15,
  '3D56': 15,
  '3D57': 15,
  '3D58': 16,
  '3D59': 15,
  '3D5A': 15,
  '3D5B': 15,
  '3D5C': 15,
  '3D5D': 16,
  '3D5E': 16,
  '3D5F': 16,
  '3D60': 16,
  '3D61': 16,
  '3D62': 16,
  '3D63': 16,
  '3D64': 16,
  '3D65': 16,
  '3D66': 16,
  '3D67': 16,
  '3D68': 17,
  '3D69': 16,
  '3D6A': 16,
  '3D6B': 16,
  '3D6C': 16,
  '3D6D': 16,
  '3D6E': 16,
  '3D6F': 17,
  '3D70': 17,
  '3D71': 17,
  '3D72': 17,
  '3D73': 17,
  '3D74': 17,
  '3D75': 17,
  '3D76': 17,
  '3D77': 17,
  '3D78': 17,
  '3D79': 17,
  '3D7A': 17,
  '3D7B': 17,
  '3D7C': 17,
  '3D7D': 18,
  '3D7E': 18,
  '3D7F': 18,
  '3D80': 18,
  '3D81': 18,
  '3D82': 18,
  '3D83': 18,
  '3D84': 19,
  '3D85': 19,
  '3D86': 19,
  '3D87': 19,
  '3D88': 19,
  '3D89': 19,
  '3D8A': 19,
  '3D8B': 19,
  '3D8C': 20,
  '3D8D': 20,
  '3D8E': 20,
  '3D8F': 20,
  '3D90': 20,
  '3D91': 20,
  '3D92': 21,
  '3D93': 21,
  '3D94': 21,
  '3D95': 21,
  '3D96': 21,
  '3D97': 22,
  '3D98': 21,
  '3D99': 22,
  '3D9A': 22,
  '3D9B': 23,
  '3D9C': 23,
  '3D9D': 23,
  '3D9E': 24,
  '3D9F': 24,
  '3DA0': 26,
  '3DA1': 6,
  '3DA2': 6,
  '3DA3': 7,
  '3DA4': 7,
  '3DA5': 7,
  '3DA6': 8,
  '3DA7': 8,
  '3DA8': 8,
  '3DA9': 8,
  '3DAA': 8,
  '3DAB': 9,
  '3DAC': 9,
  '3DAD': 9,
  '3DAE': 9,
  '3DAF': 9,
  '3DB0': 9,
  '3DB1': 9,
  '3DB2': 9,
  '3DB3': 10,
  '3DB4': 10,
  '3DB5': 10,
  '3DB6': 10,
  '3DB7': 10,
  '3DB8': 10,
  '3DB9': 11,
  '3DBA': 11,
  '3DBB': 11,
  '3DBC': 11,
  '3DBD': 11,
  '3DBE': 11,
  '3DBF': 11,
  '3DC0': 11,
  '3DC1': 11,
  '3DC2': 12,
  '3DC3': 12,
  '3DC4': 12,
  '3DC5': 12,
  '3DC6': 12,
  '3DC7': 12,
  '3DC8': 12,
  '3DC9': 12,
  '3DCA': 12,
  '3DCB': 12,
  '3DCC': 12,
  '3DCD': 12,
  '3DCE': 13,
  '3DCF': 13,
  '3DD0': 13,
  '3DD1': 13,
  '3DD2': 13,
  '3DD3': 13,
  '3DD4': 13,
  '3DD5': 13,
  '3DD6': 13,
  '3DD7': 13,
  '3DD8': 13,
  '3DD9': 13,
  '3DDA': 13,
  '3DDB': 13,
  '3DDC': 13,
  '3DDD': 13,
  '3DDE': 13,
  '3DDF': 14,
  '3DE0': 14,
  '3DE1': 14,
  '3DE2': 14,
  '3DE3': 14,
  '3DE4': 14,
  '3DE5': 14,
  '3DE6': 14,
  '3DE7': 14,
  '3DE8': 14,
  '3DE9': 14,
  '3DEA': 14,
  '3DEB': 15,
  '3DEC': 15,
  '3DED': 15,
  '3DEE': 15,
  '3DEF': 15,
  '3DF0': 15,
  '3DF1': 15,
  '3DF2': 15,
  '3DF3': 16,
  '3DF4': 16,
  '3DF5': 16,
  '3DF6': 16,
  '3DF7': 16,
  '3DF8': 16,
  '3DF9': 16,
  '3DFA': 16,
  '3DFB': 16,
  '3DFC': 16,
  '3DFD': 16,
  '3DFE': 17,
  '3DFF': 17,
  '3E00': 17,
  '3E01': 17,
  '3E02': 17,
  '3E03': 17,
  '3E04': 18,
  '3E05': 18,
  '3E06': 19,
  '3E07': 19,
  '3E08': 20,
  '3E09': 19,
  '3E0A': 20,
  '3E0B': 20,
  '3E0C': 20,
  '3E0D': 21,
  '3E0E': 22,
  '3E0F': 23,
  '3E10': 23,
  '3E11': 26,
  '3E12': 8,
  '3E13': 8,
  '3E14': 11,
  '3E15': 14,
  '3E16': 9,
  '3E17': 10,
  '3E18': 11,
  '3E19': 13,
  '3E1A': 8,
  '3E1B': 9,
  '3E1C': 12,
  '3E1D': 8,
  '3E1E': 8,
  '3E1F': 10,
  '3E20': 10,
  '3E21': 10,
  '3E22': 14,
  '3E23': 17,
  '3E24': 18,
  '3E25': 21,
  '3E26': 4,
  '3E27': 10,
  '3E28': 6,
  '3E29': 7,
  '3E2A': 7,
  '3E2B': 8,
  '3E2C': 8,
  '3E2D': 8,
  '3E2E': 8,
  '3E2F': 8,
  '3E30': 9,
  '3E31': 9,
  '3E32': 9,
  '3E33': 9,
  '3E34': 9,
  '3E35': 10,
  '3E36': 10,
  '3E37': 10,
  '3E38': 10,
  '3E39': 11,
  '3E3A': 11,
  '3E3B': 11,
  '3E3C': 11,
  '3E3D': 11,
  '3E3E': 11,
  '3E3F': 11,
  '3E40': 11,
  '3E41': 12,
  '3E42': 12,
  '3E43': 12,
  '3E44': 14,
  '3E45': 13,
  '3E46': 13,
  '3E47': 13,
  '3E48': 13,
  '3E49': 14,
  '3E4A': 14,
  '3E4B': 14,
  '3E4C': 15,
  '3E4D': 15,
  '3E4E': 15,
  '3E4F': 15,
  '3E50': 15,
  '3E51': 16,
  '3E52': 16,
  '3E53': 16,
  '3E54': 17,
  '3E55': 17,
  '3E56': 17,
  '3E57': 18,
  '3E58': 18,
  '3E59': 19,
  '3E5A': 21,
  '3E5B': 22,
  '3E5C': 8,
  '3E5D': 7,
  '3E5E': 7,
  '3E5F': 7,
  '3E60': 7,
  '3E61': 8,
  '3E62': 8,
  '3E63': 8,
  '3E64': 8,
  '3E65': 8,
  '3E66': 8,
  '3E67': 8,
  '3E68': 8,
  '3E69': 8,
  '3E6A': 8,
  '3E6B': 9,
  '3E6C': 9,
  '3E6D': 9,
  '3E6E': 9,
  '3E6F': 9,
  '3E70': 9,
  '3E71': 10,
  '3E72': 10,
  '3E73': 10,
  '3E74': 10,
  '3E75': 10,
  '3E76': 10,
  '3E77': 10,
  '3E78': 10,
  '3E79': 11,
  '3E7A': 11,
  '3E7B': 11,
  '3E7C': 11,
  '3E7D': 11,
  '3E7E': 11,
  '3E7F': 11,
  '3E80': 11,
  '3E81': 12,
  '3E82': 12,
  '3E83': 12,
  '3E84': 12,
  '3E85': 12,
  '3E86': 12,
  '3E87': 13,
  '3E88': 13,
  '3E89': 13,
  '3E8A': 13,
  '3E8B': 13,
  '3E8C': 13,
  '3E8D': 13,
  '3E8E': 14,
  '3E8F': 14,
  '3E90': 14,
  '3E91': 14,
  '3E92': 14,
  '3E93': 14,
  '3E94': 15,
  '3E95': 15,
  '3E96': 15,
  '3E97': 15,
  '3E98': 15,
  '3E99': 15,
  '3E9A': 16,
  '3E9B': 16,
  '3E9C': 16,
  '3E9D': 17,
  '3E9E': 17,
  '3E9F': 17,
  '3EA0': 18,
  '3EA1': 19,
  '3EA2': 19,
  '3EA3': 20,
  '3EA4': 20,
  '3EA5': 20,
  '3EA6': 20,
  '3EA7': 24,
  '3EA8': 6,
  '3EA9': 6,
  '3EAA': 6,
  '3EAB': 6,
  '3EAC': 7,
  '3EAD': 7,
  '3EAE': 7,
  '3EAF': 7,
  '3EB0': 8,
  '3EB1': 9,
  '3EB2': 8,
  '3EB3': 8,
  '3EB4': 8,
  '3EB5': 8,
  '3EB6': 8,
  '3EB7': 9,
  '3EB8': 10,
  '3EB9': 9,
  '3EBA': 9,
  '3EBB': 9,
  '3EBC': 9,
  '3EBD': 9,
  '3EBE': 9,
  '3EBF': 10,
  '3EC0': 10,
  '3EC1': 10,
  '3EC2': 10,
  '3EC3': 10,
  '3EC4': 10,
  '3EC5': 10,
  '3EC6': 10,
  '3EC7': 10,
  '3EC8': 10,
  '3EC9': 11,
  '3ECA': 11,
  '3ECB': 11,
  '3ECC': 11,
  '3ECD': 11,
  '3ECE': 11,
  '3ECF': 11,
  '3ED0': 11,
  '3ED1': 12,
  '3ED2': 12,
  '3ED3': 12,
  '3ED4': 12,
  '3ED5': 12,
  '3ED6': 12,
  '3ED7': 13,
  '3ED8': 12,
  '3ED9': 12,
  '3EDA': 12,
  '3EDB': 12,
  '3EDC': 12,
  '3EDD': 13,
  '3EDE': 13,
  '3EDF': 13,
  '3EE0': 13,
  '3EE1': 13,
  '3EE2': 13,
  '3EE3': 13,
  '3EE4': 13,
  '3EE5': 13,
  '3EE6': 13,
  '3EE7': 14,
  '3EE8': 14,
  '3EE9': 14,
  '3EEA': 14,
  '3EEB': 15,
  '3EEC': 15,
  '3EED': 15,
  '3EEE': 15,
  '3EEF': 15,
  '3EF0': 15,
  '3EF1': 15,
  '3EF2': 15,
  '3EF3': 15,
  '3EF4': 15,
  '3EF5': 16,
  '3EF6': 16,
  '3EF7': 16,
  '3EF8': 16,
  '3EF9': 17,
  '3EFA': 17,
  '3EFB': 16,
  '3EFC': 16,
  '3EFD': 17,
  '3EFE': 18,
  '3EFF': 17,
  '3F00': 17,
  '3F01': 17,
  '3F02': 18,
  '3F03': 19,
  '3F04': 19,
  '3F05': 19,
  '3F06': 19,
  '3F07': 20,
  '3F08': 23,
  '3F09': 9,
  '3F0A': 9,
  '3F0B': 10,
  '3F0C': 10,
  '3F0D': 11,
  '3F0E': 11,
  '3F0F': 12,
  '3F10': 14,
  '3F11': 14,
  '3F12': 14,
  '3F13': 15,
  '3F14': 15,
  '3F15': 18,
  '3F16': 26,
  '3F17': 7,
  '3F18': 8,
  '3F19': 8,
  '3F1A': 9,
  '3F1B': 9,
  '3F1C': 10,
  '3F1D': 10,
  '3F1E': 10,
  '3F1F': 10,
  '3F20': 10,
  '3F21': 11,
  '3F22': 11,
  '3F23': 11,
  '3F24': 11,
  '3F25': 11,
  '3F26': 11,
  '3F27': 12,
  '3F28': 12,
  '3F29': 12,
  '3F2A': 12,
  '3F2B': 13,
  '3F2C': 13,
  '3F2D': 13,
  '3F2E': 13,
  '3F2F': 13,
  '3F30': 13,
  '3F31': 13,
  '3F32': 14,
  '3F33': 14,
  '3F34': 14,
  '3F35': 14,
  '3F36': 14,
  '3F37': 14,
  '3F38': 15,
  '3F39': 15,
  '3F3A': 15,
  '3F3B': 16,
  '3F3C': 16,
  '3F3D': 16,
  '3F3E': 16,
  '3F3F': 17,
  '3F40': 17,
  '3F41': 17,
  '3F42': 17,
  '3F43': 17,
  '3F44': 17,
  '3F45': 17,
  '3F46': 18,
  '3F47': 18,
  '3F48': 19,
  '3F49': 19,
  '3F4A': 22,
  '3F4B': 24,
  '3F4C': 25,
  '3F4D': 10,
  '3F4E': 13,
  '3F4F': 14,
  '3F50': 15,
  '3F51': 17,
  '3F52': 12,
  '3F53': 15,
  '3F54': 16,
  '3F55': 7,
  '3F56': 7,
  '3F57': 7,
  '3F58': 9,
  '3F59': 9,
  '3F5A': 9,
  '3F5B': 10,
  '3F5C': 10,
  '3F5D': 11,
  '3F5E': 11,
  '3F5F': 12,
  '3F60': 12,
  '3F61': 13,
  '3F62': 13,
  '3F63': 13,
  '3F64': 13,
  '3F65': 14,
  '3F66': 15,
  '3F67': 15,
  '3F68': 15,
  '3F69': 16,
  '3F6A': 17,
  '3F6B': 18,
  '3F6C': 18,
  '3F6D': 19,
  '3F6E': 20,
  '3F6F': 23,
  '3F70': 13,
  '3F71': 7,
  '3F72': 7,
  '3F73': 8,
  '3F74': 8,
  '3F75': 8,
  '3F76': 8,
  '3F77': 9,
  '3F78': 9,
  '3F79': 9,
  '3F7A': 9,
  '3F7B': 9,
  '3F7C': 9,
  '3F7D': 10,
  '3F7E': 10,
  '3F7F': 10,
  '3F80': 10,
  '3F81': 10,
  '3F82': 10,
  '3F83': 10,
  '3F84': 10,
  '3F85': 10,
  '3F86': 10,
  '3F87': 10,
  '3F88': 10,
  '3F89': 10,
  '3F8A': 11,
  '3F8B': 11,
  '3F8C': 11,
  '3F8D': 11,
  '3F8E': 11,
  '3F8F': 11,
  '3F90': 11,
  '3F91': 11,
  '3F92': 11,
  '3F93': 12,
  '3F94': 12,
  '3F95': 12,
  '3F96': 12,
  '3F97': 12,
  '3F98': 12,
  '3F99': 12,
  '3F9A': 12,
  '3F9B': 12,
  '3F9C': 12,
  '3F9D': 12,
  '3F9E': 12,
  '3F9F': 12,
  '3FA0': 12,
  '3FA1': 12,
  '3FA2': 13,
  '3FA3': 13,
  '3FA4': 13,
  '3FA5': 13,
  '3FA6': 13,
  '3FA7': 13,
  '3FA8': 13,
  '3FA9': 13,
  '3FAA': 14,
  '3FAB': 14,
  '3FAC': 14,
  '3FAD': 14,
  '3FAE': 14,
  '3FAF': 14,
  '3FB0': 14,
  '3FB1': 14,
  '3FB2': 14,
  '3FB3': 14,
  '3FB4': 14,
  '3FB5': 14,
  '3FB6': 15,
  '3FB7': 15,
  '3FB8': 15,
  '3FB9': 15,
  '3FBA': 15,
  '3FBB': 15,
  '3FBC': 15,
  '3FBD': 15,
  '3FBE': 15,
  '3FBF': 15,
  '3FC0': 15,
  '3FC1': 15,
  '3FC2': 16,
  '3FC3': 16,
  '3FC4': 16,
  '3FC5': 16,
  '3FC6': 16,
  '3FC7': 16,
  '3FC8': 16,
  '3FC9': 17,
  '3FCA': 17,
  '3FCB': 18,
  '3FCC': 18,
  '3FCD': 18,
  '3FCE': 18,
  '3FCF': 18,
  '3FD0': 18,
  '3FD1': 19,
  '3FD2': 19,
  '3FD3': 19,
  '3FD4': 20,
  '3FD5': 21,
  '3FD6': 21,
  '3FD7': 21,
  '3FD8': 22,
  '3FD9': 23,
  '3FDA': 24,
  '3FDB': 24,
  '3FDC': 28,
  '3FDD': 7,
  '3FDE': 9,
  '3FDF': 10,
  '3FE0': 11,
  '3FE1': 11,
  '3FE2': 13,
  '3FE3': 14,
  '3FE4': 14,
  '3FE5': 15,
  '3FE6': 16,
  '3FE7': 19,
  '3FE8': 21,
  '3FE9': 25,
  '3FEA': 8,
  '3FEB': 9,
  '3FEC': 9,
  '3FED': 10,
  '3FEE': 10,
  '3FEF': 11,
  '3FF0': 11,
  '3FF1': 12,
  '3FF2': 13,
  '3FF3': 13,
  '3FF4': 14,
  '3FF5': 14,
  '3FF6': 15,
  '3FF7': 15,
  '3FF8': 16,
  '3FF9': 18,
  '3FFA': 20,
  '3FFB': 8,
  '3FFC': 9,
  '3FFD': 9,
  '3FFE': 10,
  '3FFF': 10,
  '4000': 10,
  '4001': 11,
  '4002': 11,
  '4003': 12,
  '4004': 13,
  '4005': 13,
  '4006': 14,
  '4007': 16,
  '4008': 16,
  '4009': 17,
  '400A': 19,
  '400B': 21,
  '400C': 22,
  '400D': 29,
  '400E': 7,
  '400F': 7,
  '4010': 8,
  '4011': 8,
  '4012': 8,
  '4013': 8,
  '4014': 8,
  '4015': 9,
  '4016': 9,
  '4017': 9,
  '4018': 9,
  '4019': 9,
  '401A': 9,
  '401B': 9,
  '401C': 9,
  '401D': 9,
  '401E': 9,
  '401F': 10,
  '4020': 10,
  '4021': 10,
  '4022': 10,
  '4023': 10,
  '4024': 10,
  '4025': 10,
  '4026': 10,
  '4027': 11,
  '4028': 11,
  '4029': 11,
  '402A': 11,
  '402B': 11,
  '402C': 11,
  '402D': 11,
  '402E': 11,
  '402F': 12,
  '4030': 12,
  '4031': 12,
  '4032': 12,
  '4033': 12,
  '4034': 12,
  '4035': 12,
  '4036': 12,
  '4037': 12,
  '4038': 12,
  '4039': 12,
  '403A': 12,
  '403B': 12,
  '403C': 12,
  '403D': 12,
  '403E': 12,
  '403F': 12,
  '4040': 12,
  '4041': 13,
  '4042': 13,
  '4043': 13,
  '4044': 13,
  '4045': 13,
  '4046': 13,
  '4047': 13,
  '4048': 13,
  '4049': 13,
  '404A': 14,
  '404B': 14,
  '404C': 14,
  '404D': 14,
  '404E': 14,
  '404F': 14,
  '4050': 14,
  '4051': 14,
  '4052': 14,
  '4053': 14,
  '4054': 14,
  '4055': 14,
  '4056': 14,
  '4057': 15,
  '4058': 15,
  '4059': 15,
  '405A': 15,
  '405B': 15,
  '405C': 15,
  '405D': 15,
  '405E': 15,
  '405F': 15,
  '4060': 15,
  '4061': 15,
  '4062': 16,
  '4063': 16,
  '4064': 16,
  '4065': 16,
  '4066': 16,
  '4067': 16,
  '4068': 16,
  '4069': 16,
  '406A': 16,
  '406B': 17,
  '406C': 17,
  '406D': 17,
  '406E': 17,
  '406F': 17,
  '4070': 17,
  '4071': 17,
  '4072': 17,
  '4073': 17,
  '4074': 18,
  '4075': 18,
  '4076': 18,
  '4077': 18,
  '4078': 18,
  '4079': 18,
  '407A': 18,
  '407B': 20,
  '407C': 20,
  '407D': 20,
  '407E': 20,
  '407F': 21,
  '4080': 21,
  '4081': 22,
  '4082': 23,
  '4083': 23,
  '4084': 25,
  '4085': 30,
  '4086': 8,
  '4087': 9,
  '4088': 11,
  '4089': 14,
  '408A': 17,
  '408B': 17,
  '408C': 17,
  '408D': 20,
  '408E': 24,
  '408F': 10,
  '4090': 10,
  '4091': 10,
  '4092': 11,
  '4093': 12,
  '4094': 13,
  '4095': 14,
  '4096': 6,
  '4097': 7,
  '4098': 8,
  '4099': 8,
  '409A': 9,
  '409B': 9,
  '409C': 9,
  '409D': 9,
  '409E': 9,
  '409F': 10,
  '40A0': 10,
  '40A1': 10,
  '40A2': 10,
  '40A3': 10,
  '40A4': 10,
  '40A5': 10,
  '40A6': 10,
  '40A7': 10,
  '40A8': 10,
  '40A9': 11,
  '40AA': 11,
  '40AB': 11,
  '40AC': 11,
  '40AD': 11,
  '40AE': 11,
  '40AF': 11,
  '40B0': 12,
  '40B1': 12,
  '40B2': 12,
  '40B3': 12,
  '40B4': 12,
  '40B5': 12,
  '40B6': 12,
  '40B7': 13,
  '40B8': 13,
  '40B9': 13,
  '40BA': 13,
  '40BB': 13,
  '40BC': 13,
  '40BD': 13,
  '40BE': 13,
  '40BF': 13,
  '40C0': 13,
  '40C1': 13,
  '40C2': 13,
  '40C3': 13,
  '40C4': 13,
  '40C5': 13,
  '40C6': 13,
  '40C7': 13,
  '40C8': 14,
  '40C9': 14,
  '40CA': 14,
  '40CB': 14,
  '40CC': 14,
  '40CD': 14,
  '40CE': 14,
  '40CF': 14,
  '40D0': 14,
  '40D1': 15,
  '40D2': 15,
  '40D3': 15,
  '40D4': 15,
  '40D5': 15,
  '40D6': 15,
  '40D7': 15,
  '40D8': 16,
  '40D9': 16,
  '40DA': 16,
  '40DB': 16,
  '40DC': 16,
  '40DD': 16,
  '40DE': 16,
  '40DF': 17,
  '40E0': 16,
  '40E1': 17,
  '40E2': 17,
  '40E3': 17,
  '40E4': 17,
  '40E5': 17,
  '40E6': 17,
  '40E7': 17,
  '40E8': 17,
  '40E9': 18,
  '40EA': 18,
  '40EB': 18,
  '40EC': 18,
  '40ED': 18,
  '40EE': 18,
  '40EF': 18,
  '40F0': 19,
  '40F1': 20,
  '40F2': 20,
  '40F3': 20,
  '40F4': 21,
  '40F5': 21,
  '40F6': 21,
  '40F7': 21,
  '40F8': 22,
  '40F9': 22,
  '40FA': 24,
  '40FB': 26,
  '40FC': 7,
  '40FD': 9,
  '40FE': 9,
  '40FF': 9,
  '4100': 9,
  '4101': 10,
  '4102': 10,
  '4103': 10,
  '4104': 11,
  '4105': 11,
  '4106': 11,
  '4107': 12,
  '4108': 12,
  '4109': 12,
  '410A': 12,
  '410B': 13,
  '410C': 13,
  '410D': 13,
  '410E': 13,
  '410F': 13,
  '4110': 13,
  '4111': 13,
  '4112': 13,
  '4113': 14,
  '4114': 14,
  '4115': 14,
  '4116': 14,
  '4117': 14,
  '4118': 15,
  '4119': 15,
  '411A': 16,
  '411B': 16,
  '411C': 16,
  '411D': 16,
  '411E': 16,
  '411F': 17,
  '4120': 18,
  '4121': 18,
  '4122': 19,
  '4123': 20,
  '4124': 21,
  '4125': 29,
  '4126': 7,
  '4127': 7,
  '4128': 8,
  '4129': 8,
  '412A': 8,
  '412B': 8,
  '412C': 8,
  '412D': 8,
  '412E': 9,
  '412F': 9,
  '4130': 9,
  '4131': 9,
  '4132': 9,
  '4133': 9,
  '4134': 9,
  '4135': 9,
  '4136': 10,
  '4137': 10,
  '4138': 10,
  '4139': 10,
  '413A': 11,
  '413B': 11,
  '413C': 11,
  '413D': 11,
  '413E': 11,
  '413F': 11,
  '4140': 11,
  '4141': 11,
  '4142': 11,
  '4143': 11,
  '4144': 11,
  '4145': 11,
  '4146': 11,
  '4147': 11,
  '4148': 11,
  '4149': 11,
  '414A': 11,
  '414B': 12,
  '414C': 12,
  '414D': 12,
  '414E': 12,
  '414F': 12,
  '4150': 12,
  '4151': 12,
  '4152': 12,
  '4153': 12,
  '4154': 13,
  '4155': 13,
  '4156': 13,
  '4157': 13,
  '4158': 13,
  '4159': 13,
  '415A': 13,
  '415B': 13,
  '415C': 13,
  '415D': 13,
  '415E': 13,
  '415F': 13,
  '4160': 14,
  '4161': 14,
  '4162': 14,
  '4163': 14,
  '4164': 14,
  '4165': 14,
  '4166': 14,
  '4167': 14,
  '4168': 14,
  '4169': 14,
  '416A': 14,
  '416B': 14,
  '416C': 15,
  '416D': 15,
  '416E': 15,
  '416F': 15,
  '4170': 15,
  '4171': 15,
  '4172': 15,
  '4173': 15,
  '4174': 15,
  '4175': 15,
  '4176': 15,
  '4177': 16,
  '4178': 16,
  '4179': 16,
  '417A': 16,
  '417B': 16,
  '417C': 16,
  '417D': 16,
  '417E': 17,
  '417F': 17,
  '4180': 17,
  '4181': 18,
  '4182': 18,
  '4183': 18,
  '4184': 18,
  '4185': 18,
  '4186': 18,
  '4187': 18,
  '4188': 20,
  '4189': 20,
  '418A': 21,
  '418B': 21,
  '418C': 21,
  '418D': 21,
  '418E': 22,
  '418F': 22,
  '4190': 30,
  '4191': 7,
  '4192': 8,
  '4193': 9,
  '4194': 9,
  '4195': 9,
  '4196': 9,
  '4197': 10,
  '4198': 10,
  '4199': 10,
  '419A': 11,
  '419B': 11,
  '419C': 11,
  '419D': 11,
  '419E': 11,
  '419F': 11,
  '41A0': 11,
  '41A1': 12,
  '41A2': 12,
  '41A3': 12,
  '41A4': 12,
  '41A5': 12,
  '41A6': 13,
  '41A7': 13,
  '41A8': 13,
  '41A9': 13,
  '41AA': 14,
  '41AB': 14,
  '41AC': 15,
  '41AD': 15,
  '41AE': 15,
  '41AF': 16,
  '41B0': 16,
  '41B1': 16,
  '41B2': 16,
  '41B3': 16,
  '41B4': 16,
  '41B5': 17,
  '41B6': 17,
  '41B7': 17,
  '41B8': 17,
  '41B9': 17,
  '41BA': 17,
  '41BB': 17,
  '41BC': 18,
  '41BD': 18,
  '41BE': 19,
  '41BF': 19,
  '41C0': 20,
  '41C1': 22,
  '41C2': 6,
  '41C3': 8,
  '41C4': 8,
  '41C5': 9,
  '41C6': 9,
  '41C7': 10,
  '41C8': 10,
  '41C9': 10,
  '41CA': 10,
  '41CB': 11,
  '41CC': 12,
  '41CD': 12,
  '41CE': 13,
  '41CF': 13,
  '41D0': 13,
  '41D1': 13,
  '41D2': 16,
  '41D3': 17,
  '41D4': 18,
  '41D5': 19,
  '41D6': 9,
  '41D7': 10,
  '41D8': 10,
  '41D9': 10,
  '41DA': 10,
  '41DB': 10,
  '41DC': 10,
  '41DD': 10,
  '41DE': 11,
  '41DF': 11,
  '41E0': 11,
  '41E1': 11,
  '41E2': 11,
  '41E3': 11,
  '41E4': 11,
  '41E5': 11,
  '41E6': 11,
  '41E7': 12,
  '41E8': 12,
  '41E9': 12,
  '41EA': 12,
  '41EB': 12,
  '41EC': 12,
  '41ED': 12,
  '41EE': 12,
  '41EF': 12,
  '41F0': 12,
  '41F1': 12,
  '41F2': 12,
  '41F3': 12,
  '41F4': 12,
  '41F5': 13,
  '41F6': 13,
  '41F7': 13,
  '41F8': 13,
  '41F9': 13,
  '41FA': 13,
  '41FB': 13,
  '41FC': 13,
  '41FD': 13,
  '41FE': 13,
  '41FF': 13,
  '4200': 13,
  '4201': 14,
  '4202': 14,
  '4203': 14,
  '4204': 14,
  '4205': 14,
  '4206': 14,
  '4207': 14,
  '4208': 14,
  '4209': 14,
  '420A': 14,
  '420B': 14,
  '420C': 14,
  '420D': 14,
  '420E': 15,
  '420F': 15,
  '4210': 15,
  '4211': 15,
  '4212': 15,
  '4213': 15,
  '4214': 15,
  '4215': 15,
  '4216': 15,
  '4217': 15,
  '4218': 15,
  '4219': 15,
  '421A': 15,
  '421B': 15,
  '421C': 15,
  '421D': 15,
  '421E': 15,
  '421F': 15,
  '4220': 15,
  '4221': 15,
  '4222': 15,
  '4223': 15,
  '4224': 15,
  '4225': 15,
  '4226': 15,
  '4227': 15,
  '4228': 15,
  '4229': 15,
  '422A': 16,
  '422B': 16,
  '422C': 16,
  '422D': 16,
  '422E': 16,
  '422F': 16,
  '4230': 16,
  '4231': 16,
  '4232': 16,
  '4233': 16,
  '4234': 16,
  '4235': 16,
  '4236': 16,
  '4237': 16,
  '4238': 17,
  '4239': 17,
  '423A': 17,
  '423B': 17,
  '423C': 17,
  '423D': 17,
  '423E': 17,
  '423F': 17,
  '4240': 17,
  '4241': 17,
  '4242': 17,
  '4243': 17,
  '4244': 17,
  '4245': 17,
  '4246': 17,
  '4247': 17,
  '4248': 18,
  '4249': 18,
  '424A': 18,
  '424B': 18,
  '424C': 18,
  '424D': 18,
  '424E': 18,
  '424F': 19,
  '4250': 18,
  '4251': 18,
  '4252': 18,
  '4253': 18,
  '4254': 18,
  '4255': 18,
  '4256': 18,
  '4257': 18,
  '4258': 18,
  '4259': 18,
  '425A': 18,
  '425B': 19,
  '425C': 19,
  '425D': 19,
  '425E': 19,
  '425F': 19,
  '4260': 19,
  '4261': 19,
  '4262': 19,
  '4263': 18,
  '4264': 19,
  '4265': 20,
  '4266': 21,
  '4267': 21,
  '4268': 21,
  '4269': 21,
  '426A': 21,
  '426B': 21,
  '426C': 21,
  '426D': 21,
  '426E': 22,
  '426F': 22,
  '4270': 22,
  '4271': 22,
  '4272': 23,
  '4273': 23,
  '4274': 23,
  '4275': 23,
  '4276': 24,
  '4277': 26,
  '4278': 27,
  '4279': 30,
  '427A': 9,
  '427B': 10,
  '427C': 10,
  '427D': 11,
  '427E': 11,
  '427F': 11,
  '4280': 11,
  '4281': 12,
  '4282': 12,
  '4283': 12,
  '4284': 12,
  '4285': 12,
  '4286': 12,
  '4287': 13,
  '4288': 13,
  '4289': 13,
  '428A': 13,
  '428B': 13,
  '428C': 13,
  '428D': 14,
  '428E': 14,
  '428F': 14,
  '4290': 14,
  '4291': 14,
  '4292': 14,
  '4293': 15,
  '4294': 15,
  '4295': 15,
  '4296': 15,
  '4297': 15,
  '4298': 15,
  '4299': 15,
  '429A': 16,
  '429B': 16,
  '429C': 17,
  '429D': 17,
  '429E': 17,
  '429F': 17,
  '42A0': 17,
  '42A1': 17,
  '42A2': 17,
  '42A3': 18,
  '42A4': 18,
  '42A5': 18,
  '42A6': 18,
  '42A7': 18,
  '42A8': 18,
  '42A9': 18,
  '42AA': 19,
  '42AB': 19,
  '42AC': 19,
  '42AD': 20,
  '42AE': 20,
  '42AF': 21,
  '42B0': 22,
  '42B1': 23,
  '42B2': 23,
  '42B3': 25,
  '42B4': 25,
  '42B5': 8,
  '42B6': 9,
  '42B7': 9,
  '42B8': 9,
  '42B9': 9,
  '42BA': 10,
  '42BB': 10,
  '42BC': 10,
  '42BD': 10,
  '42BE': 10,
  '42BF': 10,
  '42C0': 10,
  '42C1': 10,
  '42C2': 10,
  '42C3': 10,
  '42C4': 10,
  '42C5': 10,
  '42C6': 10,
  '42C7': 10,
  '42C8': 11,
  '42C9': 11,
  '42CA': 11,
  '42CB': 11,
  '42CC': 11,
  '42CD': 11,
  '42CE': 11,
  '42CF': 11,
  '42D0': 11,
  '42D1': 11,
  '42D2': 11,
  '42D3': 11,
  '42D4': 11,
  '42D5': 12,
  '42D6': 12,
  '42D7': 12,
  '42D8': 12,
  '42D9': 12,
  '42DA': 12,
  '42DB': 12,
  '42DC': 12,
  '42DD': 12,
  '42DE': 12,
  '42DF': 13,
  '42E0': 13,
  '42E1': 13,
  '42E2': 13,
  '42E3': 13,
  '42E4': 13,
  '42E5': 13,
  '42E6': 13,
  '42E7': 14,
  '42E8': 14,
  '42E9': 14,
  '42EA': 14,
  '42EB': 14,
  '42EC': 14,
  '42ED': 14,
  '42EE': 14,
  '42EF': 14,
  '42F0': 14,
  '42F1': 14,
  '42F2': 14,
  '42F3': 15,
  '42F4': 15,
  '42F5': 15,
  '42F6': 15,
  '42F7': 15,
  '42F8': 15,
  '42F9': 15,
  '42FA': 15,
  '42FB': 15,
  '42FC': 15,
  '42FD': 15,
  '42FE': 15,
  '42FF': 15,
  '4300': 15,
  '4301': 15,
  '4302': 15,
  '4303': 15,
  '4304': 15,
  '4305': 16,
  '4306': 16,
  '4307': 16,
  '4308': 16,
  '4309': 16,
  '430A': 16,
  '430B': 16,
  '430C': 17,
  '430D': 17,
  '430E': 17,
  '430F': 17,
  '4310': 17,
  '4311': 17,
  '4312': 17,
  '4313': 17,
  '4314': 17,
  '4315': 17,
  '4316': 18,
  '4317': 18,
  '4318': 18,
  '4319': 18,
  '431A': 18,
  '431B': 18,
  '431C': 19,
  '431D': 19,
  '431E': 19,
  '431F': 19,
  '4320': 19,
  '4321': 19,
  '4322': 19,
  '4323': 20,
  '4324': 20,
  '4325': 20,
  '4326': 20,
  '4327': 20,
  '4328': 21,
  '4329': 21,
  '432A': 22,
  '432B': 22,
  '432C': 22,
  '432D': 22,
  '432E': 23,
  '432F': 24,
  '4330': 24,
  '4331': 24,
  '4332': 24,
  '4333': 25,
  '4334': 25,
  '4335': 27,
  '4336': 9,
  '4337': 11,
  '4338': 10,
  '4339': 11,
  '433A': 12,
  '433B': 12,
  '433C': 13,
  '433D': 14,
  '433E': 15,
  '433F': 15,
  '4340': 16,
  '4341': 19,
  '4342': 9,
  '4343': 10,
  '4344': 11,
  '4345': 11,
  '4346': 11,
  '4347': 11,
  '4348': 11,
  '4349': 11,
  '434A': 12,
  '434B': 14,
  '434C': 14,
  '434D': 16,
  '434E': 23,
  '434F': 5,
  '4350': 8,
  '4351': 9,
  '4352': 9,
  '4353': 9,
  '4354': 9,
  '4355': 10,
  '4356': 10,
  '4357': 10,
  '4358': 11,
  '4359': 12,
  '435A': 12,
  '435B': 13,
  '435C': 13,
  '435D': 13,
  '435E': 14,
  '435F': 14,
  '4360': 16,
  '4361': 16,
  '4362': 17,
  '4363': 18,
  '4364': 19,
  '4365': 21,
  '4366': 24,
  '4367': 10,
  '4368': 10,
  '4369': 10,
  '436A': 11,
  '436B': 11,
  '436C': 11,
  '436D': 11,
  '436E': 12,
  '436F': 12,
  '4370': 12,
  '4371': 13,
  '4372': 14,
  '4373': 14,
  '4374': 14,
  '4375': 14,
  '4376': 14,
  '4377': 15,
  '4378': 16,
  '4379': 16,
  '437A': 17,
  '437B': 18,
  '437C': 18,
  '437D': 22,
  '437E': 10,
  '437F': 10,
  '4380': 11,
  '4381': 11,
  '4382': 11,
  '4383': 11,
  '4384': 11,
  '4385': 11,
  '4386': 11,
  '4387': 12,
  '4388': 12,
  '4389': 12,
  '438A': 12,
  '438B': 13,
  '438C': 13,
  '438D': 13,
  '438E': 13,
  '438F': 14,
  '4390': 14,
  '4391': 14,
  '4392': 14,
  '4393': 14,
  '4394': 16,
  '4395': 16,
  '4396': 18,
  '4397': 18,
  '4398': 18,
  '4399': 20,
  '439A': 21,
  '439B': 9,
  '439C': 12,
  '439D': 12,
  '439E': 12,
  '439F': 9,
  '43A0': 9,
  '43A1': 10,
  '43A2': 9,
  '43A3': 11,
  '43A4': 13,
  '43A5': 13,
  '43A6': 14,
  '43A7': 14,
  '43A8': 14,
  '43A9': 14,
  '43AA': 14,
  '43AB': 15,
  '43AC': 15,
  '43AD': 17,
  '43AE': 17,
  '43AF': 17,
  '43B0': 18,
  '43B1': 21,
  '43B2': 7,
  '43B3': 10,
  '43B4': 10,
  '43B5': 11,
  '43B6': 11,
  '43B7': 12,
  '43B8': 13,
  '43B9': 13,
  '43BA': 14,
  '43BB': 14,
  '43BC': 14,
  '43BD': 14,
  '43BE': 14,
  '43BF': 15,
  '43C0': 15,
  '43C1': 16,
  '43C2': 16,
  '43C3': 16,
  '43C4': 17,
  '43C5': 17,
  '43C6': 17,
  '43C7': 17,
  '43C8': 18,
  '43C9': 20,
  '43CA': 22,
  '43CB': 13,
  '43CC': 6,
  '43CD': 6,
  '43CE': 7,
  '43CF': 7,
  '43D0': 8,
  '43D1': 10,
  '43D2': 8,
  '43D3': 8,
  '43D4': 8,
  '43D5': 8,
  '43D6': 8,
  '43D7': 8,
  '43D8': 8,
  '43D9': 8,
  '43DA': 8,
  '43DB': 8,
  '43DC': 8,
  '43DD': 8,
  '43DE': 9,
  '43DF': 9,
  '43E0': 9,
  '43E1': 9,
  '43E2': 9,
  '43E3': 9,
  '43E4': 9,
  '43E5': 9,
  '43E6': 10,
  '43E7': 10,
  '43E8': 10,
  '43E9': 10,
  '43EA': 10,
  '43EB': 10,
  '43EC': 10,
  '43ED': 10,
  '43EE': 10,
  '43EF': 11,
  '43F0': 11,
  '43F1': 11,
  '43F2': 11,
  '43F3': 11,
  '43F4': 11,
  '43F5': 11,
  '43F6': 11,
  '43F7': 11,
  '43F8': 11,
  '43F9': 11,
  '43FA': 11,
  '43FB': 11,
  '43FC': 12,
  '43FD': 12,
  '43FE': 12,
  '43FF': 12,
  '4400': 12,
  '4401': 12,
  '4402': 12,
  '4403': 12,
  '4404': 12,
  '4405': 12,
  '4406': 12,
  '4407': 12,
  '4408': 12,
  '4409': 12,
  '440A': 12,
  '440B': 12,
  '440C': 12,
  '440D': 13,
  '440E': 13,
  '440F': 13,
  '4410': 13,
  '4411': 13,
  '4412': 13,
  '4413': 13,
  '4414': 13,
  '4415': 13,
  '4416': 13,
  '4417': 13,
  '4418': 13,
  '4419': 13,
  '441A': 13,
  '441B': 13,
  '441C': 14,
  '441D': 14,
  '441E': 14,
  '441F': 14,
  '4420': 14,
  '4421': 16,
  '4422': 14,
  '4423': 14,
  '4424': 14,
  '4425': 14,
  '4426': 14,
  '4427': 14,
  '4428': 14,
  '4429': 14,
  '442A': 14,
  '442B': 15,
  '442C': 15,
  '442D': 15,
  '442E': 15,
  '442F': 15,
  '4430': 15,
  '4431': 15,
  '4432': 15,
  '4433': 15,
  '4434': 15,
  '4435': 16,
  '4436': 16,
  '4437': 16,
  '4438': 16,
  '4439': 16,
  '443A': 16,
  '443B': 16,
  '443C': 16,
  '443D': 16,
  '443E': 17,
  '443F': 17,
  '4440': 17,
  '4441': 17,
  '4442': 18,
  '4443': 18,
  '4444': 18,
  '4445': 18,
  '4446': 19,
  '4447': 19,
  '4448': 19,
  '4449': 20,
  '444A': 20,
  '444B': 21,
  '444C': 21,
  '444D': 21,
  '444E': 21,
  '444F': 22,
  '4450': 11,
  '4451': 18,
  '4452': 9,
  '4453': 13,
  '4454': 10,
  '4455': 11,
  '4456': 14,
  '4457': 15,
  '4458': 16,
  '4459': 10,
  '445A': 10,
  '445B': 11,
  '445C': 15,
  '445D': 14,
  '445E': 16,
  '445F': 21,
  '4460': 8,
  '4461': 9,
  '4462': 9,
  '4463': 9,
  '4464': 10,
  '4465': 10,
  '4466': 11,
  '4467': 11,
  '4468': 11,
  '4469': 11,
  '446A': 12,
  '446B': 12,
  '446C': 12,
  '446D': 12,
  '446E': 12,
  '446F': 13,
  '4470': 13,
  '4471': 14,
  '4472': 14,
  '4473': 14,
  '4474': 14,
  '4475': 14,
  '4476': 14,
  '4477': 14,
  '4478': 14,
  '4479': 15,
  '447A': 15,
  '447B': 15,
  '447C': 16,
  '447D': 16,
  '447E': 16,
  '447F': 17,
  '4480': 17,
  '4481': 17,
  '4482': 17,
  '4483': 17,
  '4484': 17,
  '4485': 17,
  '4486': 18,
  '4487': 18,
  '4488': 18,
  '4489': 20,
  '448A': 9,
  '448B': 11,
  '448C': 16,
  '448D': 16,
  '448E': 18,
  '448F': 18,
  '4490': 22,
  '4491': 6,
  '4492': 8,
  '4493': 8,
  '4494': 8,
  '4495': 9,
  '4496': 9,
  '4497': 9,
  '4498': 9,
  '4499': 9,
  '449A': 10,
  '449B': 10,
  '449C': 10,
  '449D': 10,
  '449E': 10,
  '449F': 10,
  '44A0': 10,
  '44A1': 10,
  '44A2': 10,
  '44A3': 10,
  '44A4': 10,
  '44A5': 10,
  '44A6': 11,
  '44A7': 11,
  '44A8': 11,
  '44A9': 11,
  '44AA': 11,
  '44AB': 11,
  '44AC': 11,
  '44AD': 11,
  '44AE': 11,
  '44AF': 11,
  '44B0': 12,
  '44B1': 12,
  '44B2': 12,
  '44B3': 12,
  '44B4': 12,
  '44B5': 12,
  '44B6': 12,
  '44B7': 12,
  '44B8': 12,
  '44B9': 12,
  '44BA': 12,
  '44BB': 12,
  '44BC': 12,
  '44BD': 12,
  '44BE': 12,
  '44BF': 12,
  '44C0': 12,
  '44C1': 12,
  '44C2': 13,
  '44C3': 13,
  '44C4': 13,
  '44C5': 13,
  '44C6': 13,
  '44C7': 13,
  '44C8': 13,
  '44C9': 13,
  '44CA': 13,
  '44CB': 13,
  '44CC': 13,
  '44CD': 13,
  '44CE': 13,
  '44CF': 13,
  '44D0': 13,
  '44D1': 13,
  '44D2': 13,
  '44D3': 13,
  '44D4': 13,
  '44D5': 14,
  '44D6': 13,
  '44D7': 14,
  '44D8': 14,
  '44D9': 14,
  '44DA': 14,
  '44DB': 14,
  '44DC': 14,
  '44DD': 14,
  '44DE': 14,
  '44DF': 14,
  '44E0': 14,
  '44E1': 14,
  '44E2': 14,
  '44E3': 14,
  '44E4': 14,
  '44E5': 14,
  '44E6': 14,
  '44E7': 14,
  '44E8': 14,
  '44E9': 14,
  '44EA': 14,
  '44EB': 14,
  '44EC': 14,
  '44ED': 14,
  '44EE': 15,
  '44EF': 15,
  '44F0': 15,
  '44F1': 15,
  '44F2': 15,
  '44F3': 15,
  '44F4': 15,
  '44F5': 15,
  '44F6': 15,
  '44F7': 15,
  '44F8': 15,
  '44F9': 15,
  '44FA': 15,
  '44FB': 15,
  '44FC': 16,
  '44FD': 16,
  '44FE': 16,
  '44FF': 16,
  '4500': 16,
  '4501': 16,
  '4502': 16,
  '4503': 16,
  '4504': 16,
  '4505': 16,
  '4506': 16,
  '4507': 16,
  '4508': 16,
  '4509': 16,
  '450A': 16,
  '450B': 16,
  '450C': 16,
  '450D': 16,
  '450E': 17,
  '450F': 17,
  '4510': 17,
  '4511': 17,
  '4512': 17,
  '4513': 17,
  '4514': 17,
  '4515': 17,
  '4516': 17,
  '4517': 17,
  '4518': 17,
  '4519': 17,
  '451A': 17,
  '451B': 17,
  '451C': 17,
  '451D': 17,
  '451E': 17,
  '451F': 17,
  '4520': 17,
  '4521': 17,
  '4522': 17,
  '4523': 17,
  '4524': 17,
  '4525': 17,
  '4526': 17,
  '4527': 17,
  '4528': 17,
  '4529': 17,
  '452A': 17,
  '452B': 17,
  '452C': 17,
  '452D': 18,
  '452E': 18,
  '452F': 18,
  '4530': 18,
  '4531': 18,
  '4532': 18,
  '4533': 18,
  '4534': 18,
  '4535': 18,
  '4536': 18,
  '4537': 18,
  '4538': 18,
  '4539': 18,
  '453A': 18,
  '453B': 18,
  '453C': 18,
  '453D': 18,
  '453E': 18,
  '453F': 18,
  '4540': 18,
  '4541': 18,
  '4542': 18,
  '4543': 18,
  '4544': 18,
  '4545': 19,
  '4546': 19,
  '4547': 19,
  '4548': 19,
  '4549': 19,
  '454A': 19,
  '454B': 19,
  '454C': 19,
  '454D': 19,
  '454E': 19,
  '454F': 19,
  '4550': 19,
  '4551': 19,
  '4552': 20,
  '4553': 20,
  '4554': 20,
  '4555': 20,
  '4556': 20,
  '4557': 20,
  '4558': 20,
  '4559': 20,
  '455A': 20,
  '455B': 20,
  '455C': 20,
  '455D': 20,
  '455E': 21,
  '455F': 21,
  '4560': 21,
  '4561': 21,
  '4562': 21,
  '4563': 21,
  '4564': 21,
  '4565': 21,
  '4566': 22,
  '4567': 22,
  '4568': 22,
  '4569': 22,
  '456A': 22,
  '456B': 22,
  '456C': 22,
  '456D': 22,
  '456E': 22,
  '456F': 22,
  '4570': 22,
  '4571': 22,
  '4572': 22,
  '4573': 23,
  '4574': 23,
  '4575': 23,
  '4576': 23,
  '4577': 23,
  '4578': 24,
  '4579': 24,
  '457A': 24,
  '457B': 25,
  '457C': 25,
  '457D': 25,
  '457E': 26,
  '457F': 27,
  '4580': 27,
  '4581': 28,
  '4582': 29,
  '4583': 29,
  '4584': 29,
  '4585': 30,
  '4586': 30,
  '4587': 35,
  '4588': 8,
  '4589': 9,
  '458A': 10,
  '458B': 10,
  '458C': 10,
  '458D': 10,
  '458E': 11,
  '458F': 11,
  '4590': 12,
  '4591': 12,
  '4592': 13,
  '4593': 13,
  '4594': 13,
  '4595': 13,
  '4596': 13,
  '4597': 15,
  '4598': 16,
  '4599': 17,
  '459A': 18,
  '459B': 18,
  '459C': 19,
  '459D': 7,
  '459E': 9,
  '459F': 9,
  '45A0': 9,
  '45A1': 10,
  '45A2': 10,
  '45A3': 10,
  '45A4': 11,
  '45A5': 11,
  '45A6': 11,
  '45A7': 11,
  '45A8': 11,
  '45A9': 11,
  '45AA': 11,
  '45AB': 11,
  '45AC': 11,
  '45AD': 12,
  '45AE': 12,
  '45AF': 12,
  '45B0': 12,
  '45B1': 12,
  '45B2': 12,
  '45B3': 12,
  '45B4': 12,
  '45B5': 12,
  '45B6': 13,
  '45B7': 13,
  '45B8': 13,
  '45B9': 13,
  '45BA': 13,
  '45BB': 13,
  '45BC': 13,
  '45BD': 13,
  '45BE': 13,
  '45BF': 14,
  '45C0': 14,
  '45C1': 14,
  '45C2': 14,
  '45C3': 14,
  '45C4': 14,
  '45C5': 14,
  '45C6': 14,
  '45C7': 14,
  '45C8': 14,
  '45C9': 14,
  '45CA': 14,
  '45CB': 15,
  '45CC': 15,
  '45CD': 15,
  '45CE': 15,
  '45CF': 15,
  '45D0': 15,
  '45D1': 15,
  '45D2': 15,
  '45D3': 15,
  '45D4': 15,
  '45D5': 14,
  '45D6': 15,
  '45D7': 16,
  '45D8': 16,
  '45D9': 16,
  '45DA': 16,
  '45DB': 16,
  '45DC': 16,
  '45DD': 16,
  '45DE': 16,
  '45DF': 17,
  '45E0': 17,
  '45E1': 17,
  '45E2': 17,
  '45E3': 17,
  '45E4': 17,
  '45E5': 17,
  '45E6': 17,
  '45E7': 17,
  '45E8': 17,
  '45E9': 17,
  '45EA': 17,
  '45EB': 17,
  '45EC': 17,
  '45ED': 17,
  '45EE': 17,
  '45EF': 18,
  '45F0': 18,
  '45F1': 18,
  '45F2': 18,
  '45F3': 18,
  '45F4': 19,
  '45F5': 19,
  '45F6': 19,
  '45F7': 19,
  '45F8': 19,
  '45F9': 19,
  '45FA': 19,
  '45FB': 19,
  '45FC': 20,
  '45FD': 20,
  '45FE': 20,
  '45FF': 20,
  '4600': 20,
  '4601': 20,
  '4602': 21,
  '4603': 21,
  '4604': 21,
  '4605': 22,
  '4606': 22,
  '4607': 22,
  '4608': 22,
  '4609': 22,
  '460A': 23,
  '460B': 23,
  '460C': 23,
  '460D': 25,
  '460E': 28,
  '460F': 9,
  '4610': 10,
  '4611': 11,
  '4612': 13,
  '4613': 14,
  '4614': 15,
  '4615': 10,
  '4616': 12,
  '4617': 16,
  '4618': 16,
  '4619': 20,
  '461A': 8,
  '461B': 8,
  '461C': 9,
  '461D': 9,
  '461E': 9,
  '461F': 10,
  '4620': 10,
  '4621': 10,
  '4622': 11,
  '4623': 11,
  '4624': 11,
  '4625': 11,
  '4626': 11,
  '4627': 11,
  '4628': 12,
  '4629': 12,
  '462A': 12,
  '462B': 12,
  '462C': 12,
  '462D': 12,
  '462E': 12,
  '462F': 13,
  '4630': 13,
  '4631': 13,
  '4632': 13,
  '4633': 14,
  '4634': 14,
  '4635': 14,
  '4636': 14,
  '4637': 14,
  '4638': 14,
  '4639': 14,
  '463A': 14,
  '463B': 14,
  '463C': 14,
  '463D': 14,
  '463E': 14,
  '463F': 14,
  '4640': 14,
  '4641': 14,
  '4642': 14,
  '4643': 15,
  '4644': 15,
  '4645': 15,
  '4646': 15,
  '4647': 15,
  '4648': 15,
  '4649': 15,
  '464A': 15,
  '464B': 15,
  '464C': 15,
  '464D': 15,
  '464E': 16,
  '464F': 16,
  '4650': 16,
  '4651': 16,
  '4652': 16,
  '4653': 16,
  '4654': 17,
  '4655': 17,
  '4656': 17,
  '4657': 17,
  '4658': 17,
  '4659': 17,
  '465A': 17,
  '465B': 17,
  '465C': 17,
  '465D': 17,
  '465E': 18,
  '465F': 18,
  '4660': 18,
  '4661': 18,
  '4662': 18,
  '4663': 18,
  '4664': 19,
  '4665': 19,
  '4666': 20,
  '4667': 20,
  '4668': 20,
  '4669': 20,
  '466A': 21,
  '466B': 22,
  '466C': 23,
  '466D': 23,
  '466E': 24,
  '466F': 24,
  '4670': 25,
  '4671': 27,
  '4672': 9,
  '4673': 10,
  '4674': 11,
  '4675': 12,
  '4676': 12,
  '4677': 10,
  '4678': 10,
  '4679': 11,
  '467A': 11,
  '467B': 11,
  '467C': 12,
  '467D': 12,
  '467E': 12,
  '467F': 12,
  '4680': 13,
  '4681': 13,
  '4682': 14,
  '4683': 14,
  '4684': 15,
  '4685': 15,
  '4686': 16,
  '4687': 16,
  '4688': 16,
  '4689': 16,
  '468A': 17,
  '468B': 17,
  '468C': 17,
  '468D': 18,
  '468E': 18,
  '468F': 19,
  '4690': 19,
  '4691': 19,
  '4692': 19,
  '4693': 19,
  '4694': 21,
  '4695': 26,
  '4696': 31,
  '4697': 11,
  '4698': 13,
  '4699': 13,
  '469A': 13,
  '469B': 14,
  '469C': 15,
  '469D': 15,
  '469E': 15,
  '469F': 15,
  '46A0': 15,
  '46A1': 16,
  '46A2': 16,
  '46A3': 16,
  '46A4': 16,
  '46A5': 17,
  '46A6': 17,
  '46A7': 18,
  '46A8': 19,
  '46A9': 19,
  '46AA': 20,
  '46AB': 20,
  '46AC': 19,
  '46AD': 25,
  '46AE': 9,
  '46AF': 9,
  '46B0': 9,
  '46B1': 10,
  '46B2': 10,
  '46B3': 11,
  '46B4': 11,
  '46B5': 11,
  '46B6': 11,
  '46B7': 11,
  '46B8': 11,
  '46B9': 11,
  '46BA': 11,
  '46BB': 11,
  '46BC': 11,
  '46BD': 11,
  '46BE': 11,
  '46BF': 11,
  '46C0': 11,
  '46C1': 11,
  '46C2': 11,
  '46C3': 11,
  '46C4': 12,
  '46C5': 12,
  '46C6': 12,
  '46C7': 12,
  '46C8': 12,
  '46C9': 12,
  '46CA': 12,
  '46CB': 12,
  '46CC': 12,
  '46CD': 12,
  '46CE': 12,
  '46CF': 12,
  '46D0': 12,
  '46D1': 12,
  '46D2': 12,
  '46D3': 12,
  '46D4': 13,
  '46D5': 13,
  '46D6': 13,
  '46D7': 13,
  '46D8': 13,
  '46D9': 13,
  '46DA': 13,
  '46DB': 13,
  '46DC': 13,
  '46DD': 14,
  '46DE': 14,
  '46DF': 14,
  '46E0': 14,
  '46E1': 14,
  '46E2': 14,
  '46E3': 14,
  '46E4': 14,
  '46E5': 14,
  '46E6': 14,
  '46E7': 14,
  '46E8': 14,
  '46E9': 15,
  '46EA': 15,
  '46EB': 15,
  '46EC': 15,
  '46ED': 15,
  '46EE': 15,
  '46EF': 15,
  '46F0': 15,
  '46F1': 15,
  '46F2': 15,
  '46F3': 15,
  '46F4': 15,
  '46F5': 15,
  '46F6': 15,
  '46F7': 15,
  '46F8': 15,
  '46F9': 16,
  '46FA': 16,
  '46FB': 16,
  '46FC': 16,
  '46FD': 16,
  '46FE': 17,
  '46FF': 17,
  '4700': 17,
  '4701': 17,
  '4702': 17,
  '4703': 18,
  '4704': 18,
  '4705': 18,
  '4706': 18,
  '4707': 18,
  '4708': 18,
  '4709': 18,
  '470A': 18,
  '470B': 19,
  '470C': 19,
  '470D': 19,
  '470E': 19,
  '470F': 19,
  '4710': 19,
  '4711': 19,
  '4712': 20,
  '4713': 20,
  '4714': 20,
  '4715': 20,
  '4716': 20,
  '4717': 20,
  '4718': 20,
  '4719': 21,
  '471A': 21,
  '471B': 21,
  '471C': 21,
  '471D': 21,
  '471E': 21,
  '471F': 22,
  '4720': 22,
  '4721': 22,
  '4722': 23,
  '4723': 11,
  '4724': 13,
  '4725': 15,
  '4726': 17,
  '4727': 17,
  '4728': 18,
  '4729': 23,
  '472A': 9,
  '472B': 10,
  '472C': 12,
  '472D': 12,
  '472E': 14,
  '472F': 15,
  '4730': 17,
  '4731': 18,
  '4732': 22,
  '4733': 8,
  '4734': 11,
  '4735': 12,
  '4736': 13,
  '4737': 14,
  '4738': 14,
  '4739': 14,
  '473A': 15,
  '473B': 16,
  '473C': 16,
  '473D': 16,
  '473E': 16,
  '473F': 16,
  '4740': 17,
  '4741': 17,
  '4742': 17,
  '4743': 21,
  '4744': 25,
  '4745': 10,
  '4746': 11,
  '4747': 11,
  '4748': 12,
  '4749': 13,
  '474A': 15,
  '474B': 15,
  '474C': 15,
  '474D': 16,
  '474E': 16,
  '474F': 18,
  '4750': 19,
  '4751': 19,
  '4752': 21,
  '4753': 22,
  '4754': 25,
  '4755': 25,
  '4756': 11,
  '4757': 11,
  '4758': 11,
  '4759': 11,
  '475A': 12,
  '475B': 12,
  '475C': 14,
  '475D': 15,
  '475E': 15,
  '475F': 16,
  '4760': 17,
  '4761': 18,
  '4762': 18,
  '4763': 19,
  '4764': 19,
  '4765': 19,
  '4766': 19,
  '4767': 11,
  '4768': 11,
  '4769': 12,
  '476A': 12,
  '476B': 12,
  '476C': 12,
  '476D': 12,
  '476E': 12,
  '476F': 12,
  '4770': 13,
  '4771': 13,
  '4772': 13,
  '4773': 14,
  '4774': 14,
  '4775': 14,
  '4776': 15,
  '4777': 15,
  '4778': 15,
  '4779': 15,
  '477A': 15,
  '477B': 15,
  '477C': 15,
  '477D': 15,
  '477E': 15,
  '477F': 15,
  '4780': 16,
  '4781': 16,
  '4782': 16,
  '4783': 16,
  '4784': 16,
  '4785': 17,
  '4786': 17,
  '4787': 18,
  '4788': 19,
  '4789': 20,
  '478A': 22,
  '478B': 23,
  '478C': 13,
  '478D': 15,
  '478E': 15,
  '478F': 16,
  '4790': 16,
  '4791': 10,
  '4792': 13,
  '4793': 14,
  '4794': 14,
  '4795': 21,
  '4796': 10,
  '4797': 10,
  '4798': 10,
  '4799': 11,
  '479A': 11,
  '479B': 11,
  '479C': 11,
  '479D': 12,
  '479E': 12,
  '479F': 12,
  '47A0': 12,
  '47A1': 12,
  '47A2': 12,
  '47A3': 12,
  '47A4': 12,
  '47A5': 13,
  '47A6': 13,
  '47A7': 13,
  '47A8': 13,
  '47A9': 13,
  '47AA': 13,
  '47AB': 14,
  '47AC': 14,
  '47AD': 14,
  '47AE': 14,
  '47AF': 14,
  '47B0': 14,
  '47B1': 14,
  '47B2': 14,
  '47B3': 15,
  '47B4': 15,
  '47B5': 15,
  '47B6': 15,
  '47B7': 15,
  '47B8': 15,
  '47B9': 16,
  '47BA': 16,
  '47BB': 16,
  '47BC': 16,
  '47BD': 17,
  '47BE': 17,
  '47BF': 17,
  '47C0': 17,
  '47C1': 18,
  '47C2': 18,
  '47C3': 18,
  '47C4': 18,
  '47C5': 18,
  '47C6': 18,
  '47C7': 19,
  '47C8': 20,
  '47C9': 20,
  '47CA': 20,
  '47CB': 20,
  '47CC': 21,
  '47CD': 22,
  '47CE': 22,
  '47CF': 22,
  '47D0': 23,
  '47D1': 24,
  '47D2': 25,
  '47D3': 9,
  '47D4': 9,
  '47D5': 10,
  '47D6': 10,
  '47D7': 11,
  '47D8': 11,
  '47D9': 11,
  '47DA': 11,
  '47DB': 11,
  '47DC': 11,
  '47DD': 11,
  '47DE': 11,
  '47DF': 12,
  '47E0': 12,
  '47E1': 12,
  '47E2': 12,
  '47E3': 12,
  '47E4': 12,
  '47E5': 12,
  '47E6': 12,
  '47E7': 12,
  '47E8': 12,
  '47E9': 12,
  '47EA': 12,
  '47EB': 12,
  '47EC': 12,
  '47ED': 12,
  '47EE': 13,
  '47EF': 13,
  '47F0': 13,
  '47F1': 13,
  '47F2': 13,
  '47F3': 13,
  '47F4': 14,
  '47F5': 14,
  '47F6': 14,
  '47F7': 14,
  '47F8': 14,
  '47F9': 14,
  '47FA': 14,
  '47FB': 14,
  '47FC': 15,
  '47FD': 14,
  '47FE': 15,
  '47FF': 15,
  '4800': 15,
  '4801': 15,
  '4802': 15,
  '4803': 15,
  '4804': 15,
  '4805': 15,
  '4806': 15,
  '4807': 15,
  '4808': 15,
  '4809': 15,
  '480A': 15,
  '480B': 15,
  '480C': 15,
  '480D': 16,
  '480E': 16,
  '480F': 16,
  '4810': 16,
  '4811': 16,
  '4812': 16,
  '4813': 16,
  '4814': 16,
  '4815': 16,
  '4816': 16,
  '4817': 17,
  '4818': 17,
  '4819': 17,
  '481A': 17,
  '481B': 17,
  '481C': 17,
  '481D': 17,
  '481E': 18,
  '481F': 18,
  '4820': 18,
  '4821': 18,
  '4822': 18,
  '4823': 19,
  '4824': 19,
  '4825': 19,
  '4826': 19,
  '4827': 19,
  '4828': 20,
  '4829': 20,
  '482A': 21,
  '482B': 21,
  '482C': 22,
  '482D': 23,
  '482E': 23,
  '482F': 24,
  '4830': 25,
  '4831': 28,
  '4832': 12,
  '4833': 12,
  '4834': 12,
  '4835': 12,
  '4836': 12,
  '4837': 13,
  '4838': 13,
  '4839': 13,
  '483A': 13,
  '483B': 15,
  '483C': 16,
  '483D': 17,
  '483E': 17,
  '483F': 19,
  '4840': 20,
  '4841': 23,
  '4842': 9,
  '4843': 9,
  '4844': 9,
  '4845': 10,
  '4846': 11,
  '4847': 11,
  '4848': 11,
  '4849': 11,
  '484A': 11,
  '484B': 11,
  '484C': 11,
  '484D': 11,
  '484E': 11,
  '484F': 12,
  '4850': 12,
  '4851': 12,
  '4852': 12,
  '4853': 13,
  '4854': 13,
  '4855': 13,
  '4856': 13,
  '4857': 13,
  '4858': 14,
  '4859': 14,
  '485A': 14,
  '485B': 14,
  '485C': 15,
  '485D': 15,
  '485E': 15,
  '485F': 15,
  '4860': 16,
  '4861': 16,
  '4862': 16,
  '4863': 16,
  '4864': 16,
  '4865': 17,
  '4866': 17,
  '4867': 17,
  '4868': 17,
  '4869': 17,
  '486A': 17,
  '486B': 18,
  '486C': 18,
  '486D': 18,
  '486E': 18,
  '486F': 18,
  '4870': 18,
  '4871': 18,
  '4872': 19,
  '4873': 19,
  '4874': 19,
  '4875': 19,
  '4876': 20,
  '4877': 21,
  '4878': 21,
  '4879': 21,
  '487A': 22,
  '487B': 22,
  '487C': 24,
  '487D': 26,
  '487E': 27,
  '487F': 31,
  '4880': 10,
  '4881': 11,
  '4882': 9,
  '4883': 15,
  '4884': 20,
  '4885': 13,
  '4886': 14,
  '4887': 15,
  '4888': 20,
  '4889': 20,
  '488A': 7,
  '488B': 7,
  '488C': 8,
  '488D': 8,
  '488E': 8,
  '488F': 8,
  '4890': 9,
  '4891': 9,
  '4892': 10,
  '4893': 10,
  '4894': 10,
  '4895': 10,
  '4896': 10,
  '4897': 10,
  '4898': 10,
  '4899': 11,
  '489A': 11,
  '489B': 11,
  '489C': 12,
  '489D': 12,
  '489E': 12,
  '489F': 12,
  '48A0': 12,
  '48A1': 13,
  '48A2': 14,
  '48A3': 14,
  '48A4': 14,
  '48A5': 14,
  '48A6': 15,
  '48A7': 15,
  '48A8': 15,
  '48A9': 15,
  '48AA': 16,
  '48AB': 16,
  '48AC': 16,
  '48AD': 16,
  '48AE': 17,
  '48AF': 18,
  '48B0': 18,
  '48B1': 19,
  '48B2': 24,
  '48B3': 5,
  '48B4': 6,
  '48B5': 7,
  '48B6': 7,
  '48B7': 7,
  '48B8': 8,
  '48B9': 8,
  '48BA': 8,
  '48BB': 9,
  '48BC': 9,
  '48BD': 13,
  '48BE': 9,
  '48BF': 9,
  '48C0': 9,
  '48C1': 9,
  '48C2': 9,
  '48C3': 10,
  '48C4': 10,
  '48C5': 10,
  '48C6': 10,
  '48C7': 10,
  '48C8': 14,
  '48C9': 14,
  '48CA': 11,
  '48CB': 11,
  '48CC': 11,
  '48CD': 11,
  '48CE': 11,
  '48CF': 11,
  '48D0': 12,
  '48D1': 12,
  '48D2': 12,
  '48D3': 13,
  '48D4': 13,
  '48D5': 13,
  '48D6': 17,
  '48D7': 13,
  '48D8': 14,
  '48D9': 14,
  '48DA': 14,
  '48DB': 14,
  '48DC': 14,
  '48DD': 15,
  '48DE': 15,
  '48DF': 15,
  '48E0': 15,
  '48E1': 16,
  '48E2': 17,
  '48E3': 23,
  '48E4': 23,
  '48E5': 9,
  '48E6': 9,
  '48E7': 10,
  '48E8': 10,
  '48E9': 11,
  '48EA': 11,
  '48EB': 11,
  '48EC': 11,
  '48ED': 11,
  '48EE': 12,
  '48EF': 12,
  '48F0': 12,
  '48F1': 13,
  '48F2': 12,
  '48F3': 12,
  '48F4': 13,
  '48F5': 13,
  '48F6': 13,
  '48F7': 13,
  '48F8': 13,
  '48F9': 13,
  '48FA': 14,
  '48FB': 14,
  '48FC': 15,
  '48FD': 15,
  '48FE': 15,
  '48FF': 15,
  '4900': 16,
  '4901': 16,
  '4902': 16,
  '4903': 16,
  '4904': 16,
  '4905': 16,
  '4906': 16,
  '4907': 16,
  '4908': 16,
  '4909': 17,
  '490A': 17,
  '490B': 17,
  '490C': 17,
  '490D': 18,
  '490E': 19,
  '490F': 19,
  '4910': 19,
  '4911': 19,
  '4912': 19,
  '4913': 21,
  '4914': 21,
  '4915': 22,
  '4916': 22,
  '4917': 24,
  '4918': 24,
  '4919': 28,
  '491A': 11,
  '491B': 10,
  '491C': 11,
  '491D': 12,
  '491E': 12,
  '491F': 12,
  '4920': 12,
  '4921': 13,
  '4922': 13,
  '4923': 13,
  '4924': 14,
  '4925': 14,
  '4926': 14,
  '4927': 14,
  '4928': 14,
  '4929': 14,
  '492A': 14,
  '492B': 15,
  '492C': 15,
  '492D': 15,
  '492E': 15,
  '492F': 15,
  '4930': 15,
  '4931': 15,
  '4932': 15,
  '4933': 16,
  '4934': 16,
  '4935': 16,
  '4936': 16,
  '4937': 17,
  '4938': 17,
  '4939': 17,
  '493A': 17,
  '493B': 17,
  '493C': 17,
  '493D': 18,
  '493E': 18,
  '493F': 18,
  '4940': 18,
  '4941': 18,
  '4942': 18,
  '4943': 18,
  '4944': 18,
  '4945': 18,
  '4946': 18,
  '4947': 18,
  '4948': 19,
  '4949': 19,
  '494A': 19,
  '494B': 19,
  '494C': 19,
  '494D': 19,
  '494E': 19,
  '494F': 19,
  '4950': 19,
  '4951': 19,
  '4952': 19,
  '4953': 19,
  '4954': 20,
  '4955': 20,
  '4956': 20,
  '4957': 20,
  '4958': 20,
  '4959': 20,
  '495A': 20,
  '495B': 20,
  '495C': 20,
  '495D': 21,
  '495E': 21,
  '495F': 21,
  '4960': 21,
  '4961': 21,
  '4962': 21,
  '4963': 21,
  '4964': 21,
  '4965': 21,
  '4966': 21,
  '4967': 22,
  '4968': 22,
  '4969': 22,
  '496A': 22,
  '496B': 22,
  '496C': 22,
  '496D': 22,
  '496E': 22,
  '496F': 23,
  '4970': 23,
  '4971': 23,
  '4972': 23,
  '4973': 23,
  '4974': 23,
  '4975': 24,
  '4976': 24,
  '4977': 24,
  '4978': 25,
  '4979': 29,
  '497A': 12,
  '497B': 12,
  '497C': 12,
  '497D': 13,
  '497E': 13,
  '497F': 13,
  '4980': 14,
  '4981': 15,
  '4982': 18,
  '4983': 20,
  '4984': 20,
  '4985': 20,
  '4986': 28,
  '4987': 11,
  '4988': 13,
  '4989': 13,
  '498A': 14,
  '498B': 19,
  '498C': 11,
  '498D': 11,
  '498E': 12,
  '498F': 12,
  '4990': 12,
  '4991': 12,
  '4992': 13,
  '4993': 13,
  '4994': 13,
  '4995': 14,
  '4996': 14,
  '4997': 14,
  '4998': 14,
  '4999': 14,
  '499A': 14,
  '499B': 14,
  '499C': 15,
  '499D': 15,
  '499E': 15,
  '499F': 15,
  '49A0': 16,
  '49A1': 16,
  '49A2': 16,
  '49A3': 16,
  '49A4': 16,
  '49A5': 16,
  '49A6': 16,
  '49A7': 16,
  '49A8': 16,
  '49A9': 17,
  '49AA': 17,
  '49AB': 17,
  '49AC': 17,
  '49AD': 17,
  '49AE': 17,
  '49AF': 17,
  '49B0': 19,
  '49B1': 20,
  '49B2': 21,
  '49B3': 21,
  '49B4': 21,
  '49B5': 22,
  '49B6': 14,
  '49B7': 15,
  '49B8': 17,
  '49B9': 5,
  '49BA': 5,
  '49BB': 6,
  '49BC': 7,
  '49BD': 7,
  '49BE': 10,
  '49BF': 7,
  '49C0': 7,
  '49C1': 8,
  '49C2': 8,
  '49C3': 8,
  '49C4': 9,
  '49C5': 9,
  '49C6': 9,
  '49C7': 9,
  '49C8': 9,
  '49C9': 10,
  '49CA': 10,
  '49CB': 10,
  '49CC': 10,
  '49CD': 10,
  '49CE': 10,
  '49CF': 10,
  '49D0': 11,
  '49D1': 11,
  '49D2': 11,
  '49D3': 11,
  '49D4': 11,
  '49D5': 11,
  '49D6': 11,
  '49D7': 12,
  '49D8': 12,
  '49D9': 12,
  '49DA': 13,
  '49DB': 13,
  '49DC': 13,
  '49DD': 13,
  '49DE': 13,
  '49DF': 13,
  '49E0': 14,
  '49E1': 14,
  '49E2': 14,
  '49E3': 14,
  '49E4': 15,
  '49E5': 15,
  '49E6': 15,
  '49E7': 16,
  '49E8': 16,
  '49E9': 15,
  '49EA': 16,
  '49EB': 17,
  '49EC': 17,
  '49ED': 17,
  '49EE': 19,
  '49EF': 20,
  '49F0': 21,
  '49F1': 10,
  '49F2': 11,
  '49F3': 11,
  '49F4': 12,
  '49F5': 12,
  '49F6': 12,
  '49F7': 13,
  '49F8': 13,
  '49F9': 13,
  '49FA': 13,
  '49FB': 14,
  '49FC': 15,
  '49FD': 15,
  '49FE': 16,
  '49FF': 16,
  '4A00': 16,
  '4A01': 17,
  '4A02': 17,
  '4A03': 18,
  '4A04': 19,
  '4A05': 20,
  '4A06': 20,
  '4A07': 20,
  '4A08': 22,
  '4A09': 25,
  '4A0A': 32,
  '4A0B': 11,
  '4A0C': 12,
  '4A0D': 12,
  '4A0E': 13,
  '4A0F': 14,
  '4A10': 14,
  '4A11': 14,
  '4A12': 14,
  '4A13': 14,
  '4A14': 14,
  '4A15': 14,
  '4A16': 14,
  '4A17': 15,
  '4A18': 15,
  '4A19': 15,
  '4A1A': 16,
  '4A1B': 16,
  '4A1C': 16,
  '4A1D': 16,
  '4A1E': 17,
  '4A1F': 17,
  '4A20': 17,
  '4A21': 17,
  '4A22': 17,
  '4A23': 17,
  '4A24': 17,
  '4A25': 18,
  '4A26': 18,
  '4A27': 18,
  '4A28': 18,
  '4A29': 18,
  '4A2A': 18,
  '4A2B': 19,
  '4A2C': 19,
  '4A2D': 19,
  '4A2E': 19,
  '4A2F': 19,
  '4A30': 20,
  '4A31': 20,
  '4A32': 22,
  '4A33': 22,
  '4A34': 22,
  '4A35': 23,
  '4A36': 23,
  '4A37': 24,
  '4A38': 25,
  '4A39': 27,
  '4A3A': 36,
  '4A3B': 52,
  '4A3C': 22,
  '4A3D': 11,
  '4A3E': 11,
  '4A3F': 12,
  '4A40': 15,
  '4A41': 20,
  '4A42': 13,
  '4A43': 13,
  '4A44': 13,
  '4A45': 14,
  '4A46': 14,
  '4A47': 14,
  '4A48': 16,
  '4A49': 16,
  '4A4A': 17,
  '4A4B': 20,
  '4A4C': 21,
  '4A4D': 21,
  '4A4E': 22,
  '4A4F': 24,
  '4A50': 12,
  '4A51': 12,
  '4A52': 12,
  '4A53': 13,
  '4A54': 13,
  '4A55': 13,
  '4A56': 13,
  '4A57': 13,
  '4A58': 13,
  '4A59': 14,
  '4A5A': 14,
  '4A5B': 14,
  '4A5C': 14,
  '4A5D': 14,
  '4A5E': 14,
  '4A5F': 15,
  '4A60': 16,
  '4A61': 16,
  '4A62': 16,
  '4A63': 16,
  '4A64': 16,
  '4A65': 16,
  '4A66': 16,
  '4A67': 16,
  '4A68': 17,
  '4A69': 17,
  '4A6A': 17,
  '4A6B': 17,
  '4A6C': 17,
  '4A6D': 17,
  '4A6E': 17,
  '4A6F': 18,
  '4A70': 18,
  '4A71': 18,
  '4A72': 18,
  '4A73': 18,
  '4A74': 18,
  '4A75': 18,
  '4A76': 19,
  '4A77': 19,
  '4A78': 19,
  '4A79': 19,
  '4A7A': 19,
  '4A7B': 19,
  '4A7C': 20,
  '4A7D': 20,
  '4A7E': 20,
  '4A7F': 21,
  '4A80': 21,
  '4A81': 21,
  '4A82': 21,
  '4A83': 21,
  '4A84': 21,
  '4A85': 22,
  '4A86': 22,
  '4A87': 23,
  '4A88': 23,
  '4A89': 24,
  '4A8A': 25,
  '4A8B': 25,
  '4A8C': 26,
  '4A8D': 26,
  '4A8E': 27,
  '4A8F': 13,
  '4A90': 14,
  '4A91': 14,
  '4A92': 14,
  '4A93': 14,
  '4A94': 16,
  '4A95': 17,
  '4A96': 18,
  '4A97': 18,
  '4A98': 18,
  '4A99': 19,
  '4A9A': 19,
  '4A9B': 21,
  '4A9C': 22,
  '4A9D': 23,
  '4A9E': 13,
  '4A9F': 15,
  '4AA0': 17,
  '4AA1': 19,
  '4AA2': 19,
  '4AA3': 20,
  '4AA4': 21,
  '4AA5': 23,
  '4AA6': 12,
  '4AA7': 12,
  '4AA8': 12,
  '4AA9': 13,
  '4AAA': 14,
  '4AAB': 16,
  '4AAC': 16,
  '4AAD': 18,
  '4AAE': 19,
  '4AAF': 19,
  '4AB0': 22,
  '4AB1': 12,
  '4AB2': 12,
  '4AB3': 13,
  '4AB4': 13,
  '4AB5': 13,
  '4AB6': 14,
  '4AB7': 14,
  '4AB8': 14,
  '4AB9': 14,
  '4ABA': 14,
  '4ABB': 14,
  '4ABC': 14,
  '4ABD': 14,
  '4ABE': 14,
  '4ABF': 15,
  '4AC0': 15,
  '4AC1': 15,
  '4AC2': 15,
  '4AC3': 16,
  '4AC4': 16,
  '4AC5': 16,
  '4AC6': 16,
  '4AC7': 16,
  '4AC8': 16,
  '4AC9': 16,
  '4ACA': 16,
  '4ACB': 17,
  '4ACC': 17,
  '4ACD': 17,
  '4ACE': 17,
  '4ACF': 17,
  '4AD0': 17,
  '4AD1': 17,
  '4AD2': 17,
  '4AD3': 17,
  '4AD4': 18,
  '4AD5': 18,
  '4AD6': 18,
  '4AD7': 18,
  '4AD8': 18,
  '4AD9': 18,
  '4ADA': 18,
  '4ADB': 18,
  '4ADC': 18,
  '4ADD': 18,
  '4ADE': 19,
  '4ADF': 19,
  '4AE0': 19,
  '4AE1': 19,
  '4AE2': 19,
  '4AE3': 19,
  '4AE4': 19,
  '4AE5': 19,
  '4AE6': 19,
  '4AE7': 19,
  '4AE8': 20,
  '4AE9': 20,
  '4AEA': 20,
  '4AEB': 20,
  '4AEC': 21,
  '4AED': 21,
  '4AEE': 21,
  '4AEF': 21,
  '4AF0': 21,
  '4AF1': 21,
  '4AF2': 22,
  '4AF3': 22,
  '4AF4': 22,
  '4AF5': 24,
  '4AF6': 24,
  '4AF7': 25,
  '4AF8': 11,
  '4AF9': 12,
  '4AFA': 13,
  '4AFB': 13,
  '4AFC': 13,
  '4AFD': 13,
  '4AFE': 14,
  '4AFF': 14,
  '4B00': 14,
  '4B01': 14,
  '4B02': 14,
  '4B03': 14,
  '4B04': 15,
  '4B05': 15,
  '4B06': 16,
  '4B07': 16,
  '4B08': 16,
  '4B09': 16,
  '4B0A': 16,
  '4B0B': 17,
  '4B0C': 17,
  '4B0D': 17,
  '4B0E': 17,
  '4B0F': 17,
  '4B10': 17,
  '4B11': 18,
  '4B12': 18,
  '4B13': 18,
  '4B14': 18,
  '4B15': 18,
  '4B16': 18,
  '4B17': 18,
  '4B18': 19,
  '4B19': 19,
  '4B1A': 19,
  '4B1B': 20,
  '4B1C': 20,
  '4B1D': 21,
  '4B1E': 23,
  '4B1F': 24,
  '4B20': 17,
  '4B21': 18,
  '4B22': 11,
  '4B23': 12,
  '4B24': 12,
  '4B25': 12,
  '4B26': 13,
  '4B27': 13,
  '4B28': 13,
  '4B29': 13,
  '4B2A': 13,
  '4B2B': 14,
  '4B2C': 14,
  '4B2D': 14,
  '4B2E': 14,
  '4B2F': 14,
  '4B30': 14,
  '4B31': 14,
  '4B32': 14,
  '4B33': 14,
  '4B34': 14,
  '4B35': 15,
  '4B36': 15,
  '4B37': 15,
  '4B38': 15,
  '4B39': 15,
  '4B3A': 15,
  '4B3B': 15,
  '4B3C': 16,
  '4B3D': 16,
  '4B3E': 16,
  '4B3F': 16,
  '4B40': 16,
  '4B41': 16,
  '4B42': 16,
  '4B43': 17,
  '4B44': 17,
  '4B45': 17,
  '4B46': 17,
  '4B47': 17,
  '4B48': 18,
  '4B49': 18,
  '4B4A': 18,
  '4B4B': 18,
  '4B4C': 18,
  '4B4D': 18,
  '4B4E': 18,
  '4B4F': 18,
  '4B50': 19,
  '4B51': 19,
  '4B52': 19,
  '4B53': 19,
  '4B54': 19,
  '4B55': 20,
  '4B56': 20,
  '4B57': 20,
  '4B58': 21,
  '4B59': 21,
  '4B5A': 21,
  '4B5B': 21,
  '4B5C': 21,
  '4B5D': 22,
  '4B5E': 22,
  '4B5F': 22,
  '4B60': 22,
  '4B61': 23,
  '4B62': 23,
  '4B63': 23,
  '4B64': 23,
  '4B65': 24,
  '4B66': 26,
  '4B67': 26,
  '4B68': 27,
  '4B69': 28,
  '4B6A': 21,
  '4B6B': 15,
  '4B6C': 15,
  '4B6D': 18,
  '4B6E': 19,
  '4B6F': 14,
  '4B70': 17,
  '4B71': 17,
  '4B72': 17,
  '4B73': 27,
  '4B74': 12,
  '4B75': 13,
  '4B76': 13,
  '4B77': 14,
  '4B78': 14,
  '4B79': 14,
  '4B7A': 14,
  '4B7B': 14,
  '4B7C': 14,
  '4B7D': 14,
  '4B7E': 14,
  '4B7F': 15,
  '4B80': 15,
  '4B81': 15,
  '4B82': 15,
  '4B83': 15,
  '4B84': 15,
  '4B85': 15,
  '4B86': 16,
  '4B87': 16,
  '4B88': 16,
  '4B89': 16,
  '4B8A': 16,
  '4B8B': 16,
  '4B8C': 16,
  '4B8D': 16,
  '4B8E': 17,
  '4B8F': 17,
  '4B90': 17,
  '4B91': 17,
  '4B92': 17,
  '4B93': 18,
  '4B94': 18,
  '4B95': 18,
  '4B96': 18,
  '4B97': 18,
  '4B98': 18,
  '4B99': 18,
  '4B9A': 18,
  '4B9B': 18,
  '4B9C': 19,
  '4B9D': 19,
  '4B9E': 19,
  '4B9F': 19,
  '4BA0': 19,
  '4BA1': 19,
  '4BA2': 19,
  '4BA3': 20,
  '4BA4': 20,
  '4BA5': 20,
  '4BA6': 20,
  '4BA7': 20,
  '4BA8': 20,
  '4BA9': 20,
  '4BAA': 21,
  '4BAB': 21,
  '4BAC': 21,
  '4BAD': 21,
  '4BAE': 21,
  '4BAF': 21,
  '4BB0': 21,
  '4BB1': 21,
  '4BB2': 22,
  '4BB3': 22,
  '4BB4': 22,
  '4BB5': 22,
  '4BB6': 22,
  '4BB7': 23,
  '4BB8': 23,
  '4BB9': 23,
  '4BBA': 24,
  '4BBB': 24,
  '4BBC': 24,
  '4BBD': 25,
  '4BBE': 26,
  '4BBF': 27,
  '4BC0': 28,
  '4BC1': 29,
  '4BC2': 34,
  '4BC3': 16,
  '4BC4': 17,
  '4BC5': 20,
  '4BC6': 11,
  '4BC7': 12,
  '4BC8': 14,
  '4BC9': 14,
  '4BCA': 15,
  '4BCB': 15,
  '4BCC': 15,
  '4BCD': 15,
  '4BCE': 15,
  '4BCF': 16,
  '4BD0': 16,
  '4BD1': 16,
  '4BD2': 16,
  '4BD3': 16,
  '4BD4': 16,
  '4BD5': 17,
  '4BD6': 17,
  '4BD7': 17,
  '4BD8': 17,
  '4BD9': 17,
  '4BDA': 17,
  '4BDB': 18,
  '4BDC': 18,
  '4BDD': 19,
  '4BDE': 19,
  '4BDF': 19,
  '4BE0': 19,
  '4BE1': 20,
  '4BE2': 21,
  '4BE3': 22,
  '4BE4': 23,
  '4BE5': 24,
  '4BE6': 25,
  '4BE7': 12,
  '4BE8': 13,
  '4BE9': 14,
  '4BEA': 19,
  '4BEB': 22,
  '4BEC': 25,
  '4BED': 12,
  '4BEE': 12,
  '4BEF': 14,
  '4BF0': 14,
  '4BF1': 14,
  '4BF2': 14,
  '4BF3': 14,
  '4BF4': 14,
  '4BF5': 15,
  '4BF6': 15,
  '4BF7': 16,
  '4BF8': 16,
  '4BF9': 17,
  '4BFA': 17,
  '4BFB': 17,
  '4BFC': 17,
  '4BFD': 18,
  '4BFE': 18,
  '4BFF': 18,
  '4C00': 18,
  '4C01': 18,
  '4C02': 18,
  '4C03': 18,
  '4C04': 19,
  '4C05': 19,
  '4C06': 19,
  '4C07': 19,
  '4C08': 20,
  '4C09': 20,
  '4C0A': 20,
  '4C0B': 20,
  '4C0C': 21,
  '4C0D': 21,
  '4C0E': 22,
  '4C0F': 24,
  '4C10': 24,
  '4C11': 24,
  '4C12': 24,
  '4C13': 25,
  '4C14': 25,
  '4C15': 26,
  '4C16': 29,
  '4C17': 20,
  '4C18': 21,
  '4C19': 14,
  '4C1A': 14,
  '4C1B': 15,
  '4C1C': 16,
  '4C1D': 22,
  '4C1E': 25,
  '4C1F': 14,
  '4C20': 15,
  '4C21': 15,
  '4C22': 16,
  '4C23': 16,
  '4C24': 18,
  '4C25': 18,
  '4C26': 18,
  '4C27': 18,
  '4C28': 19,
  '4C29': 19,
  '4C2A': 20,
  '4C2B': 22,
  '4C2C': 22,
  '4C2D': 22,
  '4C2E': 23,
  '4C2F': 24,
  '4C30': 24,
  '4C31': 34,
  '4C32': 12,
  '4C33': 13,
  '4C34': 14,
  '4C35': 14,
  '4C36': 14,
  '4C37': 15,
  '4C38': 15,
  '4C39': 15,
  '4C3A': 15,
  '4C3B': 15,
  '4C3C': 15,
  '4C3D': 15,
  '4C3E': 15,
  '4C3F': 16,
  '4C40': 16,
  '4C41': 16,
  '4C42': 16,
  '4C43': 16,
  '4C44': 16,
  '4C45': 16,
  '4C46': 16,
  '4C47': 16,
  '4C48': 16,
  '4C49': 16,
  '4C4A': 17,
  '4C4B': 17,
  '4C4C': 17,
  '4C4D': 17,
  '4C4E': 17,
  '4C4F': 18,
  '4C50': 18,
  '4C51': 18,
  '4C52': 18,
  '4C53': 18,
  '4C54': 18,
  '4C55': 18,
  '4C56': 18,
  '4C57': 18,
  '4C58': 18,
  '4C59': 19,
  '4C5A': 19,
  '4C5B': 19,
  '4C5C': 19,
  '4C5D': 19,
  '4C5E': 19,
  '4C5F': 19,
  '4C60': 19,
  '4C61': 19,
  '4C62': 19,
  '4C63': 19,
  '4C64': 19,
  '4C65': 19,
  '4C66': 19,
  '4C67': 19,
  '4C68': 19,
  '4C69': 19,
  '4C6A': 19,
  '4C6B': 20,
  '4C6C': 20,
  '4C6D': 20,
  '4C6E': 20,
  '4C6F': 20,
  '4C70': 20,
  '4C71': 20,
  '4C72': 20,
  '4C73': 20,
  '4C74': 20,
  '4C75': 21,
  '4C76': 21,
  '4C77': 21,
  '4C78': 21,
  '4C79': 21,
  '4C7A': 21,
  '4C7B': 21,
  '4C7C': 21,
  '4C7D': 21,
  '4C7E': 22,
  '4C7F': 22,
  '4C80': 22,
  '4C81': 22,
  '4C82': 22,
  '4C83': 22,
  '4C84': 22,
  '4C85': 22,
  '4C86': 22,
  '4C87': 22,
  '4C88': 22,
  '4C89': 23,
  '4C8A': 23,
  '4C8B': 23,
  '4C8C': 23,
  '4C8D': 23,
  '4C8E': 23,
  '4C8F': 23,
  '4C90': 24,
  '4C91': 24,
  '4C92': 24,
  '4C93': 24,
  '4C94': 24,
  '4C95': 24,
  '4C96': 25,
  '4C97': 25,
  '4C98': 25,
  '4C99': 26,
  '4C9A': 27,
  '4C9B': 27,
  '4C9C': 44,
  '4C9D': 15,
  '4C9E': 16,
  '4C9F': 16,
  '4CA0': 20,
  '4CA1': 20,
  '4CA2': 21,
  '4CA3': 21,
  '4CA4': 21,
  '4CA5': 13,
  '4CA6': 14,
  '4CA7': 14,
  '4CA8': 14,
  '4CA9': 14,
  '4CAA': 14,
  '4CAB': 14,
  '4CAC': 15,
  '4CAD': 15,
  '4CAE': 15,
  '4CAF': 15,
  '4CB0': 15,
  '4CB1': 15,
  '4CB2': 15,
  '4CB3': 15,
  '4CB4': 15,
  '4CB5': 15,
  '4CB6': 15,
  '4CB7': 15,
  '4CB8': 15,
  '4CB9': 16,
  '4CBA': 16,
  '4CBB': 16,
  '4CBC': 16,
  '4CBD': 16,
  '4CBE': 16,
  '4CBF': 16,
  '4CC0': 16,
  '4CC1': 16,
  '4CC2': 16,
  '4CC3': 16,
  '4CC4': 16,
  '4CC5': 16,
  '4CC6': 16,
  '4CC7': 16,
  '4CC8': 16,
  '4CC9': 16,
  '4CCA': 16,
  '4CCB': 17,
  '4CCC': 17,
  '4CCD': 17,
  '4CCE': 17,
  '4CCF': 17,
  '4CD0': 17,
  '4CD1': 17,
  '4CD2': 17,
  '4CD3': 17,
  '4CD4': 17,
  '4CD5': 18,
  '4CD6': 18,
  '4CD7': 18,
  '4CD8': 18,
  '4CD9': 18,
  '4CDA': 18,
  '4CDB': 18,
  '4CDC': 18,
  '4CDD': 19,
  '4CDE': 19,
  '4CDF': 19,
  '4CE0': 19,
  '4CE1': 19,
  '4CE2': 19,
  '4CE3': 19,
  '4CE4': 19,
  '4CE5': 19,
  '4CE6': 20,
  '4CE7': 20,
  '4CE8': 20,
  '4CE9': 20,
  '4CEA': 20,
  '4CEB': 20,
  '4CEC': 20,
  '4CED': 20,
  '4CEE': 20,
  '4CEF': 20,
  '4CF0': 20,
  '4CF1': 21,
  '4CF2': 21,
  '4CF3': 21,
  '4CF4': 21,
  '4CF5': 21,
  '4CF6': 21,
  '4CF7': 22,
  '4CF8': 22,
  '4CF9': 22,
  '4CFA': 22,
  '4CFB': 22,
  '4CFC': 22,
  '4CFD': 22,
  '4CFE': 23,
  '4CFF': 23,
  '4D00': 23,
  '4D01': 23,
  '4D02': 23,
  '4D03': 23,
  '4D04': 23,
  '4D05': 23,
  '4D06': 23,
  '4D07': 24,
  '4D08': 24,
  '4D09': 24,
  '4D0A': 24,
  '4D0B': 24,
  '4D0C': 25,
  '4D0D': 25,
  '4D0E': 26,
  '4D0F': 27,
  '4D10': 31,
  '4D11': 34,
  '4D12': 35,
  '4D13': 15,
  '4D14': 17,
  '4D15': 17,
  '4D16': 19,
  '4D17': 20,
  '4D18': 21,
  '4D19': 24,
  '4D1A': 15,
  '4D1B': 18,
  '4D1C': 21,
  '4D1D': 23,
  '4D1E': 25,
  '4D1F': 14,
  '4D20': 15,
  '4D21': 15,
  '4D22': 15,
  '4D23': 16,
  '4D24': 16,
  '4D25': 16,
  '4D26': 18,
  '4D27': 19,
  '4D28': 21,
  '4D29': 22,
  '4D2A': 24,
  '4D2B': 24,
  '4D2C': 14,
  '4D2D': 14,
  '4D2E': 14,
  '4D2F': 15,
  '4D30': 15,
  '4D31': 16,
  '4D32': 16,
  '4D33': 16,
  '4D34': 16,
  '4D35': 17,
  '4D36': 18,
  '4D37': 18,
  '4D38': 18,
  '4D39': 19,
  '4D3A': 19,
  '4D3B': 19,
  '4D3C': 19,
  '4D3D': 19,
  '4D3E': 21,
  '4D3F': 21,
  '4D40': 21,
  '4D41': 22,
  '4D42': 22,
  '4D43': 23,
  '4D44': 24,
  '4D45': 22,
  '4D46': 25,
  '4D47': 15,
  '4D48': 20,
  '4D49': 20,
  '4D4A': 16,
  '4D4B': 18,
  '4D4C': 19,
  '4D4D': 20,
  '4D4E': 21,
  '4D4F': 23,
  '4D50': 24,
  '4D51': 15,
  '4D52': 16,
  '4D53': 16,
  '4D54': 20,
  '4D55': 20,
  '4D56': 20,
  '4D57': 21,
  '4D58': 21,
  '4D59': 21,
  '4D5A': 22,
  '4D5B': 23,
  '4D5C': 25,
  '4D5D': 13,
  '4D5E': 14,
  '4D5F': 15,
  '4D60': 17,
  '4D61': 16,
  '4D62': 17,
  '4D63': 17,
  '4D64': 18,
  '4D65': 18,
  '4D66': 18,
  '4D67': 18,
  '4D68': 19,
  '4D69': 19,
  '4D6A': 20,
  '4D6B': 20,
  '4D6C': 20,
  '4D6D': 21,
  '4D6E': 21,
  '4D6F': 21,
  '4D70': 23,
  '4D71': 24,
  '4D72': 25,
  '4D73': 25,
  '4D74': 25,
  '4D75': 26,
  '4D76': 18,
  '4D77': 19,
  '4D78': 22,
  '4D79': 24,
  '4D7A': 16,
  '4D7B': 24,
  '4D7C': 24,
  '4D7D': 18,
  '4D7E': 18,
  '4D7F': 18,
  '4D80': 19,
  '4D81': 21,
  '4D82': 16,
  '4D83': 17,
  '4D84': 18,
  '4D85': 19,
  '4D86': 21,
  '4D87': 22,
  '4D88': 23,
  '4D89': 23,
  '4D8A': 18,
  '4D8B': 18,
  '4D8C': 19,
  '4D8D': 20,
  '4D8E': 20,
  '4D8F': 21,
  '4D90': 27,
  '4D91': 30,
  '4D92': 17,
  '4D93': 17,
  '4D94': 18,
  '4D95': 19,
  '4D96': 19,
  '4D97': 20,
  '4D98': 20,
  '4D99': 20,
  '4D9A': 21,
  '4D9B': 21,
  '4D9C': 22,
  '4D9D': 22,
  '4D9E': 23,
  '4D9F': 23,
  '4DA0': 24,
  '4DA1': 24,
  '4DA2': 24,
  '4DA3': 25,
  '4DA4': 25,
  '4DA5': 26,
  '4DA6': 26,
  '4DA7': 27,
  '4DA8': 28,
  '4DA9': 29,
  '4DAA': 29,
  '4DAB': 35,
  '4DAC': 22,
  '4DAD': 20,
  '4DAE': 20,
  '4DAF': 19,
  '4DB0': 20,
  '4DB1': 21,
  '4DB2': 21,
  '4DB3': 21,
  '4DB4': 25,
  '4DB5': 27,
  '4E00': 1,
  '4E01': 2,
  '4E02': 2,
  '4E03': 2,
  '4E04': 2,
  '4E05': 2,
  '4E06': 2,
  '4E07': 3,
  '4E08': 3,
  '4E09': 3,
  '4E0A': 3,
  '4E0B': 3,
  '4E0C': 3,
  '4E0D': 4,
  '4E0E': 3,
  '4E0F': 4,
  '4E10': 4,
  '4E11': 4,
  '4E12': 4,
  '4E13': 4,
  '4E14': 5,
  '4E15': 5,
  '4E16': 5,
  '4E17': 5,
  '4E18': 5,
  '4E19': 5,
  '4E1A': 5,
  '4E1B': 5,
  '4E1C': 5,
  '4E1D': 5,
  '4E1E': 6,
  '4E1F': 6,
  '4E20': 6,
  '4E21': 6,
  '4E22': 6,
  '4E23': 7,
  '4E24': 7,
  '4E25': 7,
  '4E26': 8,
  '4E27': 8,
  '4E28': 1,
  '4E29': 2,
  '4E2A': 3,
  '4E2B': 3,
  '4E2C': 3,
  '4E2D': 4,
  '4E2E': 4,
  '4E2F': 4,
  '4E30': 4,
  '4E31': 5,
  '4E32': 7,
  '4E33': 8,
  '4E34': 9,
  '4E35': 10,
  '4E36': 1,
  '4E37': 2,
  '4E38': 3,
  '4E39': 4,
  '4E3A': 4,
  '4E3B': 5,
  '4E3C': 5,
  '4E3D': 7,
  '4E3E': 9,
  '4E3F': 1,
  '4E40': 1,
  '4E41': 1,
  '4E42': 2,
  '4E43': 2,
  '4E44': 2,
  '4E45': 3,
  '4E46': 3,
  '4E47': 3,
  '4E48': 3,
  '4E49': 3,
  '4E4A': 3,
  '4E4B': 3,
  '4E4C': 4,
  '4E4D': 5,
  '4E4E': 5,
  '4E4F': 4,
  '4E50': 5,
  '4E51': 6,
  '4E52': 6,
  '4E53': 6,
  '4E54': 6,
  '4E55': 7,
  '4E56': 8,
  '4E57': 9,
  '4E58': 10,
  '4E59': 1,
  '4E5A': 1,
  '4E5B': 1,
  '4E5C': 2,
  '4E5D': 2,
  '4E5E': 3,
  '4E5F': 3,
  '4E60': 3,
  '4E61': 3,
  '4E62': 4,
  '4E63': 4,
  '4E64': 4,
  '4E65': 4,
  '4E66': 4,
  '4E67': 5,
  '4E68': 6,
  '4E69': 6,
  '4E6A': 6,
  '4E6B': 6,
  '4E6C': 6,
  '4E6D': 6,
  '4E6E': 6,
  '4E6F': 6,
  '4E70': 6,
  '4E71': 7,
  '4E72': 7,
  '4E73': 8,
  '4E74': 8,
  '4E75': 8,
  '4E76': 8,
  '4E77': 8,
  '4E78': 8,
  '4E79': 9,
  '4E7A': 9,
  '4E7B': 9,
  '4E7C': 9,
  '4E7D': 10,
  '4E7E': 11,
  '4E7F': 11,
  '4E80': 11,
  '4E81': 12,
  '4E82': 13,
  '4E83': 13,
  '4E84': 13,
  '4E85': 1,
  '4E86': 2,
  '4E87': 3,
  '4E88': 4,
  '4E89': 6,
  '4E8A': 7,
  '4E8B': 8,
  '4E8C': 2,
  '4E8D': 3,
  '4E8E': 3,
  '4E8F': 3,
  '4E90': 3,
  '4E91': 4,
  '4E92': 4,
  '4E93': 4,
  '4E94': 4,
  '4E95': 4,
  '4E96': 4,
  '4E97': 5,
  '4E98': 6,
  '4E99': 6,
  '4E9A': 6,
  '4E9B': 8,
  '4E9C': 7,
  '4E9D': 8,
  '4E9E': 8,
  '4E9F': 8,
  '4EA0': 2,
  '4EA1': 3,
  '4EA2': 4,
  '4EA3': 4,
  '4EA4': 6,
  '4EA5': 6,
  '4EA6': 6,
  '4EA7': 6,
  '4EA8': 7,
  '4EA9': 7,
  '4EAA': 7,
  '4EAB': 8,
  '4EAC': 8,
  '4EAD': 9,
  '4EAE': 9,
  '4EAF': 9,
  '4EB0': 9,
  '4EB1': 9,
  '4EB2': 9,
  '4EB3': 10,
  '4EB4': 12,
  '4EB5': 12,
  '4EB6': 13,
  '4EB7': 13,
  '4EB8': 16,
  '4EB9': 22,
  '4EBA': 2,
  '4EBB': 2,
  '4EBC': 3,
  '4EBD': 3,
  '4EBE': 3,
  '4EBF': 3,
  '4EC0': 4,
  '4EC1': 4,
  '4EC2': 4,
  '4EC3': 4,
  '4EC4': 4,
  '4EC5': 4,
  '4EC6': 4,
  '4EC7': 4,
  '4EC8': 4,
  '4EC9': 4,
  '4ECA': 4,
  '4ECB': 4,
  '4ECC': 4,
  '4ECD': 4,
  '4ECE': 4,
  '4ECF': 4,
  '4ED0': 4,
  '4ED1': 4,
  '4ED2': 4,
  '4ED3': 4,
  '4ED4': 5,
  '4ED5': 5,
  '4ED6': 5,
  '4ED7': 5,
  '4ED8': 5,
  '4ED9': 5,
  '4EDA': 5,
  '4EDB': 5,
  '4EDC': 5,
  '4EDD': 5,
  '4EDE': 5,
  '4EDF': 5,
  '4EE0': 5,
  '4EE1': 5,
  '4EE2': 5,
  '4EE3': 5,
  '4EE4': 5,
  '4EE5': 4,
  '4EE6': 5,
  '4EE7': 5,
  '4EE8': 5,
  '4EE9': 5,
  '4EEA': 5,
  '4EEB': 5,
  '4EEC': 5,
  '4EED': 5,
  '4EEE': 6,
  '4EEF': 6,
  '4EF0': 6,
  '4EF1': 6,
  '4EF2': 6,
  '4EF3': 6,
  '4EF4': 6,
  '4EF5': 6,
  '4EF6': 6,
  '4EF7': 6,
  '4EF8': 6,
  '4EF9': 6,
  '4EFA': 5,
  '4EFB': 6,
  '4EFC': 6,
  '4EFD': 6,
  '4EFE': 6,
  '4EFF': 6,
  '4F00': 6,
  '4F01': 6,
  '4F02': 6,
  '4F03': 6,
  '4F04': 6,
  '4F05': 6,
  '4F06': 6,
  '4F07': 6,
  '4F08': 6,
  '4F09': 6,
  '4F0A': 6,
  '4F0B': 5,
  '4F0C': 6,
  '4F0D': 6,
  '4F0E': 6,
  '4F0F': 6,
  '4F10': 6,
  '4F11': 6,
  '4F12': 6,
  '4F13': 6,
  '4F14': 6,
  '4F15': 6,
  '4F16': 6,
  '4F17': 6,
  '4F18': 6,
  '4F19': 6,
  '4F1A': 6,
  '4F1B': 6,
  '4F1C': 6,
  '4F1D': 6,
  '4F1E': 6,
  '4F1F': 6,
  '4F20': 6,
  '4F21': 6,
  '4F22': 6,
  '4F23': 6,
  '4F24': 6,
  '4F25': 6,
  '4F26': 6,
  '4F27': 6,
  '4F28': 6,
  '4F29': 6,
  '4F2A': 6,
  '4F2B': 6,
  '4F2C': 6,
  '4F2D': 7,
  '4F2E': 7,
  '4F2F': 7,
  '4F30': 7,
  '4F31': 7,
  '4F32': 7,
  '4F33': 7,
  '4F34': 7,
  '4F35': 7,
  '4F36': 7,
  '4F37': 7,
  '4F38': 7,
  '4F39': 7,
  '4F3A': 7,
  '4F3B': 7,
  '4F3C': 6,
  '4F3D': 7,
  '4F3E': 7,
  '4F3F': 7,
  '4F40': 7,
  '4F41': 7,
  '4F42': 7,
  '4F43': 7,
  '4F44': 7,
  '4F45': 7,
  '4F46': 7,
  '4F47': 7,
  '4F48': 7,
  '4F49': 7,
  '4F4A': 7,
  '4F4B': 7,
  '4F4C': 8,
  '4F4D': 7,
  '4F4E': 7,
  '4F4F': 7,
  '4F50': 7,
  '4F51': 7,
  '4F52': 7,
  '4F53': 7,
  '4F54': 7,
  '4F55': 7,
  '4F56': 7,
  '4F57': 7,
  '4F58': 7,
  '4F59': 7,
  '4F5A': 7,
  '4F5B': 7,
  '4F5C': 7,
  '4F5D': 7,
  '4F5E': 7,
  '4F5F': 7,
  '4F60': 7,
  '4F61': 7,
  '4F62': 6,
  '4F63': 7,
  '4F64': 6,
  '4F65': 7,
  '4F66': 7,
  '4F67': 7,
  '4F68': 7,
  '4F69': 8,
  '4F6A': 8,
  '4F6B': 8,
  '4F6C': 8,
  '4F6D': 8,
  '4F6E': 8,
  '4F6F': 8,
  '4F70': 8,
  '4F71': 8,
  '4F72': 8,
  '4F73': 8,
  '4F74': 8,
  '4F75': 8,
  '4F76': 8,
  '4F77': 8,
  '4F78': 8,
  '4F79': 8,
  '4F7A': 8,
  '4F7B': 8,
  '4F7C': 8,
  '4F7D': 8,
  '4F7E': 8,
  '4F7F': 8,
  '4F80': 8,
  '4F81': 8,
  '4F82': 8,
  '4F83': 8,
  '4F84': 8,
  '4F85': 8,
  '4F86': 8,
  '4F87': 8,
  '4F88': 8,
  '4F89': 8,
  '4F8A': 8,
  '4F8B': 8,
  '4F8C': 8,
  '4F8D': 8,
  '4F8E': 8,
  '4F8F': 8,
  '4F90': 8,
  '4F91': 8,
  '4F92': 8,
  '4F93': 8,
  '4F94': 8,
  '4F95': 8,
  '4F96': 8,
  '4F97': 8,
  '4F98': 8,
  '4F99': 8,
  '4F9A': 8,
  '4F9B': 8,
  '4F9C': 8,
  '4F9D': 8,
  '4F9E': 8,
  '4F9F': 8,
  '4FA0': 8,
  '4FA1': 8,
  '4FA2': 8,
  '4FA3': 8,
  '4FA4': 8,
  '4FA5': 8,
  '4FA6': 8,
  '4FA7': 8,
  '4FA8': 8,
  '4FA9': 8,
  '4FAA': 8,
  '4FAB': 8,
  '4FAC': 8,
  '4FAD': 8,
  '4FAE': 9,
  '4FAF': 9,
  '4FB0': 9,
  '4FB1': 9,
  '4FB2': 9,
  '4FB3': 9,
  '4FB4': 9,
  '4FB5': 9,
  '4FB6': 9,
  '4FB7': 9,
  '4FB8': 9,
  '4FB9': 8,
  '4FBA': 9,
  '4FBB': 9,
  '4FBC': 9,
  '4FBD': 9,
  '4FBE': 9,
  '4FBF': 9,
  '4FC0': 9,
  '4FC1': 9,
  '4FC2': 9,
  '4FC3': 9,
  '4FC4': 9,
  '4FC5': 9,
  '4FC6': 9,
  '4FC7': 9,
  '4FC8': 9,
  '4FC9': 9,
  '4FCA': 9,
  '4FCB': 9,
  '4FCC': 9,
  '4FCD': 9,
  '4FCE': 9,
  '4FCF': 9,
  '4FD0': 9,
  '4FD1': 9,
  '4FD2': 9,
  '4FD3': 9,
  '4FD4': 9,
  '4FD5': 9,
  '4FD6': 9,
  '4FD7': 9,
  '4FD8': 9,
  '4FD9': 9,
  '4FDA': 9,
  '4FDB': 9,
  '4FDC': 9,
  '4FDD': 9,
  '4FDE': 9,
  '4FDF': 9,
  '4FE0': 9,
  '4FE1': 9,
  '4FE2': 8,
  '4FE3': 9,
  '4FE4': 9,
  '4FE5': 9,
  '4FE6': 9,
  '4FE7': 9,
  '4FE8': 9,
  '4FE9': 9,
  '4FEA': 9,
  '4FEB': 9,
  '4FEC': 9,
  '4FED': 9,
  '4FEE': 9,
  '4FEF': 10,
  '4FF0': 10,
  '4FF1': 10,
  '4FF2': 10,
  '4FF3': 10,
  '4FF4': 10,
  '4FF5': 10,
  '4FF6': 10,
  '4FF7': 10,
  '4FF8': 10,
  '4FF9': 10,
  '4FFA': 10,
  '4FFB': 10,
  '4FFC': 10,
  '4FFD': 10,
  '4FFE': 10,
  '4FFF': 10,
  '5000': 10,
  '5001': 10,
  '5002': 10,
  '5003': 10,
  '5004': 10,
  '5005': 10,
  '5006': 10,
  '5007': 10,
  '5008': 10,
  '5009': 10,
  '500A': 10,
  '500B': 10,
  '500C': 10,
  '500D': 10,
  '500E': 10,
  '500F': 10,
  '5010': 10,
  '5011': 10,
  '5012': 10,
  '5013': 10,
  '5014': 10,
  '5015': 10,
  '5016': 10,
  '5017': 10,
  '5018': 10,
  '5019': 10,
  '501A': 10,
  '501B': 10,
  '501C': 10,
  '501D': 10,
  '501E': 10,
  '501F': 10,
  '5020': 10,
  '5021': 10,
  '5022': 10,
  '5023': 10,
  '5024': 10,
  '5025': 10,
  '5026': 10,
  '5027': 10,
  '5028': 10,
  '5029': 10,
  '502A': 10,
  '502B': 10,
  '502C': 10,
  '502D': 10,
  '502E': 10,
  '502F': 10,
  '5030': 10,
  '5031': 10,
  '5032': 10,
  '5033': 10,
  '5034': 10,
  '5035': 10,
  '5036': 10,
  '5037': 10,
  '5038': 10,
  '5039': 10,
  '503A': 10,
  '503B': 10,
  '503C': 10,
  '503D': 10,
  '503E': 10,
  '503F': 10,
  '5040': 10,
  '5041': 11,
  '5042': 11,
  '5043': 11,
  '5044': 11,
  '5045': 11,
  '5046': 11,
  '5047': 11,
  '5048': 11,
  '5049': 11,
  '504A': 11,
  '504B': 11,
  '504C': 10,
  '504D': 11,
  '504E': 11,
  '504F': 11,
  '5050': 11,
  '5051': 11,
  '5052': 11,
  '5053': 11,
  '5054': 11,
  '5055': 11,
  '5056': 10,
  '5057': 11,
  '5058': 11,
  '5059': 11,
  '505A': 11,
  '505B': 11,
  '505C': 11,
  '505D': 11,
  '505E': 11,
  '505F': 11,
  '5060': 11,
  '5061': 11,
  '5062': 11,
  '5063': 11,
  '5064': 11,
  '5065': 10,
  '5066': 11,
  '5067': 11,
  '5068': 12,
  '5069': 11,
  '506A': 11,
  '506B': 11,
  '506C': 11,
  '506D': 11,
  '506E': 11,
  '506F': 11,
  '5070': 11,
  '5071': 11,
  '5072': 11,
  '5073': 11,
  '5074': 11,
  '5075': 11,
  '5076': 11,
  '5077': 11,
  '5078': 11,
  '5079': 11,
  '507A': 11,
  '507B': 11,
  '507C': 11,
  '507D': 11,
  '507E': 11,
  '507F': 11,
  '5080': 11,
  '5081': 11,
  '5082': 12,
  '5083': 12,
  '5084': 12,
  '5085': 12,
  '5086': 12,
  '5087': 11,
  '5088': 12,
  '5089': 12,
  '508A': 12,
  '508B': 12,
  '508C': 12,
  '508D': 12,
  '508E': 12,
  '508F': 12,
  '5090': 12,
  '5091': 12,
  '5092': 12,
  '5093': 12,
  '5094': 12,
  '5095': 12,
  '5096': 12,
  '5097': 12,
  '5098': 12,
  '5099': 12,
  '509A': 12,
  '509B': 12,
  '509C': 12,
  '509D': 12,
  '509E': 11,
  '509F': 12,
  '50A0': 12,
  '50A1': 12,
  '50A2': 12,
  '50A3': 12,
  '50A4': 12,
  '50A5': 12,
  '50A6': 11,
  '50A7': 12,
  '50A8': 12,
  '50A9': 12,
  '50AA': 13,
  '50AB': 13,
  '50AC': 13,
  '50AD': 13,
  '50AE': 13,
  '50AF': 13,
  '50B0': 13,
  '50B1': 13,
  '50B2': 12,
  '50B3': 13,
  '50B4': 13,
  '50B5': 13,
  '50B6': 13,
  '50B7': 13,
  '50B8': 13,
  '50B9': 13,
  '50BA': 13,
  '50BB': 13,
  '50BC': 13,
  '50BD': 13,
  '50BE': 13,
  '50BF': 13,
  '50C0': 13,
  '50C1': 13,
  '50C2': 13,
  '50C3': 12,
  '50C4': 13,
  '50C5': 13,
  '50C6': 12,
  '50C7': 13,
  '50C8': 13,
  '50C9': 13,
  '50CA': 14,
  '50CB': 13,
  '50CC': 13,
  '50CD': 13,
  '50CE': 14,
  '50CF': 13,
  '50D0': 14,
  '50D1': 14,
  '50D2': 14,
  '50D3': 14,
  '50D4': 14,
  '50D5': 14,
  '50D6': 14,
  '50D7': 14,
  '50D8': 14,
  '50D9': 13,
  '50DA': 14,
  '50DB': 14,
  '50DC': 14,
  '50DD': 14,
  '50DE': 14,
  '50DF': 14,
  '50E0': 14,
  '50E1': 14,
  '50E2': 14,
  '50E3': 14,
  '50E4': 14,
  '50E5': 14,
  '50E6': 14,
  '50E7': 14,
  '50E8': 14,
  '50E9': 14,
  '50EA': 14,
  '50EB': 14,
  '50EC': 14,
  '50ED': 14,
  '50EE': 14,
  '50EF': 14,
  '50F0': 14,
  '50F1': 14,
  '50F2': 14,
  '50F3': 14,
  '50F4': 14,
  '50F5': 15,
  '50F6': 15,
  '50F7': 14,
  '50F8': 15,
  '50F9': 15,
  '50FA': 15,
  '50FB': 15,
  '50FC': 15,
  '50FD': 15,
  '50FE': 15,
  '50FF': 15,
  '5100': 15,
  '5101': 14,
  '5102': 15,
  '5103': 15,
  '5104': 15,
  '5105': 15,
  '5106': 14,
  '5107': 15,
  '5108': 15,
  '5109': 15,
  '510A': 15,
  '510B': 15,
  '510C': 15,
  '510D': 15,
  '510E': 15,
  '510F': 15,
  '5110': 16,
  '5111': 16,
  '5112': 16,
  '5113': 16,
  '5114': 16,
  '5115': 16,
  '5116': 16,
  '5117': 16,
  '5118': 16,
  '5119': 15,
  '511A': 15,
  '511B': 16,
  '511C': 16,
  '511D': 16,
  '511E': 16,
  '511F': 17,
  '5120': 17,
  '5121': 17,
  '5122': 17,
  '5123': 16,
  '5124': 17,
  '5125': 17,
  '5126': 17,
  '5127': 17,
  '5128': 17,
  '5129': 17,
  '512A': 17,
  '512B': 16,
  '512C': 17,
  '512D': 18,
  '512E': 18,
  '512F': 18,
  '5130': 17,
  '5131': 18,
  '5132': 17,
  '5133': 19,
  '5134': 19,
  '5135': 18,
  '5136': 20,
  '5137': 21,
  '5138': 21,
  '5139': 21,
  '513A': 21,
  '513B': 22,
  '513C': 21,
  '513D': 23,
  '513E': 24,
  '513F': 2,
  '5140': 3,
  '5141': 4,
  '5142': 4,
  '5143': 4,
  '5144': 5,
  '5145': 6,
  '5146': 6,
  '5147': 6,
  '5148': 6,
  '5149': 6,
  '514A': 6,
  '514B': 7,
  '514C': 7,
  '514D': 7,
  '514E': 7,
  '514F': 7,
  '5150': 7,
  '5151': 7,
  '5152': 8,
  '5153': 8,
  '5154': 8,
  '5155': 7,
  '5156': 8,
  '5157': 9,
  '5158': 9,
  '5159': 9,
  '515A': 10,
  '515B': 10,
  '515C': 11,
  '515D': 11,
  '515E': 11,
  '515F': 12,
  '5160': 12,
  '5161': 13,
  '5162': 14,
  '5163': 16,
  '5164': 20,
  '5165': 2,
  '5166': 3,
  '5167': 4,
  '5168': 6,
  '5169': 8,
  '516A': 9,
  '516B': 2,
  '516C': 4,
  '516D': 4,
  '516E': 4,
  '516F': 4,
  '5170': 5,
  '5171': 6,
  '5172': 6,
  '5173': 6,
  '5174': 6,
  '5175': 7,
  '5176': 8,
  '5177': 8,
  '5178': 8,
  '5179': 9,
  '517A': 10,
  '517B': 9,
  '517C': 10,
  '517D': 11,
  '517E': 13,
  '517F': 13,
  '5180': 16,
  '5181': 18,
  '5182': 2,
  '5183': 4,
  '5184': 4,
  '5185': 4,
  '5186': 4,
  '5187': 4,
  '5188': 4,
  '5189': 5,
  '518A': 5,
  '518B': 5,
  '518C': 5,
  '518D': 6,
  '518E': 5,
  '518F': 7,
  '5190': 8,
  '5191': 9,
  '5192': 9,
  '5193': 10,
  '5194': 10,
  '5195': 11,
  '5196': 2,
  '5197': 4,
  '5198': 4,
  '5199': 5,
  '519A': 5,
  '519B': 6,
  '519C': 6,
  '519D': 7,
  '519E': 8,
  '519F': 9,
  '51A0': 9,
  '51A1': 10,
  '51A2': 10,
  '51A3': 10,
  '51A4': 10,
  '51A5': 10,
  '51A6': 10,
  '51A7': 10,
  '51A8': 11,
  '51A9': 14,
  '51AA': 15,
  '51AB': 2,
  '51AC': 5,
  '51AD': 5,
  '51AE': 5,
  '51AF': 5,
  '51B0': 6,
  '51B1': 6,
  '51B2': 6,
  '51B3': 6,
  '51B4': 6,
  '51B5': 7,
  '51B6': 7,
  '51B7': 7,
  '51B8': 7,
  '51B9': 7,
  '51BA': 7,
  '51BB': 7,
  '51BC': 8,
  '51BD': 8,
  '51BE': 8,
  '51BF': 8,
  '51C0': 8,
  '51C1': 9,
  '51C2': 9,
  '51C3': 9,
  '51C4': 10,
  '51C5': 10,
  '51C6': 10,
  '51C7': 10,
  '51C8': 10,
  '51C9': 10,
  '51CA': 10,
  '51CB': 10,
  '51CC': 10,
  '51CD': 10,
  '51CE': 10,
  '51CF': 11,
  '51D0': 11,
  '51D1': 11,
  '51D2': 12,
  '51D3': 12,
  '51D4': 12,
  '51D5': 12,
  '51D6': 12,
  '51D7': 13,
  '51D8': 14,
  '51D9': 15,
  '51DA': 15,
  '51DB': 15,
  '51DC': 15,
  '51DD': 16,
  '51DE': 16,
  '51DF': 17,
  '51E0': 2,
  '51E1': 3,
  '51E2': 3,
  '51E3': 3,
  '51E4': 4,
  '51E5': 5,
  '51E6': 5,
  '51E7': 5,
  '51E8': 6,
  '51E9': 6,
  '51EA': 6,
  '51EB': 6,
  '51EC': 7,
  '51ED': 8,
  '51EE': 8,
  '51EF': 8,
  '51F0': 11,
  '51F1': 12,
  '51F2': 12,
  '51F3': 14,
  '51F4': 14,
  '51F5': 2,
  '51F6': 4,
  '51F7': 5,
  '51F8': 5,
  '51F9': 5,
  '51FA': 5,
  '51FB': 5,
  '51FC': 6,
  '51FD': 8,
  '51FE': 9,
  '51FF': 12,
  '5200': 2,
  '5201': 2,
  '5202': 2,
  '5203': 3,
  '5204': 3,
  '5205': 4,
  '5206': 4,
  '5207': 4,
  '5208': 4,
  '5209': 5,
  '520A': 5,
  '520B': 5,
  '520C': 5,
  '520D': 5,
  '520E': 6,
  '520F': 6,
  '5210': 6,
  '5211': 6,
  '5212': 6,
  '5213': 6,
  '5214': 6,
  '5215': 6,
  '5216': 6,
  '5217': 6,
  '5218': 6,
  '5219': 6,
  '521A': 6,
  '521B': 6,
  '521C': 7,
  '521D': 7,
  '521E': 7,
  '521F': 7,
  '5220': 7,
  '5221': 7,
  '5222': 7,
  '5223': 7,
  '5224': 7,
  '5225': 7,
  '5226': 7,
  '5227': 7,
  '5228': 7,
  '5229': 7,
  '522A': 7,
  '522B': 7,
  '522C': 7,
  '522D': 7,
  '522E': 8,
  '522F': 8,
  '5230': 8,
  '5231': 8,
  '5232': 8,
  '5233': 8,
  '5234': 8,
  '5235': 8,
  '5236': 8,
  '5237': 8,
  '5238': 8,
  '5239': 8,
  '523A': 8,
  '523B': 8,
  '523C': 8,
  '523D': 8,
  '523E': 8,
  '523F': 8,
  '5240': 8,
  '5241': 8,
  '5242': 8,
  '5243': 9,
  '5244': 9,
  '5245': 9,
  '5246': 8,
  '5247': 9,
  '5248': 9,
  '5249': 9,
  '524A': 9,
  '524B': 9,
  '524C': 9,
  '524D': 9,
  '524E': 9,
  '524F': 9,
  '5250': 9,
  '5251': 9,
  '5252': 10,
  '5253': 10,
  '5254': 10,
  '5255': 10,
  '5256': 10,
  '5257': 10,
  '5258': 10,
  '5259': 10,
  '525A': 10,
  '525B': 10,
  '525C': 10,
  '525D': 10,
  '525E': 10,
  '525F': 10,
  '5260': 10,
  '5261': 10,
  '5262': 10,
  '5263': 10,
  '5264': 10,
  '5265': 10,
  '5266': 10,
  '5267': 10,
  '5268': 11,
  '5269': 12,
  '526A': 11,
  '526B': 11,
  '526C': 11,
  '526D': 11,
  '526E': 10,
  '526F': 11,
  '5270': 11,
  '5271': 11,
  '5272': 12,
  '5273': 11,
  '5274': 12,
  '5275': 12,
  '5276': 11,
  '5277': 13,
  '5278': 13,
  '5279': 13,
  '527A': 13,
  '527B': 13,
  '527C': 13,
  '527D': 13,
  '527E': 13,
  '527F': 13,
  '5280': 14,
  '5281': 14,
  '5282': 14,
  '5283': 14,
  '5284': 14,
  '5285': 15,
  '5286': 15,
  '5287': 15,
  '5288': 15,
  '5289': 15,
  '528A': 15,
  '528B': 15,
  '528C': 15,
  '528D': 15,
  '528E': 15,
  '528F': 15,
  '5290': 15,
  '5291': 16,
  '5292': 16,
  '5293': 16,
  '5294': 16,
  '5295': 17,
  '5296': 19,
  '5297': 21,
  '5298': 21,
  '5299': 23,
  '529A': 23,
  '529B': 2,
  '529C': 3,
  '529D': 4,
  '529E': 4,
  '529F': 5,
  '52A0': 5,
  '52A1': 5,
  '52A2': 5,
  '52A3': 6,
  '52A4': 6,
  '52A5': 6,
  '52A6': 6,
  '52A7': 6,
  '52A8': 6,
  '52A9': 7,
  '52AA': 7,
  '52AB': 7,
  '52AC': 7,
  '52AD': 7,
  '52AE': 7,
  '52AF': 7,
  '52B0': 7,
  '52B1': 7,
  '52B2': 7,
  '52B3': 7,
  '52B4': 7,
  '52B5': 8,
  '52B6': 8,
  '52B7': 8,
  '52B8': 8,
  '52B9': 8,
  '52BA': 8,
  '52BB': 8,
  '52BC': 8,
  '52BD': 8,
  '52BE': 8,
  '52BF': 8,
  '52C0': 9,
  '52C1': 9,
  '52C2': 9,
  '52C3': 9,
  '52C4': 9,
  '52C5': 9,
  '52C6': 8,
  '52C7': 9,
  '52C8': 9,
  '52C9': 9,
  '52CA': 9,
  '52CB': 9,
  '52CC': 10,
  '52CD': 10,
  '52CE': 10,
  '52CF': 10,
  '52D0': 10,
  '52D1': 10,
  '52D2': 11,
  '52D3': 11,
  '52D4': 11,
  '52D5': 11,
  '52D6': 11,
  '52D7': 11,
  '52D8': 11,
  '52D9': 10,
  '52DA': 11,
  '52DB': 12,
  '52DC': 12,
  '52DD': 12,
  '52DE': 12,
  '52DF': 12,
  '52E0': 13,
  '52E1': 13,
  '52E2': 13,
  '52E3': 13,
  '52E4': 13,
  '52E5': 14,
  '52E6': 13,
  '52E7': 13,
  '52E8': 13,
  '52E9': 14,
  '52EA': 14,
  '52EB': 14,
  '52EC': 14,
  '52ED': 14,
  '52EE': 15,
  '52EF': 15,
  '52F0': 15,
  '52F1': 14,
  '52F2': 15,
  '52F3': 16,
  '52F4': 17,
  '52F5': 16,
  '52F6': 17,
  '52F7': 19,
  '52F8': 19,
  '52F9': 2,
  '52FA': 3,
  '52FB': 4,
  '52FC': 4,
  '52FD': 4,
  '52FE': 4,
  '52FF': 4,
  '5300': 4,
  '5301': 4,
  '5302': 4,
  '5303': 5,
  '5304': 5,
  '5305': 5,
  '5306': 5,
  '5307': 5,
  '5308': 6,
  '5309': 7,
  '530A': 8,
  '530B': 8,
  '530C': 8,
  '530D': 9,
  '530E': 10,
  '530F': 11,
  '5310': 11,
  '5311': 12,
  '5312': 11,
  '5313': 11,
  '5314': 15,
  '5315': 2,
  '5316': 4,
  '5317': 5,
  '5318': 11,
  '5319': 11,
  '531A': 2,
  '531B': 5,
  '531C': 5,
  '531D': 5,
  '531E': 5,
  '531F': 6,
  '5320': 6,
  '5321': 6,
  '5322': 6,
  '5323': 7,
  '5324': 7,
  '5325': 7,
  '5326': 8,
  '5327': 9,
  '5328': 9,
  '5329': 9,
  '532A': 10,
  '532B': 10,
  '532C': 11,
  '532D': 11,
  '532E': 11,
  '532F': 13,
  '5330': 14,
  '5331': 14,
  '5332': 14,
  '5333': 15,
  '5334': 16,
  '5335': 17,
  '5336': 19,
  '5337': 20,
  '5338': 2,
  '5339': 4,
  '533A': 4,
  '533B': 7,
  '533C': 8,
  '533D': 9,
  '533E': 11,
  '533F': 10,
  '5340': 11,
  '5341': 2,
  '5342': 3,
  '5343': 3,
  '5344': 3,
  '5345': 4,
  '5346': 4,
  '5347': 4,
  '5348': 4,
  '5349': 5,
  '534A': 5,
  '534B': 6,
  '534C': 5,
  '534D': 4,
  '534E': 6,
  '534F': 6,
  '5350': 4,
  '5351': 8,
  '5352': 8,
  '5353': 8,
  '5354': 8,
  '5355': 8,
  '5356': 8,
  '5357': 9,
  '5358': 9,
  '5359': 11,
  '535A': 12,
  '535B': 21,
  '535C': 2,
  '535D': 4,
  '535E': 4,
  '535F': 5,
  '5360': 5,
  '5361': 5,
  '5362': 5,
  '5363': 7,
  '5364': 7,
  '5365': 8,
  '5366': 8,
  '5367': 8,
  '5368': 10,
  '5369': 2,
  '536A': 3,
  '536B': 3,
  '536C': 4,
  '536D': 5,
  '536E': 5,
  '536F': 5,
  '5370': 5,
  '5371': 6,
  '5372': 7,
  '5373': 7,
  '5374': 7,
  '5375': 7,
  '5376': 8,
  '5377': 8,
  '5378': 9,
  '5379': 8,
  '537A': 8,
  '537B': 9,
  '537C': 9,
  '537D': 9,
  '537E': 11,
  '537F': 10,
  '5380': 13,
  '5381': 13,
  '5382': 2,
  '5383': 4,
  '5384': 4,
  '5385': 4,
  '5386': 4,
  '5387': 5,
  '5388': 5,
  '5389': 5,
  '538A': 6,
  '538B': 6,
  '538C': 6,
  '538D': 6,
  '538E': 7,
  '538F': 7,
  '5390': 7,
  '5391': 7,
  '5392': 8,
  '5393': 8,
  '5394': 8,
  '5395': 8,
  '5396': 9,
  '5397': 9,
  '5398': 9,
  '5399': 9,
  '539A': 9,
  '539B': 9,
  '539C': 10,
  '539D': 10,
  '539E': 10,
  '539F': 10,
  '53A0': 11,
  '53A1': 11,
  '53A2': 11,
  '53A3': 11,
  '53A4': 12,
  '53A5': 12,
  '53A6': 12,
  '53A7': 12,
  '53A8': 12,
  '53A9': 11,
  '53AA': 13,
  '53AB': 12,
  '53AC': 14,
  '53AD': 14,
  '53AE': 14,
  '53AF': 13,
  '53B0': 14,
  '53B1': 15,
  '53B2': 14,
  '53B3': 16,
  '53B4': 19,
  '53B5': 30,
  '53B6': 2,
  '53B7': 4,
  '53B8': 4,
  '53B9': 4,
  '53BA': 5,
  '53BB': 5,
  '53BC': 5,
  '53BD': 6,
  '53BE': 6,
  '53BF': 7,
  '53C0': 8,
  '53C1': 8,
  '53C2': 8,
  '53C3': 11,
  '53C4': 11,
  '53C5': 12,
  '53C6': 14,
  '53C7': 15,
  '53C8': 2,
  '53C9': 3,
  '53CA': 3,
  '53CB': 4,
  '53CC': 4,
  '53CD': 4,
  '53CE': 4,
  '53CF': 5,
  '53D0': 5,
  '53D1': 5,
  '53D2': 6,
  '53D3': 7,
  '53D4': 8,
  '53D5': 8,
  '53D6': 8,
  '53D7': 8,
  '53D8': 8,
  '53D9': 9,
  '53DA': 9,
  '53DB': 9,
  '53DC': 9,
  '53DD': 9,
  '53DE': 10,
  '53DF': 9,
  '53E0': 13,
  '53E1': 16,
  '53E2': 18,
  '53E3': 3,
  '53E4': 5,
  '53E5': 5,
  '53E6': 5,
  '53E7': 5,
  '53E8': 5,
  '53E9': 5,
  '53EA': 5,
  '53EB': 5,
  '53EC': 5,
  '53ED': 5,
  '53EE': 5,
  '53EF': 5,
  '53F0': 5,
  '53F1': 5,
  '53F2': 5,
  '53F3': 5,
  '53F4': 5,
  '53F5': 5,
  '53F6': 5,
  '53F7': 5,
  '53F8': 5,
  '53F9': 5,
  '53FA': 5,
  '53FB': 5,
  '53FC': 5,
  '53FD': 5,
  '53FE': 5,
  '53FF': 6,
  '5400': 6,
  '5401': 6,
  '5402': 6,
  '5403': 6,
  '5404': 6,
  '5405': 6,
  '5406': 6,
  '5407': 6,
  '5408': 6,
  '5409': 6,
  '540A': 6,
  '540B': 6,
  '540C': 6,
  '540D': 6,
  '540E': 6,
  '540F': 6,
  '5410': 6,
  '5411': 6,
  '5412': 6,
  '5413': 6,
  '5414': 6,
  '5415': 6,
  '5416': 6,
  '5417': 6,
  '5418': 7,
  '5419': 7,
  '541A': 7,
  '541B': 7,
  '541C': 7,
  '541D': 7,
  '541E': 7,
  '541F': 7,
  '5420': 7,
  '5421': 7,
  '5422': 7,
  '5423': 7,
  '5424': 7,
  '5425': 7,
  '5426': 7,
  '5427': 7,
  '5428': 7,
  '5429': 7,
  '542A': 7,
  '542B': 7,
  '542C': 7,
  '542D': 7,
  '542E': 7,
  '542F': 7,
  '5430': 7,
  '5431': 7,
  '5432': 7,
  '5433': 7,
  '5434': 7,
  '5435': 7,
  '5436': 7,
  '5437': 7,
  '5438': 6,
  '5439': 7,
  '543A': 7,
  '543B': 7,
  '543C': 7,
  '543D': 7,
  '543E': 7,
  '543F': 7,
  '5440': 7,
  '5441': 7,
  '5442': 7,
  '5443': 7,
  '5444': 7,
  '5445': 7,
  '5446': 7,
  '5447': 7,
  '5448': 7,
  '5449': 7,
  '544A': 7,
  '544B': 7,
  '544C': 7,
  '544D': 7,
  '544E': 7,
  '544F': 7,
  '5450': 7,
  '5451': 7,
  '5452': 7,
  '5453': 7,
  '5454': 7,
  '5455': 7,
  '5456': 7,
  '5457': 7,
  '5458': 7,
  '5459': 7,
  '545A': 7,
  '545B': 7,
  '545C': 7,
  '545D': 8,
  '545E': 8,
  '545F': 8,
  '5460': 8,
  '5461': 8,
  '5462': 8,
  '5463': 8,
  '5464': 8,
  '5465': 8,
  '5466': 8,
  '5467': 8,
  '5468': 8,
  '5469': 8,
  '546A': 8,
  '546B': 8,
  '546C': 8,
  '546D': 8,
  '546E': 8,
  '546F': 8,
  '5470': 9,
  '5471': 8,
  '5472': 9,
  '5473': 8,
  '5474': 8,
  '5475': 8,
  '5476': 8,
  '5477': 8,
  '5478': 8,
  '5479': 8,
  '547A': 8,
  '547B': 8,
  '547C': 8,
  '547D': 8,
  '547E': 8,
  '547F': 8,
  '5480': 8,
  '5481': 8,
  '5482': 8,
  '5483': 8,
  '5484': 8,
  '5485': 8,
  '5486': 8,
  '5487': 8,
  '5488': 8,
  '5489': 8,
  '548A': 8,
  '548B': 8,
  '548C': 8,
  '548D': 8,
  '548E': 8,
  '548F': 8,
  '5490': 8,
  '5491': 8,
  '5492': 8,
  '5493': 7,
  '5494': 8,
  '5495': 8,
  '5496': 8,
  '5497': 8,
  '5498': 8,
  '5499': 8,
  '549A': 8,
  '549B': 8,
  '549C': 8,
  '549D': 8,
  '549E': 7,
  '549F': 9,
  '54A0': 9,
  '54A1': 9,
  '54A2': 9,
  '54A3': 9,
  '54A4': 9,
  '54A5': 9,
  '54A6': 9,
  '54A7': 9,
  '54A8': 9,
  '54A9': 9,
  '54AA': 9,
  '54AB': 9,
  '54AC': 9,
  '54AD': 9,
  '54AE': 9,
  '54AF': 9,
  '54B0': 9,
  '54B1': 9,
  '54B2': 9,
  '54B3': 9,
  '54B4': 9,
  '54B5': 9,
  '54B6': 9,
  '54B7': 9,
  '54B8': 9,
  '54B9': 9,
  '54BA': 9,
  '54BB': 9,
  '54BC': 8,
  '54BD': 9,
  '54BE': 9,
  '54BF': 9,
  '54C0': 9,
  '54C1': 9,
  '54C2': 9,
  '54C3': 9,
  '54C4': 9,
  '54C5': 9,
  '54C6': 9,
  '54C7': 9,
  '54C8': 9,
  '54C9': 9,
  '54CA': 9,
  '54CB': 9,
  '54CC': 9,
  '54CD': 9,
  '54CE': 8,
  '54CF': 9,
  '54D0': 9,
  '54D1': 9,
  '54D2': 9,
  '54D3': 9,
  '54D4': 9,
  '54D5': 9,
  '54D6': 9,
  '54D7': 9,
  '54D8': 9,
  '54D9': 9,
  '54DA': 9,
  '54DB': 9,
  '54DC': 9,
  '54DD': 9,
  '54DE': 9,
  '54DF': 9,
  '54E0': 10,
  '54E1': 10,
  '54E2': 10,
  '54E3': 10,
  '54E4': 10,
  '54E5': 10,
  '54E6': 10,
  '54E7': 10,
  '54E8': 10,
  '54E9': 10,
  '54EA': 9,
  '54EB': 10,
  '54EC': 10,
  '54ED': 10,
  '54EE': 10,
  '54EF': 10,
  '54F0': 10,
  '54F1': 10,
  '54F2': 10,
  '54F3': 10,
  '54F4': 10,
  '54F5': 10,
  '54F6': 10,
  '54F7': 10,
  '54F8': 10,
  '54F9': 10,
  '54FA': 10,
  '54FB': 10,
  '54FC': 10,
  '54FD': 10,
  '54FE': 10,
  '54FF': 10,
  '5500': 10,
  '5501': 10,
  '5502': 10,
  '5503': 10,
  '5504': 10,
  '5505': 10,
  '5506': 10,
  '5507': 10,
  '5508': 10,
  '5509': 10,
  '550A': 10,
  '550B': 10,
  '550C': 9,
  '550D': 10,
  '550E': 10,
  '550F': 10,
  '5510': 10,
  '5511': 10,
  '5512': 10,
  '5513': 10,
  '5514': 10,
  '5515': 10,
  '5516': 10,
  '5517': 10,
  '5518': 10,
  '5519': 10,
  '551A': 10,
  '551B': 10,
  '551C': 10,
  '551D': 10,
  '551E': 10,
  '551F': 10,
  '5520': 10,
  '5521': 10,
  '5522': 10,
  '5523': 10,
  '5524': 10,
  '5525': 10,
  '5526': 10,
  '5527': 10,
  '5528': 10,
  '5529': 11,
  '552A': 11,
  '552B': 11,
  '552C': 11,
  '552D': 11,
  '552E': 11,
  '552F': 11,
  '5530': 11,
  '5531': 11,
  '5532': 11,
  '5533': 11,
  '5534': 10,
  '5535': 11,
  '5536': 11,
  '5537': 11,
  '5538': 11,
  '5539': 11,
  '553A': 11,
  '553B': 11,
  '553C': 11,
  '553D': 11,
  '553E': 11,
  '553F': 11,
  '5540': 11,
  '5541': 11,
  '5542': 11,
  '5543': 11,
  '5544': 11,
  '5545': 11,
  '5546': 11,
  '5547': 11,
  '5548': 11,
  '5549': 11,
  '554A': 10,
  '554B': 11,
  '554C': 11,
  '554D': 11,
  '554E': 11,
  '554F': 11,
  '5550': 11,
  '5551': 11,
  '5552': 11,
  '5553': 11,
  '5554': 11,
  '5555': 11,
  '5556': 11,
  '5557': 11,
  '5558': 11,
  '5559': 12,
  '555A': 11,
  '555B': 11,
  '555C': 11,
  '555D': 11,
  '555E': 11,
  '555F': 11,
  '5560': 11,
  '5561': 11,
  '5562': 11,
  '5563': 12,
  '5564': 11,
  '5565': 11,
  '5566': 11,
  '5567': 11,
  '5568': 11,
  '5569': 11,
  '556A': 11,
  '556B': 11,
  '556C': 11,
  '556D': 11,
  '556E': 11,
  '556F': 11,
  '5570': 11,
  '5571': 11,
  '5572': 11,
  '5573': 11,
  '5574': 11,
  '5575': 11,
  '5576': 11,
  '5577': 11,
  '5578': 11,
  '5579': 11,
  '557A': 12,
  '557B': 12,
  '557C': 12,
  '557D': 12,
  '557E': 12,
  '557F': 12,
  '5580': 12,
  '5581': 12,
  '5582': 12,
  '5583': 12,
  '5584': 12,
  '5585': 12,
  '5586': 12,
  '5587': 12,
  '5588': 12,
  '5589': 12,
  '558A': 12,
  '558B': 12,
  '558C': 12,
  '558D': 13,
  '558E': 11,
  '558F': 11,
  '5590': 11,
  '5591': 12,
  '5592': 12,
  '5593': 12,
  '5594': 12,
  '5595': 12,
  '5596': 12,
  '5597': 12,
  '5598': 12,
  '5599': 12,
  '559A': 12,
  '559B': 12,
  '559C': 12,
  '559D': 12,
  '559E': 12,
  '559F': 12,
  '55A0': 12,
  '55A1': 12,
  '55A2': 12,
  '55A3': 12,
  '55A4': 12,
  '55A5': 12,
  '55A6': 12,
  '55A7': 12,
  '55A8': 12,
  '55A9': 12,
  '55AA': 12,
  '55AB': 12,
  '55AC': 12,
  '55AD': 12,
  '55AE': 12,
  '55AF': 11,
  '55B0': 12,
  '55B1': 12,
  '55B2': 12,
  '55B3': 12,
  '55B4': 12,
  '55B5': 11,
  '55B6': 11,
  '55B7': 12,
  '55B8': 12,
  '55B9': 12,
  '55BA': 12,
  '55BB': 12,
  '55BC': 12,
  '55BD': 12,
  '55BE': 12,
  '55BF': 13,
  '55C0': 13,
  '55C1': 13,
  '55C2': 13,
  '55C3': 13,
  '55C4': 13,
  '55C5': 13,
  '55C6': 13,
  '55C7': 13,
  '55C8': 13,
  '55C9': 13,
  '55CA': 13,
  '55CB': 13,
  '55CC': 13,
  '55CD': 13,
  '55CE': 13,
  '55CF': 12,
  '55D0': 13,
  '55D1': 13,
  '55D2': 12,
  '55D3': 13,
  '55D4': 13,
  '55D5': 13,
  '55D6': 12,
  '55D7': 12,
  '55D8': 13,
  '55D9': 13,
  '55DA': 13,
  '55DB': 13,
  '55DC': 13,
  '55DD': 13,
  '55DE': 12,
  '55DF': 12,
  '55E0': 13,
  '55E1': 13,
  '55E2': 12,
  '55E3': 13,
  '55E4': 13,
  '55E5': 13,
  '55E6': 13,
  '55E7': 13,
  '55E8': 13,
  '55E9': 13,
  '55EA': 13,
  '55EB': 13,
  '55EC': 13,
  '55ED': 13,
  '55EE': 13,
  '55EF': 13,
  '55F0': 13,
  '55F1': 13,
  '55F2': 13,
  '55F3': 13,
  '55F4': 12,
  '55F5': 13,
  '55F6': 13,
  '55F7': 13,
  '55F8': 13,
  '55F9': 13,
  '55FA': 14,
  '55FB': 14,
  '55FC': 13,
  '55FD': 14,
  '55FE': 14,
  '55FF': 14,
  '5600': 14,
  '5601': 14,
  '5602': 14,
  '5603': 14,
  '5604': 14,
  '5605': 12,
  '5606': 14,
  '5607': 14,
  '5608': 14,
  '5609': 14,
  '560A': 14,
  '560B': 14,
  '560C': 14,
  '560D': 14,
  '560E': 14,
  '560F': 14,
  '5610': 14,
  '5611': 14,
  '5612': 14,
  '5613': 14,
  '5614': 14,
  '5615': 14,
  '5616': 14,
  '5617': 14,
  '5618': 14,
  '5619': 14,
  '561A': 14,
  '561B': 14,
  '561C': 14,
  '561D': 14,
  '561E': 14,
  '561F': 13,
  '5620': 15,
  '5621': 14,
  '5622': 14,
  '5623': 14,
  '5624': 14,
  '5625': 14,
  '5626': 14,
  '5627': 14,
  '5628': 14,
  '5629': 13,
  '562A': 15,
  '562B': 15,
  '562C': 15,
  '562D': 15,
  '562E': 15,
  '562F': 16,
  '5630': 15,
  '5631': 15,
  '5632': 15,
  '5633': 15,
  '5634': 16,
  '5635': 15,
  '5636': 15,
  '5637': 15,
  '5638': 15,
  '5639': 15,
  '563A': 15,
  '563B': 15,
  '563C': 15,
  '563D': 15,
  '563E': 15,
  '563F': 15,
  '5640': 15,
  '5641': 15,
  '5642': 15,
  '5643': 15,
  '5644': 15,
  '5645': 15,
  '5646': 15,
  '5647': 15,
  '5648': 15,
  '5649': 14,
  '564A': 15,
  '564B': 15,
  '564C': 15,
  '564D': 15,
  '564E': 15,
  '564F': 15,
  '5650': 15,
  '5651': 14,
  '5652': 15,
  '5653': 15,
  '5654': 15,
  '5655': 15,
  '5656': 15,
  '5657': 15,
  '5658': 15,
  '5659': 15,
  '565A': 15,
  '565B': 15,
  '565C': 15,
  '565D': 15,
  '565E': 16,
  '565F': 16,
  '5660': 15,
  '5661': 16,
  '5662': 15,
  '5663': 16,
  '5664': 16,
  '5665': 16,
  '5666': 16,
  '5667': 15,
  '5668': 16,
  '5669': 16,
  '566A': 16,
  '566B': 16,
  '566C': 16,
  '566D': 16,
  '566E': 16,
  '566F': 16,
  '5670': 16,
  '5671': 16,
  '5672': 16,
  '5673': 16,
  '5674': 15,
  '5675': 15,
  '5676': 15,
  '5677': 16,
  '5678': 16,
  '5679': 16,
  '567A': 16,
  '567B': 16,
  '567C': 16,
  '567D': 17,
  '567E': 17,
  '567F': 17,
  '5680': 17,
  '5681': 17,
  '5682': 17,
  '5683': 16,
  '5684': 16,
  '5685': 17,
  '5686': 16,
  '5687': 17,
  '5688': 17,
  '5689': 17,
  '568A': 17,
  '568B': 17,
  '568C': 17,
  '568D': 17,
  '568E': 17,
  '568F': 17,
  '5690': 17,
  '5691': 17,
  '5692': 17,
  '5693': 17,
  '5694': 18,
  '5695': 18,
  '5696': 18,
  '5697': 18,
  '5698': 18,
  '5699': 18,
  '569A': 18,
  '569B': 18,
  '569C': 18,
  '569D': 17,
  '569E': 18,
  '569F': 18,
  '56A0': 18,
  '56A1': 18,
  '56A2': 18,
  '56A3': 18,
  '56A4': 18,
  '56A5': 19,
  '56A6': 19,
  '56A7': 19,
  '56A8': 19,
  '56A9': 19,
  '56AA': 19,
  '56AB': 19,
  '56AC': 19,
  '56AD': 19,
  '56AE': 17,
  '56AF': 19,
  '56B0': 19,
  '56B1': 20,
  '56B2': 20,
  '56B3': 20,
  '56B4': 19,
  '56B5': 20,
  '56B6': 20,
  '56B7': 20,
  '56B8': 20,
  '56B9': 20,
  '56BA': 20,
  '56BB': 21,
  '56BC': 20,
  '56BD': 20,
  '56BE': 20,
  '56BF': 20,
  '56C0': 21,
  '56C1': 21,
  '56C2': 21,
  '56C3': 21,
  '56C4': 21,
  '56C5': 22,
  '56C6': 21,
  '56C7': 22,
  '56C8': 21,
  '56C9': 22,
  '56CA': 22,
  '56CB': 22,
  '56CC': 22,
  '56CD': 21,
  '56CE': 22,
  '56CF': 23,
  '56D0': 23,
  '56D1': 24,
  '56D2': 23,
  '56D3': 24,
  '56D4': 25,
  '56D5': 25,
  '56D6': 28,
  '56D7': 3,
  '56D8': 5,
  '56D9': 5,
  '56DA': 5,
  '56DB': 5,
  '56DC': 5,
  '56DD': 6,
  '56DE': 6,
  '56DF': 6,
  '56E0': 6,
  '56E1': 6,
  '56E2': 6,
  '56E3': 6,
  '56E4': 7,
  '56E5': 7,
  '56E6': 7,
  '56E7': 7,
  '56E8': 7,
  '56E9': 7,
  '56EA': 7,
  '56EB': 7,
  '56EC': 7,
  '56ED': 7,
  '56EE': 7,
  '56EF': 7,
  '56F0': 7,
  '56F1': 7,
  '56F2': 7,
  '56F3': 7,
  '56F4': 7,
  '56F5': 7,
  '56F6': 8,
  '56F7': 8,
  '56F8': 8,
  '56F9': 8,
  '56FA': 8,
  '56FB': 8,
  '56FC': 8,
  '56FD': 8,
  '56FE': 8,
  '56FF': 9,
  '5700': 9,
  '5701': 10,
  '5702': 10,
  '5703': 10,
  '5704': 10,
  '5705': 10,
  '5706': 10,
  '5707': 11,
  '5708': 11,
  '5709': 11,
  '570A': 11,
  '570B': 11,
  '570C': 12,
  '570D': 12,
  '570E': 12,
  '570F': 12,
  '5710': 12,
  '5711': 13,
  '5712': 13,
  '5713': 13,
  '5714': 13,
  '5715': 13,
  '5716': 14,
  '5717': 14,
  '5718': 14,
  '5719': 14,
  '571A': 15,
  '571B': 16,
  '571C': 16,
  '571D': 22,
  '571E': 26,
  '571F': 3,
  '5720': 4,
  '5721': 4,
  '5722': 5,
  '5723': 5,
  '5724': 5,
  '5725': 5,
  '5726': 5,
  '5727': 5,
  '5728': 6,
  '5729': 6,
  '572A': 6,
  '572B': 6,
  '572C': 6,
  '572D': 6,
  '572E': 6,
  '572F': 6,
  '5730': 6,
  '5731': 6,
  '5732': 6,
  '5733': 6,
  '5734': 6,
  '5735': 6,
  '5736': 6,
  '5737': 6,
  '5738': 6,
  '5739': 6,
  '573A': 6,
  '573B': 7,
  '573C': 7,
  '573D': 7,
  '573E': 6,
  '573F': 7,
  '5740': 7,
  '5741': 7,
  '5742': 7,
  '5743': 7,
  '5744': 7,
  '5745': 7,
  '5746': 7,
  '5747': 7,
  '5748': 7,
  '5749': 7,
  '574A': 7,
  '574B': 7,
  '574C': 7,
  '574D': 7,
  '574E': 7,
  '574F': 7,
  '5750': 7,
  '5751': 7,
  '5752': 7,
  '5753': 7,
  '5754': 7,
  '5755': 7,
  '5756': 7,
  '5757': 7,
  '5758': 7,
  '5759': 7,
  '575A': 7,
  '575B': 7,
  '575C': 7,
  '575D': 7,
  '575E': 7,
  '575F': 7,
  '5760': 7,
  '5761': 8,
  '5762': 8,
  '5763': 8,
  '5764': 8,
  '5765': 8,
  '5766': 8,
  '5767': 8,
  '5768': 8,
  '5769': 8,
  '576A': 8,
  '576B': 8,
  '576C': 8,
  '576D': 8,
  '576E': 8,
  '576F': 8,
  '5770': 8,
  '5771': 8,
  '5772': 8,
  '5773': 8,
  '5774': 8,
  '5775': 8,
  '5776': 8,
  '5777': 8,
  '5778': 8,
  '5779': 8,
  '577A': 8,
  '577B': 8,
  '577C': 8,
  '577D': 8,
  '577E': 8,
  '577F': 8,
  '5780': 8,
  '5781': 8,
  '5782': 8,
  '5783': 8,
  '5784': 8,
  '5785': 8,
  '5786': 8,
  '5787': 8,
  '5788': 8,
  '5789': 8,
  '578A': 8,
  '578B': 9,
  '578C': 9,
  '578D': 9,
  '578E': 9,
  '578F': 9,
  '5790': 9,
  '5791': 9,
  '5792': 9,
  '5793': 9,
  '5794': 9,
  '5795': 9,
  '5796': 9,
  '5797': 9,
  '5798': 9,
  '5799': 9,
  '579A': 9,
  '579B': 9,
  '579C': 9,
  '579D': 9,
  '579E': 9,
  '579F': 9,
  '57A0': 9,
  '57A1': 9,
  '57A2': 9,
  '57A3': 9,
  '57A4': 9,
  '57A5': 9,
  '57A6': 9,
  '57A7': 9,
  '57A8': 9,
  '57A9': 9,
  '57AA': 9,
  '57AB': 9,
  '57AC': 9,
  '57AD': 9,
  '57AE': 9,
  '57AF': 9,
  '57B0': 9,
  '57B1': 9,
  '57B2': 9,
  '57B3': 9,
  '57B4': 9,
  '57B5': 9,
  '57B6': 10,
  '57B7': 10,
  '57B8': 10,
  '57B9': 9,
  '57BA': 10,
  '57BB': 10,
  '57BC': 10,
  '57BD': 10,
  '57BE': 10,
  '57BF': 10,
  '57C0': 10,
  '57C1': 10,
  '57C2': 10,
  '57C3': 10,
  '57C4': 10,
  '57C5': 9,
  '57C6': 10,
  '57C7': 10,
  '57C8': 10,
  '57C9': 10,
  '57CA': 10,
  '57CB': 10,
  '57CC': 10,
  '57CD': 10,
  '57CE': 9,
  '57CF': 9,
  '57D0': 10,
  '57D1': 10,
  '57D2': 10,
  '57D3': 10,
  '57D4': 10,
  '57D5': 10,
  '57D6': 10,
  '57D7': 10,
  '57D8': 10,
  '57D9': 10,
  '57DA': 10,
  '57DB': 10,
  '57DC': 11,
  '57DD': 11,
  '57DE': 11,
  '57DF': 11,
  '57E0': 11,
  '57E1': 11,
  '57E2': 11,
  '57E3': 11,
  '57E4': 11,
  '57E5': 11,
  '57E6': 11,
  '57E7': 11,
  '57E8': 11,
  '57E9': 9,
  '57EA': 11,
  '57EB': 11,
  '57EC': 11,
  '57ED': 11,
  '57EE': 11,
  '57EF': 11,
  '57F0': 11,
  '57F1': 11,
  '57F2': 11,
  '57F3': 11,
  '57F4': 11,
  '57F5': 11,
  '57F6': 11,
  '57F7': 11,
  '57F8': 11,
  '57F9': 11,
  '57FA': 11,
  '57FB': 11,
  '57FC': 11,
  '57FD': 11,
  '57FE': 11,
  '57FF': 11,
  '5800': 11,
  '5801': 11,
  '5802': 11,
  '5803': 11,
  '5804': 11,
  '5805': 11,
  '5806': 11,
  '5807': 11,
  '5808': 11,
  '5809': 11,
  '580A': 11,
  '580B': 11,
  '580C': 11,
  '580D': 11,
  '580E': 11,
  '580F': 11,
  '5810': 11,
  '5811': 11,
  '5812': 11,
  '5813': 11,
  '5814': 11,
  '5815': 11,
  '5816': 12,
  '5817': 12,
  '5818': 12,
  '5819': 12,
  '581A': 12,
  '581B': 12,
  '581C': 12,
  '581D': 11,
  '581E': 12,
  '581F': 12,
  '5820': 12,
  '5821': 12,
  '5822': 12,
  '5823': 12,
  '5824': 12,
  '5825': 12,
  '5826': 12,
  '5827': 12,
  '5828': 12,
  '5829': 12,
  '582A': 12,
  '582B': 12,
  '582C': 12,
  '582D': 12,
  '582E': 12,
  '582F': 12,
  '5830': 12,
  '5831': 12,
  '5832': 10,
  '5833': 12,
  '5834': 12,
  '5835': 11,
  '5836': 12,
  '5837': 12,
  '5838': 12,
  '5839': 12,
  '583A': 12,
  '583B': 12,
  '583C': 12,
  '583D': 13,
  '583E': 12,
  '583F': 12,
  '5840': 12,
  '5841': 12,
  '5842': 12,
  '5843': 12,
  '5844': 12,
  '5845': 12,
  '5846': 12,
  '5847': 12,
  '5848': 12,
  '5849': 13,
  '584A': 12,
  '584B': 13,
  '584C': 13,
  '584D': 13,
  '584E': 13,
  '584F': 13,
  '5850': 13,
  '5851': 13,
  '5852': 13,
  '5853': 13,
  '5854': 12,
  '5855': 13,
  '5856': 13,
  '5857': 13,
  '5858': 13,
  '5859': 13,
  '585A': 13,
  '585B': 13,
  '585C': 13,
  '585D': 13,
  '585E': 13,
  '585F': 12,
  '5860': 12,
  '5861': 13,
  '5862': 13,
  '5863': 13,
  '5864': 13,
  '5865': 13,
  '5866': 12,
  '5867': 13,
  '5868': 13,
  '5869': 13,
  '586A': 13,
  '586B': 13,
  '586C': 13,
  '586D': 12,
  '586E': 13,
  '586F': 13,
  '5870': 13,
  '5871': 13,
  '5872': 14,
  '5873': 13,
  '5874': 14,
  '5875': 14,
  '5876': 14,
  '5877': 14,
  '5878': 14,
  '5879': 14,
  '587A': 14,
  '587B': 13,
  '587C': 14,
  '587D': 14,
  '587E': 14,
  '587F': 14,
  '5880': 15,
  '5881': 14,
  '5882': 14,
  '5883': 14,
  '5884': 14,
  '5885': 14,
  '5886': 14,
  '5887': 14,
  '5888': 14,
  '5889': 14,
  '588A': 14,
  '588B': 14,
  '588C': 14,
  '588D': 14,
  '588E': 13,
  '588F': 14,
  '5890': 14,
  '5891': 14,
  '5892': 14,
  '5893': 13,
  '5894': 14,
  '5895': 14,
  '5896': 14,
  '5897': 14,
  '5898': 14,
  '5899': 14,
  '589A': 14,
  '589B': 14,
  '589C': 14,
  '589D': 15,
  '589E': 15,
  '589F': 14,
  '58A0': 15,
  '58A1': 15,
  '58A2': 15,
  '58A3': 15,
  '58A4': 15,
  '58A5': 15,
  '58A6': 15,
  '58A7': 15,
  '58A8': 15,
  '58A9': 15,
  '58AA': 15,
  '58AB': 15,
  '58AC': 14,
  '58AD': 14,
  '58AE': 14,
  '58AF': 15,
  '58B0': 15,
  '58B1': 15,
  '58B2': 15,
  '58B3': 15,
  '58B4': 14,
  '58B5': 15,
  '58B6': 15,
  '58B7': 13,
  '58B8': 14,
  '58B9': 15,
  '58BA': 15,
  '58BB': 16,
  '58BC': 16,
  '58BD': 16,
  '58BE': 16,
  '58BF': 16,
  '58C0': 16,
  '58C1': 16,
  '58C2': 16,
  '58C3': 16,
  '58C4': 16,
  '58C5': 16,
  '58C6': 16,
  '58C7': 16,
  '58C8': 16,
  '58C9': 16,
  '58CA': 16,
  '58CB': 16,
  '58CC': 16,
  '58CD': 17,
  '58CE': 17,
  '58CF': 17,
  '58D0': 17,
  '58D1': 17,
  '58D2': 16,
  '58D3': 17,
  '58D4': 17,
  '58D5': 17,
  '58D6': 17,
  '58D7': 17,
  '58D8': 18,
  '58D9': 17,
  '58DA': 19,
  '58DB': 19,
  '58DC': 19,
  '58DD': 18,
  '58DE': 19,
  '58DF': 19,
  '58E0': 19,
  '58E1': 19,
  '58E2': 19,
  '58E3': 20,
  '58E4': 20,
  '58E5': 20,
  '58E6': 20,
  '58E7': 22,
  '58E8': 23,
  '58E9': 24,
  '58EA': 25,
  '58EB': 3,
  '58EC': 4,
  '58ED': 5,
  '58EE': 6,
  '58EF': 7,
  '58F0': 7,
  '58F1': 7,
  '58F2': 7,
  '58F3': 7,
  '58F4': 9,
  '58F5': 9,
  '58F6': 10,
  '58F7': 11,
  '58F8': 11,
  '58F9': 12,
  '58FA': 12,
  '58FB': 12,
  '58FC': 13,
  '58FD': 14,
  '58FE': 13,
  '58FF': 15,
  '5900': 14,
  '5901': 16,
  '5902': 3,
  '5903': 4,
  '5904': 5,
  '5905': 6,
  '5906': 7,
  '5907': 8,
  '5908': 9,
  '5909': 9,
  '590A': 3,
  '590B': 7,
  '590C': 8,
  '590D': 9,
  '590E': 10,
  '590F': 10,
  '5910': 14,
  '5911': 18,
  '5912': 19,
  '5913': 19,
  '5914': 21,
  '5915': 3,
  '5916': 5,
  '5917': 5,
  '5918': 5,
  '5919': 6,
  '591A': 6,
  '591B': 6,
  '591C': 8,
  '591D': 8,
  '591E': 10,
  '591F': 11,
  '5920': 11,
  '5921': 12,
  '5922': 13,
  '5923': 14,
  '5924': 14,
  '5925': 14,
  '5926': 15,
  '5927': 3,
  '5928': 3,
  '5929': 4,
  '592A': 4,
  '592B': 4,
  '592C': 4,
  '592D': 4,
  '592E': 5,
  '592F': 5,
  '5930': 5,
  '5931': 5,
  '5932': 5,
  '5933': 5,
  '5934': 5,
  '5935': 6,
  '5936': 6,
  '5937': 6,
  '5938': 6,
  '5939': 6,
  '593A': 6,
  '593B': 6,
  '593C': 6,
  '593D': 7,
  '593E': 7,
  '593F': 7,
  '5940': 7,
  '5941': 7,
  '5942': 7,
  '5943': 8,
  '5944': 8,
  '5945': 8,
  '5946': 7,
  '5947': 8,
  '5948': 8,
  '5949': 8,
  '594A': 9,
  '594B': 8,
  '594C': 8,
  '594D': 8,
  '594E': 9,
  '594F': 9,
  '5950': 9,
  '5951': 9,
  '5952': 9,
  '5953': 9,
  '5954': 8,
  '5955': 9,
  '5956': 9,
  '5957': 10,
  '5958': 10,
  '5959': 10,
  '595A': 10,
  '595B': 11,
  '595C': 11,
  '595D': 11,
  '595E': 11,
  '595F': 11,
  '5960': 12,
  '5961': 12,
  '5962': 11,
  '5963': 12,
  '5964': 12,
  '5965': 12,
  '5966': 13,
  '5967': 13,
  '5968': 13,
  '5969': 14,
  '596A': 14,
  '596B': 15,
  '596C': 14,
  '596D': 15,
  '596E': 16,
  '596F': 16,
  '5970': 18,
  '5971': 22,
  '5972': 23,
  '5973': 3,
  '5974': 5,
  '5975': 5,
  '5976': 5,
  '5977': 6,
  '5978': 6,
  '5979': 6,
  '597A': 6,
  '597B': 6,
  '597C': 6,
  '597D': 6,
  '597E': 6,
  '597F': 6,
  '5980': 6,
  '5981': 6,
  '5982': 6,
  '5983': 6,
  '5984': 6,
  '5985': 6,
  '5986': 6,
  '5987': 6,
  '5988': 6,
  '5989': 7,
  '598A': 7,
  '598B': 7,
  '598C': 7,
  '598D': 7,
  '598E': 7,
  '598F': 7,
  '5990': 7,
  '5991': 7,
  '5992': 7,
  '5993': 7,
  '5994': 7,
  '5995': 7,
  '5996': 7,
  '5997': 7,
  '5998': 7,
  '5999': 7,
  '599A': 7,
  '599B': 7,
  '599C': 7,
  '599D': 7,
  '599E': 7,
  '599F': 7,
  '59A0': 7,
  '59A1': 7,
  '59A2': 7,
  '59A3': 7,
  '59A4': 7,
  '59A5': 7,
  '59A6': 7,
  '59A7': 7,
  '59A8': 7,
  '59A9': 7,
  '59AA': 7,
  '59AB': 7,
  '59AC': 8,
  '59AD': 8,
  '59AE': 8,
  '59AF': 8,
  '59B0': 8,
  '59B1': 8,
  '59B2': 8,
  '59B3': 8,
  '59B4': 8,
  '59B5': 8,
  '59B6': 8,
  '59B7': 8,
  '59B8': 8,
  '59B9': 8,
  '59BA': 8,
  '59BB': 8,
  '59BC': 8,
  '59BD': 8,
  '59BE': 8,
  '59BF': 8,
  '59C0': 8,
  '59C1': 8,
  '59C2': 7,
  '59C3': 8,
  '59C4': 8,
  '59C5': 8,
  '59C6': 8,
  '59C7': 8,
  '59C8': 8,
  '59C9': 7,
  '59CA': 7,
  '59CB': 8,
  '59CC': 8,
  '59CD': 8,
  '59CE': 8,
  '59CF': 8,
  '59D0': 8,
  '59D1': 8,
  '59D2': 7,
  '59D3': 8,
  '59D4': 8,
  '59D5': 9,
  '59D6': 7,
  '59D7': 8,
  '59D8': 9,
  '59D9': 9,
  '59DA': 9,
  '59DB': 9,
  '59DC': 9,
  '59DD': 9,
  '59DE': 9,
  '59DF': 9,
  '59E0': 9,
  '59E1': 9,
  '59E2': 9,
  '59E3': 9,
  '59E4': 9,
  '59E5': 9,
  '59E6': 9,
  '59E7': 9,
  '59E8': 9,
  '59E9': 9,
  '59EA': 9,
  '59EB': 9,
  '59EC': 10,
  '59ED': 9,
  '59EE': 9,
  '59EF': 9,
  '59F0': 9,
  '59F1': 9,
  '59F2': 9,
  '59F3': 9,
  '59F4': 9,
  '59F5': 9,
  '59F6': 9,
  '59F7': 9,
  '59F8': 9,
  '59F9': 9,
  '59FA': 9,
  '59FB': 9,
  '59FC': 9,
  '59FD': 9,
  '59FE': 9,
  '59FF': 9,
  '5A00': 9,
  '5A01': 9,
  '5A02': 9,
  '5A03': 9,
  '5A04': 9,
  '5A05': 9,
  '5A06': 9,
  '5A07': 9,
  '5A08': 9,
  '5A09': 10,
  '5A0A': 10,
  '5A0B': 10,
  '5A0C': 10,
  '5A0D': 9,
  '5A0E': 10,
  '5A0F': 10,
  '5A10': 10,
  '5A11': 10,
  '5A12': 10,
  '5A13': 10,
  '5A14': 10,
  '5A15': 10,
  '5A16': 10,
  '5A17': 9,
  '5A18': 10,
  '5A19': 10,
  '5A1A': 10,
  '5A1B': 10,
  '5A1C': 9,
  '5A1D': 10,
  '5A1E': 10,
  '5A1F': 10,
  '5A20': 10,
  '5A21': 10,
  '5A22': 10,
  '5A23': 10,
  '5A24': 10,
  '5A25': 10,
  '5A26': 10,
  '5A27': 10,
  '5A28': 10,
  '5A29': 10,
  '5A2A': 10,
  '5A2B': 9,
  '5A2C': 11,
  '5A2D': 10,
  '5A2E': 10,
  '5A2F': 10,
  '5A30': 9,
  '5A31': 10,
  '5A32': 10,
  '5A33': 10,
  '5A34': 10,
  '5A35': 11,
  '5A36': 11,
  '5A37': 11,
  '5A38': 11,
  '5A39': 11,
  '5A3A': 11,
  '5A3B': 11,
  '5A3C': 11,
  '5A3D': 11,
  '5A3E': 11,
  '5A3F': 10,
  '5A40': 10,
  '5A41': 11,
  '5A42': 11,
  '5A43': 11,
  '5A44': 11,
  '5A45': 11,
  '5A46': 11,
  '5A47': 11,
  '5A48': 11,
  '5A49': 11,
  '5A4A': 11,
  '5A4B': 11,
  '5A4C': 11,
  '5A4D': 11,
  '5A4E': 11,
  '5A4F': 11,
  '5A50': 11,
  '5A51': 11,
  '5A52': 11,
  '5A53': 11,
  '5A54': 11,
  '5A55': 11,
  '5A56': 11,
  '5A57': 11,
  '5A58': 11,
  '5A59': 9,
  '5A5A': 11,
  '5A5B': 11,
  '5A5C': 11,
  '5A5D': 11,
  '5A5E': 11,
  '5A5F': 11,
  '5A60': 11,
  '5A61': 11,
  '5A62': 11,
  '5A63': 12,
  '5A64': 11,
  '5A65': 11,
  '5A66': 11,
  '5A67': 11,
  '5A68': 11,
  '5A69': 11,
  '5A6A': 11,
  '5A6B': 11,
  '5A6C': 11,
  '5A6D': 11,
  '5A6E': 11,
  '5A6F': 11,
  '5A70': 11,
  '5A71': 11,
  '5A72': 10,
  '5A73': 11,
  '5A74': 11,
  '5A75': 11,
  '5A76': 11,
  '5A77': 12,
  '5A78': 12,
  '5A79': 12,
  '5A7A': 12,
  '5A7B': 12,
  '5A7C': 11,
  '5A7D': 12,
  '5A7E': 12,
  '5A7F': 12,
  '5A80': 12,
  '5A81': 12,
  '5A82': 12,
  '5A83': 12,
  '5A84': 12,
  '5A85': 12,
  '5A86': 12,
  '5A87': 12,
  '5A88': 12,
  '5A89': 12,
  '5A8A': 12,
  '5A8B': 12,
  '5A8C': 11,
  '5A8D': 12,
  '5A8E': 11,
  '5A8F': 12,
  '5A90': 13,
  '5A91': 12,
  '5A92': 12,
  '5A93': 12,
  '5A94': 12,
  '5A95': 12,
  '5A96': 11,
  '5A97': 12,
  '5A98': 12,
  '5A99': 12,
  '5A9A': 12,
  '5A9B': 12,
  '5A9C': 12,
  '5A9D': 12,
  '5A9E': 12,
  '5A9F': 12,
  '5AA0': 12,
  '5AA1': 12,
  '5AA2': 12,
  '5AA3': 12,
  '5AA4': 12,
  '5AA5': 12,
  '5AA6': 12,
  '5AA7': 11,
  '5AA8': 12,
  '5AA9': 12,
  '5AAA': 12,
  '5AAB': 12,
  '5AAC': 12,
  '5AAD': 12,
  '5AAE': 12,
  '5AAF': 12,
  '5AB0': 13,
  '5AB1': 13,
  '5AB2': 13,
  '5AB3': 13,
  '5AB4': 13,
  '5AB5': 13,
  '5AB6': 12,
  '5AB7': 13,
  '5AB8': 13,
  '5AB9': 13,
  '5ABA': 13,
  '5ABB': 13,
  '5ABC': 13,
  '5ABD': 13,
  '5ABE': 13,
  '5ABF': 12,
  '5AC0': 13,
  '5AC1': 13,
  '5AC2': 12,
  '5AC3': 13,
  '5AC4': 13,
  '5AC5': 12,
  '5AC6': 13,
  '5AC7': 13,
  '5AC8': 13,
  '5AC9': 13,
  '5ACA': 13,
  '5ACB': 13,
  '5ACC': 13,
  '5ACD': 13,
  '5ACE': 13,
  '5ACF': 11,
  '5AD0': 13,
  '5AD1': 13,
  '5AD2': 13,
  '5AD3': 13,
  '5AD4': 13,
  '5AD5': 14,
  '5AD6': 14,
  '5AD7': 14,
  '5AD8': 14,
  '5AD9': 14,
  '5ADA': 14,
  '5ADB': 14,
  '5ADC': 14,
  '5ADD': 14,
  '5ADE': 14,
  '5ADF': 13,
  '5AE0': 14,
  '5AE1': 14,
  '5AE2': 14,
  '5AE3': 14,
  '5AE4': 14,
  '5AE5': 14,
  '5AE6': 14,
  '5AE7': 14,
  '5AE8': 14,
  '5AE9': 14,
  '5AEA': 14,
  '5AEB': 13,
  '5AEC': 14,
  '5AED': 14,
  '5AEE': 14,
  '5AEF': 13,
  '5AF0': 14,
  '5AF1': 14,
  '5AF2': 14,
  '5AF3': 14,
  '5AF4': 15,
  '5AF5': 15,
  '5AF6': 15,
  '5AF7': 14,
  '5AF8': 15,
  '5AF9': 14,
  '5AFA': 15,
  '5AFB': 15,
  '5AFC': 15,
  '5AFD': 15,
  '5AFE': 15,
  '5AFF': 15,
  '5B00': 15,
  '5B01': 15,
  '5B02': 15,
  '5B03': 15,
  '5B04': 15,
  '5B05': 13,
  '5B06': 15,
  '5B07': 15,
  '5B08': 15,
  '5B09': 15,
  '5B0A': 15,
  '5B0B': 15,
  '5B0C': 15,
  '5B0D': 15,
  '5B0E': 15,
  '5B0F': 15,
  '5B10': 16,
  '5B11': 16,
  '5B12': 16,
  '5B13': 16,
  '5B14': 16,
  '5B15': 16,
  '5B16': 16,
  '5B17': 16,
  '5B18': 15,
  '5B19': 16,
  '5B1A': 16,
  '5B1B': 16,
  '5B1C': 16,
  '5B1D': 16,
  '5B1E': 15,
  '5B1F': 16,
  '5B20': 16,
  '5B21': 16,
  '5B22': 16,
  '5B23': 17,
  '5B24': 17,
  '5B25': 17,
  '5B26': 17,
  '5B27': 17,
  '5B28': 16,
  '5B29': 16,
  '5B2A': 17,
  '5B2B': 17,
  '5B2C': 17,
  '5B2D': 17,
  '5B2E': 17,
  '5B2F': 17,
  '5B30': 17,
  '5B31': 17,
  '5B32': 17,
  '5B33': 16,
  '5B34': 16,
  '5B35': 17,
  '5B36': 17,
  '5B37': 17,
  '5B38': 18,
  '5B39': 19,
  '5B3A': 17,
  '5B3B': 18,
  '5B3C': 18,
  '5B3D': 19,
  '5B3E': 19,
  '5B3F': 19,
  '5B40': 20,
  '5B41': 20,
  '5B42': 20,
  '5B43': 20,
  '5B44': 20,
  '5B45': 20,
  '5B46': 20,
  '5B47': 21,
  '5B48': 21,
  '5B49': 20,
  '5B4A': 22,
  '5B4B': 22,
  '5B4C': 22,
  '5B4D': 22,
  '5B4E': 24,
  '5B4F': 23,
  '5B50': 3,
  '5B51': 3,
  '5B52': 3,
  '5B53': 3,
  '5B54': 4,
  '5B55': 5,
  '5B56': 6,
  '5B57': 6,
  '5B58': 6,
  '5B59': 6,
  '5B5A': 7,
  '5B5B': 7,
  '5B5C': 7,
  '5B5D': 7,
  '5B5E': 7,
  '5B5F': 8,
  '5B60': 8,
  '5B61': 8,
  '5B62': 8,
  '5B63': 8,
  '5B64': 8,
  '5B65': 8,
  '5B66': 8,
  '5B67': 8,
  '5B68': 9,
  '5B69': 9,
  '5B6A': 9,
  '5B6B': 10,
  '5B6C': 10,
  '5B6D': 10,
  '5B6E': 11,
  '5B6F': 11,
  '5B70': 11,
  '5B71': 12,
  '5B72': 11,
  '5B73': 12,
  '5B74': 13,
  '5B75': 14,
  '5B76': 13,
  '5B77': 14,
  '5B78': 16,
  '5B79': 16,
  '5B7A': 17,
  '5B7B': 17,
  '5B7C': 19,
  '5B7D': 19,
  '5B7E': 20,
  '5B7F': 22,
  '5B80': 3,
  '5B81': 5,
  '5B82': 5,
  '5B83': 5,
  '5B84': 5,
  '5B85': 6,
  '5B86': 6,
  '5B87': 6,
  '5B88': 6,
  '5B89': 6,
  '5B8A': 7,
  '5B8B': 7,
  '5B8C': 7,
  '5B8D': 7,
  '5B8E': 7,
  '5B8F': 7,
  '5B90': 7,
  '5B91': 7,
  '5B92': 7,
  '5B93': 8,
  '5B94': 8,
  '5B95': 8,
  '5B96': 8,
  '5B97': 8,
  '5B98': 8,
  '5B99': 8,
  '5B9A': 8,
  '5B9B': 8,
  '5B9C': 8,
  '5B9D': 8,
  '5B9E': 8,
  '5B9F': 8,
  '5BA0': 8,
  '5BA1': 8,
  '5BA2': 9,
  '5BA3': 9,
  '5BA4': 9,
  '5BA5': 9,
  '5BA6': 9,
  '5BA7': 10,
  '5BA8': 9,
  '5BA9': 9,
  '5BAA': 9,
  '5BAB': 9,
  '5BAC': 9,
  '5BAD': 10,
  '5BAE': 10,
  '5BAF': 10,
  '5BB0': 10,
  '5BB1': 10,
  '5BB2': 10,
  '5BB3': 10,
  '5BB4': 10,
  '5BB5': 10,
  '5BB6': 10,
  '5BB7': 10,
  '5BB8': 10,
  '5BB9': 10,
  '5BBA': 10,
  '5BBB': 10,
  '5BBC': 10,
  '5BBD': 10,
  '5BBE': 10,
  '5BBF': 11,
  '5BC0': 11,
  '5BC1': 11,
  '5BC2': 11,
  '5BC3': 11,
  '5BC4': 11,
  '5BC5': 11,
  '5BC6': 11,
  '5BC7': 11,
  '5BC8': 11,
  '5BC9': 11,
  '5BCA': 12,
  '5BCB': 12,
  '5BCC': 12,
  '5BCD': 12,
  '5BCE': 12,
  '5BCF': 12,
  '5BD0': 12,
  '5BD1': 12,
  '5BD2': 12,
  '5BD3': 12,
  '5BD4': 12,
  '5BD5': 12,
  '5BD6': 13,
  '5BD7': 13,
  '5BD8': 13,
  '5BD9': 13,
  '5BDA': 13,
  '5BDB': 13,
  '5BDC': 13,
  '5BDD': 13,
  '5BDE': 13,
  '5BDF': 14,
  '5BE0': 14,
  '5BE1': 14,
  '5BE2': 14,
  '5BE3': 14,
  '5BE4': 14,
  '5BE5': 14,
  '5BE6': 14,
  '5BE7': 14,
  '5BE8': 14,
  '5BE9': 15,
  '5BEA': 15,
  '5BEB': 15,
  '5BEC': 14,
  '5BED': 15,
  '5BEE': 15,
  '5BEF': 15,
  '5BF0': 16,
  '5BF1': 17,
  '5BF2': 17,
  '5BF3': 19,
  '5BF4': 19,
  '5BF5': 19,
  '5BF6': 20,
  '5BF7': 21,
  '5BF8': 3,
  '5BF9': 5,
  '5BFA': 6,
  '5BFB': 6,
  '5BFC': 6,
  '5BFD': 7,
  '5BFE': 7,
  '5BFF': 7,
  '5C00': 8,
  '5C01': 9,
  '5C02': 9,
  '5C03': 10,
  '5C04': 10,
  '5C05': 10,
  '5C06': 9,
  '5C07': 11,
  '5C08': 11,
  '5C09': 11,
  '5C0A': 12,
  '5C0B': 12,
  '5C0C': 12,
  '5C0D': 14,
  '5C0E': 15,
  '5C0F': 3,
  '5C10': 4,
  '5C11': 4,
  '5C12': 5,
  '5C13': 5,
  '5C14': 5,
  '5C15': 5,
  '5C16': 6,
  '5C17': 6,
  '5C18': 6,
  '5C19': 8,
  '5C1A': 8,
  '5C1B': 9,
  '5C1C': 9,
  '5C1D': 9,
  '5C1E': 12,
  '5C1F': 13,
  '5C20': 13,
  '5C21': 14,
  '5C22': 3,
  '5C23': 4,
  '5C24': 4,
  '5C25': 6,
  '5C26': 7,
  '5C27': 6,
  '5C28': 7,
  '5C29': 8,
  '5C2A': 7,
  '5C2B': 7,
  '5C2C': 7,
  '5C2D': 8,
  '5C2E': 9,
  '5C2F': 9,
  '5C30': 12,
  '5C31': 12,
  '5C32': 13,
  '5C33': 12,
  '5C34': 13,
  '5C35': 15,
  '5C36': 17,
  '5C37': 17,
  '5C38': 3,
  '5C39': 4,
  '5C3A': 4,
  '5C3B': 5,
  '5C3C': 5,
  '5C3D': 6,
  '5C3E': 7,
  '5C3F': 7,
  '5C40': 7,
  '5C41': 7,
  '5C42': 7,
  '5C43': 7,
  '5C44': 8,
  '5C45': 8,
  '5C46': 8,
  '5C47': 8,
  '5C48': 8,
  '5C49': 8,
  '5C4A': 8,
  '5C4B': 9,
  '5C4C': 9,
  '5C4D': 9,
  '5C4E': 9,
  '5C4F': 9,
  '5C50': 10,
  '5C51': 10,
  '5C52': 10,
  '5C53': 10,
  '5C54': 10,
  '5C55': 10,
  '5C56': 10,
  '5C57': 10,
  '5C58': 10,
  '5C59': 10,
  '5C5A': 11,
  '5C5B': 11,
  '5C5C': 11,
  '5C5D': 11,
  '5C5E': 12,
  '5C5F': 12,
  '5C60': 11,
  '5C61': 12,
  '5C62': 14,
  '5C63': 14,
  '5C64': 15,
  '5C65': 15,
  '5C66': 15,
  '5C67': 15,
  '5C68': 17,
  '5C69': 18,
  '5C6A': 18,
  '5C6B': 19,
  '5C6C': 21,
  '5C6D': 24,
  '5C6E': 3,
  '5C6F': 4,
  '5C70': 6,
  '5C71': 3,
  '5C72': 4,
  '5C73': 5,
  '5C74': 5,
  '5C75': 5,
  '5C76': 5,
  '5C77': 5,
  '5C78': 6,
  '5C79': 6,
  '5C7A': 6,
  '5C7B': 6,
  '5C7C': 6,
  '5C7D': 6,
  '5C7E': 6,
  '5C7F': 6,
  '5C80': 6,
  '5C81': 6,
  '5C82': 6,
  '5C83': 6,
  '5C84': 7,
  '5C85': 7,
  '5C86': 7,
  '5C87': 7,
  '5C88': 7,
  '5C89': 7,
  '5C8A': 7,
  '5C8B': 6,
  '5C8C': 6,
  '5C8D': 7,
  '5C8E': 7,
  '5C8F': 7,
  '5C90': 7,
  '5C91': 7,
  '5C92': 7,
  '5C93': 7,
  '5C94': 7,
  '5C95': 7,
  '5C96': 7,
  '5C97': 7,
  '5C98': 7,
  '5C99': 7,
  '5C9A': 7,
  '5C9B': 7,
  '5C9C': 7,
  '5C9D': 8,
  '5C9E': 8,
  '5C9F': 8,
  '5CA0': 7,
  '5CA1': 8,
  '5CA2': 8,
  '5CA3': 8,
  '5CA4': 8,
  '5CA5': 8,
  '5CA6': 8,
  '5CA7': 8,
  '5CA8': 8,
  '5CA9': 8,
  '5CAA': 8,
  '5CAB': 8,
  '5CAC': 8,
  '5CAD': 8,
  '5CAE': 8,
  '5CAF': 8,
  '5CB0': 8,
  '5CB1': 8,
  '5CB2': 8,
  '5CB3': 8,
  '5CB4': 8,
  '5CB5': 8,
  '5CB6': 8,
  '5CB7': 8,
  '5CB8': 8,
  '5CB9': 8,
  '5CBA': 8,
  '5CBB': 8,
  '5CBC': 8,
  '5CBD': 8,
  '5CBE': 8,
  '5CBF': 8,
  '5CC0': 8,
  '5CC1': 8,
  '5CC2': 8,
  '5CC3': 8,
  '5CC4': 8,
  '5CC5': 8,
  '5CC6': 9,
  '5CC7': 9,
  '5CC8': 9,
  '5CC9': 9,
  '5CCA': 9,
  '5CCB': 9,
  '5CCC': 9,
  '5CCD': 9,
  '5CCE': 9,
  '5CCF': 9,
  '5CD0': 9,
  '5CD1': 9,
  '5CD2': 9,
  '5CD3': 9,
  '5CD4': 9,
  '5CD5': 9,
  '5CD6': 9,
  '5CD7': 9,
  '5CD8': 9,
  '5CD9': 9,
  '5CDA': 9,
  '5CDB': 9,
  '5CDC': 9,
  '5CDD': 9,
  '5CDE': 9,
  '5CDF': 9,
  '5CE0': 9,
  '5CE1': 9,
  '5CE2': 9,
  '5CE3': 9,
  '5CE4': 9,
  '5CE5': 9,
  '5CE6': 9,
  '5CE7': 9,
  '5CE8': 10,
  '5CE9': 10,
  '5CEA': 10,
  '5CEB': 9,
  '5CEC': 10,
  '5CED': 10,
  '5CEE': 10,
  '5CEF': 10,
  '5CF0': 10,
  '5CF1': 10,
  '5CF2': 10,
  '5CF3': 10,
  '5CF4': 10,
  '5CF5': 10,
  '5CF6': 10,
  '5CF7': 10,
  '5CF8': 9,
  '5CF9': 10,
  '5CFA': 10,
  '5CFB': 10,
  '5CFC': 10,
  '5CFD': 10,
  '5CFE': 10,
  '5CFF': 10,
  '5D00': 10,
  '5D01': 10,
  '5D02': 10,
  '5D03': 10,
  '5D04': 10,
  '5D05': 10,
  '5D06': 11,
  '5D07': 11,
  '5D08': 11,
  '5D09': 11,
  '5D0A': 11,
  '5D0B': 10,
  '5D0C': 11,
  '5D0D': 11,
  '5D0E': 11,
  '5D0F': 11,
  '5D10': 11,
  '5D11': 11,
  '5D12': 11,
  '5D13': 11,
  '5D14': 11,
  '5D15': 11,
  '5D16': 11,
  '5D17': 11,
  '5D18': 11,
  '5D19': 11,
  '5D1A': 11,
  '5D1B': 11,
  '5D1C': 11,
  '5D1D': 11,
  '5D1E': 11,
  '5D1F': 11,
  '5D20': 11,
  '5D21': 11,
  '5D22': 11,
  '5D23': 11,
  '5D24': 11,
  '5D25': 11,
  '5D26': 11,
  '5D27': 11,
  '5D28': 11,
  '5D29': 11,
  '5D2A': 11,
  '5D2B': 11,
  '5D2C': 11,
  '5D2D': 11,
  '5D2E': 11,
  '5D2F': 11,
  '5D30': 11,
  '5D31': 12,
  '5D32': 12,
  '5D33': 12,
  '5D34': 12,
  '5D35': 12,
  '5D36': 12,
  '5D37': 12,
  '5D38': 12,
  '5D39': 12,
  '5D3A': 12,
  '5D3B': 12,
  '5D3C': 12,
  '5D3D': 12,
  '5D3E': 12,
  '5D3F': 12,
  '5D40': 12,
  '5D41': 12,
  '5D42': 12,
  '5D43': 12,
  '5D44': 12,
  '5D45': 12,
  '5D46': 12,
  '5D47': 12,
  '5D48': 12,
  '5D49': 12,
  '5D4A': 13,
  '5D4B': 12,
  '5D4C': 12,
  '5D4D': 12,
  '5D4E': 12,
  '5D4F': 12,
  '5D50': 12,
  '5D51': 12,
  '5D52': 12,
  '5D53': 12,
  '5D54': 12,
  '5D55': 12,
  '5D56': 12,
  '5D57': 12,
  '5D58': 12,
  '5D59': 12,
  '5D5A': 12,
  '5D5B': 12,
  '5D5C': 12,
  '5D5D': 12,
  '5D5E': 13,
  '5D5F': 13,
  '5D60': 13,
  '5D61': 13,
  '5D62': 13,
  '5D63': 13,
  '5D64': 13,
  '5D65': 13,
  '5D66': 13,
  '5D67': 13,
  '5D68': 13,
  '5D69': 13,
  '5D6A': 13,
  '5D6B': 12,
  '5D6C': 12,
  '5D6D': 13,
  '5D6E': 13,
  '5D6F': 12,
  '5D70': 13,
  '5D71': 13,
  '5D72': 13,
  '5D73': 12,
  '5D74': 13,
  '5D75': 13,
  '5D76': 13,
  '5D77': 14,
  '5D78': 14,
  '5D79': 15,
  '5D7A': 14,
  '5D7B': 14,
  '5D7C': 14,
  '5D7D': 14,
  '5D7E': 14,
  '5D7F': 14,
  '5D80': 14,
  '5D81': 14,
  '5D82': 14,
  '5D83': 14,
  '5D84': 14,
  '5D85': 13,
  '5D86': 14,
  '5D87': 14,
  '5D88': 14,
  '5D89': 14,
  '5D8A': 14,
  '5D8B': 14,
  '5D8C': 14,
  '5D8D': 14,
  '5D8E': 14,
  '5D8F': 15,
  '5D90': 14,
  '5D91': 14,
  '5D92': 15,
  '5D93': 15,
  '5D94': 15,
  '5D95': 15,
  '5D96': 15,
  '5D97': 15,
  '5D98': 15,
  '5D99': 15,
  '5D9A': 15,
  '5D9B': 15,
  '5D9C': 15,
  '5D9D': 15,
  '5D9E': 14,
  '5D9F': 15,
  '5DA0': 15,
  '5DA1': 15,
  '5DA2': 15,
  '5DA3': 15,
  '5DA4': 15,
  '5DA5': 15,
  '5DA6': 16,
  '5DA7': 16,
  '5DA8': 16,
  '5DA9': 16,
  '5DAA': 16,
  '5DAB': 16,
  '5DAC': 16,
  '5DAD': 16,
  '5DAE': 16,
  '5DAF': 15,
  '5DB0': 16,
  '5DB1': 15,
  '5DB2': 15,
  '5DB3': 16,
  '5DB4': 15,
  '5DB5': 16,
  '5DB6': 16,
  '5DB7': 17,
  '5DB8': 17,
  '5DB9': 17,
  '5DBA': 17,
  '5DBB': 17,
  '5DBC': 16,
  '5DBD': 17,
  '5DBE': 17,
  '5DBF': 17,
  '5DC0': 18,
  '5DC1': 17,
  '5DC2': 18,
  '5DC3': 19,
  '5DC4': 19,
  '5DC5': 19,
  '5DC6': 19,
  '5DC7': 20,
  '5DC8': 20,
  '5DC9': 20,
  '5DCA': 20,
  '5DCB': 21,
  '5DCC': 19,
  '5DCD': 20,
  '5DCE': 22,
  '5DCF': 20,
  '5DD0': 21,
  '5DD1': 22,
  '5DD2': 22,
  '5DD3': 22,
  '5DD4': 22,
  '5DD5': 22,
  '5DD6': 22,
  '5DD7': 22,
  '5DD8': 23,
  '5DD9': 24,
  '5DDA': 23,
  '5DDB': 3,
  '5DDC': 2,
  '5DDD': 3,
  '5DDE': 6,
  '5DDF': 6,
  '5DE0': 7,
  '5DE1': 6,
  '5DE2': 11,
  '5DE3': 11,
  '5DE4': 15,
  '5DE5': 3,
  '5DE6': 5,
  '5DE7': 5,
  '5DE8': 4,
  '5DE9': 6,
  '5DEA': 6,
  '5DEB': 7,
  '5DEC': 9,
  '5DED': 9,
  '5DEE': 9,
  '5DEF': 12,
  '5DF0': 14,
  '5DF1': 3,
  '5DF2': 3,
  '5DF3': 3,
  '5DF4': 4,
  '5DF5': 7,
  '5DF6': 8,
  '5DF7': 9,
  '5DF8': 10,
  '5DF9': 9,
  '5DFA': 9,
  '5DFB': 9,
  '5DFC': 10,
  '5DFD': 12,
  '5DFE': 3,
  '5DFF': 4,
  '5E00': 4,
  '5E01': 4,
  '5E02': 5,
  '5E03': 5,
  '5E04': 5,
  '5E05': 5,
  '5E06': 6,
  '5E07': 6,
  '5E08': 6,
  '5E09': 7,
  '5E0A': 7,
  '5E0B': 7,
  '5E0C': 7,
  '5E0D': 7,
  '5E0E': 7,
  '5E0F': 7,
  '5E10': 7,
  '5E11': 8,
  '5E12': 8,
  '5E13': 8,
  '5E14': 8,
  '5E15': 8,
  '5E16': 8,
  '5E17': 8,
  '5E18': 8,
  '5E19': 8,
  '5E1A': 8,
  '5E1B': 8,
  '5E1C': 8,
  '5E1D': 9,
  '5E1E': 9,
  '5E1F': 9,
  '5E20': 9,
  '5E21': 9,
  '5E22': 9,
  '5E23': 9,
  '5E24': 9,
  '5E25': 9,
  '5E26': 9,
  '5E27': 9,
  '5E28': 10,
  '5E29': 10,
  '5E2A': 10,
  '5E2B': 10,
  '5E2C': 10,
  '5E2D': 10,
  '5E2E': 9,
  '5E2F': 10,
  '5E30': 10,
  '5E31': 10,
  '5E32': 11,
  '5E33': 11,
  '5E34': 11,
  '5E35': 11,
  '5E36': 11,
  '5E37': 11,
  '5E38': 11,
  '5E39': 11,
  '5E3A': 11,
  '5E3B': 11,
  '5E3C': 11,
  '5E3D': 12,
  '5E3E': 11,
  '5E3F': 12,
  '5E40': 12,
  '5E41': 12,
  '5E42': 12,
  '5E43': 12,
  '5E44': 12,
  '5E45': 12,
  '5E46': 12,
  '5E47': 12,
  '5E48': 12,
  '5E49': 12,
  '5E4A': 13,
  '5E4B': 13,
  '5E4C': 13,
  '5E4D': 13,
  '5E4E': 13,
  '5E4F': 13,
  '5E50': 13,
  '5E51': 14,
  '5E52': 14,
  '5E53': 14,
  '5E54': 14,
  '5E55': 13,
  '5E56': 14,
  '5E57': 14,
  '5E58': 14,
  '5E59': 13,
  '5E5A': 14,
  '5E5B': 14,
  '5E5C': 15,
  '5E5D': 15,
  '5E5E': 15,
  '5E5F': 15,
  '5E60': 15,
  '5E61': 15,
  '5E62': 15,
  '5E63': 14,
  '5E64': 15,
  '5E65': 15,
  '5E66': 16,
  '5E67': 16,
  '5E68': 16,
  '5E69': 15,
  '5E6A': 16,
  '5E6B': 17,
  '5E6C': 17,
  '5E6D': 17,
  '5E6E': 18,
  '5E6F': 16,
  '5E70': 19,
  '5E71': 20,
  '5E72': 3,
  '5E73': 5,
  '5E74': 6,
  '5E75': 6,
  '5E76': 6,
  '5E77': 8,
  '5E78': 8,
  '5E79': 13,
  '5E7A': 3,
  '5E7B': 4,
  '5E7C': 5,
  '5E7D': 9,
  '5E7E': 12,
  '5E7F': 3,
  '5E80': 5,
  '5E81': 5,
  '5E82': 5,
  '5E83': 5,
  '5E84': 6,
  '5E85': 6,
  '5E86': 6,
  '5E87': 7,
  '5E88': 7,
  '5E89': 7,
  '5E8A': 7,
  '5E8B': 7,
  '5E8C': 7,
  '5E8D': 7,
  '5E8E': 7,
  '5E8F': 7,
  '5E90': 7,
  '5E91': 7,
  '5E92': 7,
  '5E93': 7,
  '5E94': 7,
  '5E95': 8,
  '5E96': 8,
  '5E97': 8,
  '5E98': 8,
  '5E99': 8,
  '5E9A': 8,
  '5E9B': 9,
  '5E9C': 8,
  '5E9D': 8,
  '5E9E': 8,
  '5E9F': 8,
  '5EA0': 9,
  '5EA1': 9,
  '5EA2': 9,
  '5EA3': 9,
  '5EA4': 9,
  '5EA5': 9,
  '5EA6': 9,
  '5EA7': 10,
  '5EA8': 10,
  '5EA9': 10,
  '5EAA': 10,
  '5EAB': 10,
  '5EAC': 10,
  '5EAD': 9,
  '5EAE': 10,
  '5EAF': 10,
  '5EB0': 9,
  '5EB1': 11,
  '5EB2': 11,
  '5EB3': 11,
  '5EB4': 11,
  '5EB5': 11,
  '5EB6': 11,
  '5EB7': 11,
  '5EB8': 11,
  '5EB9': 11,
  '5EBA': 11,
  '5EBB': 11,
  '5EBC': 11,
  '5EBD': 12,
  '5EBE': 11,
  '5EBF': 11,
  '5EC0': 12,
  '5EC1': 12,
  '5EC2': 12,
  '5EC3': 12,
  '5EC4': 12,
  '5EC5': 13,
  '5EC6': 12,
  '5EC7': 13,
  '5EC8': 13,
  '5EC9': 13,
  '5ECA': 11,
  '5ECB': 12,
  '5ECC': 13,
  '5ECD': 13,
  '5ECE': 14,
  '5ECF': 14,
  '5ED0': 12,
  '5ED1': 14,
  '5ED2': 13,
  '5ED3': 13,
  '5ED4': 14,
  '5ED5': 13,
  '5ED6': 14,
  '5ED7': 14,
  '5ED8': 14,
  '5ED9': 14,
  '5EDA': 15,
  '5EDB': 15,
  '5EDC': 14,
  '5EDD': 15,
  '5EDE': 15,
  '5EDF': 15,
  '5EE0': 15,
  '5EE1': 15,
  '5EE2': 15,
  '5EE3': 14,
  '5EE4': 15,
  '5EE5': 16,
  '5EE6': 16,
  '5EE7': 16,
  '5EE8': 16,
  '5EE9': 16,
  '5EEA': 16,
  '5EEB': 18,
  '5EEC': 19,
  '5EED': 19,
  '5EEE': 20,
  '5EEF': 20,
  '5EF0': 20,
  '5EF1': 21,
  '5EF2': 22,
  '5EF3': 25,
  '5EF4': 2,
  '5EF5': 5,
  '5EF6': 6,
  '5EF7': 6,
  '5EF8': 7,
  '5EF9': 7,
  '5EFA': 8,
  '5EFB': 8,
  '5EFC': 8,
  '5EFD': 9,
  '5EFE': 3,
  '5EFF': 4,
  '5F00': 4,
  '5F01': 5,
  '5F02': 6,
  '5F03': 7,
  '5F04': 7,
  '5F05': 7,
  '5F06': 8,
  '5F07': 9,
  '5F08': 9,
  '5F09': 10,
  '5F0A': 14,
  '5F0B': 3,
  '5F0C': 4,
  '5F0D': 5,
  '5F0E': 6,
  '5F0F': 6,
  '5F10': 6,
  '5F11': 12,
  '5F12': 13,
  '5F13': 3,
  '5F14': 4,
  '5F15': 4,
  '5F16': 4,
  '5F17': 5,
  '5F18': 5,
  '5F19': 6,
  '5F1A': 6,
  '5F1B': 6,
  '5F1C': 6,
  '5F1D': 7,
  '5F1E': 7,
  '5F1F': 7,
  '5F20': 7,
  '5F21': 7,
  '5F22': 8,
  '5F23': 8,
  '5F24': 8,
  '5F25': 8,
  '5F26': 8,
  '5F27': 8,
  '5F28': 8,
  '5F29': 8,
  '5F2A': 8,
  '5F2B': 9,
  '5F2C': 10,
  '5F2D': 9,
  '5F2E': 9,
  '5F2F': 9,
  '5F30': 10,
  '5F31': 10,
  '5F32': 10,
  '5F33': 10,
  '5F34': 11,
  '5F35': 11,
  '5F36': 11,
  '5F37': 11,
  '5F38': 11,
  '5F39': 11,
  '5F3A': 12,
  '5F3B': 12,
  '5F3C': 12,
  '5F3D': 12,
  '5F3E': 12,
  '5F3F': 13,
  '5F40': 13,
  '5F41': 13,
  '5F42': 13,
  '5F43': 13,
  '5F44': 14,
  '5F45': 14,
  '5F46': 14,
  '5F47': 16,
  '5F48': 15,
  '5F49': 14,
  '5F4A': 16,
  '5F4B': 16,
  '5F4C': 17,
  '5F4D': 17,
  '5F4E': 22,
  '5F4F': 23,
  '5F50': 3,
  '5F51': 3,
  '5F52': 5,
  '5F53': 6,
  '5F54': 8,
  '5F55': 8,
  '5F56': 9,
  '5F57': 11,
  '5F58': 12,
  '5F59': 13,
  '5F5A': 13,
  '5F5B': 16,
  '5F5C': 16,
  '5F5D': 18,
  '5F5E': 18,
  '5F5F': 19,
  '5F60': 25,
  '5F61': 3,
  '5F62': 7,
  '5F63': 7,
  '5F64': 7,
  '5F65': 9,
  '5F66': 9,
  '5F67': 10,
  '5F68': 10,
  '5F69': 11,
  '5F6A': 11,
  '5F6B': 11,
  '5F6C': 11,
  '5F6D': 12,
  '5F6E': 13,
  '5F6F': 14,
  '5F70': 14,
  '5F71': 15,
  '5F72': 22,
  '5F73': 3,
  '5F74': 6,
  '5F75': 6,
  '5F76': 6,
  '5F77': 7,
  '5F78': 7,
  '5F79': 7,
  '5F7A': 7,
  '5F7B': 7,
  '5F7C': 8,
  '5F7D': 8,
  '5F7E': 8,
  '5F7F': 8,
  '5F80': 8,
  '5F81': 8,
  '5F82': 8,
  '5F83': 8,
  '5F84': 8,
  '5F85': 9,
  '5F86': 9,
  '5F87': 9,
  '5F88': 9,
  '5F89': 9,
  '5F8A': 9,
  '5F8B': 9,
  '5F8C': 9,
  '5F8D': 9,
  '5F8E': 10,
  '5F8F': 10,
  '5F90': 10,
  '5F91': 10,
  '5F92': 10,
  '5F93': 10,
  '5F94': 9,
  '5F95': 10,
  '5F96': 11,
  '5F97': 11,
  '5F98': 11,
  '5F99': 11,
  '5F9A': 12,
  '5F9B': 11,
  '5F9C': 11,
  '5F9D': 11,
  '5F9E': 11,
  '5F9F': 11,
  '5FA0': 11,
  '5FA1': 12,
  '5FA2': 11,
  '5FA3': 11,
  '5FA4': 11,
  '5FA5': 12,
  '5FA6': 12,
  '5FA7': 12,
  '5FA8': 12,
  '5FA9': 12,
  '5FAA': 12,
  '5FAB': 12,
  '5FAC': 13,
  '5FAD': 13,
  '5FAE': 13,
  '5FAF': 13,
  '5FB0': 13,
  '5FB1': 14,
  '5FB2': 15,
  '5FB3': 14,
  '5FB4': 14,
  '5FB5': 15,
  '5FB6': 14,
  '5FB7': 15,
  '5FB8': 15,
  '5FB9': 15,
  '5FBA': 15,
  '5FBB': 16,
  '5FBC': 16,
  '5FBD': 17,
  '5FBE': 17,
  '5FBF': 19,
  '5FC0': 20,
  '5FC1': 20,
  '5FC2': 21,
  '5FC3': 4,
  '5FC4': 3,
  '5FC5': 5,
  '5FC6': 4,
  '5FC7': 5,
  '5FC8': 6,
  '5FC9': 5,
  '5FCA': 5,
  '5FCB': 6,
  '5FCC': 7,
  '5FCD': 7,
  '5FCE': 7,
  '5FCF': 6,
  '5FD0': 7,
  '5FD1': 7,
  '5FD2': 7,
  '5FD3': 6,
  '5FD4': 6,
  '5FD5': 6,
  '5FD6': 6,
  '5FD7': 7,
  '5FD8': 7,
  '5FD9': 6,
  '5FDA': 6,
  '5FDB': 6,
  '5FDC': 7,
  '5FDD': 8,
  '5FDE': 8,
  '5FDF': 7,
  '5FE0': 8,
  '5FE1': 7,
  '5FE2': 8,
  '5FE3': 6,
  '5FE4': 7,
  '5FE5': 8,
  '5FE6': 7,
  '5FE7': 7,
  '5FE8': 7,
  '5FE9': 8,
  '5FEA': 7,
  '5FEB': 7,
  '5FEC': 7,
  '5FED': 7,
  '5FEE': 7,
  '5FEF': 7,
  '5FF0': 7,
  '5FF1': 7,
  '5FF2': 7,
  '5FF3': 7,
  '5FF4': 7,
  '5FF5': 8,
  '5FF6': 7,
  '5FF7': 7,
  '5FF8': 7,
  '5FF9': 7,
  '5FFA': 7,
  '5FFB': 7,
  '5FFC': 7,
  '5FFD': 8,
  '5FFE': 7,
  '5FFF': 8,
  '6000': 7,
  '6001': 8,
  '6002': 8,
  '6003': 7,
  '6004': 7,
  '6005': 7,
  '6006': 7,
  '6007': 7,
  '6008': 8,
  '6009': 8,
  '600A': 8,
  '600B': 8,
  '600C': 8,
  '600D': 8,
  '600E': 9,
  '600F': 8,
  '6010': 8,
  '6011': 8,
  '6012': 9,
  '6013': 8,
  '6014': 8,
  '6015': 8,
  '6016': 8,
  '6017': 8,
  '6018': 9,
  '6019': 8,
  '601A': 8,
  '601B': 8,
  '601C': 8,
  '601D': 9,
  '601E': 8,
  '601F': 8,
  '6020': 9,
  '6021': 8,
  '6022': 8,
  '6023': 9,
  '6024': 9,
  '6025': 9,
  '6026': 8,
  '6027': 8,
  '6028': 9,
  '6029': 8,
  '602A': 8,
  '602B': 8,
  '602C': 8,
  '602D': 8,
  '602E': 8,
  '602F': 8,
  '6030': 8,
  '6031': 9,
  '6032': 8,
  '6033': 8,
  '6034': 8,
  '6035': 8,
  '6036': 8,
  '6037': 9,
  '6038': 9,
  '6039': 9,
  '603A': 8,
  '603B': 9,
  '603C': 9,
  '603D': 8,
  '603E': 8,
  '603F': 8,
  '6040': 9,
  '6041': 10,
  '6042': 9,
  '6043': 9,
  '6044': 9,
  '6045': 9,
  '6046': 9,
  '6047': 9,
  '6048': 9,
  '6049': 9,
  '604A': 9,
  '604B': 10,
  '604C': 9,
  '604D': 9,
  '604E': 9,
  '604F': 10,
  '6050': 10,
  '6051': 9,
  '6052': 9,
  '6053': 9,
  '6054': 9,
  '6055': 10,
  '6056': 10,
  '6057': 9,
  '6058': 9,
  '6059': 10,
  '605A': 10,
  '605B': 9,
  '605C': 9,
  '605D': 10,
  '605E': 9,
  '605F': 9,
  '6060': 9,
  '6061': 9,
  '6062': 9,
  '6063': 10,
  '6064': 9,
  '6065': 10,
  '6066': 9,
  '6067': 10,
  '6068': 9,
  '6069': 10,
  '606A': 9,
  '606B': 9,
  '606C': 9,
  '606D': 10,
  '606E': 9,
  '606F': 10,
  '6070': 9,
  '6071': 9,
  '6072': 9,
  '6073': 10,
  '6074': 10,
  '6075': 10,
  '6076': 10,
  '6077': 10,
  '6078': 9,
  '6079': 9,
  '607A': 9,
  '607B': 9,
  '607C': 9,
  '607D': 9,
  '607E': 9,
  '607F': 11,
  '6080': 10,
  '6081': 10,
  '6082': 10,
  '6083': 10,
  '6084': 10,
  '6085': 10,
  '6086': 11,
  '6087': 10,
  '6088': 10,
  '6089': 11,
  '608A': 11,
  '608B': 10,
  '608C': 10,
  '608D': 10,
  '608E': 10,
  '608F': 10,
  '6090': 11,
  '6091': 10,
  '6092': 10,
  '6093': 10,
  '6094': 10,
  '6095': 10,
  '6096': 10,
  '6097': 10,
  '6098': 11,
  '6099': 10,
  '609A': 10,
  '609B': 10,
  '609C': 10,
  '609D': 10,
  '609E': 10,
  '609F': 10,
  '60A0': 11,
  '60A1': 11,
  '60A2': 10,
  '60A3': 11,
  '60A4': 11,
  '60A5': 11,
  '60A6': 10,
  '60A7': 10,
  '60A8': 11,
  '60A9': 10,
  '60AA': 11,
  '60AB': 11,
  '60AC': 11,
  '60AD': 10,
  '60AE': 10,
  '60AF': 10,
  '60B0': 11,
  '60B1': 11,
  '60B2': 12,
  '60B3': 12,
  '60B4': 11,
  '60B5': 11,
  '60B6': 12,
  '60B7': 11,
  '60B8': 11,
  '60B9': 12,
  '60BA': 11,
  '60BB': 11,
  '60BC': 11,
  '60BD': 11,
  '60BE': 11,
  '60BF': 11,
  '60C0': 11,
  '60C1': 12,
  '60C2': 11,
  '60C3': 11,
  '60C4': 12,
  '60C5': 11,
  '60C6': 11,
  '60C7': 11,
  '60C8': 11,
  '60C9': 12,
  '60CA': 11,
  '60CB': 11,
  '60CC': 12,
  '60CD': 11,
  '60CE': 12,
  '60CF': 11,
  '60D0': 11,
  '60D1': 12,
  '60D2': 12,
  '60D3': 11,
  '60D4': 11,
  '60D5': 11,
  '60D6': 12,
  '60D7': 11,
  '60D8': 11,
  '60D9': 11,
  '60DA': 11,
  '60DB': 11,
  '60DC': 11,
  '60DD': 11,
  '60DE': 11,
  '60DF': 11,
  '60E0': 12,
  '60E1': 12,
  '60E2': 12,
  '60E3': 12,
  '60E4': 11,
  '60E5': 12,
  '60E6': 11,
  '60E7': 11,
  '60E8': 11,
  '60E9': 12,
  '60EA': 12,
  '60EB': 12,
  '60EC': 11,
  '60ED': 11,
  '60EE': 11,
  '60EF': 11,
  '60F0': 12,
  '60F1': 12,
  '60F2': 12,
  '60F3': 13,
  '60F4': 12,
  '60F5': 12,
  '60F6': 12,
  '60F7': 13,
  '60F8': 12,
  '60F9': 12,
  '60FA': 12,
  '60FB': 12,
  '60FC': 12,
  '60FD': 12,
  '60FE': 12,
  '60FF': 12,
  '6100': 12,
  '6101': 13,
  '6102': 13,
  '6103': 12,
  '6104': 12,
  '6105': 12,
  '6106': 13,
  '6107': 12,
  '6108': 13,
  '6109': 12,
  '610A': 12,
  '610B': 12,
  '610C': 12,
  '610D': 13,
  '610E': 12,
  '610F': 13,
  '6110': 12,
  '6111': 12,
  '6112': 12,
  '6113': 12,
  '6114': 12,
  '6115': 12,
  '6116': 12,
  '6117': 13,
  '6118': 12,
  '6119': 13,
  '611A': 13,
  '611B': 13,
  '611C': 12,
  '611D': 12,
  '611E': 12,
  '611F': 13,
  '6120': 12,
  '6121': 12,
  '6122': 12,
  '6123': 12,
  '6124': 12,
  '6125': 11,
  '6126': 12,
  '6127': 12,
  '6128': 14,
  '6129': 13,
  '612A': 13,
  '612B': 13,
  '612C': 14,
  '612D': 13,
  '612E': 13,
  '612F': 13,
  '6130': 13,
  '6131': 13,
  '6132': 12,
  '6133': 14,
  '6134': 13,
  '6135': 13,
  '6136': 13,
  '6137': 13,
  '6138': 14,
  '6139': 13,
  '613A': 12,
  '613B': 14,
  '613C': 13,
  '613D': 13,
  '613E': 13,
  '613F': 14,
  '6140': 13,
  '6141': 14,
  '6142': 14,
  '6143': 13,
  '6144': 13,
  '6145': 12,
  '6146': 13,
  '6147': 14,
  '6148': 13,
  '6149': 13,
  '614A': 13,
  '614B': 14,
  '614C': 12,
  '614D': 13,
  '614E': 13,
  '614F': 13,
  '6150': 14,
  '6151': 13,
  '6152': 14,
  '6153': 14,
  '6154': 13,
  '6155': 14,
  '6156': 14,
  '6157': 15,
  '6158': 14,
  '6159': 15,
  '615A': 14,
  '615B': 14,
  '615C': 15,
  '615D': 14,
  '615E': 14,
  '615F': 14,
  '6160': 13,
  '6161': 14,
  '6162': 14,
  '6163': 14,
  '6164': 15,
  '6165': 13,
  '6166': 15,
  '6167': 15,
  '6168': 12,
  '6169': 13,
  '616A': 14,
  '616B': 15,
  '616C': 14,
  '616D': 15,
  '616E': 15,
  '616F': 14,
  '6170': 15,
  '6171': 14,
  '6172': 14,
  '6173': 14,
  '6174': 14,
  '6175': 14,
  '6176': 15,
  '6177': 14,
  '6178': 15,
  '6179': 15,
  '617A': 14,
  '617B': 14,
  '617C': 15,
  '617D': 14,
  '617E': 15,
  '617F': 15,
  '6180': 14,
  '6181': 14,
  '6182': 15,
  '6183': 15,
  '6184': 15,
  '6185': 15,
  '6186': 14,
  '6187': 15,
  '6188': 14,
  '6189': 15,
  '618A': 16,
  '618B': 15,
  '618C': 16,
  '618D': 15,
  '618E': 15,
  '618F': 14,
  '6190': 15,
  '6191': 16,
  '6192': 15,
  '6193': 15,
  '6194': 15,
  '6195': 15,
  '6196': 16,
  '6197': 16,
  '6198': 15,
  '6199': 16,
  '619A': 15,
  '619B': 15,
  '619C': 14,
  '619D': 16,
  '619E': 15,
  '619F': 15,
  '61A0': 16,
  '61A1': 15,
  '61A2': 15,
  '61A3': 15,
  '61A4': 15,
  '61A5': 16,
  '61A6': 15,
  '61A7': 15,
  '61A8': 15,
  '61A9': 16,
  '61AA': 15,
  '61AB': 15,
  '61AC': 15,
  '61AD': 15,
  '61AE': 15,
  '61AF': 15,
  '61B0': 15,
  '61B1': 15,
  '61B2': 16,
  '61B3': 15,
  '61B4': 16,
  '61B5': 17,
  '61B6': 16,
  '61B7': 16,
  '61B8': 16,
  '61B9': 16,
  '61BA': 16,
  '61BB': 16,
  '61BC': 16,
  '61BD': 15,
  '61BE': 16,
  '61BF': 16,
  '61C0': 16,
  '61C1': 16,
  '61C2': 15,
  '61C3': 17,
  '61C4': 16,
  '61C5': 16,
  '61C6': 16,
  '61C7': 17,
  '61C8': 16,
  '61C9': 17,
  '61CA': 15,
  '61CB': 17,
  '61CC': 16,
  '61CD': 16,
  '61CE': 16,
  '61CF': 15,
  '61D0': 16,
  '61D1': 17,
  '61D2': 16,
  '61D3': 16,
  '61D4': 16,
  '61D5': 18,
  '61D6': 18,
  '61D7': 17,
  '61D8': 18,
  '61D9': 16,
  '61DA': 17,
  '61DB': 17,
  '61DC': 16,
  '61DD': 17,
  '61DE': 16,
  '61DF': 18,
  '61E0': 17,
  '61E1': 17,
  '61E2': 17,
  '61E3': 18,
  '61E4': 17,
  '61E5': 17,
  '61E6': 17,
  '61E7': 17,
  '61E8': 17,
  '61E9': 17,
  '61EA': 18,
  '61EB': 18,
  '61EC': 18,
  '61ED': 17,
  '61EE': 18,
  '61EF': 19,
  '61F0': 18,
  '61F1': 17,
  '61F2': 19,
  '61F3': 18,
  '61F4': 18,
  '61F5': 18,
  '61F6': 19,
  '61F7': 19,
  '61F8': 20,
  '61F9': 20,
  '61FA': 20,
  '61FB': 19,
  '61FC': 21,
  '61FD': 20,
  '61FE': 21,
  '61FF': 22,
  '6200': 23,
  '6201': 23,
  '6202': 22,
  '6203': 23,
  '6204': 23,
  '6205': 25,
  '6206': 25,
  '6207': 28,
  '6208': 4,
  '6209': 5,
  '620A': 5,
  '620B': 5,
  '620C': 6,
  '620D': 6,
  '620E': 6,
  '620F': 6,
  '6210': 6,
  '6211': 7,
  '6212': 7,
  '6213': 7,
  '6214': 8,
  '6215': 8,
  '6216': 8,
  '6217': 8,
  '6218': 9,
  '6219': 10,
  '621A': 11,
  '621B': 11,
  '621C': 11,
  '621D': 11,
  '621E': 12,
  '621F': 12,
  '6220': 12,
  '6221': 13,
  '6222': 12,
  '6223': 13,
  '6224': 13,
  '6225': 13,
  '6226': 13,
  '6227': 14,
  '6228': 14,
  '6229': 14,
  '622A': 14,
  '622B': 14,
  '622C': 14,
  '622D': 15,
  '622E': 15,
  '622F': 15,
  '6230': 16,
  '6231': 16,
  '6232': 17,
  '6233': 18,
  '6234': 17,
  '6235': 22,
  '6236': 4,
  '6237': 4,
  '6238': 4,
  '6239': 5,
  '623A': 7,
  '623B': 7,
  '623C': 7,
  '623D': 8,
  '623E': 8,
  '623F': 8,
  '6240': 8,
  '6241': 9,
  '6242': 9,
  '6243': 9,
  '6244': 10,
  '6245': 10,
  '6246': 10,
  '6247': 10,
  '6248': 11,
  '6249': 12,
  '624A': 12,
  '624B': 4,
  '624C': 3,
  '624D': 3,
  '624E': 4,
  '624F': 5,
  '6250': 5,
  '6251': 5,
  '6252': 5,
  '6253': 5,
  '6254': 5,
  '6255': 5,
  '6256': 5,
  '6257': 6,
  '6258': 6,
  '6259': 6,
  '625A': 6,
  '625B': 6,
  '625C': 6,
  '625D': 6,
  '625E': 6,
  '625F': 6,
  '6260': 6,
  '6261': 6,
  '6262': 6,
  '6263': 6,
  '6264': 6,
  '6265': 6,
  '6266': 6,
  '6267': 6,
  '6268': 6,
  '6269': 6,
  '626A': 6,
  '626B': 6,
  '626C': 6,
  '626D': 7,
  '626E': 7,
  '626F': 7,
  '6270': 7,
  '6271': 6,
  '6272': 7,
  '6273': 7,
  '6274': 7,
  '6275': 7,
  '6276': 7,
  '6277': 7,
  '6278': 7,
  '6279': 7,
  '627A': 7,
  '627B': 7,
  '627C': 7,
  '627D': 7,
  '627E': 7,
  '627F': 8,
  '6280': 7,
  '6281': 7,
  '6282': 7,
  '6283': 7,
  '6284': 7,
  '6285': 7,
  '6286': 7,
  '6287': 7,
  '6288': 7,
  '6289': 7,
  '628A': 7,
  '628B': 7,
  '628C': 7,
  '628D': 7,
  '628E': 7,
  '628F': 7,
  '6290': 7,
  '6291': 7,
  '6292': 7,
  '6293': 7,
  '6294': 7,
  '6295': 7,
  '6296': 7,
  '6297': 7,
  '6298': 7,
  '6299': 7,
  '629A': 7,
  '629B': 7,
  '629C': 7,
  '629D': 7,
  '629E': 7,
  '629F': 7,
  '62A0': 7,
  '62A1': 7,
  '62A2': 7,
  '62A3': 7,
  '62A4': 7,
  '62A5': 7,
  '62A6': 8,
  '62A7': 8,
  '62A8': 8,
  '62A9': 8,
  '62AA': 8,
  '62AB': 8,
  '62AC': 8,
  '62AD': 8,
  '62AE': 8,
  '62AF': 8,
  '62B0': 8,
  '62B1': 8,
  '62B2': 8,
  '62B3': 8,
  '62B4': 8,
  '62B5': 8,
  '62B6': 8,
  '62B7': 8,
  '62B8': 7,
  '62B9': 8,
  '62BA': 8,
  '62BB': 8,
  '62BC': 8,
  '62BD': 8,
  '62BE': 8,
  '62BF': 8,
  '62C0': 8,
  '62C1': 8,
  '62C2': 8,
  '62C3': 8,
  '62C4': 8,
  '62C5': 8,
  '62C6': 8,
  '62C7': 8,
  '62C8': 8,
  '62C9': 8,
  '62CA': 8,
  '62CB': 8,
  '62CC': 8,
  '62CD': 8,
  '62CE': 8,
  '62CF': 9,
  '62D0': 8,
  '62D1': 8,
  '62D2': 7,
  '62D3': 8,
  '62D4': 8,
  '62D5': 8,
  '62D6': 8,
  '62D7': 8,
  '62D8': 8,
  '62D9': 8,
  '62DA': 8,
  '62DB': 8,
  '62DC': 9,
  '62DD': 8,
  '62DE': 8,
  '62DF': 7,
  '62E0': 8,
  '62E1': 8,
  '62E2': 8,
  '62E3': 8,
  '62E4': 8,
  '62E5': 8,
  '62E6': 8,
  '62E7': 8,
  '62E8': 8,
  '62E9': 8,
  '62EA': 9,
  '62EB': 9,
  '62EC': 9,
  '62ED': 9,
  '62EE': 9,
  '62EF': 9,
  '62F0': 9,
  '62F1': 9,
  '62F2': 10,
  '62F3': 10,
  '62F4': 9,
  '62F5': 9,
  '62F6': 9,
  '62F7': 9,
  '62F8': 9,
  '62F9': 9,
  '62FA': 9,
  '62FB': 9,
  '62FC': 9,
  '62FD': 9,
  '62FE': 9,
  '62FF': 10,
  '6300': 9,
  '6301': 9,
  '6302': 9,
  '6303': 9,
  '6304': 9,
  '6305': 9,
  '6306': 9,
  '6307': 9,
  '6308': 10,
  '6309': 9,
  '630A': 9,
  '630B': 9,
  '630C': 9,
  '630D': 9,
  '630E': 9,
  '630F': 9,
  '6310': 10,
  '6311': 9,
  '6312': 9,
  '6313': 9,
  '6314': 9,
  '6315': 9,
  '6316': 9,
  '6317': 9,
  '6318': 9,
  '6319': 10,
  '631A': 10,
  '631B': 10,
  '631C': 9,
  '631D': 9,
  '631E': 9,
  '631F': 9,
  '6320': 9,
  '6321': 9,
  '6322': 9,
  '6323': 9,
  '6324': 9,
  '6325': 9,
  '6326': 9,
  '6327': 9,
  '6328': 10,
  '6329': 10,
  '632A': 9,
  '632B': 10,
  '632C': 10,
  '632D': 10,
  '632E': 10,
  '632F': 10,
  '6330': 10,
  '6331': 10,
  '6332': 11,
  '6333': 10,
  '6334': 10,
  '6335': 10,
  '6336': 10,
  '6337': 9,
  '6338': 10,
  '6339': 10,
  '633A': 9,
  '633B': 9,
  '633C': 10,
  '633D': 10,
  '633E': 10,
  '633F': 10,
  '6340': 10,
  '6341': 10,
  '6342': 10,
  '6343': 10,
  '6344': 10,
  '6345': 10,
  '6346': 10,
  '6347': 10,
  '6348': 10,
  '6349': 10,
  '634A': 10,
  '634B': 10,
  '634C': 10,
  '634D': 10,
  '634E': 10,
  '634F': 10,
  '6350': 10,
  '6351': 10,
  '6352': 10,
  '6353': 9,
  '6354': 10,
  '6355': 10,
  '6356': 10,
  '6357': 10,
  '6358': 10,
  '6359': 10,
  '635A': 10,
  '635B': 9,
  '635C': 10,
  '635D': 10,
  '635E': 10,
  '635F': 10,
  '6360': 10,
  '6361': 10,
  '6362': 10,
  '6363': 10,
  '6364': 10,
  '6365': 11,
  '6366': 11,
  '6367': 11,
  '6368': 11,
  '6369': 11,
  '636A': 11,
  '636B': 11,
  '636C': 11,
  '636D': 11,
  '636E': 11,
  '636F': 11,
  '6370': 11,
  '6371': 11,
  '6372': 11,
  '6373': 11,
  '6374': 11,
  '6375': 11,
  '6376': 11,
  '6377': 11,
  '6378': 11,
  '6379': 11,
  '637A': 11,
  '637B': 11,
  '637C': 11,
  '637D': 11,
  '637E': 11,
  '637F': 11,
  '6380': 11,
  '6381': 11,
  '6382': 11,
  '6383': 11,
  '6384': 11,
  '6385': 11,
  '6386': 11,
  '6387': 11,
  '6388': 11,
  '6389': 11,
  '638A': 11,
  '638B': 11,
  '638C': 12,
  '638D': 11,
  '638E': 11,
  '638F': 11,
  '6390': 11,
  '6391': 11,
  '6392': 11,
  '6393': 11,
  '6394': 12,
  '6395': 11,
  '6396': 11,
  '6397': 11,
  '6398': 11,
  '6399': 11,
  '639A': 11,
  '639B': 11,
  '639C': 11,
  '639D': 11,
  '639E': 11,
  '639F': 11,
  '63A0': 11,
  '63A1': 11,
  '63A2': 11,
  '63A3': 12,
  '63A4': 11,
  '63A5': 11,
  '63A6': 11,
  '63A7': 11,
  '63A8': 11,
  '63A9': 11,
  '63AA': 11,
  '63AB': 11,
  '63AC': 11,
  '63AD': 11,
  '63AE': 11,
  '63AF': 11,
  '63B0': 12,
  '63B1': 12,
  '63B2': 11,
  '63B3': 11,
  '63B4': 11,
  '63B5': 11,
  '63B6': 11,
  '63B7': 11,
  '63B8': 11,
  '63B9': 11,
  '63BA': 11,
  '63BB': 11,
  '63BC': 11,
  '63BD': 11,
  '63BE': 12,
  '63BF': 11,
  '63C0': 12,
  '63C1': 12,
  '63C2': 12,
  '63C3': 12,
  '63C4': 12,
  '63C5': 13,
  '63C6': 12,
  '63C7': 12,
  '63C8': 12,
  '63C9': 12,
  '63CA': 12,
  '63CB': 12,
  '63CC': 12,
  '63CD': 12,
  '63CE': 12,
  '63CF': 11,
  '63D0': 12,
  '63D1': 12,
  '63D2': 12,
  '63D3': 12,
  '63D4': 12,
  '63D5': 12,
  '63D6': 12,
  '63D7': 12,
  '63D8': 12,
  '63D9': 12,
  '63DA': 12,
  '63DB': 12,
  '63DC': 12,
  '63DD': 12,
  '63DE': 12,
  '63DF': 12,
  '63E0': 12,
  '63E1': 12,
  '63E2': 12,
  '63E3': 12,
  '63E4': 10,
  '63E5': 12,
  '63E6': 12,
  '63E7': 13,
  '63E8': 12,
  '63E9': 12,
  '63EA': 12,
  '63EB': 13,
  '63EC': 12,
  '63ED': 12,
  '63EE': 12,
  '63EF': 12,
  '63F0': 12,
  '63F1': 13,
  '63F2': 12,
  '63F3': 12,
  '63F4': 12,
  '63F5': 11,
  '63F6': 11,
  '63F7': 12,
  '63F8': 12,
  '63F9': 12,
  '63FA': 12,
  '63FB': 12,
  '63FC': 12,
  '63FD': 12,
  '63FE': 12,
  '63FF': 12,
  '6400': 12,
  '6401': 12,
  '6402': 12,
  '6403': 12,
  '6404': 12,
  '6405': 12,
  '6406': 13,
  '6407': 13,
  '6408': 13,
  '6409': 13,
  '640A': 13,
  '640B': 13,
  '640C': 13,
  '640D': 13,
  '640E': 13,
  '640F': 13,
  '6410': 13,
  '6411': 12,
  '6412': 13,
  '6413': 12,
  '6414': 12,
  '6415': 13,
  '6416': 13,
  '6417': 13,
  '6418': 13,
  '6419': 13,
  '641A': 13,
  '641B': 13,
  '641C': 12,
  '641D': 13,
  '641E': 13,
  '641F': 13,
  '6420': 13,
  '6421': 13,
  '6422': 13,
  '6423': 13,
  '6424': 13,
  '6425': 12,
  '6426': 13,
  '6427': 13,
  '6428': 13,
  '6429': 13,
  '642A': 13,
  '642B': 14,
  '642C': 13,
  '642D': 12,
  '642E': 13,
  '642F': 13,
  '6430': 12,
  '6431': 13,
  '6432': 13,
  '6433': 13,
  '6434': 14,
  '6435': 13,
  '6436': 13,
  '6437': 13,
  '6438': 13,
  '6439': 13,
  '643A': 13,
  '643B': 14,
  '643C': 13,
  '643D': 12,
  '643E': 13,
  '643F': 14,
  '6440': 13,
  '6441': 13,
  '6442': 13,
  '6443': 13,
  '6444': 13,
  '6445': 13,
  '6446': 13,
  '6447': 13,
  '6448': 13,
  '6449': 13,
  '644A': 13,
  '644B': 13,
  '644C': 14,
  '644D': 14,
  '644E': 14,
  '644F': 14,
  '6450': 14,
  '6451': 14,
  '6452': 12,
  '6453': 13,
  '6454': 14,
  '6455': 14,
  '6456': 14,
  '6457': 14,
  '6458': 14,
  '6459': 13,
  '645A': 14,
  '645B': 13,
  '645C': 14,
  '645D': 14,
  '645E': 14,
  '645F': 14,
  '6460': 14,
  '6461': 12,
  '6462': 14,
  '6463': 14,
  '6464': 14,
  '6465': 14,
  '6466': 14,
  '6467': 14,
  '6468': 15,
  '6469': 15,
  '646A': 14,
  '646B': 14,
  '646C': 14,
  '646D': 14,
  '646E': 14,
  '646F': 15,
  '6470': 15,
  '6471': 14,
  '6472': 14,
  '6473': 14,
  '6474': 14,
  '6475': 14,
  '6476': 14,
  '6477': 14,
  '6478': 13,
  '6479': 14,
  '647A': 14,
  '647B': 14,
  '647C': 14,
  '647D': 14,
  '647E': 15,
  '647F': 14,
  '6480': 15,
  '6481': 14,
  '6482': 14,
  '6483': 15,
  '6484': 14,
  '6485': 15,
  '6486': 15,
  '6487': 14,
  '6488': 15,
  '6489': 16,
  '648A': 15,
  '648B': 15,
  '648C': 15,
  '648D': 15,
  '648E': 15,
  '648F': 15,
  '6490': 15,
  '6491': 15,
  '6492': 15,
  '6493': 15,
  '6494': 15,
  '6495': 15,
  '6496': 14,
  '6497': 14,
  '6498': 15,
  '6499': 15,
  '649A': 15,
  '649B': 15,
  '649C': 15,
  '649D': 15,
  '649E': 15,
  '649F': 15,
  '64A0': 15,
  '64A1': 15,
  '64A2': 15,
  '64A3': 15,
  '64A4': 15,
  '64A5': 15,
  '64A6': 14,
  '64A7': 15,
  '64A8': 15,
  '64A9': 15,
  '64AA': 15,
  '64AB': 15,
  '64AC': 15,
  '64AD': 15,
  '64AE': 15,
  '64AF': 14,
  '64B0': 15,
  '64B1': 14,
  '64B2': 15,
  '64B3': 15,
  '64B4': 15,
  '64B5': 15,
  '64B6': 13,
  '64B7': 15,
  '64B8': 15,
  '64B9': 15,
  '64BA': 15,
  '64BB': 15,
  '64BC': 16,
  '64BD': 16,
  '64BE': 14,
  '64BF': 16,
  '64C0': 16,
  '64C1': 16,
  '64C2': 16,
  '64C3': 16,
  '64C4': 16,
  '64C5': 16,
  '64C6': 14,
  '64C7': 16,
  '64C8': 16,
  '64C9': 16,
  '64CA': 17,
  '64CB': 16,
  '64CC': 16,
  '64CD': 16,
  '64CE': 16,
  '64CF': 15,
  '64D0': 16,
  '64D1': 15,
  '64D2': 15,
  '64D3': 16,
  '64D4': 16,
  '64D5': 15,
  '64D6': 15,
  '64D7': 16,
  '64D8': 17,
  '64D9': 16,
  '64DA': 16,
  '64DB': 15,
  '64DC': 16,
  '64DD': 16,
  '64DE': 16,
  '64DF': 17,
  '64E0': 17,
  '64E1': 17,
  '64E2': 17,
  '64E3': 17,
  '64E4': 17,
  '64E5': 18,
  '64E6': 17,
  '64E7': 17,
  '64E8': 17,
  '64E9': 17,
  '64EA': 18,
  '64EB': 17,
  '64EC': 17,
  '64ED': 16,
  '64EE': 17,
  '64EF': 17,
  '64F0': 17,
  '64F1': 17,
  '64F2': 17,
  '64F3': 16,
  '64F4': 17,
  '64F5': 18,
  '64F6': 18,
  '64F7': 18,
  '64F8': 18,
  '64F9': 18,
  '64FA': 18,
  '64FB': 18,
  '64FC': 18,
  '64FD': 18,
  '64FE': 18,
  '64FF': 17,
  '6500': 19,
  '6501': 17,
  '6502': 18,
  '6503': 17,
  '6504': 18,
  '6505': 18,
  '6506': 18,
  '6507': 19,
  '6508': 19,
  '6509': 19,
  '650A': 19,
  '650B': 19,
  '650C': 19,
  '650D': 19,
  '650E': 19,
  '650F': 19,
  '6510': 19,
  '6511': 19,
  '6512': 19,
  '6513': 20,
  '6514': 20,
  '6515': 20,
  '6516': 20,
  '6517': 20,
  '6518': 20,
  '6519': 20,
  '651A': 19,
  '651B': 21,
  '651C': 21,
  '651D': 21,
  '651E': 22,
  '651F': 22,
  '6520': 22,
  '6521': 21,
  '6522': 22,
  '6523': 23,
  '6524': 22,
  '6525': 23,
  '6526': 22,
  '6527': 22,
  '6528': 23,
  '6529': 23,
  '652A': 23,
  '652B': 23,
  '652C': 24,
  '652D': 24,
  '652E': 25,
  '652F': 4,
  '6530': 6,
  '6531': 9,
  '6532': 12,
  '6533': 16,
  '6534': 4,
  '6535': 4,
  '6536': 6,
  '6537': 6,
  '6538': 7,
  '6539': 7,
  '653A': 7,
  '653B': 7,
  '653C': 7,
  '653D': 8,
  '653E': 8,
  '653F': 9,
  '6540': 9,
  '6541': 9,
  '6542': 9,
  '6543': 9,
  '6544': 9,
  '6545': 9,
  '6546': 10,
  '6547': 10,
  '6548': 10,
  '6549': 10,
  '654A': 10,
  '654B': 10,
  '654C': 10,
  '654D': 11,
  '654E': 11,
  '654F': 11,
  '6550': 11,
  '6551': 11,
  '6552': 11,
  '6553': 11,
  '6554': 11,
  '6555': 11,
  '6556': 10,
  '6557': 11,
  '6558': 11,
  '6559': 11,
  '655A': 11,
  '655B': 11,
  '655C': 12,
  '655D': 11,
  '655E': 12,
  '655F': 12,
  '6560': 12,
  '6561': 12,
  '6562': 11,
  '6563': 12,
  '6564': 12,
  '6565': 12,
  '6566': 12,
  '6567': 12,
  '6568': 12,
  '6569': 12,
  '656A': 12,
  '656B': 13,
  '656C': 12,
  '656D': 13,
  '656E': 13,
  '656F': 13,
  '6570': 13,
  '6571': 14,
  '6572': 14,
  '6573': 14,
  '6574': 16,
  '6575': 15,
  '6576': 14,
  '6577': 15,
  '6578': 15,
  '6579': 15,
  '657A': 15,
  '657B': 15,
  '657C': 16,
  '657D': 16,
  '657E': 16,
  '657F': 16,
  '6580': 17,
  '6581': 17,
  '6582': 17,
  '6583': 17,
  '6584': 19,
  '6585': 20,
  '6586': 20,
  '6587': 4,
  '6588': 7,
  '6589': 8,
  '658A': 10,
  '658B': 10,
  '658C': 12,
  '658D': 11,
  '658E': 11,
  '658F': 11,
  '6590': 12,
  '6591': 12,
  '6592': 13,
  '6593': 16,
  '6594': 18,
  '6595': 21,
  '6596': 24,
  '6597': 4,
  '6598': 7,
  '6599': 10,
  '659A': 10,
  '659B': 11,
  '659C': 11,
  '659D': 12,
  '659E': 12,
  '659F': 13,
  '65A0': 14,
  '65A1': 14,
  '65A2': 15,
  '65A3': 17,
  '65A4': 4,
  '65A5': 5,
  '65A6': 8,
  '65A7': 8,
  '65A8': 8,
  '65A9': 8,
  '65AA': 9,
  '65AB': 9,
  '65AC': 11,
  '65AD': 11,
  '65AE': 12,
  '65AF': 12,
  '65B0': 13,
  '65B1': 12,
  '65B2': 14,
  '65B3': 15,
  '65B4': 16,
  '65B5': 17,
  '65B6': 17,
  '65B7': 18,
  '65B8': 25,
  '65B9': 4,
  '65BA': 8,
  '65BB': 8,
  '65BC': 8,
  '65BD': 9,
  '65BE': 9,
  '65BF': 9,
  '65C0': 9,
  '65C1': 10,
  '65C2': 10,
  '65C3': 10,
  '65C4': 10,
  '65C5': 10,
  '65C6': 10,
  '65C7': 11,
  '65C8': 11,
  '65C9': 11,
  '65CA': 10,
  '65CB': 11,
  '65CC': 11,
  '65CD': 11,
  '65CE': 11,
  '65CF': 11,
  '65D0': 12,
  '65D1': 12,
  '65D2': 13,
  '65D3': 13,
  '65D4': 12,
  '65D5': 13,
  '65D6': 14,
  '65D7': 14,
  '65D8': 16,
  '65D9': 16,
  '65DA': 17,
  '65DB': 18,
  '65DC': 19,
  '65DD': 19,
  '65DE': 18,
  '65DF': 19,
  '65E0': 4,
  '65E1': 4,
  '65E2': 9,
  '65E3': 11,
  '65E4': 12,
  '65E5': 4,
  '65E6': 5,
  '65E7': 5,
  '65E8': 6,
  '65E9': 6,
  '65EA': 6,
  '65EB': 6,
  '65EC': 6,
  '65ED': 6,
  '65EE': 6,
  '65EF': 6,
  '65F0': 7,
  '65F1': 7,
  '65F2': 7,
  '65F3': 7,
  '65F4': 7,
  '65F5': 7,
  '65F6': 7,
  '65F7': 7,
  '65F8': 7,
  '65F9': 8,
  '65FA': 8,
  '65FB': 8,
  '65FC': 8,
  '65FD': 8,
  '65FE': 8,
  '65FF': 8,
  '6600': 8,
  '6601': 8,
  '6602': 8,
  '6603': 8,
  '6604': 8,
  '6605': 7,
  '6606': 8,
  '6607': 8,
  '6608': 8,
  '6609': 8,
  '660A': 8,
  '660B': 8,
  '660C': 8,
  '660D': 8,
  '660E': 8,
  '660F': 8,
  '6610': 8,
  '6611': 8,
  '6612': 8,
  '6613': 8,
  '6614': 8,
  '6615': 8,
  '6616': 8,
  '6617': 8,
  '6618': 8,
  '6619': 8,
  '661A': 9,
  '661B': 8,
  '661C': 9,
  '661D': 9,
  '661E': 9,
  '661F': 9,
  '6620': 9,
  '6621': 9,
  '6622': 9,
  '6623': 9,
  '6624': 9,
  '6625': 9,
  '6626': 9,
  '6627': 9,
  '6628': 9,
  '6629': 9,
  '662A': 9,
  '662B': 9,
  '662C': 9,
  '662D': 9,
  '662E': 9,
  '662F': 9,
  '6630': 9,
  '6631': 9,
  '6632': 9,
  '6633': 9,
  '6634': 9,
  '6635': 9,
  '6636': 9,
  '6637': 9,
  '6638': 9,
  '6639': 9,
  '663A': 9,
  '663B': 9,
  '663C': 9,
  '663D': 9,
  '663E': 9,
  '663F': 9,
  '6640': 10,
  '6641': 10,
  '6642': 10,
  '6643': 10,
  '6644': 10,
  '6645': 10,
  '6646': 10,
  '6647': 10,
  '6648': 10,
  '6649': 10,
  '664A': 10,
  '664B': 10,
  '664C': 10,
  '664D': 10,
  '664E': 10,
  '664F': 10,
  '6650': 10,
  '6651': 10,
  '6652': 10,
  '6653': 10,
  '6654': 10,
  '6655': 10,
  '6656': 10,
  '6657': 11,
  '6658': 11,
  '6659': 11,
  '665A': 11,
  '665B': 11,
  '665C': 11,
  '665D': 11,
  '665E': 11,
  '665F': 10,
  '6660': 10,
  '6661': 11,
  '6662': 11,
  '6663': 11,
  '6664': 11,
  '6665': 11,
  '6666': 11,
  '6667': 11,
  '6668': 11,
  '6669': 12,
  '666A': 12,
  '666B': 12,
  '666C': 12,
  '666D': 12,
  '666E': 12,
  '666F': 12,
  '6670': 12,
  '6671': 12,
  '6672': 12,
  '6673': 12,
  '6674': 12,
  '6675': 12,
  '6676': 12,
  '6677': 12,
  '6678': 13,
  '6679': 12,
  '667A': 12,
  '667B': 12,
  '667C': 12,
  '667D': 12,
  '667E': 12,
  '667F': 12,
  '6680': 12,
  '6681': 12,
  '6682': 12,
  '6683': 12,
  '6684': 13,
  '6685': 13,
  '6686': 13,
  '6687': 13,
  '6688': 13,
  '6689': 13,
  '668A': 13,
  '668B': 13,
  '668C': 13,
  '668D': 13,
  '668E': 12,
  '668F': 12,
  '6690': 13,
  '6691': 12,
  '6692': 13,
  '6693': 13,
  '6694': 13,
  '6695': 13,
  '6696': 13,
  '6697': 13,
  '6698': 13,
  '6699': 13,
  '669A': 14,
  '669B': 13,
  '669C': 14,
  '669D': 14,
  '669E': 14,
  '669F': 14,
  '66A0': 14,
  '66A1': 14,
  '66A2': 14,
  '66A3': 14,
  '66A4': 14,
  '66A5': 14,
  '66A6': 14,
  '66A7': 14,
  '66A8': 14,
  '66A9': 15,
  '66AA': 15,
  '66AB': 15,
  '66AC': 15,
  '66AD': 15,
  '66AE': 14,
  '66AF': 14,
  '66B0': 15,
  '66B1': 14,
  '66B2': 15,
  '66B3': 15,
  '66B4': 15,
  '66B5': 15,
  '66B6': 15,
  '66B7': 15,
  '66B8': 16,
  '66B9': 15,
  '66BA': 16,
  '66BB': 16,
  '66BC': 15,
  '66BD': 16,
  '66BE': 16,
  '66BF': 16,
  '66C0': 16,
  '66C1': 16,
  '66C2': 15,
  '66C3': 15,
  '66C4': 14,
  '66C5': 14,
  '66C6': 16,
  '66C7': 16,
  '66C8': 16,
  '66C9': 16,
  '66CA': 16,
  '66CB': 16,
  '66CC': 16,
  '66CD': 16,
  '66CE': 17,
  '66CF': 15,
  '66D0': 17,
  '66D1': 17,
  '66D2': 17,
  '66D3': 17,
  '66D4': 16,
  '66D5': 17,
  '66D6': 17,
  '66D7': 17,
  '66D8': 18,
  '66D9': 17,
  '66DA': 17,
  '66DB': 18,
  '66DC': 18,
  '66DD': 19,
  '66DE': 18,
  '66DF': 19,
  '66E0': 18,
  '66E1': 19,
  '66E2': 19,
  '66E3': 20,
  '66E4': 20,
  '66E5': 20,
  '66E6': 20,
  '66E7': 20,
  '66E8': 20,
  '66E9': 21,
  '66EA': 23,
  '66EB': 23,
  '66EC': 23,
  '66ED': 24,
  '66EE': 23,
  '66EF': 25,
  '66F0': 4,
  '66F1': 5,
  '66F2': 6,
  '66F3': 6,
  '66F4': 7,
  '66F5': 7,
  '66F6': 8,
  '66F7': 9,
  '66F8': 10,
  '66F9': 11,
  '66FA': 10,
  '66FB': 10,
  '66FC': 11,
  '66FD': 11,
  '66FE': 12,
  '66FF': 12,
  '6700': 12,
  '6701': 12,
  '6702': 12,
  '6703': 13,
  '6704': 14,
  '6705': 14,
  '6706': 16,
  '6707': 21,
  '6708': 4,
  '6709': 6,
  '670A': 8,
  '670B': 8,
  '670C': 8,
  '670D': 8,
  '670E': 9,
  '670F': 9,
  '6710': 9,
  '6711': 9,
  '6712': 10,
  '6713': 10,
  '6714': 10,
  '6715': 10,
  '6716': 11,
  '6717': 10,
  '6718': 11,
  '6719': 11,
  '671A': 11,
  '671B': 11,
  '671C': 12,
  '671D': 12,
  '671E': 12,
  '671F': 12,
  '6720': 12,
  '6721': 13,
  '6722': 14,
  '6723': 16,
  '6724': 16,
  '6725': 16,
  '6726': 17,
  '6727': 20,
  '6728': 4,
  '6729': 4,
  '672A': 5,
  '672B': 5,
  '672C': 5,
  '672D': 5,
  '672E': 5,
  '672F': 5,
  '6730': 5,
  '6731': 6,
  '6732': 6,
  '6733': 6,
  '6734': 6,
  '6735': 6,
  '6736': 6,
  '6737': 6,
  '6738': 6,
  '6739': 6,
  '673A': 6,
  '673B': 6,
  '673C': 6,
  '673D': 6,
  '673E': 6,
  '673F': 6,
  '6740': 6,
  '6741': 6,
  '6742': 6,
  '6743': 6,
  '6744': 7,
  '6745': 7,
  '6746': 7,
  '6747': 7,
  '6748': 7,
  '6749': 7,
  '674A': 7,
  '674B': 7,
  '674C': 7,
  '674D': 7,
  '674E': 7,
  '674F': 7,
  '6750': 7,
  '6751': 7,
  '6752': 7,
  '6753': 7,
  '6754': 7,
  '6755': 7,
  '6756': 7,
  '6757': 7,
  '6758': 7,
  '6759': 7,
  '675A': 7,
  '675B': 7,
  '675C': 7,
  '675D': 7,
  '675E': 7,
  '675F': 7,
  '6760': 7,
  '6761': 7,
  '6762': 7,
  '6763': 7,
  '6764': 7,
  '6765': 7,
  '6766': 7,
  '6767': 7,
  '6768': 7,
  '6769': 7,
  '676A': 8,
  '676B': 8,
  '676C': 8,
  '676D': 8,
  '676E': 8,
  '676F': 8,
  '6770': 8,
  '6771': 8,
  '6772': 8,
  '6773': 8,
  '6774': 8,
  '6775': 8,
  '6776': 8,
  '6777': 8,
  '6778': 8,
  '6779': 8,
  '677A': 8,
  '677B': 8,
  '677C': 8,
  '677D': 8,
  '677E': 8,
  '677F': 8,
  '6780': 8,
  '6781': 7,
  '6782': 8,
  '6783': 8,
  '6784': 8,
  '6785': 8,
  '6786': 8,
  '6787': 8,
  '6788': 8,
  '6789': 8,
  '678A': 8,
  '678B': 8,
  '678C': 8,
  '678D': 8,
  '678E': 8,
  '678F': 8,
  '6790': 8,
  '6791': 8,
  '6792': 8,
  '6793': 8,
  '6794': 8,
  '6795': 8,
  '6796': 8,
  '6797': 8,
  '6798': 8,
  '6799': 8,
  '679A': 8,
  '679B': 8,
  '679C': 8,
  '679D': 8,
  '679E': 8,
  '679F': 8,
  '67A0': 8,
  '67A1': 8,
  '67A2': 8,
  '67A3': 8,
  '67A4': 8,
  '67A5': 8,
  '67A6': 8,
  '67A7': 8,
  '67A8': 8,
  '67A9': 8,
  '67AA': 8,
  '67AB': 8,
  '67AC': 8,
  '67AD': 8,
  '67AE': 9,
  '67AF': 9,
  '67B0': 9,
  '67B1': 9,
  '67B2': 9,
  '67B3': 9,
  '67B4': 9,
  '67B5': 9,
  '67B6': 9,
  '67B7': 9,
  '67B8': 9,
  '67B9': 9,
  '67BA': 9,
  '67BB': 9,
  '67BC': 9,
  '67BD': 10,
  '67BE': 9,
  '67BF': 9,
  '67C0': 9,
  '67C1': 9,
  '67C2': 9,
  '67C3': 9,
  '67C4': 9,
  '67C5': 9,
  '67C6': 9,
  '67C7': 9,
  '67C8': 9,
  '67C9': 8,
  '67CA': 9,
  '67CB': 9,
  '67CC': 9,
  '67CD': 9,
  '67CE': 9,
  '67CF': 9,
  '67D0': 9,
  '67D1': 9,
  '67D2': 9,
  '67D3': 9,
  '67D4': 9,
  '67D5': 9,
  '67D6': 9,
  '67D7': 9,
  '67D8': 9,
  '67D9': 9,
  '67DA': 9,
  '67DB': 9,
  '67DC': 8,
  '67DD': 9,
  '67DE': 9,
  '67DF': 9,
  '67E0': 9,
  '67E1': 10,
  '67E2': 9,
  '67E3': 9,
  '67E4': 9,
  '67E5': 9,
  '67E6': 9,
  '67E7': 9,
  '67E8': 9,
  '67E9': 9,
  '67EA': 9,
  '67EB': 9,
  '67EC': 9,
  '67ED': 9,
  '67EE': 9,
  '67EF': 9,
  '67F0': 9,
  '67F1': 9,
  '67F2': 9,
  '67F3': 9,
  '67F4': 10,
  '67F5': 9,
  '67F6': 9,
  '67F7': 9,
  '67F8': 9,
  '67F9': 8,
  '67FA': 9,
  '67FB': 9,
  '67FC': 9,
  '67FD': 9,
  '67FE': 9,
  '67FF': 9,
  '6800': 9,
  '6801': 9,
  '6802': 9,
  '6803': 9,
  '6804': 9,
  '6805': 9,
  '6806': 9,
  '6807': 9,
  '6808': 9,
  '6809': 9,
  '680A': 9,
  '680B': 9,
  '680C': 9,
  '680D': 9,
  '680E': 9,
  '680F': 9,
  '6810': 9,
  '6811': 9,
  '6812': 10,
  '6813': 10,
  '6814': 10,
  '6815': 10,
  '6816': 10,
  '6817': 10,
  '6818': 10,
  '6819': 10,
  '681A': 10,
  '681B': 10,
  '681C': 10,
  '681D': 10,
  '681E': 10,
  '681F': 10,
  '6820': 10,
  '6821': 10,
  '6822': 10,
  '6823': 10,
  '6824': 10,
  '6825': 10,
  '6826': 10,
  '6827': 10,
  '6828': 10,
  '6829': 10,
  '682A': 10,
  '682B': 10,
  '682C': 10,
  '682D': 10,
  '682E': 10,
  '682F': 10,
  '6830': 10,
  '6831': 10,
  '6832': 10,
  '6833': 10,
  '6834': 10,
  '6835': 10,
  '6836': 10,
  '6837': 10,
  '6838': 10,
  '6839': 10,
  '683A': 10,
  '683B': 10,
  '683C': 10,
  '683D': 10,
  '683E': 10,
  '683F': 10,
  '6840': 10,
  '6841': 10,
  '6842': 10,
  '6843': 10,
  '6844': 10,
  '6845': 10,
  '6846': 10,
  '6847': 10,
  '6848': 10,
  '6849': 10,
  '684A': 10,
  '684B': 10,
  '684C': 10,
  '684D': 10,
  '684E': 10,
  '684F': 9,
  '6850': 10,
  '6851': 10,
  '6852': 9,
  '6853': 10,
  '6854': 10,
  '6855': 10,
  '6856': 10,
  '6857': 10,
  '6858': 10,
  '6859': 10,
  '685A': 10,
  '685B': 10,
  '685C': 10,
  '685D': 10,
  '685E': 9,
  '685F': 10,
  '6860': 10,
  '6861': 10,
  '6862': 10,
  '6863': 10,
  '6864': 10,
  '6865': 10,
  '6866': 10,
  '6867': 10,
  '6868': 10,
  '6869': 10,
  '686A': 10,
  '686B': 11,
  '686C': 11,
  '686D': 11,
  '686E': 11,
  '686F': 11,
  '6870': 11,
  '6871': 11,
  '6872': 11,
  '6873': 11,
  '6874': 11,
  '6875': 11,
  '6876': 11,
  '6877': 11,
  '6878': 11,
  '6879': 11,
  '687A': 11,
  '687B': 11,
  '687C': 11,
  '687D': 11,
  '687E': 11,
  '687F': 11,
  '6880': 11,
  '6881': 11,
  '6882': 11,
  '6883': 10,
  '6884': 11,
  '6885': 11,
  '6886': 10,
  '6887': 11,
  '6888': 11,
  '6889': 11,
  '688A': 11,
  '688B': 11,
  '688C': 11,
  '688D': 11,
  '688E': 11,
  '688F': 11,
  '6890': 11,
  '6891': 11,
  '6892': 11,
  '6893': 11,
  '6894': 11,
  '6895': 11,
  '6896': 11,
  '6897': 11,
  '6898': 11,
  '6899': 11,
  '689A': 11,
  '689B': 10,
  '689C': 11,
  '689D': 10,
  '689E': 11,
  '689F': 11,
  '68A0': 10,
  '68A1': 11,
  '68A2': 11,
  '68A3': 11,
  '68A4': 11,
  '68A5': 11,
  '68A6': 11,
  '68A7': 11,
  '68A8': 11,
  '68A9': 11,
  '68AA': 11,
  '68AB': 11,
  '68AC': 11,
  '68AD': 11,
  '68AE': 11,
  '68AF': 11,
  '68B0': 11,
  '68B1': 11,
  '68B2': 11,
  '68B3': 11,
  '68B4': 10,
  '68B5': 11,
  '68B6': 11,
  '68B7': 11,
  '68B8': 11,
  '68B9': 11,
  '68BA': 11,
  '68BB': 11,
  '68BC': 11,
  '68BD': 11,
  '68BE': 11,
  '68BF': 11,
  '68C0': 11,
  '68C1': 11,
  '68C2': 11,
  '68C3': 12,
  '68C4': 12,
  '68C5': 12,
  '68C6': 12,
  '68C7': 12,
  '68C8': 12,
  '68C9': 12,
  '68CA': 12,
  '68CB': 12,
  '68CC': 12,
  '68CD': 12,
  '68CE': 12,
  '68CF': 12,
  '68D0': 12,
  '68D1': 12,
  '68D2': 12,
  '68D3': 12,
  '68D4': 12,
  '68D5': 12,
  '68D6': 12,
  '68D7': 12,
  '68D8': 12,
  '68D9': 12,
  '68DA': 12,
  '68DB': 12,
  '68DC': 12,
  '68DD': 12,
  '68DE': 12,
  '68DF': 12,
  '68E0': 12,
  '68E1': 12,
  '68E2': 12,
  '68E3': 12,
  '68E4': 12,
  '68E5': 12,
  '68E6': 10,
  '68E7': 12,
  '68E8': 12,
  '68E9': 13,
  '68EA': 12,
  '68EB': 12,
  '68EC': 12,
  '68ED': 12,
  '68EE': 12,
  '68EF': 12,
  '68F0': 12,
  '68F1': 12,
  '68F2': 12,
  '68F3': 12,
  '68F4': 12,
  '68F5': 12,
  '68F6': 12,
  '68F7': 12,
  '68F8': 12,
  '68F9': 12,
  '68FA': 12,
  '68FB': 11,
  '68FC': 12,
  '68FD': 12,
  '68FE': 12,
  '68FF': 12,
  '6900': 12,
  '6901': 12,
  '6902': 12,
  '6903': 12,
  '6904': 12,
  '6905': 12,
  '6906': 12,
  '6907': 12,
  '6908': 12,
  '6909': 12,
  '690A': 12,
  '690B': 12,
  '690C': 12,
  '690D': 12,
  '690E': 12,
  '690F': 12,
  '6910': 12,
  '6911': 12,
  '6912': 12,
  '6913': 12,
  '6914': 12,
  '6915': 12,
  '6916': 12,
  '6917': 12,
  '6918': 11,
  '6919': 12,
  '691A': 12,
  '691B': 11,
  '691C': 12,
  '691D': 12,
  '691E': 12,
  '691F': 12,
  '6920': 12,
  '6921': 12,
  '6922': 12,
  '6923': 12,
  '6924': 12,
  '6925': 12,
  '6926': 12,
  '6927': 12,
  '6928': 12,
  '6929': 12,
  '692A': 12,
  '692B': 12,
  '692C': 12,
  '692D': 12,
  '692E': 12,
  '692F': 13,
  '6930': 12,
  '6931': 13,
  '6932': 13,
  '6933': 13,
  '6934': 13,
  '6935': 13,
  '6936': 13,
  '6937': 13,
  '6938': 13,
  '6939': 13,
  '693A': 13,
  '693B': 13,
  '693C': 13,
  '693D': 13,
  '693E': 13,
  '693F': 13,
  '6940': 13,
  '6941': 13,
  '6942': 13,
  '6943': 13,
  '6944': 13,
  '6945': 13,
  '6946': 13,
  '6947': 12,
  '6948': 13,
  '6949': 12,
  '694A': 13,
  '694B': 13,
  '694C': 13,
  '694D': 13,
  '694E': 13,
  '694F': 13,
  '6950': 13,
  '6951': 13,
  '6952': 13,
  '6953': 13,
  '6954': 13,
  '6955': 13,
  '6956': 11,
  '6957': 12,
  '6958': 13,
  '6959': 13,
  '695A': 13,
  '695B': 12,
  '695C': 13,
  '695D': 13,
  '695E': 13,
  '695F': 13,
  '6960': 13,
  '6961': 13,
  '6962': 13,
  '6963': 13,
  '6964': 13,
  '6965': 13,
  '6966': 13,
  '6967': 12,
  '6968': 13,
  '6969': 13,
  '696A': 13,
  '696B': 13,
  '696C': 13,
  '696D': 13,
  '696E': 12,
  '696F': 13,
  '6970': 12,
  '6971': 13,
  '6972': 13,
  '6973': 13,
  '6974': 13,
  '6975': 12,
  '6976': 13,
  '6977': 13,
  '6978': 13,
  '6979': 13,
  '697A': 13,
  '697B': 13,
  '697C': 13,
  '697D': 13,
  '697E': 13,
  '697F': 13,
  '6980': 13,
  '6981': 13,
  '6982': 13,
  '6983': 13,
  '6984': 13,
  '6985': 13,
  '6986': 13,
  '6987': 13,
  '6988': 13,
  '6989': 13,
  '698A': 13,
  '698B': 13,
  '698C': 13,
  '698D': 14,
  '698E': 14,
  '698F': 14,
  '6990': 14,
  '6991': 14,
  '6992': 14,
  '6993': 14,
  '6994': 12,
  '6995': 14,
  '6996': 14,
  '6997': 14,
  '6998': 13,
  '6999': 13,
  '699A': 14,
  '699B': 14,
  '699C': 14,
  '699D': 14,
  '699E': 14,
  '699F': 14,
  '69A0': 14,
  '69A1': 14,
  '69A2': 14,
  '69A3': 14,
  '69A4': 14,
  '69A5': 14,
  '69A6': 14,
  '69A7': 14,
  '69A8': 14,
  '69A9': 14,
  '69AA': 14,
  '69AB': 14,
  '69AC': 14,
  '69AD': 14,
  '69AE': 14,
  '69AF': 14,
  '69B0': 14,
  '69B1': 14,
  '69B2': 14,
  '69B3': 13,
  '69B4': 14,
  '69B5': 13,
  '69B6': 14,
  '69B7': 14,
  '69B8': 14,
  '69B9': 14,
  '69BA': 14,
  '69BB': 14,
  '69BC': 14,
  '69BD': 14,
  '69BE': 13,
  '69BF': 14,
  '69C0': 14,
  '69C1': 14,
  '69C2': 14,
  '69C3': 14,
  '69C4': 14,
  '69C5': 14,
  '69C6': 13,
  '69C7': 14,
  '69C8': 14,
  '69C9': 14,
  '69CA': 14,
  '69CB': 14,
  '69CC': 13,
  '69CD': 14,
  '69CE': 13,
  '69CF': 14,
  '69D0': 13,
  '69D1': 14,
  '69D2': 14,
  '69D3': 14,
  '69D4': 14,
  '69D5': 14,
  '69D6': 14,
  '69D7': 14,
  '69D8': 14,
  '69D9': 14,
  '69DA': 14,
  '69DB': 14,
  '69DC': 14,
  '69DD': 14,
  '69DE': 14,
  '69DF': 14,
  '69E0': 14,
  '69E1': 14,
  '69E2': 15,
  '69E3': 15,
  '69E4': 14,
  '69E5': 15,
  '69E6': 15,
  '69E7': 15,
  '69E8': 14,
  '69E9': 13,
  '69EA': 15,
  '69EB': 15,
  '69EC': 15,
  '69ED': 15,
  '69EE': 15,
  '69EF': 15,
  '69F0': 14,
  '69F1': 15,
  '69F2': 15,
  '69F3': 15,
  '69F4': 15,
  '69F5': 15,
  '69F6': 15,
  '69F7': 15,
  '69F8': 15,
  '69F9': 15,
  '69FA': 15,
  '69FB': 15,
  '69FC': 15,
  '69FD': 15,
  '69FE': 15,
  '69FF': 15,
  '6A00': 15,
  '6A01': 15,
  '6A02': 15,
  '6A03': 14,
  '6A04': 14,
  '6A05': 15,
  '6A06': 14,
  '6A07': 14,
  '6A08': 15,
  '6A09': 15,
  '6A0A': 15,
  '6A0B': 14,
  '6A0C': 15,
  '6A0D': 15,
  '6A0E': 15,
  '6A0F': 15,
  '6A10': 15,
  '6A11': 15,
  '6A12': 15,
  '6A13': 15,
  '6A14': 15,
  '6A15': 15,
  '6A16': 15,
  '6A17': 15,
  '6A18': 15,
  '6A19': 15,
  '6A1A': 15,
  '6A1B': 15,
  '6A1C': 15,
  '6A1D': 15,
  '6A1E': 15,
  '6A1F': 15,
  '6A20': 15,
  '6A21': 14,
  '6A22': 15,
  '6A23': 15,
  '6A24': 14,
  '6A25': 14,
  '6A26': 15,
  '6A27': 15,
  '6A28': 16,
  '6A29': 15,
  '6A2A': 15,
  '6A2B': 15,
  '6A2C': 15,
  '6A2D': 15,
  '6A2E': 15,
  '6A2F': 15,
  '6A30': 15,
  '6A31': 15,
  '6A32': 16,
  '6A33': 16,
  '6A34': 16,
  '6A35': 16,
  '6A36': 16,
  '6A37': 16,
  '6A38': 16,
  '6A39': 16,
  '6A3A': 14,
  '6A3B': 16,
  '6A3C': 16,
  '6A3D': 16,
  '6A3E': 16,
  '6A3F': 16,
  '6A40': 16,
  '6A41': 16,
  '6A42': 16,
  '6A43': 16,
  '6A44': 15,
  '6A45': 16,
  '6A46': 16,
  '6A47': 16,
  '6A48': 16,
  '6A49': 16,
  '6A4A': 16,
  '6A4B': 16,
  '6A4C': 16,
  '6A4D': 16,
  '6A4E': 16,
  '6A4F': 16,
  '6A50': 16,
  '6A51': 16,
  '6A52': 16,
  '6A53': 16,
  '6A54': 16,
  '6A55': 16,
  '6A56': 16,
  '6A57': 15,
  '6A58': 16,
  '6A59': 16,
  '6A5A': 17,
  '6A5B': 16,
  '6A5C': 16,
  '6A5D': 16,
  '6A5E': 16,
  '6A5F': 16,
  '6A60': 16,
  '6A61': 15,
  '6A62': 15,
  '6A63': 16,
  '6A64': 16,
  '6A65': 15,
  '6A66': 16,
  '6A67': 16,
  '6A68': 16,
  '6A69': 16,
  '6A6A': 16,
  '6A6B': 16,
  '6A6C': 16,
  '6A6D': 16,
  '6A6E': 16,
  '6A6F': 16,
  '6A70': 16,
  '6A71': 16,
  '6A72': 16,
  '6A73': 16,
  '6A74': 16,
  '6A75': 16,
  '6A76': 16,
  '6A77': 16,
  '6A78': 16,
  '6A79': 16,
  '6A7A': 16,
  '6A7B': 16,
  '6A7C': 16,
  '6A7D': 16,
  '6A7E': 17,
  '6A7F': 17,
  '6A80': 17,
  '6A81': 17,
  '6A82': 17,
  '6A83': 16,
  '6A84': 17,
  '6A85': 17,
  '6A86': 17,
  '6A87': 16,
  '6A88': 17,
  '6A89': 17,
  '6A8A': 17,
  '6A8B': 17,
  '6A8C': 17,
  '6A8D': 17,
  '6A8E': 16,
  '6A8F': 17,
  '6A90': 17,
  '6A91': 17,
  '6A92': 17,
  '6A93': 17,
  '6A94': 17,
  '6A95': 17,
  '6A96': 16,
  '6A97': 17,
  '6A98': 17,
  '6A99': 16,
  '6A9A': 17,
  '6A9B': 15,
  '6A9C': 17,
  '6A9D': 16,
  '6A9E': 17,
  '6A9F': 17,
  '6AA0': 16,
  '6AA1': 17,
  '6AA2': 17,
  '6AA3': 17,
  '6AA4': 16,
  '6AA5': 17,
  '6AA6': 17,
  '6AA7': 16,
  '6AA8': 16,
  '6AA9': 17,
  '6AAA': 17,
  '6AAB': 18,
  '6AAC': 17,
  '6AAD': 18,
  '6AAE': 18,
  '6AAF': 18,
  '6AB0': 18,
  '6AB1': 18,
  '6AB2': 18,
  '6AB3': 18,
  '6AB4': 17,
  '6AB5': 18,
  '6AB6': 18,
  '6AB7': 18,
  '6AB8': 18,
  '6AB9': 18,
  '6ABA': 18,
  '6ABB': 18,
  '6ABC': 18,
  '6ABD': 18,
  '6ABE': 18,
  '6ABF': 18,
  '6AC0': 18,
  '6AC1': 18,
  '6AC2': 18,
  '6AC3': 18,
  '6AC4': 18,
  '6AC5': 18,
  '6AC6': 17,
  '6AC7': 18,
  '6AC8': 18,
  '6AC9': 18,
  '6ACA': 18,
  '6ACB': 19,
  '6ACC': 19,
  '6ACD': 19,
  '6ACE': 18,
  '6ACF': 19,
  '6AD0': 19,
  '6AD1': 19,
  '6AD2': 18,
  '6AD3': 19,
  '6AD4': 18,
  '6AD5': 19,
  '6AD6': 19,
  '6AD7': 18,
  '6AD8': 19,
  '6AD9': 18,
  '6ADA': 18,
  '6ADB': 17,
  '6ADC': 19,
  '6ADD': 19,
  '6ADE': 19,
  '6ADF': 19,
  '6AE0': 19,
  '6AE1': 18,
  '6AE2': 19,
  '6AE3': 17,
  '6AE4': 19,
  '6AE5': 19,
  '6AE6': 19,
  '6AE7': 19,
  '6AE8': 20,
  '6AE9': 20,
  '6AEA': 20,
  '6AEB': 19,
  '6AEC': 20,
  '6AED': 18,
  '6AEE': 20,
  '6AEF': 20,
  '6AF0': 20,
  '6AF1': 20,
  '6AF2': 19,
  '6AF3': 20,
  '6AF4': 20,
  '6AF5': 19,
  '6AF6': 20,
  '6AF7': 22,
  '6AF8': 20,
  '6AF9': 20,
  '6AFA': 21,
  '6AFB': 21,
  '6AFC': 21,
  '6AFD': 20,
  '6AFE': 21,
  '6AFF': 20,
  '6B00': 21,
  '6B01': 21,
  '6B02': 20,
  '6B03': 21,
  '6B04': 21,
  '6B05': 21,
  '6B06': 22,
  '6B07': 22,
  '6B08': 22,
  '6B09': 22,
  '6B0A': 21,
  '6B0B': 22,
  '6B0C': 21,
  '6B0D': 21,
  '6B0E': 22,
  '6B0F': 23,
  '6B10': 23,
  '6B11': 23,
  '6B12': 23,
  '6B13': 24,
  '6B14': 24,
  '6B15': 23,
  '6B16': 25,
  '6B17': 24,
  '6B18': 25,
  '6B19': 25,
  '6B1A': 25,
  '6B1B': 25,
  '6B1C': 26,
  '6B1D': 25,
  '6B1E': 28,
  '6B1F': 28,
  '6B20': 4,
  '6B21': 6,
  '6B22': 6,
  '6B23': 8,
  '6B24': 7,
  '6B25': 8,
  '6B26': 8,
  '6B27': 8,
  '6B28': 9,
  '6B29': 9,
  '6B2A': 9,
  '6B2B': 11,
  '6B2C': 10,
  '6B2D': 10,
  '6B2E': 10,
  '6B2F': 10,
  '6B30': 10,
  '6B31': 10,
  '6B32': 11,
  '6B33': 11,
  '6B34': 10,
  '6B35': 11,
  '6B36': 11,
  '6B37': 11,
  '6B38': 11,
  '6B39': 12,
  '6B3A': 12,
  '6B3B': 12,
  '6B3C': 12,
  '6B3D': 12,
  '6B3E': 12,
  '6B3F': 12,
  '6B40': 13,
  '6B41': 13,
  '6B42': 13,
  '6B43': 13,
  '6B44': 12,
  '6B45': 13,
  '6B46': 13,
  '6B47': 13,
  '6B48': 13,
  '6B49': 14,
  '6B4A': 14,
  '6B4B': 14,
  '6B4C': 14,
  '6B4D': 14,
  '6B4E': 15,
  '6B4F': 15,
  '6B50': 15,
  '6B51': 15,
  '6B52': 15,
  '6B53': 15,
  '6B54': 15,
  '6B55': 16,
  '6B56': 16,
  '6B57': 17,
  '6B58': 16,
  '6B59': 16,
  '6B5A': 16,
  '6B5B': 17,
  '6B5C': 17,
  '6B5D': 17,
  '6B5E': 18,
  '6B5F': 17,
  '6B60': 19,
  '6B61': 21,
  '6B62': 4,
  '6B63': 5,
  '6B64': 6,
  '6B65': 7,
  '6B66': 8,
  '6B67': 8,
  '6B68': 8,
  '6B69': 8,
  '6B6A': 9,
  '6B6B': 8,
  '6B6C': 10,
  '6B6D': 10,
  '6B6E': 12,
  '6B6F': 12,
  '6B70': 14,
  '6B71': 13,
  '6B72': 13,
  '6B73': 13,
  '6B74': 14,
  '6B75': 15,
  '6B76': 15,
  '6B77': 16,
  '6B78': 18,
  '6B79': 4,
  '6B7A': 5,
  '6B7B': 6,
  '6B7C': 7,
  '6B7D': 8,
  '6B7E': 8,
  '6B7F': 8,
  '6B80': 8,
  '6B81': 8,
  '6B82': 9,
  '6B83': 9,
  '6B84': 9,
  '6B85': 9,
  '6B86': 9,
  '6B87': 9,
  '6B88': 10,
  '6B89': 10,
  '6B8A': 10,
  '6B8B': 9,
  '6B8C': 11,
  '6B8D': 11,
  '6B8E': 11,
  '6B8F': 11,
  '6B90': 11,
  '6B91': 11,
  '6B92': 11,
  '6B93': 11,
  '6B94': 12,
  '6B95': 12,
  '6B96': 12,
  '6B97': 12,
  '6B98': 12,
  '6B99': 12,
  '6B9A': 12,
  '6B9B': 12,
  '6B9C': 13,
  '6B9D': 14,
  '6B9E': 14,
  '6B9F': 13,
  '6BA0': 14,
  '6BA1': 14,
  '6BA2': 15,
  '6BA3': 15,
  '6BA4': 15,
  '6BA5': 15,
  '6BA6': 15,
  '6BA7': 16,
  '6BA8': 16,
  '6BA9': 16,
  '6BAA': 16,
  '6BAB': 16,
  '6BAC': 17,
  '6BAD': 17,
  '6BAE': 17,
  '6BAF': 18,
  '6BB0': 19,
  '6BB1': 19,
  '6BB2': 21,
  '6BB3': 4,
  '6BB4': 8,
  '6BB5': 9,
  '6BB6': 9,
  '6BB7': 10,
  '6BB8': 11,
  '6BB9': 11,
  '6BBA': 10,
  '6BBB': 11,
  '6BBC': 12,
  '6BBD': 12,
  '6BBE': 12,
  '6BBF': 13,
  '6BC0': 13,
  '6BC1': 13,
  '6BC2': 13,
  '6BC3': 14,
  '6BC4': 14,
  '6BC5': 15,
  '6BC6': 15,
  '6BC7': 16,
  '6BC8': 16,
  '6BC9': 18,
  '6BCA': 23,
  '6BCB': 4,
  '6BCC': 4,
  '6BCD': 5,
  '6BCE': 6,
  '6BCF': 7,
  '6BD0': 7,
  '6BD1': 8,
  '6BD2': 9,
  '6BD3': 14,
  '6BD4': 4,
  '6BD5': 6,
  '6BD6': 9,
  '6BD7': 9,
  '6BD8': 9,
  '6BD9': 10,
  '6BDA': 17,
  '6BDB': 4,
  '6BDC': 7,
  '6BDD': 7,
  '6BDE': 8,
  '6BDF': 8,
  '6BE0': 9,
  '6BE1': 9,
  '6BE2': 10,
  '6BE3': 10,
  '6BE4': 10,
  '6BE5': 10,
  '6BE6': 10,
  '6BE7': 10,
  '6BE8': 10,
  '6BE9': 10,
  '6BEA': 10,
  '6BEB': 11,
  '6BEC': 11,
  '6BED': 11,
  '6BEE': 11,
  '6BEF': 12,
  '6BF0': 12,
  '6BF1': 12,
  '6BF2': 12,
  '6BF3': 12,
  '6BF4': 12,
  '6BF5': 12,
  '6BF6': 12,
  '6BF7': 13,
  '6BF8': 13,
  '6BF9': 13,
  '6BFA': 13,
  '6BFB': 13,
  '6BFC': 13,
  '6BFD': 12,
  '6BFE': 14,
  '6BFF': 15,
  '6C00': 15,
  '6C01': 14,
  '6C02': 15,
  '6C03': 16,
  '6C04': 16,
  '6C05': 16,
  '6C06': 16,
  '6C07': 16,
  '6C08': 17,
  '6C09': 17,
  '6C0A': 17,
  '6C0B': 17,
  '6C0C': 19,
  '6C0D': 22,
  '6C0E': 26,
  '6C0F': 4,
  '6C10': 5,
  '6C11': 5,
  '6C12': 6,
  '6C13': 8,
  '6C14': 4,
  '6C15': 5,
  '6C16': 6,
  '6C17': 6,
  '6C18': 6,
  '6C19': 7,
  '6C1A': 7,
  '6C1B': 8,
  '6C1C': 8,
  '6C1D': 8,
  '6C1E': 9,
  '6C1F': 9,
  '6C20': 9,
  '6C21': 9,
  '6C22': 9,
  '6C23': 10,
  '6C24': 10,
  '6C25': 10,
  '6C26': 10,
  '6C27': 10,
  '6C28': 10,
  '6C29': 10,
  '6C2A': 11,
  '6C2B': 11,
  '6C2C': 12,
  '6C2D': 12,
  '6C2E': 12,
  '6C2F': 12,
  '6C30': 12,
  '6C31': 13,
  '6C32': 13,
  '6C33': 14,
  '6C34': 4,
  '6C35': 3,
  '6C36': 5,
  '6C37': 5,
  '6C38': 5,
  '6C39': 5,
  '6C3A': 5,
  '6C3B': 5,
  '6C3C': 6,
  '6C3D': 6,
  '6C3E': 5,
  '6C3F': 5,
  '6C40': 5,
  '6C41': 5,
  '6C42': 7,
  '6C43': 5,
  '6C44': 5,
  '6C45': 5,
  '6C46': 6,
  '6C47': 5,
  '6C48': 5,
  '6C49': 5,
  '6C4A': 6,
  '6C4B': 6,
  '6C4C': 6,
  '6C4D': 6,
  '6C4E': 6,
  '6C4F': 6,
  '6C50': 6,
  '6C51': 6,
  '6C52': 6,
  '6C53': 6,
  '6C54': 6,
  '6C55': 6,
  '6C56': 7,
  '6C57': 6,
  '6C58': 6,
  '6C59': 6,
  '6C5A': 6,
  '6C5B': 6,
  '6C5C': 6,
  '6C5D': 6,
  '6C5E': 7,
  '6C5F': 6,
  '6C60': 6,
  '6C61': 6,
  '6C62': 6,
  '6C63': 6,
  '6C64': 6,
  '6C65': 7,
  '6C66': 7,
  '6C67': 7,
  '6C68': 7,
  '6C69': 7,
  '6C6A': 7,
  '6C6B': 7,
  '6C6C': 8,
  '6C6D': 7,
  '6C6E': 7,
  '6C6F': 7,
  '6C70': 7,
  '6C71': 7,
  '6C72': 6,
  '6C73': 7,
  '6C74': 7,
  '6C75': 7,
  '6C76': 7,
  '6C77': 6,
  '6C78': 7,
  '6C79': 7,
  '6C7A': 7,
  '6C7B': 7,
  '6C7C': 7,
  '6C7D': 7,
  '6C7E': 7,
  '6C7F': 7,
  '6C80': 8,
  '6C81': 7,
  '6C82': 7,
  '6C83': 7,
  '6C84': 7,
  '6C85': 7,
  '6C86': 7,
  '6C87': 7,
  '6C88': 7,
  '6C89': 7,
  '6C8A': 8,
  '6C8B': 7,
  '6C8C': 7,
  '6C8D': 7,
  '6C8E': 7,
  '6C8F': 7,
  '6C90': 7,
  '6C91': 7,
  '6C92': 7,
  '6C93': 8,
  '6C94': 7,
  '6C95': 7,
  '6C96': 7,
  '6C97': 9,
  '6C98': 7,
  '6C99': 7,
  '6C9A': 7,
  '6C9B': 7,
  '6C9C': 7,
  '6C9D': 8,
  '6C9E': 7,
  '6C9F': 7,
  '6CA0': 7,
  '6CA1': 7,
  '6CA2': 7,
  '6CA3': 7,
  '6CA4': 7,
  '6CA5': 7,
  '6CA6': 7,
  '6CA7': 7,
  '6CA8': 7,
  '6CA9': 7,
  '6CAA': 7,
  '6CAB': 8,
  '6CAC': 8,
  '6CAD': 8,
  '6CAE': 8,
  '6CAF': 9,
  '6CB0': 8,
  '6CB1': 8,
  '6CB2': 8,
  '6CB3': 8,
  '6CB4': 8,
  '6CB5': 8,
  '6CB6': 8,
  '6CB7': 8,
  '6CB8': 8,
  '6CB9': 8,
  '6CBA': 8,
  '6CBB': 8,
  '6CBC': 8,
  '6CBD': 8,
  '6CBE': 8,
  '6CBF': 8,
  '6CC0': 8,
  '6CC1': 8,
  '6CC2': 8,
  '6CC3': 8,
  '6CC4': 8,
  '6CC5': 8,
  '6CC6': 8,
  '6CC7': 8,
  '6CC8': 8,
  '6CC9': 9,
  '6CCA': 8,
  '6CCB': 8,
  '6CCC': 8,
  '6CCD': 8,
  '6CCE': 8,
  '6CCF': 8,
  '6CD0': 7,
  '6CD1': 8,
  '6CD2': 8,
  '6CD3': 8,
  '6CD4': 8,
  '6CD5': 8,
  '6CD6': 8,
  '6CD7': 8,
  '6CD8': 8,
  '6CD9': 8,
  '6CDA': 9,
  '6CDB': 7,
  '6CDC': 8,
  '6CDD': 8,
  '6CDE': 8,
  '6CDF': 8,
  '6CE0': 8,
  '6CE1': 8,
  '6CE2': 8,
  '6CE3': 8,
  '6CE4': 7,
  '6CE5': 8,
  '6CE6': 8,
  '6CE7': 8,
  '6CE8': 8,
  '6CE9': 8,
  '6CEA': 8,
  '6CEB': 8,
  '6CEC': 8,
  '6CED': 8,
  '6CEE': 8,
  '6CEF': 8,
  '6CF0': 10,
  '6CF1': 8,
  '6CF2': 7,
  '6CF3': 8,
  '6CF4': 9,
  '6CF5': 9,
  '6CF6': 9,
  '6CF7': 8,
  '6CF8': 8,
  '6CF9': 8,
  '6CFA': 8,
  '6CFB': 8,
  '6CFC': 8,
  '6CFD': 8,
  '6CFE': 8,
  '6CFF': 9,
  '6D00': 9,
  '6D01': 9,
  '6D02': 9,
  '6D03': 9,
  '6D04': 9,
  '6D05': 9,
  '6D06': 9,
  '6D07': 9,
  '6D08': 9,
  '6D09': 9,
  '6D0A': 9,
  '6D0B': 9,
  '6D0C': 9,
  '6D0D': 10,
  '6D0E': 9,
  '6D0F': 9,
  '6D10': 9,
  '6D11': 9,
  '6D12': 9,
  '6D13': 9,
  '6D14': 9,
  '6D15': 9,
  '6D16': 10,
  '6D17': 9,
  '6D18': 9,
  '6D19': 9,
  '6D1A': 9,
  '6D1B': 9,
  '6D1C': 10,
  '6D1D': 9,
  '6D1E': 9,
  '6D1F': 9,
  '6D20': 9,
  '6D21': 9,
  '6D22': 9,
  '6D23': 9,
  '6D24': 9,
  '6D25': 9,
  '6D26': 9,
  '6D27': 9,
  '6D28': 9,
  '6D29': 9,
  '6D2A': 9,
  '6D2B': 9,
  '6D2C': 9,
  '6D2D': 9,
  '6D2E': 9,
  '6D2F': 10,
  '6D30': 7,
  '6D31': 9,
  '6D32': 9,
  '6D33': 9,
  '6D34': 9,
  '6D35': 9,
  '6D36': 9,
  '6D37': 9,
  '6D38': 9,
  '6D39': 9,
  '6D3A': 9,
  '6D3B': 9,
  '6D3C': 9,
  '6D3D': 9,
  '6D3E': 9,
  '6D3F': 9,
  '6D40': 9,
  '6D41': 10,
  '6D42': 9,
  '6D43': 9,
  '6D44': 9,
  '6D45': 8,
  '6D46': 10,
  '6D47': 9,
  '6D48': 9,
  '6D49': 9,
  '6D4A': 9,
  '6D4B': 9,
  '6D4C': 9,
  '6D4D': 9,
  '6D4E': 9,
  '6D4F': 9,
  '6D50': 9,
  '6D51': 9,
  '6D52': 9,
  '6D53': 9,
  '6D54': 9,
  '6D55': 9,
  '6D56': 10,
  '6D57': 10,
  '6D58': 10,
  '6D59': 10,
  '6D5A': 10,
  '6D5B': 10,
  '6D5C': 10,
  '6D5D': 10,
  '6D5E': 10,
  '6D5F': 10,
  '6D60': 10,
  '6D61': 10,
  '6D62': 10,
  '6D63': 10,
  '6D64': 10,
  '6D65': 10,
  '6D66': 10,
  '6D67': 10,
  '6D68': 10,
  '6D69': 10,
  '6D6A': 10,
  '6D6B': 10,
  '6D6C': 10,
  '6D6D': 10,
  '6D6E': 10,
  '6D6F': 10,
  '6D70': 10,
  '6D71': 10,
  '6D72': 10,
  '6D73': 10,
  '6D74': 10,
  '6D75': 10,
  '6D76': 10,
  '6D77': 10,
  '6D78': 10,
  '6D79': 10,
  '6D7A': 10,
  '6D7B': 10,
  '6D7C': 10,
  '6D7D': 10,
  '6D7E': 10,
  '6D7F': 10,
  '6D80': 10,
  '6D81': 10,
  '6D82': 10,
  '6D83': 10,
  '6D84': 10,
  '6D85': 10,
  '6D86': 10,
  '6D87': 10,
  '6D88': 10,
  '6D89': 10,
  '6D8A': 10,
  '6D8B': 10,
  '6D8C': 10,
  '6D8D': 10,
  '6D8E': 9,
  '6D8F': 9,
  '6D90': 10,
  '6D91': 10,
  '6D92': 10,
  '6D93': 10,
  '6D94': 10,
  '6D95': 10,
  '6D96': 10,
  '6D97': 10,
  '6D98': 10,
  '6D99': 10,
  '6D9A': 10,
  '6D9B': 10,
  '6D9C': 10,
  '6D9D': 10,
  '6D9E': 10,
  '6D9F': 10,
  '6DA0': 10,
  '6DA1': 10,
  '6DA2': 10,
  '6DA3': 10,
  '6DA4': 10,
  '6DA5': 10,
  '6DA6': 10,
  '6DA7': 10,
  '6DA8': 10,
  '6DA9': 10,
  '6DAA': 11,
  '6DAB': 11,
  '6DAC': 11,
  '6DAD': 11,
  '6DAE': 11,
  '6DAF': 11,
  '6DB0': 11,
  '6DB1': 11,
  '6DB2': 11,
  '6DB3': 11,
  '6DB4': 11,
  '6DB5': 11,
  '6DB6': 11,
  '6DB7': 11,
  '6DB8': 11,
  '6DB9': 11,
  '6DBA': 11,
  '6DBB': 11,
  '6DBC': 11,
  '6DBD': 11,
  '6DBE': 11,
  '6DBF': 11,
  '6DC0': 11,
  '6DC1': 11,
  '6DC2': 11,
  '6DC3': 11,
  '6DC4': 11,
  '6DC5': 11,
  '6DC6': 11,
  '6DC7': 11,
  '6DC8': 11,
  '6DC9': 11,
  '6DCA': 11,
  '6DCB': 11,
  '6DCC': 11,
  '6DCD': 11,
  '6DCE': 11,
  '6DCF': 11,
  '6DD0': 11,
  '6DD1': 11,
  '6DD2': 11,
  '6DD3': 10,
  '6DD4': 11,
  '6DD5': 11,
  '6DD6': 11,
  '6DD7': 11,
  '6DD8': 11,
  '6DD9': 11,
  '6DDA': 11,
  '6DDB': 11,
  '6DDC': 11,
  '6DDD': 11,
  '6DDE': 11,
  '6DDF': 11,
  '6DE0': 11,
  '6DE1': 11,
  '6DE2': 11,
  '6DE3': 11,
  '6DE4': 11,
  '6DE5': 11,
  '6DE6': 11,
  '6DE7': 11,
  '6DE8': 11,
  '6DE9': 11,
  '6DEA': 11,
  '6DEB': 11,
  '6DEC': 11,
  '6DED': 11,
  '6DEE': 11,
  '6DEF': 11,
  '6DF0': 11,
  '6DF1': 11,
  '6DF2': 11,
  '6DF3': 11,
  '6DF4': 11,
  '6DF5': 12,
  '6DF6': 11,
  '6DF7': 11,
  '6DF8': 11,
  '6DF9': 11,
  '6DFA': 11,
  '6DFB': 11,
  '6DFC': 12,
  '6DFD': 10,
  '6DFE': 12,
  '6DFF': 11,
  '6E00': 11,
  '6E01': 11,
  '6E02': 11,
  '6E03': 11,
  '6E04': 11,
  '6E05': 11,
  '6E06': 11,
  '6E07': 11,
  '6E08': 11,
  '6E09': 11,
  '6E0A': 11,
  '6E0B': 11,
  '6E0C': 11,
  '6E0D': 11,
  '6E0E': 11,
  '6E0F': 11,
  '6E10': 11,
  '6E11': 11,
  '6E12': 11,
  '6E13': 11,
  '6E14': 11,
  '6E15': 11,
  '6E16': 11,
  '6E17': 11,
  '6E18': 12,
  '6E19': 12,
  '6E1A': 11,
  '6E1B': 12,
  '6E1C': 12,
  '6E1D': 12,
  '6E1E': 12,
  '6E1F': 12,
  '6E20': 11,
  '6E21': 12,
  '6E22': 12,
  '6E23': 12,
  '6E24': 12,
  '6E25': 12,
  '6E26': 11,
  '6E27': 12,
  '6E28': 12,
  '6E29': 12,
  '6E2A': 12,
  '6E2B': 12,
  '6E2C': 12,
  '6E2D': 12,
  '6E2E': 11,
  '6E2F': 12,
  '6E30': 12,
  '6E31': 12,
  '6E32': 12,
  '6E33': 12,
  '6E34': 12,
  '6E35': 11,
  '6E36': 11,
  '6E37': 12,
  '6E38': 12,
  '6E39': 12,
  '6E3A': 12,
  '6E3B': 12,
  '6E3C': 12,
  '6E3D': 12,
  '6E3E': 12,
  '6E3F': 12,
  '6E40': 12,
  '6E41': 12,
  '6E42': 12,
  '6E43': 12,
  '6E44': 12,
  '6E45': 12,
  '6E46': 12,
  '6E47': 12,
  '6E48': 12,
  '6E49': 12,
  '6E4A': 12,
  '6E4B': 12,
  '6E4C': 12,
  '6E4D': 12,
  '6E4E': 12,
  '6E4F': 12,
  '6E50': 12,
  '6E51': 12,
  '6E52': 12,
  '6E53': 12,
  '6E54': 12,
  '6E55': 11,
  '6E56': 12,
  '6E57': 12,
  '6E58': 12,
  '6E59': 12,
  '6E5A': 12,
  '6E5B': 12,
  '6E5C': 12,
  '6E5D': 12,
  '6E5E': 12,
  '6E5F': 12,
  '6E60': 12,
  '6E61': 12,
  '6E62': 12,
  '6E63': 12,
  '6E64': 12,
  '6E65': 12,
  '6E66': 12,
  '6E67': 12,
  '6E68': 12,
  '6E69': 12,
  '6E6A': 12,
  '6E6B': 12,
  '6E6C': 13,
  '6E6D': 12,
  '6E6E': 12,
  '6E6F': 12,
  '6E70': 12,
  '6E71': 12,
  '6E72': 12,
  '6E73': 12,
  '6E74': 11,
  '6E75': 12,
  '6E76': 12,
  '6E77': 12,
  '6E78': 12,
  '6E79': 12,
  '6E7A': 12,
  '6E7B': 12,
  '6E7C': 12,
  '6E7D': 12,
  '6E7E': 12,
  '6E7F': 12,
  '6E80': 12,
  '6E81': 12,
  '6E82': 12,
  '6E83': 12,
  '6E84': 12,
  '6E85': 12,
  '6E86': 12,
  '6E87': 12,
  '6E88': 12,
  '6E89': 12,
  '6E8A': 12,
  '6E8B': 12,
  '6E8C': 12,
  '6E8D': 13,
  '6E8E': 13,
  '6E8F': 13,
  '6E90': 13,
  '6E91': 13,
  '6E92': 13,
  '6E93': 13,
  '6E94': 13,
  '6E95': 13,
  '6E96': 13,
  '6E97': 13,
  '6E98': 13,
  '6E99': 13,
  '6E9A': 12,
  '6E9B': 13,
  '6E9C': 13,
  '6E9D': 13,
  '6E9E': 12,
  '6E9F': 13,
  '6EA0': 12,
  '6EA1': 13,
  '6EA2': 13,
  '6EA3': 13,
  '6EA4': 13,
  '6EA5': 13,
  '6EA6': 13,
  '6EA7': 13,
  '6EA8': 13,
  '6EA9': 13,
  '6EAA': 13,
  '6EAB': 13,
  '6EAC': 12,
  '6EAD': 13,
  '6EAE': 13,
  '6EAF': 13,
  '6EB0': 13,
  '6EB1': 13,
  '6EB2': 12,
  '6EB3': 13,
  '6EB4': 13,
  '6EB5': 13,
  '6EB6': 13,
  '6EB7': 13,
  '6EB8': 13,
  '6EB9': 13,
  '6EBA': 13,
  '6EBB': 13,
  '6EBC': 13,
  '6EBD': 13,
  '6EBE': 12,
  '6EBF': 13,
  '6EC0': 13,
  '6EC1': 12,
  '6EC2': 13,
  '6EC3': 13,
  '6EC4': 13,
  '6EC5': 13,
  '6EC6': 13,
  '6EC7': 13,
  '6EC8': 13,
  '6EC9': 13,
  '6ECA': 13,
  '6ECB': 12,
  '6ECC': 13,
  '6ECD': 13,
  '6ECE': 14,
  '6ECF': 13,
  '6ED0': 13,
  '6ED1': 12,
  '6ED2': 13,
  '6ED3': 13,
  '6ED4': 13,
  '6ED5': 15,
  '6ED6': 13,
  '6ED7': 13,
  '6ED8': 13,
  '6ED9': 13,
  '6EDA': 13,
  '6EDB': 13,
  '6EDC': 13,
  '6EDD': 13,
  '6EDE': 12,
  '6EDF': 13,
  '6EE0': 13,
  '6EE1': 13,
  '6EE2': 13,
  '6EE3': 13,
  '6EE4': 13,
  '6EE5': 13,
  '6EE6': 13,
  '6EE7': 13,
  '6EE8': 13,
  '6EE9': 13,
  '6EEA': 13,
  '6EEB': 13,
  '6EEC': 14,
  '6EED': 13,
  '6EEE': 14,
  '6EEF': 14,
  '6EF0': 14,
  '6EF1': 14,
  '6EF2': 14,
  '6EF3': 14,
  '6EF4': 14,
  '6EF5': 14,
  '6EF6': 13,
  '6EF7': 14,
  '6EF8': 14,
  '6EF9': 14,
  '6EFA': 14,
  '6EFB': 14,
  '6EFC': 14,
  '6EFD': 14,
  '6EFE': 14,
  '6EFF': 14,
  '6F00': 15,
  '6F01': 14,
  '6F02': 14,
  '6F03': 14,
  '6F04': 14,
  '6F05': 14,
  '6F06': 14,
  '6F07': 14,
  '6F08': 14,
  '6F09': 14,
  '6F0A': 14,
  '6F0B': 14,
  '6F0C': 14,
  '6F0D': 14,
  '6F0E': 14,
  '6F0F': 14,
  '6F10': 15,
  '6F11': 14,
  '6F12': 15,
  '6F13': 13,
  '6F14': 14,
  '6F15': 14,
  '6F16': 14,
  '6F17': 14,
  '6F18': 14,
  '6F19': 14,
  '6F1A': 14,
  '6F1B': 14,
  '6F1C': 14,
  '6F1D': 14,
  '6F1E': 14,
  '6F1F': 14,
  '6F20': 13,
  '6F21': 14,
  '6F22': 14,
  '6F23': 13,
  '6F24': 14,
  '6F25': 14,
  '6F26': 15,
  '6F27': 14,
  '6F28': 13,
  '6F29': 14,
  '6F2A': 14,
  '6F2B': 14,
  '6F2C': 14,
  '6F2D': 13,
  '6F2E': 14,
  '6F2F': 14,
  '6F30': 14,
  '6F31': 14,
  '6F32': 14,
  '6F33': 14,
  '6F34': 14,
  '6F35': 14,
  '6F36': 14,
  '6F37': 13,
  '6F38': 14,
  '6F39': 14,
  '6F3A': 14,
  '6F3B': 14,
  '6F3C': 14,
  '6F3D': 15,
  '6F3E': 14,
  '6F3F': 15,
  '6F40': 14,
  '6F41': 15,
  '6F42': 14,
  '6F43': 13,
  '6F44': 14,
  '6F45': 14,
  '6F46': 14,
  '6F47': 14,
  '6F48': 14,
  '6F49': 14,
  '6F4A': 14,
  '6F4B': 14,
  '6F4C': 14,
  '6F4D': 14,
  '6F4E': 14,
  '6F4F': 15,
  '6F50': 15,
  '6F51': 15,
  '6F52': 14,
  '6F53': 15,
  '6F54': 15,
  '6F55': 15,
  '6F56': 15,
  '6F57': 15,
  '6F58': 15,
  '6F59': 15,
  '6F5A': 16,
  '6F5B': 15,
  '6F5C': 15,
  '6F5D': 15,
  '6F5E': 16,
  '6F5F': 15,
  '6F60': 15,
  '6F61': 15,
  '6F62': 14,
  '6F63': 15,
  '6F64': 15,
  '6F65': 15,
  '6F66': 15,
  '6F67': 15,
  '6F68': 15,
  '6F69': 14,
  '6F6A': 15,
  '6F6B': 15,
  '6F6C': 15,
  '6F6D': 15,
  '6F6E': 15,
  '6F6F': 15,
  '6F70': 15,
  '6F71': 15,
  '6F72': 15,
  '6F73': 14,
  '6F74': 14,
  '6F75': 15,
  '6F76': 15,
  '6F77': 15,
  '6F78': 15,
  '6F79': 15,
  '6F7A': 15,
  '6F7B': 15,
  '6F7C': 15,
  '6F7D': 15,
  '6F7E': 15,
  '6F7F': 15,
  '6F80': 17,
  '6F81': 15,
  '6F82': 15,
  '6F83': 16,
  '6F84': 15,
  '6F85': 15,
  '6F86': 15,
  '6F87': 15,
  '6F88': 15,
  '6F89': 14,
  '6F8A': 15,
  '6F8B': 15,
  '6F8C': 15,
  '6F8D': 15,
  '6F8E': 15,
  '6F8F': 15,
  '6F90': 15,
  '6F91': 15,
  '6F92': 15,
  '6F93': 15,
  '6F94': 15,
  '6F95': 13,
  '6F96': 15,
  '6F97': 15,
  '6F98': 15,
  '6F99': 15,
  '6F9A': 14,
  '6F9B': 15,
  '6F9C': 15,
  '6F9D': 15,
  '6F9E': 16,
  '6F9F': 16,
  '6FA0': 16,
  '6FA1': 16,
  '6FA2': 16,
  '6FA3': 16,
  '6FA4': 16,
  '6FA5': 16,
  '6FA6': 16,
  '6FA7': 16,
  '6FA8': 16,
  '6FA9': 17,
  '6FAA': 16,
  '6FAB': 15,
  '6FAC': 16,
  '6FAD': 16,
  '6FAE': 16,
  '6FAF': 16,
  '6FB0': 16,
  '6FB1': 16,
  '6FB2': 16,
  '6FB3': 15,
  '6FB4': 16,
  '6FB5': 16,
  '6FB6': 16,
  '6FB7': 16,
  '6FB8': 16,
  '6FB9': 16,
  '6FBA': 16,
  '6FBB': 15,
  '6FBC': 16,
  '6FBD': 16,
  '6FBE': 15,
  '6FBF': 16,
  '6FC0': 16,
  '6FC1': 16,
  '6FC2': 16,
  '6FC3': 16,
  '6FC4': 14,
  '6FC5': 16,
  '6FC6': 15,
  '6FC7': 16,
  '6FC8': 15,
  '6FC9': 16,
  '6FCA': 16,
  '6FCB': 16,
  '6FCC': 17,
  '6FCD': 15,
  '6FCE': 15,
  '6FCF': 16,
  '6FD0': 15,
  '6FD1': 16,
  '6FD2': 16,
  '6FD3': 16,
  '6FD4': 17,
  '6FD5': 17,
  '6FD6': 16,
  '6FD7': 16,
  '6FD8': 17,
  '6FD9': 17,
  '6FDA': 17,
  '6FDB': 16,
  '6FDC': 17,
  '6FDD': 17,
  '6FDE': 17,
  '6FDF': 17,
  '6FE0': 17,
  '6FE1': 17,
  '6FE2': 17,
  '6FE3': 17,
  '6FE4': 17,
  '6FE5': 17,
  '6FE6': 17,
  '6FE7': 17,
  '6FE8': 16,
  '6FE9': 16,
  '6FEA': 17,
  '6FEB': 17,
  '6FEC': 17,
  '6FED': 16,
  '6FEE': 17,
  '6FEF': 17,
  '6FF0': 17,
  '6FF1': 17,
  '6FF2': 17,
  '6FF3': 19,
  '6FF4': 17,
  '6FF5': 17,
  '6FF6': 17,
  '6FF7': 18,
  '6FF8': 16,
  '6FF9': 17,
  '6FFA': 18,
  '6FFB': 17,
  '6FFC': 18,
  '6FFD': 18,
  '6FFE': 18,
  '6FFF': 17,
  '7000': 18,
  '7001': 17,
  '7002': 18,
  '7003': 18,
  '7004': 16,
  '7005': 18,
  '7006': 18,
  '7007': 17,
  '7008': 18,
  '7009': 18,
  '700A': 18,
  '700B': 18,
  '700C': 18,
  '700D': 18,
  '700E': 17,
  '700F': 18,
  '7010': 18,
  '7011': 18,
  '7012': 18,
  '7013': 18,
  '7014': 18,
  '7015': 19,
  '7016': 19,
  '7017': 19,
  '7018': 19,
  '7019': 19,
  '701A': 19,
  '701B': 19,
  '701C': 19,
  '701D': 19,
  '701E': 17,
  '701F': 19,
  '7020': 19,
  '7021': 17,
  '7022': 18,
  '7023': 19,
  '7024': 19,
  '7025': 19,
  '7026': 18,
  '7027': 19,
  '7028': 19,
  '7029': 19,
  '702A': 20,
  '702B': 19,
  '702C': 19,
  '702D': 19,
  '702E': 19,
  '702F': 19,
  '7030': 20,
  '7031': 20,
  '7032': 20,
  '7033': 19,
  '7034': 20,
  '7035': 20,
  '7036': 20,
  '7037': 20,
  '7038': 20,
  '7039': 20,
  '703A': 20,
  '703B': 20,
  '703C': 20,
  '703D': 20,
  '703E': 20,
  '703F': 20,
  '7040': 20,
  '7041': 20,
  '7042': 20,
  '7043': 21,
  '7044': 21,
  '7045': 21,
  '7046': 20,
  '7047': 21,
  '7048': 21,
  '7049': 21,
  '704A': 21,
  '704B': 21,
  '704C': 20,
  '704D': 21,
  '704E': 26,
  '704F': 21,
  '7050': 21,
  '7051': 22,
  '7052': 22,
  '7053': 23,
  '7054': 22,
  '7055': 21,
  '7056': 22,
  '7057': 22,
  '7058': 22,
  '7059': 23,
  '705A': 23,
  '705B': 23,
  '705C': 23,
  '705D': 24,
  '705E': 24,
  '705F': 24,
  '7060': 24,
  '7061': 23,
  '7062': 25,
  '7063': 25,
  '7064': 26,
  '7065': 27,
  '7066': 26,
  '7067': 27,
  '7068': 27,
  '7069': 31,
  '706A': 32,
  '706B': 4,
  '706C': 4,
  '706D': 5,
  '706E': 6,
  '706F': 6,
  '7070': 6,
  '7071': 6,
  '7072': 6,
  '7073': 6,
  '7074': 7,
  '7075': 7,
  '7076': 7,
  '7077': 7,
  '7078': 7,
  '7079': 7,
  '707A': 7,
  '707B': 7,
  '707C': 7,
  '707D': 7,
  '707E': 7,
  '707F': 7,
  '7080': 7,
  '7081': 8,
  '7082': 8,
  '7083': 8,
  '7084': 8,
  '7085': 8,
  '7086': 8,
  '7087': 8,
  '7088': 8,
  '7089': 8,
  '708A': 8,
  '708B': 8,
  '708C': 8,
  '708D': 8,
  '708E': 8,
  '708F': 8,
  '7090': 8,
  '7091': 8,
  '7092': 8,
  '7093': 8,
  '7094': 8,
  '7095': 8,
  '7096': 8,
  '7097': 8,
  '7098': 8,
  '7099': 8,
  '709A': 8,
  '709B': 8,
  '709C': 8,
  '709D': 8,
  '709E': 8,
  '709F': 9,
  '70A0': 9,
  '70A1': 9,
  '70A2': 9,
  '70A3': 9,
  '70A4': 9,
  '70A5': 9,
  '70A6': 9,
  '70A7': 9,
  '70A8': 9,
  '70A9': 9,
  '70AA': 9,
  '70AB': 9,
  '70AC': 8,
  '70AD': 9,
  '70AE': 9,
  '70AF': 9,
  '70B0': 9,
  '70B1': 9,
  '70B2': 9,
  '70B3': 9,
  '70B4': 9,
  '70B5': 9,
  '70B6': 9,
  '70B7': 9,
  '70B8': 9,
  '70B9': 9,
  '70BA': 9,
  '70BB': 9,
  '70BC': 9,
  '70BD': 9,
  '70BE': 9,
  '70BF': 9,
  '70C0': 9,
  '70C1': 9,
  '70C2': 9,
  '70C3': 9,
  '70C4': 10,
  '70C5': 10,
  '70C6': 10,
  '70C7': 10,
  '70C8': 10,
  '70C9': 10,
  '70CA': 10,
  '70CB': 10,
  '70CC': 10,
  '70CD': 10,
  '70CE': 8,
  '70CF': 10,
  '70D0': 10,
  '70D1': 10,
  '70D2': 10,
  '70D3': 10,
  '70D4': 10,
  '70D5': 10,
  '70D6': 10,
  '70D7': 10,
  '70D8': 10,
  '70D9': 10,
  '70DA': 10,
  '70DB': 10,
  '70DC': 10,
  '70DD': 10,
  '70DE': 10,
  '70DF': 10,
  '70E0': 10,
  '70E1': 10,
  '70E2': 10,
  '70E3': 10,
  '70E4': 10,
  '70E5': 10,
  '70E6': 10,
  '70E7': 10,
  '70E8': 10,
  '70E9': 10,
  '70EA': 10,
  '70EB': 10,
  '70EC': 10,
  '70ED': 10,
  '70EE': 10,
  '70EF': 11,
  '70F0': 11,
  '70F1': 11,
  '70F2': 11,
  '70F3': 11,
  '70F4': 11,
  '70F5': 10,
  '70F6': 10,
  '70F7': 11,
  '70F8': 11,
  '70F9': 11,
  '70FA': 11,
  '70FB': 10,
  '70FC': 11,
  '70FD': 11,
  '70FE': 11,
  '70FF': 11,
  '7100': 11,
  '7101': 11,
  '7102': 11,
  '7103': 11,
  '7104': 11,
  '7105': 11,
  '7106': 11,
  '7107': 11,
  '7108': 11,
  '7109': 11,
  '710A': 11,
  '710B': 11,
  '710C': 11,
  '710D': 11,
  '710E': 11,
  '710F': 11,
  '7110': 11,
  '7111': 11,
  '7112': 10,
  '7113': 11,
  '7114': 11,
  '7115': 11,
  '7116': 11,
  '7117': 11,
  '7118': 11,
  '7119': 12,
  '711A': 12,
  '711B': 12,
  '711C': 12,
  '711D': 12,
  '711E': 12,
  '711F': 12,
  '7120': 12,
  '7121': 12,
  '7122': 12,
  '7123': 12,
  '7124': 12,
  '7125': 12,
  '7126': 12,
  '7127': 12,
  '7128': 12,
  '7129': 12,
  '712A': 12,
  '712B': 11,
  '712C': 12,
  '712D': 12,
  '712E': 12,
  '712F': 12,
  '7130': 12,
  '7131': 12,
  '7132': 12,
  '7133': 12,
  '7134': 12,
  '7135': 12,
  '7136': 12,
  '7137': 12,
  '7138': 12,
  '7139': 12,
  '713A': 12,
  '713B': 12,
  '713C': 12,
  '713D': 12,
  '713E': 12,
  '713F': 12,
  '7140': 12,
  '7141': 13,
  '7142': 13,
  '7143': 13,
  '7144': 13,
  '7145': 13,
  '7146': 13,
  '7147': 13,
  '7148': 13,
  '7149': 13,
  '714A': 13,
  '714B': 13,
  '714C': 13,
  '714D': 13,
  '714E': 13,
  '714F': 13,
  '7150': 12,
  '7151': 12,
  '7152': 13,
  '7153': 13,
  '7154': 13,
  '7155': 14,
  '7156': 13,
  '7157': 13,
  '7158': 13,
  '7159': 13,
  '715A': 12,
  '715B': 14,
  '715C': 13,
  '715D': 13,
  '715E': 13,
  '715F': 13,
  '7160': 13,
  '7161': 12,
  '7162': 13,
  '7163': 13,
  '7164': 13,
  '7165': 13,
  '7166': 13,
  '7167': 13,
  '7168': 13,
  '7169': 13,
  '716A': 13,
  '716B': 13,
  '716C': 13,
  '716D': 13,
  '716E': 12,
  '716F': 13,
  '7170': 13,
  '7171': 12,
  '7172': 13,
  '7173': 13,
  '7174': 13,
  '7175': 13,
  '7176': 13,
  '7177': 13,
  '7178': 13,
  '7179': 14,
  '717A': 13,
  '717B': 14,
  '717C': 14,
  '717D': 14,
  '717E': 14,
  '717F': 14,
  '7180': 14,
  '7181': 14,
  '7182': 14,
  '7183': 14,
  '7184': 14,
  '7185': 14,
  '7186': 14,
  '7187': 14,
  '7188': 14,
  '7189': 14,
  '718A': 14,
  '718B': 14,
  '718C': 14,
  '718D': 13,
  '718E': 14,
  '718F': 14,
  '7190': 14,
  '7191': 14,
  '7192': 14,
  '7193': 14,
  '7194': 14,
  '7195': 14,
  '7196': 14,
  '7197': 14,
  '7198': 14,
  '7199': 14,
  '719A': 14,
  '719B': 15,
  '719C': 15,
  '719D': 15,
  '719E': 15,
  '719F': 15,
  '71A0': 15,
  '71A1': 15,
  '71A2': 14,
  '71A3': 15,
  '71A4': 15,
  '71A5': 14,
  '71A6': 15,
  '71A7': 15,
  '71A8': 15,
  '71A9': 15,
  '71AA': 15,
  '71AB': 15,
  '71AC': 14,
  '71AD': 15,
  '71AE': 15,
  '71AF': 15,
  '71B0': 15,
  '71B1': 15,
  '71B2': 15,
  '71B3': 15,
  '71B4': 15,
  '71B5': 15,
  '71B6': 16,
  '71B7': 16,
  '71B8': 16,
  '71B9': 16,
  '71BA': 16,
  '71BB': 16,
  '71BC': 15,
  '71BD': 17,
  '71BE': 16,
  '71BF': 15,
  '71C0': 16,
  '71C1': 14,
  '71C2': 16,
  '71C3': 16,
  '71C4': 16,
  '71C5': 16,
  '71C6': 16,
  '71C7': 16,
  '71C8': 16,
  '71C9': 16,
  '71CA': 16,
  '71CB': 16,
  '71CC': 16,
  '71CD': 16,
  '71CE': 16,
  '71CF': 16,
  '71D0': 16,
  '71D1': 16,
  '71D2': 16,
  '71D3': 16,
  '71D4': 16,
  '71D5': 16,
  '71D6': 16,
  '71D7': 16,
  '71D8': 16,
  '71D9': 16,
  '71DA': 16,
  '71DB': 16,
  '71DC': 16,
  '71DD': 16,
  '71DE': 16,
  '71DF': 16,
  '71E0': 16,
  '71E1': 17,
  '71E2': 17,
  '71E3': 17,
  '71E4': 16,
  '71E5': 17,
  '71E6': 17,
  '71E7': 16,
  '71E8': 17,
  '71E9': 17,
  '71EA': 16,
  '71EB': 17,
  '71EC': 17,
  '71ED': 17,
  '71EE': 17,
  '71EF': 17,
  '71F0': 17,
  '71F1': 17,
  '71F2': 17,
  '71F3': 17,
  '71F4': 17,
  '71F5': 16,
  '71F6': 17,
  '71F7': 17,
  '71F8': 18,
  '71F9': 18,
  '71FA': 18,
  '71FB': 18,
  '71FC': 18,
  '71FD': 18,
  '71FE': 18,
  '71FF': 18,
  '7200': 18,
  '7201': 18,
  '7202': 19,
  '7203': 18,
  '7204': 18,
  '7205': 19,
  '7206': 19,
  '7207': 18,
  '7208': 19,
  '7209': 19,
  '720A': 19,
  '720B': 20,
  '720C': 18,
  '720D': 19,
  '720E': 19,
  '720F': 20,
  '7210': 20,
  '7211': 19,
  '7212': 20,
  '7213': 20,
  '7214': 20,
  '7215': 19,
  '7216': 20,
  '7217': 18,
  '7218': 20,
  '7219': 21,
  '721A': 21,
  '721B': 21,
  '721C': 22,
  '721D': 21,
  '721E': 22,
  '721F': 21,
  '7220': 22,
  '7221': 22,
  '7222': 23,
  '7223': 24,
  '7224': 24,
  '7225': 25,
  '7226': 25,
  '7227': 28,
  '7228': 30,
  '7229': 33,
  '722A': 4,
  '722B': 4,
  '722C': 8,
  '722D': 8,
  '722E': 9,
  '722F': 9,
  '7230': 9,
  '7231': 10,
  '7232': 12,
  '7233': 14,
  '7234': 15,
  '7235': 17,
  '7236': 4,
  '7237': 6,
  '7238': 8,
  '7239': 10,
  '723A': 12,
  '723B': 4,
  '723C': 9,
  '723D': 11,
  '723E': 14,
  '723F': 4,
  '7240': 8,
  '7241': 9,
  '7242': 10,
  '7243': 13,
  '7244': 14,
  '7245': 15,
  '7246': 17,
  '7247': 4,
  '7248': 8,
  '7249': 9,
  '724A': 9,
  '724B': 12,
  '724C': 12,
  '724D': 12,
  '724E': 13,
  '724F': 13,
  '7250': 13,
  '7251': 13,
  '7252': 13,
  '7253': 14,
  '7254': 14,
  '7255': 15,
  '7256': 15,
  '7257': 15,
  '7258': 19,
  '7259': 4,
  '725A': 12,
  '725B': 4,
  '725C': 4,
  '725D': 6,
  '725E': 6,
  '725F': 6,
  '7260': 7,
  '7261': 7,
  '7262': 7,
  '7263': 7,
  '7264': 7,
  '7265': 8,
  '7266': 8,
  '7267': 8,
  '7268': 8,
  '7269': 8,
  '726A': 8,
  '726B': 8,
  '726C': 8,
  '726D': 9,
  '726E': 9,
  '726F': 9,
  '7270': 9,
  '7271': 9,
  '7272': 9,
  '7273': 9,
  '7274': 9,
  '7275': 9,
  '7276': 10,
  '7277': 10,
  '7278': 10,
  '7279': 10,
  '727A': 10,
  '727B': 11,
  '727C': 11,
  '727D': 11,
  '727E': 11,
  '727F': 11,
  '7280': 12,
  '7281': 11,
  '7282': 12,
  '7283': 12,
  '7284': 12,
  '7285': 12,
  '7286': 12,
  '7287': 12,
  '7288': 12,
  '7289': 12,
  '728A': 12,
  '728B': 12,
  '728C': 13,
  '728D': 12,
  '728E': 13,
  '728F': 13,
  '7290': 13,
  '7291': 13,
  '7292': 14,
  '7293': 14,
  '7294': 14,
  '7295': 14,
  '7296': 14,
  '7297': 14,
  '7298': 15,
  '7299': 15,
  '729A': 15,
  '729B': 15,
  '729C': 16,
  '729D': 16,
  '729E': 16,
  '729F': 16,
  '72A0': 17,
  '72A1': 18,
  '72A2': 19,
  '72A3': 19,
  '72A4': 19,
  '72A5': 19,
  '72A6': 19,
  '72A7': 20,
  '72A8': 20,
  '72A9': 21,
  '72AA': 25,
  '72AB': 27,
  '72AC': 4,
  '72AD': 3,
  '72AE': 5,
  '72AF': 5,
  '72B0': 5,
  '72B1': 6,
  '72B2': 6,
  '72B3': 6,
  '72B4': 6,
  '72B5': 6,
  '72B6': 7,
  '72B7': 6,
  '72B8': 6,
  '72B9': 7,
  '72BA': 7,
  '72BB': 7,
  '72BC': 7,
  '72BD': 7,
  '72BE': 7,
  '72BF': 7,
  '72C0': 8,
  '72C1': 7,
  '72C2': 7,
  '72C3': 7,
  '72C4': 7,
  '72C5': 7,
  '72C6': 7,
  '72C7': 7,
  '72C8': 7,
  '72C9': 8,
  '72CA': 9,
  '72CB': 8,
  '72CC': 8,
  '72CD': 8,
  '72CE': 8,
  '72CF': 8,
  '72D0': 8,
  '72D1': 8,
  '72D2': 8,
  '72D3': 8,
  '72D4': 8,
  '72D5': 8,
  '72D6': 8,
  '72D7': 8,
  '72D8': 8,
  '72D9': 8,
  '72DA': 8,
  '72DB': 8,
  '72DC': 8,
  '72DD': 8,
  '72DE': 8,
  '72DF': 9,
  '72E0': 9,
  '72E1': 9,
  '72E2': 9,
  '72E3': 9,
  '72E4': 9,
  '72E5': 9,
  '72E6': 9,
  '72E7': 9,
  '72E8': 9,
  '72E9': 9,
  '72EA': 9,
  '72EB': 9,
  '72EC': 9,
  '72ED': 9,
  '72EE': 9,
  '72EF': 9,
  '72F0': 9,
  '72F1': 9,
  '72F2': 9,
  '72F3': 10,
  '72F4': 10,
  '72F5': 10,
  '72F6': 10,
  '72F7': 10,
  '72F8': 10,
  '72F9': 10,
  '72FA': 10,
  '72FB': 10,
  '72FC': 10,
  '72FD': 10,
  '72FE': 10,
  '72FF': 9,
  '7300': 10,
  '7301': 10,
  '7302': 10,
  '7303': 10,
  '7304': 11,
  '7305': 11,
  '7306': 12,
  '7307': 11,
  '7308': 11,
  '7309': 11,
  '730A': 11,
  '730B': 12,
  '730C': 12,
  '730D': 11,
  '730E': 11,
  '730F': 11,
  '7310': 10,
  '7311': 11,
  '7312': 12,
  '7313': 11,
  '7314': 11,
  '7315': 11,
  '7316': 11,
  '7317': 11,
  '7318': 11,
  '7319': 11,
  '731A': 11,
  '731B': 11,
  '731C': 11,
  '731D': 11,
  '731E': 11,
  '731F': 11,
  '7320': 11,
  '7321': 11,
  '7322': 12,
  '7323': 12,
  '7324': 12,
  '7325': 12,
  '7326': 12,
  '7327': 11,
  '7328': 12,
  '7329': 12,
  '732A': 11,
  '732B': 11,
  '732C': 12,
  '732D': 12,
  '732E': 13,
  '732F': 12,
  '7330': 12,
  '7331': 12,
  '7332': 12,
  '7333': 12,
  '7334': 12,
  '7335': 12,
  '7336': 12,
  '7337': 13,
  '7338': 12,
  '7339': 12,
  '733A': 13,
  '733B': 13,
  '733C': 13,
  '733D': 13,
  '733E': 12,
  '733F': 13,
  '7340': 12,
  '7341': 13,
  '7342': 13,
  '7343': 14,
  '7344': 14,
  '7345': 13,
  '7346': 13,
  '7347': 12,
  '7348': 13,
  '7349': 13,
  '734A': 13,
  '734B': 15,
  '734C': 14,
  '734D': 14,
  '734E': 15,
  '734F': 13,
  '7350': 14,
  '7351': 14,
  '7352': 14,
  '7353': 13,
  '7354': 14,
  '7355': 14,
  '7356': 15,
  '7357': 15,
  '7358': 15,
  '7359': 14,
  '735A': 14,
  '735B': 15,
  '735C': 15,
  '735D': 15,
  '735E': 15,
  '735F': 15,
  '7360': 15,
  '7361': 14,
  '7362': 15,
  '7363': 16,
  '7364': 15,
  '7365': 16,
  '7366': 15,
  '7367': 16,
  '7368': 16,
  '7369': 16,
  '736A': 16,
  '736B': 16,
  '736C': 16,
  '736D': 16,
  '736E': 17,
  '736F': 17,
  '7370': 17,
  '7371': 17,
  '7372': 16,
  '7373': 17,
  '7374': 16,
  '7375': 18,
  '7376': 18,
  '7377': 17,
  '7378': 19,
  '7379': 19,
  '737A': 19,
  '737B': 20,
  '737C': 20,
  '737D': 20,
  '737E': 20,
  '737F': 22,
  '7380': 22,
  '7381': 22,
  '7382': 22,
  '7383': 23,
  '7384': 5,
  '7385': 9,
  '7386': 10,
  '7387': 11,
  '7388': 11,
  '7389': 5,
  '738A': 5,
  '738B': 4,
  '738C': 5,
  '738D': 5,
  '738E': 6,
  '738F': 6,
  '7390': 6,
  '7391': 6,
  '7392': 7,
  '7393': 7,
  '7394': 7,
  '7395': 7,
  '7396': 7,
  '7397': 7,
  '7398': 7,
  '7399': 7,
  '739A': 7,
  '739B': 7,
  '739C': 8,
  '739D': 8,
  '739E': 8,
  '739F': 8,
  '73A0': 8,
  '73A1': 8,
  '73A2': 8,
  '73A3': 8,
  '73A4': 8,
  '73A5': 8,
  '73A6': 8,
  '73A7': 8,
  '73A8': 8,
  '73A9': 8,
  '73AA': 8,
  '73AB': 8,
  '73AC': 8,
  '73AD': 8,
  '73AE': 8,
  '73AF': 8,
  '73B0': 8,
  '73B1': 8,
  '73B2': 9,
  '73B3': 9,
  '73B4': 9,
  '73B5': 9,
  '73B6': 9,
  '73B7': 9,
  '73B8': 9,
  '73B9': 9,
  '73BA': 10,
  '73BB': 9,
  '73BC': 10,
  '73BD': 9,
  '73BE': 9,
  '73BF': 9,
  '73C0': 9,
  '73C1': 8,
  '73C2': 9,
  '73C3': 9,
  '73C4': 9,
  '73C5': 9,
  '73C6': 9,
  '73C7': 9,
  '73C8': 9,
  '73C9': 9,
  '73CA': 9,
  '73CB': 9,
  '73CC': 9,
  '73CD': 9,
  '73CE': 9,
  '73CF': 9,
  '73D0': 9,
  '73D1': 9,
  '73D2': 10,
  '73D3': 10,
  '73D4': 10,
  '73D5': 10,
  '73D6': 10,
  '73D7': 10,
  '73D8': 10,
  '73D9': 10,
  '73DA': 10,
  '73DB': 10,
  '73DC': 10,
  '73DD': 10,
  '73DE': 10,
  '73DF': 10,
  '73E0': 10,
  '73E1': 10,
  '73E2': 10,
  '73E3': 10,
  '73E4': 10,
  '73E5': 10,
  '73E6': 10,
  '73E7': 10,
  '73E8': 10,
  '73E9': 10,
  '73EA': 10,
  '73EB': 10,
  '73EC': 10,
  '73ED': 10,
  '73EE': 10,
  '73EF': 10,
  '73F0': 10,
  '73F1': 10,
  '73F2': 10,
  '73F3': 11,
  '73F4': 11,
  '73F5': 11,
  '73F6': 11,
  '73F7': 12,
  '73F8': 11,
  '73F9': 10,
  '73FA': 11,
  '73FB': 11,
  '73FC': 11,
  '73FD': 10,
  '73FE': 11,
  '73FF': 11,
  '7400': 11,
  '7401': 11,
  '7402': 11,
  '7403': 11,
  '7404': 11,
  '7405': 11,
  '7406': 11,
  '7407': 11,
  '7408': 11,
  '7409': 11,
  '740A': 10,
  '740B': 11,
  '740C': 11,
  '740D': 11,
  '740E': 11,
  '740F': 11,
  '7410': 11,
  '7411': 11,
  '7412': 11,
  '7413': 11,
  '7414': 12,
  '7415': 12,
  '7416': 12,
  '7417': 12,
  '7418': 12,
  '7419': 12,
  '741A': 12,
  '741B': 12,
  '741C': 12,
  '741D': 12,
  '741E': 13,
  '741F': 12,
  '7420': 12,
  '7421': 12,
  '7422': 12,
  '7423': 12,
  '7424': 10,
  '7425': 12,
  '7426': 12,
  '7427': 13,
  '7428': 12,
  '7429': 12,
  '742A': 12,
  '742B': 12,
  '742C': 12,
  '742D': 12,
  '742E': 12,
  '742F': 12,
  '7430': 12,
  '7431': 12,
  '7432': 12,
  '7433': 12,
  '7434': 12,
  '7435': 12,
  '7436': 12,
  '7437': 11,
  '7438': 12,
  '7439': 12,
  '743A': 12,
  '743B': 12,
  '743C': 12,
  '743D': 12,
  '743E': 13,
  '743F': 13,
  '7440': 13,
  '7441': 13,
  '7442': 13,
  '7443': 13,
  '7444': 13,
  '7445': 13,
  '7446': 13,
  '7447': 13,
  '7448': 13,
  '7449': 13,
  '744A': 13,
  '744B': 13,
  '744C': 13,
  '744D': 13,
  '744E': 13,
  '744F': 13,
  '7450': 13,
  '7451': 13,
  '7452': 13,
  '7453': 13,
  '7454': 13,
  '7455': 13,
  '7456': 13,
  '7457': 13,
  '7458': 12,
  '7459': 13,
  '745A': 13,
  '745B': 12,
  '745C': 13,
  '745D': 13,
  '745E': 13,
  '745F': 13,
  '7460': 14,
  '7461': 14,
  '7462': 14,
  '7463': 14,
  '7464': 14,
  '7465': 13,
  '7466': 14,
  '7467': 14,
  '7468': 14,
  '7469': 15,
  '746A': 14,
  '746B': 14,
  '746C': 15,
  '746D': 14,
  '746E': 14,
  '746F': 12,
  '7470': 13,
  '7471': 14,
  '7472': 14,
  '7473': 13,
  '7474': 14,
  '7475': 13,
  '7476': 14,
  '7477': 14,
  '7478': 14,
  '7479': 14,
  '747A': 15,
  '747B': 15,
  '747C': 15,
  '747D': 15,
  '747E': 15,
  '747F': 16,
  '7480': 15,
  '7481': 15,
  '7482': 15,
  '7483': 14,
  '7484': 15,
  '7485': 15,
  '7486': 15,
  '7487': 15,
  '7488': 14,
  '7489': 14,
  '748A': 15,
  '748B': 15,
  '748C': 15,
  '748D': 14,
  '748E': 15,
  '748F': 16,
  '7490': 17,
  '7491': 16,
  '7492': 16,
  '7493': 14,
  '7494': 16,
  '7495': 16,
  '7496': 15,
  '7497': 17,
  '7498': 16,
  '7499': 16,
  '749A': 16,
  '749B': 17,
  '749C': 15,
  '749D': 16,
  '749E': 16,
  '749F': 16,
  '74A0': 16,
  '74A1': 15,
  '74A2': 16,
  '74A3': 16,
  '74A4': 16,
  '74A5': 16,
  '74A6': 17,
  '74A7': 18,
  '74A8': 17,
  '74A9': 17,
  '74AA': 17,
  '74AB': 17,
  '74AC': 17,
  '74AD': 16,
  '74AE': 17,
  '74AF': 17,
  '74B0': 17,
  '74B1': 17,
  '74B2': 16,
  '74B3': 17,
  '74B4': 17,
  '74B5': 17,
  '74B6': 18,
  '74B7': 19,
  '74B8': 18,
  '74B9': 18,
  '74BA': 20,
  '74BB': 18,
  '74BC': 18,
  '74BD': 19,
  '74BE': 18,
  '74BF': 18,
  '74C0': 18,
  '74C1': 17,
  '74C2': 17,
  '74C3': 19,
  '74C4': 19,
  '74C5': 19,
  '74C6': 19,
  '74C7': 19,
  '74C8': 19,
  '74C9': 19,
  '74CA': 18,
  '74CB': 18,
  '74CC': 20,
  '74CD': 18,
  '74CE': 20,
  '74CF': 20,
  '74D0': 20,
  '74D1': 20,
  '74D2': 20,
  '74D3': 21,
  '74D4': 21,
  '74D5': 22,
  '74D6': 21,
  '74D7': 22,
  '74D8': 21,
  '74D9': 22,
  '74DA': 23,
  '74DB': 24,
  '74DC': 5,
  '74DD': 8,
  '74DE': 10,
  '74DF': 10,
  '74E0': 11,
  '74E1': 13,
  '74E2': 16,
  '74E3': 19,
  '74E4': 22,
  '74E5': 24,
  '74E6': 4,
  '74E7': 7,
  '74E8': 7,
  '74E9': 7,
  '74EA': 8,
  '74EB': 8,
  '74EC': 8,
  '74ED': 8,
  '74EE': 8,
  '74EF': 8,
  '74F0': 8,
  '74F1': 9,
  '74F2': 8,
  '74F3': 9,
  '74F4': 9,
  '74F5': 9,
  '74F6': 10,
  '74F7': 10,
  '74F8': 11,
  '74F9': 11,
  '74FA': 11,
  '74FB': 11,
  '74FC': 11,
  '74FD': 12,
  '74FE': 12,
  '74FF': 12,
  '7500': 12,
  '7501': 12,
  '7502': 13,
  '7503': 13,
  '7504': 13,
  '7505': 13,
  '7506': 13,
  '7507': 14,
  '7508': 14,
  '7509': 14,
  '750A': 15,
  '750B': 15,
  '750C': 15,
  '750D': 14,
  '750E': 15,
  '750F': 16,
  '7510': 16,
  '7511': 16,
  '7512': 16,
  '7513': 17,
  '7514': 17,
  '7515': 17,
  '7516': 18,
  '7517': 20,
  '7518': 5,
  '7519': 8,
  '751A': 9,
  '751B': 11,
  '751C': 11,
  '751D': 13,
  '751E': 13,
  '751F': 5,
  '7520': 9,
  '7521': 10,
  '7522': 11,
  '7523': 11,
  '7524': 12,
  '7525': 12,
  '7526': 12,
  '7527': 14,
  '7528': 5,
  '7529': 5,
  '752A': 6,
  '752B': 7,
  '752C': 7,
  '752D': 9,
  '752E': 9,
  '752F': 12,
  '7530': 5,
  '7531': 5,
  '7532': 5,
  '7533': 5,
  '7534': 5,
  '7535': 5,
  '7536': 6,
  '7537': 7,
  '7538': 7,
  '7539': 7,
  '753A': 7,
  '753B': 8,
  '753C': 7,
  '753D': 8,
  '753E': 8,
  '753F': 8,
  '7540': 8,
  '7541': 8,
  '7542': 8,
  '7543': 8,
  '7544': 8,
  '7545': 8,
  '7546': 9,
  '7547': 9,
  '7548': 9,
  '7549': 9,
  '754A': 9,
  '754B': 9,
  '754C': 9,
  '754D': 9,
  '754E': 9,
  '754F': 9,
  '7550': 9,
  '7551': 9,
  '7552': 9,
  '7553': 9,
  '7554': 10,
  '7555': 10,
  '7556': 10,
  '7557': 10,
  '7558': 10,
  '7559': 10,
  '755A': 10,
  '755B': 10,
  '755C': 10,
  '755D': 10,
  '755E': 10,
  '755F': 10,
  '7560': 10,
  '7561': 11,
  '7562': 10,
  '7563': 11,
  '7564': 11,
  '7565': 11,
  '7566': 11,
  '7567': 11,
  '7568': 11,
  '7569': 11,
  '756A': 12,
  '756B': 12,
  '756C': 12,
  '756D': 12,
  '756E': 12,
  '756F': 12,
  '7570': 11,
  '7571': 12,
  '7572': 12,
  '7573': 12,
  '7574': 12,
  '7575': 13,
  '7576': 13,
  '7577': 13,
  '7578': 13,
  '7579': 13,
  '757A': 13,
  '757B': 14,
  '757C': 14,
  '757D': 14,
  '757E': 15,
  '757F': 15,
  '7580': 16,
  '7581': 16,
  '7582': 16,
  '7583': 17,
  '7584': 17,
  '7585': 18,
  '7586': 19,
  '7587': 19,
  '7588': 20,
  '7589': 20,
  '758A': 22,
  '758B': 5,
  '758C': 8,
  '758D': 10,
  '758E': 12,
  '758F': 12,
  '7590': 14,
  '7591': 14,
  '7592': 5,
  '7593': 7,
  '7594': 7,
  '7595': 7,
  '7596': 7,
  '7597': 7,
  '7598': 8,
  '7599': 8,
  '759A': 8,
  '759B': 8,
  '759C': 8,
  '759D': 8,
  '759E': 8,
  '759F': 8,
  '75A0': 8,
  '75A1': 8,
  '75A2': 9,
  '75A3': 9,
  '75A4': 9,
  '75A5': 9,
  '75A6': 9,
  '75A7': 9,
  '75A8': 9,
  '75A9': 9,
  '75AA': 9,
  '75AB': 9,
  '75AC': 9,
  '75AD': 9,
  '75AE': 9,
  '75AF': 9,
  '75B0': 10,
  '75B1': 10,
  '75B2': 10,
  '75B3': 10,
  '75B4': 10,
  '75B5': 11,
  '75B6': 10,
  '75B7': 10,
  '75B8': 10,
  '75B9': 10,
  '75BA': 9,
  '75BB': 10,
  '75BC': 10,
  '75BD': 10,
  '75BE': 10,
  '75BF': 10,
  '75C0': 10,
  '75C1': 10,
  '75C2': 10,
  '75C3': 10,
  '75C4': 10,
  '75C5': 10,
  '75C6': 10,
  '75C7': 10,
  '75C8': 10,
  '75C9': 10,
  '75CA': 11,
  '75CB': 11,
  '75CC': 11,
  '75CD': 11,
  '75CE': 11,
  '75CF': 11,
  '75D0': 11,
  '75D1': 11,
  '75D2': 11,
  '75D3': 11,
  '75D4': 11,
  '75D5': 11,
  '75D6': 11,
  '75D7': 12,
  '75D8': 12,
  '75D9': 12,
  '75DA': 12,
  '75DB': 12,
  '75DC': 12,
  '75DD': 12,
  '75DE': 12,
  '75DF': 12,
  '75E0': 12,
  '75E1': 12,
  '75E2': 12,
  '75E3': 12,
  '75E4': 12,
  '75E5': 12,
  '75E6': 12,
  '75E7': 12,
  '75E8': 12,
  '75E9': 12,
  '75EA': 12,
  '75EB': 12,
  '75EC': 13,
  '75ED': 13,
  '75EE': 13,
  '75EF': 13,
  '75F0': 13,
  '75F1': 13,
  '75F2': 13,
  '75F3': 13,
  '75F4': 13,
  '75F5': 13,
  '75F6': 13,
  '75F7': 13,
  '75F8': 13,
  '75F9': 13,
  '75FA': 13,
  '75FB': 13,
  '75FC': 13,
  '75FD': 13,
  '75FE': 12,
  '75FF': 13,
  '7600': 13,
  '7601': 13,
  '7602': 13,
  '7603': 13,
  '7604': 13,
  '7605': 13,
  '7606': 13,
  '7607': 14,
  '7608': 14,
  '7609': 14,
  '760A': 14,
  '760B': 14,
  '760C': 14,
  '760D': 14,
  '760E': 14,
  '760F': 13,
  '7610': 13,
  '7611': 13,
  '7612': 14,
  '7613': 14,
  '7614': 13,
  '7615': 14,
  '7616': 14,
  '7617': 14,
  '7618': 14,
  '7619': 14,
  '761A': 15,
  '761B': 15,
  '761C': 15,
  '761D': 15,
  '761E': 15,
  '761F': 14,
  '7620': 15,
  '7621': 15,
  '7622': 15,
  '7623': 14,
  '7624': 15,
  '7625': 14,
  '7626': 14,
  '7627': 14,
  '7628': 15,
  '7629': 14,
  '762A': 15,
  '762B': 15,
  '762C': 16,
  '762D': 16,
  '762E': 16,
  '762F': 16,
  '7630': 16,
  '7631': 16,
  '7632': 16,
  '7633': 16,
  '7634': 16,
  '7635': 16,
  '7636': 16,
  '7637': 16,
  '7638': 16,
  '7639': 16,
  '763A': 16,
  '763B': 16,
  '763C': 15,
  '763D': 16,
  '763E': 16,
  '763F': 16,
  '7640': 16,
  '7641': 17,
  '7642': 17,
  '7643': 16,
  '7644': 17,
  '7645': 17,
  '7646': 17,
  '7647': 17,
  '7648': 17,
  '7649': 17,
  '764A': 15,
  '764B': 17,
  '764C': 17,
  '764D': 17,
  '764E': 17,
  '764F': 18,
  '7650': 18,
  '7651': 18,
  '7652': 18,
  '7653': 18,
  '7654': 18,
  '7655': 18,
  '7656': 18,
  '7657': 18,
  '7658': 17,
  '7659': 18,
  '765A': 18,
  '765B': 18,
  '765C': 18,
  '765D': 18,
  '765E': 18,
  '765F': 19,
  '7660': 19,
  '7661': 19,
  '7662': 19,
  '7663': 19,
  '7664': 18,
  '7665': 20,
  '7666': 20,
  '7667': 21,
  '7668': 21,
  '7669': 21,
  '766A': 21,
  '766B': 21,
  '766C': 22,
  '766D': 22,
  '766E': 21,
  '766F': 23,
  '7670': 23,
  '7671': 24,
  '7672': 24,
  '7673': 26,
  '7674': 28,
  '7675': 30,
  '7676': 5,
  '7677': 8,
  '7678': 9,
  '7679': 9,
  '767A': 9,
  '767B': 12,
  '767C': 12,
  '767D': 5,
  '767E': 6,
  '767F': 6,
  '7680': 7,
  '7681': 7,
  '7682': 7,
  '7683': 7,
  '7684': 8,
  '7685': 9,
  '7686': 9,
  '7687': 9,
  '7688': 9,
  '7689': 11,
  '768A': 10,
  '768B': 10,
  '768C': 10,
  '768D': 9,
  '768E': 11,
  '768F': 11,
  '7690': 11,
  '7691': 11,
  '7692': 12,
  '7693': 12,
  '7694': 12,
  '7695': 12,
  '7696': 12,
  '7697': 13,
  '7698': 13,
  '7699': 13,
  '769A': 15,
  '769B': 15,
  '769C': 15,
  '769D': 15,
  '769E': 15,
  '769F': 16,
  '76A0': 16,
  '76A1': 16,
  '76A2': 17,
  '76A3': 15,
  '76A4': 17,
  '76A5': 17,
  '76A6': 18,
  '76A7': 18,
  '76A8': 18,
  '76A9': 19,
  '76AA': 20,
  '76AB': 20,
  '76AC': 21,
  '76AD': 22,
  '76AE': 5,
  '76AF': 8,
  '76B0': 10,
  '76B1': 10,
  '76B2': 11,
  '76B3': 12,
  '76B4': 12,
  '76B5': 13,
  '76B6': 14,
  '76B7': 14,
  '76B8': 14,
  '76B9': 14,
  '76BA': 15,
  '76BB': 16,
  '76BC': 17,
  '76BD': 18,
  '76BE': 20,
  '76BF': 5,
  '76C0': 7,
  '76C1': 7,
  '76C2': 8,
  '76C3': 9,
  '76C4': 9,
  '76C5': 9,
  '76C6': 9,
  '76C7': 9,
  '76C8': 9,
  '76C9': 10,
  '76CA': 10,
  '76CB': 10,
  '76CC': 10,
  '76CD': 10,
  '76CE': 10,
  '76CF': 10,
  '76D0': 10,
  '76D1': 10,
  '76D2': 11,
  '76D3': 11,
  '76D4': 11,
  '76D5': 11,
  '76D6': 11,
  '76D7': 11,
  '76D8': 11,
  '76D9': 12,
  '76DA': 12,
  '76DB': 11,
  '76DC': 12,
  '76DD': 13,
  '76DE': 13,
  '76DF': 13,
  '76E0': 14,
  '76E1': 14,
  '76E2': 14,
  '76E3': 14,
  '76E4': 15,
  '76E5': 16,
  '76E6': 16,
  '76E7': 16,
  '76E8': 17,
  '76E9': 17,
  '76EA': 17,
  '76EB': 18,
  '76EC': 18,
  '76ED': 20,
  '76EE': 5,
  '76EF': 7,
  '76F0': 8,
  '76F1': 8,
  '76F2': 8,
  '76F3': 8,
  '76F4': 8,
  '76F5': 8,
  '76F6': 9,
  '76F7': 9,
  '76F8': 9,
  '76F9': 9,
  '76FA': 9,
  '76FB': 9,
  '76FC': 9,
  '76FD': 9,
  '76FE': 9,
  '76FF': 9,
  '7700': 9,
  '7701': 9,
  '7702': 9,
  '7703': 9,
  '7704': 9,
  '7705': 9,
  '7706': 9,
  '7707': 9,
  '7708': 9,
  '7709': 9,
  '770A': 9,
  '770B': 9,
  '770C': 9,
  '770D': 9,
  '770E': 10,
  '770F': 10,
  '7710': 10,
  '7711': 10,
  '7712': 10,
  '7713': 10,
  '7714': 10,
  '7715': 10,
  '7716': 10,
  '7717': 10,
  '7718': 10,
  '7719': 10,
  '771A': 10,
  '771B': 10,
  '771C': 10,
  '771D': 10,
  '771E': 10,
  '771F': 10,
  '7720': 10,
  '7721': 10,
  '7722': 10,
  '7723': 10,
  '7724': 10,
  '7725': 11,
  '7726': 11,
  '7727': 10,
  '7728': 9,
  '7729': 10,
  '772A': 10,
  '772B': 10,
  '772C': 10,
  '772D': 11,
  '772E': 11,
  '772F': 11,
  '7730': 11,
  '7731': 11,
  '7732': 11,
  '7733': 11,
  '7734': 11,
  '7735': 11,
  '7736': 11,
  '7737': 11,
  '7738': 11,
  '7739': 11,
  '773A': 11,
  '773B': 11,
  '773C': 11,
  '773D': 11,
  '773E': 11,
  '773F': 10,
  '7740': 11,
  '7741': 11,
  '7742': 12,
  '7743': 12,
  '7744': 12,
  '7745': 12,
  '7746': 12,
  '7747': 12,
  '7748': 12,
  '7749': 12,
  '774A': 12,
  '774B': 12,
  '774C': 12,
  '774D': 12,
  '774E': 12,
  '774F': 12,
  '7750': 12,
  '7751': 12,
  '7752': 13,
  '7753': 13,
  '7754': 13,
  '7755': 13,
  '7756': 13,
  '7757': 13,
  '7758': 13,
  '7759': 13,
  '775A': 13,
  '775B': 13,
  '775C': 13,
  '775D': 13,
  '775E': 13,
  '775F': 13,
  '7760': 13,
  '7761': 13,
  '7762': 13,
  '7763': 13,
  '7764': 13,
  '7765': 13,
  '7766': 13,
  '7767': 13,
  '7768': 13,
  '7769': 13,
  '776A': 13,
  '776B': 13,
  '776C': 13,
  '776D': 13,
  '776E': 14,
  '776F': 14,
  '7770': 13,
  '7771': 14,
  '7772': 14,
  '7773': 14,
  '7774': 14,
  '7775': 14,
  '7776': 14,
  '7777': 13,
  '7778': 14,
  '7779': 13,
  '777A': 14,
  '777B': 14,
  '777C': 14,
  '777D': 14,
  '777E': 14,
  '777F': 14,
  '7780': 14,
  '7781': 14,
  '7782': 14,
  '7783': 14,
  '7784': 13,
  '7785': 14,
  '7786': 14,
  '7787': 14,
  '7788': 15,
  '7789': 15,
  '778A': 15,
  '778B': 15,
  '778C': 15,
  '778D': 14,
  '778E': 15,
  '778F': 15,
  '7790': 15,
  '7791': 15,
  '7792': 15,
  '7793': 15,
  '7794': 16,
  '7795': 16,
  '7796': 16,
  '7797': 16,
  '7798': 16,
  '7799': 15,
  '779A': 16,
  '779B': 16,
  '779C': 16,
  '779D': 15,
  '779E': 16,
  '779F': 16,
  '77A0': 16,
  '77A1': 16,
  '77A2': 15,
  '77A3': 16,
  '77A4': 17,
  '77A5': 16,
  '77A6': 17,
  '77A7': 17,
  '77A8': 17,
  '77A9': 17,
  '77AA': 17,
  '77AB': 17,
  '77AC': 17,
  '77AD': 17,
  '77AE': 17,
  '77AF': 17,
  '77B0': 16,
  '77B1': 15,
  '77B2': 17,
  '77B3': 17,
  '77B4': 17,
  '77B5': 17,
  '77B6': 17,
  '77B7': 17,
  '77B8': 17,
  '77B9': 18,
  '77BA': 18,
  '77BB': 18,
  '77BC': 18,
  '77BD': 18,
  '77BE': 18,
  '77BF': 18,
  '77C0': 18,
  '77C1': 18,
  '77C2': 18,
  '77C3': 19,
  '77C4': 19,
  '77C5': 19,
  '77C6': 18,
  '77C7': 18,
  '77C8': 19,
  '77C9': 19,
  '77CA': 19,
  '77CB': 19,
  '77CC': 19,
  '77CD': 20,
  '77CE': 19,
  '77CF': 20,
  '77D0': 21,
  '77D1': 21,
  '77D2': 20,
  '77D3': 21,
  '77D4': 22,
  '77D5': 24,
  '77D6': 24,
  '77D7': 24,
  '77D8': 25,
  '77D9': 24,
  '77DA': 26,
  '77DB': 5,
  '77DC': 9,
  '77DD': 10,
  '77DE': 12,
  '77DF': 12,
  '77E0': 13,
  '77E1': 25,
  '77E2': 5,
  '77E3': 7,
  '77E4': 8,
  '77E5': 8,
  '77E6': 9,
  '77E7': 9,
  '77E8': 9,
  '77E9': 9,
  '77EA': 11,
  '77EB': 11,
  '77EC': 12,
  '77ED': 12,
  '77EE': 13,
  '77EF': 17,
  '77F0': 17,
  '77F1': 18,
  '77F2': 20,
  '77F3': 5,
  '77F4': 7,
  '77F5': 7,
  '77F6': 7,
  '77F7': 8,
  '77F8': 8,
  '77F9': 8,
  '77FA': 8,
  '77FB': 8,
  '77FC': 8,
  '77FD': 8,
  '77FE': 8,
  '77FF': 8,
  '7800': 8,
  '7801': 8,
  '7802': 9,
  '7803': 9,
  '7804': 9,
  '7805': 9,
  '7806': 9,
  '7807': 9,
  '7808': 9,
  '7809': 9,
  '780A': 9,
  '780B': 9,
  '780C': 9,
  '780D': 9,
  '780E': 9,
  '780F': 9,
  '7810': 8,
  '7811': 9,
  '7812': 9,
  '7813': 9,
  '7814': 9,
  '7815': 9,
  '7816': 9,
  '7817': 9,
  '7818': 9,
  '7819': 9,
  '781A': 9,
  '781B': 9,
  '781C': 9,
  '781D': 10,
  '781E': 10,
  '781F': 10,
  '7820': 10,
  '7821': 10,
  '7822': 10,
  '7823': 10,
  '7824': 10,
  '7825': 10,
  '7826': 11,
  '7827': 10,
  '7828': 10,
  '7829': 10,
  '782A': 10,
  '782B': 10,
  '782C': 10,
  '782D': 9,
  '782E': 10,
  '782F': 10,
  '7830': 10,
  '7831': 10,
  '7832': 10,
  '7833': 10,
  '7834': 10,
  '7835': 10,
  '7836': 10,
  '7837': 10,
  '7838': 10,
  '7839': 10,
  '783A': 10,
  '783B': 10,
  '783C': 10,
  '783D': 10,
  '783E': 10,
  '783F': 10,
  '7840': 10,
  '7841': 10,
  '7842': 11,
  '7843': 11,
  '7844': 11,
  '7845': 11,
  '7846': 11,
  '7847': 11,
  '7848': 11,
  '7849': 11,
  '784A': 11,
  '784B': 11,
  '784C': 11,
  '784D': 11,
  '784E': 11,
  '784F': 11,
  '7850': 11,
  '7851': 11,
  '7852': 11,
  '7853': 11,
  '7854': 11,
  '7855': 11,
  '7856': 11,
  '7857': 11,
  '7858': 11,
  '7859': 11,
  '785A': 11,
  '785B': 11,
  '785C': 12,
  '785D': 12,
  '785E': 12,
  '785F': 11,
  '7860': 12,
  '7861': 12,
  '7862': 12,
  '7863': 12,
  '7864': 12,
  '7865': 12,
  '7866': 12,
  '7867': 12,
  '7868': 12,
  '7869': 12,
  '786A': 12,
  '786B': 12,
  '786C': 12,
  '786D': 11,
  '786E': 12,
  '786F': 12,
  '7870': 12,
  '7871': 12,
  '7872': 12,
  '7873': 12,
  '7874': 12,
  '7875': 12,
  '7876': 12,
  '7877': 12,
  '7878': 13,
  '7879': 13,
  '787A': 13,
  '787B': 13,
  '787C': 13,
  '787D': 13,
  '787E': 13,
  '787F': 13,
  '7880': 11,
  '7881': 13,
  '7882': 13,
  '7883': 13,
  '7884': 13,
  '7885': 13,
  '7886': 13,
  '7887': 13,
  '7888': 13,
  '7889': 13,
  '788A': 13,
  '788B': 13,
  '788C': 13,
  '788D': 13,
  '788E': 13,
  '788F': 13,
  '7890': 13,
  '7891': 13,
  '7892': 13,
  '7893': 13,
  '7894': 13,
  '7895': 13,
  '7896': 13,
  '7897': 13,
  '7898': 13,
  '7899': 13,
  '789A': 13,
  '789B': 13,
  '789C': 13,
  '789D': 14,
  '789E': 14,
  '789F': 14,
  '78A0': 14,
  '78A1': 14,
  '78A2': 13,
  '78A3': 14,
  '78A4': 13,
  '78A5': 14,
  '78A6': 14,
  '78A7': 14,
  '78A8': 14,
  '78A9': 14,
  '78AA': 14,
  '78AB': 14,
  '78AC': 14,
  '78AD': 14,
  '78AE': 14,
  '78AF': 14,
  '78B0': 13,
  '78B1': 14,
  '78B2': 14,
  '78B3': 14,
  '78B4': 14,
  '78B5': 14,
  '78B6': 14,
  '78B7': 14,
  '78B8': 14,
  '78B9': 14,
  '78BA': 15,
  '78BB': 15,
  '78BC': 15,
  '78BD': 15,
  '78BE': 15,
  '78BF': 15,
  '78C0': 14,
  '78C1': 14,
  '78C2': 15,
  '78C3': 15,
  '78C4': 15,
  '78C5': 15,
  '78C6': 14,
  '78C7': 15,
  '78C8': 14,
  '78C9': 15,
  '78CA': 15,
  '78CB': 14,
  '78CC': 15,
  '78CD': 15,
  '78CE': 15,
  '78CF': 15,
  '78D0': 15,
  '78D1': 15,
  '78D2': 15,
  '78D3': 14,
  '78D4': 15,
  '78D5': 15,
  '78D6': 16,
  '78D7': 15,
  '78D8': 15,
  '78D9': 15,
  '78DA': 16,
  '78DB': 16,
  '78DC': 16,
  '78DD': 15,
  '78DE': 16,
  '78DF': 16,
  '78E0': 16,
  '78E1': 16,
  '78E2': 16,
  '78E3': 16,
  '78E4': 15,
  '78E5': 16,
  '78E6': 16,
  '78E7': 16,
  '78E8': 16,
  '78E9': 16,
  '78EA': 16,
  '78EB': 16,
  '78EC': 16,
  '78ED': 16,
  '78EE': 16,
  '78EF': 17,
  '78F0': 17,
  '78F1': 17,
  '78F2': 16,
  '78F3': 17,
  '78F4': 17,
  '78F5': 17,
  '78F6': 17,
  '78F7': 17,
  '78F8': 17,
  '78F9': 17,
  '78FA': 16,
  '78FB': 17,
  '78FC': 17,
  '78FD': 17,
  '78FE': 17,
  '78FF': 17,
  '7900': 17,
  '7901': 17,
  '7902': 17,
  '7903': 17,
  '7904': 17,
  '7905': 17,
  '7906': 18,
  '7907': 17,
  '7908': 17,
  '7909': 18,
  '790A': 18,
  '790B': 18,
  '790C': 18,
  '790D': 17,
  '790E': 18,
  '790F': 18,
  '7910': 18,
  '7911': 18,
  '7912': 18,
  '7913': 18,
  '7914': 18,
  '7915': 18,
  '7916': 18,
  '7917': 19,
  '7918': 19,
  '7919': 19,
  '791A': 18,
  '791B': 19,
  '791C': 18,
  '791D': 19,
  '791E': 18,
  '791F': 19,
  '7920': 18,
  '7921': 18,
  '7922': 19,
  '7923': 19,
  '7924': 19,
  '7925': 20,
  '7926': 19,
  '7927': 20,
  '7928': 20,
  '7929': 20,
  '792A': 19,
  '792B': 20,
  '792C': 20,
  '792D': 21,
  '792E': 21,
  '792F': 21,
  '7930': 21,
  '7931': 21,
  '7932': 21,
  '7933': 21,
  '7934': 21,
  '7935': 22,
  '7936': 22,
  '7937': 22,
  '7938': 24,
  '7939': 24,
  '793A': 5,
  '793B': 4,
  '793C': 5,
  '793D': 6,
  '793E': 7,
  '793F': 7,
  '7940': 7,
  '7941': 6,
  '7942': 7,
  '7943': 7,
  '7944': 8,
  '7945': 8,
  '7946': 8,
  '7947': 8,
  '7948': 8,
  '7949': 8,
  '794A': 8,
  '794B': 8,
  '794C': 8,
  '794D': 9,
  '794E': 8,
  '794F': 9,
  '7950': 9,
  '7951': 9,
  '7952': 9,
  '7953': 9,
  '7954': 9,
  '7955': 9,
  '7956': 9,
  '7957': 9,
  '7958': 10,
  '7959': 9,
  '795A': 9,
  '795B': 9,
  '795C': 9,
  '795D': 9,
  '795E': 9,
  '795F': 10,
  '7960': 9,
  '7961': 11,
  '7962': 9,
  '7963': 10,
  '7964': 10,
  '7965': 10,
  '7966': 11,
  '7967': 10,
  '7968': 11,
  '7969': 10,
  '796A': 10,
  '796B': 10,
  '796C': 10,
  '796D': 11,
  '796E': 10,
  '796F': 10,
  '7970': 11,
  '7971': 11,
  '7972': 11,
  '7973': 11,
  '7974': 11,
  '7975': 11,
  '7976': 11,
  '7977': 11,
  '7978': 11,
  '7979': 12,
  '797A': 12,
  '797B': 12,
  '797C': 12,
  '797D': 12,
  '797E': 12,
  '797F': 12,
  '7980': 13,
  '7981': 13,
  '7982': 12,
  '7983': 12,
  '7984': 12,
  '7985': 12,
  '7986': 12,
  '7987': 12,
  '7988': 13,
  '7989': 13,
  '798A': 13,
  '798B': 13,
  '798C': 13,
  '798D': 12,
  '798E': 13,
  '798F': 13,
  '7990': 13,
  '7991': 13,
  '7992': 13,
  '7993': 13,
  '7994': 13,
  '7995': 13,
  '7996': 13,
  '7997': 13,
  '7998': 13,
  '7999': 13,
  '799A': 14,
  '799B': 14,
  '799C': 15,
  '799D': 14,
  '799E': 14,
  '799F': 14,
  '79A0': 14,
  '79A1': 14,
  '79A2': 14,
  '79A3': 14,
  '79A4': 15,
  '79A5': 15,
  '79A6': 17,
  '79A7': 16,
  '79A8': 16,
  '79A9': 15,
  '79AA': 16,
  '79AB': 16,
  '79AC': 17,
  '79AD': 16,
  '79AE': 17,
  '79AF': 17,
  '79B0': 18,
  '79B1': 18,
  '79B2': 18,
  '79B3': 21,
  '79B4': 21,
  '79B5': 22,
  '79B6': 23,
  '79B7': 23,
  '79B8': 4,
  '79B9': 9,
  '79BA': 9,
  '79BB': 10,
  '79BC': 11,
  '79BD': 12,
  '79BE': 5,
  '79BF': 7,
  '79C0': 7,
  '79C1': 7,
  '79C2': 7,
  '79C3': 7,
  '79C4': 8,
  '79C5': 8,
  '79C6': 8,
  '79C7': 8,
  '79C8': 8,
  '79C9': 8,
  '79CA': 8,
  '79CB': 9,
  '79CC': 9,
  '79CD': 9,
  '79CE': 9,
  '79CF': 9,
  '79D0': 9,
  '79D1': 9,
  '79D2': 9,
  '79D3': 9,
  '79D4': 9,
  '79D5': 9,
  '79D6': 9,
  '79D7': 9,
  '79D8': 10,
  '79D9': 10,
  '79DA': 10,
  '79DB': 10,
  '79DC': 10,
  '79DD': 10,
  '79DE': 10,
  '79DF': 10,
  '79E0': 10,
  '79E1': 10,
  '79E2': 10,
  '79E3': 10,
  '79E4': 10,
  '79E5': 10,
  '79E6': 10,
  '79E7': 10,
  '79E8': 10,
  '79E9': 10,
  '79EA': 10,
  '79EB': 10,
  '79EC': 9,
  '79ED': 9,
  '79EE': 10,
  '79EF': 10,
  '79F0': 10,
  '79F1': 11,
  '79F2': 11,
  '79F3': 11,
  '79F4': 11,
  '79F5': 11,
  '79F6': 11,
  '79F7': 11,
  '79F8': 11,
  '79F9': 11,
  '79FA': 11,
  '79FB': 11,
  '79FC': 11,
  '79FD': 11,
  '79FE': 11,
  '79FF': 12,
  '7A00': 12,
  '7A01': 12,
  '7A02': 12,
  '7A03': 12,
  '7A04': 12,
  '7A05': 12,
  '7A06': 11,
  '7A07': 12,
  '7A08': 12,
  '7A09': 12,
  '7A0A': 12,
  '7A0B': 12,
  '7A0C': 12,
  '7A0D': 12,
  '7A0E': 12,
  '7A0F': 13,
  '7A10': 13,
  '7A11': 13,
  '7A12': 13,
  '7A13': 13,
  '7A14': 13,
  '7A15': 13,
  '7A16': 13,
  '7A17': 13,
  '7A18': 13,
  '7A19': 13,
  '7A1A': 13,
  '7A1B': 13,
  '7A1C': 13,
  '7A1D': 13,
  '7A1E': 13,
  '7A1F': 13,
  '7A20': 13,
  '7A21': 13,
  '7A22': 13,
  '7A23': 13,
  '7A24': 13,
  '7A25': 13,
  '7A26': 14,
  '7A27': 14,
  '7A28': 14,
  '7A29': 14,
  '7A2A': 14,
  '7A2B': 14,
  '7A2C': 14,
  '7A2D': 14,
  '7A2E': 14,
  '7A2F': 14,
  '7A30': 14,
  '7A31': 14,
  '7A32': 14,
  '7A33': 14,
  '7A34': 15,
  '7A35': 14,
  '7A36': 15,
  '7A37': 15,
  '7A38': 15,
  '7A39': 15,
  '7A3A': 15,
  '7A3B': 15,
  '7A3C': 15,
  '7A3D': 15,
  '7A3E': 15,
  '7A3F': 15,
  '7A40': 15,
  '7A41': 14,
  '7A42': 15,
  '7A43': 15,
  '7A44': 16,
  '7A45': 16,
  '7A46': 16,
  '7A47': 16,
  '7A48': 16,
  '7A49': 17,
  '7A4A': 14,
  '7A4B': 16,
  '7A4C': 16,
  '7A4D': 16,
  '7A4E': 16,
  '7A4F': 16,
  '7A50': 16,
  '7A51': 16,
  '7A52': 16,
  '7A53': 16,
  '7A54': 16,
  '7A55': 17,
  '7A56': 17,
  '7A57': 17,
  '7A58': 17,
  '7A59': 17,
  '7A5A': 17,
  '7A5B': 17,
  '7A5C': 17,
  '7A5D': 17,
  '7A5E': 17,
  '7A5F': 17,
  '7A60': 18,
  '7A61': 18,
  '7A62': 18,
  '7A63': 18,
  '7A64': 19,
  '7A65': 18,
  '7A66': 19,
  '7A67': 19,
  '7A68': 19,
  '7A69': 19,
  '7A6A': 19,
  '7A6B': 18,
  '7A6C': 19,
  '7A6D': 20,
  '7A6E': 20,
  '7A6F': 20,
  '7A70': 22,
  '7A71': 22,
  '7A72': 24,
  '7A73': 24,
  '7A74': 5,
  '7A75': 6,
  '7A76': 7,
  '7A77': 7,
  '7A78': 8,
  '7A79': 8,
  '7A7A': 8,
  '7A7B': 8,
  '7A7C': 9,
  '7A7D': 9,
  '7A7E': 9,
  '7A7F': 9,
  '7A80': 9,
  '7A81': 9,
  '7A82': 9,
  '7A83': 9,
  '7A84': 10,
  '7A85': 10,
  '7A86': 9,
  '7A87': 10,
  '7A88': 10,
  '7A89': 10,
  '7A8A': 10,
  '7A8B': 10,
  '7A8C': 10,
  '7A8D': 10,
  '7A8E': 10,
  '7A8F': 11,
  '7A90': 11,
  '7A91': 11,
  '7A92': 11,
  '7A93': 11,
  '7A94': 11,
  '7A95': 11,
  '7A96': 12,
  '7A97': 12,
  '7A98': 12,
  '7A99': 12,
  '7A9A': 11,
  '7A9B': 12,
  '7A9C': 12,
  '7A9D': 12,
  '7A9E': 13,
  '7A9F': 13,
  '7AA0': 13,
  '7AA1': 13,
  '7AA2': 13,
  '7AA3': 13,
  '7AA4': 13,
  '7AA5': 13,
  '7AA6': 13,
  '7AA7': 13,
  '7AA8': 14,
  '7AA9': 13,
  '7AAA': 14,
  '7AAB': 14,
  '7AAC': 14,
  '7AAD': 14,
  '7AAE': 15,
  '7AAF': 15,
  '7AB0': 15,
  '7AB1': 15,
  '7AB2': 15,
  '7AB3': 15,
  '7AB4': 15,
  '7AB5': 16,
  '7AB6': 16,
  '7AB7': 16,
  '7AB8': 16,
  '7AB9': 16,
  '7ABA': 16,
  '7ABB': 16,
  '7ABC': 16,
  '7ABD': 16,
  '7ABE': 17,
  '7ABF': 16,
  '7AC0': 17,
  '7AC1': 17,
  '7AC2': 17,
  '7AC3': 17,
  '7AC4': 18,
  '7AC5': 18,
  '7AC6': 18,
  '7AC7': 20,
  '7AC8': 21,
  '7AC9': 21,
  '7ACA': 22,
  '7ACB': 5,
  '7ACC': 7,
  '7ACD': 7,
  '7ACE': 8,
  '7ACF': 8,
  '7AD0': 9,
  '7AD1': 9,
  '7AD2': 9,
  '7AD3': 9,
  '7AD4': 9,
  '7AD5': 9,
  '7AD6': 9,
  '7AD7': 9,
  '7AD8': 10,
  '7AD9': 10,
  '7ADA': 10,
  '7ADB': 10,
  '7ADC': 10,
  '7ADD': 10,
  '7ADE': 10,
  '7ADF': 11,
  '7AE0': 11,
  '7AE1': 11,
  '7AE2': 12,
  '7AE3': 12,
  '7AE4': 12,
  '7AE5': 12,
  '7AE6': 12,
  '7AE7': 12,
  '7AE8': 13,
  '7AE9': 13,
  '7AEA': 13,
  '7AEB': 11,
  '7AEC': 14,
  '7AED': 14,
  '7AEE': 14,
  '7AEF': 14,
  '7AF0': 14,
  '7AF1': 16,
  '7AF2': 17,
  '7AF3': 17,
  '7AF4': 17,
  '7AF5': 18,
  '7AF6': 20,
  '7AF7': 20,
  '7AF8': 22,
  '7AF9': 6,
  '7AFA': 8,
  '7AFB': 8,
  '7AFC': 9,
  '7AFD': 9,
  '7AFE': 9,
  '7AFF': 9,
  '7B00': 9,
  '7B01': 9,
  '7B02': 9,
  '7B03': 9,
  '7B04': 10,
  '7B05': 10,
  '7B06': 10,
  '7B07': 10,
  '7B08': 9,
  '7B09': 10,
  '7B0A': 10,
  '7B0B': 10,
  '7B0C': 10,
  '7B0D': 10,
  '7B0E': 10,
  '7B0F': 10,
  '7B10': 10,
  '7B11': 10,
  '7B12': 10,
  '7B13': 10,
  '7B14': 10,
  '7B15': 10,
  '7B16': 10,
  '7B17': 11,
  '7B18': 11,
  '7B19': 11,
  '7B1A': 11,
  '7B1B': 11,
  '7B1C': 11,
  '7B1D': 11,
  '7B1E': 11,
  '7B1F': 11,
  '7B20': 11,
  '7B21': 11,
  '7B22': 11,
  '7B23': 11,
  '7B24': 11,
  '7B25': 11,
  '7B26': 11,
  '7B27': 11,
  '7B28': 11,
  '7B29': 11,
  '7B2A': 11,
  '7B2B': 10,
  '7B2C': 11,
  '7B2D': 11,
  '7B2E': 11,
  '7B2F': 11,
  '7B30': 11,
  '7B31': 11,
  '7B32': 11,
  '7B33': 11,
  '7B34': 11,
  '7B35': 11,
  '7B36': 11,
  '7B37': 11,
  '7B38': 11,
  '7B39': 11,
  '7B3A': 11,
  '7B3B': 11,
  '7B3C': 11,
  '7B3D': 11,
  '7B3E': 11,
  '7B3F': 12,
  '7B40': 12,
  '7B41': 12,
  '7B42': 12,
  '7B43': 12,
  '7B44': 12,
  '7B45': 12,
  '7B46': 12,
  '7B47': 11,
  '7B48': 12,
  '7B49': 12,
  '7B4A': 12,
  '7B4B': 12,
  '7B4C': 12,
  '7B4D': 12,
  '7B4E': 12,
  '7B4F': 12,
  '7B50': 12,
  '7B51': 12,
  '7B52': 12,
  '7B53': 12,
  '7B54': 12,
  '7B55': 12,
  '7B56': 12,
  '7B57': 12,
  '7B58': 12,
  '7B59': 12,
  '7B5A': 12,
  '7B5B': 12,
  '7B5C': 12,
  '7B5D': 12,
  '7B5E': 13,
  '7B5F': 13,
  '7B60': 13,
  '7B61': 13,
  '7B62': 13,
  '7B63': 13,
  '7B64': 13,
  '7B65': 12,
  '7B66': 13,
  '7B67': 13,
  '7B68': 13,
  '7B69': 13,
  '7B6A': 13,
  '7B6B': 13,
  '7B6C': 12,
  '7B6D': 13,
  '7B6E': 13,
  '7B6F': 13,
  '7B70': 13,
  '7B71': 13,
  '7B72': 13,
  '7B73': 12,
  '7B74': 13,
  '7B75': 12,
  '7B76': 13,
  '7B77': 13,
  '7B78': 13,
  '7B79': 13,
  '7B7A': 13,
  '7B7B': 13,
  '7B7C': 13,
  '7B7D': 13,
  '7B7E': 13,
  '7B7F': 13,
  '7B80': 13,
  '7B81': 14,
  '7B82': 14,
  '7B83': 14,
  '7B84': 14,
  '7B85': 14,
  '7B86': 14,
  '7B87': 14,
  '7B88': 14,
  '7B89': 14,
  '7B8A': 14,
  '7B8B': 14,
  '7B8C': 14,
  '7B8D': 14,
  '7B8E': 14,
  '7B8F': 14,
  '7B90': 14,
  '7B91': 14,
  '7B92': 14,
  '7B93': 14,
  '7B94': 14,
  '7B95': 14,
  '7B96': 14,
  '7B97': 14,
  '7B98': 14,
  '7B99': 14,
  '7B9A': 14,
  '7B9B': 14,
  '7B9C': 14,
  '7B9D': 14,
  '7B9E': 14,
  '7B9F': 14,
  '7BA0': 14,
  '7BA1': 14,
  '7BA2': 14,
  '7BA3': 14,
  '7BA4': 14,
  '7BA5': 14,
  '7BA6': 14,
  '7BA7': 14,
  '7BA8': 14,
  '7BA9': 14,
  '7BAA': 14,
  '7BAB': 14,
  '7BAC': 14,
  '7BAD': 15,
  '7BAE': 15,
  '7BAF': 15,
  '7BB0': 15,
  '7BB1': 15,
  '7BB2': 15,
  '7BB3': 15,
  '7BB4': 15,
  '7BB5': 15,
  '7BB6': 15,
  '7BB7': 15,
  '7BB8': 14,
  '7BB9': 15,
  '7BBA': 15,
  '7BBB': 15,
  '7BBC': 15,
  '7BBD': 15,
  '7BBE': 15,
  '7BBF': 15,
  '7BC0': 13,
  '7BC1': 15,
  '7BC2': 15,
  '7BC3': 15,
  '7BC4': 15,
  '7BC5': 15,
  '7BC6': 15,
  '7BC7': 15,
  '7BC8': 15,
  '7BC9': 16,
  '7BCA': 15,
  '7BCB': 15,
  '7BCC': 15,
  '7BCD': 15,
  '7BCE': 15,
  '7BCF': 15,
  '7BD0': 15,
  '7BD1': 15,
  '7BD2': 15,
  '7BD3': 15,
  '7BD4': 16,
  '7BD5': 16,
  '7BD6': 16,
  '7BD7': 16,
  '7BD8': 16,
  '7BD9': 16,
  '7BDA': 16,
  '7BDB': 16,
  '7BDC': 16,
  '7BDD': 16,
  '7BDE': 16,
  '7BDF': 16,
  '7BE0': 16,
  '7BE1': 16,
  '7BE2': 16,
  '7BE3': 16,
  '7BE4': 16,
  '7BE5': 16,
  '7BE6': 16,
  '7BE7': 16,
  '7BE8': 15,
  '7BE9': 16,
  '7BEA': 16,
  '7BEB': 16,
  '7BEC': 16,
  '7BED': 16,
  '7BEE': 16,
  '7BEF': 16,
  '7BF0': 16,
  '7BF1': 16,
  '7BF2': 17,
  '7BF3': 16,
  '7BF4': 16,
  '7BF5': 17,
  '7BF6': 17,
  '7BF7': 16,
  '7BF8': 17,
  '7BF9': 16,
  '7BFA': 17,
  '7BFB': 17,
  '7BFC': 17,
  '7BFD': 18,
  '7BFE': 17,
  '7BFF': 17,
  '7C00': 17,
  '7C01': 17,
  '7C02': 17,
  '7C03': 17,
  '7C04': 17,
  '7C05': 17,
  '7C06': 17,
  '7C07': 17,
  '7C08': 17,
  '7C09': 16,
  '7C0A': 17,
  '7C0B': 17,
  '7C0C': 17,
  '7C0D': 17,
  '7C0E': 17,
  '7C0F': 17,
  '7C10': 17,
  '7C11': 16,
  '7C12': 17,
  '7C13': 17,
  '7C14': 17,
  '7C15': 17,
  '7C16': 17,
  '7C17': 17,
  '7C18': 17,
  '7C19': 18,
  '7C1A': 18,
  '7C1B': 18,
  '7C1C': 18,
  '7C1D': 18,
  '7C1E': 18,
  '7C1F': 18,
  '7C20': 18,
  '7C21': 18,
  '7C22': 18,
  '7C23': 18,
  '7C24': 18,
  '7C25': 18,
  '7C26': 18,
  '7C27': 17,
  '7C28': 18,
  '7C29': 18,
  '7C2A': 18,
  '7C2B': 19,
  '7C2C': 19,
  '7C2D': 18,
  '7C2E': 18,
  '7C2F': 18,
  '7C30': 18,
  '7C31': 18,
  '7C32': 18,
  '7C33': 19,
  '7C34': 19,
  '7C35': 19,
  '7C36': 18,
  '7C37': 19,
  '7C38': 19,
  '7C39': 19,
  '7C3A': 19,
  '7C3B': 17,
  '7C3C': 19,
  '7C3D': 19,
  '7C3E': 19,
  '7C3F': 19,
  '7C40': 19,
  '7C41': 19,
  '7C42': 19,
  '7C43': 20,
  '7C44': 20,
  '7C45': 19,
  '7C46': 19,
  '7C47': 20,
  '7C48': 19,
  '7C49': 20,
  '7C4A': 20,
  '7C4B': 20,
  '7C4C': 20,
  '7C4D': 20,
  '7C4E': 20,
  '7C4F': 20,
  '7C50': 21,
  '7C51': 21,
  '7C52': 21,
  '7C53': 21,
  '7C54': 21,
  '7C55': 20,
  '7C56': 21,
  '7C57': 22,
  '7C58': 22,
  '7C59': 22,
  '7C5A': 22,
  '7C5B': 22,
  '7C5C': 22,
  '7C5D': 22,
  '7C5E': 23,
  '7C5F': 22,
  '7C60': 22,
  '7C61': 22,
  '7C62': 23,
  '7C63': 23,
  '7C64': 23,
  '7C65': 23,
  '7C66': 23,
  '7C67': 22,
  '7C68': 23,
  '7C69': 24,
  '7C6A': 24,
  '7C6B': 25,
  '7C6C': 24,
  '7C6D': 25,
  '7C6E': 25,
  '7C6F': 26,
  '7C70': 26,
  '7C71': 30,
  '7C72': 32,
  '7C73': 6,
  '7C74': 8,
  '7C75': 8,
  '7C76': 8,
  '7C77': 9,
  '7C78': 9,
  '7C79': 9,
  '7C7A': 9,
  '7C7B': 9,
  '7C7C': 9,
  '7C7D': 9,
  '7C7E': 9,
  '7C7F': 9,
  '7C80': 9,
  '7C81': 9,
  '7C82': 9,
  '7C83': 10,
  '7C84': 10,
  '7C85': 10,
  '7C86': 10,
  '7C87': 10,
  '7C88': 10,
  '7C89': 10,
  '7C8A': 10,
  '7C8B': 10,
  '7C8C': 10,
  '7C8D': 10,
  '7C8E': 10,
  '7C8F': 10,
  '7C90': 10,
  '7C91': 10,
  '7C92': 11,
  '7C93': 11,
  '7C94': 10,
  '7C95': 11,
  '7C96': 11,
  '7C97': 11,
  '7C98': 11,
  '7C99': 11,
  '7C9A': 11,
  '7C9B': 11,
  '7C9C': 11,
  '7C9D': 11,
  '7C9E': 12,
  '7C9F': 12,
  '7CA0': 12,
  '7CA1': 12,
  '7CA2': 12,
  '7CA3': 11,
  '7CA4': 12,
  '7CA5': 12,
  '7CA6': 12,
  '7CA7': 12,
  '7CA8': 12,
  '7CA9': 12,
  '7CAA': 12,
  '7CAB': 12,
  '7CAC': 12,
  '7CAD': 12,
  '7CAE': 13,
  '7CAF': 13,
  '7CB0': 13,
  '7CB1': 13,
  '7CB2': 13,
  '7CB3': 13,
  '7CB4': 13,
  '7CB5': 13,
  '7CB6': 14,
  '7CB7': 14,
  '7CB8': 14,
  '7CB9': 14,
  '7CBA': 14,
  '7CBB': 14,
  '7CBC': 14,
  '7CBD': 14,
  '7CBE': 14,
  '7CBF': 14,
  '7CC0': 13,
  '7CC1': 14,
  '7CC2': 15,
  '7CC3': 15,
  '7CC4': 15,
  '7CC5': 15,
  '7CC6': 15,
  '7CC7': 15,
  '7CC8': 15,
  '7CC9': 15,
  '7CCA': 15,
  '7CCB': 15,
  '7CCC': 15,
  '7CCD': 15,
  '7CCE': 15,
  '7CCF': 16,
  '7CD0': 16,
  '7CD1': 16,
  '7CD2': 16,
  '7CD3': 16,
  '7CD4': 15,
  '7CD5': 16,
  '7CD6': 16,
  '7CD7': 16,
  '7CD8': 16,
  '7CD9': 16,
  '7CDA': 16,
  '7CDB': 17,
  '7CDC': 17,
  '7CDD': 17,
  '7CDE': 17,
  '7CDF': 17,
  '7CE0': 17,
  '7CE1': 17,
  '7CE2': 16,
  '7CE3': 18,
  '7CE4': 18,
  '7CE5': 18,
  '7CE6': 18,
  '7CE7': 18,
  '7CE8': 18,
  '7CE9': 19,
  '7CEA': 19,
  '7CEB': 19,
  '7CEC': 19,
  '7CED': 19,
  '7CEE': 20,
  '7CEF': 20,
  '7CF0': 20,
  '7CF1': 22,
  '7CF2': 20,
  '7CF3': 26,
  '7CF4': 22,
  '7CF5': 22,
  '7CF6': 25,
  '7CF7': 26,
  '7CF8': 6,
  '7CF9': 6,
  '7CFA': 7,
  '7CFB': 7,
  '7CFC': 8,
  '7CFD': 8,
  '7CFE': 8,
  '7CFF': 8,
  '7D00': 9,
  '7D01': 9,
  '7D02': 9,
  '7D03': 9,
  '7D04': 9,
  '7D05': 9,
  '7D06': 9,
  '7D07': 9,
  '7D08': 9,
  '7D09': 9,
  '7D0A': 10,
  '7D0B': 10,
  '7D0C': 10,
  '7D0D': 10,
  '7D0E': 10,
  '7D0F': 10,
  '7D10': 10,
  '7D11': 10,
  '7D12': 10,
  '7D13': 10,
  '7D14': 10,
  '7D15': 10,
  '7D16': 10,
  '7D17': 10,
  '7D18': 10,
  '7D19': 10,
  '7D1A': 9,
  '7D1B': 10,
  '7D1C': 10,
  '7D1D': 10,
  '7D1E': 10,
  '7D1F': 10,
  '7D20': 10,
  '7D21': 10,
  '7D22': 10,
  '7D23': 10,
  '7D24': 10,
  '7D25': 10,
  '7D26': 10,
  '7D27': 10,
  '7D28': 11,
  '7D29': 11,
  '7D2A': 12,
  '7D2B': 12,
  '7D2C': 11,
  '7D2D': 11,
  '7D2E': 11,
  '7D2F': 11,
  '7D30': 11,
  '7D31': 11,
  '7D32': 11,
  '7D33': 11,
  '7D34': 11,
  '7D35': 11,
  '7D36': 11,
  '7D37': 11,
  '7D38': 11,
  '7D39': 11,
  '7D3A': 11,
  '7D3B': 11,
  '7D3C': 11,
  '7D3D': 11,
  '7D3E': 11,
  '7D3F': 11,
  '7D40': 11,
  '7D41': 11,
  '7D42': 11,
  '7D43': 11,
  '7D44': 11,
  '7D45': 11,
  '7D46': 11,
  '7D47': 11,
  '7D48': 11,
  '7D49': 11,
  '7D4A': 11,
  '7D4B': 11,
  '7D4C': 11,
  '7D4D': 12,
  '7D4E': 12,
  '7D4F': 12,
  '7D50': 12,
  '7D51': 12,
  '7D52': 12,
  '7D53': 12,
  '7D54': 12,
  '7D55': 12,
  '7D56': 12,
  '7D57': 12,
  '7D58': 12,
  '7D59': 12,
  '7D5A': 12,
  '7D5B': 12,
  '7D5C': 12,
  '7D5D': 12,
  '7D5E': 12,
  '7D5F': 12,
  '7D60': 12,
  '7D61': 12,
  '7D62': 12,
  '7D63': 12,
  '7D64': 12,
  '7D65': 12,
  '7D66': 12,
  '7D67': 12,
  '7D68': 12,
  '7D69': 12,
  '7D6A': 12,
  '7D6B': 12,
  '7D6C': 12,
  '7D6D': 12,
  '7D6E': 12,
  '7D6F': 12,
  '7D70': 12,
  '7D71': 12,
  '7D72': 12,
  '7D73': 12,
  '7D74': 12,
  '7D75': 12,
  '7D76': 12,
  '7D77': 12,
  '7D78': 13,
  '7D79': 13,
  '7D7A': 13,
  '7D7B': 13,
  '7D7C': 13,
  '7D7D': 12,
  '7D7E': 12,
  '7D7F': 13,
  '7D80': 13,
  '7D81': 12,
  '7D82': 13,
  '7D83': 13,
  '7D84': 13,
  '7D85': 13,
  '7D86': 13,
  '7D87': 13,
  '7D88': 13,
  '7D89': 13,
  '7D8A': 13,
  '7D8B': 13,
  '7D8C': 13,
  '7D8D': 13,
  '7D8E': 12,
  '7D8F': 13,
  '7D90': 13,
  '7D91': 13,
  '7D92': 13,
  '7D93': 13,
  '7D94': 13,
  '7D95': 13,
  '7D96': 12,
  '7D97': 13,
  '7D98': 13,
  '7D99': 13,
  '7D9A': 13,
  '7D9B': 13,
  '7D9C': 14,
  '7D9D': 14,
  '7D9E': 14,
  '7D9F': 14,
  '7DA0': 14,
  '7DA1': 14,
  '7DA2': 14,
  '7DA3': 14,
  '7DA4': 13,
  '7DA5': 14,
  '7DA6': 14,
  '7DA7': 14,
  '7DA8': 14,
  '7DA9': 14,
  '7DAA': 14,
  '7DAB': 14,
  '7DAC': 14,
  '7DAD': 14,
  '7DAE': 14,
  '7DAF': 14,
  '7DB0': 14,
  '7DB1': 14,
  '7DB2': 14,
  '7DB3': 14,
  '7DB4': 14,
  '7DB5': 14,
  '7DB6': 14,
  '7DB7': 14,
  '7DB8': 14,
  '7DB9': 14,
  '7DBA': 14,
  '7DBB': 14,
  '7DBC': 14,
  '7DBD': 14,
  '7DBE': 14,
  '7DBF': 14,
  '7DC0': 14,
  '7DC1': 14,
  '7DC2': 14,
  '7DC3': 14,
  '7DC4': 14,
  '7DC5': 14,
  '7DC6': 14,
  '7DC7': 14,
  '7DC8': 14,
  '7DC9': 14,
  '7DCA': 14,
  '7DCB': 14,
  '7DCC': 14,
  '7DCD': 14,
  '7DCE': 14,
  '7DCF': 14,
  '7DD0': 14,
  '7DD1': 14,
  '7DD2': 14,
  '7DD3': 14,
  '7DD4': 14,
  '7DD5': 14,
  '7DD6': 15,
  '7DD7': 15,
  '7DD8': 15,
  '7DD9': 15,
  '7DDA': 15,
  '7DDB': 15,
  '7DDC': 15,
  '7DDD': 15,
  '7DDE': 15,
  '7DDF': 15,
  '7DE0': 15,
  '7DE1': 15,
  '7DE2': 14,
  '7DE3': 15,
  '7DE4': 15,
  '7DE5': 15,
  '7DE6': 15,
  '7DE7': 15,
  '7DE8': 15,
  '7DE9': 15,
  '7DEA': 15,
  '7DEB': 15,
  '7DEC': 15,
  '7DED': 15,
  '7DEE': 15,
  '7DEF': 15,
  '7DF0': 15,
  '7DF1': 15,
  '7DF2': 15,
  '7DF3': 15,
  '7DF4': 15,
  '7DF5': 15,
  '7DF6': 15,
  '7DF7': 15,
  '7DF8': 15,
  '7DF9': 15,
  '7DFA': 14,
  '7DFB': 16,
  '7DFC': 15,
  '7DFD': 15,
  '7DFE': 15,
  '7DFF': 15,
  '7E00': 15,
  '7E01': 15,
  '7E02': 15,
  '7E03': 15,
  '7E04': 15,
  '7E05': 15,
  '7E06': 15,
  '7E07': 15,
  '7E08': 16,
  '7E09': 16,
  '7E0A': 16,
  '7E0B': 15,
  '7E0C': 15,
  '7E0D': 16,
  '7E0E': 15,
  '7E0F': 16,
  '7E10': 16,
  '7E11': 16,
  '7E12': 15,
  '7E13': 16,
  '7E14': 16,
  '7E15': 16,
  '7E16': 16,
  '7E17': 16,
  '7E18': 16,
  '7E19': 15,
  '7E1A': 16,
  '7E1B': 16,
  '7E1C': 16,
  '7E1D': 16,
  '7E1E': 16,
  '7E1F': 16,
  '7E20': 16,
  '7E21': 16,
  '7E22': 16,
  '7E23': 16,
  '7E24': 16,
  '7E25': 16,
  '7E26': 16,
  '7E27': 16,
  '7E28': 16,
  '7E29': 17,
  '7E2A': 16,
  '7E2B': 16,
  '7E2C': 17,
  '7E2D': 16,
  '7E2E': 17,
  '7E2F': 17,
  '7E30': 17,
  '7E31': 17,
  '7E32': 17,
  '7E33': 17,
  '7E34': 17,
  '7E35': 17,
  '7E36': 17,
  '7E37': 17,
  '7E38': 16,
  '7E39': 17,
  '7E3A': 16,
  '7E3B': 17,
  '7E3C': 17,
  '7E3D': 17,
  '7E3E': 17,
  '7E3F': 17,
  '7E40': 17,
  '7E41': 17,
  '7E42': 17,
  '7E43': 17,
  '7E44': 17,
  '7E45': 17,
  '7E46': 17,
  '7E47': 17,
  '7E48': 17,
  '7E49': 17,
  '7E4A': 17,
  '7E4B': 17,
  '7E4C': 17,
  '7E4D': 17,
  '7E4E': 18,
  '7E4F': 18,
  '7E50': 18,
  '7E51': 18,
  '7E52': 18,
  '7E53': 18,
  '7E54': 18,
  '7E55': 18,
  '7E56': 18,
  '7E57': 18,
  '7E58': 18,
  '7E59': 18,
  '7E5A': 18,
  '7E5B': 18,
  '7E5C': 18,
  '7E5D': 18,
  '7E5E': 18,
  '7E5F': 18,
  '7E60': 18,
  '7E61': 19,
  '7E62': 18,
  '7E63': 18,
  '7E64': 17,
  '7E65': 18,
  '7E66': 18,
  '7E67': 18,
  '7E68': 18,
  '7E69': 19,
  '7E6A': 19,
  '7E6B': 19,
  '7E6C': 19,
  '7E6D': 18,
  '7E6E': 19,
  '7E6F': 19,
  '7E70': 19,
  '7E71': 18,
  '7E72': 19,
  '7E73': 19,
  '7E74': 19,
  '7E75': 19,
  '7E76': 19,
  '7E77': 19,
  '7E78': 18,
  '7E79': 19,
  '7E7A': 19,
  '7E7B': 20,
  '7E7C': 20,
  '7E7D': 20,
  '7E7E': 19,
  '7E7F': 20,
  '7E80': 20,
  '7E81': 20,
  '7E82': 20,
  '7E83': 20,
  '7E84': 19,
  '7E85': 21,
  '7E86': 21,
  '7E87': 21,
  '7E88': 21,
  '7E89': 21,
  '7E8A': 20,
  '7E8B': 21,
  '7E8C': 21,
  '7E8D': 21,
  '7E8E': 21,
  '7E8F': 21,
  '7E90': 21,
  '7E91': 22,
  '7E92': 22,
  '7E93': 23,
  '7E94': 23,
  '7E95': 23,
  '7E96': 23,
  '7E97': 24,
  '7E98': 25,
  '7E99': 25,
  '7E9A': 25,
  '7E9B': 25,
  '7E9C': 27,
  '7E9D': 27,
  '7E9E': 29,
  '7E9F': 3,
  '7EA0': 5,
  '7EA1': 6,
  '7EA2': 6,
  '7EA3': 6,
  '7EA4': 6,
  '7EA5': 6,
  '7EA6': 6,
  '7EA7': 6,
  '7EA8': 6,
  '7EA9': 6,
  '7EAA': 6,
  '7EAB': 6,
  '7EAC': 7,
  '7EAD': 7,
  '7EAE': 7,
  '7EAF': 7,
  '7EB0': 7,
  '7EB1': 7,
  '7EB2': 7,
  '7EB3': 7,
  '7EB4': 7,
  '7EB5': 7,
  '7EB6': 7,
  '7EB7': 7,
  '7EB8': 7,
  '7EB9': 7,
  '7EBA': 7,
  '7EBB': 7,
  '7EBC': 7,
  '7EBD': 7,
  '7EBE': 7,
  '7EBF': 8,
  '7EC0': 8,
  '7EC1': 8,
  '7EC2': 8,
  '7EC3': 8,
  '7EC4': 8,
  '7EC5': 8,
  '7EC6': 8,
  '7EC7': 8,
  '7EC8': 8,
  '7EC9': 8,
  '7ECA': 8,
  '7ECB': 8,
  '7ECC': 8,
  '7ECD': 8,
  '7ECE': 8,
  '7ECF': 8,
  '7ED0': 8,
  '7ED1': 9,
  '7ED2': 9,
  '7ED3': 9,
  '7ED4': 9,
  '7ED5': 9,
  '7ED6': 9,
  '7ED7': 9,
  '7ED8': 9,
  '7ED9': 9,
  '7EDA': 9,
  '7EDB': 9,
  '7EDC': 9,
  '7EDD': 9,
  '7EDE': 9,
  '7EDF': 9,
  '7EE0': 10,
  '7EE1': 10,
  '7EE2': 10,
  '7EE3': 10,
  '7EE4': 10,
  '7EE5': 10,
  '7EE6': 10,
  '7EE7': 10,
  '7EE8': 10,
  '7EE9': 11,
  '7EEA': 11,
  '7EEB': 11,
  '7EEC': 11,
  '7EED': 11,
  '7EEE': 11,
  '7EEF': 11,
  '7EF0': 11,
  '7EF1': 11,
  '7EF2': 11,
  '7EF3': 11,
  '7EF4': 11,
  '7EF5': 11,
  '7EF6': 11,
  '7EF7': 11,
  '7EF8': 11,
  '7EF9': 11,
  '7EFA': 11,
  '7EFB': 11,
  '7EFC': 11,
  '7EFD': 11,
  '7EFE': 11,
  '7EFF': 11,
  '7F00': 11,
  '7F01': 11,
  '7F02': 12,
  '7F03': 12,
  '7F04': 12,
  '7F05': 12,
  '7F06': 12,
  '7F07': 12,
  '7F08': 12,
  '7F09': 12,
  '7F0A': 12,
  '7F0B': 12,
  '7F0C': 12,
  '7F0D': 11,
  '7F0E': 12,
  '7F0F': 12,
  '7F10': 12,
  '7F11': 12,
  '7F12': 12,
  '7F13': 12,
  '7F14': 12,
  '7F15': 12,
  '7F16': 12,
  '7F17': 12,
  '7F18': 12,
  '7F19': 13,
  '7F1A': 13,
  '7F1B': 13,
  '7F1C': 13,
  '7F1D': 13,
  '7F1E': 13,
  '7F1F': 13,
  '7F20': 13,
  '7F21': 13,
  '7F22': 13,
  '7F23': 13,
  '7F24': 13,
  '7F25': 14,
  '7F26': 14,
  '7F27': 14,
  '7F28': 14,
  '7F29': 14,
  '7F2A': 14,
  '7F2B': 14,
  '7F2C': 15,
  '7F2D': 15,
  '7F2E': 15,
  '7F2F': 15,
  '7F30': 16,
  '7F31': 16,
  '7F32': 16,
  '7F33': 16,
  '7F34': 16,
  '7F35': 19,
  '7F36': 6,
  '7F37': 8,
  '7F38': 9,
  '7F39': 10,
  '7F3A': 10,
  '7F3B': 10,
  '7F3C': 10,
  '7F3D': 11,
  '7F3E': 12,
  '7F3F': 12,
  '7F40': 12,
  '7F41': 14,
  '7F42': 14,
  '7F43': 16,
  '7F44': 17,
  '7F45': 17,
  '7F46': 17,
  '7F47': 18,
  '7F48': 18,
  '7F49': 18,
  '7F4A': 19,
  '7F4B': 19,
  '7F4C': 20,
  '7F4D': 21,
  '7F4E': 22,
  '7F4F': 22,
  '7F50': 23,
  '7F51': 6,
  '7F52': 5,
  '7F53': 4,
  '7F54': 8,
  '7F55': 7,
  '7F56': 8,
  '7F57': 8,
  '7F58': 9,
  '7F59': 8,
  '7F5A': 9,
  '7F5B': 10,
  '7F5C': 10,
  '7F5D': 10,
  '7F5E': 10,
  '7F5F': 10,
  '7F60': 10,
  '7F61': 10,
  '7F62': 10,
  '7F63': 11,
  '7F64': 12,
  '7F65': 12,
  '7F66': 12,
  '7F67': 13,
  '7F68': 13,
  '7F69': 13,
  '7F6A': 13,
  '7F6B': 13,
  '7F6C': 13,
  '7F6D': 13,
  '7F6E': 13,
  '7F6F': 14,
  '7F70': 14,
  '7F71': 14,
  '7F72': 13,
  '7F73': 14,
  '7F74': 14,
  '7F75': 15,
  '7F76': 15,
  '7F77': 15,
  '7F78': 15,
  '7F79': 16,
  '7F7A': 16,
  '7F7B': 16,
  '7F7C': 15,
  '7F7D': 17,
  '7F7E': 17,
  '7F7F': 17,
  '7F80': 18,
  '7F81': 17,
  '7F82': 18,
  '7F83': 18,
  '7F84': 19,
  '7F85': 19,
  '7F86': 19,
  '7F87': 22,
  '7F88': 24,
  '7F89': 24,
  '7F8A': 6,
  '7F8B': 8,
  '7F8C': 7,
  '7F8D': 9,
  '7F8E': 9,
  '7F8F': 9,
  '7F90': 10,
  '7F91': 9,
  '7F92': 10,
  '7F93': 10,
  '7F94': 10,
  '7F95': 11,
  '7F96': 10,
  '7F97': 9,
  '7F98': 10,
  '7F99': 10,
  '7F9A': 11,
  '7F9B': 11,
  '7F9C': 11,
  '7F9D': 11,
  '7F9E': 10,
  '7F9F': 11,
  '7FA0': 12,
  '7FA1': 12,
  '7FA2': 12,
  '7FA3': 13,
  '7FA4': 13,
  '7FA5': 13,
  '7FA6': 13,
  '7FA7': 13,
  '7FA8': 13,
  '7FA9': 13,
  '7FAA': 13,
  '7FAB': 14,
  '7FAC': 15,
  '7FAD': 15,
  '7FAE': 15,
  '7FAF': 15,
  '7FB0': 15,
  '7FB1': 16,
  '7FB2': 16,
  '7FB3': 18,
  '7FB4': 18,
  '7FB5': 18,
  '7FB6': 19,
  '7FB7': 19,
  '7FB8': 19,
  '7FB9': 19,
  '7FBA': 20,
  '7FBB': 21,
  '7FBC': 21,
  '7FBD': 6,
  '7FBE': 9,
  '7FBF': 9,
  '7FC0': 10,
  '7FC1': 10,
  '7FC2': 10,
  '7FC3': 10,
  '7FC4': 10,
  '7FC5': 10,
  '7FC6': 10,
  '7FC7': 11,
  '7FC8': 11,
  '7FC9': 11,
  '7FCA': 11,
  '7FCB': 11,
  '7FCC': 11,
  '7FCD': 11,
  '7FCE': 11,
  '7FCF': 11,
  '7FD0': 11,
  '7FD1': 11,
  '7FD2': 11,
  '7FD3': 12,
  '7FD4': 12,
  '7FD5': 12,
  '7FD6': 12,
  '7FD7': 12,
  '7FD8': 12,
  '7FD9': 12,
  '7FDA': 12,
  '7FDB': 12,
  '7FDC': 13,
  '7FDD': 13,
  '7FDE': 14,
  '7FDF': 14,
  '7FE0': 14,
  '7FE1': 14,
  '7FE2': 14,
  '7FE3': 14,
  '7FE4': 14,
  '7FE5': 14,
  '7FE6': 15,
  '7FE7': 15,
  '7FE8': 15,
  '7FE9': 15,
  '7FEA': 15,
  '7FEB': 15,
  '7FEC': 15,
  '7FED': 15,
  '7FEE': 16,
  '7FEF': 16,
  '7FF0': 16,
  '7FF1': 16,
  '7FF2': 17,
  '7FF3': 17,
  '7FF4': 16,
  '7FF5': 17,
  '7FF6': 17,
  '7FF7': 18,
  '7FF8': 18,
  '7FF9': 18,
  '7FFA': 18,
  '7FFB': 18,
  '7FFC': 17,
  '7FFD': 19,
  '7FFE': 19,
  '7FFF': 20,
  '8000': 20,
  '8001': 6,
  '8002': 4,
  '8003': 6,
  '8004': 10,
  '8005': 8,
  '8006': 10,
  '8007': 9,
  '8008': 11,
  '8009': 9,
  '800A': 10,
  '800B': 12,
  '800C': 6,
  '800D': 9,
  '800E': 9,
  '800F': 9,
  '8010': 9,
  '8011': 9,
  '8012': 6,
  '8013': 8,
  '8014': 9,
  '8015': 10,
  '8016': 10,
  '8017': 10,
  '8018': 10,
  '8019': 10,
  '801A': 11,
  '801B': 11,
  '801C': 11,
  '801D': 11,
  '801E': 11,
  '801F': 10,
  '8020': 12,
  '8021': 13,
  '8022': 13,
  '8023': 14,
  '8024': 14,
  '8025': 14,
  '8026': 15,
  '8027': 15,
  '8028': 16,
  '8029': 16,
  '802A': 16,
  '802B': 17,
  '802C': 17,
  '802D': 18,
  '802E': 18,
  '802F': 19,
  '8030': 21,
  '8031': 22,
  '8032': 22,
  '8033': 6,
  '8034': 7,
  '8035': 8,
  '8036': 8,
  '8037': 9,
  '8038': 10,
  '8039': 10,
  '803A': 10,
  '803B': 10,
  '803C': 10,
  '803D': 10,
  '803E': 10,
  '803F': 10,
  '8040': 10,
  '8041': 10,
  '8042': 10,
  '8043': 11,
  '8044': 11,
  '8045': 11,
  '8046': 11,
  '8047': 11,
  '8048': 11,
  '8049': 11,
  '804A': 11,
  '804B': 11,
  '804C': 11,
  '804D': 11,
  '804E': 12,
  '804F': 12,
  '8050': 12,
  '8051': 12,
  '8052': 12,
  '8053': 12,
  '8054': 12,
  '8055': 13,
  '8056': 13,
  '8057': 13,
  '8058': 13,
  '8059': 14,
  '805A': 14,
  '805B': 14,
  '805C': 14,
  '805D': 14,
  '805E': 14,
  '805F': 14,
  '8060': 12,
  '8061': 14,
  '8062': 14,
  '8063': 14,
  '8064': 15,
  '8065': 15,
  '8066': 15,
  '8067': 15,
  '8068': 15,
  '8069': 15,
  '806A': 15,
  '806B': 15,
  '806C': 16,
  '806D': 15,
  '806E': 16,
  '806F': 17,
  '8070': 17,
  '8071': 16,
  '8072': 17,
  '8073': 17,
  '8074': 17,
  '8075': 18,
  '8076': 18,
  '8077': 18,
  '8078': 19,
  '8079': 20,
  '807A': 20,
  '807B': 20,
  '807C': 19,
  '807D': 22,
  '807E': 22,
  '807F': 6,
  '8080': 4,
  '8081': 10,
  '8082': 10,
  '8083': 8,
  '8084': 13,
  '8085': 13,
  '8086': 13,
  '8087': 14,
  '8088': 14,
  '8089': 6,
  '808A': 5,
  '808B': 6,
  '808C': 6,
  '808D': 6,
  '808E': 6,
  '808F': 8,
  '8090': 7,
  '8091': 7,
  '8092': 7,
  '8093': 7,
  '8094': 7,
  '8095': 7,
  '8096': 7,
  '8097': 7,
  '8098': 7,
  '8099': 7,
  '809A': 7,
  '809B': 7,
  '809C': 7,
  '809D': 7,
  '809E': 7,
  '809F': 7,
  '80A0': 7,
  '80A1': 8,
  '80A2': 8,
  '80A3': 8,
  '80A4': 8,
  '80A5': 8,
  '80A6': 8,
  '80A7': 8,
  '80A8': 8,
  '80A9': 8,
  '80AA': 8,
  '80AB': 8,
  '80AC': 8,
  '80AD': 8,
  '80AE': 8,
  '80AF': 8,
  '80B0': 8,
  '80B1': 8,
  '80B2': 8,
  '80B3': 8,
  '80B4': 8,
  '80B5': 8,
  '80B6': 8,
  '80B7': 8,
  '80B8': 8,
  '80B9': 8,
  '80BA': 8,
  '80BB': 8,
  '80BC': 8,
  '80BD': 8,
  '80BE': 8,
  '80BF': 8,
  '80C0': 8,
  '80C1': 8,
  '80C2': 9,
  '80C3': 9,
  '80C4': 9,
  '80C5': 9,
  '80C6': 9,
  '80C7': 9,
  '80C8': 9,
  '80C9': 9,
  '80CA': 9,
  '80CB': 9,
  '80CC': 9,
  '80CD': 9,
  '80CE': 9,
  '80CF': 8,
  '80D0': 9,
  '80D1': 9,
  '80D2': 9,
  '80D3': 9,
  '80D4': 12,
  '80D5': 9,
  '80D6': 9,
  '80D7': 9,
  '80D8': 9,
  '80D9': 9,
  '80DA': 9,
  '80DB': 9,
  '80DC': 9,
  '80DD': 9,
  '80DE': 9,
  '80DF': 9,
  '80E0': 9,
  '80E1': 9,
  '80E2': 9,
  '80E3': 9,
  '80E4': 9,
  '80E5': 9,
  '80E6': 9,
  '80E7': 9,
  '80E8': 9,
  '80E9': 9,
  '80EA': 9,
  '80EB': 9,
  '80EC': 11,
  '80ED': 10,
  '80EE': 10,
  '80EF': 10,
  '80F0': 10,
  '80F1': 10,
  '80F2': 10,
  '80F3': 10,
  '80F4': 10,
  '80F5': 10,
  '80F6': 10,
  '80F7': 10,
  '80F8': 10,
  '80F9': 10,
  '80FA': 10,
  '80FB': 10,
  '80FC': 10,
  '80FD': 10,
  '80FE': 12,
  '80FF': 10,
  '8100': 10,
  '8101': 10,
  '8102': 10,
  '8103': 10,
  '8104': 10,
  '8105': 10,
  '8106': 10,
  '8107': 10,
  '8108': 10,
  '8109': 9,
  '810A': 10,
  '810B': 10,
  '810C': 10,
  '810D': 10,
  '810E': 10,
  '810F': 10,
  '8110': 10,
  '8111': 10,
  '8112': 10,
  '8113': 10,
  '8114': 12,
  '8115': 11,
  '8116': 11,
  '8117': 11,
  '8118': 11,
  '8119': 11,
  '811A': 11,
  '811B': 11,
  '811C': 11,
  '811D': 11,
  '811E': 11,
  '811F': 11,
  '8120': 10,
  '8121': 10,
  '8122': 11,
  '8123': 11,
  '8124': 11,
  '8125': 11,
  '8126': 11,
  '8127': 11,
  '8128': 11,
  '8129': 10,
  '812A': 11,
  '812B': 11,
  '812C': 11,
  '812D': 11,
  '812E': 11,
  '812F': 11,
  '8130': 11,
  '8131': 11,
  '8132': 11,
  '8133': 11,
  '8134': 11,
  '8135': 11,
  '8136': 11,
  '8137': 11,
  '8138': 11,
  '8139': 12,
  '813A': 12,
  '813B': 12,
  '813C': 12,
  '813D': 12,
  '813E': 12,
  '813F': 12,
  '8140': 12,
  '8141': 12,
  '8142': 12,
  '8143': 12,
  '8144': 12,
  '8145': 12,
  '8146': 12,
  '8147': 12,
  '8148': 12,
  '8149': 12,
  '814A': 12,
  '814B': 12,
  '814C': 12,
  '814D': 12,
  '814E': 12,
  '814F': 12,
  '8150': 14,
  '8151': 12,
  '8152': 12,
  '8153': 12,
  '8154': 12,
  '8155': 12,
  '8156': 12,
  '8157': 12,
  '8158': 12,
  '8159': 12,
  '815A': 12,
  '815B': 13,
  '815C': 13,
  '815D': 13,
  '815E': 13,
  '815F': 13,
  '8160': 13,
  '8161': 12,
  '8162': 13,
  '8163': 13,
  '8164': 13,
  '8165': 13,
  '8166': 13,
  '8167': 13,
  '8168': 13,
  '8169': 13,
  '816A': 13,
  '816B': 13,
  '816C': 13,
  '816D': 13,
  '816E': 13,
  '816F': 13,
  '8170': 13,
  '8171': 12,
  '8172': 13,
  '8173': 13,
  '8174': 12,
  '8175': 13,
  '8176': 13,
  '8177': 13,
  '8178': 13,
  '8179': 13,
  '817A': 13,
  '817B': 13,
  '817C': 13,
  '817D': 13,
  '817E': 13,
  '817F': 13,
  '8180': 14,
  '8181': 14,
  '8182': 14,
  '8183': 14,
  '8184': 13,
  '8185': 14,
  '8186': 14,
  '8187': 13,
  '8188': 14,
  '8189': 14,
  '818A': 14,
  '818B': 14,
  '818C': 14,
  '818D': 14,
  '818E': 14,
  '818F': 14,
  '8190': 16,
  '8191': 14,
  '8192': 15,
  '8193': 15,
  '8194': 15,
  '8195': 15,
  '8196': 14,
  '8197': 15,
  '8198': 15,
  '8199': 16,
  '819A': 15,
  '819B': 15,
  '819C': 14,
  '819D': 15,
  '819E': 15,
  '819F': 15,
  '81A0': 15,
  '81A1': 15,
  '81A2': 15,
  '81A3': 15,
  '81A4': 15,
  '81A5': 17,
  '81A6': 16,
  '81A7': 16,
  '81A8': 16,
  '81A9': 16,
  '81AA': 16,
  '81AB': 16,
  '81AC': 16,
  '81AD': 16,
  '81AE': 16,
  '81AF': 16,
  '81B0': 16,
  '81B1': 16,
  '81B2': 16,
  '81B3': 16,
  '81B4': 16,
  '81B5': 15,
  '81B6': 16,
  '81B7': 15,
  '81B8': 17,
  '81B9': 16,
  '81BA': 17,
  '81BB': 17,
  '81BC': 15,
  '81BD': 17,
  '81BE': 17,
  '81BF': 17,
  '81C0': 17,
  '81C1': 17,
  '81C2': 17,
  '81C3': 17,
  '81C4': 17,
  '81C5': 17,
  '81C6': 17,
  '81C7': 16,
  '81C8': 16,
  '81C9': 17,
  '81CA': 17,
  '81CB': 19,
  '81CC': 17,
  '81CD': 18,
  '81CE': 18,
  '81CF': 18,
  '81D0': 18,
  '81D1': 18,
  '81D2': 17,
  '81D3': 18,
  '81D4': 19,
  '81D5': 19,
  '81D6': 20,
  '81D7': 18,
  '81D8': 19,
  '81D9': 20,
  '81DA': 20,
  '81DB': 20,
  '81DC': 20,
  '81DD': 21,
  '81DE': 22,
  '81DF': 21,
  '81E0': 25,
  '81E1': 25,
  '81E2': 23,
  '81E3': 6,
  '81E4': 8,
  '81E5': 8,
  '81E6': 12,
  '81E7': 14,
  '81E8': 17,
  '81E9': 17,
  '81EA': 6,
  '81EB': 7,
  '81EC': 10,
  '81ED': 10,
  '81EE': 12,
  '81EF': 12,
  '81F0': 12,
  '81F1': 15,
  '81F2': 16,
  '81F3': 6,
  '81F4': 10,
  '81F5': 12,
  '81F6': 12,
  '81F7': 12,
  '81F8': 12,
  '81F9': 12,
  '81FA': 14,
  '81FB': 16,
  '81FC': 6,
  '81FD': 8,
  '81FE': 8,
  '81FF': 9,
  '8200': 10,
  '8201': 9,
  '8202': 11,
  '8203': 12,
  '8204': 12,
  '8205': 13,
  '8206': 14,
  '8207': 13,
  '8208': 16,
  '8209': 16,
  '820A': 17,
  '820B': 20,
  '820C': 6,
  '820D': 8,
  '820E': 8,
  '820F': 8,
  '8210': 10,
  '8211': 11,
  '8212': 12,
  '8213': 14,
  '8214': 14,
  '8215': 14,
  '8216': 15,
  '8217': 15,
  '8218': 16,
  '8219': 18,
  '821A': 19,
  '821B': 6,
  '821C': 12,
  '821D': 13,
  '821E': 14,
  '821F': 6,
  '8220': 8,
  '8221': 9,
  '8222': 9,
  '8223': 9,
  '8224': 9,
  '8225': 10,
  '8226': 10,
  '8227': 10,
  '8228': 10,
  '8229': 10,
  '822A': 10,
  '822B': 10,
  '822C': 10,
  '822D': 10,
  '822E': 10,
  '822F': 10,
  '8230': 10,
  '8231': 10,
  '8232': 11,
  '8233': 11,
  '8234': 11,
  '8235': 11,
  '8236': 11,
  '8237': 11,
  '8238': 11,
  '8239': 11,
  '823A': 11,
  '823B': 11,
  '823C': 12,
  '823D': 12,
  '823E': 12,
  '823F': 12,
  '8240': 13,
  '8241': 13,
  '8242': 13,
  '8243': 13,
  '8244': 13,
  '8245': 13,
  '8246': 13,
  '8247': 12,
  '8248': 13,
  '8249': 13,
  '824A': 14,
  '824B': 14,
  '824C': 14,
  '824D': 14,
  '824E': 15,
  '824F': 15,
  '8250': 15,
  '8251': 15,
  '8252': 15,
  '8253': 15,
  '8254': 15,
  '8255': 16,
  '8256': 15,
  '8257': 16,
  '8258': 15,
  '8259': 16,
  '825A': 17,
  '825B': 17,
  '825C': 17,
  '825D': 17,
  '825E': 18,
  '825F': 18,
  '8260': 18,
  '8261': 19,
  '8262': 19,
  '8263': 19,
  '8264': 19,
  '8265': 18,
  '8266': 20,
  '8267': 19,
  '8268': 19,
  '8269': 20,
  '826A': 21,
  '826B': 22,
  '826C': 23,
  '826D': 24,
  '826E': 6,
  '826F': 7,
  '8270': 8,
  '8271': 17,
  '8272': 6,
  '8273': 10,
  '8274': 11,
  '8275': 12,
  '8276': 19,
  '8277': 24,
  '8278': 6,
  '8279': 3,
  '827A': 4,
  '827B': 5,
  '827C': 5,
  '827D': 5,
  '827E': 5,
  '827F': 5,
  '8280': 5,
  '8281': 5,
  '8282': 5,
  '8283': 6,
  '8284': 6,
  '8285': 6,
  '8286': 6,
  '8287': 6,
  '8288': 7,
  '8289': 6,
  '828A': 6,
  '828B': 6,
  '828C': 6,
  '828D': 6,
  '828E': 6,
  '828F': 6,
  '8290': 6,
  '8291': 6,
  '8292': 6,
  '8293': 6,
  '8294': 9,
  '8295': 6,
  '8296': 6,
  '8297': 6,
  '8298': 7,
  '8299': 7,
  '829A': 7,
  '829B': 7,
  '829C': 7,
  '829D': 6,
  '829E': 7,
  '829F': 7,
  '82A0': 7,
  '82A1': 7,
  '82A2': 7,
  '82A3': 7,
  '82A4': 7,
  '82A5': 7,
  '82A6': 7,
  '82A7': 7,
  '82A8': 6,
  '82A9': 7,
  '82AA': 7,
  '82AB': 7,
  '82AC': 7,
  '82AD': 7,
  '82AE': 7,
  '82AF': 7,
  '82B0': 7,
  '82B1': 7,
  '82B2': 7,
  '82B3': 7,
  '82B4': 7,
  '82B5': 7,
  '82B6': 7,
  '82B7': 7,
  '82B8': 7,
  '82B9': 7,
  '82BA': 7,
  '82BB': 10,
  '82BC': 7,
  '82BD': 7,
  '82BE': 7,
  '82BF': 7,
  '82C0': 7,
  '82C1': 7,
  '82C2': 7,
  '82C3': 7,
  '82C4': 7,
  '82C5': 7,
  '82C6': 7,
  '82C7': 7,
  '82C8': 7,
  '82C9': 7,
  '82CA': 7,
  '82CB': 7,
  '82CC': 7,
  '82CD': 7,
  '82CE': 7,
  '82CF': 7,
  '82D0': 8,
  '82D1': 8,
  '82D2': 8,
  '82D3': 8,
  '82D4': 8,
  '82D5': 8,
  '82D6': 8,
  '82D7': 8,
  '82D8': 8,
  '82D9': 8,
  '82DA': 8,
  '82DB': 8,
  '82DC': 8,
  '82DD': 8,
  '82DE': 8,
  '82DF': 8,
  '82E0': 8,
  '82E1': 7,
  '82E2': 8,
  '82E3': 7,
  '82E4': 8,
  '82E5': 8,
  '82E6': 8,
  '82E7': 8,
  '82E8': 8,
  '82E9': 8,
  '82EA': 8,
  '82EB': 8,
  '82EC': 8,
  '82ED': 8,
  '82EE': 8,
  '82EF': 8,
  '82F0': 8,
  '82F1': 8,
  '82F2': 8,
  '82F3': 8,
  '82F4': 8,
  '82F5': 8,
  '82F6': 8,
  '82F7': 8,
  '82F8': 8,
  '82F9': 8,
  '82FA': 8,
  '82FB': 8,
  '82FC': 8,
  '82FD': 8,
  '82FE': 8,
  '82FF': 8,
  '8300': 8,
  '8301': 8,
  '8302': 8,
  '8303': 8,
  '8304': 8,
  '8305': 8,
  '8306': 8,
  '8307': 8,
  '8308': 9,
  '8309': 8,
  '830A': 8,
  '830B': 8,
  '830C': 8,
  '830D': 9,
  '830E': 8,
  '830F': 8,
  '8310': 8,
  '8311': 8,
  '8312': 12,
  '8313': 8,
  '8314': 8,
  '8315': 8,
  '8316': 9,
  '8317': 9,
  '8318': 9,
  '8319': 9,
  '831A': 8,
  '831B': 9,
  '831C': 9,
  '831D': 10,
  '831E': 9,
  '831F': 9,
  '8320': 9,
  '8321': 9,
  '8322': 9,
  '8323': 10,
  '8324': 9,
  '8325': 9,
  '8326': 9,
  '8327': 9,
  '8328': 9,
  '8329': 9,
  '832A': 9,
  '832B': 9,
  '832C': 9,
  '832D': 9,
  '832E': 9,
  '832F': 9,
  '8330': 9,
  '8331': 9,
  '8332': 9,
  '8333': 9,
  '8334': 9,
  '8335': 9,
  '8336': 9,
  '8337': 9,
  '8338': 9,
  '8339': 9,
  '833A': 9,
  '833B': 12,
  '833C': 9,
  '833D': 9,
  '833E': 7,
  '833F': 9,
  '8340': 9,
  '8341': 9,
  '8342': 9,
  '8343': 9,
  '8344': 9,
  '8345': 9,
  '8346': 9,
  '8347': 9,
  '8348': 9,
  '8349': 9,
  '834A': 9,
  '834B': 9,
  '834C': 9,
  '834D': 9,
  '834E': 9,
  '834F': 9,
  '8350': 9,
  '8351': 9,
  '8352': 9,
  '8353': 9,
  '8354': 9,
  '8355': 9,
  '8356': 9,
  '8357': 9,
  '8358': 9,
  '8359': 9,
  '835A': 9,
  '835B': 9,
  '835C': 9,
  '835D': 9,
  '835E': 9,
  '835F': 9,
  '8360': 9,
  '8361': 9,
  '8362': 9,
  '8363': 9,
  '8364': 9,
  '8365': 9,
  '8366': 9,
  '8367': 9,
  '8368': 9,
  '8369': 9,
  '836A': 9,
  '836B': 9,
  '836C': 9,
  '836D': 9,
  '836E': 9,
  '836F': 9,
  '8370': 10,
  '8371': 10,
  '8372': 10,
  '8373': 10,
  '8374': 10,
  '8375': 10,
  '8376': 10,
  '8377': 10,
  '8378': 10,
  '8379': 10,
  '837A': 10,
  '837B': 10,
  '837C': 10,
  '837D': 10,
  '837E': 10,
  '837F': 9,
  '8380': 10,
  '8381': 10,
  '8382': 10,
  '8383': 10,
  '8384': 10,
  '8385': 10,
  '8386': 10,
  '8387': 10,
  '8388': 10,
  '8389': 10,
  '838A': 10,
  '838B': 10,
  '838C': 10,
  '838D': 10,
  '838E': 10,
  '838F': 10,
  '8390': 10,
  '8391': 10,
  '8392': 9,
  '8393': 10,
  '8394': 10,
  '8395': 10,
  '8396': 10,
  '8397': 10,
  '8398': 10,
  '8399': 10,
  '839A': 9,
  '839B': 9,
  '839C': 10,
  '839D': 10,
  '839E': 10,
  '839F': 10,
  '83A0': 10,
  '83A1': 10,
  '83A2': 10,
  '83A3': 10,
  '83A4': 10,
  '83A5': 10,
  '83A6': 10,
  '83A7': 10,
  '83A8': 10,
  '83A9': 10,
  '83AA': 10,
  '83AB': 10,
  '83AC': 10,
  '83AD': 10,
  '83AE': 10,
  '83AF': 10,
  '83B0': 10,
  '83B1': 10,
  '83B2': 10,
  '83B3': 10,
  '83B4': 10,
  '83B5': 10,
  '83B6': 10,
  '83B7': 10,
  '83B8': 10,
  '83B9': 10,
  '83BA': 10,
  '83BB': 13,
  '83BC': 10,
  '83BD': 10,
  '83BE': 11,
  '83BF': 11,
  '83C0': 11,
  '83C1': 11,
  '83C2': 11,
  '83C3': 10,
  '83C4': 11,
  '83C5': 11,
  '83C6': 11,
  '83C7': 11,
  '83C8': 11,
  '83C9': 11,
  '83CA': 11,
  '83CB': 11,
  '83CC': 11,
  '83CD': 11,
  '83CE': 11,
  '83CF': 11,
  '83D0': 12,
  '83D1': 11,
  '83D2': 11,
  '83D3': 11,
  '83D4': 11,
  '83D5': 11,
  '83D6': 11,
  '83D7': 11,
  '83D8': 11,
  '83D9': 11,
  '83DA': 11,
  '83DB': 11,
  '83DC': 11,
  '83DD': 11,
  '83DE': 11,
  '83DF': 11,
  '83E0': 11,
  '83E1': 11,
  '83E2': 11,
  '83E3': 11,
  '83E4': 11,
  '83E5': 11,
  '83E6': 10,
  '83E7': 11,
  '83E8': 11,
  '83E9': 11,
  '83EA': 11,
  '83EB': 11,
  '83EC': 11,
  '83ED': 11,
  '83EE': 11,
  '83EF': 10,
  '83F0': 11,
  '83F1': 11,
  '83F2': 11,
  '83F3': 11,
  '83F4': 11,
  '83F5': 11,
  '83F6': 11,
  '83F7': 11,
  '83F8': 11,
  '83F9': 11,
  '83FA': 11,
  '83FB': 11,
  '83FC': 11,
  '83FD': 11,
  '83FE': 11,
  '83FF': 11,
  '8400': 11,
  '8401': 11,
  '8402': 11,
  '8403': 11,
  '8404': 11,
  '8405': 11,
  '8406': 11,
  '8407': 11,
  '8408': 11,
  '8409': 11,
  '840A': 11,
  '840B': 11,
  '840C': 11,
  '840D': 11,
  '840E': 11,
  '840F': 11,
  '8410': 11,
  '8411': 11,
  '8412': 11,
  '8413': 11,
  '8414': 11,
  '8415': 11,
  '8416': 11,
  '8417': 11,
  '8418': 11,
  '8419': 11,
  '841A': 11,
  '841B': 11,
  '841C': 11,
  '841D': 11,
  '841E': 11,
  '841F': 11,
  '8420': 11,
  '8421': 11,
  '8422': 11,
  '8423': 11,
  '8424': 11,
  '8425': 11,
  '8426': 11,
  '8427': 11,
  '8428': 11,
  '8429': 12,
  '842A': 12,
  '842B': 12,
  '842C': 12,
  '842D': 12,
  '842E': 12,
  '842F': 12,
  '8430': 12,
  '8431': 12,
  '8432': 12,
  '8433': 12,
  '8434': 12,
  '8435': 11,
  '8436': 12,
  '8437': 12,
  '8438': 11,
  '8439': 12,
  '843A': 12,
  '843B': 12,
  '843C': 12,
  '843D': 12,
  '843E': 12,
  '843F': 12,
  '8440': 12,
  '8441': 12,
  '8442': 12,
  '8443': 12,
  '8444': 12,
  '8445': 12,
  '8446': 12,
  '8447': 12,
  '8448': 12,
  '8449': 12,
  '844A': 12,
  '844B': 12,
  '844C': 12,
  '844D': 12,
  '844E': 12,
  '844F': 12,
  '8450': 12,
  '8451': 12,
  '8452': 12,
  '8453': 12,
  '8454': 12,
  '8455': 12,
  '8456': 12,
  '8457': 11,
  '8458': 12,
  '8459': 12,
  '845A': 12,
  '845B': 12,
  '845C': 12,
  '845D': 12,
  '845E': 12,
  '845F': 12,
  '8460': 12,
  '8461': 12,
  '8462': 12,
  '8463': 12,
  '8464': 12,
  '8465': 12,
  '8466': 12,
  '8467': 12,
  '8468': 12,
  '8469': 12,
  '846A': 12,
  '846B': 12,
  '846C': 12,
  '846D': 12,
  '846E': 12,
  '846F': 12,
  '8470': 12,
  '8471': 12,
  '8472': 12,
  '8473': 12,
  '8474': 12,
  '8475': 12,
  '8476': 12,
  '8477': 12,
  '8478': 12,
  '8479': 12,
  '847A': 12,
  '847B': 12,
  '847C': 12,
  '847D': 12,
  '847E': 12,
  '847F': 12,
  '8480': 12,
  '8481': 11,
  '8482': 12,
  '8483': 12,
  '8484': 12,
  '8485': 12,
  '8486': 12,
  '8487': 12,
  '8488': 12,
  '8489': 12,
  '848A': 15,
  '848B': 12,
  '848C': 12,
  '848D': 12,
  '848E': 12,
  '848F': 12,
  '8490': 12,
  '8491': 13,
  '8492': 13,
  '8493': 13,
  '8494': 13,
  '8495': 13,
  '8496': 13,
  '8497': 13,
  '8498': 13,
  '8499': 13,
  '849A': 13,
  '849B': 13,
  '849C': 13,
  '849D': 13,
  '849E': 13,
  '849F': 13,
  '84A0': 13,
  '84A1': 13,
  '84A2': 12,
  '84A3': 13,
  '84A4': 13,
  '84A5': 13,
  '84A6': 13,
  '84A7': 13,
  '84A8': 13,
  '84A9': 13,
  '84AA': 13,
  '84AB': 12,
  '84AC': 13,
  '84AD': 13,
  '84AE': 13,
  '84AF': 13,
  '84B0': 13,
  '84B1': 13,
  '84B2': 13,
  '84B3': 13,
  '84B4': 13,
  '84B5': 13,
  '84B6': 13,
  '84B7': 13,
  '84B8': 13,
  '84B9': 13,
  '84BA': 13,
  '84BB': 13,
  '84BC': 13,
  '84BD': 13,
  '84BE': 12,
  '84BF': 13,
  '84C0': 13,
  '84C1': 13,
  '84C2': 13,
  '84C3': 12,
  '84C4': 13,
  '84C5': 13,
  '84C6': 13,
  '84C7': 12,
  '84C8': 11,
  '84C9': 13,
  '84CA': 13,
  '84CB': 13,
  '84CC': 13,
  '84CD': 13,
  '84CE': 13,
  '84CF': 13,
  '84D0': 13,
  '84D1': 13,
  '84D2': 13,
  '84D3': 13,
  '84D4': 13,
  '84D5': 13,
  '84D6': 13,
  '84D7': 13,
  '84D8': 13,
  '84D9': 13,
  '84DA': 12,
  '84DB': 13,
  '84DC': 13,
  '84DD': 13,
  '84DE': 16,
  '84DF': 13,
  '84E0': 13,
  '84E1': 13,
  '84E2': 13,
  '84E3': 13,
  '84E4': 13,
  '84E5': 13,
  '84E6': 13,
  '84E7': 13,
  '84E8': 13,
  '84E9': 13,
  '84EA': 13,
  '84EB': 13,
  '84EC': 13,
  '84ED': 14,
  '84EE': 13,
  '84EF': 14,
  '84F0': 14,
  '84F1': 12,
  '84F2': 14,
  '84F3': 14,
  '84F4': 14,
  '84F5': 14,
  '84F6': 14,
  '84F7': 14,
  '84F8': 14,
  '84F9': 15,
  '84FA': 14,
  '84FB': 14,
  '84FC': 14,
  '84FD': 13,
  '84FE': 14,
  '84FF': 14,
  '8500': 13,
  '8501': 14,
  '8502': 14,
  '8503': 15,
  '8504': 14,
  '8505': 14,
  '8506': 14,
  '8507': 12,
  '8508': 14,
  '8509': 14,
  '850A': 14,
  '850B': 14,
  '850C': 14,
  '850D': 14,
  '850E': 14,
  '850F': 14,
  '8510': 14,
  '8511': 14,
  '8512': 14,
  '8513': 14,
  '8514': 14,
  '8515': 14,
  '8516': 14,
  '8517': 14,
  '8518': 14,
  '8519': 14,
  '851A': 14,
  '851B': 14,
  '851C': 13,
  '851D': 14,
  '851E': 14,
  '851F': 14,
  '8520': 14,
  '8521': 14,
  '8522': 14,
  '8523': 14,
  '8524': 14,
  '8525': 14,
  '8526': 14,
  '8527': 14,
  '8528': 14,
  '8529': 14,
  '852A': 14,
  '852B': 14,
  '852C': 15,
  '852D': 13,
  '852E': 14,
  '852F': 13,
  '8530': 14,
  '8531': 13,
  '8532': 14,
  '8533': 14,
  '8534': 14,
  '8535': 14,
  '8536': 14,
  '8537': 14,
  '8538': 14,
  '8539': 14,
  '853A': 14,
  '853B': 14,
  '853C': 14,
  '853D': 14,
  '853E': 15,
  '853F': 15,
  '8540': 15,
  '8541': 15,
  '8542': 15,
  '8543': 15,
  '8544': 15,
  '8545': 15,
  '8546': 15,
  '8547': 15,
  '8548': 15,
  '8549': 15,
  '854A': 15,
  '854B': 15,
  '854C': 15,
  '854D': 15,
  '854E': 15,
  '854F': 14,
  '8550': 15,
  '8551': 15,
  '8552': 15,
  '8553': 15,
  '8554': 15,
  '8555': 15,
  '8556': 14,
  '8557': 16,
  '8558': 15,
  '8559': 15,
  '855A': 15,
  '855B': 15,
  '855C': 15,
  '855D': 15,
  '855E': 15,
  '855F': 15,
  '8560': 15,
  '8561': 15,
  '8562': 15,
  '8563': 15,
  '8564': 15,
  '8565': 15,
  '8566': 15,
  '8567': 15,
  '8568': 15,
  '8569': 15,
  '856A': 15,
  '856B': 15,
  '856C': 15,
  '856D': 16,
  '856E': 15,
  '856F': 14,
  '8570': 15,
  '8571': 15,
  '8572': 15,
  '8573': 15,
  '8574': 15,
  '8575': 15,
  '8576': 16,
  '8577': 16,
  '8578': 15,
  '8579': 16,
  '857A': 15,
  '857B': 16,
  '857C': 16,
  '857D': 16,
  '857E': 16,
  '857F': 16,
  '8580': 16,
  '8581': 15,
  '8582': 16,
  '8583': 16,
  '8584': 16,
  '8585': 16,
  '8586': 16,
  '8587': 16,
  '8588': 16,
  '8589': 16,
  '858A': 16,
  '858B': 16,
  '858C': 14,
  '858D': 16,
  '858E': 16,
  '858F': 16,
  '8590': 16,
  '8591': 16,
  '8592': 16,
  '8593': 16,
  '8594': 16,
  '8595': 16,
  '8596': 14,
  '8597': 16,
  '8598': 15,
  '8599': 16,
  '859A': 16,
  '859B': 16,
  '859C': 16,
  '859D': 16,
  '859E': 16,
  '859F': 16,
  '85A0': 16,
  '85A1': 15,
  '85A2': 16,
  '85A3': 16,
  '85A4': 16,
  '85A5': 16,
  '85A6': 16,
  '85A7': 16,
  '85A8': 16,
  '85A9': 16,
  '85AA': 16,
  '85AB': 16,
  '85AC': 16,
  '85AD': 16,
  '85AE': 16,
  '85AF': 16,
  '85B0': 17,
  '85B1': 17,
  '85B2': 17,
  '85B3': 16,
  '85B4': 17,
  '85B5': 17,
  '85B6': 17,
  '85B7': 17,
  '85B8': 17,
  '85B9': 17,
  '85BA': 17,
  '85BB': 17,
  '85BC': 17,
  '85BD': 16,
  '85BE': 17,
  '85BF': 17,
  '85C0': 17,
  '85C1': 17,
  '85C2': 17,
  '85C3': 17,
  '85C4': 17,
  '85C5': 17,
  '85C6': 17,
  '85C7': 16,
  '85C8': 17,
  '85C9': 17,
  '85CA': 17,
  '85CB': 17,
  '85CC': 17,
  '85CD': 17,
  '85CE': 17,
  '85CF': 17,
  '85D0': 17,
  '85D1': 17,
  '85D2': 17,
  '85D3': 17,
  '85D4': 21,
  '85D5': 18,
  '85D6': 18,
  '85D7': 17,
  '85D8': 18,
  '85D9': 18,
  '85DA': 18,
  '85DB': 18,
  '85DC': 18,
  '85DD': 18,
  '85DE': 18,
  '85DF': 18,
  '85E0': 18,
  '85E1': 17,
  '85E2': 18,
  '85E3': 18,
  '85E4': 18,
  '85E5': 18,
  '85E6': 18,
  '85E7': 18,
  '85E8': 18,
  '85E9': 18,
  '85EA': 18,
  '85EB': 18,
  '85EC': 17,
  '85ED': 18,
  '85EE': 19,
  '85EF': 18,
  '85F0': 18,
  '85F1': 17,
  '85F2': 18,
  '85F3': 18,
  '85F4': 18,
  '85F5': 18,
  '85F6': 19,
  '85F7': 18,
  '85F8': 18,
  '85F9': 19,
  '85FA': 19,
  '85FB': 19,
  '85FC': 19,
  '85FD': 19,
  '85FE': 19,
  '85FF': 19,
  '8600': 19,
  '8601': 19,
  '8602': 19,
  '8603': 19,
  '8604': 19,
  '8605': 19,
  '8606': 19,
  '8607': 19,
  '8608': 19,
  '8609': 19,
  '860A': 19,
  '860B': 19,
  '860C': 20,
  '860D': 19,
  '860E': 19,
  '860F': 19,
  '8610': 19,
  '8611': 19,
  '8612': 19,
  '8613': 19,
  '8614': 19,
  '8615': 19,
  '8616': 20,
  '8617': 20,
  '8618': 20,
  '8619': 20,
  '861A': 20,
  '861B': 20,
  '861C': 20,
  '861D': 20,
  '861E': 20,
  '861F': 19,
  '8620': 20,
  '8621': 20,
  '8622': 19,
  '8623': 19,
  '8624': 20,
  '8625': 20,
  '8626': 20,
  '8627': 19,
  '8628': 20,
  '8629': 20,
  '862A': 20,
  '862B': 20,
  '862C': 21,
  '862D': 20,
  '862E': 20,
  '862F': 20,
  '8630': 20,
  '8631': 22,
  '8632': 21,
  '8633': 20,
  '8634': 21,
  '8635': 21,
  '8636': 20,
  '8637': 21,
  '8638': 22,
  '8639': 22,
  '863A': 21,
  '863B': 22,
  '863C': 22,
  '863D': 22,
  '863E': 22,
  '863F': 22,
  '8640': 22,
  '8641': 24,
  '8642': 24,
  '8643': 23,
  '8644': 26,
  '8645': 23,
  '8646': 24,
  '8647': 23,
  '8648': 24,
  '8649': 24,
  '864A': 26,
  '864B': 29,
  '864C': 27,
  '864D': 6,
  '864E': 8,
  '864F': 8,
  '8650': 9,
  '8651': 10,
  '8652': 10,
  '8653': 10,
  '8654': 10,
  '8655': 11,
  '8656': 11,
  '8657': 11,
  '8658': 11,
  '8659': 11,
  '865A': 11,
  '865B': 12,
  '865C': 13,
  '865D': 12,
  '865E': 13,
  '865F': 13,
  '8660': 14,
  '8661': 13,
  '8662': 15,
  '8663': 16,
  '8664': 16,
  '8665': 16,
  '8666': 16,
  '8667': 17,
  '8668': 17,
  '8669': 18,
  '866A': 26,
  '866B': 6,
  '866C': 7,
  '866D': 8,
  '866E': 8,
  '866F': 8,
  '8670': 8,
  '8671': 8,
  '8672': 8,
  '8673': 9,
  '8674': 9,
  '8675': 9,
  '8676': 9,
  '8677': 9,
  '8678': 9,
  '8679': 9,
  '867A': 9,
  '867B': 9,
  '867C': 9,
  '867D': 9,
  '867E': 9,
  '867F': 9,
  '8680': 9,
  '8681': 9,
  '8682': 9,
  '8683': 9,
  '8684': 10,
  '8685': 10,
  '8686': 10,
  '8687': 10,
  '8688': 10,
  '8689': 10,
  '868A': 10,
  '868B': 10,
  '868C': 10,
  '868D': 10,
  '868E': 10,
  '868F': 10,
  '8690': 10,
  '8691': 10,
  '8692': 10,
  '8693': 10,
  '8694': 10,
  '8695': 10,
  '8696': 10,
  '8697': 10,
  '8698': 10,
  '8699': 10,
  '869A': 10,
  '869B': 10,
  '869C': 10,
  '869D': 10,
  '869E': 10,
  '869F': 10,
  '86A0': 10,
  '86A1': 10,
  '86A2': 10,
  '86A3': 10,
  '86A4': 9,
  '86A5': 10,
  '86A6': 10,
  '86A7': 10,
  '86A8': 10,
  '86A9': 10,
  '86AA': 10,
  '86AB': 11,
  '86AC': 10,
  '86AD': 11,
  '86AE': 11,
  '86AF': 11,
  '86B0': 11,
  '86B1': 11,
  '86B2': 11,
  '86B3': 11,
  '86B4': 11,
  '86B5': 11,
  '86B6': 11,
  '86B7': 10,
  '86B8': 11,
  '86B9': 11,
  '86BA': 11,
  '86BB': 11,
  '86BC': 11,
  '86BD': 11,
  '86BE': 11,
  '86BF': 11,
  '86C0': 11,
  '86C1': 11,
  '86C2': 11,
  '86C3': 11,
  '86C4': 11,
  '86C5': 11,
  '86C6': 11,
  '86C7': 11,
  '86C8': 11,
  '86C9': 11,
  '86CA': 11,
  '86CB': 11,
  '86CC': 11,
  '86CD': 11,
  '86CE': 11,
  '86CF': 11,
  '86D0': 12,
  '86D1': 12,
  '86D2': 12,
  '86D3': 12,
  '86D4': 12,
  '86D5': 12,
  '86D6': 13,
  '86D7': 12,
  '86D8': 12,
  '86D9': 12,
  '86DA': 12,
  '86DB': 12,
  '86DC': 12,
  '86DD': 12,
  '86DE': 12,
  '86DF': 12,
  '86E0': 12,
  '86E1': 12,
  '86E2': 12,
  '86E3': 12,
  '86E4': 12,
  '86E5': 12,
  '86E6': 12,
  '86E7': 12,
  '86E8': 12,
  '86E9': 12,
  '86EA': 12,
  '86EB': 12,
  '86EC': 12,
  '86ED': 12,
  '86EE': 12,
  '86EF': 12,
  '86F0': 12,
  '86F1': 12,
  '86F2': 12,
  '86F3': 12,
  '86F4': 12,
  '86F5': 13,
  '86F6': 13,
  '86F7': 13,
  '86F8': 13,
  '86F9': 13,
  '86FA': 13,
  '86FB': 13,
  '86FC': 13,
  '86FD': 13,
  '86FE': 13,
  '86FF': 13,
  '8700': 13,
  '8701': 13,
  '8702': 13,
  '8703': 13,
  '8704': 13,
  '8705': 13,
  '8706': 13,
  '8707': 13,
  '8708': 13,
  '8709': 13,
  '870A': 13,
  '870B': 13,
  '870C': 13,
  '870D': 13,
  '870E': 13,
  '870F': 13,
  '8710': 13,
  '8711': 12,
  '8712': 12,
  '8713': 12,
  '8714': 13,
  '8715': 13,
  '8716': 13,
  '8717': 13,
  '8718': 14,
  '8719': 14,
  '871A': 14,
  '871B': 14,
  '871C': 14,
  '871D': 14,
  '871E': 14,
  '871F': 14,
  '8720': 14,
  '8721': 14,
  '8722': 14,
  '8723': 13,
  '8724': 14,
  '8725': 14,
  '8726': 14,
  '8727': 14,
  '8728': 14,
  '8729': 14,
  '872A': 14,
  '872B': 14,
  '872C': 14,
  '872D': 14,
  '872E': 14,
  '872F': 14,
  '8730': 14,
  '8731': 14,
  '8732': 14,
  '8733': 14,
  '8734': 14,
  '8735': 15,
  '8736': 14,
  '8737': 14,
  '8738': 14,
  '8739': 13,
  '873A': 14,
  '873B': 14,
  '873C': 14,
  '873D': 14,
  '873E': 14,
  '873F': 14,
  '8740': 14,
  '8741': 14,
  '8742': 14,
  '8743': 14,
  '8744': 14,
  '8745': 14,
  '8746': 13,
  '8747': 14,
  '8748': 14,
  '8749': 14,
  '874A': 14,
  '874B': 14,
  '874C': 15,
  '874D': 13,
  '874E': 15,
  '874F': 15,
  '8750': 15,
  '8751': 15,
  '8752': 15,
  '8753': 15,
  '8754': 15,
  '8755': 14,
  '8756': 15,
  '8757': 15,
  '8758': 15,
  '8759': 15,
  '875A': 15,
  '875B': 15,
  '875C': 15,
  '875D': 15,
  '875E': 15,
  '875F': 15,
  '8760': 15,
  '8761': 15,
  '8762': 15,
  '8763': 15,
  '8764': 15,
  '8765': 15,
  '8766': 15,
  '8767': 14,
  '8768': 15,
  '8769': 15,
  '876A': 15,
  '876B': 14,
  '876C': 15,
  '876D': 15,
  '876E': 15,
  '876F': 15,
  '8770': 15,
  '8771': 15,
  '8772': 15,
  '8773': 15,
  '8774': 15,
  '8775': 15,
  '8776': 15,
  '8777': 15,
  '8778': 14,
  '8779': 15,
  '877A': 15,
  '877B': 15,
  '877C': 15,
  '877D': 15,
  '877E': 15,
  '877F': 15,
  '8780': 15,
  '8781': 15,
  '8782': 14,
  '8783': 16,
  '8784': 16,
  '8785': 16,
  '8786': 15,
  '8787': 16,
  '8788': 16,
  '8789': 16,
  '878A': 16,
  '878B': 15,
  '878C': 16,
  '878D': 16,
  '878E': 16,
  '878F': 16,
  '8790': 16,
  '8791': 16,
  '8792': 16,
  '8793': 16,
  '8794': 16,
  '8795': 16,
  '8796': 15,
  '8797': 16,
  '8798': 16,
  '8799': 16,
  '879A': 16,
  '879B': 16,
  '879C': 16,
  '879D': 15,
  '879E': 16,
  '879F': 16,
  '87A0': 16,
  '87A1': 16,
  '87A2': 16,
  '87A3': 16,
  '87A4': 16,
  '87A5': 16,
  '87A6': 16,
  '87A7': 16,
  '87A8': 16,
  '87A9': 16,
  '87AA': 17,
  '87AB': 17,
  '87AC': 17,
  '87AD': 16,
  '87AE': 17,
  '87AF': 16,
  '87B0': 17,
  '87B1': 17,
  '87B2': 17,
  '87B3': 17,
  '87B4': 16,
  '87B5': 17,
  '87B6': 16,
  '87B7': 17,
  '87B8': 17,
  '87B9': 17,
  '87BA': 17,
  '87BB': 17,
  '87BC': 17,
  '87BD': 17,
  '87BE': 17,
  '87BF': 17,
  '87C0': 17,
  '87C1': 17,
  '87C2': 17,
  '87C3': 17,
  '87C4': 17,
  '87C5': 17,
  '87C6': 16,
  '87C7': 16,
  '87C8': 17,
  '87C9': 17,
  '87CA': 17,
  '87CB': 17,
  '87CC': 17,
  '87CD': 17,
  '87CE': 17,
  '87CF': 17,
  '87D0': 17,
  '87D1': 17,
  '87D2': 16,
  '87D3': 17,
  '87D4': 18,
  '87D5': 19,
  '87D6': 18,
  '87D7': 18,
  '87D8': 18,
  '87D9': 18,
  '87DA': 18,
  '87DB': 18,
  '87DC': 18,
  '87DD': 17,
  '87DE': 17,
  '87DF': 18,
  '87E0': 18,
  '87E1': 18,
  '87E2': 18,
  '87E3': 18,
  '87E4': 18,
  '87E5': 17,
  '87E6': 18,
  '87E7': 18,
  '87E8': 18,
  '87E9': 18,
  '87EA': 18,
  '87EB': 18,
  '87EC': 18,
  '87ED': 18,
  '87EE': 18,
  '87EF': 18,
  '87F0': 19,
  '87F1': 18,
  '87F2': 18,
  '87F3': 18,
  '87F4': 18,
  '87F5': 18,
  '87F6': 19,
  '87F7': 19,
  '87F8': 19,
  '87F9': 19,
  '87FA': 19,
  '87FB': 19,
  '87FC': 18,
  '87FD': 18,
  '87FE': 19,
  '87FF': 19,
  '8800': 19,
  '8801': 17,
  '8802': 18,
  '8803': 19,
  '8804': 18,
  '8805': 19,
  '8806': 18,
  '8807': 18,
  '8808': 19,
  '8809': 19,
  '880A': 19,
  '880B': 19,
  '880C': 19,
  '880D': 19,
  '880E': 17,
  '880F': 19,
  '8810': 20,
  '8811': 20,
  '8812': 20,
  '8813': 19,
  '8814': 20,
  '8815': 20,
  '8816': 19,
  '8817': 20,
  '8818': 20,
  '8819': 20,
  '881A': 20,
  '881B': 20,
  '881C': 21,
  '881D': 21,
  '881E': 19,
  '881F': 21,
  '8820': 21,
  '8821': 21,
  '8822': 21,
  '8823': 20,
  '8824': 21,
  '8825': 22,
  '8826': 22,
  '8827': 22,
  '8828': 22,
  '8829': 21,
  '882A': 22,
  '882B': 21,
  '882C': 22,
  '882D': 22,
  '882E': 23,
  '882F': 23,
  '8830': 23,
  '8831': 23,
  '8832': 23,
  '8833': 23,
  '8834': 22,
  '8835': 24,
  '8836': 24,
  '8837': 24,
  '8838': 23,
  '8839': 24,
  '883A': 24,
  '883B': 25,
  '883C': 26,
  '883D': 27,
  '883E': 27,
  '883F': 27,
  '8840': 6,
  '8841': 9,
  '8842': 9,
  '8843': 10,
  '8844': 10,
  '8845': 11,
  '8846': 12,
  '8847': 12,
  '8848': 12,
  '8849': 12,
  '884A': 20,
  '884B': 24,
  '884C': 6,
  '884D': 9,
  '884E': 9,
  '884F': 10,
  '8850': 10,
  '8851': 11,
  '8852': 11,
  '8853': 11,
  '8854': 11,
  '8855': 12,
  '8856': 12,
  '8857': 12,
  '8858': 13,
  '8859': 13,
  '885A': 15,
  '885B': 15,
  '885C': 15,
  '885D': 15,
  '885E': 16,
  '885F': 16,
  '8860': 16,
  '8861': 16,
  '8862': 24,
  '8863': 6,
  '8864': 5,
  '8865': 7,
  '8866': 8,
  '8867': 8,
  '8868': 8,
  '8869': 8,
  '886A': 8,
  '886B': 8,
  '886C': 8,
  '886D': 9,
  '886E': 10,
  '886F': 9,
  '8870': 10,
  '8871': 8,
  '8872': 9,
  '8873': 9,
  '8874': 9,
  '8875': 9,
  '8876': 9,
  '8877': 10,
  '8878': 9,
  '8879': 9,
  '887A': 10,
  '887B': 9,
  '887C': 9,
  '887D': 9,
  '887E': 10,
  '887F': 9,
  '8880': 9,
  '8881': 10,
  '8882': 9,
  '8883': 10,
  '8884': 9,
  '8885': 10,
  '8886': 9,
  '8887': 9,
  '8888': 11,
  '8889': 10,
  '888A': 10,
  '888B': 11,
  '888C': 11,
  '888D': 10,
  '888E': 10,
  '888F': 10,
  '8890': 10,
  '8891': 10,
  '8892': 10,
  '8893': 10,
  '8894': 10,
  '8895': 10,
  '8896': 10,
  '8897': 10,
  '8898': 10,
  '8899': 10,
  '889A': 10,
  '889B': 10,
  '889C': 10,
  '889D': 10,
  '889E': 11,
  '889F': 10,
  '88A0': 11,
  '88A1': 10,
  '88A2': 10,
  '88A3': 10,
  '88A4': 11,
  '88A5': 10,
  '88A6': 10,
  '88A7': 10,
  '88A8': 10,
  '88A9': 10,
  '88AA': 10,
  '88AB': 10,
  '88AC': 11,
  '88AD': 11,
  '88AE': 10,
  '88AF': 10,
  '88B0': 11,
  '88B1': 11,
  '88B2': 12,
  '88B3': 11,
  '88B4': 11,
  '88B5': 11,
  '88B6': 11,
  '88B7': 11,
  '88B8': 11,
  '88B9': 11,
  '88BA': 11,
  '88BB': 11,
  '88BC': 11,
  '88BD': 11,
  '88BE': 11,
  '88BF': 11,
  '88C0': 11,
  '88C1': 12,
  '88C2': 12,
  '88C3': 11,
  '88C4': 11,
  '88C5': 12,
  '88C6': 11,
  '88C7': 12,
  '88C8': 11,
  '88C9': 11,
  '88CA': 13,
  '88CB': 12,
  '88CC': 12,
  '88CD': 12,
  '88CE': 12,
  '88CF': 13,
  '88D0': 12,
  '88D1': 12,
  '88D2': 12,
  '88D3': 12,
  '88D4': 13,
  '88D5': 12,
  '88D6': 12,
  '88D7': 12,
  '88D8': 13,
  '88D9': 12,
  '88DA': 13,
  '88DB': 13,
  '88DC': 12,
  '88DD': 13,
  '88DE': 12,
  '88DF': 13,
  '88E0': 13,
  '88E1': 12,
  '88E2': 12,
  '88E3': 12,
  '88E4': 12,
  '88E5': 12,
  '88E6': 14,
  '88E7': 13,
  '88E8': 13,
  '88E9': 13,
  '88EA': 13,
  '88EB': 14,
  '88EC': 13,
  '88ED': 13,
  '88EE': 13,
  '88EF': 13,
  '88F0': 13,
  '88F1': 13,
  '88F2': 13,
  '88F3': 14,
  '88F4': 14,
  '88F5': 14,
  '88F6': 13,
  '88F7': 13,
  '88F8': 13,
  '88F9': 14,
  '88FA': 13,
  '88FB': 14,
  '88FC': 13,
  '88FD': 14,
  '88FE': 13,
  '88FF': 13,
  '8900': 13,
  '8901': 12,
  '8902': 13,
  '8903': 13,
  '8904': 13,
  '8905': 14,
  '8906': 14,
  '8907': 14,
  '8908': 14,
  '8909': 14,
  '890A': 14,
  '890B': 14,
  '890C': 14,
  '890D': 14,
  '890E': 14,
  '890F': 14,
  '8910': 14,
  '8911': 14,
  '8912': 15,
  '8913': 14,
  '8914': 14,
  '8915': 14,
  '8916': 14,
  '8917': 14,
  '8918': 14,
  '8919': 14,
  '891A': 13,
  '891B': 14,
  '891C': 15,
  '891D': 14,
  '891E': 14,
  '891F': 15,
  '8920': 15,
  '8921': 14,
  '8922': 15,
  '8923': 15,
  '8924': 15,
  '8925': 15,
  '8926': 15,
  '8927': 16,
  '8928': 14,
  '8929': 16,
  '892A': 14,
  '892B': 15,
  '892C': 15,
  '892D': 16,
  '892E': 16,
  '892F': 15,
  '8930': 16,
  '8931': 16,
  '8932': 15,
  '8933': 15,
  '8934': 15,
  '8935': 15,
  '8936': 16,
  '8937': 16,
  '8938': 16,
  '8939': 16,
  '893A': 17,
  '893B': 17,
  '893C': 17,
  '893D': 17,
  '893E': 16,
  '893F': 16,
  '8940': 16,
  '8941': 17,
  '8942': 16,
  '8943': 16,
  '8944': 17,
  '8945': 15,
  '8946': 17,
  '8947': 17,
  '8948': 17,
  '8949': 17,
  '894A': 17,
  '894B': 17,
  '894C': 17,
  '894D': 17,
  '894E': 17,
  '894F': 17,
  '8950': 16,
  '8951': 17,
  '8952': 16,
  '8953': 17,
  '8954': 16,
  '8955': 17,
  '8956': 17,
  '8957': 18,
  '8958': 18,
  '8959': 18,
  '895A': 17,
  '895B': 18,
  '895C': 18,
  '895D': 18,
  '895E': 19,
  '895F': 18,
  '8960': 18,
  '8961': 18,
  '8962': 18,
  '8963': 19,
  '8964': 19,
  '8965': 19,
  '8966': 19,
  '8967': 19,
  '8968': 20,
  '8969': 20,
  '896A': 19,
  '896B': 20,
  '896C': 20,
  '896D': 20,
  '896E': 20,
  '896F': 21,
  '8970': 21,
  '8971': 21,
  '8972': 22,
  '8973': 22,
  '8974': 22,
  '8975': 23,
  '8976': 22,
  '8977': 22,
  '8978': 24,
  '8979': 24,
  '897A': 23,
  '897B': 24,
  '897C': 23,
  '897D': 25,
  '897E': 6,
  '897F': 6,
  '8980': 6,
  '8981': 9,
  '8982': 10,
  '8983': 12,
  '8984': 12,
  '8985': 13,
  '8986': 18,
  '8987': 19,
  '8988': 19,
  '8989': 23,
  '898A': 25,
  '898B': 7,
  '898C': 9,
  '898D': 10,
  '898E': 10,
  '898F': 11,
  '8990': 11,
  '8991': 11,
  '8992': 11,
  '8993': 11,
  '8994': 11,
  '8995': 12,
  '8996': 11,
  '8997': 12,
  '8998': 12,
  '8999': 12,
  '899A': 12,
  '899B': 13,
  '899C': 13,
  '899D': 14,
  '899E': 14,
  '899F': 14,
  '89A0': 14,
  '89A1': 14,
  '89A2': 15,
  '89A3': 15,
  '89A4': 15,
  '89A5': 15,
  '89A6': 16,
  '89A7': 16,
  '89A8': 16,
  '89A9': 15,
  '89AA': 16,
  '89AB': 17,
  '89AC': 17,
  '89AD': 17,
  '89AE': 17,
  '89AF': 17,
  '89B0': 18,
  '89B1': 18,
  '89B2': 18,
  '89B3': 18,
  '89B4': 19,
  '89B5': 19,
  '89B6': 19,
  '89B7': 18,
  '89B8': 19,
  '89B9': 20,
  '89BA': 20,
  '89BB': 20,
  '89BC': 21,
  '89BD': 21,
  '89BE': 22,
  '89BF': 22,
  '89C0': 24,
  '89C1': 4,
  '89C2': 6,
  '89C3': 7,
  '89C4': 8,
  '89C5': 8,
  '89C6': 8,
  '89C7': 9,
  '89C8': 9,
  '89C9': 9,
  '89CA': 10,
  '89CB': 11,
  '89CC': 12,
  '89CD': 12,
  '89CE': 13,
  '89CF': 14,
  '89D0': 15,
  '89D1': 15,
  '89D2': 7,
  '89D3': 9,
  '89D4': 9,
  '89D5': 11,
  '89D6': 11,
  '89D7': 11,
  '89D8': 11,
  '89D9': 10,
  '89DA': 12,
  '89DB': 12,
  '89DC': 13,
  '89DD': 12,
  '89DE': 12,
  '89DF': 13,
  '89E0': 13,
  '89E1': 13,
  '89E2': 13,
  '89E3': 13,
  '89E4': 13,
  '89E5': 13,
  '89E6': 13,
  '89E7': 13,
  '89E8': 14,
  '89E9': 14,
  '89EA': 14,
  '89EB': 14,
  '89EC': 15,
  '89ED': 15,
  '89EE': 15,
  '89EF': 15,
  '89F0': 15,
  '89F1': 16,
  '89F2': 17,
  '89F3': 17,
  '89F4': 18,
  '89F5': 18,
  '89F6': 19,
  '89F7': 20,
  '89F8': 20,
  '89F9': 19,
  '89FA': 21,
  '89FB': 22,
  '89FC': 21,
  '89FD': 22,
  '89FE': 23,
  '89FF': 25,
  '8A00': 7,
  '8A01': 7,
  '8A02': 9,
  '8A03': 9,
  '8A04': 9,
  '8A05': 9,
  '8A06': 9,
  '8A07': 9,
  '8A08': 9,
  '8A09': 10,
  '8A0A': 10,
  '8A0B': 10,
  '8A0C': 10,
  '8A0D': 10,
  '8A0E': 10,
  '8A0F': 10,
  '8A10': 10,
  '8A11': 10,
  '8A12': 10,
  '8A13': 10,
  '8A14': 10,
  '8A15': 10,
  '8A16': 10,
  '8A17': 10,
  '8A18': 10,
  '8A19': 10,
  '8A1A': 10,
  '8A1B': 11,
  '8A1C': 11,
  '8A1D': 11,
  '8A1E': 11,
  '8A1F': 11,
  '8A20': 11,
  '8A21': 11,
  '8A22': 11,
  '8A23': 11,
  '8A24': 11,
  '8A25': 11,
  '8A26': 11,
  '8A27': 11,
  '8A28': 11,
  '8A29': 11,
  '8A2A': 11,
  '8A2B': 11,
  '8A2C': 11,
  '8A2D': 11,
  '8A2E': 11,
  '8A2F': 10,
  '8A30': 11,
  '8A31': 11,
  '8A32': 11,
  '8A33': 11,
  '8A34': 12,
  '8A35': 12,
  '8A36': 12,
  '8A37': 12,
  '8A38': 12,
  '8A39': 12,
  '8A3A': 12,
  '8A3B': 12,
  '8A3C': 12,
  '8A3D': 12,
  '8A3E': 13,
  '8A3F': 13,
  '8A40': 12,
  '8A41': 12,
  '8A42': 12,
  '8A43': 12,
  '8A44': 12,
  '8A45': 12,
  '8A46': 12,
  '8A47': 12,
  '8A48': 12,
  '8A49': 12,
  '8A4A': 12,
  '8A4B': 12,
  '8A4C': 12,
  '8A4D': 12,
  '8A4E': 11,
  '8A4F': 12,
  '8A50': 12,
  '8A51': 12,
  '8A52': 12,
  '8A53': 12,
  '8A54': 12,
  '8A55': 12,
  '8A56': 12,
  '8A57': 12,
  '8A58': 12,
  '8A59': 12,
  '8A5A': 12,
  '8A5B': 12,
  '8A5C': 12,
  '8A5D': 12,
  '8A5E': 12,
  '8A5F': 12,
  '8A60': 12,
  '8A61': 13,
  '8A62': 13,
  '8A63': 13,
  '8A64': 13,
  '8A65': 13,
  '8A66': 13,
  '8A67': 13,
  '8A68': 13,
  '8A69': 13,
  '8A6A': 13,
  '8A6B': 13,
  '8A6C': 13,
  '8A6D': 13,
  '8A6E': 13,
  '8A6F': 13,
  '8A70': 13,
  '8A71': 13,
  '8A72': 13,
  '8A73': 13,
  '8A74': 13,
  '8A75': 13,
  '8A76': 13,
  '8A77': 13,
  '8A78': 13,
  '8A79': 13,
  '8A7A': 13,
  '8A7B': 13,
  '8A7C': 13,
  '8A7D': 13,
  '8A7E': 13,
  '8A7F': 13,
  '8A80': 13,
  '8A81': 13,
  '8A82': 13,
  '8A83': 13,
  '8A84': 13,
  '8A85': 13,
  '8A86': 13,
  '8A87': 13,
  '8A88': 13,
  '8A89': 13,
  '8A8A': 13,
  '8A8B': 14,
  '8A8C': 14,
  '8A8D': 14,
  '8A8E': 14,
  '8A8F': 14,
  '8A90': 14,
  '8A91': 14,
  '8A92': 14,
  '8A93': 14,
  '8A94': 13,
  '8A95': 13,
  '8A96': 14,
  '8A97': 14,
  '8A98': 14,
  '8A99': 14,
  '8A9A': 14,
  '8A9B': 14,
  '8A9C': 14,
  '8A9D': 14,
  '8A9E': 14,
  '8A9F': 14,
  '8AA0': 13,
  '8AA1': 14,
  '8AA2': 14,
  '8AA3': 14,
  '8AA4': 14,
  '8AA5': 14,
  '8AA6': 14,
  '8AA7': 14,
  '8AA8': 14,
  '8AA9': 14,
  '8AAA': 14,
  '8AAB': 14,
  '8AAC': 14,
  '8AAD': 14,
  '8AAE': 14,
  '8AAF': 15,
  '8AB0': 15,
  '8AB1': 15,
  '8AB2': 15,
  '8AB3': 15,
  '8AB4': 15,
  '8AB5': 15,
  '8AB6': 15,
  '8AB7': 15,
  '8AB8': 15,
  '8AB9': 15,
  '8ABA': 15,
  '8ABB': 15,
  '8ABC': 15,
  '8ABD': 15,
  '8ABE': 15,
  '8ABF': 15,
  '8AC0': 15,
  '8AC1': 15,
  '8AC2': 15,
  '8AC3': 15,
  '8AC4': 15,
  '8AC5': 15,
  '8AC6': 15,
  '8AC7': 15,
  '8AC8': 15,
  '8AC9': 15,
  '8ACA': 15,
  '8ACB': 15,
  '8ACC': 15,
  '8ACD': 13,
  '8ACE': 15,
  '8ACF': 15,
  '8AD0': 15,
  '8AD1': 15,
  '8AD2': 15,
  '8AD3': 15,
  '8AD4': 15,
  '8AD5': 15,
  '8AD6': 15,
  '8AD7': 15,
  '8AD8': 15,
  '8AD9': 15,
  '8ADA': 15,
  '8ADB': 15,
  '8ADC': 16,
  '8ADD': 16,
  '8ADE': 16,
  '8ADF': 16,
  '8AE0': 16,
  '8AE1': 16,
  '8AE2': 16,
  '8AE3': 15,
  '8AE4': 16,
  '8AE5': 16,
  '8AE6': 16,
  '8AE7': 16,
  '8AE8': 16,
  '8AE9': 15,
  '8AEA': 16,
  '8AEB': 16,
  '8AEC': 16,
  '8AED': 16,
  '8AEE': 16,
  '8AEF': 16,
  '8AF0': 16,
  '8AF1': 16,
  '8AF2': 16,
  '8AF3': 16,
  '8AF4': 16,
  '8AF5': 16,
  '8AF6': 16,
  '8AF7': 16,
  '8AF8': 15,
  '8AF9': 16,
  '8AFA': 16,
  '8AFB': 16,
  '8AFC': 16,
  '8AFD': 16,
  '8AFE': 15,
  '8AFF': 16,
  '8B00': 16,
  '8B01': 16,
  '8B02': 16,
  '8B03': 16,
  '8B04': 17,
  '8B05': 17,
  '8B06': 17,
  '8B07': 17,
  '8B08': 17,
  '8B09': 16,
  '8B0A': 16,
  '8B0B': 17,
  '8B0C': 17,
  '8B0D': 17,
  '8B0E': 16,
  '8B0F': 16,
  '8B10': 17,
  '8B11': 17,
  '8B12': 17,
  '8B13': 17,
  '8B14': 16,
  '8B15': 17,
  '8B16': 17,
  '8B17': 17,
  '8B18': 17,
  '8B19': 17,
  '8B1A': 17,
  '8B1B': 17,
  '8B1C': 17,
  '8B1D': 17,
  '8B1E': 17,
  '8B1F': 17,
  '8B20': 17,
  '8B21': 17,
  '8B22': 17,
  '8B23': 18,
  '8B24': 18,
  '8B25': 18,
  '8B26': 18,
  '8B27': 17,
  '8B28': 17,
  '8B29': 17,
  '8B2A': 18,
  '8B2B': 18,
  '8B2C': 18,
  '8B2D': 18,
  '8B2E': 18,
  '8B2F': 18,
  '8B30': 17,
  '8B31': 18,
  '8B32': 18,
  '8B33': 18,
  '8B34': 18,
  '8B35': 18,
  '8B36': 18,
  '8B37': 17,
  '8B38': 17,
  '8B39': 18,
  '8B3A': 18,
  '8B3B': 18,
  '8B3C': 18,
  '8B3D': 19,
  '8B3E': 18,
  '8B3F': 19,
  '8B40': 18,
  '8B41': 17,
  '8B42': 19,
  '8B43': 18,
  '8B44': 19,
  '8B45': 21,
  '8B46': 19,
  '8B47': 18,
  '8B48': 19,
  '8B49': 19,
  '8B4A': 19,
  '8B4B': 19,
  '8B4C': 19,
  '8B4D': 20,
  '8B4E': 19,
  '8B4F': 19,
  '8B50': 19,
  '8B51': 19,
  '8B52': 19,
  '8B53': 19,
  '8B54': 19,
  '8B55': 19,
  '8B56': 19,
  '8B57': 19,
  '8B58': 19,
  '8B59': 19,
  '8B5A': 19,
  '8B5B': 19,
  '8B5C': 19,
  '8B5D': 20,
  '8B5E': 20,
  '8B5F': 20,
  '8B60': 20,
  '8B61': 20,
  '8B62': 19,
  '8B63': 20,
  '8B64': 20,
  '8B65': 20,
  '8B66': 19,
  '8B67': 20,
  '8B68': 20,
  '8B69': 20,
  '8B6A': 19,
  '8B6B': 20,
  '8B6C': 20,
  '8B6D': 20,
  '8B6E': 20,
  '8B6F': 20,
  '8B70': 20,
  '8B71': 20,
  '8B72': 20,
  '8B73': 21,
  '8B74': 20,
  '8B75': 21,
  '8B76': 21,
  '8B77': 20,
  '8B78': 21,
  '8B79': 21,
  '8B7A': 21,
  '8B7B': 21,
  '8B7C': 21,
  '8B7D': 20,
  '8B7E': 22,
  '8B7F': 22,
  '8B80': 22,
  '8B81': 21,
  '8B82': 21,
  '8B83': 22,
  '8B84': 22,
  '8B85': 22,
  '8B86': 22,
  '8B87': 23,
  '8B88': 23,
  '8B89': 22,
  '8B8A': 23,
  '8B8B': 23,
  '8B8C': 23,
  '8B8D': 23,
  '8B8E': 23,
  '8B8F': 23,
  '8B90': 23,
  '8B91': 24,
  '8B92': 24,
  '8B93': 24,
  '8B94': 23,
  '8B95': 24,
  '8B96': 24,
  '8B97': 25,
  '8B98': 25,
  '8B99': 24,
  '8B9A': 26,
  '8B9B': 25,
  '8B9C': 27,
  '8B9D': 26,
  '8B9E': 27,
  '8B9F': 29,
  '8BA0': 2,
  '8BA1': 4,
  '8BA2': 4,
  '8BA3': 4,
  '8BA4': 4,
  '8BA5': 4,
  '8BA6': 5,
  '8BA7': 5,
  '8BA8': 5,
  '8BA9': 5,
  '8BAA': 5,
  '8BAB': 5,
  '8BAC': 5,
  '8BAD': 5,
  '8BAE': 5,
  '8BAF': 5,
  '8BB0': 5,
  '8BB1': 5,
  '8BB2': 6,
  '8BB3': 6,
  '8BB4': 6,
  '8BB5': 6,
  '8BB6': 6,
  '8BB7': 6,
  '8BB8': 6,
  '8BB9': 6,
  '8BBA': 6,
  '8BBB': 6,
  '8BBC': 6,
  '8BBD': 6,
  '8BBE': 6,
  '8BBF': 6,
  '8BC0': 6,
  '8BC1': 7,
  '8BC2': 7,
  '8BC3': 7,
  '8BC4': 7,
  '8BC5': 7,
  '8BC6': 7,
  '8BC7': 7,
  '8BC8': 7,
  '8BC9': 7,
  '8BCA': 7,
  '8BCB': 7,
  '8BCC': 7,
  '8BCD': 7,
  '8BCE': 7,
  '8BCF': 7,
  '8BD0': 7,
  '8BD1': 7,
  '8BD2': 7,
  '8BD3': 8,
  '8BD4': 8,
  '8BD5': 8,
  '8BD6': 8,
  '8BD7': 8,
  '8BD8': 8,
  '8BD9': 8,
  '8BDA': 8,
  '8BDB': 8,
  '8BDC': 8,
  '8BDD': 8,
  '8BDE': 8,
  '8BDF': 8,
  '8BE0': 8,
  '8BE1': 8,
  '8BE2': 8,
  '8BE3': 8,
  '8BE4': 8,
  '8BE5': 8,
  '8BE6': 8,
  '8BE7': 8,
  '8BE8': 8,
  '8BE9': 8,
  '8BEA': 9,
  '8BEB': 9,
  '8BEC': 9,
  '8BED': 9,
  '8BEE': 9,
  '8BEF': 9,
  '8BF0': 9,
  '8BF1': 9,
  '8BF2': 9,
  '8BF3': 9,
  '8BF4': 9,
  '8BF5': 9,
  '8BF6': 9,
  '8BF7': 10,
  '8BF8': 10,
  '8BF9': 10,
  '8BFA': 10,
  '8BFB': 10,
  '8BFC': 10,
  '8BFD': 10,
  '8BFE': 10,
  '8BFF': 10,
  '8C00': 10,
  '8C01': 10,
  '8C02': 10,
  '8C03': 10,
  '8C04': 10,
  '8C05': 10,
  '8C06': 10,
  '8C07': 10,
  '8C08': 10,
  '8C09': 10,
  '8C0A': 10,
  '8C0B': 11,
  '8C0C': 11,
  '8C0D': 11,
  '8C0E': 11,
  '8C0F': 11,
  '8C10': 11,
  '8C11': 11,
  '8C12': 11,
  '8C13': 11,
  '8C14': 11,
  '8C15': 11,
  '8C16': 11,
  '8C17': 11,
  '8C18': 11,
  '8C19': 11,
  '8C1A': 11,
  '8C1B': 11,
  '8C1C': 11,
  '8C1D': 11,
  '8C1E': 11,
  '8C1F': 12,
  '8C20': 12,
  '8C21': 12,
  '8C22': 12,
  '8C23': 12,
  '8C24': 12,
  '8C25': 12,
  '8C26': 12,
  '8C27': 12,
  '8C28': 13,
  '8C29': 13,
  '8C2A': 13,
  '8C2B': 13,
  '8C2C': 13,
  '8C2D': 14,
  '8C2E': 14,
  '8C2F': 14,
  '8C30': 14,
  '8C31': 14,
  '8C32': 14,
  '8C33': 15,
  '8C34': 15,
  '8C35': 15,
  '8C36': 19,
  '8C37': 7,
  '8C38': 10,
  '8C39': 11,
  '8C3A': 11,
  '8C3B': 11,
  '8C3C': 13,
  '8C3D': 14,
  '8C3E': 15,
  '8C3F': 17,
  '8C40': 17,
  '8C41': 17,
  '8C42': 18,
  '8C43': 18,
  '8C44': 22,
  '8C45': 23,
  '8C46': 7,
  '8C47': 10,
  '8C48': 10,
  '8C49': 11,
  '8C4A': 13,
  '8C4B': 13,
  '8C4C': 15,
  '8C4D': 15,
  '8C4E': 15,
  '8C4F': 17,
  '8C50': 18,
  '8C51': 20,
  '8C52': 25,
  '8C53': 27,
  '8C54': 28,
  '8C55': 7,
  '8C56': 8,
  '8C57': 10,
  '8C58': 11,
  '8C59': 11,
  '8C5A': 11,
  '8C5B': 11,
  '8C5C': 11,
  '8C5D': 11,
  '8C5E': 12,
  '8C5F': 12,
  '8C60': 12,
  '8C61': 11,
  '8C62': 13,
  '8C63': 13,
  '8C64': 13,
  '8C65': 13,
  '8C66': 13,
  '8C67': 14,
  '8C68': 14,
  '8C69': 14,
  '8C6A': 14,
  '8C6B': 15,
  '8C6C': 15,
  '8C6D': 16,
  '8C6E': 16,
  '8C6F': 17,
  '8C70': 17,
  '8C71': 16,
  '8C72': 17,
  '8C73': 17,
  '8C74': 18,
  '8C75': 18,
  '8C76': 19,
  '8C77': 19,
  '8C78': 7,
  '8C79': 10,
  '8C7A': 10,
  '8C7B': 10,
  '8C7C': 11,
  '8C7D': 11,
  '8C7E': 12,
  '8C7F': 12,
  '8C80': 12,
  '8C81': 12,
  '8C82': 12,
  '8C83': 12,
  '8C84': 13,
  '8C85': 13,
  '8C86': 13,
  '8C87': 13,
  '8C88': 13,
  '8C89': 13,
  '8C8A': 13,
  '8C8B': 14,
  '8C8C': 14,
  '8C8D': 14,
  '8C8E': 15,
  '8C8F': 15,
  '8C90': 16,
  '8C91': 16,
  '8C92': 16,
  '8C93': 15,
  '8C94': 17,
  '8C95': 17,
  '8C96': 17,
  '8C97': 18,
  '8C98': 17,
  '8C99': 18,
  '8C9A': 19,
  '8C9B': 24,
  '8C9C': 27,
  '8C9D': 7,
  '8C9E': 9,
  '8C9F': 9,
  '8CA0': 9,
  '8CA1': 10,
  '8CA2': 10,
  '8CA3': 10,
  '8CA4': 10,
  '8CA5': 11,
  '8CA6': 11,
  '8CA7': 11,
  '8CA8': 11,
  '8CA9': 11,
  '8CAA': 11,
  '8CAB': 11,
  '8CAC': 11,
  '8CAD': 11,
  '8CAE': 11,
  '8CAF': 12,
  '8CB0': 12,
  '8CB1': 12,
  '8CB2': 13,
  '8CB3': 12,
  '8CB4': 12,
  '8CB5': 12,
  '8CB6': 11,
  '8CB7': 12,
  '8CB8': 12,
  '8CB9': 12,
  '8CBA': 12,
  '8CBB': 12,
  '8CBC': 12,
  '8CBD': 12,
  '8CBE': 12,
  '8CBF': 12,
  '8CC0': 12,
  '8CC1': 12,
  '8CC2': 13,
  '8CC3': 13,
  '8CC4': 13,
  '8CC5': 13,
  '8CC6': 13,
  '8CC7': 13,
  '8CC8': 13,
  '8CC9': 13,
  '8CCA': 13,
  '8CCB': 13,
  '8CCC': 13,
  '8CCD': 13,
  '8CCE': 13,
  '8CCF': 14,
  '8CD0': 14,
  '8CD1': 14,
  '8CD2': 14,
  '8CD3': 14,
  '8CD4': 14,
  '8CD5': 14,
  '8CD6': 14,
  '8CD7': 14,
  '8CD8': 14,
  '8CD9': 15,
  '8CDA': 15,
  '8CDB': 15,
  '8CDC': 15,
  '8CDD': 15,
  '8CDE': 15,
  '8CDF': 15,
  '8CE0': 15,
  '8CE1': 15,
  '8CE2': 15,
  '8CE3': 15,
  '8CE4': 15,
  '8CE5': 15,
  '8CE6': 15,
  '8CE7': 15,
  '8CE8': 15,
  '8CE9': 15,
  '8CEA': 15,
  '8CEB': 15,
  '8CEC': 15,
  '8CED': 15,
  '8CEE': 16,
  '8CEF': 16,
  '8CF0': 16,
  '8CF1': 16,
  '8CF2': 16,
  '8CF3': 16,
  '8CF4': 16,
  '8CF5': 16,
  '8CF6': 17,
  '8CF7': 17,
  '8CF8': 17,
  '8CF9': 17,
  '8CFA': 17,
  '8CFB': 17,
  '8CFC': 17,
  '8CFD': 17,
  '8CFE': 18,
  '8CFF': 18,
  '8D00': 18,
  '8D01': 18,
  '8D02': 18,
  '8D03': 18,
  '8D04': 18,
  '8D05': 17,
  '8D06': 19,
  '8D07': 19,
  '8D08': 19,
  '8D09': 19,
  '8D0A': 19,
  '8D0B': 19,
  '8D0C': 19,
  '8D0D': 20,
  '8D0E': 19,
  '8D0F': 20,
  '8D10': 21,
  '8D11': 21,
  '8D12': 21,
  '8D13': 21,
  '8D14': 21,
  '8D15': 22,
  '8D16': 22,
  '8D17': 22,
  '8D18': 22,
  '8D19': 23,
  '8D1A': 23,
  '8D1B': 24,
  '8D1C': 24,
  '8D1D': 4,
  '8D1E': 6,
  '8D1F': 6,
  '8D20': 6,
  '8D21': 7,
  '8D22': 7,
  '8D23': 8,
  '8D24': 8,
  '8D25': 8,
  '8D26': 8,
  '8D27': 8,
  '8D28': 8,
  '8D29': 8,
  '8D2A': 8,
  '8D2B': 8,
  '8D2C': 8,
  '8D2D': 8,
  '8D2E': 8,
  '8D2F': 8,
  '8D30': 9,
  '8D31': 9,
  '8D32': 9,
  '8D33': 9,
  '8D34': 9,
  '8D35': 9,
  '8D36': 9,
  '8D37': 9,
  '8D38': 9,
  '8D39': 9,
  '8D3A': 9,
  '8D3B': 9,
  '8D3C': 10,
  '8D3D': 10,
  '8D3E': 10,
  '8D3F': 10,
  '8D40': 10,
  '8D41': 10,
  '8D42': 10,
  '8D43': 10,
  '8D44': 10,
  '8D45': 10,
  '8D46': 10,
  '8D47': 11,
  '8D48': 11,
  '8D49': 11,
  '8D4A': 11,
  '8D4B': 12,
  '8D4C': 12,
  '8D4D': 12,
  '8D4E': 12,
  '8D4F': 12,
  '8D50': 12,
  '8D51': 12,
  '8D52': 12,
  '8D53': 12,
  '8D54': 12,
  '8D55': 12,
  '8D56': 13,
  '8D57': 13,
  '8D58': 14,
  '8D59': 14,
  '8D5A': 14,
  '8D5B': 14,
  '8D5C': 15,
  '8D5D': 16,
  '8D5E': 16,
  '8D5F': 16,
  '8D60': 16,
  '8D61': 17,
  '8D62': 17,
  '8D63': 21,
  '8D64': 7,
  '8D65': 11,
  '8D66': 11,
  '8D67': 11,
  '8D68': 13,
  '8D69': 13,
  '8D6A': 13,
  '8D6B': 14,
  '8D6C': 16,
  '8D6D': 15,
  '8D6E': 16,
  '8D6F': 17,
  '8D70': 7,
  '8D71': 6,
  '8D72': 9,
  '8D73': 9,
  '8D74': 9,
  '8D75': 9,
  '8D76': 10,
  '8D77': 10,
  '8D78': 10,
  '8D79': 11,
  '8D7A': 11,
  '8D7B': 11,
  '8D7C': 11,
  '8D7D': 11,
  '8D7E': 11,
  '8D7F': 11,
  '8D80': 11,
  '8D81': 12,
  '8D82': 12,
  '8D83': 12,
  '8D84': 12,
  '8D85': 12,
  '8D86': 12,
  '8D87': 12,
  '8D88': 12,
  '8D89': 12,
  '8D8A': 12,
  '8D8B': 12,
  '8D8C': 13,
  '8D8D': 13,
  '8D8E': 13,
  '8D8F': 13,
  '8D90': 13,
  '8D91': 13,
  '8D92': 13,
  '8D93': 13,
  '8D94': 13,
  '8D95': 14,
  '8D96': 14,
  '8D97': 14,
  '8D98': 14,
  '8D99': 14,
  '8D9A': 14,
  '8D9B': 15,
  '8D9C': 15,
  '8D9D': 15,
  '8D9E': 15,
  '8D9F': 15,
  '8DA0': 15,
  '8DA1': 15,
  '8DA2': 15,
  '8DA3': 15,
  '8DA4': 15,
  '8DA5': 16,
  '8DA6': 16,
  '8DA7': 16,
  '8DA8': 17,
  '8DA9': 18,
  '8DAA': 18,
  '8DAB': 19,
  '8DAC': 19,
  '8DAD': 19,
  '8DAE': 20,
  '8DAF': 21,
  '8DB0': 21,
  '8DB1': 23,
  '8DB2': 26,
  '8DB3': 7,
  '8DB4': 9,
  '8DB5': 10,
  '8DB6': 10,
  '8DB7': 10,
  '8DB8': 10,
  '8DB9': 11,
  '8DBA': 11,
  '8DBB': 11,
  '8DBC': 11,
  '8DBD': 11,
  '8DBE': 11,
  '8DBF': 10,
  '8DC0': 11,
  '8DC1': 11,
  '8DC2': 11,
  '8DC3': 11,
  '8DC4': 11,
  '8DC5': 12,
  '8DC6': 12,
  '8DC7': 12,
  '8DC8': 12,
  '8DC9': 12,
  '8DCA': 12,
  '8DCB': 12,
  '8DCC': 12,
  '8DCD': 12,
  '8DCE': 12,
  '8DCF': 12,
  '8DD0': 13,
  '8DD1': 12,
  '8DD2': 12,
  '8DD3': 12,
  '8DD4': 12,
  '8DD5': 12,
  '8DD6': 12,
  '8DD7': 12,
  '8DD8': 12,
  '8DD9': 12,
  '8DDA': 12,
  '8DDB': 12,
  '8DDC': 12,
  '8DDD': 11,
  '8DDE': 12,
  '8DDF': 13,
  '8DE0': 13,
  '8DE1': 13,
  '8DE2': 13,
  '8DE3': 13,
  '8DE4': 13,
  '8DE5': 13,
  '8DE6': 13,
  '8DE7': 13,
  '8DE8': 13,
  '8DE9': 13,
  '8DEA': 13,
  '8DEB': 13,
  '8DEC': 13,
  '8DED': 13,
  '8DEE': 13,
  '8DEF': 13,
  '8DF0': 13,
  '8DF1': 13,
  '8DF2': 13,
  '8DF3': 13,
  '8DF4': 13,
  '8DF5': 12,
  '8DF6': 13,
  '8DF7': 13,
  '8DF8': 13,
  '8DF9': 13,
  '8DFA': 13,
  '8DFB': 13,
  '8DFC': 14,
  '8DFD': 14,
  '8DFE': 14,
  '8DFF': 14,
  '8E00': 14,
  '8E01': 14,
  '8E02': 14,
  '8E03': 14,
  '8E04': 14,
  '8E05': 14,
  '8E06': 14,
  '8E07': 14,
  '8E08': 14,
  '8E09': 14,
  '8E0A': 14,
  '8E0B': 14,
  '8E0C': 14,
  '8E0D': 14,
  '8E0E': 14,
  '8E0F': 15,
  '8E10': 15,
  '8E11': 15,
  '8E12': 15,
  '8E13': 15,
  '8E14': 15,
  '8E15': 15,
  '8E16': 15,
  '8E17': 15,
  '8E18': 15,
  '8E19': 15,
  '8E1A': 15,
  '8E1B': 15,
  '8E1C': 15,
  '8E1D': 15,
  '8E1E': 15,
  '8E1F': 15,
  '8E20': 15,
  '8E21': 15,
  '8E22': 15,
  '8E23': 15,
  '8E24': 15,
  '8E25': 15,
  '8E26': 15,
  '8E27': 15,
  '8E28': 15,
  '8E29': 15,
  '8E2A': 15,
  '8E2B': 15,
  '8E2C': 15,
  '8E2D': 13,
  '8E2E': 15,
  '8E2F': 15,
  '8E30': 16,
  '8E31': 16,
  '8E32': 16,
  '8E33': 16,
  '8E34': 16,
  '8E35': 16,
  '8E36': 16,
  '8E37': 15,
  '8E38': 16,
  '8E39': 16,
  '8E3A': 15,
  '8E3B': 15,
  '8E3C': 16,
  '8E3D': 16,
  '8E3E': 16,
  '8E3F': 16,
  '8E40': 16,
  '8E41': 16,
  '8E42': 16,
  '8E43': 15,
  '8E44': 16,
  '8E45': 16,
  '8E46': 16,
  '8E47': 17,
  '8E48': 17,
  '8E49': 16,
  '8E4A': 17,
  '8E4B': 17,
  '8E4C': 17,
  '8E4D': 17,
  '8E4E': 17,
  '8E4F': 17,
  '8E50': 17,
  '8E51': 17,
  '8E52': 17,
  '8E53': 17,
  '8E54': 18,
  '8E55': 17,
  '8E56': 18,
  '8E57': 18,
  '8E58': 18,
  '8E59': 18,
  '8E5A': 18,
  '8E5B': 18,
  '8E5C': 18,
  '8E5D': 18,
  '8E5E': 18,
  '8E5F': 18,
  '8E60': 18,
  '8E61': 18,
  '8E62': 18,
  '8E63': 18,
  '8E64': 18,
  '8E65': 17,
  '8E66': 18,
  '8E67': 18,
  '8E68': 19,
  '8E69': 18,
  '8E6A': 19,
  '8E6B': 19,
  '8E6C': 19,
  '8E6D': 19,
  '8E6E': 19,
  '8E6F': 19,
  '8E70': 19,
  '8E71': 19,
  '8E72': 19,
  '8E73': 19,
  '8E74': 19,
  '8E75': 19,
  '8E76': 19,
  '8E77': 19,
  '8E78': 19,
  '8E79': 19,
  '8E7A': 19,
  '8E7B': 19,
  '8E7C': 19,
  '8E7D': 19,
  '8E7E': 19,
  '8E7F': 19,
  '8E80': 18,
  '8E81': 20,
  '8E82': 19,
  '8E83': 20,
  '8E84': 20,
  '8E85': 20,
  '8E86': 20,
  '8E87': 18,
  '8E88': 20,
  '8E89': 19,
  '8E8A': 21,
  '8E8B': 21,
  '8E8C': 21,
  '8E8D': 21,
  '8E8E': 21,
  '8E8F': 21,
  '8E90': 22,
  '8E91': 21,
  '8E92': 22,
  '8E93': 22,
  '8E94': 22,
  '8E95': 22,
  '8E96': 22,
  '8E97': 22,
  '8E98': 23,
  '8E99': 23,
  '8E9A': 22,
  '8E9B': 23,
  '8E9C': 23,
  '8E9D': 24,
  '8E9E': 24,
  '8E9F': 24,
  '8EA0': 23,
  '8EA1': 25,
  '8EA2': 25,
  '8EA3': 25,
  '8EA4': 24,
  '8EA5': 25,
  '8EA6': 26,
  '8EA7': 26,
  '8EA8': 28,
  '8EA9': 27,
  '8EAA': 26,
  '8EAB': 7,
  '8EAC': 10,
  '8EAD': 11,
  '8EAE': 11,
  '8EAF': 11,
  '8EB0': 12,
  '8EB1': 13,
  '8EB2': 13,
  '8EB3': 13,
  '8EB4': 14,
  '8EB5': 14,
  '8EB6': 15,
  '8EB7': 15,
  '8EB8': 15,
  '8EB9': 15,
  '8EBA': 15,
  '8EBB': 15,
  '8EBC': 15,
  '8EBD': 16,
  '8EBE': 16,
  '8EBF': 18,
  '8EC0': 18,
  '8EC1': 18,
  '8EC2': 19,
  '8EC3': 19,
  '8EC4': 19,
  '8EC5': 19,
  '8EC6': 20,
  '8EC7': 21,
  '8EC8': 24,
  '8EC9': 27,
  '8ECA': 7,
  '8ECB': 8,
  '8ECC': 9,
  '8ECD': 9,
  '8ECE': 10,
  '8ECF': 10,
  '8ED0': 10,
  '8ED1': 10,
  '8ED2': 10,
  '8ED3': 10,
  '8ED4': 10,
  '8ED5': 10,
  '8ED6': 11,
  '8ED7': 11,
  '8ED8': 11,
  '8ED9': 11,
  '8EDA': 11,
  '8EDB': 11,
  '8EDC': 11,
  '8EDD': 11,
  '8EDE': 11,
  '8EDF': 11,
  '8EE0': 11,
  '8EE1': 11,
  '8EE2': 11,
  '8EE3': 11,
  '8EE4': 12,
  '8EE5': 12,
  '8EE6': 12,
  '8EE7': 12,
  '8EE8': 12,
  '8EE9': 12,
  '8EEA': 12,
  '8EEB': 12,
  '8EEC': 12,
  '8EED': 13,
  '8EEE': 12,
  '8EEF': 12,
  '8EF0': 12,
  '8EF1': 12,
  '8EF2': 12,
  '8EF3': 12,
  '8EF4': 12,
  '8EF5': 12,
  '8EF6': 12,
  '8EF7': 12,
  '8EF8': 12,
  '8EF9': 12,
  '8EFA': 12,
  '8EFB': 12,
  '8EFC': 12,
  '8EFD': 12,
  '8EFE': 13,
  '8EFF': 13,
  '8F00': 13,
  '8F01': 13,
  '8F02': 13,
  '8F03': 13,
  '8F04': 13,
  '8F05': 13,
  '8F06': 13,
  '8F07': 13,
  '8F08': 13,
  '8F09': 13,
  '8F0A': 13,
  '8F0B': 13,
  '8F0C': 13,
  '8F0D': 14,
  '8F0E': 14,
  '8F0F': 14,
  '8F10': 14,
  '8F11': 14,
  '8F12': 14,
  '8F13': 14,
  '8F14': 14,
  '8F15': 14,
  '8F16': 15,
  '8F17': 15,
  '8F18': 15,
  '8F19': 15,
  '8F1A': 15,
  '8F1B': 15,
  '8F1C': 15,
  '8F1D': 15,
  '8F1E': 15,
  '8F1F': 15,
  '8F20': 15,
  '8F21': 15,
  '8F22': 15,
  '8F23': 15,
  '8F24': 15,
  '8F25': 15,
  '8F26': 15,
  '8F27': 15,
  '8F28': 15,
  '8F29': 15,
  '8F2A': 15,
  '8F2B': 15,
  '8F2C': 15,
  '8F2D': 16,
  '8F2E': 16,
  '8F2F': 16,
  '8F30': 16,
  '8F31': 16,
  '8F32': 16,
  '8F33': 16,
  '8F34': 16,
  '8F35': 16,
  '8F36': 16,
  '8F37': 16,
  '8F38': 16,
  '8F39': 16,
  '8F3A': 16,
  '8F3B': 16,
  '8F3C': 16,
  '8F3D': 17,
  '8F3E': 17,
  '8F3F': 17,
  '8F40': 17,
  '8F41': 17,
  '8F42': 17,
  '8F43': 17,
  '8F44': 17,
  '8F45': 17,
  '8F46': 18,
  '8F47': 18,
  '8F48': 18,
  '8F49': 18,
  '8F4A': 18,
  '8F4B': 17,
  '8F4C': 18,
  '8F4D': 19,
  '8F4E': 19,
  '8F4F': 19,
  '8F50': 19,
  '8F51': 19,
  '8F52': 19,
  '8F53': 19,
  '8F54': 19,
  '8F55': 19,
  '8F56': 20,
  '8F57': 20,
  '8F58': 20,
  '8F59': 20,
  '8F5A': 20,
  '8F5B': 21,
  '8F5C': 21,
  '8F5D': 20,
  '8F5E': 21,
  '8F5F': 21,
  '8F60': 22,
  '8F61': 22,
  '8F62': 22,
  '8F63': 23,
  '8F64': 23,
  '8F65': 26,
  '8F66': 4,
  '8F67': 5,
  '8F68': 6,
  '8F69': 7,
  '8F6A': 7,
  '8F6B': 7,
  '8F6C': 8,
  '8F6D': 8,
  '8F6E': 8,
  '8F6F': 8,
  '8F70': 8,
  '8F71': 9,
  '8F72': 9,
  '8F73': 9,
  '8F74': 9,
  '8F75': 9,
  '8F76': 9,
  '8F77': 9,
  '8F78': 9,
  '8F79': 9,
  '8F7A': 9,
  '8F7B': 9,
  '8F7C': 10,
  '8F7D': 10,
  '8F7E': 10,
  '8F7F': 10,
  '8F80': 10,
  '8F81': 10,
  '8F82': 10,
  '8F83': 10,
  '8F84': 11,
  '8F85': 11,
  '8F86': 11,
  '8F87': 12,
  '8F88': 12,
  '8F89': 12,
  '8F8A': 12,
  '8F8B': 12,
  '8F8C': 12,
  '8F8D': 12,
  '8F8E': 12,
  '8F8F': 13,
  '8F90': 13,
  '8F91': 13,
  '8F92': 13,
  '8F93': 13,
  '8F94': 13,
  '8F95': 14,
  '8F96': 14,
  '8F97': 14,
  '8F98': 15,
  '8F99': 16,
  '8F9A': 16,
  '8F9B': 7,
  '8F9C': 12,
  '8F9D': 12,
  '8F9E': 13,
  '8F9F': 13,
  '8FA0': 13,
  '8FA1': 14,
  '8FA2': 14,
  '8FA3': 14,
  '8FA4': 15,
  '8FA5': 16,
  '8FA6': 16,
  '8FA7': 16,
  '8FA8': 16,
  '8FA9': 16,
  '8FAA': 16,
  '8FAB': 17,
  '8FAC': 18,
  '8FAD': 19,
  '8FAE': 20,
  '8FAF': 21,
  '8FB0': 7,
  '8FB1': 10,
  '8FB2': 13,
  '8FB3': 15,
  '8FB4': 19,
  '8FB5': 7,
  '8FB6': 3,
  '8FB7': 4,
  '8FB8': 5,
  '8FB9': 5,
  '8FBA': 5,
  '8FBB': 5,
  '8FBC': 5,
  '8FBD': 5,
  '8FBE': 6,
  '8FBF': 6,
  '8FC0': 6,
  '8FC1': 6,
  '8FC2': 6,
  '8FC3': 6,
  '8FC4': 6,
  '8FC5': 6,
  '8FC6': 6,
  '8FC7': 6,
  '8FC8': 6,
  '8FC9': 6,
  '8FCA': 7,
  '8FCB': 7,
  '8FCC': 7,
  '8FCD': 7,
  '8FCE': 7,
  '8FCF': 7,
  '8FD0': 7,
  '8FD1': 7,
  '8FD2': 7,
  '8FD3': 7,
  '8FD4': 7,
  '8FD5': 7,
  '8FD6': 7,
  '8FD7': 7,
  '8FD8': 7,
  '8FD9': 7,
  '8FDA': 7,
  '8FDB': 7,
  '8FDC': 7,
  '8FDD': 7,
  '8FDE': 7,
  '8FDF': 7,
  '8FE0': 8,
  '8FE1': 8,
  '8FE2': 8,
  '8FE3': 8,
  '8FE4': 8,
  '8FE5': 8,
  '8FE6': 8,
  '8FE7': 8,
  '8FE8': 8,
  '8FE9': 8,
  '8FEA': 8,
  '8FEB': 8,
  '8FEC': 8,
  '8FED': 8,
  '8FEE': 8,
  '8FEF': 8,
  '8FF0': 8,
  '8FF1': 8,
  '8FF2': 8,
  '8FF3': 8,
  '8FF4': 9,
  '8FF5': 9,
  '8FF6': 9,
  '8FF7': 9,
  '8FF8': 9,
  '8FF9': 9,
  '8FFA': 9,
  '8FFB': 9,
  '8FFC': 9,
  '8FFD': 9,
  '8FFE': 9,
  '8FFF': 9,
  '9000': 9,
  '9001': 9,
  '9002': 9,
  '9003': 9,
  '9004': 9,
  '9005': 9,
  '9006': 9,
  '9007': 9,
  '9008': 9,
  '9009': 9,
  '900A': 9,
  '900B': 10,
  '900C': 10,
  '900D': 10,
  '900E': 10,
  '900F': 10,
  '9010': 10,
  '9011': 10,
  '9012': 10,
  '9013': 10,
  '9014': 10,
  '9015': 10,
  '9016': 10,
  '9017': 10,
  '9018': 10,
  '9019': 10,
  '901A': 10,
  '901B': 10,
  '901C': 10,
  '901D': 10,
  '901E': 10,
  '901F': 10,
  '9020': 10,
  '9021': 10,
  '9022': 10,
  '9023': 10,
  '9024': 10,
  '9025': 10,
  '9026': 10,
  '9027': 10,
  '9028': 11,
  '9029': 11,
  '902A': 11,
  '902B': 11,
  '902C': 11,
  '902D': 11,
  '902E': 11,
  '902F': 11,
  '9030': 11,
  '9031': 11,
  '9032': 11,
  '9033': 11,
  '9034': 11,
  '9035': 11,
  '9036': 11,
  '9037': 11,
  '9038': 11,
  '9039': 11,
  '903A': 11,
  '903B': 11,
  '903C': 12,
  '903D': 11,
  '903E': 12,
  '903F': 12,
  '9040': 12,
  '9041': 12,
  '9042': 12,
  '9043': 12,
  '9044': 12,
  '9045': 12,
  '9046': 12,
  '9047': 12,
  '9048': 12,
  '9049': 12,
  '904A': 12,
  '904B': 12,
  '904C': 12,
  '904D': 12,
  '904E': 11,
  '904F': 12,
  '9050': 12,
  '9051': 12,
  '9052': 12,
  '9053': 12,
  '9054': 12,
  '9055': 12,
  '9056': 12,
  '9057': 12,
  '9058': 13,
  '9059': 13,
  '905A': 12,
  '905B': 13,
  '905C': 13,
  '905D': 13,
  '905E': 13,
  '905F': 13,
  '9060': 13,
  '9061': 13,
  '9062': 13,
  '9063': 13,
  '9064': 13,
  '9065': 13,
  '9066': 14,
  '9067': 14,
  '9068': 13,
  '9069': 14,
  '906A': 14,
  '906B': 14,
  '906C': 14,
  '906D': 14,
  '906E': 14,
  '906F': 14,
  '9070': 14,
  '9071': 14,
  '9072': 15,
  '9073': 13,
  '9074': 15,
  '9075': 15,
  '9076': 15,
  '9077': 15,
  '9078': 15,
  '9079': 15,
  '907A': 15,
  '907B': 15,
  '907C': 15,
  '907D': 16,
  '907E': 16,
  '907F': 16,
  '9080': 16,
  '9081': 15,
  '9082': 16,
  '9083': 17,
  '9084': 16,
  '9085': 16,
  '9086': 15,
  '9087': 17,
  '9088': 17,
  '9089': 17,
  '908A': 18,
  '908B': 18,
  '908C': 18,
  '908D': 19,
  '908E': 21,
  '908F': 22,
  '9090': 22,
  '9091': 7,
  '9092': 4,
  '9093': 4,
  '9094': 5,
  '9095': 10,
  '9096': 5,
  '9097': 5,
  '9098': 5,
  '9099': 5,
  '909A': 5,
  '909B': 5,
  '909C': 5,
  '909D': 5,
  '909E': 6,
  '909F': 6,
  '90A0': 6,
  '90A1': 6,
  '90A2': 6,
  '90A3': 6,
  '90A4': 6,
  '90A5': 6,
  '90A6': 6,
  '90A7': 6,
  '90A8': 6,
  '90A9': 6,
  '90AA': 6,
  '90AB': 11,
  '90AC': 6,
  '90AD': 7,
  '90AE': 7,
  '90AF': 7,
  '90B0': 7,
  '90B1': 7,
  '90B2': 7,
  '90B3': 7,
  '90B4': 7,
  '90B5': 7,
  '90B6': 7,
  '90B7': 6,
  '90B8': 7,
  '90B9': 7,
  '90BA': 7,
  '90BB': 7,
  '90BC': 8,
  '90BD': 8,
  '90BE': 8,
  '90BF': 8,
  '90C0': 8,
  '90C1': 8,
  '90C2': 8,
  '90C3': 8,
  '90C4': 8,
  '90C5': 8,
  '90C6': 8,
  '90C7': 8,
  '90C8': 8,
  '90C9': 8,
  '90CA': 8,
  '90CB': 8,
  '90CC': 13,
  '90CD': 8,
  '90CE': 8,
  '90CF': 8,
  '90D0': 8,
  '90D1': 8,
  '90D2': 13,
  '90D3': 8,
  '90D4': 8,
  '90D5': 8,
  '90D6': 9,
  '90D7': 9,
  '90D8': 8,
  '90D9': 9,
  '90DA': 9,
  '90DB': 9,
  '90DC': 9,
  '90DD': 9,
  '90DE': 9,
  '90DF': 9,
  '90E0': 9,
  '90E1': 9,
  '90E2': 9,
  '90E3': 9,
  '90E4': 9,
  '90E5': 9,
  '90E6': 9,
  '90E7': 9,
  '90E8': 10,
  '90E9': 10,
  '90EA': 10,
  '90EB': 10,
  '90EC': 10,
  '90ED': 10,
  '90EE': 10,
  '90EF': 10,
  '90F0': 10,
  '90F1': 8,
  '90F2': 10,
  '90F3': 10,
  '90F4': 10,
  '90F5': 10,
  '90F6': 15,
  '90F7': 10,
  '90F8': 10,
  '90F9': 11,
  '90FA': 16,
  '90FB': 11,
  '90FC': 11,
  '90FD': 10,
  '90FE': 11,
  '90FF': 11,
  '9100': 10,
  '9101': 11,
  '9102': 11,
  '9103': 11,
  '9104': 11,
  '9105': 11,
  '9106': 11,
  '9107': 11,
  '9108': 11,
  '9109': 11,
  '910A': 11,
  '910B': 11,
  '910C': 12,
  '910D': 12,
  '910E': 12,
  '910F': 12,
  '9110': 12,
  '9111': 12,
  '9112': 12,
  '9113': 12,
  '9114': 12,
  '9115': 12,
  '9116': 12,
  '9117': 12,
  '9118': 13,
  '9119': 13,
  '911A': 12,
  '911B': 13,
  '911C': 13,
  '911D': 13,
  '911E': 13,
  '911F': 13,
  '9120': 13,
  '9121': 13,
  '9122': 13,
  '9123': 13,
  '9124': 13,
  '9125': 13,
  '9126': 14,
  '9127': 14,
  '9128': 18,
  '9129': 14,
  '912A': 14,
  '912B': 14,
  '912C': 14,
  '912D': 14,
  '912E': 14,
  '912F': 14,
  '9130': 14,
  '9131': 14,
  '9132': 14,
  '9133': 15,
  '9134': 15,
  '9135': 15,
  '9136': 15,
  '9137': 15,
  '9138': 15,
  '9139': 16,
  '913A': 16,
  '913B': 17,
  '913C': 17,
  '913D': 17,
  '913E': 17,
  '913F': 17,
  '9140': 18,
  '9141': 19,
  '9142': 18,
  '9143': 19,
  '9144': 19,
  '9145': 20,
  '9146': 20,
  '9147': 21,
  '9148': 21,
  '9149': 7,
  '914A': 9,
  '914B': 9,
  '914C': 10,
  '914D': 10,
  '914E': 10,
  '914F': 10,
  '9150': 10,
  '9151': 10,
  '9152': 10,
  '9153': 11,
  '9154': 11,
  '9155': 11,
  '9156': 11,
  '9157': 11,
  '9158': 11,
  '9159': 11,
  '915A': 11,
  '915B': 11,
  '915C': 11,
  '915D': 11,
  '915E': 11,
  '915F': 12,
  '9160': 12,
  '9161': 12,
  '9162': 12,
  '9163': 12,
  '9164': 12,
  '9165': 12,
  '9166': 12,
  '9167': 13,
  '9168': 13,
  '9169': 13,
  '916A': 13,
  '916B': 13,
  '916C': 13,
  '916D': 13,
  '916E': 13,
  '916F': 13,
  '9170': 13,
  '9171': 13,
  '9172': 14,
  '9173': 14,
  '9174': 14,
  '9175': 14,
  '9176': 14,
  '9177': 14,
  '9178': 14,
  '9179': 14,
  '917A': 14,
  '917B': 14,
  '917C': 14,
  '917D': 14,
  '917E': 14,
  '917F': 14,
  '9180': 15,
  '9181': 15,
  '9182': 15,
  '9183': 15,
  '9184': 15,
  '9185': 15,
  '9186': 15,
  '9187': 15,
  '9188': 15,
  '9189': 15,
  '918A': 15,
  '918B': 15,
  '918C': 15,
  '918D': 16,
  '918E': 16,
  '918F': 15,
  '9190': 16,
  '9191': 16,
  '9192': 16,
  '9193': 16,
  '9194': 16,
  '9195': 16,
  '9196': 16,
  '9197': 16,
  '9198': 17,
  '9199': 16,
  '919A': 16,
  '919B': 16,
  '919C': 16,
  '919D': 16,
  '919E': 17,
  '919F': 17,
  '91A0': 17,
  '91A1': 17,
  '91A2': 17,
  '91A3': 17,
  '91A4': 17,
  '91A5': 18,
  '91A6': 18,
  '91A7': 18,
  '91A8': 17,
  '91A9': 18,
  '91AA': 18,
  '91AB': 18,
  '91AC': 18,
  '91AD': 19,
  '91AE': 19,
  '91AF': 19,
  '91B0': 19,
  '91B1': 19,
  '91B2': 20,
  '91B3': 20,
  '91B4': 20,
  '91B5': 20,
  '91B6': 20,
  '91B7': 20,
  '91B8': 20,
  '91B9': 21,
  '91BA': 21,
  '91BB': 21,
  '91BC': 23,
  '91BD': 24,
  '91BE': 24,
  '91BF': 24,
  '91C0': 24,
  '91C1': 26,
  '91C2': 24,
  '91C3': 26,
  '91C4': 26,
  '91C5': 26,
  '91C6': 7,
  '91C7': 8,
  '91C8': 11,
  '91C9': 12,
  '91CA': 12,
  '91CB': 20,
  '91CC': 7,
  '91CD': 9,
  '91CE': 11,
  '91CF': 12,
  '91D0': 18,
  '91D1': 8,
  '91D2': 8,
  '91D3': 9,
  '91D4': 9,
  '91D5': 10,
  '91D6': 10,
  '91D7': 10,
  '91D8': 10,
  '91D9': 10,
  '91DA': 10,
  '91DB': 10,
  '91DC': 10,
  '91DD': 10,
  '91DE': 10,
  '91DF': 10,
  '91E0': 10,
  '91E1': 10,
  '91E2': 10,
  '91E3': 11,
  '91E4': 11,
  '91E5': 11,
  '91E6': 11,
  '91E7': 11,
  '91E8': 11,
  '91E9': 11,
  '91EA': 11,
  '91EB': 11,
  '91EC': 11,
  '91ED': 11,
  '91EE': 11,
  '91EF': 11,
  '91F0': 11,
  '91F1': 11,
  '91F2': 11,
  '91F3': 11,
  '91F4': 11,
  '91F5': 11,
  '91F6': 11,
  '91F7': 11,
  '91F8': 11,
  '91F9': 11,
  '91FA': 11,
  '91FB': 11,
  '91FC': 11,
  '91FD': 12,
  '91FE': 12,
  '91FF': 12,
  '9200': 12,
  '9201': 12,
  '9202': 12,
  '9203': 12,
  '9204': 12,
  '9205': 12,
  '9206': 12,
  '9207': 12,
  '9208': 12,
  '9209': 12,
  '920A': 12,
  '920B': 12,
  '920C': 12,
  '920D': 12,
  '920E': 12,
  '920F': 12,
  '9210': 12,
  '9211': 12,
  '9212': 11,
  '9213': 12,
  '9214': 12,
  '9215': 12,
  '9216': 12,
  '9217': 12,
  '9218': 12,
  '9219': 12,
  '921A': 12,
  '921B': 12,
  '921C': 12,
  '921D': 12,
  '921E': 12,
  '921F': 12,
  '9220': 12,
  '9221': 12,
  '9222': 12,
  '9223': 12,
  '9224': 12,
  '9225': 12,
  '9226': 12,
  '9227': 12,
  '9228': 12,
  '9229': 12,
  '922A': 12,
  '922B': 12,
  '922C': 12,
  '922D': 14,
  '922E': 13,
  '922F': 13,
  '9230': 13,
  '9231': 13,
  '9232': 13,
  '9233': 13,
  '9234': 13,
  '9235': 13,
  '9236': 13,
  '9237': 13,
  '9238': 13,
  '9239': 13,
  '923A': 13,
  '923B': 13,
  '923C': 13,
  '923D': 13,
  '923E': 13,
  '923F': 13,
  '9240': 13,
  '9241': 13,
  '9242': 13,
  '9243': 13,
  '9244': 13,
  '9245': 12,
  '9246': 13,
  '9247': 13,
  '9248': 13,
  '9249': 13,
  '924A': 13,
  '924B': 13,
  '924C': 13,
  '924D': 13,
  '924E': 13,
  '924F': 13,
  '9250': 13,
  '9251': 13,
  '9252': 13,
  '9253': 13,
  '9254': 13,
  '9255': 13,
  '9256': 13,
  '9257': 13,
  '9258': 13,
  '9259': 13,
  '925A': 13,
  '925B': 13,
  '925C': 13,
  '925D': 13,
  '925E': 13,
  '925F': 13,
  '9260': 13,
  '9261': 13,
  '9262': 13,
  '9263': 13,
  '9264': 13,
  '9265': 13,
  '9266': 13,
  '9267': 13,
  '9268': 13,
  '9269': 13,
  '926A': 13,
  '926B': 13,
  '926C': 13,
  '926D': 13,
  '926E': 13,
  '926F': 12,
  '9270': 13,
  '9271': 13,
  '9272': 13,
  '9273': 13,
  '9274': 13,
  '9275': 14,
  '9276': 14,
  '9277': 14,
  '9278': 14,
  '9279': 14,
  '927A': 14,
  '927B': 14,
  '927C': 14,
  '927D': 14,
  '927E': 14,
  '927F': 14,
  '9280': 14,
  '9281': 14,
  '9282': 14,
  '9283': 14,
  '9284': 14,
  '9285': 14,
  '9286': 14,
  '9287': 14,
  '9288': 14,
  '9289': 14,
  '928A': 14,
  '928B': 14,
  '928C': 14,
  '928D': 14,
  '928E': 14,
  '928F': 13,
  '9290': 14,
  '9291': 14,
  '9292': 14,
  '9293': 14,
  '9294': 14,
  '9295': 14,
  '9296': 14,
  '9297': 14,
  '9298': 14,
  '9299': 14,
  '929A': 14,
  '929B': 14,
  '929C': 14,
  '929D': 14,
  '929E': 14,
  '929F': 14,
  '92A0': 14,
  '92A1': 14,
  '92A2': 14,
  '92A3': 14,
  '92A4': 14,
  '92A5': 14,
  '92A6': 14,
  '92A7': 14,
  '92A8': 14,
  '92A9': 14,
  '92AA': 14,
  '92AB': 14,
  '92AC': 14,
  '92AD': 14,
  '92AE': 14,
  '92AF': 14,
  '92B0': 14,
  '92B1': 14,
  '92B2': 15,
  '92B3': 15,
  '92B4': 15,
  '92B5': 15,
  '92B6': 15,
  '92B7': 15,
  '92B8': 15,
  '92B9': 15,
  '92BA': 15,
  '92BB': 15,
  '92BC': 15,
  '92BD': 15,
  '92BE': 15,
  '92BF': 15,
  '92C0': 15,
  '92C1': 14,
  '92C2': 15,
  '92C3': 15,
  '92C4': 15,
  '92C5': 15,
  '92C6': 15,
  '92C7': 15,
  '92C8': 15,
  '92C9': 15,
  '92CA': 15,
  '92CB': 14,
  '92CC': 14,
  '92CD': 15,
  '92CE': 15,
  '92CF': 15,
  '92D0': 15,
  '92D1': 15,
  '92D2': 15,
  '92D3': 15,
  '92D4': 15,
  '92D5': 15,
  '92D6': 15,
  '92D7': 15,
  '92D8': 15,
  '92D9': 15,
  '92DA': 15,
  '92DB': 15,
  '92DC': 15,
  '92DD': 15,
  '92DE': 15,
  '92DF': 15,
  '92E0': 15,
  '92E1': 15,
  '92E2': 15,
  '92E3': 14,
  '92E4': 15,
  '92E5': 15,
  '92E6': 15,
  '92E7': 15,
  '92E8': 15,
  '92E9': 14,
  '92EA': 15,
  '92EB': 15,
  '92EC': 15,
  '92ED': 15,
  '92EE': 14,
  '92EF': 15,
  '92F0': 15,
  '92F1': 15,
  '92F2': 15,
  '92F3': 15,
  '92F4': 15,
  '92F5': 15,
  '92F6': 15,
  '92F7': 16,
  '92F8': 16,
  '92F9': 16,
  '92FA': 16,
  '92FB': 16,
  '92FC': 16,
  '92FD': 16,
  '92FE': 16,
  '92FF': 16,
  '9300': 16,
  '9301': 16,
  '9302': 16,
  '9303': 16,
  '9304': 16,
  '9305': 16,
  '9306': 16,
  '9307': 16,
  '9308': 16,
  '9309': 16,
  '930A': 16,
  '930B': 16,
  '930C': 16,
  '930D': 16,
  '930E': 16,
  '930F': 16,
  '9310': 16,
  '9311': 16,
  '9312': 15,
  '9313': 15,
  '9314': 16,
  '9315': 16,
  '9316': 16,
  '9317': 16,
  '9318': 16,
  '9319': 16,
  '931A': 14,
  '931B': 16,
  '931C': 16,
  '931D': 16,
  '931E': 16,
  '931F': 16,
  '9320': 16,
  '9321': 16,
  '9322': 16,
  '9323': 16,
  '9324': 16,
  '9325': 16,
  '9326': 16,
  '9327': 16,
  '9328': 16,
  '9329': 16,
  '932A': 16,
  '932B': 16,
  '932C': 16,
  '932D': 16,
  '932E': 16,
  '932F': 16,
  '9330': 16,
  '9331': 16,
  '9332': 16,
  '9333': 16,
  '9334': 16,
  '9335': 15,
  '9336': 16,
  '9337': 16,
  '9338': 16,
  '9339': 16,
  '933A': 15,
  '933B': 16,
  '933C': 16,
  '933D': 16,
  '933E': 16,
  '933F': 16,
  '9340': 16,
  '9341': 16,
  '9342': 16,
  '9343': 16,
  '9344': 16,
  '9345': 16,
  '9346': 16,
  '9347': 17,
  '9348': 16,
  '9349': 17,
  '934A': 17,
  '934B': 16,
  '934C': 17,
  '934D': 17,
  '934E': 17,
  '934F': 17,
  '9350': 17,
  '9351': 17,
  '9352': 17,
  '9353': 17,
  '9354': 17,
  '9355': 17,
  '9356': 17,
  '9357': 17,
  '9358': 17,
  '9359': 17,
  '935A': 17,
  '935B': 17,
  '935C': 17,
  '935D': 17,
  '935E': 17,
  '935F': 17,
  '9360': 17,
  '9361': 17,
  '9362': 17,
  '9363': 16,
  '9364': 17,
  '9365': 17,
  '9366': 17,
  '9367': 17,
  '9368': 17,
  '9369': 16,
  '936A': 17,
  '936B': 17,
  '936C': 17,
  '936D': 17,
  '936E': 17,
  '936F': 17,
  '9370': 17,
  '9371': 17,
  '9372': 17,
  '9373': 17,
  '9374': 17,
  '9375': 16,
  '9376': 17,
  '9377': 17,
  '9378': 17,
  '9379': 17,
  '937A': 16,
  '937B': 17,
  '937C': 17,
  '937D': 17,
  '937E': 17,
  '937F': 17,
  '9380': 17,
  '9381': 16,
  '9382': 17,
  '9383': 17,
  '9384': 17,
  '9385': 17,
  '9386': 17,
  '9387': 17,
  '9388': 17,
  '9389': 18,
  '938A': 18,
  '938B': 18,
  '938C': 18,
  '938D': 18,
  '938E': 18,
  '938F': 18,
  '9390': 18,
  '9391': 18,
  '9392': 18,
  '9393': 18,
  '9394': 18,
  '9395': 18,
  '9396': 18,
  '9397': 18,
  '9398': 18,
  '9399': 18,
  '939A': 17,
  '939B': 18,
  '939C': 18,
  '939D': 17,
  '939E': 18,
  '939F': 18,
  '93A0': 18,
  '93A1': 17,
  '93A2': 18,
  '93A3': 18,
  '93A4': 18,
  '93A5': 18,
  '93A6': 18,
  '93A7': 18,
  '93A8': 18,
  '93A9': 18,
  '93AA': 17,
  '93AB': 18,
  '93AC': 18,
  '93AD': 18,
  '93AE': 18,
  '93AF': 16,
  '93B0': 18,
  '93B1': 18,
  '93B2': 18,
  '93B3': 18,
  '93B4': 18,
  '93B5': 18,
  '93B6': 18,
  '93B7': 18,
  '93B8': 18,
  '93B9': 17,
  '93BA': 17,
  '93BB': 18,
  '93BC': 18,
  '93BD': 18,
  '93BE': 17,
  '93BF': 18,
  '93C0': 19,
  '93C1': 19,
  '93C2': 19,
  '93C3': 19,
  '93C4': 19,
  '93C5': 18,
  '93C6': 19,
  '93C7': 19,
  '93C8': 18,
  '93C9': 19,
  '93CA': 18,
  '93CB': 19,
  '93CC': 18,
  '93CD': 19,
  '93CE': 18,
  '93CF': 19,
  '93D0': 19,
  '93D1': 19,
  '93D2': 19,
  '93D3': 19,
  '93D4': 19,
  '93D5': 19,
  '93D6': 19,
  '93D7': 19,
  '93D8': 19,
  '93D9': 19,
  '93DA': 19,
  '93DB': 19,
  '93DC': 19,
  '93DD': 19,
  '93DE': 19,
  '93DF': 19,
  '93E0': 18,
  '93E1': 19,
  '93E2': 19,
  '93E3': 19,
  '93E4': 19,
  '93E5': 19,
  '93E6': 19,
  '93E7': 19,
  '93E8': 19,
  '93E9': 19,
  '93EA': 19,
  '93EB': 19,
  '93EC': 19,
  '93ED': 19,
  '93EE': 19,
  '93EF': 19,
  '93F0': 19,
  '93F1': 19,
  '93F2': 19,
  '93F3': 20,
  '93F4': 21,
  '93F5': 18,
  '93F6': 20,
  '93F7': 20,
  '93F8': 20,
  '93F9': 20,
  '93FA': 20,
  '93FB': 20,
  '93FC': 20,
  '93FD': 21,
  '93FE': 20,
  '93FF': 20,
  '9400': 20,
  '9401': 20,
  '9402': 20,
  '9403': 20,
  '9404': 19,
  '9405': 19,
  '9406': 19,
  '9407': 20,
  '9408': 20,
  '9409': 20,
  '940A': 19,
  '940B': 20,
  '940C': 19,
  '940D': 20,
  '940E': 20,
  '940F': 20,
  '9410': 20,
  '9411': 20,
  '9412': 20,
  '9413': 20,
  '9414': 20,
  '9415': 20,
  '9416': 20,
  '9417': 20,
  '9418': 20,
  '9419': 20,
  '941A': 20,
  '941B': 20,
  '941C': 20,
  '941D': 20,
  '941E': 20,
  '941F': 20,
  '9420': 20,
  '9421': 20,
  '9422': 20,
  '9423': 20,
  '9424': 20,
  '9425': 20,
  '9426': 20,
  '9427': 20,
  '9428': 20,
  '9429': 20,
  '942A': 21,
  '942B': 20,
  '942C': 21,
  '942D': 20,
  '942E': 21,
  '942F': 19,
  '9430': 21,
  '9431': 21,
  '9432': 21,
  '9433': 21,
  '9434': 21,
  '9435': 21,
  '9436': 21,
  '9437': 20,
  '9438': 21,
  '9439': 19,
  '943A': 21,
  '943B': 21,
  '943C': 20,
  '943D': 20,
  '943E': 21,
  '943F': 21,
  '9440': 21,
  '9441': 21,
  '9442': 22,
  '9443': 22,
  '9444': 22,
  '9445': 22,
  '9446': 22,
  '9447': 22,
  '9448': 22,
  '9449': 21,
  '944A': 21,
  '944B': 22,
  '944C': 22,
  '944D': 22,
  '944E': 22,
  '944F': 22,
  '9450': 22,
  '9451': 22,
  '9452': 22,
  '9453': 21,
  '9454': 22,
  '9455': 23,
  '9456': 22,
  '9457': 23,
  '9458': 23,
  '9459': 23,
  '945A': 23,
  '945B': 22,
  '945C': 23,
  '945D': 21,
  '945E': 23,
  '945F': 23,
  '9460': 23,
  '9461': 23,
  '9462': 23,
  '9463': 23,
  '9464': 23,
  '9465': 23,
  '9466': 23,
  '9467': 22,
  '9468': 24,
  '9469': 24,
  '946A': 24,
  '946B': 24,
  '946C': 24,
  '946D': 25,
  '946E': 24,
  '946F': 25,
  '9470': 25,
  '9471': 25,
  '9472': 25,
  '9473': 25,
  '9474': 26,
  '9475': 25,
  '9476': 25,
  '9477': 26,
  '9478': 26,
  '9479': 26,
  '947A': 26,
  '947B': 27,
  '947C': 27,
  '947D': 27,
  '947E': 27,
  '947F': 28,
  '9480': 28,
  '9481': 28,
  '9482': 28,
  '9483': 29,
  '9484': 28,
  '9485': 5,
  '9486': 6,
  '9487': 6,
  '9488': 7,
  '9489': 7,
  '948A': 7,
  '948B': 7,
  '948C': 7,
  '948D': 8,
  '948E': 8,
  '948F': 8,
  '9490': 8,
  '9491': 8,
  '9492': 8,
  '9493': 8,
  '9494': 8,
  '9495': 8,
  '9496': 8,
  '9497': 8,
  '9498': 9,
  '9499': 9,
  '949A': 9,
  '949B': 9,
  '949C': 9,
  '949D': 9,
  '949E': 9,
  '949F': 9,
  '94A0': 9,
  '94A1': 9,
  '94A2': 9,
  '94A3': 9,
  '94A4': 9,
  '94A5': 9,
  '94A6': 9,
  '94A7': 9,
  '94A8': 9,
  '94A9': 9,
  '94AA': 9,
  '94AB': 9,
  '94AC': 9,
  '94AD': 9,
  '94AE': 9,
  '94AF': 9,
  '94B0': 10,
  '94B1': 10,
  '94B2': 10,
  '94B3': 10,
  '94B4': 10,
  '94B5': 10,
  '94B6': 10,
  '94B7': 10,
  '94B8': 10,
  '94B9': 10,
  '94BA': 10,
  '94BB': 10,
  '94BC': 10,
  '94BD': 10,
  '94BE': 10,
  '94BF': 10,
  '94C0': 10,
  '94C1': 10,
  '94C2': 10,
  '94C3': 10,
  '94C4': 10,
  '94C5': 10,
  '94C6': 10,
  '94C7': 10,
  '94C8': 10,
  '94C9': 10,
  '94CA': 10,
  '94CB': 10,
  '94CC': 10,
  '94CD': 10,
  '94CE': 10,
  '94CF': 11,
  '94D0': 11,
  '94D1': 11,
  '94D2': 11,
  '94D3': 11,
  '94D4': 11,
  '94D5': 11,
  '94D6': 11,
  '94D7': 11,
  '94D8': 11,
  '94D9': 11,
  '94DA': 11,
  '94DB': 11,
  '94DC': 11,
  '94DD': 11,
  '94DE': 11,
  '94DF': 11,
  '94E0': 11,
  '94E1': 11,
  '94E2': 11,
  '94E3': 11,
  '94E4': 11,
  '94E5': 11,
  '94E6': 11,
  '94E7': 11,
  '94E8': 11,
  '94E9': 11,
  '94EA': 11,
  '94EB': 11,
  '94EC': 11,
  '94ED': 11,
  '94EE': 11,
  '94EF': 11,
  '94F0': 11,
  '94F1': 11,
  '94F2': 11,
  '94F3': 11,
  '94F4': 11,
  '94F5': 11,
  '94F6': 11,
  '94F7': 11,
  '94F8': 12,
  '94F9': 12,
  '94FA': 12,
  '94FB': 12,
  '94FC': 12,
  '94FD': 12,
  '94FE': 12,
  '94FF': 12,
  '9500': 12,
  '9501': 12,
  '9502': 12,
  '9503': 12,
  '9504': 12,
  '9505': 12,
  '9506': 12,
  '9507': 12,
  '9508': 12,
  '9509': 12,
  '950A': 12,
  '950B': 12,
  '950C': 12,
  '950D': 12,
  '950E': 12,
  '950F': 12,
  '9510': 12,
  '9511': 12,
  '9512': 12,
  '9513': 12,
  '9514': 12,
  '9515': 12,
  '9516': 13,
  '9517': 13,
  '9518': 13,
  '9519': 13,
  '951A': 13,
  '951B': 13,
  '951C': 13,
  '951D': 13,
  '951E': 13,
  '951F': 13,
  '9520': 13,
  '9521': 13,
  '9522': 13,
  '9523': 13,
  '9524': 13,
  '9525': 13,
  '9526': 13,
  '9527': 13,
  '9528': 13,
  '9529': 13,
  '952A': 13,
  '952B': 13,
  '952C': 13,
  '952D': 13,
  '952E': 13,
  '952F': 13,
  '9530': 13,
  '9531': 13,
  '9532': 14,
  '9533': 13,
  '9534': 14,
  '9535': 14,
  '9536': 14,
  '9537': 14,
  '9538': 14,
  '9539': 14,
  '953A': 14,
  '953B': 14,
  '953C': 14,
  '953D': 14,
  '953E': 14,
  '953F': 14,
  '9540': 14,
  '9541': 14,
  '9542': 14,
  '9543': 14,
  '9544': 14,
  '9545': 14,
  '9546': 15,
  '9547': 15,
  '9548': 15,
  '9549': 15,
  '954A': 15,
  '954B': 15,
  '954C': 15,
  '954D': 15,
  '954E': 15,
  '954F': 15,
  '9550': 15,
  '9551': 15,
  '9552': 15,
  '9553': 15,
  '9554': 15,
  '9555': 15,
  '9556': 16,
  '9557': 16,
  '9558': 16,
  '9559': 16,
  '955A': 16,
  '955B': 16,
  '955C': 16,
  '955D': 16,
  '955E': 16,
  '955F': 16,
  '9560': 16,
  '9561': 17,
  '9562': 17,
  '9563': 17,
  '9564': 17,
  '9565': 17,
  '9566': 17,
  '9567': 17,
  '9568': 17,
  '9569': 17,
  '956A': 17,
  '956B': 17,
  '956C': 18,
  '956D': 18,
  '956E': 18,
  '956F': 18,
  '9570': 18,
  '9571': 18,
  '9572': 19,
  '9573': 20,
  '9574': 20,
  '9575': 22,
  '9576': 22,
  '9577': 8,
  '9578': 7,
  '9579': 10,
  '957A': 11,
  '957B': 12,
  '957C': 15,
  '957D': 19,
  '957E': 21,
  '957F': 4,
  '9580': 8,
  '9581': 9,
  '9582': 9,
  '9583': 10,
  '9584': 10,
  '9585': 10,
  '9586': 11,
  '9587': 11,
  '9588': 11,
  '9589': 11,
  '958A': 11,
  '958B': 12,
  '958C': 12,
  '958D': 12,
  '958E': 12,
  '958F': 12,
  '9590': 12,
  '9591': 12,
  '9592': 12,
  '9593': 12,
  '9594': 12,
  '9595': 12,
  '9596': 12,
  '9597': 12,
  '9598': 13,
  '9599': 13,
  '959A': 13,
  '959B': 13,
  '959C': 13,
  '959D': 13,
  '959E': 13,
  '959F': 13,
  '95A0': 13,
  '95A1': 14,
  '95A2': 14,
  '95A3': 14,
  '95A4': 14,
  '95A5': 14,
  '95A6': 14,
  '95A7': 14,
  '95A8': 14,
  '95A9': 14,
  '95AA': 14,
  '95AB': 15,
  '95AC': 15,
  '95AD': 14,
  '95AE': 14,
  '95AF': 15,
  '95B0': 14,
  '95B1': 15,
  '95B2': 15,
  '95B3': 15,
  '95B4': 15,
  '95B5': 16,
  '95B6': 16,
  '95B7': 16,
  '95B8': 16,
  '95B9': 16,
  '95BA': 16,
  '95BB': 16,
  '95BC': 16,
  '95BD': 16,
  '95BE': 16,
  '95BF': 16,
  '95C0': 17,
  '95C1': 16,
  '95C2': 16,
  '95C3': 17,
  '95C4': 17,
  '95C5': 17,
  '95C6': 17,
  '95C7': 17,
  '95C8': 17,
  '95C9': 17,
  '95CA': 17,
  '95CB': 17,
  '95CC': 17,
  '95CD': 16,
  '95CE': 17,
  '95CF': 17,
  '95D0': 18,
  '95D1': 18,
  '95D2': 18,
  '95D3': 18,
  '95D4': 18,
  '95D5': 18,
  '95D6': 18,
  '95D7': 18,
  '95D8': 18,
  '95D9': 19,
  '95DA': 19,
  '95DB': 19,
  '95DC': 19,
  '95DD': 19,
  '95DE': 19,
  '95DF': 20,
  '95E0': 20,
  '95E1': 20,
  '95E2': 21,
  '95E3': 21,
  '95E4': 21,
  '95E5': 20,
  '95E6': 21,
  '95E7': 22,
  '95E8': 3,
  '95E9': 4,
  '95EA': 5,
  '95EB': 6,
  '95EC': 6,
  '95ED': 6,
  '95EE': 6,
  '95EF': 6,
  '95F0': 7,
  '95F1': 7,
  '95F2': 7,
  '95F3': 7,
  '95F4': 7,
  '95F5': 7,
  '95F6': 7,
  '95F7': 7,
  '95F8': 8,
  '95F9': 8,
  '95FA': 9,
  '95FB': 9,
  '95FC': 9,
  '95FD': 9,
  '95FE': 9,
  '95FF': 9,
  '9600': 9,
  '9601': 9,
  '9602': 9,
  '9603': 10,
  '9604': 10,
  '9605': 10,
  '9606': 10,
  '9607': 11,
  '9608': 11,
  '9609': 11,
  '960A': 11,
  '960B': 11,
  '960C': 11,
  '960D': 11,
  '960E': 11,
  '960F': 11,
  '9610': 11,
  '9611': 12,
  '9612': 12,
  '9613': 12,
  '9614': 12,
  '9615': 12,
  '9616': 13,
  '9617': 13,
  '9618': 13,
  '9619': 13,
  '961A': 14,
  '961B': 16,
  '961C': 8,
  '961D': 2,
  '961E': 4,
  '961F': 4,
  '9620': 5,
  '9621': 5,
  '9622': 5,
  '9623': 5,
  '9624': 5,
  '9625': 6,
  '9626': 6,
  '9627': 6,
  '9628': 6,
  '9629': 6,
  '962A': 6,
  '962B': 6,
  '962C': 6,
  '962D': 6,
  '962E': 6,
  '962F': 6,
  '9630': 6,
  '9631': 6,
  '9632': 6,
  '9633': 6,
  '9634': 6,
  '9635': 6,
  '9636': 6,
  '9637': 7,
  '9638': 7,
  '9639': 7,
  '963A': 7,
  '963B': 7,
  '963C': 7,
  '963D': 7,
  '963E': 7,
  '963F': 7,
  '9640': 7,
  '9641': 7,
  '9642': 7,
  '9643': 7,
  '9644': 7,
  '9645': 7,
  '9646': 7,
  '9647': 7,
  '9648': 7,
  '9649': 7,
  '964A': 8,
  '964B': 8,
  '964C': 8,
  '964D': 8,
  '964E': 8,
  '964F': 8,
  '9650': 8,
  '9651': 8,
  '9652': 8,
  '9653': 8,
  '9654': 8,
  '9655': 8,
  '9656': 9,
  '9657': 9,
  '9658': 9,
  '9659': 9,
  '965A': 10,
  '965B': 9,
  '965C': 9,
  '965D': 9,
  '965E': 9,
  '965F': 9,
  '9660': 9,
  '9661': 9,
  '9662': 9,
  '9663': 9,
  '9664': 9,
  '9665': 9,
  '9666': 9,
  '9667': 9,
  '9668': 9,
  '9669': 9,
  '966A': 10,
  '966B': 10,
  '966C': 10,
  '966D': 10,
  '966E': 10,
  '966F': 10,
  '9670': 10,
  '9671': 10,
  '9672': 10,
  '9673': 10,
  '9674': 10,
  '9675': 10,
  '9676': 10,
  '9677': 10,
  '9678': 10,
  '9679': 10,
  '967A': 10,
  '967B': 11,
  '967C': 10,
  '967D': 11,
  '967E': 11,
  '967F': 11,
  '9680': 11,
  '9681': 11,
  '9682': 11,
  '9683': 11,
  '9684': 11,
  '9685': 11,
  '9686': 11,
  '9687': 11,
  '9688': 11,
  '9689': 11,
  '968A': 11,
  '968B': 11,
  '968C': 11,
  '968D': 11,
  '968E': 11,
  '968F': 11,
  '9690': 11,
  '9691': 12,
  '9692': 12,
  '9693': 12,
  '9694': 12,
  '9695': 12,
  '9696': 12,
  '9697': 11,
  '9698': 12,
  '9699': 12,
  '969A': 13,
  '969B': 13,
  '969C': 13,
  '969D': 13,
  '969E': 12,
  '969F': 13,
  '96A0': 13,
  '96A1': 13,
  '96A2': 14,
  '96A3': 14,
  '96A4': 14,
  '96A5': 14,
  '96A6': 15,
  '96A7': 14,
  '96A8': 14,
  '96A9': 14,
  '96AA': 15,
  '96AB': 14,
  '96AC': 16,
  '96AD': 16,
  '96AE': 16,
  '96AF': 16,
  '96B0': 16,
  '96B1': 16,
  '96B2': 16,
  '96B3': 17,
  '96B4': 18,
  '96B5': 19,
  '96B6': 8,
  '96B7': 16,
  '96B8': 17,
  '96B9': 8,
  '96BA': 10,
  '96BB': 10,
  '96BC': 10,
  '96BD': 10,
  '96BE': 10,
  '96BF': 11,
  '96C0': 11,
  '96C1': 12,
  '96C2': 12,
  '96C3': 12,
  '96C4': 12,
  '96C5': 12,
  '96C6': 12,
  '96C7': 12,
  '96C8': 12,
  '96C9': 13,
  '96CA': 13,
  '96CB': 12,
  '96CC': 14,
  '96CD': 13,
  '96CE': 13,
  '96CF': 13,
  '96D0': 14,
  '96D1': 14,
  '96D2': 14,
  '96D3': 15,
  '96D4': 16,
  '96D5': 16,
  '96D6': 17,
  '96D7': 18,
  '96D8': 17,
  '96D9': 18,
  '96DA': 17,
  '96DB': 18,
  '96DC': 18,
  '96DD': 18,
  '96DE': 18,
  '96DF': 18,
  '96E0': 18,
  '96E1': 19,
  '96E2': 18,
  '96E3': 19,
  '96E4': 21,
  '96E5': 24,
  '96E6': 24,
  '96E7': 28,
  '96E8': 8,
  '96E9': 11,
  '96EA': 11,
  '96EB': 11,
  '96EC': 12,
  '96ED': 11,
  '96EE': 12,
  '96EF': 12,
  '96F0': 12,
  '96F1': 12,
  '96F2': 12,
  '96F3': 12,
  '96F4': 13,
  '96F5': 13,
  '96F6': 13,
  '96F7': 13,
  '96F8': 13,
  '96F9': 13,
  '96FA': 13,
  '96FB': 13,
  '96FC': 13,
  '96FD': 13,
  '96FE': 13,
  '96FF': 14,
  '9700': 14,
  '9701': 14,
  '9702': 15,
  '9703': 15,
  '9704': 15,
  '9705': 15,
  '9706': 14,
  '9707': 15,
  '9708': 15,
  '9709': 15,
  '970A': 15,
  '970B': 16,
  '970C': 16,
  '970D': 16,
  '970E': 16,
  '970F': 16,
  '9710': 16,
  '9711': 16,
  '9712': 16,
  '9713': 16,
  '9714': 16,
  '9715': 16,
  '9716': 16,
  '9717': 16,
  '9718': 17,
  '9719': 16,
  '971A': 17,
  '971B': 17,
  '971C': 17,
  '971D': 17,
  '971E': 17,
  '971F': 17,
  '9720': 17,
  '9721': 17,
  '9722': 18,
  '9723': 18,
  '9724': 18,
  '9725': 18,
  '9726': 19,
  '9727': 18,
  '9728': 19,
  '9729': 18,
  '972A': 19,
  '972B': 19,
  '972C': 19,
  '972D': 19,
  '972E': 20,
  '972F': 20,
  '9730': 20,
  '9731': 20,
  '9732': 21,
  '9733': 19,
  '9734': 20,
  '9735': 20,
  '9736': 21,
  '9737': 21,
  '9738': 21,
  '9739': 21,
  '973A': 21,
  '973B': 21,
  '973C': 22,
  '973D': 22,
  '973E': 22,
  '973F': 21,
  '9740': 21,
  '9741': 23,
  '9742': 24,
  '9743': 24,
  '9744': 24,
  '9745': 24,
  '9746': 23,
  '9747': 24,
  '9748': 24,
  '9749': 25,
  '974A': 26,
  '974B': 27,
  '974C': 27,
  '974D': 27,
  '974E': 27,
  '974F': 29,
  '9750': 39,
  '9751': 8,
  '9752': 8,
  '9753': 12,
  '9754': 12,
  '9755': 13,
  '9756': 13,
  '9757': 14,
  '9758': 14,
  '9759': 14,
  '975A': 15,
  '975B': 16,
  '975C': 16,
  '975D': 18,
  '975E': 8,
  '975F': 12,
  '9760': 15,
  '9761': 19,
  '9762': 9,
  '9763': 8,
  '9764': 14,
  '9765': 15,
  '9766': 16,
  '9767': 21,
  '9768': 23,
  '9769': 9,
  '976A': 11,
  '976B': 12,
  '976C': 12,
  '976D': 12,
  '976E': 12,
  '976F': 12,
  '9770': 12,
  '9771': 12,
  '9772': 13,
  '9773': 13,
  '9774': 13,
  '9775': 13,
  '9776': 13,
  '9777': 13,
  '9778': 12,
  '9779': 13,
  '977A': 14,
  '977B': 14,
  '977C': 14,
  '977D': 14,
  '977E': 14,
  '977F': 14,
  '9780': 14,
  '9781': 14,
  '9782': 14,
  '9783': 14,
  '9784': 14,
  '9785': 14,
  '9786': 14,
  '9787': 15,
  '9788': 15,
  '9789': 15,
  '978A': 15,
  '978B': 15,
  '978C': 15,
  '978D': 15,
  '978E': 15,
  '978F': 15,
  '9790': 15,
  '9791': 15,
  '9792': 15,
  '9793': 16,
  '9794': 16,
  '9795': 16,
  '9796': 16,
  '9797': 15,
  '9798': 16,
  '9799': 16,
  '979A': 17,
  '979B': 17,
  '979C': 17,
  '979D': 17,
  '979E': 17,
  '979F': 17,
  '97A0': 17,
  '97A1': 17,
  '97A2': 18,
  '97A3': 18,
  '97A4': 18,
  '97A5': 18,
  '97A6': 18,
  '97A7': 18,
  '97A8': 18,
  '97A9': 18,
  '97AA': 18,
  '97AB': 18,
  '97AC': 17,
  '97AD': 18,
  '97AE': 18,
  '97AF': 18,
  '97B0': 18,
  '97B1': 19,
  '97B2': 19,
  '97B3': 18,
  '97B4': 19,
  '97B5': 19,
  '97B6': 19,
  '97B7': 19,
  '97B8': 19,
  '97B9': 19,
  '97BA': 20,
  '97BB': 20,
  '97BC': 21,
  '97BD': 21,
  '97BE': 19,
  '97BF': 21,
  '97C0': 22,
  '97C1': 22,
  '97C2': 22,
  '97C3': 21,
  '97C4': 22,
  '97C5': 23,
  '97C6': 24,
  '97C7': 24,
  '97C8': 23,
  '97C9': 25,
  '97CA': 29,
  '97CB': 9,
  '97CC': 12,
  '97CD': 14,
  '97CE': 14,
  '97CF': 15,
  '97D0': 15,
  '97D1': 15,
  '97D2': 16,
  '97D3': 17,
  '97D4': 17,
  '97D5': 17,
  '97D6': 18,
  '97D7': 18,
  '97D8': 18,
  '97D9': 18,
  '97DA': 18,
  '97DB': 19,
  '97DC': 19,
  '97DD': 19,
  '97DE': 18,
  '97DF': 19,
  '97E0': 19,
  '97E1': 19,
  '97E2': 21,
  '97E3': 22,
  '97E4': 23,
  '97E5': 24,
  '97E6': 4,
  '97E7': 7,
  '97E8': 9,
  '97E9': 12,
  '97EA': 13,
  '97EB': 13,
  '97EC': 14,
  '97ED': 9,
  '97EE': 12,
  '97EF': 15,
  '97F0': 16,
  '97F1': 17,
  '97F2': 19,
  '97F3': 9,
  '97F4': 13,
  '97F5': 13,
  '97F6': 14,
  '97F7': 14,
  '97F8': 16,
  '97F9': 18,
  '97FA': 17,
  '97FB': 19,
  '97FC': 19,
  '97FD': 20,
  '97FE': 20,
  '97FF': 20,
  '9800': 22,
  '9801': 9,
  '9802': 11,
  '9803': 11,
  '9804': 11,
  '9805': 12,
  '9806': 12,
  '9807': 12,
  '9808': 12,
  '9809': 13,
  '980A': 13,
  '980B': 13,
  '980C': 13,
  '980D': 13,
  '980E': 13,
  '980F': 13,
  '9810': 13,
  '9811': 13,
  '9812': 13,
  '9813': 13,
  '9814': 14,
  '9815': 14,
  '9816': 14,
  '9817': 14,
  '9818': 14,
  '9819': 14,
  '981A': 14,
  '981B': 15,
  '981C': 15,
  '981D': 15,
  '981E': 15,
  '981F': 15,
  '9820': 15,
  '9821': 15,
  '9822': 15,
  '9823': 15,
  '9824': 16,
  '9825': 16,
  '9826': 15,
  '9827': 15,
  '9828': 15,
  '9829': 15,
  '982A': 15,
  '982B': 15,
  '982C': 15,
  '982D': 16,
  '982E': 16,
  '982F': 16,
  '9830': 16,
  '9831': 16,
  '9832': 15,
  '9833': 16,
  '9834': 16,
  '9835': 16,
  '9836': 16,
  '9837': 16,
  '9838': 16,
  '9839': 16,
  '983A': 16,
  '983B': 16,
  '983C': 16,
  '983D': 16,
  '983E': 18,
  '983F': 18,
  '9840': 17,
  '9841': 17,
  '9842': 17,
  '9843': 17,
  '9844': 17,
  '9845': 17,
  '9846': 17,
  '9847': 17,
  '9848': 17,
  '9849': 17,
  '984A': 17,
  '984B': 18,
  '984C': 18,
  '984D': 18,
  '984E': 18,
  '984F': 18,
  '9850': 18,
  '9851': 18,
  '9852': 18,
  '9853': 18,
  '9854': 18,
  '9855': 18,
  '9856': 19,
  '9857': 19,
  '9858': 19,
  '9859': 19,
  '985A': 19,
  '985B': 19,
  '985C': 19,
  '985D': 18,
  '985E': 19,
  '985F': 20,
  '9860': 20,
  '9861': 20,
  '9862': 20,
  '9863': 20,
  '9864': 21,
  '9865': 21,
  '9866': 21,
  '9867': 21,
  '9868': 21,
  '9869': 22,
  '986A': 22,
  '986B': 22,
  '986C': 23,
  '986D': 22,
  '986E': 23,
  '986F': 23,
  '9870': 24,
  '9871': 25,
  '9872': 25,
  '9873': 27,
  '9874': 26,
  '9875': 6,
  '9876': 8,
  '9877': 8,
  '9878': 9,
  '9879': 9,
  '987A': 9,
  '987B': 9,
  '987C': 10,
  '987D': 10,
  '987E': 10,
  '987F': 10,
  '9880': 10,
  '9881': 10,
  '9882': 10,
  '9883': 10,
  '9884': 10,
  '9885': 11,
  '9886': 11,
  '9887': 11,
  '9888': 11,
  '9889': 12,
  '988A': 12,
  '988B': 12,
  '988C': 12,
  '988D': 12,
  '988E': 12,
  '988F': 12,
  '9890': 13,
  '9891': 13,
  '9892': 13,
  '9893': 13,
  '9894': 13,
  '9895': 13,
  '9896': 13,
  '9897': 14,
  '9898': 15,
  '9899': 15,
  '989A': 15,
  '989B': 15,
  '989C': 15,
  '989D': 15,
  '989E': 16,
  '989F': 16,
  '98A0': 16,
  '98A1': 16,
  '98A2': 18,
  '98A3': 18,
  '98A4': 19,
  '98A5': 20,
  '98A6': 21,
  '98A7': 23,
  '98A8': 9,
  '98A9': 12,
  '98AA': 12,
  '98AB': 13,
  '98AC': 13,
  '98AD': 14,
  '98AE': 14,
  '98AF': 14,
  '98B0': 14,
  '98B1': 14,
  '98B2': 15,
  '98B3': 15,
  '98B4': 16,
  '98B5': 16,
  '98B6': 17,
  '98B7': 17,
  '98B8': 18,
  '98B9': 18,
  '98BA': 18,
  '98BB': 19,
  '98BC': 18,
  '98BD': 19,
  '98BE': 18,
  '98BF': 19,
  '98C0': 19,
  '98C1': 20,
  '98C2': 20,
  '98C3': 20,
  '98C4': 20,
  '98C5': 21,
  '98C6': 21,
  '98C7': 21,
  '98C8': 21,
  '98C9': 21,
  '98CA': 21,
  '98CB': 22,
  '98CC': 26,
  '98CD': 27,
  '98CE': 4,
  '98CF': 7,
  '98D0': 9,
  '98D1': 9,
  '98D2': 9,
  '98D3': 12,
  '98D4': 13,
  '98D5': 13,
  '98D6': 14,
  '98D7': 14,
  '98D8': 15,
  '98D9': 16,
  '98DA': 16,
  '98DB': 9,
  '98DC': 21,
  '98DD': 27,
  '98DE': 3,
  '98DF': 9,
  '98E0': 8,
  '98E1': 11,
  '98E2': 10,
  '98E3': 10,
  '98E4': 10,
  '98E5': 11,
  '98E6': 11,
  '98E7': 12,
  '98E8': 12,
  '98E9': 12,
  '98EA': 12,
  '98EB': 12,
  '98EC': 13,
  '98ED': 12,
  '98EE': 13,
  '98EF': 12,
  '98F0': 12,
  '98F1': 13,
  '98F2': 12,
  '98F3': 13,
  '98F4': 13,
  '98F5': 13,
  '98F6': 13,
  '98F7': 13,
  '98F8': 14,
  '98F9': 13,
  '98FA': 15,
  '98FB': 13,
  '98FC': 13,
  '98FD': 13,
  '98FE': 13,
  '98FF': 13,
  '9900': 13,
  '9901': 14,
  '9902': 14,
  '9903': 14,
  '9904': 14,
  '9905': 14,
  '9906': 14,
  '9907': 14,
  '9908': 15,
  '9909': 14,
  '990A': 14,
  '990B': 15,
  '990C': 14,
  '990D': 15,
  '990E': 14,
  '990F': 14,
  '9910': 16,
  '9911': 15,
  '9912': 15,
  '9913': 15,
  '9914': 15,
  '9915': 15,
  '9916': 15,
  '9917': 15,
  '9918': 15,
  '9919': 15,
  '991A': 16,
  '991B': 16,
  '991C': 16,
  '991D': 15,
  '991E': 16,
  '991F': 16,
  '9920': 16,
  '9921': 16,
  '9922': 16,
  '9923': 16,
  '9924': 16,
  '9925': 17,
  '9926': 16,
  '9927': 16,
  '9928': 16,
  '9929': 16,
  '992A': 17,
  '992B': 17,
  '992C': 17,
  '992D': 17,
  '992E': 18,
  '992F': 17,
  '9930': 17,
  '9931': 17,
  '9932': 17,
  '9933': 17,
  '9934': 16,
  '9935': 17,
  '9936': 17,
  '9937': 17,
  '9938': 17,
  '9939': 18,
  '993A': 18,
  '993B': 18,
  '993C': 18,
  '993D': 17,
  '993E': 18,
  '993F': 17,
  '9940': 18,
  '9941': 18,
  '9942': 17,
  '9943': 18,
  '9944': 19,
  '9945': 19,
  '9946': 18,
  '9947': 19,
  '9948': 18,
  '9949': 19,
  '994A': 20,
  '994B': 20,
  '994C': 20,
  '994D': 20,
  '994E': 20,
  '994F': 20,
  '9950': 20,
  '9951': 20,
  '9952': 20,
  '9953': 20,
  '9954': 22,
  '9955': 22,
  '9956': 21,
  '9957': 20,
  '9958': 21,
  '9959': 20,
  '995A': 21,
  '995B': 21,
  '995C': 23,
  '995D': 24,
  '995E': 25,
  '995F': 25,
  '9960': 27,
  '9961': 27,
  '9962': 30,
  '9963': 3,
  '9964': 5,
  '9965': 5,
  '9966': 6,
  '9967': 6,
  '9968': 7,
  '9969': 7,
  '996A': 7,
  '996B': 7,
  '996C': 7,
  '996D': 7,
  '996E': 7,
  '996F': 8,
  '9970': 8,
  '9971': 8,
  '9972': 8,
  '9973': 8,
  '9974': 8,
  '9975': 9,
  '9976': 9,
  '9977': 9,
  '9978': 9,
  '9979': 9,
  '997A': 9,
  '997B': 9,
  '997C': 9,
  '997D': 10,
  '997E': 10,
  '997F': 10,
  '9980': 10,
  '9981': 10,
  '9982': 10,
  '9983': 11,
  '9984': 11,
  '9985': 11,
  '9986': 11,
  '9987': 12,
  '9988': 12,
  '9989': 12,
  '998A': 12,
  '998B': 12,
  '998C': 13,
  '998D': 13,
  '998E': 13,
  '998F': 13,
  '9990': 13,
  '9991': 14,
  '9992': 14,
  '9993': 15,
  '9994': 15,
  '9995': 25,
  '9996': 9,
  '9997': 11,
  '9998': 17,
  '9999': 9,
  '999A': 13,
  '999B': 14,
  '999C': 14,
  '999D': 14,
  '999E': 16,
  '999F': 16,
  '99A0': 16,
  '99A1': 17,
  '99A2': 17,
  '99A3': 17,
  '99A4': 18,
  '99A5': 18,
  '99A6': 19,
  '99A7': 18,
  '99A8': 20,
  '99A9': 21,
  '99AA': 23,
  '99AB': 27,
  '99AC': 10,
  '99AD': 12,
  '99AE': 12,
  '99AF': 13,
  '99B0': 13,
  '99B1': 13,
  '99B2': 13,
  '99B3': 13,
  '99B4': 13,
  '99B5': 13,
  '99B6': 14,
  '99B7': 14,
  '99B8': 14,
  '99B9': 14,
  '99BA': 13,
  '99BB': 14,
  '99BC': 14,
  '99BD': 14,
  '99BE': 14,
  '99BF': 14,
  '99C0': 14,
  '99C1': 14,
  '99C2': 14,
  '99C3': 14,
  '99C4': 14,
  '99C5': 14,
  '99C6': 14,
  '99C7': 14,
  '99C8': 15,
  '99C9': 15,
  '99CA': 15,
  '99CB': 15,
  '99CC': 15,
  '99CD': 15,
  '99CE': 15,
  '99CF': 14,
  '99D0': 15,
  '99D1': 15,
  '99D2': 15,
  '99D3': 15,
  '99D4': 15,
  '99D5': 15,
  '99D6': 15,
  '99D7': 15,
  '99D8': 15,
  '99D9': 15,
  '99DA': 15,
  '99DB': 15,
  '99DC': 15,
  '99DD': 15,
  '99DE': 15,
  '99DF': 15,
  '99E0': 15,
  '99E1': 16,
  '99E2': 16,
  '99E3': 16,
  '99E4': 16,
  '99E5': 16,
  '99E6': 16,
  '99E7': 16,
  '99E8': 16,
  '99E9': 16,
  '99EA': 16,
  '99EB': 16,
  '99EC': 16,
  '99ED': 16,
  '99EE': 16,
  '99EF': 16,
  '99F0': 16,
  '99F1': 16,
  '99F2': 16,
  '99F3': 16,
  '99F4': 17,
  '99F5': 17,
  '99F6': 17,
  '99F7': 17,
  '99F8': 17,
  '99F9': 17,
  '99FA': 17,
  '99FB': 17,
  '99FC': 17,
  '99FD': 17,
  '99FE': 17,
  '99FF': 17,
  '9A00': 17,
  '9A01': 17,
  '9A02': 17,
  '9A03': 17,
  '9A04': 18,
  '9A05': 18,
  '9A06': 18,
  '9A07': 18,
  '9A08': 18,
  '9A09': 18,
  '9A0A': 18,
  '9A0B': 18,
  '9A0C': 18,
  '9A0D': 18,
  '9A0E': 18,
  '9A0F': 18,
  '9A10': 18,
  '9A11': 18,
  '9A12': 18,
  '9A13': 18,
  '9A14': 19,
  '9A15': 19,
  '9A16': 19,
  '9A17': 19,
  '9A18': 19,
  '9A19': 19,
  '9A1A': 19,
  '9A1B': 19,
  '9A1C': 19,
  '9A1D': 18,
  '9A1E': 19,
  '9A1F': 19,
  '9A20': 19,
  '9A21': 19,
  '9A22': 19,
  '9A23': 19,
  '9A24': 19,
  '9A25': 19,
  '9A26': 19,
  '9A27': 18,
  '9A28': 19,
  '9A29': 19,
  '9A2A': 19,
  '9A2B': 20,
  '9A2C': 20,
  '9A2D': 19,
  '9A2E': 20,
  '9A2F': 20,
  '9A30': 20,
  '9A31': 20,
  '9A32': 19,
  '9A33': 20,
  '9A34': 20,
  '9A35': 20,
  '9A36': 20,
  '9A37': 19,
  '9A38': 20,
  '9A39': 21,
  '9A3A': 21,
  '9A3B': 21,
  '9A3C': 21,
  '9A3D': 21,
  '9A3E': 21,
  '9A3F': 21,
  '9A40': 20,
  '9A41': 20,
  '9A42': 21,
  '9A43': 21,
  '9A44': 21,
  '9A45': 21,
  '9A46': 20,
  '9A47': 21,
  '9A48': 22,
  '9A49': 21,
  '9A4A': 20,
  '9A4B': 22,
  '9A4C': 23,
  '9A4D': 22,
  '9A4E': 22,
  '9A4F': 22,
  '9A50': 22,
  '9A51': 22,
  '9A52': 22,
  '9A53': 22,
  '9A54': 22,
  '9A55': 22,
  '9A56': 23,
  '9A57': 23,
  '9A58': 23,
  '9A59': 23,
  '9A5A': 22,
  '9A5B': 23,
  '9A5C': 23,
  '9A5D': 24,
  '9A5E': 24,
  '9A5F': 24,
  '9A60': 26,
  '9A61': 26,
  '9A62': 26,
  '9A63': 26,
  '9A64': 27,
  '9A65': 26,
  '9A66': 27,
  '9A67': 27,
  '9A68': 28,
  '9A69': 27,
  '9A6A': 29,
  '9A6B': 30,
  '9A6C': 3,
  '9A6D': 5,
  '9A6E': 6,
  '9A6F': 6,
  '9A70': 6,
  '9A71': 7,
  '9A72': 7,
  '9A73': 7,
  '9A74': 7,
  '9A75': 8,
  '9A76': 8,
  '9A77': 8,
  '9A78': 8,
  '9A79': 8,
  '9A7A': 8,
  '9A7B': 8,
  '9A7C': 8,
  '9A7D': 8,
  '9A7E': 8,
  '9A7F': 8,
  '9A80': 8,
  '9A81': 9,
  '9A82': 9,
  '9A83': 9,
  '9A84': 9,
  '9A85': 9,
  '9A86': 9,
  '9A87': 9,
  '9A88': 9,
  '9A89': 9,
  '9A8A': 10,
  '9A8B': 10,
  '9A8C': 10,
  '9A8D': 10,
  '9A8E': 10,
  '9A8F': 10,
  '9A90': 11,
  '9A91': 11,
  '9A92': 11,
  '9A93': 11,
  '9A94': 11,
  '9A95': 11,
  '9A96': 11,
  '9A97': 12,
  '9A98': 12,
  '9A99': 12,
  '9A9A': 12,
  '9A9B': 12,
  '9A9C': 13,
  '9A9D': 13,
  '9A9E': 13,
  '9A9F': 13,
  '9AA0': 14,
  '9AA1': 14,
  '9AA2': 14,
  '9AA3': 15,
  '9AA4': 17,
  '9AA5': 19,
  '9AA6': 20,
  '9AA7': 20,
  '9AA8': 9,
  '9AA9': 11,
  '9AAA': 12,
  '9AAB': 12,
  '9AAC': 12,
  '9AAD': 12,
  '9AAE': 12,
  '9AAF': 13,
  '9AB0': 13,
  '9AB1': 13,
  '9AB2': 14,
  '9AB3': 14,
  '9AB4': 15,
  '9AB5': 14,
  '9AB6': 14,
  '9AB7': 14,
  '9AB8': 15,
  '9AB9': 15,
  '9ABA': 15,
  '9ABB': 15,
  '9ABC': 15,
  '9ABD': 16,
  '9ABE': 16,
  '9ABF': 15,
  '9AC0': 17,
  '9AC1': 17,
  '9AC2': 18,
  '9AC3': 18,
  '9AC4': 18,
  '9AC5': 18,
  '9AC6': 19,
  '9AC7': 19,
  '9AC8': 19,
  '9AC9': 19,
  '9ACA': 18,
  '9ACB': 19,
  '9ACC': 19,
  '9ACD': 20,
  '9ACE': 20,
  '9ACF': 20,
  '9AD0': 21,
  '9AD1': 22,
  '9AD2': 21,
  '9AD3': 21,
  '9AD4': 22,
  '9AD5': 23,
  '9AD6': 23,
  '9AD7': 25,
  '9AD8': 10,
  '9AD9': 11,
  '9ADA': 14,
  '9ADB': 15,
  '9ADC': 18,
  '9ADD': 22,
  '9ADE': 23,
  '9ADF': 10,
  '9AE0': 12,
  '9AE1': 13,
  '9AE2': 13,
  '9AE3': 14,
  '9AE4': 14,
  '9AE5': 14,
  '9AE6': 14,
  '9AE7': 14,
  '9AE8': 14,
  '9AE9': 14,
  '9AEA': 14,
  '9AEB': 15,
  '9AEC': 15,
  '9AED': 16,
  '9AEE': 15,
  '9AEF': 15,
  '9AF0': 15,
  '9AF1': 15,
  '9AF2': 15,
  '9AF3': 15,
  '9AF4': 15,
  '9AF5': 16,
  '9AF6': 16,
  '9AF7': 16,
  '9AF8': 16,
  '9AF9': 16,
  '9AFA': 16,
  '9AFB': 16,
  '9AFC': 17,
  '9AFD': 17,
  '9AFE': 17,
  '9AFF': 17,
  '9B00': 17,
  '9B01': 17,
  '9B02': 17,
  '9B03': 18,
  '9B04': 18,
  '9B05': 18,
  '9B06': 18,
  '9B07': 16,
  '9B08': 18,
  '9B09': 19,
  '9B0A': 19,
  '9B0B': 19,
  '9B0C': 19,
  '9B0D': 19,
  '9B0E': 19,
  '9B0F': 19,
  '9B10': 20,
  '9B11': 20,
  '9B12': 20,
  '9B13': 20,
  '9B14': 20,
  '9B15': 20,
  '9B16': 21,
  '9B17': 21,
  '9B18': 21,
  '9B19': 22,
  '9B1A': 22,
  '9B1B': 22,
  '9B1C': 22,
  '9B1D': 22,
  '9B1E': 23,
  '9B1F': 23,
  '9B20': 23,
  '9B21': 24,
  '9B22': 24,
  '9B23': 25,
  '9B24': 27,
  '9B25': 10,
  '9B26': 14,
  '9B27': 15,
  '9B28': 16,
  '9B29': 18,
  '9B2A': 20,
  '9B2B': 21,
  '9B2C': 25,
  '9B2D': 24,
  '9B2E': 27,
  '9B2F': 10,
  '9B30': 27,
  '9B31': 29,
  '9B32': 10,
  '9B33': 16,
  '9B34': 17,
  '9B35': 18,
  '9B36': 18,
  '9B37': 19,
  '9B38': 20,
  '9B39': 21,
  '9B3A': 21,
  '9B3B': 22,
  '9B3C': 9,
  '9B3D': 12,
  '9B3E': 13,
  '9B3F': 13,
  '9B40': 13,
  '9B41': 13,
  '9B42': 13,
  '9B43': 14,
  '9B44': 14,
  '9B45': 14,
  '9B46': 14,
  '9B47': 15,
  '9B48': 16,
  '9B49': 16,
  '9B4A': 17,
  '9B4B': 17,
  '9B4C': 17,
  '9B4D': 17,
  '9B4E': 17,
  '9B4F': 17,
  '9B50': 19,
  '9B51': 19,
  '9B52': 20,
  '9B53': 19,
  '9B54': 20,
  '9B55': 21,
  '9B56': 20,
  '9B57': 23,
  '9B58': 23,
  '9B59': 23,
  '9B5A': 11,
  '9B5B': 13,
  '9B5C': 13,
  '9B5D': 13,
  '9B5E': 13,
  '9B5F': 14,
  '9B60': 14,
  '9B61': 14,
  '9B62': 14,
  '9B63': 15,
  '9B64': 15,
  '9B65': 14,
  '9B66': 15,
  '9B67': 15,
  '9B68': 15,
  '9B69': 15,
  '9B6A': 15,
  '9B6B': 15,
  '9B6C': 15,
  '9B6D': 15,
  '9B6E': 15,
  '9B6F': 15,
  '9B70': 15,
  '9B71': 15,
  '9B72': 15,
  '9B73': 15,
  '9B74': 15,
  '9B75': 15,
  '9B76': 15,
  '9B77': 15,
  '9B78': 15,
  '9B79': 15,
  '9B7A': 16,
  '9B7B': 16,
  '9B7C': 16,
  '9B7D': 16,
  '9B7E': 16,
  '9B7F': 16,
  '9B80': 16,
  '9B81': 16,
  '9B82': 16,
  '9B83': 16,
  '9B84': 16,
  '9B85': 16,
  '9B86': 17,
  '9B87': 16,
  '9B88': 16,
  '9B89': 16,
  '9B8A': 16,
  '9B8B': 16,
  '9B8C': 16,
  '9B8D': 16,
  '9B8E': 16,
  '9B8F': 16,
  '9B90': 16,
  '9B91': 16,
  '9B92': 16,
  '9B93': 16,
  '9B94': 15,
  '9B95': 16,
  '9B96': 16,
  '9B97': 16,
  '9B98': 16,
  '9B99': 17,
  '9B9A': 17,
  '9B9B': 17,
  '9B9C': 17,
  '9B9D': 17,
  '9B9E': 17,
  '9B9F': 17,
  '9BA0': 17,
  '9BA1': 17,
  '9BA2': 17,
  '9BA3': 16,
  '9BA4': 17,
  '9BA5': 17,
  '9BA6': 17,
  '9BA7': 17,
  '9BA8': 17,
  '9BA9': 17,
  '9BAA': 17,
  '9BAB': 17,
  '9BAC': 17,
  '9BAD': 17,
  '9BAE': 17,
  '9BAF': 17,
  '9BB0': 17,
  '9BB1': 17,
  '9BB2': 17,
  '9BB3': 17,
  '9BB4': 17,
  '9BB5': 18,
  '9BB6': 18,
  '9BB7': 18,
  '9BB8': 18,
  '9BB9': 18,
  '9BBA': 17,
  '9BBB': 18,
  '9BBC': 18,
  '9BBD': 18,
  '9BBE': 18,
  '9BBF': 18,
  '9BC0': 18,
  '9BC1': 18,
  '9BC2': 18,
  '9BC3': 18,
  '9BC4': 18,
  '9BC5': 17,
  '9BC6': 18,
  '9BC7': 18,
  '9BC8': 17,
  '9BC9': 18,
  '9BCA': 18,
  '9BCB': 18,
  '9BCC': 18,
  '9BCD': 18,
  '9BCE': 17,
  '9BCF': 18,
  '9BD0': 18,
  '9BD1': 18,
  '9BD2': 18,
  '9BD3': 18,
  '9BD4': 19,
  '9BD5': 19,
  '9BD6': 19,
  '9BD7': 18,
  '9BD8': 19,
  '9BD9': 19,
  '9BDA': 19,
  '9BDB': 19,
  '9BDC': 19,
  '9BDD': 19,
  '9BDE': 19,
  '9BDF': 19,
  '9BE0': 19,
  '9BE1': 19,
  '9BE2': 19,
  '9BE3': 19,
  '9BE4': 19,
  '9BE5': 19,
  '9BE6': 19,
  '9BE7': 19,
  '9BE8': 19,
  '9BE9': 19,
  '9BEA': 19,
  '9BEB': 19,
  '9BEC': 19,
  '9BED': 19,
  '9BEE': 19,
  '9BEF': 19,
  '9BF0': 19,
  '9BF1': 19,
  '9BF2': 19,
  '9BF3': 19,
  '9BF4': 19,
  '9BF5': 19,
  '9BF6': 20,
  '9BF7': 20,
  '9BF8': 20,
  '9BF9': 20,
  '9BFA': 19,
  '9BFB': 20,
  '9BFC': 20,
  '9BFD': 18,
  '9BFE': 20,
  '9BFF': 20,
  '9C00': 20,
  '9C01': 20,
  '9C02': 20,
  '9C03': 20,
  '9C04': 20,
  '9C05': 20,
  '9C06': 20,
  '9C07': 20,
  '9C08': 20,
  '9C09': 20,
  '9C0A': 20,
  '9C0B': 20,
  '9C0C': 20,
  '9C0D': 20,
  '9C0E': 19,
  '9C0F': 20,
  '9C10': 20,
  '9C11': 20,
  '9C12': 20,
  '9C13': 20,
  '9C14': 20,
  '9C15': 20,
  '9C16': 20,
  '9C17': 20,
  '9C18': 20,
  '9C19': 19,
  '9C1A': 20,
  '9C1B': 20,
  '9C1C': 21,
  '9C1D': 21,
  '9C1E': 21,
  '9C1F': 21,
  '9C20': 20,
  '9C21': 21,
  '9C22': 21,
  '9C23': 21,
  '9C24': 21,
  '9C25': 21,
  '9C26': 20,
  '9C27': 21,
  '9C28': 21,
  '9C29': 21,
  '9C2A': 21,
  '9C2B': 21,
  '9C2C': 21,
  '9C2D': 21,
  '9C2E': 21,
  '9C2F': 21,
  '9C30': 20,
  '9C31': 21,
  '9C32': 21,
  '9C33': 22,
  '9C34': 22,
  '9C35': 22,
  '9C36': 22,
  '9C37': 21,
  '9C38': 22,
  '9C39': 22,
  '9C3A': 22,
  '9C3B': 22,
  '9C3C': 22,
  '9C3D': 22,
  '9C3E': 22,
  '9C3F': 22,
  '9C40': 20,
  '9C41': 21,
  '9C42': 22,
  '9C43': 21,
  '9C44': 22,
  '9C45': 22,
  '9C46': 22,
  '9C47': 22,
  '9C48': 22,
  '9C49': 22,
  '9C4A': 23,
  '9C4B': 22,
  '9C4C': 22,
  '9C4D': 23,
  '9C4E': 23,
  '9C4F': 23,
  '9C50': 24,
  '9C51': 22,
  '9C52': 23,
  '9C53': 23,
  '9C54': 23,
  '9C55': 23,
  '9C56': 23,
  '9C57': 23,
  '9C58': 23,
  '9C59': 23,
  '9C5A': 23,
  '9C5B': 23,
  '9C5C': 22,
  '9C5D': 23,
  '9C5E': 24,
  '9C5F': 24,
  '9C60': 24,
  '9C61': 24,
  '9C62': 24,
  '9C63': 24,
  '9C64': 24,
  '9C65': 24,
  '9C66': 24,
  '9C67': 24,
  '9C68': 25,
  '9C69': 24,
  '9C6A': 23,
  '9C6B': 24,
  '9C6C': 25,
  '9C6D': 25,
  '9C6E': 24,
  '9C6F': 24,
  '9C70': 24,
  '9C71': 25,
  '9C72': 26,
  '9C73': 26,
  '9C74': 25,
  '9C75': 26,
  '9C76': 25,
  '9C77': 27,
  '9C78': 27,
  '9C79': 28,
  '9C7A': 30,
  '9C7B': 33,
  '9C7C': 8,
  '9C7D': 10,
  '9C7E': 11,
  '9C7F': 12,
  '9C80': 12,
  '9C81': 12,
  '9C82': 12,
  '9C83': 12,
  '9C84': 13,
  '9C85': 13,
  '9C86': 13,
  '9C87': 13,
  '9C88': 13,
  '9C89': 13,
  '9C8A': 13,
  '9C8B': 13,
  '9C8C': 13,
  '9C8D': 13,
  '9C8E': 13,
  '9C8F': 13,
  '9C90': 13,
  '9C91': 14,
  '9C92': 14,
  '9C93': 14,
  '9C94': 14,
  '9C95': 14,
  '9C96': 14,
  '9C97': 14,
  '9C98': 14,
  '9C99': 14,
  '9C9A': 14,
  '9C9B': 14,
  '9C9C': 14,
  '9C9D': 14,
  '9C9E': 14,
  '9C9F': 14,
  '9CA0': 15,
  '9CA1': 15,
  '9CA2': 15,
  '9CA3': 15,
  '9CA4': 15,
  '9CA5': 15,
  '9CA6': 15,
  '9CA7': 15,
  '9CA8': 15,
  '9CA9': 15,
  '9CAA': 15,
  '9CAB': 15,
  '9CAC': 15,
  '9CAD': 16,
  '9CAE': 16,
  '9CAF': 16,
  '9CB0': 16,
  '9CB1': 16,
  '9CB2': 16,
  '9CB3': 16,
  '9CB4': 16,
  '9CB5': 16,
  '9CB6': 16,
  '9CB7': 16,
  '9CB8': 16,
  '9CB9': 16,
  '9CBA': 16,
  '9CBB': 16,
  '9CBC': 17,
  '9CBD': 17,
  '9CBE': 17,
  '9CBF': 17,
  '9CC0': 17,
  '9CC1': 17,
  '9CC2': 17,
  '9CC3': 17,
  '9CC4': 17,
  '9CC5': 17,
  '9CC6': 17,
  '9CC7': 17,
  '9CC8': 17,
  '9CC9': 17,
  '9CCA': 17,
  '9CCB': 17,
  '9CCC': 18,
  '9CCD': 18,
  '9CCE': 18,
  '9CCF': 18,
  '9CD0': 18,
  '9CD1': 18,
  '9CD2': 18,
  '9CD3': 19,
  '9CD4': 19,
  '9CD5': 19,
  '9CD6': 19,
  '9CD7': 19,
  '9CD8': 19,
  '9CD9': 19,
  '9CDA': 19,
  '9CDB': 19,
  '9CDC': 20,
  '9CDD': 20,
  '9CDE': 20,
  '9CDF': 20,
  '9CE0': 21,
  '9CE1': 21,
  '9CE2': 21,
  '9CE3': 21,
  '9CE4': 22,
  '9CE5': 11,
  '9CE6': 12,
  '9CE7': 13,
  '9CE8': 13,
  '9CE9': 13,
  '9CEA': 13,
  '9CEB': 13,
  '9CEC': 9,
  '9CED': 13,
  '9CEE': 13,
  '9CEF': 13,
  '9CF0': 13,
  '9CF1': 14,
  '9CF2': 14,
  '9CF3': 14,
  '9CF4': 14,
  '9CF5': 14,
  '9CF6': 14,
  '9CF7': 15,
  '9CF8': 15,
  '9CF9': 15,
  '9CFA': 15,
  '9CFB': 15,
  '9CFC': 15,
  '9CFD': 15,
  '9CFE': 15,
  '9CFF': 15,
  '9D00': 15,
  '9D01': 15,
  '9D02': 15,
  '9D03': 15,
  '9D04': 15,
  '9D05': 15,
  '9D06': 15,
  '9D07': 15,
  '9D08': 15,
  '9D09': 15,
  '9D0A': 16,
  '9D0B': 15,
  '9D0C': 15,
  '9D0D': 15,
  '9D0E': 15,
  '9D0F': 16,
  '9D10': 16,
  '9D11': 16,
  '9D12': 16,
  '9D13': 16,
  '9D14': 15,
  '9D15': 16,
  '9D16': 16,
  '9D17': 16,
  '9D18': 16,
  '9D19': 16,
  '9D1A': 16,
  '9D1B': 16,
  '9D1C': 17,
  '9D1D': 16,
  '9D1E': 16,
  '9D1F': 16,
  '9D20': 16,
  '9D21': 16,
  '9D22': 16,
  '9D23': 16,
  '9D24': 16,
  '9D25': 16,
  '9D26': 16,
  '9D27': 16,
  '9D28': 16,
  '9D29': 16,
  '9D2A': 16,
  '9D2B': 16,
  '9D2C': 16,
  '9D2D': 17,
  '9D2E': 17,
  '9D2F': 17,
  '9D30': 17,
  '9D31': 16,
  '9D32': 17,
  '9D33': 17,
  '9D34': 17,
  '9D35': 17,
  '9D36': 17,
  '9D37': 17,
  '9D38': 17,
  '9D39': 17,
  '9D3A': 17,
  '9D3B': 17,
  '9D3C': 17,
  '9D3D': 17,
  '9D3E': 17,
  '9D3F': 17,
  '9D40': 17,
  '9D41': 17,
  '9D42': 17,
  '9D43': 17,
  '9D44': 17,
  '9D45': 17,
  '9D46': 17,
  '9D47': 17,
  '9D48': 17,
  '9D49': 17,
  '9D4A': 18,
  '9D4B': 18,
  '9D4C': 18,
  '9D4D': 18,
  '9D4E': 18,
  '9D4F': 18,
  '9D50': 18,
  '9D51': 18,
  '9D52': 18,
  '9D53': 18,
  '9D54': 18,
  '9D55': 18,
  '9D56': 16,
  '9D57': 18,
  '9D58': 18,
  '9D59': 18,
  '9D5A': 18,
  '9D5B': 18,
  '9D5C': 18,
  '9D5D': 18,
  '9D5E': 18,
  '9D5F': 18,
  '9D60': 18,
  '9D61': 19,
  '9D62': 18,
  '9D63': 18,
  '9D64': 18,
  '9D65': 18,
  '9D66': 19,
  '9D67': 17,
  '9D68': 19,
  '9D69': 19,
  '9D6A': 19,
  '9D6B': 19,
  '9D6C': 19,
  '9D6D': 19,
  '9D6E': 19,
  '9D6F': 19,
  '9D70': 19,
  '9D71': 19,
  '9D72': 19,
  '9D73': 19,
  '9D74': 19,
  '9D75': 19,
  '9D76': 19,
  '9D77': 19,
  '9D78': 19,
  '9D79': 19,
  '9D7A': 19,
  '9D7B': 19,
  '9D7C': 19,
  '9D7D': 19,
  '9D7E': 19,
  '9D7F': 19,
  '9D80': 19,
  '9D81': 19,
  '9D82': 19,
  '9D83': 19,
  '9D84': 19,
  '9D85': 19,
  '9D86': 19,
  '9D87': 19,
  '9D88': 19,
  '9D89': 19,
  '9D8A': 19,
  '9D8B': 19,
  '9D8C': 19,
  '9D8D': 19,
  '9D8E': 19,
  '9D8F': 19,
  '9D90': 19,
  '9D91': 19,
  '9D92': 20,
  '9D93': 19,
  '9D94': 20,
  '9D95': 20,
  '9D96': 20,
  '9D97': 20,
  '9D98': 20,
  '9D99': 20,
  '9D9A': 20,
  '9D9B': 20,
  '9D9C': 19,
  '9D9D': 20,
  '9D9E': 20,
  '9D9F': 20,
  '9DA0': 20,
  '9DA1': 20,
  '9DA2': 20,
  '9DA3': 20,
  '9DA4': 20,
  '9DA5': 20,
  '9DA6': 20,
  '9DA7': 19,
  '9DA8': 20,
  '9DA9': 20,
  '9DAA': 20,
  '9DAB': 20,
  '9DAC': 21,
  '9DAD': 21,
  '9DAE': 21,
  '9DAF': 21,
  '9DB0': 21,
  '9DB1': 21,
  '9DB2': 21,
  '9DB3': 21,
  '9DB4': 21,
  '9DB5': 21,
  '9DB6': 21,
  '9DB7': 21,
  '9DB8': 21,
  '9DB9': 21,
  '9DBA': 21,
  '9DBB': 20,
  '9DBC': 21,
  '9DBD': 21,
  '9DBE': 21,
  '9DBF': 20,
  '9DC0': 20,
  '9DC1': 21,
  '9DC2': 21,
  '9DC3': 21,
  '9DC4': 21,
  '9DC5': 21,
  '9DC6': 21,
  '9DC7': 21,
  '9DC8': 21,
  '9DC9': 21,
  '9DCA': 21,
  '9DCB': 21,
  '9DCC': 21,
  '9DCD': 21,
  '9DCE': 21,
  '9DCF': 21,
  '9DD0': 22,
  '9DD1': 22,
  '9DD2': 22,
  '9DD3': 22,
  '9DD4': 21,
  '9DD5': 22,
  '9DD6': 22,
  '9DD7': 22,
  '9DD8': 22,
  '9DD9': 22,
  '9DDA': 22,
  '9DDB': 22,
  '9DDC': 22,
  '9DDD': 21,
  '9DDE': 22,
  '9DDF': 22,
  '9DE0': 22,
  '9DE1': 23,
  '9DE2': 23,
  '9DE3': 23,
  '9DE4': 23,
  '9DE5': 23,
  '9DE6': 23,
  '9DE7': 23,
  '9DE8': 21,
  '9DE9': 22,
  '9DEA': 23,
  '9DEB': 24,
  '9DEC': 22,
  '9DED': 23,
  '9DEE': 23,
  '9DEF': 23,
  '9DF0': 23,
  '9DF1': 23,
  '9DF2': 23,
  '9DF3': 23,
  '9DF4': 23,
  '9DF5': 22,
  '9DF6': 23,
  '9DF7': 23,
  '9DF8': 23,
  '9DF9': 24,
  '9DFA': 24,
  '9DFB': 23,
  '9DFC': 23,
  '9DFD': 24,
  '9DFE': 24,
  '9DFF': 24,
  '9E00': 24,
  '9E01': 24,
  '9E02': 24,
  '9E03': 24,
  '9E04': 24,
  '9E05': 24,
  '9E06': 24,
  '9E07': 24,
  '9E08': 24,
  '9E09': 24,
  '9E0A': 24,
  '9E0B': 25,
  '9E0C': 24,
  '9E0D': 25,
  '9E0E': 25,
  '9E0F': 24,
  '9E10': 25,
  '9E11': 25,
  '9E12': 24,
  '9E13': 26,
  '9E14': 26,
  '9E15': 27,
  '9E16': 27,
  '9E17': 27,
  '9E18': 28,
  '9E19': 28,
  '9E1A': 28,
  '9E1B': 28,
  '9E1C': 29,
  '9E1D': 30,
  '9E1E': 30,
  '9E1F': 5,
  '9E20': 7,
  '9E21': 7,
  '9E22': 8,
  '9E23': 8,
  '9E24': 8,
  '9E25': 9,
  '9E26': 9,
  '9E27': 9,
  '9E28': 9,
  '9E29': 9,
  '9E2A': 10,
  '9E2B': 10,
  '9E2C': 10,
  '9E2D': 10,
  '9E2E': 10,
  '9E2F': 10,
  '9E30': 10,
  '9E31': 10,
  '9E32': 10,
  '9E33': 10,
  '9E34': 10,
  '9E35': 10,
  '9E36': 10,
  '9E37': 11,
  '9E38': 11,
  '9E39': 11,
  '9E3A': 11,
  '9E3B': 11,
  '9E3C': 11,
  '9E3D': 11,
  '9E3E': 11,
  '9E3F': 11,
  '9E40': 12,
  '9E41': 12,
  '9E42': 12,
  '9E43': 12,
  '9E44': 12,
  '9E45': 12,
  '9E46': 12,
  '9E47': 12,
  '9E48': 12,
  '9E49': 13,
  '9E4A': 13,
  '9E4B': 13,
  '9E4C': 13,
  '9E4D': 13,
  '9E4E': 13,
  '9E4F': 13,
  '9E50': 13,
  '9E51': 13,
  '9E52': 13,
  '9E53': 13,
  '9E54': 13,
  '9E55': 14,
  '9E56': 14,
  '9E57': 14,
  '9E58': 14,
  '9E59': 14,
  '9E5A': 14,
  '9E5B': 14,
  '9E5C': 14,
  '9E5D': 15,
  '9E5E': 15,
  '9E5F': 15,
  '9E60': 15,
  '9E61': 15,
  '9E62': 15,
  '9E63': 15,
  '9E64': 15,
  '9E65': 16,
  '9E66': 16,
  '9E67': 16,
  '9E68': 16,
  '9E69': 17,
  '9E6A': 17,
  '9E6B': 17,
  '9E6C': 17,
  '9E6D': 18,
  '9E6E': 18,
  '9E6F': 18,
  '9E70': 18,
  '9E71': 18,
  '9E72': 18,
  '9E73': 22,
  '9E74': 22,
  '9E75': 11,
  '9E76': 15,
  '9E77': 16,
  '9E78': 19,
  '9E79': 20,
  '9E7A': 20,
  '9E7B': 21,
  '9E7C': 24,
  '9E7D': 24,
  '9E7E': 16,
  '9E7F': 11,
  '9E80': 13,
  '9E81': 13,
  '9E82': 13,
  '9E83': 15,
  '9E84': 15,
  '9E85': 16,
  '9E86': 16,
  '9E87': 16,
  '9E88': 16,
  '9E89': 17,
  '9E8A': 17,
  '9E8B': 17,
  '9E8C': 18,
  '9E8D': 18,
  '9E8E': 18,
  '9E8F': 18,
  '9E90': 18,
  '9E91': 19,
  '9E92': 19,
  '9E93': 19,
  '9E94': 19,
  '9E95': 19,
  '9E96': 19,
  '9E97': 19,
  '9E98': 20,
  '9E99': 20,
  '9E9A': 20,
  '9E9B': 20,
  '9E9C': 21,
  '9E9D': 21,
  '9E9E': 22,
  '9E9F': 23,
  '9EA0': 24,
  '9EA1': 25,
  '9EA2': 28,
  '9EA3': 30,
  '9EA4': 33,
  '9EA5': 11,
  '9EA6': 7,
  '9EA7': 14,
  '9EA8': 15,
  '9EA9': 15,
  '9EAA': 15,
  '9EAB': 15,
  '9EAC': 16,
  '9EAD': 16,
  '9EAE': 16,
  '9EAF': 17,
  '9EB0': 17,
  '9EB1': 18,
  '9EB2': 18,
  '9EB3': 19,
  '9EB4': 19,
  '9EB5': 20,
  '9EB6': 21,
  '9EB7': 29,
  '9EB8': 11,
  '9EB9': 15,
  '9EBA': 16,
  '9EBB': 11,
  '9EBC': 14,
  '9EBD': 14,
  '9EBE': 15,
  '9EBF': 17,
  '9EC0': 19,
  '9EC1': 20,
  '9EC2': 23,
  '9EC3': 12,
  '9EC4': 11,
  '9EC5': 15,
  '9EC6': 15,
  '9EC7': 16,
  '9EC8': 16,
  '9EC9': 16,
  '9ECA': 17,
  '9ECB': 17,
  '9ECC': 24,
  '9ECD': 12,
  '9ECE': 15,
  '9ECF': 17,
  '9ED0': 22,
  '9ED1': 12,
  '9ED2': 11,
  '9ED3': 15,
  '9ED4': 16,
  '9ED5': 16,
  '9ED6': 16,
  '9ED7': 16,
  '9ED8': 16,
  '9ED9': 15,
  '9EDA': 17,
  '9EDB': 17,
  '9EDC': 17,
  '9EDD': 17,
  '9EDE': 17,
  '9EDF': 18,
  '9EE0': 18,
  '9EE1': 18,
  '9EE2': 19,
  '9EE3': 19,
  '9EE4': 20,
  '9EE5': 20,
  '9EE6': 20,
  '9EE7': 20,
  '9EE8': 20,
  '9EE9': 20,
  '9EEA': 20,
  '9EEB': 21,
  '9EEC': 21,
  '9EED': 21,
  '9EEE': 21,
  '9EEF': 21,
  '9EF0': 22,
  '9EF1': 22,
  '9EF2': 23,
  '9EF3': 23,
  '9EF4': 23,
  '9EF5': 25,
  '9EF6': 26,
  '9EF7': 27,
  '9EF8': 28,
  '9EF9': 12,
  '9EFA': 16,
  '9EFB': 17,
  '9EFC': 19,
  '9EFD': 13,
  '9EFE': 8,
  '9EFF': 17,
  '9F00': 18,
  '9F01': 18,
  '9F02': 18,
  '9F03': 19,
  '9F04': 19,
  '9F05': 21,
  '9F06': 23,
  '9F07': 23,
  '9F08': 24,
  '9F09': 25,
  '9F0A': 26,
  '9F0B': 12,
  '9F0C': 13,
  '9F0D': 20,
  '9F0E': 12,
  '9F0F': 14,
  '9F10': 14,
  '9F11': 14,
  '9F12': 15,
  '9F13': 13,
  '9F14': 13,
  '9F15': 18,
  '9F16': 18,
  '9F17': 19,
  '9F18': 22,
  '9F19': 21,
  '9F1A': 21,
  '9F1B': 21,
  '9F1C': 22,
  '9F1D': 25,
  '9F1E': 24,
  '9F1F': 25,
  '9F20': 13,
  '9F21': 8,
  '9F22': 17,
  '9F23': 17,
  '9F24': 17,
  '9F25': 18,
  '9F26': 18,
  '9F27': 18,
  '9F28': 18,
  '9F29': 18,
  '9F2A': 18,
  '9F2B': 18,
  '9F2C': 18,
  '9F2D': 19,
  '9F2E': 19,
  '9F2F': 20,
  '9F30': 20,
  '9F31': 21,
  '9F32': 22,
  '9F33': 22,
  '9F34': 22,
  '9F35': 22,
  '9F36': 23,
  '9F37': 23,
  '9F38': 23,
  '9F39': 23,
  '9F3A': 28,
  '9F3B': 14,
  '9F3C': 16,
  '9F3D': 16,
  '9F3E': 17,
  '9F3F': 17,
  '9F40': 18,
  '9F41': 19,
  '9F42': 22,
  '9F43': 23,
  '9F44': 23,
  '9F45': 24,
  '9F46': 24,
  '9F47': 25,
  '9F48': 27,
  '9F49': 36,
  '9F4A': 14,
  '9F4B': 17,
  '9F4C': 18,
  '9F4D': 19,
  '9F4E': 21,
  '9F4F': 23,
  '9F50': 6,
  '9F51': 15,
  '9F52': 15,
  '9F53': 16,
  '9F54': 17,
  '9F55': 18,
  '9F56': 19,
  '9F57': 19,
  '9F58': 19,
  '9F59': 20,
  '9F5A': 20,
  '9F5B': 20,
  '9F5C': 21,
  '9F5D': 20,
  '9F5E': 20,
  '9F5F': 20,
  '9F60': 20,
  '9F61': 20,
  '9F62': 17,
  '9F63': 20,
  '9F64': 21,
  '9F65': 21,
  '9F66': 21,
  '9F67': 21,
  '9F68': 21,
  '9F69': 21,
  '9F6A': 22,
  '9F6B': 22,
  '9F6C': 22,
  '9F6D': 23,
  '9F6E': 23,
  '9F6F': 23,
  '9F70': 23,
  '9F71': 23,
  '9F72': 24,
  '9F73': 24,
  '9F74': 24,
  '9F75': 24,
  '9F76': 24,
  '9F77': 24,
  '9F78': 25,
  '9F79': 24,
  '9F7A': 25,
  '9F7B': 25,
  '9F7C': 28,
  '9F7D': 28,
  '9F7E': 35,
  '9F7F': 8,
  '9F80': 10,
  '9F81': 11,
  '9F82': 12,
  '9F83': 13,
  '9F84': 13,
  '9F85': 13,
  '9F86': 13,
  '9F87': 14,
  '9F88': 14,
  '9F89': 15,
  '9F8A': 15,
  '9F8B': 17,
  '9F8C': 17,
  '9F8D': 16,
  '9F8E': 18,
  '9F8F': 19,
  '9F90': 19,
  '9F91': 20,
  '9F92': 21,
  '9F93': 22,
  '9F94': 22,
  '9F95': 22,
  '9F96': 32,
  '9F97': 33,
  '9F98': 48,
  '9F99': 5,
  '9F9A': 11,
  '9F9B': 11,
  '9F9C': 17,
  '9F9D': 22,
  '9F9E': 28,
  '9F9F': 7,
  '9FA0': 17,
  '9FA1': 21,
  '9FA2': 22,
  '9FA3': 25,
  '9FA4': 26,
  '9FA5': 26,
  '9FA6': 15,
  '9FA7': 16,
  '9FA8': 10,
  '9FA9': 17,
  '9FAA': 9,
  '9FAB': 12,
  '9FAC': 16,
  '9FAD': 21,
  '9FAE': 27,
  '9FAF': 13,
  '9FB0': 4,
  '9FB1': 5,
  '9FB2': 18,
  '9FB3': 16,
  '9FB4': 2,
  '9FB5': 3,
  '9FB6': 4,
  '9FB7': 5,
  '9FB8': 5,
  '9FB9': 6,
  '9FBA': 8,
  '9FBB': 19,
  '9FBC': 17,
  '9FBD': 15,
  '9FBE': 13,
  '9FBF': 11,
  '9FC0': 21,
  '9FC1': 13,
  '9FC2': 18,
  '9FC3': 12,
  '9FC4': 10,
  '9FC5': 17,
  '9FC6': 8,
  '9FC7': 8,
  '9FC8': 7,
  '9FC9': 7,
  '9FCA': 11,
  '9FCB': 17,
  '9FCC': 12,
  F900: 10,
  F901: 7,
  F902: 7,
  F903: 13,
  F904: 13,
  F905: 7,
  F906: 5,
  F907: 16,
  F908: 16,
  F909: 9,
  F90A: 8,
  F90B: 12,
  F90C: 8,
  F90D: 19,
  F90E: 21,
  F90F: 19,
  F910: 25,
  F911: 17,
  F912: 14,
  F913: 23,
  F914: 15,
  F915: 9,
  F916: 10,
  F917: 10,
  F918: 15,
  F919: 13,
  F91A: 16,
  F91B: 13,
  F91C: 7,
  F91D: 21,
  F91E: 21,
  F91F: 23,
  F920: 30,
  F921: 12,
  F922: 17,
  F923: 20,
  F924: 20,
  F925: 8,
  F926: 19,
  F927: 21,
  F928: 13,
  F929: 10,
  F92A: 10,
  F92B: 10,
  F92C: 10,
  F92D: 8,
  F92E: 7,
  F92F: 12,
  F930: 16,
  F931: 19,
  F932: 20,
  F933: 16,
  F934: 6,
  F935: 22,
  F936: 12,
  F937: 13,
  F938: 20,
  F939: 15,
  F93A: 23,
  F93B: 13,
  F93C: 13,
  F93D: 14,
  F93E: 14,
  F93F: 16,
  F940: 11,
  F941: 15,
  F942: 19,
  F943: 7,
  F944: 22,
  F945: 22,
  F946: 7,
  F947: 15,
  F948: 13,
  F949: 13,
  F94A: 18,
  F94B: 14,
  F94C: 15,
  F94D: 11,
  F94E: 14,
  F94F: 11,
  F950: 17,
  F951: 9,
  F952: 11,
  F953: 6,
  F954: 15,
  F955: 10,
  F956: 13,
  F957: 14,
  F958: 14,
  F959: 11,
  F95A: 22,
  F95B: 8,
  F95C: 15,
  F95D: 16,
  F95E: 4,
  F95F: 14,
  F960: 8,
  F961: 11,
  F962: 12,
  F963: 5,
  F964: 17,
  F965: 9,
  F966: 12,
  F967: 4,
  F968: 8,
  F969: 15,
  F96A: 10,
  F96B: 11,
  F96C: 13,
  F96D: 9,
  F96E: 15,
  F96F: 14,
  F970: 11,
  F971: 7,
  F972: 7,
  F973: 9,
  F974: 11,
  F975: 11,
  F976: 11,
  F977: 9,
  F978: 8,
  F979: 10,
  F97A: 11,
  F97B: 18,
  F97C: 7,
  F97D: 15,
  F97E: 12,
  F97F: 17,
  F980: 7,
  F981: 3,
  F982: 19,
  F983: 10,
  F984: 18,
  F985: 20,
  F986: 15,
  F987: 29,
  F988: 19,
  F989: 15,
  F98A: 2,
  F98B: 16,
  F98C: 16,
  F98D: 22,
  F98E: 6,
  F98F: 15,
  F990: 22,
  F991: 15,
  F992: 14,
  F993: 13,
  F994: 15,
  F995: 8,
  F996: 15,
  F997: 17,
  F998: 15,
  F999: 17,
  F99A: 11,
  F99B: 17,
  F99C: 6,
  F99D: 6,
  F99E: 9,
  F99F: 10,
  F9A0: 12,
  F9A1: 14,
  F9A2: 13,
  F9A3: 7,
  F9A4: 11,
  F9A5: 17,
  F9A6: 19,
  F9A7: 18,
  F9A8: 5,
  F9A9: 8,
  F9AA: 14,
  F9AB: 17,
  F9AC: 8,
  F9AD: 9,
  F9AE: 14,
  F9AF: 11,
  F9B0: 11,
  F9B1: 13,
  F9B2: 13,
  F9B3: 24,
  F9B4: 14,
  F9B5: 8,
  F9B6: 18,
  F9B7: 20,
  F9B8: 16,
  F9B9: 11,
  F9BA: 2,
  F9BB: 14,
  F9BC: 15,
  F9BD: 7,
  F9BE: 10,
  F9BF: 15,
  F9C0: 16,
  F9C1: 17,
  F9C2: 17,
  F9C3: 16,
  F9C4: 16,
  F9C5: 13,
  F9C6: 7,
  F9C7: 15,
  F9C8: 8,
  F9C9: 9,
  F9CA: 10,
  F9CB: 13,
  F9CC: 11,
  F9CD: 10,
  F9CE: 12,
  F9CF: 10,
  F9D0: 19,
  F9D1: 4,
  F9D2: 15,
  F9D3: 11,
  F9D4: 10,
  F9D5: 11,
  F9D6: 11,
  F9D7: 15,
  F9D8: 9,
  F9D9: 13,
  F9DA: 10,
  F9DB: 11,
  F9DC: 12,
  F9DD: 7,
  F9DE: 6,
  F9DF: 15,
  F9E0: 8,
  F9E1: 7,
  F9E2: 11,
  F9E3: 8,
  F9E4: 11,
  F9E5: 12,
  F9E6: 16,
  F9E7: 13,
  F9E8: 13,
  F9E9: 7,
  F9EA: 19,
  F9EB: 11,
  F9EC: 13,
  F9ED: 7,
  F9EE: 16,
  F9EF: 16,
  F9F0: 22,
  F9F1: 15,
  F9F2: 23,
  F9F3: 23,
  F9F4: 8,
  F9F5: 11,
  F9F6: 17,
  F9F7: 5,
  F9F8: 11,
  F9F9: 11,
  F9FA: 7,
  F9FB: 8,
  F9FC: 19,
  F9FD: 4,
  F9FE: 12,
  F9FF: 8,
  FA00: 4,
  FA01: 9,
  FA02: 8,
  FA03: 16,
  FA04: 6,
  FA05: 9,
  FA06: 15,
  FA07: 16,
  FA08: 6,
  FA09: 9,
  FA0A: 7,
  FA0B: 14,
  FA0C: 3,
  FA0D: 13,
  FA0E: 13,
  FA0F: 10,
  FA10: 13,
  FA11: 12,
  FA12: 12,
  FA13: 13,
  FA14: 14,
  FA15: 15,
  FA16: 11,
  FA17: 10,
  FA18: 6,
  FA19: 10,
  FA1A: 11,
  FA1B: 14,
  FA1C: 13,
  FA1D: 14,
  FA1E: 6,
  FA1F: 19,
  FA20: 27,
  FA21: 11,
  FA22: 16,
  FA23: 11,
  FA24: 8,
  FA25: 12,
  FA26: 12,
  FA27: 15,
  FA28: 16,
  FA29: 13,
  FA2A: 13,
  FA2B: 14,
  FA2C: 17,
  FA2D: 22,
  '20000': 2,
  '20001': 2,
  '20002': 2,
  '20003': 3,
  '20004': 3,
  '20005': 3,
  '20006': 3,
  '20007': 4,
  '20008': 4,
  '20009': 4,
  '2000A': 4,
  '2000B': 4,
  '2000C': 4,
  '2000D': 5,
  '2000E': 5,
  '2000F': 5,
  '20010': 5,
  '20011': 5,
  '20012': 5,
  '20013': 5,
  '20014': 5,
  '20015': 5,
  '20016': 5,
  '20017': 5,
  '20018': 6,
  '20019': 6,
  '2001A': 6,
  '2001B': 6,
  '2001C': 6,
  '2001D': 6,
  '2001E': 6,
  '2001F': 6,
  '20020': 6,
  '20021': 7,
  '20022': 7,
  '20023': 7,
  '20024': 7,
  '20025': 7,
  '20026': 7,
  '20027': 7,
  '20028': 7,
  '20029': 6,
  '2002A': 7,
  '2002B': 7,
  '2002C': 8,
  '2002D': 8,
  '2002E': 8,
  '2002F': 8,
  '20030': 8,
  '20031': 8,
  '20032': 8,
  '20033': 8,
  '20034': 8,
  '20035': 9,
  '20036': 9,
  '20037': 9,
  '20038': 9,
  '20039': 9,
  '2003A': 9,
  '2003B': 9,
  '2003C': 10,
  '2003D': 10,
  '2003E': 10,
  '2003F': 10,
  '20040': 10,
  '20041': 11,
  '20042': 11,
  '20043': 11,
  '20044': 11,
  '20045': 11,
  '20046': 12,
  '20047': 12,
  '20048': 12,
  '20049': 12,
  '2004A': 12,
  '2004B': 12,
  '2004C': 13,
  '2004D': 13,
  '2004E': 14,
  '2004F': 14,
  '20050': 14,
  '20051': 14,
  '20052': 14,
  '20053': 15,
  '20054': 15,
  '20055': 16,
  '20056': 17,
  '20057': 16,
  '20058': 16,
  '20059': 16,
  '2005A': 16,
  '2005B': 16,
  '2005C': 17,
  '2005D': 16,
  '2005E': 17,
  '2005F': 18,
  '20060': 20,
  '20061': 2,
  '20062': 2,
  '20063': 4,
  '20064': 5,
  '20065': 5,
  '20066': 6,
  '20067': 7,
  '20068': 7,
  '20069': 8,
  '2006A': 8,
  '2006B': 8,
  '2006C': 8,
  '2006D': 9,
  '2006E': 9,
  '2006F': 9,
  '20070': 10,
  '20071': 10,
  '20072': 6,
  '20073': 11,
  '20074': 12,
  '20075': 12,
  '20076': 14,
  '20077': 14,
  '20078': 15,
  '20079': 18,
  '2007A': 19,
  '2007B': 22,
  '2007C': 3,
  '2007D': 3,
  '2007E': 4,
  '2007F': 4,
  '20080': 5,
  '20081': 5,
  '20082': 6,
  '20083': 10,
  '20084': 13,
  '20085': 16,
  '20086': 2,
  '20087': 2,
  '20088': 2,
  '20089': 2,
  '2008A': 2,
  '2008B': 3,
  '2008C': 3,
  '2008D': 3,
  '2008E': 3,
  '2008F': 4,
  '20090': 4,
  '20091': 4,
  '20092': 4,
  '20093': 4,
  '20094': 5,
  '20095': 5,
  '20096': 5,
  '20097': 5,
  '20098': 5,
  '20099': 5,
  '2009A': 5,
  '2009B': 5,
  '2009C': 5,
  '2009D': 5,
  '2009E': 5,
  '2009F': 5,
  '200A0': 5,
  '200A1': 5,
  '200A2': 6,
  '200A3': 6,
  '200A4': 6,
  '200A5': 6,
  '200A6': 6,
  '200A7': 6,
  '200A8': 6,
  '200A9': 6,
  '200AA': 6,
  '200AB': 6,
  '200AC': 6,
  '200AD': 6,
  '200AE': 6,
  '200AF': 7,
  '200B0': 7,
  '200B1': 7,
  '200B2': 8,
  '200B3': 8,
  '200B4': 9,
  '200B5': 8,
  '200B6': 8,
  '200B7': 9,
  '200B8': 9,
  '200B9': 10,
  '200BA': 10,
  '200BB': 10,
  '200BC': 10,
  '200BD': 10,
  '200BE': 10,
  '200BF': 11,
  '200C0': 11,
  '200C1': 11,
  '200C2': 11,
  '200C3': 12,
  '200C4': 12,
  '200C5': 14,
  '200C6': 13,
  '200C7': 15,
  '200C8': 23,
  '200C9': 1,
  '200CA': 1,
  '200CB': 1,
  '200CC': 1,
  '200CD': 1,
  '200CE': 1,
  '200CF': 2,
  '200D0': 2,
  '200D1': 1,
  '200D2': 3,
  '200D3': 3,
  '200D4': 3,
  '200D5': 4,
  '200D6': 4,
  '200D7': 4,
  '200D8': 4,
  '200D9': 4,
  '200DA': 4,
  '200DB': 4,
  '200DC': 4,
  '200DD': 4,
  '200DE': 5,
  '200DF': 5,
  '200E0': 5,
  '200E1': 5,
  '200E2': 5,
  '200E3': 5,
  '200E4': 6,
  '200E5': 6,
  '200E6': 6,
  '200E7': 6,
  '200E8': 6,
  '200E9': 7,
  '200EA': 7,
  '200EB': 7,
  '200EC': 7,
  '200ED': 8,
  '200EE': 8,
  '200EF': 8,
  '200F0': 8,
  '200F1': 8,
  '200F2': 9,
  '200F3': 9,
  '200F4': 9,
  '200F5': 9,
  '200F6': 9,
  '200F7': 9,
  '200F8': 10,
  '200F9': 10,
  '200FA': 10,
  '200FB': 10,
  '200FC': 11,
  '200FD': 11,
  '200FE': 11,
  '200FF': 11,
  '20100': 11,
  '20101': 11,
  '20102': 11,
  '20103': 13,
  '20104': 13,
  '20105': 14,
  '20106': 14,
  '20107': 14,
  '20108': 14,
  '20109': 17,
  '2010A': 19,
  '2010B': 20,
  '2010C': 1,
  '2010D': 2,
  '2010E': 2,
  '2010F': 2,
  '20110': 2,
  '20111': 3,
  '20112': 4,
  '20113': 4,
  '20114': 4,
  '20115': 6,
  '20116': 7,
  '20117': 7,
  '20118': 7,
  '20119': 8,
  '2011A': 11,
  '2011B': 12,
  '2011C': 15,
  '2011D': 16,
  '2011E': 2,
  '2011F': 2,
  '20120': 2,
  '20121': 4,
  '20122': 6,
  '20123': 6,
  '20124': 6,
  '20125': 6,
  '20126': 6,
  '20127': 6,
  '20128': 7,
  '20129': 7,
  '2012A': 7,
  '2012B': 8,
  '2012C': 8,
  '2012D': 8,
  '2012E': 8,
  '2012F': 8,
  '20130': 9,
  '20131': 9,
  '20132': 9,
  '20133': 9,
  '20134': 9,
  '20135': 10,
  '20136': 10,
  '20137': 10,
  '20138': 10,
  '20139': 11,
  '2013A': 11,
  '2013B': 12,
  '2013C': 13,
  '2013D': 13,
  '2013E': 13,
  '2013F': 14,
  '20140': 15,
  '20141': 4,
  '20142': 5,
  '20143': 6,
  '20144': 6,
  '20145': 6,
  '20146': 6,
  '20147': 6,
  '20148': 7,
  '20149': 7,
  '2014A': 7,
  '2014B': 7,
  '2014C': 8,
  '2014D': 8,
  '2014E': 8,
  '2014F': 8,
  '20150': 8,
  '20151': 8,
  '20152': 8,
  '20153': 9,
  '20154': 9,
  '20155': 9,
  '20156': 10,
  '20157': 10,
  '20158': 10,
  '20159': 10,
  '2015A': 10,
  '2015B': 10,
  '2015C': 10,
  '2015D': 10,
  '2015E': 11,
  '2015F': 11,
  '20160': 11,
  '20161': 11,
  '20162': 11,
  '20163': 11,
  '20164': 11,
  '20165': 11,
  '20166': 11,
  '20167': 11,
  '20168': 12,
  '20169': 12,
  '2016A': 12,
  '2016B': 12,
  '2016C': 12,
  '2016D': 12,
  '2016E': 12,
  '2016F': 12,
  '20170': 12,
  '20171': 12,
  '20172': 12,
  '20173': 13,
  '20174': 13,
  '20175': 13,
  '20176': 13,
  '20177': 13,
  '20178': 13,
  '20179': 13,
  '2017A': 13,
  '2017B': 13,
  '2017C': 13,
  '2017D': 13,
  '2017E': 14,
  '2017F': 14,
  '20180': 13,
  '20181': 15,
  '20182': 15,
  '20183': 15,
  '20184': 15,
  '20185': 15,
  '20186': 15,
  '20187': 15,
  '20188': 15,
  '20189': 15,
  '2018A': 15,
  '2018B': 16,
  '2018C': 16,
  '2018D': 16,
  '2018E': 16,
  '2018F': 16,
  '20190': 16,
  '20191': 16,
  '20192': 16,
  '20193': 16,
  '20194': 16,
  '20195': 16,
  '20196': 16,
  '20197': 16,
  '20198': 16,
  '20199': 17,
  '2019A': 18,
  '2019B': 18,
  '2019C': 18,
  '2019D': 19,
  '2019E': 21,
  '2019F': 24,
  '201A0': 28,
  '201A1': 29,
  '201A2': 2,
  '201A3': 3,
  '201A4': 3,
  '201A5': 3,
  '201A6': 4,
  '201A7': 4,
  '201A8': 4,
  '201A9': 5,
  '201AA': 5,
  '201AB': 5,
  '201AC': 5,
  '201AD': 5,
  '201AE': 5,
  '201AF': 5,
  '201B0': 5,
  '201B1': 5,
  '201B2': 5,
  '201B3': 5,
  '201B4': 6,
  '201B5': 6,
  '201B6': 6,
  '201B7': 6,
  '201B8': 6,
  '201B9': 6,
  '201BA': 6,
  '201BB': 6,
  '201BC': 6,
  '201BD': 6,
  '201BE': 6,
  '201BF': 6,
  '201C0': 6,
  '201C1': 6,
  '201C2': 6,
  '201C3': 6,
  '201C4': 6,
  '201C5': 6,
  '201C6': 6,
  '201C7': 6,
  '201C8': 6,
  '201C9': 6,
  '201CA': 6,
  '201CB': 6,
  '201CC': 6,
  '201CD': 6,
  '201CE': 6,
  '201CF': 6,
  '201D0': 6,
  '201D1': 6,
  '201D2': 6,
  '201D3': 6,
  '201D4': 6,
  '201D5': 6,
  '201D6': 7,
  '201D7': 7,
  '201D8': 7,
  '201D9': 7,
  '201DA': 7,
  '201DB': 7,
  '201DC': 7,
  '201DD': 7,
  '201DE': 7,
  '201DF': 7,
  '201E0': 7,
  '201E1': 7,
  '201E2': 7,
  '201E3': 7,
  '201E4': 7,
  '201E5': 7,
  '201E6': 7,
  '201E7': 7,
  '201E8': 7,
  '201E9': 7,
  '201EA': 7,
  '201EB': 7,
  '201EC': 7,
  '201ED': 7,
  '201EE': 7,
  '201EF': 7,
  '201F0': 7,
  '201F1': 7,
  '201F2': 7,
  '201F3': 7,
  '201F4': 7,
  '201F5': 7,
  '201F6': 7,
  '201F7': 7,
  '201F8': 7,
  '201F9': 7,
  '201FA': 7,
  '201FB': 7,
  '201FC': 7,
  '201FD': 7,
  '201FE': 7,
  '201FF': 7,
  '20200': 7,
  '20201': 7,
  '20202': 7,
  '20203': 8,
  '20204': 8,
  '20205': 8,
  '20206': 8,
  '20207': 8,
  '20208': 8,
  '20209': 8,
  '2020A': 8,
  '2020B': 8,
  '2020C': 8,
  '2020D': 8,
  '2020E': 8,
  '2020F': 8,
  '20210': 8,
  '20211': 8,
  '20212': 8,
  '20213': 8,
  '20214': 8,
  '20215': 8,
  '20216': 8,
  '20217': 8,
  '20218': 8,
  '20219': 8,
  '2021A': 8,
  '2021B': 8,
  '2021C': 8,
  '2021D': 8,
  '2021E': 8,
  '2021F': 8,
  '20220': 8,
  '20221': 8,
  '20222': 8,
  '20223': 8,
  '20224': 8,
  '20225': 8,
  '20226': 8,
  '20227': 8,
  '20228': 8,
  '20229': 8,
  '2022A': 8,
  '2022B': 8,
  '2022C': 8,
  '2022D': 9,
  '2022E': 9,
  '2022F': 9,
  '20230': 9,
  '20231': 9,
  '20232': 9,
  '20233': 9,
  '20234': 9,
  '20235': 9,
  '20236': 9,
  '20237': 9,
  '20238': 9,
  '20239': 9,
  '2023A': 9,
  '2023B': 9,
  '2023C': 9,
  '2023D': 9,
  '2023E': 9,
  '2023F': 9,
  '20240': 9,
  '20241': 9,
  '20242': 9,
  '20243': 9,
  '20244': 9,
  '20245': 9,
  '20246': 9,
  '20247': 9,
  '20248': 9,
  '20249': 9,
  '2024A': 9,
  '2024B': 9,
  '2024C': 9,
  '2024D': 9,
  '2024E': 9,
  '2024F': 9,
  '20250': 9,
  '20251': 9,
  '20252': 9,
  '20253': 9,
  '20254': 9,
  '20255': 9,
  '20256': 9,
  '20257': 9,
  '20258': 9,
  '20259': 9,
  '2025A': 9,
  '2025B': 9,
  '2025C': 9,
  '2025D': 9,
  '2025E': 9,
  '2025F': 9,
  '20260': 9,
  '20261': 9,
  '20262': 9,
  '20263': 10,
  '20264': 10,
  '20265': 10,
  '20266': 10,
  '20267': 10,
  '20268': 10,
  '20269': 10,
  '2026A': 10,
  '2026B': 10,
  '2026C': 10,
  '2026D': 10,
  '2026E': 10,
  '2026F': 10,
  '20270': 10,
  '20271': 10,
  '20272': 10,
  '20273': 10,
  '20274': 10,
  '20275': 10,
  '20276': 10,
  '20277': 10,
  '20278': 10,
  '20279': 10,
  '2027A': 10,
  '2027B': 10,
  '2027C': 10,
  '2027D': 10,
  '2027E': 10,
  '2027F': 10,
  '20280': 10,
  '20281': 10,
  '20282': 10,
  '20283': 10,
  '20284': 10,
  '20285': 10,
  '20286': 10,
  '20287': 10,
  '20288': 10,
  '20289': 10,
  '2028A': 10,
  '2028B': 10,
  '2028C': 10,
  '2028D': 10,
  '2028E': 10,
  '2028F': 10,
  '20290': 10,
  '20291': 10,
  '20292': 10,
  '20293': 10,
  '20294': 10,
  '20295': 10,
  '20296': 10,
  '20297': 10,
  '20298': 10,
  '20299': 10,
  '2029A': 10,
  '2029B': 10,
  '2029C': 10,
  '2029D': 10,
  '2029E': 10,
  '2029F': 10,
  '202A0': 10,
  '202A1': 10,
  '202A2': 10,
  '202A3': 10,
  '202A4': 10,
  '202A5': 10,
  '202A6': 10,
  '202A7': 11,
  '202A8': 11,
  '202A9': 11,
  '202AA': 11,
  '202AB': 11,
  '202AC': 11,
  '202AD': 11,
  '202AE': 11,
  '202AF': 11,
  '202B0': 11,
  '202B1': 11,
  '202B2': 11,
  '202B3': 11,
  '202B4': 11,
  '202B5': 11,
  '202B6': 11,
  '202B7': 11,
  '202B8': 11,
  '202B9': 11,
  '202BA': 11,
  '202BB': 11,
  '202BC': 11,
  '202BD': 11,
  '202BE': 11,
  '202BF': 11,
  '202C0': 11,
  '202C1': 11,
  '202C2': 11,
  '202C3': 11,
  '202C4': 11,
  '202C5': 11,
  '202C6': 11,
  '202C7': 11,
  '202C8': 11,
  '202C9': 11,
  '202CA': 11,
  '202CB': 11,
  '202CC': 11,
  '202CD': 11,
  '202CE': 11,
  '202CF': 11,
  '202D0': 11,
  '202D1': 8,
  '202D2': 11,
  '202D3': 11,
  '202D4': 11,
  '202D5': 11,
  '202D6': 11,
  '202D7': 11,
  '202D8': 11,
  '202D9': 11,
  '202DA': 11,
  '202DB': 11,
  '202DC': 11,
  '202DD': 11,
  '202DE': 11,
  '202DF': 10,
  '202E0': 11,
  '202E1': 11,
  '202E2': 11,
  '202E3': 11,
  '202E4': 11,
  '202E5': 11,
  '202E6': 11,
  '202E7': 11,
  '202E8': 11,
  '202E9': 11,
  '202EA': 11,
  '202EB': 11,
  '202EC': 11,
  '202ED': 12,
  '202EE': 12,
  '202EF': 12,
  '202F0': 12,
  '202F1': 12,
  '202F2': 12,
  '202F3': 12,
  '202F4': 12,
  '202F5': 12,
  '202F6': 12,
  '202F7': 12,
  '202F8': 12,
  '202F9': 12,
  '202FA': 12,
  '202FB': 12,
  '202FC': 12,
  '202FD': 12,
  '202FE': 12,
  '202FF': 12,
  '20300': 12,
  '20301': 12,
  '20302': 12,
  '20303': 12,
  '20304': 12,
  '20305': 12,
  '20306': 12,
  '20307': 12,
  '20308': 12,
  '20309': 12,
  '2030A': 12,
  '2030B': 12,
  '2030C': 12,
  '2030D': 12,
  '2030E': 12,
  '2030F': 12,
  '20310': 12,
  '20311': 12,
  '20312': 12,
  '20313': 12,
  '20314': 12,
  '20315': 12,
  '20316': 12,
  '20317': 12,
  '20318': 12,
  '20319': 12,
  '2031A': 12,
  '2031B': 12,
  '2031C': 12,
  '2031D': 12,
  '2031E': 12,
  '2031F': 12,
  '20320': 12,
  '20321': 12,
  '20322': 12,
  '20323': 12,
  '20324': 12,
  '20325': 12,
  '20326': 12,
  '20327': 12,
  '20328': 12,
  '20329': 12,
  '2032A': 11,
  '2032B': 13,
  '2032C': 13,
  '2032D': 13,
  '2032E': 13,
  '2032F': 13,
  '20330': 13,
  '20331': 13,
  '20332': 13,
  '20333': 13,
  '20334': 13,
  '20335': 13,
  '20336': 13,
  '20337': 13,
  '20338': 13,
  '20339': 13,
  '2033A': 13,
  '2033B': 13,
  '2033C': 13,
  '2033D': 13,
  '2033E': 13,
  '2033F': 13,
  '20340': 13,
  '20341': 13,
  '20342': 13,
  '20343': 13,
  '20344': 13,
  '20345': 13,
  '20346': 13,
  '20347': 13,
  '20348': 13,
  '20349': 13,
  '2034A': 13,
  '2034B': 13,
  '2034C': 13,
  '2034D': 13,
  '2034E': 13,
  '2034F': 13,
  '20350': 13,
  '20351': 13,
  '20352': 13,
  '20353': 13,
  '20354': 13,
  '20355': 13,
  '20356': 13,
  '20357': 13,
  '20358': 13,
  '20359': 13,
  '2035A': 13,
  '2035B': 13,
  '2035C': 13,
  '2035D': 13,
  '2035E': 13,
  '2035F': 13,
  '20360': 13,
  '20361': 13,
  '20362': 13,
  '20363': 13,
  '20364': 13,
  '20365': 14,
  '20366': 13,
  '20367': 13,
  '20368': 13,
  '20369': 13,
  '2036A': 13,
  '2036B': 13,
  '2036C': 13,
  '2036D': 14,
  '2036E': 14,
  '2036F': 14,
  '20370': 14,
  '20371': 14,
  '20372': 14,
  '20373': 14,
  '20374': 14,
  '20375': 14,
  '20376': 14,
  '20377': 14,
  '20378': 14,
  '20379': 14,
  '2037A': 14,
  '2037B': 14,
  '2037C': 14,
  '2037D': 14,
  '2037E': 14,
  '2037F': 14,
  '20380': 14,
  '20381': 14,
  '20382': 14,
  '20383': 14,
  '20384': 14,
  '20385': 14,
  '20386': 14,
  '20387': 14,
  '20388': 14,
  '20389': 14,
  '2038A': 14,
  '2038B': 14,
  '2038C': 14,
  '2038D': 14,
  '2038E': 14,
  '2038F': 14,
  '20390': 14,
  '20391': 14,
  '20392': 14,
  '20393': 14,
  '20394': 14,
  '20395': 14,
  '20396': 14,
  '20397': 14,
  '20398': 14,
  '20399': 14,
  '2039A': 14,
  '2039B': 14,
  '2039C': 20,
  '2039D': 14,
  '2039E': 14,
  '2039F': 14,
  '203A0': 14,
  '203A1': 14,
  '203A2': 14,
  '203A3': 14,
  '203A4': 14,
  '203A5': 14,
  '203A6': 14,
  '203A7': 14,
  '203A8': 14,
  '203A9': 14,
  '203AA': 14,
  '203AB': 14,
  '203AC': 14,
  '203AD': 14,
  '203AE': 14,
  '203AF': 14,
  '203B0': 14,
  '203B1': 14,
  '203B2': 14,
  '203B3': 14,
  '203B4': 14,
  '203B5': 13,
  '203B6': 14,
  '203B7': 15,
  '203B8': 15,
  '203B9': 15,
  '203BA': 15,
  '203BB': 15,
  '203BC': 15,
  '203BD': 15,
  '203BE': 15,
  '203BF': 15,
  '203C0': 15,
  '203C1': 15,
  '203C2': 15,
  '203C3': 15,
  '203C4': 15,
  '203C5': 15,
  '203C6': 15,
  '203C7': 15,
  '203C8': 15,
  '203C9': 15,
  '203CA': 15,
  '203CB': 15,
  '203CC': 15,
  '203CD': 15,
  '203CE': 15,
  '203CF': 15,
  '203D0': 14,
  '203D1': 15,
  '203D2': 16,
  '203D3': 15,
  '203D4': 15,
  '203D5': 15,
  '203D6': 15,
  '203D7': 15,
  '203D8': 15,
  '203D9': 15,
  '203DA': 15,
  '203DB': 15,
  '203DC': 15,
  '203DD': 15,
  '203DE': 15,
  '203DF': 15,
  '203E0': 15,
  '203E1': 15,
  '203E2': 15,
  '203E3': 15,
  '203E4': 15,
  '203E5': 15,
  '203E6': 15,
  '203E7': 15,
  '203E8': 16,
  '203E9': 16,
  '203EA': 16,
  '203EB': 16,
  '203EC': 16,
  '203ED': 16,
  '203EE': 16,
  '203EF': 16,
  '203F0': 16,
  '203F1': 16,
  '203F2': 16,
  '203F3': 16,
  '203F4': 16,
  '203F5': 16,
  '203F6': 16,
  '203F7': 16,
  '203F8': 16,
  '203F9': 16,
  '203FA': 16,
  '203FB': 16,
  '203FC': 16,
  '203FD': 16,
  '203FE': 16,
  '203FF': 16,
  '20400': 16,
  '20401': 16,
  '20402': 16,
  '20403': 16,
  '20404': 16,
  '20405': 16,
  '20406': 16,
  '20407': 16,
  '20408': 17,
  '20409': 17,
  '2040A': 17,
  '2040B': 17,
  '2040C': 17,
  '2040D': 17,
  '2040E': 17,
  '2040F': 17,
  '20410': 17,
  '20411': 17,
  '20412': 17,
  '20413': 17,
  '20414': 17,
  '20415': 17,
  '20416': 17,
  '20417': 17,
  '20418': 17,
  '20419': 17,
  '2041A': 17,
  '2041B': 17,
  '2041C': 17,
  '2041D': 17,
  '2041E': 17,
  '2041F': 17,
  '20420': 17,
  '20421': 17,
  '20422': 17,
  '20423': 18,
  '20424': 18,
  '20425': 18,
  '20426': 18,
  '20427': 18,
  '20428': 18,
  '20429': 18,
  '2042A': 18,
  '2042B': 18,
  '2042C': 18,
  '2042D': 18,
  '2042E': 18,
  '2042F': 18,
  '20430': 18,
  '20431': 18,
  '20432': 18,
  '20433': 18,
  '20434': 18,
  '20435': 18,
  '20436': 18,
  '20437': 18,
  '20438': 18,
  '20439': 18,
  '2043A': 18,
  '2043B': 19,
  '2043C': 19,
  '2043D': 19,
  '2043E': 19,
  '2043F': 19,
  '20440': 19,
  '20441': 19,
  '20442': 19,
  '20443': 19,
  '20444': 19,
  '20445': 19,
  '20446': 19,
  '20447': 19,
  '20448': 19,
  '20449': 19,
  '2044A': 19,
  '2044B': 19,
  '2044C': 19,
  '2044D': 20,
  '2044E': 20,
  '2044F': 20,
  '20450': 20,
  '20451': 20,
  '20452': 20,
  '20453': 20,
  '20454': 20,
  '20455': 20,
  '20456': 20,
  '20457': 21,
  '20458': 21,
  '20459': 21,
  '2045A': 21,
  '2045B': 21,
  '2045C': 21,
  '2045D': 21,
  '2045E': 20,
  '2045F': 21,
  '20460': 21,
  '20461': 21,
  '20462': 21,
  '20463': 21,
  '20464': 22,
  '20465': 22,
  '20466': 22,
  '20467': 22,
  '20468': 22,
  '20469': 22,
  '2046A': 23,
  '2046B': 23,
  '2046C': 23,
  '2046D': 23,
  '2046E': 23,
  '2046F': 23,
  '20470': 24,
  '20471': 25,
  '20472': 26,
  '20473': 26,
  '20474': 29,
  '20475': 29,
  '20476': 4,
  '20477': 5,
  '20478': 5,
  '20479': 6,
  '2047A': 6,
  '2047B': 6,
  '2047C': 6,
  '2047D': 7,
  '2047E': 7,
  '2047F': 7,
  '20480': 7,
  '20481': 7,
  '20482': 7,
  '20483': 8,
  '20484': 8,
  '20485': 8,
  '20486': 8,
  '20487': 8,
  '20488': 8,
  '20489': 8,
  '2048A': 8,
  '2048B': 8,
  '2048C': 9,
  '2048D': 9,
  '2048E': 9,
  '2048F': 9,
  '20490': 10,
  '20491': 10,
  '20492': 10,
  '20493': 10,
  '20494': 10,
  '20495': 10,
  '20496': 10,
  '20497': 11,
  '20498': 11,
  '20499': 11,
  '2049A': 11,
  '2049B': 11,
  '2049C': 11,
  '2049D': 11,
  '2049E': 12,
  '2049F': 12,
  '204A0': 12,
  '204A1': 12,
  '204A2': 12,
  '204A3': 12,
  '204A4': 13,
  '204A5': 13,
  '204A6': 13,
  '204A7': 14,
  '204A8': 14,
  '204A9': 14,
  '204AA': 14,
  '204AB': 14,
  '204AC': 14,
  '204AD': 14,
  '204AE': 14,
  '204AF': 14,
  '204B0': 15,
  '204B1': 15,
  '204B2': 15,
  '204B3': 16,
  '204B4': 15,
  '204B5': 15,
  '204B6': 15,
  '204B7': 15,
  '204B8': 16,
  '204B9': 16,
  '204BA': 16,
  '204BB': 16,
  '204BC': 16,
  '204BD': 16,
  '204BE': 16,
  '204BF': 17,
  '204C0': 17,
  '204C1': 17,
  '204C2': 17,
  '204C3': 17,
  '204C4': 17,
  '204C5': 17,
  '204C6': 18,
  '204C7': 17,
  '204C8': 18,
  '204C9': 18,
  '204CA': 18,
  '204CB': 19,
  '204CC': 19,
  '204CD': 20,
  '204CE': 19,
  '204CF': 20,
  '204D0': 20,
  '204D1': 21,
  '204D2': 21,
  '204D3': 22,
  '204D4': 22,
  '204D5': 23,
  '204D6': 23,
  '204D7': 24,
  '204D8': 24,
  '204D9': 24,
  '204DA': 26,
  '204DB': 3,
  '204DC': 4,
  '204DD': 4,
  '204DE': 4,
  '204DF': 5,
  '204E0': 6,
  '204E1': 6,
  '204E2': 6,
  '204E3': 6,
  '204E4': 7,
  '204E5': 7,
  '204E6': 7,
  '204E7': 7,
  '204E8': 7,
  '204E9': 8,
  '204EA': 9,
  '204EB': 9,
  '204EC': 9,
  '204ED': 10,
  '204EE': 10,
  '204EF': 10,
  '204F0': 10,
  '204F1': 11,
  '204F2': 12,
  '204F3': 12,
  '204F4': 13,
  '204F5': 13,
  '204F6': 13,
  '204F7': 13,
  '204F8': 14,
  '204F9': 14,
  '204FA': 14,
  '204FB': 14,
  '204FC': 16,
  '204FD': 17,
  '204FE': 18,
  '204FF': 20,
  '20500': 3,
  '20501': 4,
  '20502': 4,
  '20503': 4,
  '20504': 4,
  '20505': 5,
  '20506': 5,
  '20507': 3,
  '20508': 6,
  '20509': 6,
  '2050A': 7,
  '2050B': 7,
  '2050C': 7,
  '2050D': 7,
  '2050E': 7,
  '2050F': 7,
  '20510': 8,
  '20511': 8,
  '20512': 9,
  '20513': 9,
  '20514': 9,
  '20515': 9,
  '20516': 9,
  '20517': 9,
  '20518': 9,
  '20519': 10,
  '2051A': 10,
  '2051B': 10,
  '2051C': 11,
  '2051D': 11,
  '2051E': 12,
  '2051F': 12,
  '20520': 12,
  '20521': 12,
  '20522': 12,
  '20523': 12,
  '20524': 12,
  '20525': 12,
  '20526': 12,
  '20527': 12,
  '20528': 13,
  '20529': 13,
  '2052A': 13,
  '2052B': 13,
  '2052C': 13,
  '2052D': 13,
  '2052E': 13,
  '2052F': 14,
  '20530': 14,
  '20531': 14,
  '20532': 14,
  '20533': 16,
  '20534': 16,
  '20535': 16,
  '20536': 18,
  '20537': 18,
  '20538': 19,
  '20539': 19,
  '2053A': 19,
  '2053B': 64,
  '2053C': 3,
  '2053D': 4,
  '2053E': 4,
  '2053F': 4,
  '20540': 4,
  '20541': 5,
  '20542': 5,
  '20543': 5,
  '20544': 5,
  '20545': 5,
  '20546': 5,
  '20547': 5,
  '20548': 5,
  '20549': 5,
  '2054A': 5,
  '2054B': 6,
  '2054C': 6,
  '2054D': 6,
  '2054E': 6,
  '2054F': 6,
  '20550': 7,
  '20551': 7,
  '20552': 7,
  '20553': 7,
  '20554': 7,
  '20555': 7,
  '20556': 8,
  '20557': 8,
  '20558': 8,
  '20559': 8,
  '2055A': 8,
  '2055B': 9,
  '2055C': 9,
  '2055D': 10,
  '2055E': 10,
  '2055F': 10,
  '20560': 11,
  '20561': 11,
  '20562': 11,
  '20563': 12,
  '20564': 12,
  '20565': 12,
  '20566': 13,
  '20567': 13,
  '20568': 14,
  '20569': 14,
  '2056A': 15,
  '2056B': 14,
  '2056C': 15,
  '2056D': 16,
  '2056E': 16,
  '2056F': 18,
  '20570': 18,
  '20571': 20,
  '20572': 22,
  '20573': 4,
  '20574': 4,
  '20575': 5,
  '20576': 5,
  '20577': 5,
  '20578': 5,
  '20579': 6,
  '2057A': 6,
  '2057B': 6,
  '2057C': 6,
  '2057D': 6,
  '2057E': 6,
  '2057F': 6,
  '20580': 7,
  '20581': 7,
  '20582': 7,
  '20583': 7,
  '20584': 8,
  '20585': 8,
  '20586': 8,
  '20587': 9,
  '20588': 9,
  '20589': 9,
  '2058A': 9,
  '2058B': 9,
  '2058C': 10,
  '2058D': 10,
  '2058E': 10,
  '2058F': 10,
  '20590': 10,
  '20591': 10,
  '20592': 10,
  '20593': 11,
  '20594': 12,
  '20595': 12,
  '20596': 12,
  '20597': 12,
  '20598': 12,
  '20599': 12,
  '2059A': 13,
  '2059B': 13,
  '2059C': 14,
  '2059D': 14,
  '2059E': 14,
  '2059F': 14,
  '205A0': 16,
  '205A1': 16,
  '205A2': 17,
  '205A3': 18,
  '205A4': 18,
  '205A5': 18,
  '205A6': 20,
  '205A7': 20,
  '205A8': 20,
  '205A9': 21,
  '205AA': 22,
  '205AB': 23,
  '205AC': 3,
  '205AD': 4,
  '205AE': 5,
  '205AF': 5,
  '205B0': 5,
  '205B1': 6,
  '205B2': 6,
  '205B3': 6,
  '205B4': 6,
  '205B5': 6,
  '205B6': 6,
  '205B7': 7,
  '205B8': 7,
  '205B9': 7,
  '205BA': 7,
  '205BB': 7,
  '205BC': 7,
  '205BD': 7,
  '205BE': 7,
  '205BF': 7,
  '205C0': 8,
  '205C1': 7,
  '205C2': 8,
  '205C3': 8,
  '205C4': 8,
  '205C5': 8,
  '205C6': 8,
  '205C7': 8,
  '205C8': 9,
  '205C9': 9,
  '205CA': 9,
  '205CB': 9,
  '205CC': 9,
  '205CD': 9,
  '205CE': 9,
  '205CF': 9,
  '205D0': 9,
  '205D1': 9,
  '205D2': 9,
  '205D3': 9,
  '205D4': 9,
  '205D5': 9,
  '205D6': 9,
  '205D7': 9,
  '205D8': 10,
  '205D9': 10,
  '205DA': 10,
  '205DB': 10,
  '205DC': 10,
  '205DD': 10,
  '205DE': 10,
  '205DF': 10,
  '205E0': 10,
  '205E1': 10,
  '205E2': 10,
  '205E3': 10,
  '205E4': 10,
  '205E5': 11,
  '205E6': 11,
  '205E7': 11,
  '205E8': 11,
  '205E9': 11,
  '205EA': 11,
  '205EB': 11,
  '205EC': 11,
  '205ED': 11,
  '205EE': 11,
  '205EF': 11,
  '205F0': 11,
  '205F1': 12,
  '205F2': 12,
  '205F3': 12,
  '205F4': 12,
  '205F5': 12,
  '205F6': 12,
  '205F7': 12,
  '205F8': 12,
  '205F9': 12,
  '205FA': 13,
  '205FB': 13,
  '205FC': 13,
  '205FD': 13,
  '205FE': 13,
  '205FF': 13,
  '20600': 13,
  '20601': 13,
  '20602': 13,
  '20603': 13,
  '20604': 14,
  '20605': 14,
  '20606': 14,
  '20607': 14,
  '20608': 14,
  '20609': 14,
  '2060A': 15,
  '2060B': 15,
  '2060C': 15,
  '2060D': 15,
  '2060E': 15,
  '2060F': 15,
  '20610': 15,
  '20611': 15,
  '20612': 16,
  '20613': 14,
  '20614': 16,
  '20615': 16,
  '20616': 17,
  '20617': 17,
  '20618': 17,
  '20619': 17,
  '2061A': 17,
  '2061B': 17,
  '2061C': 17,
  '2061D': 18,
  '2061E': 18,
  '2061F': 18,
  '20620': 18,
  '20621': 18,
  '20622': 19,
  '20623': 19,
  '20624': 21,
  '20625': 22,
  '20626': 26,
  '20627': 2,
  '20628': 2,
  '20629': 4,
  '2062A': 4,
  '2062B': 4,
  '2062C': 4,
  '2062D': 4,
  '2062E': 4,
  '2062F': 4,
  '20630': 4,
  '20631': 5,
  '20632': 5,
  '20633': 5,
  '20634': 5,
  '20635': 6,
  '20636': 6,
  '20637': 6,
  '20638': 6,
  '20639': 6,
  '2063A': 6,
  '2063B': 6,
  '2063C': 7,
  '2063D': 7,
  '2063E': 7,
  '2063F': 7,
  '20640': 7,
  '20641': 7,
  '20642': 7,
  '20643': 7,
  '20644': 7,
  '20645': 7,
  '20646': 8,
  '20647': 8,
  '20648': 8,
  '20649': 8,
  '2064A': 8,
  '2064B': 8,
  '2064C': 8,
  '2064D': 8,
  '2064E': 8,
  '2064F': 9,
  '20650': 9,
  '20651': 9,
  '20652': 10,
  '20653': 10,
  '20654': 10,
  '20655': 10,
  '20656': 10,
  '20657': 10,
  '20658': 11,
  '20659': 11,
  '2065A': 11,
  '2065B': 11,
  '2065C': 11,
  '2065D': 11,
  '2065E': 11,
  '2065F': 12,
  '20660': 12,
  '20661': 12,
  '20662': 12,
  '20663': 12,
  '20664': 13,
  '20665': 13,
  '20666': 13,
  '20667': 13,
  '20668': 14,
  '20669': 14,
  '2066A': 14,
  '2066B': 15,
  '2066C': 15,
  '2066D': 16,
  '2066E': 16,
  '2066F': 16,
  '20670': 16,
  '20671': 17,
  '20672': 20,
  '20673': 21,
  '20674': 2,
  '20675': 3,
  '20676': 4,
  '20677': 4,
  '20678': 4,
  '20679': 5,
  '2067A': 5,
  '2067B': 5,
  '2067C': 5,
  '2067D': 5,
  '2067E': 6,
  '2067F': 6,
  '20680': 6,
  '20681': 6,
  '20682': 6,
  '20683': 6,
  '20684': 6,
  '20685': 6,
  '20686': 7,
  '20687': 7,
  '20688': 7,
  '20689': 7,
  '2068A': 7,
  '2068B': 8,
  '2068C': 8,
  '2068D': 8,
  '2068E': 9,
  '2068F': 9,
  '20690': 9,
  '20691': 9,
  '20692': 10,
  '20693': 10,
  '20694': 11,
  '20695': 11,
  '20696': 11,
  '20697': 11,
  '20698': 12,
  '20699': 14,
  '2069A': 14,
  '2069B': 15,
  '2069C': 15,
  '2069D': 16,
  '2069E': 17,
  '2069F': 19,
  '206A0': 20,
  '206A1': 21,
  '206A2': 24,
  '206A3': 2,
  '206A4': 3,
  '206A5': 4,
  '206A6': 4,
  '206A7': 4,
  '206A8': 4,
  '206A9': 4,
  '206AA': 4,
  '206AB': 4,
  '206AC': 4,
  '206AD': 5,
  '206AE': 5,
  '206AF': 5,
  '206B0': 5,
  '206B1': 5,
  '206B2': 5,
  '206B3': 5,
  '206B4': 5,
  '206B5': 6,
  '206B6': 5,
  '206B7': 6,
  '206B8': 6,
  '206B9': 6,
  '206BA': 6,
  '206BB': 5,
  '206BC': 6,
  '206BD': 6,
  '206BE': 6,
  '206BF': 6,
  '206C0': 6,
  '206C1': 6,
  '206C2': 6,
  '206C3': 6,
  '206C4': 6,
  '206C5': 6,
  '206C6': 6,
  '206C7': 6,
  '206C8': 6,
  '206C9': 6,
  '206CA': 6,
  '206CB': 6,
  '206CC': 6,
  '206CD': 6,
  '206CE': 7,
  '206CF': 7,
  '206D0': 7,
  '206D1': 7,
  '206D2': 7,
  '206D3': 7,
  '206D4': 7,
  '206D5': 7,
  '206D6': 7,
  '206D7': 7,
  '206D8': 7,
  '206D9': 7,
  '206DA': 7,
  '206DB': 7,
  '206DC': 7,
  '206DD': 7,
  '206DE': 7,
  '206DF': 7,
  '206E0': 7,
  '206E1': 7,
  '206E2': 7,
  '206E3': 7,
  '206E4': 7,
  '206E5': 7,
  '206E6': 7,
  '206E7': 8,
  '206E8': 8,
  '206E9': 8,
  '206EA': 8,
  '206EB': 8,
  '206EC': 8,
  '206ED': 8,
  '206EE': 8,
  '206EF': 8,
  '206F0': 8,
  '206F1': 8,
  '206F2': 8,
  '206F3': 8,
  '206F4': 8,
  '206F5': 8,
  '206F6': 8,
  '206F7': 8,
  '206F8': 8,
  '206F9': 8,
  '206FA': 8,
  '206FB': 8,
  '206FC': 8,
  '206FD': 8,
  '206FE': 8,
  '206FF': 8,
  '20700': 8,
  '20701': 8,
  '20702': 8,
  '20703': 8,
  '20704': 8,
  '20705': 8,
  '20706': 8,
  '20707': 8,
  '20708': 8,
  '20709': 8,
  '2070A': 8,
  '2070B': 8,
  '2070C': 8,
  '2070D': 8,
  '2070E': 8,
  '2070F': 8,
  '20710': 9,
  '20711': 9,
  '20712': 9,
  '20713': 9,
  '20714': 9,
  '20715': 9,
  '20716': 9,
  '20717': 9,
  '20718': 9,
  '20719': 9,
  '2071A': 9,
  '2071B': 9,
  '2071C': 9,
  '2071D': 9,
  '2071E': 9,
  '2071F': 9,
  '20720': 9,
  '20721': 9,
  '20722': 9,
  '20723': 9,
  '20724': 9,
  '20725': 9,
  '20726': 9,
  '20727': 9,
  '20728': 9,
  '20729': 9,
  '2072A': 9,
  '2072B': 9,
  '2072C': 9,
  '2072D': 9,
  '2072E': 9,
  '2072F': 9,
  '20730': 9,
  '20731': 10,
  '20732': 10,
  '20733': 10,
  '20734': 10,
  '20735': 10,
  '20736': 10,
  '20737': 10,
  '20738': 10,
  '20739': 10,
  '2073A': 10,
  '2073B': 10,
  '2073C': 10,
  '2073D': 10,
  '2073E': 10,
  '2073F': 10,
  '20740': 10,
  '20741': 10,
  '20742': 10,
  '20743': 10,
  '20744': 10,
  '20745': 10,
  '20746': 10,
  '20747': 10,
  '20748': 10,
  '20749': 10,
  '2074A': 10,
  '2074B': 10,
  '2074C': 10,
  '2074D': 10,
  '2074E': 10,
  '2074F': 10,
  '20750': 10,
  '20751': 10,
  '20752': 10,
  '20753': 10,
  '20754': 10,
  '20755': 10,
  '20756': 10,
  '20757': 10,
  '20758': 10,
  '20759': 10,
  '2075A': 10,
  '2075B': 10,
  '2075C': 10,
  '2075D': 11,
  '2075E': 11,
  '2075F': 11,
  '20760': 11,
  '20761': 11,
  '20762': 11,
  '20763': 11,
  '20764': 11,
  '20765': 11,
  '20766': 11,
  '20767': 11,
  '20768': 11,
  '20769': 11,
  '2076A': 11,
  '2076B': 11,
  '2076C': 11,
  '2076D': 11,
  '2076E': 11,
  '2076F': 11,
  '20770': 11,
  '20771': 11,
  '20772': 11,
  '20773': 11,
  '20774': 11,
  '20775': 11,
  '20776': 10,
  '20777': 11,
  '20778': 11,
  '20779': 11,
  '2077A': 11,
  '2077B': 11,
  '2077C': 11,
  '2077D': 11,
  '2077E': 11,
  '2077F': 12,
  '20780': 12,
  '20781': 12,
  '20782': 12,
  '20783': 12,
  '20784': 12,
  '20785': 12,
  '20786': 12,
  '20787': 12,
  '20788': 12,
  '20789': 12,
  '2078A': 12,
  '2078B': 12,
  '2078C': 12,
  '2078D': 12,
  '2078E': 12,
  '2078F': 12,
  '20790': 12,
  '20791': 12,
  '20792': 12,
  '20793': 12,
  '20794': 12,
  '20795': 12,
  '20796': 12,
  '20797': 12,
  '20798': 12,
  '20799': 12,
  '2079A': 12,
  '2079B': 12,
  '2079C': 12,
  '2079D': 12,
  '2079E': 12,
  '2079F': 12,
  '207A0': 12,
  '207A1': 12,
  '207A2': 12,
  '207A3': 12,
  '207A4': 12,
  '207A5': 13,
  '207A6': 13,
  '207A7': 13,
  '207A8': 13,
  '207A9': 13,
  '207AA': 13,
  '207AB': 13,
  '207AC': 13,
  '207AD': 13,
  '207AE': 13,
  '207AF': 13,
  '207B0': 13,
  '207B1': 13,
  '207B2': 13,
  '207B3': 13,
  '207B4': 13,
  '207B5': 13,
  '207B6': 13,
  '207B7': 13,
  '207B8': 13,
  '207B9': 13,
  '207BA': 13,
  '207BB': 13,
  '207BC': 13,
  '207BD': 13,
  '207BE': 13,
  '207BF': 13,
  '207C0': 13,
  '207C1': 13,
  '207C2': 14,
  '207C3': 14,
  '207C4': 14,
  '207C5': 14,
  '207C6': 14,
  '207C7': 14,
  '207C8': 14,
  '207C9': 14,
  '207CA': 14,
  '207CB': 14,
  '207CC': 14,
  '207CD': 14,
  '207CE': 14,
  '207CF': 14,
  '207D0': 14,
  '207D1': 14,
  '207D2': 14,
  '207D3': 14,
  '207D4': 14,
  '207D5': 14,
  '207D6': 14,
  '207D7': 14,
  '207D8': 14,
  '207D9': 14,
  '207DA': 14,
  '207DB': 14,
  '207DC': 14,
  '207DD': 14,
  '207DE': 14,
  '207DF': 14,
  '207E0': 15,
  '207E1': 14,
  '207E2': 14,
  '207E3': 14,
  '207E4': 14,
  '207E5': 14,
  '207E6': 15,
  '207E7': 15,
  '207E8': 15,
  '207E9': 15,
  '207EA': 15,
  '207EB': 15,
  '207EC': 15,
  '207ED': 15,
  '207EE': 15,
  '207EF': 15,
  '207F0': 15,
  '207F1': 15,
  '207F2': 15,
  '207F3': 15,
  '207F4': 15,
  '207F5': 15,
  '207F6': 15,
  '207F7': 15,
  '207F8': 15,
  '207F9': 15,
  '207FA': 16,
  '207FB': 16,
  '207FC': 16,
  '207FD': 16,
  '207FE': 16,
  '207FF': 16,
  '20800': 16,
  '20801': 16,
  '20802': 16,
  '20803': 16,
  '20804': 16,
  '20805': 16,
  '20806': 16,
  '20807': 16,
  '20808': 16,
  '20809': 16,
  '2080A': 16,
  '2080B': 16,
  '2080C': 17,
  '2080D': 17,
  '2080E': 17,
  '2080F': 17,
  '20810': 17,
  '20811': 17,
  '20812': 17,
  '20813': 17,
  '20814': 17,
  '20815': 17,
  '20816': 17,
  '20817': 17,
  '20818': 17,
  '20819': 17,
  '2081A': 17,
  '2081B': 18,
  '2081C': 18,
  '2081D': 18,
  '2081E': 18,
  '2081F': 18,
  '20820': 18,
  '20821': 19,
  '20822': 19,
  '20823': 19,
  '20824': 20,
  '20825': 21,
  '20826': 20,
  '20827': 20,
  '20828': 20,
  '20829': 20,
  '2082A': 21,
  '2082B': 21,
  '2082C': 21,
  '2082D': 21,
  '2082E': 23,
  '2082F': 24,
  '20830': 26,
  '20831': 26,
  '20832': 3,
  '20833': 4,
  '20834': 4,
  '20835': 4,
  '20836': 5,
  '20837': 5,
  '20838': 5,
  '20839': 6,
  '2083A': 6,
  '2083B': 6,
  '2083C': 6,
  '2083D': 6,
  '2083E': 6,
  '2083F': 6,
  '20840': 6,
  '20841': 6,
  '20842': 7,
  '20843': 7,
  '20844': 7,
  '20845': 7,
  '20846': 7,
  '20847': 7,
  '20848': 7,
  '20849': 7,
  '2084A': 7,
  '2084B': 7,
  '2084C': 7,
  '2084D': 7,
  '2084E': 7,
  '2084F': 7,
  '20850': 7,
  '20851': 8,
  '20852': 8,
  '20853': 8,
  '20854': 8,
  '20855': 8,
  '20856': 8,
  '20857': 8,
  '20858': 8,
  '20859': 8,
  '2085A': 8,
  '2085B': 8,
  '2085C': 8,
  '2085D': 8,
  '2085E': 9,
  '2085F': 9,
  '20860': 9,
  '20861': 9,
  '20862': 9,
  '20863': 9,
  '20864': 9,
  '20865': 9,
  '20866': 9,
  '20867': 9,
  '20868': 9,
  '20869': 9,
  '2086A': 9,
  '2086B': 10,
  '2086C': 10,
  '2086D': 10,
  '2086E': 10,
  '2086F': 10,
  '20870': 10,
  '20871': 10,
  '20872': 10,
  '20873': 10,
  '20874': 10,
  '20875': 10,
  '20876': 10,
  '20877': 10,
  '20878': 10,
  '20879': 10,
  '2087A': 10,
  '2087B': 11,
  '2087C': 11,
  '2087D': 11,
  '2087E': 11,
  '2087F': 11,
  '20880': 11,
  '20881': 11,
  '20882': 11,
  '20883': 11,
  '20884': 11,
  '20885': 12,
  '20886': 12,
  '20887': 12,
  '20888': 12,
  '20889': 12,
  '2088A': 12,
  '2088B': 12,
  '2088C': 12,
  '2088D': 12,
  '2088E': 12,
  '2088F': 12,
  '20890': 12,
  '20891': 12,
  '20892': 12,
  '20893': 13,
  '20894': 13,
  '20895': 13,
  '20896': 13,
  '20897': 13,
  '20898': 13,
  '20899': 13,
  '2089A': 13,
  '2089B': 13,
  '2089C': 13,
  '2089D': 13,
  '2089E': 13,
  '2089F': 13,
  '208A0': 14,
  '208A1': 14,
  '208A2': 14,
  '208A3': 14,
  '208A4': 14,
  '208A5': 14,
  '208A6': 14,
  '208A7': 14,
  '208A8': 14,
  '208A9': 14,
  '208AA': 14,
  '208AB': 14,
  '208AC': 14,
  '208AD': 14,
  '208AE': 14,
  '208AF': 14,
  '208B0': 15,
  '208B1': 15,
  '208B2': 15,
  '208B3': 15,
  '208B4': 15,
  '208B5': 15,
  '208B6': 16,
  '208B7': 16,
  '208B8': 16,
  '208B9': 17,
  '208BA': 17,
  '208BB': 17,
  '208BC': 17,
  '208BD': 17,
  '208BE': 17,
  '208BF': 17,
  '208C0': 18,
  '208C1': 18,
  '208C2': 18,
  '208C3': 18,
  '208C4': 19,
  '208C5': 19,
  '208C6': 20,
  '208C7': 21,
  '208C8': 23,
  '208C9': 23,
  '208CA': 25,
  '208CB': 34,
  '208CC': 4,
  '208CD': 5,
  '208CE': 5,
  '208CF': 5,
  '208D0': 5,
  '208D1': 6,
  '208D2': 6,
  '208D3': 6,
  '208D4': 7,
  '208D5': 7,
  '208D6': 7,
  '208D7': 7,
  '208D8': 8,
  '208D9': 8,
  '208DA': 8,
  '208DB': 8,
  '208DC': 8,
  '208DD': 8,
  '208DE': 9,
  '208DF': 9,
  '208E0': 9,
  '208E1': 9,
  '208E2': 9,
  '208E3': 9,
  '208E4': 10,
  '208E5': 10,
  '208E6': 10,
  '208E7': 10,
  '208E8': 10,
  '208E9': 10,
  '208EA': 10,
  '208EB': 10,
  '208EC': 10,
  '208ED': 10,
  '208EE': 11,
  '208EF': 11,
  '208F0': 11,
  '208F1': 11,
  '208F2': 11,
  '208F3': 11,
  '208F4': 11,
  '208F5': 12,
  '208F6': 12,
  '208F7': 12,
  '208F8': 13,
  '208F9': 13,
  '208FA': 13,
  '208FB': 13,
  '208FC': 13,
  '208FD': 14,
  '208FE': 14,
  '208FF': 14,
  '20900': 14,
  '20901': 14,
  '20902': 14,
  '20903': 15,
  '20904': 16,
  '20905': 16,
  '20906': 16,
  '20907': 16,
  '20908': 17,
  '20909': 17,
  '2090A': 17,
  '2090B': 20,
  '2090C': 20,
  '2090D': 20,
  '2090E': 2,
  '2090F': 4,
  '20910': 5,
  '20911': 7,
  '20912': 7,
  '20913': 7,
  '20914': 7,
  '20915': 7,
  '20916': 8,
  '20917': 9,
  '20918': 9,
  '20919': 9,
  '2091A': 9,
  '2091B': 9,
  '2091C': 9,
  '2091D': 9,
  '2091E': 10,
  '2091F': 10,
  '20920': 10,
  '20921': 10,
  '20922': 10,
  '20923': 12,
  '20924': 12,
  '20925': 13,
  '20926': 14,
  '20927': 14,
  '20928': 14,
  '20929': 17,
  '2092A': 18,
  '2092B': 19,
  '2092C': 2,
  '2092D': 4,
  '2092E': 6,
  '2092F': 6,
  '20930': 6,
  '20931': 6,
  '20932': 6,
  '20933': 7,
  '20934': 7,
  '20935': 7,
  '20936': 7,
  '20937': 7,
  '20938': 8,
  '20939': 8,
  '2093A': 8,
  '2093B': 8,
  '2093C': 9,
  '2093D': 9,
  '2093E': 9,
  '2093F': 9,
  '20940': 9,
  '20941': 9,
  '20942': 9,
  '20943': 9,
  '20944': 9,
  '20945': 9,
  '20946': 9,
  '20947': 9,
  '20948': 9,
  '20949': 10,
  '2094A': 10,
  '2094B': 10,
  '2094C': 10,
  '2094D': 10,
  '2094E': 11,
  '2094F': 11,
  '20950': 12,
  '20951': 12,
  '20952': 12,
  '20953': 12,
  '20954': 12,
  '20955': 12,
  '20956': 13,
  '20957': 13,
  '20958': 13,
  '20959': 14,
  '2095A': 14,
  '2095B': 14,
  '2095C': 15,
  '2095D': 15,
  '2095E': 15,
  '2095F': 15,
  '20960': 15,
  '20961': 15,
  '20962': 16,
  '20963': 16,
  '20964': 18,
  '20965': 18,
  '20966': 19,
  '20967': 19,
  '20968': 20,
  '20969': 20,
  '2096A': 22,
  '2096B': 26,
  '2096C': 30,
  '2096D': 4,
  '2096E': 6,
  '2096F': 6,
  '20970': 7,
  '20971': 7,
  '20972': 7,
  '20973': 8,
  '20974': 9,
  '20975': 9,
  '20976': 10,
  '20977': 14,
  '20978': 20,
  '20979': 22,
  '2097A': 22,
  '2097B': 4,
  '2097C': 4,
  '2097D': 5,
  '2097E': 5,
  '2097F': 4,
  '20980': 5,
  '20981': 5,
  '20982': 6,
  '20983': 6,
  '20984': 6,
  '20985': 6,
  '20986': 6,
  '20987': 6,
  '20988': 6,
  '20989': 6,
  '2098A': 6,
  '2098B': 6,
  '2098C': 6,
  '2098D': 6,
  '2098E': 6,
  '2098F': 6,
  '20990': 6,
  '20991': 7,
  '20992': 7,
  '20993': 7,
  '20994': 7,
  '20995': 7,
  '20996': 7,
  '20997': 7,
  '20998': 8,
  '20999': 8,
  '2099A': 8,
  '2099B': 8,
  '2099C': 8,
  '2099D': 8,
  '2099E': 8,
  '2099F': 8,
  '209A0': 8,
  '209A1': 8,
  '209A2': 8,
  '209A3': 10,
  '209A4': 10,
  '209A5': 10,
  '209A6': 10,
  '209A7': 10,
  '209A8': 10,
  '209A9': 10,
  '209AA': 11,
  '209AB': 11,
  '209AC': 11,
  '209AD': 11,
  '209AE': 11,
  '209AF': 11,
  '209B0': 12,
  '209B1': 12,
  '209B2': 12,
  '209B3': 12,
  '209B4': 13,
  '209B5': 13,
  '209B6': 13,
  '209B7': 13,
  '209B8': 13,
  '209B9': 13,
  '209BA': 13,
  '209BB': 13,
  '209BC': 13,
  '209BD': 14,
  '209BE': 14,
  '209BF': 14,
  '209C0': 15,
  '209C1': 15,
  '209C2': 15,
  '209C3': 15,
  '209C4': 15,
  '209C5': 17,
  '209C6': 17,
  '209C7': 17,
  '209C8': 17,
  '209C9': 18,
  '209CA': 18,
  '209CB': 18,
  '209CC': 18,
  '209CD': 18,
  '209CE': 19,
  '209CF': 21,
  '209D0': 25,
  '209D1': 26,
  '209D2': 3,
  '209D3': 5,
  '209D4': 6,
  '209D5': 6,
  '209D6': 6,
  '209D7': 6,
  '209D8': 6,
  '209D9': 7,
  '209DA': 7,
  '209DB': 7,
  '209DC': 7,
  '209DD': 7,
  '209DE': 8,
  '209DF': 8,
  '209E0': 8,
  '209E1': 8,
  '209E2': 8,
  '209E3': 8,
  '209E4': 8,
  '209E5': 8,
  '209E6': 8,
  '209E7': 8,
  '209E8': 8,
  '209E9': 9,
  '209EA': 9,
  '209EB': 9,
  '209EC': 9,
  '209ED': 9,
  '209EE': 9,
  '209EF': 9,
  '209F0': 9,
  '209F1': 9,
  '209F2': 9,
  '209F3': 10,
  '209F4': 10,
  '209F5': 10,
  '209F6': 10,
  '209F7': 10,
  '209F8': 11,
  '209F9': 10,
  '209FA': 11,
  '209FB': 11,
  '209FC': 11,
  '209FD': 11,
  '209FE': 11,
  '209FF': 11,
  '20A00': 12,
  '20A01': 12,
  '20A02': 12,
  '20A03': 12,
  '20A04': 12,
  '20A05': 13,
  '20A06': 14,
  '20A07': 14,
  '20A08': 15,
  '20A09': 15,
  '20A0A': 15,
  '20A0B': 27,
  '20A0C': 33,
  '20A0D': 4,
  '20A0E': 4,
  '20A0F': 4,
  '20A10': 4,
  '20A11': 5,
  '20A12': 6,
  '20A13': 6,
  '20A14': 6,
  '20A15': 6,
  '20A16': 6,
  '20A17': 6,
  '20A18': 7,
  '20A19': 9,
  '20A1A': 9,
  '20A1B': 9,
  '20A1C': 9,
  '20A1D': 9,
  '20A1E': 10,
  '20A1F': 12,
  '20A20': 12,
  '20A21': 12,
  '20A22': 13,
  '20A23': 13,
  '20A24': 13,
  '20A25': 14,
  '20A26': 17,
  '20A27': 18,
  '20A28': 18,
  '20A29': 18,
  '20A2A': 18,
  '20A2B': 26,
  '20A2C': 4,
  '20A2D': 5,
  '20A2E': 5,
  '20A2F': 5,
  '20A30': 5,
  '20A31': 5,
  '20A32': 5,
  '20A33': 5,
  '20A34': 6,
  '20A35': 6,
  '20A36': 6,
  '20A37': 6,
  '20A38': 6,
  '20A39': 6,
  '20A3A': 6,
  '20A3B': 6,
  '20A3C': 6,
  '20A3D': 6,
  '20A3E': 6,
  '20A3F': 6,
  '20A40': 7,
  '20A41': 7,
  '20A42': 7,
  '20A43': 7,
  '20A44': 7,
  '20A45': 7,
  '20A46': 7,
  '20A47': 7,
  '20A48': 7,
  '20A49': 7,
  '20A4A': 8,
  '20A4B': 8,
  '20A4C': 8,
  '20A4D': 8,
  '20A4E': 8,
  '20A4F': 8,
  '20A50': 8,
  '20A51': 8,
  '20A52': 8,
  '20A53': 8,
  '20A54': 8,
  '20A55': 8,
  '20A56': 8,
  '20A57': 9,
  '20A58': 9,
  '20A59': 9,
  '20A5A': 9,
  '20A5B': 9,
  '20A5C': 9,
  '20A5D': 9,
  '20A5E': 9,
  '20A5F': 9,
  '20A60': 9,
  '20A61': 9,
  '20A62': 9,
  '20A63': 9,
  '20A64': 9,
  '20A65': 9,
  '20A66': 9,
  '20A67': 10,
  '20A68': 10,
  '20A69': 10,
  '20A6A': 10,
  '20A6B': 10,
  '20A6C': 10,
  '20A6D': 10,
  '20A6E': 10,
  '20A6F': 10,
  '20A70': 10,
  '20A71': 10,
  '20A72': 10,
  '20A73': 10,
  '20A74': 10,
  '20A75': 10,
  '20A76': 10,
  '20A77': 10,
  '20A78': 11,
  '20A79': 11,
  '20A7A': 11,
  '20A7B': 11,
  '20A7C': 11,
  '20A7D': 11,
  '20A7E': 11,
  '20A7F': 13,
  '20A80': 11,
  '20A81': 11,
  '20A82': 11,
  '20A83': 11,
  '20A84': 11,
  '20A85': 11,
  '20A86': 11,
  '20A87': 12,
  '20A88': 12,
  '20A89': 12,
  '20A8A': 12,
  '20A8B': 12,
  '20A8C': 12,
  '20A8D': 12,
  '20A8E': 12,
  '20A8F': 12,
  '20A90': 12,
  '20A91': 13,
  '20A92': 13,
  '20A93': 13,
  '20A94': 13,
  '20A95': 13,
  '20A96': 13,
  '20A97': 13,
  '20A98': 13,
  '20A99': 14,
  '20A9A': 14,
  '20A9B': 14,
  '20A9C': 14,
  '20A9D': 14,
  '20A9E': 14,
  '20A9F': 14,
  '20AA0': 14,
  '20AA1': 14,
  '20AA2': 14,
  '20AA3': 14,
  '20AA4': 14,
  '20AA5': 14,
  '20AA6': 14,
  '20AA7': 14,
  '20AA8': 14,
  '20AA9': 14,
  '20AAA': 14,
  '20AAB': 14,
  '20AAC': 14,
  '20AAD': 15,
  '20AAE': 15,
  '20AAF': 15,
  '20AB0': 15,
  '20AB1': 15,
  '20AB2': 15,
  '20AB3': 15,
  '20AB4': 15,
  '20AB5': 15,
  '20AB6': 15,
  '20AB7': 15,
  '20AB8': 15,
  '20AB9': 15,
  '20ABA': 16,
  '20ABB': 16,
  '20ABC': 16,
  '20ABD': 16,
  '20ABE': 16,
  '20ABF': 17,
  '20AC0': 18,
  '20AC1': 18,
  '20AC2': 18,
  '20AC3': 18,
  '20AC4': 18,
  '20AC5': 18,
  '20AC6': 18,
  '20AC7': 20,
  '20AC8': 20,
  '20AC9': 21,
  '20ACA': 21,
  '20ACB': 22,
  '20ACC': 23,
  '20ACD': 23,
  '20ACE': 23,
  '20ACF': 24,
  '20AD0': 29,
  '20AD1': 32,
  '20AD2': 33,
  '20AD3': 3,
  '20AD4': 3,
  '20AD5': 3,
  '20AD6': 3,
  '20AD7': 4,
  '20AD8': 4,
  '20AD9': 5,
  '20ADA': 5,
  '20ADB': 5,
  '20ADC': 5,
  '20ADD': 5,
  '20ADE': 6,
  '20ADF': 6,
  '20AE0': 6,
  '20AE1': 6,
  '20AE2': 6,
  '20AE3': 6,
  '20AE4': 6,
  '20AE5': 6,
  '20AE6': 7,
  '20AE7': 7,
  '20AE8': 7,
  '20AE9': 8,
  '20AEA': 8,
  '20AEB': 8,
  '20AEC': 8,
  '20AED': 8,
  '20AEE': 8,
  '20AEF': 8,
  '20AF0': 9,
  '20AF1': 9,
  '20AF2': 9,
  '20AF3': 9,
  '20AF4': 9,
  '20AF5': 9,
  '20AF6': 9,
  '20AF7': 9,
  '20AF8': 9,
  '20AF9': 10,
  '20AFA': 10,
  '20AFB': 10,
  '20AFC': 10,
  '20AFD': 11,
  '20AFE': 11,
  '20AFF': 12,
  '20B00': 12,
  '20B01': 12,
  '20B02': 12,
  '20B03': 12,
  '20B04': 12,
  '20B05': 12,
  '20B06': 13,
  '20B07': 13,
  '20B08': 13,
  '20B09': 13,
  '20B0A': 13,
  '20B0B': 13,
  '20B0C': 13,
  '20B0D': 14,
  '20B0E': 14,
  '20B0F': 14,
  '20B10': 14,
  '20B11': 15,
  '20B12': 15,
  '20B13': 16,
  '20B14': 19,
  '20B15': 17,
  '20B16': 17,
  '20B17': 17,
  '20B18': 19,
  '20B19': 19,
  '20B1A': 4,
  '20B1B': 4,
  '20B1C': 4,
  '20B1D': 4,
  '20B1E': 4,
  '20B1F': 4,
  '20B20': 4,
  '20B21': 5,
  '20B22': 5,
  '20B23': 5,
  '20B24': 5,
  '20B25': 5,
  '20B26': 5,
  '20B27': 5,
  '20B28': 5,
  '20B29': 6,
  '20B2A': 6,
  '20B2B': 6,
  '20B2C': 6,
  '20B2D': 6,
  '20B2E': 6,
  '20B2F': 6,
  '20B30': 6,
  '20B31': 7,
  '20B32': 7,
  '20B33': 7,
  '20B34': 7,
  '20B35': 7,
  '20B36': 7,
  '20B37': 7,
  '20B38': 7,
  '20B39': 8,
  '20B3A': 8,
  '20B3B': 8,
  '20B3C': 8,
  '20B3D': 8,
  '20B3E': 8,
  '20B3F': 9,
  '20B40': 9,
  '20B41': 9,
  '20B42': 9,
  '20B43': 9,
  '20B44': 9,
  '20B45': 9,
  '20B46': 9,
  '20B47': 9,
  '20B48': 9,
  '20B49': 9,
  '20B4A': 10,
  '20B4B': 10,
  '20B4C': 10,
  '20B4D': 10,
  '20B4E': 10,
  '20B4F': 10,
  '20B50': 10,
  '20B51': 10,
  '20B52': 10,
  '20B53': 10,
  '20B54': 10,
  '20B55': 11,
  '20B56': 11,
  '20B57': 11,
  '20B58': 11,
  '20B59': 11,
  '20B5A': 11,
  '20B5B': 11,
  '20B5C': 11,
  '20B5D': 11,
  '20B5E': 11,
  '20B5F': 11,
  '20B60': 11,
  '20B61': 11,
  '20B62': 11,
  '20B63': 11,
  '20B64': 11,
  '20B65': 12,
  '20B66': 12,
  '20B67': 12,
  '20B68': 12,
  '20B69': 12,
  '20B6A': 12,
  '20B6B': 12,
  '20B6C': 12,
  '20B6D': 12,
  '20B6E': 12,
  '20B6F': 13,
  '20B70': 13,
  '20B71': 13,
  '20B72': 13,
  '20B73': 13,
  '20B74': 13,
  '20B75': 13,
  '20B76': 13,
  '20B77': 13,
  '20B78': 13,
  '20B79': 13,
  '20B7A': 13,
  '20B7B': 14,
  '20B7C': 14,
  '20B7D': 14,
  '20B7E': 14,
  '20B7F': 14,
  '20B80': 14,
  '20B81': 14,
  '20B82': 14,
  '20B83': 15,
  '20B84': 15,
  '20B85': 15,
  '20B86': 15,
  '20B87': 16,
  '20B88': 16,
  '20B89': 16,
  '20B8A': 16,
  '20B8B': 16,
  '20B8C': 16,
  '20B8D': 17,
  '20B8E': 17,
  '20B8F': 17,
  '20B90': 18,
  '20B91': 19,
  '20B92': 19,
  '20B93': 21,
  '20B94': 21,
  '20B95': 22,
  '20B96': 23,
  '20B97': 24,
  '20B98': 24,
  '20B99': 4,
  '20B9A': 4,
  '20B9B': 4,
  '20B9C': 4,
  '20B9D': 4,
  '20B9E': 4,
  '20B9F': 5,
  '20BA0': 5,
  '20BA1': 5,
  '20BA2': 5,
  '20BA3': 5,
  '20BA4': 5,
  '20BA5': 5,
  '20BA6': 5,
  '20BA7': 5,
  '20BA8': 5,
  '20BA9': 5,
  '20BAA': 5,
  '20BAB': 6,
  '20BAC': 6,
  '20BAD': 6,
  '20BAE': 6,
  '20BAF': 6,
  '20BB0': 6,
  '20BB1': 6,
  '20BB2': 6,
  '20BB3': 6,
  '20BB4': 6,
  '20BB5': 6,
  '20BB6': 6,
  '20BB7': 6,
  '20BB8': 6,
  '20BB9': 6,
  '20BBA': 6,
  '20BBB': 6,
  '20BBC': 6,
  '20BBD': 6,
  '20BBE': 6,
  '20BBF': 6,
  '20BC0': 6,
  '20BC1': 6,
  '20BC2': 6,
  '20BC3': 6,
  '20BC4': 6,
  '20BC5': 6,
  '20BC6': 6,
  '20BC7': 6,
  '20BC8': 6,
  '20BC9': 6,
  '20BCA': 6,
  '20BCB': 7,
  '20BCC': 7,
  '20BCD': 7,
  '20BCE': 7,
  '20BCF': 7,
  '20BD0': 7,
  '20BD1': 7,
  '20BD2': 7,
  '20BD3': 7,
  '20BD4': 7,
  '20BD5': 7,
  '20BD6': 7,
  '20BD7': 7,
  '20BD8': 7,
  '20BD9': 7,
  '20BDA': 7,
  '20BDB': 7,
  '20BDC': 7,
  '20BDD': 7,
  '20BDE': 7,
  '20BDF': 7,
  '20BE0': 7,
  '20BE1': 7,
  '20BE2': 7,
  '20BE3': 7,
  '20BE4': 7,
  '20BE5': 7,
  '20BE6': 7,
  '20BE7': 7,
  '20BE8': 7,
  '20BE9': 7,
  '20BEA': 7,
  '20BEB': 7,
  '20BEC': 7,
  '20BED': 7,
  '20BEE': 7,
  '20BEF': 7,
  '20BF0': 7,
  '20BF1': 7,
  '20BF2': 7,
  '20BF3': 7,
  '20BF4': 7,
  '20BF5': 7,
  '20BF6': 7,
  '20BF7': 7,
  '20BF8': 7,
  '20BF9': 7,
  '20BFA': 7,
  '20BFB': 7,
  '20BFC': 7,
  '20BFD': 7,
  '20BFE': 7,
  '20BFF': 7,
  '20C00': 7,
  '20C01': 7,
  '20C02': 7,
  '20C03': 7,
  '20C04': 7,
  '20C05': 7,
  '20C06': 7,
  '20C07': 7,
  '20C08': 8,
  '20C09': 8,
  '20C0A': 8,
  '20C0B': 8,
  '20C0C': 8,
  '20C0D': 8,
  '20C0E': 8,
  '20C0F': 8,
  '20C10': 8,
  '20C11': 8,
  '20C12': 8,
  '20C13': 8,
  '20C14': 8,
  '20C15': 8,
  '20C16': 8,
  '20C17': 8,
  '20C18': 8,
  '20C19': 8,
  '20C1A': 8,
  '20C1B': 8,
  '20C1C': 8,
  '20C1D': 8,
  '20C1E': 8,
  '20C1F': 8,
  '20C20': 8,
  '20C21': 8,
  '20C22': 8,
  '20C23': 8,
  '20C24': 8,
  '20C25': 8,
  '20C26': 8,
  '20C27': 8,
  '20C28': 8,
  '20C29': 8,
  '20C2A': 8,
  '20C2B': 8,
  '20C2C': 8,
  '20C2D': 8,
  '20C2E': 8,
  '20C2F': 8,
  '20C30': 8,
  '20C31': 8,
  '20C32': 8,
  '20C33': 8,
  '20C34': 8,
  '20C35': 8,
  '20C36': 8,
  '20C37': 8,
  '20C38': 8,
  '20C39': 8,
  '20C3A': 8,
  '20C3B': 8,
  '20C3C': 8,
  '20C3D': 8,
  '20C3E': 8,
  '20C3F': 8,
  '20C40': 8,
  '20C41': 8,
  '20C42': 8,
  '20C43': 8,
  '20C44': 8,
  '20C45': 8,
  '20C46': 8,
  '20C47': 8,
  '20C48': 8,
  '20C49': 8,
  '20C4A': 8,
  '20C4B': 8,
  '20C4C': 8,
  '20C4D': 8,
  '20C4E': 8,
  '20C4F': 8,
  '20C50': 9,
  '20C51': 9,
  '20C52': 9,
  '20C53': 9,
  '20C54': 9,
  '20C55': 9,
  '20C56': 9,
  '20C57': 9,
  '20C58': 9,
  '20C59': 9,
  '20C5A': 9,
  '20C5B': 9,
  '20C5C': 9,
  '20C5D': 9,
  '20C5E': 9,
  '20C5F': 9,
  '20C60': 9,
  '20C61': 9,
  '20C62': 9,
  '20C63': 9,
  '20C64': 9,
  '20C65': 9,
  '20C66': 9,
  '20C67': 9,
  '20C68': 9,
  '20C69': 9,
  '20C6A': 9,
  '20C6B': 9,
  '20C6C': 9,
  '20C6D': 9,
  '20C6E': 9,
  '20C6F': 9,
  '20C70': 9,
  '20C71': 9,
  '20C72': 9,
  '20C73': 9,
  '20C74': 9,
  '20C75': 9,
  '20C76': 9,
  '20C77': 9,
  '20C78': 9,
  '20C79': 9,
  '20C7A': 9,
  '20C7B': 9,
  '20C7C': 9,
  '20C7D': 9,
  '20C7E': 9,
  '20C7F': 9,
  '20C80': 9,
  '20C81': 9,
  '20C82': 9,
  '20C83': 9,
  '20C84': 9,
  '20C85': 9,
  '20C86': 9,
  '20C87': 9,
  '20C88': 9,
  '20C89': 9,
  '20C8A': 9,
  '20C8B': 9,
  '20C8C': 9,
  '20C8D': 9,
  '20C8E': 9,
  '20C8F': 9,
  '20C90': 9,
  '20C91': 9,
  '20C92': 9,
  '20C93': 9,
  '20C94': 9,
  '20C95': 9,
  '20C96': 9,
  '20C97': 9,
  '20C98': 9,
  '20C99': 9,
  '20C9A': 9,
  '20C9B': 9,
  '20C9C': 9,
  '20C9D': 9,
  '20C9E': 9,
  '20C9F': 9,
  '20CA0': 9,
  '20CA1': 9,
  '20CA2': 9,
  '20CA3': 9,
  '20CA4': 9,
  '20CA5': 9,
  '20CA6': 9,
  '20CA7': 9,
  '20CA8': 9,
  '20CA9': 9,
  '20CAA': 9,
  '20CAB': 9,
  '20CAC': 10,
  '20CAD': 10,
  '20CAE': 10,
  '20CAF': 10,
  '20CB0': 10,
  '20CB1': 10,
  '20CB2': 10,
  '20CB3': 10,
  '20CB4': 10,
  '20CB5': 10,
  '20CB6': 10,
  '20CB7': 10,
  '20CB8': 10,
  '20CB9': 10,
  '20CBA': 10,
  '20CBB': 10,
  '20CBC': 10,
  '20CBD': 10,
  '20CBE': 10,
  '20CBF': 10,
  '20CC0': 10,
  '20CC1': 10,
  '20CC2': 10,
  '20CC3': 10,
  '20CC4': 10,
  '20CC5': 10,
  '20CC6': 10,
  '20CC7': 10,
  '20CC8': 10,
  '20CC9': 10,
  '20CCA': 10,
  '20CCB': 10,
  '20CCC': 10,
  '20CCD': 10,
  '20CCE': 10,
  '20CCF': 10,
  '20CD0': 10,
  '20CD1': 10,
  '20CD2': 10,
  '20CD3': 10,
  '20CD4': 10,
  '20CD5': 10,
  '20CD6': 10,
  '20CD7': 10,
  '20CD8': 10,
  '20CD9': 10,
  '20CDA': 10,
  '20CDB': 10,
  '20CDC': 10,
  '20CDD': 10,
  '20CDE': 10,
  '20CDF': 10,
  '20CE0': 10,
  '20CE1': 10,
  '20CE2': 10,
  '20CE3': 10,
  '20CE4': 10,
  '20CE5': 11,
  '20CE6': 10,
  '20CE7': 10,
  '20CE8': 10,
  '20CE9': 10,
  '20CEA': 10,
  '20CEB': 10,
  '20CEC': 10,
  '20CED': 10,
  '20CEE': 10,
  '20CEF': 10,
  '20CF0': 10,
  '20CF1': 10,
  '20CF2': 10,
  '20CF3': 10,
  '20CF4': 10,
  '20CF5': 10,
  '20CF6': 10,
  '20CF7': 10,
  '20CF8': 10,
  '20CF9': 10,
  '20CFA': 10,
  '20CFB': 10,
  '20CFC': 10,
  '20CFD': 10,
  '20CFE': 10,
  '20CFF': 10,
  '20D00': 10,
  '20D01': 10,
  '20D02': 10,
  '20D03': 10,
  '20D04': 10,
  '20D05': 10,
  '20D06': 10,
  '20D07': 10,
  '20D08': 10,
  '20D09': 10,
  '20D0A': 10,
  '20D0B': 10,
  '20D0C': 10,
  '20D0D': 10,
  '20D0E': 10,
  '20D0F': 10,
  '20D10': 10,
  '20D11': 10,
  '20D12': 10,
  '20D13': 10,
  '20D14': 10,
  '20D15': 10,
  '20D16': 10,
  '20D17': 10,
  '20D18': 10,
  '20D19': 10,
  '20D1A': 10,
  '20D1B': 10,
  '20D1C': 10,
  '20D1D': 10,
  '20D1E': 10,
  '20D1F': 10,
  '20D20': 10,
  '20D21': 10,
  '20D22': 10,
  '20D23': 10,
  '20D24': 10,
  '20D25': 10,
  '20D26': 10,
  '20D27': 11,
  '20D28': 11,
  '20D29': 11,
  '20D2A': 11,
  '20D2B': 11,
  '20D2C': 11,
  '20D2D': 11,
  '20D2E': 11,
  '20D2F': 11,
  '20D30': 11,
  '20D31': 11,
  '20D32': 11,
  '20D33': 11,
  '20D34': 11,
  '20D35': 11,
  '20D36': 11,
  '20D37': 11,
  '20D38': 11,
  '20D39': 11,
  '20D3A': 11,
  '20D3B': 11,
  '20D3C': 11,
  '20D3D': 11,
  '20D3E': 11,
  '20D3F': 11,
  '20D40': 11,
  '20D41': 11,
  '20D42': 11,
  '20D43': 11,
  '20D44': 11,
  '20D45': 11,
  '20D46': 11,
  '20D47': 11,
  '20D48': 11,
  '20D49': 11,
  '20D4A': 11,
  '20D4B': 11,
  '20D4C': 11,
  '20D4D': 11,
  '20D4E': 11,
  '20D4F': 11,
  '20D50': 11,
  '20D51': 11,
  '20D52': 11,
  '20D53': 11,
  '20D54': 11,
  '20D55': 11,
  '20D56': 11,
  '20D57': 11,
  '20D58': 11,
  '20D59': 11,
  '20D5A': 11,
  '20D5B': 11,
  '20D5C': 11,
  '20D5D': 11,
  '20D5E': 11,
  '20D5F': 11,
  '20D60': 11,
  '20D61': 11,
  '20D62': 11,
  '20D63': 11,
  '20D64': 11,
  '20D65': 11,
  '20D66': 11,
  '20D67': 11,
  '20D68': 11,
  '20D69': 11,
  '20D6A': 11,
  '20D6B': 11,
  '20D6C': 11,
  '20D6D': 11,
  '20D6E': 11,
  '20D6F': 11,
  '20D70': 11,
  '20D71': 11,
  '20D72': 11,
  '20D73': 11,
  '20D74': 11,
  '20D75': 11,
  '20D76': 11,
  '20D77': 11,
  '20D78': 11,
  '20D79': 11,
  '20D7A': 11,
  '20D7B': 11,
  '20D7C': 11,
  '20D7D': 11,
  '20D7E': 11,
  '20D7F': 11,
  '20D80': 11,
  '20D81': 11,
  '20D82': 11,
  '20D83': 11,
  '20D84': 11,
  '20D85': 11,
  '20D86': 11,
  '20D87': 11,
  '20D88': 11,
  '20D89': 11,
  '20D8A': 11,
  '20D8B': 11,
  '20D8C': 11,
  '20D8D': 11,
  '20D8E': 11,
  '20D8F': 11,
  '20D90': 11,
  '20D91': 11,
  '20D92': 11,
  '20D93': 11,
  '20D94': 11,
  '20D95': 11,
  '20D96': 11,
  '20D97': 11,
  '20D98': 11,
  '20D99': 11,
  '20D9A': 11,
  '20D9B': 11,
  '20D9C': 11,
  '20D9D': 11,
  '20D9E': 11,
  '20D9F': 11,
  '20DA0': 11,
  '20DA1': 11,
  '20DA2': 11,
  '20DA3': 11,
  '20DA4': 11,
  '20DA5': 11,
  '20DA6': 11,
  '20DA7': 11,
  '20DA8': 11,
  '20DA9': 11,
  '20DAA': 11,
  '20DAB': 11,
  '20DAC': 11,
  '20DAD': 11,
  '20DAE': 11,
  '20DAF': 11,
  '20DB0': 11,
  '20DB1': 11,
  '20DB2': 11,
  '20DB3': 11,
  '20DB4': 11,
  '20DB5': 11,
  '20DB6': 11,
  '20DB7': 12,
  '20DB8': 12,
  '20DB9': 12,
  '20DBA': 12,
  '20DBB': 12,
  '20DBC': 12,
  '20DBD': 12,
  '20DBE': 12,
  '20DBF': 12,
  '20DC0': 12,
  '20DC1': 12,
  '20DC2': 12,
  '20DC3': 12,
  '20DC4': 12,
  '20DC5': 12,
  '20DC6': 12,
  '20DC7': 12,
  '20DC8': 12,
  '20DC9': 12,
  '20DCA': 12,
  '20DCB': 12,
  '20DCC': 12,
  '20DCD': 12,
  '20DCE': 12,
  '20DCF': 12,
  '20DD0': 12,
  '20DD1': 12,
  '20DD2': 12,
  '20DD3': 12,
  '20DD4': 12,
  '20DD5': 12,
  '20DD6': 12,
  '20DD7': 12,
  '20DD8': 12,
  '20DD9': 12,
  '20DDA': 12,
  '20DDB': 12,
  '20DDC': 12,
  '20DDD': 12,
  '20DDE': 12,
  '20DDF': 12,
  '20DE0': 12,
  '20DE1': 12,
  '20DE2': 13,
  '20DE3': 12,
  '20DE4': 12,
  '20DE5': 12,
  '20DE6': 12,
  '20DE7': 12,
  '20DE8': 12,
  '20DE9': 12,
  '20DEA': 12,
  '20DEB': 12,
  '20DEC': 12,
  '20DED': 12,
  '20DEE': 12,
  '20DEF': 12,
  '20DF0': 12,
  '20DF1': 12,
  '20DF2': 12,
  '20DF3': 12,
  '20DF4': 12,
  '20DF5': 12,
  '20DF6': 12,
  '20DF7': 12,
  '20DF8': 12,
  '20DF9': 12,
  '20DFA': 12,
  '20DFB': 12,
  '20DFC': 12,
  '20DFD': 12,
  '20DFE': 12,
  '20DFF': 12,
  '20E00': 12,
  '20E01': 12,
  '20E02': 12,
  '20E03': 12,
  '20E04': 12,
  '20E05': 12,
  '20E06': 12,
  '20E07': 12,
  '20E08': 12,
  '20E09': 12,
  '20E0A': 12,
  '20E0B': 12,
  '20E0C': 12,
  '20E0D': 12,
  '20E0E': 12,
  '20E0F': 12,
  '20E10': 12,
  '20E11': 12,
  '20E12': 12,
  '20E13': 12,
  '20E14': 12,
  '20E15': 12,
  '20E16': 12,
  '20E17': 12,
  '20E18': 12,
  '20E19': 12,
  '20E1A': 12,
  '20E1B': 12,
  '20E1C': 12,
  '20E1D': 12,
  '20E1E': 12,
  '20E1F': 12,
  '20E20': 12,
  '20E21': 12,
  '20E22': 12,
  '20E23': 12,
  '20E24': 12,
  '20E25': 12,
  '20E26': 12,
  '20E27': 12,
  '20E28': 12,
  '20E29': 12,
  '20E2A': 12,
  '20E2B': 12,
  '20E2C': 12,
  '20E2D': 12,
  '20E2E': 12,
  '20E2F': 12,
  '20E30': 12,
  '20E31': 12,
  '20E32': 12,
  '20E33': 12,
  '20E34': 12,
  '20E35': 12,
  '20E36': 13,
  '20E37': 13,
  '20E38': 13,
  '20E39': 13,
  '20E3A': 13,
  '20E3B': 12,
  '20E3C': 12,
  '20E3D': 12,
  '20E3E': 12,
  '20E3F': 13,
  '20E40': 13,
  '20E41': 13,
  '20E42': 13,
  '20E43': 13,
  '20E44': 13,
  '20E45': 13,
  '20E46': 13,
  '20E47': 13,
  '20E48': 13,
  '20E49': 13,
  '20E4A': 13,
  '20E4B': 13,
  '20E4C': 13,
  '20E4D': 13,
  '20E4E': 13,
  '20E4F': 13,
  '20E50': 13,
  '20E51': 13,
  '20E52': 13,
  '20E53': 13,
  '20E54': 13,
  '20E55': 13,
  '20E56': 13,
  '20E57': 13,
  '20E58': 13,
  '20E59': 13,
  '20E5A': 13,
  '20E5B': 13,
  '20E5C': 13,
  '20E5D': 13,
  '20E5E': 13,
  '20E5F': 13,
  '20E60': 13,
  '20E61': 13,
  '20E62': 13,
  '20E63': 13,
  '20E64': 13,
  '20E65': 13,
  '20E66': 13,
  '20E67': 13,
  '20E68': 13,
  '20E69': 13,
  '20E6A': 13,
  '20E6B': 13,
  '20E6C': 13,
  '20E6D': 13,
  '20E6E': 13,
  '20E6F': 13,
  '20E70': 15,
  '20E71': 15,
  '20E72': 15,
  '20E73': 13,
  '20E74': 13,
  '20E75': 13,
  '20E76': 13,
  '20E77': 13,
  '20E78': 13,
  '20E79': 13,
  '20E7A': 13,
  '20E7B': 13,
  '20E7C': 13,
  '20E7D': 13,
  '20E7E': 13,
  '20E7F': 13,
  '20E80': 13,
  '20E81': 13,
  '20E82': 13,
  '20E83': 13,
  '20E84': 13,
  '20E85': 13,
  '20E86': 13,
  '20E87': 13,
  '20E88': 13,
  '20E89': 13,
  '20E8A': 13,
  '20E8B': 13,
  '20E8C': 13,
  '20E8D': 13,
  '20E8E': 13,
  '20E8F': 13,
  '20E90': 13,
  '20E91': 13,
  '20E92': 13,
  '20E93': 13,
  '20E94': 13,
  '20E95': 13,
  '20E96': 13,
  '20E97': 13,
  '20E98': 13,
  '20E99': 13,
  '20E9A': 13,
  '20E9B': 13,
  '20E9C': 13,
  '20E9D': 13,
  '20E9E': 13,
  '20E9F': 13,
  '20EA0': 13,
  '20EA1': 13,
  '20EA2': 13,
  '20EA3': 13,
  '20EA4': 13,
  '20EA5': 13,
  '20EA6': 13,
  '20EA7': 13,
  '20EA8': 13,
  '20EA9': 13,
  '20EAA': 13,
  '20EAB': 13,
  '20EAC': 13,
  '20EAD': 13,
  '20EAE': 13,
  '20EAF': 13,
  '20EB0': 13,
  '20EB1': 13,
  '20EB2': 13,
  '20EB3': 13,
  '20EB4': 13,
  '20EB5': 13,
  '20EB6': 13,
  '20EB7': 13,
  '20EB8': 13,
  '20EB9': 13,
  '20EBA': 13,
  '20EBB': 13,
  '20EBC': 13,
  '20EBD': 13,
  '20EBE': 13,
  '20EBF': 13,
  '20EC0': 13,
  '20EC1': 13,
  '20EC2': 13,
  '20EC3': 13,
  '20EC4': 13,
  '20EC5': 13,
  '20EC6': 13,
  '20EC7': 13,
  '20EC8': 13,
  '20EC9': 13,
  '20ECA': 13,
  '20ECB': 13,
  '20ECC': 13,
  '20ECD': 13,
  '20ECE': 13,
  '20ECF': 13,
  '20ED0': 13,
  '20ED1': 13,
  '20ED2': 13,
  '20ED3': 13,
  '20ED4': 14,
  '20ED5': 14,
  '20ED6': 14,
  '20ED7': 14,
  '20ED8': 14,
  '20ED9': 14,
  '20EDA': 14,
  '20EDB': 14,
  '20EDC': 14,
  '20EDD': 14,
  '20EDE': 14,
  '20EDF': 14,
  '20EE0': 14,
  '20EE1': 14,
  '20EE2': 14,
  '20EE3': 14,
  '20EE4': 14,
  '20EE5': 14,
  '20EE6': 14,
  '20EE7': 14,
  '20EE8': 14,
  '20EE9': 14,
  '20EEA': 14,
  '20EEB': 14,
  '20EEC': 14,
  '20EED': 14,
  '20EEE': 14,
  '20EEF': 14,
  '20EF0': 14,
  '20EF1': 14,
  '20EF2': 14,
  '20EF3': 14,
  '20EF4': 14,
  '20EF5': 14,
  '20EF6': 14,
  '20EF7': 14,
  '20EF8': 14,
  '20EF9': 14,
  '20EFA': 14,
  '20EFB': 14,
  '20EFC': 14,
  '20EFD': 14,
  '20EFE': 14,
  '20EFF': 14,
  '20F00': 14,
  '20F01': 14,
  '20F02': 14,
  '20F03': 14,
  '20F04': 14,
  '20F05': 14,
  '20F06': 14,
  '20F07': 14,
  '20F08': 14,
  '20F09': 14,
  '20F0A': 14,
  '20F0B': 14,
  '20F0C': 14,
  '20F0D': 14,
  '20F0E': 14,
  '20F0F': 14,
  '20F10': 14,
  '20F11': 14,
  '20F12': 14,
  '20F13': 14,
  '20F14': 14,
  '20F15': 14,
  '20F16': 14,
  '20F17': 14,
  '20F18': 14,
  '20F19': 14,
  '20F1A': 14,
  '20F1B': 14,
  '20F1C': 14,
  '20F1D': 14,
  '20F1E': 14,
  '20F1F': 14,
  '20F20': 14,
  '20F21': 14,
  '20F22': 14,
  '20F23': 14,
  '20F24': 14,
  '20F25': 14,
  '20F26': 14,
  '20F27': 14,
  '20F28': 14,
  '20F29': 14,
  '20F2A': 14,
  '20F2B': 14,
  '20F2C': 14,
  '20F2D': 14,
  '20F2E': 14,
  '20F2F': 14,
  '20F30': 14,
  '20F31': 14,
  '20F32': 14,
  '20F33': 14,
  '20F34': 14,
  '20F35': 14,
  '20F36': 14,
  '20F37': 14,
  '20F38': 14,
  '20F39': 14,
  '20F3A': 14,
  '20F3B': 14,
  '20F3C': 14,
  '20F3D': 14,
  '20F3E': 14,
  '20F3F': 14,
  '20F40': 14,
  '20F41': 14,
  '20F42': 14,
  '20F43': 14,
  '20F44': 14,
  '20F45': 14,
  '20F46': 14,
  '20F47': 14,
  '20F48': 14,
  '20F49': 14,
  '20F4A': 14,
  '20F4B': 14,
  '20F4C': 14,
  '20F4D': 14,
  '20F4E': 14,
  '20F4F': 14,
  '20F50': 14,
  '20F51': 14,
  '20F52': 14,
  '20F53': 14,
  '20F54': 14,
  '20F55': 14,
  '20F56': 14,
  '20F57': 14,
  '20F58': 14,
  '20F59': 14,
  '20F5A': 14,
  '20F5B': 14,
  '20F5C': 14,
  '20F5D': 14,
  '20F5E': 14,
  '20F5F': 14,
  '20F60': 14,
  '20F61': 15,
  '20F62': 15,
  '20F63': 15,
  '20F64': 15,
  '20F65': 15,
  '20F66': 15,
  '20F67': 15,
  '20F68': 15,
  '20F69': 15,
  '20F6A': 15,
  '20F6B': 15,
  '20F6C': 15,
  '20F6D': 15,
  '20F6E': 15,
  '20F6F': 15,
  '20F70': 15,
  '20F71': 15,
  '20F72': 15,
  '20F73': 15,
  '20F74': 15,
  '20F75': 15,
  '20F76': 15,
  '20F77': 15,
  '20F78': 15,
  '20F79': 15,
  '20F7A': 15,
  '20F7B': 15,
  '20F7C': 15,
  '20F7D': 15,
  '20F7E': 15,
  '20F7F': 15,
  '20F80': 15,
  '20F81': 15,
  '20F82': 15,
  '20F83': 15,
  '20F84': 15,
  '20F85': 15,
  '20F86': 15,
  '20F87': 15,
  '20F88': 15,
  '20F89': 15,
  '20F8A': 15,
  '20F8B': 15,
  '20F8C': 15,
  '20F8D': 15,
  '20F8E': 15,
  '20F8F': 15,
  '20F90': 15,
  '20F91': 15,
  '20F92': 15,
  '20F93': 15,
  '20F94': 15,
  '20F95': 15,
  '20F96': 15,
  '20F97': 15,
  '20F98': 15,
  '20F99': 15,
  '20F9A': 15,
  '20F9B': 15,
  '20F9C': 15,
  '20F9D': 15,
  '20F9E': 15,
  '20F9F': 15,
  '20FA0': 15,
  '20FA1': 15,
  '20FA2': 15,
  '20FA3': 15,
  '20FA4': 15,
  '20FA5': 15,
  '20FA6': 15,
  '20FA7': 14,
  '20FA8': 15,
  '20FA9': 15,
  '20FAA': 15,
  '20FAB': 15,
  '20FAC': 15,
  '20FAD': 15,
  '20FAE': 15,
  '20FAF': 15,
  '20FB0': 15,
  '20FB1': 15,
  '20FB2': 15,
  '20FB3': 15,
  '20FB4': 15,
  '20FB5': 15,
  '20FB6': 15,
  '20FB7': 15,
  '20FB8': 15,
  '20FB9': 15,
  '20FBA': 15,
  '20FBB': 15,
  '20FBC': 15,
  '20FBD': 15,
  '20FBE': 15,
  '20FBF': 15,
  '20FC0': 15,
  '20FC1': 15,
  '20FC2': 15,
  '20FC3': 15,
  '20FC4': 15,
  '20FC5': 15,
  '20FC6': 15,
  '20FC7': 16,
  '20FC8': 16,
  '20FC9': 16,
  '20FCA': 16,
  '20FCB': 16,
  '20FCC': 16,
  '20FCD': 16,
  '20FCE': 16,
  '20FCF': 16,
  '20FD0': 16,
  '20FD1': 16,
  '20FD2': 16,
  '20FD3': 16,
  '20FD4': 16,
  '20FD5': 16,
  '20FD6': 16,
  '20FD7': 16,
  '20FD8': 16,
  '20FD9': 16,
  '20FDA': 16,
  '20FDB': 16,
  '20FDC': 16,
  '20FDD': 16,
  '20FDE': 16,
  '20FDF': 16,
  '20FE0': 16,
  '20FE1': 16,
  '20FE2': 16,
  '20FE3': 16,
  '20FE4': 16,
  '20FE5': 16,
  '20FE6': 16,
  '20FE7': 16,
  '20FE8': 16,
  '20FE9': 16,
  '20FEA': 16,
  '20FEB': 16,
  '20FEC': 16,
  '20FED': 16,
  '20FEE': 16,
  '20FEF': 16,
  '20FF0': 16,
  '20FF1': 16,
  '20FF2': 16,
  '20FF3': 16,
  '20FF4': 16,
  '20FF5': 16,
  '20FF6': 16,
  '20FF7': 16,
  '20FF8': 16,
  '20FF9': 16,
  '20FFA': 16,
  '20FFB': 16,
  '20FFC': 16,
  '20FFD': 16,
  '20FFE': 16,
  '20FFF': 16,
  '21000': 16,
  '21001': 16,
  '21002': 16,
  '21003': 16,
  '21004': 16,
  '21005': 16,
  '21006': 16,
  '21007': 16,
  '21008': 16,
  '21009': 16,
  '2100A': 16,
  '2100B': 16,
  '2100C': 16,
  '2100D': 16,
  '2100E': 16,
  '2100F': 16,
  '21010': 16,
  '21011': 16,
  '21012': 16,
  '21013': 16,
  '21014': 15,
  '21015': 16,
  '21016': 16,
  '21017': 16,
  '21018': 16,
  '21019': 16,
  '2101A': 16,
  '2101B': 16,
  '2101C': 16,
  '2101D': 16,
  '2101E': 16,
  '2101F': 16,
  '21020': 16,
  '21021': 16,
  '21022': 16,
  '21023': 16,
  '21024': 16,
  '21025': 16,
  '21026': 15,
  '21027': 16,
  '21028': 16,
  '21029': 16,
  '2102A': 16,
  '2102B': 16,
  '2102C': 16,
  '2102D': 16,
  '2102E': 16,
  '2102F': 16,
  '21030': 16,
  '21031': 16,
  '21032': 16,
  '21033': 16,
  '21034': 16,
  '21035': 16,
  '21036': 16,
  '21037': 16,
  '21038': 16,
  '21039': 16,
  '2103A': 16,
  '2103B': 16,
  '2103C': 16,
  '2103D': 17,
  '2103E': 17,
  '2103F': 17,
  '21040': 17,
  '21041': 17,
  '21042': 17,
  '21043': 17,
  '21044': 17,
  '21045': 17,
  '21046': 17,
  '21047': 17,
  '21048': 17,
  '21049': 17,
  '2104A': 17,
  '2104B': 17,
  '2104C': 17,
  '2104D': 17,
  '2104E': 17,
  '2104F': 17,
  '21050': 17,
  '21051': 17,
  '21052': 17,
  '21053': 17,
  '21054': 17,
  '21055': 17,
  '21056': 17,
  '21057': 17,
  '21058': 17,
  '21059': 17,
  '2105A': 17,
  '2105B': 17,
  '2105C': 17,
  '2105D': 17,
  '2105E': 17,
  '2105F': 17,
  '21060': 17,
  '21061': 17,
  '21062': 17,
  '21063': 17,
  '21064': 17,
  '21065': 17,
  '21066': 17,
  '21067': 17,
  '21068': 17,
  '21069': 17,
  '2106A': 17,
  '2106B': 17,
  '2106C': 17,
  '2106D': 17,
  '2106E': 17,
  '2106F': 17,
  '21070': 17,
  '21071': 17,
  '21072': 17,
  '21073': 17,
  '21074': 17,
  '21075': 17,
  '21076': 17,
  '21077': 17,
  '21078': 17,
  '21079': 17,
  '2107A': 17,
  '2107B': 17,
  '2107C': 17,
  '2107D': 17,
  '2107E': 17,
  '2107F': 17,
  '21080': 17,
  '21081': 17,
  '21082': 17,
  '21083': 17,
  '21084': 17,
  '21085': 17,
  '21086': 17,
  '21087': 17,
  '21088': 17,
  '21089': 17,
  '2108A': 17,
  '2108B': 17,
  '2108C': 17,
  '2108D': 17,
  '2108E': 17,
  '2108F': 18,
  '21090': 18,
  '21091': 18,
  '21092': 18,
  '21093': 18,
  '21094': 18,
  '21095': 18,
  '21096': 18,
  '21097': 18,
  '21098': 18,
  '21099': 18,
  '2109A': 18,
  '2109B': 18,
  '2109C': 18,
  '2109D': 18,
  '2109E': 18,
  '2109F': 18,
  '210A0': 18,
  '210A1': 18,
  '210A2': 18,
  '210A3': 18,
  '210A4': 18,
  '210A5': 18,
  '210A6': 18,
  '210A7': 18,
  '210A8': 18,
  '210A9': 18,
  '210AA': 18,
  '210AB': 18,
  '210AC': 18,
  '210AD': 18,
  '210AE': 18,
  '210AF': 18,
  '210B0': 18,
  '210B1': 18,
  '210B2': 18,
  '210B3': 18,
  '210B4': 18,
  '210B5': 18,
  '210B6': 18,
  '210B7': 18,
  '210B8': 18,
  '210B9': 18,
  '210BA': 18,
  '210BB': 18,
  '210BC': 18,
  '210BD': 18,
  '210BE': 18,
  '210BF': 18,
  '210C0': 18,
  '210C1': 18,
  '210C2': 18,
  '210C3': 18,
  '210C4': 18,
  '210C5': 18,
  '210C6': 18,
  '210C7': 18,
  '210C8': 18,
  '210C9': 18,
  '210CA': 18,
  '210CB': 18,
  '210CC': 18,
  '210CD': 18,
  '210CE': 18,
  '210CF': 18,
  '210D0': 18,
  '210D1': 18,
  '210D2': 18,
  '210D3': 18,
  '210D4': 18,
  '210D5': 18,
  '210D6': 18,
  '210D7': 18,
  '210D8': 18,
  '210D9': 18,
  '210DA': 18,
  '210DB': 18,
  '210DC': 18,
  '210DD': 18,
  '210DE': 18,
  '210DF': 18,
  '210E0': 19,
  '210E1': 19,
  '210E2': 19,
  '210E3': 19,
  '210E4': 19,
  '210E5': 19,
  '210E6': 19,
  '210E7': 19,
  '210E8': 19,
  '210E9': 19,
  '210EA': 19,
  '210EB': 19,
  '210EC': 19,
  '210ED': 19,
  '210EE': 19,
  '210EF': 19,
  '210F0': 18,
  '210F1': 19,
  '210F2': 19,
  '210F3': 19,
  '210F4': 19,
  '210F5': 19,
  '210F6': 19,
  '210F7': 19,
  '210F8': 19,
  '210F9': 19,
  '210FA': 19,
  '210FB': 19,
  '210FC': 19,
  '210FD': 19,
  '210FE': 19,
  '210FF': 19,
  '21100': 19,
  '21101': 19,
  '21102': 19,
  '21103': 19,
  '21104': 19,
  '21105': 19,
  '21106': 19,
  '21107': 19,
  '21108': 19,
  '21109': 19,
  '2110A': 19,
  '2110B': 19,
  '2110C': 19,
  '2110D': 19,
  '2110E': 19,
  '2110F': 19,
  '21110': 19,
  '21111': 20,
  '21112': 20,
  '21113': 20,
  '21114': 20,
  '21115': 20,
  '21116': 20,
  '21117': 20,
  '21118': 20,
  '21119': 20,
  '2111A': 20,
  '2111B': 20,
  '2111C': 20,
  '2111D': 20,
  '2111E': 20,
  '2111F': 20,
  '21120': 20,
  '21121': 20,
  '21122': 20,
  '21123': 20,
  '21124': 20,
  '21125': 20,
  '21126': 20,
  '21127': 20,
  '21128': 20,
  '21129': 20,
  '2112A': 20,
  '2112B': 20,
  '2112C': 20,
  '2112D': 20,
  '2112E': 20,
  '2112F': 20,
  '21130': 20,
  '21131': 21,
  '21132': 21,
  '21133': 21,
  '21134': 21,
  '21135': 21,
  '21136': 21,
  '21137': 21,
  '21138': 21,
  '21139': 21,
  '2113A': 21,
  '2113B': 21,
  '2113C': 21,
  '2113D': 21,
  '2113E': 21,
  '2113F': 21,
  '21140': 21,
  '21141': 21,
  '21142': 21,
  '21143': 21,
  '21144': 21,
  '21145': 21,
  '21146': 21,
  '21147': 21,
  '21148': 21,
  '21149': 21,
  '2114A': 21,
  '2114B': 21,
  '2114C': 21,
  '2114D': 21,
  '2114E': 21,
  '2114F': 21,
  '21150': 21,
  '21151': 21,
  '21152': 21,
  '21153': 21,
  '21154': 21,
  '21155': 21,
  '21156': 21,
  '21157': 22,
  '21158': 22,
  '21159': 22,
  '2115A': 22,
  '2115B': 22,
  '2115C': 22,
  '2115D': 22,
  '2115E': 22,
  '2115F': 22,
  '21160': 22,
  '21161': 22,
  '21162': 22,
  '21163': 21,
  '21164': 22,
  '21165': 22,
  '21166': 22,
  '21167': 22,
  '21168': 22,
  '21169': 22,
  '2116A': 22,
  '2116B': 22,
  '2116C': 22,
  '2116D': 22,
  '2116E': 22,
  '2116F': 22,
  '21170': 23,
  '21171': 23,
  '21172': 23,
  '21173': 23,
  '21174': 23,
  '21175': 23,
  '21176': 23,
  '21177': 23,
  '21178': 23,
  '21179': 24,
  '2117A': 24,
  '2117B': 24,
  '2117C': 24,
  '2117D': 24,
  '2117E': 24,
  '2117F': 24,
  '21180': 24,
  '21181': 24,
  '21182': 24,
  '21183': 24,
  '21184': 24,
  '21185': 24,
  '21186': 25,
  '21187': 25,
  '21188': 25,
  '21189': 25,
  '2118A': 25,
  '2118B': 25,
  '2118C': 25,
  '2118D': 25,
  '2118E': 25,
  '2118F': 26,
  '21190': 26,
  '21191': 26,
  '21192': 26,
  '21193': 26,
  '21194': 26,
  '21195': 26,
  '21196': 26,
  '21197': 26,
  '21198': 26,
  '21199': 27,
  '2119A': 27,
  '2119B': 28,
  '2119C': 28,
  '2119D': 28,
  '2119E': 27,
  '2119F': 31,
  '211A0': 4,
  '211A1': 4,
  '211A2': 4,
  '211A3': 5,
  '211A4': 5,
  '211A5': 5,
  '211A6': 5,
  '211A7': 6,
  '211A8': 6,
  '211A9': 6,
  '211AA': 6,
  '211AB': 6,
  '211AC': 6,
  '211AD': 6,
  '211AE': 6,
  '211AF': 6,
  '211B0': 6,
  '211B1': 6,
  '211B2': 6,
  '211B3': 6,
  '211B4': 7,
  '211B5': 7,
  '211B6': 7,
  '211B7': 7,
  '211B8': 7,
  '211B9': 7,
  '211BA': 7,
  '211BB': 7,
  '211BC': 7,
  '211BD': 7,
  '211BE': 7,
  '211BF': 7,
  '211C0': 7,
  '211C1': 7,
  '211C2': 7,
  '211C3': 7,
  '211C4': 7,
  '211C5': 7,
  '211C6': 7,
  '211C7': 7,
  '211C8': 8,
  '211C9': 8,
  '211CA': 8,
  '211CB': 8,
  '211CC': 8,
  '211CD': 8,
  '211CE': 8,
  '211CF': 8,
  '211D0': 8,
  '211D1': 8,
  '211D2': 9,
  '211D3': 9,
  '211D4': 9,
  '211D5': 9,
  '211D6': 9,
  '211D7': 9,
  '211D8': 9,
  '211D9': 9,
  '211DA': 9,
  '211DB': 9,
  '211DC': 9,
  '211DD': 9,
  '211DE': 9,
  '211DF': 9,
  '211E0': 10,
  '211E1': 10,
  '211E2': 10,
  '211E3': 10,
  '211E4': 10,
  '211E5': 10,
  '211E6': 10,
  '211E7': 10,
  '211E8': 10,
  '211E9': 10,
  '211EA': 10,
  '211EB': 10,
  '211EC': 10,
  '211ED': 10,
  '211EE': 10,
  '211EF': 10,
  '211F0': 11,
  '211F1': 11,
  '211F2': 11,
  '211F3': 11,
  '211F4': 11,
  '211F5': 11,
  '211F6': 11,
  '211F7': 11,
  '211F8': 11,
  '211F9': 11,
  '211FA': 11,
  '211FB': 11,
  '211FC': 12,
  '211FD': 12,
  '211FE': 12,
  '211FF': 12,
  '21200': 11,
  '21201': 12,
  '21202': 12,
  '21203': 12,
  '21204': 12,
  '21205': 12,
  '21206': 12,
  '21207': 12,
  '21208': 12,
  '21209': 12,
  '2120A': 13,
  '2120B': 13,
  '2120C': 13,
  '2120D': 13,
  '2120E': 13,
  '2120F': 13,
  '21210': 13,
  '21211': 13,
  '21212': 13,
  '21213': 13,
  '21214': 13,
  '21215': 14,
  '21216': 14,
  '21217': 14,
  '21218': 14,
  '21219': 14,
  '2121A': 14,
  '2121B': 14,
  '2121C': 14,
  '2121D': 14,
  '2121E': 14,
  '2121F': 14,
  '21220': 14,
  '21221': 14,
  '21222': 15,
  '21223': 15,
  '21224': 15,
  '21225': 16,
  '21226': 15,
  '21227': 15,
  '21228': 15,
  '21229': 15,
  '2122A': 16,
  '2122B': 16,
  '2122C': 16,
  '2122D': 17,
  '2122E': 17,
  '2122F': 17,
  '21230': 17,
  '21231': 17,
  '21232': 18,
  '21233': 19,
  '21234': 19,
  '21235': 20,
  '21236': 20,
  '21237': 20,
  '21238': 21,
  '21239': 21,
  '2123A': 21,
  '2123B': 26,
  '2123C': 4,
  '2123D': 4,
  '2123E': 4,
  '2123F': 5,
  '21240': 5,
  '21241': 5,
  '21242': 5,
  '21243': 5,
  '21244': 5,
  '21245': 5,
  '21246': 5,
  '21247': 5,
  '21248': 6,
  '21249': 6,
  '2124A': 6,
  '2124B': 6,
  '2124C': 6,
  '2124D': 6,
  '2124E': 6,
  '2124F': 6,
  '21250': 6,
  '21251': 6,
  '21252': 6,
  '21253': 6,
  '21254': 6,
  '21255': 6,
  '21256': 6,
  '21257': 6,
  '21258': 7,
  '21259': 7,
  '2125A': 7,
  '2125B': 7,
  '2125C': 7,
  '2125D': 7,
  '2125E': 7,
  '2125F': 7,
  '21260': 7,
  '21261': 7,
  '21262': 7,
  '21263': 7,
  '21264': 7,
  '21265': 7,
  '21266': 7,
  '21267': 7,
  '21268': 7,
  '21269': 7,
  '2126A': 7,
  '2126B': 7,
  '2126C': 7,
  '2126D': 7,
  '2126E': 7,
  '2126F': 7,
  '21270': 7,
  '21271': 7,
  '21272': 7,
  '21273': 7,
  '21274': 7,
  '21275': 7,
  '21276': 7,
  '21277': 7,
  '21278': 7,
  '21279': 7,
  '2127A': 7,
  '2127B': 7,
  '2127C': 7,
  '2127D': 7,
  '2127E': 7,
  '2127F': 7,
  '21280': 7,
  '21281': 7,
  '21282': 7,
  '21283': 7,
  '21284': 8,
  '21285': 8,
  '21286': 8,
  '21287': 8,
  '21288': 8,
  '21289': 8,
  '2128A': 8,
  '2128B': 8,
  '2128C': 8,
  '2128D': 8,
  '2128E': 8,
  '2128F': 8,
  '21290': 8,
  '21291': 8,
  '21292': 8,
  '21293': 8,
  '21294': 8,
  '21295': 8,
  '21296': 8,
  '21297': 8,
  '21298': 8,
  '21299': 8,
  '2129A': 8,
  '2129B': 8,
  '2129C': 8,
  '2129D': 8,
  '2129E': 8,
  '2129F': 8,
  '212A0': 8,
  '212A1': 8,
  '212A2': 8,
  '212A3': 8,
  '212A4': 8,
  '212A5': 8,
  '212A6': 8,
  '212A7': 8,
  '212A8': 8,
  '212A9': 8,
  '212AA': 8,
  '212AB': 8,
  '212AC': 8,
  '212AD': 8,
  '212AE': 8,
  '212AF': 8,
  '212B0': 8,
  '212B1': 8,
  '212B2': 8,
  '212B3': 8,
  '212B4': 8,
  '212B5': 8,
  '212B6': 8,
  '212B7': 9,
  '212B8': 9,
  '212B9': 9,
  '212BA': 9,
  '212BB': 9,
  '212BC': 9,
  '212BD': 9,
  '212BE': 9,
  '212BF': 9,
  '212C0': 9,
  '212C1': 9,
  '212C2': 9,
  '212C3': 9,
  '212C4': 9,
  '212C5': 9,
  '212C6': 9,
  '212C7': 9,
  '212C8': 9,
  '212C9': 9,
  '212CA': 9,
  '212CB': 9,
  '212CC': 9,
  '212CD': 9,
  '212CE': 9,
  '212CF': 9,
  '212D0': 9,
  '212D1': 9,
  '212D2': 9,
  '212D3': 9,
  '212D4': 9,
  '212D5': 9,
  '212D6': 9,
  '212D7': 9,
  '212D8': 9,
  '212D9': 9,
  '212DA': 9,
  '212DB': 9,
  '212DC': 9,
  '212DD': 9,
  '212DE': 9,
  '212DF': 9,
  '212E0': 9,
  '212E1': 9,
  '212E2': 9,
  '212E3': 9,
  '212E4': 9,
  '212E5': 9,
  '212E6': 9,
  '212E7': 9,
  '212E8': 9,
  '212E9': 9,
  '212EA': 9,
  '212EB': 9,
  '212EC': 9,
  '212ED': 10,
  '212EE': 10,
  '212EF': 10,
  '212F0': 10,
  '212F1': 10,
  '212F2': 10,
  '212F3': 10,
  '212F4': 10,
  '212F5': 10,
  '212F6': 10,
  '212F7': 10,
  '212F8': 10,
  '212F9': 10,
  '212FA': 10,
  '212FB': 10,
  '212FC': 10,
  '212FD': 10,
  '212FE': 10,
  '212FF': 10,
  '21300': 10,
  '21301': 10,
  '21302': 10,
  '21303': 10,
  '21304': 10,
  '21305': 10,
  '21306': 10,
  '21307': 10,
  '21308': 10,
  '21309': 10,
  '2130A': 10,
  '2130B': 10,
  '2130C': 10,
  '2130D': 10,
  '2130E': 10,
  '2130F': 10,
  '21310': 10,
  '21311': 10,
  '21312': 10,
  '21313': 10,
  '21314': 10,
  '21315': 10,
  '21316': 10,
  '21317': 10,
  '21318': 10,
  '21319': 10,
  '2131A': 10,
  '2131B': 10,
  '2131C': 10,
  '2131D': 10,
  '2131E': 10,
  '2131F': 10,
  '21320': 10,
  '21321': 10,
  '21322': 10,
  '21323': 10,
  '21324': 10,
  '21325': 10,
  '21326': 11,
  '21327': 11,
  '21328': 11,
  '21329': 11,
  '2132A': 11,
  '2132B': 11,
  '2132C': 11,
  '2132D': 11,
  '2132E': 11,
  '2132F': 11,
  '21330': 11,
  '21331': 11,
  '21332': 11,
  '21333': 11,
  '21334': 11,
  '21335': 11,
  '21336': 11,
  '21337': 11,
  '21338': 11,
  '21339': 11,
  '2133A': 11,
  '2133B': 11,
  '2133C': 11,
  '2133D': 11,
  '2133E': 11,
  '2133F': 11,
  '21340': 11,
  '21341': 11,
  '21342': 11,
  '21343': 11,
  '21344': 11,
  '21345': 11,
  '21346': 11,
  '21347': 11,
  '21348': 11,
  '21349': 11,
  '2134A': 11,
  '2134B': 11,
  '2134C': 11,
  '2134D': 11,
  '2134E': 11,
  '2134F': 11,
  '21350': 11,
  '21351': 11,
  '21352': 11,
  '21353': 11,
  '21354': 11,
  '21355': 11,
  '21356': 11,
  '21357': 11,
  '21358': 11,
  '21359': 11,
  '2135A': 11,
  '2135B': 11,
  '2135C': 11,
  '2135D': 11,
  '2135E': 11,
  '2135F': 11,
  '21360': 11,
  '21361': 11,
  '21362': 11,
  '21363': 11,
  '21364': 12,
  '21365': 12,
  '21366': 12,
  '21367': 12,
  '21368': 12,
  '21369': 12,
  '2136A': 12,
  '2136B': 12,
  '2136C': 12,
  '2136D': 12,
  '2136E': 12,
  '2136F': 12,
  '21370': 12,
  '21371': 12,
  '21372': 12,
  '21373': 12,
  '21374': 12,
  '21375': 12,
  '21376': 12,
  '21377': 12,
  '21378': 12,
  '21379': 12,
  '2137A': 12,
  '2137B': 12,
  '2137C': 12,
  '2137D': 12,
  '2137E': 12,
  '2137F': 12,
  '21380': 12,
  '21381': 12,
  '21382': 12,
  '21383': 12,
  '21384': 12,
  '21385': 12,
  '21386': 12,
  '21387': 12,
  '21388': 12,
  '21389': 12,
  '2138A': 12,
  '2138B': 12,
  '2138C': 12,
  '2138D': 12,
  '2138E': 12,
  '2138F': 12,
  '21390': 12,
  '21391': 12,
  '21392': 12,
  '21393': 12,
  '21394': 12,
  '21395': 12,
  '21396': 12,
  '21397': 12,
  '21398': 12,
  '21399': 12,
  '2139A': 12,
  '2139B': 12,
  '2139C': 12,
  '2139D': 12,
  '2139E': 12,
  '2139F': 12,
  '213A0': 12,
  '213A1': 12,
  '213A2': 12,
  '213A3': 12,
  '213A4': 12,
  '213A5': 12,
  '213A6': 12,
  '213A7': 12,
  '213A8': 12,
  '213A9': 12,
  '213AA': 12,
  '213AB': 12,
  '213AC': 12,
  '213AD': 12,
  '213AE': 13,
  '213AF': 13,
  '213B0': 13,
  '213B1': 13,
  '213B2': 13,
  '213B3': 13,
  '213B4': 13,
  '213B5': 13,
  '213B6': 13,
  '213B7': 13,
  '213B8': 13,
  '213B9': 13,
  '213BA': 13,
  '213BB': 13,
  '213BC': 13,
  '213BD': 13,
  '213BE': 13,
  '213BF': 13,
  '213C0': 13,
  '213C1': 13,
  '213C2': 13,
  '213C3': 13,
  '213C4': 13,
  '213C5': 13,
  '213C6': 13,
  '213C7': 13,
  '213C8': 13,
  '213C9': 13,
  '213CA': 13,
  '213CB': 13,
  '213CC': 13,
  '213CD': 13,
  '213CE': 13,
  '213CF': 13,
  '213D0': 13,
  '213D1': 13,
  '213D2': 13,
  '213D3': 13,
  '213D4': 13,
  '213D5': 13,
  '213D6': 13,
  '213D7': 13,
  '213D8': 13,
  '213D9': 13,
  '213DA': 13,
  '213DB': 13,
  '213DC': 13,
  '213DD': 13,
  '213DE': 13,
  '213DF': 13,
  '213E0': 13,
  '213E1': 13,
  '213E2': 13,
  '213E3': 13,
  '213E4': 13,
  '213E5': 13,
  '213E6': 13,
  '213E7': 13,
  '213E8': 13,
  '213E9': 13,
  '213EA': 13,
  '213EB': 13,
  '213EC': 13,
  '213ED': 14,
  '213EE': 14,
  '213EF': 14,
  '213F0': 14,
  '213F1': 14,
  '213F2': 14,
  '213F3': 14,
  '213F4': 14,
  '213F5': 14,
  '213F6': 14,
  '213F7': 14,
  '213F8': 14,
  '213F9': 14,
  '213FA': 14,
  '213FB': 14,
  '213FC': 14,
  '213FD': 14,
  '213FE': 14,
  '213FF': 14,
  '21400': 14,
  '21401': 14,
  '21402': 14,
  '21403': 14,
  '21404': 14,
  '21405': 14,
  '21406': 14,
  '21407': 14,
  '21408': 14,
  '21409': 14,
  '2140A': 14,
  '2140B': 14,
  '2140C': 14,
  '2140D': 14,
  '2140E': 14,
  '2140F': 14,
  '21410': 14,
  '21411': 14,
  '21412': 14,
  '21413': 14,
  '21414': 14,
  '21415': 14,
  '21416': 14,
  '21417': 14,
  '21418': 14,
  '21419': 14,
  '2141A': 14,
  '2141B': 14,
  '2141C': 14,
  '2141D': 14,
  '2141E': 15,
  '2141F': 15,
  '21420': 15,
  '21421': 15,
  '21422': 15,
  '21423': 15,
  '21424': 15,
  '21425': 15,
  '21426': 15,
  '21427': 15,
  '21428': 15,
  '21429': 15,
  '2142A': 15,
  '2142B': 15,
  '2142C': 15,
  '2142D': 15,
  '2142E': 15,
  '2142F': 15,
  '21430': 15,
  '21431': 15,
  '21432': 15,
  '21433': 15,
  '21434': 15,
  '21435': 15,
  '21436': 15,
  '21437': 15,
  '21438': 15,
  '21439': 15,
  '2143A': 15,
  '2143B': 15,
  '2143C': 15,
  '2143D': 15,
  '2143E': 15,
  '2143F': 15,
  '21440': 15,
  '21441': 15,
  '21442': 15,
  '21443': 15,
  '21444': 15,
  '21445': 15,
  '21446': 15,
  '21447': 15,
  '21448': 15,
  '21449': 15,
  '2144A': 15,
  '2144B': 15,
  '2144C': 15,
  '2144D': 15,
  '2144E': 15,
  '2144F': 15,
  '21450': 15,
  '21451': 15,
  '21452': 15,
  '21453': 15,
  '21454': 15,
  '21455': 15,
  '21456': 15,
  '21457': 15,
  '21458': 15,
  '21459': 15,
  '2145A': 15,
  '2145B': 15,
  '2145C': 15,
  '2145D': 15,
  '2145E': 16,
  '2145F': 16,
  '21460': 16,
  '21461': 16,
  '21462': 16,
  '21463': 16,
  '21464': 16,
  '21465': 16,
  '21466': 16,
  '21467': 16,
  '21468': 16,
  '21469': 16,
  '2146A': 16,
  '2146B': 16,
  '2146C': 16,
  '2146D': 16,
  '2146E': 16,
  '2146F': 16,
  '21470': 16,
  '21471': 16,
  '21472': 16,
  '21473': 16,
  '21474': 16,
  '21475': 16,
  '21476': 16,
  '21477': 16,
  '21478': 16,
  '21479': 16,
  '2147A': 16,
  '2147B': 16,
  '2147C': 16,
  '2147D': 16,
  '2147E': 16,
  '2147F': 16,
  '21480': 16,
  '21481': 16,
  '21482': 16,
  '21483': 16,
  '21484': 16,
  '21485': 16,
  '21486': 16,
  '21487': 16,
  '21488': 16,
  '21489': 17,
  '2148A': 17,
  '2148B': 17,
  '2148C': 17,
  '2148D': 17,
  '2148E': 17,
  '2148F': 17,
  '21490': 17,
  '21491': 17,
  '21492': 17,
  '21493': 17,
  '21494': 17,
  '21495': 17,
  '21496': 17,
  '21497': 17,
  '21498': 17,
  '21499': 17,
  '2149A': 17,
  '2149B': 17,
  '2149C': 17,
  '2149D': 17,
  '2149E': 17,
  '2149F': 17,
  '214A0': 17,
  '214A1': 17,
  '214A2': 17,
  '214A3': 17,
  '214A4': 17,
  '214A5': 17,
  '214A6': 17,
  '214A7': 17,
  '214A8': 17,
  '214A9': 17,
  '214AA': 17,
  '214AB': 17,
  '214AC': 17,
  '214AD': 17,
  '214AE': 17,
  '214AF': 17,
  '214B0': 18,
  '214B1': 18,
  '214B2': 18,
  '214B3': 18,
  '214B4': 18,
  '214B5': 18,
  '214B6': 18,
  '214B7': 18,
  '214B8': 18,
  '214B9': 18,
  '214BA': 18,
  '214BB': 18,
  '214BC': 18,
  '214BD': 18,
  '214BE': 18,
  '214BF': 18,
  '214C0': 19,
  '214C1': 18,
  '214C2': 18,
  '214C3': 18,
  '214C4': 18,
  '214C5': 18,
  '214C6': 18,
  '214C7': 18,
  '214C8': 18,
  '214C9': 18,
  '214CA': 18,
  '214CB': 18,
  '214CC': 19,
  '214CD': 18,
  '214CE': 18,
  '214CF': 18,
  '214D0': 19,
  '214D1': 19,
  '214D2': 19,
  '214D3': 19,
  '214D4': 19,
  '214D5': 19,
  '214D6': 19,
  '214D7': 19,
  '214D8': 19,
  '214D9': 19,
  '214DA': 19,
  '214DB': 19,
  '214DC': 19,
  '214DD': 19,
  '214DE': 19,
  '214DF': 19,
  '214E0': 19,
  '214E1': 19,
  '214E2': 19,
  '214E3': 19,
  '214E4': 19,
  '214E5': 19,
  '214E6': 20,
  '214E7': 20,
  '214E8': 20,
  '214E9': 20,
  '214EA': 20,
  '214EB': 20,
  '214EC': 20,
  '214ED': 20,
  '214EE': 20,
  '214EF': 20,
  '214F0': 20,
  '214F1': 21,
  '214F2': 21,
  '214F3': 21,
  '214F4': 21,
  '214F5': 21,
  '214F6': 21,
  '214F7': 20,
  '214F8': 21,
  '214F9': 21,
  '214FA': 22,
  '214FB': 21,
  '214FC': 21,
  '214FD': 21,
  '214FE': 21,
  '214FF': 21,
  '21500': 21,
  '21501': 21,
  '21502': 22,
  '21503': 22,
  '21504': 22,
  '21505': 22,
  '21506': 22,
  '21507': 22,
  '21508': 22,
  '21509': 22,
  '2150A': 23,
  '2150B': 23,
  '2150C': 23,
  '2150D': 23,
  '2150E': 23,
  '2150F': 24,
  '21510': 24,
  '21511': 24,
  '21512': 24,
  '21513': 24,
  '21514': 24,
  '21515': 24,
  '21516': 26,
  '21517': 29,
  '21518': 30,
  '21519': 36,
  '2151A': 39,
  '2151B': 5,
  '2151C': 6,
  '2151D': 7,
  '2151E': 7,
  '2151F': 8,
  '21520': 9,
  '21521': 9,
  '21522': 9,
  '21523': 10,
  '21524': 10,
  '21525': 10,
  '21526': 10,
  '21527': 10,
  '21528': 10,
  '21529': 10,
  '2152A': 11,
  '2152B': 11,
  '2152C': 11,
  '2152D': 11,
  '2152E': 12,
  '2152F': 12,
  '21530': 13,
  '21531': 12,
  '21532': 13,
  '21533': 13,
  '21534': 13,
  '21535': 13,
  '21536': 14,
  '21537': 13,
  '21538': 14,
  '21539': 14,
  '2153A': 14,
  '2153B': 14,
  '2153C': 15,
  '2153D': 15,
  '2153E': 16,
  '2153F': 16,
  '21540': 16,
  '21541': 17,
  '21542': 17,
  '21543': 17,
  '21544': 17,
  '21545': 18,
  '21546': 18,
  '21547': 18,
  '21548': 18,
  '21549': 18,
  '2154A': 18,
  '2154B': 19,
  '2154C': 19,
  '2154D': 20,
  '2154E': 20,
  '2154F': 22,
  '21550': 22,
  '21551': 22,
  '21552': 3,
  '21553': 5,
  '21554': 5,
  '21555': 6,
  '21556': 6,
  '21557': 7,
  '21558': 7,
  '21559': 7,
  '2155A': 10,
  '2155B': 11,
  '2155C': 11,
  '2155D': 13,
  '2155E': 6,
  '2155F': 6,
  '21560': 7,
  '21561': 7,
  '21562': 8,
  '21563': 8,
  '21564': 8,
  '21565': 8,
  '21566': 10,
  '21567': 11,
  '21568': 11,
  '21569': 11,
  '2156A': 11,
  '2156B': 11,
  '2156C': 11,
  '2156D': 12,
  '2156E': 13,
  '2156F': 13,
  '21570': 13,
  '21571': 13,
  '21572': 14,
  '21573': 14,
  '21574': 14,
  '21575': 14,
  '21576': 15,
  '21577': 15,
  '21578': 16,
  '21579': 16,
  '2157A': 16,
  '2157B': 17,
  '2157C': 18,
  '2157D': 18,
  '2157E': 18,
  '2157F': 18,
  '21580': 19,
  '21581': 20,
  '21582': 23,
  '21583': 30,
  '21584': 5,
  '21585': 5,
  '21586': 5,
  '21587': 6,
  '21588': 6,
  '21589': 6,
  '2158A': 7,
  '2158B': 7,
  '2158C': 7,
  '2158D': 8,
  '2158E': 8,
  '2158F': 8,
  '21590': 9,
  '21591': 9,
  '21592': 10,
  '21593': 10,
  '21594': 10,
  '21595': 10,
  '21596': 10,
  '21597': 10,
  '21598': 10,
  '21599': 10,
  '2159A': 10,
  '2159B': 10,
  '2159C': 10,
  '2159D': 11,
  '2159E': 11,
  '2159F': 11,
  '215A0': 11,
  '215A1': 11,
  '215A2': 11,
  '215A3': 11,
  '215A4': 11,
  '215A5': 11,
  '215A6': 12,
  '215A7': 12,
  '215A8': 12,
  '215A9': 12,
  '215AA': 12,
  '215AB': 12,
  '215AC': 12,
  '215AD': 12,
  '215AE': 12,
  '215AF': 13,
  '215B0': 12,
  '215B1': 13,
  '215B2': 13,
  '215B3': 13,
  '215B4': 13,
  '215B5': 13,
  '215B6': 14,
  '215B7': 14,
  '215B8': 14,
  '215B9': 14,
  '215BA': 14,
  '215BB': 14,
  '215BC': 14,
  '215BD': 14,
  '215BE': 14,
  '215BF': 15,
  '215C0': 15,
  '215C1': 15,
  '215C2': 16,
  '215C3': 16,
  '215C4': 16,
  '215C5': 16,
  '215C6': 17,
  '215C7': 17,
  '215C8': 17,
  '215C9': 18,
  '215CA': 18,
  '215CB': 19,
  '215CC': 19,
  '215CD': 21,
  '215CE': 21,
  '215CF': 26,
  '215D0': 19,
  '215D1': 31,
  '215D2': 4,
  '215D3': 4,
  '215D4': 4,
  '215D5': 5,
  '215D6': 5,
  '215D7': 5,
  '215D8': 5,
  '215D9': 5,
  '215DA': 5,
  '215DB': 5,
  '215DC': 4,
  '215DD': 6,
  '215DE': 6,
  '215DF': 6,
  '215E0': 6,
  '215E1': 6,
  '215E2': 6,
  '215E3': 6,
  '215E4': 6,
  '215E5': 7,
  '215E6': 7,
  '215E7': 7,
  '215E8': 7,
  '215E9': 7,
  '215EA': 7,
  '215EB': 7,
  '215EC': 7,
  '215ED': 7,
  '215EE': 7,
  '215EF': 7,
  '215F0': 7,
  '215F1': 7,
  '215F2': 7,
  '215F3': 7,
  '215F4': 7,
  '215F5': 7,
  '215F6': 7,
  '215F7': 8,
  '215F8': 8,
  '215F9': 8,
  '215FA': 8,
  '215FB': 8,
  '215FC': 8,
  '215FD': 8,
  '215FE': 8,
  '215FF': 8,
  '21600': 8,
  '21601': 8,
  '21602': 8,
  '21603': 8,
  '21604': 8,
  '21605': 8,
  '21606': 8,
  '21607': 8,
  '21608': 8,
  '21609': 8,
  '2160A': 8,
  '2160B': 8,
  '2160C': 8,
  '2160D': 9,
  '2160E': 9,
  '2160F': 9,
  '21610': 9,
  '21611': 9,
  '21612': 9,
  '21613': 9,
  '21614': 9,
  '21615': 9,
  '21616': 9,
  '21617': 9,
  '21618': 9,
  '21619': 9,
  '2161A': 9,
  '2161B': 9,
  '2161C': 9,
  '2161D': 10,
  '2161E': 10,
  '2161F': 10,
  '21620': 10,
  '21621': 10,
  '21622': 10,
  '21623': 10,
  '21624': 10,
  '21625': 10,
  '21626': 10,
  '21627': 10,
  '21628': 10,
  '21629': 10,
  '2162A': 10,
  '2162B': 10,
  '2162C': 10,
  '2162D': 10,
  '2162E': 10,
  '2162F': 10,
  '21630': 10,
  '21631': 10,
  '21632': 10,
  '21633': 10,
  '21634': 11,
  '21635': 11,
  '21636': 11,
  '21637': 11,
  '21638': 11,
  '21639': 11,
  '2163A': 11,
  '2163B': 11,
  '2163C': 11,
  '2163D': 11,
  '2163E': 11,
  '2163F': 11,
  '21640': 11,
  '21641': 11,
  '21642': 11,
  '21643': 11,
  '21644': 11,
  '21645': 11,
  '21646': 11,
  '21647': 12,
  '21648': 12,
  '21649': 12,
  '2164A': 12,
  '2164B': 12,
  '2164C': 12,
  '2164D': 12,
  '2164E': 12,
  '2164F': 12,
  '21650': 12,
  '21651': 12,
  '21652': 12,
  '21653': 12,
  '21654': 12,
  '21655': 12,
  '21656': 12,
  '21657': 12,
  '21658': 12,
  '21659': 12,
  '2165A': 12,
  '2165B': 13,
  '2165C': 13,
  '2165D': 13,
  '2165E': 13,
  '2165F': 13,
  '21660': 13,
  '21661': 13,
  '21662': 13,
  '21663': 13,
  '21664': 13,
  '21665': 13,
  '21666': 13,
  '21667': 13,
  '21668': 13,
  '21669': 13,
  '2166A': 13,
  '2166B': 13,
  '2166C': 14,
  '2166D': 14,
  '2166E': 14,
  '2166F': 14,
  '21670': 14,
  '21671': 14,
  '21672': 14,
  '21673': 14,
  '21674': 14,
  '21675': 14,
  '21676': 14,
  '21677': 15,
  '21678': 15,
  '21679': 15,
  '2167A': 15,
  '2167B': 15,
  '2167C': 15,
  '2167D': 15,
  '2167E': 15,
  '2167F': 15,
  '21680': 15,
  '21681': 15,
  '21682': 15,
  '21683': 15,
  '21684': 15,
  '21685': 15,
  '21686': 16,
  '21687': 16,
  '21688': 16,
  '21689': 16,
  '2168A': 16,
  '2168B': 16,
  '2168C': 16,
  '2168D': 16,
  '2168E': 16,
  '2168F': 17,
  '21690': 17,
  '21691': 17,
  '21692': 17,
  '21693': 17,
  '21694': 17,
  '21695': 17,
  '21696': 17,
  '21697': 18,
  '21698': 19,
  '21699': 19,
  '2169A': 19,
  '2169B': 20,
  '2169C': 21,
  '2169D': 21,
  '2169E': 22,
  '2169F': 22,
  '216A0': 23,
  '216A1': 23,
  '216A2': 23,
  '216A3': 24,
  '216A4': 24,
  '216A5': 27,
  '216A6': 4,
  '216A7': 5,
  '216A8': 5,
  '216A9': 5,
  '216AA': 5,
  '216AB': 5,
  '216AC': 5,
  '216AD': 5,
  '216AE': 6,
  '216AF': 6,
  '216B0': 6,
  '216B1': 6,
  '216B2': 6,
  '216B3': 6,
  '216B4': 6,
  '216B5': 6,
  '216B6': 6,
  '216B7': 6,
  '216B8': 6,
  '216B9': 6,
  '216BA': 6,
  '216BB': 6,
  '216BC': 7,
  '216BD': 7,
  '216BE': 7,
  '216BF': 7,
  '216C0': 7,
  '216C1': 7,
  '216C2': 7,
  '216C3': 7,
  '216C4': 7,
  '216C5': 7,
  '216C6': 7,
  '216C7': 7,
  '216C8': 7,
  '216C9': 7,
  '216CA': 7,
  '216CB': 7,
  '216CC': 7,
  '216CD': 7,
  '216CE': 7,
  '216CF': 7,
  '216D0': 7,
  '216D1': 7,
  '216D2': 7,
  '216D3': 7,
  '216D4': 7,
  '216D5': 7,
  '216D6': 7,
  '216D7': 7,
  '216D8': 7,
  '216D9': 8,
  '216DA': 8,
  '216DB': 8,
  '216DC': 8,
  '216DD': 8,
  '216DE': 8,
  '216DF': 8,
  '216E0': 8,
  '216E1': 8,
  '216E2': 8,
  '216E3': 8,
  '216E4': 8,
  '216E5': 8,
  '216E6': 8,
  '216E7': 8,
  '216E8': 8,
  '216E9': 8,
  '216EA': 8,
  '216EB': 8,
  '216EC': 8,
  '216ED': 8,
  '216EE': 8,
  '216EF': 8,
  '216F0': 8,
  '216F1': 8,
  '216F2': 8,
  '216F3': 8,
  '216F4': 8,
  '216F5': 8,
  '216F6': 8,
  '216F7': 8,
  '216F8': 8,
  '216F9': 8,
  '216FA': 8,
  '216FB': 8,
  '216FC': 8,
  '216FD': 8,
  '216FE': 8,
  '216FF': 8,
  '21700': 8,
  '21701': 8,
  '21702': 9,
  '21703': 9,
  '21704': 9,
  '21705': 9,
  '21706': 9,
  '21707': 9,
  '21708': 9,
  '21709': 9,
  '2170A': 9,
  '2170B': 9,
  '2170C': 9,
  '2170D': 9,
  '2170E': 9,
  '2170F': 9,
  '21710': 9,
  '21711': 9,
  '21712': 9,
  '21713': 9,
  '21714': 9,
  '21715': 9,
  '21716': 9,
  '21717': 9,
  '21718': 9,
  '21719': 9,
  '2171A': 9,
  '2171B': 9,
  '2171C': 9,
  '2171D': 9,
  '2171E': 9,
  '2171F': 9,
  '21720': 9,
  '21721': 9,
  '21722': 9,
  '21723': 9,
  '21724': 9,
  '21725': 9,
  '21726': 9,
  '21727': 9,
  '21728': 9,
  '21729': 9,
  '2172A': 9,
  '2172B': 9,
  '2172C': 9,
  '2172D': 9,
  '2172E': 10,
  '2172F': 10,
  '21730': 10,
  '21731': 10,
  '21732': 10,
  '21733': 10,
  '21734': 10,
  '21735': 10,
  '21736': 10,
  '21737': 10,
  '21738': 10,
  '21739': 10,
  '2173A': 10,
  '2173B': 10,
  '2173C': 10,
  '2173D': 10,
  '2173E': 10,
  '2173F': 10,
  '21740': 10,
  '21741': 10,
  '21742': 10,
  '21743': 10,
  '21744': 10,
  '21745': 10,
  '21746': 10,
  '21747': 10,
  '21748': 10,
  '21749': 10,
  '2174A': 10,
  '2174B': 10,
  '2174C': 10,
  '2174D': 10,
  '2174E': 10,
  '2174F': 10,
  '21750': 10,
  '21751': 10,
  '21752': 10,
  '21753': 10,
  '21754': 10,
  '21755': 10,
  '21756': 10,
  '21757': 10,
  '21758': 10,
  '21759': 10,
  '2175A': 10,
  '2175B': 10,
  '2175C': 10,
  '2175D': 10,
  '2175E': 11,
  '2175F': 11,
  '21760': 11,
  '21761': 11,
  '21762': 11,
  '21763': 11,
  '21764': 11,
  '21765': 11,
  '21766': 11,
  '21767': 11,
  '21768': 11,
  '21769': 11,
  '2176A': 11,
  '2176B': 11,
  '2176C': 11,
  '2176D': 11,
  '2176E': 11,
  '2176F': 11,
  '21770': 11,
  '21771': 11,
  '21772': 11,
  '21773': 11,
  '21774': 11,
  '21775': 11,
  '21776': 11,
  '21777': 11,
  '21778': 11,
  '21779': 11,
  '2177A': 11,
  '2177B': 11,
  '2177C': 11,
  '2177D': 11,
  '2177E': 11,
  '2177F': 11,
  '21780': 11,
  '21781': 11,
  '21782': 11,
  '21783': 11,
  '21784': 11,
  '21785': 11,
  '21786': 11,
  '21787': 11,
  '21788': 11,
  '21789': 11,
  '2178A': 11,
  '2178B': 11,
  '2178C': 11,
  '2178D': 11,
  '2178E': 11,
  '2178F': 11,
  '21790': 11,
  '21791': 11,
  '21792': 11,
  '21793': 11,
  '21794': 11,
  '21795': 11,
  '21796': 11,
  '21797': 11,
  '21798': 11,
  '21799': 11,
  '2179A': 11,
  '2179B': 11,
  '2179C': 12,
  '2179D': 12,
  '2179E': 12,
  '2179F': 12,
  '217A0': 12,
  '217A1': 12,
  '217A2': 12,
  '217A3': 12,
  '217A4': 12,
  '217A5': 12,
  '217A6': 12,
  '217A7': 12,
  '217A8': 12,
  '217A9': 12,
  '217AA': 12,
  '217AB': 12,
  '217AC': 12,
  '217AD': 12,
  '217AE': 12,
  '217AF': 12,
  '217B0': 12,
  '217B1': 12,
  '217B2': 12,
  '217B3': 12,
  '217B4': 12,
  '217B5': 12,
  '217B6': 12,
  '217B7': 12,
  '217B8': 12,
  '217B9': 12,
  '217BA': 12,
  '217BB': 12,
  '217BC': 12,
  '217BD': 12,
  '217BE': 12,
  '217BF': 12,
  '217C0': 12,
  '217C1': 12,
  '217C2': 12,
  '217C3': 12,
  '217C4': 12,
  '217C5': 12,
  '217C6': 12,
  '217C7': 12,
  '217C8': 12,
  '217C9': 12,
  '217CA': 12,
  '217CB': 12,
  '217CC': 12,
  '217CD': 12,
  '217CE': 12,
  '217CF': 12,
  '217D0': 12,
  '217D1': 12,
  '217D2': 12,
  '217D3': 12,
  '217D4': 12,
  '217D5': 12,
  '217D6': 12,
  '217D7': 12,
  '217D8': 12,
  '217D9': 12,
  '217DA': 12,
  '217DB': 12,
  '217DC': 12,
  '217DD': 12,
  '217DE': 12,
  '217DF': 12,
  '217E0': 12,
  '217E1': 12,
  '217E2': 12,
  '217E3': 12,
  '217E4': 12,
  '217E5': 12,
  '217E6': 12,
  '217E7': 12,
  '217E8': 13,
  '217E9': 13,
  '217EA': 13,
  '217EB': 13,
  '217EC': 13,
  '217ED': 13,
  '217EE': 13,
  '217EF': 13,
  '217F0': 13,
  '217F1': 13,
  '217F2': 13,
  '217F3': 13,
  '217F4': 13,
  '217F5': 13,
  '217F6': 13,
  '217F7': 13,
  '217F8': 13,
  '217F9': 13,
  '217FA': 13,
  '217FB': 13,
  '217FC': 13,
  '217FD': 13,
  '217FE': 13,
  '217FF': 13,
  '21800': 13,
  '21801': 13,
  '21802': 13,
  '21803': 13,
  '21804': 13,
  '21805': 13,
  '21806': 13,
  '21807': 13,
  '21808': 13,
  '21809': 13,
  '2180A': 13,
  '2180B': 13,
  '2180C': 13,
  '2180D': 13,
  '2180E': 13,
  '2180F': 13,
  '21810': 13,
  '21811': 13,
  '21812': 13,
  '21813': 13,
  '21814': 13,
  '21815': 13,
  '21816': 15,
  '21817': 14,
  '21818': 15,
  '21819': 14,
  '2181A': 14,
  '2181B': 14,
  '2181C': 14,
  '2181D': 14,
  '2181E': 14,
  '2181F': 14,
  '21820': 14,
  '21821': 14,
  '21822': 14,
  '21823': 12,
  '21824': 14,
  '21825': 14,
  '21826': 14,
  '21827': 14,
  '21828': 14,
  '21829': 14,
  '2182A': 14,
  '2182B': 14,
  '2182C': 14,
  '2182D': 14,
  '2182E': 14,
  '2182F': 14,
  '21830': 14,
  '21831': 14,
  '21832': 15,
  '21833': 14,
  '21834': 14,
  '21835': 14,
  '21836': 14,
  '21837': 14,
  '21838': 14,
  '21839': 14,
  '2183A': 14,
  '2183B': 14,
  '2183C': 14,
  '2183D': 14,
  '2183E': 14,
  '2183F': 14,
  '21840': 14,
  '21841': 14,
  '21842': 14,
  '21843': 14,
  '21844': 14,
  '21845': 14,
  '21846': 14,
  '21847': 14,
  '21848': 14,
  '21849': 14,
  '2184A': 14,
  '2184B': 14,
  '2184C': 14,
  '2184D': 14,
  '2184E': 14,
  '2184F': 15,
  '21850': 15,
  '21851': 15,
  '21852': 15,
  '21853': 15,
  '21854': 15,
  '21855': 15,
  '21856': 15,
  '21857': 15,
  '21858': 15,
  '21859': 15,
  '2185A': 15,
  '2185B': 15,
  '2185C': 15,
  '2185D': 15,
  '2185E': 15,
  '2185F': 15,
  '21860': 15,
  '21861': 15,
  '21862': 15,
  '21863': 15,
  '21864': 15,
  '21865': 15,
  '21866': 15,
  '21867': 15,
  '21868': 15,
  '21869': 15,
  '2186A': 15,
  '2186B': 15,
  '2186C': 15,
  '2186D': 15,
  '2186E': 15,
  '2186F': 15,
  '21870': 15,
  '21871': 15,
  '21872': 15,
  '21873': 15,
  '21874': 15,
  '21875': 15,
  '21876': 15,
  '21877': 15,
  '21878': 15,
  '21879': 15,
  '2187A': 15,
  '2187B': 15,
  '2187C': 15,
  '2187D': 15,
  '2187E': 15,
  '2187F': 15,
  '21880': 15,
  '21881': 15,
  '21882': 15,
  '21883': 15,
  '21884': 15,
  '21885': 15,
  '21886': 15,
  '21887': 15,
  '21888': 15,
  '21889': 15,
  '2188A': 15,
  '2188B': 15,
  '2188C': 15,
  '2188D': 15,
  '2188E': 16,
  '2188F': 15,
  '21890': 15,
  '21891': 16,
  '21892': 16,
  '21893': 16,
  '21894': 16,
  '21895': 16,
  '21896': 16,
  '21897': 16,
  '21898': 16,
  '21899': 16,
  '2189A': 16,
  '2189B': 16,
  '2189C': 16,
  '2189D': 16,
  '2189E': 16,
  '2189F': 16,
  '218A0': 16,
  '218A1': 16,
  '218A2': 16,
  '218A3': 16,
  '218A4': 16,
  '218A5': 16,
  '218A6': 16,
  '218A7': 16,
  '218A8': 16,
  '218A9': 16,
  '218AA': 16,
  '218AB': 16,
  '218AC': 16,
  '218AD': 16,
  '218AE': 16,
  '218AF': 16,
  '218B0': 16,
  '218B1': 16,
  '218B2': 16,
  '218B3': 16,
  '218B4': 16,
  '218B5': 16,
  '218B6': 16,
  '218B7': 16,
  '218B8': 16,
  '218B9': 16,
  '218BA': 16,
  '218BB': 16,
  '218BC': 16,
  '218BD': 16,
  '218BE': 16,
  '218BF': 16,
  '218C0': 16,
  '218C1': 16,
  '218C2': 16,
  '218C3': 16,
  '218C4': 16,
  '218C5': 17,
  '218C6': 17,
  '218C7': 17,
  '218C8': 17,
  '218C9': 17,
  '218CA': 17,
  '218CB': 17,
  '218CC': 17,
  '218CD': 17,
  '218CE': 17,
  '218CF': 17,
  '218D0': 17,
  '218D1': 17,
  '218D2': 17,
  '218D3': 17,
  '218D4': 17,
  '218D5': 17,
  '218D6': 17,
  '218D7': 17,
  '218D8': 17,
  '218D9': 17,
  '218DA': 17,
  '218DB': 17,
  '218DC': 17,
  '218DD': 17,
  '218DE': 17,
  '218DF': 17,
  '218E0': 17,
  '218E1': 17,
  '218E2': 17,
  '218E3': 17,
  '218E4': 17,
  '218E5': 17,
  '218E6': 17,
  '218E7': 17,
  '218E8': 17,
  '218E9': 17,
  '218EA': 18,
  '218EB': 18,
  '218EC': 18,
  '218ED': 18,
  '218EE': 18,
  '218EF': 18,
  '218F0': 18,
  '218F1': 18,
  '218F2': 18,
  '218F3': 18,
  '218F4': 18,
  '218F5': 18,
  '218F6': 18,
  '218F7': 18,
  '218F8': 18,
  '218F9': 18,
  '218FA': 18,
  '218FB': 18,
  '218FC': 18,
  '218FD': 19,
  '218FE': 19,
  '218FF': 19,
  '21900': 19,
  '21901': 19,
  '21902': 19,
  '21903': 19,
  '21904': 19,
  '21905': 19,
  '21906': 19,
  '21907': 19,
  '21908': 19,
  '21909': 19,
  '2190A': 19,
  '2190B': 19,
  '2190C': 19,
  '2190D': 19,
  '2190E': 20,
  '2190F': 20,
  '21910': 20,
  '21911': 20,
  '21912': 20,
  '21913': 20,
  '21914': 20,
  '21915': 20,
  '21916': 20,
  '21917': 20,
  '21918': 21,
  '21919': 21,
  '2191A': 21,
  '2191B': 21,
  '2191C': 21,
  '2191D': 21,
  '2191E': 19,
  '2191F': 21,
  '21920': 21,
  '21921': 22,
  '21922': 22,
  '21923': 22,
  '21924': 22,
  '21925': 22,
  '21926': 22,
  '21927': 22,
  '21928': 22,
  '21929': 22,
  '2192A': 22,
  '2192B': 23,
  '2192C': 23,
  '2192D': 23,
  '2192E': 24,
  '2192F': 24,
  '21930': 24,
  '21931': 24,
  '21932': 24,
  '21933': 24,
  '21934': 25,
  '21935': 25,
  '21936': 25,
  '21937': 27,
  '21938': 27,
  '21939': 27,
  '2193A': 27,
  '2193B': 33,
  '2193C': 3,
  '2193D': 4,
  '2193E': 5,
  '2193F': 5,
  '21940': 5,
  '21941': 5,
  '21942': 5,
  '21943': 6,
  '21944': 6,
  '21945': 6,
  '21946': 7,
  '21947': 7,
  '21948': 7,
  '21949': 7,
  '2194A': 7,
  '2194B': 7,
  '2194C': 7,
  '2194D': 7,
  '2194E': 8,
  '2194F': 8,
  '21950': 8,
  '21951': 8,
  '21952': 8,
  '21953': 8,
  '21954': 8,
  '21955': 8,
  '21956': 8,
  '21957': 8,
  '21958': 8,
  '21959': 8,
  '2195A': 8,
  '2195B': 9,
  '2195C': 9,
  '2195D': 9,
  '2195E': 9,
  '2195F': 9,
  '21960': 9,
  '21961': 9,
  '21962': 9,
  '21963': 9,
  '21964': 9,
  '21965': 9,
  '21966': 9,
  '21967': 9,
  '21968': 10,
  '21969': 10,
  '2196A': 10,
  '2196B': 10,
  '2196C': 10,
  '2196D': 10,
  '2196E': 11,
  '2196F': 11,
  '21970': 11,
  '21971': 11,
  '21972': 11,
  '21973': 11,
  '21974': 11,
  '21975': 11,
  '21976': 12,
  '21977': 12,
  '21978': 12,
  '21979': 12,
  '2197A': 12,
  '2197B': 12,
  '2197C': 12,
  '2197D': 12,
  '2197E': 12,
  '2197F': 12,
  '21980': 12,
  '21981': 12,
  '21982': 12,
  '21983': 13,
  '21984': 13,
  '21985': 13,
  '21986': 13,
  '21987': 13,
  '21988': 13,
  '21989': 13,
  '2198A': 13,
  '2198B': 13,
  '2198C': 13,
  '2198D': 13,
  '2198E': 13,
  '2198F': 13,
  '21990': 13,
  '21991': 14,
  '21992': 14,
  '21993': 14,
  '21994': 14,
  '21995': 14,
  '21996': 14,
  '21997': 15,
  '21998': 15,
  '21999': 15,
  '2199A': 15,
  '2199B': 15,
  '2199C': 15,
  '2199D': 15,
  '2199E': 16,
  '2199F': 16,
  '219A0': 16,
  '219A1': 16,
  '219A2': 16,
  '219A3': 16,
  '219A4': 16,
  '219A5': 16,
  '219A6': 16,
  '219A7': 16,
  '219A8': 16,
  '219A9': 17,
  '219AA': 18,
  '219AB': 18,
  '219AC': 18,
  '219AD': 19,
  '219AE': 19,
  '219AF': 19,
  '219B0': 19,
  '219B1': 20,
  '219B2': 20,
  '219B3': 20,
  '219B4': 20,
  '219B5': 20,
  '219B6': 20,
  '219B7': 22,
  '219B8': 27,
  '219B9': 4,
  '219BA': 5,
  '219BB': 5,
  '219BC': 5,
  '219BD': 6,
  '219BE': 6,
  '219BF': 6,
  '219C0': 6,
  '219C1': 6,
  '219C2': 6,
  '219C3': 6,
  '219C4': 6,
  '219C5': 6,
  '219C6': 6,
  '219C7': 6,
  '219C8': 6,
  '219C9': 6,
  '219CA': 6,
  '219CB': 7,
  '219CC': 7,
  '219CD': 7,
  '219CE': 7,
  '219CF': 7,
  '219D0': 7,
  '219D1': 7,
  '219D2': 7,
  '219D3': 7,
  '219D4': 7,
  '219D5': 7,
  '219D6': 8,
  '219D7': 8,
  '219D8': 8,
  '219D9': 8,
  '219DA': 8,
  '219DB': 8,
  '219DC': 8,
  '219DD': 8,
  '219DE': 8,
  '219DF': 8,
  '219E0': 8,
  '219E1': 8,
  '219E2': 8,
  '219E3': 8,
  '219E4': 8,
  '219E5': 8,
  '219E6': 8,
  '219E7': 8,
  '219E8': 8,
  '219E9': 9,
  '219EA': 9,
  '219EB': 9,
  '219EC': 9,
  '219ED': 9,
  '219EE': 9,
  '219EF': 9,
  '219F0': 9,
  '219F1': 9,
  '219F2': 9,
  '219F3': 9,
  '219F4': 9,
  '219F5': 9,
  '219F6': 9,
  '219F7': 9,
  '219F8': 9,
  '219F9': 9,
  '219FA': 9,
  '219FB': 9,
  '219FC': 9,
  '219FD': 9,
  '219FE': 9,
  '219FF': 9,
  '21A00': 10,
  '21A01': 10,
  '21A02': 10,
  '21A03': 10,
  '21A04': 10,
  '21A05': 10,
  '21A06': 10,
  '21A07': 10,
  '21A08': 10,
  '21A09': 10,
  '21A0A': 10,
  '21A0B': 10,
  '21A0C': 10,
  '21A0D': 10,
  '21A0E': 10,
  '21A0F': 10,
  '21A10': 10,
  '21A11': 10,
  '21A12': 10,
  '21A13': 10,
  '21A14': 10,
  '21A15': 10,
  '21A16': 10,
  '21A17': 10,
  '21A18': 10,
  '21A19': 10,
  '21A1A': 10,
  '21A1B': 10,
  '21A1C': 10,
  '21A1D': 10,
  '21A1E': 10,
  '21A1F': 10,
  '21A20': 10,
  '21A21': 10,
  '21A22': 11,
  '21A23': 11,
  '21A24': 11,
  '21A25': 11,
  '21A26': 11,
  '21A27': 11,
  '21A28': 11,
  '21A29': 11,
  '21A2A': 11,
  '21A2B': 11,
  '21A2C': 11,
  '21A2D': 11,
  '21A2E': 11,
  '21A2F': 11,
  '21A30': 11,
  '21A31': 11,
  '21A32': 11,
  '21A33': 11,
  '21A34': 11,
  '21A35': 11,
  '21A36': 11,
  '21A37': 11,
  '21A38': 11,
  '21A39': 11,
  '21A3A': 11,
  '21A3B': 11,
  '21A3C': 11,
  '21A3D': 12,
  '21A3E': 12,
  '21A3F': 12,
  '21A40': 12,
  '21A41': 12,
  '21A42': 12,
  '21A43': 12,
  '21A44': 12,
  '21A45': 12,
  '21A46': 12,
  '21A47': 12,
  '21A48': 12,
  '21A49': 12,
  '21A4A': 12,
  '21A4B': 12,
  '21A4C': 12,
  '21A4D': 12,
  '21A4E': 12,
  '21A4F': 12,
  '21A50': 12,
  '21A51': 12,
  '21A52': 12,
  '21A53': 12,
  '21A54': 12,
  '21A55': 12,
  '21A56': 12,
  '21A57': 12,
  '21A58': 12,
  '21A59': 12,
  '21A5A': 12,
  '21A5B': 12,
  '21A5C': 12,
  '21A5D': 12,
  '21A5E': 12,
  '21A5F': 13,
  '21A60': 13,
  '21A61': 13,
  '21A62': 13,
  '21A63': 13,
  '21A64': 13,
  '21A65': 13,
  '21A66': 13,
  '21A67': 13,
  '21A68': 13,
  '21A69': 13,
  '21A6A': 13,
  '21A6B': 13,
  '21A6C': 13,
  '21A6D': 13,
  '21A6E': 13,
  '21A6F': 13,
  '21A70': 13,
  '21A71': 13,
  '21A72': 13,
  '21A73': 13,
  '21A74': 13,
  '21A75': 13,
  '21A76': 13,
  '21A77': 13,
  '21A78': 13,
  '21A79': 13,
  '21A7A': 13,
  '21A7B': 14,
  '21A7C': 14,
  '21A7D': 14,
  '21A7E': 14,
  '21A7F': 14,
  '21A80': 14,
  '21A81': 14,
  '21A82': 14,
  '21A83': 14,
  '21A84': 14,
  '21A85': 14,
  '21A86': 14,
  '21A87': 14,
  '21A88': 14,
  '21A89': 14,
  '21A8A': 14,
  '21A8B': 14,
  '21A8C': 14,
  '21A8D': 14,
  '21A8E': 14,
  '21A8F': 14,
  '21A90': 14,
  '21A91': 14,
  '21A92': 14,
  '21A93': 14,
  '21A94': 14,
  '21A95': 14,
  '21A96': 14,
  '21A97': 14,
  '21A98': 14,
  '21A99': 14,
  '21A9A': 14,
  '21A9B': 14,
  '21A9C': 14,
  '21A9D': 14,
  '21A9E': 14,
  '21A9F': 15,
  '21AA0': 15,
  '21AA1': 15,
  '21AA2': 15,
  '21AA3': 15,
  '21AA4': 15,
  '21AA5': 15,
  '21AA6': 15,
  '21AA7': 15,
  '21AA8': 15,
  '21AA9': 15,
  '21AAA': 15,
  '21AAB': 15,
  '21AAC': 15,
  '21AAD': 15,
  '21AAE': 15,
  '21AAF': 15,
  '21AB0': 15,
  '21AB1': 15,
  '21AB2': 15,
  '21AB3': 15,
  '21AB4': 15,
  '21AB5': 15,
  '21AB6': 15,
  '21AB7': 15,
  '21AB8': 15,
  '21AB9': 15,
  '21ABA': 15,
  '21ABB': 15,
  '21ABC': 15,
  '21ABD': 15,
  '21ABE': 16,
  '21ABF': 16,
  '21AC0': 16,
  '21AC1': 16,
  '21AC2': 16,
  '21AC3': 16,
  '21AC4': 16,
  '21AC5': 16,
  '21AC6': 16,
  '21AC7': 16,
  '21AC8': 16,
  '21AC9': 16,
  '21ACA': 16,
  '21ACB': 16,
  '21ACC': 16,
  '21ACD': 16,
  '21ACE': 16,
  '21ACF': 16,
  '21AD0': 16,
  '21AD1': 17,
  '21AD2': 17,
  '21AD3': 17,
  '21AD4': 17,
  '21AD5': 17,
  '21AD6': 17,
  '21AD7': 17,
  '21AD8': 17,
  '21AD9': 17,
  '21ADA': 17,
  '21ADB': 17,
  '21ADC': 17,
  '21ADD': 17,
  '21ADE': 18,
  '21ADF': 18,
  '21AE0': 18,
  '21AE1': 18,
  '21AE2': 18,
  '21AE3': 18,
  '21AE4': 18,
  '21AE5': 18,
  '21AE6': 18,
  '21AE7': 18,
  '21AE8': 18,
  '21AE9': 18,
  '21AEA': 18,
  '21AEB': 18,
  '21AEC': 19,
  '21AED': 19,
  '21AEE': 19,
  '21AEF': 19,
  '21AF0': 19,
  '21AF1': 19,
  '21AF2': 19,
  '21AF3': 19,
  '21AF4': 19,
  '21AF5': 19,
  '21AF6': 19,
  '21AF7': 20,
  '21AF8': 20,
  '21AF9': 20,
  '21AFA': 20,
  '21AFB': 20,
  '21AFC': 21,
  '21AFD': 21,
  '21AFE': 21,
  '21AFF': 21,
  '21B00': 21,
  '21B01': 21,
  '21B02': 21,
  '21B03': 21,
  '21B04': 22,
  '21B05': 22,
  '21B06': 22,
  '21B07': 22,
  '21B08': 22,
  '21B09': 22,
  '21B0A': 22,
  '21B0B': 22,
  '21B0C': 23,
  '21B0D': 24,
  '21B0E': 27,
  '21B0F': 24,
  '21B10': 24,
  '21B11': 25,
  '21B12': 28,
  '21B13': 25,
  '21B14': 25,
  '21B15': 25,
  '21B16': 26,
  '21B17': 26,
  '21B18': 26,
  '21B19': 27,
  '21B1A': 27,
  '21B1B': 29,
  '21B1C': 36,
  '21B1D': 4,
  '21B1E': 6,
  '21B1F': 8,
  '21B20': 8,
  '21B21': 8,
  '21B22': 8,
  '21B23': 8,
  '21B24': 8,
  '21B25': 8,
  '21B26': 8,
  '21B27': 9,
  '21B28': 9,
  '21B29': 9,
  '21B2A': 9,
  '21B2B': 9,
  '21B2C': 9,
  '21B2D': 10,
  '21B2E': 11,
  '21B2F': 11,
  '21B30': 11,
  '21B31': 11,
  '21B32': 11,
  '21B33': 12,
  '21B34': 12,
  '21B35': 12,
  '21B36': 12,
  '21B37': 12,
  '21B38': 12,
  '21B39': 13,
  '21B3A': 13,
  '21B3B': 13,
  '21B3C': 13,
  '21B3D': 14,
  '21B3E': 14,
  '21B3F': 14,
  '21B40': 14,
  '21B41': 14,
  '21B42': 14,
  '21B43': 15,
  '21B44': 15,
  '21B45': 15,
  '21B46': 15,
  '21B47': 15,
  '21B48': 15,
  '21B49': 15,
  '21B4A': 16,
  '21B4B': 16,
  '21B4C': 16,
  '21B4D': 17,
  '21B4E': 17,
  '21B4F': 17,
  '21B50': 18,
  '21B51': 19,
  '21B52': 20,
  '21B53': 21,
  '21B54': 3,
  '21B55': 4,
  '21B56': 4,
  '21B57': 5,
  '21B58': 5,
  '21B59': 6,
  '21B5A': 6,
  '21B5B': 6,
  '21B5C': 6,
  '21B5D': 7,
  '21B5E': 7,
  '21B5F': 7,
  '21B60': 7,
  '21B61': 7,
  '21B62': 7,
  '21B63': 7,
  '21B64': 7,
  '21B65': 8,
  '21B66': 8,
  '21B67': 8,
  '21B68': 8,
  '21B69': 8,
  '21B6A': 9,
  '21B6B': 9,
  '21B6C': 9,
  '21B6D': 9,
  '21B6E': 9,
  '21B6F': 9,
  '21B70': 9,
  '21B71': 9,
  '21B72': 9,
  '21B73': 9,
  '21B74': 10,
  '21B75': 10,
  '21B76': 10,
  '21B77': 10,
  '21B78': 10,
  '21B79': 10,
  '21B7A': 10,
  '21B7B': 10,
  '21B7C': 10,
  '21B7D': 11,
  '21B7E': 11,
  '21B7F': 11,
  '21B80': 11,
  '21B81': 11,
  '21B82': 11,
  '21B83': 11,
  '21B84': 11,
  '21B85': 11,
  '21B86': 11,
  '21B87': 11,
  '21B88': 11,
  '21B89': 11,
  '21B8A': 11,
  '21B8B': 11,
  '21B8C': 11,
  '21B8D': 11,
  '21B8E': 12,
  '21B8F': 12,
  '21B90': 12,
  '21B91': 12,
  '21B92': 12,
  '21B93': 12,
  '21B94': 12,
  '21B95': 12,
  '21B96': 12,
  '21B97': 12,
  '21B98': 13,
  '21B99': 13,
  '21B9A': 13,
  '21B9B': 13,
  '21B9C': 13,
  '21B9D': 13,
  '21B9E': 14,
  '21B9F': 14,
  '21BA0': 14,
  '21BA1': 14,
  '21BA2': 14,
  '21BA3': 14,
  '21BA4': 14,
  '21BA5': 14,
  '21BA6': 15,
  '21BA7': 15,
  '21BA8': 15,
  '21BA9': 15,
  '21BAA': 15,
  '21BAB': 15,
  '21BAC': 15,
  '21BAD': 15,
  '21BAE': 15,
  '21BAF': 15,
  '21BB0': 16,
  '21BB1': 16,
  '21BB2': 16,
  '21BB3': 16,
  '21BB4': 17,
  '21BB5': 17,
  '21BB6': 17,
  '21BB7': 18,
  '21BB8': 18,
  '21BB9': 18,
  '21BBA': 18,
  '21BBB': 18,
  '21BBC': 19,
  '21BBD': 19,
  '21BBE': 19,
  '21BBF': 21,
  '21BC0': 32,
  '21BC1': 3,
  '21BC2': 3,
  '21BC3': 4,
  '21BC4': 5,
  '21BC5': 5,
  '21BC6': 5,
  '21BC7': 5,
  '21BC8': 5,
  '21BC9': 5,
  '21BCA': 5,
  '21BCB': 6,
  '21BCC': 6,
  '21BCD': 6,
  '21BCE': 6,
  '21BCF': 7,
  '21BD0': 7,
  '21BD1': 7,
  '21BD2': 7,
  '21BD3': 7,
  '21BD4': 7,
  '21BD5': 7,
  '21BD6': 7,
  '21BD7': 7,
  '21BD8': 7,
  '21BD9': 7,
  '21BDA': 8,
  '21BDB': 8,
  '21BDC': 8,
  '21BDD': 8,
  '21BDE': 8,
  '21BDF': 8,
  '21BE0': 8,
  '21BE1': 8,
  '21BE2': 9,
  '21BE3': 9,
  '21BE4': 9,
  '21BE5': 9,
  '21BE6': 9,
  '21BE7': 9,
  '21BE8': 10,
  '21BE9': 10,
  '21BEA': 10,
  '21BEB': 10,
  '21BEC': 10,
  '21BED': 10,
  '21BEE': 10,
  '21BEF': 10,
  '21BF0': 10,
  '21BF1': 10,
  '21BF2': 10,
  '21BF3': 11,
  '21BF4': 11,
  '21BF5': 11,
  '21BF6': 11,
  '21BF7': 11,
  '21BF8': 11,
  '21BF9': 12,
  '21BFA': 12,
  '21BFB': 12,
  '21BFC': 12,
  '21BFD': 12,
  '21BFE': 12,
  '21BFF': 12,
  '21C00': 12,
  '21C01': 12,
  '21C02': 12,
  '21C03': 13,
  '21C04': 13,
  '21C05': 13,
  '21C06': 13,
  '21C07': 13,
  '21C08': 13,
  '21C09': 13,
  '21C0A': 13,
  '21C0B': 14,
  '21C0C': 14,
  '21C0D': 14,
  '21C0E': 14,
  '21C0F': 14,
  '21C10': 15,
  '21C11': 15,
  '21C12': 15,
  '21C13': 15,
  '21C14': 15,
  '21C15': 15,
  '21C16': 16,
  '21C17': 16,
  '21C18': 16,
  '21C19': 16,
  '21C1A': 16,
  '21C1B': 17,
  '21C1C': 17,
  '21C1D': 21,
  '21C1E': 21,
  '21C1F': 22,
  '21C20': 22,
  '21C21': 25,
  '21C22': 25,
  '21C23': 3,
  '21C24': 5,
  '21C25': 5,
  '21C26': 5,
  '21C27': 5,
  '21C28': 5,
  '21C29': 6,
  '21C2A': 6,
  '21C2B': 6,
  '21C2C': 6,
  '21C2D': 6,
  '21C2E': 6,
  '21C2F': 6,
  '21C30': 6,
  '21C31': 6,
  '21C32': 6,
  '21C33': 6,
  '21C34': 5,
  '21C35': 7,
  '21C36': 7,
  '21C37': 7,
  '21C38': 7,
  '21C39': 7,
  '21C3A': 7,
  '21C3B': 7,
  '21C3C': 7,
  '21C3D': 7,
  '21C3E': 8,
  '21C3F': 8,
  '21C40': 8,
  '21C41': 8,
  '21C42': 8,
  '21C43': 8,
  '21C44': 8,
  '21C45': 8,
  '21C46': 8,
  '21C47': 8,
  '21C48': 8,
  '21C49': 8,
  '21C4A': 8,
  '21C4B': 8,
  '21C4C': 9,
  '21C4D': 9,
  '21C4E': 9,
  '21C4F': 9,
  '21C50': 9,
  '21C51': 9,
  '21C52': 9,
  '21C53': 9,
  '21C54': 9,
  '21C55': 9,
  '21C56': 9,
  '21C57': 9,
  '21C58': 9,
  '21C59': 9,
  '21C5A': 9,
  '21C5B': 9,
  '21C5C': 9,
  '21C5D': 9,
  '21C5E': 9,
  '21C5F': 9,
  '21C60': 9,
  '21C61': 9,
  '21C62': 10,
  '21C63': 10,
  '21C64': 10,
  '21C65': 10,
  '21C66': 10,
  '21C67': 10,
  '21C68': 10,
  '21C69': 10,
  '21C6A': 10,
  '21C6B': 10,
  '21C6C': 10,
  '21C6D': 10,
  '21C6E': 10,
  '21C6F': 10,
  '21C70': 10,
  '21C71': 10,
  '21C72': 10,
  '21C73': 10,
  '21C74': 10,
  '21C75': 10,
  '21C76': 10,
  '21C77': 10,
  '21C78': 10,
  '21C79': 10,
  '21C7A': 11,
  '21C7B': 11,
  '21C7C': 11,
  '21C7D': 11,
  '21C7E': 11,
  '21C7F': 11,
  '21C80': 11,
  '21C81': 11,
  '21C82': 11,
  '21C83': 11,
  '21C84': 11,
  '21C85': 11,
  '21C86': 11,
  '21C87': 11,
  '21C88': 11,
  '21C89': 11,
  '21C8A': 11,
  '21C8B': 11,
  '21C8C': 11,
  '21C8D': 11,
  '21C8E': 11,
  '21C8F': 11,
  '21C90': 11,
  '21C91': 11,
  '21C92': 11,
  '21C93': 11,
  '21C94': 12,
  '21C95': 12,
  '21C96': 12,
  '21C97': 12,
  '21C98': 12,
  '21C99': 12,
  '21C9A': 12,
  '21C9B': 12,
  '21C9C': 12,
  '21C9D': 12,
  '21C9E': 12,
  '21C9F': 12,
  '21CA0': 12,
  '21CA1': 12,
  '21CA2': 12,
  '21CA3': 12,
  '21CA4': 12,
  '21CA5': 12,
  '21CA6': 12,
  '21CA7': 12,
  '21CA8': 12,
  '21CA9': 12,
  '21CAA': 12,
  '21CAB': 12,
  '21CAC': 13,
  '21CAD': 13,
  '21CAE': 13,
  '21CAF': 13,
  '21CB0': 13,
  '21CB1': 13,
  '21CB2': 13,
  '21CB3': 13,
  '21CB4': 13,
  '21CB5': 13,
  '21CB6': 13,
  '21CB7': 13,
  '21CB8': 13,
  '21CB9': 13,
  '21CBA': 13,
  '21CBB': 13,
  '21CBC': 13,
  '21CBD': 13,
  '21CBE': 13,
  '21CBF': 13,
  '21CC0': 13,
  '21CC1': 13,
  '21CC2': 13,
  '21CC3': 13,
  '21CC4': 14,
  '21CC5': 14,
  '21CC6': 14,
  '21CC7': 14,
  '21CC8': 14,
  '21CC9': 14,
  '21CCA': 14,
  '21CCB': 14,
  '21CCC': 14,
  '21CCD': 14,
  '21CCE': 14,
  '21CCF': 14,
  '21CD0': 15,
  '21CD1': 15,
  '21CD2': 15,
  '21CD3': 15,
  '21CD4': 15,
  '21CD5': 15,
  '21CD6': 15,
  '21CD7': 15,
  '21CD8': 15,
  '21CD9': 15,
  '21CDA': 15,
  '21CDB': 15,
  '21CDC': 15,
  '21CDD': 15,
  '21CDE': 15,
  '21CDF': 16,
  '21CE0': 16,
  '21CE1': 16,
  '21CE2': 16,
  '21CE3': 16,
  '21CE4': 16,
  '21CE5': 16,
  '21CE6': 16,
  '21CE7': 16,
  '21CE8': 17,
  '21CE9': 17,
  '21CEA': 18,
  '21CEB': 18,
  '21CEC': 19,
  '21CED': 19,
  '21CEE': 19,
  '21CEF': 19,
  '21CF0': 19,
  '21CF1': 20,
  '21CF2': 20,
  '21CF3': 20,
  '21CF4': 20,
  '21CF5': 21,
  '21CF6': 21,
  '21CF7': 21,
  '21CF8': 22,
  '21CF9': 22,
  '21CFA': 22,
  '21CFB': 24,
  '21CFC': 25,
  '21CFD': 25,
  '21CFE': 3,
  '21CFF': 5,
  '21D00': 6,
  '21D01': 6,
  '21D02': 6,
  '21D03': 6,
  '21D04': 6,
  '21D05': 6,
  '21D06': 7,
  '21D07': 7,
  '21D08': 7,
  '21D09': 7,
  '21D0A': 7,
  '21D0B': 8,
  '21D0C': 8,
  '21D0D': 8,
  '21D0E': 9,
  '21D0F': 9,
  '21D10': 9,
  '21D11': 9,
  '21D12': 9,
  '21D13': 9,
  '21D14': 9,
  '21D15': 10,
  '21D16': 10,
  '21D17': 10,
  '21D18': 10,
  '21D19': 10,
  '21D1A': 10,
  '21D1B': 11,
  '21D1C': 11,
  '21D1D': 12,
  '21D1E': 12,
  '21D1F': 12,
  '21D20': 12,
  '21D21': 12,
  '21D22': 12,
  '21D23': 12,
  '21D24': 13,
  '21D25': 13,
  '21D26': 15,
  '21D27': 15,
  '21D28': 15,
  '21D29': 15,
  '21D2A': 18,
  '21D2B': 21,
  '21D2C': 22,
  '21D2D': 4,
  '21D2E': 4,
  '21D2F': 4,
  '21D30': 5,
  '21D31': 5,
  '21D32': 5,
  '21D33': 5,
  '21D34': 5,
  '21D35': 5,
  '21D36': 5,
  '21D37': 5,
  '21D38': 5,
  '21D39': 5,
  '21D3A': 5,
  '21D3B': 5,
  '21D3C': 5,
  '21D3D': 5,
  '21D3E': 6,
  '21D3F': 6,
  '21D40': 6,
  '21D41': 6,
  '21D42': 6,
  '21D43': 6,
  '21D44': 6,
  '21D45': 6,
  '21D46': 6,
  '21D47': 6,
  '21D48': 6,
  '21D49': 6,
  '21D4A': 6,
  '21D4B': 6,
  '21D4C': 6,
  '21D4D': 6,
  '21D4E': 6,
  '21D4F': 6,
  '21D50': 6,
  '21D51': 6,
  '21D52': 6,
  '21D53': 7,
  '21D54': 7,
  '21D55': 7,
  '21D56': 7,
  '21D57': 7,
  '21D58': 7,
  '21D59': 7,
  '21D5A': 7,
  '21D5B': 7,
  '21D5C': 7,
  '21D5D': 7,
  '21D5E': 7,
  '21D5F': 7,
  '21D60': 7,
  '21D61': 7,
  '21D62': 7,
  '21D63': 7,
  '21D64': 7,
  '21D65': 7,
  '21D66': 7,
  '21D67': 7,
  '21D68': 7,
  '21D69': 7,
  '21D6A': 7,
  '21D6B': 7,
  '21D6C': 7,
  '21D6D': 7,
  '21D6E': 7,
  '21D6F': 7,
  '21D70': 7,
  '21D71': 7,
  '21D72': 7,
  '21D73': 7,
  '21D74': 7,
  '21D75': 7,
  '21D76': 7,
  '21D77': 7,
  '21D78': 7,
  '21D79': 7,
  '21D7A': 7,
  '21D7B': 7,
  '21D7C': 7,
  '21D7D': 7,
  '21D7E': 7,
  '21D7F': 7,
  '21D80': 7,
  '21D81': 7,
  '21D82': 7,
  '21D83': 8,
  '21D84': 8,
  '21D85': 8,
  '21D86': 8,
  '21D87': 8,
  '21D88': 8,
  '21D89': 8,
  '21D8A': 8,
  '21D8B': 8,
  '21D8C': 8,
  '21D8D': 8,
  '21D8E': 8,
  '21D8F': 8,
  '21D90': 8,
  '21D91': 8,
  '21D92': 8,
  '21D93': 8,
  '21D94': 8,
  '21D95': 8,
  '21D96': 8,
  '21D97': 8,
  '21D98': 8,
  '21D99': 8,
  '21D9A': 8,
  '21D9B': 8,
  '21D9C': 8,
  '21D9D': 8,
  '21D9E': 8,
  '21D9F': 8,
  '21DA0': 8,
  '21DA1': 8,
  '21DA2': 8,
  '21DA3': 8,
  '21DA4': 8,
  '21DA5': 8,
  '21DA6': 8,
  '21DA7': 8,
  '21DA8': 8,
  '21DA9': 8,
  '21DAA': 8,
  '21DAB': 9,
  '21DAC': 9,
  '21DAD': 9,
  '21DAE': 9,
  '21DAF': 9,
  '21DB0': 9,
  '21DB1': 9,
  '21DB2': 9,
  '21DB3': 9,
  '21DB4': 9,
  '21DB5': 9,
  '21DB6': 9,
  '21DB7': 9,
  '21DB8': 9,
  '21DB9': 9,
  '21DBA': 9,
  '21DBB': 9,
  '21DBC': 9,
  '21DBD': 9,
  '21DBE': 9,
  '21DBF': 9,
  '21DC0': 9,
  '21DC1': 9,
  '21DC2': 9,
  '21DC3': 9,
  '21DC4': 9,
  '21DC5': 9,
  '21DC6': 9,
  '21DC7': 9,
  '21DC8': 9,
  '21DC9': 9,
  '21DCA': 9,
  '21DCB': 9,
  '21DCC': 9,
  '21DCD': 9,
  '21DCE': 9,
  '21DCF': 9,
  '21DD0': 9,
  '21DD1': 9,
  '21DD2': 9,
  '21DD3': 9,
  '21DD4': 9,
  '21DD5': 10,
  '21DD6': 10,
  '21DD7': 10,
  '21DD8': 10,
  '21DD9': 10,
  '21DDA': 10,
  '21DDB': 10,
  '21DDC': 10,
  '21DDD': 10,
  '21DDE': 10,
  '21DDF': 10,
  '21DE0': 10,
  '21DE1': 10,
  '21DE2': 10,
  '21DE3': 10,
  '21DE4': 10,
  '21DE5': 10,
  '21DE6': 10,
  '21DE7': 10,
  '21DE8': 10,
  '21DE9': 10,
  '21DEA': 10,
  '21DEB': 10,
  '21DEC': 10,
  '21DED': 10,
  '21DEE': 10,
  '21DEF': 10,
  '21DF0': 10,
  '21DF1': 10,
  '21DF2': 10,
  '21DF3': 10,
  '21DF4': 10,
  '21DF5': 10,
  '21DF6': 10,
  '21DF7': 10,
  '21DF8': 10,
  '21DF9': 10,
  '21DFA': 10,
  '21DFB': 10,
  '21DFC': 10,
  '21DFD': 10,
  '21DFE': 10,
  '21DFF': 10,
  '21E00': 10,
  '21E01': 10,
  '21E02': 10,
  '21E03': 10,
  '21E04': 10,
  '21E05': 10,
  '21E06': 10,
  '21E07': 10,
  '21E08': 10,
  '21E09': 10,
  '21E0A': 10,
  '21E0B': 10,
  '21E0C': 10,
  '21E0D': 10,
  '21E0E': 10,
  '21E0F': 10,
  '21E10': 10,
  '21E11': 11,
  '21E12': 11,
  '21E13': 11,
  '21E14': 11,
  '21E15': 11,
  '21E16': 11,
  '21E17': 11,
  '21E18': 11,
  '21E19': 11,
  '21E1A': 11,
  '21E1B': 11,
  '21E1C': 11,
  '21E1D': 11,
  '21E1E': 11,
  '21E1F': 11,
  '21E20': 11,
  '21E21': 11,
  '21E22': 11,
  '21E23': 11,
  '21E24': 11,
  '21E25': 11,
  '21E26': 11,
  '21E27': 11,
  '21E28': 11,
  '21E29': 11,
  '21E2A': 11,
  '21E2B': 11,
  '21E2C': 11,
  '21E2D': 11,
  '21E2E': 11,
  '21E2F': 11,
  '21E30': 11,
  '21E31': 11,
  '21E32': 11,
  '21E33': 11,
  '21E34': 11,
  '21E35': 11,
  '21E36': 11,
  '21E37': 11,
  '21E38': 11,
  '21E39': 11,
  '21E3A': 11,
  '21E3B': 11,
  '21E3C': 11,
  '21E3D': 11,
  '21E3E': 11,
  '21E3F': 11,
  '21E40': 11,
  '21E41': 11,
  '21E42': 11,
  '21E43': 11,
  '21E44': 11,
  '21E45': 11,
  '21E46': 11,
  '21E47': 11,
  '21E48': 11,
  '21E49': 11,
  '21E4A': 11,
  '21E4B': 11,
  '21E4C': 11,
  '21E4D': 11,
  '21E4E': 11,
  '21E4F': 11,
  '21E50': 11,
  '21E51': 11,
  '21E52': 11,
  '21E53': 11,
  '21E54': 11,
  '21E55': 11,
  '21E56': 11,
  '21E57': 11,
  '21E58': 11,
  '21E59': 11,
  '21E5A': 11,
  '21E5B': 11,
  '21E5C': 11,
  '21E5D': 11,
  '21E5E': 11,
  '21E5F': 11,
  '21E60': 11,
  '21E61': 11,
  '21E62': 11,
  '21E63': 11,
  '21E64': 11,
  '21E65': 11,
  '21E66': 11,
  '21E67': 11,
  '21E68': 11,
  '21E69': 11,
  '21E6A': 12,
  '21E6B': 12,
  '21E6C': 12,
  '21E6D': 12,
  '21E6E': 12,
  '21E6F': 12,
  '21E70': 12,
  '21E71': 12,
  '21E72': 12,
  '21E73': 12,
  '21E74': 12,
  '21E75': 12,
  '21E76': 12,
  '21E77': 12,
  '21E78': 12,
  '21E79': 12,
  '21E7A': 12,
  '21E7B': 12,
  '21E7C': 12,
  '21E7D': 12,
  '21E7E': 12,
  '21E7F': 12,
  '21E80': 12,
  '21E81': 12,
  '21E82': 12,
  '21E83': 12,
  '21E84': 12,
  '21E85': 12,
  '21E86': 12,
  '21E87': 12,
  '21E88': 12,
  '21E89': 12,
  '21E8A': 12,
  '21E8B': 12,
  '21E8C': 12,
  '21E8D': 12,
  '21E8E': 12,
  '21E8F': 12,
  '21E90': 12,
  '21E91': 12,
  '21E92': 12,
  '21E93': 12,
  '21E94': 12,
  '21E95': 12,
  '21E96': 12,
  '21E97': 12,
  '21E98': 12,
  '21E99': 12,
  '21E9A': 12,
  '21E9B': 12,
  '21E9C': 12,
  '21E9D': 12,
  '21E9E': 12,
  '21E9F': 12,
  '21EA0': 12,
  '21EA1': 12,
  '21EA2': 12,
  '21EA3': 12,
  '21EA4': 12,
  '21EA5': 12,
  '21EA6': 12,
  '21EA7': 12,
  '21EA8': 12,
  '21EA9': 12,
  '21EAA': 13,
  '21EAB': 13,
  '21EAC': 13,
  '21EAD': 13,
  '21EAE': 13,
  '21EAF': 13,
  '21EB0': 13,
  '21EB1': 13,
  '21EB2': 13,
  '21EB3': 13,
  '21EB4': 13,
  '21EB5': 13,
  '21EB6': 13,
  '21EB7': 13,
  '21EB8': 13,
  '21EB9': 13,
  '21EBA': 13,
  '21EBB': 13,
  '21EBC': 13,
  '21EBD': 13,
  '21EBE': 13,
  '21EBF': 13,
  '21EC0': 13,
  '21EC1': 13,
  '21EC2': 13,
  '21EC3': 13,
  '21EC4': 13,
  '21EC5': 13,
  '21EC6': 13,
  '21EC7': 13,
  '21EC8': 13,
  '21EC9': 13,
  '21ECA': 13,
  '21ECB': 13,
  '21ECC': 13,
  '21ECD': 13,
  '21ECE': 13,
  '21ECF': 13,
  '21ED0': 13,
  '21ED1': 13,
  '21ED2': 13,
  '21ED3': 13,
  '21ED4': 13,
  '21ED5': 13,
  '21ED6': 13,
  '21ED7': 13,
  '21ED8': 14,
  '21ED9': 14,
  '21EDA': 14,
  '21EDB': 14,
  '21EDC': 14,
  '21EDD': 14,
  '21EDE': 14,
  '21EDF': 14,
  '21EE0': 14,
  '21EE1': 14,
  '21EE2': 14,
  '21EE3': 14,
  '21EE4': 14,
  '21EE5': 14,
  '21EE6': 14,
  '21EE7': 14,
  '21EE8': 14,
  '21EE9': 14,
  '21EEA': 14,
  '21EEB': 14,
  '21EEC': 14,
  '21EED': 14,
  '21EEE': 14,
  '21EEF': 14,
  '21EF0': 14,
  '21EF1': 14,
  '21EF2': 14,
  '21EF3': 14,
  '21EF4': 14,
  '21EF5': 14,
  '21EF6': 14,
  '21EF7': 14,
  '21EF8': 14,
  '21EF9': 14,
  '21EFA': 14,
  '21EFB': 14,
  '21EFC': 14,
  '21EFD': 14,
  '21EFE': 14,
  '21EFF': 14,
  '21F00': 14,
  '21F01': 14,
  '21F02': 14,
  '21F03': 14,
  '21F04': 14,
  '21F05': 14,
  '21F06': 14,
  '21F07': 14,
  '21F08': 14,
  '21F09': 14,
  '21F0A': 15,
  '21F0B': 15,
  '21F0C': 15,
  '21F0D': 15,
  '21F0E': 15,
  '21F0F': 15,
  '21F10': 15,
  '21F11': 15,
  '21F12': 15,
  '21F13': 15,
  '21F14': 15,
  '21F15': 15,
  '21F16': 15,
  '21F17': 15,
  '21F18': 15,
  '21F19': 15,
  '21F1A': 15,
  '21F1B': 15,
  '21F1C': 15,
  '21F1D': 15,
  '21F1E': 15,
  '21F1F': 15,
  '21F20': 15,
  '21F21': 15,
  '21F22': 15,
  '21F23': 15,
  '21F24': 15,
  '21F25': 15,
  '21F26': 15,
  '21F27': 15,
  '21F28': 15,
  '21F29': 15,
  '21F2A': 15,
  '21F2B': 15,
  '21F2C': 15,
  '21F2D': 15,
  '21F2E': 15,
  '21F2F': 15,
  '21F30': 15,
  '21F31': 15,
  '21F32': 15,
  '21F33': 15,
  '21F34': 15,
  '21F35': 15,
  '21F36': 15,
  '21F37': 15,
  '21F38': 15,
  '21F39': 15,
  '21F3A': 15,
  '21F3B': 15,
  '21F3C': 15,
  '21F3D': 16,
  '21F3E': 16,
  '21F3F': 16,
  '21F40': 16,
  '21F41': 16,
  '21F42': 16,
  '21F43': 16,
  '21F44': 16,
  '21F45': 16,
  '21F46': 16,
  '21F47': 16,
  '21F48': 16,
  '21F49': 17,
  '21F4A': 16,
  '21F4B': 16,
  '21F4C': 16,
  '21F4D': 16,
  '21F4E': 16,
  '21F4F': 16,
  '21F50': 16,
  '21F51': 16,
  '21F52': 16,
  '21F53': 16,
  '21F54': 16,
  '21F55': 16,
  '21F56': 16,
  '21F57': 16,
  '21F58': 16,
  '21F59': 16,
  '21F5A': 16,
  '21F5B': 16,
  '21F5C': 17,
  '21F5D': 17,
  '21F5E': 17,
  '21F5F': 17,
  '21F60': 17,
  '21F61': 17,
  '21F62': 17,
  '21F63': 17,
  '21F64': 17,
  '21F65': 17,
  '21F66': 17,
  '21F67': 17,
  '21F68': 17,
  '21F69': 17,
  '21F6A': 17,
  '21F6B': 17,
  '21F6C': 17,
  '21F6D': 17,
  '21F6E': 17,
  '21F6F': 17,
  '21F70': 17,
  '21F71': 17,
  '21F72': 17,
  '21F73': 17,
  '21F74': 17,
  '21F75': 17,
  '21F76': 17,
  '21F77': 17,
  '21F78': 17,
  '21F79': 17,
  '21F7A': 17,
  '21F7B': 17,
  '21F7C': 17,
  '21F7D': 17,
  '21F7E': 17,
  '21F7F': 17,
  '21F80': 17,
  '21F81': 17,
  '21F82': 18,
  '21F83': 18,
  '21F84': 18,
  '21F85': 18,
  '21F86': 18,
  '21F87': 18,
  '21F88': 18,
  '21F89': 18,
  '21F8A': 18,
  '21F8B': 18,
  '21F8C': 18,
  '21F8D': 18,
  '21F8E': 18,
  '21F8F': 18,
  '21F90': 18,
  '21F91': 18,
  '21F92': 18,
  '21F93': 18,
  '21F94': 18,
  '21F95': 18,
  '21F96': 18,
  '21F97': 18,
  '21F98': 19,
  '21F99': 19,
  '21F9A': 19,
  '21F9B': 19,
  '21F9C': 19,
  '21F9D': 19,
  '21F9E': 19,
  '21F9F': 19,
  '21FA0': 18,
  '21FA1': 19,
  '21FA2': 19,
  '21FA3': 19,
  '21FA4': 19,
  '21FA5': 19,
  '21FA6': 19,
  '21FA7': 19,
  '21FA8': 19,
  '21FA9': 19,
  '21FAA': 19,
  '21FAB': 19,
  '21FAC': 19,
  '21FAD': 19,
  '21FAE': 20,
  '21FAF': 20,
  '21FB0': 20,
  '21FB1': 20,
  '21FB2': 20,
  '21FB3': 20,
  '21FB4': 20,
  '21FB5': 20,
  '21FB6': 20,
  '21FB7': 20,
  '21FB8': 20,
  '21FB9': 20,
  '21FBA': 20,
  '21FBB': 17,
  '21FBC': 21,
  '21FBD': 21,
  '21FBE': 21,
  '21FBF': 21,
  '21FC0': 21,
  '21FC1': 21,
  '21FC2': 21,
  '21FC3': 21,
  '21FC4': 21,
  '21FC5': 21,
  '21FC6': 21,
  '21FC7': 22,
  '21FC8': 22,
  '21FC9': 22,
  '21FCA': 22,
  '21FCB': 22,
  '21FCC': 22,
  '21FCD': 22,
  '21FCE': 22,
  '21FCF': 22,
  '21FD0': 22,
  '21FD1': 22,
  '21FD2': 23,
  '21FD3': 23,
  '21FD4': 24,
  '21FD5': 24,
  '21FD6': 24,
  '21FD7': 24,
  '21FD8': 24,
  '21FD9': 24,
  '21FDA': 24,
  '21FDB': 24,
  '21FDC': 24,
  '21FDD': 25,
  '21FDE': 25,
  '21FDF': 25,
  '21FE0': 27,
  '21FE1': 27,
  '21FE2': 28,
  '21FE3': 27,
  '21FE4': 27,
  '21FE5': 32,
  '21FE6': 3,
  '21FE7': 4,
  '21FE8': 3,
  '21FE9': 6,
  '21FEA': 6,
  '21FEB': 6,
  '21FEC': 6,
  '21FED': 6,
  '21FEE': 7,
  '21FEF': 7,
  '21FF0': 7,
  '21FF1': 8,
  '21FF2': 8,
  '21FF3': 8,
  '21FF4': 8,
  '21FF5': 8,
  '21FF6': 8,
  '21FF7': 9,
  '21FF8': 9,
  '21FF9': 9,
  '21FFA': 9,
  '21FFB': 9,
  '21FFC': 10,
  '21FFD': 10,
  '21FFE': 11,
  '21FFF': 11,
  '22000': 11,
  '22001': 12,
  '22002': 14,
  '22003': 15,
  '22004': 16,
  '22005': 16,
  '22006': 16,
  '22007': 17,
  '22008': 18,
  '22009': 19,
  '2200A': 19,
  '2200B': 20,
  '2200C': 22,
  '2200D': 22,
  '2200E': 24,
  '2200F': 27,
  '22010': 29,
  '22011': 4,
  '22012': 4,
  '22013': 4,
  '22014': 4,
  '22015': 4,
  '22016': 5,
  '22017': 5,
  '22018': 5,
  '22019': 5,
  '2201A': 6,
  '2201B': 6,
  '2201C': 7,
  '2201D': 7,
  '2201E': 7,
  '2201F': 8,
  '22020': 9,
  '22021': 10,
  '22022': 10,
  '22023': 11,
  '22024': 12,
  '22025': 12,
  '22026': 13,
  '22027': 13,
  '22028': 15,
  '22029': 15,
  '2202A': 15,
  '2202B': 15,
  '2202C': 16,
  '2202D': 17,
  '2202E': 18,
  '2202F': 19,
  '22030': 21,
  '22031': 21,
  '22032': 21,
  '22033': 4,
  '22034': 5,
  '22035': 5,
  '22036': 6,
  '22037': 8,
  '22038': 8,
  '22039': 9,
  '2203A': 9,
  '2203B': 9,
  '2203C': 10,
  '2203D': 10,
  '2203E': 10,
  '2203F': 10,
  '22040': 11,
  '22041': 11,
  '22042': 11,
  '22043': 11,
  '22044': 11,
  '22045': 12,
  '22046': 12,
  '22047': 12,
  '22048': 12,
  '22049': 12,
  '2204A': 13,
  '2204B': 15,
  '2204C': 15,
  '2204D': 16,
  '2204E': 17,
  '2204F': 16,
  '22050': 18,
  '22051': 20,
  '22052': 5,
  '22053': 5,
  '22054': 5,
  '22055': 6,
  '22056': 6,
  '22057': 6,
  '22058': 6,
  '22059': 6,
  '2205A': 6,
  '2205B': 6,
  '2205C': 6,
  '2205D': 6,
  '2205E': 6,
  '2205F': 6,
  '22060': 6,
  '22061': 6,
  '22062': 6,
  '22063': 6,
  '22064': 6,
  '22065': 7,
  '22066': 7,
  '22067': 7,
  '22068': 7,
  '22069': 7,
  '2206A': 7,
  '2206B': 7,
  '2206C': 7,
  '2206D': 7,
  '2206E': 7,
  '2206F': 7,
  '22070': 7,
  '22071': 7,
  '22072': 7,
  '22073': 7,
  '22074': 7,
  '22075': 7,
  '22076': 7,
  '22077': 7,
  '22078': 7,
  '22079': 7,
  '2207A': 7,
  '2207B': 8,
  '2207C': 8,
  '2207D': 8,
  '2207E': 8,
  '2207F': 8,
  '22080': 8,
  '22081': 8,
  '22082': 8,
  '22083': 8,
  '22084': 8,
  '22085': 8,
  '22086': 8,
  '22087': 8,
  '22088': 8,
  '22089': 8,
  '2208A': 8,
  '2208B': 8,
  '2208C': 8,
  '2208D': 8,
  '2208E': 8,
  '2208F': 8,
  '22090': 9,
  '22091': 9,
  '22092': 9,
  '22093': 9,
  '22094': 9,
  '22095': 9,
  '22096': 9,
  '22097': 9,
  '22098': 9,
  '22099': 9,
  '2209A': 9,
  '2209B': 9,
  '2209C': 9,
  '2209D': 9,
  '2209E': 9,
  '2209F': 9,
  '220A0': 9,
  '220A1': 9,
  '220A2': 9,
  '220A3': 9,
  '220A4': 9,
  '220A5': 9,
  '220A6': 9,
  '220A7': 9,
  '220A8': 9,
  '220A9': 9,
  '220AA': 9,
  '220AB': 9,
  '220AC': 9,
  '220AD': 9,
  '220AE': 9,
  '220AF': 9,
  '220B0': 9,
  '220B1': 10,
  '220B2': 10,
  '220B3': 10,
  '220B4': 10,
  '220B5': 10,
  '220B6': 10,
  '220B7': 10,
  '220B8': 10,
  '220B9': 10,
  '220BA': 10,
  '220BB': 10,
  '220BC': 10,
  '220BD': 10,
  '220BE': 10,
  '220BF': 10,
  '220C0': 10,
  '220C1': 10,
  '220C2': 10,
  '220C3': 10,
  '220C4': 10,
  '220C5': 10,
  '220C6': 10,
  '220C7': 10,
  '220C8': 10,
  '220C9': 10,
  '220CA': 10,
  '220CB': 10,
  '220CC': 10,
  '220CD': 11,
  '220CE': 11,
  '220CF': 11,
  '220D0': 11,
  '220D1': 11,
  '220D2': 11,
  '220D3': 11,
  '220D4': 11,
  '220D5': 11,
  '220D6': 11,
  '220D7': 11,
  '220D8': 11,
  '220D9': 11,
  '220DA': 11,
  '220DB': 11,
  '220DC': 11,
  '220DD': 11,
  '220DE': 11,
  '220DF': 11,
  '220E0': 11,
  '220E1': 11,
  '220E2': 11,
  '220E3': 11,
  '220E4': 11,
  '220E5': 11,
  '220E6': 11,
  '220E7': 11,
  '220E8': 11,
  '220E9': 11,
  '220EA': 11,
  '220EB': 12,
  '220EC': 12,
  '220ED': 12,
  '220EE': 12,
  '220EF': 12,
  '220F0': 12,
  '220F1': 12,
  '220F2': 12,
  '220F3': 12,
  '220F4': 12,
  '220F5': 12,
  '220F6': 12,
  '220F7': 12,
  '220F8': 12,
  '220F9': 12,
  '220FA': 12,
  '220FB': 12,
  '220FC': 12,
  '220FD': 12,
  '220FE': 12,
  '220FF': 12,
  '22100': 12,
  '22101': 12,
  '22102': 12,
  '22103': 12,
  '22104': 12,
  '22105': 12,
  '22106': 12,
  '22107': 12,
  '22108': 12,
  '22109': 12,
  '2210A': 13,
  '2210B': 13,
  '2210C': 13,
  '2210D': 13,
  '2210E': 13,
  '2210F': 13,
  '22110': 13,
  '22111': 13,
  '22112': 13,
  '22113': 13,
  '22114': 13,
  '22115': 13,
  '22116': 13,
  '22117': 13,
  '22118': 13,
  '22119': 13,
  '2211A': 13,
  '2211B': 13,
  '2211C': 13,
  '2211D': 13,
  '2211E': 13,
  '2211F': 14,
  '22120': 14,
  '22121': 14,
  '22122': 14,
  '22123': 14,
  '22124': 14,
  '22125': 14,
  '22126': 14,
  '22127': 14,
  '22128': 14,
  '22129': 14,
  '2212A': 14,
  '2212B': 14,
  '2212C': 14,
  '2212D': 14,
  '2212E': 14,
  '2212F': 14,
  '22130': 14,
  '22131': 14,
  '22132': 14,
  '22133': 15,
  '22134': 15,
  '22135': 15,
  '22136': 15,
  '22137': 15,
  '22138': 15,
  '22139': 15,
  '2213A': 15,
  '2213B': 15,
  '2213C': 15,
  '2213D': 15,
  '2213E': 15,
  '2213F': 15,
  '22140': 15,
  '22141': 15,
  '22142': 15,
  '22143': 15,
  '22144': 15,
  '22145': 15,
  '22146': 15,
  '22147': 15,
  '22148': 15,
  '22149': 15,
  '2214A': 15,
  '2214B': 15,
  '2214C': 15,
  '2214D': 16,
  '2214E': 16,
  '2214F': 16,
  '22150': 16,
  '22151': 16,
  '22152': 16,
  '22153': 16,
  '22154': 16,
  '22155': 16,
  '22156': 16,
  '22157': 16,
  '22158': 16,
  '22159': 16,
  '2215A': 16,
  '2215B': 16,
  '2215C': 16,
  '2215D': 16,
  '2215E': 16,
  '2215F': 17,
  '22160': 17,
  '22161': 17,
  '22162': 17,
  '22163': 17,
  '22164': 17,
  '22165': 17,
  '22166': 17,
  '22167': 17,
  '22168': 17,
  '22169': 18,
  '2216A': 18,
  '2216B': 18,
  '2216C': 18,
  '2216D': 19,
  '2216E': 19,
  '2216F': 19,
  '22170': 19,
  '22171': 19,
  '22172': 19,
  '22173': 19,
  '22174': 19,
  '22175': 19,
  '22176': 19,
  '22177': 19,
  '22178': 20,
  '22179': 20,
  '2217A': 20,
  '2217B': 21,
  '2217C': 21,
  '2217D': 21,
  '2217E': 22,
  '2217F': 22,
  '22180': 22,
  '22181': 22,
  '22182': 23,
  '22183': 24,
  '22184': 24,
  '22185': 24,
  '22186': 24,
  '22187': 27,
  '22188': 27,
  '22189': 5,
  '2218A': 7,
  '2218B': 7,
  '2218C': 7,
  '2218D': 8,
  '2218E': 8,
  '2218F': 8,
  '22190': 8,
  '22191': 9,
  '22192': 9,
  '22193': 9,
  '22194': 9,
  '22195': 9,
  '22196': 10,
  '22197': 10,
  '22198': 10,
  '22199': 10,
  '2219A': 11,
  '2219B': 12,
  '2219C': 12,
  '2219D': 12,
  '2219E': 13,
  '2219F': 13,
  '221A0': 13,
  '221A1': 14,
  '221A2': 14,
  '221A3': 14,
  '221A4': 14,
  '221A5': 15,
  '221A6': 16,
  '221A7': 16,
  '221A8': 16,
  '221A9': 16,
  '221AA': 18,
  '221AB': 19,
  '221AC': 20,
  '221AD': 21,
  '221AE': 23,
  '221AF': 4,
  '221B0': 4,
  '221B1': 4,
  '221B2': 5,
  '221B3': 5,
  '221B4': 5,
  '221B5': 5,
  '221B6': 6,
  '221B7': 7,
  '221B8': 7,
  '221B9': 7,
  '221BA': 7,
  '221BB': 8,
  '221BC': 8,
  '221BD': 8,
  '221BE': 8,
  '221BF': 9,
  '221C0': 9,
  '221C1': 10,
  '221C2': 9,
  '221C3': 10,
  '221C4': 10,
  '221C5': 10,
  '221C6': 10,
  '221C7': 11,
  '221C8': 11,
  '221C9': 11,
  '221CA': 12,
  '221CB': 13,
  '221CC': 14,
  '221CD': 14,
  '221CE': 14,
  '221CF': 14,
  '221D0': 15,
  '221D1': 15,
  '221D2': 15,
  '221D3': 15,
  '221D4': 17,
  '221D5': 18,
  '221D6': 22,
  '221D7': 6,
  '221D8': 6,
  '221D9': 6,
  '221DA': 6,
  '221DB': 6,
  '221DC': 6,
  '221DD': 6,
  '221DE': 6,
  '221DF': 6,
  '221E0': 6,
  '221E1': 6,
  '221E2': 6,
  '221E3': 7,
  '221E4': 7,
  '221E5': 7,
  '221E6': 7,
  '221E7': 7,
  '221E8': 7,
  '221E9': 7,
  '221EA': 7,
  '221EB': 7,
  '221EC': 7,
  '221ED': 7,
  '221EE': 7,
  '221EF': 7,
  '221F0': 7,
  '221F1': 7,
  '221F2': 8,
  '221F3': 8,
  '221F4': 8,
  '221F5': 8,
  '221F6': 8,
  '221F7': 8,
  '221F8': 8,
  '221F9': 8,
  '221FA': 8,
  '221FB': 8,
  '221FC': 8,
  '221FD': 8,
  '221FE': 8,
  '221FF': 8,
  '22200': 8,
  '22201': 8,
  '22202': 8,
  '22203': 8,
  '22204': 8,
  '22205': 8,
  '22206': 8,
  '22207': 9,
  '22208': 9,
  '22209': 9,
  '2220A': 9,
  '2220B': 9,
  '2220C': 9,
  '2220D': 9,
  '2220E': 9,
  '2220F': 9,
  '22210': 9,
  '22211': 9,
  '22212': 9,
  '22213': 9,
  '22214': 9,
  '22215': 9,
  '22216': 9,
  '22217': 9,
  '22218': 9,
  '22219': 10,
  '2221A': 10,
  '2221B': 10,
  '2221C': 10,
  '2221D': 10,
  '2221E': 10,
  '2221F': 10,
  '22220': 10,
  '22221': 10,
  '22222': 10,
  '22223': 10,
  '22224': 10,
  '22225': 10,
  '22226': 10,
  '22227': 10,
  '22228': 10,
  '22229': 10,
  '2222A': 10,
  '2222B': 10,
  '2222C': 10,
  '2222D': 10,
  '2222E': 10,
  '2222F': 10,
  '22230': 10,
  '22231': 10,
  '22232': 10,
  '22233': 11,
  '22234': 11,
  '22235': 11,
  '22236': 11,
  '22237': 11,
  '22238': 11,
  '22239': 11,
  '2223A': 11,
  '2223B': 11,
  '2223C': 11,
  '2223D': 11,
  '2223E': 11,
  '2223F': 11,
  '22240': 11,
  '22241': 11,
  '22242': 11,
  '22243': 11,
  '22244': 11,
  '22245': 11,
  '22246': 11,
  '22247': 11,
  '22248': 11,
  '22249': 11,
  '2224A': 11,
  '2224B': 11,
  '2224C': 11,
  '2224D': 11,
  '2224E': 11,
  '2224F': 11,
  '22250': 11,
  '22251': 11,
  '22252': 11,
  '22253': 11,
  '22254': 11,
  '22255': 11,
  '22256': 11,
  '22257': 11,
  '22258': 11,
  '22259': 11,
  '2225A': 11,
  '2225B': 12,
  '2225C': 12,
  '2225D': 12,
  '2225E': 12,
  '2225F': 12,
  '22260': 12,
  '22261': 12,
  '22262': 12,
  '22263': 12,
  '22264': 12,
  '22265': 12,
  '22266': 12,
  '22267': 12,
  '22268': 12,
  '22269': 12,
  '2226A': 12,
  '2226B': 12,
  '2226C': 12,
  '2226D': 12,
  '2226E': 12,
  '2226F': 12,
  '22270': 12,
  '22271': 12,
  '22272': 12,
  '22273': 12,
  '22274': 12,
  '22275': 12,
  '22276': 12,
  '22277': 12,
  '22278': 12,
  '22279': 12,
  '2227A': 12,
  '2227B': 12,
  '2227C': 13,
  '2227D': 12,
  '2227E': 12,
  '2227F': 13,
  '22280': 13,
  '22281': 13,
  '22282': 13,
  '22283': 13,
  '22284': 13,
  '22285': 13,
  '22286': 13,
  '22287': 13,
  '22288': 13,
  '22289': 13,
  '2228A': 13,
  '2228B': 13,
  '2228C': 13,
  '2228D': 13,
  '2228E': 13,
  '2228F': 13,
  '22290': 13,
  '22291': 13,
  '22292': 13,
  '22293': 13,
  '22294': 13,
  '22295': 14,
  '22296': 14,
  '22297': 14,
  '22298': 14,
  '22299': 14,
  '2229A': 14,
  '2229B': 14,
  '2229C': 14,
  '2229D': 14,
  '2229E': 14,
  '2229F': 14,
  '222A0': 14,
  '222A1': 14,
  '222A2': 14,
  '222A3': 14,
  '222A4': 14,
  '222A5': 14,
  '222A6': 14,
  '222A7': 14,
  '222A8': 14,
  '222A9': 14,
  '222AA': 14,
  '222AB': 14,
  '222AC': 14,
  '222AD': 15,
  '222AE': 15,
  '222AF': 15,
  '222B0': 15,
  '222B1': 15,
  '222B2': 15,
  '222B3': 15,
  '222B4': 15,
  '222B5': 15,
  '222B6': 15,
  '222B7': 15,
  '222B8': 15,
  '222B9': 15,
  '222BA': 15,
  '222BB': 15,
  '222BC': 15,
  '222BD': 15,
  '222BE': 15,
  '222BF': 15,
  '222C0': 15,
  '222C1': 15,
  '222C2': 16,
  '222C3': 16,
  '222C4': 16,
  '222C5': 16,
  '222C6': 16,
  '222C7': 16,
  '222C8': 16,
  '222C9': 16,
  '222CA': 16,
  '222CB': 16,
  '222CC': 16,
  '222CD': 16,
  '222CE': 16,
  '222CF': 16,
  '222D0': 16,
  '222D1': 17,
  '222D2': 17,
  '222D3': 17,
  '222D4': 17,
  '222D5': 17,
  '222D6': 17,
  '222D7': 17,
  '222D8': 17,
  '222D9': 17,
  '222DA': 17,
  '222DB': 17,
  '222DC': 17,
  '222DD': 17,
  '222DE': 17,
  '222DF': 17,
  '222E0': 17,
  '222E1': 18,
  '222E2': 18,
  '222E3': 18,
  '222E4': 18,
  '222E5': 18,
  '222E6': 18,
  '222E7': 18,
  '222E8': 18,
  '222E9': 18,
  '222EA': 18,
  '222EB': 18,
  '222EC': 18,
  '222ED': 18,
  '222EE': 19,
  '222EF': 19,
  '222F0': 19,
  '222F1': 19,
  '222F2': 19,
  '222F3': 19,
  '222F4': 19,
  '222F5': 19,
  '222F6': 19,
  '222F7': 19,
  '222F8': 19,
  '222F9': 19,
  '222FA': 19,
  '222FB': 20,
  '222FC': 20,
  '222FD': 20,
  '222FE': 20,
  '222FF': 20,
  '22300': 20,
  '22301': 20,
  '22302': 20,
  '22303': 20,
  '22304': 21,
  '22305': 21,
  '22306': 21,
  '22307': 21,
  '22308': 22,
  '22309': 22,
  '2230A': 22,
  '2230B': 22,
  '2230C': 22,
  '2230D': 23,
  '2230E': 23,
  '2230F': 23,
  '22310': 23,
  '22311': 23,
  '22312': 24,
  '22313': 26,
  '22314': 27,
  '22315': 27,
  '22316': 28,
  '22317': 4,
  '22318': 5,
  '22319': 5,
  '2231A': 5,
  '2231B': 7,
  '2231C': 6,
  '2231D': 7,
  '2231E': 7,
  '2231F': 8,
  '22320': 9,
  '22321': 9,
  '22322': 9,
  '22323': 9,
  '22324': 9,
  '22325': 9,
  '22326': 10,
  '22327': 10,
  '22328': 11,
  '22329': 11,
  '2232A': 11,
  '2232B': 12,
  '2232C': 3,
  '2232D': 4,
  '2232E': 5,
  '2232F': 5,
  '22330': 6,
  '22331': 6,
  '22332': 6,
  '22333': 7,
  '22334': 9,
  '22335': 7,
  '22336': 7,
  '22337': 7,
  '22338': 7,
  '22339': 7,
  '2233A': 7,
  '2233B': 8,
  '2233C': 9,
  '2233D': 8,
  '2233E': 8,
  '2233F': 8,
  '22340': 8,
  '22341': 8,
  '22342': 9,
  '22343': 9,
  '22344': 9,
  '22345': 9,
  '22346': 9,
  '22347': 9,
  '22348': 9,
  '22349': 9,
  '2234A': 9,
  '2234B': 9,
  '2234C': 9,
  '2234D': 10,
  '2234E': 10,
  '2234F': 10,
  '22350': 10,
  '22351': 10,
  '22352': 10,
  '22353': 10,
  '22354': 11,
  '22355': 11,
  '22356': 11,
  '22357': 11,
  '22358': 11,
  '22359': 11,
  '2235A': 12,
  '2235B': 12,
  '2235C': 12,
  '2235D': 12,
  '2235E': 12,
  '2235F': 12,
  '22360': 12,
  '22361': 12,
  '22362': 13,
  '22363': 13,
  '22364': 13,
  '22365': 13,
  '22366': 14,
  '22367': 14,
  '22368': 14,
  '22369': 14,
  '2236A': 14,
  '2236B': 14,
  '2236C': 15,
  '2236D': 14,
  '2236E': 16,
  '2236F': 16,
  '22370': 16,
  '22371': 16,
  '22372': 17,
  '22373': 17,
  '22374': 17,
  '22375': 19,
  '22376': 22,
  '22377': 27,
  '22378': 27,
  '22379': 29,
  '2237A': 3,
  '2237B': 4,
  '2237C': 4,
  '2237D': 6,
  '2237E': 6,
  '2237F': 7,
  '22380': 7,
  '22381': 7,
  '22382': 7,
  '22383': 7,
  '22384': 8,
  '22385': 8,
  '22386': 9,
  '22387': 9,
  '22388': 9,
  '22389': 9,
  '2238A': 9,
  '2238B': 10,
  '2238C': 10,
  '2238D': 11,
  '2238E': 12,
  '2238F': 13,
  '22390': 13,
  '22391': 15,
  '22392': 15,
  '22393': 16,
  '22394': 17,
  '22395': 18,
  '22396': 21,
  '22397': 3,
  '22398': 3,
  '22399': 4,
  '2239A': 4,
  '2239B': 4,
  '2239C': 4,
  '2239D': 4,
  '2239E': 4,
  '2239F': 4,
  '223A0': 4,
  '223A1': 4,
  '223A2': 5,
  '223A3': 5,
  '223A4': 5,
  '223A5': 5,
  '223A6': 5,
  '223A7': 5,
  '223A8': 5,
  '223A9': 5,
  '223AA': 5,
  '223AB': 6,
  '223AC': 6,
  '223AD': 6,
  '223AE': 6,
  '223AF': 6,
  '223B0': 6,
  '223B1': 6,
  '223B2': 6,
  '223B3': 6,
  '223B4': 6,
  '223B5': 6,
  '223B6': 6,
  '223B7': 7,
  '223B8': 7,
  '223B9': 7,
  '223BA': 7,
  '223BB': 7,
  '223BC': 7,
  '223BD': 7,
  '223BE': 7,
  '223BF': 7,
  '223C0': 7,
  '223C1': 7,
  '223C2': 7,
  '223C3': 7,
  '223C4': 7,
  '223C5': 7,
  '223C6': 8,
  '223C7': 8,
  '223C8': 8,
  '223C9': 8,
  '223CA': 8,
  '223CB': 8,
  '223CC': 8,
  '223CD': 8,
  '223CE': 8,
  '223CF': 8,
  '223D0': 8,
  '223D1': 8,
  '223D2': 8,
  '223D3': 8,
  '223D4': 9,
  '223D5': 9,
  '223D6': 9,
  '223D7': 9,
  '223D8': 9,
  '223D9': 9,
  '223DA': 9,
  '223DB': 9,
  '223DC': 9,
  '223DD': 9,
  '223DE': 9,
  '223DF': 9,
  '223E0': 9,
  '223E1': 9,
  '223E2': 9,
  '223E3': 9,
  '223E4': 10,
  '223E5': 10,
  '223E6': 10,
  '223E7': 10,
  '223E8': 10,
  '223E9': 10,
  '223EA': 10,
  '223EB': 10,
  '223EC': 10,
  '223ED': 10,
  '223EE': 11,
  '223EF': 11,
  '223F0': 11,
  '223F1': 11,
  '223F2': 11,
  '223F3': 11,
  '223F4': 11,
  '223F5': 11,
  '223F6': 11,
  '223F7': 11,
  '223F8': 11,
  '223F9': 11,
  '223FA': 11,
  '223FB': 11,
  '223FC': 11,
  '223FD': 11,
  '223FE': 11,
  '223FF': 11,
  '22400': 12,
  '22401': 12,
  '22402': 12,
  '22403': 12,
  '22404': 12,
  '22405': 12,
  '22406': 12,
  '22407': 12,
  '22408': 12,
  '22409': 12,
  '2240A': 13,
  '2240B': 13,
  '2240C': 13,
  '2240D': 13,
  '2240E': 13,
  '2240F': 13,
  '22410': 13,
  '22411': 13,
  '22412': 14,
  '22413': 14,
  '22414': 14,
  '22415': 14,
  '22416': 14,
  '22417': 14,
  '22418': 14,
  '22419': 14,
  '2241A': 14,
  '2241B': 14,
  '2241C': 14,
  '2241D': 14,
  '2241E': 15,
  '2241F': 15,
  '22420': 15,
  '22421': 15,
  '22422': 15,
  '22423': 15,
  '22424': 15,
  '22425': 16,
  '22426': 16,
  '22427': 16,
  '22428': 16,
  '22429': 16,
  '2242A': 16,
  '2242B': 16,
  '2242C': 17,
  '2242D': 17,
  '2242E': 17,
  '2242F': 17,
  '22430': 17,
  '22431': 17,
  '22432': 18,
  '22433': 18,
  '22434': 18,
  '22435': 18,
  '22436': 18,
  '22437': 18,
  '22438': 19,
  '22439': 19,
  '2243A': 19,
  '2243B': 19,
  '2243C': 19,
  '2243D': 19,
  '2243E': 19,
  '2243F': 20,
  '22440': 20,
  '22441': 20,
  '22442': 20,
  '22443': 20,
  '22444': 20,
  '22445': 20,
  '22446': 21,
  '22447': 21,
  '22448': 21,
  '22449': 21,
  '2244A': 22,
  '2244B': 24,
  '2244C': 25,
  '2244D': 28,
  '2244E': 32,
  '2244F': 6,
  '22450': 6,
  '22451': 6,
  '22452': 6,
  '22453': 7,
  '22454': 8,
  '22455': 8,
  '22456': 8,
  '22457': 9,
  '22458': 9,
  '22459': 9,
  '2245A': 9,
  '2245B': 10,
  '2245C': 10,
  '2245D': 10,
  '2245E': 11,
  '2245F': 11,
  '22460': 11,
  '22461': 11,
  '22462': 12,
  '22463': 12,
  '22464': 12,
  '22465': 13,
  '22466': 13,
  '22467': 13,
  '22468': 14,
  '22469': 14,
  '2246A': 14,
  '2246B': 14,
  '2246C': 14,
  '2246D': 15,
  '2246E': 15,
  '2246F': 16,
  '22470': 16,
  '22471': 16,
  '22472': 17,
  '22473': 18,
  '22474': 18,
  '22475': 18,
  '22476': 18,
  '22477': 19,
  '22478': 19,
  '22479': 19,
  '2247A': 19,
  '2247B': 20,
  '2247C': 20,
  '2247D': 22,
  '2247E': 23,
  '2247F': 26,
  '22480': 5,
  '22481': 5,
  '22482': 5,
  '22483': 6,
  '22484': 6,
  '22485': 6,
  '22486': 6,
  '22487': 7,
  '22488': 7,
  '22489': 8,
  '2248A': 8,
  '2248B': 8,
  '2248C': 8,
  '2248D': 8,
  '2248E': 9,
  '2248F': 10,
  '22490': 10,
  '22491': 10,
  '22492': 10,
  '22493': 10,
  '22494': 11,
  '22495': 11,
  '22496': 11,
  '22497': 11,
  '22498': 11,
  '22499': 11,
  '2249A': 11,
  '2249B': 11,
  '2249C': 11,
  '2249D': 11,
  '2249E': 12,
  '2249F': 12,
  '224A0': 12,
  '224A1': 12,
  '224A2': 12,
  '224A3': 12,
  '224A4': 13,
  '224A5': 13,
  '224A6': 13,
  '224A7': 13,
  '224A8': 13,
  '224A9': 14,
  '224AA': 14,
  '224AB': 15,
  '224AC': 15,
  '224AD': 15,
  '224AE': 15,
  '224AF': 15,
  '224B0': 16,
  '224B1': 16,
  '224B2': 16,
  '224B3': 16,
  '224B4': 17,
  '224B5': 18,
  '224B6': 21,
  '224B7': 20,
  '224B8': 22,
  '224B9': 22,
  '224BA': 22,
  '224BB': 25,
  '224BC': 4,
  '224BD': 4,
  '224BE': 5,
  '224BF': 5,
  '224C0': 6,
  '224C1': 6,
  '224C2': 6,
  '224C3': 6,
  '224C4': 7,
  '224C5': 7,
  '224C6': 7,
  '224C7': 7,
  '224C8': 7,
  '224C9': 7,
  '224CA': 7,
  '224CB': 7,
  '224CC': 7,
  '224CD': 7,
  '224CE': 7,
  '224CF': 7,
  '224D0': 7,
  '224D1': 7,
  '224D2': 8,
  '224D3': 8,
  '224D4': 8,
  '224D5': 8,
  '224D6': 8,
  '224D7': 8,
  '224D8': 8,
  '224D9': 8,
  '224DA': 8,
  '224DB': 8,
  '224DC': 9,
  '224DD': 9,
  '224DE': 9,
  '224DF': 9,
  '224E0': 9,
  '224E1': 9,
  '224E2': 9,
  '224E3': 9,
  '224E4': 9,
  '224E5': 9,
  '224E6': 9,
  '224E7': 9,
  '224E8': 9,
  '224E9': 9,
  '224EA': 10,
  '224EB': 10,
  '224EC': 10,
  '224ED': 10,
  '224EE': 10,
  '224EF': 10,
  '224F0': 10,
  '224F1': 10,
  '224F2': 10,
  '224F3': 10,
  '224F4': 10,
  '224F5': 10,
  '224F6': 10,
  '224F7': 10,
  '224F8': 10,
  '224F9': 10,
  '224FA': 10,
  '224FB': 10,
  '224FC': 10,
  '224FD': 10,
  '224FE': 10,
  '224FF': 10,
  '22500': 10,
  '22501': 11,
  '22502': 11,
  '22503': 11,
  '22504': 11,
  '22505': 11,
  '22506': 11,
  '22507': 11,
  '22508': 11,
  '22509': 11,
  '2250A': 11,
  '2250B': 11,
  '2250C': 11,
  '2250D': 11,
  '2250E': 11,
  '2250F': 11,
  '22510': 11,
  '22511': 11,
  '22512': 11,
  '22513': 11,
  '22514': 11,
  '22515': 11,
  '22516': 11,
  '22517': 11,
  '22518': 11,
  '22519': 11,
  '2251A': 11,
  '2251B': 11,
  '2251C': 11,
  '2251D': 11,
  '2251E': 11,
  '2251F': 12,
  '22520': 12,
  '22521': 12,
  '22522': 12,
  '22523': 12,
  '22524': 12,
  '22525': 12,
  '22526': 12,
  '22527': 12,
  '22528': 12,
  '22529': 12,
  '2252A': 12,
  '2252B': 12,
  '2252C': 12,
  '2252D': 12,
  '2252E': 12,
  '2252F': 12,
  '22530': 12,
  '22531': 12,
  '22532': 13,
  '22533': 13,
  '22534': 13,
  '22535': 13,
  '22536': 13,
  '22537': 13,
  '22538': 13,
  '22539': 13,
  '2253A': 13,
  '2253B': 13,
  '2253C': 13,
  '2253D': 13,
  '2253E': 13,
  '2253F': 13,
  '22540': 13,
  '22541': 13,
  '22542': 13,
  '22543': 13,
  '22544': 13,
  '22545': 13,
  '22546': 13,
  '22547': 13,
  '22548': 13,
  '22549': 13,
  '2254A': 13,
  '2254B': 13,
  '2254C': 13,
  '2254D': 13,
  '2254E': 13,
  '2254F': 14,
  '22550': 14,
  '22551': 14,
  '22552': 14,
  '22553': 14,
  '22554': 14,
  '22555': 14,
  '22556': 14,
  '22557': 14,
  '22558': 14,
  '22559': 14,
  '2255A': 14,
  '2255B': 14,
  '2255C': 14,
  '2255D': 14,
  '2255E': 14,
  '2255F': 14,
  '22560': 14,
  '22561': 14,
  '22562': 14,
  '22563': 14,
  '22564': 14,
  '22565': 14,
  '22566': 14,
  '22567': 13,
  '22568': 14,
  '22569': 14,
  '2256A': 15,
  '2256B': 15,
  '2256C': 15,
  '2256D': 15,
  '2256E': 15,
  '2256F': 15,
  '22570': 15,
  '22571': 15,
  '22572': 15,
  '22573': 15,
  '22574': 15,
  '22575': 15,
  '22576': 15,
  '22577': 15,
  '22578': 15,
  '22579': 16,
  '2257A': 16,
  '2257B': 16,
  '2257C': 16,
  '2257D': 16,
  '2257E': 16,
  '2257F': 16,
  '22580': 16,
  '22581': 16,
  '22582': 16,
  '22583': 16,
  '22584': 16,
  '22585': 16,
  '22586': 16,
  '22587': 16,
  '22588': 17,
  '22589': 17,
  '2258A': 17,
  '2258B': 17,
  '2258C': 17,
  '2258D': 17,
  '2258E': 17,
  '2258F': 18,
  '22590': 18,
  '22591': 18,
  '22592': 18,
  '22593': 18,
  '22594': 18,
  '22595': 18,
  '22596': 18,
  '22597': 18,
  '22598': 19,
  '22599': 19,
  '2259A': 19,
  '2259B': 19,
  '2259C': 19,
  '2259D': 20,
  '2259E': 20,
  '2259F': 20,
  '225A0': 20,
  '225A1': 20,
  '225A2': 20,
  '225A3': 21,
  '225A4': 21,
  '225A5': 22,
  '225A6': 23,
  '225A7': 25,
  '225A8': 27,
  '225A9': 3,
  '225AA': 4,
  '225AB': 5,
  '225AC': 5,
  '225AD': 5,
  '225AE': 5,
  '225AF': 5,
  '225B0': 5,
  '225B1': 5,
  '225B2': 6,
  '225B3': 6,
  '225B4': 6,
  '225B5': 6,
  '225B6': 6,
  '225B7': 6,
  '225B8': 6,
  '225B9': 6,
  '225BA': 6,
  '225BB': 6,
  '225BC': 6,
  '225BD': 6,
  '225BE': 6,
  '225BF': 6,
  '225C0': 6,
  '225C1': 6,
  '225C2': 6,
  '225C3': 6,
  '225C4': 6,
  '225C5': 6,
  '225C6': 6,
  '225C7': 6,
  '225C8': 6,
  '225C9': 7,
  '225CA': 7,
  '225CB': 7,
  '225CC': 7,
  '225CD': 7,
  '225CE': 7,
  '225CF': 7,
  '225D0': 7,
  '225D1': 7,
  '225D2': 7,
  '225D3': 7,
  '225D4': 7,
  '225D5': 7,
  '225D6': 7,
  '225D7': 7,
  '225D8': 7,
  '225D9': 7,
  '225DA': 7,
  '225DB': 7,
  '225DC': 7,
  '225DD': 7,
  '225DE': 7,
  '225DF': 7,
  '225E0': 7,
  '225E1': 7,
  '225E2': 7,
  '225E3': 7,
  '225E4': 7,
  '225E5': 7,
  '225E6': 7,
  '225E7': 7,
  '225E8': 7,
  '225E9': 7,
  '225EA': 7,
  '225EB': 7,
  '225EC': 7,
  '225ED': 7,
  '225EE': 7,
  '225EF': 7,
  '225F0': 7,
  '225F1': 7,
  '225F2': 7,
  '225F3': 7,
  '225F4': 7,
  '225F5': 7,
  '225F6': 7,
  '225F7': 7,
  '225F8': 7,
  '225F9': 7,
  '225FA': 7,
  '225FB': 7,
  '225FC': 7,
  '225FD': 7,
  '225FE': 7,
  '225FF': 8,
  '22600': 8,
  '22601': 8,
  '22602': 8,
  '22603': 8,
  '22604': 8,
  '22605': 8,
  '22606': 8,
  '22607': 8,
  '22608': 8,
  '22609': 8,
  '2260A': 8,
  '2260B': 8,
  '2260C': 8,
  '2260D': 8,
  '2260E': 8,
  '2260F': 8,
  '22610': 8,
  '22611': 8,
  '22612': 8,
  '22613': 8,
  '22614': 8,
  '22615': 8,
  '22616': 8,
  '22617': 8,
  '22618': 8,
  '22619': 8,
  '2261A': 8,
  '2261B': 8,
  '2261C': 8,
  '2261D': 8,
  '2261E': 8,
  '2261F': 8,
  '22620': 8,
  '22621': 8,
  '22622': 8,
  '22623': 8,
  '22624': 8,
  '22625': 8,
  '22626': 8,
  '22627': 8,
  '22628': 8,
  '22629': 8,
  '2262A': 8,
  '2262B': 8,
  '2262C': 8,
  '2262D': 8,
  '2262E': 8,
  '2262F': 8,
  '22630': 8,
  '22631': 8,
  '22632': 8,
  '22633': 8,
  '22634': 9,
  '22635': 9,
  '22636': 9,
  '22637': 9,
  '22638': 9,
  '22639': 9,
  '2263A': 9,
  '2263B': 9,
  '2263C': 9,
  '2263D': 9,
  '2263E': 9,
  '2263F': 9,
  '22640': 9,
  '22641': 9,
  '22642': 9,
  '22643': 9,
  '22644': 9,
  '22645': 9,
  '22646': 9,
  '22647': 9,
  '22648': 9,
  '22649': 9,
  '2264A': 9,
  '2264B': 9,
  '2264C': 9,
  '2264D': 9,
  '2264E': 9,
  '2264F': 9,
  '22650': 9,
  '22651': 9,
  '22652': 9,
  '22653': 9,
  '22654': 9,
  '22655': 9,
  '22656': 9,
  '22657': 9,
  '22658': 9,
  '22659': 9,
  '2265A': 9,
  '2265B': 9,
  '2265C': 9,
  '2265D': 9,
  '2265E': 9,
  '2265F': 9,
  '22660': 9,
  '22661': 9,
  '22662': 9,
  '22663': 9,
  '22664': 9,
  '22665': 9,
  '22666': 9,
  '22667': 9,
  '22668': 9,
  '22669': 9,
  '2266A': 9,
  '2266B': 9,
  '2266C': 9,
  '2266D': 9,
  '2266E': 9,
  '2266F': 9,
  '22670': 9,
  '22671': 10,
  '22672': 10,
  '22673': 10,
  '22674': 10,
  '22675': 10,
  '22676': 10,
  '22677': 10,
  '22678': 10,
  '22679': 10,
  '2267A': 10,
  '2267B': 10,
  '2267C': 10,
  '2267D': 10,
  '2267E': 10,
  '2267F': 10,
  '22680': 10,
  '22681': 10,
  '22682': 10,
  '22683': 10,
  '22684': 10,
  '22685': 10,
  '22686': 10,
  '22687': 10,
  '22688': 10,
  '22689': 10,
  '2268A': 10,
  '2268B': 10,
  '2268C': 10,
  '2268D': 10,
  '2268E': 10,
  '2268F': 10,
  '22690': 10,
  '22691': 10,
  '22692': 10,
  '22693': 10,
  '22694': 10,
  '22695': 10,
  '22696': 10,
  '22697': 10,
  '22698': 10,
  '22699': 10,
  '2269A': 10,
  '2269B': 10,
  '2269C': 10,
  '2269D': 10,
  '2269E': 10,
  '2269F': 10,
  '226A0': 10,
  '226A1': 10,
  '226A2': 10,
  '226A3': 10,
  '226A4': 10,
  '226A5': 10,
  '226A6': 10,
  '226A7': 10,
  '226A8': 10,
  '226A9': 10,
  '226AA': 10,
  '226AB': 10,
  '226AC': 10,
  '226AD': 10,
  '226AE': 10,
  '226AF': 10,
  '226B0': 10,
  '226B1': 10,
  '226B2': 10,
  '226B3': 10,
  '226B4': 10,
  '226B5': 10,
  '226B6': 10,
  '226B7': 10,
  '226B8': 10,
  '226B9': 10,
  '226BA': 10,
  '226BB': 10,
  '226BC': 10,
  '226BD': 10,
  '226BE': 10,
  '226BF': 10,
  '226C0': 11,
  '226C1': 11,
  '226C2': 11,
  '226C3': 11,
  '226C4': 11,
  '226C5': 11,
  '226C6': 11,
  '226C7': 11,
  '226C8': 11,
  '226C9': 11,
  '226CA': 11,
  '226CB': 11,
  '226CC': 11,
  '226CD': 11,
  '226CE': 11,
  '226CF': 11,
  '226D0': 11,
  '226D1': 11,
  '226D2': 11,
  '226D3': 11,
  '226D4': 11,
  '226D5': 11,
  '226D6': 11,
  '226D7': 11,
  '226D8': 11,
  '226D9': 11,
  '226DA': 11,
  '226DB': 11,
  '226DC': 11,
  '226DD': 11,
  '226DE': 11,
  '226DF': 11,
  '226E0': 11,
  '226E1': 11,
  '226E2': 11,
  '226E3': 11,
  '226E4': 11,
  '226E5': 11,
  '226E6': 11,
  '226E7': 11,
  '226E8': 11,
  '226E9': 11,
  '226EA': 11,
  '226EB': 11,
  '226EC': 11,
  '226ED': 11,
  '226EE': 11,
  '226EF': 11,
  '226F0': 11,
  '226F1': 11,
  '226F2': 11,
  '226F3': 11,
  '226F4': 11,
  '226F5': 11,
  '226F6': 11,
  '226F7': 11,
  '226F8': 11,
  '226F9': 11,
  '226FA': 11,
  '226FB': 11,
  '226FC': 11,
  '226FD': 11,
  '226FE': 11,
  '226FF': 11,
  '22700': 11,
  '22701': 11,
  '22702': 11,
  '22703': 11,
  '22704': 11,
  '22705': 11,
  '22706': 11,
  '22707': 11,
  '22708': 11,
  '22709': 11,
  '2270A': 11,
  '2270B': 11,
  '2270C': 11,
  '2270D': 11,
  '2270E': 11,
  '2270F': 11,
  '22710': 11,
  '22711': 11,
  '22712': 11,
  '22713': 11,
  '22714': 11,
  '22715': 11,
  '22716': 11,
  '22717': 11,
  '22718': 11,
  '22719': 11,
  '2271A': 11,
  '2271B': 11,
  '2271C': 11,
  '2271D': 11,
  '2271E': 11,
  '2271F': 11,
  '22720': 11,
  '22721': 11,
  '22722': 11,
  '22723': 11,
  '22724': 11,
  '22725': 11,
  '22726': 11,
  '22727': 11,
  '22728': 12,
  '22729': 12,
  '2272A': 12,
  '2272B': 12,
  '2272C': 12,
  '2272D': 12,
  '2272E': 12,
  '2272F': 12,
  '22730': 12,
  '22731': 12,
  '22732': 12,
  '22733': 12,
  '22734': 12,
  '22735': 12,
  '22736': 12,
  '22737': 12,
  '22738': 12,
  '22739': 12,
  '2273A': 12,
  '2273B': 12,
  '2273C': 12,
  '2273D': 12,
  '2273E': 12,
  '2273F': 12,
  '22740': 12,
  '22741': 12,
  '22742': 12,
  '22743': 12,
  '22744': 12,
  '22745': 12,
  '22746': 12,
  '22747': 12,
  '22748': 12,
  '22749': 12,
  '2274A': 12,
  '2274B': 12,
  '2274C': 12,
  '2274D': 12,
  '2274E': 12,
  '2274F': 12,
  '22750': 12,
  '22751': 12,
  '22752': 12,
  '22753': 12,
  '22754': 12,
  '22755': 12,
  '22756': 12,
  '22757': 12,
  '22758': 12,
  '22759': 12,
  '2275A': 12,
  '2275B': 12,
  '2275C': 12,
  '2275D': 12,
  '2275E': 12,
  '2275F': 12,
  '22760': 12,
  '22761': 12,
  '22762': 12,
  '22763': 12,
  '22764': 12,
  '22765': 12,
  '22766': 12,
  '22767': 12,
  '22768': 12,
  '22769': 12,
  '2276A': 12,
  '2276B': 12,
  '2276C': 12,
  '2276D': 12,
  '2276E': 12,
  '2276F': 12,
  '22770': 12,
  '22771': 12,
  '22772': 12,
  '22773': 12,
  '22774': 12,
  '22775': 12,
  '22776': 12,
  '22777': 12,
  '22778': 12,
  '22779': 12,
  '2277A': 12,
  '2277B': 12,
  '2277C': 12,
  '2277D': 12,
  '2277E': 12,
  '2277F': 12,
  '22780': 12,
  '22781': 12,
  '22782': 12,
  '22783': 12,
  '22784': 12,
  '22785': 12,
  '22786': 12,
  '22787': 12,
  '22788': 12,
  '22789': 12,
  '2278A': 12,
  '2278B': 12,
  '2278C': 12,
  '2278D': 13,
  '2278E': 13,
  '2278F': 13,
  '22790': 13,
  '22791': 13,
  '22792': 13,
  '22793': 13,
  '22794': 13,
  '22795': 13,
  '22796': 13,
  '22797': 13,
  '22798': 13,
  '22799': 13,
  '2279A': 13,
  '2279B': 13,
  '2279C': 13,
  '2279D': 13,
  '2279E': 13,
  '2279F': 13,
  '227A0': 13,
  '227A1': 13,
  '227A2': 13,
  '227A3': 13,
  '227A4': 13,
  '227A5': 13,
  '227A6': 13,
  '227A7': 13,
  '227A8': 13,
  '227A9': 13,
  '227AA': 13,
  '227AB': 13,
  '227AC': 13,
  '227AD': 13,
  '227AE': 13,
  '227AF': 13,
  '227B0': 13,
  '227B1': 13,
  '227B2': 13,
  '227B3': 13,
  '227B4': 13,
  '227B5': 13,
  '227B6': 13,
  '227B7': 13,
  '227B8': 13,
  '227B9': 13,
  '227BA': 13,
  '227BB': 13,
  '227BC': 13,
  '227BD': 13,
  '227BE': 13,
  '227BF': 13,
  '227C0': 13,
  '227C1': 13,
  '227C2': 13,
  '227C3': 13,
  '227C4': 13,
  '227C5': 13,
  '227C6': 13,
  '227C7': 13,
  '227C8': 13,
  '227C9': 13,
  '227CA': 13,
  '227CB': 13,
  '227CC': 13,
  '227CD': 13,
  '227CE': 13,
  '227CF': 13,
  '227D0': 13,
  '227D1': 13,
  '227D2': 13,
  '227D3': 13,
  '227D4': 13,
  '227D5': 13,
  '227D6': 13,
  '227D7': 13,
  '227D8': 13,
  '227D9': 13,
  '227DA': 13,
  '227DB': 13,
  '227DC': 13,
  '227DD': 13,
  '227DE': 13,
  '227DF': 13,
  '227E0': 14,
  '227E1': 14,
  '227E2': 14,
  '227E3': 14,
  '227E4': 14,
  '227E5': 14,
  '227E6': 14,
  '227E7': 14,
  '227E8': 14,
  '227E9': 14,
  '227EA': 14,
  '227EB': 14,
  '227EC': 14,
  '227ED': 14,
  '227EE': 14,
  '227EF': 14,
  '227F0': 14,
  '227F1': 14,
  '227F2': 14,
  '227F3': 14,
  '227F4': 14,
  '227F5': 14,
  '227F6': 14,
  '227F7': 14,
  '227F8': 14,
  '227F9': 14,
  '227FA': 14,
  '227FB': 14,
  '227FC': 14,
  '227FD': 14,
  '227FE': 14,
  '227FF': 14,
  '22800': 14,
  '22801': 14,
  '22802': 14,
  '22803': 14,
  '22804': 14,
  '22805': 14,
  '22806': 14,
  '22807': 14,
  '22808': 14,
  '22809': 14,
  '2280A': 14,
  '2280B': 14,
  '2280C': 14,
  '2280D': 14,
  '2280E': 14,
  '2280F': 14,
  '22810': 14,
  '22811': 14,
  '22812': 14,
  '22813': 14,
  '22814': 14,
  '22815': 14,
  '22816': 14,
  '22817': 14,
  '22818': 14,
  '22819': 14,
  '2281A': 14,
  '2281B': 14,
  '2281C': 14,
  '2281D': 14,
  '2281E': 14,
  '2281F': 14,
  '22820': 14,
  '22821': 14,
  '22822': 14,
  '22823': 14,
  '22824': 14,
  '22825': 14,
  '22826': 14,
  '22827': 14,
  '22828': 14,
  '22829': 14,
  '2282A': 14,
  '2282B': 14,
  '2282C': 14,
  '2282D': 14,
  '2282E': 14,
  '2282F': 14,
  '22830': 14,
  '22831': 15,
  '22832': 15,
  '22833': 15,
  '22834': 15,
  '22835': 15,
  '22836': 15,
  '22837': 15,
  '22838': 15,
  '22839': 15,
  '2283A': 15,
  '2283B': 15,
  '2283C': 15,
  '2283D': 15,
  '2283E': 15,
  '2283F': 15,
  '22840': 15,
  '22841': 15,
  '22842': 15,
  '22843': 15,
  '22844': 15,
  '22845': 15,
  '22846': 15,
  '22847': 15,
  '22848': 15,
  '22849': 15,
  '2284A': 15,
  '2284B': 15,
  '2284C': 15,
  '2284D': 15,
  '2284E': 15,
  '2284F': 15,
  '22850': 15,
  '22851': 15,
  '22852': 15,
  '22853': 15,
  '22854': 15,
  '22855': 15,
  '22856': 15,
  '22857': 15,
  '22858': 15,
  '22859': 15,
  '2285A': 15,
  '2285B': 15,
  '2285C': 15,
  '2285D': 15,
  '2285E': 15,
  '2285F': 15,
  '22860': 15,
  '22861': 15,
  '22862': 15,
  '22863': 15,
  '22864': 15,
  '22865': 15,
  '22866': 15,
  '22867': 15,
  '22868': 15,
  '22869': 15,
  '2286A': 15,
  '2286B': 15,
  '2286C': 15,
  '2286D': 15,
  '2286E': 15,
  '2286F': 15,
  '22870': 15,
  '22871': 15,
  '22872': 15,
  '22873': 15,
  '22874': 15,
  '22875': 15,
  '22876': 15,
  '22877': 15,
  '22878': 15,
  '22879': 15,
  '2287A': 15,
  '2287B': 15,
  '2287C': 15,
  '2287D': 15,
  '2287E': 15,
  '2287F': 15,
  '22880': 15,
  '22881': 15,
  '22882': 15,
  '22883': 15,
  '22884': 15,
  '22885': 15,
  '22886': 15,
  '22887': 15,
  '22888': 15,
  '22889': 15,
  '2288A': 15,
  '2288B': 15,
  '2288C': 15,
  '2288D': 15,
  '2288E': 15,
  '2288F': 15,
  '22890': 15,
  '22891': 15,
  '22892': 16,
  '22893': 16,
  '22894': 16,
  '22895': 16,
  '22896': 16,
  '22897': 16,
  '22898': 16,
  '22899': 16,
  '2289A': 16,
  '2289B': 16,
  '2289C': 16,
  '2289D': 16,
  '2289E': 16,
  '2289F': 16,
  '228A0': 16,
  '228A1': 16,
  '228A2': 16,
  '228A3': 16,
  '228A4': 16,
  '228A5': 16,
  '228A6': 16,
  '228A7': 16,
  '228A8': 16,
  '228A9': 16,
  '228AA': 16,
  '228AB': 16,
  '228AC': 16,
  '228AD': 16,
  '228AE': 16,
  '228AF': 16,
  '228B0': 16,
  '228B1': 16,
  '228B2': 16,
  '228B3': 16,
  '228B4': 16,
  '228B5': 16,
  '228B6': 16,
  '228B7': 16,
  '228B8': 16,
  '228B9': 16,
  '228BA': 16,
  '228BB': 16,
  '228BC': 16,
  '228BD': 16,
  '228BE': 16,
  '228BF': 16,
  '228C0': 16,
  '228C1': 16,
  '228C2': 16,
  '228C3': 16,
  '228C4': 16,
  '228C5': 16,
  '228C6': 16,
  '228C7': 16,
  '228C8': 16,
  '228C9': 16,
  '228CA': 16,
  '228CB': 16,
  '228CC': 16,
  '228CD': 16,
  '228CE': 17,
  '228CF': 17,
  '228D0': 17,
  '228D1': 17,
  '228D2': 17,
  '228D3': 17,
  '228D4': 17,
  '228D5': 17,
  '228D6': 17,
  '228D7': 17,
  '228D8': 17,
  '228D9': 17,
  '228DA': 17,
  '228DB': 17,
  '228DC': 17,
  '228DD': 17,
  '228DE': 17,
  '228DF': 17,
  '228E0': 17,
  '228E1': 17,
  '228E2': 17,
  '228E3': 17,
  '228E4': 17,
  '228E5': 17,
  '228E6': 17,
  '228E7': 17,
  '228E8': 17,
  '228E9': 17,
  '228EA': 17,
  '228EB': 17,
  '228EC': 17,
  '228ED': 17,
  '228EE': 17,
  '228EF': 17,
  '228F0': 17,
  '228F1': 17,
  '228F2': 17,
  '228F3': 17,
  '228F4': 17,
  '228F5': 17,
  '228F6': 17,
  '228F7': 17,
  '228F8': 17,
  '228F9': 17,
  '228FA': 17,
  '228FB': 18,
  '228FC': 18,
  '228FD': 18,
  '228FE': 18,
  '228FF': 18,
  '22900': 18,
  '22901': 18,
  '22902': 18,
  '22903': 18,
  '22904': 18,
  '22905': 18,
  '22906': 18,
  '22907': 18,
  '22908': 18,
  '22909': 18,
  '2290A': 18,
  '2290B': 18,
  '2290C': 18,
  '2290D': 18,
  '2290E': 18,
  '2290F': 18,
  '22910': 18,
  '22911': 18,
  '22912': 18,
  '22913': 18,
  '22914': 18,
  '22915': 18,
  '22916': 18,
  '22917': 18,
  '22918': 18,
  '22919': 18,
  '2291A': 18,
  '2291B': 18,
  '2291C': 18,
  '2291D': 18,
  '2291E': 18,
  '2291F': 18,
  '22920': 18,
  '22921': 18,
  '22922': 18,
  '22923': 18,
  '22924': 18,
  '22925': 18,
  '22926': 19,
  '22927': 19,
  '22928': 19,
  '22929': 19,
  '2292A': 19,
  '2292B': 19,
  '2292C': 19,
  '2292D': 19,
  '2292E': 19,
  '2292F': 19,
  '22930': 19,
  '22931': 19,
  '22932': 19,
  '22933': 19,
  '22934': 19,
  '22935': 19,
  '22936': 19,
  '22937': 19,
  '22938': 19,
  '22939': 19,
  '2293A': 19,
  '2293B': 19,
  '2293C': 19,
  '2293D': 19,
  '2293E': 19,
  '2293F': 19,
  '22940': 19,
  '22941': 19,
  '22942': 19,
  '22943': 19,
  '22944': 19,
  '22945': 19,
  '22946': 19,
  '22947': 19,
  '22948': 19,
  '22949': 19,
  '2294A': 19,
  '2294B': 20,
  '2294C': 20,
  '2294D': 20,
  '2294E': 20,
  '2294F': 20,
  '22950': 20,
  '22951': 20,
  '22952': 20,
  '22953': 20,
  '22954': 20,
  '22955': 20,
  '22956': 20,
  '22957': 21,
  '22958': 21,
  '22959': 21,
  '2295A': 21,
  '2295B': 21,
  '2295C': 21,
  '2295D': 21,
  '2295E': 21,
  '2295F': 21,
  '22960': 21,
  '22961': 21,
  '22962': 21,
  '22963': 21,
  '22964': 21,
  '22965': 22,
  '22966': 22,
  '22967': 22,
  '22968': 22,
  '22969': 22,
  '2296A': 22,
  '2296B': 22,
  '2296C': 22,
  '2296D': 22,
  '2296E': 22,
  '2296F': 23,
  '22970': 23,
  '22971': 23,
  '22972': 23,
  '22973': 23,
  '22974': 23,
  '22975': 23,
  '22976': 23,
  '22977': 23,
  '22978': 23,
  '22979': 23,
  '2297A': 23,
  '2297B': 24,
  '2297C': 24,
  '2297D': 24,
  '2297E': 24,
  '2297F': 24,
  '22980': 24,
  '22981': 24,
  '22982': 24,
  '22983': 26,
  '22984': 26,
  '22985': 27,
  '22986': 27,
  '22987': 27,
  '22988': 28,
  '22989': 28,
  '2298A': 30,
  '2298B': 31,
  '2298C': 5,
  '2298D': 5,
  '2298E': 6,
  '2298F': 6,
  '22990': 6,
  '22991': 6,
  '22992': 7,
  '22993': 7,
  '22994': 7,
  '22995': 7,
  '22996': 7,
  '22997': 7,
  '22998': 7,
  '22999': 7,
  '2299A': 8,
  '2299B': 8,
  '2299C': 8,
  '2299D': 8,
  '2299E': 8,
  '2299F': 8,
  '229A0': 8,
  '229A1': 8,
  '229A2': 8,
  '229A3': 8,
  '229A4': 8,
  '229A5': 8,
  '229A6': 9,
  '229A7': 9,
  '229A8': 9,
  '229A9': 9,
  '229AA': 9,
  '229AB': 9,
  '229AC': 9,
  '229AD': 9,
  '229AE': 9,
  '229AF': 9,
  '229B0': 9,
  '229B1': 9,
  '229B2': 9,
  '229B3': 9,
  '229B4': 9,
  '229B5': 9,
  '229B6': 9,
  '229B7': 10,
  '229B8': 10,
  '229B9': 10,
  '229BA': 10,
  '229BB': 10,
  '229BC': 10,
  '229BD': 10,
  '229BE': 10,
  '229BF': 11,
  '229C0': 11,
  '229C1': 11,
  '229C2': 11,
  '229C3': 11,
  '229C4': 11,
  '229C5': 11,
  '229C6': 11,
  '229C7': 11,
  '229C8': 12,
  '229C9': 12,
  '229CA': 12,
  '229CB': 12,
  '229CC': 12,
  '229CD': 12,
  '229CE': 12,
  '229CF': 12,
  '229D0': 12,
  '229D1': 12,
  '229D2': 12,
  '229D3': 12,
  '229D4': 13,
  '229D5': 13,
  '229D6': 13,
  '229D7': 13,
  '229D8': 13,
  '229D9': 13,
  '229DA': 13,
  '229DB': 13,
  '229DC': 13,
  '229DD': 13,
  '229DE': 13,
  '229DF': 13,
  '229E0': 13,
  '229E1': 13,
  '229E2': 14,
  '229E3': 14,
  '229E4': 14,
  '229E5': 14,
  '229E6': 14,
  '229E7': 14,
  '229E8': 15,
  '229E9': 14,
  '229EA': 14,
  '229EB': 14,
  '229EC': 14,
  '229ED': 14,
  '229EE': 14,
  '229EF': 14,
  '229F0': 14,
  '229F1': 14,
  '229F2': 15,
  '229F3': 15,
  '229F4': 15,
  '229F5': 15,
  '229F6': 15,
  '229F7': 15,
  '229F8': 15,
  '229F9': 15,
  '229FA': 15,
  '229FB': 15,
  '229FC': 15,
  '229FD': 15,
  '229FE': 15,
  '229FF': 15,
  '22A00': 15,
  '22A01': 15,
  '22A02': 15,
  '22A03': 15,
  '22A04': 15,
  '22A05': 15,
  '22A06': 15,
  '22A07': 16,
  '22A08': 16,
  '22A09': 16,
  '22A0A': 16,
  '22A0B': 16,
  '22A0C': 16,
  '22A0D': 16,
  '22A0E': 16,
  '22A0F': 16,
  '22A10': 16,
  '22A11': 16,
  '22A12': 16,
  '22A13': 17,
  '22A14': 17,
  '22A15': 17,
  '22A16': 17,
  '22A17': 17,
  '22A18': 18,
  '22A19': 18,
  '22A1A': 19,
  '22A1B': 19,
  '22A1C': 19,
  '22A1D': 19,
  '22A1E': 20,
  '22A1F': 20,
  '22A20': 20,
  '22A21': 21,
  '22A22': 21,
  '22A23': 21,
  '22A24': 5,
  '22A25': 5,
  '22A26': 6,
  '22A27': 6,
  '22A28': 6,
  '22A29': 6,
  '22A2A': 7,
  '22A2B': 7,
  '22A2C': 7,
  '22A2D': 7,
  '22A2E': 7,
  '22A2F': 8,
  '22A30': 8,
  '22A31': 8,
  '22A32': 8,
  '22A33': 8,
  '22A34': 8,
  '22A35': 8,
  '22A36': 9,
  '22A37': 9,
  '22A38': 9,
  '22A39': 9,
  '22A3A': 9,
  '22A3B': 9,
  '22A3C': 9,
  '22A3D': 9,
  '22A3E': 9,
  '22A3F': 9,
  '22A40': 9,
  '22A41': 9,
  '22A42': 9,
  '22A43': 9,
  '22A44': 9,
  '22A45': 10,
  '22A46': 10,
  '22A47': 10,
  '22A48': 10,
  '22A49': 10,
  '22A4A': 10,
  '22A4B': 10,
  '22A4C': 10,
  '22A4D': 11,
  '22A4E': 11,
  '22A4F': 11,
  '22A50': 11,
  '22A51': 12,
  '22A52': 12,
  '22A53': 12,
  '22A54': 12,
  '22A55': 12,
  '22A56': 13,
  '22A57': 13,
  '22A58': 14,
  '22A59': 14,
  '22A5A': 14,
  '22A5B': 14,
  '22A5C': 15,
  '22A5D': 15,
  '22A5E': 15,
  '22A5F': 16,
  '22A60': 17,
  '22A61': 18,
  '22A62': 21,
  '22A63': 23,
  '22A64': 23,
  '22A65': 4,
  '22A66': 4,
  '22A67': 4,
  '22A68': 5,
  '22A69': 5,
  '22A6A': 5,
  '22A6B': 5,
  '22A6C': 5,
  '22A6D': 6,
  '22A6E': 6,
  '22A6F': 6,
  '22A70': 6,
  '22A71': 6,
  '22A72': 6,
  '22A73': 6,
  '22A74': 6,
  '22A75': 6,
  '22A76': 6,
  '22A77': 6,
  '22A78': 6,
  '22A79': 6,
  '22A7A': 6,
  '22A7B': 6,
  '22A7C': 6,
  '22A7D': 6,
  '22A7E': 6,
  '22A7F': 6,
  '22A80': 6,
  '22A81': 6,
  '22A82': 6,
  '22A83': 7,
  '22A84': 7,
  '22A85': 7,
  '22A86': 7,
  '22A87': 7,
  '22A88': 7,
  '22A89': 7,
  '22A8A': 7,
  '22A8B': 7,
  '22A8C': 7,
  '22A8D': 7,
  '22A8E': 7,
  '22A8F': 7,
  '22A90': 7,
  '22A91': 7,
  '22A92': 7,
  '22A93': 7,
  '22A94': 7,
  '22A95': 7,
  '22A96': 7,
  '22A97': 7,
  '22A98': 7,
  '22A99': 7,
  '22A9A': 7,
  '22A9B': 7,
  '22A9C': 7,
  '22A9D': 7,
  '22A9E': 7,
  '22A9F': 7,
  '22AA0': 7,
  '22AA1': 7,
  '22AA2': 7,
  '22AA3': 7,
  '22AA4': 7,
  '22AA5': 7,
  '22AA6': 7,
  '22AA7': 7,
  '22AA8': 7,
  '22AA9': 7,
  '22AAA': 7,
  '22AAB': 7,
  '22AAC': 7,
  '22AAD': 7,
  '22AAE': 7,
  '22AAF': 7,
  '22AB0': 7,
  '22AB1': 7,
  '22AB2': 7,
  '22AB3': 7,
  '22AB4': 8,
  '22AB5': 8,
  '22AB6': 8,
  '22AB7': 8,
  '22AB8': 8,
  '22AB9': 8,
  '22ABA': 8,
  '22ABB': 8,
  '22ABC': 8,
  '22ABD': 8,
  '22ABE': 8,
  '22ABF': 8,
  '22AC0': 8,
  '22AC1': 8,
  '22AC2': 8,
  '22AC3': 8,
  '22AC4': 8,
  '22AC5': 8,
  '22AC6': 8,
  '22AC7': 8,
  '22AC8': 8,
  '22AC9': 8,
  '22ACA': 8,
  '22ACB': 8,
  '22ACC': 8,
  '22ACD': 8,
  '22ACE': 8,
  '22ACF': 8,
  '22AD0': 8,
  '22AD1': 8,
  '22AD2': 8,
  '22AD3': 8,
  '22AD4': 8,
  '22AD5': 8,
  '22AD6': 8,
  '22AD7': 8,
  '22AD8': 8,
  '22AD9': 8,
  '22ADA': 8,
  '22ADB': 8,
  '22ADC': 8,
  '22ADD': 8,
  '22ADE': 8,
  '22ADF': 8,
  '22AE0': 8,
  '22AE1': 8,
  '22AE2': 8,
  '22AE3': 8,
  '22AE4': 9,
  '22AE5': 9,
  '22AE6': 9,
  '22AE7': 9,
  '22AE8': 9,
  '22AE9': 9,
  '22AEA': 9,
  '22AEB': 9,
  '22AEC': 9,
  '22AED': 9,
  '22AEE': 9,
  '22AEF': 9,
  '22AF0': 9,
  '22AF1': 9,
  '22AF2': 9,
  '22AF3': 9,
  '22AF4': 9,
  '22AF5': 9,
  '22AF6': 9,
  '22AF7': 9,
  '22AF8': 9,
  '22AF9': 9,
  '22AFA': 9,
  '22AFB': 9,
  '22AFC': 9,
  '22AFD': 9,
  '22AFE': 9,
  '22AFF': 9,
  '22B00': 9,
  '22B01': 9,
  '22B02': 9,
  '22B03': 9,
  '22B04': 9,
  '22B05': 9,
  '22B06': 9,
  '22B07': 9,
  '22B08': 9,
  '22B09': 9,
  '22B0A': 9,
  '22B0B': 9,
  '22B0C': 9,
  '22B0D': 9,
  '22B0E': 9,
  '22B0F': 9,
  '22B10': 9,
  '22B11': 9,
  '22B12': 9,
  '22B13': 9,
  '22B14': 9,
  '22B15': 9,
  '22B16': 9,
  '22B17': 9,
  '22B18': 9,
  '22B19': 9,
  '22B1A': 9,
  '22B1B': 9,
  '22B1C': 9,
  '22B1D': 9,
  '22B1E': 9,
  '22B1F': 9,
  '22B20': 9,
  '22B21': 9,
  '22B22': 9,
  '22B23': 9,
  '22B24': 9,
  '22B25': 9,
  '22B26': 9,
  '22B27': 9,
  '22B28': 9,
  '22B29': 9,
  '22B2A': 9,
  '22B2B': 9,
  '22B2C': 9,
  '22B2D': 9,
  '22B2E': 9,
  '22B2F': 10,
  '22B30': 10,
  '22B31': 10,
  '22B32': 10,
  '22B33': 10,
  '22B34': 10,
  '22B35': 10,
  '22B36': 10,
  '22B37': 10,
  '22B38': 10,
  '22B39': 10,
  '22B3A': 10,
  '22B3B': 10,
  '22B3C': 10,
  '22B3D': 10,
  '22B3E': 10,
  '22B3F': 10,
  '22B40': 10,
  '22B41': 10,
  '22B42': 10,
  '22B43': 10,
  '22B44': 10,
  '22B45': 10,
  '22B46': 10,
  '22B47': 10,
  '22B48': 10,
  '22B49': 10,
  '22B4A': 10,
  '22B4B': 10,
  '22B4C': 10,
  '22B4D': 10,
  '22B4E': 10,
  '22B4F': 10,
  '22B50': 10,
  '22B51': 10,
  '22B52': 10,
  '22B53': 10,
  '22B54': 10,
  '22B55': 10,
  '22B56': 10,
  '22B57': 10,
  '22B58': 10,
  '22B59': 10,
  '22B5A': 10,
  '22B5B': 10,
  '22B5C': 10,
  '22B5D': 10,
  '22B5E': 10,
  '22B5F': 10,
  '22B60': 10,
  '22B61': 10,
  '22B62': 10,
  '22B63': 10,
  '22B64': 10,
  '22B65': 10,
  '22B66': 10,
  '22B67': 10,
  '22B68': 10,
  '22B69': 10,
  '22B6A': 10,
  '22B6B': 10,
  '22B6C': 10,
  '22B6D': 10,
  '22B6E': 10,
  '22B6F': 10,
  '22B70': 10,
  '22B71': 10,
  '22B72': 10,
  '22B73': 10,
  '22B74': 10,
  '22B75': 10,
  '22B76': 10,
  '22B77': 10,
  '22B78': 10,
  '22B79': 10,
  '22B7A': 10,
  '22B7B': 10,
  '22B7C': 10,
  '22B7D': 10,
  '22B7E': 10,
  '22B7F': 10,
  '22B80': 10,
  '22B81': 11,
  '22B82': 11,
  '22B83': 11,
  '22B84': 11,
  '22B85': 11,
  '22B86': 11,
  '22B87': 11,
  '22B88': 11,
  '22B89': 11,
  '22B8A': 11,
  '22B8B': 11,
  '22B8C': 11,
  '22B8D': 11,
  '22B8E': 11,
  '22B8F': 11,
  '22B90': 11,
  '22B91': 11,
  '22B92': 11,
  '22B93': 11,
  '22B94': 11,
  '22B95': 11,
  '22B96': 11,
  '22B97': 11,
  '22B98': 11,
  '22B99': 11,
  '22B9A': 11,
  '22B9B': 11,
  '22B9C': 11,
  '22B9D': 11,
  '22B9E': 11,
  '22B9F': 11,
  '22BA0': 11,
  '22BA1': 11,
  '22BA2': 11,
  '22BA3': 11,
  '22BA4': 11,
  '22BA5': 11,
  '22BA6': 11,
  '22BA7': 11,
  '22BA8': 11,
  '22BA9': 11,
  '22BAA': 11,
  '22BAB': 11,
  '22BAC': 11,
  '22BAD': 11,
  '22BAE': 11,
  '22BAF': 11,
  '22BB0': 11,
  '22BB1': 11,
  '22BB2': 11,
  '22BB3': 11,
  '22BB4': 11,
  '22BB5': 11,
  '22BB6': 11,
  '22BB7': 11,
  '22BB8': 11,
  '22BB9': 11,
  '22BBA': 11,
  '22BBB': 11,
  '22BBC': 11,
  '22BBD': 11,
  '22BBE': 11,
  '22BBF': 11,
  '22BC0': 11,
  '22BC1': 11,
  '22BC2': 11,
  '22BC3': 11,
  '22BC4': 11,
  '22BC5': 11,
  '22BC6': 11,
  '22BC7': 11,
  '22BC8': 11,
  '22BC9': 11,
  '22BCA': 11,
  '22BCB': 11,
  '22BCC': 11,
  '22BCD': 11,
  '22BCE': 11,
  '22BCF': 11,
  '22BD0': 11,
  '22BD1': 11,
  '22BD2': 11,
  '22BD3': 11,
  '22BD4': 11,
  '22BD5': 11,
  '22BD6': 11,
  '22BD7': 11,
  '22BD8': 11,
  '22BD9': 11,
  '22BDA': 11,
  '22BDB': 11,
  '22BDC': 11,
  '22BDD': 11,
  '22BDE': 11,
  '22BDF': 11,
  '22BE0': 11,
  '22BE1': 11,
  '22BE2': 11,
  '22BE3': 11,
  '22BE4': 11,
  '22BE5': 11,
  '22BE6': 11,
  '22BE7': 11,
  '22BE8': 11,
  '22BE9': 12,
  '22BEA': 12,
  '22BEB': 12,
  '22BEC': 12,
  '22BED': 12,
  '22BEE': 12,
  '22BEF': 12,
  '22BF0': 12,
  '22BF1': 12,
  '22BF2': 12,
  '22BF3': 12,
  '22BF4': 12,
  '22BF5': 12,
  '22BF6': 12,
  '22BF7': 12,
  '22BF8': 12,
  '22BF9': 12,
  '22BFA': 12,
  '22BFB': 12,
  '22BFC': 12,
  '22BFD': 12,
  '22BFE': 12,
  '22BFF': 12,
  '22C00': 12,
  '22C01': 12,
  '22C02': 12,
  '22C03': 12,
  '22C04': 12,
  '22C05': 12,
  '22C06': 12,
  '22C07': 12,
  '22C08': 12,
  '22C09': 12,
  '22C0A': 12,
  '22C0B': 12,
  '22C0C': 12,
  '22C0D': 12,
  '22C0E': 12,
  '22C0F': 12,
  '22C10': 12,
  '22C11': 12,
  '22C12': 12,
  '22C13': 12,
  '22C14': 12,
  '22C15': 12,
  '22C16': 12,
  '22C17': 12,
  '22C18': 12,
  '22C19': 12,
  '22C1A': 12,
  '22C1B': 12,
  '22C1C': 12,
  '22C1D': 12,
  '22C1E': 12,
  '22C1F': 12,
  '22C20': 12,
  '22C21': 12,
  '22C22': 12,
  '22C23': 12,
  '22C24': 12,
  '22C25': 12,
  '22C26': 12,
  '22C27': 12,
  '22C28': 12,
  '22C29': 12,
  '22C2A': 12,
  '22C2B': 12,
  '22C2C': 12,
  '22C2D': 12,
  '22C2E': 12,
  '22C2F': 12,
  '22C30': 12,
  '22C31': 12,
  '22C32': 12,
  '22C33': 12,
  '22C34': 12,
  '22C35': 12,
  '22C36': 12,
  '22C37': 12,
  '22C38': 12,
  '22C39': 12,
  '22C3A': 12,
  '22C3B': 12,
  '22C3C': 12,
  '22C3D': 12,
  '22C3E': 12,
  '22C3F': 13,
  '22C40': 12,
  '22C41': 12,
  '22C42': 12,
  '22C43': 12,
  '22C44': 12,
  '22C45': 12,
  '22C46': 12,
  '22C47': 12,
  '22C48': 12,
  '22C49': 12,
  '22C4A': 12,
  '22C4B': 12,
  '22C4C': 12,
  '22C4D': 12,
  '22C4E': 12,
  '22C4F': 12,
  '22C50': 12,
  '22C51': 12,
  '22C52': 12,
  '22C53': 12,
  '22C54': 12,
  '22C55': 12,
  '22C56': 12,
  '22C57': 12,
  '22C58': 12,
  '22C59': 12,
  '22C5A': 12,
  '22C5B': 12,
  '22C5C': 12,
  '22C5D': 12,
  '22C5E': 12,
  '22C5F': 13,
  '22C60': 13,
  '22C61': 13,
  '22C62': 13,
  '22C63': 13,
  '22C64': 13,
  '22C65': 13,
  '22C66': 13,
  '22C67': 13,
  '22C68': 13,
  '22C69': 13,
  '22C6A': 13,
  '22C6B': 13,
  '22C6C': 13,
  '22C6D': 13,
  '22C6E': 13,
  '22C6F': 13,
  '22C70': 13,
  '22C71': 13,
  '22C72': 13,
  '22C73': 13,
  '22C74': 13,
  '22C75': 13,
  '22C76': 13,
  '22C77': 13,
  '22C78': 13,
  '22C79': 13,
  '22C7A': 13,
  '22C7B': 13,
  '22C7C': 13,
  '22C7D': 13,
  '22C7E': 13,
  '22C7F': 13,
  '22C80': 13,
  '22C81': 13,
  '22C82': 13,
  '22C83': 13,
  '22C84': 13,
  '22C85': 13,
  '22C86': 13,
  '22C87': 13,
  '22C88': 13,
  '22C89': 13,
  '22C8A': 13,
  '22C8B': 13,
  '22C8C': 13,
  '22C8D': 13,
  '22C8E': 13,
  '22C8F': 13,
  '22C90': 13,
  '22C91': 13,
  '22C92': 13,
  '22C93': 13,
  '22C94': 13,
  '22C95': 13,
  '22C96': 13,
  '22C97': 13,
  '22C98': 13,
  '22C99': 13,
  '22C9A': 13,
  '22C9B': 13,
  '22C9C': 13,
  '22C9D': 13,
  '22C9E': 13,
  '22C9F': 13,
  '22CA0': 13,
  '22CA1': 13,
  '22CA2': 13,
  '22CA3': 13,
  '22CA4': 13,
  '22CA5': 13,
  '22CA6': 13,
  '22CA7': 13,
  '22CA8': 13,
  '22CA9': 13,
  '22CAA': 13,
  '22CAB': 13,
  '22CAC': 13,
  '22CAD': 13,
  '22CAE': 13,
  '22CAF': 13,
  '22CB0': 13,
  '22CB1': 13,
  '22CB2': 13,
  '22CB3': 14,
  '22CB4': 14,
  '22CB5': 14,
  '22CB6': 14,
  '22CB7': 14,
  '22CB8': 14,
  '22CB9': 14,
  '22CBA': 14,
  '22CBB': 14,
  '22CBC': 14,
  '22CBD': 14,
  '22CBE': 14,
  '22CBF': 14,
  '22CC0': 14,
  '22CC1': 14,
  '22CC2': 14,
  '22CC3': 14,
  '22CC4': 14,
  '22CC5': 14,
  '22CC6': 14,
  '22CC7': 14,
  '22CC8': 14,
  '22CC9': 14,
  '22CCA': 14,
  '22CCB': 14,
  '22CCC': 14,
  '22CCD': 14,
  '22CCE': 14,
  '22CCF': 14,
  '22CD0': 14,
  '22CD1': 14,
  '22CD2': 14,
  '22CD3': 14,
  '22CD4': 14,
  '22CD5': 14,
  '22CD6': 14,
  '22CD7': 14,
  '22CD8': 14,
  '22CD9': 14,
  '22CDA': 14,
  '22CDB': 14,
  '22CDC': 14,
  '22CDD': 14,
  '22CDE': 14,
  '22CDF': 14,
  '22CE0': 14,
  '22CE1': 14,
  '22CE2': 14,
  '22CE3': 14,
  '22CE4': 14,
  '22CE5': 14,
  '22CE6': 14,
  '22CE7': 14,
  '22CE8': 14,
  '22CE9': 14,
  '22CEA': 14,
  '22CEB': 14,
  '22CEC': 14,
  '22CED': 14,
  '22CEE': 14,
  '22CEF': 14,
  '22CF0': 14,
  '22CF1': 14,
  '22CF2': 14,
  '22CF3': 14,
  '22CF4': 14,
  '22CF5': 14,
  '22CF6': 14,
  '22CF7': 14,
  '22CF8': 14,
  '22CF9': 14,
  '22CFA': 14,
  '22CFB': 14,
  '22CFC': 14,
  '22CFD': 14,
  '22CFE': 14,
  '22CFF': 14,
  '22D00': 14,
  '22D01': 14,
  '22D02': 14,
  '22D03': 14,
  '22D04': 14,
  '22D05': 14,
  '22D06': 14,
  '22D07': 14,
  '22D08': 14,
  '22D09': 14,
  '22D0A': 14,
  '22D0B': 14,
  '22D0C': 14,
  '22D0D': 14,
  '22D0E': 14,
  '22D0F': 14,
  '22D10': 14,
  '22D11': 14,
  '22D12': 14,
  '22D13': 14,
  '22D14': 14,
  '22D15': 14,
  '22D16': 14,
  '22D17': 14,
  '22D18': 14,
  '22D19': 14,
  '22D1A': 14,
  '22D1B': 14,
  '22D1C': 14,
  '22D1D': 14,
  '22D1E': 14,
  '22D1F': 14,
  '22D20': 15,
  '22D21': 15,
  '22D22': 15,
  '22D23': 15,
  '22D24': 15,
  '22D25': 15,
  '22D26': 15,
  '22D27': 15,
  '22D28': 15,
  '22D29': 15,
  '22D2A': 15,
  '22D2B': 15,
  '22D2C': 15,
  '22D2D': 15,
  '22D2E': 15,
  '22D2F': 15,
  '22D30': 15,
  '22D31': 15,
  '22D32': 15,
  '22D33': 15,
  '22D34': 15,
  '22D35': 15,
  '22D36': 15,
  '22D37': 15,
  '22D38': 15,
  '22D39': 15,
  '22D3A': 15,
  '22D3B': 15,
  '22D3C': 15,
  '22D3D': 15,
  '22D3E': 15,
  '22D3F': 15,
  '22D40': 15,
  '22D41': 15,
  '22D42': 15,
  '22D43': 15,
  '22D44': 15,
  '22D45': 15,
  '22D46': 15,
  '22D47': 15,
  '22D48': 15,
  '22D49': 15,
  '22D4A': 15,
  '22D4B': 15,
  '22D4C': 15,
  '22D4D': 15,
  '22D4E': 15,
  '22D4F': 15,
  '22D50': 15,
  '22D51': 15,
  '22D52': 15,
  '22D53': 15,
  '22D54': 15,
  '22D55': 15,
  '22D56': 15,
  '22D57': 15,
  '22D58': 15,
  '22D59': 15,
  '22D5A': 15,
  '22D5B': 15,
  '22D5C': 15,
  '22D5D': 15,
  '22D5E': 15,
  '22D5F': 15,
  '22D60': 15,
  '22D61': 15,
  '22D62': 15,
  '22D63': 15,
  '22D64': 15,
  '22D65': 15,
  '22D66': 15,
  '22D67': 15,
  '22D68': 15,
  '22D69': 15,
  '22D6A': 15,
  '22D6B': 15,
  '22D6C': 15,
  '22D6D': 15,
  '22D6E': 15,
  '22D6F': 15,
  '22D70': 15,
  '22D71': 15,
  '22D72': 15,
  '22D73': 15,
  '22D74': 15,
  '22D75': 15,
  '22D76': 15,
  '22D77': 15,
  '22D78': 15,
  '22D79': 15,
  '22D7A': 15,
  '22D7B': 15,
  '22D7C': 15,
  '22D7D': 15,
  '22D7E': 15,
  '22D7F': 16,
  '22D80': 16,
  '22D81': 16,
  '22D82': 16,
  '22D83': 16,
  '22D84': 16,
  '22D85': 16,
  '22D86': 16,
  '22D87': 16,
  '22D88': 16,
  '22D89': 16,
  '22D8A': 16,
  '22D8B': 16,
  '22D8C': 16,
  '22D8D': 16,
  '22D8E': 16,
  '22D8F': 16,
  '22D90': 16,
  '22D91': 16,
  '22D92': 16,
  '22D93': 16,
  '22D94': 16,
  '22D95': 16,
  '22D96': 16,
  '22D97': 16,
  '22D98': 16,
  '22D99': 16,
  '22D9A': 16,
  '22D9B': 16,
  '22D9C': 16,
  '22D9D': 16,
  '22D9E': 16,
  '22D9F': 16,
  '22DA0': 16,
  '22DA1': 16,
  '22DA2': 16,
  '22DA3': 16,
  '22DA4': 16,
  '22DA5': 16,
  '22DA6': 16,
  '22DA7': 16,
  '22DA8': 16,
  '22DA9': 16,
  '22DAA': 16,
  '22DAB': 16,
  '22DAC': 16,
  '22DAD': 16,
  '22DAE': 16,
  '22DAF': 16,
  '22DB0': 16,
  '22DB1': 16,
  '22DB2': 16,
  '22DB3': 16,
  '22DB4': 16,
  '22DB5': 16,
  '22DB6': 16,
  '22DB7': 16,
  '22DB8': 16,
  '22DB9': 16,
  '22DBA': 16,
  '22DBB': 16,
  '22DBC': 16,
  '22DBD': 16,
  '22DBE': 16,
  '22DBF': 16,
  '22DC0': 16,
  '22DC1': 16,
  '22DC2': 16,
  '22DC3': 16,
  '22DC4': 16,
  '22DC5': 15,
  '22DC6': 16,
  '22DC7': 16,
  '22DC8': 16,
  '22DC9': 16,
  '22DCA': 16,
  '22DCB': 17,
  '22DCC': 17,
  '22DCD': 17,
  '22DCE': 17,
  '22DCF': 17,
  '22DD0': 17,
  '22DD1': 17,
  '22DD2': 17,
  '22DD3': 17,
  '22DD4': 17,
  '22DD5': 17,
  '22DD6': 17,
  '22DD7': 17,
  '22DD8': 17,
  '22DD9': 17,
  '22DDA': 17,
  '22DDB': 17,
  '22DDC': 17,
  '22DDD': 17,
  '22DDE': 17,
  '22DDF': 17,
  '22DE0': 17,
  '22DE1': 17,
  '22DE2': 17,
  '22DE3': 17,
  '22DE4': 17,
  '22DE5': 17,
  '22DE6': 17,
  '22DE7': 17,
  '22DE8': 17,
  '22DE9': 17,
  '22DEA': 17,
  '22DEB': 17,
  '22DEC': 17,
  '22DED': 17,
  '22DEE': 17,
  '22DEF': 17,
  '22DF0': 17,
  '22DF1': 17,
  '22DF2': 17,
  '22DF3': 17,
  '22DF4': 17,
  '22DF5': 17,
  '22DF6': 18,
  '22DF7': 18,
  '22DF8': 18,
  '22DF9': 18,
  '22DFA': 18,
  '22DFB': 18,
  '22DFC': 18,
  '22DFD': 18,
  '22DFE': 18,
  '22DFF': 18,
  '22E00': 18,
  '22E01': 18,
  '22E02': 18,
  '22E03': 18,
  '22E04': 18,
  '22E05': 18,
  '22E06': 18,
  '22E07': 18,
  '22E08': 18,
  '22E09': 18,
  '22E0A': 18,
  '22E0B': 18,
  '22E0C': 18,
  '22E0D': 18,
  '22E0E': 18,
  '22E0F': 18,
  '22E10': 18,
  '22E11': 18,
  '22E12': 18,
  '22E13': 18,
  '22E14': 18,
  '22E15': 18,
  '22E16': 18,
  '22E17': 18,
  '22E18': 18,
  '22E19': 18,
  '22E1A': 18,
  '22E1B': 18,
  '22E1C': 18,
  '22E1D': 18,
  '22E1E': 18,
  '22E1F': 18,
  '22E20': 18,
  '22E21': 18,
  '22E22': 18,
  '22E23': 19,
  '22E24': 19,
  '22E25': 19,
  '22E26': 19,
  '22E27': 19,
  '22E28': 19,
  '22E29': 19,
  '22E2A': 19,
  '22E2B': 19,
  '22E2C': 19,
  '22E2D': 19,
  '22E2E': 19,
  '22E2F': 19,
  '22E30': 19,
  '22E31': 19,
  '22E32': 19,
  '22E33': 19,
  '22E34': 19,
  '22E35': 19,
  '22E36': 19,
  '22E37': 19,
  '22E38': 19,
  '22E39': 19,
  '22E3A': 19,
  '22E3B': 19,
  '22E3C': 19,
  '22E3D': 19,
  '22E3E': 19,
  '22E3F': 19,
  '22E40': 19,
  '22E41': 19,
  '22E42': 19,
  '22E43': 19,
  '22E44': 19,
  '22E45': 19,
  '22E46': 19,
  '22E47': 19,
  '22E48': 19,
  '22E49': 19,
  '22E4A': 19,
  '22E4B': 19,
  '22E4C': 19,
  '22E4D': 20,
  '22E4E': 20,
  '22E4F': 20,
  '22E50': 20,
  '22E51': 20,
  '22E52': 20,
  '22E53': 20,
  '22E54': 20,
  '22E55': 20,
  '22E56': 20,
  '22E57': 20,
  '22E58': 20,
  '22E59': 20,
  '22E5A': 20,
  '22E5B': 20,
  '22E5C': 20,
  '22E5D': 20,
  '22E5E': 20,
  '22E5F': 20,
  '22E60': 20,
  '22E61': 20,
  '22E62': 21,
  '22E63': 20,
  '22E64': 20,
  '22E65': 20,
  '22E66': 20,
  '22E67': 20,
  '22E68': 20,
  '22E69': 20,
  '22E6A': 20,
  '22E6B': 20,
  '22E6C': 21,
  '22E6D': 21,
  '22E6E': 21,
  '22E6F': 21,
  '22E70': 21,
  '22E71': 21,
  '22E72': 21,
  '22E73': 21,
  '22E74': 21,
  '22E75': 21,
  '22E76': 21,
  '22E77': 21,
  '22E78': 21,
  '22E79': 21,
  '22E7A': 21,
  '22E7B': 21,
  '22E7C': 21,
  '22E7D': 21,
  '22E7E': 21,
  '22E7F': 21,
  '22E80': 21,
  '22E81': 21,
  '22E82': 22,
  '22E83': 22,
  '22E84': 22,
  '22E85': 22,
  '22E86': 22,
  '22E87': 22,
  '22E88': 22,
  '22E89': 22,
  '22E8A': 22,
  '22E8B': 22,
  '22E8C': 22,
  '22E8D': 22,
  '22E8E': 22,
  '22E8F': 22,
  '22E90': 22,
  '22E91': 22,
  '22E92': 22,
  '22E93': 22,
  '22E94': 22,
  '22E95': 22,
  '22E96': 23,
  '22E97': 23,
  '22E98': 23,
  '22E99': 23,
  '22E9A': 23,
  '22E9B': 23,
  '22E9C': 23,
  '22E9D': 23,
  '22E9E': 24,
  '22E9F': 24,
  '22EA0': 24,
  '22EA1': 24,
  '22EA2': 24,
  '22EA3': 24,
  '22EA4': 24,
  '22EA5': 24,
  '22EA6': 24,
  '22EA7': 24,
  '22EA8': 24,
  '22EA9': 24,
  '22EAA': 24,
  '22EAB': 24,
  '22EAC': 25,
  '22EAD': 25,
  '22EAE': 25,
  '22EAF': 25,
  '22EB0': 27,
  '22EB1': 27,
  '22EB2': 27,
  '22EB3': 28,
  '22EB4': 32,
  '22EB5': 6,
  '22EB6': 7,
  '22EB7': 8,
  '22EB8': 8,
  '22EB9': 8,
  '22EBA': 8,
  '22EBB': 8,
  '22EBC': 8,
  '22EBD': 8,
  '22EBE': 9,
  '22EBF': 9,
  '22EC0': 9,
  '22EC1': 10,
  '22EC2': 10,
  '22EC3': 10,
  '22EC4': 10,
  '22EC5': 10,
  '22EC6': 10,
  '22EC7': 10,
  '22EC8': 10,
  '22EC9': 10,
  '22ECA': 11,
  '22ECB': 11,
  '22ECC': 11,
  '22ECD': 11,
  '22ECE': 12,
  '22ECF': 12,
  '22ED0': 12,
  '22ED1': 12,
  '22ED2': 12,
  '22ED3': 12,
  '22ED4': 12,
  '22ED5': 13,
  '22ED6': 13,
  '22ED7': 13,
  '22ED8': 13,
  '22ED9': 13,
  '22EDA': 14,
  '22EDB': 14,
  '22EDC': 14,
  '22EDD': 15,
  '22EDE': 15,
  '22EDF': 15,
  '22EE0': 16,
  '22EE1': 16,
  '22EE2': 16,
  '22EE3': 16,
  '22EE4': 16,
  '22EE5': 17,
  '22EE6': 17,
  '22EE7': 17,
  '22EE8': 17,
  '22EE9': 17,
  '22EEA': 20,
  '22EEB': 7,
  '22EEC': 7,
  '22EED': 7,
  '22EEE': 7,
  '22EEF': 7,
  '22EF0': 7,
  '22EF1': 7,
  '22EF2': 7,
  '22EF3': 8,
  '22EF4': 8,
  '22EF5': 8,
  '22EF6': 8,
  '22EF7': 8,
  '22EF8': 8,
  '22EF9': 8,
  '22EFA': 8,
  '22EFB': 8,
  '22EFC': 8,
  '22EFD': 8,
  '22EFE': 8,
  '22EFF': 8,
  '22F00': 8,
  '22F01': 8,
  '22F02': 8,
  '22F03': 8,
  '22F04': 8,
  '22F05': 8,
  '22F06': 8,
  '22F07': 8,
  '22F08': 8,
  '22F09': 9,
  '22F0A': 9,
  '22F0B': 9,
  '22F0C': 9,
  '22F0D': 9,
  '22F0E': 9,
  '22F0F': 9,
  '22F10': 9,
  '22F11': 9,
  '22F12': 9,
  '22F13': 9,
  '22F14': 9,
  '22F15': 9,
  '22F16': 9,
  '22F17': 9,
  '22F18': 9,
  '22F19': 9,
  '22F1A': 9,
  '22F1B': 10,
  '22F1C': 10,
  '22F1D': 10,
  '22F1E': 10,
  '22F1F': 10,
  '22F20': 10,
  '22F21': 10,
  '22F22': 10,
  '22F23': 10,
  '22F24': 10,
  '22F25': 10,
  '22F26': 10,
  '22F27': 10,
  '22F28': 10,
  '22F29': 10,
  '22F2A': 10,
  '22F2B': 10,
  '22F2C': 10,
  '22F2D': 10,
  '22F2E': 10,
  '22F2F': 10,
  '22F30': 10,
  '22F31': 10,
  '22F32': 10,
  '22F33': 10,
  '22F34': 10,
  '22F35': 10,
  '22F36': 10,
  '22F37': 10,
  '22F38': 10,
  '22F39': 11,
  '22F3A': 11,
  '22F3B': 11,
  '22F3C': 11,
  '22F3D': 11,
  '22F3E': 11,
  '22F3F': 11,
  '22F40': 11,
  '22F41': 11,
  '22F42': 11,
  '22F43': 11,
  '22F44': 11,
  '22F45': 11,
  '22F46': 11,
  '22F47': 11,
  '22F48': 11,
  '22F49': 11,
  '22F4A': 11,
  '22F4B': 11,
  '22F4C': 11,
  '22F4D': 11,
  '22F4E': 11,
  '22F4F': 11,
  '22F50': 11,
  '22F51': 11,
  '22F52': 11,
  '22F53': 11,
  '22F54': 11,
  '22F55': 11,
  '22F56': 11,
  '22F57': 11,
  '22F58': 11,
  '22F59': 11,
  '22F5A': 12,
  '22F5B': 12,
  '22F5C': 11,
  '22F5D': 12,
  '22F5E': 12,
  '22F5F': 12,
  '22F60': 12,
  '22F61': 12,
  '22F62': 12,
  '22F63': 12,
  '22F64': 12,
  '22F65': 12,
  '22F66': 12,
  '22F67': 12,
  '22F68': 12,
  '22F69': 12,
  '22F6A': 12,
  '22F6B': 12,
  '22F6C': 12,
  '22F6D': 12,
  '22F6E': 12,
  '22F6F': 12,
  '22F70': 12,
  '22F71': 12,
  '22F72': 12,
  '22F73': 12,
  '22F74': 12,
  '22F75': 12,
  '22F76': 12,
  '22F77': 11,
  '22F78': 12,
  '22F79': 12,
  '22F7A': 12,
  '22F7B': 12,
  '22F7C': 12,
  '22F7D': 12,
  '22F7E': 12,
  '22F7F': 13,
  '22F80': 13,
  '22F81': 13,
  '22F82': 13,
  '22F83': 13,
  '22F84': 13,
  '22F85': 13,
  '22F86': 13,
  '22F87': 13,
  '22F88': 13,
  '22F89': 13,
  '22F8A': 13,
  '22F8B': 13,
  '22F8C': 13,
  '22F8D': 13,
  '22F8E': 13,
  '22F8F': 13,
  '22F90': 13,
  '22F91': 13,
  '22F92': 13,
  '22F93': 13,
  '22F94': 13,
  '22F95': 13,
  '22F96': 13,
  '22F97': 13,
  '22F98': 13,
  '22F99': 13,
  '22F9A': 13,
  '22F9B': 13,
  '22F9C': 13,
  '22F9D': 13,
  '22F9E': 13,
  '22F9F': 13,
  '22FA0': 13,
  '22FA1': 13,
  '22FA2': 13,
  '22FA3': 12,
  '22FA4': 13,
  '22FA5': 13,
  '22FA6': 14,
  '22FA7': 14,
  '22FA8': 14,
  '22FA9': 14,
  '22FAA': 14,
  '22FAB': 14,
  '22FAC': 14,
  '22FAD': 14,
  '22FAE': 14,
  '22FAF': 14,
  '22FB0': 14,
  '22FB1': 14,
  '22FB2': 14,
  '22FB3': 14,
  '22FB4': 14,
  '22FB5': 14,
  '22FB6': 14,
  '22FB7': 14,
  '22FB8': 14,
  '22FB9': 14,
  '22FBA': 14,
  '22FBB': 14,
  '22FBC': 14,
  '22FBD': 14,
  '22FBE': 14,
  '22FBF': 14,
  '22FC0': 14,
  '22FC1': 14,
  '22FC2': 14,
  '22FC3': 14,
  '22FC4': 14,
  '22FC5': 14,
  '22FC6': 13,
  '22FC7': 15,
  '22FC8': 15,
  '22FC9': 15,
  '22FCA': 15,
  '22FCB': 15,
  '22FCC': 15,
  '22FCD': 15,
  '22FCE': 15,
  '22FCF': 15,
  '22FD0': 15,
  '22FD1': 15,
  '22FD2': 15,
  '22FD3': 15,
  '22FD4': 15,
  '22FD5': 15,
  '22FD6': 15,
  '22FD7': 15,
  '22FD8': 15,
  '22FD9': 15,
  '22FDA': 15,
  '22FDB': 15,
  '22FDC': 15,
  '22FDD': 15,
  '22FDE': 16,
  '22FDF': 16,
  '22FE0': 16,
  '22FE1': 16,
  '22FE2': 16,
  '22FE3': 16,
  '22FE4': 16,
  '22FE5': 16,
  '22FE6': 16,
  '22FE7': 16,
  '22FE8': 16,
  '22FE9': 16,
  '22FEA': 16,
  '22FEB': 16,
  '22FEC': 16,
  '22FED': 16,
  '22FEE': 16,
  '22FEF': 16,
  '22FF0': 16,
  '22FF1': 16,
  '22FF2': 16,
  '22FF3': 16,
  '22FF4': 16,
  '22FF5': 16,
  '22FF6': 16,
  '22FF7': 16,
  '22FF8': 16,
  '22FF9': 16,
  '22FFA': 16,
  '22FFB': 16,
  '22FFC': 16,
  '22FFD': 16,
  '22FFE': 17,
  '22FFF': 17,
  '23000': 17,
  '23001': 17,
  '23002': 17,
  '23003': 17,
  '23004': 18,
  '23005': 17,
  '23006': 17,
  '23007': 17,
  '23008': 17,
  '23009': 17,
  '2300A': 17,
  '2300B': 17,
  '2300C': 17,
  '2300D': 17,
  '2300E': 17,
  '2300F': 17,
  '23010': 18,
  '23011': 18,
  '23012': 18,
  '23013': 18,
  '23014': 18,
  '23015': 18,
  '23016': 18,
  '23017': 18,
  '23018': 18,
  '23019': 18,
  '2301A': 18,
  '2301B': 19,
  '2301C': 19,
  '2301D': 19,
  '2301E': 19,
  '2301F': 19,
  '23020': 19,
  '23021': 19,
  '23022': 19,
  '23023': 20,
  '23024': 20,
  '23025': 20,
  '23026': 20,
  '23027': 20,
  '23028': 20,
  '23029': 20,
  '2302A': 20,
  '2302B': 20,
  '2302C': 20,
  '2302D': 20,
  '2302E': 21,
  '2302F': 21,
  '23030': 21,
  '23031': 21,
  '23032': 21,
  '23033': 22,
  '23034': 22,
  '23035': 23,
  '23036': 23,
  '23037': 23,
  '23038': 23,
  '23039': 23,
  '2303A': 23,
  '2303B': 25,
  '2303C': 25,
  '2303D': 27,
  '2303E': 27,
  '2303F': 28,
  '23040': 29,
  '23041': 6,
  '23042': 7,
  '23043': 8,
  '23044': 8,
  '23045': 8,
  '23046': 9,
  '23047': 9,
  '23048': 9,
  '23049': 9,
  '2304A': 10,
  '2304B': 10,
  '2304C': 10,
  '2304D': 10,
  '2304E': 11,
  '2304F': 11,
  '23050': 11,
  '23051': 11,
  '23052': 11,
  '23053': 11,
  '23054': 12,
  '23055': 12,
  '23056': 12,
  '23057': 13,
  '23058': 13,
  '23059': 13,
  '2305A': 15,
  '2305B': 15,
  '2305C': 15,
  '2305D': 15,
  '2305E': 15,
  '2305F': 15,
  '23060': 15,
  '23061': 15,
  '23062': 16,
  '23063': 16,
  '23064': 17,
  '23065': 18,
  '23066': 18,
  '23067': 19,
  '23068': 20,
  '23069': 21,
  '2306A': 21,
  '2306B': 23,
  '2306C': 4,
  '2306D': 7,
  '2306E': 8,
  '2306F': 8,
  '23070': 8,
  '23071': 9,
  '23072': 9,
  '23073': 10,
  '23074': 10,
  '23075': 10,
  '23076': 10,
  '23077': 11,
  '23078': 11,
  '23079': 11,
  '2307A': 11,
  '2307B': 11,
  '2307C': 12,
  '2307D': 12,
  '2307E': 12,
  '2307F': 12,
  '23080': 12,
  '23081': 13,
  '23082': 14,
  '23083': 13,
  '23084': 13,
  '23085': 13,
  '23086': 14,
  '23087': 14,
  '23088': 14,
  '23089': 15,
  '2308A': 15,
  '2308B': 15,
  '2308C': 17,
  '2308D': 17,
  '2308E': 18,
  '2308F': 19,
  '23090': 20,
  '23091': 4,
  '23092': 6,
  '23093': 7,
  '23094': 8,
  '23095': 8,
  '23096': 8,
  '23097': 8,
  '23098': 8,
  '23099': 9,
  '2309A': 9,
  '2309B': 10,
  '2309C': 10,
  '2309D': 10,
  '2309E': 11,
  '2309F': 11,
  '230A0': 11,
  '230A1': 12,
  '230A2': 12,
  '230A3': 12,
  '230A4': 12,
  '230A5': 12,
  '230A6': 12,
  '230A7': 12,
  '230A8': 12,
  '230A9': 12,
  '230AA': 12,
  '230AB': 11,
  '230AC': 12,
  '230AD': 12,
  '230AE': 13,
  '230AF': 13,
  '230B0': 13,
  '230B1': 13,
  '230B2': 13,
  '230B3': 13,
  '230B4': 13,
  '230B5': 13,
  '230B6': 14,
  '230B7': 14,
  '230B8': 14,
  '230B9': 14,
  '230BA': 14,
  '230BB': 15,
  '230BC': 15,
  '230BD': 15,
  '230BE': 15,
  '230BF': 15,
  '230C0': 16,
  '230C1': 16,
  '230C2': 16,
  '230C3': 16,
  '230C4': 16,
  '230C5': 16,
  '230C6': 16,
  '230C7': 17,
  '230C8': 17,
  '230C9': 17,
  '230CA': 17,
  '230CB': 17,
  '230CC': 18,
  '230CD': 18,
  '230CE': 18,
  '230CF': 18,
  '230D0': 18,
  '230D1': 19,
  '230D2': 19,
  '230D3': 19,
  '230D4': 20,
  '230D5': 20,
  '230D6': 22,
  '230D7': 6,
  '230D8': 7,
  '230D9': 7,
  '230DA': 8,
  '230DB': 8,
  '230DC': 8,
  '230DD': 9,
  '230DE': 9,
  '230DF': 9,
  '230E0': 9,
  '230E1': 9,
  '230E2': 9,
  '230E3': 9,
  '230E4': 9,
  '230E5': 9,
  '230E6': 10,
  '230E7': 10,
  '230E8': 10,
  '230E9': 10,
  '230EA': 10,
  '230EB': 10,
  '230EC': 10,
  '230ED': 10,
  '230EE': 10,
  '230EF': 10,
  '230F0': 10,
  '230F1': 10,
  '230F2': 10,
  '230F3': 11,
  '230F4': 11,
  '230F5': 11,
  '230F6': 11,
  '230F7': 11,
  '230F8': 11,
  '230F9': 11,
  '230FA': 11,
  '230FB': 11,
  '230FC': 12,
  '230FD': 12,
  '230FE': 12,
  '230FF': 12,
  '23100': 12,
  '23101': 12,
  '23102': 12,
  '23103': 12,
  '23104': 12,
  '23105': 12,
  '23106': 12,
  '23107': 12,
  '23108': 13,
  '23109': 13,
  '2310A': 13,
  '2310B': 13,
  '2310C': 13,
  '2310D': 13,
  '2310E': 14,
  '2310F': 14,
  '23110': 14,
  '23111': 14,
  '23112': 14,
  '23113': 14,
  '23114': 15,
  '23115': 15,
  '23116': 15,
  '23117': 16,
  '23118': 16,
  '23119': 16,
  '2311A': 16,
  '2311B': 16,
  '2311C': 16,
  '2311D': 17,
  '2311E': 18,
  '2311F': 18,
  '23120': 18,
  '23121': 18,
  '23122': 18,
  '23123': 18,
  '23124': 19,
  '23125': 20,
  '23126': 21,
  '23127': 22,
  '23128': 22,
  '23129': 23,
  '2312A': 24,
  '2312B': 25,
  '2312C': 26,
  '2312D': 6,
  '2312E': 7,
  '2312F': 7,
  '23130': 9,
  '23131': 10,
  '23132': 10,
  '23133': 10,
  '23134': 12,
  '23135': 12,
  '23136': 13,
  '23137': 13,
  '23138': 13,
  '23139': 15,
  '2313A': 16,
  '2313B': 5,
  '2313C': 5,
  '2313D': 5,
  '2313E': 6,
  '2313F': 6,
  '23140': 6,
  '23141': 6,
  '23142': 6,
  '23143': 6,
  '23144': 7,
  '23145': 6,
  '23146': 7,
  '23147': 7,
  '23148': 7,
  '23149': 7,
  '2314A': 7,
  '2314B': 7,
  '2314C': 7,
  '2314D': 7,
  '2314E': 7,
  '2314F': 7,
  '23150': 7,
  '23151': 7,
  '23152': 7,
  '23153': 7,
  '23154': 7,
  '23155': 7,
  '23156': 7,
  '23157': 7,
  '23158': 7,
  '23159': 7,
  '2315A': 8,
  '2315B': 8,
  '2315C': 8,
  '2315D': 8,
  '2315E': 8,
  '2315F': 8,
  '23160': 8,
  '23161': 8,
  '23162': 8,
  '23163': 8,
  '23164': 8,
  '23165': 8,
  '23166': 8,
  '23167': 8,
  '23168': 8,
  '23169': 8,
  '2316A': 8,
  '2316B': 8,
  '2316C': 8,
  '2316D': 8,
  '2316E': 8,
  '2316F': 8,
  '23170': 8,
  '23171': 8,
  '23172': 8,
  '23173': 8,
  '23174': 8,
  '23175': 8,
  '23176': 8,
  '23177': 9,
  '23178': 9,
  '23179': 9,
  '2317A': 9,
  '2317B': 9,
  '2317C': 9,
  '2317D': 9,
  '2317E': 9,
  '2317F': 9,
  '23180': 9,
  '23181': 9,
  '23182': 9,
  '23183': 9,
  '23184': 9,
  '23185': 9,
  '23186': 9,
  '23187': 9,
  '23188': 9,
  '23189': 9,
  '2318A': 9,
  '2318B': 9,
  '2318C': 9,
  '2318D': 9,
  '2318E': 9,
  '2318F': 9,
  '23190': 9,
  '23191': 9,
  '23192': 10,
  '23193': 10,
  '23194': 10,
  '23195': 10,
  '23196': 10,
  '23197': 10,
  '23198': 10,
  '23199': 10,
  '2319A': 10,
  '2319B': 10,
  '2319C': 10,
  '2319D': 10,
  '2319E': 10,
  '2319F': 10,
  '231A0': 10,
  '231A1': 10,
  '231A2': 10,
  '231A3': 10,
  '231A4': 10,
  '231A5': 10,
  '231A6': 10,
  '231A7': 10,
  '231A8': 10,
  '231A9': 10,
  '231AA': 10,
  '231AB': 10,
  '231AC': 10,
  '231AD': 10,
  '231AE': 10,
  '231AF': 10,
  '231B0': 10,
  '231B1': 10,
  '231B2': 11,
  '231B3': 11,
  '231B4': 11,
  '231B5': 11,
  '231B6': 11,
  '231B7': 11,
  '231B8': 11,
  '231B9': 11,
  '231BA': 11,
  '231BB': 11,
  '231BC': 11,
  '231BD': 11,
  '231BE': 11,
  '231BF': 11,
  '231C0': 11,
  '231C1': 11,
  '231C2': 11,
  '231C3': 11,
  '231C4': 11,
  '231C5': 11,
  '231C6': 11,
  '231C7': 11,
  '231C8': 11,
  '231C9': 11,
  '231CA': 11,
  '231CB': 11,
  '231CC': 11,
  '231CD': 11,
  '231CE': 11,
  '231CF': 11,
  '231D0': 11,
  '231D1': 11,
  '231D2': 11,
  '231D3': 11,
  '231D4': 11,
  '231D5': 11,
  '231D6': 11,
  '231D7': 11,
  '231D8': 11,
  '231D9': 11,
  '231DA': 11,
  '231DB': 11,
  '231DC': 11,
  '231DD': 11,
  '231DE': 11,
  '231DF': 11,
  '231E0': 11,
  '231E1': 11,
  '231E2': 12,
  '231E3': 12,
  '231E4': 12,
  '231E5': 12,
  '231E6': 12,
  '231E7': 12,
  '231E8': 12,
  '231E9': 12,
  '231EA': 12,
  '231EB': 12,
  '231EC': 12,
  '231ED': 12,
  '231EE': 12,
  '231EF': 12,
  '231F0': 12,
  '231F1': 12,
  '231F2': 12,
  '231F3': 12,
  '231F4': 12,
  '231F5': 12,
  '231F6': 12,
  '231F7': 12,
  '231F8': 12,
  '231F9': 12,
  '231FA': 12,
  '231FB': 12,
  '231FC': 12,
  '231FD': 12,
  '231FE': 12,
  '231FF': 12,
  '23200': 12,
  '23201': 12,
  '23202': 12,
  '23203': 12,
  '23204': 12,
  '23205': 12,
  '23206': 12,
  '23207': 12,
  '23208': 12,
  '23209': 12,
  '2320A': 12,
  '2320B': 12,
  '2320C': 12,
  '2320D': 12,
  '2320E': 12,
  '2320F': 12,
  '23210': 12,
  '23211': 12,
  '23212': 12,
  '23213': 12,
  '23214': 12,
  '23215': 12,
  '23216': 12,
  '23217': 12,
  '23218': 12,
  '23219': 12,
  '2321A': 12,
  '2321B': 12,
  '2321C': 12,
  '2321D': 12,
  '2321E': 12,
  '2321F': 12,
  '23220': 12,
  '23221': 12,
  '23222': 12,
  '23223': 12,
  '23224': 13,
  '23225': 13,
  '23226': 13,
  '23227': 13,
  '23228': 13,
  '23229': 13,
  '2322A': 13,
  '2322B': 13,
  '2322C': 13,
  '2322D': 13,
  '2322E': 13,
  '2322F': 13,
  '23230': 13,
  '23231': 13,
  '23232': 13,
  '23233': 13,
  '23234': 13,
  '23235': 13,
  '23236': 13,
  '23237': 13,
  '23238': 13,
  '23239': 13,
  '2323A': 13,
  '2323B': 13,
  '2323C': 13,
  '2323D': 13,
  '2323E': 13,
  '2323F': 13,
  '23240': 13,
  '23241': 13,
  '23242': 13,
  '23243': 13,
  '23244': 13,
  '23245': 13,
  '23246': 13,
  '23247': 13,
  '23248': 13,
  '23249': 13,
  '2324A': 13,
  '2324B': 13,
  '2324C': 13,
  '2324D': 13,
  '2324E': 13,
  '2324F': 13,
  '23250': 13,
  '23251': 13,
  '23252': 14,
  '23253': 14,
  '23254': 14,
  '23255': 14,
  '23256': 14,
  '23257': 14,
  '23258': 14,
  '23259': 14,
  '2325A': 14,
  '2325B': 14,
  '2325C': 14,
  '2325D': 14,
  '2325E': 14,
  '2325F': 14,
  '23260': 14,
  '23261': 13,
  '23262': 14,
  '23263': 14,
  '23264': 14,
  '23265': 14,
  '23266': 14,
  '23267': 14,
  '23268': 14,
  '23269': 14,
  '2326A': 14,
  '2326B': 14,
  '2326C': 14,
  '2326D': 14,
  '2326E': 14,
  '2326F': 14,
  '23270': 14,
  '23271': 14,
  '23272': 14,
  '23273': 14,
  '23274': 14,
  '23275': 14,
  '23276': 14,
  '23277': 14,
  '23278': 14,
  '23279': 14,
  '2327A': 14,
  '2327B': 15,
  '2327C': 15,
  '2327D': 15,
  '2327E': 15,
  '2327F': 15,
  '23280': 15,
  '23281': 15,
  '23282': 15,
  '23283': 15,
  '23284': 15,
  '23285': 15,
  '23286': 15,
  '23287': 15,
  '23288': 15,
  '23289': 16,
  '2328A': 15,
  '2328B': 15,
  '2328C': 15,
  '2328D': 15,
  '2328E': 15,
  '2328F': 15,
  '23290': 15,
  '23291': 15,
  '23292': 15,
  '23293': 15,
  '23294': 15,
  '23295': 15,
  '23296': 15,
  '23297': 15,
  '23298': 15,
  '23299': 15,
  '2329A': 15,
  '2329B': 15,
  '2329C': 15,
  '2329D': 15,
  '2329E': 16,
  '2329F': 16,
  '232A0': 16,
  '232A1': 16,
  '232A2': 16,
  '232A3': 16,
  '232A4': 16,
  '232A5': 16,
  '232A6': 16,
  '232A7': 16,
  '232A8': 16,
  '232A9': 16,
  '232AA': 16,
  '232AB': 16,
  '232AC': 16,
  '232AD': 16,
  '232AE': 16,
  '232AF': 16,
  '232B0': 16,
  '232B1': 16,
  '232B2': 16,
  '232B3': 16,
  '232B4': 16,
  '232B5': 16,
  '232B6': 16,
  '232B7': 16,
  '232B8': 16,
  '232B9': 16,
  '232BA': 16,
  '232BB': 16,
  '232BC': 16,
  '232BD': 16,
  '232BE': 16,
  '232BF': 16,
  '232C0': 16,
  '232C1': 16,
  '232C2': 16,
  '232C3': 16,
  '232C4': 16,
  '232C5': 16,
  '232C6': 16,
  '232C7': 15,
  '232C8': 16,
  '232C9': 17,
  '232CA': 17,
  '232CB': 17,
  '232CC': 17,
  '232CD': 17,
  '232CE': 17,
  '232CF': 17,
  '232D0': 17,
  '232D1': 17,
  '232D2': 17,
  '232D3': 17,
  '232D4': 17,
  '232D5': 17,
  '232D6': 17,
  '232D7': 17,
  '232D8': 17,
  '232D9': 17,
  '232DA': 17,
  '232DB': 17,
  '232DC': 17,
  '232DD': 17,
  '232DE': 18,
  '232DF': 18,
  '232E0': 18,
  '232E1': 18,
  '232E2': 18,
  '232E3': 18,
  '232E4': 18,
  '232E5': 18,
  '232E6': 18,
  '232E7': 18,
  '232E8': 18,
  '232E9': 18,
  '232EA': 18,
  '232EB': 18,
  '232EC': 18,
  '232ED': 18,
  '232EE': 18,
  '232EF': 18,
  '232F0': 18,
  '232F1': 18,
  '232F2': 19,
  '232F3': 19,
  '232F4': 19,
  '232F5': 19,
  '232F6': 19,
  '232F7': 19,
  '232F8': 19,
  '232F9': 19,
  '232FA': 19,
  '232FB': 19,
  '232FC': 19,
  '232FD': 19,
  '232FE': 19,
  '232FF': 20,
  '23300': 20,
  '23301': 20,
  '23302': 20,
  '23303': 20,
  '23304': 20,
  '23305': 20,
  '23306': 20,
  '23307': 20,
  '23308': 21,
  '23309': 21,
  '2330A': 21,
  '2330B': 21,
  '2330C': 21,
  '2330D': 22,
  '2330E': 22,
  '2330F': 22,
  '23310': 22,
  '23311': 22,
  '23312': 22,
  '23313': 22,
  '23314': 22,
  '23315': 22,
  '23316': 23,
  '23317': 24,
  '23318': 24,
  '23319': 24,
  '2331A': 24,
  '2331B': 24,
  '2331C': 25,
  '2331D': 25,
  '2331E': 27,
  '2331F': 28,
  '23320': 36,
  '23321': 7,
  '23322': 7,
  '23323': 8,
  '23324': 8,
  '23325': 8,
  '23326': 8,
  '23327': 9,
  '23328': 9,
  '23329': 9,
  '2332A': 9,
  '2332B': 9,
  '2332C': 9,
  '2332D': 10,
  '2332E': 10,
  '2332F': 10,
  '23330': 10,
  '23331': 10,
  '23332': 10,
  '23333': 10,
  '23334': 10,
  '23335': 10,
  '23336': 10,
  '23337': 11,
  '23338': 11,
  '23339': 11,
  '2333A': 11,
  '2333B': 12,
  '2333C': 12,
  '2333D': 12,
  '2333E': 13,
  '2333F': 13,
  '23340': 13,
  '23341': 13,
  '23342': 13,
  '23343': 14,
  '23344': 14,
  '23345': 14,
  '23346': 15,
  '23347': 15,
  '23348': 15,
  '23349': 15,
  '2334A': 15,
  '2334B': 16,
  '2334C': 16,
  '2334D': 16,
  '2334E': 16,
  '2334F': 17,
  '23350': 17,
  '23351': 18,
  '23352': 18,
  '23353': 18,
  '23354': 18,
  '23355': 18,
  '23356': 19,
  '23357': 19,
  '23358': 20,
  '23359': 20,
  '2335A': 24,
  '2335B': 28,
  '2335C': 35,
  '2335D': 5,
  '2335E': 6,
  '2335F': 7,
  '23360': 7,
  '23361': 7,
  '23362': 7,
  '23363': 7,
  '23364': 8,
  '23365': 9,
  '23366': 9,
  '23367': 9,
  '23368': 9,
  '23369': 9,
  '2336A': 9,
  '2336B': 9,
  '2336C': 9,
  '2336D': 10,
  '2336E': 10,
  '2336F': 10,
  '23370': 10,
  '23371': 11,
  '23372': 11,
  '23373': 11,
  '23374': 11,
  '23375': 11,
  '23376': 11,
  '23377': 12,
  '23378': 12,
  '23379': 12,
  '2337A': 12,
  '2337B': 12,
  '2337C': 12,
  '2337D': 12,
  '2337E': 12,
  '2337F': 12,
  '23380': 12,
  '23381': 12,
  '23382': 12,
  '23383': 12,
  '23384': 13,
  '23385': 13,
  '23386': 13,
  '23387': 13,
  '23388': 13,
  '23389': 13,
  '2338A': 13,
  '2338B': 13,
  '2338C': 13,
  '2338D': 14,
  '2338E': 14,
  '2338F': 14,
  '23390': 14,
  '23391': 14,
  '23392': 15,
  '23393': 15,
  '23394': 15,
  '23395': 15,
  '23396': 15,
  '23397': 15,
  '23398': 15,
  '23399': 15,
  '2339A': 16,
  '2339B': 16,
  '2339C': 17,
  '2339D': 16,
  '2339E': 16,
  '2339F': 16,
  '233A0': 16,
  '233A1': 16,
  '233A2': 16,
  '233A3': 17,
  '233A4': 17,
  '233A5': 17,
  '233A6': 17,
  '233A7': 17,
  '233A8': 17,
  '233A9': 18,
  '233AA': 18,
  '233AB': 18,
  '233AC': 18,
  '233AD': 18,
  '233AE': 20,
  '233AF': 20,
  '233B0': 22,
  '233B1': 23,
  '233B2': 24,
  '233B3': 4,
  '233B4': 4,
  '233B5': 5,
  '233B6': 5,
  '233B7': 5,
  '233B8': 6,
  '233B9': 6,
  '233BA': 6,
  '233BB': 6,
  '233BC': 6,
  '233BD': 6,
  '233BE': 6,
  '233BF': 6,
  '233C0': 6,
  '233C1': 7,
  '233C2': 7,
  '233C3': 7,
  '233C4': 7,
  '233C5': 7,
  '233C6': 7,
  '233C7': 7,
  '233C8': 7,
  '233C9': 7,
  '233CA': 7,
  '233CB': 7,
  '233CC': 7,
  '233CD': 7,
  '233CE': 7,
  '233CF': 7,
  '233D0': 7,
  '233D1': 7,
  '233D2': 7,
  '233D3': 7,
  '233D4': 8,
  '233D5': 8,
  '233D6': 8,
  '233D7': 8,
  '233D8': 8,
  '233D9': 8,
  '233DA': 8,
  '233DB': 8,
  '233DC': 8,
  '233DD': 8,
  '233DE': 8,
  '233DF': 8,
  '233E0': 8,
  '233E1': 8,
  '233E2': 8,
  '233E3': 8,
  '233E4': 8,
  '233E5': 8,
  '233E6': 8,
  '233E7': 8,
  '233E8': 8,
  '233E9': 8,
  '233EA': 8,
  '233EB': 8,
  '233EC': 8,
  '233ED': 8,
  '233EE': 8,
  '233EF': 8,
  '233F0': 8,
  '233F1': 8,
  '233F2': 8,
  '233F3': 8,
  '233F4': 8,
  '233F5': 8,
  '233F6': 8,
  '233F7': 8,
  '233F8': 8,
  '233F9': 8,
  '233FA': 8,
  '233FB': 8,
  '233FC': 8,
  '233FD': 8,
  '233FE': 8,
  '233FF': 8,
  '23400': 8,
  '23401': 8,
  '23402': 8,
  '23403': 8,
  '23404': 8,
  '23405': 8,
  '23406': 8,
  '23407': 8,
  '23408': 9,
  '23409': 9,
  '2340A': 9,
  '2340B': 9,
  '2340C': 9,
  '2340D': 9,
  '2340E': 9,
  '2340F': 9,
  '23410': 9,
  '23411': 9,
  '23412': 9,
  '23413': 9,
  '23414': 9,
  '23415': 9,
  '23416': 9,
  '23417': 9,
  '23418': 9,
  '23419': 9,
  '2341A': 9,
  '2341B': 9,
  '2341C': 9,
  '2341D': 9,
  '2341E': 9,
  '2341F': 9,
  '23420': 9,
  '23421': 9,
  '23422': 9,
  '23423': 9,
  '23424': 9,
  '23425': 9,
  '23426': 9,
  '23427': 9,
  '23428': 9,
  '23429': 9,
  '2342A': 9,
  '2342B': 9,
  '2342C': 9,
  '2342D': 9,
  '2342E': 9,
  '2342F': 9,
  '23430': 9,
  '23431': 9,
  '23432': 9,
  '23433': 9,
  '23434': 9,
  '23435': 10,
  '23436': 10,
  '23437': 10,
  '23438': 10,
  '23439': 10,
  '2343A': 10,
  '2343B': 10,
  '2343C': 10,
  '2343D': 10,
  '2343E': 10,
  '2343F': 10,
  '23440': 10,
  '23441': 10,
  '23442': 10,
  '23443': 10,
  '23444': 10,
  '23445': 10,
  '23446': 10,
  '23447': 10,
  '23448': 10,
  '23449': 10,
  '2344A': 10,
  '2344B': 10,
  '2344C': 10,
  '2344D': 10,
  '2344E': 10,
  '2344F': 10,
  '23450': 10,
  '23451': 10,
  '23452': 10,
  '23453': 10,
  '23454': 10,
  '23455': 10,
  '23456': 10,
  '23457': 10,
  '23458': 10,
  '23459': 10,
  '2345A': 10,
  '2345B': 10,
  '2345C': 10,
  '2345D': 10,
  '2345E': 10,
  '2345F': 10,
  '23460': 10,
  '23461': 10,
  '23462': 10,
  '23463': 10,
  '23464': 10,
  '23465': 10,
  '23466': 10,
  '23467': 10,
  '23468': 10,
  '23469': 10,
  '2346A': 10,
  '2346B': 10,
  '2346C': 10,
  '2346D': 10,
  '2346E': 10,
  '2346F': 10,
  '23470': 10,
  '23471': 10,
  '23472': 10,
  '23473': 10,
  '23474': 10,
  '23475': 10,
  '23476': 10,
  '23477': 10,
  '23478': 10,
  '23479': 10,
  '2347A': 10,
  '2347B': 10,
  '2347C': 10,
  '2347D': 10,
  '2347E': 10,
  '2347F': 11,
  '23480': 11,
  '23481': 11,
  '23482': 11,
  '23483': 11,
  '23484': 11,
  '23485': 11,
  '23486': 11,
  '23487': 11,
  '23488': 11,
  '23489': 11,
  '2348A': 11,
  '2348B': 11,
  '2348C': 11,
  '2348D': 11,
  '2348E': 11,
  '2348F': 11,
  '23490': 11,
  '23491': 11,
  '23492': 11,
  '23493': 11,
  '23494': 11,
  '23495': 11,
  '23496': 11,
  '23497': 11,
  '23498': 11,
  '23499': 11,
  '2349A': 11,
  '2349B': 11,
  '2349C': 11,
  '2349D': 11,
  '2349E': 11,
  '2349F': 11,
  '234A0': 11,
  '234A1': 11,
  '234A2': 11,
  '234A3': 11,
  '234A4': 11,
  '234A5': 11,
  '234A6': 11,
  '234A7': 11,
  '234A8': 11,
  '234A9': 11,
  '234AA': 11,
  '234AB': 11,
  '234AC': 11,
  '234AD': 11,
  '234AE': 11,
  '234AF': 11,
  '234B0': 11,
  '234B1': 11,
  '234B2': 11,
  '234B3': 11,
  '234B4': 11,
  '234B5': 11,
  '234B6': 11,
  '234B7': 11,
  '234B8': 11,
  '234B9': 11,
  '234BA': 11,
  '234BB': 11,
  '234BC': 11,
  '234BD': 11,
  '234BE': 11,
  '234BF': 11,
  '234C0': 11,
  '234C1': 12,
  '234C2': 12,
  '234C3': 12,
  '234C4': 12,
  '234C5': 12,
  '234C6': 12,
  '234C7': 12,
  '234C8': 12,
  '234C9': 12,
  '234CA': 12,
  '234CB': 12,
  '234CC': 12,
  '234CD': 12,
  '234CE': 12,
  '234CF': 12,
  '234D0': 12,
  '234D1': 12,
  '234D2': 12,
  '234D3': 12,
  '234D4': 12,
  '234D5': 12,
  '234D6': 12,
  '234D7': 12,
  '234D8': 12,
  '234D9': 12,
  '234DA': 12,
  '234DB': 12,
  '234DC': 12,
  '234DD': 12,
  '234DE': 12,
  '234DF': 12,
  '234E0': 12,
  '234E1': 12,
  '234E2': 12,
  '234E3': 12,
  '234E4': 12,
  '234E5': 12,
  '234E6': 12,
  '234E7': 12,
  '234E8': 12,
  '234E9': 12,
  '234EA': 12,
  '234EB': 12,
  '234EC': 12,
  '234ED': 12,
  '234EE': 12,
  '234EF': 12,
  '234F0': 12,
  '234F1': 12,
  '234F2': 12,
  '234F3': 12,
  '234F4': 12,
  '234F5': 12,
  '234F6': 12,
  '234F7': 12,
  '234F8': 12,
  '234F9': 12,
  '234FA': 12,
  '234FB': 12,
  '234FC': 12,
  '234FD': 12,
  '234FE': 12,
  '234FF': 12,
  '23500': 12,
  '23501': 12,
  '23502': 12,
  '23503': 12,
  '23504': 12,
  '23505': 12,
  '23506': 12,
  '23507': 12,
  '23508': 12,
  '23509': 12,
  '2350A': 12,
  '2350B': 12,
  '2350C': 12,
  '2350D': 12,
  '2350E': 12,
  '2350F': 12,
  '23510': 12,
  '23511': 12,
  '23512': 12,
  '23513': 12,
  '23514': 12,
  '23515': 12,
  '23516': 12,
  '23517': 12,
  '23518': 12,
  '23519': 12,
  '2351A': 12,
  '2351B': 12,
  '2351C': 12,
  '2351D': 12,
  '2351E': 12,
  '2351F': 12,
  '23520': 12,
  '23521': 12,
  '23522': 12,
  '23523': 12,
  '23524': 12,
  '23525': 12,
  '23526': 12,
  '23527': 12,
  '23528': 12,
  '23529': 12,
  '2352A': 12,
  '2352B': 12,
  '2352C': 12,
  '2352D': 12,
  '2352E': 12,
  '2352F': 12,
  '23530': 12,
  '23531': 13,
  '23532': 13,
  '23533': 13,
  '23534': 13,
  '23535': 13,
  '23536': 13,
  '23537': 13,
  '23538': 13,
  '23539': 13,
  '2353A': 13,
  '2353B': 13,
  '2353C': 13,
  '2353D': 13,
  '2353E': 13,
  '2353F': 13,
  '23540': 13,
  '23541': 13,
  '23542': 13,
  '23543': 13,
  '23544': 13,
  '23545': 13,
  '23546': 13,
  '23547': 13,
  '23548': 13,
  '23549': 13,
  '2354A': 13,
  '2354B': 13,
  '2354C': 13,
  '2354D': 13,
  '2354E': 13,
  '2354F': 13,
  '23550': 13,
  '23551': 13,
  '23552': 13,
  '23553': 13,
  '23554': 13,
  '23555': 13,
  '23556': 13,
  '23557': 13,
  '23558': 13,
  '23559': 13,
  '2355A': 13,
  '2355B': 13,
  '2355C': 13,
  '2355D': 13,
  '2355E': 13,
  '2355F': 13,
  '23560': 13,
  '23561': 13,
  '23562': 13,
  '23563': 13,
  '23564': 14,
  '23565': 13,
  '23566': 13,
  '23567': 13,
  '23568': 13,
  '23569': 13,
  '2356A': 13,
  '2356B': 13,
  '2356C': 13,
  '2356D': 13,
  '2356E': 13,
  '2356F': 13,
  '23570': 13,
  '23571': 13,
  '23572': 13,
  '23573': 13,
  '23574': 13,
  '23575': 13,
  '23576': 13,
  '23577': 13,
  '23578': 13,
  '23579': 13,
  '2357A': 13,
  '2357B': 13,
  '2357C': 13,
  '2357D': 13,
  '2357E': 13,
  '2357F': 13,
  '23580': 13,
  '23581': 13,
  '23582': 13,
  '23583': 13,
  '23584': 13,
  '23585': 13,
  '23586': 13,
  '23587': 13,
  '23588': 13,
  '23589': 13,
  '2358A': 13,
  '2358B': 13,
  '2358C': 13,
  '2358D': 13,
  '2358E': 13,
  '2358F': 13,
  '23590': 13,
  '23591': 13,
  '23592': 13,
  '23593': 13,
  '23594': 13,
  '23595': 13,
  '23596': 13,
  '23597': 13,
  '23598': 13,
  '23599': 13,
  '2359A': 13,
  '2359B': 13,
  '2359C': 13,
  '2359D': 13,
  '2359E': 13,
  '2359F': 13,
  '235A0': 13,
  '235A1': 13,
  '235A2': 13,
  '235A3': 13,
  '235A4': 13,
  '235A5': 13,
  '235A6': 13,
  '235A7': 13,
  '235A8': 13,
  '235A9': 13,
  '235AA': 14,
  '235AB': 14,
  '235AC': 14,
  '235AD': 14,
  '235AE': 14,
  '235AF': 14,
  '235B0': 14,
  '235B1': 14,
  '235B2': 14,
  '235B3': 14,
  '235B4': 14,
  '235B5': 14,
  '235B6': 14,
  '235B7': 14,
  '235B8': 14,
  '235B9': 14,
  '235BA': 14,
  '235BB': 14,
  '235BC': 14,
  '235BD': 14,
  '235BE': 14,
  '235BF': 14,
  '235C0': 14,
  '235C1': 14,
  '235C2': 14,
  '235C3': 14,
  '235C4': 14,
  '235C5': 14,
  '235C6': 14,
  '235C7': 14,
  '235C8': 14,
  '235C9': 14,
  '235CA': 14,
  '235CB': 14,
  '235CC': 14,
  '235CD': 14,
  '235CE': 14,
  '235CF': 14,
  '235D0': 14,
  '235D1': 14,
  '235D2': 14,
  '235D3': 14,
  '235D4': 14,
  '235D5': 14,
  '235D6': 14,
  '235D7': 14,
  '235D8': 14,
  '235D9': 14,
  '235DA': 14,
  '235DB': 14,
  '235DC': 14,
  '235DD': 14,
  '235DE': 14,
  '235DF': 14,
  '235E0': 14,
  '235E1': 14,
  '235E2': 14,
  '235E3': 14,
  '235E4': 14,
  '235E5': 14,
  '235E6': 14,
  '235E7': 14,
  '235E8': 14,
  '235E9': 14,
  '235EA': 14,
  '235EB': 14,
  '235EC': 14,
  '235ED': 14,
  '235EE': 14,
  '235EF': 14,
  '235F0': 14,
  '235F1': 14,
  '235F2': 14,
  '235F3': 14,
  '235F4': 14,
  '235F5': 14,
  '235F6': 14,
  '235F7': 14,
  '235F8': 14,
  '235F9': 14,
  '235FA': 14,
  '235FB': 14,
  '235FC': 14,
  '235FD': 14,
  '235FE': 14,
  '235FF': 14,
  '23600': 14,
  '23601': 14,
  '23602': 14,
  '23603': 14,
  '23604': 14,
  '23605': 14,
  '23606': 14,
  '23607': 14,
  '23608': 14,
  '23609': 14,
  '2360A': 14,
  '2360B': 14,
  '2360C': 14,
  '2360D': 14,
  '2360E': 14,
  '2360F': 14,
  '23610': 14,
  '23611': 14,
  '23612': 14,
  '23613': 14,
  '23614': 14,
  '23615': 15,
  '23616': 15,
  '23617': 15,
  '23618': 15,
  '23619': 15,
  '2361A': 15,
  '2361B': 15,
  '2361C': 15,
  '2361D': 15,
  '2361E': 15,
  '2361F': 15,
  '23620': 15,
  '23621': 15,
  '23622': 15,
  '23623': 15,
  '23624': 15,
  '23625': 15,
  '23626': 15,
  '23627': 15,
  '23628': 15,
  '23629': 15,
  '2362A': 15,
  '2362B': 15,
  '2362C': 15,
  '2362D': 15,
  '2362E': 15,
  '2362F': 15,
  '23630': 15,
  '23631': 15,
  '23632': 15,
  '23633': 15,
  '23634': 15,
  '23635': 15,
  '23636': 15,
  '23637': 15,
  '23638': 15,
  '23639': 15,
  '2363A': 15,
  '2363B': 15,
  '2363C': 15,
  '2363D': 15,
  '2363E': 15,
  '2363F': 15,
  '23640': 15,
  '23641': 15,
  '23642': 15,
  '23643': 15,
  '23644': 15,
  '23645': 15,
  '23646': 15,
  '23647': 15,
  '23648': 15,
  '23649': 15,
  '2364A': 15,
  '2364B': 15,
  '2364C': 15,
  '2364D': 15,
  '2364E': 15,
  '2364F': 15,
  '23650': 15,
  '23651': 15,
  '23652': 16,
  '23653': 15,
  '23654': 15,
  '23655': 15,
  '23656': 15,
  '23657': 15,
  '23658': 15,
  '23659': 15,
  '2365A': 15,
  '2365B': 15,
  '2365C': 15,
  '2365D': 15,
  '2365E': 15,
  '2365F': 15,
  '23660': 15,
  '23661': 15,
  '23662': 15,
  '23663': 15,
  '23664': 15,
  '23665': 15,
  '23666': 15,
  '23667': 15,
  '23668': 15,
  '23669': 15,
  '2366A': 15,
  '2366B': 15,
  '2366C': 15,
  '2366D': 15,
  '2366E': 15,
  '2366F': 15,
  '23670': 15,
  '23671': 15,
  '23672': 15,
  '23673': 15,
  '23674': 15,
  '23675': 15,
  '23676': 15,
  '23677': 15,
  '23678': 15,
  '23679': 15,
  '2367A': 15,
  '2367B': 16,
  '2367C': 16,
  '2367D': 16,
  '2367E': 16,
  '2367F': 16,
  '23680': 16,
  '23681': 16,
  '23682': 16,
  '23683': 16,
  '23684': 16,
  '23685': 16,
  '23686': 16,
  '23687': 16,
  '23688': 16,
  '23689': 16,
  '2368A': 16,
  '2368B': 16,
  '2368C': 16,
  '2368D': 16,
  '2368E': 16,
  '2368F': 16,
  '23690': 16,
  '23691': 16,
  '23692': 16,
  '23693': 16,
  '23694': 16,
  '23695': 16,
  '23696': 16,
  '23697': 16,
  '23698': 16,
  '23699': 16,
  '2369A': 16,
  '2369B': 16,
  '2369C': 16,
  '2369D': 16,
  '2369E': 16,
  '2369F': 16,
  '236A0': 16,
  '236A1': 16,
  '236A2': 16,
  '236A3': 16,
  '236A4': 16,
  '236A5': 16,
  '236A6': 16,
  '236A7': 16,
  '236A8': 16,
  '236A9': 16,
  '236AA': 16,
  '236AB': 16,
  '236AC': 16,
  '236AD': 16,
  '236AE': 16,
  '236AF': 16,
  '236B0': 16,
  '236B1': 16,
  '236B2': 16,
  '236B3': 16,
  '236B4': 16,
  '236B5': 16,
  '236B6': 16,
  '236B7': 16,
  '236B8': 16,
  '236B9': 16,
  '236BA': 16,
  '236BB': 16,
  '236BC': 16,
  '236BD': 16,
  '236BE': 16,
  '236BF': 16,
  '236C0': 16,
  '236C1': 16,
  '236C2': 16,
  '236C3': 16,
  '236C4': 16,
  '236C5': 16,
  '236C6': 16,
  '236C7': 16,
  '236C8': 16,
  '236C9': 16,
  '236CA': 16,
  '236CB': 16,
  '236CC': 16,
  '236CD': 16,
  '236CE': 16,
  '236CF': 16,
  '236D0': 16,
  '236D1': 16,
  '236D2': 16,
  '236D3': 16,
  '236D4': 16,
  '236D5': 16,
  '236D6': 16,
  '236D7': 16,
  '236D8': 16,
  '236D9': 16,
  '236DA': 16,
  '236DB': 16,
  '236DC': 16,
  '236DD': 16,
  '236DE': 16,
  '236DF': 16,
  '236E0': 16,
  '236E1': 16,
  '236E2': 16,
  '236E3': 16,
  '236E4': 16,
  '236E5': 16,
  '236E6': 16,
  '236E7': 16,
  '236E8': 16,
  '236E9': 16,
  '236EA': 16,
  '236EB': 16,
  '236EC': 16,
  '236ED': 16,
  '236EE': 16,
  '236EF': 16,
  '236F0': 17,
  '236F1': 17,
  '236F2': 17,
  '236F3': 17,
  '236F4': 17,
  '236F5': 17,
  '236F6': 17,
  '236F7': 17,
  '236F8': 17,
  '236F9': 17,
  '236FA': 17,
  '236FB': 17,
  '236FC': 17,
  '236FD': 17,
  '236FE': 17,
  '236FF': 17,
  '23700': 17,
  '23701': 17,
  '23702': 17,
  '23703': 17,
  '23704': 17,
  '23705': 17,
  '23706': 17,
  '23707': 17,
  '23708': 17,
  '23709': 17,
  '2370A': 17,
  '2370B': 17,
  '2370C': 17,
  '2370D': 17,
  '2370E': 17,
  '2370F': 17,
  '23710': 17,
  '23711': 17,
  '23712': 17,
  '23713': 17,
  '23714': 17,
  '23715': 17,
  '23716': 17,
  '23717': 17,
  '23718': 17,
  '23719': 17,
  '2371A': 17,
  '2371B': 17,
  '2371C': 17,
  '2371D': 17,
  '2371E': 17,
  '2371F': 17,
  '23720': 17,
  '23721': 17,
  '23722': 16,
  '23723': 17,
  '23724': 17,
  '23725': 17,
  '23726': 17,
  '23727': 17,
  '23728': 17,
  '23729': 17,
  '2372A': 17,
  '2372B': 17,
  '2372C': 17,
  '2372D': 17,
  '2372E': 17,
  '2372F': 17,
  '23730': 17,
  '23731': 17,
  '23732': 17,
  '23733': 17,
  '23734': 17,
  '23735': 17,
  '23736': 17,
  '23737': 17,
  '23738': 17,
  '23739': 17,
  '2373A': 17,
  '2373B': 17,
  '2373C': 17,
  '2373D': 17,
  '2373E': 17,
  '2373F': 17,
  '23740': 17,
  '23741': 18,
  '23742': 18,
  '23743': 18,
  '23744': 18,
  '23745': 18,
  '23746': 18,
  '23747': 18,
  '23748': 18,
  '23749': 18,
  '2374A': 18,
  '2374B': 18,
  '2374C': 18,
  '2374D': 18,
  '2374E': 18,
  '2374F': 18,
  '23750': 18,
  '23751': 18,
  '23752': 18,
  '23753': 18,
  '23754': 18,
  '23755': 18,
  '23756': 18,
  '23757': 18,
  '23758': 18,
  '23759': 19,
  '2375A': 18,
  '2375B': 18,
  '2375C': 18,
  '2375D': 18,
  '2375E': 18,
  '2375F': 18,
  '23760': 18,
  '23761': 18,
  '23762': 18,
  '23763': 18,
  '23764': 18,
  '23765': 18,
  '23766': 18,
  '23767': 18,
  '23768': 18,
  '23769': 18,
  '2376A': 18,
  '2376B': 18,
  '2376C': 18,
  '2376D': 18,
  '2376E': 18,
  '2376F': 18,
  '23770': 18,
  '23771': 18,
  '23772': 18,
  '23773': 18,
  '23774': 18,
  '23775': 18,
  '23776': 18,
  '23777': 18,
  '23778': 18,
  '23779': 18,
  '2377A': 18,
  '2377B': 18,
  '2377C': 18,
  '2377D': 18,
  '2377E': 18,
  '2377F': 18,
  '23780': 18,
  '23781': 18,
  '23782': 18,
  '23783': 18,
  '23784': 18,
  '23785': 18,
  '23786': 18,
  '23787': 18,
  '23788': 18,
  '23789': 18,
  '2378A': 18,
  '2378B': 18,
  '2378C': 18,
  '2378D': 18,
  '2378E': 18,
  '2378F': 18,
  '23790': 19,
  '23791': 19,
  '23792': 19,
  '23793': 19,
  '23794': 19,
  '23795': 19,
  '23796': 19,
  '23797': 19,
  '23798': 19,
  '23799': 19,
  '2379A': 19,
  '2379B': 19,
  '2379C': 19,
  '2379D': 19,
  '2379E': 19,
  '2379F': 19,
  '237A0': 19,
  '237A1': 19,
  '237A2': 19,
  '237A3': 19,
  '237A4': 19,
  '237A5': 19,
  '237A6': 19,
  '237A7': 19,
  '237A8': 19,
  '237A9': 19,
  '237AA': 19,
  '237AB': 19,
  '237AC': 19,
  '237AD': 19,
  '237AE': 19,
  '237AF': 19,
  '237B0': 19,
  '237B1': 19,
  '237B2': 19,
  '237B3': 19,
  '237B4': 19,
  '237B5': 19,
  '237B6': 19,
  '237B7': 19,
  '237B8': 19,
  '237B9': 19,
  '237BA': 19,
  '237BB': 19,
  '237BC': 19,
  '237BD': 19,
  '237BE': 19,
  '237BF': 19,
  '237C0': 19,
  '237C1': 19,
  '237C2': 19,
  '237C3': 19,
  '237C4': 20,
  '237C5': 20,
  '237C6': 20,
  '237C7': 20,
  '237C8': 20,
  '237C9': 20,
  '237CA': 20,
  '237CB': 20,
  '237CC': 20,
  '237CD': 20,
  '237CE': 20,
  '237CF': 20,
  '237D0': 20,
  '237D1': 19,
  '237D2': 20,
  '237D3': 20,
  '237D4': 20,
  '237D5': 20,
  '237D6': 20,
  '237D7': 20,
  '237D8': 20,
  '237D9': 20,
  '237DA': 20,
  '237DB': 20,
  '237DC': 20,
  '237DD': 20,
  '237DE': 20,
  '237DF': 20,
  '237E0': 20,
  '237E1': 20,
  '237E2': 20,
  '237E3': 20,
  '237E4': 20,
  '237E5': 20,
  '237E6': 20,
  '237E7': 20,
  '237E8': 20,
  '237E9': 20,
  '237EA': 20,
  '237EB': 20,
  '237EC': 20,
  '237ED': 20,
  '237EE': 20,
  '237EF': 21,
  '237F0': 21,
  '237F1': 21,
  '237F2': 21,
  '237F3': 21,
  '237F4': 21,
  '237F5': 21,
  '237F6': 21,
  '237F7': 21,
  '237F8': 21,
  '237F9': 21,
  '237FA': 21,
  '237FB': 21,
  '237FC': 21,
  '237FD': 21,
  '237FE': 21,
  '237FF': 21,
  '23800': 21,
  '23801': 21,
  '23802': 22,
  '23803': 21,
  '23804': 21,
  '23805': 21,
  '23806': 21,
  '23807': 21,
  '23808': 21,
  '23809': 21,
  '2380A': 21,
  '2380B': 21,
  '2380C': 21,
  '2380D': 21,
  '2380E': 21,
  '2380F': 21,
  '23810': 21,
  '23811': 21,
  '23812': 21,
  '23813': 21,
  '23814': 21,
  '23815': 21,
  '23816': 21,
  '23817': 21,
  '23818': 21,
  '23819': 22,
  '2381A': 22,
  '2381B': 22,
  '2381C': 22,
  '2381D': 22,
  '2381E': 22,
  '2381F': 22,
  '23820': 22,
  '23821': 22,
  '23822': 22,
  '23823': 22,
  '23824': 22,
  '23825': 22,
  '23826': 22,
  '23827': 22,
  '23828': 22,
  '23829': 22,
  '2382A': 22,
  '2382B': 22,
  '2382C': 22,
  '2382D': 22,
  '2382E': 22,
  '2382F': 22,
  '23830': 22,
  '23831': 22,
  '23832': 22,
  '23833': 22,
  '23834': 22,
  '23835': 22,
  '23836': 22,
  '23837': 23,
  '23838': 23,
  '23839': 23,
  '2383A': 23,
  '2383B': 23,
  '2383C': 23,
  '2383D': 23,
  '2383E': 23,
  '2383F': 23,
  '23840': 23,
  '23841': 23,
  '23842': 23,
  '23843': 23,
  '23844': 23,
  '23845': 23,
  '23846': 23,
  '23847': 23,
  '23848': 23,
  '23849': 23,
  '2384A': 23,
  '2384B': 24,
  '2384C': 24,
  '2384D': 24,
  '2384E': 24,
  '2384F': 24,
  '23850': 24,
  '23851': 24,
  '23852': 24,
  '23853': 24,
  '23854': 24,
  '23855': 24,
  '23856': 24,
  '23857': 24,
  '23858': 24,
  '23859': 24,
  '2385A': 24,
  '2385B': 24,
  '2385C': 24,
  '2385D': 24,
  '2385E': 25,
  '2385F': 25,
  '23860': 25,
  '23861': 25,
  '23862': 25,
  '23863': 25,
  '23864': 25,
  '23865': 25,
  '23866': 25,
  '23867': 25,
  '23868': 25,
  '23869': 26,
  '2386A': 26,
  '2386B': 26,
  '2386C': 26,
  '2386D': 26,
  '2386E': 26,
  '2386F': 26,
  '23870': 26,
  '23871': 26,
  '23872': 27,
  '23873': 27,
  '23874': 27,
  '23875': 27,
  '23876': 28,
  '23877': 28,
  '23878': 28,
  '23879': 29,
  '2387A': 30,
  '2387B': 30,
  '2387C': 31,
  '2387D': 32,
  '2387E': 32,
  '2387F': 34,
  '23880': 6,
  '23881': 7,
  '23882': 7,
  '23883': 7,
  '23884': 7,
  '23885': 7,
  '23886': 7,
  '23887': 7,
  '23888': 7,
  '23889': 8,
  '2388A': 8,
  '2388B': 8,
  '2388C': 8,
  '2388D': 8,
  '2388E': 8,
  '2388F': 8,
  '23890': 8,
  '23891': 8,
  '23892': 8,
  '23893': 8,
  '23894': 8,
  '23895': 8,
  '23896': 8,
  '23897': 9,
  '23898': 9,
  '23899': 9,
  '2389A': 9,
  '2389B': 9,
  '2389C': 9,
  '2389D': 9,
  '2389E': 9,
  '2389F': 9,
  '238A0': 9,
  '238A1': 9,
  '238A2': 9,
  '238A3': 9,
  '238A4': 9,
  '238A5': 9,
  '238A6': 9,
  '238A7': 10,
  '238A8': 10,
  '238A9': 10,
  '238AA': 10,
  '238AB': 10,
  '238AC': 10,
  '238AD': 10,
  '238AE': 10,
  '238AF': 10,
  '238B0': 10,
  '238B1': 10,
  '238B2': 10,
  '238B3': 10,
  '238B4': 10,
  '238B5': 10,
  '238B6': 10,
  '238B7': 10,
  '238B8': 10,
  '238B9': 10,
  '238BA': 11,
  '238BB': 11,
  '238BC': 11,
  '238BD': 11,
  '238BE': 11,
  '238BF': 11,
  '238C0': 11,
  '238C1': 11,
  '238C2': 11,
  '238C3': 11,
  '238C4': 11,
  '238C5': 11,
  '238C6': 11,
  '238C7': 11,
  '238C8': 12,
  '238C9': 12,
  '238CA': 12,
  '238CB': 12,
  '238CC': 12,
  '238CD': 12,
  '238CE': 12,
  '238CF': 12,
  '238D0': 12,
  '238D1': 12,
  '238D2': 12,
  '238D3': 12,
  '238D4': 12,
  '238D5': 12,
  '238D6': 12,
  '238D7': 12,
  '238D8': 12,
  '238D9': 12,
  '238DA': 12,
  '238DB': 12,
  '238DC': 12,
  '238DD': 13,
  '238DE': 13,
  '238DF': 13,
  '238E0': 13,
  '238E1': 13,
  '238E2': 13,
  '238E3': 13,
  '238E4': 13,
  '238E5': 13,
  '238E6': 13,
  '238E7': 13,
  '238E8': 13,
  '238E9': 13,
  '238EA': 13,
  '238EB': 13,
  '238EC': 13,
  '238ED': 13,
  '238EE': 13,
  '238EF': 13,
  '238F0': 13,
  '238F1': 13,
  '238F2': 14,
  '238F3': 14,
  '238F4': 14,
  '238F5': 14,
  '238F6': 14,
  '238F7': 14,
  '238F8': 14,
  '238F9': 14,
  '238FA': 14,
  '238FB': 14,
  '238FC': 14,
  '238FD': 14,
  '238FE': 14,
  '238FF': 14,
  '23900': 14,
  '23901': 14,
  '23902': 14,
  '23903': 14,
  '23904': 14,
  '23905': 14,
  '23906': 14,
  '23907': 14,
  '23908': 15,
  '23909': 15,
  '2390A': 15,
  '2390B': 15,
  '2390C': 15,
  '2390D': 15,
  '2390E': 15,
  '2390F': 15,
  '23910': 15,
  '23911': 15,
  '23912': 15,
  '23913': 15,
  '23914': 15,
  '23915': 15,
  '23916': 15,
  '23917': 15,
  '23918': 16,
  '23919': 16,
  '2391A': 16,
  '2391B': 16,
  '2391C': 16,
  '2391D': 16,
  '2391E': 16,
  '2391F': 16,
  '23920': 17,
  '23921': 17,
  '23922': 17,
  '23923': 17,
  '23924': 17,
  '23925': 17,
  '23926': 17,
  '23927': 17,
  '23928': 18,
  '23929': 18,
  '2392A': 18,
  '2392B': 18,
  '2392C': 19,
  '2392D': 19,
  '2392E': 19,
  '2392F': 19,
  '23930': 19,
  '23931': 20,
  '23932': 20,
  '23933': 20,
  '23934': 21,
  '23935': 21,
  '23936': 22,
  '23937': 21,
  '23938': 21,
  '23939': 22,
  '2393A': 22,
  '2393B': 22,
  '2393C': 22,
  '2393D': 23,
  '2393E': 24,
  '2393F': 25,
  '23940': 25,
  '23941': 26,
  '23942': 4,
  '23943': 5,
  '23944': 5,
  '23945': 6,
  '23946': 6,
  '23947': 7,
  '23948': 7,
  '23949': 8,
  '2394A': 7,
  '2394B': 7,
  '2394C': 7,
  '2394D': 8,
  '2394E': 8,
  '2394F': 8,
  '23950': 8,
  '23951': 8,
  '23952': 8,
  '23953': 8,
  '23954': 8,
  '23955': 8,
  '23956': 8,
  '23957': 8,
  '23958': 8,
  '23959': 8,
  '2395A': 8,
  '2395B': 8,
  '2395C': 8,
  '2395D': 8,
  '2395E': 8,
  '2395F': 8,
  '23960': 8,
  '23961': 8,
  '23962': 9,
  '23963': 9,
  '23964': 9,
  '23965': 8,
  '23966': 10,
  '23967': 10,
  '23968': 10,
  '23969': 10,
  '2396A': 10,
  '2396B': 10,
  '2396C': 10,
  '2396D': 10,
  '2396E': 10,
  '2396F': 10,
  '23970': 10,
  '23971': 10,
  '23972': 10,
  '23973': 11,
  '23974': 11,
  '23975': 11,
  '23976': 11,
  '23977': 11,
  '23978': 11,
  '23979': 12,
  '2397A': 12,
  '2397B': 12,
  '2397C': 12,
  '2397D': 12,
  '2397E': 12,
  '2397F': 12,
  '23980': 12,
  '23981': 12,
  '23982': 12,
  '23983': 12,
  '23984': 12,
  '23985': 12,
  '23986': 12,
  '23987': 13,
  '23988': 13,
  '23989': 13,
  '2398A': 13,
  '2398B': 15,
  '2398C': 13,
  '2398D': 13,
  '2398E': 13,
  '2398F': 13,
  '23990': 14,
  '23991': 14,
  '23992': 14,
  '23993': 14,
  '23994': 15,
  '23995': 15,
  '23996': 15,
  '23997': 15,
  '23998': 15,
  '23999': 15,
  '2399A': 15,
  '2399B': 15,
  '2399C': 16,
  '2399D': 16,
  '2399E': 16,
  '2399F': 16,
  '239A0': 16,
  '239A1': 17,
  '239A2': 17,
  '239A3': 17,
  '239A4': 17,
  '239A5': 17,
  '239A6': 18,
  '239A7': 18,
  '239A8': 18,
  '239A9': 18,
  '239AA': 18,
  '239AB': 18,
  '239AC': 19,
  '239AD': 19,
  '239AE': 21,
  '239AF': 22,
  '239B0': 22,
  '239B1': 23,
  '239B2': 23,
  '239B3': 24,
  '239B4': 24,
  '239B5': 4,
  '239B6': 4,
  '239B7': 5,
  '239B8': 6,
  '239B9': 6,
  '239BA': 6,
  '239BB': 6,
  '239BC': 6,
  '239BD': 6,
  '239BE': 6,
  '239BF': 7,
  '239C0': 7,
  '239C1': 7,
  '239C2': 7,
  '239C3': 7,
  '239C4': 7,
  '239C5': 7,
  '239C6': 7,
  '239C7': 7,
  '239C8': 7,
  '239C9': 8,
  '239CA': 8,
  '239CB': 8,
  '239CC': 8,
  '239CD': 8,
  '239CE': 8,
  '239CF': 8,
  '239D0': 8,
  '239D1': 8,
  '239D2': 8,
  '239D3': 8,
  '239D4': 8,
  '239D5': 8,
  '239D6': 8,
  '239D7': 8,
  '239D8': 8,
  '239D9': 8,
  '239DA': 8,
  '239DB': 8,
  '239DC': 8,
  '239DD': 9,
  '239DE': 9,
  '239DF': 9,
  '239E0': 9,
  '239E1': 9,
  '239E2': 9,
  '239E3': 9,
  '239E4': 9,
  '239E5': 9,
  '239E6': 9,
  '239E7': 9,
  '239E8': 9,
  '239E9': 9,
  '239EA': 9,
  '239EB': 9,
  '239EC': 9,
  '239ED': 9,
  '239EE': 9,
  '239EF': 9,
  '239F0': 9,
  '239F1': 9,
  '239F2': 10,
  '239F3': 10,
  '239F4': 10,
  '239F5': 10,
  '239F6': 10,
  '239F7': 10,
  '239F8': 10,
  '239F9': 10,
  '239FA': 10,
  '239FB': 10,
  '239FC': 10,
  '239FD': 10,
  '239FE': 10,
  '239FF': 10,
  '23A00': 10,
  '23A01': 10,
  '23A02': 10,
  '23A03': 10,
  '23A04': 10,
  '23A05': 11,
  '23A06': 11,
  '23A07': 11,
  '23A08': 11,
  '23A09': 11,
  '23A0A': 11,
  '23A0B': 11,
  '23A0C': 11,
  '23A0D': 11,
  '23A0E': 11,
  '23A0F': 11,
  '23A10': 11,
  '23A11': 11,
  '23A12': 11,
  '23A13': 11,
  '23A14': 11,
  '23A15': 11,
  '23A16': 12,
  '23A17': 12,
  '23A18': 12,
  '23A19': 12,
  '23A1A': 12,
  '23A1B': 12,
  '23A1C': 12,
  '23A1D': 12,
  '23A1E': 12,
  '23A1F': 12,
  '23A20': 12,
  '23A21': 12,
  '23A22': 12,
  '23A23': 12,
  '23A24': 12,
  '23A25': 12,
  '23A26': 12,
  '23A27': 12,
  '23A28': 12,
  '23A29': 12,
  '23A2A': 12,
  '23A2B': 12,
  '23A2C': 12,
  '23A2D': 12,
  '23A2E': 12,
  '23A2F': 12,
  '23A30': 12,
  '23A31': 13,
  '23A32': 13,
  '23A33': 13,
  '23A34': 13,
  '23A35': 13,
  '23A36': 13,
  '23A37': 13,
  '23A38': 13,
  '23A39': 13,
  '23A3A': 13,
  '23A3B': 13,
  '23A3C': 13,
  '23A3D': 13,
  '23A3E': 13,
  '23A3F': 13,
  '23A40': 13,
  '23A41': 13,
  '23A42': 13,
  '23A43': 13,
  '23A44': 14,
  '23A45': 14,
  '23A46': 14,
  '23A47': 14,
  '23A48': 14,
  '23A49': 14,
  '23A4A': 14,
  '23A4B': 14,
  '23A4C': 14,
  '23A4D': 15,
  '23A4E': 15,
  '23A4F': 15,
  '23A50': 15,
  '23A51': 15,
  '23A52': 15,
  '23A53': 15,
  '23A54': 15,
  '23A55': 15,
  '23A56': 15,
  '23A57': 15,
  '23A58': 15,
  '23A59': 15,
  '23A5A': 15,
  '23A5B': 15,
  '23A5C': 15,
  '23A5D': 16,
  '23A5E': 16,
  '23A5F': 16,
  '23A60': 16,
  '23A61': 16,
  '23A62': 16,
  '23A63': 16,
  '23A64': 16,
  '23A65': 16,
  '23A66': 16,
  '23A67': 16,
  '23A68': 17,
  '23A69': 17,
  '23A6A': 17,
  '23A6B': 17,
  '23A6C': 17,
  '23A6D': 17,
  '23A6E': 17,
  '23A6F': 18,
  '23A70': 18,
  '23A71': 18,
  '23A72': 18,
  '23A73': 18,
  '23A74': 18,
  '23A75': 18,
  '23A76': 18,
  '23A77': 19,
  '23A78': 19,
  '23A79': 20,
  '23A7A': 20,
  '23A7B': 20,
  '23A7C': 21,
  '23A7D': 21,
  '23A7E': 22,
  '23A7F': 23,
  '23A80': 23,
  '23A81': 23,
  '23A82': 8,
  '23A83': 8,
  '23A84': 8,
  '23A85': 9,
  '23A86': 9,
  '23A87': 9,
  '23A88': 10,
  '23A89': 10,
  '23A8A': 10,
  '23A8B': 11,
  '23A8C': 11,
  '23A8D': 11,
  '23A8E': 11,
  '23A8F': 11,
  '23A90': 11,
  '23A91': 11,
  '23A92': 11,
  '23A93': 11,
  '23A94': 11,
  '23A95': 11,
  '23A96': 11,
  '23A97': 11,
  '23A98': 11,
  '23A99': 12,
  '23A9A': 12,
  '23A9B': 12,
  '23A9C': 12,
  '23A9D': 12,
  '23A9E': 12,
  '23A9F': 12,
  '23AA0': 13,
  '23AA1': 13,
  '23AA2': 13,
  '23AA3': 13,
  '23AA4': 13,
  '23AA5': 13,
  '23AA6': 13,
  '23AA7': 13,
  '23AA8': 13,
  '23AA9': 13,
  '23AAA': 13,
  '23AAB': 13,
  '23AAC': 13,
  '23AAD': 13,
  '23AAE': 14,
  '23AAF': 14,
  '23AB0': 14,
  '23AB1': 14,
  '23AB2': 14,
  '23AB3': 14,
  '23AB4': 14,
  '23AB5': 14,
  '23AB6': 15,
  '23AB7': 15,
  '23AB8': 15,
  '23AB9': 15,
  '23ABA': 15,
  '23ABB': 16,
  '23ABC': 16,
  '23ABD': 16,
  '23ABE': 16,
  '23ABF': 16,
  '23AC0': 16,
  '23AC1': 16,
  '23AC2': 16,
  '23AC3': 16,
  '23AC4': 16,
  '23AC5': 16,
  '23AC6': 16,
  '23AC7': 16,
  '23AC8': 16,
  '23AC9': 16,
  '23ACA': 16,
  '23ACB': 16,
  '23ACC': 17,
  '23ACD': 17,
  '23ACE': 17,
  '23ACF': 17,
  '23AD0': 18,
  '23AD1': 18,
  '23AD2': 18,
  '23AD3': 18,
  '23AD4': 18,
  '23AD5': 18,
  '23AD6': 18,
  '23AD7': 18,
  '23AD8': 19,
  '23AD9': 19,
  '23ADA': 19,
  '23ADB': 19,
  '23ADC': 19,
  '23ADD': 19,
  '23ADE': 20,
  '23ADF': 20,
  '23AE0': 21,
  '23AE1': 21,
  '23AE2': 23,
  '23AE3': 23,
  '23AE4': 23,
  '23AE5': 23,
  '23AE6': 24,
  '23AE7': 24,
  '23AE8': 24,
  '23AE9': 25,
  '23AEA': 27,
  '23AEB': 27,
  '23AEC': 4,
  '23AED': 6,
  '23AEE': 7,
  '23AEF': 8,
  '23AF0': 8,
  '23AF1': 8,
  '23AF2': 8,
  '23AF3': 9,
  '23AF4': 10,
  '23AF5': 11,
  '23AF6': 11,
  '23AF7': 11,
  '23AF8': 11,
  '23AF9': 13,
  '23AFA': 14,
  '23AFB': 14,
  '23AFC': 14,
  '23AFD': 16,
  '23AFE': 16,
  '23AFF': 17,
  '23B00': 19,
  '23B01': 22,
  '23B02': 7,
  '23B03': 7,
  '23B04': 8,
  '23B05': 8,
  '23B06': 8,
  '23B07': 8,
  '23B08': 10,
  '23B09': 10,
  '23B0A': 10,
  '23B0B': 11,
  '23B0C': 11,
  '23B0D': 13,
  '23B0E': 14,
  '23B0F': 14,
  '23B10': 14,
  '23B11': 14,
  '23B12': 15,
  '23B13': 16,
  '23B14': 16,
  '23B15': 17,
  '23B16': 19,
  '23B17': 20,
  '23B18': 20,
  '23B19': 22,
  '23B1A': 27,
  '23B1B': 4,
  '23B1C': 6,
  '23B1D': 6,
  '23B1E': 6,
  '23B1F': 6,
  '23B20': 6,
  '23B21': 7,
  '23B22': 7,
  '23B23': 7,
  '23B24': 7,
  '23B25': 7,
  '23B26': 7,
  '23B27': 7,
  '23B28': 7,
  '23B29': 8,
  '23B2A': 8,
  '23B2B': 8,
  '23B2C': 8,
  '23B2D': 8,
  '23B2E': 8,
  '23B2F': 8,
  '23B30': 8,
  '23B31': 8,
  '23B32': 8,
  '23B33': 8,
  '23B34': 8,
  '23B35': 8,
  '23B36': 8,
  '23B37': 8,
  '23B38': 9,
  '23B39': 9,
  '23B3A': 9,
  '23B3B': 9,
  '23B3C': 9,
  '23B3D': 9,
  '23B3E': 9,
  '23B3F': 9,
  '23B40': 9,
  '23B41': 9,
  '23B42': 9,
  '23B43': 9,
  '23B44': 9,
  '23B45': 9,
  '23B46': 9,
  '23B47': 9,
  '23B48': 9,
  '23B49': 9,
  '23B4A': 9,
  '23B4B': 9,
  '23B4C': 9,
  '23B4D': 9,
  '23B4E': 9,
  '23B4F': 9,
  '23B50': 9,
  '23B51': 9,
  '23B52': 9,
  '23B53': 9,
  '23B54': 9,
  '23B55': 9,
  '23B56': 9,
  '23B57': 9,
  '23B58': 9,
  '23B59': 9,
  '23B5A': 9,
  '23B5B': 9,
  '23B5C': 10,
  '23B5D': 10,
  '23B5E': 10,
  '23B5F': 10,
  '23B60': 10,
  '23B61': 10,
  '23B62': 10,
  '23B63': 10,
  '23B64': 10,
  '23B65': 10,
  '23B66': 10,
  '23B67': 10,
  '23B68': 10,
  '23B69': 10,
  '23B6A': 10,
  '23B6B': 10,
  '23B6C': 10,
  '23B6D': 10,
  '23B6E': 10,
  '23B6F': 10,
  '23B70': 10,
  '23B71': 11,
  '23B72': 11,
  '23B73': 11,
  '23B74': 11,
  '23B75': 11,
  '23B76': 11,
  '23B77': 11,
  '23B78': 11,
  '23B79': 11,
  '23B7A': 11,
  '23B7B': 11,
  '23B7C': 11,
  '23B7D': 11,
  '23B7E': 11,
  '23B7F': 11,
  '23B80': 11,
  '23B81': 11,
  '23B82': 11,
  '23B83': 11,
  '23B84': 11,
  '23B85': 11,
  '23B86': 11,
  '23B87': 11,
  '23B88': 12,
  '23B89': 12,
  '23B8A': 12,
  '23B8B': 12,
  '23B8C': 12,
  '23B8D': 12,
  '23B8E': 12,
  '23B8F': 12,
  '23B90': 12,
  '23B91': 12,
  '23B92': 12,
  '23B93': 12,
  '23B94': 12,
  '23B95': 12,
  '23B96': 12,
  '23B97': 12,
  '23B98': 12,
  '23B99': 12,
  '23B9A': 12,
  '23B9B': 12,
  '23B9C': 12,
  '23B9D': 12,
  '23B9E': 12,
  '23B9F': 12,
  '23BA0': 12,
  '23BA1': 12,
  '23BA2': 12,
  '23BA3': 12,
  '23BA4': 12,
  '23BA5': 12,
  '23BA6': 12,
  '23BA7': 12,
  '23BA8': 13,
  '23BA9': 13,
  '23BAA': 13,
  '23BAB': 13,
  '23BAC': 13,
  '23BAD': 13,
  '23BAE': 13,
  '23BAF': 13,
  '23BB0': 13,
  '23BB1': 13,
  '23BB2': 13,
  '23BB3': 13,
  '23BB4': 13,
  '23BB5': 13,
  '23BB6': 13,
  '23BB7': 13,
  '23BB8': 13,
  '23BB9': 13,
  '23BBA': 13,
  '23BBB': 13,
  '23BBC': 13,
  '23BBD': 13,
  '23BBE': 13,
  '23BBF': 13,
  '23BC0': 13,
  '23BC1': 13,
  '23BC2': 13,
  '23BC3': 13,
  '23BC4': 13,
  '23BC5': 14,
  '23BC6': 14,
  '23BC7': 14,
  '23BC8': 14,
  '23BC9': 14,
  '23BCA': 14,
  '23BCB': 14,
  '23BCC': 14,
  '23BCD': 14,
  '23BCE': 14,
  '23BCF': 14,
  '23BD0': 14,
  '23BD1': 14,
  '23BD2': 14,
  '23BD3': 14,
  '23BD4': 14,
  '23BD5': 14,
  '23BD6': 14,
  '23BD7': 14,
  '23BD8': 14,
  '23BD9': 14,
  '23BDA': 14,
  '23BDB': 14,
  '23BDC': 14,
  '23BDD': 14,
  '23BDE': 14,
  '23BDF': 14,
  '23BE0': 14,
  '23BE1': 14,
  '23BE2': 14,
  '23BE3': 15,
  '23BE4': 15,
  '23BE5': 15,
  '23BE6': 15,
  '23BE7': 15,
  '23BE8': 15,
  '23BE9': 15,
  '23BEA': 15,
  '23BEB': 15,
  '23BEC': 15,
  '23BED': 15,
  '23BEE': 15,
  '23BEF': 15,
  '23BF0': 15,
  '23BF1': 15,
  '23BF2': 15,
  '23BF3': 15,
  '23BF4': 15,
  '23BF5': 15,
  '23BF6': 15,
  '23BF7': 15,
  '23BF8': 16,
  '23BF9': 16,
  '23BFA': 16,
  '23BFB': 16,
  '23BFC': 16,
  '23BFD': 16,
  '23BFE': 16,
  '23BFF': 16,
  '23C00': 16,
  '23C01': 16,
  '23C02': 16,
  '23C03': 16,
  '23C04': 16,
  '23C05': 16,
  '23C06': 16,
  '23C07': 16,
  '23C08': 16,
  '23C09': 16,
  '23C0A': 17,
  '23C0B': 17,
  '23C0C': 17,
  '23C0D': 17,
  '23C0E': 17,
  '23C0F': 17,
  '23C10': 17,
  '23C11': 17,
  '23C12': 17,
  '23C13': 17,
  '23C14': 17,
  '23C15': 17,
  '23C16': 17,
  '23C17': 17,
  '23C18': 17,
  '23C19': 17,
  '23C1A': 18,
  '23C1B': 18,
  '23C1C': 18,
  '23C1D': 18,
  '23C1E': 18,
  '23C1F': 18,
  '23C20': 18,
  '23C21': 18,
  '23C22': 18,
  '23C23': 18,
  '23C24': 18,
  '23C25': 18,
  '23C26': 18,
  '23C27': 18,
  '23C28': 18,
  '23C29': 18,
  '23C2A': 18,
  '23C2B': 19,
  '23C2C': 19,
  '23C2D': 19,
  '23C2E': 19,
  '23C2F': 19,
  '23C30': 19,
  '23C31': 20,
  '23C32': 20,
  '23C33': 20,
  '23C34': 20,
  '23C35': 20,
  '23C36': 21,
  '23C37': 21,
  '23C38': 21,
  '23C39': 21,
  '23C3A': 21,
  '23C3B': 22,
  '23C3C': 22,
  '23C3D': 22,
  '23C3E': 22,
  '23C3F': 23,
  '23C40': 23,
  '23C41': 23,
  '23C42': 26,
  '23C43': 26,
  '23C44': 28,
  '23C45': 8,
  '23C46': 8,
  '23C47': 9,
  '23C48': 9,
  '23C49': 9,
  '23C4A': 9,
  '23C4B': 11,
  '23C4C': 11,
  '23C4D': 11,
  '23C4E': 11,
  '23C4F': 13,
  '23C50': 14,
  '23C51': 16,
  '23C52': 17,
  '23C53': 18,
  '23C54': 18,
  '23C55': 6,
  '23C56': 6,
  '23C57': 7,
  '23C58': 7,
  '23C59': 8,
  '23C5A': 8,
  '23C5B': 8,
  '23C5C': 9,
  '23C5D': 9,
  '23C5E': 9,
  '23C5F': 9,
  '23C60': 9,
  '23C61': 10,
  '23C62': 12,
  '23C63': 11,
  '23C64': 12,
  '23C65': 12,
  '23C66': 13,
  '23C67': 13,
  '23C68': 13,
  '23C69': 14,
  '23C6A': 15,
  '23C6B': 15,
  '23C6C': 17,
  '23C6D': 17,
  '23C6E': 18,
  '23C6F': 21,
  '23C70': 21,
  '23C71': 3,
  '23C72': 4,
  '23C73': 4,
  '23C74': 4,
  '23C75': 5,
  '23C76': 5,
  '23C77': 5,
  '23C78': 5,
  '23C79': 5,
  '23C7A': 5,
  '23C7B': 5,
  '23C7C': 5,
  '23C7D': 5,
  '23C7E': 5,
  '23C7F': 5,
  '23C80': 6,
  '23C81': 6,
  '23C82': 6,
  '23C83': 6,
  '23C84': 6,
  '23C85': 6,
  '23C86': 6,
  '23C87': 6,
  '23C88': 6,
  '23C89': 6,
  '23C8A': 6,
  '23C8B': 6,
  '23C8C': 6,
  '23C8D': 6,
  '23C8E': 7,
  '23C8F': 7,
  '23C90': 7,
  '23C91': 7,
  '23C92': 7,
  '23C93': 7,
  '23C94': 7,
  '23C95': 7,
  '23C96': 7,
  '23C97': 7,
  '23C98': 7,
  '23C99': 7,
  '23C9A': 7,
  '23C9B': 7,
  '23C9C': 7,
  '23C9D': 7,
  '23C9E': 7,
  '23C9F': 7,
  '23CA0': 7,
  '23CA1': 7,
  '23CA2': 7,
  '23CA3': 7,
  '23CA4': 7,
  '23CA5': 7,
  '23CA6': 7,
  '23CA7': 7,
  '23CA8': 7,
  '23CA9': 7,
  '23CAA': 7,
  '23CAB': 7,
  '23CAC': 7,
  '23CAD': 7,
  '23CAE': 7,
  '23CAF': 7,
  '23CB0': 7,
  '23CB1': 7,
  '23CB2': 8,
  '23CB3': 8,
  '23CB4': 8,
  '23CB5': 8,
  '23CB6': 8,
  '23CB7': 8,
  '23CB8': 8,
  '23CB9': 8,
  '23CBA': 8,
  '23CBB': 8,
  '23CBC': 8,
  '23CBD': 8,
  '23CBE': 8,
  '23CBF': 8,
  '23CC0': 8,
  '23CC1': 8,
  '23CC2': 8,
  '23CC3': 8,
  '23CC4': 8,
  '23CC5': 8,
  '23CC6': 8,
  '23CC7': 8,
  '23CC8': 8,
  '23CC9': 8,
  '23CCA': 8,
  '23CCB': 8,
  '23CCC': 8,
  '23CCD': 8,
  '23CCE': 8,
  '23CCF': 8,
  '23CD0': 8,
  '23CD1': 8,
  '23CD2': 8,
  '23CD3': 8,
  '23CD4': 8,
  '23CD5': 8,
  '23CD6': 8,
  '23CD7': 8,
  '23CD8': 8,
  '23CD9': 8,
  '23CDA': 8,
  '23CDB': 8,
  '23CDC': 8,
  '23CDD': 8,
  '23CDE': 8,
  '23CDF': 8,
  '23CE0': 8,
  '23CE1': 8,
  '23CE2': 8,
  '23CE3': 8,
  '23CE4': 9,
  '23CE5': 9,
  '23CE6': 9,
  '23CE7': 9,
  '23CE8': 9,
  '23CE9': 9,
  '23CEA': 9,
  '23CEB': 9,
  '23CEC': 9,
  '23CED': 9,
  '23CEE': 9,
  '23CEF': 9,
  '23CF0': 9,
  '23CF1': 9,
  '23CF2': 9,
  '23CF3': 9,
  '23CF4': 9,
  '23CF5': 9,
  '23CF6': 9,
  '23CF7': 9,
  '23CF8': 9,
  '23CF9': 9,
  '23CFA': 9,
  '23CFB': 9,
  '23CFC': 9,
  '23CFD': 9,
  '23CFE': 9,
  '23CFF': 9,
  '23D00': 9,
  '23D01': 9,
  '23D02': 9,
  '23D03': 9,
  '23D04': 9,
  '23D05': 9,
  '23D06': 9,
  '23D07': 9,
  '23D08': 9,
  '23D09': 9,
  '23D0A': 9,
  '23D0B': 9,
  '23D0C': 9,
  '23D0D': 9,
  '23D0E': 9,
  '23D0F': 9,
  '23D10': 9,
  '23D11': 9,
  '23D12': 9,
  '23D13': 9,
  '23D14': 9,
  '23D15': 9,
  '23D16': 9,
  '23D17': 9,
  '23D18': 9,
  '23D19': 9,
  '23D1A': 9,
  '23D1B': 9,
  '23D1C': 9,
  '23D1D': 9,
  '23D1E': 10,
  '23D1F': 10,
  '23D20': 10,
  '23D21': 10,
  '23D22': 10,
  '23D23': 10,
  '23D24': 10,
  '23D25': 10,
  '23D26': 10,
  '23D27': 10,
  '23D28': 10,
  '23D29': 10,
  '23D2A': 10,
  '23D2B': 10,
  '23D2C': 10,
  '23D2D': 10,
  '23D2E': 10,
  '23D2F': 10,
  '23D30': 10,
  '23D31': 10,
  '23D32': 10,
  '23D33': 10,
  '23D34': 10,
  '23D35': 10,
  '23D36': 10,
  '23D37': 10,
  '23D38': 10,
  '23D39': 10,
  '23D3A': 10,
  '23D3B': 10,
  '23D3C': 10,
  '23D3D': 10,
  '23D3E': 10,
  '23D3F': 10,
  '23D40': 10,
  '23D41': 10,
  '23D42': 10,
  '23D43': 10,
  '23D44': 10,
  '23D45': 10,
  '23D46': 10,
  '23D47': 10,
  '23D48': 10,
  '23D49': 10,
  '23D4A': 10,
  '23D4B': 10,
  '23D4C': 10,
  '23D4D': 10,
  '23D4E': 10,
  '23D4F': 10,
  '23D50': 10,
  '23D51': 10,
  '23D52': 10,
  '23D53': 10,
  '23D54': 10,
  '23D55': 10,
  '23D56': 10,
  '23D57': 10,
  '23D58': 10,
  '23D59': 10,
  '23D5A': 10,
  '23D5B': 10,
  '23D5C': 10,
  '23D5D': 10,
  '23D5E': 10,
  '23D5F': 10,
  '23D60': 10,
  '23D61': 10,
  '23D62': 10,
  '23D63': 10,
  '23D64': 10,
  '23D65': 10,
  '23D66': 10,
  '23D67': 10,
  '23D68': 10,
  '23D69': 10,
  '23D6A': 10,
  '23D6B': 10,
  '23D6C': 10,
  '23D6D': 10,
  '23D6E': 10,
  '23D6F': 10,
  '23D70': 10,
  '23D71': 10,
  '23D72': 10,
  '23D73': 10,
  '23D74': 10,
  '23D75': 10,
  '23D76': 10,
  '23D77': 11,
  '23D78': 11,
  '23D79': 11,
  '23D7A': 11,
  '23D7B': 11,
  '23D7C': 11,
  '23D7D': 11,
  '23D7E': 11,
  '23D7F': 11,
  '23D80': 11,
  '23D81': 11,
  '23D82': 11,
  '23D83': 11,
  '23D84': 11,
  '23D85': 11,
  '23D86': 11,
  '23D87': 11,
  '23D88': 11,
  '23D89': 11,
  '23D8A': 11,
  '23D8B': 11,
  '23D8C': 11,
  '23D8D': 11,
  '23D8E': 11,
  '23D8F': 11,
  '23D90': 11,
  '23D91': 11,
  '23D92': 11,
  '23D93': 11,
  '23D94': 11,
  '23D95': 11,
  '23D96': 11,
  '23D97': 11,
  '23D98': 11,
  '23D99': 11,
  '23D9A': 11,
  '23D9B': 11,
  '23D9C': 11,
  '23D9D': 11,
  '23D9E': 11,
  '23D9F': 11,
  '23DA0': 11,
  '23DA1': 11,
  '23DA2': 11,
  '23DA3': 11,
  '23DA4': 11,
  '23DA5': 11,
  '23DA6': 11,
  '23DA7': 11,
  '23DA8': 11,
  '23DA9': 11,
  '23DAA': 11,
  '23DAB': 11,
  '23DAC': 11,
  '23DAD': 11,
  '23DAE': 11,
  '23DAF': 11,
  '23DB0': 11,
  '23DB1': 11,
  '23DB2': 11,
  '23DB3': 11,
  '23DB4': 11,
  '23DB5': 11,
  '23DB6': 11,
  '23DB7': 11,
  '23DB8': 11,
  '23DB9': 11,
  '23DBA': 11,
  '23DBB': 11,
  '23DBC': 11,
  '23DBD': 11,
  '23DBE': 11,
  '23DBF': 11,
  '23DC0': 11,
  '23DC1': 11,
  '23DC2': 11,
  '23DC3': 11,
  '23DC4': 11,
  '23DC5': 11,
  '23DC6': 11,
  '23DC7': 11,
  '23DC8': 11,
  '23DC9': 11,
  '23DCA': 11,
  '23DCB': 11,
  '23DCC': 11,
  '23DCD': 11,
  '23DCE': 11,
  '23DCF': 11,
  '23DD0': 11,
  '23DD1': 11,
  '23DD2': 11,
  '23DD3': 11,
  '23DD4': 11,
  '23DD5': 11,
  '23DD6': 11,
  '23DD7': 11,
  '23DD8': 11,
  '23DD9': 11,
  '23DDA': 10,
  '23DDB': 11,
  '23DDC': 11,
  '23DDD': 11,
  '23DDE': 11,
  '23DDF': 11,
  '23DE0': 11,
  '23DE1': 11,
  '23DE2': 11,
  '23DE3': 11,
  '23DE4': 11,
  '23DE5': 11,
  '23DE6': 11,
  '23DE7': 11,
  '23DE8': 11,
  '23DE9': 11,
  '23DEA': 11,
  '23DEB': 11,
  '23DEC': 11,
  '23DED': 11,
  '23DEE': 11,
  '23DEF': 11,
  '23DF0': 11,
  '23DF1': 11,
  '23DF2': 11,
  '23DF3': 11,
  '23DF4': 11,
  '23DF5': 11,
  '23DF6': 11,
  '23DF7': 11,
  '23DF8': 11,
  '23DF9': 11,
  '23DFA': 11,
  '23DFB': 11,
  '23DFC': 12,
  '23DFD': 12,
  '23DFE': 12,
  '23DFF': 12,
  '23E00': 12,
  '23E01': 12,
  '23E02': 12,
  '23E03': 12,
  '23E04': 12,
  '23E05': 12,
  '23E06': 12,
  '23E07': 12,
  '23E08': 12,
  '23E09': 12,
  '23E0A': 11,
  '23E0B': 12,
  '23E0C': 12,
  '23E0D': 12,
  '23E0E': 12,
  '23E0F': 12,
  '23E10': 12,
  '23E11': 12,
  '23E12': 12,
  '23E13': 12,
  '23E14': 12,
  '23E15': 12,
  '23E16': 12,
  '23E17': 12,
  '23E18': 12,
  '23E19': 12,
  '23E1A': 12,
  '23E1B': 12,
  '23E1C': 12,
  '23E1D': 12,
  '23E1E': 12,
  '23E1F': 12,
  '23E20': 12,
  '23E21': 12,
  '23E22': 12,
  '23E23': 12,
  '23E24': 12,
  '23E25': 12,
  '23E26': 12,
  '23E27': 12,
  '23E28': 12,
  '23E29': 12,
  '23E2A': 12,
  '23E2B': 12,
  '23E2C': 12,
  '23E2D': 12,
  '23E2E': 12,
  '23E2F': 12,
  '23E30': 12,
  '23E31': 12,
  '23E32': 12,
  '23E33': 12,
  '23E34': 12,
  '23E35': 12,
  '23E36': 12,
  '23E37': 12,
  '23E38': 12,
  '23E39': 12,
  '23E3A': 12,
  '23E3B': 12,
  '23E3C': 12,
  '23E3D': 12,
  '23E3E': 12,
  '23E3F': 12,
  '23E40': 12,
  '23E41': 12,
  '23E42': 12,
  '23E43': 12,
  '23E44': 12,
  '23E45': 12,
  '23E46': 12,
  '23E47': 12,
  '23E48': 12,
  '23E49': 12,
  '23E4A': 12,
  '23E4B': 12,
  '23E4C': 12,
  '23E4D': 12,
  '23E4E': 12,
  '23E4F': 12,
  '23E50': 12,
  '23E51': 12,
  '23E52': 12,
  '23E53': 12,
  '23E54': 12,
  '23E55': 12,
  '23E56': 12,
  '23E57': 12,
  '23E58': 12,
  '23E59': 12,
  '23E5A': 12,
  '23E5B': 12,
  '23E5C': 12,
  '23E5D': 13,
  '23E5E': 13,
  '23E5F': 13,
  '23E60': 13,
  '23E61': 13,
  '23E62': 13,
  '23E63': 13,
  '23E64': 13,
  '23E65': 13,
  '23E66': 13,
  '23E67': 13,
  '23E68': 13,
  '23E69': 13,
  '23E6A': 13,
  '23E6B': 13,
  '23E6C': 13,
  '23E6D': 13,
  '23E6E': 13,
  '23E6F': 13,
  '23E70': 13,
  '23E71': 13,
  '23E72': 13,
  '23E73': 13,
  '23E74': 13,
  '23E75': 13,
  '23E76': 13,
  '23E77': 13,
  '23E78': 13,
  '23E79': 13,
  '23E7A': 13,
  '23E7B': 13,
  '23E7C': 13,
  '23E7D': 13,
  '23E7E': 13,
  '23E7F': 13,
  '23E80': 13,
  '23E81': 13,
  '23E82': 13,
  '23E83': 13,
  '23E84': 13,
  '23E85': 13,
  '23E86': 13,
  '23E87': 13,
  '23E88': 13,
  '23E89': 13,
  '23E8A': 13,
  '23E8B': 13,
  '23E8C': 13,
  '23E8D': 13,
  '23E8E': 13,
  '23E8F': 13,
  '23E90': 13,
  '23E91': 13,
  '23E92': 13,
  '23E93': 13,
  '23E94': 13,
  '23E95': 13,
  '23E96': 13,
  '23E97': 13,
  '23E98': 13,
  '23E99': 13,
  '23E9A': 13,
  '23E9B': 13,
  '23E9C': 13,
  '23E9D': 13,
  '23E9E': 13,
  '23E9F': 13,
  '23EA0': 13,
  '23EA1': 13,
  '23EA2': 13,
  '23EA3': 13,
  '23EA4': 13,
  '23EA5': 13,
  '23EA6': 13,
  '23EA7': 13,
  '23EA8': 13,
  '23EA9': 13,
  '23EAA': 13,
  '23EAB': 13,
  '23EAC': 13,
  '23EAD': 13,
  '23EAE': 13,
  '23EAF': 13,
  '23EB0': 13,
  '23EB1': 13,
  '23EB2': 13,
  '23EB3': 13,
  '23EB4': 13,
  '23EB5': 13,
  '23EB6': 13,
  '23EB7': 13,
  '23EB8': 13,
  '23EB9': 13,
  '23EBA': 13,
  '23EBB': 13,
  '23EBC': 13,
  '23EBD': 13,
  '23EBE': 13,
  '23EBF': 13,
  '23EC0': 13,
  '23EC1': 13,
  '23EC2': 13,
  '23EC3': 13,
  '23EC4': 13,
  '23EC5': 13,
  '23EC6': 13,
  '23EC7': 13,
  '23EC8': 13,
  '23EC9': 13,
  '23ECA': 13,
  '23ECB': 13,
  '23ECC': 13,
  '23ECD': 14,
  '23ECE': 14,
  '23ECF': 14,
  '23ED0': 14,
  '23ED1': 14,
  '23ED2': 14,
  '23ED3': 14,
  '23ED4': 14,
  '23ED5': 14,
  '23ED6': 14,
  '23ED7': 14,
  '23ED8': 14,
  '23ED9': 14,
  '23EDA': 14,
  '23EDB': 14,
  '23EDC': 14,
  '23EDD': 14,
  '23EDE': 14,
  '23EDF': 14,
  '23EE0': 14,
  '23EE1': 14,
  '23EE2': 14,
  '23EE3': 14,
  '23EE4': 14,
  '23EE5': 14,
  '23EE6': 14,
  '23EE7': 14,
  '23EE8': 14,
  '23EE9': 14,
  '23EEA': 14,
  '23EEB': 14,
  '23EEC': 14,
  '23EED': 14,
  '23EEE': 14,
  '23EEF': 14,
  '23EF0': 14,
  '23EF1': 14,
  '23EF2': 14,
  '23EF3': 14,
  '23EF4': 15,
  '23EF5': 14,
  '23EF6': 14,
  '23EF7': 14,
  '23EF8': 14,
  '23EF9': 14,
  '23EFA': 14,
  '23EFB': 14,
  '23EFC': 14,
  '23EFD': 14,
  '23EFE': 14,
  '23EFF': 14,
  '23F00': 14,
  '23F01': 14,
  '23F02': 14,
  '23F03': 14,
  '23F04': 14,
  '23F05': 14,
  '23F06': 14,
  '23F07': 14,
  '23F08': 14,
  '23F09': 14,
  '23F0A': 14,
  '23F0B': 14,
  '23F0C': 14,
  '23F0D': 14,
  '23F0E': 14,
  '23F0F': 14,
  '23F10': 14,
  '23F11': 14,
  '23F12': 14,
  '23F13': 14,
  '23F14': 14,
  '23F15': 14,
  '23F16': 14,
  '23F17': 14,
  '23F18': 14,
  '23F19': 15,
  '23F1A': 14,
  '23F1B': 14,
  '23F1C': 14,
  '23F1D': 14,
  '23F1E': 14,
  '23F1F': 14,
  '23F20': 14,
  '23F21': 14,
  '23F22': 14,
  '23F23': 14,
  '23F24': 14,
  '23F25': 14,
  '23F26': 14,
  '23F27': 14,
  '23F28': 14,
  '23F29': 14,
  '23F2A': 14,
  '23F2B': 14,
  '23F2C': 14,
  '23F2D': 14,
  '23F2E': 14,
  '23F2F': 14,
  '23F30': 14,
  '23F31': 14,
  '23F32': 14,
  '23F33': 14,
  '23F34': 14,
  '23F35': 14,
  '23F36': 14,
  '23F37': 14,
  '23F38': 14,
  '23F39': 14,
  '23F3A': 14,
  '23F3B': 14,
  '23F3C': 14,
  '23F3D': 14,
  '23F3E': 14,
  '23F3F': 14,
  '23F40': 14,
  '23F41': 14,
  '23F42': 14,
  '23F43': 15,
  '23F44': 15,
  '23F45': 15,
  '23F46': 15,
  '23F47': 15,
  '23F48': 15,
  '23F49': 15,
  '23F4A': 15,
  '23F4B': 15,
  '23F4C': 15,
  '23F4D': 15,
  '23F4E': 15,
  '23F4F': 15,
  '23F50': 15,
  '23F51': 15,
  '23F52': 15,
  '23F53': 15,
  '23F54': 15,
  '23F55': 15,
  '23F56': 15,
  '23F57': 15,
  '23F58': 15,
  '23F59': 15,
  '23F5A': 15,
  '23F5B': 15,
  '23F5C': 15,
  '23F5D': 15,
  '23F5E': 15,
  '23F5F': 15,
  '23F60': 15,
  '23F61': 15,
  '23F62': 15,
  '23F63': 15,
  '23F64': 15,
  '23F65': 15,
  '23F66': 15,
  '23F67': 15,
  '23F68': 15,
  '23F69': 15,
  '23F6A': 15,
  '23F6B': 15,
  '23F6C': 15,
  '23F6D': 15,
  '23F6E': 15,
  '23F6F': 15,
  '23F70': 15,
  '23F71': 15,
  '23F72': 15,
  '23F73': 15,
  '23F74': 15,
  '23F75': 15,
  '23F76': 15,
  '23F77': 15,
  '23F78': 15,
  '23F79': 15,
  '23F7A': 15,
  '23F7B': 15,
  '23F7C': 15,
  '23F7D': 15,
  '23F7E': 15,
  '23F7F': 15,
  '23F80': 15,
  '23F81': 15,
  '23F82': 15,
  '23F83': 15,
  '23F84': 15,
  '23F85': 15,
  '23F86': 15,
  '23F87': 15,
  '23F88': 15,
  '23F89': 15,
  '23F8A': 15,
  '23F8B': 15,
  '23F8C': 15,
  '23F8D': 15,
  '23F8E': 15,
  '23F8F': 15,
  '23F90': 15,
  '23F91': 15,
  '23F92': 15,
  '23F93': 15,
  '23F94': 15,
  '23F95': 15,
  '23F96': 15,
  '23F97': 15,
  '23F98': 15,
  '23F99': 15,
  '23F9A': 15,
  '23F9B': 15,
  '23F9C': 15,
  '23F9D': 15,
  '23F9E': 15,
  '23F9F': 15,
  '23FA0': 15,
  '23FA1': 15,
  '23FA2': 15,
  '23FA3': 15,
  '23FA4': 15,
  '23FA5': 15,
  '23FA6': 15,
  '23FA7': 15,
  '23FA8': 15,
  '23FA9': 16,
  '23FAA': 15,
  '23FAB': 15,
  '23FAC': 15,
  '23FAD': 15,
  '23FAE': 15,
  '23FAF': 15,
  '23FB0': 15,
  '23FB1': 15,
  '23FB2': 15,
  '23FB3': 15,
  '23FB4': 15,
  '23FB5': 15,
  '23FB6': 15,
  '23FB7': 15,
  '23FB8': 15,
  '23FB9': 15,
  '23FBA': 15,
  '23FBB': 15,
  '23FBC': 15,
  '23FBD': 15,
  '23FBE': 15,
  '23FBF': 15,
  '23FC0': 17,
  '23FC1': 15,
  '23FC2': 15,
  '23FC3': 15,
  '23FC4': 15,
  '23FC5': 16,
  '23FC6': 16,
  '23FC7': 16,
  '23FC8': 16,
  '23FC9': 16,
  '23FCA': 16,
  '23FCB': 16,
  '23FCC': 16,
  '23FCD': 16,
  '23FCE': 16,
  '23FCF': 16,
  '23FD0': 16,
  '23FD1': 16,
  '23FD2': 16,
  '23FD3': 16,
  '23FD4': 16,
  '23FD5': 16,
  '23FD6': 16,
  '23FD7': 16,
  '23FD8': 16,
  '23FD9': 16,
  '23FDA': 16,
  '23FDB': 16,
  '23FDC': 16,
  '23FDD': 16,
  '23FDE': 16,
  '23FDF': 16,
  '23FE0': 16,
  '23FE1': 16,
  '23FE2': 16,
  '23FE3': 16,
  '23FE4': 16,
  '23FE5': 16,
  '23FE6': 16,
  '23FE7': 16,
  '23FE8': 16,
  '23FE9': 16,
  '23FEA': 16,
  '23FEB': 16,
  '23FEC': 16,
  '23FED': 16,
  '23FEE': 16,
  '23FEF': 16,
  '23FF0': 16,
  '23FF1': 16,
  '23FF2': 16,
  '23FF3': 16,
  '23FF4': 16,
  '23FF5': 16,
  '23FF6': 16,
  '23FF7': 16,
  '23FF8': 16,
  '23FF9': 16,
  '23FFA': 16,
  '23FFB': 16,
  '23FFC': 16,
  '23FFD': 16,
  '23FFE': 16,
  '23FFF': 16,
  '24000': 16,
  '24001': 16,
  '24002': 16,
  '24003': 16,
  '24004': 16,
  '24005': 16,
  '24006': 16,
  '24007': 16,
  '24008': 16,
  '24009': 16,
  '2400A': 16,
  '2400B': 16,
  '2400C': 16,
  '2400D': 16,
  '2400E': 16,
  '2400F': 16,
  '24010': 16,
  '24011': 16,
  '24012': 16,
  '24013': 16,
  '24014': 16,
  '24015': 16,
  '24016': 16,
  '24017': 16,
  '24018': 16,
  '24019': 16,
  '2401A': 16,
  '2401B': 16,
  '2401C': 16,
  '2401D': 16,
  '2401E': 16,
  '2401F': 16,
  '24020': 16,
  '24021': 16,
  '24022': 16,
  '24023': 17,
  '24024': 17,
  '24025': 17,
  '24026': 17,
  '24027': 17,
  '24028': 17,
  '24029': 17,
  '2402A': 17,
  '2402B': 17,
  '2402C': 17,
  '2402D': 17,
  '2402E': 17,
  '2402F': 17,
  '24030': 17,
  '24031': 17,
  '24032': 17,
  '24033': 17,
  '24034': 17,
  '24035': 17,
  '24036': 17,
  '24037': 17,
  '24038': 17,
  '24039': 17,
  '2403A': 17,
  '2403B': 17,
  '2403C': 17,
  '2403D': 17,
  '2403E': 17,
  '2403F': 17,
  '24040': 17,
  '24041': 17,
  '24042': 17,
  '24043': 17,
  '24044': 17,
  '24045': 17,
  '24046': 17,
  '24047': 17,
  '24048': 17,
  '24049': 17,
  '2404A': 17,
  '2404B': 17,
  '2404C': 17,
  '2404D': 17,
  '2404E': 17,
  '2404F': 17,
  '24050': 17,
  '24051': 17,
  '24052': 17,
  '24053': 17,
  '24054': 17,
  '24055': 17,
  '24056': 17,
  '24057': 17,
  '24058': 17,
  '24059': 17,
  '2405A': 17,
  '2405B': 17,
  '2405C': 17,
  '2405D': 17,
  '2405E': 18,
  '2405F': 17,
  '24060': 17,
  '24061': 18,
  '24062': 18,
  '24063': 18,
  '24064': 18,
  '24065': 18,
  '24066': 18,
  '24067': 18,
  '24068': 18,
  '24069': 18,
  '2406A': 18,
  '2406B': 18,
  '2406C': 18,
  '2406D': 18,
  '2406E': 18,
  '2406F': 18,
  '24070': 18,
  '24071': 18,
  '24072': 18,
  '24073': 18,
  '24074': 18,
  '24075': 18,
  '24076': 18,
  '24077': 18,
  '24078': 18,
  '24079': 18,
  '2407A': 18,
  '2407B': 18,
  '2407C': 18,
  '2407D': 18,
  '2407E': 18,
  '2407F': 18,
  '24080': 18,
  '24081': 18,
  '24082': 18,
  '24083': 18,
  '24084': 18,
  '24085': 18,
  '24086': 18,
  '24087': 18,
  '24088': 18,
  '24089': 18,
  '2408A': 18,
  '2408B': 18,
  '2408C': 18,
  '2408D': 18,
  '2408E': 18,
  '2408F': 18,
  '24090': 18,
  '24091': 18,
  '24092': 18,
  '24093': 18,
  '24094': 18,
  '24095': 18,
  '24096': 18,
  '24097': 18,
  '24098': 18,
  '24099': 18,
  '2409A': 18,
  '2409B': 18,
  '2409C': 18,
  '2409D': 19,
  '2409E': 18,
  '2409F': 18,
  '240A0': 18,
  '240A1': 19,
  '240A2': 18,
  '240A3': 18,
  '240A4': 18,
  '240A5': 18,
  '240A6': 18,
  '240A7': 18,
  '240A8': 18,
  '240A9': 18,
  '240AA': 18,
  '240AB': 18,
  '240AC': 18,
  '240AD': 18,
  '240AE': 18,
  '240AF': 18,
  '240B0': 18,
  '240B1': 18,
  '240B2': 18,
  '240B3': 19,
  '240B4': 19,
  '240B5': 19,
  '240B6': 19,
  '240B7': 19,
  '240B8': 19,
  '240B9': 19,
  '240BA': 19,
  '240BB': 19,
  '240BC': 19,
  '240BD': 19,
  '240BE': 19,
  '240BF': 19,
  '240C0': 19,
  '240C1': 19,
  '240C2': 19,
  '240C3': 19,
  '240C4': 19,
  '240C5': 19,
  '240C6': 19,
  '240C7': 19,
  '240C8': 19,
  '240C9': 19,
  '240CA': 19,
  '240CB': 19,
  '240CC': 19,
  '240CD': 19,
  '240CE': 19,
  '240CF': 19,
  '240D0': 19,
  '240D1': 19,
  '240D2': 19,
  '240D3': 19,
  '240D4': 19,
  '240D5': 19,
  '240D6': 19,
  '240D7': 19,
  '240D8': 19,
  '240D9': 19,
  '240DA': 19,
  '240DB': 19,
  '240DC': 19,
  '240DD': 19,
  '240DE': 19,
  '240DF': 19,
  '240E0': 19,
  '240E1': 19,
  '240E2': 19,
  '240E3': 19,
  '240E4': 19,
  '240E5': 19,
  '240E6': 19,
  '240E7': 19,
  '240E8': 20,
  '240E9': 20,
  '240EA': 20,
  '240EB': 20,
  '240EC': 20,
  '240ED': 20,
  '240EE': 20,
  '240EF': 20,
  '240F0': 20,
  '240F1': 20,
  '240F2': 20,
  '240F3': 20,
  '240F4': 20,
  '240F5': 20,
  '240F6': 20,
  '240F7': 20,
  '240F8': 20,
  '240F9': 20,
  '240FA': 20,
  '240FB': 20,
  '240FC': 20,
  '240FD': 20,
  '240FE': 20,
  '240FF': 20,
  '24100': 20,
  '24101': 20,
  '24102': 20,
  '24103': 20,
  '24104': 20,
  '24105': 20,
  '24106': 20,
  '24107': 20,
  '24108': 20,
  '24109': 20,
  '2410A': 20,
  '2410B': 20,
  '2410C': 20,
  '2410D': 20,
  '2410E': 21,
  '2410F': 21,
  '24110': 21,
  '24111': 21,
  '24112': 21,
  '24113': 21,
  '24114': 21,
  '24115': 21,
  '24116': 21,
  '24117': 21,
  '24118': 21,
  '24119': 21,
  '2411A': 21,
  '2411B': 21,
  '2411C': 21,
  '2411D': 21,
  '2411E': 21,
  '2411F': 21,
  '24120': 21,
  '24121': 21,
  '24122': 21,
  '24123': 21,
  '24124': 21,
  '24125': 21,
  '24126': 21,
  '24127': 21,
  '24128': 21,
  '24129': 21,
  '2412A': 21,
  '2412B': 21,
  '2412C': 21,
  '2412D': 21,
  '2412E': 21,
  '2412F': 21,
  '24130': 21,
  '24131': 21,
  '24132': 21,
  '24133': 21,
  '24134': 21,
  '24135': 21,
  '24136': 22,
  '24137': 22,
  '24138': 22,
  '24139': 22,
  '2413A': 22,
  '2413B': 22,
  '2413C': 22,
  '2413D': 22,
  '2413E': 22,
  '2413F': 22,
  '24140': 22,
  '24141': 22,
  '24142': 22,
  '24143': 22,
  '24144': 22,
  '24145': 22,
  '24146': 22,
  '24147': 22,
  '24148': 22,
  '24149': 22,
  '2414A': 23,
  '2414B': 23,
  '2414C': 23,
  '2414D': 23,
  '2414E': 23,
  '2414F': 23,
  '24150': 23,
  '24151': 23,
  '24152': 23,
  '24153': 23,
  '24154': 23,
  '24155': 23,
  '24156': 23,
  '24157': 23,
  '24158': 23,
  '24159': 23,
  '2415A': 23,
  '2415B': 23,
  '2415C': 23,
  '2415D': 23,
  '2415E': 23,
  '2415F': 23,
  '24160': 24,
  '24161': 24,
  '24162': 24,
  '24163': 24,
  '24164': 24,
  '24165': 24,
  '24166': 24,
  '24167': 24,
  '24168': 24,
  '24169': 25,
  '2416A': 25,
  '2416B': 25,
  '2416C': 25,
  '2416D': 25,
  '2416E': 25,
  '2416F': 25,
  '24170': 26,
  '24171': 26,
  '24172': 26,
  '24173': 26,
  '24174': 26,
  '24175': 26,
  '24176': 26,
  '24177': 27,
  '24178': 27,
  '24179': 27,
  '2417A': 27,
  '2417B': 27,
  '2417C': 28,
  '2417D': 28,
  '2417E': 29,
  '2417F': 30,
  '24180': 33,
  '24181': 35,
  '24182': 5,
  '24183': 6,
  '24184': 6,
  '24185': 6,
  '24186': 6,
  '24187': 6,
  '24188': 6,
  '24189': 6,
  '2418A': 6,
  '2418B': 6,
  '2418C': 6,
  '2418D': 7,
  '2418E': 7,
  '2418F': 7,
  '24190': 7,
  '24191': 7,
  '24192': 7,
  '24193': 7,
  '24194': 7,
  '24195': 7,
  '24196': 7,
  '24197': 7,
  '24198': 7,
  '24199': 7,
  '2419A': 7,
  '2419B': 7,
  '2419C': 7,
  '2419D': 8,
  '2419E': 8,
  '2419F': 8,
  '241A0': 8,
  '241A1': 8,
  '241A2': 8,
  '241A3': 8,
  '241A4': 8,
  '241A5': 8,
  '241A6': 8,
  '241A7': 8,
  '241A8': 8,
  '241A9': 8,
  '241AA': 8,
  '241AB': 8,
  '241AC': 8,
  '241AD': 8,
  '241AE': 8,
  '241AF': 8,
  '241B0': 8,
  '241B1': 8,
  '241B2': 8,
  '241B3': 8,
  '241B4': 8,
  '241B5': 8,
  '241B6': 8,
  '241B7': 8,
  '241B8': 8,
  '241B9': 8,
  '241BA': 8,
  '241BB': 8,
  '241BC': 9,
  '241BD': 9,
  '241BE': 9,
  '241BF': 9,
  '241C0': 9,
  '241C1': 9,
  '241C2': 9,
  '241C3': 9,
  '241C4': 9,
  '241C5': 9,
  '241C6': 9,
  '241C7': 9,
  '241C8': 9,
  '241C9': 9,
  '241CA': 9,
  '241CB': 9,
  '241CC': 9,
  '241CD': 9,
  '241CE': 9,
  '241CF': 9,
  '241D0': 9,
  '241D1': 9,
  '241D2': 9,
  '241D3': 9,
  '241D4': 9,
  '241D5': 9,
  '241D6': 9,
  '241D7': 9,
  '241D8': 9,
  '241D9': 9,
  '241DA': 9,
  '241DB': 9,
  '241DC': 9,
  '241DD': 9,
  '241DE': 9,
  '241DF': 9,
  '241E0': 9,
  '241E1': 9,
  '241E2': 9,
  '241E3': 9,
  '241E4': 9,
  '241E5': 9,
  '241E6': 9,
  '241E7': 9,
  '241E8': 9,
  '241E9': 9,
  '241EA': 9,
  '241EB': 9,
  '241EC': 9,
  '241ED': 9,
  '241EE': 9,
  '241EF': 10,
  '241F0': 10,
  '241F1': 10,
  '241F2': 10,
  '241F3': 10,
  '241F4': 10,
  '241F5': 10,
  '241F6': 10,
  '241F7': 10,
  '241F8': 10,
  '241F9': 10,
  '241FA': 10,
  '241FB': 10,
  '241FC': 10,
  '241FD': 10,
  '241FE': 10,
  '241FF': 10,
  '24200': 10,
  '24201': 10,
  '24202': 10,
  '24203': 10,
  '24204': 10,
  '24205': 10,
  '24206': 10,
  '24207': 10,
  '24208': 10,
  '24209': 10,
  '2420A': 10,
  '2420B': 10,
  '2420C': 10,
  '2420D': 10,
  '2420E': 10,
  '2420F': 10,
  '24210': 10,
  '24211': 10,
  '24212': 10,
  '24213': 10,
  '24214': 10,
  '24215': 10,
  '24216': 10,
  '24217': 10,
  '24218': 10,
  '24219': 10,
  '2421A': 10,
  '2421B': 10,
  '2421C': 10,
  '2421D': 10,
  '2421E': 10,
  '2421F': 10,
  '24220': 10,
  '24221': 10,
  '24222': 10,
  '24223': 10,
  '24224': 10,
  '24225': 11,
  '24226': 11,
  '24227': 11,
  '24228': 11,
  '24229': 11,
  '2422A': 11,
  '2422B': 11,
  '2422C': 11,
  '2422D': 11,
  '2422E': 11,
  '2422F': 11,
  '24230': 11,
  '24231': 11,
  '24232': 11,
  '24233': 11,
  '24234': 11,
  '24235': 11,
  '24236': 11,
  '24237': 11,
  '24238': 11,
  '24239': 11,
  '2423A': 11,
  '2423B': 11,
  '2423C': 11,
  '2423D': 11,
  '2423E': 11,
  '2423F': 11,
  '24240': 11,
  '24241': 11,
  '24242': 11,
  '24243': 11,
  '24244': 11,
  '24245': 11,
  '24246': 11,
  '24247': 11,
  '24248': 11,
  '24249': 11,
  '2424A': 11,
  '2424B': 11,
  '2424C': 11,
  '2424D': 11,
  '2424E': 11,
  '2424F': 11,
  '24250': 11,
  '24251': 11,
  '24252': 11,
  '24253': 11,
  '24254': 11,
  '24255': 11,
  '24256': 11,
  '24257': 11,
  '24258': 11,
  '24259': 11,
  '2425A': 11,
  '2425B': 11,
  '2425C': 11,
  '2425D': 11,
  '2425E': 11,
  '2425F': 11,
  '24260': 11,
  '24261': 11,
  '24262': 12,
  '24263': 12,
  '24264': 12,
  '24265': 12,
  '24266': 12,
  '24267': 12,
  '24268': 12,
  '24269': 12,
  '2426A': 12,
  '2426B': 12,
  '2426C': 12,
  '2426D': 12,
  '2426E': 12,
  '2426F': 12,
  '24270': 12,
  '24271': 12,
  '24272': 12,
  '24273': 12,
  '24274': 12,
  '24275': 12,
  '24276': 12,
  '24277': 12,
  '24278': 12,
  '24279': 12,
  '2427A': 12,
  '2427B': 12,
  '2427C': 12,
  '2427D': 12,
  '2427E': 12,
  '2427F': 12,
  '24280': 12,
  '24281': 12,
  '24282': 12,
  '24283': 12,
  '24284': 12,
  '24285': 12,
  '24286': 12,
  '24287': 12,
  '24288': 12,
  '24289': 12,
  '2428A': 12,
  '2428B': 12,
  '2428C': 12,
  '2428D': 12,
  '2428E': 12,
  '2428F': 12,
  '24290': 12,
  '24291': 12,
  '24292': 12,
  '24293': 12,
  '24294': 12,
  '24295': 12,
  '24296': 12,
  '24297': 12,
  '24298': 12,
  '24299': 12,
  '2429A': 12,
  '2429B': 12,
  '2429C': 12,
  '2429D': 12,
  '2429E': 12,
  '2429F': 12,
  '242A0': 12,
  '242A1': 12,
  '242A2': 12,
  '242A3': 12,
  '242A4': 12,
  '242A5': 12,
  '242A6': 12,
  '242A7': 12,
  '242A8': 12,
  '242A9': 12,
  '242AA': 12,
  '242AB': 12,
  '242AC': 12,
  '242AD': 12,
  '242AE': 12,
  '242AF': 12,
  '242B0': 12,
  '242B1': 12,
  '242B2': 13,
  '242B3': 13,
  '242B4': 13,
  '242B5': 13,
  '242B6': 13,
  '242B7': 13,
  '242B8': 13,
  '242B9': 13,
  '242BA': 13,
  '242BB': 13,
  '242BC': 13,
  '242BD': 13,
  '242BE': 13,
  '242BF': 13,
  '242C0': 13,
  '242C1': 13,
  '242C2': 13,
  '242C3': 13,
  '242C4': 13,
  '242C5': 13,
  '242C6': 13,
  '242C7': 13,
  '242C8': 13,
  '242C9': 13,
  '242CA': 13,
  '242CB': 13,
  '242CC': 13,
  '242CD': 13,
  '242CE': 13,
  '242CF': 13,
  '242D0': 13,
  '242D1': 13,
  '242D2': 13,
  '242D3': 13,
  '242D4': 13,
  '242D5': 13,
  '242D6': 13,
  '242D7': 13,
  '242D8': 13,
  '242D9': 13,
  '242DA': 13,
  '242DB': 13,
  '242DC': 13,
  '242DD': 13,
  '242DE': 13,
  '242DF': 13,
  '242E0': 13,
  '242E1': 13,
  '242E2': 13,
  '242E3': 13,
  '242E4': 13,
  '242E5': 13,
  '242E6': 13,
  '242E7': 13,
  '242E8': 13,
  '242E9': 13,
  '242EA': 13,
  '242EB': 13,
  '242EC': 13,
  '242ED': 13,
  '242EE': 13,
  '242EF': 13,
  '242F0': 13,
  '242F1': 13,
  '242F2': 13,
  '242F3': 11,
  '242F4': 13,
  '242F5': 13,
  '242F6': 13,
  '242F7': 13,
  '242F8': 13,
  '242F9': 13,
  '242FA': 13,
  '242FB': 13,
  '242FC': 13,
  '242FD': 13,
  '242FE': 13,
  '242FF': 13,
  '24300': 13,
  '24301': 13,
  '24302': 13,
  '24303': 13,
  '24304': 13,
  '24305': 13,
  '24306': 13,
  '24307': 14,
  '24308': 14,
  '24309': 14,
  '2430A': 14,
  '2430B': 14,
  '2430C': 14,
  '2430D': 14,
  '2430E': 14,
  '2430F': 14,
  '24310': 14,
  '24311': 14,
  '24312': 14,
  '24313': 14,
  '24314': 14,
  '24315': 14,
  '24316': 14,
  '24317': 14,
  '24318': 14,
  '24319': 14,
  '2431A': 14,
  '2431B': 14,
  '2431C': 14,
  '2431D': 14,
  '2431E': 14,
  '2431F': 14,
  '24320': 14,
  '24321': 14,
  '24322': 14,
  '24323': 14,
  '24324': 14,
  '24325': 14,
  '24326': 14,
  '24327': 14,
  '24328': 14,
  '24329': 14,
  '2432A': 14,
  '2432B': 14,
  '2432C': 14,
  '2432D': 14,
  '2432E': 14,
  '2432F': 14,
  '24330': 14,
  '24331': 14,
  '24332': 15,
  '24333': 14,
  '24334': 14,
  '24335': 14,
  '24336': 14,
  '24337': 14,
  '24338': 14,
  '24339': 14,
  '2433A': 14,
  '2433B': 14,
  '2433C': 14,
  '2433D': 14,
  '2433E': 14,
  '2433F': 14,
  '24340': 14,
  '24341': 14,
  '24342': 14,
  '24343': 14,
  '24344': 14,
  '24345': 14,
  '24346': 14,
  '24347': 14,
  '24348': 14,
  '24349': 14,
  '2434A': 14,
  '2434B': 14,
  '2434C': 14,
  '2434D': 14,
  '2434E': 14,
  '2434F': 13,
  '24350': 15,
  '24351': 15,
  '24352': 15,
  '24353': 15,
  '24354': 15,
  '24355': 15,
  '24356': 15,
  '24357': 15,
  '24358': 15,
  '24359': 15,
  '2435A': 15,
  '2435B': 15,
  '2435C': 15,
  '2435D': 15,
  '2435E': 15,
  '2435F': 15,
  '24360': 15,
  '24361': 15,
  '24362': 15,
  '24363': 15,
  '24364': 15,
  '24365': 15,
  '24366': 15,
  '24367': 15,
  '24368': 15,
  '24369': 16,
  '2436A': 15,
  '2436B': 15,
  '2436C': 15,
  '2436D': 15,
  '2436E': 15,
  '2436F': 15,
  '24370': 15,
  '24371': 15,
  '24372': 15,
  '24373': 15,
  '24374': 15,
  '24375': 15,
  '24376': 15,
  '24377': 15,
  '24378': 15,
  '24379': 15,
  '2437A': 15,
  '2437B': 15,
  '2437C': 15,
  '2437D': 15,
  '2437E': 15,
  '2437F': 15,
  '24380': 14,
  '24381': 15,
  '24382': 15,
  '24383': 15,
  '24384': 15,
  '24385': 15,
  '24386': 15,
  '24387': 15,
  '24388': 15,
  '24389': 15,
  '2438A': 15,
  '2438B': 15,
  '2438C': 15,
  '2438D': 15,
  '2438E': 15,
  '2438F': 15,
  '24390': 15,
  '24391': 15,
  '24392': 15,
  '24393': 15,
  '24394': 15,
  '24395': 15,
  '24396': 15,
  '24397': 15,
  '24398': 15,
  '24399': 15,
  '2439A': 15,
  '2439B': 15,
  '2439C': 15,
  '2439D': 16,
  '2439E': 16,
  '2439F': 16,
  '243A0': 16,
  '243A1': 16,
  '243A2': 16,
  '243A3': 16,
  '243A4': 16,
  '243A5': 16,
  '243A6': 16,
  '243A7': 16,
  '243A8': 16,
  '243A9': 16,
  '243AA': 16,
  '243AB': 16,
  '243AC': 16,
  '243AD': 16,
  '243AE': 16,
  '243AF': 16,
  '243B0': 16,
  '243B1': 16,
  '243B2': 16,
  '243B3': 16,
  '243B4': 16,
  '243B5': 16,
  '243B6': 16,
  '243B7': 16,
  '243B8': 16,
  '243B9': 16,
  '243BA': 16,
  '243BB': 16,
  '243BC': 16,
  '243BD': 16,
  '243BE': 16,
  '243BF': 16,
  '243C0': 16,
  '243C1': 16,
  '243C2': 16,
  '243C3': 16,
  '243C4': 16,
  '243C5': 16,
  '243C6': 16,
  '243C7': 16,
  '243C8': 16,
  '243C9': 16,
  '243CA': 16,
  '243CB': 16,
  '243CC': 16,
  '243CD': 16,
  '243CE': 16,
  '243CF': 16,
  '243D0': 16,
  '243D1': 16,
  '243D2': 16,
  '243D3': 16,
  '243D4': 16,
  '243D5': 16,
  '243D6': 16,
  '243D7': 16,
  '243D8': 16,
  '243D9': 16,
  '243DA': 16,
  '243DB': 16,
  '243DC': 16,
  '243DD': 16,
  '243DE': 16,
  '243DF': 16,
  '243E0': 16,
  '243E1': 16,
  '243E2': 16,
  '243E3': 16,
  '243E4': 16,
  '243E5': 16,
  '243E6': 16,
  '243E7': 16,
  '243E8': 16,
  '243E9': 16,
  '243EA': 16,
  '243EB': 16,
  '243EC': 16,
  '243ED': 16,
  '243EE': 16,
  '243EF': 16,
  '243F0': 16,
  '243F1': 16,
  '243F2': 16,
  '243F3': 16,
  '243F4': 16,
  '243F5': 17,
  '243F6': 17,
  '243F7': 17,
  '243F8': 17,
  '243F9': 17,
  '243FA': 17,
  '243FB': 17,
  '243FC': 17,
  '243FD': 17,
  '243FE': 17,
  '243FF': 17,
  '24400': 17,
  '24401': 17,
  '24402': 17,
  '24403': 17,
  '24404': 17,
  '24405': 17,
  '24406': 17,
  '24407': 17,
  '24408': 17,
  '24409': 17,
  '2440A': 17,
  '2440B': 17,
  '2440C': 17,
  '2440D': 17,
  '2440E': 17,
  '2440F': 17,
  '24410': 17,
  '24411': 17,
  '24412': 17,
  '24413': 17,
  '24414': 17,
  '24415': 17,
  '24416': 17,
  '24417': 17,
  '24418': 17,
  '24419': 17,
  '2441A': 17,
  '2441B': 17,
  '2441C': 17,
  '2441D': 17,
  '2441E': 17,
  '2441F': 17,
  '24420': 17,
  '24421': 17,
  '24422': 17,
  '24423': 17,
  '24424': 18,
  '24425': 18,
  '24426': 18,
  '24427': 18,
  '24428': 18,
  '24429': 18,
  '2442A': 18,
  '2442B': 18,
  '2442C': 18,
  '2442D': 18,
  '2442E': 18,
  '2442F': 18,
  '24430': 18,
  '24431': 18,
  '24432': 18,
  '24433': 18,
  '24434': 18,
  '24435': 18,
  '24436': 18,
  '24437': 18,
  '24438': 18,
  '24439': 18,
  '2443A': 18,
  '2443B': 18,
  '2443C': 18,
  '2443D': 18,
  '2443E': 18,
  '2443F': 18,
  '24440': 18,
  '24441': 18,
  '24442': 18,
  '24443': 18,
  '24444': 18,
  '24445': 18,
  '24446': 18,
  '24447': 18,
  '24448': 18,
  '24449': 18,
  '2444A': 18,
  '2444B': 18,
  '2444C': 18,
  '2444D': 18,
  '2444E': 18,
  '2444F': 18,
  '24450': 18,
  '24451': 18,
  '24452': 19,
  '24453': 19,
  '24454': 19,
  '24455': 19,
  '24456': 19,
  '24457': 19,
  '24458': 19,
  '24459': 19,
  '2445A': 19,
  '2445B': 19,
  '2445C': 19,
  '2445D': 19,
  '2445E': 19,
  '2445F': 19,
  '24460': 19,
  '24461': 19,
  '24462': 19,
  '24463': 19,
  '24464': 19,
  '24465': 19,
  '24466': 19,
  '24467': 19,
  '24468': 19,
  '24469': 19,
  '2446A': 19,
  '2446B': 19,
  '2446C': 19,
  '2446D': 19,
  '2446E': 19,
  '2446F': 19,
  '24470': 19,
  '24471': 19,
  '24472': 19,
  '24473': 20,
  '24474': 20,
  '24475': 20,
  '24476': 20,
  '24477': 20,
  '24478': 20,
  '24479': 20,
  '2447A': 20,
  '2447B': 20,
  '2447C': 20,
  '2447D': 20,
  '2447E': 20,
  '2447F': 20,
  '24480': 20,
  '24481': 20,
  '24482': 20,
  '24483': 20,
  '24484': 20,
  '24485': 20,
  '24486': 20,
  '24487': 20,
  '24488': 20,
  '24489': 20,
  '2448A': 20,
  '2448B': 20,
  '2448C': 20,
  '2448D': 20,
  '2448E': 20,
  '2448F': 20,
  '24490': 20,
  '24491': 20,
  '24492': 20,
  '24493': 20,
  '24494': 20,
  '24495': 20,
  '24496': 20,
  '24497': 20,
  '24498': 20,
  '24499': 20,
  '2449A': 20,
  '2449B': 20,
  '2449C': 20,
  '2449D': 20,
  '2449E': 20,
  '2449F': 20,
  '244A0': 20,
  '244A1': 21,
  '244A2': 21,
  '244A3': 21,
  '244A4': 21,
  '244A5': 21,
  '244A6': 21,
  '244A7': 21,
  '244A8': 20,
  '244A9': 22,
  '244AA': 21,
  '244AB': 21,
  '244AC': 21,
  '244AD': 21,
  '244AE': 21,
  '244AF': 21,
  '244B0': 21,
  '244B1': 21,
  '244B2': 21,
  '244B3': 21,
  '244B4': 21,
  '244B5': 21,
  '244B6': 21,
  '244B7': 21,
  '244B8': 21,
  '244B9': 21,
  '244BA': 22,
  '244BB': 22,
  '244BC': 23,
  '244BD': 22,
  '244BE': 22,
  '244BF': 22,
  '244C0': 22,
  '244C1': 22,
  '244C2': 22,
  '244C3': 22,
  '244C4': 22,
  '244C5': 22,
  '244C6': 22,
  '244C7': 22,
  '244C8': 23,
  '244C9': 23,
  '244CA': 23,
  '244CB': 23,
  '244CC': 23,
  '244CD': 23,
  '244CE': 23,
  '244CF': 23,
  '244D0': 23,
  '244D1': 23,
  '244D2': 23,
  '244D3': 23,
  '244D4': 23,
  '244D5': 23,
  '244D6': 23,
  '244D7': 24,
  '244D8': 24,
  '244D9': 24,
  '244DA': 24,
  '244DB': 24,
  '244DC': 25,
  '244DD': 25,
  '244DE': 25,
  '244DF': 24,
  '244E0': 25,
  '244E1': 25,
  '244E2': 25,
  '244E3': 25,
  '244E4': 26,
  '244E5': 26,
  '244E6': 27,
  '244E7': 27,
  '244E8': 27,
  '244E9': 27,
  '244EA': 28,
  '244EB': 29,
  '244EC': 28,
  '244ED': 30,
  '244EE': 33,
  '244EF': 4,
  '244F0': 4,
  '244F1': 5,
  '244F2': 7,
  '244F3': 7,
  '244F4': 7,
  '244F5': 7,
  '244F6': 8,
  '244F7': 8,
  '244F8': 8,
  '244F9': 8,
  '244FA': 8,
  '244FB': 8,
  '244FC': 8,
  '244FD': 9,
  '244FE': 9,
  '244FF': 9,
  '24500': 9,
  '24501': 9,
  '24502': 9,
  '24503': 9,
  '24504': 9,
  '24505': 9,
  '24506': 9,
  '24507': 9,
  '24508': 9,
  '24509': 9,
  '2450A': 9,
  '2450B': 9,
  '2450C': 10,
  '2450D': 10,
  '2450E': 10,
  '2450F': 10,
  '24510': 11,
  '24511': 11,
  '24512': 11,
  '24513': 11,
  '24514': 12,
  '24515': 12,
  '24516': 12,
  '24517': 12,
  '24518': 12,
  '24519': 12,
  '2451A': 12,
  '2451B': 12,
  '2451C': 13,
  '2451D': 13,
  '2451E': 13,
  '2451F': 13,
  '24520': 13,
  '24521': 13,
  '24522': 14,
  '24523': 14,
  '24524': 14,
  '24525': 14,
  '24526': 14,
  '24527': 15,
  '24528': 15,
  '24529': 15,
  '2452A': 15,
  '2452B': 15,
  '2452C': 15,
  '2452D': 15,
  '2452E': 16,
  '2452F': 16,
  '24530': 16,
  '24531': 16,
  '24532': 17,
  '24533': 17,
  '24534': 17,
  '24535': 17,
  '24536': 17,
  '24537': 17,
  '24538': 17,
  '24539': 18,
  '2453A': 19,
  '2453B': 18,
  '2453C': 17,
  '2453D': 18,
  '2453E': 18,
  '2453F': 19,
  '24540': 19,
  '24541': 19,
  '24542': 20,
  '24543': 20,
  '24544': 20,
  '24545': 20,
  '24546': 20,
  '24547': 21,
  '24548': 23,
  '24549': 23,
  '2454A': 23,
  '2454B': 24,
  '2454C': 25,
  '2454D': 25,
  '2454E': 7,
  '2454F': 7,
  '24550': 8,
  '24551': 8,
  '24552': 9,
  '24553': 10,
  '24554': 10,
  '24555': 11,
  '24556': 12,
  '24557': 12,
  '24558': 13,
  '24559': 14,
  '2455A': 14,
  '2455B': 16,
  '2455C': 5,
  '2455D': 8,
  '2455E': 9,
  '2455F': 9,
  '24560': 10,
  '24561': 10,
  '24562': 11,
  '24563': 12,
  '24564': 13,
  '24565': 13,
  '24566': 15,
  '24567': 15,
  '24568': 15,
  '24569': 16,
  '2456A': 4,
  '2456B': 5,
  '2456C': 5,
  '2456D': 6,
  '2456E': 7,
  '2456F': 8,
  '24570': 8,
  '24571': 8,
  '24572': 9,
  '24573': 9,
  '24574': 9,
  '24575': 9,
  '24576': 10,
  '24577': 10,
  '24578': 10,
  '24579': 10,
  '2457A': 10,
  '2457B': 11,
  '2457C': 11,
  '2457D': 11,
  '2457E': 11,
  '2457F': 11,
  '24580': 11,
  '24581': 11,
  '24582': 11,
  '24583': 11,
  '24584': 12,
  '24585': 12,
  '24586': 12,
  '24587': 12,
  '24588': 12,
  '24589': 12,
  '2458A': 12,
  '2458B': 12,
  '2458C': 13,
  '2458D': 13,
  '2458E': 13,
  '2458F': 14,
  '24590': 14,
  '24591': 14,
  '24592': 14,
  '24593': 15,
  '24594': 15,
  '24595': 15,
  '24596': 16,
  '24597': 16,
  '24598': 16,
  '24599': 16,
  '2459A': 16,
  '2459B': 16,
  '2459C': 17,
  '2459D': 17,
  '2459E': 17,
  '2459F': 17,
  '245A0': 19,
  '245A1': 19,
  '245A2': 20,
  '245A3': 22,
  '245A4': 23,
  '245A5': 27,
  '245A6': 28,
  '245A7': 28,
  '245A8': 5,
  '245A9': 7,
  '245AA': 7,
  '245AB': 7,
  '245AC': 8,
  '245AD': 8,
  '245AE': 8,
  '245AF': 8,
  '245B0': 8,
  '245B1': 9,
  '245B2': 9,
  '245B3': 9,
  '245B4': 9,
  '245B5': 9,
  '245B6': 9,
  '245B7': 9,
  '245B8': 9,
  '245B9': 9,
  '245BA': 10,
  '245BB': 10,
  '245BC': 10,
  '245BD': 10,
  '245BE': 10,
  '245BF': 10,
  '245C0': 11,
  '245C1': 11,
  '245C2': 11,
  '245C3': 11,
  '245C4': 11,
  '245C5': 11,
  '245C6': 11,
  '245C7': 12,
  '245C8': 12,
  '245C9': 12,
  '245CA': 12,
  '245CB': 12,
  '245CC': 12,
  '245CD': 12,
  '245CE': 12,
  '245CF': 12,
  '245D0': 12,
  '245D1': 12,
  '245D2': 12,
  '245D3': 12,
  '245D4': 12,
  '245D5': 12,
  '245D6': 12,
  '245D7': 12,
  '245D8': 13,
  '245D9': 13,
  '245DA': 13,
  '245DB': 13,
  '245DC': 13,
  '245DD': 13,
  '245DE': 13,
  '245DF': 13,
  '245E0': 13,
  '245E1': 13,
  '245E2': 14,
  '245E3': 14,
  '245E4': 14,
  '245E5': 14,
  '245E6': 14,
  '245E7': 14,
  '245E8': 15,
  '245E9': 15,
  '245EA': 15,
  '245EB': 15,
  '245EC': 15,
  '245ED': 15,
  '245EE': 15,
  '245EF': 15,
  '245F0': 15,
  '245F1': 15,
  '245F2': 15,
  '245F3': 16,
  '245F4': 16,
  '245F5': 16,
  '245F6': 16,
  '245F7': 16,
  '245F8': 16,
  '245F9': 16,
  '245FA': 17,
  '245FB': 17,
  '245FC': 17,
  '245FD': 17,
  '245FE': 17,
  '245FF': 18,
  '24600': 18,
  '24601': 18,
  '24602': 18,
  '24603': 20,
  '24604': 21,
  '24605': 7,
  '24606': 8,
  '24607': 9,
  '24608': 10,
  '24609': 10,
  '2460A': 11,
  '2460B': 11,
  '2460C': 12,
  '2460D': 12,
  '2460E': 12,
  '2460F': 12,
  '24610': 13,
  '24611': 14,
  '24612': 15,
  '24613': 18,
  '24614': 5,
  '24615': 6,
  '24616': 6,
  '24617': 6,
  '24618': 7,
  '24619': 7,
  '2461A': 7,
  '2461B': 7,
  '2461C': 8,
  '2461D': 8,
  '2461E': 8,
  '2461F': 8,
  '24620': 8,
  '24621': 8,
  '24622': 8,
  '24623': 8,
  '24624': 8,
  '24625': 8,
  '24626': 8,
  '24627': 8,
  '24628': 8,
  '24629': 8,
  '2462A': 8,
  '2462B': 8,
  '2462C': 8,
  '2462D': 8,
  '2462E': 8,
  '2462F': 8,
  '24630': 8,
  '24631': 8,
  '24632': 8,
  '24633': 8,
  '24634': 8,
  '24635': 8,
  '24636': 8,
  '24637': 8,
  '24638': 9,
  '24639': 9,
  '2463A': 9,
  '2463B': 9,
  '2463C': 9,
  '2463D': 9,
  '2463E': 9,
  '2463F': 9,
  '24640': 9,
  '24641': 9,
  '24642': 9,
  '24643': 9,
  '24644': 9,
  '24645': 9,
  '24646': 9,
  '24647': 9,
  '24648': 9,
  '24649': 9,
  '2464A': 9,
  '2464B': 9,
  '2464C': 9,
  '2464D': 9,
  '2464E': 9,
  '2464F': 9,
  '24650': 9,
  '24651': 10,
  '24652': 10,
  '24653': 10,
  '24654': 10,
  '24655': 10,
  '24656': 10,
  '24657': 10,
  '24658': 10,
  '24659': 10,
  '2465A': 10,
  '2465B': 11,
  '2465C': 11,
  '2465D': 11,
  '2465E': 11,
  '2465F': 11,
  '24660': 11,
  '24661': 11,
  '24662': 11,
  '24663': 11,
  '24664': 11,
  '24665': 11,
  '24666': 11,
  '24667': 11,
  '24668': 11,
  '24669': 11,
  '2466A': 11,
  '2466B': 11,
  '2466C': 11,
  '2466D': 11,
  '2466E': 11,
  '2466F': 12,
  '24670': 12,
  '24671': 12,
  '24672': 12,
  '24673': 12,
  '24674': 12,
  '24675': 12,
  '24676': 12,
  '24677': 12,
  '24678': 12,
  '24679': 12,
  '2467A': 12,
  '2467B': 12,
  '2467C': 12,
  '2467D': 12,
  '2467E': 12,
  '2467F': 12,
  '24680': 12,
  '24681': 12,
  '24682': 12,
  '24683': 12,
  '24684': 12,
  '24685': 12,
  '24686': 12,
  '24687': 12,
  '24688': 12,
  '24689': 12,
  '2468A': 12,
  '2468B': 12,
  '2468C': 12,
  '2468D': 13,
  '2468E': 13,
  '2468F': 13,
  '24690': 13,
  '24691': 13,
  '24692': 13,
  '24693': 13,
  '24694': 13,
  '24695': 13,
  '24696': 13,
  '24697': 13,
  '24698': 13,
  '24699': 13,
  '2469A': 13,
  '2469B': 13,
  '2469C': 13,
  '2469D': 13,
  '2469E': 13,
  '2469F': 13,
  '246A0': 13,
  '246A1': 13,
  '246A2': 13,
  '246A3': 13,
  '246A4': 13,
  '246A5': 13,
  '246A6': 13,
  '246A7': 13,
  '246A8': 13,
  '246A9': 14,
  '246AA': 14,
  '246AB': 14,
  '246AC': 14,
  '246AD': 14,
  '246AE': 14,
  '246AF': 14,
  '246B0': 14,
  '246B1': 14,
  '246B2': 14,
  '246B3': 14,
  '246B4': 14,
  '246B5': 14,
  '246B6': 14,
  '246B7': 14,
  '246B8': 14,
  '246B9': 14,
  '246BA': 14,
  '246BB': 14,
  '246BC': 14,
  '246BD': 14,
  '246BE': 14,
  '246BF': 13,
  '246C0': 14,
  '246C1': 14,
  '246C2': 14,
  '246C3': 14,
  '246C4': 14,
  '246C5': 14,
  '246C6': 14,
  '246C7': 14,
  '246C8': 14,
  '246C9': 14,
  '246CA': 15,
  '246CB': 15,
  '246CC': 15,
  '246CD': 15,
  '246CE': 15,
  '246CF': 15,
  '246D0': 15,
  '246D1': 15,
  '246D2': 15,
  '246D3': 15,
  '246D4': 15,
  '246D5': 15,
  '246D6': 15,
  '246D7': 15,
  '246D8': 15,
  '246D9': 15,
  '246DA': 15,
  '246DB': 15,
  '246DC': 15,
  '246DD': 15,
  '246DE': 15,
  '246DF': 15,
  '246E0': 15,
  '246E1': 16,
  '246E2': 16,
  '246E3': 16,
  '246E4': 16,
  '246E5': 16,
  '246E6': 16,
  '246E7': 16,
  '246E8': 16,
  '246E9': 16,
  '246EA': 16,
  '246EB': 16,
  '246EC': 16,
  '246ED': 16,
  '246EE': 16,
  '246EF': 17,
  '246F0': 17,
  '246F1': 17,
  '246F2': 17,
  '246F3': 17,
  '246F4': 17,
  '246F5': 17,
  '246F6': 17,
  '246F7': 17,
  '246F8': 17,
  '246F9': 18,
  '246FA': 18,
  '246FB': 19,
  '246FC': 19,
  '246FD': 19,
  '246FE': 19,
  '246FF': 19,
  '24700': 19,
  '24701': 19,
  '24702': 20,
  '24703': 20,
  '24704': 20,
  '24705': 20,
  '24706': 20,
  '24707': 21,
  '24708': 21,
  '24709': 21,
  '2470A': 21,
  '2470B': 21,
  '2470C': 22,
  '2470D': 22,
  '2470E': 22,
  '2470F': 22,
  '24710': 22,
  '24711': 23,
  '24712': 23,
  '24713': 23,
  '24714': 24,
  '24715': 24,
  '24716': 25,
  '24717': 25,
  '24718': 25,
  '24719': 28,
  '2471A': 4,
  '2471B': 5,
  '2471C': 5,
  '2471D': 5,
  '2471E': 5,
  '2471F': 5,
  '24720': 5,
  '24721': 6,
  '24722': 6,
  '24723': 6,
  '24724': 6,
  '24725': 6,
  '24726': 6,
  '24727': 6,
  '24728': 6,
  '24729': 6,
  '2472A': 6,
  '2472B': 6,
  '2472C': 6,
  '2472D': 6,
  '2472E': 6,
  '2472F': 7,
  '24730': 7,
  '24731': 7,
  '24732': 7,
  '24733': 7,
  '24734': 7,
  '24735': 7,
  '24736': 7,
  '24737': 7,
  '24738': 7,
  '24739': 7,
  '2473A': 7,
  '2473B': 7,
  '2473C': 7,
  '2473D': 7,
  '2473E': 7,
  '2473F': 7,
  '24740': 7,
  '24741': 7,
  '24742': 7,
  '24743': 7,
  '24744': 7,
  '24745': 7,
  '24746': 7,
  '24747': 7,
  '24748': 7,
  '24749': 7,
  '2474A': 7,
  '2474B': 7,
  '2474C': 7,
  '2474D': 7,
  '2474E': 7,
  '2474F': 8,
  '24750': 8,
  '24751': 8,
  '24752': 8,
  '24753': 8,
  '24754': 8,
  '24755': 8,
  '24756': 8,
  '24757': 8,
  '24758': 8,
  '24759': 8,
  '2475A': 8,
  '2475B': 8,
  '2475C': 8,
  '2475D': 8,
  '2475E': 8,
  '2475F': 8,
  '24760': 8,
  '24761': 8,
  '24762': 8,
  '24763': 8,
  '24764': 8,
  '24765': 8,
  '24766': 8,
  '24767': 8,
  '24768': 8,
  '24769': 8,
  '2476A': 8,
  '2476B': 8,
  '2476C': 8,
  '2476D': 8,
  '2476E': 7,
  '2476F': 9,
  '24770': 9,
  '24771': 9,
  '24772': 9,
  '24773': 9,
  '24774': 9,
  '24775': 9,
  '24776': 9,
  '24777': 9,
  '24778': 9,
  '24779': 9,
  '2477A': 9,
  '2477B': 9,
  '2477C': 9,
  '2477D': 9,
  '2477E': 9,
  '2477F': 9,
  '24780': 9,
  '24781': 9,
  '24782': 9,
  '24783': 9,
  '24784': 9,
  '24785': 9,
  '24786': 9,
  '24787': 9,
  '24788': 9,
  '24789': 9,
  '2478A': 9,
  '2478B': 9,
  '2478C': 9,
  '2478D': 9,
  '2478E': 9,
  '2478F': 9,
  '24790': 9,
  '24791': 9,
  '24792': 9,
  '24793': 9,
  '24794': 9,
  '24795': 9,
  '24796': 9,
  '24797': 9,
  '24798': 9,
  '24799': 10,
  '2479A': 10,
  '2479B': 10,
  '2479C': 10,
  '2479D': 10,
  '2479E': 10,
  '2479F': 10,
  '247A0': 10,
  '247A1': 10,
  '247A2': 10,
  '247A3': 9,
  '247A4': 10,
  '247A5': 10,
  '247A6': 10,
  '247A7': 10,
  '247A8': 10,
  '247A9': 10,
  '247AA': 10,
  '247AB': 10,
  '247AC': 10,
  '247AD': 10,
  '247AE': 10,
  '247AF': 10,
  '247B0': 10,
  '247B1': 10,
  '247B2': 10,
  '247B3': 10,
  '247B4': 10,
  '247B5': 10,
  '247B6': 10,
  '247B7': 10,
  '247B8': 10,
  '247B9': 10,
  '247BA': 10,
  '247BB': 10,
  '247BC': 10,
  '247BD': 10,
  '247BE': 10,
  '247BF': 10,
  '247C0': 10,
  '247C1': 10,
  '247C2': 10,
  '247C3': 11,
  '247C4': 11,
  '247C5': 11,
  '247C6': 11,
  '247C7': 11,
  '247C8': 11,
  '247C9': 11,
  '247CA': 11,
  '247CB': 11,
  '247CC': 11,
  '247CD': 11,
  '247CE': 11,
  '247CF': 11,
  '247D0': 11,
  '247D1': 11,
  '247D2': 11,
  '247D3': 11,
  '247D4': 11,
  '247D5': 11,
  '247D6': 11,
  '247D7': 11,
  '247D8': 11,
  '247D9': 11,
  '247DA': 11,
  '247DB': 11,
  '247DC': 12,
  '247DD': 12,
  '247DE': 12,
  '247DF': 12,
  '247E0': 12,
  '247E1': 12,
  '247E2': 12,
  '247E3': 12,
  '247E4': 12,
  '247E5': 12,
  '247E6': 12,
  '247E7': 12,
  '247E8': 12,
  '247E9': 12,
  '247EA': 12,
  '247EB': 12,
  '247EC': 12,
  '247ED': 12,
  '247EE': 12,
  '247EF': 12,
  '247F0': 12,
  '247F1': 12,
  '247F2': 12,
  '247F3': 12,
  '247F4': 12,
  '247F5': 13,
  '247F6': 12,
  '247F7': 12,
  '247F8': 12,
  '247F9': 12,
  '247FA': 12,
  '247FB': 12,
  '247FC': 12,
  '247FD': 12,
  '247FE': 12,
  '247FF': 12,
  '24800': 12,
  '24801': 12,
  '24802': 12,
  '24803': 12,
  '24804': 12,
  '24805': 12,
  '24806': 12,
  '24807': 12,
  '24808': 12,
  '24809': 12,
  '2480A': 12,
  '2480B': 12,
  '2480C': 12,
  '2480D': 13,
  '2480E': 13,
  '2480F': 13,
  '24810': 13,
  '24811': 13,
  '24812': 13,
  '24813': 13,
  '24814': 13,
  '24815': 13,
  '24816': 13,
  '24817': 13,
  '24818': 13,
  '24819': 13,
  '2481A': 13,
  '2481B': 13,
  '2481C': 13,
  '2481D': 13,
  '2481E': 13,
  '2481F': 13,
  '24820': 13,
  '24821': 13,
  '24822': 13,
  '24823': 13,
  '24824': 13,
  '24825': 13,
  '24826': 13,
  '24827': 13,
  '24828': 13,
  '24829': 13,
  '2482A': 13,
  '2482B': 13,
  '2482C': 13,
  '2482D': 13,
  '2482E': 13,
  '2482F': 13,
  '24830': 13,
  '24831': 13,
  '24832': 13,
  '24833': 13,
  '24834': 13,
  '24835': 13,
  '24836': 13,
  '24837': 13,
  '24838': 13,
  '24839': 14,
  '2483A': 14,
  '2483B': 14,
  '2483C': 14,
  '2483D': 14,
  '2483E': 14,
  '2483F': 14,
  '24840': 14,
  '24841': 14,
  '24842': 14,
  '24843': 14,
  '24844': 14,
  '24845': 14,
  '24846': 14,
  '24847': 14,
  '24848': 14,
  '24849': 14,
  '2484A': 14,
  '2484B': 14,
  '2484C': 14,
  '2484D': 14,
  '2484E': 14,
  '2484F': 14,
  '24850': 14,
  '24851': 14,
  '24852': 14,
  '24853': 14,
  '24854': 14,
  '24855': 14,
  '24856': 14,
  '24857': 14,
  '24858': 14,
  '24859': 14,
  '2485A': 14,
  '2485B': 14,
  '2485C': 14,
  '2485D': 14,
  '2485E': 14,
  '2485F': 14,
  '24860': 14,
  '24861': 14,
  '24862': 14,
  '24863': 15,
  '24864': 15,
  '24865': 15,
  '24866': 15,
  '24867': 15,
  '24868': 15,
  '24869': 15,
  '2486A': 15,
  '2486B': 15,
  '2486C': 15,
  '2486D': 15,
  '2486E': 15,
  '2486F': 15,
  '24870': 15,
  '24871': 15,
  '24872': 15,
  '24873': 15,
  '24874': 15,
  '24875': 15,
  '24876': 15,
  '24877': 15,
  '24878': 15,
  '24879': 15,
  '2487A': 15,
  '2487B': 15,
  '2487C': 15,
  '2487D': 15,
  '2487E': 15,
  '2487F': 15,
  '24880': 15,
  '24881': 15,
  '24882': 15,
  '24883': 15,
  '24884': 15,
  '24885': 15,
  '24886': 15,
  '24887': 15,
  '24888': 15,
  '24889': 15,
  '2488A': 15,
  '2488B': 15,
  '2488C': 16,
  '2488D': 16,
  '2488E': 16,
  '2488F': 16,
  '24890': 16,
  '24891': 16,
  '24892': 16,
  '24893': 16,
  '24894': 16,
  '24895': 16,
  '24896': 16,
  '24897': 16,
  '24898': 16,
  '24899': 16,
  '2489A': 16,
  '2489B': 16,
  '2489C': 16,
  '2489D': 16,
  '2489E': 16,
  '2489F': 16,
  '248A0': 16,
  '248A1': 16,
  '248A2': 16,
  '248A3': 16,
  '248A4': 16,
  '248A5': 16,
  '248A6': 17,
  '248A7': 17,
  '248A8': 17,
  '248A9': 17,
  '248AA': 17,
  '248AB': 17,
  '248AC': 17,
  '248AD': 17,
  '248AE': 17,
  '248AF': 17,
  '248B0': 17,
  '248B1': 17,
  '248B2': 17,
  '248B3': 17,
  '248B4': 18,
  '248B5': 18,
  '248B6': 18,
  '248B7': 18,
  '248B8': 18,
  '248B9': 18,
  '248BA': 18,
  '248BB': 18,
  '248BC': 18,
  '248BD': 18,
  '248BE': 18,
  '248BF': 18,
  '248C0': 18,
  '248C1': 18,
  '248C2': 18,
  '248C3': 18,
  '248C4': 18,
  '248C5': 19,
  '248C6': 19,
  '248C7': 19,
  '248C8': 19,
  '248C9': 19,
  '248CA': 19,
  '248CB': 19,
  '248CC': 19,
  '248CD': 20,
  '248CE': 20,
  '248CF': 20,
  '248D0': 20,
  '248D1': 21,
  '248D2': 21,
  '248D3': 21,
  '248D4': 21,
  '248D5': 21,
  '248D6': 21,
  '248D7': 22,
  '248D8': 22,
  '248D9': 22,
  '248DA': 22,
  '248DB': 23,
  '248DC': 23,
  '248DD': 23,
  '248DE': 23,
  '248DF': 24,
  '248E0': 24,
  '248E1': 24,
  '248E2': 24,
  '248E3': 25,
  '248E4': 27,
  '248E5': 5,
  '248E6': 9,
  '248E7': 12,
  '248E8': 14,
  '248E9': 4,
  '248EA': 6,
  '248EB': 6,
  '248EC': 6,
  '248ED': 7,
  '248EE': 7,
  '248EF': 7,
  '248F0': 7,
  '248F1': 7,
  '248F2': 7,
  '248F3': 7,
  '248F4': 7,
  '248F5': 7,
  '248F6': 7,
  '248F7': 7,
  '248F8': 7,
  '248F9': 8,
  '248FA': 8,
  '248FB': 8,
  '248FC': 8,
  '248FD': 8,
  '248FE': 8,
  '248FF': 8,
  '24900': 8,
  '24901': 8,
  '24902': 8,
  '24903': 8,
  '24904': 8,
  '24905': 8,
  '24906': 8,
  '24907': 8,
  '24908': 8,
  '24909': 8,
  '2490A': 8,
  '2490B': 8,
  '2490C': 8,
  '2490D': 8,
  '2490E': 8,
  '2490F': 9,
  '24910': 9,
  '24911': 9,
  '24912': 9,
  '24913': 9,
  '24914': 9,
  '24915': 9,
  '24916': 9,
  '24917': 9,
  '24918': 9,
  '24919': 9,
  '2491A': 9,
  '2491B': 9,
  '2491C': 9,
  '2491D': 9,
  '2491E': 9,
  '2491F': 9,
  '24920': 9,
  '24921': 9,
  '24922': 9,
  '24923': 9,
  '24924': 9,
  '24925': 9,
  '24926': 9,
  '24927': 9,
  '24928': 9,
  '24929': 9,
  '2492A': 9,
  '2492B': 9,
  '2492C': 9,
  '2492D': 9,
  '2492E': 9,
  '2492F': 9,
  '24930': 9,
  '24931': 9,
  '24932': 9,
  '24933': 9,
  '24934': 9,
  '24935': 10,
  '24936': 10,
  '24937': 10,
  '24938': 10,
  '24939': 10,
  '2493A': 10,
  '2493B': 10,
  '2493C': 10,
  '2493D': 10,
  '2493E': 10,
  '2493F': 10,
  '24940': 10,
  '24941': 10,
  '24942': 10,
  '24943': 10,
  '24944': 10,
  '24945': 10,
  '24946': 10,
  '24947': 10,
  '24948': 10,
  '24949': 10,
  '2494A': 10,
  '2494B': 10,
  '2494C': 10,
  '2494D': 10,
  '2494E': 10,
  '2494F': 10,
  '24950': 10,
  '24951': 10,
  '24952': 10,
  '24953': 10,
  '24954': 10,
  '24955': 10,
  '24956': 11,
  '24957': 11,
  '24958': 11,
  '24959': 11,
  '2495A': 11,
  '2495B': 11,
  '2495C': 11,
  '2495D': 11,
  '2495E': 11,
  '2495F': 11,
  '24960': 11,
  '24961': 11,
  '24962': 11,
  '24963': 11,
  '24964': 11,
  '24965': 11,
  '24966': 11,
  '24967': 11,
  '24968': 11,
  '24969': 11,
  '2496A': 11,
  '2496B': 11,
  '2496C': 11,
  '2496D': 11,
  '2496E': 11,
  '2496F': 11,
  '24970': 11,
  '24971': 11,
  '24972': 11,
  '24973': 11,
  '24974': 11,
  '24975': 11,
  '24976': 11,
  '24977': 11,
  '24978': 11,
  '24979': 11,
  '2497A': 11,
  '2497B': 12,
  '2497C': 12,
  '2497D': 12,
  '2497E': 12,
  '2497F': 12,
  '24980': 12,
  '24981': 12,
  '24982': 12,
  '24983': 12,
  '24984': 12,
  '24985': 12,
  '24986': 12,
  '24987': 12,
  '24988': 12,
  '24989': 12,
  '2498A': 12,
  '2498B': 12,
  '2498C': 12,
  '2498D': 12,
  '2498E': 12,
  '2498F': 12,
  '24990': 12,
  '24991': 12,
  '24992': 12,
  '24993': 12,
  '24994': 12,
  '24995': 12,
  '24996': 12,
  '24997': 12,
  '24998': 12,
  '24999': 12,
  '2499A': 12,
  '2499B': 12,
  '2499C': 12,
  '2499D': 12,
  '2499E': 12,
  '2499F': 12,
  '249A0': 12,
  '249A1': 12,
  '249A2': 12,
  '249A3': 12,
  '249A4': 12,
  '249A5': 12,
  '249A6': 12,
  '249A7': 12,
  '249A8': 12,
  '249A9': 12,
  '249AA': 12,
  '249AB': 12,
  '249AC': 12,
  '249AD': 12,
  '249AE': 12,
  '249AF': 13,
  '249B0': 13,
  '249B1': 13,
  '249B2': 13,
  '249B3': 13,
  '249B4': 13,
  '249B5': 13,
  '249B6': 13,
  '249B7': 13,
  '249B8': 13,
  '249B9': 13,
  '249BA': 13,
  '249BB': 13,
  '249BC': 13,
  '249BD': 13,
  '249BE': 13,
  '249BF': 13,
  '249C0': 13,
  '249C1': 13,
  '249C2': 13,
  '249C3': 13,
  '249C4': 13,
  '249C5': 13,
  '249C6': 13,
  '249C7': 13,
  '249C8': 13,
  '249C9': 13,
  '249CA': 13,
  '249CB': 13,
  '249CC': 13,
  '249CD': 13,
  '249CE': 13,
  '249CF': 13,
  '249D0': 13,
  '249D1': 13,
  '249D2': 13,
  '249D3': 13,
  '249D4': 13,
  '249D5': 13,
  '249D6': 13,
  '249D7': 13,
  '249D8': 13,
  '249D9': 13,
  '249DA': 13,
  '249DB': 13,
  '249DC': 13,
  '249DD': 13,
  '249DE': 13,
  '249DF': 13,
  '249E0': 13,
  '249E1': 13,
  '249E2': 13,
  '249E3': 13,
  '249E4': 13,
  '249E5': 13,
  '249E6': 13,
  '249E7': 13,
  '249E8': 13,
  '249E9': 13,
  '249EA': 14,
  '249EB': 14,
  '249EC': 14,
  '249ED': 14,
  '249EE': 14,
  '249EF': 14,
  '249F0': 14,
  '249F1': 14,
  '249F2': 14,
  '249F3': 14,
  '249F4': 14,
  '249F5': 14,
  '249F6': 14,
  '249F7': 14,
  '249F8': 14,
  '249F9': 14,
  '249FA': 14,
  '249FB': 14,
  '249FC': 14,
  '249FD': 14,
  '249FE': 14,
  '249FF': 14,
  '24A00': 14,
  '24A01': 14,
  '24A02': 14,
  '24A03': 14,
  '24A04': 14,
  '24A05': 14,
  '24A06': 14,
  '24A07': 14,
  '24A08': 14,
  '24A09': 14,
  '24A0A': 14,
  '24A0B': 14,
  '24A0C': 14,
  '24A0D': 14,
  '24A0E': 14,
  '24A0F': 14,
  '24A10': 14,
  '24A11': 14,
  '24A12': 14,
  '24A13': 14,
  '24A14': 15,
  '24A15': 15,
  '24A16': 15,
  '24A17': 15,
  '24A18': 15,
  '24A19': 15,
  '24A1A': 15,
  '24A1B': 15,
  '24A1C': 15,
  '24A1D': 15,
  '24A1E': 15,
  '24A1F': 15,
  '24A20': 15,
  '24A21': 15,
  '24A22': 15,
  '24A23': 15,
  '24A24': 15,
  '24A25': 15,
  '24A26': 15,
  '24A27': 15,
  '24A28': 15,
  '24A29': 15,
  '24A2A': 15,
  '24A2B': 15,
  '24A2C': 15,
  '24A2D': 15,
  '24A2E': 15,
  '24A2F': 15,
  '24A30': 15,
  '24A31': 15,
  '24A32': 15,
  '24A33': 15,
  '24A34': 15,
  '24A35': 15,
  '24A36': 15,
  '24A37': 15,
  '24A38': 15,
  '24A39': 15,
  '24A3A': 15,
  '24A3B': 15,
  '24A3C': 15,
  '24A3D': 15,
  '24A3E': 15,
  '24A3F': 15,
  '24A40': 16,
  '24A41': 15,
  '24A42': 16,
  '24A43': 16,
  '24A44': 16,
  '24A45': 16,
  '24A46': 16,
  '24A47': 16,
  '24A48': 16,
  '24A49': 16,
  '24A4A': 16,
  '24A4B': 16,
  '24A4C': 16,
  '24A4D': 16,
  '24A4E': 16,
  '24A4F': 16,
  '24A50': 16,
  '24A51': 16,
  '24A52': 16,
  '24A53': 16,
  '24A54': 16,
  '24A55': 16,
  '24A56': 16,
  '24A57': 16,
  '24A58': 16,
  '24A59': 16,
  '24A5A': 16,
  '24A5B': 16,
  '24A5C': 16,
  '24A5D': 16,
  '24A5E': 16,
  '24A5F': 16,
  '24A60': 16,
  '24A61': 16,
  '24A62': 16,
  '24A63': 16,
  '24A64': 16,
  '24A65': 16,
  '24A66': 16,
  '24A67': 16,
  '24A68': 16,
  '24A69': 16,
  '24A6A': 16,
  '24A6B': 17,
  '24A6C': 17,
  '24A6D': 17,
  '24A6E': 17,
  '24A6F': 17,
  '24A70': 17,
  '24A71': 17,
  '24A72': 17,
  '24A73': 17,
  '24A74': 17,
  '24A75': 17,
  '24A76': 17,
  '24A77': 17,
  '24A78': 17,
  '24A79': 17,
  '24A7A': 17,
  '24A7B': 17,
  '24A7C': 17,
  '24A7D': 17,
  '24A7E': 17,
  '24A7F': 17,
  '24A80': 17,
  '24A81': 17,
  '24A82': 17,
  '24A83': 17,
  '24A84': 17,
  '24A85': 17,
  '24A86': 17,
  '24A87': 17,
  '24A88': 17,
  '24A89': 17,
  '24A8A': 17,
  '24A8B': 18,
  '24A8C': 18,
  '24A8D': 18,
  '24A8E': 18,
  '24A8F': 18,
  '24A90': 18,
  '24A91': 18,
  '24A92': 18,
  '24A93': 18,
  '24A94': 18,
  '24A95': 18,
  '24A96': 18,
  '24A97': 18,
  '24A98': 18,
  '24A99': 18,
  '24A9A': 18,
  '24A9B': 18,
  '24A9C': 18,
  '24A9D': 18,
  '24A9E': 18,
  '24A9F': 18,
  '24AA0': 18,
  '24AA1': 18,
  '24AA2': 18,
  '24AA3': 18,
  '24AA4': 18,
  '24AA5': 18,
  '24AA6': 18,
  '24AA7': 18,
  '24AA8': 18,
  '24AA9': 19,
  '24AAA': 19,
  '24AAB': 19,
  '24AAC': 19,
  '24AAD': 19,
  '24AAE': 19,
  '24AAF': 19,
  '24AB0': 19,
  '24AB1': 19,
  '24AB2': 19,
  '24AB3': 19,
  '24AB4': 18,
  '24AB5': 19,
  '24AB6': 19,
  '24AB7': 19,
  '24AB8': 19,
  '24AB9': 19,
  '24ABA': 19,
  '24ABB': 19,
  '24ABC': 19,
  '24ABD': 19,
  '24ABE': 20,
  '24ABF': 20,
  '24AC0': 20,
  '24AC1': 20,
  '24AC2': 20,
  '24AC3': 20,
  '24AC4': 20,
  '24AC5': 20,
  '24AC6': 20,
  '24AC7': 20,
  '24AC8': 20,
  '24AC9': 21,
  '24ACA': 21,
  '24ACB': 21,
  '24ACC': 21,
  '24ACD': 21,
  '24ACE': 21,
  '24ACF': 21,
  '24AD0': 21,
  '24AD1': 21,
  '24AD2': 21,
  '24AD3': 21,
  '24AD4': 22,
  '24AD5': 22,
  '24AD6': 22,
  '24AD7': 22,
  '24AD8': 22,
  '24AD9': 23,
  '24ADA': 23,
  '24ADB': 23,
  '24ADC': 23,
  '24ADD': 23,
  '24ADE': 23,
  '24ADF': 23,
  '24AE0': 24,
  '24AE1': 24,
  '24AE2': 26,
  '24AE3': 25,
  '24AE4': 25,
  '24AE5': 25,
  '24AE6': 25,
  '24AE7': 25,
  '24AE8': 26,
  '24AE9': 28,
  '24AEA': 8,
  '24AEB': 9,
  '24AEC': 9,
  '24AED': 9,
  '24AEE': 9,
  '24AEF': 9,
  '24AF0': 10,
  '24AF1': 10,
  '24AF2': 10,
  '24AF3': 10,
  '24AF4': 10,
  '24AF5': 11,
  '24AF6': 11,
  '24AF7': 11,
  '24AF8': 11,
  '24AF9': 11,
  '24AFA': 12,
  '24AFB': 12,
  '24AFC': 12,
  '24AFD': 12,
  '24AFE': 12,
  '24AFF': 12,
  '24B00': 12,
  '24B01': 13,
  '24B02': 13,
  '24B03': 13,
  '24B04': 13,
  '24B05': 13,
  '24B06': 13,
  '24B07': 15,
  '24B08': 14,
  '24B09': 14,
  '24B0A': 14,
  '24B0B': 14,
  '24B0C': 14,
  '24B0D': 15,
  '24B0E': 16,
  '24B0F': 16,
  '24B10': 15,
  '24B11': 15,
  '24B12': 15,
  '24B13': 15,
  '24B14': 15,
  '24B15': 15,
  '24B16': 16,
  '24B17': 17,
  '24B18': 18,
  '24B19': 18,
  '24B1A': 18,
  '24B1B': 21,
  '24B1C': 21,
  '24B1D': 21,
  '24B1E': 22,
  '24B1F': 22,
  '24B20': 23,
  '24B21': 23,
  '24B22': 24,
  '24B23': 26,
  '24B24': 26,
  '24B25': 30,
  '24B26': 7,
  '24B27': 7,
  '24B28': 8,
  '24B29': 8,
  '24B2A': 8,
  '24B2B': 8,
  '24B2C': 9,
  '24B2D': 9,
  '24B2E': 9,
  '24B2F': 9,
  '24B30': 9,
  '24B31': 9,
  '24B32': 9,
  '24B33': 10,
  '24B34': 10,
  '24B35': 10,
  '24B36': 10,
  '24B37': 10,
  '24B38': 10,
  '24B39': 10,
  '24B3A': 10,
  '24B3B': 10,
  '24B3C': 10,
  '24B3D': 10,
  '24B3E': 11,
  '24B3F': 11,
  '24B40': 11,
  '24B41': 11,
  '24B42': 11,
  '24B43': 11,
  '24B44': 11,
  '24B45': 11,
  '24B46': 11,
  '24B47': 11,
  '24B48': 11,
  '24B49': 11,
  '24B4A': 11,
  '24B4B': 11,
  '24B4C': 12,
  '24B4D': 12,
  '24B4E': 12,
  '24B4F': 12,
  '24B50': 12,
  '24B51': 12,
  '24B52': 12,
  '24B53': 12,
  '24B54': 12,
  '24B55': 12,
  '24B56': 12,
  '24B57': 12,
  '24B58': 12,
  '24B59': 12,
  '24B5A': 12,
  '24B5B': 13,
  '24B5C': 13,
  '24B5D': 13,
  '24B5E': 13,
  '24B5F': 13,
  '24B60': 13,
  '24B61': 13,
  '24B62': 13,
  '24B63': 13,
  '24B64': 13,
  '24B65': 13,
  '24B66': 13,
  '24B67': 14,
  '24B68': 14,
  '24B69': 14,
  '24B6A': 14,
  '24B6B': 14,
  '24B6C': 14,
  '24B6D': 14,
  '24B6E': 14,
  '24B6F': 14,
  '24B70': 14,
  '24B71': 14,
  '24B72': 13,
  '24B73': 14,
  '24B74': 14,
  '24B75': 14,
  '24B76': 14,
  '24B77': 14,
  '24B78': 14,
  '24B79': 15,
  '24B7A': 15,
  '24B7B': 15,
  '24B7C': 15,
  '24B7D': 15,
  '24B7E': 15,
  '24B7F': 15,
  '24B80': 15,
  '24B81': 15,
  '24B82': 15,
  '24B83': 15,
  '24B84': 15,
  '24B85': 16,
  '24B86': 16,
  '24B87': 16,
  '24B88': 16,
  '24B89': 16,
  '24B8A': 16,
  '24B8B': 16,
  '24B8C': 16,
  '24B8D': 16,
  '24B8E': 17,
  '24B8F': 17,
  '24B90': 17,
  '24B91': 17,
  '24B92': 17,
  '24B93': 17,
  '24B94': 17,
  '24B95': 17,
  '24B96': 13,
  '24B97': 17,
  '24B98': 18,
  '24B99': 18,
  '24B9A': 18,
  '24B9B': 18,
  '24B9C': 18,
  '24B9D': 18,
  '24B9E': 18,
  '24B9F': 18,
  '24BA0': 19,
  '24BA1': 19,
  '24BA2': 20,
  '24BA3': 20,
  '24BA4': 20,
  '24BA5': 20,
  '24BA6': 21,
  '24BA7': 21,
  '24BA8': 21,
  '24BA9': 21,
  '24BAA': 21,
  '24BAB': 21,
  '24BAC': 21,
  '24BAD': 22,
  '24BAE': 22,
  '24BAF': 22,
  '24BB0': 23,
  '24BB1': 23,
  '24BB2': 23,
  '24BB3': 23,
  '24BB4': 23,
  '24BB5': 23,
  '24BB6': 24,
  '24BB7': 25,
  '24BB8': 27,
  '24BB9': 29,
  '24BBA': 5,
  '24BBB': 8,
  '24BBC': 8,
  '24BBD': 8,
  '24BBE': 8,
  '24BBF': 8,
  '24BC0': 8,
  '24BC1': 9,
  '24BC2': 9,
  '24BC3': 9,
  '24BC4': 9,
  '24BC5': 10,
  '24BC6': 12,
  '24BC7': 13,
  '24BC8': 14,
  '24BC9': 15,
  '24BCA': 15,
  '24BCB': 16,
  '24BCC': 16,
  '24BCD': 17,
  '24BCE': 18,
  '24BCF': 18,
  '24BD0': 21,
  '24BD1': 22,
  '24BD2': 28,
  '24BD3': 5,
  '24BD4': 6,
  '24BD5': 8,
  '24BD6': 8,
  '24BD7': 8,
  '24BD8': 9,
  '24BD9': 9,
  '24BDA': 9,
  '24BDB': 9,
  '24BDC': 9,
  '24BDD': 9,
  '24BDE': 9,
  '24BDF': 10,
  '24BE0': 11,
  '24BE1': 11,
  '24BE2': 11,
  '24BE3': 11,
  '24BE4': 11,
  '24BE5': 11,
  '24BE6': 12,
  '24BE7': 12,
  '24BE8': 12,
  '24BE9': 12,
  '24BEA': 12,
  '24BEB': 12,
  '24BEC': 13,
  '24BED': 13,
  '24BEE': 13,
  '24BEF': 13,
  '24BF0': 13,
  '24BF1': 13,
  '24BF2': 14,
  '24BF3': 14,
  '24BF4': 14,
  '24BF5': 15,
  '24BF6': 16,
  '24BF7': 17,
  '24BF8': 17,
  '24BF9': 17,
  '24BFA': 18,
  '24BFB': 19,
  '24BFC': 19,
  '24BFD': 19,
  '24BFE': 19,
  '24BFF': 19,
  '24C00': 21,
  '24C01': 22,
  '24C02': 22,
  '24C03': 6,
  '24C04': 7,
  '24C05': 8,
  '24C06': 8,
  '24C07': 10,
  '24C08': 11,
  '24C09': 12,
  '24C0A': 14,
  '24C0B': 15,
  '24C0C': 15,
  '24C0D': 18,
  '24C0E': 19,
  '24C0F': 22,
  '24C10': 25,
  '24C11': 23,
  '24C12': 5,
  '24C13': 6,
  '24C14': 6,
  '24C15': 7,
  '24C16': 7,
  '24C17': 7,
  '24C18': 7,
  '24C19': 7,
  '24C1A': 7,
  '24C1B': 7,
  '24C1C': 7,
  '24C1D': 8,
  '24C1E': 8,
  '24C1F': 8,
  '24C20': 8,
  '24C21': 8,
  '24C22': 8,
  '24C23': 8,
  '24C24': 8,
  '24C25': 8,
  '24C26': 8,
  '24C27': 8,
  '24C28': 8,
  '24C29': 9,
  '24C2A': 9,
  '24C2B': 9,
  '24C2C': 9,
  '24C2D': 9,
  '24C2E': 9,
  '24C2F': 9,
  '24C30': 9,
  '24C31': 9,
  '24C32': 9,
  '24C33': 9,
  '24C34': 9,
  '24C35': 9,
  '24C36': 9,
  '24C37': 9,
  '24C38': 9,
  '24C39': 9,
  '24C3A': 9,
  '24C3B': 9,
  '24C3C': 9,
  '24C3D': 9,
  '24C3E': 9,
  '24C3F': 9,
  '24C40': 9,
  '24C41': 9,
  '24C42': 9,
  '24C43': 9,
  '24C44': 9,
  '24C45': 9,
  '24C46': 9,
  '24C47': 9,
  '24C48': 9,
  '24C49': 9,
  '24C4A': 9,
  '24C4B': 9,
  '24C4C': 10,
  '24C4D': 10,
  '24C4E': 10,
  '24C4F': 10,
  '24C50': 10,
  '24C51': 10,
  '24C52': 10,
  '24C53': 10,
  '24C54': 10,
  '24C55': 10,
  '24C56': 10,
  '24C57': 10,
  '24C58': 10,
  '24C59': 10,
  '24C5A': 10,
  '24C5B': 10,
  '24C5C': 10,
  '24C5D': 10,
  '24C5E': 10,
  '24C5F': 10,
  '24C60': 10,
  '24C61': 10,
  '24C62': 10,
  '24C63': 10,
  '24C64': 10,
  '24C65': 11,
  '24C66': 11,
  '24C67': 11,
  '24C68': 11,
  '24C69': 11,
  '24C6A': 11,
  '24C6B': 11,
  '24C6C': 11,
  '24C6D': 11,
  '24C6E': 11,
  '24C6F': 11,
  '24C70': 11,
  '24C71': 11,
  '24C72': 11,
  '24C73': 11,
  '24C74': 11,
  '24C75': 11,
  '24C76': 11,
  '24C77': 11,
  '24C78': 11,
  '24C79': 11,
  '24C7A': 11,
  '24C7B': 12,
  '24C7C': 12,
  '24C7D': 12,
  '24C7E': 12,
  '24C7F': 12,
  '24C80': 12,
  '24C81': 12,
  '24C82': 12,
  '24C83': 12,
  '24C84': 12,
  '24C85': 12,
  '24C86': 12,
  '24C87': 12,
  '24C88': 12,
  '24C89': 12,
  '24C8A': 12,
  '24C8B': 12,
  '24C8C': 12,
  '24C8D': 12,
  '24C8E': 12,
  '24C8F': 12,
  '24C90': 12,
  '24C91': 13,
  '24C92': 13,
  '24C93': 13,
  '24C94': 13,
  '24C95': 13,
  '24C96': 13,
  '24C97': 13,
  '24C98': 13,
  '24C99': 13,
  '24C9A': 13,
  '24C9B': 13,
  '24C9C': 13,
  '24C9D': 13,
  '24C9E': 13,
  '24C9F': 13,
  '24CA0': 13,
  '24CA1': 13,
  '24CA2': 13,
  '24CA3': 13,
  '24CA4': 13,
  '24CA5': 13,
  '24CA6': 13,
  '24CA7': 13,
  '24CA8': 13,
  '24CA9': 13,
  '24CAA': 13,
  '24CAB': 14,
  '24CAC': 14,
  '24CAD': 14,
  '24CAE': 14,
  '24CAF': 14,
  '24CB0': 14,
  '24CB1': 14,
  '24CB2': 14,
  '24CB3': 14,
  '24CB4': 14,
  '24CB5': 14,
  '24CB6': 14,
  '24CB7': 15,
  '24CB8': 15,
  '24CB9': 15,
  '24CBA': 15,
  '24CBB': 15,
  '24CBC': 15,
  '24CBD': 15,
  '24CBE': 15,
  '24CBF': 15,
  '24CC0': 15,
  '24CC1': 15,
  '24CC2': 15,
  '24CC3': 15,
  '24CC4': 15,
  '24CC5': 15,
  '24CC6': 15,
  '24CC7': 15,
  '24CC8': 16,
  '24CC9': 16,
  '24CCA': 16,
  '24CCB': 16,
  '24CCC': 16,
  '24CCD': 16,
  '24CCE': 16,
  '24CCF': 16,
  '24CD0': 16,
  '24CD1': 16,
  '24CD2': 17,
  '24CD3': 17,
  '24CD4': 17,
  '24CD5': 17,
  '24CD6': 17,
  '24CD7': 17,
  '24CD8': 17,
  '24CD9': 17,
  '24CDA': 17,
  '24CDB': 17,
  '24CDC': 17,
  '24CDD': 17,
  '24CDE': 17,
  '24CDF': 17,
  '24CE0': 17,
  '24CE1': 17,
  '24CE2': 17,
  '24CE3': 17,
  '24CE4': 18,
  '24CE5': 18,
  '24CE6': 18,
  '24CE7': 18,
  '24CE8': 18,
  '24CE9': 19,
  '24CEA': 19,
  '24CEB': 19,
  '24CEC': 19,
  '24CED': 19,
  '24CEE': 19,
  '24CEF': 19,
  '24CF0': 19,
  '24CF1': 20,
  '24CF2': 19,
  '24CF3': 20,
  '24CF4': 20,
  '24CF5': 20,
  '24CF6': 20,
  '24CF7': 20,
  '24CF8': 20,
  '24CF9': 21,
  '24CFA': 21,
  '24CFB': 21,
  '24CFC': 21,
  '24CFD': 21,
  '24CFE': 21,
  '24CFF': 22,
  '24D00': 22,
  '24D01': 22,
  '24D02': 23,
  '24D03': 23,
  '24D04': 23,
  '24D05': 24,
  '24D06': 25,
  '24D07': 26,
  '24D08': 26,
  '24D09': 26,
  '24D0A': 26,
  '24D0B': 26,
  '24D0C': 27,
  '24D0D': 27,
  '24D0E': 28,
  '24D0F': 30,
  '24D10': 32,
  '24D11': 33,
  '24D12': 36,
  '24D13': 5,
  '24D14': 5,
  '24D15': 6,
  '24D16': 8,
  '24D17': 10,
  '24D18': 9,
  '24D19': 12,
  '24D1A': 12,
  '24D1B': 12,
  '24D1C': 13,
  '24D1D': 13,
  '24D1E': 15,
  '24D1F': 16,
  '24D20': 16,
  '24D21': 16,
  '24D22': 16,
  '24D23': 18,
  '24D24': 22,
  '24D25': 6,
  '24D26': 7,
  '24D27': 7,
  '24D28': 7,
  '24D29': 7,
  '24D2A': 7,
  '24D2B': 7,
  '24D2C': 7,
  '24D2D': 7,
  '24D2E': 7,
  '24D2F': 8,
  '24D30': 8,
  '24D31': 8,
  '24D32': 8,
  '24D33': 8,
  '24D34': 8,
  '24D35': 8,
  '24D36': 8,
  '24D37': 9,
  '24D38': 9,
  '24D39': 9,
  '24D3A': 9,
  '24D3B': 9,
  '24D3C': 9,
  '24D3D': 9,
  '24D3E': 9,
  '24D3F': 9,
  '24D40': 9,
  '24D41': 9,
  '24D42': 9,
  '24D43': 9,
  '24D44': 9,
  '24D45': 9,
  '24D46': 9,
  '24D47': 9,
  '24D48': 9,
  '24D49': 9,
  '24D4A': 9,
  '24D4B': 9,
  '24D4C': 9,
  '24D4D': 9,
  '24D4E': 9,
  '24D4F': 9,
  '24D50': 9,
  '24D51': 9,
  '24D52': 9,
  '24D53': 9,
  '24D54': 9,
  '24D55': 9,
  '24D56': 9,
  '24D57': 10,
  '24D58': 10,
  '24D59': 10,
  '24D5A': 10,
  '24D5B': 10,
  '24D5C': 10,
  '24D5D': 10,
  '24D5E': 10,
  '24D5F': 10,
  '24D60': 10,
  '24D61': 10,
  '24D62': 10,
  '24D63': 10,
  '24D64': 10,
  '24D65': 10,
  '24D66': 10,
  '24D67': 10,
  '24D68': 10,
  '24D69': 10,
  '24D6A': 10,
  '24D6B': 10,
  '24D6C': 10,
  '24D6D': 10,
  '24D6E': 10,
  '24D6F': 10,
  '24D70': 10,
  '24D71': 10,
  '24D72': 10,
  '24D73': 10,
  '24D74': 10,
  '24D75': 10,
  '24D76': 10,
  '24D77': 11,
  '24D78': 11,
  '24D79': 11,
  '24D7A': 11,
  '24D7B': 11,
  '24D7C': 11,
  '24D7D': 11,
  '24D7E': 11,
  '24D7F': 11,
  '24D80': 11,
  '24D81': 11,
  '24D82': 11,
  '24D83': 11,
  '24D84': 11,
  '24D85': 11,
  '24D86': 11,
  '24D87': 11,
  '24D88': 11,
  '24D89': 11,
  '24D8A': 11,
  '24D8B': 11,
  '24D8C': 11,
  '24D8D': 11,
  '24D8E': 11,
  '24D8F': 11,
  '24D90': 11,
  '24D91': 11,
  '24D92': 11,
  '24D93': 11,
  '24D94': 11,
  '24D95': 12,
  '24D96': 12,
  '24D97': 12,
  '24D98': 12,
  '24D99': 12,
  '24D9A': 12,
  '24D9B': 12,
  '24D9C': 12,
  '24D9D': 12,
  '24D9E': 12,
  '24D9F': 12,
  '24DA0': 12,
  '24DA1': 12,
  '24DA2': 12,
  '24DA3': 12,
  '24DA4': 12,
  '24DA5': 12,
  '24DA6': 12,
  '24DA7': 12,
  '24DA8': 12,
  '24DA9': 12,
  '24DAA': 12,
  '24DAB': 12,
  '24DAC': 12,
  '24DAD': 12,
  '24DAE': 12,
  '24DAF': 12,
  '24DB0': 12,
  '24DB1': 12,
  '24DB2': 12,
  '24DB3': 12,
  '24DB4': 12,
  '24DB5': 12,
  '24DB6': 12,
  '24DB7': 12,
  '24DB8': 12,
  '24DB9': 12,
  '24DBA': 12,
  '24DBB': 12,
  '24DBC': 12,
  '24DBD': 12,
  '24DBE': 12,
  '24DBF': 12,
  '24DC0': 12,
  '24DC1': 12,
  '24DC2': 13,
  '24DC3': 13,
  '24DC4': 13,
  '24DC5': 13,
  '24DC6': 13,
  '24DC7': 13,
  '24DC8': 13,
  '24DC9': 13,
  '24DCA': 13,
  '24DCB': 13,
  '24DCC': 13,
  '24DCD': 13,
  '24DCE': 13,
  '24DCF': 13,
  '24DD0': 13,
  '24DD1': 13,
  '24DD2': 13,
  '24DD3': 13,
  '24DD4': 13,
  '24DD5': 13,
  '24DD6': 13,
  '24DD7': 13,
  '24DD8': 13,
  '24DD9': 13,
  '24DDA': 13,
  '24DDB': 13,
  '24DDC': 13,
  '24DDD': 13,
  '24DDE': 13,
  '24DDF': 13,
  '24DE0': 13,
  '24DE1': 13,
  '24DE2': 13,
  '24DE3': 13,
  '24DE4': 13,
  '24DE5': 13,
  '24DE6': 13,
  '24DE7': 13,
  '24DE8': 13,
  '24DE9': 13,
  '24DEA': 13,
  '24DEB': 13,
  '24DEC': 13,
  '24DED': 13,
  '24DEE': 13,
  '24DEF': 13,
  '24DF0': 13,
  '24DF1': 13,
  '24DF2': 13,
  '24DF3': 13,
  '24DF4': 13,
  '24DF5': 13,
  '24DF6': 13,
  '24DF7': 13,
  '24DF8': 13,
  '24DF9': 13,
  '24DFA': 13,
  '24DFB': 14,
  '24DFC': 14,
  '24DFD': 14,
  '24DFE': 14,
  '24DFF': 14,
  '24E00': 14,
  '24E01': 14,
  '24E02': 14,
  '24E03': 14,
  '24E04': 14,
  '24E05': 14,
  '24E06': 14,
  '24E07': 14,
  '24E08': 14,
  '24E09': 14,
  '24E0A': 14,
  '24E0B': 14,
  '24E0C': 14,
  '24E0D': 14,
  '24E0E': 14,
  '24E0F': 14,
  '24E10': 14,
  '24E11': 14,
  '24E12': 14,
  '24E13': 14,
  '24E14': 14,
  '24E15': 14,
  '24E16': 14,
  '24E17': 14,
  '24E18': 14,
  '24E19': 14,
  '24E1A': 14,
  '24E1B': 14,
  '24E1C': 14,
  '24E1D': 14,
  '24E1E': 14,
  '24E1F': 14,
  '24E20': 14,
  '24E21': 14,
  '24E22': 14,
  '24E23': 14,
  '24E24': 14,
  '24E25': 14,
  '24E26': 14,
  '24E27': 14,
  '24E28': 14,
  '24E29': 14,
  '24E2A': 15,
  '24E2B': 15,
  '24E2C': 15,
  '24E2D': 15,
  '24E2E': 15,
  '24E2F': 15,
  '24E30': 15,
  '24E31': 15,
  '24E32': 15,
  '24E33': 15,
  '24E34': 15,
  '24E35': 15,
  '24E36': 15,
  '24E37': 15,
  '24E38': 15,
  '24E39': 15,
  '24E3A': 15,
  '24E3B': 15,
  '24E3C': 15,
  '24E3D': 15,
  '24E3E': 15,
  '24E3F': 15,
  '24E40': 15,
  '24E41': 15,
  '24E42': 15,
  '24E43': 15,
  '24E44': 15,
  '24E45': 15,
  '24E46': 15,
  '24E47': 15,
  '24E48': 15,
  '24E49': 15,
  '24E4A': 15,
  '24E4B': 15,
  '24E4C': 15,
  '24E4D': 15,
  '24E4E': 15,
  '24E4F': 15,
  '24E50': 15,
  '24E51': 15,
  '24E52': 15,
  '24E53': 15,
  '24E54': 15,
  '24E55': 15,
  '24E56': 15,
  '24E57': 15,
  '24E58': 15,
  '24E59': 15,
  '24E5A': 15,
  '24E5B': 15,
  '24E5C': 15,
  '24E5D': 16,
  '24E5E': 16,
  '24E5F': 16,
  '24E60': 16,
  '24E61': 16,
  '24E62': 16,
  '24E63': 16,
  '24E64': 16,
  '24E65': 16,
  '24E66': 16,
  '24E67': 16,
  '24E68': 16,
  '24E69': 16,
  '24E6A': 16,
  '24E6B': 16,
  '24E6C': 16,
  '24E6D': 16,
  '24E6E': 16,
  '24E6F': 16,
  '24E70': 16,
  '24E71': 16,
  '24E72': 16,
  '24E73': 16,
  '24E74': 16,
  '24E75': 16,
  '24E76': 16,
  '24E77': 16,
  '24E78': 16,
  '24E79': 16,
  '24E7A': 16,
  '24E7B': 16,
  '24E7C': 16,
  '24E7D': 16,
  '24E7E': 16,
  '24E7F': 16,
  '24E80': 16,
  '24E81': 16,
  '24E82': 16,
  '24E83': 17,
  '24E84': 17,
  '24E85': 17,
  '24E86': 17,
  '24E87': 17,
  '24E88': 17,
  '24E89': 17,
  '24E8A': 17,
  '24E8B': 17,
  '24E8C': 17,
  '24E8D': 17,
  '24E8E': 17,
  '24E8F': 17,
  '24E90': 17,
  '24E91': 17,
  '24E92': 17,
  '24E93': 17,
  '24E94': 17,
  '24E95': 17,
  '24E96': 17,
  '24E97': 17,
  '24E98': 17,
  '24E99': 17,
  '24E9A': 17,
  '24E9B': 17,
  '24E9C': 17,
  '24E9D': 17,
  '24E9E': 16,
  '24E9F': 17,
  '24EA0': 17,
  '24EA1': 17,
  '24EA2': 17,
  '24EA3': 17,
  '24EA4': 17,
  '24EA5': 17,
  '24EA6': 17,
  '24EA7': 17,
  '24EA8': 17,
  '24EA9': 17,
  '24EAA': 17,
  '24EAB': 17,
  '24EAC': 17,
  '24EAD': 17,
  '24EAE': 17,
  '24EAF': 17,
  '24EB0': 17,
  '24EB1': 17,
  '24EB2': 17,
  '24EB3': 17,
  '24EB4': 17,
  '24EB5': 17,
  '24EB6': 17,
  '24EB7': 17,
  '24EB8': 17,
  '24EB9': 17,
  '24EBA': 18,
  '24EBB': 18,
  '24EBC': 18,
  '24EBD': 18,
  '24EBE': 18,
  '24EBF': 18,
  '24EC0': 18,
  '24EC1': 18,
  '24EC2': 18,
  '24EC3': 18,
  '24EC4': 18,
  '24EC5': 18,
  '24EC6': 18,
  '24EC7': 18,
  '24EC8': 18,
  '24EC9': 18,
  '24ECA': 18,
  '24ECB': 18,
  '24ECC': 18,
  '24ECD': 18,
  '24ECE': 18,
  '24ECF': 18,
  '24ED0': 18,
  '24ED1': 18,
  '24ED2': 18,
  '24ED3': 18,
  '24ED4': 18,
  '24ED5': 19,
  '24ED6': 19,
  '24ED7': 19,
  '24ED8': 19,
  '24ED9': 19,
  '24EDA': 19,
  '24EDB': 19,
  '24EDC': 19,
  '24EDD': 19,
  '24EDE': 19,
  '24EDF': 19,
  '24EE0': 19,
  '24EE1': 19,
  '24EE2': 19,
  '24EE3': 19,
  '24EE4': 19,
  '24EE5': 19,
  '24EE6': 19,
  '24EE7': 19,
  '24EE8': 19,
  '24EE9': 19,
  '24EEA': 19,
  '24EEB': 19,
  '24EEC': 19,
  '24EED': 19,
  '24EEE': 19,
  '24EEF': 19,
  '24EF0': 20,
  '24EF1': 20,
  '24EF2': 20,
  '24EF3': 20,
  '24EF4': 20,
  '24EF5': 20,
  '24EF6': 20,
  '24EF7': 20,
  '24EF8': 20,
  '24EF9': 20,
  '24EFA': 20,
  '24EFB': 20,
  '24EFC': 20,
  '24EFD': 20,
  '24EFE': 20,
  '24EFF': 20,
  '24F00': 21,
  '24F01': 21,
  '24F02': 21,
  '24F03': 21,
  '24F04': 21,
  '24F05': 21,
  '24F06': 21,
  '24F07': 21,
  '24F08': 21,
  '24F09': 21,
  '24F0A': 21,
  '24F0B': 22,
  '24F0C': 22,
  '24F0D': 22,
  '24F0E': 22,
  '24F0F': 22,
  '24F10': 23,
  '24F11': 23,
  '24F12': 23,
  '24F13': 23,
  '24F14': 23,
  '24F15': 23,
  '24F16': 23,
  '24F17': 23,
  '24F18': 24,
  '24F19': 24,
  '24F1A': 24,
  '24F1B': 24,
  '24F1C': 25,
  '24F1D': 25,
  '24F1E': 25,
  '24F1F': 26,
  '24F20': 26,
  '24F21': 26,
  '24F22': 27,
  '24F23': 28,
  '24F24': 29,
  '24F25': 7,
  '24F26': 7,
  '24F27': 9,
  '24F28': 9,
  '24F29': 10,
  '24F2A': 10,
  '24F2B': 10,
  '24F2C': 11,
  '24F2D': 11,
  '24F2E': 11,
  '24F2F': 12,
  '24F30': 12,
  '24F31': 12,
  '24F32': 12,
  '24F33': 13,
  '24F34': 13,
  '24F35': 13,
  '24F36': 14,
  '24F37': 15,
  '24F38': 15,
  '24F39': 16,
  '24F3A': 16,
  '24F3B': 20,
  '24F3C': 16,
  '24F3D': 6,
  '24F3E': 6,
  '24F3F': 7,
  '24F40': 7,
  '24F41': 7,
  '24F42': 8,
  '24F43': 8,
  '24F44': 8,
  '24F45': 8,
  '24F46': 8,
  '24F47': 8,
  '24F48': 9,
  '24F49': 9,
  '24F4A': 9,
  '24F4B': 9,
  '24F4C': 9,
  '24F4D': 9,
  '24F4E': 9,
  '24F4F': 9,
  '24F50': 9,
  '24F51': 9,
  '24F52': 9,
  '24F53': 9,
  '24F54': 9,
  '24F55': 9,
  '24F56': 9,
  '24F57': 9,
  '24F58': 10,
  '24F59': 10,
  '24F5A': 10,
  '24F5B': 10,
  '24F5C': 10,
  '24F5D': 10,
  '24F5E': 10,
  '24F5F': 10,
  '24F60': 10,
  '24F61': 10,
  '24F62': 10,
  '24F63': 10,
  '24F64': 10,
  '24F65': 11,
  '24F66': 11,
  '24F67': 11,
  '24F68': 11,
  '24F69': 11,
  '24F6A': 11,
  '24F6B': 11,
  '24F6C': 12,
  '24F6D': 12,
  '24F6E': 12,
  '24F6F': 12,
  '24F70': 12,
  '24F71': 12,
  '24F72': 12,
  '24F73': 12,
  '24F74': 12,
  '24F75': 12,
  '24F76': 12,
  '24F77': 12,
  '24F78': 12,
  '24F79': 13,
  '24F7A': 13,
  '24F7B': 13,
  '24F7C': 13,
  '24F7D': 13,
  '24F7E': 13,
  '24F7F': 13,
  '24F80': 13,
  '24F81': 13,
  '24F82': 13,
  '24F83': 13,
  '24F84': 13,
  '24F85': 13,
  '24F86': 13,
  '24F87': 13,
  '24F88': 14,
  '24F89': 14,
  '24F8A': 14,
  '24F8B': 14,
  '24F8C': 14,
  '24F8D': 14,
  '24F8E': 14,
  '24F8F': 14,
  '24F90': 14,
  '24F91': 14,
  '24F92': 14,
  '24F93': 14,
  '24F94': 15,
  '24F95': 15,
  '24F96': 15,
  '24F97': 15,
  '24F98': 15,
  '24F99': 15,
  '24F9A': 15,
  '24F9B': 16,
  '24F9C': 16,
  '24F9D': 16,
  '24F9E': 16,
  '24F9F': 16,
  '24FA0': 17,
  '24FA1': 17,
  '24FA2': 17,
  '24FA3': 17,
  '24FA4': 17,
  '24FA5': 17,
  '24FA6': 17,
  '24FA7': 18,
  '24FA8': 18,
  '24FA9': 18,
  '24FAA': 18,
  '24FAB': 19,
  '24FAC': 19,
  '24FAD': 19,
  '24FAE': 19,
  '24FAF': 19,
  '24FB0': 19,
  '24FB1': 19,
  '24FB2': 19,
  '24FB3': 19,
  '24FB4': 20,
  '24FB5': 20,
  '24FB6': 20,
  '24FB7': 20,
  '24FB8': 21,
  '24FB9': 21,
  '24FBA': 21,
  '24FBB': 22,
  '24FBC': 22,
  '24FBD': 22,
  '24FBE': 23,
  '24FBF': 23,
  '24FC0': 24,
  '24FC1': 24,
  '24FC2': 24,
  '24FC3': 24,
  '24FC4': 25,
  '24FC5': 29,
  '24FC6': 7,
  '24FC7': 7,
  '24FC8': 8,
  '24FC9': 8,
  '24FCA': 8,
  '24FCB': 8,
  '24FCC': 8,
  '24FCD': 8,
  '24FCE': 9,
  '24FCF': 9,
  '24FD0': 9,
  '24FD1': 9,
  '24FD2': 9,
  '24FD3': 9,
  '24FD4': 9,
  '24FD5': 10,
  '24FD6': 10,
  '24FD7': 10,
  '24FD8': 10,
  '24FD9': 10,
  '24FDA': 10,
  '24FDB': 10,
  '24FDC': 10,
  '24FDD': 10,
  '24FDE': 10,
  '24FDF': 11,
  '24FE0': 11,
  '24FE1': 11,
  '24FE2': 11,
  '24FE3': 11,
  '24FE4': 11,
  '24FE5': 11,
  '24FE6': 11,
  '24FE7': 12,
  '24FE8': 12,
  '24FE9': 12,
  '24FEA': 12,
  '24FEB': 12,
  '24FEC': 12,
  '24FED': 12,
  '24FEE': 12,
  '24FEF': 13,
  '24FF0': 12,
  '24FF1': 12,
  '24FF2': 12,
  '24FF3': 13,
  '24FF4': 13,
  '24FF5': 13,
  '24FF6': 13,
  '24FF7': 13,
  '24FF8': 13,
  '24FF9': 13,
  '24FFA': 13,
  '24FFB': 13,
  '24FFC': 13,
  '24FFD': 13,
  '24FFE': 13,
  '24FFF': 13,
  '25000': 14,
  '25001': 14,
  '25002': 14,
  '25003': 14,
  '25004': 14,
  '25005': 14,
  '25006': 14,
  '25007': 14,
  '25008': 14,
  '25009': 14,
  '2500A': 14,
  '2500B': 14,
  '2500C': 14,
  '2500D': 15,
  '2500E': 15,
  '2500F': 15,
  '25010': 15,
  '25011': 15,
  '25012': 15,
  '25013': 15,
  '25014': 16,
  '25015': 16,
  '25016': 16,
  '25017': 16,
  '25018': 16,
  '25019': 16,
  '2501A': 16,
  '2501B': 16,
  '2501C': 16,
  '2501D': 16,
  '2501E': 16,
  '2501F': 17,
  '25020': 17,
  '25021': 17,
  '25022': 18,
  '25023': 18,
  '25024': 18,
  '25025': 18,
  '25026': 18,
  '25027': 18,
  '25028': 18,
  '25029': 18,
  '2502A': 18,
  '2502B': 19,
  '2502C': 19,
  '2502D': 19,
  '2502E': 19,
  '2502F': 20,
  '25030': 20,
  '25031': 20,
  '25032': 20,
  '25033': 20,
  '25034': 21,
  '25035': 21,
  '25036': 22,
  '25037': 22,
  '25038': 22,
  '25039': 24,
  '2503A': 24,
  '2503B': 25,
  '2503C': 26,
  '2503D': 29,
  '2503E': 33,
  '2503F': 7,
  '25040': 7,
  '25041': 8,
  '25042': 8,
  '25043': 8,
  '25044': 8,
  '25045': 8,
  '25046': 9,
  '25047': 9,
  '25048': 9,
  '25049': 9,
  '2504A': 9,
  '2504B': 9,
  '2504C': 9,
  '2504D': 9,
  '2504E': 10,
  '2504F': 10,
  '25050': 10,
  '25051': 10,
  '25052': 10,
  '25053': 10,
  '25054': 10,
  '25055': 10,
  '25056': 10,
  '25057': 10,
  '25058': 10,
  '25059': 10,
  '2505A': 10,
  '2505B': 10,
  '2505C': 10,
  '2505D': 10,
  '2505E': 11,
  '2505F': 11,
  '25060': 11,
  '25061': 11,
  '25062': 11,
  '25063': 11,
  '25064': 11,
  '25065': 11,
  '25066': 11,
  '25067': 11,
  '25068': 11,
  '25069': 11,
  '2506A': 11,
  '2506B': 10,
  '2506C': 12,
  '2506D': 12,
  '2506E': 12,
  '2506F': 12,
  '25070': 12,
  '25071': 12,
  '25072': 12,
  '25073': 12,
  '25074': 12,
  '25075': 12,
  '25076': 12,
  '25077': 13,
  '25078': 13,
  '25079': 13,
  '2507A': 13,
  '2507B': 13,
  '2507C': 13,
  '2507D': 13,
  '2507E': 13,
  '2507F': 13,
  '25080': 13,
  '25081': 13,
  '25082': 13,
  '25083': 14,
  '25084': 14,
  '25085': 14,
  '25086': 14,
  '25087': 15,
  '25088': 15,
  '25089': 15,
  '2508A': 15,
  '2508B': 15,
  '2508C': 15,
  '2508D': 15,
  '2508E': 15,
  '2508F': 15,
  '25090': 15,
  '25091': 15,
  '25092': 15,
  '25093': 16,
  '25094': 16,
  '25095': 16,
  '25096': 16,
  '25097': 16,
  '25098': 16,
  '25099': 16,
  '2509A': 16,
  '2509B': 16,
  '2509C': 16,
  '2509D': 16,
  '2509E': 16,
  '2509F': 16,
  '250A0': 16,
  '250A1': 16,
  '250A2': 16,
  '250A3': 16,
  '250A4': 16,
  '250A5': 17,
  '250A6': 17,
  '250A7': 17,
  '250A8': 17,
  '250A9': 17,
  '250AA': 17,
  '250AB': 17,
  '250AC': 17,
  '250AD': 17,
  '250AE': 17,
  '250AF': 17,
  '250B0': 17,
  '250B1': 17,
  '250B2': 17,
  '250B3': 17,
  '250B4': 17,
  '250B5': 17,
  '250B6': 17,
  '250B7': 17,
  '250B8': 18,
  '250B9': 18,
  '250BA': 18,
  '250BB': 19,
  '250BC': 18,
  '250BD': 18,
  '250BE': 18,
  '250BF': 18,
  '250C0': 18,
  '250C1': 19,
  '250C2': 19,
  '250C3': 19,
  '250C4': 19,
  '250C5': 19,
  '250C6': 19,
  '250C7': 20,
  '250C8': 20,
  '250C9': 20,
  '250CA': 20,
  '250CB': 20,
  '250CC': 20,
  '250CD': 20,
  '250CE': 20,
  '250CF': 20,
  '250D0': 20,
  '250D1': 20,
  '250D2': 21,
  '250D3': 22,
  '250D4': 22,
  '250D5': 22,
  '250D6': 22,
  '250D7': 22,
  '250D8': 23,
  '250D9': 23,
  '250DA': 24,
  '250DB': 24,
  '250DC': 24,
  '250DD': 24,
  '250DE': 23,
  '250DF': 25,
  '250E0': 25,
  '250E1': 26,
  '250E2': 30,
  '250E3': 33,
  '250E4': 6,
  '250E5': 6,
  '250E6': 7,
  '250E7': 7,
  '250E8': 7,
  '250E9': 7,
  '250EA': 7,
  '250EB': 7,
  '250EC': 7,
  '250ED': 7,
  '250EE': 7,
  '250EF': 7,
  '250F0': 7,
  '250F1': 7,
  '250F2': 8,
  '250F3': 8,
  '250F4': 8,
  '250F5': 8,
  '250F6': 8,
  '250F7': 8,
  '250F8': 8,
  '250F9': 8,
  '250FA': 8,
  '250FB': 8,
  '250FC': 8,
  '250FD': 8,
  '250FE': 8,
  '250FF': 8,
  '25100': 8,
  '25101': 8,
  '25102': 8,
  '25103': 9,
  '25104': 9,
  '25105': 9,
  '25106': 9,
  '25107': 9,
  '25108': 9,
  '25109': 9,
  '2510A': 9,
  '2510B': 9,
  '2510C': 9,
  '2510D': 9,
  '2510E': 9,
  '2510F': 9,
  '25110': 9,
  '25111': 9,
  '25112': 9,
  '25113': 9,
  '25114': 9,
  '25115': 9,
  '25116': 9,
  '25117': 9,
  '25118': 9,
  '25119': 9,
  '2511A': 9,
  '2511B': 9,
  '2511C': 9,
  '2511D': 9,
  '2511E': 9,
  '2511F': 9,
  '25120': 9,
  '25121': 9,
  '25122': 9,
  '25123': 9,
  '25124': 9,
  '25125': 9,
  '25126': 9,
  '25127': 9,
  '25128': 9,
  '25129': 9,
  '2512A': 9,
  '2512B': 9,
  '2512C': 9,
  '2512D': 9,
  '2512E': 9,
  '2512F': 9,
  '25130': 9,
  '25131': 10,
  '25132': 10,
  '25133': 10,
  '25134': 10,
  '25135': 10,
  '25136': 10,
  '25137': 10,
  '25138': 10,
  '25139': 10,
  '2513A': 10,
  '2513B': 10,
  '2513C': 10,
  '2513D': 10,
  '2513E': 10,
  '2513F': 10,
  '25140': 10,
  '25141': 10,
  '25142': 10,
  '25143': 10,
  '25144': 10,
  '25145': 10,
  '25146': 10,
  '25147': 10,
  '25148': 10,
  '25149': 10,
  '2514A': 10,
  '2514B': 10,
  '2514C': 10,
  '2514D': 10,
  '2514E': 10,
  '2514F': 10,
  '25150': 10,
  '25151': 10,
  '25152': 10,
  '25153': 10,
  '25154': 10,
  '25155': 10,
  '25156': 10,
  '25157': 10,
  '25158': 10,
  '25159': 10,
  '2515A': 11,
  '2515B': 11,
  '2515C': 11,
  '2515D': 11,
  '2515E': 11,
  '2515F': 11,
  '25160': 11,
  '25161': 11,
  '25162': 11,
  '25163': 11,
  '25164': 11,
  '25165': 11,
  '25166': 11,
  '25167': 11,
  '25168': 11,
  '25169': 11,
  '2516A': 11,
  '2516B': 11,
  '2516C': 11,
  '2516D': 11,
  '2516E': 11,
  '2516F': 11,
  '25170': 11,
  '25171': 11,
  '25172': 11,
  '25173': 11,
  '25174': 11,
  '25175': 11,
  '25176': 11,
  '25177': 11,
  '25178': 11,
  '25179': 11,
  '2517A': 11,
  '2517B': 11,
  '2517C': 11,
  '2517D': 11,
  '2517E': 11,
  '2517F': 11,
  '25180': 11,
  '25181': 11,
  '25182': 11,
  '25183': 11,
  '25184': 11,
  '25185': 11,
  '25186': 11,
  '25187': 11,
  '25188': 11,
  '25189': 11,
  '2518A': 12,
  '2518B': 12,
  '2518C': 12,
  '2518D': 12,
  '2518E': 12,
  '2518F': 12,
  '25190': 12,
  '25191': 12,
  '25192': 12,
  '25193': 12,
  '25194': 12,
  '25195': 12,
  '25196': 12,
  '25197': 12,
  '25198': 12,
  '25199': 12,
  '2519A': 12,
  '2519B': 12,
  '2519C': 12,
  '2519D': 12,
  '2519E': 12,
  '2519F': 12,
  '251A0': 12,
  '251A1': 12,
  '251A2': 12,
  '251A3': 12,
  '251A4': 12,
  '251A5': 12,
  '251A6': 12,
  '251A7': 12,
  '251A8': 12,
  '251A9': 12,
  '251AA': 12,
  '251AB': 12,
  '251AC': 12,
  '251AD': 12,
  '251AE': 12,
  '251AF': 12,
  '251B0': 12,
  '251B1': 12,
  '251B2': 12,
  '251B3': 12,
  '251B4': 12,
  '251B5': 12,
  '251B6': 12,
  '251B7': 12,
  '251B8': 12,
  '251B9': 12,
  '251BA': 12,
  '251BB': 12,
  '251BC': 12,
  '251BD': 12,
  '251BE': 12,
  '251BF': 12,
  '251C0': 12,
  '251C1': 12,
  '251C2': 12,
  '251C3': 12,
  '251C4': 12,
  '251C5': 13,
  '251C6': 12,
  '251C7': 12,
  '251C8': 12,
  '251C9': 13,
  '251CA': 13,
  '251CB': 13,
  '251CC': 13,
  '251CD': 13,
  '251CE': 13,
  '251CF': 13,
  '251D0': 13,
  '251D1': 13,
  '251D2': 13,
  '251D3': 13,
  '251D4': 13,
  '251D5': 13,
  '251D6': 13,
  '251D7': 13,
  '251D8': 13,
  '251D9': 13,
  '251DA': 13,
  '251DB': 13,
  '251DC': 13,
  '251DD': 13,
  '251DE': 13,
  '251DF': 13,
  '251E0': 13,
  '251E1': 13,
  '251E2': 13,
  '251E3': 13,
  '251E4': 13,
  '251E5': 13,
  '251E6': 13,
  '251E7': 13,
  '251E8': 13,
  '251E9': 13,
  '251EA': 13,
  '251EB': 12,
  '251EC': 13,
  '251ED': 13,
  '251EE': 13,
  '251EF': 13,
  '251F0': 13,
  '251F1': 13,
  '251F2': 13,
  '251F3': 13,
  '251F4': 13,
  '251F5': 13,
  '251F6': 13,
  '251F7': 13,
  '251F8': 13,
  '251F9': 13,
  '251FA': 13,
  '251FB': 13,
  '251FC': 13,
  '251FD': 13,
  '251FE': 13,
  '251FF': 13,
  '25200': 13,
  '25201': 13,
  '25202': 14,
  '25203': 14,
  '25204': 14,
  '25205': 14,
  '25206': 14,
  '25207': 14,
  '25208': 14,
  '25209': 14,
  '2520A': 14,
  '2520B': 14,
  '2520C': 14,
  '2520D': 14,
  '2520E': 14,
  '2520F': 14,
  '25210': 14,
  '25211': 14,
  '25212': 14,
  '25213': 14,
  '25214': 14,
  '25215': 14,
  '25216': 14,
  '25217': 14,
  '25218': 14,
  '25219': 14,
  '2521A': 14,
  '2521B': 14,
  '2521C': 14,
  '2521D': 14,
  '2521E': 14,
  '2521F': 14,
  '25220': 14,
  '25221': 14,
  '25222': 14,
  '25223': 14,
  '25224': 14,
  '25225': 14,
  '25226': 14,
  '25227': 14,
  '25228': 14,
  '25229': 14,
  '2522A': 14,
  '2522B': 14,
  '2522C': 14,
  '2522D': 14,
  '2522E': 14,
  '2522F': 14,
  '25230': 14,
  '25231': 14,
  '25232': 14,
  '25233': 14,
  '25234': 14,
  '25235': 14,
  '25236': 14,
  '25237': 14,
  '25238': 14,
  '25239': 14,
  '2523A': 14,
  '2523B': 14,
  '2523C': 15,
  '2523D': 15,
  '2523E': 15,
  '2523F': 15,
  '25240': 15,
  '25241': 15,
  '25242': 15,
  '25243': 15,
  '25244': 15,
  '25245': 15,
  '25246': 15,
  '25247': 15,
  '25248': 15,
  '25249': 15,
  '2524A': 15,
  '2524B': 15,
  '2524C': 15,
  '2524D': 15,
  '2524E': 15,
  '2524F': 15,
  '25250': 15,
  '25251': 15,
  '25252': 15,
  '25253': 15,
  '25254': 15,
  '25255': 15,
  '25256': 15,
  '25257': 15,
  '25258': 15,
  '25259': 15,
  '2525A': 15,
  '2525B': 15,
  '2525C': 15,
  '2525D': 15,
  '2525E': 15,
  '2525F': 15,
  '25260': 15,
  '25261': 15,
  '25262': 15,
  '25263': 15,
  '25264': 15,
  '25265': 15,
  '25266': 15,
  '25267': 15,
  '25268': 15,
  '25269': 15,
  '2526A': 15,
  '2526B': 15,
  '2526C': 15,
  '2526D': 15,
  '2526E': 15,
  '2526F': 15,
  '25270': 15,
  '25271': 15,
  '25272': 15,
  '25273': 15,
  '25274': 16,
  '25275': 16,
  '25276': 16,
  '25277': 16,
  '25278': 16,
  '25279': 16,
  '2527A': 16,
  '2527B': 16,
  '2527C': 16,
  '2527D': 16,
  '2527E': 16,
  '2527F': 16,
  '25280': 16,
  '25281': 16,
  '25282': 16,
  '25283': 16,
  '25284': 16,
  '25285': 16,
  '25286': 16,
  '25287': 15,
  '25288': 16,
  '25289': 16,
  '2528A': 16,
  '2528B': 16,
  '2528C': 16,
  '2528D': 16,
  '2528E': 16,
  '2528F': 16,
  '25290': 16,
  '25291': 16,
  '25292': 16,
  '25293': 16,
  '25294': 16,
  '25295': 16,
  '25296': 16,
  '25297': 16,
  '25298': 16,
  '25299': 16,
  '2529A': 16,
  '2529B': 16,
  '2529C': 16,
  '2529D': 16,
  '2529E': 16,
  '2529F': 16,
  '252A0': 16,
  '252A1': 16,
  '252A2': 16,
  '252A3': 16,
  '252A4': 16,
  '252A5': 16,
  '252A6': 16,
  '252A7': 16,
  '252A8': 16,
  '252A9': 16,
  '252AA': 17,
  '252AB': 17,
  '252AC': 17,
  '252AD': 17,
  '252AE': 17,
  '252AF': 17,
  '252B0': 17,
  '252B1': 17,
  '252B2': 17,
  '252B3': 17,
  '252B4': 17,
  '252B5': 17,
  '252B6': 17,
  '252B7': 17,
  '252B8': 17,
  '252B9': 17,
  '252BA': 17,
  '252BB': 17,
  '252BC': 17,
  '252BD': 17,
  '252BE': 17,
  '252BF': 17,
  '252C0': 17,
  '252C1': 17,
  '252C2': 17,
  '252C3': 17,
  '252C4': 17,
  '252C5': 17,
  '252C6': 17,
  '252C7': 17,
  '252C8': 17,
  '252C9': 17,
  '252CA': 17,
  '252CB': 17,
  '252CC': 17,
  '252CD': 17,
  '252CE': 17,
  '252CF': 17,
  '252D0': 17,
  '252D1': 17,
  '252D2': 17,
  '252D3': 17,
  '252D4': 17,
  '252D5': 17,
  '252D6': 16,
  '252D7': 17,
  '252D8': 18,
  '252D9': 18,
  '252DA': 18,
  '252DB': 18,
  '252DC': 18,
  '252DD': 18,
  '252DE': 18,
  '252DF': 18,
  '252E0': 18,
  '252E1': 18,
  '252E2': 18,
  '252E3': 18,
  '252E4': 18,
  '252E5': 18,
  '252E6': 18,
  '252E7': 18,
  '252E8': 18,
  '252E9': 18,
  '252EA': 18,
  '252EB': 18,
  '252EC': 18,
  '252ED': 18,
  '252EE': 18,
  '252EF': 18,
  '252F0': 18,
  '252F1': 18,
  '252F2': 18,
  '252F3': 18,
  '252F4': 18,
  '252F5': 18,
  '252F6': 18,
  '252F7': 18,
  '252F8': 18,
  '252F9': 18,
  '252FA': 18,
  '252FB': 18,
  '252FC': 18,
  '252FD': 18,
  '252FE': 18,
  '252FF': 19,
  '25300': 19,
  '25301': 19,
  '25302': 19,
  '25303': 19,
  '25304': 19,
  '25305': 19,
  '25306': 19,
  '25307': 19,
  '25308': 19,
  '25309': 19,
  '2530A': 19,
  '2530B': 19,
  '2530C': 19,
  '2530D': 19,
  '2530E': 19,
  '2530F': 19,
  '25310': 19,
  '25311': 19,
  '25312': 19,
  '25313': 19,
  '25314': 19,
  '25315': 19,
  '25316': 19,
  '25317': 19,
  '25318': 19,
  '25319': 19,
  '2531A': 20,
  '2531B': 20,
  '2531C': 20,
  '2531D': 20,
  '2531E': 20,
  '2531F': 20,
  '25320': 20,
  '25321': 20,
  '25322': 20,
  '25323': 20,
  '25324': 20,
  '25325': 20,
  '25326': 20,
  '25327': 20,
  '25328': 20,
  '25329': 20,
  '2532A': 20,
  '2532B': 20,
  '2532C': 20,
  '2532D': 21,
  '2532E': 21,
  '2532F': 21,
  '25330': 21,
  '25331': 21,
  '25332': 21,
  '25333': 21,
  '25334': 21,
  '25335': 21,
  '25336': 21,
  '25337': 21,
  '25338': 21,
  '25339': 21,
  '2533A': 22,
  '2533B': 22,
  '2533C': 22,
  '2533D': 22,
  '2533E': 22,
  '2533F': 22,
  '25340': 22,
  '25341': 22,
  '25342': 21,
  '25343': 22,
  '25344': 22,
  '25345': 22,
  '25346': 22,
  '25347': 22,
  '25348': 23,
  '25349': 23,
  '2534A': 23,
  '2534B': 23,
  '2534C': 23,
  '2534D': 23,
  '2534E': 23,
  '2534F': 24,
  '25350': 24,
  '25351': 24,
  '25352': 24,
  '25353': 25,
  '25354': 26,
  '25355': 26,
  '25356': 26,
  '25357': 26,
  '25358': 26,
  '25359': 27,
  '2535A': 27,
  '2535B': 28,
  '2535C': 29,
  '2535D': 6,
  '2535E': 8,
  '2535F': 9,
  '25360': 9,
  '25361': 10,
  '25362': 10,
  '25363': 10,
  '25364': 10,
  '25365': 10,
  '25366': 10,
  '25367': 11,
  '25368': 11,
  '25369': 11,
  '2536A': 12,
  '2536B': 12,
  '2536C': 12,
  '2536D': 12,
  '2536E': 12,
  '2536F': 12,
  '25370': 12,
  '25371': 12,
  '25372': 13,
  '25373': 13,
  '25374': 13,
  '25375': 13,
  '25376': 14,
  '25377': 14,
  '25378': 14,
  '25379': 14,
  '2537A': 14,
  '2537B': 14,
  '2537C': 14,
  '2537D': 14,
  '2537E': 14,
  '2537F': 14,
  '25380': 14,
  '25381': 14,
  '25382': 15,
  '25383': 15,
  '25384': 15,
  '25385': 15,
  '25386': 15,
  '25387': 15,
  '25388': 15,
  '25389': 15,
  '2538A': 16,
  '2538B': 16,
  '2538C': 16,
  '2538D': 16,
  '2538E': 18,
  '2538F': 17,
  '25390': 17,
  '25391': 17,
  '25392': 17,
  '25393': 18,
  '25394': 18,
  '25395': 18,
  '25396': 19,
  '25397': 19,
  '25398': 19,
  '25399': 19,
  '2539A': 19,
  '2539B': 19,
  '2539C': 19,
  '2539D': 21,
  '2539E': 20,
  '2539F': 20,
  '253A0': 20,
  '253A1': 23,
  '253A2': 23,
  '253A3': 23,
  '253A4': 35,
  '253A5': 36,
  '253A6': 7,
  '253A7': 7,
  '253A8': 8,
  '253A9': 9,
  '253AA': 9,
  '253AB': 9,
  '253AC': 9,
  '253AD': 9,
  '253AE': 9,
  '253AF': 9,
  '253B0': 10,
  '253B1': 10,
  '253B2': 10,
  '253B3': 10,
  '253B4': 10,
  '253B5': 10,
  '253B6': 10,
  '253B7': 10,
  '253B8': 10,
  '253B9': 11,
  '253BA': 11,
  '253BB': 11,
  '253BC': 11,
  '253BD': 11,
  '253BE': 11,
  '253BF': 11,
  '253C0': 11,
  '253C1': 11,
  '253C2': 11,
  '253C3': 11,
  '253C4': 11,
  '253C5': 11,
  '253C6': 11,
  '253C7': 11,
  '253C8': 11,
  '253C9': 11,
  '253CA': 11,
  '253CB': 11,
  '253CC': 11,
  '253CD': 11,
  '253CE': 12,
  '253CF': 12,
  '253D0': 12,
  '253D1': 12,
  '253D2': 12,
  '253D3': 12,
  '253D4': 12,
  '253D5': 12,
  '253D6': 12,
  '253D7': 12,
  '253D8': 13,
  '253D9': 13,
  '253DA': 13,
  '253DB': 13,
  '253DC': 13,
  '253DD': 13,
  '253DE': 13,
  '253DF': 13,
  '253E0': 13,
  '253E1': 13,
  '253E2': 13,
  '253E3': 13,
  '253E4': 13,
  '253E5': 13,
  '253E6': 13,
  '253E7': 13,
  '253E8': 13,
  '253E9': 13,
  '253EA': 14,
  '253EB': 14,
  '253EC': 14,
  '253ED': 14,
  '253EE': 14,
  '253EF': 14,
  '253F0': 14,
  '253F1': 14,
  '253F2': 15,
  '253F3': 15,
  '253F4': 15,
  '253F5': 15,
  '253F6': 15,
  '253F7': 15,
  '253F8': 15,
  '253F9': 15,
  '253FA': 15,
  '253FB': 16,
  '253FC': 16,
  '253FD': 16,
  '253FE': 16,
  '253FF': 16,
  '25400': 17,
  '25401': 17,
  '25402': 17,
  '25403': 17,
  '25404': 17,
  '25405': 17,
  '25406': 17,
  '25407': 18,
  '25408': 18,
  '25409': 18,
  '2540A': 18,
  '2540B': 18,
  '2540C': 19,
  '2540D': 19,
  '2540E': 19,
  '2540F': 19,
  '25410': 23,
  '25411': 22,
  '25412': 22,
  '25413': 23,
  '25414': 23,
  '25415': 6,
  '25416': 6,
  '25417': 7,
  '25418': 7,
  '25419': 7,
  '2541A': 7,
  '2541B': 7,
  '2541C': 7,
  '2541D': 8,
  '2541E': 8,
  '2541F': 8,
  '25420': 8,
  '25421': 8,
  '25422': 8,
  '25423': 8,
  '25424': 8,
  '25425': 8,
  '25426': 8,
  '25427': 8,
  '25428': 8,
  '25429': 9,
  '2542A': 9,
  '2542B': 9,
  '2542C': 9,
  '2542D': 9,
  '2542E': 9,
  '2542F': 9,
  '25430': 9,
  '25431': 9,
  '25432': 9,
  '25433': 9,
  '25434': 9,
  '25435': 9,
  '25436': 9,
  '25437': 9,
  '25438': 9,
  '25439': 9,
  '2543A': 9,
  '2543B': 9,
  '2543C': 9,
  '2543D': 9,
  '2543E': 9,
  '2543F': 9,
  '25440': 9,
  '25441': 9,
  '25442': 9,
  '25443': 9,
  '25444': 9,
  '25445': 9,
  '25446': 10,
  '25447': 10,
  '25448': 10,
  '25449': 10,
  '2544A': 10,
  '2544B': 10,
  '2544C': 10,
  '2544D': 10,
  '2544E': 10,
  '2544F': 10,
  '25450': 10,
  '25451': 10,
  '25452': 10,
  '25453': 10,
  '25454': 10,
  '25455': 10,
  '25456': 10,
  '25457': 10,
  '25458': 10,
  '25459': 10,
  '2545A': 10,
  '2545B': 10,
  '2545C': 10,
  '2545D': 10,
  '2545E': 10,
  '2545F': 10,
  '25460': 10,
  '25461': 10,
  '25462': 10,
  '25463': 10,
  '25464': 10,
  '25465': 10,
  '25466': 10,
  '25467': 10,
  '25468': 10,
  '25469': 10,
  '2546A': 10,
  '2546B': 10,
  '2546C': 10,
  '2546D': 10,
  '2546E': 10,
  '2546F': 10,
  '25470': 10,
  '25471': 10,
  '25472': 10,
  '25473': 11,
  '25474': 11,
  '25475': 11,
  '25476': 11,
  '25477': 11,
  '25478': 11,
  '25479': 11,
  '2547A': 11,
  '2547B': 11,
  '2547C': 11,
  '2547D': 11,
  '2547E': 11,
  '2547F': 11,
  '25480': 11,
  '25481': 11,
  '25482': 11,
  '25483': 11,
  '25484': 11,
  '25485': 11,
  '25486': 11,
  '25487': 11,
  '25488': 11,
  '25489': 11,
  '2548A': 11,
  '2548B': 11,
  '2548C': 11,
  '2548D': 11,
  '2548E': 11,
  '2548F': 11,
  '25490': 11,
  '25491': 11,
  '25492': 11,
  '25493': 11,
  '25494': 11,
  '25495': 11,
  '25496': 11,
  '25497': 11,
  '25498': 11,
  '25499': 11,
  '2549A': 11,
  '2549B': 11,
  '2549C': 11,
  '2549D': 11,
  '2549E': 11,
  '2549F': 11,
  '254A0': 11,
  '254A1': 11,
  '254A2': 11,
  '254A3': 11,
  '254A4': 11,
  '254A5': 11,
  '254A6': 11,
  '254A7': 11,
  '254A8': 11,
  '254A9': 11,
  '254AA': 12,
  '254AB': 12,
  '254AC': 12,
  '254AD': 12,
  '254AE': 12,
  '254AF': 12,
  '254B0': 12,
  '254B1': 12,
  '254B2': 12,
  '254B3': 12,
  '254B4': 12,
  '254B5': 12,
  '254B6': 12,
  '254B7': 12,
  '254B8': 12,
  '254B9': 12,
  '254BA': 12,
  '254BB': 12,
  '254BC': 12,
  '254BD': 12,
  '254BE': 12,
  '254BF': 12,
  '254C0': 12,
  '254C1': 12,
  '254C2': 12,
  '254C3': 12,
  '254C4': 12,
  '254C5': 12,
  '254C6': 12,
  '254C7': 12,
  '254C8': 12,
  '254C9': 12,
  '254CA': 13,
  '254CB': 13,
  '254CC': 13,
  '254CD': 13,
  '254CE': 13,
  '254CF': 13,
  '254D0': 13,
  '254D1': 13,
  '254D2': 13,
  '254D3': 13,
  '254D4': 13,
  '254D5': 13,
  '254D6': 13,
  '254D7': 13,
  '254D8': 13,
  '254D9': 13,
  '254DA': 13,
  '254DB': 13,
  '254DC': 13,
  '254DD': 13,
  '254DE': 13,
  '254DF': 13,
  '254E0': 13,
  '254E1': 13,
  '254E2': 13,
  '254E3': 13,
  '254E4': 13,
  '254E5': 13,
  '254E6': 13,
  '254E7': 13,
  '254E8': 13,
  '254E9': 13,
  '254EA': 13,
  '254EB': 13,
  '254EC': 13,
  '254ED': 13,
  '254EE': 13,
  '254EF': 13,
  '254F0': 13,
  '254F1': 13,
  '254F2': 13,
  '254F3': 13,
  '254F4': 13,
  '254F5': 13,
  '254F6': 13,
  '254F7': 13,
  '254F8': 13,
  '254F9': 13,
  '254FA': 14,
  '254FB': 14,
  '254FC': 14,
  '254FD': 14,
  '254FE': 14,
  '254FF': 14,
  '25500': 14,
  '25501': 14,
  '25502': 14,
  '25503': 14,
  '25504': 14,
  '25505': 14,
  '25506': 14,
  '25507': 14,
  '25508': 14,
  '25509': 14,
  '2550A': 14,
  '2550B': 14,
  '2550C': 14,
  '2550D': 14,
  '2550E': 14,
  '2550F': 14,
  '25510': 14,
  '25511': 14,
  '25512': 14,
  '25513': 14,
  '25514': 14,
  '25515': 14,
  '25516': 14,
  '25517': 14,
  '25518': 14,
  '25519': 14,
  '2551A': 14,
  '2551B': 14,
  '2551C': 14,
  '2551D': 14,
  '2551E': 14,
  '2551F': 14,
  '25520': 14,
  '25521': 14,
  '25522': 14,
  '25523': 14,
  '25524': 14,
  '25525': 14,
  '25526': 14,
  '25527': 14,
  '25528': 14,
  '25529': 14,
  '2552A': 14,
  '2552B': 14,
  '2552C': 14,
  '2552D': 15,
  '2552E': 15,
  '2552F': 15,
  '25530': 15,
  '25531': 15,
  '25532': 15,
  '25533': 15,
  '25534': 15,
  '25535': 15,
  '25536': 15,
  '25537': 15,
  '25538': 15,
  '25539': 15,
  '2553A': 15,
  '2553B': 15,
  '2553C': 15,
  '2553D': 15,
  '2553E': 15,
  '2553F': 15,
  '25540': 15,
  '25541': 15,
  '25542': 15,
  '25543': 15,
  '25544': 15,
  '25545': 15,
  '25546': 15,
  '25547': 15,
  '25548': 15,
  '25549': 15,
  '2554A': 15,
  '2554B': 15,
  '2554C': 16,
  '2554D': 16,
  '2554E': 16,
  '2554F': 16,
  '25550': 16,
  '25551': 16,
  '25552': 16,
  '25553': 16,
  '25554': 16,
  '25555': 16,
  '25556': 16,
  '25557': 16,
  '25558': 16,
  '25559': 16,
  '2555A': 16,
  '2555B': 16,
  '2555C': 16,
  '2555D': 16,
  '2555E': 16,
  '2555F': 16,
  '25560': 16,
  '25561': 16,
  '25562': 16,
  '25563': 16,
  '25564': 16,
  '25565': 16,
  '25566': 16,
  '25567': 16,
  '25568': 16,
  '25569': 16,
  '2556A': 16,
  '2556B': 16,
  '2556C': 16,
  '2556D': 16,
  '2556E': 16,
  '2556F': 16,
  '25570': 17,
  '25571': 17,
  '25572': 17,
  '25573': 17,
  '25574': 17,
  '25575': 17,
  '25576': 17,
  '25577': 17,
  '25578': 17,
  '25579': 17,
  '2557A': 17,
  '2557B': 17,
  '2557C': 17,
  '2557D': 17,
  '2557E': 17,
  '2557F': 17,
  '25580': 17,
  '25581': 17,
  '25582': 17,
  '25583': 17,
  '25584': 17,
  '25585': 17,
  '25586': 17,
  '25587': 17,
  '25588': 17,
  '25589': 17,
  '2558A': 17,
  '2558B': 17,
  '2558C': 17,
  '2558D': 17,
  '2558E': 17,
  '2558F': 17,
  '25590': 17,
  '25591': 17,
  '25592': 17,
  '25593': 17,
  '25594': 17,
  '25595': 17,
  '25596': 17,
  '25597': 18,
  '25598': 18,
  '25599': 18,
  '2559A': 18,
  '2559B': 18,
  '2559C': 18,
  '2559D': 18,
  '2559E': 18,
  '2559F': 18,
  '255A0': 18,
  '255A1': 18,
  '255A2': 18,
  '255A3': 18,
  '255A4': 18,
  '255A5': 18,
  '255A6': 18,
  '255A7': 18,
  '255A8': 18,
  '255A9': 18,
  '255AA': 19,
  '255AB': 19,
  '255AC': 19,
  '255AD': 19,
  '255AE': 19,
  '255AF': 19,
  '255B0': 19,
  '255B1': 19,
  '255B2': 19,
  '255B3': 19,
  '255B4': 19,
  '255B5': 19,
  '255B6': 19,
  '255B7': 19,
  '255B8': 19,
  '255B9': 19,
  '255BA': 19,
  '255BB': 20,
  '255BC': 20,
  '255BD': 20,
  '255BE': 20,
  '255BF': 20,
  '255C0': 20,
  '255C1': 20,
  '255C2': 20,
  '255C3': 20,
  '255C4': 20,
  '255C5': 20,
  '255C6': 20,
  '255C7': 20,
  '255C8': 20,
  '255C9': 20,
  '255CA': 20,
  '255CB': 20,
  '255CC': 20,
  '255CD': 20,
  '255CE': 20,
  '255CF': 20,
  '255D0': 20,
  '255D1': 20,
  '255D2': 21,
  '255D3': 21,
  '255D4': 21,
  '255D5': 21,
  '255D6': 21,
  '255D7': 21,
  '255D8': 21,
  '255D9': 21,
  '255DA': 21,
  '255DB': 21,
  '255DC': 21,
  '255DD': 22,
  '255DE': 22,
  '255DF': 22,
  '255E0': 22,
  '255E1': 22,
  '255E2': 22,
  '255E3': 22,
  '255E4': 22,
  '255E5': 22,
  '255E6': 22,
  '255E7': 22,
  '255E8': 22,
  '255E9': 22,
  '255EA': 22,
  '255EB': 23,
  '255EC': 23,
  '255ED': 23,
  '255EE': 23,
  '255EF': 23,
  '255F0': 23,
  '255F1': 23,
  '255F2': 23,
  '255F3': 23,
  '255F4': 24,
  '255F5': 24,
  '255F6': 24,
  '255F7': 24,
  '255F8': 24,
  '255F9': 25,
  '255FA': 26,
  '255FB': 26,
  '255FC': 26,
  '255FD': 26,
  '255FE': 27,
  '255FF': 27,
  '25600': 27,
  '25601': 28,
  '25602': 28,
  '25603': 29,
  '25604': 34,
  '25605': 4,
  '25606': 5,
  '25607': 6,
  '25608': 6,
  '25609': 6,
  '2560A': 6,
  '2560B': 6,
  '2560C': 6,
  '2560D': 7,
  '2560E': 7,
  '2560F': 7,
  '25610': 7,
  '25611': 7,
  '25612': 7,
  '25613': 7,
  '25614': 7,
  '25615': 8,
  '25616': 8,
  '25617': 8,
  '25618': 8,
  '25619': 8,
  '2561A': 8,
  '2561B': 8,
  '2561C': 8,
  '2561D': 8,
  '2561E': 8,
  '2561F': 8,
  '25620': 8,
  '25621': 8,
  '25622': 8,
  '25623': 8,
  '25624': 8,
  '25625': 8,
  '25626': 8,
  '25627': 8,
  '25628': 8,
  '25629': 8,
  '2562A': 8,
  '2562B': 9,
  '2562C': 9,
  '2562D': 9,
  '2562E': 9,
  '2562F': 9,
  '25630': 9,
  '25631': 9,
  '25632': 9,
  '25633': 9,
  '25634': 9,
  '25635': 9,
  '25636': 9,
  '25637': 9,
  '25638': 9,
  '25639': 9,
  '2563A': 9,
  '2563B': 9,
  '2563C': 9,
  '2563D': 9,
  '2563E': 9,
  '2563F': 9,
  '25640': 9,
  '25641': 9,
  '25642': 9,
  '25643': 9,
  '25644': 9,
  '25645': 9,
  '25646': 9,
  '25647': 9,
  '25648': 9,
  '25649': 9,
  '2564A': 9,
  '2564B': 9,
  '2564C': 9,
  '2564D': 9,
  '2564E': 10,
  '2564F': 10,
  '25650': 10,
  '25651': 10,
  '25652': 10,
  '25653': 10,
  '25654': 10,
  '25655': 10,
  '25656': 10,
  '25657': 10,
  '25658': 10,
  '25659': 10,
  '2565A': 10,
  '2565B': 10,
  '2565C': 10,
  '2565D': 10,
  '2565E': 10,
  '2565F': 10,
  '25660': 10,
  '25661': 10,
  '25662': 10,
  '25663': 10,
  '25664': 10,
  '25665': 10,
  '25666': 10,
  '25667': 10,
  '25668': 10,
  '25669': 10,
  '2566A': 10,
  '2566B': 11,
  '2566C': 11,
  '2566D': 11,
  '2566E': 11,
  '2566F': 11,
  '25670': 11,
  '25671': 11,
  '25672': 11,
  '25673': 11,
  '25674': 11,
  '25675': 11,
  '25676': 11,
  '25677': 11,
  '25678': 11,
  '25679': 11,
  '2567A': 11,
  '2567B': 11,
  '2567C': 11,
  '2567D': 11,
  '2567E': 11,
  '2567F': 11,
  '25680': 11,
  '25681': 11,
  '25682': 11,
  '25683': 11,
  '25684': 11,
  '25685': 11,
  '25686': 11,
  '25687': 11,
  '25688': 12,
  '25689': 12,
  '2568A': 12,
  '2568B': 12,
  '2568C': 12,
  '2568D': 12,
  '2568E': 12,
  '2568F': 12,
  '25690': 12,
  '25691': 12,
  '25692': 12,
  '25693': 12,
  '25694': 12,
  '25695': 12,
  '25696': 12,
  '25697': 12,
  '25698': 12,
  '25699': 12,
  '2569A': 12,
  '2569B': 12,
  '2569C': 12,
  '2569D': 12,
  '2569E': 12,
  '2569F': 12,
  '256A0': 12,
  '256A1': 12,
  '256A2': 12,
  '256A3': 12,
  '256A4': 12,
  '256A5': 12,
  '256A6': 13,
  '256A7': 13,
  '256A8': 13,
  '256A9': 13,
  '256AA': 13,
  '256AB': 13,
  '256AC': 13,
  '256AD': 13,
  '256AE': 13,
  '256AF': 13,
  '256B0': 13,
  '256B1': 13,
  '256B2': 13,
  '256B3': 13,
  '256B4': 13,
  '256B5': 13,
  '256B6': 13,
  '256B7': 13,
  '256B8': 13,
  '256B9': 13,
  '256BA': 13,
  '256BB': 13,
  '256BC': 13,
  '256BD': 13,
  '256BE': 13,
  '256BF': 13,
  '256C0': 13,
  '256C1': 13,
  '256C2': 13,
  '256C3': 13,
  '256C4': 13,
  '256C5': 14,
  '256C6': 14,
  '256C7': 14,
  '256C8': 14,
  '256C9': 14,
  '256CA': 14,
  '256CB': 14,
  '256CC': 14,
  '256CD': 14,
  '256CE': 14,
  '256CF': 14,
  '256D0': 14,
  '256D1': 14,
  '256D2': 14,
  '256D3': 14,
  '256D4': 14,
  '256D5': 14,
  '256D6': 14,
  '256D7': 14,
  '256D8': 15,
  '256D9': 15,
  '256DA': 15,
  '256DB': 15,
  '256DC': 15,
  '256DD': 15,
  '256DE': 15,
  '256DF': 15,
  '256E0': 15,
  '256E1': 15,
  '256E2': 15,
  '256E3': 15,
  '256E4': 15,
  '256E5': 15,
  '256E6': 15,
  '256E7': 15,
  '256E8': 15,
  '256E9': 15,
  '256EA': 15,
  '256EB': 15,
  '256EC': 15,
  '256ED': 15,
  '256EE': 16,
  '256EF': 16,
  '256F0': 16,
  '256F1': 16,
  '256F2': 16,
  '256F3': 16,
  '256F4': 16,
  '256F5': 16,
  '256F6': 16,
  '256F7': 16,
  '256F8': 16,
  '256F9': 16,
  '256FA': 16,
  '256FB': 16,
  '256FC': 16,
  '256FD': 16,
  '256FE': 16,
  '256FF': 16,
  '25700': 16,
  '25701': 16,
  '25702': 16,
  '25703': 17,
  '25704': 17,
  '25705': 17,
  '25706': 17,
  '25707': 17,
  '25708': 17,
  '25709': 17,
  '2570A': 17,
  '2570B': 17,
  '2570C': 17,
  '2570D': 17,
  '2570E': 17,
  '2570F': 17,
  '25710': 17,
  '25711': 17,
  '25712': 18,
  '25713': 18,
  '25714': 18,
  '25715': 18,
  '25716': 18,
  '25717': 18,
  '25718': 18,
  '25719': 18,
  '2571A': 19,
  '2571B': 19,
  '2571C': 19,
  '2571D': 19,
  '2571E': 19,
  '2571F': 19,
  '25720': 20,
  '25721': 20,
  '25722': 20,
  '25723': 20,
  '25724': 20,
  '25725': 21,
  '25726': 21,
  '25727': 21,
  '25728': 21,
  '25729': 21,
  '2572A': 22,
  '2572B': 22,
  '2572C': 22,
  '2572D': 22,
  '2572E': 22,
  '2572F': 22,
  '25730': 23,
  '25731': 23,
  '25732': 23,
  '25733': 23,
  '25734': 24,
  '25735': 24,
  '25736': 25,
  '25737': 25,
  '25738': 25,
  '25739': 26,
  '2573A': 26,
  '2573B': 5,
  '2573C': 8,
  '2573D': 10,
  '2573E': 11,
  '2573F': 12,
  '25740': 12,
  '25741': 12,
  '25742': 12,
  '25743': 13,
  '25744': 14,
  '25745': 15,
  '25746': 15,
  '25747': 15,
  '25748': 17,
  '25749': 17,
  '2574A': 21,
  '2574B': 24,
  '2574C': 5,
  '2574D': 6,
  '2574E': 6,
  '2574F': 6,
  '25750': 7,
  '25751': 7,
  '25752': 7,
  '25753': 7,
  '25754': 8,
  '25755': 8,
  '25756': 8,
  '25757': 8,
  '25758': 8,
  '25759': 8,
  '2575A': 8,
  '2575B': 8,
  '2575C': 8,
  '2575D': 8,
  '2575E': 8,
  '2575F': 8,
  '25760': 8,
  '25761': 8,
  '25762': 8,
  '25763': 9,
  '25764': 9,
  '25765': 9,
  '25766': 9,
  '25767': 9,
  '25768': 9,
  '25769': 9,
  '2576A': 9,
  '2576B': 9,
  '2576C': 9,
  '2576D': 9,
  '2576E': 9,
  '2576F': 9,
  '25770': 9,
  '25771': 9,
  '25772': 9,
  '25773': 9,
  '25774': 9,
  '25775': 9,
  '25776': 9,
  '25777': 9,
  '25778': 9,
  '25779': 9,
  '2577A': 9,
  '2577B': 9,
  '2577C': 9,
  '2577D': 9,
  '2577E': 10,
  '2577F': 10,
  '25780': 10,
  '25781': 10,
  '25782': 10,
  '25783': 10,
  '25784': 10,
  '25785': 10,
  '25786': 10,
  '25787': 10,
  '25788': 10,
  '25789': 10,
  '2578A': 10,
  '2578B': 10,
  '2578C': 10,
  '2578D': 10,
  '2578E': 10,
  '2578F': 10,
  '25790': 10,
  '25791': 10,
  '25792': 10,
  '25793': 10,
  '25794': 10,
  '25795': 10,
  '25796': 10,
  '25797': 10,
  '25798': 11,
  '25799': 11,
  '2579A': 11,
  '2579B': 11,
  '2579C': 11,
  '2579D': 11,
  '2579E': 11,
  '2579F': 11,
  '257A0': 11,
  '257A1': 11,
  '257A2': 11,
  '257A3': 11,
  '257A4': 11,
  '257A5': 11,
  '257A6': 11,
  '257A7': 11,
  '257A8': 11,
  '257A9': 11,
  '257AA': 11,
  '257AB': 11,
  '257AC': 11,
  '257AD': 11,
  '257AE': 11,
  '257AF': 11,
  '257B0': 11,
  '257B1': 11,
  '257B2': 12,
  '257B3': 12,
  '257B4': 12,
  '257B5': 12,
  '257B6': 12,
  '257B7': 12,
  '257B8': 12,
  '257B9': 12,
  '257BA': 12,
  '257BB': 12,
  '257BC': 12,
  '257BD': 12,
  '257BE': 12,
  '257BF': 12,
  '257C0': 12,
  '257C1': 12,
  '257C2': 12,
  '257C3': 12,
  '257C4': 12,
  '257C5': 11,
  '257C6': 12,
  '257C7': 12,
  '257C8': 12,
  '257C9': 12,
  '257CA': 12,
  '257CB': 12,
  '257CC': 12,
  '257CD': 13,
  '257CE': 13,
  '257CF': 13,
  '257D0': 13,
  '257D1': 13,
  '257D2': 13,
  '257D3': 13,
  '257D4': 13,
  '257D5': 13,
  '257D6': 13,
  '257D7': 13,
  '257D8': 13,
  '257D9': 13,
  '257DA': 13,
  '257DB': 13,
  '257DC': 13,
  '257DD': 13,
  '257DE': 13,
  '257DF': 13,
  '257E0': 13,
  '257E1': 13,
  '257E2': 13,
  '257E3': 13,
  '257E4': 13,
  '257E5': 13,
  '257E6': 13,
  '257E7': 13,
  '257E8': 13,
  '257E9': 13,
  '257EA': 13,
  '257EB': 13,
  '257EC': 13,
  '257ED': 13,
  '257EE': 13,
  '257EF': 13,
  '257F0': 13,
  '257F1': 13,
  '257F2': 13,
  '257F3': 13,
  '257F4': 13,
  '257F5': 13,
  '257F6': 13,
  '257F7': 13,
  '257F8': 13,
  '257F9': 13,
  '257FA': 13,
  '257FB': 13,
  '257FC': 13,
  '257FD': 14,
  '257FE': 14,
  '257FF': 14,
  '25800': 14,
  '25801': 14,
  '25802': 14,
  '25803': 14,
  '25804': 14,
  '25805': 14,
  '25806': 14,
  '25807': 14,
  '25808': 14,
  '25809': 14,
  '2580A': 14,
  '2580B': 14,
  '2580C': 14,
  '2580D': 14,
  '2580E': 14,
  '2580F': 14,
  '25810': 14,
  '25811': 14,
  '25812': 14,
  '25813': 14,
  '25814': 14,
  '25815': 14,
  '25816': 14,
  '25817': 14,
  '25818': 14,
  '25819': 14,
  '2581A': 14,
  '2581B': 14,
  '2581C': 14,
  '2581D': 14,
  '2581E': 14,
  '2581F': 14,
  '25820': 14,
  '25821': 14,
  '25822': 14,
  '25823': 14,
  '25824': 14,
  '25825': 14,
  '25826': 14,
  '25827': 14,
  '25828': 14,
  '25829': 14,
  '2582A': 14,
  '2582B': 14,
  '2582C': 14,
  '2582D': 14,
  '2582E': 14,
  '2582F': 14,
  '25830': 14,
  '25831': 15,
  '25832': 15,
  '25833': 15,
  '25834': 15,
  '25835': 15,
  '25836': 15,
  '25837': 15,
  '25838': 15,
  '25839': 15,
  '2583A': 15,
  '2583B': 15,
  '2583C': 15,
  '2583D': 15,
  '2583E': 15,
  '2583F': 15,
  '25840': 15,
  '25841': 15,
  '25842': 15,
  '25843': 15,
  '25844': 15,
  '25845': 15,
  '25846': 15,
  '25847': 15,
  '25848': 15,
  '25849': 15,
  '2584A': 15,
  '2584B': 15,
  '2584C': 15,
  '2584D': 15,
  '2584E': 15,
  '2584F': 15,
  '25850': 15,
  '25851': 15,
  '25852': 15,
  '25853': 15,
  '25854': 15,
  '25855': 15,
  '25856': 15,
  '25857': 15,
  '25858': 15,
  '25859': 15,
  '2585A': 15,
  '2585B': 15,
  '2585C': 16,
  '2585D': 16,
  '2585E': 16,
  '2585F': 16,
  '25860': 16,
  '25861': 16,
  '25862': 16,
  '25863': 16,
  '25864': 16,
  '25865': 16,
  '25866': 16,
  '25867': 16,
  '25868': 16,
  '25869': 16,
  '2586A': 16,
  '2586B': 16,
  '2586C': 16,
  '2586D': 16,
  '2586E': 16,
  '2586F': 16,
  '25870': 16,
  '25871': 16,
  '25872': 16,
  '25873': 16,
  '25874': 16,
  '25875': 16,
  '25876': 16,
  '25877': 16,
  '25878': 16,
  '25879': 16,
  '2587A': 16,
  '2587B': 16,
  '2587C': 16,
  '2587D': 16,
  '2587E': 16,
  '2587F': 16,
  '25880': 16,
  '25881': 16,
  '25882': 16,
  '25883': 16,
  '25884': 16,
  '25885': 16,
  '25886': 16,
  '25887': 16,
  '25888': 16,
  '25889': 16,
  '2588A': 17,
  '2588B': 17,
  '2588C': 17,
  '2588D': 17,
  '2588E': 17,
  '2588F': 17,
  '25890': 17,
  '25891': 17,
  '25892': 17,
  '25893': 17,
  '25894': 17,
  '25895': 17,
  '25896': 17,
  '25897': 17,
  '25898': 17,
  '25899': 17,
  '2589A': 17,
  '2589B': 17,
  '2589C': 17,
  '2589D': 17,
  '2589E': 17,
  '2589F': 17,
  '258A0': 17,
  '258A1': 17,
  '258A2': 17,
  '258A3': 17,
  '258A4': 17,
  '258A5': 17,
  '258A6': 17,
  '258A7': 17,
  '258A8': 17,
  '258A9': 17,
  '258AA': 17,
  '258AB': 17,
  '258AC': 17,
  '258AD': 17,
  '258AE': 17,
  '258AF': 17,
  '258B0': 17,
  '258B1': 17,
  '258B2': 17,
  '258B3': 17,
  '258B4': 18,
  '258B5': 18,
  '258B6': 18,
  '258B7': 18,
  '258B8': 18,
  '258B9': 18,
  '258BA': 18,
  '258BB': 18,
  '258BC': 18,
  '258BD': 18,
  '258BE': 18,
  '258BF': 18,
  '258C0': 18,
  '258C1': 18,
  '258C2': 18,
  '258C3': 18,
  '258C4': 18,
  '258C5': 18,
  '258C6': 18,
  '258C7': 18,
  '258C8': 18,
  '258C9': 18,
  '258CA': 19,
  '258CB': 18,
  '258CC': 18,
  '258CD': 18,
  '258CE': 18,
  '258CF': 18,
  '258D0': 18,
  '258D1': 18,
  '258D2': 18,
  '258D3': 18,
  '258D4': 18,
  '258D5': 19,
  '258D6': 19,
  '258D7': 19,
  '258D8': 19,
  '258D9': 19,
  '258DA': 19,
  '258DB': 19,
  '258DC': 19,
  '258DD': 19,
  '258DE': 19,
  '258DF': 19,
  '258E0': 19,
  '258E1': 19,
  '258E2': 20,
  '258E3': 19,
  '258E4': 19,
  '258E5': 19,
  '258E6': 19,
  '258E7': 19,
  '258E8': 20,
  '258E9': 20,
  '258EA': 20,
  '258EB': 20,
  '258EC': 20,
  '258ED': 20,
  '258EE': 20,
  '258EF': 20,
  '258F0': 20,
  '258F1': 20,
  '258F2': 20,
  '258F3': 20,
  '258F4': 20,
  '258F5': 20,
  '258F6': 20,
  '258F7': 20,
  '258F8': 20,
  '258F9': 20,
  '258FA': 20,
  '258FB': 20,
  '258FC': 20,
  '258FD': 20,
  '258FE': 20,
  '258FF': 20,
  '25900': 21,
  '25901': 21,
  '25902': 21,
  '25903': 21,
  '25904': 21,
  '25905': 21,
  '25906': 22,
  '25907': 22,
  '25908': 22,
  '25909': 22,
  '2590A': 23,
  '2590B': 23,
  '2590C': 23,
  '2590D': 23,
  '2590E': 23,
  '2590F': 23,
  '25910': 23,
  '25911': 23,
  '25912': 23,
  '25913': 24,
  '25914': 24,
  '25915': 24,
  '25916': 24,
  '25917': 25,
  '25918': 25,
  '25919': 25,
  '2591A': 25,
  '2591B': 26,
  '2591C': 26,
  '2591D': 26,
  '2591E': 29,
  '2591F': 29,
  '25920': 32,
  '25921': 35,
  '25922': 5,
  '25923': 7,
  '25924': 7,
  '25925': 7,
  '25926': 7,
  '25927': 8,
  '25928': 8,
  '25929': 8,
  '2592A': 8,
  '2592B': 8,
  '2592C': 8,
  '2592D': 8,
  '2592E': 8,
  '2592F': 8,
  '25930': 8,
  '25931': 8,
  '25932': 8,
  '25933': 8,
  '25934': 9,
  '25935': 9,
  '25936': 9,
  '25937': 9,
  '25938': 9,
  '25939': 9,
  '2593A': 9,
  '2593B': 9,
  '2593C': 9,
  '2593D': 9,
  '2593E': 9,
  '2593F': 9,
  '25940': 9,
  '25941': 9,
  '25942': 9,
  '25943': 9,
  '25944': 9,
  '25945': 9,
  '25946': 9,
  '25947': 10,
  '25948': 10,
  '25949': 10,
  '2594A': 10,
  '2594B': 10,
  '2594C': 10,
  '2594D': 10,
  '2594E': 10,
  '2594F': 10,
  '25950': 10,
  '25951': 10,
  '25952': 10,
  '25953': 10,
  '25954': 10,
  '25955': 10,
  '25956': 10,
  '25957': 10,
  '25958': 10,
  '25959': 10,
  '2595A': 10,
  '2595B': 10,
  '2595C': 10,
  '2595D': 10,
  '2595E': 10,
  '2595F': 10,
  '25960': 11,
  '25961': 11,
  '25962': 11,
  '25963': 11,
  '25964': 11,
  '25965': 11,
  '25966': 11,
  '25967': 11,
  '25968': 11,
  '25969': 11,
  '2596A': 11,
  '2596B': 11,
  '2596C': 11,
  '2596D': 11,
  '2596E': 11,
  '2596F': 11,
  '25970': 11,
  '25971': 11,
  '25972': 11,
  '25973': 11,
  '25974': 11,
  '25975': 11,
  '25976': 12,
  '25977': 12,
  '25978': 12,
  '25979': 12,
  '2597A': 12,
  '2597B': 12,
  '2597C': 12,
  '2597D': 12,
  '2597E': 12,
  '2597F': 12,
  '25980': 12,
  '25981': 12,
  '25982': 12,
  '25983': 12,
  '25984': 12,
  '25985': 12,
  '25986': 12,
  '25987': 12,
  '25988': 12,
  '25989': 12,
  '2598A': 12,
  '2598B': 12,
  '2598C': 12,
  '2598D': 12,
  '2598E': 12,
  '2598F': 12,
  '25990': 12,
  '25991': 12,
  '25992': 12,
  '25993': 12,
  '25994': 12,
  '25995': 12,
  '25996': 12,
  '25997': 12,
  '25998': 12,
  '25999': 12,
  '2599A': 12,
  '2599B': 13,
  '2599C': 13,
  '2599D': 13,
  '2599E': 13,
  '2599F': 13,
  '259A0': 13,
  '259A1': 13,
  '259A2': 13,
  '259A3': 13,
  '259A4': 13,
  '259A5': 13,
  '259A6': 13,
  '259A7': 13,
  '259A8': 13,
  '259A9': 13,
  '259AA': 13,
  '259AB': 13,
  '259AC': 13,
  '259AD': 13,
  '259AE': 13,
  '259AF': 13,
  '259B0': 13,
  '259B1': 13,
  '259B2': 13,
  '259B3': 13,
  '259B4': 13,
  '259B5': 13,
  '259B6': 13,
  '259B7': 13,
  '259B8': 14,
  '259B9': 14,
  '259BA': 14,
  '259BB': 14,
  '259BC': 14,
  '259BD': 14,
  '259BE': 14,
  '259BF': 14,
  '259C0': 14,
  '259C1': 14,
  '259C2': 14,
  '259C3': 14,
  '259C4': 14,
  '259C5': 14,
  '259C6': 14,
  '259C7': 14,
  '259C8': 14,
  '259C9': 14,
  '259CA': 14,
  '259CB': 14,
  '259CC': 14,
  '259CD': 14,
  '259CE': 14,
  '259CF': 14,
  '259D0': 15,
  '259D1': 15,
  '259D2': 15,
  '259D3': 15,
  '259D4': 15,
  '259D5': 15,
  '259D6': 15,
  '259D7': 15,
  '259D8': 15,
  '259D9': 15,
  '259DA': 15,
  '259DB': 15,
  '259DC': 15,
  '259DD': 15,
  '259DE': 15,
  '259DF': 15,
  '259E0': 15,
  '259E1': 15,
  '259E2': 15,
  '259E3': 15,
  '259E4': 15,
  '259E5': 15,
  '259E6': 15,
  '259E7': 15,
  '259E8': 15,
  '259E9': 15,
  '259EA': 15,
  '259EB': 15,
  '259EC': 16,
  '259ED': 16,
  '259EE': 16,
  '259EF': 16,
  '259F0': 16,
  '259F1': 16,
  '259F2': 16,
  '259F3': 16,
  '259F4': 16,
  '259F5': 16,
  '259F6': 16,
  '259F7': 16,
  '259F8': 16,
  '259F9': 16,
  '259FA': 16,
  '259FB': 16,
  '259FC': 17,
  '259FD': 16,
  '259FE': 16,
  '259FF': 16,
  '25A00': 16,
  '25A01': 16,
  '25A02': 16,
  '25A03': 16,
  '25A04': 16,
  '25A05': 16,
  '25A06': 16,
  '25A07': 16,
  '25A08': 16,
  '25A09': 16,
  '25A0A': 16,
  '25A0B': 16,
  '25A0C': 17,
  '25A0D': 17,
  '25A0E': 17,
  '25A0F': 17,
  '25A10': 17,
  '25A11': 17,
  '25A12': 17,
  '25A13': 17,
  '25A14': 17,
  '25A15': 17,
  '25A16': 17,
  '25A17': 17,
  '25A18': 17,
  '25A19': 17,
  '25A1A': 17,
  '25A1B': 17,
  '25A1C': 17,
  '25A1D': 17,
  '25A1E': 17,
  '25A1F': 17,
  '25A20': 18,
  '25A21': 18,
  '25A22': 18,
  '25A23': 18,
  '25A24': 18,
  '25A25': 18,
  '25A26': 18,
  '25A27': 18,
  '25A28': 18,
  '25A29': 17,
  '25A2A': 19,
  '25A2B': 19,
  '25A2C': 19,
  '25A2D': 19,
  '25A2E': 19,
  '25A2F': 19,
  '25A30': 20,
  '25A31': 20,
  '25A32': 19,
  '25A33': 20,
  '25A34': 20,
  '25A35': 21,
  '25A36': 20,
  '25A37': 20,
  '25A38': 20,
  '25A39': 20,
  '25A3A': 20,
  '25A3B': 21,
  '25A3C': 21,
  '25A3D': 21,
  '25A3E': 21,
  '25A3F': 21,
  '25A40': 21,
  '25A41': 21,
  '25A42': 21,
  '25A43': 21,
  '25A44': 21,
  '25A45': 21,
  '25A46': 21,
  '25A47': 21,
  '25A48': 21,
  '25A49': 22,
  '25A4A': 23,
  '25A4B': 23,
  '25A4C': 24,
  '25A4D': 24,
  '25A4E': 25,
  '25A4F': 24,
  '25A50': 24,
  '25A51': 24,
  '25A52': 25,
  '25A53': 26,
  '25A54': 29,
  '25A55': 7,
  '25A56': 7,
  '25A57': 8,
  '25A58': 8,
  '25A59': 9,
  '25A5A': 9,
  '25A5B': 9,
  '25A5C': 9,
  '25A5D': 9,
  '25A5E': 9,
  '25A5F': 9,
  '25A60': 10,
  '25A61': 10,
  '25A62': 10,
  '25A63': 10,
  '25A64': 10,
  '25A65': 10,
  '25A66': 10,
  '25A67': 10,
  '25A68': 10,
  '25A69': 10,
  '25A6A': 10,
  '25A6B': 10,
  '25A6C': 10,
  '25A6D': 10,
  '25A6E': 10,
  '25A6F': 10,
  '25A70': 10,
  '25A71': 11,
  '25A72': 11,
  '25A73': 11,
  '25A74': 11,
  '25A75': 11,
  '25A76': 11,
  '25A77': 11,
  '25A78': 11,
  '25A79': 11,
  '25A7A': 11,
  '25A7B': 11,
  '25A7C': 11,
  '25A7D': 11,
  '25A7E': 12,
  '25A7F': 12,
  '25A80': 12,
  '25A81': 12,
  '25A82': 12,
  '25A83': 12,
  '25A84': 12,
  '25A85': 12,
  '25A86': 12,
  '25A87': 12,
  '25A88': 12,
  '25A89': 12,
  '25A8A': 13,
  '25A8B': 13,
  '25A8C': 13,
  '25A8D': 13,
  '25A8E': 13,
  '25A8F': 13,
  '25A90': 13,
  '25A91': 13,
  '25A92': 13,
  '25A93': 13,
  '25A94': 13,
  '25A95': 13,
  '25A96': 13,
  '25A97': 13,
  '25A98': 14,
  '25A99': 14,
  '25A9A': 14,
  '25A9B': 14,
  '25A9C': 14,
  '25A9D': 14,
  '25A9E': 14,
  '25A9F': 14,
  '25AA0': 14,
  '25AA1': 14,
  '25AA2': 14,
  '25AA3': 14,
  '25AA4': 14,
  '25AA5': 15,
  '25AA6': 15,
  '25AA7': 15,
  '25AA8': 15,
  '25AA9': 15,
  '25AAA': 15,
  '25AAB': 15,
  '25AAC': 15,
  '25AAD': 16,
  '25AAE': 16,
  '25AAF': 17,
  '25AB0': 16,
  '25AB1': 16,
  '25AB2': 17,
  '25AB3': 17,
  '25AB4': 17,
  '25AB5': 17,
  '25AB6': 17,
  '25AB7': 17,
  '25AB8': 17,
  '25AB9': 17,
  '25ABA': 19,
  '25ABB': 17,
  '25ABC': 18,
  '25ABD': 18,
  '25ABE': 18,
  '25ABF': 18,
  '25AC0': 18,
  '25AC1': 18,
  '25AC2': 18,
  '25AC3': 18,
  '25AC4': 18,
  '25AC5': 19,
  '25AC6': 19,
  '25AC7': 19,
  '25AC8': 20,
  '25AC9': 20,
  '25ACA': 20,
  '25ACB': 20,
  '25ACC': 20,
  '25ACD': 20,
  '25ACE': 21,
  '25ACF': 21,
  '25AD0': 22,
  '25AD1': 22,
  '25AD2': 25,
  '25AD3': 23,
  '25AD4': 24,
  '25AD5': 25,
  '25AD6': 25,
  '25AD7': 6,
  '25AD8': 7,
  '25AD9': 8,
  '25ADA': 8,
  '25ADB': 8,
  '25ADC': 8,
  '25ADD': 9,
  '25ADE': 9,
  '25ADF': 9,
  '25AE0': 9,
  '25AE1': 9,
  '25AE2': 9,
  '25AE3': 9,
  '25AE4': 9,
  '25AE5': 9,
  '25AE6': 9,
  '25AE7': 9,
  '25AE8': 9,
  '25AE9': 9,
  '25AEA': 9,
  '25AEB': 9,
  '25AEC': 9,
  '25AED': 9,
  '25AEE': 9,
  '25AEF': 10,
  '25AF0': 10,
  '25AF1': 10,
  '25AF2': 10,
  '25AF3': 10,
  '25AF4': 10,
  '25AF5': 10,
  '25AF6': 10,
  '25AF7': 10,
  '25AF8': 10,
  '25AF9': 10,
  '25AFA': 10,
  '25AFB': 10,
  '25AFC': 10,
  '25AFD': 10,
  '25AFE': 10,
  '25AFF': 10,
  '25B00': 10,
  '25B01': 10,
  '25B02': 10,
  '25B03': 10,
  '25B04': 10,
  '25B05': 10,
  '25B06': 10,
  '25B07': 10,
  '25B08': 10,
  '25B09': 10,
  '25B0A': 10,
  '25B0B': 10,
  '25B0C': 11,
  '25B0D': 11,
  '25B0E': 11,
  '25B0F': 11,
  '25B10': 11,
  '25B11': 11,
  '25B12': 11,
  '25B13': 11,
  '25B14': 11,
  '25B15': 11,
  '25B16': 11,
  '25B17': 11,
  '25B18': 11,
  '25B19': 11,
  '25B1A': 11,
  '25B1B': 11,
  '25B1C': 11,
  '25B1D': 11,
  '25B1E': 11,
  '25B1F': 11,
  '25B20': 11,
  '25B21': 11,
  '25B22': 11,
  '25B23': 11,
  '25B24': 11,
  '25B25': 11,
  '25B26': 11,
  '25B27': 11,
  '25B28': 11,
  '25B29': 11,
  '25B2A': 12,
  '25B2B': 12,
  '25B2C': 12,
  '25B2D': 12,
  '25B2E': 12,
  '25B2F': 12,
  '25B30': 12,
  '25B31': 12,
  '25B32': 12,
  '25B33': 12,
  '25B34': 12,
  '25B35': 12,
  '25B36': 12,
  '25B37': 12,
  '25B38': 12,
  '25B39': 12,
  '25B3A': 12,
  '25B3B': 12,
  '25B3C': 12,
  '25B3D': 12,
  '25B3E': 12,
  '25B3F': 11,
  '25B40': 12,
  '25B41': 12,
  '25B42': 12,
  '25B43': 12,
  '25B44': 12,
  '25B45': 12,
  '25B46': 12,
  '25B47': 12,
  '25B48': 12,
  '25B49': 12,
  '25B4A': 12,
  '25B4B': 12,
  '25B4C': 12,
  '25B4D': 12,
  '25B4E': 12,
  '25B4F': 12,
  '25B50': 13,
  '25B51': 13,
  '25B52': 13,
  '25B53': 13,
  '25B54': 13,
  '25B55': 13,
  '25B56': 13,
  '25B57': 13,
  '25B58': 13,
  '25B59': 13,
  '25B5A': 13,
  '25B5B': 13,
  '25B5C': 13,
  '25B5D': 13,
  '25B5E': 13,
  '25B5F': 13,
  '25B60': 13,
  '25B61': 13,
  '25B62': 13,
  '25B63': 13,
  '25B64': 13,
  '25B65': 13,
  '25B66': 13,
  '25B67': 13,
  '25B68': 13,
  '25B69': 13,
  '25B6A': 13,
  '25B6B': 13,
  '25B6C': 13,
  '25B6D': 13,
  '25B6E': 13,
  '25B6F': 13,
  '25B70': 13,
  '25B71': 13,
  '25B72': 13,
  '25B73': 13,
  '25B74': 13,
  '25B75': 13,
  '25B76': 13,
  '25B77': 13,
  '25B78': 13,
  '25B79': 13,
  '25B7A': 13,
  '25B7B': 13,
  '25B7C': 13,
  '25B7D': 13,
  '25B7E': 13,
  '25B7F': 13,
  '25B80': 13,
  '25B81': 13,
  '25B82': 13,
  '25B83': 13,
  '25B84': 13,
  '25B85': 13,
  '25B86': 13,
  '25B87': 13,
  '25B88': 13,
  '25B89': 13,
  '25B8A': 13,
  '25B8B': 13,
  '25B8C': 13,
  '25B8D': 14,
  '25B8E': 14,
  '25B8F': 14,
  '25B90': 14,
  '25B91': 14,
  '25B92': 14,
  '25B93': 14,
  '25B94': 14,
  '25B95': 14,
  '25B96': 14,
  '25B97': 14,
  '25B98': 14,
  '25B99': 14,
  '25B9A': 14,
  '25B9B': 14,
  '25B9C': 14,
  '25B9D': 14,
  '25B9E': 14,
  '25B9F': 14,
  '25BA0': 14,
  '25BA1': 14,
  '25BA2': 14,
  '25BA3': 14,
  '25BA4': 14,
  '25BA5': 14,
  '25BA6': 14,
  '25BA7': 14,
  '25BA8': 14,
  '25BA9': 14,
  '25BAA': 14,
  '25BAB': 14,
  '25BAC': 14,
  '25BAD': 14,
  '25BAE': 14,
  '25BAF': 14,
  '25BB0': 14,
  '25BB1': 14,
  '25BB2': 14,
  '25BB3': 14,
  '25BB4': 14,
  '25BB5': 14,
  '25BB6': 14,
  '25BB7': 14,
  '25BB8': 14,
  '25BB9': 14,
  '25BBA': 14,
  '25BBB': 14,
  '25BBC': 14,
  '25BBD': 14,
  '25BBE': 14,
  '25BBF': 14,
  '25BC0': 14,
  '25BC1': 14,
  '25BC2': 14,
  '25BC3': 14,
  '25BC4': 14,
  '25BC5': 14,
  '25BC6': 14,
  '25BC7': 14,
  '25BC8': 14,
  '25BC9': 14,
  '25BCA': 14,
  '25BCB': 14,
  '25BCC': 14,
  '25BCD': 14,
  '25BCE': 14,
  '25BCF': 14,
  '25BD0': 14,
  '25BD1': 15,
  '25BD2': 15,
  '25BD3': 15,
  '25BD4': 15,
  '25BD5': 15,
  '25BD6': 15,
  '25BD7': 15,
  '25BD8': 15,
  '25BD9': 15,
  '25BDA': 15,
  '25BDB': 15,
  '25BDC': 15,
  '25BDD': 15,
  '25BDE': 15,
  '25BDF': 15,
  '25BE0': 15,
  '25BE1': 15,
  '25BE2': 15,
  '25BE3': 15,
  '25BE4': 15,
  '25BE5': 15,
  '25BE6': 15,
  '25BE7': 15,
  '25BE8': 15,
  '25BE9': 15,
  '25BEA': 15,
  '25BEB': 15,
  '25BEC': 15,
  '25BED': 15,
  '25BEE': 15,
  '25BEF': 15,
  '25BF0': 15,
  '25BF1': 15,
  '25BF2': 15,
  '25BF3': 15,
  '25BF4': 15,
  '25BF5': 15,
  '25BF6': 15,
  '25BF7': 15,
  '25BF8': 15,
  '25BF9': 15,
  '25BFA': 15,
  '25BFB': 15,
  '25BFC': 15,
  '25BFD': 15,
  '25BFE': 15,
  '25BFF': 15,
  '25C00': 15,
  '25C01': 15,
  '25C02': 15,
  '25C03': 15,
  '25C04': 15,
  '25C05': 15,
  '25C06': 15,
  '25C07': 15,
  '25C08': 15,
  '25C09': 15,
  '25C0A': 15,
  '25C0B': 15,
  '25C0C': 15,
  '25C0D': 15,
  '25C0E': 15,
  '25C0F': 15,
  '25C10': 15,
  '25C11': 15,
  '25C12': 15,
  '25C13': 15,
  '25C14': 15,
  '25C15': 15,
  '25C16': 15,
  '25C17': 15,
  '25C18': 16,
  '25C19': 16,
  '25C1A': 16,
  '25C1B': 16,
  '25C1C': 16,
  '25C1D': 16,
  '25C1E': 16,
  '25C1F': 16,
  '25C20': 16,
  '25C21': 16,
  '25C22': 16,
  '25C23': 16,
  '25C24': 16,
  '25C25': 16,
  '25C26': 16,
  '25C27': 16,
  '25C28': 16,
  '25C29': 16,
  '25C2A': 16,
  '25C2B': 16,
  '25C2C': 16,
  '25C2D': 16,
  '25C2E': 16,
  '25C2F': 16,
  '25C30': 16,
  '25C31': 16,
  '25C32': 16,
  '25C33': 16,
  '25C34': 16,
  '25C35': 16,
  '25C36': 16,
  '25C37': 16,
  '25C38': 16,
  '25C39': 16,
  '25C3A': 16,
  '25C3B': 16,
  '25C3C': 16,
  '25C3D': 16,
  '25C3E': 16,
  '25C3F': 16,
  '25C40': 16,
  '25C41': 16,
  '25C42': 16,
  '25C43': 16,
  '25C44': 16,
  '25C45': 16,
  '25C46': 16,
  '25C47': 16,
  '25C48': 16,
  '25C49': 16,
  '25C4A': 16,
  '25C4B': 16,
  '25C4C': 16,
  '25C4D': 16,
  '25C4E': 16,
  '25C4F': 16,
  '25C50': 16,
  '25C51': 16,
  '25C52': 16,
  '25C53': 16,
  '25C54': 16,
  '25C55': 16,
  '25C56': 16,
  '25C57': 16,
  '25C58': 16,
  '25C59': 16,
  '25C5A': 16,
  '25C5B': 16,
  '25C5C': 16,
  '25C5D': 16,
  '25C5E': 16,
  '25C5F': 16,
  '25C60': 16,
  '25C61': 16,
  '25C62': 16,
  '25C63': 16,
  '25C64': 16,
  '25C65': 16,
  '25C66': 16,
  '25C67': 16,
  '25C68': 16,
  '25C69': 16,
  '25C6A': 16,
  '25C6B': 16,
  '25C6C': 16,
  '25C6D': 16,
  '25C6E': 16,
  '25C6F': 16,
  '25C70': 16,
  '25C71': 16,
  '25C72': 16,
  '25C73': 16,
  '25C74': 16,
  '25C75': 17,
  '25C76': 17,
  '25C77': 17,
  '25C78': 17,
  '25C79': 17,
  '25C7A': 17,
  '25C7B': 17,
  '25C7C': 17,
  '25C7D': 17,
  '25C7E': 17,
  '25C7F': 17,
  '25C80': 17,
  '25C81': 17,
  '25C82': 17,
  '25C83': 17,
  '25C84': 17,
  '25C85': 17,
  '25C86': 17,
  '25C87': 17,
  '25C88': 17,
  '25C89': 17,
  '25C8A': 17,
  '25C8B': 17,
  '25C8C': 17,
  '25C8D': 17,
  '25C8E': 17,
  '25C8F': 17,
  '25C90': 17,
  '25C91': 17,
  '25C92': 17,
  '25C93': 17,
  '25C94': 17,
  '25C95': 17,
  '25C96': 17,
  '25C97': 17,
  '25C98': 17,
  '25C99': 17,
  '25C9A': 17,
  '25C9B': 17,
  '25C9C': 17,
  '25C9D': 17,
  '25C9E': 17,
  '25C9F': 17,
  '25CA0': 17,
  '25CA1': 17,
  '25CA2': 17,
  '25CA3': 17,
  '25CA4': 17,
  '25CA5': 17,
  '25CA6': 17,
  '25CA7': 17,
  '25CA8': 17,
  '25CA9': 17,
  '25CAA': 17,
  '25CAB': 17,
  '25CAC': 17,
  '25CAD': 17,
  '25CAE': 17,
  '25CAF': 17,
  '25CB0': 17,
  '25CB1': 17,
  '25CB2': 17,
  '25CB3': 17,
  '25CB4': 17,
  '25CB5': 17,
  '25CB6': 17,
  '25CB7': 17,
  '25CB8': 17,
  '25CB9': 17,
  '25CBA': 17,
  '25CBB': 17,
  '25CBC': 17,
  '25CBD': 17,
  '25CBE': 17,
  '25CBF': 17,
  '25CC0': 17,
  '25CC1': 18,
  '25CC2': 17,
  '25CC3': 17,
  '25CC4': 17,
  '25CC5': 17,
  '25CC6': 18,
  '25CC7': 18,
  '25CC8': 18,
  '25CC9': 18,
  '25CCA': 18,
  '25CCB': 18,
  '25CCC': 18,
  '25CCD': 18,
  '25CCE': 18,
  '25CCF': 18,
  '25CD0': 18,
  '25CD1': 18,
  '25CD2': 18,
  '25CD3': 18,
  '25CD4': 18,
  '25CD5': 18,
  '25CD6': 18,
  '25CD7': 18,
  '25CD8': 18,
  '25CD9': 18,
  '25CDA': 18,
  '25CDB': 18,
  '25CDC': 18,
  '25CDD': 18,
  '25CDE': 18,
  '25CDF': 18,
  '25CE0': 18,
  '25CE1': 18,
  '25CE2': 18,
  '25CE3': 18,
  '25CE4': 18,
  '25CE5': 18,
  '25CE6': 18,
  '25CE7': 18,
  '25CE8': 18,
  '25CE9': 18,
  '25CEA': 18,
  '25CEB': 18,
  '25CEC': 18,
  '25CED': 18,
  '25CEE': 18,
  '25CEF': 18,
  '25CF0': 18,
  '25CF1': 18,
  '25CF2': 18,
  '25CF3': 18,
  '25CF4': 18,
  '25CF5': 18,
  '25CF6': 18,
  '25CF7': 18,
  '25CF8': 18,
  '25CF9': 18,
  '25CFA': 18,
  '25CFB': 18,
  '25CFC': 18,
  '25CFD': 18,
  '25CFE': 18,
  '25CFF': 18,
  '25D00': 18,
  '25D01': 18,
  '25D02': 18,
  '25D03': 18,
  '25D04': 18,
  '25D05': 18,
  '25D06': 18,
  '25D07': 18,
  '25D08': 18,
  '25D09': 18,
  '25D0A': 18,
  '25D0B': 18,
  '25D0C': 18,
  '25D0D': 18,
  '25D0E': 17,
  '25D0F': 18,
  '25D10': 18,
  '25D11': 18,
  '25D12': 18,
  '25D13': 18,
  '25D14': 18,
  '25D15': 18,
  '25D16': 18,
  '25D17': 18,
  '25D18': 18,
  '25D19': 18,
  '25D1A': 18,
  '25D1B': 18,
  '25D1C': 18,
  '25D1D': 18,
  '25D1E': 18,
  '25D1F': 18,
  '25D20': 18,
  '25D21': 19,
  '25D22': 19,
  '25D23': 19,
  '25D24': 19,
  '25D25': 19,
  '25D26': 19,
  '25D27': 19,
  '25D28': 19,
  '25D29': 19,
  '25D2A': 19,
  '25D2B': 19,
  '25D2C': 19,
  '25D2D': 19,
  '25D2E': 19,
  '25D2F': 19,
  '25D30': 19,
  '25D31': 19,
  '25D32': 19,
  '25D33': 19,
  '25D34': 19,
  '25D35': 19,
  '25D36': 19,
  '25D37': 19,
  '25D38': 19,
  '25D39': 19,
  '25D3A': 19,
  '25D3B': 19,
  '25D3C': 19,
  '25D3D': 19,
  '25D3E': 19,
  '25D3F': 19,
  '25D40': 19,
  '25D41': 19,
  '25D42': 19,
  '25D43': 19,
  '25D44': 19,
  '25D45': 19,
  '25D46': 19,
  '25D47': 19,
  '25D48': 19,
  '25D49': 19,
  '25D4A': 19,
  '25D4B': 19,
  '25D4C': 19,
  '25D4D': 19,
  '25D4E': 19,
  '25D4F': 19,
  '25D50': 19,
  '25D51': 19,
  '25D52': 19,
  '25D53': 19,
  '25D54': 19,
  '25D55': 19,
  '25D56': 19,
  '25D57': 19,
  '25D58': 19,
  '25D59': 19,
  '25D5A': 19,
  '25D5B': 19,
  '25D5C': 20,
  '25D5D': 20,
  '25D5E': 20,
  '25D5F': 20,
  '25D60': 20,
  '25D61': 20,
  '25D62': 20,
  '25D63': 20,
  '25D64': 20,
  '25D65': 20,
  '25D66': 20,
  '25D67': 20,
  '25D68': 20,
  '25D69': 20,
  '25D6A': 20,
  '25D6B': 20,
  '25D6C': 20,
  '25D6D': 20,
  '25D6E': 20,
  '25D6F': 20,
  '25D70': 20,
  '25D71': 20,
  '25D72': 20,
  '25D73': 20,
  '25D74': 20,
  '25D75': 20,
  '25D76': 20,
  '25D77': 20,
  '25D78': 20,
  '25D79': 20,
  '25D7A': 20,
  '25D7B': 20,
  '25D7C': 20,
  '25D7D': 20,
  '25D7E': 20,
  '25D7F': 20,
  '25D80': 20,
  '25D81': 20,
  '25D82': 20,
  '25D83': 20,
  '25D84': 20,
  '25D85': 21,
  '25D86': 21,
  '25D87': 21,
  '25D88': 21,
  '25D89': 21,
  '25D8A': 21,
  '25D8B': 21,
  '25D8C': 21,
  '25D8D': 21,
  '25D8E': 21,
  '25D8F': 21,
  '25D90': 21,
  '25D91': 21,
  '25D92': 21,
  '25D93': 21,
  '25D94': 21,
  '25D95': 21,
  '25D96': 21,
  '25D97': 21,
  '25D98': 21,
  '25D99': 21,
  '25D9A': 21,
  '25D9B': 21,
  '25D9C': 21,
  '25D9D': 21,
  '25D9E': 21,
  '25D9F': 21,
  '25DA0': 21,
  '25DA1': 21,
  '25DA2': 21,
  '25DA3': 21,
  '25DA4': 21,
  '25DA5': 21,
  '25DA6': 21,
  '25DA7': 21,
  '25DA8': 21,
  '25DA9': 21,
  '25DAA': 21,
  '25DAB': 21,
  '25DAC': 21,
  '25DAD': 21,
  '25DAE': 21,
  '25DAF': 21,
  '25DB0': 21,
  '25DB1': 21,
  '25DB2': 21,
  '25DB3': 21,
  '25DB4': 21,
  '25DB5': 22,
  '25DB6': 22,
  '25DB7': 22,
  '25DB8': 22,
  '25DB9': 22,
  '25DBA': 22,
  '25DBB': 22,
  '25DBC': 22,
  '25DBD': 22,
  '25DBE': 22,
  '25DBF': 22,
  '25DC0': 22,
  '25DC1': 22,
  '25DC2': 22,
  '25DC3': 22,
  '25DC4': 22,
  '25DC5': 22,
  '25DC6': 22,
  '25DC7': 22,
  '25DC8': 22,
  '25DC9': 22,
  '25DCA': 22,
  '25DCB': 22,
  '25DCC': 22,
  '25DCD': 22,
  '25DCE': 22,
  '25DCF': 22,
  '25DD0': 22,
  '25DD1': 22,
  '25DD2': 22,
  '25DD3': 22,
  '25DD4': 23,
  '25DD5': 23,
  '25DD6': 23,
  '25DD7': 23,
  '25DD8': 23,
  '25DD9': 23,
  '25DDA': 23,
  '25DDB': 23,
  '25DDC': 23,
  '25DDD': 23,
  '25DDE': 23,
  '25DDF': 23,
  '25DE0': 23,
  '25DE1': 23,
  '25DE2': 23,
  '25DE3': 23,
  '25DE4': 23,
  '25DE5': 23,
  '25DE6': 23,
  '25DE7': 23,
  '25DE8': 24,
  '25DE9': 24,
  '25DEA': 24,
  '25DEB': 24,
  '25DEC': 24,
  '25DED': 24,
  '25DEE': 24,
  '25DEF': 24,
  '25DF0': 24,
  '25DF1': 24,
  '25DF2': 24,
  '25DF3': 24,
  '25DF4': 24,
  '25DF5': 24,
  '25DF6': 24,
  '25DF7': 24,
  '25DF8': 24,
  '25DF9': 24,
  '25DFA': 24,
  '25DFB': 24,
  '25DFC': 25,
  '25DFD': 25,
  '25DFE': 25,
  '25DFF': 25,
  '25E00': 25,
  '25E01': 25,
  '25E02': 25,
  '25E03': 25,
  '25E04': 25,
  '25E05': 25,
  '25E06': 25,
  '25E07': 25,
  '25E08': 26,
  '25E09': 26,
  '25E0A': 26,
  '25E0B': 26,
  '25E0C': 26,
  '25E0D': 26,
  '25E0E': 26,
  '25E0F': 26,
  '25E10': 27,
  '25E11': 27,
  '25E12': 27,
  '25E13': 27,
  '25E14': 27,
  '25E15': 27,
  '25E16': 27,
  '25E17': 28,
  '25E18': 29,
  '25E19': 29,
  '25E1A': 28,
  '25E1B': 28,
  '25E1C': 28,
  '25E1D': 28,
  '25E1E': 29,
  '25E1F': 29,
  '25E20': 29,
  '25E21': 30,
  '25E22': 30,
  '25E23': 31,
  '25E24': 32,
  '25E25': 7,
  '25E26': 7,
  '25E27': 8,
  '25E28': 8,
  '25E29': 8,
  '25E2A': 8,
  '25E2B': 9,
  '25E2C': 9,
  '25E2D': 9,
  '25E2E': 9,
  '25E2F': 9,
  '25E30': 9,
  '25E31': 9,
  '25E32': 9,
  '25E33': 10,
  '25E34': 10,
  '25E35': 10,
  '25E36': 10,
  '25E37': 10,
  '25E38': 10,
  '25E39': 10,
  '25E3A': 10,
  '25E3B': 10,
  '25E3C': 10,
  '25E3D': 10,
  '25E3E': 10,
  '25E3F': 10,
  '25E40': 10,
  '25E41': 11,
  '25E42': 11,
  '25E43': 11,
  '25E44': 11,
  '25E45': 11,
  '25E46': 11,
  '25E47': 11,
  '25E48': 11,
  '25E49': 11,
  '25E4A': 11,
  '25E4B': 11,
  '25E4C': 11,
  '25E4D': 11,
  '25E4E': 11,
  '25E4F': 11,
  '25E50': 11,
  '25E51': 11,
  '25E52': 11,
  '25E53': 11,
  '25E54': 11,
  '25E55': 11,
  '25E56': 11,
  '25E57': 12,
  '25E58': 11,
  '25E59': 11,
  '25E5A': 12,
  '25E5B': 12,
  '25E5C': 12,
  '25E5D': 12,
  '25E5E': 12,
  '25E5F': 12,
  '25E60': 12,
  '25E61': 12,
  '25E62': 12,
  '25E63': 12,
  '25E64': 12,
  '25E65': 12,
  '25E66': 12,
  '25E67': 12,
  '25E68': 12,
  '25E69': 12,
  '25E6A': 12,
  '25E6B': 12,
  '25E6C': 12,
  '25E6D': 12,
  '25E6E': 12,
  '25E6F': 12,
  '25E70': 12,
  '25E71': 11,
  '25E72': 13,
  '25E73': 13,
  '25E74': 13,
  '25E75': 13,
  '25E76': 13,
  '25E77': 13,
  '25E78': 13,
  '25E79': 13,
  '25E7A': 13,
  '25E7B': 13,
  '25E7C': 13,
  '25E7D': 13,
  '25E7E': 13,
  '25E7F': 13,
  '25E80': 13,
  '25E81': 13,
  '25E82': 13,
  '25E83': 13,
  '25E84': 13,
  '25E85': 13,
  '25E86': 13,
  '25E87': 13,
  '25E88': 13,
  '25E89': 13,
  '25E8A': 13,
  '25E8B': 13,
  '25E8C': 13,
  '25E8D': 13,
  '25E8E': 13,
  '25E8F': 13,
  '25E90': 13,
  '25E91': 13,
  '25E92': 13,
  '25E93': 13,
  '25E94': 13,
  '25E95': 13,
  '25E96': 13,
  '25E97': 13,
  '25E98': 14,
  '25E99': 14,
  '25E9A': 14,
  '25E9B': 14,
  '25E9C': 14,
  '25E9D': 14,
  '25E9E': 14,
  '25E9F': 14,
  '25EA0': 14,
  '25EA1': 14,
  '25EA2': 14,
  '25EA3': 14,
  '25EA4': 14,
  '25EA5': 14,
  '25EA6': 14,
  '25EA7': 14,
  '25EA8': 14,
  '25EA9': 14,
  '25EAA': 14,
  '25EAB': 14,
  '25EAC': 14,
  '25EAD': 14,
  '25EAE': 14,
  '25EAF': 14,
  '25EB0': 14,
  '25EB1': 14,
  '25EB2': 14,
  '25EB3': 14,
  '25EB4': 14,
  '25EB5': 14,
  '25EB6': 14,
  '25EB7': 14,
  '25EB8': 14,
  '25EB9': 14,
  '25EBA': 14,
  '25EBB': 14,
  '25EBC': 14,
  '25EBD': 14,
  '25EBE': 14,
  '25EBF': 14,
  '25EC0': 14,
  '25EC1': 15,
  '25EC2': 15,
  '25EC3': 15,
  '25EC4': 15,
  '25EC5': 15,
  '25EC6': 15,
  '25EC7': 15,
  '25EC8': 15,
  '25EC9': 15,
  '25ECA': 15,
  '25ECB': 15,
  '25ECC': 15,
  '25ECD': 15,
  '25ECE': 15,
  '25ECF': 15,
  '25ED0': 15,
  '25ED1': 15,
  '25ED2': 15,
  '25ED3': 15,
  '25ED4': 15,
  '25ED5': 15,
  '25ED6': 15,
  '25ED7': 15,
  '25ED8': 15,
  '25ED9': 15,
  '25EDA': 15,
  '25EDB': 15,
  '25EDC': 15,
  '25EDD': 15,
  '25EDE': 15,
  '25EDF': 15,
  '25EE0': 15,
  '25EE1': 15,
  '25EE2': 15,
  '25EE3': 15,
  '25EE4': 16,
  '25EE5': 16,
  '25EE6': 16,
  '25EE7': 16,
  '25EE8': 16,
  '25EE9': 16,
  '25EEA': 16,
  '25EEB': 16,
  '25EEC': 16,
  '25EED': 16,
  '25EEE': 16,
  '25EEF': 16,
  '25EF0': 16,
  '25EF1': 16,
  '25EF2': 16,
  '25EF3': 16,
  '25EF4': 16,
  '25EF5': 16,
  '25EF6': 16,
  '25EF7': 16,
  '25EF8': 16,
  '25EF9': 16,
  '25EFA': 16,
  '25EFB': 16,
  '25EFC': 16,
  '25EFD': 16,
  '25EFE': 16,
  '25EFF': 17,
  '25F00': 17,
  '25F01': 17,
  '25F02': 17,
  '25F03': 17,
  '25F04': 17,
  '25F05': 17,
  '25F06': 17,
  '25F07': 17,
  '25F08': 17,
  '25F09': 17,
  '25F0A': 17,
  '25F0B': 17,
  '25F0C': 17,
  '25F0D': 17,
  '25F0E': 17,
  '25F0F': 17,
  '25F10': 17,
  '25F11': 17,
  '25F12': 17,
  '25F13': 17,
  '25F14': 17,
  '25F15': 17,
  '25F16': 17,
  '25F17': 18,
  '25F18': 18,
  '25F19': 18,
  '25F1A': 18,
  '25F1B': 18,
  '25F1C': 18,
  '25F1D': 18,
  '25F1E': 18,
  '25F1F': 18,
  '25F20': 18,
  '25F21': 18,
  '25F22': 18,
  '25F23': 18,
  '25F24': 18,
  '25F25': 18,
  '25F26': 18,
  '25F27': 18,
  '25F28': 18,
  '25F29': 18,
  '25F2A': 18,
  '25F2B': 18,
  '25F2C': 18,
  '25F2D': 18,
  '25F2E': 18,
  '25F2F': 18,
  '25F30': 18,
  '25F31': 18,
  '25F32': 18,
  '25F33': 18,
  '25F34': 18,
  '25F35': 18,
  '25F36': 19,
  '25F37': 19,
  '25F38': 19,
  '25F39': 19,
  '25F3A': 19,
  '25F3B': 19,
  '25F3C': 19,
  '25F3D': 19,
  '25F3E': 19,
  '25F3F': 19,
  '25F40': 19,
  '25F41': 19,
  '25F42': 19,
  '25F43': 19,
  '25F44': 19,
  '25F45': 19,
  '25F46': 19,
  '25F47': 19,
  '25F48': 19,
  '25F49': 19,
  '25F4A': 19,
  '25F4B': 19,
  '25F4C': 19,
  '25F4D': 19,
  '25F4E': 19,
  '25F4F': 20,
  '25F50': 20,
  '25F51': 20,
  '25F52': 20,
  '25F53': 20,
  '25F54': 20,
  '25F55': 20,
  '25F56': 20,
  '25F57': 21,
  '25F58': 21,
  '25F59': 21,
  '25F5A': 21,
  '25F5B': 21,
  '25F5C': 21,
  '25F5D': 21,
  '25F5E': 21,
  '25F5F': 21,
  '25F60': 21,
  '25F61': 22,
  '25F62': 21,
  '25F63': 21,
  '25F64': 22,
  '25F65': 22,
  '25F66': 22,
  '25F67': 22,
  '25F68': 22,
  '25F69': 22,
  '25F6A': 22,
  '25F6B': 22,
  '25F6C': 23,
  '25F6D': 23,
  '25F6E': 23,
  '25F6F': 23,
  '25F70': 23,
  '25F71': 24,
  '25F72': 24,
  '25F73': 24,
  '25F74': 24,
  '25F75': 24,
  '25F76': 25,
  '25F77': 25,
  '25F78': 25,
  '25F79': 25,
  '25F7A': 25,
  '25F7B': 26,
  '25F7C': 26,
  '25F7D': 26,
  '25F7E': 26,
  '25F7F': 26,
  '25F80': 27,
  '25F81': 27,
  '25F82': 30,
  '25F83': 31,
  '25F84': 33,
  '25F85': 8,
  '25F86': 8,
  '25F87': 8,
  '25F88': 8,
  '25F89': 8,
  '25F8A': 8,
  '25F8B': 8,
  '25F8C': 9,
  '25F8D': 9,
  '25F8E': 9,
  '25F8F': 9,
  '25F90': 9,
  '25F91': 9,
  '25F92': 9,
  '25F93': 9,
  '25F94': 9,
  '25F95': 9,
  '25F96': 9,
  '25F97': 9,
  '25F98': 9,
  '25F99': 10,
  '25F9A': 10,
  '25F9B': 10,
  '25F9C': 10,
  '25F9D': 10,
  '25F9E': 10,
  '25F9F': 10,
  '25FA0': 10,
  '25FA1': 10,
  '25FA2': 10,
  '25FA3': 10,
  '25FA4': 10,
  '25FA5': 10,
  '25FA6': 10,
  '25FA7': 10,
  '25FA8': 10,
  '25FA9': 10,
  '25FAA': 10,
  '25FAB': 10,
  '25FAC': 10,
  '25FAD': 10,
  '25FAE': 10,
  '25FAF': 10,
  '25FB0': 10,
  '25FB1': 10,
  '25FB2': 10,
  '25FB3': 10,
  '25FB4': 10,
  '25FB5': 10,
  '25FB6': 10,
  '25FB7': 10,
  '25FB8': 10,
  '25FB9': 10,
  '25FBA': 10,
  '25FBB': 10,
  '25FBC': 10,
  '25FBD': 10,
  '25FBE': 10,
  '25FBF': 10,
  '25FC0': 10,
  '25FC1': 10,
  '25FC2': 10,
  '25FC3': 11,
  '25FC4': 11,
  '25FC5': 11,
  '25FC6': 11,
  '25FC7': 11,
  '25FC8': 11,
  '25FC9': 11,
  '25FCA': 11,
  '25FCB': 11,
  '25FCC': 11,
  '25FCD': 11,
  '25FCE': 11,
  '25FCF': 11,
  '25FD0': 11,
  '25FD1': 11,
  '25FD2': 11,
  '25FD3': 11,
  '25FD4': 11,
  '25FD5': 11,
  '25FD6': 11,
  '25FD7': 11,
  '25FD8': 11,
  '25FD9': 11,
  '25FDA': 11,
  '25FDB': 11,
  '25FDC': 11,
  '25FDD': 11,
  '25FDE': 11,
  '25FDF': 11,
  '25FE0': 11,
  '25FE1': 11,
  '25FE2': 11,
  '25FE3': 11,
  '25FE4': 11,
  '25FE5': 11,
  '25FE6': 12,
  '25FE7': 12,
  '25FE8': 12,
  '25FE9': 12,
  '25FEA': 12,
  '25FEB': 12,
  '25FEC': 12,
  '25FED': 12,
  '25FEE': 12,
  '25FEF': 12,
  '25FF0': 12,
  '25FF1': 12,
  '25FF2': 12,
  '25FF3': 12,
  '25FF4': 12,
  '25FF5': 12,
  '25FF6': 12,
  '25FF7': 12,
  '25FF8': 12,
  '25FF9': 12,
  '25FFA': 12,
  '25FFB': 12,
  '25FFC': 12,
  '25FFD': 12,
  '25FFE': 12,
  '25FFF': 12,
  '26000': 12,
  '26001': 12,
  '26002': 12,
  '26003': 12,
  '26004': 12,
  '26005': 12,
  '26006': 12,
  '26007': 12,
  '26008': 12,
  '26009': 12,
  '2600A': 12,
  '2600B': 12,
  '2600C': 12,
  '2600D': 12,
  '2600E': 12,
  '2600F': 12,
  '26010': 12,
  '26011': 12,
  '26012': 13,
  '26013': 13,
  '26014': 13,
  '26015': 13,
  '26016': 13,
  '26017': 13,
  '26018': 13,
  '26019': 13,
  '2601A': 13,
  '2601B': 13,
  '2601C': 13,
  '2601D': 13,
  '2601E': 13,
  '2601F': 13,
  '26020': 13,
  '26021': 13,
  '26022': 13,
  '26023': 13,
  '26024': 13,
  '26025': 13,
  '26026': 13,
  '26027': 13,
  '26028': 13,
  '26029': 13,
  '2602A': 13,
  '2602B': 13,
  '2602C': 13,
  '2602D': 13,
  '2602E': 13,
  '2602F': 13,
  '26030': 13,
  '26031': 13,
  '26032': 13,
  '26033': 13,
  '26034': 13,
  '26035': 13,
  '26036': 13,
  '26037': 13,
  '26038': 13,
  '26039': 13,
  '2603A': 13,
  '2603B': 13,
  '2603C': 13,
  '2603D': 13,
  '2603E': 13,
  '2603F': 13,
  '26040': 13,
  '26041': 13,
  '26042': 13,
  '26043': 13,
  '26044': 13,
  '26045': 13,
  '26046': 14,
  '26047': 14,
  '26048': 14,
  '26049': 14,
  '2604A': 14,
  '2604B': 14,
  '2604C': 14,
  '2604D': 14,
  '2604E': 14,
  '2604F': 14,
  '26050': 14,
  '26051': 14,
  '26052': 14,
  '26053': 14,
  '26054': 14,
  '26055': 14,
  '26056': 14,
  '26057': 14,
  '26058': 14,
  '26059': 14,
  '2605A': 14,
  '2605B': 14,
  '2605C': 14,
  '2605D': 14,
  '2605E': 14,
  '2605F': 14,
  '26060': 14,
  '26061': 14,
  '26062': 14,
  '26063': 14,
  '26064': 14,
  '26065': 14,
  '26066': 14,
  '26067': 14,
  '26068': 14,
  '26069': 14,
  '2606A': 14,
  '2606B': 14,
  '2606C': 14,
  '2606D': 14,
  '2606E': 14,
  '2606F': 14,
  '26070': 14,
  '26071': 14,
  '26072': 14,
  '26073': 14,
  '26074': 14,
  '26075': 14,
  '26076': 14,
  '26077': 14,
  '26078': 14,
  '26079': 14,
  '2607A': 14,
  '2607B': 14,
  '2607C': 14,
  '2607D': 14,
  '2607E': 14,
  '2607F': 14,
  '26080': 15,
  '26081': 15,
  '26082': 15,
  '26083': 15,
  '26084': 15,
  '26085': 15,
  '26086': 15,
  '26087': 15,
  '26088': 15,
  '26089': 15,
  '2608A': 15,
  '2608B': 15,
  '2608C': 15,
  '2608D': 15,
  '2608E': 15,
  '2608F': 15,
  '26090': 15,
  '26091': 15,
  '26092': 15,
  '26093': 15,
  '26094': 15,
  '26095': 15,
  '26096': 15,
  '26097': 15,
  '26098': 15,
  '26099': 15,
  '2609A': 15,
  '2609B': 15,
  '2609C': 15,
  '2609D': 15,
  '2609E': 15,
  '2609F': 15,
  '260A0': 15,
  '260A1': 15,
  '260A2': 15,
  '260A3': 15,
  '260A4': 15,
  '260A5': 15,
  '260A6': 15,
  '260A7': 16,
  '260A8': 15,
  '260A9': 15,
  '260AA': 15,
  '260AB': 15,
  '260AC': 15,
  '260AD': 15,
  '260AE': 15,
  '260AF': 15,
  '260B0': 15,
  '260B1': 15,
  '260B2': 15,
  '260B3': 15,
  '260B4': 15,
  '260B5': 15,
  '260B6': 15,
  '260B7': 15,
  '260B8': 15,
  '260B9': 15,
  '260BA': 15,
  '260BB': 15,
  '260BC': 15,
  '260BD': 15,
  '260BE': 15,
  '260BF': 15,
  '260C0': 15,
  '260C1': 15,
  '260C2': 16,
  '260C3': 16,
  '260C4': 16,
  '260C5': 16,
  '260C6': 16,
  '260C7': 16,
  '260C8': 16,
  '260C9': 16,
  '260CA': 16,
  '260CB': 16,
  '260CC': 16,
  '260CD': 16,
  '260CE': 16,
  '260CF': 16,
  '260D0': 16,
  '260D1': 16,
  '260D2': 16,
  '260D3': 16,
  '260D4': 16,
  '260D5': 16,
  '260D6': 16,
  '260D7': 16,
  '260D8': 16,
  '260D9': 16,
  '260DA': 16,
  '260DB': 16,
  '260DC': 16,
  '260DD': 16,
  '260DE': 16,
  '260DF': 16,
  '260E0': 16,
  '260E1': 16,
  '260E2': 16,
  '260E3': 16,
  '260E4': 16,
  '260E5': 16,
  '260E6': 16,
  '260E7': 16,
  '260E8': 16,
  '260E9': 16,
  '260EA': 16,
  '260EB': 16,
  '260EC': 16,
  '260ED': 16,
  '260EE': 16,
  '260EF': 16,
  '260F0': 16,
  '260F1': 16,
  '260F2': 16,
  '260F3': 16,
  '260F4': 16,
  '260F5': 16,
  '260F6': 16,
  '260F7': 16,
  '260F8': 16,
  '260F9': 16,
  '260FA': 16,
  '260FB': 16,
  '260FC': 16,
  '260FD': 16,
  '260FE': 16,
  '260FF': 16,
  '26100': 16,
  '26101': 16,
  '26102': 17,
  '26103': 16,
  '26104': 16,
  '26105': 16,
  '26106': 16,
  '26107': 16,
  '26108': 16,
  '26109': 17,
  '2610A': 17,
  '2610B': 17,
  '2610C': 17,
  '2610D': 17,
  '2610E': 17,
  '2610F': 17,
  '26110': 17,
  '26111': 17,
  '26112': 17,
  '26113': 17,
  '26114': 17,
  '26115': 17,
  '26116': 17,
  '26117': 17,
  '26118': 17,
  '26119': 17,
  '2611A': 17,
  '2611B': 17,
  '2611C': 17,
  '2611D': 17,
  '2611E': 17,
  '2611F': 17,
  '26120': 17,
  '26121': 17,
  '26122': 17,
  '26123': 17,
  '26124': 17,
  '26125': 17,
  '26126': 17,
  '26127': 17,
  '26128': 17,
  '26129': 17,
  '2612A': 17,
  '2612B': 17,
  '2612C': 17,
  '2612D': 17,
  '2612E': 17,
  '2612F': 17,
  '26130': 17,
  '26131': 17,
  '26132': 17,
  '26133': 17,
  '26134': 17,
  '26135': 17,
  '26136': 17,
  '26137': 17,
  '26138': 17,
  '26139': 17,
  '2613A': 17,
  '2613B': 17,
  '2613C': 18,
  '2613D': 18,
  '2613E': 18,
  '2613F': 18,
  '26140': 18,
  '26141': 18,
  '26142': 18,
  '26143': 18,
  '26144': 18,
  '26145': 18,
  '26146': 18,
  '26147': 18,
  '26148': 18,
  '26149': 18,
  '2614A': 18,
  '2614B': 18,
  '2614C': 18,
  '2614D': 18,
  '2614E': 18,
  '2614F': 18,
  '26150': 18,
  '26151': 18,
  '26152': 18,
  '26153': 18,
  '26154': 18,
  '26155': 18,
  '26156': 18,
  '26157': 18,
  '26158': 18,
  '26159': 18,
  '2615A': 18,
  '2615B': 18,
  '2615C': 18,
  '2615D': 18,
  '2615E': 18,
  '2615F': 18,
  '26160': 18,
  '26161': 18,
  '26162': 18,
  '26163': 18,
  '26164': 18,
  '26165': 18,
  '26166': 18,
  '26167': 18,
  '26168': 18,
  '26169': 18,
  '2616A': 18,
  '2616B': 18,
  '2616C': 18,
  '2616D': 18,
  '2616E': 18,
  '2616F': 18,
  '26170': 18,
  '26171': 18,
  '26172': 18,
  '26173': 18,
  '26174': 18,
  '26175': 19,
  '26176': 19,
  '26177': 19,
  '26178': 19,
  '26179': 19,
  '2617A': 19,
  '2617B': 19,
  '2617C': 19,
  '2617D': 19,
  '2617E': 19,
  '2617F': 19,
  '26180': 19,
  '26181': 19,
  '26182': 19,
  '26183': 19,
  '26184': 19,
  '26185': 19,
  '26186': 19,
  '26187': 19,
  '26188': 19,
  '26189': 19,
  '2618A': 19,
  '2618B': 19,
  '2618C': 19,
  '2618D': 19,
  '2618E': 19,
  '2618F': 19,
  '26190': 19,
  '26191': 19,
  '26192': 19,
  '26193': 19,
  '26194': 19,
  '26195': 19,
  '26196': 19,
  '26197': 19,
  '26198': 19,
  '26199': 19,
  '2619A': 19,
  '2619B': 19,
  '2619C': 20,
  '2619D': 20,
  '2619E': 20,
  '2619F': 20,
  '261A0': 20,
  '261A1': 20,
  '261A2': 20,
  '261A3': 20,
  '261A4': 21,
  '261A5': 19,
  '261A6': 20,
  '261A7': 20,
  '261A8': 21,
  '261A9': 20,
  '261AA': 20,
  '261AB': 21,
  '261AC': 20,
  '261AD': 20,
  '261AE': 20,
  '261AF': 20,
  '261B0': 20,
  '261B1': 20,
  '261B2': 20,
  '261B3': 20,
  '261B4': 20,
  '261B5': 20,
  '261B6': 20,
  '261B7': 20,
  '261B8': 20,
  '261B9': 20,
  '261BA': 20,
  '261BB': 21,
  '261BC': 21,
  '261BD': 21,
  '261BE': 21,
  '261BF': 21,
  '261C0': 21,
  '261C1': 21,
  '261C2': 22,
  '261C3': 21,
  '261C4': 21,
  '261C5': 21,
  '261C6': 21,
  '261C7': 21,
  '261C8': 21,
  '261C9': 21,
  '261CA': 21,
  '261CB': 21,
  '261CC': 21,
  '261CD': 21,
  '261CE': 21,
  '261CF': 21,
  '261D0': 21,
  '261D1': 21,
  '261D2': 21,
  '261D3': 21,
  '261D4': 22,
  '261D5': 22,
  '261D6': 22,
  '261D7': 22,
  '261D8': 22,
  '261D9': 22,
  '261DA': 22,
  '261DB': 22,
  '261DC': 22,
  '261DD': 22,
  '261DE': 22,
  '261DF': 22,
  '261E0': 22,
  '261E1': 22,
  '261E2': 22,
  '261E3': 22,
  '261E4': 22,
  '261E5': 22,
  '261E6': 22,
  '261E7': 23,
  '261E8': 23,
  '261E9': 23,
  '261EA': 23,
  '261EB': 23,
  '261EC': 23,
  '261ED': 23,
  '261EE': 23,
  '261EF': 23,
  '261F0': 24,
  '261F1': 24,
  '261F2': 24,
  '261F3': 24,
  '261F4': 24,
  '261F5': 24,
  '261F6': 24,
  '261F7': 24,
  '261F8': 25,
  '261F9': 24,
  '261FA': 25,
  '261FB': 25,
  '261FC': 26,
  '261FD': 26,
  '261FE': 27,
  '261FF': 27,
  '26200': 27,
  '26201': 27,
  '26202': 27,
  '26203': 28,
  '26204': 28,
  '26205': 29,
  '26206': 30,
  '26207': 31,
  '26208': 8,
  '26209': 9,
  '2620A': 10,
  '2620B': 10,
  '2620C': 10,
  '2620D': 11,
  '2620E': 11,
  '2620F': 11,
  '26210': 11,
  '26211': 12,
  '26212': 12,
  '26213': 12,
  '26214': 12,
  '26215': 12,
  '26216': 13,
  '26217': 13,
  '26218': 13,
  '26219': 13,
  '2621A': 14,
  '2621B': 15,
  '2621C': 15,
  '2621D': 15,
  '2621E': 16,
  '2621F': 16,
  '26220': 17,
  '26221': 17,
  '26222': 6,
  '26223': 9,
  '26224': 9,
  '26225': 9,
  '26226': 10,
  '26227': 10,
  '26228': 10,
  '26229': 11,
  '2622A': 11,
  '2622B': 11,
  '2622C': 11,
  '2622D': 11,
  '2622E': 11,
  '2622F': 12,
  '26230': 12,
  '26231': 12,
  '26232': 12,
  '26233': 12,
  '26234': 13,
  '26235': 13,
  '26236': 13,
  '26237': 13,
  '26238': 14,
  '26239': 14,
  '2623A': 14,
  '2623B': 14,
  '2623C': 14,
  '2623D': 14,
  '2623E': 14,
  '2623F': 14,
  '26240': 14,
  '26241': 15,
  '26242': 15,
  '26243': 15,
  '26244': 15,
  '26245': 15,
  '26246': 15,
  '26247': 15,
  '26248': 16,
  '26249': 16,
  '2624A': 16,
  '2624B': 16,
  '2624C': 16,
  '2624D': 16,
  '2624E': 16,
  '2624F': 17,
  '26250': 17,
  '26251': 17,
  '26252': 17,
  '26253': 17,
  '26254': 17,
  '26255': 18,
  '26256': 18,
  '26257': 18,
  '26258': 18,
  '26259': 19,
  '2625A': 19,
  '2625B': 19,
  '2625C': 19,
  '2625D': 20,
  '2625E': 20,
  '2625F': 21,
  '26260': 21,
  '26261': 22,
  '26262': 23,
  '26263': 23,
  '26264': 23,
  '26265': 24,
  '26266': 24,
  '26267': 26,
  '26268': 27,
  '26269': 31,
  '2626A': 5,
  '2626B': 5,
  '2626C': 7,
  '2626D': 5,
  '2626E': 7,
  '2626F': 6,
  '26270': 5,
  '26271': 7,
  '26272': 7,
  '26273': 7,
  '26274': 7,
  '26275': 7,
  '26276': 7,
  '26277': 8,
  '26278': 8,
  '26279': 8,
  '2627A': 8,
  '2627B': 8,
  '2627C': 8,
  '2627D': 8,
  '2627E': 8,
  '2627F': 8,
  '26280': 8,
  '26281': 9,
  '26282': 9,
  '26283': 9,
  '26284': 9,
  '26285': 9,
  '26286': 9,
  '26287': 9,
  '26288': 9,
  '26289': 9,
  '2628A': 9,
  '2628B': 9,
  '2628C': 9,
  '2628D': 9,
  '2628E': 10,
  '2628F': 10,
  '26290': 10,
  '26291': 10,
  '26292': 10,
  '26293': 10,
  '26294': 10,
  '26295': 10,
  '26296': 10,
  '26297': 10,
  '26298': 10,
  '26299': 10,
  '2629A': 10,
  '2629B': 10,
  '2629C': 10,
  '2629D': 10,
  '2629E': 10,
  '2629F': 10,
  '262A0': 10,
  '262A1': 10,
  '262A2': 10,
  '262A3': 10,
  '262A4': 10,
  '262A5': 10,
  '262A6': 10,
  '262A7': 10,
  '262A8': 10,
  '262A9': 10,
  '262AA': 11,
  '262AB': 11,
  '262AC': 11,
  '262AD': 11,
  '262AE': 11,
  '262AF': 11,
  '262B0': 11,
  '262B1': 11,
  '262B2': 11,
  '262B3': 10,
  '262B4': 11,
  '262B5': 11,
  '262B6': 11,
  '262B7': 11,
  '262B8': 11,
  '262B9': 11,
  '262BA': 11,
  '262BB': 11,
  '262BC': 12,
  '262BD': 12,
  '262BE': 12,
  '262BF': 12,
  '262C0': 12,
  '262C1': 12,
  '262C2': 12,
  '262C3': 12,
  '262C4': 12,
  '262C5': 13,
  '262C6': 13,
  '262C7': 13,
  '262C8': 13,
  '262C9': 13,
  '262CA': 13,
  '262CB': 13,
  '262CC': 13,
  '262CD': 13,
  '262CE': 13,
  '262CF': 13,
  '262D0': 13,
  '262D1': 12,
  '262D2': 13,
  '262D3': 13,
  '262D4': 13,
  '262D5': 13,
  '262D6': 13,
  '262D7': 13,
  '262D8': 13,
  '262D9': 13,
  '262DA': 13,
  '262DB': 13,
  '262DC': 13,
  '262DD': 14,
  '262DE': 14,
  '262DF': 14,
  '262E0': 14,
  '262E1': 14,
  '262E2': 14,
  '262E3': 14,
  '262E4': 14,
  '262E5': 14,
  '262E6': 14,
  '262E7': 14,
  '262E8': 14,
  '262E9': 14,
  '262EA': 14,
  '262EB': 14,
  '262EC': 14,
  '262ED': 14,
  '262EE': 14,
  '262EF': 15,
  '262F0': 15,
  '262F1': 15,
  '262F2': 15,
  '262F3': 15,
  '262F4': 15,
  '262F5': 15,
  '262F6': 15,
  '262F7': 15,
  '262F8': 15,
  '262F9': 15,
  '262FA': 15,
  '262FB': 15,
  '262FC': 15,
  '262FD': 16,
  '262FE': 16,
  '262FF': 16,
  '26300': 16,
  '26301': 16,
  '26302': 16,
  '26303': 16,
  '26304': 16,
  '26305': 16,
  '26306': 16,
  '26307': 16,
  '26308': 16,
  '26309': 16,
  '2630A': 16,
  '2630B': 16,
  '2630C': 16,
  '2630D': 16,
  '2630E': 16,
  '2630F': 16,
  '26310': 16,
  '26311': 16,
  '26312': 17,
  '26313': 17,
  '26314': 17,
  '26315': 17,
  '26316': 17,
  '26317': 17,
  '26318': 17,
  '26319': 17,
  '2631A': 17,
  '2631B': 17,
  '2631C': 17,
  '2631D': 17,
  '2631E': 17,
  '2631F': 18,
  '26320': 18,
  '26321': 18,
  '26322': 18,
  '26323': 18,
  '26324': 18,
  '26325': 18,
  '26326': 18,
  '26327': 18,
  '26328': 18,
  '26329': 18,
  '2632A': 19,
  '2632B': 19,
  '2632C': 19,
  '2632D': 19,
  '2632E': 19,
  '2632F': 19,
  '26330': 19,
  '26331': 19,
  '26332': 19,
  '26333': 19,
  '26334': 19,
  '26335': 20,
  '26336': 20,
  '26337': 20,
  '26338': 20,
  '26339': 20,
  '2633A': 20,
  '2633B': 21,
  '2633C': 21,
  '2633D': 23,
  '2633E': 24,
  '2633F': 24,
  '26340': 24,
  '26341': 24,
  '26342': 24,
  '26343': 24,
  '26344': 24,
  '26345': 24,
  '26346': 26,
  '26347': 27,
  '26348': 28,
  '26349': 28,
  '2634A': 28,
  '2634B': 6,
  '2634C': 6,
  '2634D': 6,
  '2634E': 8,
  '2634F': 8,
  '26350': 8,
  '26351': 9,
  '26352': 9,
  '26353': 9,
  '26354': 9,
  '26355': 9,
  '26356': 9,
  '26357': 10,
  '26358': 10,
  '26359': 10,
  '2635A': 10,
  '2635B': 10,
  '2635C': 10,
  '2635D': 10,
  '2635E': 10,
  '2635F': 10,
  '26360': 10,
  '26361': 10,
  '26362': 10,
  '26363': 10,
  '26364': 10,
  '26365': 11,
  '26366': 11,
  '26367': 11,
  '26368': 11,
  '26369': 11,
  '2636A': 11,
  '2636B': 11,
  '2636C': 11,
  '2636D': 11,
  '2636E': 10,
  '2636F': 11,
  '26370': 11,
  '26371': 11,
  '26372': 11,
  '26373': 11,
  '26374': 11,
  '26375': 11,
  '26376': 11,
  '26377': 11,
  '26378': 11,
  '26379': 12,
  '2637A': 12,
  '2637B': 12,
  '2637C': 12,
  '2637D': 12,
  '2637E': 12,
  '2637F': 12,
  '26380': 12,
  '26381': 12,
  '26382': 12,
  '26383': 12,
  '26384': 12,
  '26385': 12,
  '26386': 13,
  '26387': 13,
  '26388': 13,
  '26389': 13,
  '2638A': 13,
  '2638B': 13,
  '2638C': 13,
  '2638D': 13,
  '2638E': 13,
  '2638F': 13,
  '26390': 13,
  '26391': 13,
  '26392': 13,
  '26393': 13,
  '26394': 13,
  '26395': 13,
  '26396': 13,
  '26397': 14,
  '26398': 14,
  '26399': 14,
  '2639A': 14,
  '2639B': 14,
  '2639C': 14,
  '2639D': 14,
  '2639E': 14,
  '2639F': 14,
  '263A0': 14,
  '263A1': 14,
  '263A2': 15,
  '263A3': 15,
  '263A4': 15,
  '263A5': 15,
  '263A6': 15,
  '263A7': 15,
  '263A8': 15,
  '263A9': 15,
  '263AA': 15,
  '263AB': 15,
  '263AC': 15,
  '263AD': 15,
  '263AE': 15,
  '263AF': 16,
  '263B0': 16,
  '263B1': 16,
  '263B2': 16,
  '263B3': 16,
  '263B4': 16,
  '263B5': 16,
  '263B6': 16,
  '263B7': 17,
  '263B8': 17,
  '263B9': 17,
  '263BA': 17,
  '263BB': 17,
  '263BC': 17,
  '263BD': 17,
  '263BE': 17,
  '263BF': 17,
  '263C0': 17,
  '263C1': 17,
  '263C2': 17,
  '263C3': 17,
  '263C4': 17,
  '263C5': 18,
  '263C6': 18,
  '263C7': 18,
  '263C8': 18,
  '263C9': 18,
  '263CA': 18,
  '263CB': 18,
  '263CC': 18,
  '263CD': 18,
  '263CE': 18,
  '263CF': 18,
  '263D0': 18,
  '263D1': 18,
  '263D2': 18,
  '263D3': 18,
  '263D4': 18,
  '263D5': 19,
  '263D6': 19,
  '263D7': 19,
  '263D8': 19,
  '263D9': 19,
  '263DA': 19,
  '263DB': 19,
  '263DC': 20,
  '263DD': 20,
  '263DE': 20,
  '263DF': 20,
  '263E0': 20,
  '263E1': 20,
  '263E2': 21,
  '263E3': 21,
  '263E4': 21,
  '263E5': 21,
  '263E6': 21,
  '263E7': 21,
  '263E8': 22,
  '263E9': 22,
  '263EA': 23,
  '263EB': 23,
  '263EC': 24,
  '263ED': 25,
  '263EE': 26,
  '263EF': 27,
  '263F0': 30,
  '263F1': 30,
  '263F2': 8,
  '263F3': 7,
  '263F4': 9,
  '263F5': 9,
  '263F6': 9,
  '263F7': 9,
  '263F8': 9,
  '263F9': 9,
  '263FA': 9,
  '263FB': 9,
  '263FC': 9,
  '263FD': 9,
  '263FE': 9,
  '263FF': 9,
  '26400': 9,
  '26401': 9,
  '26402': 10,
  '26403': 10,
  '26404': 10,
  '26405': 10,
  '26406': 10,
  '26407': 10,
  '26408': 10,
  '26409': 10,
  '2640A': 10,
  '2640B': 10,
  '2640C': 10,
  '2640D': 10,
  '2640E': 10,
  '2640F': 10,
  '26410': 10,
  '26411': 10,
  '26412': 10,
  '26413': 10,
  '26414': 10,
  '26415': 11,
  '26416': 11,
  '26417': 11,
  '26418': 11,
  '26419': 11,
  '2641A': 11,
  '2641B': 11,
  '2641C': 11,
  '2641D': 11,
  '2641E': 11,
  '2641F': 11,
  '26420': 11,
  '26421': 11,
  '26422': 11,
  '26423': 12,
  '26424': 12,
  '26425': 12,
  '26426': 12,
  '26427': 12,
  '26428': 12,
  '26429': 12,
  '2642A': 12,
  '2642B': 12,
  '2642C': 12,
  '2642D': 12,
  '2642E': 12,
  '2642F': 12,
  '26430': 12,
  '26431': 12,
  '26432': 12,
  '26433': 12,
  '26434': 12,
  '26435': 12,
  '26436': 12,
  '26437': 12,
  '26438': 13,
  '26439': 13,
  '2643A': 13,
  '2643B': 13,
  '2643C': 13,
  '2643D': 13,
  '2643E': 13,
  '2643F': 13,
  '26440': 13,
  '26441': 13,
  '26442': 13,
  '26443': 13,
  '26444': 13,
  '26445': 14,
  '26446': 13,
  '26447': 14,
  '26448': 14,
  '26449': 14,
  '2644A': 14,
  '2644B': 14,
  '2644C': 14,
  '2644D': 14,
  '2644E': 14,
  '2644F': 14,
  '26450': 14,
  '26451': 14,
  '26452': 14,
  '26453': 14,
  '26454': 14,
  '26455': 14,
  '26456': 14,
  '26457': 14,
  '26458': 15,
  '26459': 15,
  '2645A': 15,
  '2645B': 15,
  '2645C': 15,
  '2645D': 15,
  '2645E': 15,
  '2645F': 15,
  '26460': 15,
  '26461': 15,
  '26462': 15,
  '26463': 15,
  '26464': 15,
  '26465': 15,
  '26466': 15,
  '26467': 15,
  '26468': 15,
  '26469': 15,
  '2646A': 15,
  '2646B': 15,
  '2646C': 15,
  '2646D': 15,
  '2646E': 15,
  '2646F': 15,
  '26470': 16,
  '26471': 16,
  '26472': 16,
  '26473': 16,
  '26474': 16,
  '26475': 16,
  '26476': 16,
  '26477': 16,
  '26478': 16,
  '26479': 16,
  '2647A': 16,
  '2647B': 15,
  '2647C': 16,
  '2647D': 16,
  '2647E': 16,
  '2647F': 16,
  '26480': 16,
  '26481': 16,
  '26482': 16,
  '26483': 17,
  '26484': 17,
  '26485': 17,
  '26486': 17,
  '26487': 17,
  '26488': 17,
  '26489': 17,
  '2648A': 17,
  '2648B': 17,
  '2648C': 17,
  '2648D': 18,
  '2648E': 18,
  '2648F': 18,
  '26490': 18,
  '26491': 18,
  '26492': 18,
  '26493': 18,
  '26494': 18,
  '26495': 18,
  '26496': 18,
  '26497': 18,
  '26498': 18,
  '26499': 18,
  '2649A': 18,
  '2649B': 18,
  '2649C': 19,
  '2649D': 19,
  '2649E': 16,
  '2649F': 18,
  '264A0': 19,
  '264A1': 19,
  '264A2': 20,
  '264A3': 20,
  '264A4': 20,
  '264A5': 20,
  '264A6': 21,
  '264A7': 22,
  '264A8': 21,
  '264A9': 21,
  '264AA': 20,
  '264AB': 20,
  '264AC': 21,
  '264AD': 22,
  '264AE': 22,
  '264AF': 22,
  '264B0': 24,
  '264B1': 7,
  '264B2': 7,
  '264B3': 8,
  '264B4': 8,
  '264B5': 8,
  '264B6': 9,
  '264B7': 9,
  '264B8': 9,
  '264B9': 9,
  '264BA': 10,
  '264BB': 10,
  '264BC': 10,
  '264BD': 10,
  '264BE': 10,
  '264BF': 10,
  '264C0': 11,
  '264C1': 13,
  '264C2': 14,
  '264C3': 17,
  '264C4': 15,
  '264C5': 15,
  '264C6': 16,
  '264C7': 16,
  '264C8': 17,
  '264C9': 17,
  '264CA': 18,
  '264CB': 23,
  '264CC': 23,
  '264CD': 26,
  '264CE': 8,
  '264CF': 8,
  '264D0': 6,
  '264D1': 8,
  '264D2': 10,
  '264D3': 11,
  '264D4': 12,
  '264D5': 12,
  '264D6': 13,
  '264D7': 14,
  '264D8': 15,
  '264D9': 15,
  '264DA': 15,
  '264DB': 15,
  '264DC': 15,
  '264DD': 16,
  '264DE': 16,
  '264DF': 17,
  '264E0': 17,
  '264E1': 17,
  '264E2': 22,
  '264E3': 24,
  '264E4': 6,
  '264E5': 8,
  '264E6': 8,
  '264E7': 8,
  '264E8': 9,
  '264E9': 9,
  '264EA': 9,
  '264EB': 10,
  '264EC': 11,
  '264ED': 11,
  '264EE': 11,
  '264EF': 12,
  '264F0': 12,
  '264F1': 12,
  '264F2': 12,
  '264F3': 12,
  '264F4': 13,
  '264F5': 13,
  '264F6': 13,
  '264F7': 14,
  '264F8': 14,
  '264F9': 14,
  '264FA': 14,
  '264FB': 14,
  '264FC': 14,
  '264FD': 14,
  '264FE': 14,
  '264FF': 14,
  '26500': 14,
  '26501': 14,
  '26502': 15,
  '26503': 15,
  '26504': 15,
  '26505': 15,
  '26506': 15,
  '26507': 15,
  '26508': 15,
  '26509': 15,
  '2650A': 15,
  '2650B': 16,
  '2650C': 16,
  '2650D': 16,
  '2650E': 16,
  '2650F': 16,
  '26510': 16,
  '26511': 16,
  '26512': 16,
  '26513': 17,
  '26514': 17,
  '26515': 17,
  '26516': 17,
  '26517': 17,
  '26518': 17,
  '26519': 17,
  '2651A': 17,
  '2651B': 18,
  '2651C': 18,
  '2651D': 18,
  '2651E': 18,
  '2651F': 19,
  '26520': 18,
  '26521': 18,
  '26522': 18,
  '26523': 18,
  '26524': 18,
  '26525': 19,
  '26526': 19,
  '26527': 19,
  '26528': 19,
  '26529': 21,
  '2652A': 21,
  '2652B': 24,
  '2652C': 24,
  '2652D': 25,
  '2652E': 7,
  '2652F': 8,
  '26530': 8,
  '26531': 8,
  '26532': 8,
  '26533': 8,
  '26534': 8,
  '26535': 8,
  '26536': 8,
  '26537': 9,
  '26538': 9,
  '26539': 9,
  '2653A': 9,
  '2653B': 9,
  '2653C': 10,
  '2653D': 10,
  '2653E': 10,
  '2653F': 10,
  '26540': 10,
  '26541': 10,
  '26542': 10,
  '26543': 10,
  '26544': 10,
  '26545': 10,
  '26546': 10,
  '26547': 10,
  '26548': 10,
  '26549': 10,
  '2654A': 10,
  '2654B': 10,
  '2654C': 10,
  '2654D': 10,
  '2654E': 10,
  '2654F': 10,
  '26550': 11,
  '26551': 11,
  '26552': 11,
  '26553': 11,
  '26554': 11,
  '26555': 11,
  '26556': 11,
  '26557': 11,
  '26558': 11,
  '26559': 11,
  '2655A': 11,
  '2655B': 11,
  '2655C': 11,
  '2655D': 11,
  '2655E': 11,
  '2655F': 11,
  '26560': 12,
  '26561': 12,
  '26562': 12,
  '26563': 13,
  '26564': 12,
  '26565': 12,
  '26566': 12,
  '26567': 12,
  '26568': 12,
  '26569': 12,
  '2656A': 12,
  '2656B': 12,
  '2656C': 12,
  '2656D': 12,
  '2656E': 12,
  '2656F': 12,
  '26570': 12,
  '26571': 12,
  '26572': 12,
  '26573': 12,
  '26574': 12,
  '26575': 13,
  '26576': 13,
  '26577': 13,
  '26578': 13,
  '26579': 13,
  '2657A': 13,
  '2657B': 13,
  '2657C': 13,
  '2657D': 13,
  '2657E': 13,
  '2657F': 13,
  '26580': 13,
  '26581': 13,
  '26582': 13,
  '26583': 13,
  '26584': 13,
  '26585': 13,
  '26586': 13,
  '26587': 13,
  '26588': 14,
  '26589': 14,
  '2658A': 14,
  '2658B': 14,
  '2658C': 14,
  '2658D': 14,
  '2658E': 14,
  '2658F': 14,
  '26590': 14,
  '26591': 14,
  '26592': 14,
  '26593': 14,
  '26594': 14,
  '26595': 14,
  '26596': 14,
  '26597': 13,
  '26598': 14,
  '26599': 14,
  '2659A': 14,
  '2659B': 14,
  '2659C': 14,
  '2659D': 14,
  '2659E': 14,
  '2659F': 14,
  '265A0': 14,
  '265A1': 14,
  '265A2': 15,
  '265A3': 15,
  '265A4': 15,
  '265A5': 15,
  '265A6': 15,
  '265A7': 15,
  '265A8': 15,
  '265A9': 15,
  '265AA': 15,
  '265AB': 15,
  '265AC': 15,
  '265AD': 15,
  '265AE': 15,
  '265AF': 15,
  '265B0': 15,
  '265B1': 15,
  '265B2': 15,
  '265B3': 15,
  '265B4': 15,
  '265B5': 15,
  '265B6': 15,
  '265B7': 15,
  '265B8': 15,
  '265B9': 15,
  '265BA': 15,
  '265BB': 15,
  '265BC': 16,
  '265BD': 16,
  '265BE': 16,
  '265BF': 16,
  '265C0': 16,
  '265C1': 16,
  '265C2': 16,
  '265C3': 16,
  '265C4': 16,
  '265C5': 16,
  '265C6': 16,
  '265C7': 15,
  '265C8': 16,
  '265C9': 16,
  '265CA': 16,
  '265CB': 16,
  '265CC': 16,
  '265CD': 16,
  '265CE': 16,
  '265CF': 16,
  '265D0': 17,
  '265D1': 17,
  '265D2': 17,
  '265D3': 17,
  '265D4': 17,
  '265D5': 17,
  '265D6': 17,
  '265D7': 17,
  '265D8': 17,
  '265D9': 17,
  '265DA': 17,
  '265DB': 17,
  '265DC': 17,
  '265DD': 17,
  '265DE': 17,
  '265DF': 18,
  '265E0': 18,
  '265E1': 18,
  '265E2': 18,
  '265E3': 18,
  '265E4': 18,
  '265E5': 18,
  '265E6': 18,
  '265E7': 18,
  '265E8': 18,
  '265E9': 18,
  '265EA': 18,
  '265EB': 18,
  '265EC': 18,
  '265ED': 18,
  '265EE': 18,
  '265EF': 18,
  '265F0': 18,
  '265F1': 18,
  '265F2': 18,
  '265F3': 19,
  '265F4': 19,
  '265F5': 19,
  '265F6': 19,
  '265F7': 19,
  '265F8': 19,
  '265F9': 19,
  '265FA': 19,
  '265FB': 19,
  '265FC': 19,
  '265FD': 19,
  '265FE': 20,
  '265FF': 20,
  '26600': 20,
  '26601': 20,
  '26602': 20,
  '26603': 20,
  '26604': 20,
  '26605': 21,
  '26606': 21,
  '26607': 21,
  '26608': 21,
  '26609': 21,
  '2660A': 22,
  '2660B': 22,
  '2660C': 22,
  '2660D': 23,
  '2660E': 23,
  '2660F': 24,
  '26610': 25,
  '26611': 26,
  '26612': 6,
  '26613': 8,
  '26614': 9,
  '26615': 11,
  '26616': 11,
  '26617': 11,
  '26618': 12,
  '26619': 12,
  '2661A': 12,
  '2661B': 13,
  '2661C': 13,
  '2661D': 14,
  '2661E': 14,
  '2661F': 14,
  '26620': 15,
  '26621': 15,
  '26622': 15,
  '26623': 16,
  '26624': 17,
  '26625': 17,
  '26626': 17,
  '26627': 19,
  '26628': 20,
  '26629': 6,
  '2662A': 6,
  '2662B': 6,
  '2662C': 6,
  '2662D': 6,
  '2662E': 6,
  '2662F': 6,
  '26630': 6,
  '26631': 6,
  '26632': 6,
  '26633': 6,
  '26634': 7,
  '26635': 7,
  '26636': 7,
  '26637': 7,
  '26638': 7,
  '26639': 7,
  '2663A': 7,
  '2663B': 7,
  '2663C': 7,
  '2663D': 7,
  '2663E': 7,
  '2663F': 8,
  '26640': 8,
  '26641': 8,
  '26642': 8,
  '26643': 8,
  '26644': 8,
  '26645': 8,
  '26646': 8,
  '26647': 8,
  '26648': 8,
  '26649': 8,
  '2664A': 8,
  '2664B': 8,
  '2664C': 8,
  '2664D': 8,
  '2664E': 8,
  '2664F': 8,
  '26650': 8,
  '26651': 8,
  '26652': 8,
  '26653': 8,
  '26654': 8,
  '26655': 8,
  '26656': 8,
  '26657': 8,
  '26658': 8,
  '26659': 8,
  '2665A': 8,
  '2665B': 8,
  '2665C': 8,
  '2665D': 8,
  '2665E': 8,
  '2665F': 8,
  '26660': 8,
  '26661': 8,
  '26662': 8,
  '26663': 8,
  '26664': 8,
  '26665': 8,
  '26666': 8,
  '26667': 8,
  '26668': 8,
  '26669': 8,
  '2666A': 9,
  '2666B': 9,
  '2666C': 9,
  '2666D': 9,
  '2666E': 9,
  '2666F': 9,
  '26670': 9,
  '26671': 9,
  '26672': 9,
  '26673': 9,
  '26674': 9,
  '26675': 9,
  '26676': 9,
  '26677': 9,
  '26678': 9,
  '26679': 9,
  '2667A': 9,
  '2667B': 9,
  '2667C': 9,
  '2667D': 9,
  '2667E': 9,
  '2667F': 9,
  '26680': 9,
  '26681': 9,
  '26682': 9,
  '26683': 9,
  '26684': 9,
  '26685': 9,
  '26686': 9,
  '26687': 9,
  '26688': 9,
  '26689': 9,
  '2668A': 9,
  '2668B': 9,
  '2668C': 9,
  '2668D': 9,
  '2668E': 9,
  '2668F': 9,
  '26690': 9,
  '26691': 9,
  '26692': 9,
  '26693': 9,
  '26694': 9,
  '26695': 9,
  '26696': 9,
  '26697': 9,
  '26698': 9,
  '26699': 9,
  '2669A': 9,
  '2669B': 9,
  '2669C': 9,
  '2669D': 10,
  '2669E': 10,
  '2669F': 10,
  '266A0': 10,
  '266A1': 10,
  '266A2': 10,
  '266A3': 10,
  '266A4': 10,
  '266A5': 10,
  '266A6': 10,
  '266A7': 10,
  '266A8': 10,
  '266A9': 10,
  '266AA': 10,
  '266AB': 10,
  '266AC': 10,
  '266AD': 10,
  '266AE': 10,
  '266AF': 10,
  '266B0': 10,
  '266B1': 10,
  '266B2': 10,
  '266B3': 10,
  '266B4': 10,
  '266B5': 10,
  '266B6': 10,
  '266B7': 10,
  '266B8': 10,
  '266B9': 10,
  '266BA': 10,
  '266BB': 10,
  '266BC': 10,
  '266BD': 10,
  '266BE': 10,
  '266BF': 10,
  '266C0': 10,
  '266C1': 10,
  '266C2': 10,
  '266C3': 10,
  '266C4': 10,
  '266C5': 10,
  '266C6': 10,
  '266C7': 10,
  '266C8': 10,
  '266C9': 10,
  '266CA': 10,
  '266CB': 10,
  '266CC': 10,
  '266CD': 10,
  '266CE': 10,
  '266CF': 10,
  '266D0': 10,
  '266D1': 10,
  '266D2': 10,
  '266D3': 10,
  '266D4': 11,
  '266D5': 11,
  '266D6': 11,
  '266D7': 11,
  '266D8': 11,
  '266D9': 11,
  '266DA': 11,
  '266DB': 11,
  '266DC': 11,
  '266DD': 11,
  '266DE': 11,
  '266DF': 11,
  '266E0': 11,
  '266E1': 11,
  '266E2': 11,
  '266E3': 11,
  '266E4': 11,
  '266E5': 11,
  '266E6': 11,
  '266E7': 11,
  '266E8': 11,
  '266E9': 11,
  '266EA': 11,
  '266EB': 11,
  '266EC': 11,
  '266ED': 11,
  '266EE': 11,
  '266EF': 11,
  '266F0': 11,
  '266F1': 11,
  '266F2': 11,
  '266F3': 11,
  '266F4': 11,
  '266F5': 11,
  '266F6': 11,
  '266F7': 11,
  '266F8': 11,
  '266F9': 11,
  '266FA': 11,
  '266FB': 11,
  '266FC': 11,
  '266FD': 11,
  '266FE': 11,
  '266FF': 11,
  '26700': 11,
  '26701': 11,
  '26702': 11,
  '26703': 11,
  '26704': 11,
  '26705': 11,
  '26706': 12,
  '26707': 12,
  '26708': 12,
  '26709': 12,
  '2670A': 12,
  '2670B': 12,
  '2670C': 12,
  '2670D': 12,
  '2670E': 12,
  '2670F': 12,
  '26710': 12,
  '26711': 12,
  '26712': 12,
  '26713': 12,
  '26714': 12,
  '26715': 12,
  '26716': 12,
  '26717': 12,
  '26718': 12,
  '26719': 12,
  '2671A': 12,
  '2671B': 12,
  '2671C': 12,
  '2671D': 12,
  '2671E': 12,
  '2671F': 12,
  '26720': 12,
  '26721': 12,
  '26722': 12,
  '26723': 12,
  '26724': 12,
  '26725': 12,
  '26726': 12,
  '26727': 12,
  '26728': 12,
  '26729': 12,
  '2672A': 12,
  '2672B': 12,
  '2672C': 12,
  '2672D': 12,
  '2672E': 12,
  '2672F': 12,
  '26730': 12,
  '26731': 12,
  '26732': 12,
  '26733': 12,
  '26734': 12,
  '26735': 12,
  '26736': 12,
  '26737': 12,
  '26738': 12,
  '26739': 12,
  '2673A': 12,
  '2673B': 12,
  '2673C': 12,
  '2673D': 12,
  '2673E': 12,
  '2673F': 12,
  '26740': 12,
  '26741': 12,
  '26742': 12,
  '26743': 12,
  '26744': 12,
  '26745': 12,
  '26746': 12,
  '26747': 12,
  '26748': 12,
  '26749': 12,
  '2674A': 12,
  '2674B': 12,
  '2674C': 12,
  '2674D': 12,
  '2674E': 12,
  '2674F': 12,
  '26750': 12,
  '26751': 12,
  '26752': 12,
  '26753': 12,
  '26754': 12,
  '26755': 12,
  '26756': 12,
  '26757': 12,
  '26758': 12,
  '26759': 12,
  '2675A': 13,
  '2675B': 13,
  '2675C': 13,
  '2675D': 13,
  '2675E': 13,
  '2675F': 13,
  '26760': 13,
  '26761': 13,
  '26762': 13,
  '26763': 13,
  '26764': 13,
  '26765': 13,
  '26766': 13,
  '26767': 13,
  '26768': 13,
  '26769': 13,
  '2676A': 13,
  '2676B': 13,
  '2676C': 13,
  '2676D': 13,
  '2676E': 13,
  '2676F': 13,
  '26770': 13,
  '26771': 13,
  '26772': 13,
  '26773': 13,
  '26774': 13,
  '26775': 13,
  '26776': 13,
  '26777': 13,
  '26778': 13,
  '26779': 13,
  '2677A': 13,
  '2677B': 13,
  '2677C': 13,
  '2677D': 13,
  '2677E': 13,
  '2677F': 13,
  '26780': 13,
  '26781': 13,
  '26782': 13,
  '26783': 13,
  '26784': 13,
  '26785': 13,
  '26786': 13,
  '26787': 13,
  '26788': 13,
  '26789': 13,
  '2678A': 13,
  '2678B': 13,
  '2678C': 13,
  '2678D': 13,
  '2678E': 13,
  '2678F': 13,
  '26790': 13,
  '26791': 13,
  '26792': 13,
  '26793': 13,
  '26794': 13,
  '26795': 13,
  '26796': 13,
  '26797': 13,
  '26798': 13,
  '26799': 14,
  '2679A': 14,
  '2679B': 14,
  '2679C': 14,
  '2679D': 14,
  '2679E': 14,
  '2679F': 14,
  '267A0': 14,
  '267A1': 14,
  '267A2': 14,
  '267A3': 14,
  '267A4': 14,
  '267A5': 14,
  '267A6': 14,
  '267A7': 14,
  '267A8': 14,
  '267A9': 14,
  '267AA': 14,
  '267AB': 14,
  '267AC': 14,
  '267AD': 14,
  '267AE': 14,
  '267AF': 14,
  '267B0': 14,
  '267B1': 14,
  '267B2': 14,
  '267B3': 14,
  '267B4': 14,
  '267B5': 14,
  '267B6': 14,
  '267B7': 14,
  '267B8': 14,
  '267B9': 14,
  '267BA': 14,
  '267BB': 14,
  '267BC': 14,
  '267BD': 14,
  '267BE': 14,
  '267BF': 14,
  '267C0': 14,
  '267C1': 14,
  '267C2': 14,
  '267C3': 14,
  '267C4': 14,
  '267C5': 14,
  '267C6': 14,
  '267C7': 14,
  '267C8': 14,
  '267C9': 14,
  '267CA': 14,
  '267CB': 14,
  '267CC': 14,
  '267CD': 14,
  '267CE': 14,
  '267CF': 14,
  '267D0': 14,
  '267D1': 14,
  '267D2': 14,
  '267D3': 13,
  '267D4': 13,
  '267D5': 14,
  '267D6': 14,
  '267D7': 14,
  '267D8': 15,
  '267D9': 15,
  '267DA': 15,
  '267DB': 15,
  '267DC': 15,
  '267DD': 15,
  '267DE': 15,
  '267DF': 15,
  '267E0': 15,
  '267E1': 15,
  '267E2': 15,
  '267E3': 15,
  '267E4': 15,
  '267E5': 15,
  '267E6': 15,
  '267E7': 15,
  '267E8': 15,
  '267E9': 15,
  '267EA': 15,
  '267EB': 15,
  '267EC': 15,
  '267ED': 15,
  '267EE': 15,
  '267EF': 15,
  '267F0': 15,
  '267F1': 15,
  '267F2': 15,
  '267F3': 15,
  '267F4': 15,
  '267F5': 15,
  '267F6': 15,
  '267F7': 15,
  '267F8': 15,
  '267F9': 15,
  '267FA': 15,
  '267FB': 15,
  '267FC': 15,
  '267FD': 15,
  '267FE': 15,
  '267FF': 15,
  '26800': 15,
  '26801': 15,
  '26802': 15,
  '26803': 16,
  '26804': 16,
  '26805': 16,
  '26806': 16,
  '26807': 16,
  '26808': 16,
  '26809': 16,
  '2680A': 16,
  '2680B': 16,
  '2680C': 16,
  '2680D': 16,
  '2680E': 16,
  '2680F': 16,
  '26810': 16,
  '26811': 16,
  '26812': 16,
  '26813': 16,
  '26814': 16,
  '26815': 16,
  '26816': 16,
  '26817': 16,
  '26818': 16,
  '26819': 16,
  '2681A': 16,
  '2681B': 16,
  '2681C': 16,
  '2681D': 16,
  '2681E': 16,
  '2681F': 16,
  '26820': 16,
  '26821': 16,
  '26822': 16,
  '26823': 16,
  '26824': 16,
  '26825': 16,
  '26826': 16,
  '26827': 16,
  '26828': 16,
  '26829': 16,
  '2682A': 16,
  '2682B': 16,
  '2682C': 16,
  '2682D': 16,
  '2682E': 16,
  '2682F': 16,
  '26830': 16,
  '26831': 16,
  '26832': 16,
  '26833': 16,
  '26834': 16,
  '26835': 16,
  '26836': 16,
  '26837': 16,
  '26838': 16,
  '26839': 16,
  '2683A': 16,
  '2683B': 16,
  '2683C': 16,
  '2683D': 16,
  '2683E': 17,
  '2683F': 17,
  '26840': 17,
  '26841': 17,
  '26842': 17,
  '26843': 17,
  '26844': 16,
  '26845': 17,
  '26846': 17,
  '26847': 17,
  '26848': 17,
  '26849': 17,
  '2684A': 17,
  '2684B': 17,
  '2684C': 17,
  '2684D': 17,
  '2684E': 18,
  '2684F': 17,
  '26850': 17,
  '26851': 17,
  '26852': 17,
  '26853': 17,
  '26854': 17,
  '26855': 17,
  '26856': 17,
  '26857': 16,
  '26858': 17,
  '26859': 17,
  '2685A': 17,
  '2685B': 17,
  '2685C': 17,
  '2685D': 17,
  '2685E': 17,
  '2685F': 17,
  '26860': 17,
  '26861': 17,
  '26862': 17,
  '26863': 17,
  '26864': 17,
  '26865': 17,
  '26866': 17,
  '26867': 17,
  '26868': 17,
  '26869': 17,
  '2686A': 17,
  '2686B': 17,
  '2686C': 17,
  '2686D': 17,
  '2686E': 16,
  '2686F': 17,
  '26870': 18,
  '26871': 18,
  '26872': 18,
  '26873': 18,
  '26874': 18,
  '26875': 18,
  '26876': 18,
  '26877': 18,
  '26878': 18,
  '26879': 18,
  '2687A': 18,
  '2687B': 18,
  '2687C': 18,
  '2687D': 18,
  '2687E': 18,
  '2687F': 18,
  '26880': 18,
  '26881': 18,
  '26882': 18,
  '26883': 18,
  '26884': 18,
  '26885': 18,
  '26886': 18,
  '26887': 18,
  '26888': 18,
  '26889': 18,
  '2688A': 19,
  '2688B': 19,
  '2688C': 19,
  '2688D': 19,
  '2688E': 19,
  '2688F': 19,
  '26890': 19,
  '26891': 19,
  '26892': 19,
  '26893': 19,
  '26894': 19,
  '26895': 19,
  '26896': 19,
  '26897': 19,
  '26898': 19,
  '26899': 19,
  '2689A': 19,
  '2689B': 19,
  '2689C': 19,
  '2689D': 19,
  '2689E': 19,
  '2689F': 19,
  '268A0': 19,
  '268A1': 19,
  '268A2': 19,
  '268A3': 19,
  '268A4': 19,
  '268A5': 20,
  '268A6': 20,
  '268A7': 20,
  '268A8': 20,
  '268A9': 20,
  '268AA': 20,
  '268AB': 20,
  '268AC': 20,
  '268AD': 20,
  '268AE': 20,
  '268AF': 20,
  '268B0': 20,
  '268B1': 20,
  '268B2': 20,
  '268B3': 20,
  '268B4': 20,
  '268B5': 20,
  '268B6': 20,
  '268B7': 20,
  '268B8': 21,
  '268B9': 21,
  '268BA': 21,
  '268BB': 21,
  '268BC': 21,
  '268BD': 21,
  '268BE': 21,
  '268BF': 22,
  '268C0': 22,
  '268C1': 22,
  '268C2': 22,
  '268C3': 22,
  '268C4': 22,
  '268C5': 22,
  '268C6': 23,
  '268C7': 23,
  '268C8': 23,
  '268C9': 23,
  '268CA': 23,
  '268CB': 23,
  '268CC': 23,
  '268CD': 23,
  '268CE': 23,
  '268CF': 23,
  '268D0': 23,
  '268D1': 24,
  '268D2': 24,
  '268D3': 24,
  '268D4': 24,
  '268D5': 24,
  '268D6': 25,
  '268D7': 25,
  '268D8': 26,
  '268D9': 26,
  '268DA': 26,
  '268DB': 27,
  '268DC': 28,
  '268DD': 7,
  '268DE': 6,
  '268DF': 9,
  '268E0': 10,
  '268E1': 10,
  '268E2': 10,
  '268E3': 10,
  '268E4': 11,
  '268E5': 11,
  '268E6': 12,
  '268E7': 12,
  '268E8': 12,
  '268E9': 12,
  '268EA': 16,
  '268EB': 14,
  '268EC': 14,
  '268ED': 15,
  '268EE': 14,
  '268EF': 15,
  '268F0': 17,
  '268F1': 17,
  '268F2': 18,
  '268F3': 18,
  '268F4': 21,
  '268F5': 21,
  '268F6': 22,
  '268F7': 29,
  '268F8': 31,
  '268F9': 6,
  '268FA': 7,
  '268FB': 7,
  '268FC': 7,
  '268FD': 8,
  '268FE': 9,
  '268FF': 9,
  '26900': 9,
  '26901': 10,
  '26902': 10,
  '26903': 10,
  '26904': 10,
  '26905': 10,
  '26906': 10,
  '26907': 10,
  '26908': 10,
  '26909': 10,
  '2690A': 10,
  '2690B': 10,
  '2690C': 11,
  '2690D': 11,
  '2690E': 11,
  '2690F': 11,
  '26910': 12,
  '26911': 12,
  '26912': 12,
  '26913': 12,
  '26914': 13,
  '26915': 14,
  '26916': 14,
  '26917': 14,
  '26918': 15,
  '26919': 15,
  '2691A': 15,
  '2691B': 15,
  '2691C': 15,
  '2691D': 15,
  '2691E': 16,
  '2691F': 16,
  '26920': 16,
  '26921': 17,
  '26922': 17,
  '26923': 17,
  '26924': 17,
  '26925': 18,
  '26926': 19,
  '26927': 19,
  '26928': 19,
  '26929': 19,
  '2692A': 19,
  '2692B': 20,
  '2692C': 20,
  '2692D': 20,
  '2692E': 20,
  '2692F': 21,
  '26930': 21,
  '26931': 24,
  '26932': 26,
  '26933': 6,
  '26934': 6,
  '26935': 8,
  '26936': 9,
  '26937': 9,
  '26938': 9,
  '26939': 10,
  '2693A': 10,
  '2693B': 11,
  '2693C': 11,
  '2693D': 11,
  '2693E': 11,
  '2693F': 12,
  '26940': 12,
  '26941': 13,
  '26942': 14,
  '26943': 14,
  '26944': 14,
  '26945': 15,
  '26946': 15,
  '26947': 15,
  '26948': 14,
  '26949': 16,
  '2694A': 16,
  '2694B': 16,
  '2694C': 16,
  '2694D': 16,
  '2694E': 17,
  '2694F': 18,
  '26950': 18,
  '26951': 6,
  '26952': 6,
  '26953': 6,
  '26954': 7,
  '26955': 8,
  '26956': 8,
  '26957': 9,
  '26958': 9,
  '26959': 8,
  '2695A': 9,
  '2695B': 9,
  '2695C': 9,
  '2695D': 10,
  '2695E': 10,
  '2695F': 10,
  '26960': 10,
  '26961': 10,
  '26962': 10,
  '26963': 10,
  '26964': 10,
  '26965': 11,
  '26966': 11,
  '26967': 9,
  '26968': 11,
  '26969': 11,
  '2696A': 11,
  '2696B': 11,
  '2696C': 11,
  '2696D': 12,
  '2696E': 12,
  '2696F': 12,
  '26970': 12,
  '26971': 12,
  '26972': 12,
  '26973': 13,
  '26974': 13,
  '26975': 13,
  '26976': 13,
  '26977': 13,
  '26978': 13,
  '26979': 13,
  '2697A': 13,
  '2697B': 14,
  '2697C': 14,
  '2697D': 14,
  '2697E': 14,
  '2697F': 14,
  '26980': 14,
  '26981': 14,
  '26982': 14,
  '26983': 14,
  '26984': 14,
  '26985': 15,
  '26986': 15,
  '26987': 15,
  '26988': 15,
  '26989': 15,
  '2698A': 15,
  '2698B': 15,
  '2698C': 15,
  '2698D': 15,
  '2698E': 15,
  '2698F': 16,
  '26990': 16,
  '26991': 16,
  '26992': 16,
  '26993': 16,
  '26994': 15,
  '26995': 16,
  '26996': 16,
  '26997': 16,
  '26998': 17,
  '26999': 17,
  '2699A': 17,
  '2699B': 17,
  '2699C': 17,
  '2699D': 17,
  '2699E': 17,
  '2699F': 17,
  '269A0': 17,
  '269A1': 18,
  '269A2': 18,
  '269A3': 18,
  '269A4': 18,
  '269A5': 18,
  '269A6': 18,
  '269A7': 18,
  '269A8': 18,
  '269A9': 19,
  '269AA': 19,
  '269AB': 19,
  '269AC': 19,
  '269AD': 20,
  '269AE': 20,
  '269AF': 19,
  '269B0': 20,
  '269B1': 20,
  '269B2': 20,
  '269B3': 20,
  '269B4': 20,
  '269B5': 20,
  '269B6': 21,
  '269B7': 21,
  '269B8': 21,
  '269B9': 22,
  '269BA': 23,
  '269BB': 23,
  '269BC': 24,
  '269BD': 25,
  '269BE': 25,
  '269BF': 25,
  '269C0': 26,
  '269C1': 27,
  '269C2': 27,
  '269C3': 30,
  '269C4': 42,
  '269C5': 48,
  '269C6': 7,
  '269C7': 9,
  '269C8': 10,
  '269C9': 10,
  '269CA': 10,
  '269CB': 10,
  '269CC': 10,
  '269CD': 10,
  '269CE': 10,
  '269CF': 10,
  '269D0': 10,
  '269D1': 11,
  '269D2': 11,
  '269D3': 11,
  '269D4': 11,
  '269D5': 12,
  '269D6': 12,
  '269D7': 12,
  '269D8': 12,
  '269D9': 12,
  '269DA': 12,
  '269DB': 12,
  '269DC': 12,
  '269DD': 13,
  '269DE': 13,
  '269DF': 14,
  '269E0': 14,
  '269E1': 14,
  '269E2': 14,
  '269E3': 14,
  '269E4': 14,
  '269E5': 14,
  '269E6': 14,
  '269E7': 15,
  '269E8': 15,
  '269E9': 15,
  '269EA': 15,
  '269EB': 15,
  '269EC': 15,
  '269ED': 16,
  '269EE': 16,
  '269EF': 16,
  '269F0': 16,
  '269F1': 17,
  '269F2': 17,
  '269F3': 17,
  '269F4': 18,
  '269F5': 18,
  '269F6': 18,
  '269F7': 19,
  '269F8': 20,
  '269F9': 21,
  '269FA': 22,
  '269FB': 23,
  '269FC': 23,
  '269FD': 26,
  '269FE': 15,
  '269FF': 15,
  '26A00': 18,
  '26A01': 20,
  '26A02': 23,
  '26A03': 22,
  '26A04': 24,
  '26A05': 25,
  '26A06': 27,
  '26A07': 7,
  '26A08': 8,
  '26A09': 8,
  '26A0A': 8,
  '26A0B': 8,
  '26A0C': 8,
  '26A0D': 8,
  '26A0E': 9,
  '26A0F': 9,
  '26A10': 9,
  '26A11': 9,
  '26A12': 9,
  '26A13': 9,
  '26A14': 9,
  '26A15': 10,
  '26A16': 10,
  '26A17': 10,
  '26A18': 10,
  '26A19': 10,
  '26A1A': 10,
  '26A1B': 10,
  '26A1C': 10,
  '26A1D': 10,
  '26A1E': 10,
  '26A1F': 10,
  '26A20': 10,
  '26A21': 11,
  '26A22': 11,
  '26A23': 11,
  '26A24': 11,
  '26A25': 11,
  '26A26': 11,
  '26A27': 11,
  '26A28': 11,
  '26A29': 11,
  '26A2A': 11,
  '26A2B': 11,
  '26A2C': 11,
  '26A2D': 11,
  '26A2E': 11,
  '26A2F': 12,
  '26A30': 12,
  '26A31': 12,
  '26A32': 12,
  '26A33': 13,
  '26A34': 12,
  '26A35': 12,
  '26A36': 12,
  '26A37': 12,
  '26A38': 12,
  '26A39': 12,
  '26A3A': 12,
  '26A3B': 12,
  '26A3C': 12,
  '26A3D': 13,
  '26A3E': 13,
  '26A3F': 13,
  '26A40': 13,
  '26A41': 13,
  '26A42': 13,
  '26A43': 13,
  '26A44': 13,
  '26A45': 13,
  '26A46': 13,
  '26A47': 13,
  '26A48': 13,
  '26A49': 13,
  '26A4A': 14,
  '26A4B': 14,
  '26A4C': 14,
  '26A4D': 14,
  '26A4E': 14,
  '26A4F': 14,
  '26A50': 14,
  '26A51': 14,
  '26A52': 14,
  '26A53': 14,
  '26A54': 14,
  '26A55': 14,
  '26A56': 14,
  '26A57': 14,
  '26A58': 14,
  '26A59': 14,
  '26A5A': 14,
  '26A5B': 14,
  '26A5C': 14,
  '26A5D': 15,
  '26A5E': 15,
  '26A5F': 15,
  '26A60': 15,
  '26A61': 15,
  '26A62': 15,
  '26A63': 15,
  '26A64': 15,
  '26A65': 15,
  '26A66': 15,
  '26A67': 15,
  '26A68': 15,
  '26A69': 15,
  '26A6A': 15,
  '26A6B': 15,
  '26A6C': 15,
  '26A6D': 15,
  '26A6E': 15,
  '26A6F': 15,
  '26A70': 15,
  '26A71': 15,
  '26A72': 15,
  '26A73': 15,
  '26A74': 16,
  '26A75': 16,
  '26A76': 16,
  '26A77': 16,
  '26A78': 16,
  '26A79': 16,
  '26A7A': 16,
  '26A7B': 16,
  '26A7C': 16,
  '26A7D': 16,
  '26A7E': 16,
  '26A7F': 15,
  '26A80': 16,
  '26A81': 16,
  '26A82': 16,
  '26A83': 16,
  '26A84': 16,
  '26A85': 16,
  '26A86': 17,
  '26A87': 17,
  '26A88': 17,
  '26A89': 17,
  '26A8A': 17,
  '26A8B': 17,
  '26A8C': 17,
  '26A8D': 17,
  '26A8E': 17,
  '26A8F': 17,
  '26A90': 17,
  '26A91': 18,
  '26A92': 18,
  '26A93': 18,
  '26A94': 18,
  '26A95': 18,
  '26A96': 18,
  '26A97': 18,
  '26A98': 18,
  '26A99': 18,
  '26A9A': 18,
  '26A9B': 18,
  '26A9C': 18,
  '26A9D': 18,
  '26A9E': 18,
  '26A9F': 18,
  '26AA0': 18,
  '26AA1': 18,
  '26AA2': 18,
  '26AA3': 18,
  '26AA4': 18,
  '26AA5': 18,
  '26AA6': 18,
  '26AA7': 18,
  '26AA8': 19,
  '26AA9': 19,
  '26AAA': 19,
  '26AAB': 19,
  '26AAC': 19,
  '26AAD': 19,
  '26AAE': 19,
  '26AAF': 19,
  '26AB0': 19,
  '26AB1': 20,
  '26AB2': 20,
  '26AB3': 20,
  '26AB4': 20,
  '26AB5': 20,
  '26AB6': 21,
  '26AB7': 21,
  '26AB8': 21,
  '26AB9': 21,
  '26ABA': 21,
  '26ABB': 21,
  '26ABC': 21,
  '26ABD': 22,
  '26ABE': 22,
  '26ABF': 22,
  '26AC0': 22,
  '26AC1': 22,
  '26AC2': 22,
  '26AC3': 23,
  '26AC4': 23,
  '26AC5': 25,
  '26AC6': 25,
  '26AC7': 26,
  '26AC8': 27,
  '26AC9': 26,
  '26ACA': 30,
  '26ACB': 9,
  '26ACC': 11,
  '26ACD': 11,
  '26ACE': 12,
  '26ACF': 13,
  '26AD0': 15,
  '26AD1': 17,
  '26AD2': 20,
  '26AD3': 9,
  '26AD4': 11,
  '26AD5': 11,
  '26AD6': 11,
  '26AD7': 11,
  '26AD8': 11,
  '26AD9': 11,
  '26ADA': 12,
  '26ADB': 13,
  '26ADC': 13,
  '26ADD': 13,
  '26ADE': 14,
  '26ADF': 14,
  '26AE0': 14,
  '26AE1': 14,
  '26AE2': 14,
  '26AE3': 14,
  '26AE4': 15,
  '26AE5': 15,
  '26AE6': 15,
  '26AE7': 15,
  '26AE8': 15,
  '26AE9': 15,
  '26AEA': 16,
  '26AEB': 16,
  '26AEC': 16,
  '26AED': 16,
  '26AEE': 16,
  '26AEF': 20,
  '26AF0': 20,
  '26AF1': 20,
  '26AF2': 25,
  '26AF3': 5,
  '26AF4': 5,
  '26AF5': 5,
  '26AF6': 6,
  '26AF7': 6,
  '26AF8': 6,
  '26AF9': 6,
  '26AFA': 6,
  '26AFB': 6,
  '26AFC': 6,
  '26AFD': 6,
  '26AFE': 6,
  '26AFF': 6,
  '26B00': 6,
  '26B01': 7,
  '26B02': 7,
  '26B03': 7,
  '26B04': 7,
  '26B05': 7,
  '26B06': 7,
  '26B07': 7,
  '26B08': 7,
  '26B09': 7,
  '26B0A': 7,
  '26B0B': 7,
  '26B0C': 7,
  '26B0D': 7,
  '26B0E': 7,
  '26B0F': 7,
  '26B10': 7,
  '26B11': 7,
  '26B12': 7,
  '26B13': 8,
  '26B14': 8,
  '26B15': 8,
  '26B16': 8,
  '26B17': 8,
  '26B18': 8,
  '26B19': 8,
  '26B1A': 8,
  '26B1B': 8,
  '26B1C': 8,
  '26B1D': 8,
  '26B1E': 8,
  '26B1F': 8,
  '26B20': 8,
  '26B21': 8,
  '26B22': 8,
  '26B23': 8,
  '26B24': 8,
  '26B25': 8,
  '26B26': 8,
  '26B27': 8,
  '26B28': 8,
  '26B29': 8,
  '26B2A': 8,
  '26B2B': 8,
  '26B2C': 8,
  '26B2D': 8,
  '26B2E': 8,
  '26B2F': 8,
  '26B30': 8,
  '26B31': 8,
  '26B32': 8,
  '26B33': 8,
  '26B34': 8,
  '26B35': 8,
  '26B36': 8,
  '26B37': 9,
  '26B38': 9,
  '26B39': 9,
  '26B3A': 9,
  '26B3B': 9,
  '26B3C': 9,
  '26B3D': 9,
  '26B3E': 9,
  '26B3F': 9,
  '26B40': 9,
  '26B41': 9,
  '26B42': 9,
  '26B43': 9,
  '26B44': 9,
  '26B45': 9,
  '26B46': 9,
  '26B47': 9,
  '26B48': 9,
  '26B49': 9,
  '26B4A': 9,
  '26B4B': 9,
  '26B4C': 9,
  '26B4D': 9,
  '26B4E': 9,
  '26B4F': 9,
  '26B50': 9,
  '26B51': 9,
  '26B52': 9,
  '26B53': 9,
  '26B54': 9,
  '26B55': 9,
  '26B56': 9,
  '26B57': 9,
  '26B58': 9,
  '26B59': 9,
  '26B5A': 9,
  '26B5B': 9,
  '26B5C': 9,
  '26B5D': 9,
  '26B5E': 9,
  '26B5F': 9,
  '26B60': 9,
  '26B61': 9,
  '26B62': 9,
  '26B63': 9,
  '26B64': 9,
  '26B65': 9,
  '26B66': 9,
  '26B67': 9,
  '26B68': 9,
  '26B69': 9,
  '26B6A': 9,
  '26B6B': 9,
  '26B6C': 9,
  '26B6D': 10,
  '26B6E': 10,
  '26B6F': 10,
  '26B70': 10,
  '26B71': 10,
  '26B72': 10,
  '26B73': 10,
  '26B74': 10,
  '26B75': 10,
  '26B76': 10,
  '26B77': 10,
  '26B78': 10,
  '26B79': 10,
  '26B7A': 10,
  '26B7B': 10,
  '26B7C': 10,
  '26B7D': 10,
  '26B7E': 10,
  '26B7F': 10,
  '26B80': 10,
  '26B81': 10,
  '26B82': 10,
  '26B83': 10,
  '26B84': 10,
  '26B85': 10,
  '26B86': 10,
  '26B87': 10,
  '26B88': 10,
  '26B89': 10,
  '26B8A': 10,
  '26B8B': 10,
  '26B8C': 10,
  '26B8D': 10,
  '26B8E': 10,
  '26B8F': 10,
  '26B90': 10,
  '26B91': 10,
  '26B92': 10,
  '26B93': 10,
  '26B94': 10,
  '26B95': 10,
  '26B96': 10,
  '26B97': 10,
  '26B98': 10,
  '26B99': 10,
  '26B9A': 10,
  '26B9B': 10,
  '26B9C': 10,
  '26B9D': 10,
  '26B9E': 10,
  '26B9F': 10,
  '26BA0': 10,
  '26BA1': 10,
  '26BA2': 10,
  '26BA3': 10,
  '26BA4': 10,
  '26BA5': 10,
  '26BA6': 10,
  '26BA7': 10,
  '26BA8': 10,
  '26BA9': 10,
  '26BAA': 10,
  '26BAB': 10,
  '26BAC': 10,
  '26BAD': 10,
  '26BAE': 10,
  '26BAF': 10,
  '26BB0': 10,
  '26BB1': 10,
  '26BB2': 10,
  '26BB3': 10,
  '26BB4': 10,
  '26BB5': 10,
  '26BB6': 11,
  '26BB7': 11,
  '26BB8': 11,
  '26BB9': 11,
  '26BBA': 11,
  '26BBB': 11,
  '26BBC': 11,
  '26BBD': 11,
  '26BBE': 11,
  '26BBF': 11,
  '26BC0': 11,
  '26BC1': 11,
  '26BC2': 11,
  '26BC3': 11,
  '26BC4': 11,
  '26BC5': 11,
  '26BC6': 11,
  '26BC7': 11,
  '26BC8': 11,
  '26BC9': 11,
  '26BCA': 11,
  '26BCB': 11,
  '26BCC': 11,
  '26BCD': 11,
  '26BCE': 11,
  '26BCF': 11,
  '26BD0': 11,
  '26BD1': 11,
  '26BD2': 11,
  '26BD3': 11,
  '26BD4': 11,
  '26BD5': 11,
  '26BD6': 11,
  '26BD7': 11,
  '26BD8': 11,
  '26BD9': 11,
  '26BDA': 11,
  '26BDB': 11,
  '26BDC': 11,
  '26BDD': 11,
  '26BDE': 11,
  '26BDF': 11,
  '26BE0': 11,
  '26BE1': 11,
  '26BE2': 11,
  '26BE3': 11,
  '26BE4': 11,
  '26BE5': 11,
  '26BE6': 11,
  '26BE7': 11,
  '26BE8': 11,
  '26BE9': 11,
  '26BEA': 11,
  '26BEB': 11,
  '26BEC': 11,
  '26BED': 11,
  '26BEE': 11,
  '26BEF': 11,
  '26BF0': 11,
  '26BF1': 12,
  '26BF2': 11,
  '26BF3': 11,
  '26BF4': 11,
  '26BF5': 11,
  '26BF6': 11,
  '26BF7': 11,
  '26BF8': 11,
  '26BF9': 11,
  '26BFA': 11,
  '26BFB': 11,
  '26BFC': 11,
  '26BFD': 11,
  '26BFE': 11,
  '26BFF': 11,
  '26C00': 11,
  '26C01': 11,
  '26C02': 11,
  '26C03': 11,
  '26C04': 11,
  '26C05': 11,
  '26C06': 11,
  '26C07': 11,
  '26C08': 11,
  '26C09': 11,
  '26C0A': 11,
  '26C0B': 11,
  '26C0C': 11,
  '26C0D': 11,
  '26C0E': 11,
  '26C0F': 11,
  '26C10': 11,
  '26C11': 11,
  '26C12': 11,
  '26C13': 11,
  '26C14': 11,
  '26C15': 11,
  '26C16': 12,
  '26C17': 11,
  '26C18': 11,
  '26C19': 11,
  '26C1A': 11,
  '26C1B': 11,
  '26C1C': 11,
  '26C1D': 11,
  '26C1E': 11,
  '26C1F': 11,
  '26C20': 11,
  '26C21': 11,
  '26C22': 11,
  '26C23': 11,
  '26C24': 11,
  '26C25': 11,
  '26C26': 11,
  '26C27': 11,
  '26C28': 11,
  '26C29': 11,
  '26C2A': 12,
  '26C2B': 12,
  '26C2C': 12,
  '26C2D': 12,
  '26C2E': 12,
  '26C2F': 12,
  '26C30': 12,
  '26C31': 12,
  '26C32': 12,
  '26C33': 12,
  '26C34': 12,
  '26C35': 12,
  '26C36': 12,
  '26C37': 12,
  '26C38': 12,
  '26C39': 12,
  '26C3A': 12,
  '26C3B': 12,
  '26C3C': 12,
  '26C3D': 12,
  '26C3E': 12,
  '26C3F': 12,
  '26C40': 12,
  '26C41': 12,
  '26C42': 12,
  '26C43': 12,
  '26C44': 12,
  '26C45': 12,
  '26C46': 12,
  '26C47': 12,
  '26C48': 12,
  '26C49': 12,
  '26C4A': 12,
  '26C4B': 12,
  '26C4C': 12,
  '26C4D': 12,
  '26C4E': 12,
  '26C4F': 12,
  '26C50': 12,
  '26C51': 12,
  '26C52': 12,
  '26C53': 12,
  '26C54': 12,
  '26C55': 12,
  '26C56': 12,
  '26C57': 12,
  '26C58': 12,
  '26C59': 12,
  '26C5A': 12,
  '26C5B': 12,
  '26C5C': 12,
  '26C5D': 12,
  '26C5E': 12,
  '26C5F': 12,
  '26C60': 12,
  '26C61': 12,
  '26C62': 12,
  '26C63': 12,
  '26C64': 12,
  '26C65': 12,
  '26C66': 12,
  '26C67': 12,
  '26C68': 12,
  '26C69': 12,
  '26C6A': 12,
  '26C6B': 12,
  '26C6C': 12,
  '26C6D': 12,
  '26C6E': 12,
  '26C6F': 12,
  '26C70': 12,
  '26C71': 12,
  '26C72': 12,
  '26C73': 12,
  '26C74': 12,
  '26C75': 12,
  '26C76': 12,
  '26C77': 12,
  '26C78': 12,
  '26C79': 12,
  '26C7A': 12,
  '26C7B': 12,
  '26C7C': 12,
  '26C7D': 12,
  '26C7E': 12,
  '26C7F': 12,
  '26C80': 12,
  '26C81': 12,
  '26C82': 12,
  '26C83': 12,
  '26C84': 12,
  '26C85': 12,
  '26C86': 12,
  '26C87': 12,
  '26C88': 12,
  '26C89': 12,
  '26C8A': 12,
  '26C8B': 12,
  '26C8C': 12,
  '26C8D': 12,
  '26C8E': 12,
  '26C8F': 12,
  '26C90': 12,
  '26C91': 12,
  '26C92': 12,
  '26C93': 12,
  '26C94': 12,
  '26C95': 12,
  '26C96': 12,
  '26C97': 12,
  '26C98': 12,
  '26C99': 12,
  '26C9A': 12,
  '26C9B': 12,
  '26C9C': 12,
  '26C9D': 12,
  '26C9E': 12,
  '26C9F': 12,
  '26CA0': 12,
  '26CA1': 12,
  '26CA2': 12,
  '26CA3': 12,
  '26CA4': 12,
  '26CA5': 12,
  '26CA6': 12,
  '26CA7': 12,
  '26CA8': 12,
  '26CA9': 12,
  '26CAA': 12,
  '26CAB': 12,
  '26CAC': 12,
  '26CAD': 12,
  '26CAE': 12,
  '26CAF': 12,
  '26CB0': 12,
  '26CB1': 12,
  '26CB2': 12,
  '26CB3': 12,
  '26CB4': 12,
  '26CB5': 12,
  '26CB6': 12,
  '26CB7': 12,
  '26CB8': 12,
  '26CB9': 12,
  '26CBA': 12,
  '26CBB': 12,
  '26CBC': 12,
  '26CBD': 12,
  '26CBE': 12,
  '26CBF': 12,
  '26CC0': 12,
  '26CC1': 13,
  '26CC2': 13,
  '26CC3': 13,
  '26CC4': 13,
  '26CC5': 13,
  '26CC6': 13,
  '26CC7': 13,
  '26CC8': 13,
  '26CC9': 13,
  '26CCA': 13,
  '26CCB': 13,
  '26CCC': 13,
  '26CCD': 13,
  '26CCE': 13,
  '26CCF': 13,
  '26CD0': 13,
  '26CD1': 13,
  '26CD2': 13,
  '26CD3': 13,
  '26CD4': 13,
  '26CD5': 13,
  '26CD6': 13,
  '26CD7': 13,
  '26CD8': 13,
  '26CD9': 13,
  '26CDA': 13,
  '26CDB': 13,
  '26CDC': 13,
  '26CDD': 13,
  '26CDE': 13,
  '26CDF': 13,
  '26CE0': 13,
  '26CE1': 13,
  '26CE2': 13,
  '26CE3': 13,
  '26CE4': 13,
  '26CE5': 13,
  '26CE6': 13,
  '26CE7': 13,
  '26CE8': 13,
  '26CE9': 13,
  '26CEA': 13,
  '26CEB': 13,
  '26CEC': 13,
  '26CED': 13,
  '26CEE': 13,
  '26CEF': 13,
  '26CF0': 13,
  '26CF1': 13,
  '26CF2': 13,
  '26CF3': 13,
  '26CF4': 13,
  '26CF5': 13,
  '26CF6': 13,
  '26CF7': 13,
  '26CF8': 13,
  '26CF9': 13,
  '26CFA': 13,
  '26CFB': 13,
  '26CFC': 13,
  '26CFD': 13,
  '26CFE': 13,
  '26CFF': 13,
  '26D00': 13,
  '26D01': 13,
  '26D02': 13,
  '26D03': 13,
  '26D04': 13,
  '26D05': 13,
  '26D06': 13,
  '26D07': 13,
  '26D08': 13,
  '26D09': 13,
  '26D0A': 13,
  '26D0B': 13,
  '26D0C': 13,
  '26D0D': 13,
  '26D0E': 13,
  '26D0F': 13,
  '26D10': 13,
  '26D11': 13,
  '26D12': 13,
  '26D13': 13,
  '26D14': 13,
  '26D15': 13,
  '26D16': 13,
  '26D17': 13,
  '26D18': 13,
  '26D19': 13,
  '26D1A': 13,
  '26D1B': 13,
  '26D1C': 13,
  '26D1D': 13,
  '26D1E': 13,
  '26D1F': 13,
  '26D20': 13,
  '26D21': 13,
  '26D22': 13,
  '26D23': 13,
  '26D24': 13,
  '26D25': 13,
  '26D26': 13,
  '26D27': 13,
  '26D28': 13,
  '26D29': 13,
  '26D2A': 13,
  '26D2B': 13,
  '26D2C': 13,
  '26D2D': 13,
  '26D2E': 13,
  '26D2F': 13,
  '26D30': 13,
  '26D31': 13,
  '26D32': 13,
  '26D33': 13,
  '26D34': 13,
  '26D35': 13,
  '26D36': 13,
  '26D37': 13,
  '26D38': 13,
  '26D39': 13,
  '26D3A': 13,
  '26D3B': 13,
  '26D3C': 13,
  '26D3D': 13,
  '26D3E': 13,
  '26D3F': 13,
  '26D40': 13,
  '26D41': 13,
  '26D42': 13,
  '26D43': 13,
  '26D44': 13,
  '26D45': 13,
  '26D46': 13,
  '26D47': 14,
  '26D48': 13,
  '26D49': 13,
  '26D4A': 13,
  '26D4B': 13,
  '26D4C': 13,
  '26D4D': 13,
  '26D4E': 13,
  '26D4F': 13,
  '26D50': 13,
  '26D51': 13,
  '26D52': 13,
  '26D53': 13,
  '26D54': 13,
  '26D55': 13,
  '26D56': 13,
  '26D57': 13,
  '26D58': 13,
  '26D59': 13,
  '26D5A': 13,
  '26D5B': 13,
  '26D5C': 13,
  '26D5D': 13,
  '26D5E': 13,
  '26D5F': 13,
  '26D60': 13,
  '26D61': 14,
  '26D62': 14,
  '26D63': 14,
  '26D64': 14,
  '26D65': 14,
  '26D66': 14,
  '26D67': 14,
  '26D68': 14,
  '26D69': 14,
  '26D6A': 14,
  '26D6B': 14,
  '26D6C': 14,
  '26D6D': 14,
  '26D6E': 14,
  '26D6F': 14,
  '26D70': 14,
  '26D71': 14,
  '26D72': 14,
  '26D73': 14,
  '26D74': 14,
  '26D75': 14,
  '26D76': 14,
  '26D77': 14,
  '26D78': 14,
  '26D79': 14,
  '26D7A': 14,
  '26D7B': 14,
  '26D7C': 14,
  '26D7D': 14,
  '26D7E': 14,
  '26D7F': 14,
  '26D80': 14,
  '26D81': 14,
  '26D82': 14,
  '26D83': 14,
  '26D84': 14,
  '26D85': 14,
  '26D86': 14,
  '26D87': 14,
  '26D88': 14,
  '26D89': 14,
  '26D8A': 14,
  '26D8B': 14,
  '26D8C': 14,
  '26D8D': 14,
  '26D8E': 14,
  '26D8F': 14,
  '26D90': 14,
  '26D91': 14,
  '26D92': 14,
  '26D93': 14,
  '26D94': 14,
  '26D95': 14,
  '26D96': 14,
  '26D97': 14,
  '26D98': 14,
  '26D99': 14,
  '26D9A': 14,
  '26D9B': 14,
  '26D9C': 14,
  '26D9D': 14,
  '26D9E': 14,
  '26D9F': 14,
  '26DA0': 14,
  '26DA1': 14,
  '26DA2': 14,
  '26DA3': 14,
  '26DA4': 14,
  '26DA5': 14,
  '26DA6': 14,
  '26DA7': 14,
  '26DA8': 14,
  '26DA9': 14,
  '26DAA': 14,
  '26DAB': 14,
  '26DAC': 14,
  '26DAD': 14,
  '26DAE': 14,
  '26DAF': 14,
  '26DB0': 14,
  '26DB1': 14,
  '26DB2': 14,
  '26DB3': 14,
  '26DB4': 14,
  '26DB5': 14,
  '26DB6': 14,
  '26DB7': 14,
  '26DB8': 14,
  '26DB9': 14,
  '26DBA': 14,
  '26DBB': 14,
  '26DBC': 14,
  '26DBD': 14,
  '26DBE': 14,
  '26DBF': 14,
  '26DC0': 14,
  '26DC1': 14,
  '26DC2': 14,
  '26DC3': 14,
  '26DC4': 14,
  '26DC5': 14,
  '26DC6': 14,
  '26DC7': 14,
  '26DC8': 14,
  '26DC9': 14,
  '26DCA': 14,
  '26DCB': 14,
  '26DCC': 14,
  '26DCD': 14,
  '26DCE': 14,
  '26DCF': 14,
  '26DD0': 14,
  '26DD1': 14,
  '26DD2': 14,
  '26DD3': 14,
  '26DD4': 14,
  '26DD5': 14,
  '26DD6': 14,
  '26DD7': 14,
  '26DD8': 14,
  '26DD9': 14,
  '26DDA': 14,
  '26DDB': 14,
  '26DDC': 14,
  '26DDD': 14,
  '26DDE': 14,
  '26DDF': 14,
  '26DE0': 14,
  '26DE1': 14,
  '26DE2': 14,
  '26DE3': 14,
  '26DE4': 14,
  '26DE5': 14,
  '26DE6': 14,
  '26DE7': 14,
  '26DE8': 14,
  '26DE9': 14,
  '26DEA': 14,
  '26DEB': 14,
  '26DEC': 14,
  '26DED': 14,
  '26DEE': 14,
  '26DEF': 14,
  '26DF0': 14,
  '26DF1': 14,
  '26DF2': 14,
  '26DF3': 14,
  '26DF4': 14,
  '26DF5': 14,
  '26DF6': 14,
  '26DF7': 14,
  '26DF8': 14,
  '26DF9': 14,
  '26DFA': 14,
  '26DFB': 14,
  '26DFC': 14,
  '26DFD': 14,
  '26DFE': 14,
  '26DFF': 15,
  '26E00': 15,
  '26E01': 15,
  '26E02': 15,
  '26E03': 15,
  '26E04': 15,
  '26E05': 15,
  '26E06': 15,
  '26E07': 15,
  '26E08': 15,
  '26E09': 15,
  '26E0A': 15,
  '26E0B': 15,
  '26E0C': 15,
  '26E0D': 15,
  '26E0E': 15,
  '26E0F': 15,
  '26E10': 15,
  '26E11': 15,
  '26E12': 15,
  '26E13': 15,
  '26E14': 15,
  '26E15': 15,
  '26E16': 15,
  '26E17': 15,
  '26E18': 15,
  '26E19': 15,
  '26E1A': 15,
  '26E1B': 15,
  '26E1C': 15,
  '26E1D': 15,
  '26E1E': 15,
  '26E1F': 15,
  '26E20': 15,
  '26E21': 15,
  '26E22': 15,
  '26E23': 15,
  '26E24': 15,
  '26E25': 15,
  '26E26': 15,
  '26E27': 15,
  '26E28': 15,
  '26E29': 15,
  '26E2A': 15,
  '26E2B': 15,
  '26E2C': 15,
  '26E2D': 15,
  '26E2E': 15,
  '26E2F': 15,
  '26E30': 15,
  '26E31': 15,
  '26E32': 15,
  '26E33': 15,
  '26E34': 15,
  '26E35': 15,
  '26E36': 15,
  '26E37': 15,
  '26E38': 15,
  '26E39': 15,
  '26E3A': 15,
  '26E3B': 15,
  '26E3C': 15,
  '26E3D': 16,
  '26E3E': 15,
  '26E3F': 15,
  '26E40': 15,
  '26E41': 15,
  '26E42': 15,
  '26E43': 15,
  '26E44': 15,
  '26E45': 15,
  '26E46': 15,
  '26E47': 15,
  '26E48': 15,
  '26E49': 15,
  '26E4A': 15,
  '26E4B': 15,
  '26E4C': 15,
  '26E4D': 15,
  '26E4E': 15,
  '26E4F': 15,
  '26E50': 15,
  '26E51': 15,
  '26E52': 15,
  '26E53': 15,
  '26E54': 15,
  '26E55': 15,
  '26E56': 15,
  '26E57': 15,
  '26E58': 15,
  '26E59': 15,
  '26E5A': 15,
  '26E5B': 15,
  '26E5C': 15,
  '26E5D': 15,
  '26E5E': 15,
  '26E5F': 15,
  '26E60': 15,
  '26E61': 15,
  '26E62': 15,
  '26E63': 15,
  '26E64': 15,
  '26E65': 15,
  '26E66': 15,
  '26E67': 15,
  '26E68': 15,
  '26E69': 15,
  '26E6A': 15,
  '26E6B': 15,
  '26E6C': 15,
  '26E6D': 15,
  '26E6E': 15,
  '26E6F': 15,
  '26E70': 15,
  '26E71': 15,
  '26E72': 15,
  '26E73': 15,
  '26E74': 15,
  '26E75': 15,
  '26E76': 15,
  '26E77': 15,
  '26E78': 15,
  '26E79': 15,
  '26E7A': 15,
  '26E7B': 15,
  '26E7C': 15,
  '26E7D': 15,
  '26E7E': 15,
  '26E7F': 15,
  '26E80': 15,
  '26E81': 15,
  '26E82': 15,
  '26E83': 15,
  '26E84': 15,
  '26E85': 30,
  '26E86': 16,
  '26E87': 16,
  '26E88': 16,
  '26E89': 16,
  '26E8A': 16,
  '26E8B': 16,
  '26E8C': 16,
  '26E8D': 16,
  '26E8E': 16,
  '26E8F': 16,
  '26E90': 16,
  '26E91': 16,
  '26E92': 16,
  '26E93': 16,
  '26E94': 16,
  '26E95': 16,
  '26E96': 16,
  '26E97': 16,
  '26E98': 16,
  '26E99': 16,
  '26E9A': 16,
  '26E9B': 16,
  '26E9C': 16,
  '26E9D': 16,
  '26E9E': 16,
  '26E9F': 16,
  '26EA0': 16,
  '26EA1': 16,
  '26EA2': 16,
  '26EA3': 16,
  '26EA4': 16,
  '26EA5': 16,
  '26EA6': 16,
  '26EA7': 16,
  '26EA8': 16,
  '26EA9': 16,
  '26EAA': 16,
  '26EAB': 16,
  '26EAC': 16,
  '26EAD': 16,
  '26EAE': 16,
  '26EAF': 16,
  '26EB0': 16,
  '26EB1': 16,
  '26EB2': 16,
  '26EB3': 16,
  '26EB4': 16,
  '26EB5': 16,
  '26EB6': 16,
  '26EB7': 16,
  '26EB8': 16,
  '26EB9': 16,
  '26EBA': 16,
  '26EBB': 16,
  '26EBC': 16,
  '26EBD': 16,
  '26EBE': 16,
  '26EBF': 16,
  '26EC0': 16,
  '26EC1': 16,
  '26EC2': 16,
  '26EC3': 16,
  '26EC4': 16,
  '26EC5': 16,
  '26EC6': 16,
  '26EC7': 18,
  '26EC8': 16,
  '26EC9': 16,
  '26ECA': 16,
  '26ECB': 16,
  '26ECC': 16,
  '26ECD': 16,
  '26ECE': 16,
  '26ECF': 16,
  '26ED0': 16,
  '26ED1': 16,
  '26ED2': 16,
  '26ED3': 16,
  '26ED4': 16,
  '26ED5': 16,
  '26ED6': 16,
  '26ED7': 16,
  '26ED8': 16,
  '26ED9': 16,
  '26EDA': 16,
  '26EDB': 16,
  '26EDC': 16,
  '26EDD': 16,
  '26EDE': 16,
  '26EDF': 16,
  '26EE0': 16,
  '26EE1': 16,
  '26EE2': 16,
  '26EE3': 16,
  '26EE4': 16,
  '26EE5': 16,
  '26EE6': 16,
  '26EE7': 16,
  '26EE8': 16,
  '26EE9': 16,
  '26EEA': 16,
  '26EEB': 16,
  '26EEC': 16,
  '26EED': 16,
  '26EEE': 16,
  '26EEF': 16,
  '26EF0': 16,
  '26EF1': 16,
  '26EF2': 16,
  '26EF3': 16,
  '26EF4': 16,
  '26EF5': 16,
  '26EF6': 16,
  '26EF7': 16,
  '26EF8': 16,
  '26EF9': 16,
  '26EFA': 16,
  '26EFB': 16,
  '26EFC': 16,
  '26EFD': 16,
  '26EFE': 17,
  '26EFF': 16,
  '26F00': 16,
  '26F01': 16,
  '26F02': 16,
  '26F03': 16,
  '26F04': 16,
  '26F05': 16,
  '26F06': 16,
  '26F07': 16,
  '26F08': 16,
  '26F09': 16,
  '26F0A': 16,
  '26F0B': 16,
  '26F0C': 16,
  '26F0D': 16,
  '26F0E': 16,
  '26F0F': 16,
  '26F10': 16,
  '26F11': 16,
  '26F12': 16,
  '26F13': 16,
  '26F14': 16,
  '26F15': 16,
  '26F16': 16,
  '26F17': 16,
  '26F18': 16,
  '26F19': 16,
  '26F1A': 16,
  '26F1B': 16,
  '26F1C': 16,
  '26F1D': 16,
  '26F1E': 16,
  '26F1F': 16,
  '26F20': 16,
  '26F21': 16,
  '26F22': 16,
  '26F23': 16,
  '26F24': 16,
  '26F25': 16,
  '26F26': 16,
  '26F27': 16,
  '26F28': 16,
  '26F29': 17,
  '26F2A': 17,
  '26F2B': 17,
  '26F2C': 17,
  '26F2D': 17,
  '26F2E': 17,
  '26F2F': 17,
  '26F30': 17,
  '26F31': 17,
  '26F32': 17,
  '26F33': 17,
  '26F34': 17,
  '26F35': 17,
  '26F36': 17,
  '26F37': 17,
  '26F38': 17,
  '26F39': 17,
  '26F3A': 17,
  '26F3B': 17,
  '26F3C': 17,
  '26F3D': 17,
  '26F3E': 17,
  '26F3F': 17,
  '26F40': 17,
  '26F41': 17,
  '26F42': 17,
  '26F43': 17,
  '26F44': 17,
  '26F45': 17,
  '26F46': 17,
  '26F47': 17,
  '26F48': 17,
  '26F49': 17,
  '26F4A': 17,
  '26F4B': 17,
  '26F4C': 17,
  '26F4D': 17,
  '26F4E': 17,
  '26F4F': 17,
  '26F50': 17,
  '26F51': 17,
  '26F52': 17,
  '26F53': 17,
  '26F54': 17,
  '26F55': 17,
  '26F56': 17,
  '26F57': 17,
  '26F58': 17,
  '26F59': 17,
  '26F5A': 17,
  '26F5B': 17,
  '26F5C': 17,
  '26F5D': 17,
  '26F5E': 17,
  '26F5F': 17,
  '26F60': 17,
  '26F61': 17,
  '26F62': 17,
  '26F63': 17,
  '26F64': 17,
  '26F65': 17,
  '26F66': 17,
  '26F67': 17,
  '26F68': 17,
  '26F69': 17,
  '26F6A': 17,
  '26F6B': 17,
  '26F6C': 17,
  '26F6D': 16,
  '26F6E': 17,
  '26F6F': 17,
  '26F70': 17,
  '26F71': 17,
  '26F72': 17,
  '26F73': 17,
  '26F74': 17,
  '26F75': 17,
  '26F76': 17,
  '26F77': 17,
  '26F78': 17,
  '26F79': 17,
  '26F7A': 17,
  '26F7B': 17,
  '26F7C': 17,
  '26F7D': 17,
  '26F7E': 17,
  '26F7F': 17,
  '26F80': 17,
  '26F81': 17,
  '26F82': 17,
  '26F83': 17,
  '26F84': 17,
  '26F85': 17,
  '26F86': 17,
  '26F87': 17,
  '26F88': 17,
  '26F89': 17,
  '26F8A': 17,
  '26F8B': 17,
  '26F8C': 17,
  '26F8D': 17,
  '26F8E': 17,
  '26F8F': 17,
  '26F90': 17,
  '26F91': 17,
  '26F92': 17,
  '26F93': 17,
  '26F94': 17,
  '26F95': 17,
  '26F96': 17,
  '26F97': 17,
  '26F98': 17,
  '26F99': 17,
  '26F9A': 17,
  '26F9B': 17,
  '26F9C': 17,
  '26F9D': 17,
  '26F9E': 17,
  '26F9F': 17,
  '26FA0': 17,
  '26FA1': 17,
  '26FA2': 17,
  '26FA3': 17,
  '26FA4': 17,
  '26FA5': 17,
  '26FA6': 17,
  '26FA7': 17,
  '26FA8': 17,
  '26FA9': 17,
  '26FAA': 18,
  '26FAB': 18,
  '26FAC': 18,
  '26FAD': 18,
  '26FAE': 18,
  '26FAF': 18,
  '26FB0': 18,
  '26FB1': 18,
  '26FB2': 18,
  '26FB3': 18,
  '26FB4': 18,
  '26FB5': 18,
  '26FB6': 18,
  '26FB7': 18,
  '26FB8': 18,
  '26FB9': 18,
  '26FBA': 18,
  '26FBB': 18,
  '26FBC': 18,
  '26FBD': 18,
  '26FBE': 18,
  '26FBF': 18,
  '26FC0': 18,
  '26FC1': 18,
  '26FC2': 18,
  '26FC3': 18,
  '26FC4': 18,
  '26FC5': 18,
  '26FC6': 18,
  '26FC7': 18,
  '26FC8': 18,
  '26FC9': 18,
  '26FCA': 18,
  '26FCB': 18,
  '26FCC': 18,
  '26FCD': 18,
  '26FCE': 18,
  '26FCF': 18,
  '26FD0': 18,
  '26FD1': 18,
  '26FD2': 18,
  '26FD3': 18,
  '26FD4': 19,
  '26FD5': 19,
  '26FD6': 18,
  '26FD7': 18,
  '26FD8': 18,
  '26FD9': 18,
  '26FDA': 18,
  '26FDB': 18,
  '26FDC': 18,
  '26FDD': 19,
  '26FDE': 18,
  '26FDF': 18,
  '26FE0': 18,
  '26FE1': 17,
  '26FE2': 18,
  '26FE3': 18,
  '26FE4': 18,
  '26FE5': 18,
  '26FE6': 18,
  '26FE7': 18,
  '26FE8': 18,
  '26FE9': 18,
  '26FEA': 18,
  '26FEB': 18,
  '26FEC': 18,
  '26FED': 18,
  '26FEE': 18,
  '26FEF': 18,
  '26FF0': 18,
  '26FF1': 18,
  '26FF2': 18,
  '26FF3': 18,
  '26FF4': 18,
  '26FF5': 18,
  '26FF6': 18,
  '26FF7': 18,
  '26FF8': 17,
  '26FF9': 18,
  '26FFA': 18,
  '26FFB': 18,
  '26FFC': 18,
  '26FFD': 18,
  '26FFE': 18,
  '26FFF': 18,
  '27000': 18,
  '27001': 18,
  '27002': 18,
  '27003': 18,
  '27004': 18,
  '27005': 18,
  '27006': 18,
  '27007': 18,
  '27008': 18,
  '27009': 18,
  '2700A': 18,
  '2700B': 18,
  '2700C': 18,
  '2700D': 18,
  '2700E': 18,
  '2700F': 18,
  '27010': 18,
  '27011': 18,
  '27012': 18,
  '27013': 18,
  '27014': 18,
  '27015': 18,
  '27016': 18,
  '27017': 18,
  '27018': 18,
  '27019': 18,
  '2701A': 18,
  '2701B': 18,
  '2701C': 18,
  '2701D': 18,
  '2701E': 18,
  '2701F': 18,
  '27020': 19,
  '27021': 19,
  '27022': 19,
  '27023': 19,
  '27024': 19,
  '27025': 19,
  '27026': 19,
  '27027': 19,
  '27028': 19,
  '27029': 19,
  '2702A': 19,
  '2702B': 19,
  '2702C': 19,
  '2702D': 19,
  '2702E': 19,
  '2702F': 19,
  '27030': 19,
  '27031': 19,
  '27032': 19,
  '27033': 19,
  '27034': 19,
  '27035': 19,
  '27036': 19,
  '27037': 19,
  '27038': 19,
  '27039': 19,
  '2703A': 19,
  '2703B': 19,
  '2703C': 19,
  '2703D': 19,
  '2703E': 19,
  '2703F': 19,
  '27040': 19,
  '27041': 19,
  '27042': 19,
  '27043': 19,
  '27044': 19,
  '27045': 19,
  '27046': 19,
  '27047': 19,
  '27048': 19,
  '27049': 19,
  '2704A': 19,
  '2704B': 19,
  '2704C': 19,
  '2704D': 19,
  '2704E': 19,
  '2704F': 19,
  '27050': 19,
  '27051': 19,
  '27052': 19,
  '27053': 19,
  '27054': 19,
  '27055': 19,
  '27056': 19,
  '27057': 19,
  '27058': 19,
  '27059': 19,
  '2705A': 19,
  '2705B': 19,
  '2705C': 19,
  '2705D': 19,
  '2705E': 19,
  '2705F': 19,
  '27060': 19,
  '27061': 19,
  '27062': 19,
  '27063': 19,
  '27064': 19,
  '27065': 19,
  '27066': 19,
  '27067': 19,
  '27068': 19,
  '27069': 19,
  '2706A': 19,
  '2706B': 19,
  '2706C': 19,
  '2706D': 19,
  '2706E': 19,
  '2706F': 19,
  '27070': 19,
  '27071': 19,
  '27072': 19,
  '27073': 19,
  '27074': 19,
  '27075': 19,
  '27076': 19,
  '27077': 19,
  '27078': 19,
  '27079': 19,
  '2707A': 19,
  '2707B': 20,
  '2707C': 20,
  '2707D': 20,
  '2707E': 20,
  '2707F': 20,
  '27080': 20,
  '27081': 20,
  '27082': 20,
  '27083': 20,
  '27084': 20,
  '27085': 20,
  '27086': 20,
  '27087': 20,
  '27088': 20,
  '27089': 20,
  '2708A': 20,
  '2708B': 20,
  '2708C': 20,
  '2708D': 20,
  '2708E': 20,
  '2708F': 20,
  '27090': 20,
  '27091': 20,
  '27092': 20,
  '27093': 20,
  '27094': 20,
  '27095': 20,
  '27096': 20,
  '27097': 20,
  '27098': 20,
  '27099': 20,
  '2709A': 20,
  '2709B': 20,
  '2709C': 20,
  '2709D': 20,
  '2709E': 20,
  '2709F': 20,
  '270A0': 20,
  '270A1': 20,
  '270A2': 20,
  '270A3': 20,
  '270A4': 20,
  '270A5': 20,
  '270A6': 20,
  '270A7': 20,
  '270A8': 20,
  '270A9': 20,
  '270AA': 20,
  '270AB': 20,
  '270AC': 20,
  '270AD': 20,
  '270AE': 20,
  '270AF': 20,
  '270B0': 20,
  '270B1': 20,
  '270B2': 20,
  '270B3': 20,
  '270B4': 20,
  '270B5': 20,
  '270B6': 20,
  '270B7': 20,
  '270B8': 20,
  '270B9': 20,
  '270BA': 20,
  '270BB': 20,
  '270BC': 20,
  '270BD': 20,
  '270BE': 20,
  '270BF': 20,
  '270C0': 20,
  '270C1': 20,
  '270C2': 20,
  '270C3': 20,
  '270C4': 20,
  '270C5': 20,
  '270C6': 20,
  '270C7': 20,
  '270C8': 20,
  '270C9': 20,
  '270CA': 20,
  '270CB': 20,
  '270CC': 19,
  '270CD': 21,
  '270CE': 20,
  '270CF': 21,
  '270D0': 21,
  '270D1': 21,
  '270D2': 21,
  '270D3': 21,
  '270D4': 21,
  '270D5': 21,
  '270D6': 21,
  '270D7': 21,
  '270D8': 21,
  '270D9': 21,
  '270DA': 21,
  '270DB': 21,
  '270DC': 21,
  '270DD': 21,
  '270DE': 21,
  '270DF': 21,
  '270E0': 21,
  '270E1': 21,
  '270E2': 21,
  '270E3': 21,
  '270E4': 21,
  '270E5': 21,
  '270E6': 21,
  '270E7': 21,
  '270E8': 21,
  '270E9': 21,
  '270EA': 21,
  '270EB': 21,
  '270EC': 21,
  '270ED': 21,
  '270EE': 21,
  '270EF': 21,
  '270F0': 21,
  '270F1': 21,
  '270F2': 21,
  '270F3': 21,
  '270F4': 21,
  '270F5': 21,
  '270F6': 21,
  '270F7': 21,
  '270F8': 21,
  '270F9': 21,
  '270FA': 21,
  '270FB': 21,
  '270FC': 21,
  '270FD': 21,
  '270FE': 21,
  '270FF': 21,
  '27100': 21,
  '27101': 21,
  '27102': 21,
  '27103': 21,
  '27104': 21,
  '27105': 21,
  '27106': 21,
  '27107': 21,
  '27108': 21,
  '27109': 21,
  '2710A': 21,
  '2710B': 21,
  '2710C': 21,
  '2710D': 22,
  '2710E': 22,
  '2710F': 22,
  '27110': 22,
  '27111': 22,
  '27112': 22,
  '27113': 22,
  '27114': 22,
  '27115': 22,
  '27116': 22,
  '27117': 22,
  '27118': 22,
  '27119': 22,
  '2711A': 22,
  '2711B': 22,
  '2711C': 22,
  '2711D': 22,
  '2711E': 22,
  '2711F': 22,
  '27120': 22,
  '27121': 22,
  '27122': 22,
  '27123': 22,
  '27124': 22,
  '27125': 22,
  '27126': 22,
  '27127': 22,
  '27128': 22,
  '27129': 22,
  '2712A': 22,
  '2712B': 22,
  '2712C': 22,
  '2712D': 22,
  '2712E': 22,
  '2712F': 22,
  '27130': 22,
  '27131': 22,
  '27132': 22,
  '27133': 22,
  '27134': 22,
  '27135': 22,
  '27136': 23,
  '27137': 23,
  '27138': 23,
  '27139': 23,
  '2713A': 23,
  '2713B': 23,
  '2713C': 23,
  '2713D': 23,
  '2713E': 23,
  '2713F': 23,
  '27140': 23,
  '27141': 23,
  '27142': 23,
  '27143': 23,
  '27144': 23,
  '27145': 23,
  '27146': 23,
  '27147': 23,
  '27148': 23,
  '27149': 23,
  '2714A': 23,
  '2714B': 23,
  '2714C': 23,
  '2714D': 23,
  '2714E': 23,
  '2714F': 23,
  '27150': 23,
  '27151': 23,
  '27152': 23,
  '27153': 23,
  '27154': 23,
  '27155': 23,
  '27156': 24,
  '27157': 24,
  '27158': 24,
  '27159': 24,
  '2715A': 24,
  '2715B': 24,
  '2715C': 24,
  '2715D': 24,
  '2715E': 24,
  '2715F': 24,
  '27160': 24,
  '27161': 24,
  '27162': 24,
  '27163': 24,
  '27164': 24,
  '27165': 24,
  '27166': 24,
  '27167': 24,
  '27168': 24,
  '27169': 24,
  '2716A': 24,
  '2716B': 24,
  '2716C': 24,
  '2716D': 24,
  '2716E': 25,
  '2716F': 25,
  '27170': 25,
  '27171': 25,
  '27172': 25,
  '27173': 25,
  '27174': 25,
  '27175': 28,
  '27176': 25,
  '27177': 25,
  '27178': 25,
  '27179': 25,
  '2717A': 26,
  '2717B': 26,
  '2717C': 26,
  '2717D': 26,
  '2717E': 26,
  '2717F': 26,
  '27180': 26,
  '27181': 26,
  '27182': 26,
  '27183': 26,
  '27184': 26,
  '27185': 26,
  '27186': 25,
  '27187': 27,
  '27188': 27,
  '27189': 27,
  '2718A': 27,
  '2718B': 27,
  '2718C': 27,
  '2718D': 27,
  '2718E': 27,
  '2718F': 28,
  '27190': 28,
  '27191': 28,
  '27192': 28,
  '27193': 37,
  '27194': 29,
  '27195': 29,
  '27196': 30,
  '27197': 31,
  '27198': 43,
  '27199': 33,
  '2719A': 30,
  '2719B': 8,
  '2719C': 9,
  '2719D': 9,
  '2719E': 9,
  '2719F': 10,
  '271A0': 10,
  '271A1': 10,
  '271A2': 10,
  '271A3': 11,
  '271A4': 11,
  '271A5': 11,
  '271A6': 11,
  '271A7': 11,
  '271A8': 11,
  '271A9': 11,
  '271AA': 11,
  '271AB': 11,
  '271AC': 11,
  '271AD': 11,
  '271AE': 11,
  '271AF': 11,
  '271B0': 11,
  '271B1': 12,
  '271B2': 12,
  '271B3': 12,
  '271B4': 12,
  '271B5': 12,
  '271B6': 12,
  '271B7': 12,
  '271B8': 12,
  '271B9': 12,
  '271BA': 13,
  '271BB': 13,
  '271BC': 13,
  '271BD': 13,
  '271BE': 13,
  '271BF': 13,
  '271C0': 13,
  '271C1': 14,
  '271C2': 14,
  '271C3': 14,
  '271C4': 14,
  '271C5': 14,
  '271C6': 14,
  '271C7': 14,
  '271C8': 14,
  '271C9': 14,
  '271CA': 14,
  '271CB': 14,
  '271CC': 14,
  '271CD': 14,
  '271CE': 14,
  '271CF': 15,
  '271D0': 15,
  '271D1': 15,
  '271D2': 15,
  '271D3': 15,
  '271D4': 15,
  '271D5': 15,
  '271D6': 15,
  '271D7': 15,
  '271D8': 15,
  '271D9': 15,
  '271DA': 15,
  '271DB': 15,
  '271DC': 16,
  '271DD': 16,
  '271DE': 16,
  '271DF': 16,
  '271E0': 16,
  '271E1': 16,
  '271E2': 16,
  '271E3': 16,
  '271E4': 16,
  '271E5': 16,
  '271E6': 16,
  '271E7': 16,
  '271E8': 16,
  '271E9': 16,
  '271EA': 16,
  '271EB': 16,
  '271EC': 16,
  '271ED': 16,
  '271EE': 16,
  '271EF': 16,
  '271F0': 16,
  '271F1': 17,
  '271F2': 17,
  '271F3': 17,
  '271F4': 17,
  '271F5': 17,
  '271F6': 17,
  '271F7': 17,
  '271F8': 17,
  '271F9': 17,
  '271FA': 17,
  '271FB': 18,
  '271FC': 18,
  '271FD': 18,
  '271FE': 18,
  '271FF': 18,
  '27200': 18,
  '27201': 18,
  '27202': 19,
  '27203': 18,
  '27204': 18,
  '27205': 19,
  '27206': 19,
  '27207': 19,
  '27208': 19,
  '27209': 19,
  '2720A': 19,
  '2720B': 20,
  '2720C': 20,
  '2720D': 20,
  '2720E': 20,
  '2720F': 20,
  '27210': 19,
  '27211': 21,
  '27212': 21,
  '27213': 21,
  '27214': 21,
  '27215': 21,
  '27216': 21,
  '27217': 21,
  '27218': 22,
  '27219': 22,
  '2721A': 23,
  '2721B': 23,
  '2721C': 26,
  '2721D': 7,
  '2721E': 7,
  '2721F': 8,
  '27220': 8,
  '27221': 8,
  '27222': 8,
  '27223': 8,
  '27224': 8,
  '27225': 8,
  '27226': 8,
  '27227': 8,
  '27228': 9,
  '27229': 9,
  '2722A': 9,
  '2722B': 9,
  '2722C': 9,
  '2722D': 9,
  '2722E': 9,
  '2722F': 9,
  '27230': 9,
  '27231': 9,
  '27232': 9,
  '27233': 9,
  '27234': 9,
  '27235': 9,
  '27236': 9,
  '27237': 9,
  '27238': 9,
  '27239': 9,
  '2723A': 9,
  '2723B': 10,
  '2723C': 10,
  '2723D': 10,
  '2723E': 10,
  '2723F': 10,
  '27240': 10,
  '27241': 10,
  '27242': 10,
  '27243': 10,
  '27244': 10,
  '27245': 10,
  '27246': 10,
  '27247': 10,
  '27248': 10,
  '27249': 10,
  '2724A': 10,
  '2724B': 10,
  '2724C': 10,
  '2724D': 10,
  '2724E': 10,
  '2724F': 10,
  '27250': 10,
  '27251': 10,
  '27252': 10,
  '27253': 10,
  '27254': 10,
  '27255': 10,
  '27256': 10,
  '27257': 10,
  '27258': 10,
  '27259': 10,
  '2725A': 10,
  '2725B': 10,
  '2725C': 10,
  '2725D': 10,
  '2725E': 11,
  '2725F': 11,
  '27260': 11,
  '27261': 11,
  '27262': 11,
  '27263': 11,
  '27264': 11,
  '27265': 11,
  '27266': 11,
  '27267': 11,
  '27268': 11,
  '27269': 11,
  '2726A': 11,
  '2726B': 11,
  '2726C': 11,
  '2726D': 11,
  '2726E': 11,
  '2726F': 11,
  '27270': 11,
  '27271': 11,
  '27272': 11,
  '27273': 11,
  '27274': 11,
  '27275': 11,
  '27276': 11,
  '27277': 11,
  '27278': 11,
  '27279': 11,
  '2727A': 11,
  '2727B': 11,
  '2727C': 11,
  '2727D': 11,
  '2727E': 11,
  '2727F': 11,
  '27280': 11,
  '27281': 11,
  '27282': 11,
  '27283': 11,
  '27284': 11,
  '27285': 11,
  '27286': 11,
  '27287': 11,
  '27288': 11,
  '27289': 11,
  '2728A': 11,
  '2728B': 11,
  '2728C': 11,
  '2728D': 11,
  '2728E': 11,
  '2728F': 12,
  '27290': 12,
  '27291': 12,
  '27292': 12,
  '27293': 12,
  '27294': 12,
  '27295': 12,
  '27296': 12,
  '27297': 12,
  '27298': 12,
  '27299': 12,
  '2729A': 12,
  '2729B': 12,
  '2729C': 12,
  '2729D': 12,
  '2729E': 12,
  '2729F': 12,
  '272A0': 12,
  '272A1': 12,
  '272A2': 12,
  '272A3': 12,
  '272A4': 12,
  '272A5': 12,
  '272A6': 12,
  '272A7': 12,
  '272A8': 12,
  '272A9': 12,
  '272AA': 12,
  '272AB': 13,
  '272AC': 12,
  '272AD': 12,
  '272AE': 13,
  '272AF': 12,
  '272B0': 12,
  '272B1': 12,
  '272B2': 12,
  '272B3': 12,
  '272B4': 12,
  '272B5': 12,
  '272B6': 12,
  '272B7': 12,
  '272B8': 12,
  '272B9': 12,
  '272BA': 12,
  '272BB': 12,
  '272BC': 12,
  '272BD': 12,
  '272BE': 12,
  '272BF': 12,
  '272C0': 12,
  '272C1': 12,
  '272C2': 12,
  '272C3': 12,
  '272C4': 12,
  '272C5': 12,
  '272C6': 12,
  '272C7': 12,
  '272C8': 13,
  '272C9': 13,
  '272CA': 13,
  '272CB': 13,
  '272CC': 13,
  '272CD': 13,
  '272CE': 13,
  '272CF': 13,
  '272D0': 13,
  '272D1': 13,
  '272D2': 13,
  '272D3': 13,
  '272D4': 13,
  '272D5': 13,
  '272D6': 13,
  '272D7': 13,
  '272D8': 13,
  '272D9': 13,
  '272DA': 13,
  '272DB': 13,
  '272DC': 13,
  '272DD': 13,
  '272DE': 14,
  '272DF': 13,
  '272E0': 13,
  '272E1': 13,
  '272E2': 13,
  '272E3': 13,
  '272E4': 13,
  '272E5': 13,
  '272E6': 13,
  '272E7': 13,
  '272E8': 13,
  '272E9': 13,
  '272EA': 13,
  '272EB': 13,
  '272EC': 13,
  '272ED': 13,
  '272EE': 13,
  '272EF': 13,
  '272F0': 13,
  '272F1': 13,
  '272F2': 13,
  '272F3': 13,
  '272F4': 13,
  '272F5': 13,
  '272F6': 13,
  '272F7': 13,
  '272F8': 13,
  '272F9': 13,
  '272FA': 13,
  '272FB': 13,
  '272FC': 13,
  '272FD': 13,
  '272FE': 13,
  '272FF': 13,
  '27300': 13,
  '27301': 13,
  '27302': 13,
  '27303': 14,
  '27304': 14,
  '27305': 14,
  '27306': 14,
  '27307': 14,
  '27308': 14,
  '27309': 14,
  '2730A': 14,
  '2730B': 14,
  '2730C': 14,
  '2730D': 14,
  '2730E': 14,
  '2730F': 14,
  '27310': 14,
  '27311': 14,
  '27312': 14,
  '27313': 14,
  '27314': 14,
  '27315': 14,
  '27316': 14,
  '27317': 14,
  '27318': 14,
  '27319': 14,
  '2731A': 14,
  '2731B': 14,
  '2731C': 14,
  '2731D': 14,
  '2731E': 13,
  '2731F': 14,
  '27320': 14,
  '27321': 14,
  '27322': 14,
  '27323': 14,
  '27324': 14,
  '27325': 14,
  '27326': 14,
  '27327': 14,
  '27328': 14,
  '27329': 14,
  '2732A': 14,
  '2732B': 14,
  '2732C': 14,
  '2732D': 14,
  '2732E': 14,
  '2732F': 14,
  '27330': 15,
  '27331': 14,
  '27332': 14,
  '27333': 14,
  '27334': 14,
  '27335': 14,
  '27336': 14,
  '27337': 14,
  '27338': 14,
  '27339': 14,
  '2733A': 14,
  '2733B': 14,
  '2733C': 14,
  '2733D': 14,
  '2733E': 14,
  '2733F': 14,
  '27340': 14,
  '27341': 14,
  '27342': 14,
  '27343': 14,
  '27344': 14,
  '27345': 14,
  '27346': 14,
  '27347': 14,
  '27348': 14,
  '27349': 14,
  '2734A': 14,
  '2734B': 14,
  '2734C': 14,
  '2734D': 14,
  '2734E': 14,
  '2734F': 14,
  '27350': 14,
  '27351': 14,
  '27352': 15,
  '27353': 15,
  '27354': 15,
  '27355': 15,
  '27356': 15,
  '27357': 15,
  '27358': 15,
  '27359': 15,
  '2735A': 15,
  '2735B': 15,
  '2735C': 15,
  '2735D': 15,
  '2735E': 15,
  '2735F': 15,
  '27360': 15,
  '27361': 15,
  '27362': 15,
  '27363': 15,
  '27364': 15,
  '27365': 15,
  '27366': 15,
  '27367': 15,
  '27368': 15,
  '27369': 15,
  '2736A': 15,
  '2736B': 15,
  '2736C': 15,
  '2736D': 15,
  '2736E': 15,
  '2736F': 15,
  '27370': 15,
  '27371': 15,
  '27372': 15,
  '27373': 15,
  '27374': 15,
  '27375': 15,
  '27376': 15,
  '27377': 15,
  '27378': 15,
  '27379': 15,
  '2737A': 15,
  '2737B': 15,
  '2737C': 15,
  '2737D': 15,
  '2737E': 15,
  '2737F': 15,
  '27380': 15,
  '27381': 15,
  '27382': 15,
  '27383': 15,
  '27384': 15,
  '27385': 15,
  '27386': 15,
  '27387': 15,
  '27388': 15,
  '27389': 15,
  '2738A': 15,
  '2738B': 15,
  '2738C': 15,
  '2738D': 15,
  '2738E': 15,
  '2738F': 15,
  '27390': 15,
  '27391': 15,
  '27392': 15,
  '27393': 15,
  '27394': 15,
  '27395': 15,
  '27396': 15,
  '27397': 15,
  '27398': 15,
  '27399': 15,
  '2739A': 15,
  '2739B': 15,
  '2739C': 15,
  '2739D': 15,
  '2739E': 15,
  '2739F': 15,
  '273A0': 15,
  '273A1': 16,
  '273A2': 16,
  '273A3': 16,
  '273A4': 16,
  '273A5': 16,
  '273A6': 16,
  '273A7': 16,
  '273A8': 16,
  '273A9': 16,
  '273AA': 16,
  '273AB': 16,
  '273AC': 16,
  '273AD': 16,
  '273AE': 16,
  '273AF': 16,
  '273B0': 16,
  '273B1': 16,
  '273B2': 16,
  '273B3': 16,
  '273B4': 16,
  '273B5': 16,
  '273B6': 16,
  '273B7': 16,
  '273B8': 16,
  '273B9': 16,
  '273BA': 16,
  '273BB': 16,
  '273BC': 16,
  '273BD': 16,
  '273BE': 16,
  '273BF': 16,
  '273C0': 16,
  '273C1': 16,
  '273C2': 16,
  '273C3': 16,
  '273C4': 16,
  '273C5': 16,
  '273C6': 16,
  '273C7': 15,
  '273C8': 16,
  '273C9': 16,
  '273CA': 16,
  '273CB': 16,
  '273CC': 16,
  '273CD': 16,
  '273CE': 16,
  '273CF': 16,
  '273D0': 16,
  '273D1': 16,
  '273D2': 16,
  '273D3': 16,
  '273D4': 16,
  '273D5': 16,
  '273D6': 16,
  '273D7': 16,
  '273D8': 16,
  '273D9': 16,
  '273DA': 16,
  '273DB': 16,
  '273DC': 16,
  '273DD': 16,
  '273DE': 16,
  '273DF': 16,
  '273E0': 16,
  '273E1': 16,
  '273E2': 16,
  '273E3': 16,
  '273E4': 16,
  '273E5': 16,
  '273E6': 16,
  '273E7': 16,
  '273E8': 16,
  '273E9': 16,
  '273EA': 16,
  '273EB': 16,
  '273EC': 16,
  '273ED': 16,
  '273EE': 16,
  '273EF': 16,
  '273F0': 16,
  '273F1': 16,
  '273F2': 16,
  '273F3': 16,
  '273F4': 16,
  '273F5': 16,
  '273F6': 16,
  '273F7': 16,
  '273F8': 17,
  '273F9': 17,
  '273FA': 17,
  '273FB': 17,
  '273FC': 17,
  '273FD': 17,
  '273FE': 17,
  '273FF': 17,
  '27400': 17,
  '27401': 17,
  '27402': 17,
  '27403': 17,
  '27404': 17,
  '27405': 17,
  '27406': 17,
  '27407': 17,
  '27408': 17,
  '27409': 17,
  '2740A': 17,
  '2740B': 17,
  '2740C': 17,
  '2740D': 17,
  '2740E': 17,
  '2740F': 17,
  '27410': 17,
  '27411': 17,
  '27412': 17,
  '27413': 17,
  '27414': 17,
  '27415': 17,
  '27416': 17,
  '27417': 17,
  '27418': 17,
  '27419': 17,
  '2741A': 17,
  '2741B': 17,
  '2741C': 17,
  '2741D': 17,
  '2741E': 17,
  '2741F': 17,
  '27420': 17,
  '27421': 17,
  '27422': 17,
  '27423': 17,
  '27424': 17,
  '27425': 17,
  '27426': 17,
  '27427': 17,
  '27428': 17,
  '27429': 17,
  '2742A': 17,
  '2742B': 17,
  '2742C': 17,
  '2742D': 17,
  '2742E': 17,
  '2742F': 17,
  '27430': 18,
  '27431': 17,
  '27432': 17,
  '27433': 17,
  '27434': 17,
  '27435': 17,
  '27436': 17,
  '27437': 17,
  '27438': 17,
  '27439': 17,
  '2743A': 17,
  '2743B': 17,
  '2743C': 17,
  '2743D': 17,
  '2743E': 17,
  '2743F': 17,
  '27440': 17,
  '27441': 17,
  '27442': 17,
  '27443': 17,
  '27444': 18,
  '27445': 18,
  '27446': 18,
  '27447': 18,
  '27448': 18,
  '27449': 18,
  '2744A': 18,
  '2744B': 18,
  '2744C': 18,
  '2744D': 18,
  '2744E': 18,
  '2744F': 18,
  '27450': 18,
  '27451': 18,
  '27452': 18,
  '27453': 18,
  '27454': 18,
  '27455': 18,
  '27456': 18,
  '27457': 18,
  '27458': 18,
  '27459': 18,
  '2745A': 18,
  '2745B': 18,
  '2745C': 18,
  '2745D': 18,
  '2745E': 18,
  '2745F': 18,
  '27460': 18,
  '27461': 18,
  '27462': 18,
  '27463': 18,
  '27464': 18,
  '27465': 18,
  '27466': 18,
  '27467': 18,
  '27468': 18,
  '27469': 18,
  '2746A': 18,
  '2746B': 18,
  '2746C': 18,
  '2746D': 18,
  '2746E': 18,
  '2746F': 18,
  '27470': 18,
  '27471': 18,
  '27472': 18,
  '27473': 18,
  '27474': 18,
  '27475': 18,
  '27476': 18,
  '27477': 18,
  '27478': 18,
  '27479': 18,
  '2747A': 18,
  '2747B': 18,
  '2747C': 18,
  '2747D': 18,
  '2747E': 18,
  '2747F': 18,
  '27480': 18,
  '27481': 18,
  '27482': 18,
  '27483': 18,
  '27484': 18,
  '27485': 18,
  '27486': 18,
  '27487': 18,
  '27488': 18,
  '27489': 18,
  '2748A': 18,
  '2748B': 18,
  '2748C': 18,
  '2748D': 18,
  '2748E': 19,
  '2748F': 19,
  '27490': 19,
  '27491': 19,
  '27492': 19,
  '27493': 19,
  '27494': 19,
  '27495': 19,
  '27496': 19,
  '27497': 19,
  '27498': 19,
  '27499': 19,
  '2749A': 19,
  '2749B': 19,
  '2749C': 19,
  '2749D': 19,
  '2749E': 19,
  '2749F': 19,
  '274A0': 19,
  '274A1': 19,
  '274A2': 19,
  '274A3': 19,
  '274A4': 19,
  '274A5': 19,
  '274A6': 19,
  '274A7': 19,
  '274A8': 19,
  '274A9': 19,
  '274AA': 19,
  '274AB': 19,
  '274AC': 19,
  '274AD': 19,
  '274AE': 19,
  '274AF': 19,
  '274B0': 19,
  '274B1': 19,
  '274B2': 19,
  '274B3': 19,
  '274B4': 19,
  '274B5': 19,
  '274B6': 19,
  '274B7': 19,
  '274B8': 19,
  '274B9': 19,
  '274BA': 19,
  '274BB': 19,
  '274BC': 19,
  '274BD': 19,
  '274BE': 19,
  '274BF': 19,
  '274C0': 19,
  '274C1': 19,
  '274C2': 19,
  '274C3': 19,
  '274C4': 19,
  '274C5': 19,
  '274C6': 19,
  '274C7': 19,
  '274C8': 19,
  '274C9': 20,
  '274CA': 20,
  '274CB': 20,
  '274CC': 20,
  '274CD': 20,
  '274CE': 20,
  '274CF': 20,
  '274D0': 20,
  '274D1': 20,
  '274D2': 20,
  '274D3': 20,
  '274D4': 20,
  '274D5': 20,
  '274D6': 20,
  '274D7': 20,
  '274D8': 20,
  '274D9': 20,
  '274DA': 20,
  '274DB': 20,
  '274DC': 20,
  '274DD': 20,
  '274DE': 20,
  '274DF': 20,
  '274E0': 20,
  '274E1': 20,
  '274E2': 20,
  '274E3': 20,
  '274E4': 20,
  '274E5': 20,
  '274E6': 20,
  '274E7': 20,
  '274E8': 20,
  '274E9': 20,
  '274EA': 20,
  '274EB': 20,
  '274EC': 20,
  '274ED': 20,
  '274EE': 20,
  '274EF': 20,
  '274F0': 20,
  '274F1': 21,
  '274F2': 21,
  '274F3': 21,
  '274F4': 21,
  '274F5': 21,
  '274F6': 21,
  '274F7': 21,
  '274F8': 21,
  '274F9': 21,
  '274FA': 21,
  '274FB': 21,
  '274FC': 21,
  '274FD': 21,
  '274FE': 21,
  '274FF': 21,
  '27500': 21,
  '27501': 21,
  '27502': 21,
  '27503': 21,
  '27504': 21,
  '27505': 21,
  '27506': 21,
  '27507': 21,
  '27508': 21,
  '27509': 21,
  '2750A': 21,
  '2750B': 21,
  '2750C': 21,
  '2750D': 21,
  '2750E': 21,
  '2750F': 21,
  '27510': 21,
  '27511': 21,
  '27512': 21,
  '27513': 21,
  '27514': 21,
  '27515': 21,
  '27516': 21,
  '27517': 21,
  '27518': 21,
  '27519': 21,
  '2751A': 21,
  '2751B': 21,
  '2751C': 21,
  '2751D': 22,
  '2751E': 22,
  '2751F': 22,
  '27520': 22,
  '27521': 22,
  '27522': 22,
  '27523': 22,
  '27524': 22,
  '27525': 22,
  '27526': 22,
  '27527': 22,
  '27528': 22,
  '27529': 22,
  '2752A': 22,
  '2752B': 22,
  '2752C': 22,
  '2752D': 22,
  '2752E': 22,
  '2752F': 22,
  '27530': 22,
  '27531': 22,
  '27532': 22,
  '27533': 22,
  '27534': 22,
  '27535': 22,
  '27536': 22,
  '27537': 22,
  '27538': 22,
  '27539': 22,
  '2753A': 22,
  '2753B': 22,
  '2753C': 22,
  '2753D': 22,
  '2753E': 22,
  '2753F': 22,
  '27540': 22,
  '27541': 22,
  '27542': 22,
  '27543': 23,
  '27544': 23,
  '27545': 23,
  '27546': 23,
  '27547': 23,
  '27548': 23,
  '27549': 23,
  '2754A': 23,
  '2754B': 23,
  '2754C': 23,
  '2754D': 23,
  '2754E': 23,
  '2754F': 23,
  '27550': 23,
  '27551': 23,
  '27552': 23,
  '27553': 24,
  '27554': 23,
  '27555': 23,
  '27556': 23,
  '27557': 23,
  '27558': 23,
  '27559': 23,
  '2755A': 23,
  '2755B': 24,
  '2755C': 24,
  '2755D': 24,
  '2755E': 24,
  '2755F': 24,
  '27560': 24,
  '27561': 24,
  '27562': 24,
  '27563': 24,
  '27564': 24,
  '27565': 24,
  '27566': 24,
  '27567': 24,
  '27568': 24,
  '27569': 24,
  '2756A': 24,
  '2756B': 24,
  '2756C': 24,
  '2756D': 24,
  '2756E': 24,
  '2756F': 25,
  '27570': 25,
  '27571': 25,
  '27572': 25,
  '27573': 25,
  '27574': 25,
  '27575': 25,
  '27576': 25,
  '27577': 25,
  '27578': 25,
  '27579': 25,
  '2757A': 25,
  '2757B': 25,
  '2757C': 25,
  '2757D': 26,
  '2757E': 26,
  '2757F': 26,
  '27580': 26,
  '27581': 26,
  '27582': 26,
  '27583': 26,
  '27584': 27,
  '27585': 27,
  '27586': 27,
  '27587': 27,
  '27588': 27,
  '27589': 27,
  '2758A': 26,
  '2758B': 27,
  '2758C': 27,
  '2758D': 27,
  '2758E': 27,
  '2758F': 27,
  '27590': 27,
  '27591': 28,
  '27592': 28,
  '27593': 28,
  '27594': 28,
  '27595': 28,
  '27596': 28,
  '27597': 28,
  '27598': 29,
  '27599': 29,
  '2759A': 29,
  '2759B': 29,
  '2759C': 30,
  '2759D': 30,
  '2759E': 30,
  '2759F': 30,
  '275A0': 30,
  '275A1': 30,
  '275A2': 31,
  '275A3': 31,
  '275A4': 32,
  '275A5': 32,
  '275A6': 32,
  '275A7': 8,
  '275A8': 8,
  '275A9': 8,
  '275AA': 9,
  '275AB': 10,
  '275AC': 10,
  '275AD': 10,
  '275AE': 10,
  '275AF': 10,
  '275B0': 11,
  '275B1': 11,
  '275B2': 11,
  '275B3': 12,
  '275B4': 12,
  '275B5': 13,
  '275B6': 13,
  '275B7': 13,
  '275B8': 13,
  '275B9': 13,
  '275BA': 14,
  '275BB': 14,
  '275BC': 14,
  '275BD': 14,
  '275BE': 14,
  '275BF': 14,
  '275C0': 15,
  '275C1': 15,
  '275C2': 15,
  '275C3': 15,
  '275C4': 14,
  '275C5': 15,
  '275C6': 16,
  '275C7': 16,
  '275C8': 16,
  '275C9': 16,
  '275CA': 16,
  '275CB': 17,
  '275CC': 17,
  '275CD': 17,
  '275CE': 18,
  '275CF': 18,
  '275D0': 18,
  '275D1': 18,
  '275D2': 18,
  '275D3': 18,
  '275D4': 19,
  '275D5': 19,
  '275D6': 20,
  '275D7': 20,
  '275D8': 21,
  '275D9': 22,
  '275DA': 22,
  '275DB': 23,
  '275DC': 30,
  '275DD': 8,
  '275DE': 8,
  '275DF': 8,
  '275E0': 8,
  '275E1': 9,
  '275E2': 9,
  '275E3': 9,
  '275E4': 9,
  '275E5': 9,
  '275E6': 10,
  '275E7': 10,
  '275E8': 10,
  '275E9': 10,
  '275EA': 11,
  '275EB': 12,
  '275EC': 12,
  '275ED': 12,
  '275EE': 12,
  '275EF': 12,
  '275F0': 12,
  '275F1': 12,
  '275F2': 13,
  '275F3': 13,
  '275F4': 13,
  '275F5': 13,
  '275F6': 13,
  '275F7': 13,
  '275F8': 14,
  '275F9': 15,
  '275FA': 14,
  '275FB': 14,
  '275FC': 15,
  '275FD': 15,
  '275FE': 16,
  '275FF': 17,
  '27600': 17,
  '27601': 17,
  '27602': 18,
  '27603': 18,
  '27604': 19,
  '27605': 21,
  '27606': 22,
  '27607': 5,
  '27608': 7,
  '27609': 7,
  '2760A': 7,
  '2760B': 7,
  '2760C': 7,
  '2760D': 8,
  '2760E': 8,
  '2760F': 8,
  '27610': 8,
  '27611': 8,
  '27612': 8,
  '27613': 8,
  '27614': 8,
  '27615': 8,
  '27616': 8,
  '27617': 8,
  '27618': 8,
  '27619': 8,
  '2761A': 8,
  '2761B': 8,
  '2761C': 8,
  '2761D': 9,
  '2761E': 9,
  '2761F': 9,
  '27620': 9,
  '27621': 9,
  '27622': 9,
  '27623': 9,
  '27624': 9,
  '27625': 9,
  '27626': 9,
  '27627': 9,
  '27628': 9,
  '27629': 9,
  '2762A': 9,
  '2762B': 9,
  '2762C': 9,
  '2762D': 9,
  '2762E': 9,
  '2762F': 9,
  '27630': 9,
  '27631': 9,
  '27632': 9,
  '27633': 9,
  '27634': 9,
  '27635': 9,
  '27636': 9,
  '27637': 9,
  '27638': 9,
  '27639': 9,
  '2763A': 9,
  '2763B': 9,
  '2763C': 9,
  '2763D': 10,
  '2763E': 11,
  '2763F': 10,
  '27640': 10,
  '27641': 10,
  '27642': 10,
  '27643': 10,
  '27644': 10,
  '27645': 10,
  '27646': 10,
  '27647': 10,
  '27648': 10,
  '27649': 10,
  '2764A': 10,
  '2764B': 10,
  '2764C': 10,
  '2764D': 10,
  '2764E': 10,
  '2764F': 10,
  '27650': 10,
  '27651': 10,
  '27652': 10,
  '27653': 10,
  '27654': 10,
  '27655': 10,
  '27656': 10,
  '27657': 10,
  '27658': 10,
  '27659': 10,
  '2765A': 10,
  '2765B': 10,
  '2765C': 10,
  '2765D': 10,
  '2765E': 11,
  '2765F': 11,
  '27660': 11,
  '27661': 11,
  '27662': 11,
  '27663': 11,
  '27664': 11,
  '27665': 11,
  '27666': 11,
  '27667': 11,
  '27668': 11,
  '27669': 11,
  '2766A': 11,
  '2766B': 11,
  '2766C': 11,
  '2766D': 11,
  '2766E': 11,
  '2766F': 11,
  '27670': 11,
  '27671': 11,
  '27672': 11,
  '27673': 11,
  '27674': 11,
  '27675': 11,
  '27676': 11,
  '27677': 11,
  '27678': 11,
  '27679': 11,
  '2767A': 11,
  '2767B': 11,
  '2767C': 11,
  '2767D': 11,
  '2767E': 11,
  '2767F': 11,
  '27680': 12,
  '27681': 12,
  '27682': 12,
  '27683': 12,
  '27684': 12,
  '27685': 12,
  '27686': 12,
  '27687': 12,
  '27688': 12,
  '27689': 12,
  '2768A': 12,
  '2768B': 12,
  '2768C': 12,
  '2768D': 12,
  '2768E': 12,
  '2768F': 12,
  '27690': 12,
  '27691': 12,
  '27692': 12,
  '27693': 12,
  '27694': 12,
  '27695': 12,
  '27696': 12,
  '27697': 12,
  '27698': 12,
  '27699': 12,
  '2769A': 12,
  '2769B': 12,
  '2769C': 12,
  '2769D': 12,
  '2769E': 12,
  '2769F': 12,
  '276A0': 12,
  '276A1': 12,
  '276A2': 12,
  '276A3': 12,
  '276A4': 13,
  '276A5': 13,
  '276A6': 13,
  '276A7': 13,
  '276A8': 13,
  '276A9': 13,
  '276AA': 13,
  '276AB': 13,
  '276AC': 13,
  '276AD': 13,
  '276AE': 13,
  '276AF': 13,
  '276B0': 13,
  '276B1': 13,
  '276B2': 13,
  '276B3': 13,
  '276B4': 13,
  '276B5': 13,
  '276B6': 13,
  '276B7': 13,
  '276B8': 13,
  '276B9': 13,
  '276BA': 13,
  '276BB': 14,
  '276BC': 13,
  '276BD': 13,
  '276BE': 13,
  '276BF': 13,
  '276C0': 13,
  '276C1': 13,
  '276C2': 13,
  '276C3': 13,
  '276C4': 13,
  '276C5': 13,
  '276C6': 13,
  '276C7': 13,
  '276C8': 13,
  '276C9': 13,
  '276CA': 13,
  '276CB': 13,
  '276CC': 13,
  '276CD': 13,
  '276CE': 13,
  '276CF': 14,
  '276D0': 14,
  '276D1': 14,
  '276D2': 14,
  '276D3': 14,
  '276D4': 14,
  '276D5': 14,
  '276D6': 14,
  '276D7': 14,
  '276D8': 14,
  '276D9': 14,
  '276DA': 14,
  '276DB': 14,
  '276DC': 14,
  '276DD': 14,
  '276DE': 14,
  '276DF': 14,
  '276E0': 14,
  '276E1': 14,
  '276E2': 14,
  '276E3': 14,
  '276E4': 14,
  '276E5': 14,
  '276E6': 14,
  '276E7': 14,
  '276E8': 14,
  '276E9': 14,
  '276EA': 14,
  '276EB': 14,
  '276EC': 14,
  '276ED': 14,
  '276EE': 14,
  '276EF': 14,
  '276F0': 14,
  '276F1': 14,
  '276F2': 14,
  '276F3': 14,
  '276F4': 14,
  '276F5': 14,
  '276F6': 14,
  '276F7': 14,
  '276F8': 15,
  '276F9': 15,
  '276FA': 15,
  '276FB': 15,
  '276FC': 15,
  '276FD': 15,
  '276FE': 15,
  '276FF': 15,
  '27700': 15,
  '27701': 15,
  '27702': 15,
  '27703': 15,
  '27704': 15,
  '27705': 15,
  '27706': 15,
  '27707': 15,
  '27708': 15,
  '27709': 15,
  '2770A': 15,
  '2770B': 15,
  '2770C': 15,
  '2770D': 15,
  '2770E': 15,
  '2770F': 15,
  '27710': 15,
  '27711': 15,
  '27712': 15,
  '27713': 15,
  '27714': 15,
  '27715': 15,
  '27716': 15,
  '27717': 15,
  '27718': 15,
  '27719': 15,
  '2771A': 15,
  '2771B': 15,
  '2771C': 15,
  '2771D': 16,
  '2771E': 16,
  '2771F': 16,
  '27720': 16,
  '27721': 16,
  '27722': 16,
  '27723': 16,
  '27724': 16,
  '27725': 16,
  '27726': 16,
  '27727': 16,
  '27728': 15,
  '27729': 16,
  '2772A': 16,
  '2772B': 16,
  '2772C': 16,
  '2772D': 17,
  '2772E': 16,
  '2772F': 16,
  '27730': 16,
  '27731': 16,
  '27732': 16,
  '27733': 16,
  '27734': 16,
  '27735': 16,
  '27736': 16,
  '27737': 16,
  '27738': 16,
  '27739': 16,
  '2773A': 16,
  '2773B': 16,
  '2773C': 16,
  '2773D': 16,
  '2773E': 16,
  '2773F': 16,
  '27740': 16,
  '27741': 16,
  '27742': 17,
  '27743': 17,
  '27744': 17,
  '27745': 17,
  '27746': 17,
  '27747': 17,
  '27748': 17,
  '27749': 17,
  '2774A': 17,
  '2774B': 17,
  '2774C': 17,
  '2774D': 17,
  '2774E': 17,
  '2774F': 17,
  '27750': 17,
  '27751': 17,
  '27752': 17,
  '27753': 17,
  '27754': 17,
  '27755': 17,
  '27756': 17,
  '27757': 17,
  '27758': 17,
  '27759': 17,
  '2775A': 17,
  '2775B': 17,
  '2775C': 17,
  '2775D': 17,
  '2775E': 17,
  '2775F': 17,
  '27760': 17,
  '27761': 17,
  '27762': 17,
  '27763': 17,
  '27764': 17,
  '27765': 17,
  '27766': 17,
  '27767': 17,
  '27768': 17,
  '27769': 17,
  '2776A': 17,
  '2776B': 17,
  '2776C': 17,
  '2776D': 17,
  '2776E': 17,
  '2776F': 17,
  '27770': 17,
  '27771': 18,
  '27772': 18,
  '27773': 18,
  '27774': 18,
  '27775': 18,
  '27776': 18,
  '27777': 18,
  '27778': 18,
  '27779': 18,
  '2777A': 18,
  '2777B': 18,
  '2777C': 18,
  '2777D': 18,
  '2777E': 18,
  '2777F': 18,
  '27780': 18,
  '27781': 18,
  '27782': 18,
  '27783': 18,
  '27784': 18,
  '27785': 18,
  '27786': 18,
  '27787': 18,
  '27788': 18,
  '27789': 18,
  '2778A': 18,
  '2778B': 18,
  '2778C': 18,
  '2778D': 19,
  '2778E': 19,
  '2778F': 19,
  '27790': 19,
  '27791': 19,
  '27792': 19,
  '27793': 19,
  '27794': 19,
  '27795': 19,
  '27796': 19,
  '27797': 20,
  '27798': 19,
  '27799': 19,
  '2779A': 19,
  '2779B': 19,
  '2779C': 19,
  '2779D': 19,
  '2779E': 19,
  '2779F': 19,
  '277A0': 19,
  '277A1': 19,
  '277A2': 19,
  '277A3': 19,
  '277A4': 19,
  '277A5': 19,
  '277A6': 19,
  '277A7': 20,
  '277A8': 20,
  '277A9': 20,
  '277AA': 20,
  '277AB': 20,
  '277AC': 20,
  '277AD': 20,
  '277AE': 20,
  '277AF': 20,
  '277B0': 20,
  '277B1': 20,
  '277B2': 21,
  '277B3': 20,
  '277B4': 20,
  '277B5': 20,
  '277B6': 21,
  '277B7': 21,
  '277B8': 21,
  '277B9': 21,
  '277BA': 21,
  '277BB': 21,
  '277BC': 21,
  '277BD': 21,
  '277BE': 21,
  '277BF': 21,
  '277C0': 21,
  '277C1': 21,
  '277C2': 21,
  '277C3': 22,
  '277C4': 22,
  '277C5': 22,
  '277C6': 22,
  '277C7': 22,
  '277C8': 23,
  '277C9': 23,
  '277CA': 23,
  '277CB': 23,
  '277CC': 24,
  '277CD': 24,
  '277CE': 24,
  '277CF': 24,
  '277D0': 25,
  '277D1': 25,
  '277D2': 25,
  '277D3': 25,
  '277D4': 25,
  '277D5': 26,
  '277D6': 26,
  '277D7': 26,
  '277D8': 27,
  '277D9': 29,
  '277DA': 29,
  '277DB': 31,
  '277DC': 33,
  '277DD': 33,
  '277DE': 37,
  '277DF': 37,
  '277E0': 7,
  '277E1': 7,
  '277E2': 7,
  '277E3': 8,
  '277E4': 9,
  '277E5': 9,
  '277E6': 9,
  '277E7': 10,
  '277E8': 10,
  '277E9': 10,
  '277EA': 11,
  '277EB': 11,
  '277EC': 11,
  '277ED': 12,
  '277EE': 12,
  '277EF': 13,
  '277F0': 13,
  '277F1': 14,
  '277F2': 14,
  '277F3': 14,
  '277F4': 14,
  '277F5': 14,
  '277F6': 14,
  '277F7': 14,
  '277F8': 14,
  '277F9': 15,
  '277FA': 15,
  '277FB': 15,
  '277FC': 16,
  '277FD': 16,
  '277FE': 16,
  '277FF': 17,
  '27800': 18,
  '27801': 18,
  '27802': 19,
  '27803': 20,
  '27804': 21,
  '27805': 25,
  '27806': 8,
  '27807': 8,
  '27808': 9,
  '27809': 10,
  '2780A': 10,
  '2780B': 10,
  '2780C': 10,
  '2780D': 10,
  '2780E': 11,
  '2780F': 11,
  '27810': 11,
  '27811': 11,
  '27812': 11,
  '27813': 11,
  '27814': 11,
  '27815': 11,
  '27816': 11,
  '27817': 11,
  '27818': 11,
  '27819': 11,
  '2781A': 11,
  '2781B': 11,
  '2781C': 12,
  '2781D': 12,
  '2781E': 12,
  '2781F': 12,
  '27820': 12,
  '27821': 12,
  '27822': 12,
  '27823': 12,
  '27824': 12,
  '27825': 12,
  '27826': 12,
  '27827': 12,
  '27828': 13,
  '27829': 13,
  '2782A': 13,
  '2782B': 13,
  '2782C': 13,
  '2782D': 13,
  '2782E': 13,
  '2782F': 13,
  '27830': 13,
  '27831': 13,
  '27832': 13,
  '27833': 13,
  '27834': 13,
  '27835': 13,
  '27836': 13,
  '27837': 13,
  '27838': 13,
  '27839': 13,
  '2783A': 13,
  '2783B': 14,
  '2783C': 14,
  '2783D': 14,
  '2783E': 14,
  '2783F': 14,
  '27840': 14,
  '27841': 14,
  '27842': 14,
  '27843': 14,
  '27844': 14,
  '27845': 14,
  '27846': 14,
  '27847': 14,
  '27848': 14,
  '27849': 14,
  '2784A': 14,
  '2784B': 15,
  '2784C': 15,
  '2784D': 15,
  '2784E': 15,
  '2784F': 15,
  '27850': 15,
  '27851': 15,
  '27852': 15,
  '27853': 15,
  '27854': 15,
  '27855': 15,
  '27856': 15,
  '27857': 15,
  '27858': 15,
  '27859': 15,
  '2785A': 15,
  '2785B': 15,
  '2785C': 15,
  '2785D': 15,
  '2785E': 15,
  '2785F': 15,
  '27860': 15,
  '27861': 16,
  '27862': 16,
  '27863': 16,
  '27864': 16,
  '27865': 16,
  '27866': 16,
  '27867': 16,
  '27868': 16,
  '27869': 16,
  '2786A': 16,
  '2786B': 16,
  '2786C': 16,
  '2786D': 16,
  '2786E': 16,
  '2786F': 16,
  '27870': 16,
  '27871': 16,
  '27872': 16,
  '27873': 16,
  '27874': 16,
  '27875': 16,
  '27876': 16,
  '27877': 17,
  '27878': 17,
  '27879': 17,
  '2787A': 17,
  '2787B': 17,
  '2787C': 17,
  '2787D': 17,
  '2787E': 17,
  '2787F': 17,
  '27880': 17,
  '27881': 17,
  '27882': 18,
  '27883': 18,
  '27884': 18,
  '27885': 18,
  '27886': 18,
  '27887': 18,
  '27888': 18,
  '27889': 18,
  '2788A': 18,
  '2788B': 18,
  '2788C': 19,
  '2788D': 19,
  '2788E': 19,
  '2788F': 19,
  '27890': 19,
  '27891': 19,
  '27892': 20,
  '27893': 20,
  '27894': 20,
  '27895': 20,
  '27896': 20,
  '27897': 20,
  '27898': 21,
  '27899': 21,
  '2789A': 21,
  '2789B': 21,
  '2789C': 22,
  '2789D': 22,
  '2789E': 22,
  '2789F': 22,
  '278A0': 22,
  '278A1': 22,
  '278A2': 24,
  '278A3': 24,
  '278A4': 24,
  '278A5': 24,
  '278A6': 25,
  '278A7': 25,
  '278A8': 25,
  '278A9': 25,
  '278AA': 25,
  '278AB': 25,
  '278AC': 26,
  '278AD': 27,
  '278AE': 28,
  '278AF': 34,
  '278B0': 37,
  '278B1': 44,
  '278B2': 7,
  '278B3': 8,
  '278B4': 9,
  '278B5': 9,
  '278B6': 9,
  '278B7': 10,
  '278B8': 10,
  '278B9': 10,
  '278BA': 10,
  '278BB': 11,
  '278BC': 11,
  '278BD': 11,
  '278BE': 11,
  '278BF': 11,
  '278C0': 11,
  '278C1': 11,
  '278C2': 11,
  '278C3': 11,
  '278C4': 11,
  '278C5': 11,
  '278C6': 11,
  '278C7': 11,
  '278C8': 11,
  '278C9': 11,
  '278CA': 11,
  '278CB': 11,
  '278CC': 11,
  '278CD': 11,
  '278CE': 11,
  '278CF': 11,
  '278D0': 11,
  '278D1': 12,
  '278D2': 12,
  '278D3': 12,
  '278D4': 12,
  '278D5': 12,
  '278D6': 12,
  '278D7': 12,
  '278D8': 12,
  '278D9': 12,
  '278DA': 12,
  '278DB': 12,
  '278DC': 12,
  '278DD': 12,
  '278DE': 12,
  '278DF': 12,
  '278E0': 12,
  '278E1': 13,
  '278E2': 13,
  '278E3': 13,
  '278E4': 13,
  '278E5': 13,
  '278E6': 13,
  '278E7': 13,
  '278E8': 14,
  '278E9': 14,
  '278EA': 14,
  '278EB': 14,
  '278EC': 14,
  '278ED': 14,
  '278EE': 14,
  '278EF': 14,
  '278F0': 14,
  '278F1': 14,
  '278F2': 14,
  '278F3': 14,
  '278F4': 15,
  '278F5': 15,
  '278F6': 15,
  '278F7': 15,
  '278F8': 15,
  '278F9': 15,
  '278FA': 15,
  '278FB': 15,
  '278FC': 15,
  '278FD': 15,
  '278FE': 15,
  '278FF': 15,
  '27900': 15,
  '27901': 15,
  '27902': 15,
  '27903': 15,
  '27904': 15,
  '27905': 15,
  '27906': 15,
  '27907': 15,
  '27908': 15,
  '27909': 15,
  '2790A': 15,
  '2790B': 16,
  '2790C': 16,
  '2790D': 16,
  '2790E': 16,
  '2790F': 16,
  '27910': 16,
  '27911': 16,
  '27912': 16,
  '27913': 16,
  '27914': 16,
  '27915': 16,
  '27916': 16,
  '27917': 16,
  '27918': 16,
  '27919': 16,
  '2791A': 16,
  '2791B': 17,
  '2791C': 17,
  '2791D': 17,
  '2791E': 17,
  '2791F': 17,
  '27920': 17,
  '27921': 17,
  '27922': 16,
  '27923': 17,
  '27924': 17,
  '27925': 17,
  '27926': 17,
  '27927': 17,
  '27928': 17,
  '27929': 17,
  '2792A': 17,
  '2792B': 17,
  '2792C': 17,
  '2792D': 17,
  '2792E': 17,
  '2792F': 18,
  '27930': 18,
  '27931': 18,
  '27932': 18,
  '27933': 18,
  '27934': 18,
  '27935': 18,
  '27936': 18,
  '27937': 18,
  '27938': 18,
  '27939': 18,
  '2793A': 19,
  '2793B': 19,
  '2793C': 19,
  '2793D': 19,
  '2793E': 19,
  '2793F': 19,
  '27940': 19,
  '27941': 19,
  '27942': 19,
  '27943': 19,
  '27944': 20,
  '27945': 20,
  '27946': 20,
  '27947': 20,
  '27948': 21,
  '27949': 21,
  '2794A': 21,
  '2794B': 21,
  '2794C': 22,
  '2794D': 22,
  '2794E': 22,
  '2794F': 22,
  '27950': 22,
  '27951': 23,
  '27952': 23,
  '27953': 24,
  '27954': 24,
  '27955': 25,
  '27956': 26,
  '27957': 28,
  '27958': 28,
  '27959': 29,
  '2795A': 29,
  '2795B': 7,
  '2795C': 7,
  '2795D': 8,
  '2795E': 8,
  '2795F': 8,
  '27960': 9,
  '27961': 10,
  '27962': 10,
  '27963': 10,
  '27964': 10,
  '27965': 10,
  '27966': 10,
  '27967': 10,
  '27968': 10,
  '27969': 10,
  '2796A': 10,
  '2796B': 10,
  '2796C': 10,
  '2796D': 10,
  '2796E': 11,
  '2796F': 11,
  '27970': 11,
  '27971': 11,
  '27972': 11,
  '27973': 11,
  '27974': 11,
  '27975': 11,
  '27976': 11,
  '27977': 11,
  '27978': 11,
  '27979': 11,
  '2797A': 11,
  '2797B': 11,
  '2797C': 11,
  '2797D': 11,
  '2797E': 11,
  '2797F': 11,
  '27980': 11,
  '27981': 11,
  '27982': 11,
  '27983': 11,
  '27984': 11,
  '27985': 11,
  '27986': 11,
  '27987': 11,
  '27988': 11,
  '27989': 11,
  '2798A': 11,
  '2798B': 11,
  '2798C': 11,
  '2798D': 11,
  '2798E': 11,
  '2798F': 11,
  '27990': 11,
  '27991': 11,
  '27992': 11,
  '27993': 11,
  '27994': 11,
  '27995': 11,
  '27996': 11,
  '27997': 11,
  '27998': 11,
  '27999': 11,
  '2799A': 11,
  '2799B': 11,
  '2799C': 12,
  '2799D': 12,
  '2799E': 12,
  '2799F': 12,
  '279A0': 12,
  '279A1': 12,
  '279A2': 12,
  '279A3': 12,
  '279A4': 12,
  '279A5': 12,
  '279A6': 12,
  '279A7': 12,
  '279A8': 12,
  '279A9': 12,
  '279AA': 12,
  '279AB': 12,
  '279AC': 12,
  '279AD': 12,
  '279AE': 12,
  '279AF': 12,
  '279B0': 12,
  '279B1': 12,
  '279B2': 12,
  '279B3': 12,
  '279B4': 12,
  '279B5': 12,
  '279B6': 12,
  '279B7': 12,
  '279B8': 12,
  '279B9': 12,
  '279BA': 12,
  '279BB': 12,
  '279BC': 12,
  '279BD': 12,
  '279BE': 12,
  '279BF': 12,
  '279C0': 12,
  '279C1': 12,
  '279C2': 12,
  '279C3': 13,
  '279C4': 13,
  '279C5': 13,
  '279C6': 13,
  '279C7': 13,
  '279C8': 13,
  '279C9': 13,
  '279CA': 13,
  '279CB': 13,
  '279CC': 13,
  '279CD': 13,
  '279CE': 13,
  '279CF': 13,
  '279D0': 13,
  '279D1': 13,
  '279D2': 13,
  '279D3': 13,
  '279D4': 13,
  '279D5': 13,
  '279D6': 13,
  '279D7': 13,
  '279D8': 13,
  '279D9': 13,
  '279DA': 13,
  '279DB': 13,
  '279DC': 13,
  '279DD': 13,
  '279DE': 13,
  '279DF': 13,
  '279E0': 13,
  '279E1': 13,
  '279E2': 13,
  '279E3': 13,
  '279E4': 13,
  '279E5': 13,
  '279E6': 13,
  '279E7': 13,
  '279E8': 13,
  '279E9': 13,
  '279EA': 13,
  '279EB': 13,
  '279EC': 13,
  '279ED': 13,
  '279EE': 13,
  '279EF': 13,
  '279F0': 13,
  '279F1': 13,
  '279F2': 13,
  '279F3': 13,
  '279F4': 14,
  '279F5': 14,
  '279F6': 14,
  '279F7': 14,
  '279F8': 14,
  '279F9': 14,
  '279FA': 14,
  '279FB': 14,
  '279FC': 14,
  '279FD': 14,
  '279FE': 14,
  '279FF': 14,
  '27A00': 14,
  '27A01': 14,
  '27A02': 14,
  '27A03': 14,
  '27A04': 14,
  '27A05': 14,
  '27A06': 14,
  '27A07': 14,
  '27A08': 14,
  '27A09': 14,
  '27A0A': 14,
  '27A0B': 14,
  '27A0C': 14,
  '27A0D': 14,
  '27A0E': 14,
  '27A0F': 14,
  '27A10': 14,
  '27A11': 14,
  '27A12': 14,
  '27A13': 14,
  '27A14': 14,
  '27A15': 14,
  '27A16': 14,
  '27A17': 14,
  '27A18': 14,
  '27A19': 14,
  '27A1A': 14,
  '27A1B': 14,
  '27A1C': 13,
  '27A1D': 14,
  '27A1E': 14,
  '27A1F': 14,
  '27A20': 14,
  '27A21': 14,
  '27A22': 14,
  '27A23': 14,
  '27A24': 14,
  '27A25': 14,
  '27A26': 15,
  '27A27': 15,
  '27A28': 15,
  '27A29': 15,
  '27A2A': 15,
  '27A2B': 15,
  '27A2C': 15,
  '27A2D': 15,
  '27A2E': 15,
  '27A2F': 15,
  '27A30': 15,
  '27A31': 15,
  '27A32': 15,
  '27A33': 15,
  '27A34': 15,
  '27A35': 15,
  '27A36': 15,
  '27A37': 15,
  '27A38': 15,
  '27A39': 15,
  '27A3A': 15,
  '27A3B': 15,
  '27A3C': 15,
  '27A3D': 15,
  '27A3E': 15,
  '27A3F': 15,
  '27A40': 15,
  '27A41': 15,
  '27A42': 15,
  '27A43': 15,
  '27A44': 15,
  '27A45': 15,
  '27A46': 15,
  '27A47': 15,
  '27A48': 15,
  '27A49': 15,
  '27A4A': 15,
  '27A4B': 15,
  '27A4C': 15,
  '27A4D': 15,
  '27A4E': 15,
  '27A4F': 15,
  '27A50': 15,
  '27A51': 15,
  '27A52': 15,
  '27A53': 15,
  '27A54': 15,
  '27A55': 15,
  '27A56': 15,
  '27A57': 15,
  '27A58': 15,
  '27A59': 15,
  '27A5A': 15,
  '27A5B': 15,
  '27A5C': 15,
  '27A5D': 15,
  '27A5E': 15,
  '27A5F': 15,
  '27A60': 15,
  '27A61': 15,
  '27A62': 15,
  '27A63': 16,
  '27A64': 16,
  '27A65': 16,
  '27A66': 16,
  '27A67': 16,
  '27A68': 16,
  '27A69': 16,
  '27A6A': 16,
  '27A6B': 16,
  '27A6C': 16,
  '27A6D': 16,
  '27A6E': 16,
  '27A6F': 16,
  '27A70': 16,
  '27A71': 16,
  '27A72': 16,
  '27A73': 16,
  '27A74': 16,
  '27A75': 16,
  '27A76': 16,
  '27A77': 16,
  '27A78': 16,
  '27A79': 16,
  '27A7A': 16,
  '27A7B': 16,
  '27A7C': 16,
  '27A7D': 16,
  '27A7E': 16,
  '27A7F': 16,
  '27A80': 16,
  '27A81': 16,
  '27A82': 16,
  '27A83': 16,
  '27A84': 16,
  '27A85': 16,
  '27A86': 16,
  '27A87': 16,
  '27A88': 16,
  '27A89': 16,
  '27A8A': 16,
  '27A8B': 16,
  '27A8C': 16,
  '27A8D': 16,
  '27A8E': 16,
  '27A8F': 16,
  '27A90': 16,
  '27A91': 16,
  '27A92': 16,
  '27A93': 16,
  '27A94': 16,
  '27A95': 16,
  '27A96': 16,
  '27A97': 16,
  '27A98': 17,
  '27A99': 17,
  '27A9A': 17,
  '27A9B': 17,
  '27A9C': 17,
  '27A9D': 17,
  '27A9E': 17,
  '27A9F': 17,
  '27AA0': 17,
  '27AA1': 17,
  '27AA2': 17,
  '27AA3': 17,
  '27AA4': 17,
  '27AA5': 17,
  '27AA6': 17,
  '27AA7': 17,
  '27AA8': 17,
  '27AA9': 17,
  '27AAA': 17,
  '27AAB': 17,
  '27AAC': 17,
  '27AAD': 17,
  '27AAE': 17,
  '27AAF': 17,
  '27AB0': 17,
  '27AB1': 17,
  '27AB2': 17,
  '27AB3': 17,
  '27AB4': 17,
  '27AB5': 17,
  '27AB6': 17,
  '27AB7': 17,
  '27AB8': 17,
  '27AB9': 17,
  '27ABA': 17,
  '27ABB': 17,
  '27ABC': 17,
  '27ABD': 17,
  '27ABE': 17,
  '27ABF': 17,
  '27AC0': 17,
  '27AC1': 17,
  '27AC2': 17,
  '27AC3': 17,
  '27AC4': 17,
  '27AC5': 17,
  '27AC6': 17,
  '27AC7': 17,
  '27AC8': 17,
  '27AC9': 17,
  '27ACA': 17,
  '27ACB': 17,
  '27ACC': 17,
  '27ACD': 17,
  '27ACE': 17,
  '27ACF': 17,
  '27AD0': 17,
  '27AD1': 17,
  '27AD2': 18,
  '27AD3': 18,
  '27AD4': 18,
  '27AD5': 18,
  '27AD6': 18,
  '27AD7': 18,
  '27AD8': 18,
  '27AD9': 18,
  '27ADA': 18,
  '27ADB': 18,
  '27ADC': 18,
  '27ADD': 18,
  '27ADE': 18,
  '27ADF': 18,
  '27AE0': 18,
  '27AE1': 18,
  '27AE2': 18,
  '27AE3': 18,
  '27AE4': 18,
  '27AE5': 18,
  '27AE6': 18,
  '27AE7': 18,
  '27AE8': 18,
  '27AE9': 18,
  '27AEA': 18,
  '27AEB': 18,
  '27AEC': 18,
  '27AED': 18,
  '27AEE': 18,
  '27AEF': 18,
  '27AF0': 18,
  '27AF1': 18,
  '27AF2': 18,
  '27AF3': 18,
  '27AF4': 18,
  '27AF5': 18,
  '27AF6': 18,
  '27AF7': 18,
  '27AF8': 18,
  '27AF9': 18,
  '27AFA': 18,
  '27AFB': 18,
  '27AFC': 18,
  '27AFD': 18,
  '27AFE': 19,
  '27AFF': 19,
  '27B00': 19,
  '27B01': 19,
  '27B02': 19,
  '27B03': 19,
  '27B04': 19,
  '27B05': 19,
  '27B06': 19,
  '27B07': 19,
  '27B08': 19,
  '27B09': 19,
  '27B0A': 19,
  '27B0B': 19,
  '27B0C': 19,
  '27B0D': 19,
  '27B0E': 19,
  '27B0F': 19,
  '27B10': 19,
  '27B11': 19,
  '27B12': 19,
  '27B13': 19,
  '27B14': 19,
  '27B15': 19,
  '27B16': 19,
  '27B17': 19,
  '27B18': 19,
  '27B19': 19,
  '27B1A': 19,
  '27B1B': 19,
  '27B1C': 19,
  '27B1D': 19,
  '27B1E': 19,
  '27B1F': 19,
  '27B20': 19,
  '27B21': 19,
  '27B22': 19,
  '27B23': 19,
  '27B24': 19,
  '27B25': 19,
  '27B26': 19,
  '27B27': 20,
  '27B28': 20,
  '27B29': 20,
  '27B2A': 20,
  '27B2B': 20,
  '27B2C': 20,
  '27B2D': 20,
  '27B2E': 20,
  '27B2F': 20,
  '27B30': 20,
  '27B31': 20,
  '27B32': 20,
  '27B33': 20,
  '27B34': 20,
  '27B35': 20,
  '27B36': 20,
  '27B37': 20,
  '27B38': 20,
  '27B39': 20,
  '27B3A': 20,
  '27B3B': 20,
  '27B3C': 20,
  '27B3D': 20,
  '27B3E': 20,
  '27B3F': 20,
  '27B40': 20,
  '27B41': 20,
  '27B42': 21,
  '27B43': 21,
  '27B44': 21,
  '27B45': 21,
  '27B46': 21,
  '27B47': 21,
  '27B48': 21,
  '27B49': 21,
  '27B4A': 21,
  '27B4B': 21,
  '27B4C': 21,
  '27B4D': 21,
  '27B4E': 21,
  '27B4F': 21,
  '27B50': 21,
  '27B51': 22,
  '27B52': 21,
  '27B53': 21,
  '27B54': 21,
  '27B55': 21,
  '27B56': 21,
  '27B57': 21,
  '27B58': 21,
  '27B59': 21,
  '27B5A': 21,
  '27B5B': 21,
  '27B5C': 22,
  '27B5D': 22,
  '27B5E': 22,
  '27B5F': 22,
  '27B60': 22,
  '27B61': 22,
  '27B62': 22,
  '27B63': 22,
  '27B64': 22,
  '27B65': 22,
  '27B66': 22,
  '27B67': 22,
  '27B68': 22,
  '27B69': 22,
  '27B6A': 22,
  '27B6B': 22,
  '27B6C': 22,
  '27B6D': 22,
  '27B6E': 22,
  '27B6F': 22,
  '27B70': 22,
  '27B71': 22,
  '27B72': 22,
  '27B73': 22,
  '27B74': 22,
  '27B75': 22,
  '27B76': 22,
  '27B77': 22,
  '27B78': 23,
  '27B79': 23,
  '27B7A': 23,
  '27B7B': 23,
  '27B7C': 23,
  '27B7D': 23,
  '27B7E': 23,
  '27B7F': 23,
  '27B80': 23,
  '27B81': 23,
  '27B82': 23,
  '27B83': 23,
  '27B84': 23,
  '27B85': 24,
  '27B86': 24,
  '27B87': 24,
  '27B88': 24,
  '27B89': 24,
  '27B8A': 24,
  '27B8B': 24,
  '27B8C': 24,
  '27B8D': 24,
  '27B8E': 24,
  '27B8F': 24,
  '27B90': 24,
  '27B91': 25,
  '27B92': 25,
  '27B93': 25,
  '27B94': 25,
  '27B95': 25,
  '27B96': 25,
  '27B97': 25,
  '27B98': 25,
  '27B99': 26,
  '27B9A': 26,
  '27B9B': 26,
  '27B9C': 26,
  '27B9D': 26,
  '27B9E': 27,
  '27B9F': 27,
  '27BA0': 27,
  '27BA1': 27,
  '27BA2': 28,
  '27BA3': 28,
  '27BA4': 28,
  '27BA5': 28,
  '27BA6': 28,
  '27BA7': 28,
  '27BA8': 29,
  '27BA9': 39,
  '27BAA': 7,
  '27BAB': 7,
  '27BAC': 10,
  '27BAD': 10,
  '27BAE': 10,
  '27BAF': 11,
  '27BB0': 11,
  '27BB1': 11,
  '27BB2': 11,
  '27BB3': 12,
  '27BB4': 12,
  '27BB5': 13,
  '27BB6': 13,
  '27BB7': 13,
  '27BB8': 14,
  '27BB9': 14,
  '27BBA': 15,
  '27BBB': 15,
  '27BBC': 15,
  '27BBD': 15,
  '27BBE': 15,
  '27BBF': 15,
  '27BC0': 16,
  '27BC1': 16,
  '27BC2': 16,
  '27BC3': 16,
  '27BC4': 16,
  '27BC5': 16,
  '27BC6': 17,
  '27BC7': 17,
  '27BC8': 17,
  '27BC9': 17,
  '27BCA': 18,
  '27BCB': 19,
  '27BCC': 19,
  '27BCD': 19,
  '27BCE': 19,
  '27BCF': 19,
  '27BD0': 19,
  '27BD1': 19,
  '27BD2': 19,
  '27BD3': 20,
  '27BD4': 20,
  '27BD5': 21,
  '27BD6': 21,
  '27BD7': 25,
  '27BD8': 27,
  '27BD9': 31,
  '27BDA': 9,
  '27BDB': 10,
  '27BDC': 10,
  '27BDD': 10,
  '27BDE': 11,
  '27BDF': 11,
  '27BE0': 12,
  '27BE1': 12,
  '27BE2': 12,
  '27BE3': 12,
  '27BE4': 12,
  '27BE5': 13,
  '27BE6': 13,
  '27BE7': 13,
  '27BE8': 13,
  '27BE9': 14,
  '27BEA': 14,
  '27BEB': 14,
  '27BEC': 14,
  '27BED': 15,
  '27BEE': 15,
  '27BEF': 15,
  '27BF0': 15,
  '27BF1': 15,
  '27BF2': 15,
  '27BF3': 15,
  '27BF4': 15,
  '27BF5': 15,
  '27BF6': 15,
  '27BF7': 16,
  '27BF8': 16,
  '27BF9': 16,
  '27BFA': 16,
  '27BFB': 16,
  '27BFC': 17,
  '27BFD': 17,
  '27BFE': 17,
  '27BFF': 17,
  '27C00': 17,
  '27C01': 17,
  '27C02': 17,
  '27C03': 18,
  '27C04': 18,
  '27C05': 18,
  '27C06': 18,
  '27C07': 18,
  '27C08': 17,
  '27C09': 19,
  '27C0A': 19,
  '27C0B': 19,
  '27C0C': 19,
  '27C0D': 19,
  '27C0E': 19,
  '27C0F': 19,
  '27C10': 19,
  '27C11': 20,
  '27C12': 20,
  '27C13': 20,
  '27C14': 20,
  '27C15': 20,
  '27C16': 20,
  '27C17': 21,
  '27C18': 21,
  '27C19': 22,
  '27C1A': 22,
  '27C1B': 22,
  '27C1C': 22,
  '27C1D': 23,
  '27C1E': 23,
  '27C1F': 23,
  '27C20': 24,
  '27C21': 25,
  '27C22': 25,
  '27C23': 27,
  '27C24': 27,
  '27C25': 29,
  '27C26': 8,
  '27C27': 7,
  '27C28': 7,
  '27C29': 9,
  '27C2A': 10,
  '27C2B': 10,
  '27C2C': 10,
  '27C2D': 10,
  '27C2E': 11,
  '27C2F': 11,
  '27C30': 11,
  '27C31': 11,
  '27C32': 11,
  '27C33': 11,
  '27C34': 11,
  '27C35': 12,
  '27C36': 12,
  '27C37': 12,
  '27C38': 12,
  '27C39': 12,
  '27C3A': 12,
  '27C3B': 12,
  '27C3C': 12,
  '27C3D': 12,
  '27C3E': 12,
  '27C3F': 13,
  '27C40': 13,
  '27C41': 13,
  '27C42': 13,
  '27C43': 13,
  '27C44': 13,
  '27C45': 13,
  '27C46': 13,
  '27C47': 13,
  '27C48': 13,
  '27C49': 13,
  '27C4A': 13,
  '27C4B': 13,
  '27C4C': 13,
  '27C4D': 14,
  '27C4E': 14,
  '27C4F': 14,
  '27C50': 14,
  '27C51': 14,
  '27C52': 14,
  '27C53': 14,
  '27C54': 14,
  '27C55': 14,
  '27C56': 14,
  '27C57': 14,
  '27C58': 15,
  '27C59': 15,
  '27C5A': 15,
  '27C5B': 15,
  '27C5C': 15,
  '27C5D': 15,
  '27C5E': 15,
  '27C5F': 15,
  '27C60': 15,
  '27C61': 15,
  '27C62': 15,
  '27C63': 15,
  '27C64': 15,
  '27C65': 15,
  '27C66': 15,
  '27C67': 15,
  '27C68': 16,
  '27C69': 16,
  '27C6A': 16,
  '27C6B': 16,
  '27C6C': 16,
  '27C6D': 16,
  '27C6E': 16,
  '27C6F': 16,
  '27C70': 16,
  '27C71': 16,
  '27C72': 17,
  '27C73': 17,
  '27C74': 17,
  '27C75': 17,
  '27C76': 17,
  '27C77': 17,
  '27C78': 17,
  '27C79': 17,
  '27C7A': 17,
  '27C7B': 18,
  '27C7C': 18,
  '27C7D': 18,
  '27C7E': 18,
  '27C7F': 18,
  '27C80': 18,
  '27C81': 18,
  '27C82': 19,
  '27C83': 19,
  '27C84': 19,
  '27C85': 19,
  '27C86': 19,
  '27C87': 20,
  '27C88': 20,
  '27C89': 20,
  '27C8A': 20,
  '27C8B': 20,
  '27C8C': 21,
  '27C8D': 21,
  '27C8E': 21,
  '27C8F': 21,
  '27C90': 22,
  '27C91': 22,
  '27C92': 22,
  '27C93': 22,
  '27C94': 23,
  '27C95': 23,
  '27C96': 23,
  '27C97': 22,
  '27C98': 24,
  '27C99': 24,
  '27C9A': 25,
  '27C9B': 25,
  '27C9C': 25,
  '27C9D': 27,
  '27C9E': 29,
  '27C9F': 36,
  '27CA0': 8,
  '27CA1': 9,
  '27CA2': 10,
  '27CA3': 10,
  '27CA4': 11,
  '27CA5': 11,
  '27CA6': 11,
  '27CA7': 11,
  '27CA8': 11,
  '27CA9': 11,
  '27CAA': 11,
  '27CAB': 11,
  '27CAC': 12,
  '27CAD': 12,
  '27CAE': 12,
  '27CAF': 12,
  '27CB0': 12,
  '27CB1': 12,
  '27CB2': 12,
  '27CB3': 12,
  '27CB4': 12,
  '27CB5': 12,
  '27CB6': 12,
  '27CB7': 12,
  '27CB8': 12,
  '27CB9': 12,
  '27CBA': 12,
  '27CBB': 12,
  '27CBC': 12,
  '27CBD': 12,
  '27CBE': 13,
  '27CBF': 13,
  '27CC0': 13,
  '27CC1': 13,
  '27CC2': 13,
  '27CC3': 13,
  '27CC4': 13,
  '27CC5': 13,
  '27CC6': 13,
  '27CC7': 13,
  '27CC8': 13,
  '27CC9': 13,
  '27CCA': 14,
  '27CCB': 14,
  '27CCC': 14,
  '27CCD': 14,
  '27CCE': 14,
  '27CCF': 14,
  '27CD0': 14,
  '27CD1': 14,
  '27CD2': 14,
  '27CD3': 14,
  '27CD4': 14,
  '27CD5': 14,
  '27CD6': 14,
  '27CD7': 14,
  '27CD8': 14,
  '27CD9': 15,
  '27CDA': 15,
  '27CDB': 15,
  '27CDC': 15,
  '27CDD': 15,
  '27CDE': 15,
  '27CDF': 15,
  '27CE0': 15,
  '27CE1': 15,
  '27CE2': 15,
  '27CE3': 15,
  '27CE4': 15,
  '27CE5': 15,
  '27CE6': 16,
  '27CE7': 16,
  '27CE8': 16,
  '27CE9': 16,
  '27CEA': 16,
  '27CEB': 16,
  '27CEC': 16,
  '27CED': 16,
  '27CEE': 16,
  '27CEF': 16,
  '27CF0': 16,
  '27CF1': 15,
  '27CF2': 16,
  '27CF3': 16,
  '27CF4': 16,
  '27CF5': 17,
  '27CF6': 17,
  '27CF7': 17,
  '27CF8': 17,
  '27CF9': 17,
  '27CFA': 17,
  '27CFB': 17,
  '27CFC': 17,
  '27CFD': 17,
  '27CFE': 17,
  '27CFF': 17,
  '27D00': 17,
  '27D01': 18,
  '27D02': 18,
  '27D03': 18,
  '27D04': 18,
  '27D05': 18,
  '27D06': 19,
  '27D07': 18,
  '27D08': 18,
  '27D09': 18,
  '27D0A': 18,
  '27D0B': 18,
  '27D0C': 19,
  '27D0D': 19,
  '27D0E': 19,
  '27D0F': 19,
  '27D10': 20,
  '27D11': 19,
  '27D12': 19,
  '27D13': 19,
  '27D14': 19,
  '27D15': 19,
  '27D16': 20,
  '27D17': 20,
  '27D18': 20,
  '27D19': 20,
  '27D1A': 20,
  '27D1B': 20,
  '27D1C': 20,
  '27D1D': 21,
  '27D1E': 21,
  '27D1F': 22,
  '27D20': 23,
  '27D21': 23,
  '27D22': 24,
  '27D23': 27,
  '27D24': 9,
  '27D25': 9,
  '27D26': 9,
  '27D27': 9,
  '27D28': 9,
  '27D29': 9,
  '27D2A': 10,
  '27D2B': 10,
  '27D2C': 10,
  '27D2D': 10,
  '27D2E': 10,
  '27D2F': 10,
  '27D30': 10,
  '27D31': 10,
  '27D32': 10,
  '27D33': 11,
  '27D34': 11,
  '27D35': 11,
  '27D36': 11,
  '27D37': 11,
  '27D38': 11,
  '27D39': 11,
  '27D3A': 11,
  '27D3B': 11,
  '27D3C': 11,
  '27D3D': 11,
  '27D3E': 11,
  '27D3F': 11,
  '27D40': 11,
  '27D41': 11,
  '27D42': 11,
  '27D43': 11,
  '27D44': 11,
  '27D45': 11,
  '27D46': 11,
  '27D47': 11,
  '27D48': 11,
  '27D49': 12,
  '27D4A': 12,
  '27D4B': 12,
  '27D4C': 12,
  '27D4D': 12,
  '27D4E': 12,
  '27D4F': 12,
  '27D50': 12,
  '27D51': 12,
  '27D52': 12,
  '27D53': 12,
  '27D54': 12,
  '27D55': 12,
  '27D56': 12,
  '27D57': 12,
  '27D58': 12,
  '27D59': 12,
  '27D5A': 12,
  '27D5B': 12,
  '27D5C': 12,
  '27D5D': 12,
  '27D5E': 12,
  '27D5F': 12,
  '27D60': 12,
  '27D61': 12,
  '27D62': 12,
  '27D63': 13,
  '27D64': 13,
  '27D65': 13,
  '27D66': 13,
  '27D67': 13,
  '27D68': 13,
  '27D69': 13,
  '27D6A': 13,
  '27D6B': 13,
  '27D6C': 13,
  '27D6D': 13,
  '27D6E': 13,
  '27D6F': 13,
  '27D70': 13,
  '27D71': 13,
  '27D72': 13,
  '27D73': 13,
  '27D74': 13,
  '27D75': 13,
  '27D76': 13,
  '27D77': 13,
  '27D78': 13,
  '27D79': 13,
  '27D7A': 13,
  '27D7B': 13,
  '27D7C': 13,
  '27D7D': 13,
  '27D7E': 13,
  '27D7F': 13,
  '27D80': 13,
  '27D81': 13,
  '27D82': 13,
  '27D83': 13,
  '27D84': 13,
  '27D85': 14,
  '27D86': 14,
  '27D87': 14,
  '27D88': 14,
  '27D89': 14,
  '27D8A': 14,
  '27D8B': 14,
  '27D8C': 14,
  '27D8D': 14,
  '27D8E': 14,
  '27D8F': 14,
  '27D90': 14,
  '27D91': 14,
  '27D92': 14,
  '27D93': 14,
  '27D94': 14,
  '27D95': 14,
  '27D96': 14,
  '27D97': 14,
  '27D98': 14,
  '27D99': 14,
  '27D9A': 14,
  '27D9B': 15,
  '27D9C': 15,
  '27D9D': 15,
  '27D9E': 15,
  '27D9F': 15,
  '27DA0': 15,
  '27DA1': 15,
  '27DA2': 15,
  '27DA3': 15,
  '27DA4': 15,
  '27DA5': 15,
  '27DA6': 15,
  '27DA7': 15,
  '27DA8': 15,
  '27DA9': 15,
  '27DAA': 15,
  '27DAB': 15,
  '27DAC': 15,
  '27DAD': 15,
  '27DAE': 15,
  '27DAF': 15,
  '27DB0': 15,
  '27DB1': 16,
  '27DB2': 16,
  '27DB3': 16,
  '27DB4': 16,
  '27DB5': 16,
  '27DB6': 16,
  '27DB7': 16,
  '27DB8': 16,
  '27DB9': 16,
  '27DBA': 16,
  '27DBB': 16,
  '27DBC': 16,
  '27DBD': 16,
  '27DBE': 16,
  '27DBF': 16,
  '27DC0': 16,
  '27DC1': 16,
  '27DC2': 16,
  '27DC3': 16,
  '27DC4': 16,
  '27DC5': 17,
  '27DC6': 16,
  '27DC7': 16,
  '27DC8': 16,
  '27DC9': 16,
  '27DCA': 16,
  '27DCB': 16,
  '27DCC': 17,
  '27DCD': 17,
  '27DCE': 17,
  '27DCF': 17,
  '27DD0': 17,
  '27DD1': 17,
  '27DD2': 17,
  '27DD3': 17,
  '27DD4': 17,
  '27DD5': 17,
  '27DD6': 17,
  '27DD7': 17,
  '27DD8': 17,
  '27DD9': 17,
  '27DDA': 17,
  '27DDB': 17,
  '27DDC': 17,
  '27DDD': 17,
  '27DDE': 18,
  '27DDF': 18,
  '27DE0': 18,
  '27DE1': 18,
  '27DE2': 18,
  '27DE3': 18,
  '27DE4': 18,
  '27DE5': 18,
  '27DE6': 18,
  '27DE7': 18,
  '27DE8': 18,
  '27DE9': 18,
  '27DEA': 18,
  '27DEB': 18,
  '27DEC': 18,
  '27DED': 18,
  '27DEE': 18,
  '27DEF': 18,
  '27DF0': 18,
  '27DF1': 18,
  '27DF2': 18,
  '27DF3': 18,
  '27DF4': 18,
  '27DF5': 18,
  '27DF6': 18,
  '27DF7': 18,
  '27DF8': 18,
  '27DF9': 18,
  '27DFA': 18,
  '27DFB': 18,
  '27DFC': 19,
  '27DFD': 19,
  '27DFE': 19,
  '27DFF': 19,
  '27E00': 19,
  '27E01': 19,
  '27E02': 19,
  '27E03': 19,
  '27E04': 19,
  '27E05': 19,
  '27E06': 19,
  '27E07': 19,
  '27E08': 19,
  '27E09': 19,
  '27E0A': 19,
  '27E0B': 19,
  '27E0C': 19,
  '27E0D': 19,
  '27E0E': 19,
  '27E0F': 19,
  '27E10': 19,
  '27E11': 19,
  '27E12': 19,
  '27E13': 19,
  '27E14': 19,
  '27E15': 19,
  '27E16': 20,
  '27E17': 20,
  '27E18': 20,
  '27E19': 20,
  '27E1A': 20,
  '27E1B': 20,
  '27E1C': 20,
  '27E1D': 20,
  '27E1E': 20,
  '27E1F': 20,
  '27E20': 20,
  '27E21': 20,
  '27E22': 20,
  '27E23': 20,
  '27E24': 20,
  '27E25': 20,
  '27E26': 21,
  '27E27': 21,
  '27E28': 21,
  '27E29': 21,
  '27E2A': 21,
  '27E2B': 21,
  '27E2C': 21,
  '27E2D': 21,
  '27E2E': 21,
  '27E2F': 21,
  '27E30': 21,
  '27E31': 22,
  '27E32': 22,
  '27E33': 22,
  '27E34': 22,
  '27E35': 22,
  '27E36': 22,
  '27E37': 22,
  '27E38': 22,
  '27E39': 22,
  '27E3A': 22,
  '27E3B': 22,
  '27E3C': 22,
  '27E3D': 23,
  '27E3E': 23,
  '27E3F': 22,
  '27E40': 23,
  '27E41': 23,
  '27E42': 23,
  '27E43': 23,
  '27E44': 23,
  '27E45': 23,
  '27E46': 23,
  '27E47': 23,
  '27E48': 24,
  '27E49': 24,
  '27E4A': 24,
  '27E4B': 24,
  '27E4C': 25,
  '27E4D': 25,
  '27E4E': 26,
  '27E4F': 26,
  '27E50': 26,
  '27E51': 8,
  '27E52': 9,
  '27E53': 11,
  '27E54': 12,
  '27E55': 12,
  '27E56': 12,
  '27E57': 15,
  '27E58': 8,
  '27E59': 9,
  '27E5A': 9,
  '27E5B': 11,
  '27E5C': 11,
  '27E5D': 12,
  '27E5E': 12,
  '27E5F': 12,
  '27E60': 13,
  '27E61': 13,
  '27E62': 13,
  '27E63': 14,
  '27E64': 14,
  '27E65': 14,
  '27E66': 14,
  '27E67': 15,
  '27E68': 15,
  '27E69': 15,
  '27E6A': 15,
  '27E6B': 15,
  '27E6C': 16,
  '27E6D': 16,
  '27E6E': 16,
  '27E6F': 16,
  '27E70': 16,
  '27E71': 16,
  '27E72': 17,
  '27E73': 17,
  '27E74': 17,
  '27E75': 17,
  '27E76': 18,
  '27E77': 18,
  '27E78': 19,
  '27E79': 19,
  '27E7A': 19,
  '27E7B': 19,
  '27E7C': 19,
  '27E7D': 21,
  '27E7E': 21,
  '27E7F': 21,
  '27E80': 22,
  '27E81': 22,
  '27E82': 23,
  '27E83': 23,
  '27E84': 23,
  '27E85': 23,
  '27E86': 7,
  '27E87': 8,
  '27E88': 9,
  '27E89': 9,
  '27E8A': 9,
  '27E8B': 9,
  '27E8C': 9,
  '27E8D': 9,
  '27E8E': 9,
  '27E8F': 10,
  '27E90': 10,
  '27E91': 10,
  '27E92': 10,
  '27E93': 10,
  '27E94': 10,
  '27E95': 10,
  '27E96': 10,
  '27E97': 10,
  '27E98': 10,
  '27E99': 10,
  '27E9A': 10,
  '27E9B': 10,
  '27E9C': 10,
  '27E9D': 11,
  '27E9E': 11,
  '27E9F': 11,
  '27EA0': 11,
  '27EA1': 11,
  '27EA2': 11,
  '27EA3': 11,
  '27EA4': 11,
  '27EA5': 11,
  '27EA6': 11,
  '27EA7': 11,
  '27EA8': 11,
  '27EA9': 11,
  '27EAA': 11,
  '27EAB': 11,
  '27EAC': 11,
  '27EAD': 11,
  '27EAE': 11,
  '27EAF': 11,
  '27EB0': 11,
  '27EB1': 11,
  '27EB2': 11,
  '27EB3': 11,
  '27EB4': 11,
  '27EB5': 11,
  '27EB6': 12,
  '27EB7': 12,
  '27EB8': 12,
  '27EB9': 12,
  '27EBA': 12,
  '27EBB': 12,
  '27EBC': 12,
  '27EBD': 12,
  '27EBE': 12,
  '27EBF': 12,
  '27EC0': 12,
  '27EC1': 12,
  '27EC2': 12,
  '27EC3': 12,
  '27EC4': 12,
  '27EC5': 12,
  '27EC6': 12,
  '27EC7': 12,
  '27EC8': 12,
  '27EC9': 12,
  '27ECA': 12,
  '27ECB': 12,
  '27ECC': 12,
  '27ECD': 12,
  '27ECE': 12,
  '27ECF': 12,
  '27ED0': 13,
  '27ED1': 13,
  '27ED2': 13,
  '27ED3': 13,
  '27ED4': 13,
  '27ED5': 13,
  '27ED6': 13,
  '27ED7': 13,
  '27ED8': 13,
  '27ED9': 13,
  '27EDA': 13,
  '27EDB': 13,
  '27EDC': 13,
  '27EDD': 13,
  '27EDE': 13,
  '27EDF': 13,
  '27EE0': 13,
  '27EE1': 13,
  '27EE2': 13,
  '27EE3': 13,
  '27EE4': 13,
  '27EE5': 13,
  '27EE6': 13,
  '27EE7': 13,
  '27EE8': 13,
  '27EE9': 13,
  '27EEA': 13,
  '27EEB': 13,
  '27EEC': 13,
  '27EED': 13,
  '27EEE': 13,
  '27EEF': 14,
  '27EF0': 14,
  '27EF1': 14,
  '27EF2': 14,
  '27EF3': 14,
  '27EF4': 14,
  '27EF5': 14,
  '27EF6': 14,
  '27EF7': 14,
  '27EF8': 14,
  '27EF9': 14,
  '27EFA': 14,
  '27EFB': 14,
  '27EFC': 14,
  '27EFD': 14,
  '27EFE': 14,
  '27EFF': 14,
  '27F00': 14,
  '27F01': 14,
  '27F02': 14,
  '27F03': 14,
  '27F04': 14,
  '27F05': 14,
  '27F06': 14,
  '27F07': 14,
  '27F08': 14,
  '27F09': 14,
  '27F0A': 14,
  '27F0B': 14,
  '27F0C': 14,
  '27F0D': 14,
  '27F0E': 15,
  '27F0F': 15,
  '27F10': 15,
  '27F11': 15,
  '27F12': 15,
  '27F13': 15,
  '27F14': 15,
  '27F15': 15,
  '27F16': 15,
  '27F17': 15,
  '27F18': 15,
  '27F19': 15,
  '27F1A': 15,
  '27F1B': 15,
  '27F1C': 15,
  '27F1D': 14,
  '27F1E': 15,
  '27F1F': 16,
  '27F20': 15,
  '27F21': 15,
  '27F22': 15,
  '27F23': 15,
  '27F24': 15,
  '27F25': 15,
  '27F26': 15,
  '27F27': 15,
  '27F28': 16,
  '27F29': 16,
  '27F2A': 16,
  '27F2B': 16,
  '27F2C': 16,
  '27F2D': 16,
  '27F2E': 16,
  '27F2F': 16,
  '27F30': 16,
  '27F31': 16,
  '27F32': 16,
  '27F33': 16,
  '27F34': 16,
  '27F35': 16,
  '27F36': 16,
  '27F37': 16,
  '27F38': 16,
  '27F39': 16,
  '27F3A': 16,
  '27F3B': 16,
  '27F3C': 16,
  '27F3D': 16,
  '27F3E': 16,
  '27F3F': 16,
  '27F40': 16,
  '27F41': 16,
  '27F42': 16,
  '27F43': 16,
  '27F44': 16,
  '27F45': 16,
  '27F46': 16,
  '27F47': 16,
  '27F48': 16,
  '27F49': 17,
  '27F4A': 17,
  '27F4B': 17,
  '27F4C': 17,
  '27F4D': 17,
  '27F4E': 17,
  '27F4F': 17,
  '27F50': 17,
  '27F51': 17,
  '27F52': 17,
  '27F53': 17,
  '27F54': 17,
  '27F55': 17,
  '27F56': 17,
  '27F57': 17,
  '27F58': 17,
  '27F59': 17,
  '27F5A': 17,
  '27F5B': 17,
  '27F5C': 17,
  '27F5D': 17,
  '27F5E': 18,
  '27F5F': 18,
  '27F60': 18,
  '27F61': 18,
  '27F62': 18,
  '27F63': 18,
  '27F64': 18,
  '27F65': 18,
  '27F66': 18,
  '27F67': 18,
  '27F68': 18,
  '27F69': 18,
  '27F6A': 18,
  '27F6B': 18,
  '27F6C': 18,
  '27F6D': 18,
  '27F6E': 18,
  '27F6F': 18,
  '27F70': 18,
  '27F71': 18,
  '27F72': 18,
  '27F73': 18,
  '27F74': 18,
  '27F75': 18,
  '27F76': 19,
  '27F77': 19,
  '27F78': 19,
  '27F79': 19,
  '27F7A': 19,
  '27F7B': 19,
  '27F7C': 19,
  '27F7D': 19,
  '27F7E': 19,
  '27F7F': 19,
  '27F80': 19,
  '27F81': 19,
  '27F82': 19,
  '27F83': 19,
  '27F84': 19,
  '27F85': 19,
  '27F86': 19,
  '27F87': 19,
  '27F88': 19,
  '27F89': 19,
  '27F8A': 19,
  '27F8B': 19,
  '27F8C': 19,
  '27F8D': 20,
  '27F8E': 20,
  '27F8F': 20,
  '27F90': 20,
  '27F91': 20,
  '27F92': 20,
  '27F93': 20,
  '27F94': 20,
  '27F95': 20,
  '27F96': 20,
  '27F97': 20,
  '27F98': 20,
  '27F99': 21,
  '27F9A': 21,
  '27F9B': 21,
  '27F9C': 21,
  '27F9D': 21,
  '27F9E': 21,
  '27F9F': 21,
  '27FA0': 21,
  '27FA1': 22,
  '27FA2': 22,
  '27FA3': 22,
  '27FA4': 22,
  '27FA5': 22,
  '27FA6': 22,
  '27FA7': 22,
  '27FA8': 23,
  '27FA9': 23,
  '27FAA': 23,
  '27FAB': 23,
  '27FAC': 23,
  '27FAD': 23,
  '27FAE': 24,
  '27FAF': 24,
  '27FB0': 25,
  '27FB1': 25,
  '27FB2': 25,
  '27FB3': 25,
  '27FB4': 25,
  '27FB5': 27,
  '27FB6': 28,
  '27FB7': 7,
  '27FB8': 8,
  '27FB9': 8,
  '27FBA': 9,
  '27FBB': 9,
  '27FBC': 9,
  '27FBD': 9,
  '27FBE': 9,
  '27FBF': 9,
  '27FC0': 9,
  '27FC1': 10,
  '27FC2': 10,
  '27FC3': 10,
  '27FC4': 10,
  '27FC5': 10,
  '27FC6': 10,
  '27FC7': 10,
  '27FC8': 10,
  '27FC9': 10,
  '27FCA': 10,
  '27FCB': 10,
  '27FCC': 10,
  '27FCD': 10,
  '27FCE': 10,
  '27FCF': 10,
  '27FD0': 10,
  '27FD1': 10,
  '27FD2': 11,
  '27FD3': 11,
  '27FD4': 11,
  '27FD5': 11,
  '27FD6': 11,
  '27FD7': 11,
  '27FD8': 11,
  '27FD9': 11,
  '27FDA': 11,
  '27FDB': 11,
  '27FDC': 11,
  '27FDD': 11,
  '27FDE': 11,
  '27FDF': 11,
  '27FE0': 11,
  '27FE1': 11,
  '27FE2': 11,
  '27FE3': 11,
  '27FE4': 11,
  '27FE5': 11,
  '27FE6': 11,
  '27FE7': 11,
  '27FE8': 11,
  '27FE9': 11,
  '27FEA': 11,
  '27FEB': 11,
  '27FEC': 11,
  '27FED': 11,
  '27FEE': 11,
  '27FEF': 11,
  '27FF0': 11,
  '27FF1': 11,
  '27FF2': 12,
  '27FF3': 12,
  '27FF4': 12,
  '27FF5': 12,
  '27FF6': 12,
  '27FF7': 12,
  '27FF8': 12,
  '27FF9': 12,
  '27FFA': 12,
  '27FFB': 12,
  '27FFC': 12,
  '27FFD': 12,
  '27FFE': 12,
  '27FFF': 12,
  '28000': 12,
  '28001': 12,
  '28002': 12,
  '28003': 12,
  '28004': 12,
  '28005': 12,
  '28006': 12,
  '28007': 12,
  '28008': 12,
  '28009': 12,
  '2800A': 12,
  '2800B': 12,
  '2800C': 12,
  '2800D': 12,
  '2800E': 12,
  '2800F': 12,
  '28010': 12,
  '28011': 12,
  '28012': 12,
  '28013': 12,
  '28014': 12,
  '28015': 13,
  '28016': 13,
  '28017': 13,
  '28018': 13,
  '28019': 13,
  '2801A': 13,
  '2801B': 13,
  '2801C': 13,
  '2801D': 13,
  '2801E': 13,
  '2801F': 13,
  '28020': 13,
  '28021': 13,
  '28022': 13,
  '28023': 13,
  '28024': 13,
  '28025': 13,
  '28026': 13,
  '28027': 13,
  '28028': 13,
  '28029': 13,
  '2802A': 13,
  '2802B': 13,
  '2802C': 13,
  '2802D': 13,
  '2802E': 13,
  '2802F': 13,
  '28030': 13,
  '28031': 13,
  '28032': 13,
  '28033': 13,
  '28034': 13,
  '28035': 13,
  '28036': 13,
  '28037': 13,
  '28038': 13,
  '28039': 13,
  '2803A': 13,
  '2803B': 13,
  '2803C': 13,
  '2803D': 13,
  '2803E': 13,
  '2803F': 13,
  '28040': 14,
  '28041': 14,
  '28042': 14,
  '28043': 14,
  '28044': 14,
  '28045': 14,
  '28046': 14,
  '28047': 14,
  '28048': 14,
  '28049': 14,
  '2804A': 14,
  '2804B': 14,
  '2804C': 14,
  '2804D': 14,
  '2804E': 14,
  '2804F': 14,
  '28050': 14,
  '28051': 14,
  '28052': 14,
  '28053': 14,
  '28054': 14,
  '28055': 14,
  '28056': 14,
  '28057': 14,
  '28058': 14,
  '28059': 14,
  '2805A': 14,
  '2805B': 14,
  '2805C': 14,
  '2805D': 14,
  '2805E': 14,
  '2805F': 14,
  '28060': 14,
  '28061': 14,
  '28062': 14,
  '28063': 14,
  '28064': 14,
  '28065': 14,
  '28066': 14,
  '28067': 14,
  '28068': 14,
  '28069': 14,
  '2806A': 14,
  '2806B': 14,
  '2806C': 14,
  '2806D': 14,
  '2806E': 14,
  '2806F': 14,
  '28070': 14,
  '28071': 14,
  '28072': 14,
  '28073': 14,
  '28074': 14,
  '28075': 15,
  '28076': 15,
  '28077': 15,
  '28078': 15,
  '28079': 15,
  '2807A': 15,
  '2807B': 15,
  '2807C': 15,
  '2807D': 15,
  '2807E': 15,
  '2807F': 15,
  '28080': 15,
  '28081': 15,
  '28082': 15,
  '28083': 15,
  '28084': 15,
  '28085': 15,
  '28086': 15,
  '28087': 15,
  '28088': 15,
  '28089': 15,
  '2808A': 15,
  '2808B': 15,
  '2808C': 15,
  '2808D': 15,
  '2808E': 15,
  '2808F': 15,
  '28090': 15,
  '28091': 15,
  '28092': 15,
  '28093': 15,
  '28094': 15,
  '28095': 15,
  '28096': 15,
  '28097': 15,
  '28098': 15,
  '28099': 15,
  '2809A': 15,
  '2809B': 15,
  '2809C': 15,
  '2809D': 16,
  '2809E': 16,
  '2809F': 15,
  '280A0': 16,
  '280A1': 16,
  '280A2': 16,
  '280A3': 16,
  '280A4': 16,
  '280A5': 16,
  '280A6': 16,
  '280A7': 16,
  '280A8': 16,
  '280A9': 16,
  '280AA': 16,
  '280AB': 16,
  '280AC': 16,
  '280AD': 16,
  '280AE': 16,
  '280AF': 16,
  '280B0': 16,
  '280B1': 16,
  '280B2': 16,
  '280B3': 16,
  '280B4': 16,
  '280B5': 16,
  '280B6': 16,
  '280B7': 16,
  '280B8': 16,
  '280B9': 16,
  '280BA': 16,
  '280BB': 16,
  '280BC': 16,
  '280BD': 16,
  '280BE': 16,
  '280BF': 16,
  '280C0': 16,
  '280C1': 16,
  '280C2': 16,
  '280C3': 16,
  '280C4': 16,
  '280C5': 16,
  '280C6': 16,
  '280C7': 16,
  '280C8': 16,
  '280C9': 16,
  '280CA': 16,
  '280CB': 16,
  '280CC': 16,
  '280CD': 16,
  '280CE': 16,
  '280CF': 16,
  '280D0': 16,
  '280D1': 16,
  '280D2': 16,
  '280D3': 17,
  '280D4': 17,
  '280D5': 17,
  '280D6': 17,
  '280D7': 17,
  '280D8': 17,
  '280D9': 17,
  '280DA': 17,
  '280DB': 17,
  '280DC': 17,
  '280DD': 17,
  '280DE': 17,
  '280DF': 17,
  '280E0': 17,
  '280E1': 17,
  '280E2': 17,
  '280E3': 17,
  '280E4': 17,
  '280E5': 17,
  '280E6': 17,
  '280E7': 17,
  '280E8': 17,
  '280E9': 17,
  '280EA': 17,
  '280EB': 17,
  '280EC': 17,
  '280ED': 17,
  '280EE': 17,
  '280EF': 17,
  '280F0': 17,
  '280F1': 17,
  '280F2': 17,
  '280F3': 17,
  '280F4': 17,
  '280F5': 17,
  '280F6': 17,
  '280F7': 17,
  '280F8': 17,
  '280F9': 17,
  '280FA': 17,
  '280FB': 17,
  '280FC': 17,
  '280FD': 17,
  '280FE': 17,
  '280FF': 17,
  '28100': 17,
  '28101': 17,
  '28102': 17,
  '28103': 18,
  '28104': 17,
  '28105': 18,
  '28106': 18,
  '28107': 18,
  '28108': 18,
  '28109': 18,
  '2810A': 18,
  '2810B': 18,
  '2810C': 18,
  '2810D': 18,
  '2810E': 18,
  '2810F': 18,
  '28110': 18,
  '28111': 18,
  '28112': 18,
  '28113': 18,
  '28114': 18,
  '28115': 18,
  '28116': 18,
  '28117': 18,
  '28118': 18,
  '28119': 18,
  '2811A': 18,
  '2811B': 18,
  '2811C': 18,
  '2811D': 18,
  '2811E': 18,
  '2811F': 18,
  '28120': 18,
  '28121': 18,
  '28122': 18,
  '28123': 18,
  '28124': 18,
  '28125': 18,
  '28126': 18,
  '28127': 18,
  '28128': 18,
  '28129': 18,
  '2812A': 18,
  '2812B': 18,
  '2812C': 18,
  '2812D': 18,
  '2812E': 18,
  '2812F': 18,
  '28130': 18,
  '28131': 18,
  '28132': 18,
  '28133': 18,
  '28134': 18,
  '28135': 18,
  '28136': 18,
  '28137': 18,
  '28138': 18,
  '28139': 18,
  '2813A': 18,
  '2813B': 18,
  '2813C': 18,
  '2813D': 18,
  '2813E': 18,
  '2813F': 18,
  '28140': 18,
  '28141': 18,
  '28142': 18,
  '28143': 18,
  '28144': 18,
  '28145': 18,
  '28146': 18,
  '28147': 18,
  '28148': 18,
  '28149': 18,
  '2814A': 19,
  '2814B': 19,
  '2814C': 19,
  '2814D': 19,
  '2814E': 19,
  '2814F': 19,
  '28150': 19,
  '28151': 19,
  '28152': 19,
  '28153': 19,
  '28154': 19,
  '28155': 19,
  '28156': 19,
  '28157': 19,
  '28158': 19,
  '28159': 19,
  '2815A': 18,
  '2815B': 19,
  '2815C': 19,
  '2815D': 19,
  '2815E': 19,
  '2815F': 19,
  '28160': 19,
  '28161': 19,
  '28162': 19,
  '28163': 19,
  '28164': 19,
  '28165': 19,
  '28166': 19,
  '28167': 19,
  '28168': 19,
  '28169': 19,
  '2816A': 19,
  '2816B': 19,
  '2816C': 19,
  '2816D': 19,
  '2816E': 19,
  '2816F': 19,
  '28170': 19,
  '28171': 19,
  '28172': 19,
  '28173': 19,
  '28174': 19,
  '28175': 19,
  '28176': 19,
  '28177': 19,
  '28178': 19,
  '28179': 19,
  '2817A': 19,
  '2817B': 19,
  '2817C': 19,
  '2817D': 19,
  '2817E': 19,
  '2817F': 19,
  '28180': 19,
  '28181': 20,
  '28182': 20,
  '28183': 20,
  '28184': 20,
  '28185': 20,
  '28186': 20,
  '28187': 20,
  '28188': 20,
  '28189': 20,
  '2818A': 20,
  '2818B': 20,
  '2818C': 20,
  '2818D': 20,
  '2818E': 20,
  '2818F': 20,
  '28190': 20,
  '28191': 20,
  '28192': 20,
  '28193': 20,
  '28194': 20,
  '28195': 20,
  '28196': 20,
  '28197': 20,
  '28198': 20,
  '28199': 20,
  '2819A': 20,
  '2819B': 20,
  '2819C': 20,
  '2819D': 20,
  '2819E': 20,
  '2819F': 20,
  '281A0': 20,
  '281A1': 20,
  '281A2': 20,
  '281A3': 20,
  '281A4': 20,
  '281A5': 20,
  '281A6': 20,
  '281A7': 20,
  '281A8': 20,
  '281A9': 20,
  '281AA': 21,
  '281AB': 21,
  '281AC': 21,
  '281AD': 21,
  '281AE': 21,
  '281AF': 21,
  '281B0': 21,
  '281B1': 21,
  '281B2': 21,
  '281B3': 21,
  '281B4': 21,
  '281B5': 21,
  '281B6': 21,
  '281B7': 21,
  '281B8': 21,
  '281B9': 21,
  '281BA': 21,
  '281BB': 21,
  '281BC': 21,
  '281BD': 21,
  '281BE': 21,
  '281BF': 21,
  '281C0': 21,
  '281C1': 22,
  '281C2': 22,
  '281C3': 22,
  '281C4': 22,
  '281C5': 22,
  '281C6': 22,
  '281C7': 22,
  '281C8': 22,
  '281C9': 22,
  '281CA': 22,
  '281CB': 22,
  '281CC': 22,
  '281CD': 22,
  '281CE': 22,
  '281CF': 22,
  '281D0': 22,
  '281D1': 22,
  '281D2': 22,
  '281D3': 22,
  '281D4': 22,
  '281D5': 22,
  '281D6': 23,
  '281D7': 23,
  '281D8': 23,
  '281D9': 23,
  '281DA': 23,
  '281DB': 23,
  '281DC': 23,
  '281DD': 23,
  '281DE': 23,
  '281DF': 23,
  '281E0': 23,
  '281E1': 23,
  '281E2': 23,
  '281E3': 23,
  '281E4': 24,
  '281E5': 24,
  '281E6': 24,
  '281E7': 24,
  '281E8': 24,
  '281E9': 24,
  '281EA': 24,
  '281EB': 24,
  '281EC': 24,
  '281ED': 24,
  '281EE': 25,
  '281EF': 25,
  '281F0': 25,
  '281F1': 25,
  '281F2': 25,
  '281F3': 25,
  '281F4': 25,
  '281F5': 25,
  '281F6': 25,
  '281F7': 25,
  '281F8': 25,
  '281F9': 25,
  '281FA': 25,
  '281FB': 26,
  '281FC': 26,
  '281FD': 26,
  '281FE': 26,
  '281FF': 26,
  '28200': 26,
  '28201': 27,
  '28202': 27,
  '28203': 27,
  '28204': 28,
  '28205': 28,
  '28206': 28,
  '28207': 28,
  '28208': 29,
  '28209': 29,
  '2820A': 29,
  '2820B': 30,
  '2820C': 30,
  '2820D': 31,
  '2820E': 37,
  '2820F': 7,
  '28210': 7,
  '28211': 7,
  '28212': 9,
  '28213': 10,
  '28214': 10,
  '28215': 10,
  '28216': 10,
  '28217': 10,
  '28218': 11,
  '28219': 11,
  '2821A': 11,
  '2821B': 11,
  '2821C': 11,
  '2821D': 11,
  '2821E': 11,
  '2821F': 11,
  '28220': 11,
  '28221': 11,
  '28222': 11,
  '28223': 11,
  '28224': 11,
  '28225': 11,
  '28226': 11,
  '28227': 11,
  '28228': 11,
  '28229': 12,
  '2822A': 12,
  '2822B': 12,
  '2822C': 12,
  '2822D': 12,
  '2822E': 12,
  '2822F': 12,
  '28230': 12,
  '28231': 12,
  '28232': 12,
  '28233': 12,
  '28234': 12,
  '28235': 12,
  '28236': 12,
  '28237': 12,
  '28238': 13,
  '28239': 13,
  '2823A': 13,
  '2823B': 13,
  '2823C': 13,
  '2823D': 13,
  '2823E': 13,
  '2823F': 13,
  '28240': 13,
  '28241': 13,
  '28242': 13,
  '28243': 13,
  '28244': 13,
  '28245': 13,
  '28246': 13,
  '28247': 13,
  '28248': 14,
  '28249': 14,
  '2824A': 14,
  '2824B': 14,
  '2824C': 14,
  '2824D': 14,
  '2824E': 14,
  '2824F': 14,
  '28250': 14,
  '28251': 14,
  '28252': 14,
  '28253': 14,
  '28254': 15,
  '28255': 15,
  '28256': 15,
  '28257': 15,
  '28258': 15,
  '28259': 15,
  '2825A': 15,
  '2825B': 15,
  '2825C': 15,
  '2825D': 15,
  '2825E': 15,
  '2825F': 15,
  '28260': 15,
  '28261': 15,
  '28262': 16,
  '28263': 16,
  '28264': 16,
  '28265': 16,
  '28266': 17,
  '28267': 16,
  '28268': 16,
  '28269': 16,
  '2826A': 16,
  '2826B': 16,
  '2826C': 16,
  '2826D': 16,
  '2826E': 17,
  '2826F': 17,
  '28270': 17,
  '28271': 17,
  '28272': 17,
  '28273': 17,
  '28274': 17,
  '28275': 17,
  '28276': 17,
  '28277': 17,
  '28278': 17,
  '28279': 18,
  '2827A': 18,
  '2827B': 18,
  '2827C': 18,
  '2827D': 18,
  '2827E': 18,
  '2827F': 19,
  '28280': 19,
  '28281': 19,
  '28282': 19,
  '28283': 19,
  '28284': 19,
  '28285': 19,
  '28286': 19,
  '28287': 19,
  '28288': 19,
  '28289': 19,
  '2828A': 19,
  '2828B': 19,
  '2828C': 19,
  '2828D': 20,
  '2828E': 20,
  '2828F': 20,
  '28290': 20,
  '28291': 20,
  '28292': 20,
  '28293': 21,
  '28294': 21,
  '28295': 21,
  '28296': 21,
  '28297': 21,
  '28298': 22,
  '28299': 22,
  '2829A': 22,
  '2829B': 23,
  '2829C': 23,
  '2829D': 25,
  '2829E': 25,
  '2829F': 26,
  '282A0': 9,
  '282A1': 9,
  '282A2': 9,
  '282A3': 9,
  '282A4': 9,
  '282A5': 9,
  '282A6': 9,
  '282A7': 10,
  '282A8': 10,
  '282A9': 10,
  '282AA': 10,
  '282AB': 10,
  '282AC': 10,
  '282AD': 10,
  '282AE': 10,
  '282AF': 10,
  '282B0': 10,
  '282B1': 10,
  '282B2': 10,
  '282B3': 11,
  '282B4': 11,
  '282B5': 11,
  '282B6': 11,
  '282B7': 11,
  '282B8': 11,
  '282B9': 11,
  '282BA': 11,
  '282BB': 11,
  '282BC': 11,
  '282BD': 11,
  '282BE': 11,
  '282BF': 11,
  '282C0': 11,
  '282C1': 11,
  '282C2': 11,
  '282C3': 11,
  '282C4': 11,
  '282C5': 11,
  '282C6': 11,
  '282C7': 11,
  '282C8': 11,
  '282C9': 11,
  '282CA': 11,
  '282CB': 11,
  '282CC': 11,
  '282CD': 11,
  '282CE': 12,
  '282CF': 12,
  '282D0': 12,
  '282D1': 12,
  '282D2': 12,
  '282D3': 12,
  '282D4': 12,
  '282D5': 12,
  '282D6': 12,
  '282D7': 12,
  '282D8': 12,
  '282D9': 12,
  '282DA': 12,
  '282DB': 12,
  '282DC': 12,
  '282DD': 12,
  '282DE': 12,
  '282DF': 12,
  '282E0': 12,
  '282E1': 12,
  '282E2': 12,
  '282E3': 12,
  '282E4': 12,
  '282E5': 12,
  '282E6': 12,
  '282E7': 12,
  '282E8': 13,
  '282E9': 13,
  '282EA': 13,
  '282EB': 13,
  '282EC': 13,
  '282ED': 13,
  '282EE': 13,
  '282EF': 13,
  '282F0': 13,
  '282F1': 13,
  '282F2': 13,
  '282F3': 13,
  '282F4': 13,
  '282F5': 13,
  '282F6': 13,
  '282F7': 13,
  '282F8': 13,
  '282F9': 13,
  '282FA': 13,
  '282FB': 13,
  '282FC': 13,
  '282FD': 13,
  '282FE': 13,
  '282FF': 13,
  '28300': 13,
  '28301': 13,
  '28302': 14,
  '28303': 14,
  '28304': 14,
  '28305': 14,
  '28306': 14,
  '28307': 14,
  '28308': 14,
  '28309': 14,
  '2830A': 14,
  '2830B': 14,
  '2830C': 14,
  '2830D': 14,
  '2830E': 14,
  '2830F': 14,
  '28310': 14,
  '28311': 14,
  '28312': 14,
  '28313': 14,
  '28314': 14,
  '28315': 14,
  '28316': 14,
  '28317': 14,
  '28318': 14,
  '28319': 14,
  '2831A': 14,
  '2831B': 14,
  '2831C': 14,
  '2831D': 14,
  '2831E': 14,
  '2831F': 14,
  '28320': 15,
  '28321': 15,
  '28322': 15,
  '28323': 15,
  '28324': 15,
  '28325': 15,
  '28326': 15,
  '28327': 15,
  '28328': 15,
  '28329': 15,
  '2832A': 15,
  '2832B': 15,
  '2832C': 15,
  '2832D': 15,
  '2832E': 15,
  '2832F': 15,
  '28330': 15,
  '28331': 15,
  '28332': 15,
  '28333': 15,
  '28334': 15,
  '28335': 15,
  '28336': 15,
  '28337': 15,
  '28338': 15,
  '28339': 15,
  '2833A': 15,
  '2833B': 15,
  '2833C': 15,
  '2833D': 15,
  '2833E': 15,
  '2833F': 15,
  '28340': 15,
  '28341': 15,
  '28342': 15,
  '28343': 15,
  '28344': 15,
  '28345': 15,
  '28346': 15,
  '28347': 16,
  '28348': 16,
  '28349': 16,
  '2834A': 16,
  '2834B': 16,
  '2834C': 16,
  '2834D': 16,
  '2834E': 16,
  '2834F': 16,
  '28350': 16,
  '28351': 16,
  '28352': 16,
  '28353': 16,
  '28354': 16,
  '28355': 16,
  '28356': 16,
  '28357': 16,
  '28358': 16,
  '28359': 16,
  '2835A': 16,
  '2835B': 16,
  '2835C': 16,
  '2835D': 16,
  '2835E': 16,
  '2835F': 16,
  '28360': 16,
  '28361': 16,
  '28362': 16,
  '28363': 16,
  '28364': 16,
  '28365': 16,
  '28366': 16,
  '28367': 16,
  '28368': 16,
  '28369': 17,
  '2836A': 17,
  '2836B': 17,
  '2836C': 17,
  '2836D': 17,
  '2836E': 17,
  '2836F': 17,
  '28370': 17,
  '28371': 17,
  '28372': 17,
  '28373': 17,
  '28374': 17,
  '28375': 17,
  '28376': 17,
  '28377': 17,
  '28378': 17,
  '28379': 17,
  '2837A': 17,
  '2837B': 17,
  '2837C': 17,
  '2837D': 17,
  '2837E': 17,
  '2837F': 17,
  '28380': 17,
  '28381': 17,
  '28382': 17,
  '28383': 17,
  '28384': 17,
  '28385': 17,
  '28386': 17,
  '28387': 17,
  '28388': 17,
  '28389': 17,
  '2838A': 18,
  '2838B': 18,
  '2838C': 18,
  '2838D': 18,
  '2838E': 18,
  '2838F': 18,
  '28390': 18,
  '28391': 18,
  '28392': 18,
  '28393': 18,
  '28394': 18,
  '28395': 18,
  '28396': 18,
  '28397': 18,
  '28398': 18,
  '28399': 18,
  '2839A': 18,
  '2839B': 18,
  '2839C': 18,
  '2839D': 18,
  '2839E': 18,
  '2839F': 18,
  '283A0': 18,
  '283A1': 18,
  '283A2': 18,
  '283A3': 18,
  '283A4': 19,
  '283A5': 19,
  '283A6': 19,
  '283A7': 19,
  '283A8': 19,
  '283A9': 19,
  '283AA': 19,
  '283AB': 19,
  '283AC': 19,
  '283AD': 19,
  '283AE': 19,
  '283AF': 19,
  '283B0': 19,
  '283B1': 19,
  '283B2': 20,
  '283B3': 20,
  '283B4': 20,
  '283B5': 20,
  '283B6': 20,
  '283B7': 20,
  '283B8': 20,
  '283B9': 20,
  '283BA': 20,
  '283BB': 20,
  '283BC': 20,
  '283BD': 20,
  '283BE': 20,
  '283BF': 20,
  '283C0': 20,
  '283C1': 20,
  '283C2': 20,
  '283C3': 20,
  '283C4': 20,
  '283C5': 20,
  '283C6': 20,
  '283C7': 20,
  '283C8': 21,
  '283C9': 21,
  '283CA': 21,
  '283CB': 21,
  '283CC': 21,
  '283CD': 21,
  '283CE': 21,
  '283CF': 21,
  '283D0': 21,
  '283D1': 22,
  '283D2': 22,
  '283D3': 22,
  '283D4': 22,
  '283D5': 22,
  '283D6': 22,
  '283D7': 22,
  '283D8': 22,
  '283D9': 22,
  '283DA': 22,
  '283DB': 22,
  '283DC': 22,
  '283DD': 22,
  '283DE': 23,
  '283DF': 23,
  '283E0': 23,
  '283E1': 23,
  '283E2': 23,
  '283E3': 23,
  '283E4': 23,
  '283E5': 23,
  '283E6': 23,
  '283E7': 23,
  '283E8': 23,
  '283E9': 24,
  '283EA': 24,
  '283EB': 24,
  '283EC': 24,
  '283ED': 24,
  '283EE': 24,
  '283EF': 24,
  '283F0': 24,
  '283F1': 24,
  '283F2': 24,
  '283F3': 25,
  '283F4': 25,
  '283F5': 26,
  '283F6': 26,
  '283F7': 26,
  '283F8': 26,
  '283F9': 27,
  '283FA': 28,
  '283FB': 27,
  '283FC': 28,
  '283FD': 26,
  '283FE': 27,
  '283FF': 28,
  '28400': 28,
  '28401': 29,
  '28402': 30,
  '28403': 31,
  '28404': 38,
  '28405': 8,
  '28406': 8,
  '28407': 9,
  '28408': 10,
  '28409': 16,
  '2840A': 20,
  '2840B': 7,
  '2840C': 8,
  '2840D': 10,
  '2840E': 10,
  '2840F': 11,
  '28410': 11,
  '28411': 11,
  '28412': 12,
  '28413': 12,
  '28414': 13,
  '28415': 13,
  '28416': 13,
  '28417': 13,
  '28418': 14,
  '28419': 14,
  '2841A': 14,
  '2841B': 14,
  '2841C': 15,
  '2841D': 15,
  '2841E': 15,
  '2841F': 15,
  '28420': 16,
  '28421': 16,
  '28422': 16,
  '28423': 16,
  '28424': 16,
  '28425': 16,
  '28426': 16,
  '28427': 16,
  '28428': 17,
  '28429': 17,
  '2842A': 17,
  '2842B': 17,
  '2842C': 17,
  '2842D': 17,
  '2842E': 17,
  '2842F': 17,
  '28430': 18,
  '28431': 18,
  '28432': 18,
  '28433': 18,
  '28434': 18,
  '28435': 19,
  '28436': 19,
  '28437': 19,
  '28438': 19,
  '28439': 19,
  '2843A': 20,
  '2843B': 20,
  '2843C': 20,
  '2843D': 21,
  '2843E': 22,
  '2843F': 22,
  '28440': 24,
  '28441': 29,
  '28442': 30,
  '28443': 7,
  '28444': 7,
  '28445': 13,
  '28446': 13,
  '28447': 13,
  '28448': 15,
  '28449': 16,
  '2844A': 19,
  '2844B': 21,
  '2844C': 22,
  '2844D': 5,
  '2844E': 5,
  '2844F': 5,
  '28450': 5,
  '28451': 6,
  '28452': 6,
  '28453': 6,
  '28454': 6,
  '28455': 6,
  '28456': 6,
  '28457': 6,
  '28458': 6,
  '28459': 6,
  '2845A': 6,
  '2845B': 6,
  '2845C': 6,
  '2845D': 6,
  '2845E': 6,
  '2845F': 6,
  '28460': 6,
  '28461': 6,
  '28462': 7,
  '28463': 7,
  '28464': 7,
  '28465': 7,
  '28466': 7,
  '28467': 7,
  '28468': 7,
  '28469': 7,
  '2846A': 7,
  '2846B': 7,
  '2846C': 7,
  '2846D': 7,
  '2846E': 7,
  '2846F': 7,
  '28470': 7,
  '28471': 7,
  '28472': 7,
  '28473': 7,
  '28474': 7,
  '28475': 7,
  '28476': 7,
  '28477': 7,
  '28478': 7,
  '28479': 7,
  '2847A': 7,
  '2847B': 7,
  '2847C': 7,
  '2847D': 7,
  '2847E': 7,
  '2847F': 7,
  '28480': 7,
  '28481': 7,
  '28482': 8,
  '28483': 8,
  '28484': 8,
  '28485': 8,
  '28486': 8,
  '28487': 8,
  '28488': 8,
  '28489': 8,
  '2848A': 8,
  '2848B': 8,
  '2848C': 8,
  '2848D': 8,
  '2848E': 8,
  '2848F': 8,
  '28490': 8,
  '28491': 8,
  '28492': 8,
  '28493': 8,
  '28494': 8,
  '28495': 8,
  '28496': 8,
  '28497': 8,
  '28498': 8,
  '28499': 8,
  '2849A': 8,
  '2849B': 8,
  '2849C': 8,
  '2849D': 8,
  '2849E': 8,
  '2849F': 8,
  '284A0': 8,
  '284A1': 8,
  '284A2': 8,
  '284A3': 8,
  '284A4': 8,
  '284A5': 9,
  '284A6': 9,
  '284A7': 9,
  '284A8': 9,
  '284A9': 9,
  '284AA': 9,
  '284AB': 9,
  '284AC': 9,
  '284AD': 9,
  '284AE': 9,
  '284AF': 9,
  '284B0': 9,
  '284B1': 9,
  '284B2': 9,
  '284B3': 9,
  '284B4': 9,
  '284B5': 9,
  '284B6': 9,
  '284B7': 9,
  '284B8': 9,
  '284B9': 9,
  '284BA': 9,
  '284BB': 9,
  '284BC': 9,
  '284BD': 9,
  '284BE': 9,
  '284BF': 9,
  '284C0': 9,
  '284C1': 9,
  '284C2': 9,
  '284C3': 9,
  '284C4': 9,
  '284C5': 10,
  '284C6': 10,
  '284C7': 10,
  '284C8': 10,
  '284C9': 10,
  '284CA': 10,
  '284CB': 10,
  '284CC': 10,
  '284CD': 10,
  '284CE': 10,
  '284CF': 10,
  '284D0': 10,
  '284D1': 10,
  '284D2': 10,
  '284D3': 10,
  '284D4': 10,
  '284D5': 10,
  '284D6': 10,
  '284D7': 10,
  '284D8': 10,
  '284D9': 10,
  '284DA': 10,
  '284DB': 10,
  '284DC': 10,
  '284DD': 10,
  '284DE': 10,
  '284DF': 10,
  '284E0': 10,
  '284E1': 10,
  '284E2': 10,
  '284E3': 10,
  '284E4': 10,
  '284E5': 10,
  '284E6': 10,
  '284E7': 10,
  '284E8': 10,
  '284E9': 10,
  '284EA': 10,
  '284EB': 11,
  '284EC': 11,
  '284ED': 11,
  '284EE': 11,
  '284EF': 11,
  '284F0': 11,
  '284F1': 11,
  '284F2': 11,
  '284F3': 11,
  '284F4': 11,
  '284F5': 11,
  '284F6': 11,
  '284F7': 11,
  '284F8': 11,
  '284F9': 11,
  '284FA': 11,
  '284FB': 11,
  '284FC': 11,
  '284FD': 11,
  '284FE': 11,
  '284FF': 11,
  '28500': 11,
  '28501': 11,
  '28502': 11,
  '28503': 11,
  '28504': 11,
  '28505': 11,
  '28506': 11,
  '28507': 11,
  '28508': 11,
  '28509': 11,
  '2850A': 11,
  '2850B': 11,
  '2850C': 11,
  '2850D': 11,
  '2850E': 12,
  '2850F': 11,
  '28510': 11,
  '28511': 11,
  '28512': 11,
  '28513': 11,
  '28514': 11,
  '28515': 11,
  '28516': 11,
  '28517': 11,
  '28518': 11,
  '28519': 11,
  '2851A': 11,
  '2851B': 11,
  '2851C': 11,
  '2851D': 12,
  '2851E': 12,
  '2851F': 12,
  '28520': 11,
  '28521': 12,
  '28522': 12,
  '28523': 12,
  '28524': 12,
  '28525': 12,
  '28526': 12,
  '28527': 12,
  '28528': 12,
  '28529': 12,
  '2852A': 12,
  '2852B': 12,
  '2852C': 12,
  '2852D': 12,
  '2852E': 12,
  '2852F': 12,
  '28530': 12,
  '28531': 12,
  '28532': 12,
  '28533': 12,
  '28534': 12,
  '28535': 12,
  '28536': 12,
  '28537': 12,
  '28538': 12,
  '28539': 12,
  '2853A': 12,
  '2853B': 12,
  '2853C': 12,
  '2853D': 12,
  '2853E': 12,
  '2853F': 12,
  '28540': 12,
  '28541': 12,
  '28542': 12,
  '28543': 12,
  '28544': 12,
  '28545': 12,
  '28546': 12,
  '28547': 12,
  '28548': 12,
  '28549': 12,
  '2854A': 12,
  '2854B': 12,
  '2854C': 12,
  '2854D': 12,
  '2854E': 12,
  '2854F': 12,
  '28550': 12,
  '28551': 12,
  '28552': 12,
  '28553': 12,
  '28554': 12,
  '28555': 12,
  '28556': 12,
  '28557': 12,
  '28558': 12,
  '28559': 12,
  '2855A': 12,
  '2855B': 12,
  '2855C': 12,
  '2855D': 13,
  '2855E': 13,
  '2855F': 13,
  '28560': 13,
  '28561': 13,
  '28562': 13,
  '28563': 13,
  '28564': 13,
  '28565': 13,
  '28566': 13,
  '28567': 13,
  '28568': 13,
  '28569': 13,
  '2856A': 13,
  '2856B': 13,
  '2856C': 13,
  '2856D': 13,
  '2856E': 13,
  '2856F': 13,
  '28570': 13,
  '28571': 13,
  '28572': 13,
  '28573': 13,
  '28574': 13,
  '28575': 13,
  '28576': 13,
  '28577': 13,
  '28578': 13,
  '28579': 13,
  '2857A': 13,
  '2857B': 13,
  '2857C': 13,
  '2857D': 13,
  '2857E': 13,
  '2857F': 13,
  '28580': 13,
  '28581': 13,
  '28582': 13,
  '28583': 13,
  '28584': 13,
  '28585': 13,
  '28586': 14,
  '28587': 14,
  '28588': 14,
  '28589': 14,
  '2858A': 14,
  '2858B': 14,
  '2858C': 14,
  '2858D': 14,
  '2858E': 14,
  '2858F': 14,
  '28590': 14,
  '28591': 14,
  '28592': 14,
  '28593': 14,
  '28594': 14,
  '28595': 14,
  '28596': 14,
  '28597': 14,
  '28598': 14,
  '28599': 14,
  '2859A': 14,
  '2859B': 14,
  '2859C': 14,
  '2859D': 14,
  '2859E': 14,
  '2859F': 14,
  '285A0': 14,
  '285A1': 14,
  '285A2': 14,
  '285A3': 14,
  '285A4': 14,
  '285A5': 14,
  '285A6': 14,
  '285A7': 14,
  '285A8': 14,
  '285A9': 14,
  '285AA': 14,
  '285AB': 14,
  '285AC': 14,
  '285AD': 14,
  '285AE': 14,
  '285AF': 14,
  '285B0': 14,
  '285B1': 14,
  '285B2': 14,
  '285B3': 14,
  '285B4': 15,
  '285B5': 15,
  '285B6': 15,
  '285B7': 15,
  '285B8': 15,
  '285B9': 15,
  '285BA': 15,
  '285BB': 15,
  '285BC': 15,
  '285BD': 15,
  '285BE': 15,
  '285BF': 15,
  '285C0': 15,
  '285C1': 15,
  '285C2': 15,
  '285C3': 15,
  '285C4': 15,
  '285C5': 15,
  '285C6': 15,
  '285C7': 15,
  '285C8': 15,
  '285C9': 15,
  '285CA': 15,
  '285CB': 15,
  '285CC': 15,
  '285CD': 15,
  '285CE': 15,
  '285CF': 15,
  '285D0': 15,
  '285D1': 15,
  '285D2': 15,
  '285D3': 16,
  '285D4': 15,
  '285D5': 15,
  '285D6': 15,
  '285D7': 15,
  '285D8': 15,
  '285D9': 15,
  '285DA': 15,
  '285DB': 15,
  '285DC': 15,
  '285DD': 15,
  '285DE': 15,
  '285DF': 15,
  '285E0': 15,
  '285E1': 15,
  '285E2': 15,
  '285E3': 15,
  '285E4': 15,
  '285E5': 16,
  '285E6': 16,
  '285E7': 16,
  '285E8': 16,
  '285E9': 16,
  '285EA': 16,
  '285EB': 16,
  '285EC': 16,
  '285ED': 16,
  '285EE': 16,
  '285EF': 16,
  '285F0': 16,
  '285F1': 16,
  '285F2': 16,
  '285F3': 16,
  '285F4': 16,
  '285F5': 16,
  '285F6': 16,
  '285F7': 16,
  '285F8': 16,
  '285F9': 16,
  '285FA': 16,
  '285FB': 16,
  '285FC': 16,
  '285FD': 16,
  '285FE': 16,
  '285FF': 16,
  '28600': 16,
  '28601': 16,
  '28602': 16,
  '28603': 16,
  '28604': 16,
  '28605': 16,
  '28606': 16,
  '28607': 17,
  '28608': 17,
  '28609': 17,
  '2860A': 17,
  '2860B': 17,
  '2860C': 17,
  '2860D': 17,
  '2860E': 17,
  '2860F': 17,
  '28610': 17,
  '28611': 17,
  '28612': 17,
  '28613': 17,
  '28614': 17,
  '28615': 17,
  '28616': 17,
  '28617': 17,
  '28618': 17,
  '28619': 17,
  '2861A': 17,
  '2861B': 17,
  '2861C': 17,
  '2861D': 17,
  '2861E': 17,
  '2861F': 17,
  '28620': 17,
  '28621': 18,
  '28622': 18,
  '28623': 18,
  '28624': 18,
  '28625': 18,
  '28626': 18,
  '28627': 18,
  '28628': 18,
  '28629': 18,
  '2862A': 18,
  '2862B': 18,
  '2862C': 18,
  '2862D': 18,
  '2862E': 18,
  '2862F': 18,
  '28630': 18,
  '28631': 18,
  '28632': 18,
  '28633': 18,
  '28634': 18,
  '28635': 18,
  '28636': 18,
  '28637': 19,
  '28638': 19,
  '28639': 19,
  '2863A': 19,
  '2863B': 19,
  '2863C': 20,
  '2863D': 19,
  '2863E': 19,
  '2863F': 19,
  '28640': 19,
  '28641': 19,
  '28642': 20,
  '28643': 20,
  '28644': 20,
  '28645': 20,
  '28646': 20,
  '28647': 20,
  '28648': 20,
  '28649': 20,
  '2864A': 20,
  '2864B': 20,
  '2864C': 20,
  '2864D': 20,
  '2864E': 20,
  '2864F': 20,
  '28650': 20,
  '28651': 20,
  '28652': 21,
  '28653': 21,
  '28654': 21,
  '28655': 21,
  '28656': 21,
  '28657': 21,
  '28658': 21,
  '28659': 22,
  '2865A': 22,
  '2865B': 22,
  '2865C': 22,
  '2865D': 22,
  '2865E': 22,
  '2865F': 23,
  '28660': 23,
  '28661': 23,
  '28662': 23,
  '28663': 23,
  '28664': 24,
  '28665': 24,
  '28666': 25,
  '28667': 26,
  '28668': 2,
  '28669': 4,
  '2866A': 4,
  '2866B': 5,
  '2866C': 5,
  '2866D': 5,
  '2866E': 5,
  '2866F': 5,
  '28670': 5,
  '28671': 5,
  '28672': 5,
  '28673': 5,
  '28674': 5,
  '28675': 5,
  '28676': 6,
  '28677': 6,
  '28678': 6,
  '28679': 6,
  '2867A': 6,
  '2867B': 6,
  '2867C': 6,
  '2867D': 6,
  '2867E': 6,
  '2867F': 6,
  '28680': 6,
  '28681': 6,
  '28682': 6,
  '28683': 6,
  '28684': 6,
  '28685': 6,
  '28686': 6,
  '28687': 6,
  '28688': 6,
  '28689': 6,
  '2868A': 6,
  '2868B': 6,
  '2868C': 6,
  '2868D': 6,
  '2868E': 6,
  '2868F': 6,
  '28690': 6,
  '28691': 6,
  '28692': 6,
  '28693': 7,
  '28694': 7,
  '28695': 7,
  '28696': 7,
  '28697': 7,
  '28698': 7,
  '28699': 7,
  '2869A': 7,
  '2869B': 7,
  '2869C': 7,
  '2869D': 7,
  '2869E': 7,
  '2869F': 7,
  '286A0': 7,
  '286A1': 7,
  '286A2': 7,
  '286A3': 7,
  '286A4': 7,
  '286A5': 7,
  '286A6': 7,
  '286A7': 7,
  '286A8': 7,
  '286A9': 7,
  '286AA': 7,
  '286AB': 7,
  '286AC': 7,
  '286AD': 7,
  '286AE': 7,
  '286AF': 8,
  '286B0': 8,
  '286B1': 8,
  '286B2': 8,
  '286B3': 8,
  '286B4': 8,
  '286B5': 8,
  '286B6': 8,
  '286B7': 8,
  '286B8': 8,
  '286B9': 8,
  '286BA': 8,
  '286BB': 8,
  '286BC': 8,
  '286BD': 8,
  '286BE': 8,
  '286BF': 8,
  '286C0': 8,
  '286C1': 8,
  '286C2': 8,
  '286C3': 8,
  '286C4': 8,
  '286C5': 8,
  '286C6': 8,
  '286C7': 8,
  '286C8': 8,
  '286C9': 8,
  '286CA': 8,
  '286CB': 9,
  '286CC': 9,
  '286CD': 9,
  '286CE': 9,
  '286CF': 9,
  '286D0': 9,
  '286D1': 9,
  '286D2': 9,
  '286D3': 9,
  '286D4': 9,
  '286D5': 9,
  '286D6': 9,
  '286D7': 9,
  '286D8': 9,
  '286D9': 9,
  '286DA': 9,
  '286DB': 9,
  '286DC': 9,
  '286DD': 9,
  '286DE': 9,
  '286DF': 9,
  '286E0': 9,
  '286E1': 9,
  '286E2': 9,
  '286E3': 9,
  '286E4': 9,
  '286E5': 9,
  '286E6': 9,
  '286E7': 9,
  '286E8': 9,
  '286E9': 9,
  '286EA': 9,
  '286EB': 10,
  '286EC': 10,
  '286ED': 10,
  '286EE': 10,
  '286EF': 10,
  '286F0': 10,
  '286F1': 10,
  '286F2': 10,
  '286F3': 10,
  '286F4': 10,
  '286F5': 10,
  '286F6': 10,
  '286F7': 10,
  '286F8': 10,
  '286F9': 10,
  '286FA': 10,
  '286FB': 10,
  '286FC': 10,
  '286FD': 10,
  '286FE': 10,
  '286FF': 10,
  '28700': 10,
  '28701': 10,
  '28702': 10,
  '28703': 10,
  '28704': 10,
  '28705': 10,
  '28706': 10,
  '28707': 10,
  '28708': 10,
  '28709': 10,
  '2870A': 10,
  '2870B': 10,
  '2870C': 10,
  '2870D': 10,
  '2870E': 10,
  '2870F': 11,
  '28710': 11,
  '28711': 11,
  '28712': 11,
  '28713': 11,
  '28714': 11,
  '28715': 11,
  '28716': 11,
  '28717': 12,
  '28718': 11,
  '28719': 11,
  '2871A': 11,
  '2871B': 11,
  '2871C': 11,
  '2871D': 11,
  '2871E': 11,
  '2871F': 11,
  '28720': 11,
  '28721': 11,
  '28722': 11,
  '28723': 11,
  '28724': 11,
  '28725': 11,
  '28726': 11,
  '28727': 11,
  '28728': 11,
  '28729': 11,
  '2872A': 11,
  '2872B': 11,
  '2872C': 11,
  '2872D': 11,
  '2872E': 11,
  '2872F': 11,
  '28730': 11,
  '28731': 11,
  '28732': 12,
  '28733': 12,
  '28734': 12,
  '28735': 12,
  '28736': 12,
  '28737': 12,
  '28738': 12,
  '28739': 12,
  '2873A': 12,
  '2873B': 12,
  '2873C': 12,
  '2873D': 12,
  '2873E': 12,
  '2873F': 12,
  '28740': 12,
  '28741': 12,
  '28742': 12,
  '28743': 12,
  '28744': 12,
  '28745': 12,
  '28746': 12,
  '28747': 12,
  '28748': 12,
  '28749': 12,
  '2874A': 12,
  '2874B': 13,
  '2874C': 13,
  '2874D': 13,
  '2874E': 13,
  '2874F': 13,
  '28750': 13,
  '28751': 13,
  '28752': 13,
  '28753': 13,
  '28754': 13,
  '28755': 13,
  '28756': 13,
  '28757': 13,
  '28758': 13,
  '28759': 13,
  '2875A': 13,
  '2875B': 13,
  '2875C': 13,
  '2875D': 13,
  '2875E': 13,
  '2875F': 13,
  '28760': 13,
  '28761': 13,
  '28762': 13,
  '28763': 13,
  '28764': 13,
  '28765': 13,
  '28766': 13,
  '28767': 13,
  '28768': 13,
  '28769': 13,
  '2876A': 13,
  '2876B': 14,
  '2876C': 14,
  '2876D': 14,
  '2876E': 14,
  '2876F': 14,
  '28770': 14,
  '28771': 14,
  '28772': 14,
  '28773': 14,
  '28774': 14,
  '28775': 14,
  '28776': 14,
  '28777': 14,
  '28778': 14,
  '28779': 13,
  '2877A': 14,
  '2877B': 14,
  '2877C': 14,
  '2877D': 14,
  '2877E': 14,
  '2877F': 14,
  '28780': 14,
  '28781': 14,
  '28782': 14,
  '28783': 14,
  '28784': 14,
  '28785': 14,
  '28786': 14,
  '28787': 14,
  '28788': 14,
  '28789': 14,
  '2878A': 14,
  '2878B': 14,
  '2878C': 14,
  '2878D': 14,
  '2878E': 14,
  '2878F': 14,
  '28790': 14,
  '28791': 15,
  '28792': 15,
  '28793': 15,
  '28794': 15,
  '28795': 15,
  '28796': 15,
  '28797': 15,
  '28798': 15,
  '28799': 15,
  '2879A': 15,
  '2879B': 15,
  '2879C': 15,
  '2879D': 15,
  '2879E': 15,
  '2879F': 15,
  '287A0': 15,
  '287A1': 15,
  '287A2': 15,
  '287A3': 15,
  '287A4': 15,
  '287A5': 15,
  '287A6': 15,
  '287A7': 16,
  '287A8': 16,
  '287A9': 16,
  '287AA': 16,
  '287AB': 16,
  '287AC': 16,
  '287AD': 16,
  '287AE': 16,
  '287AF': 16,
  '287B0': 16,
  '287B1': 16,
  '287B2': 16,
  '287B3': 16,
  '287B4': 16,
  '287B5': 16,
  '287B6': 16,
  '287B7': 16,
  '287B8': 16,
  '287B9': 16,
  '287BA': 17,
  '287BB': 17,
  '287BC': 17,
  '287BD': 17,
  '287BE': 17,
  '287BF': 17,
  '287C0': 17,
  '287C1': 17,
  '287C2': 17,
  '287C3': 17,
  '287C4': 17,
  '287C5': 17,
  '287C6': 17,
  '287C7': 17,
  '287C8': 17,
  '287C9': 17,
  '287CA': 17,
  '287CB': 17,
  '287CC': 17,
  '287CD': 18,
  '287CE': 18,
  '287CF': 18,
  '287D0': 18,
  '287D1': 18,
  '287D2': 18,
  '287D3': 18,
  '287D4': 18,
  '287D5': 18,
  '287D6': 18,
  '287D7': 18,
  '287D8': 18,
  '287D9': 19,
  '287DA': 19,
  '287DB': 19,
  '287DC': 19,
  '287DD': 19,
  '287DE': 19,
  '287DF': 19,
  '287E0': 20,
  '287E1': 20,
  '287E2': 20,
  '287E3': 20,
  '287E4': 21,
  '287E5': 21,
  '287E6': 21,
  '287E7': 21,
  '287E8': 22,
  '287E9': 23,
  '287EA': 23,
  '287EB': 23,
  '287EC': 23,
  '287ED': 23,
  '287EE': 23,
  '287EF': 26,
  '287F0': 10,
  '287F1': 10,
  '287F2': 10,
  '287F3': 10,
  '287F4': 11,
  '287F5': 11,
  '287F6': 11,
  '287F7': 11,
  '287F8': 11,
  '287F9': 11,
  '287FA': 11,
  '287FB': 11,
  '287FC': 11,
  '287FD': 11,
  '287FE': 11,
  '287FF': 11,
  '28800': 11,
  '28801': 11,
  '28802': 11,
  '28803': 11,
  '28804': 11,
  '28805': 11,
  '28806': 11,
  '28807': 11,
  '28808': 11,
  '28809': 11,
  '2880A': 11,
  '2880B': 12,
  '2880C': 12,
  '2880D': 12,
  '2880E': 12,
  '2880F': 12,
  '28810': 12,
  '28811': 12,
  '28812': 12,
  '28813': 12,
  '28814': 12,
  '28815': 12,
  '28816': 12,
  '28817': 12,
  '28818': 12,
  '28819': 12,
  '2881A': 12,
  '2881B': 12,
  '2881C': 12,
  '2881D': 12,
  '2881E': 12,
  '2881F': 12,
  '28820': 12,
  '28821': 12,
  '28822': 12,
  '28823': 12,
  '28824': 13,
  '28825': 13,
  '28826': 13,
  '28827': 13,
  '28828': 13,
  '28829': 13,
  '2882A': 13,
  '2882B': 13,
  '2882C': 13,
  '2882D': 13,
  '2882E': 13,
  '2882F': 13,
  '28830': 13,
  '28831': 13,
  '28832': 13,
  '28833': 13,
  '28834': 13,
  '28835': 13,
  '28836': 13,
  '28837': 14,
  '28838': 14,
  '28839': 14,
  '2883A': 14,
  '2883B': 14,
  '2883C': 14,
  '2883D': 14,
  '2883E': 14,
  '2883F': 14,
  '28840': 14,
  '28841': 14,
  '28842': 14,
  '28843': 14,
  '28844': 14,
  '28845': 14,
  '28846': 14,
  '28847': 14,
  '28848': 14,
  '28849': 14,
  '2884A': 14,
  '2884B': 14,
  '2884C': 15,
  '2884D': 15,
  '2884E': 15,
  '2884F': 15,
  '28850': 15,
  '28851': 15,
  '28852': 15,
  '28853': 15,
  '28854': 15,
  '28855': 15,
  '28856': 15,
  '28857': 15,
  '28858': 15,
  '28859': 15,
  '2885A': 15,
  '2885B': 15,
  '2885C': 15,
  '2885D': 15,
  '2885E': 15,
  '2885F': 15,
  '28860': 15,
  '28861': 15,
  '28862': 15,
  '28863': 15,
  '28864': 15,
  '28865': 15,
  '28866': 15,
  '28867': 15,
  '28868': 15,
  '28869': 16,
  '2886A': 16,
  '2886B': 16,
  '2886C': 16,
  '2886D': 16,
  '2886E': 16,
  '2886F': 16,
  '28870': 16,
  '28871': 16,
  '28872': 16,
  '28873': 16,
  '28874': 16,
  '28875': 16,
  '28876': 16,
  '28877': 16,
  '28878': 16,
  '28879': 16,
  '2887A': 16,
  '2887B': 16,
  '2887C': 16,
  '2887D': 16,
  '2887E': 16,
  '2887F': 16,
  '28880': 16,
  '28881': 16,
  '28882': 16,
  '28883': 16,
  '28884': 16,
  '28885': 17,
  '28886': 17,
  '28887': 17,
  '28888': 17,
  '28889': 17,
  '2888A': 17,
  '2888B': 17,
  '2888C': 17,
  '2888D': 17,
  '2888E': 17,
  '2888F': 17,
  '28890': 17,
  '28891': 17,
  '28892': 17,
  '28893': 17,
  '28894': 17,
  '28895': 17,
  '28896': 17,
  '28897': 17,
  '28898': 17,
  '28899': 17,
  '2889A': 17,
  '2889B': 17,
  '2889C': 17,
  '2889D': 17,
  '2889E': 17,
  '2889F': 17,
  '288A0': 17,
  '288A1': 18,
  '288A2': 18,
  '288A3': 18,
  '288A4': 18,
  '288A5': 18,
  '288A6': 18,
  '288A7': 18,
  '288A8': 18,
  '288A9': 18,
  '288AA': 18,
  '288AB': 18,
  '288AC': 18,
  '288AD': 18,
  '288AE': 18,
  '288AF': 18,
  '288B0': 18,
  '288B1': 18,
  '288B2': 18,
  '288B3': 18,
  '288B4': 18,
  '288B5': 18,
  '288B6': 18,
  '288B7': 18,
  '288B8': 18,
  '288B9': 18,
  '288BA': 18,
  '288BB': 18,
  '288BC': 18,
  '288BD': 19,
  '288BE': 19,
  '288BF': 19,
  '288C0': 19,
  '288C1': 19,
  '288C2': 19,
  '288C3': 19,
  '288C4': 19,
  '288C5': 19,
  '288C6': 19,
  '288C7': 19,
  '288C8': 19,
  '288C9': 19,
  '288CA': 19,
  '288CB': 19,
  '288CC': 19,
  '288CD': 19,
  '288CE': 19,
  '288CF': 19,
  '288D0': 19,
  '288D1': 19,
  '288D2': 19,
  '288D3': 19,
  '288D4': 19,
  '288D5': 19,
  '288D6': 20,
  '288D7': 20,
  '288D8': 20,
  '288D9': 20,
  '288DA': 20,
  '288DB': 20,
  '288DC': 20,
  '288DD': 20,
  '288DE': 20,
  '288DF': 20,
  '288E0': 20,
  '288E1': 20,
  '288E2': 20,
  '288E3': 20,
  '288E4': 20,
  '288E5': 20,
  '288E6': 21,
  '288E7': 21,
  '288E8': 21,
  '288E9': 21,
  '288EA': 21,
  '288EB': 21,
  '288EC': 21,
  '288ED': 21,
  '288EE': 22,
  '288EF': 22,
  '288F0': 22,
  '288F1': 22,
  '288F2': 22,
  '288F3': 22,
  '288F4': 22,
  '288F5': 22,
  '288F6': 23,
  '288F7': 23,
  '288F8': 23,
  '288F9': 23,
  '288FA': 23,
  '288FB': 23,
  '288FC': 23,
  '288FD': 23,
  '288FE': 24,
  '288FF': 24,
  '28900': 24,
  '28901': 24,
  '28902': 24,
  '28903': 25,
  '28904': 25,
  '28905': 26,
  '28906': 26,
  '28907': 27,
  '28908': 27,
  '28909': 27,
  '2890A': 28,
  '2890B': 28,
  '2890C': 28,
  '2890D': 31,
  '2890E': 31,
  '2890F': 10,
  '28910': 11,
  '28911': 11,
  '28912': 14,
  '28913': 14,
  '28914': 14,
  '28915': 15,
  '28916': 15,
  '28917': 15,
  '28918': 16,
  '28919': 16,
  '2891A': 17,
  '2891B': 17,
  '2891C': 17,
  '2891D': 18,
  '2891E': 20,
  '2891F': 21,
  '28920': 21,
  '28921': 22,
  '28922': 11,
  '28923': 10,
  '28924': 10,
  '28925': 11,
  '28926': 12,
  '28927': 12,
  '28928': 12,
  '28929': 12,
  '2892A': 13,
  '2892B': 13,
  '2892C': 14,
  '2892D': 15,
  '2892E': 15,
  '2892F': 15,
  '28930': 16,
  '28931': 16,
  '28932': 17,
  '28933': 17,
  '28934': 18,
  '28935': 18,
  '28936': 18,
  '28937': 18,
  '28938': 20,
  '28939': 21,
  '2893A': 21,
  '2893B': 25,
  '2893C': 30,
  '2893D': 10,
  '2893E': 10,
  '2893F': 10,
  '28940': 10,
  '28941': 10,
  '28942': 11,
  '28943': 11,
  '28944': 11,
  '28945': 11,
  '28946': 11,
  '28947': 11,
  '28948': 11,
  '28949': 11,
  '2894A': 12,
  '2894B': 12,
  '2894C': 12,
  '2894D': 12,
  '2894E': 12,
  '2894F': 12,
  '28950': 12,
  '28951': 12,
  '28952': 12,
  '28953': 12,
  '28954': 12,
  '28955': 12,
  '28956': 12,
  '28957': 12,
  '28958': 12,
  '28959': 12,
  '2895A': 12,
  '2895B': 12,
  '2895C': 12,
  '2895D': 12,
  '2895E': 12,
  '2895F': 12,
  '28960': 12,
  '28961': 12,
  '28962': 12,
  '28963': 12,
  '28964': 12,
  '28965': 13,
  '28966': 13,
  '28967': 13,
  '28968': 13,
  '28969': 13,
  '2896A': 13,
  '2896B': 13,
  '2896C': 13,
  '2896D': 13,
  '2896E': 13,
  '2896F': 13,
  '28970': 13,
  '28971': 13,
  '28972': 13,
  '28973': 13,
  '28974': 13,
  '28975': 13,
  '28976': 13,
  '28977': 13,
  '28978': 13,
  '28979': 13,
  '2897A': 13,
  '2897B': 13,
  '2897C': 13,
  '2897D': 13,
  '2897E': 13,
  '2897F': 13,
  '28980': 13,
  '28981': 13,
  '28982': 14,
  '28983': 14,
  '28984': 14,
  '28985': 14,
  '28986': 14,
  '28987': 14,
  '28988': 14,
  '28989': 14,
  '2898A': 14,
  '2898B': 14,
  '2898C': 14,
  '2898D': 14,
  '2898E': 14,
  '2898F': 14,
  '28990': 14,
  '28991': 14,
  '28992': 14,
  '28993': 14,
  '28994': 14,
  '28995': 14,
  '28996': 14,
  '28997': 14,
  '28998': 14,
  '28999': 14,
  '2899A': 14,
  '2899B': 14,
  '2899C': 14,
  '2899D': 14,
  '2899E': 14,
  '2899F': 14,
  '289A0': 14,
  '289A1': 14,
  '289A2': 14,
  '289A3': 14,
  '289A4': 14,
  '289A5': 14,
  '289A6': 14,
  '289A7': 14,
  '289A8': 14,
  '289A9': 14,
  '289AA': 14,
  '289AB': 14,
  '289AC': 15,
  '289AD': 15,
  '289AE': 15,
  '289AF': 15,
  '289B0': 15,
  '289B1': 15,
  '289B2': 15,
  '289B3': 15,
  '289B4': 15,
  '289B5': 15,
  '289B6': 15,
  '289B7': 15,
  '289B8': 15,
  '289B9': 15,
  '289BA': 15,
  '289BB': 15,
  '289BC': 15,
  '289BD': 15,
  '289BE': 15,
  '289BF': 15,
  '289C0': 15,
  '289C1': 15,
  '289C2': 15,
  '289C3': 15,
  '289C4': 15,
  '289C5': 15,
  '289C6': 15,
  '289C7': 15,
  '289C8': 15,
  '289C9': 15,
  '289CA': 15,
  '289CB': 15,
  '289CC': 15,
  '289CD': 15,
  '289CE': 15,
  '289CF': 15,
  '289D0': 15,
  '289D1': 15,
  '289D2': 15,
  '289D3': 15,
  '289D4': 15,
  '289D5': 15,
  '289D6': 15,
  '289D7': 15,
  '289D8': 15,
  '289D9': 15,
  '289DA': 15,
  '289DB': 15,
  '289DC': 15,
  '289DD': 15,
  '289DE': 15,
  '289DF': 15,
  '289E0': 15,
  '289E1': 15,
  '289E2': 15,
  '289E3': 15,
  '289E4': 15,
  '289E5': 15,
  '289E6': 16,
  '289E7': 16,
  '289E8': 16,
  '289E9': 16,
  '289EA': 16,
  '289EB': 16,
  '289EC': 16,
  '289ED': 16,
  '289EE': 16,
  '289EF': 16,
  '289F0': 16,
  '289F1': 16,
  '289F2': 16,
  '289F3': 16,
  '289F4': 16,
  '289F5': 16,
  '289F6': 16,
  '289F7': 16,
  '289F8': 16,
  '289F9': 16,
  '289FA': 16,
  '289FB': 16,
  '289FC': 16,
  '289FD': 16,
  '289FE': 16,
  '289FF': 16,
  '28A00': 16,
  '28A01': 16,
  '28A02': 16,
  '28A03': 16,
  '28A04': 16,
  '28A05': 16,
  '28A06': 16,
  '28A07': 16,
  '28A08': 16,
  '28A09': 16,
  '28A0A': 16,
  '28A0B': 16,
  '28A0C': 16,
  '28A0D': 16,
  '28A0E': 16,
  '28A0F': 16,
  '28A10': 16,
  '28A11': 16,
  '28A12': 16,
  '28A13': 16,
  '28A14': 16,
  '28A15': 16,
  '28A16': 16,
  '28A17': 16,
  '28A18': 16,
  '28A19': 16,
  '28A1A': 16,
  '28A1B': 16,
  '28A1C': 16,
  '28A1D': 16,
  '28A1E': 16,
  '28A1F': 16,
  '28A20': 16,
  '28A21': 16,
  '28A22': 16,
  '28A23': 16,
  '28A24': 16,
  '28A25': 16,
  '28A26': 16,
  '28A27': 16,
  '28A28': 16,
  '28A29': 16,
  '28A2A': 16,
  '28A2B': 16,
  '28A2C': 16,
  '28A2D': 16,
  '28A2E': 16,
  '28A2F': 17,
  '28A30': 17,
  '28A31': 17,
  '28A32': 17,
  '28A33': 17,
  '28A34': 17,
  '28A35': 17,
  '28A36': 17,
  '28A37': 17,
  '28A38': 17,
  '28A39': 17,
  '28A3A': 17,
  '28A3B': 17,
  '28A3C': 17,
  '28A3D': 17,
  '28A3E': 17,
  '28A3F': 17,
  '28A40': 17,
  '28A41': 17,
  '28A42': 17,
  '28A43': 17,
  '28A44': 17,
  '28A45': 17,
  '28A46': 17,
  '28A47': 17,
  '28A48': 17,
  '28A49': 17,
  '28A4A': 17,
  '28A4B': 17,
  '28A4C': 17,
  '28A4D': 17,
  '28A4E': 17,
  '28A4F': 17,
  '28A50': 17,
  '28A51': 17,
  '28A52': 17,
  '28A53': 17,
  '28A54': 17,
  '28A55': 17,
  '28A56': 17,
  '28A57': 17,
  '28A58': 17,
  '28A59': 17,
  '28A5A': 17,
  '28A5B': 17,
  '28A5C': 17,
  '28A5D': 17,
  '28A5E': 17,
  '28A5F': 17,
  '28A60': 17,
  '28A61': 17,
  '28A62': 17,
  '28A63': 17,
  '28A64': 17,
  '28A65': 17,
  '28A66': 17,
  '28A67': 17,
  '28A68': 17,
  '28A69': 17,
  '28A6A': 17,
  '28A6B': 17,
  '28A6C': 17,
  '28A6D': 17,
  '28A6E': 17,
  '28A6F': 17,
  '28A70': 17,
  '28A71': 17,
  '28A72': 17,
  '28A73': 17,
  '28A74': 17,
  '28A75': 17,
  '28A76': 17,
  '28A77': 17,
  '28A78': 17,
  '28A79': 17,
  '28A7A': 17,
  '28A7B': 17,
  '28A7C': 17,
  '28A7D': 17,
  '28A7E': 17,
  '28A7F': 17,
  '28A80': 17,
  '28A81': 17,
  '28A82': 17,
  '28A83': 17,
  '28A84': 17,
  '28A85': 17,
  '28A86': 17,
  '28A87': 18,
  '28A88': 18,
  '28A89': 18,
  '28A8A': 18,
  '28A8B': 18,
  '28A8C': 18,
  '28A8D': 18,
  '28A8E': 18,
  '28A8F': 18,
  '28A90': 18,
  '28A91': 18,
  '28A92': 18,
  '28A93': 18,
  '28A94': 18,
  '28A95': 18,
  '28A96': 18,
  '28A97': 18,
  '28A98': 18,
  '28A99': 18,
  '28A9A': 18,
  '28A9B': 18,
  '28A9C': 18,
  '28A9D': 18,
  '28A9E': 18,
  '28A9F': 18,
  '28AA0': 18,
  '28AA1': 18,
  '28AA2': 18,
  '28AA3': 18,
  '28AA4': 18,
  '28AA5': 18,
  '28AA6': 18,
  '28AA7': 18,
  '28AA8': 18,
  '28AA9': 18,
  '28AAA': 18,
  '28AAB': 18,
  '28AAC': 18,
  '28AAD': 18,
  '28AAE': 18,
  '28AAF': 18,
  '28AB0': 18,
  '28AB1': 18,
  '28AB2': 18,
  '28AB3': 18,
  '28AB4': 18,
  '28AB5': 18,
  '28AB6': 18,
  '28AB7': 18,
  '28AB8': 18,
  '28AB9': 18,
  '28ABA': 18,
  '28ABB': 18,
  '28ABC': 18,
  '28ABD': 18,
  '28ABE': 18,
  '28ABF': 18,
  '28AC0': 18,
  '28AC1': 18,
  '28AC2': 18,
  '28AC3': 18,
  '28AC4': 18,
  '28AC5': 18,
  '28AC6': 18,
  '28AC7': 18,
  '28AC8': 18,
  '28AC9': 18,
  '28ACA': 18,
  '28ACB': 18,
  '28ACC': 18,
  '28ACD': 18,
  '28ACE': 18,
  '28ACF': 19,
  '28AD0': 19,
  '28AD1': 19,
  '28AD2': 19,
  '28AD3': 19,
  '28AD4': 19,
  '28AD5': 19,
  '28AD6': 19,
  '28AD7': 19,
  '28AD8': 19,
  '28AD9': 19,
  '28ADA': 19,
  '28ADB': 19,
  '28ADC': 19,
  '28ADD': 19,
  '28ADE': 19,
  '28ADF': 19,
  '28AE0': 19,
  '28AE1': 19,
  '28AE2': 19,
  '28AE3': 19,
  '28AE4': 19,
  '28AE5': 19,
  '28AE6': 19,
  '28AE7': 19,
  '28AE8': 19,
  '28AE9': 19,
  '28AEA': 19,
  '28AEB': 19,
  '28AEC': 19,
  '28AED': 19,
  '28AEE': 19,
  '28AEF': 19,
  '28AF0': 19,
  '28AF1': 19,
  '28AF2': 19,
  '28AF3': 19,
  '28AF4': 19,
  '28AF5': 19,
  '28AF6': 19,
  '28AF7': 19,
  '28AF8': 19,
  '28AF9': 19,
  '28AFA': 19,
  '28AFB': 19,
  '28AFC': 19,
  '28AFD': 19,
  '28AFE': 19,
  '28AFF': 19,
  '28B00': 19,
  '28B01': 19,
  '28B02': 19,
  '28B03': 19,
  '28B04': 19,
  '28B05': 19,
  '28B06': 19,
  '28B07': 19,
  '28B08': 19,
  '28B09': 19,
  '28B0A': 19,
  '28B0B': 19,
  '28B0C': 19,
  '28B0D': 20,
  '28B0E': 20,
  '28B0F': 20,
  '28B10': 20,
  '28B11': 20,
  '28B12': 20,
  '28B13': 20,
  '28B14': 20,
  '28B15': 21,
  '28B16': 20,
  '28B17': 20,
  '28B18': 20,
  '28B19': 20,
  '28B1A': 20,
  '28B1B': 20,
  '28B1C': 20,
  '28B1D': 20,
  '28B1E': 20,
  '28B1F': 20,
  '28B20': 20,
  '28B21': 20,
  '28B22': 20,
  '28B23': 20,
  '28B24': 20,
  '28B25': 20,
  '28B26': 20,
  '28B27': 20,
  '28B28': 20,
  '28B29': 20,
  '28B2A': 20,
  '28B2B': 20,
  '28B2C': 20,
  '28B2D': 20,
  '28B2E': 20,
  '28B2F': 20,
  '28B30': 20,
  '28B31': 20,
  '28B32': 20,
  '28B33': 20,
  '28B34': 20,
  '28B35': 20,
  '28B36': 20,
  '28B37': 20,
  '28B38': 20,
  '28B39': 20,
  '28B3A': 20,
  '28B3B': 20,
  '28B3C': 20,
  '28B3D': 20,
  '28B3E': 20,
  '28B3F': 20,
  '28B40': 20,
  '28B41': 20,
  '28B42': 20,
  '28B43': 20,
  '28B44': 20,
  '28B45': 20,
  '28B46': 20,
  '28B47': 20,
  '28B48': 20,
  '28B49': 20,
  '28B4A': 20,
  '28B4B': 20,
  '28B4C': 20,
  '28B4D': 20,
  '28B4E': 20,
  '28B4F': 20,
  '28B50': 20,
  '28B51': 20,
  '28B52': 20,
  '28B53': 20,
  '28B54': 20,
  '28B55': 21,
  '28B56': 21,
  '28B57': 21,
  '28B58': 21,
  '28B59': 21,
  '28B5A': 21,
  '28B5B': 21,
  '28B5C': 21,
  '28B5D': 21,
  '28B5E': 21,
  '28B5F': 21,
  '28B60': 21,
  '28B61': 21,
  '28B62': 21,
  '28B63': 21,
  '28B64': 21,
  '28B65': 21,
  '28B66': 21,
  '28B67': 21,
  '28B68': 21,
  '28B69': 21,
  '28B6A': 21,
  '28B6B': 21,
  '28B6C': 21,
  '28B6D': 21,
  '28B6E': 21,
  '28B6F': 21,
  '28B70': 21,
  '28B71': 21,
  '28B72': 21,
  '28B73': 21,
  '28B74': 21,
  '28B75': 21,
  '28B76': 21,
  '28B77': 21,
  '28B78': 21,
  '28B79': 21,
  '28B7A': 21,
  '28B7B': 21,
  '28B7C': 21,
  '28B7D': 21,
  '28B7E': 21,
  '28B7F': 21,
  '28B80': 21,
  '28B81': 21,
  '28B82': 21,
  '28B83': 21,
  '28B84': 21,
  '28B85': 21,
  '28B86': 21,
  '28B87': 21,
  '28B88': 21,
  '28B89': 21,
  '28B8A': 21,
  '28B8B': 21,
  '28B8C': 21,
  '28B8D': 21,
  '28B8E': 21,
  '28B8F': 21,
  '28B90': 21,
  '28B91': 21,
  '28B92': 22,
  '28B93': 22,
  '28B94': 22,
  '28B95': 22,
  '28B96': 22,
  '28B97': 22,
  '28B98': 22,
  '28B99': 22,
  '28B9A': 22,
  '28B9B': 22,
  '28B9C': 22,
  '28B9D': 22,
  '28B9E': 22,
  '28B9F': 22,
  '28BA0': 22,
  '28BA1': 22,
  '28BA2': 22,
  '28BA3': 22,
  '28BA4': 22,
  '28BA5': 22,
  '28BA6': 22,
  '28BA7': 22,
  '28BA8': 22,
  '28BA9': 22,
  '28BAA': 22,
  '28BAB': 22,
  '28BAC': 22,
  '28BAD': 22,
  '28BAE': 22,
  '28BAF': 22,
  '28BB0': 22,
  '28BB1': 22,
  '28BB2': 22,
  '28BB3': 22,
  '28BB4': 22,
  '28BB5': 22,
  '28BB6': 22,
  '28BB7': 22,
  '28BB8': 23,
  '28BB9': 23,
  '28BBA': 23,
  '28BBB': 23,
  '28BBC': 23,
  '28BBD': 23,
  '28BBE': 23,
  '28BBF': 23,
  '28BC0': 23,
  '28BC1': 23,
  '28BC2': 23,
  '28BC3': 23,
  '28BC4': 23,
  '28BC5': 23,
  '28BC6': 23,
  '28BC7': 23,
  '28BC8': 23,
  '28BC9': 23,
  '28BCA': 23,
  '28BCB': 23,
  '28BCC': 23,
  '28BCD': 23,
  '28BCE': 23,
  '28BCF': 23,
  '28BD0': 23,
  '28BD1': 23,
  '28BD2': 23,
  '28BD3': 23,
  '28BD4': 23,
  '28BD5': 23,
  '28BD6': 23,
  '28BD7': 23,
  '28BD8': 23,
  '28BD9': 23,
  '28BDA': 23,
  '28BDB': 23,
  '28BDC': 23,
  '28BDD': 24,
  '28BDE': 24,
  '28BDF': 24,
  '28BE0': 24,
  '28BE1': 24,
  '28BE2': 24,
  '28BE3': 24,
  '28BE4': 24,
  '28BE5': 24,
  '28BE6': 24,
  '28BE7': 24,
  '28BE8': 24,
  '28BE9': 24,
  '28BEA': 24,
  '28BEB': 24,
  '28BEC': 24,
  '28BED': 24,
  '28BEE': 24,
  '28BEF': 24,
  '28BF0': 24,
  '28BF1': 24,
  '28BF2': 24,
  '28BF3': 24,
  '28BF4': 24,
  '28BF5': 24,
  '28BF6': 24,
  '28BF7': 24,
  '28BF8': 24,
  '28BF9': 24,
  '28BFA': 25,
  '28BFB': 25,
  '28BFC': 25,
  '28BFD': 25,
  '28BFE': 25,
  '28BFF': 25,
  '28C00': 25,
  '28C01': 25,
  '28C02': 25,
  '28C03': 25,
  '28C04': 25,
  '28C05': 25,
  '28C06': 25,
  '28C07': 25,
  '28C08': 25,
  '28C09': 26,
  '28C0A': 26,
  '28C0B': 26,
  '28C0C': 26,
  '28C0D': 26,
  '28C0E': 26,
  '28C0F': 26,
  '28C10': 26,
  '28C11': 26,
  '28C12': 26,
  '28C13': 26,
  '28C14': 26,
  '28C15': 26,
  '28C16': 26,
  '28C17': 26,
  '28C18': 26,
  '28C19': 26,
  '28C1A': 26,
  '28C1B': 26,
  '28C1C': 26,
  '28C1D': 26,
  '28C1E': 27,
  '28C1F': 27,
  '28C20': 27,
  '28C21': 27,
  '28C22': 27,
  '28C23': 27,
  '28C24': 27,
  '28C25': 27,
  '28C26': 27,
  '28C27': 27,
  '28C28': 27,
  '28C29': 28,
  '28C2A': 28,
  '28C2B': 28,
  '28C2C': 28,
  '28C2D': 28,
  '28C2E': 28,
  '28C2F': 28,
  '28C30': 28,
  '28C31': 25,
  '28C32': 29,
  '28C33': 29,
  '28C34': 29,
  '28C35': 29,
  '28C36': 29,
  '28C37': 30,
  '28C38': 30,
  '28C39': 30,
  '28C3A': 31,
  '28C3B': 32,
  '28C3C': 35,
  '28C3D': 38,
  '28C3E': 8,
  '28C3F': 8,
  '28C40': 9,
  '28C41': 9,
  '28C42': 9,
  '28C43': 10,
  '28C44': 10,
  '28C45': 10,
  '28C46': 10,
  '28C47': 12,
  '28C48': 12,
  '28C49': 13,
  '28C4A': 13,
  '28C4B': 13,
  '28C4C': 13,
  '28C4D': 15,
  '28C4E': 14,
  '28C4F': 15,
  '28C50': 16,
  '28C51': 17,
  '28C52': 16,
  '28C53': 17,
  '28C54': 17,
  '28C55': 18,
  '28C56': 18,
  '28C57': 7,
  '28C58': 7,
  '28C59': 9,
  '28C5A': 11,
  '28C5B': 11,
  '28C5C': 11,
  '28C5D': 11,
  '28C5E': 11,
  '28C5F': 11,
  '28C60': 11,
  '28C61': 11,
  '28C62': 11,
  '28C63': 11,
  '28C64': 11,
  '28C65': 11,
  '28C66': 12,
  '28C67': 12,
  '28C68': 12,
  '28C69': 12,
  '28C6A': 12,
  '28C6B': 12,
  '28C6C': 12,
  '28C6D': 12,
  '28C6E': 12,
  '28C6F': 12,
  '28C70': 12,
  '28C71': 12,
  '28C72': 13,
  '28C73': 13,
  '28C74': 13,
  '28C75': 13,
  '28C76': 13,
  '28C77': 13,
  '28C78': 13,
  '28C79': 13,
  '28C7A': 13,
  '28C7B': 13,
  '28C7C': 13,
  '28C7D': 13,
  '28C7E': 13,
  '28C7F': 13,
  '28C80': 14,
  '28C81': 14,
  '28C82': 14,
  '28C83': 14,
  '28C84': 14,
  '28C85': 14,
  '28C86': 14,
  '28C87': 15,
  '28C88': 15,
  '28C89': 15,
  '28C8A': 15,
  '28C8B': 15,
  '28C8C': 15,
  '28C8D': 15,
  '28C8E': 15,
  '28C8F': 15,
  '28C90': 15,
  '28C91': 15,
  '28C92': 15,
  '28C93': 16,
  '28C94': 16,
  '28C95': 16,
  '28C96': 16,
  '28C97': 16,
  '28C98': 16,
  '28C99': 16,
  '28C9A': 16,
  '28C9B': 16,
  '28C9C': 16,
  '28C9D': 16,
  '28C9E': 17,
  '28C9F': 17,
  '28CA0': 17,
  '28CA1': 17,
  '28CA2': 17,
  '28CA3': 17,
  '28CA4': 17,
  '28CA5': 18,
  '28CA6': 18,
  '28CA7': 18,
  '28CA8': 18,
  '28CA9': 18,
  '28CAA': 18,
  '28CAB': 18,
  '28CAC': 18,
  '28CAD': 19,
  '28CAE': 19,
  '28CAF': 19,
  '28CB0': 19,
  '28CB1': 19,
  '28CB2': 19,
  '28CB3': 20,
  '28CB4': 20,
  '28CB5': 20,
  '28CB6': 20,
  '28CB7': 20,
  '28CB8': 21,
  '28CB9': 21,
  '28CBA': 21,
  '28CBB': 22,
  '28CBC': 22,
  '28CBD': 22,
  '28CBE': 22,
  '28CBF': 22,
  '28CC0': 23,
  '28CC1': 23,
  '28CC2': 24,
  '28CC3': 24,
  '28CC4': 26,
  '28CC5': 28,
  '28CC6': 29,
  '28CC7': 8,
  '28CC8': 8,
  '28CC9': 9,
  '28CCA': 10,
  '28CCB': 10,
  '28CCC': 10,
  '28CCD': 10,
  '28CCE': 10,
  '28CCF': 10,
  '28CD0': 11,
  '28CD1': 11,
  '28CD2': 11,
  '28CD3': 11,
  '28CD4': 11,
  '28CD5': 11,
  '28CD6': 11,
  '28CD7': 12,
  '28CD8': 12,
  '28CD9': 12,
  '28CDA': 12,
  '28CDB': 12,
  '28CDC': 12,
  '28CDD': 12,
  '28CDE': 12,
  '28CDF': 12,
  '28CE0': 12,
  '28CE1': 12,
  '28CE2': 12,
  '28CE3': 12,
  '28CE4': 12,
  '28CE5': 12,
  '28CE6': 12,
  '28CE7': 12,
  '28CE8': 12,
  '28CE9': 12,
  '28CEA': 12,
  '28CEB': 12,
  '28CEC': 12,
  '28CED': 12,
  '28CEE': 12,
  '28CEF': 12,
  '28CF0': 12,
  '28CF1': 12,
  '28CF2': 12,
  '28CF3': 13,
  '28CF4': 13,
  '28CF5': 13,
  '28CF6': 13,
  '28CF7': 13,
  '28CF8': 13,
  '28CF9': 13,
  '28CFA': 13,
  '28CFB': 13,
  '28CFC': 13,
  '28CFD': 13,
  '28CFE': 13,
  '28CFF': 13,
  '28D00': 13,
  '28D01': 13,
  '28D02': 13,
  '28D03': 13,
  '28D04': 13,
  '28D05': 13,
  '28D06': 13,
  '28D07': 13,
  '28D08': 13,
  '28D09': 13,
  '28D0A': 13,
  '28D0B': 13,
  '28D0C': 13,
  '28D0D': 14,
  '28D0E': 14,
  '28D0F': 14,
  '28D10': 14,
  '28D11': 14,
  '28D12': 14,
  '28D13': 14,
  '28D14': 14,
  '28D15': 14,
  '28D16': 14,
  '28D17': 14,
  '28D18': 14,
  '28D19': 14,
  '28D1A': 14,
  '28D1B': 14,
  '28D1C': 14,
  '28D1D': 14,
  '28D1E': 14,
  '28D1F': 14,
  '28D20': 14,
  '28D21': 14,
  '28D22': 14,
  '28D23': 14,
  '28D24': 14,
  '28D25': 14,
  '28D26': 14,
  '28D27': 15,
  '28D28': 15,
  '28D29': 15,
  '28D2A': 15,
  '28D2B': 15,
  '28D2C': 15,
  '28D2D': 15,
  '28D2E': 15,
  '28D2F': 15,
  '28D30': 15,
  '28D31': 15,
  '28D32': 15,
  '28D33': 15,
  '28D34': 15,
  '28D35': 15,
  '28D36': 15,
  '28D37': 15,
  '28D38': 15,
  '28D39': 15,
  '28D3A': 15,
  '28D3B': 15,
  '28D3C': 15,
  '28D3D': 15,
  '28D3E': 15,
  '28D3F': 15,
  '28D40': 15,
  '28D41': 15,
  '28D42': 15,
  '28D43': 15,
  '28D44': 15,
  '28D45': 15,
  '28D46': 15,
  '28D47': 15,
  '28D48': 16,
  '28D49': 16,
  '28D4A': 16,
  '28D4B': 16,
  '28D4C': 16,
  '28D4D': 16,
  '28D4E': 16,
  '28D4F': 16,
  '28D50': 16,
  '28D51': 16,
  '28D52': 16,
  '28D53': 16,
  '28D54': 16,
  '28D55': 16,
  '28D56': 16,
  '28D57': 16,
  '28D58': 16,
  '28D59': 16,
  '28D5A': 16,
  '28D5B': 16,
  '28D5C': 16,
  '28D5D': 16,
  '28D5E': 16,
  '28D5F': 16,
  '28D60': 16,
  '28D61': 16,
  '28D62': 16,
  '28D63': 16,
  '28D64': 16,
  '28D65': 17,
  '28D66': 17,
  '28D67': 17,
  '28D68': 17,
  '28D69': 17,
  '28D6A': 17,
  '28D6B': 17,
  '28D6C': 17,
  '28D6D': 17,
  '28D6E': 17,
  '28D6F': 17,
  '28D70': 17,
  '28D71': 17,
  '28D72': 17,
  '28D73': 17,
  '28D74': 17,
  '28D75': 17,
  '28D76': 17,
  '28D77': 17,
  '28D78': 17,
  '28D79': 17,
  '28D7A': 17,
  '28D7B': 17,
  '28D7C': 17,
  '28D7D': 17,
  '28D7E': 17,
  '28D7F': 17,
  '28D80': 18,
  '28D81': 18,
  '28D82': 18,
  '28D83': 18,
  '28D84': 18,
  '28D85': 18,
  '28D86': 18,
  '28D87': 18,
  '28D88': 18,
  '28D89': 18,
  '28D8A': 18,
  '28D8B': 18,
  '28D8C': 18,
  '28D8D': 18,
  '28D8E': 18,
  '28D8F': 18,
  '28D90': 18,
  '28D91': 18,
  '28D92': 18,
  '28D93': 18,
  '28D94': 18,
  '28D95': 18,
  '28D96': 18,
  '28D97': 18,
  '28D98': 18,
  '28D99': 18,
  '28D9A': 18,
  '28D9B': 18,
  '28D9C': 19,
  '28D9D': 19,
  '28D9E': 19,
  '28D9F': 19,
  '28DA0': 19,
  '28DA1': 19,
  '28DA2': 19,
  '28DA3': 19,
  '28DA4': 19,
  '28DA5': 19,
  '28DA6': 19,
  '28DA7': 19,
  '28DA8': 19,
  '28DA9': 19,
  '28DAA': 19,
  '28DAB': 19,
  '28DAC': 20,
  '28DAD': 20,
  '28DAE': 20,
  '28DAF': 20,
  '28DB0': 20,
  '28DB1': 20,
  '28DB2': 20,
  '28DB3': 20,
  '28DB4': 20,
  '28DB5': 20,
  '28DB6': 20,
  '28DB7': 20,
  '28DB8': 20,
  '28DB9': 20,
  '28DBA': 20,
  '28DBB': 20,
  '28DBC': 20,
  '28DBD': 20,
  '28DBE': 20,
  '28DBF': 20,
  '28DC0': 20,
  '28DC1': 20,
  '28DC2': 20,
  '28DC3': 21,
  '28DC4': 21,
  '28DC5': 21,
  '28DC6': 21,
  '28DC7': 21,
  '28DC8': 21,
  '28DC9': 21,
  '28DCA': 21,
  '28DCB': 21,
  '28DCC': 21,
  '28DCD': 21,
  '28DCE': 21,
  '28DCF': 21,
  '28DD0': 21,
  '28DD1': 21,
  '28DD2': 21,
  '28DD3': 21,
  '28DD4': 22,
  '28DD5': 21,
  '28DD6': 21,
  '28DD7': 21,
  '28DD8': 22,
  '28DD9': 22,
  '28DDA': 22,
  '28DDB': 22,
  '28DDC': 22,
  '28DDD': 22,
  '28DDE': 22,
  '28DDF': 23,
  '28DE0': 23,
  '28DE1': 23,
  '28DE2': 23,
  '28DE3': 23,
  '28DE4': 23,
  '28DE5': 23,
  '28DE6': 24,
  '28DE7': 24,
  '28DE8': 24,
  '28DE9': 24,
  '28DEA': 24,
  '28DEB': 24,
  '28DEC': 24,
  '28DED': 24,
  '28DEE': 24,
  '28DEF': 25,
  '28DF0': 25,
  '28DF1': 25,
  '28DF2': 25,
  '28DF3': 25,
  '28DF4': 25,
  '28DF5': 25,
  '28DF6': 24,
  '28DF7': 26,
  '28DF8': 25,
  '28DF9': 26,
  '28DFA': 26,
  '28DFB': 27,
  '28DFC': 29,
  '28DFD': 32,
  '28DFE': 32,
  '28DFF': 6,
  '28E00': 6,
  '28E01': 6,
  '28E02': 7,
  '28E03': 7,
  '28E04': 9,
  '28E05': 9,
  '28E06': 12,
  '28E07': 12,
  '28E08': 12,
  '28E09': 13,
  '28E0A': 13,
  '28E0B': 15,
  '28E0C': 15,
  '28E0D': 17,
  '28E0E': 20,
  '28E0F': 2,
  '28E10': 4,
  '28E11': 4,
  '28E12': 4,
  '28E13': 4,
  '28E14': 4,
  '28E15': 4,
  '28E16': 5,
  '28E17': 5,
  '28E18': 5,
  '28E19': 5,
  '28E1A': 6,
  '28E1B': 6,
  '28E1C': 6,
  '28E1D': 6,
  '28E1E': 6,
  '28E1F': 6,
  '28E20': 6,
  '28E21': 6,
  '28E22': 6,
  '28E23': 6,
  '28E24': 6,
  '28E25': 6,
  '28E26': 6,
  '28E27': 6,
  '28E28': 6,
  '28E29': 6,
  '28E2A': 7,
  '28E2B': 7,
  '28E2C': 7,
  '28E2D': 7,
  '28E2E': 7,
  '28E2F': 7,
  '28E30': 7,
  '28E31': 7,
  '28E32': 7,
  '28E33': 7,
  '28E34': 7,
  '28E35': 7,
  '28E36': 7,
  '28E37': 7,
  '28E38': 7,
  '28E39': 7,
  '28E3A': 7,
  '28E3B': 7,
  '28E3C': 7,
  '28E3D': 7,
  '28E3E': 7,
  '28E3F': 7,
  '28E40': 7,
  '28E41': 8,
  '28E42': 8,
  '28E43': 8,
  '28E44': 8,
  '28E45': 8,
  '28E46': 8,
  '28E47': 8,
  '28E48': 8,
  '28E49': 8,
  '28E4A': 8,
  '28E4B': 8,
  '28E4C': 8,
  '28E4D': 8,
  '28E4E': 8,
  '28E4F': 8,
  '28E50': 8,
  '28E51': 8,
  '28E52': 8,
  '28E53': 8,
  '28E54': 8,
  '28E55': 8,
  '28E56': 8,
  '28E57': 8,
  '28E58': 9,
  '28E59': 9,
  '28E5A': 9,
  '28E5B': 9,
  '28E5C': 9,
  '28E5D': 9,
  '28E5E': 9,
  '28E5F': 9,
  '28E60': 9,
  '28E61': 9,
  '28E62': 9,
  '28E63': 9,
  '28E64': 9,
  '28E65': 9,
  '28E66': 9,
  '28E67': 9,
  '28E68': 9,
  '28E69': 9,
  '28E6A': 9,
  '28E6B': 9,
  '28E6C': 9,
  '28E6D': 9,
  '28E6E': 9,
  '28E6F': 9,
  '28E70': 9,
  '28E71': 9,
  '28E72': 9,
  '28E73': 9,
  '28E74': 10,
  '28E75': 10,
  '28E76': 10,
  '28E77': 10,
  '28E78': 10,
  '28E79': 10,
  '28E7A': 10,
  '28E7B': 10,
  '28E7C': 10,
  '28E7D': 10,
  '28E7E': 10,
  '28E7F': 10,
  '28E80': 10,
  '28E81': 10,
  '28E82': 10,
  '28E83': 10,
  '28E84': 10,
  '28E85': 10,
  '28E86': 10,
  '28E87': 10,
  '28E88': 10,
  '28E89': 10,
  '28E8A': 10,
  '28E8B': 10,
  '28E8C': 10,
  '28E8D': 10,
  '28E8E': 10,
  '28E8F': 10,
  '28E90': 10,
  '28E91': 10,
  '28E92': 10,
  '28E93': 10,
  '28E94': 10,
  '28E95': 10,
  '28E96': 10,
  '28E97': 10,
  '28E98': 10,
  '28E99': 10,
  '28E9A': 10,
  '28E9B': 10,
  '28E9C': 10,
  '28E9D': 10,
  '28E9E': 11,
  '28E9F': 11,
  '28EA0': 11,
  '28EA1': 11,
  '28EA2': 11,
  '28EA3': 11,
  '28EA4': 11,
  '28EA5': 11,
  '28EA6': 11,
  '28EA7': 11,
  '28EA8': 11,
  '28EA9': 11,
  '28EAA': 11,
  '28EAB': 11,
  '28EAC': 11,
  '28EAD': 11,
  '28EAE': 11,
  '28EAF': 11,
  '28EB0': 11,
  '28EB1': 11,
  '28EB2': 11,
  '28EB3': 11,
  '28EB4': 11,
  '28EB5': 11,
  '28EB6': 11,
  '28EB7': 11,
  '28EB8': 11,
  '28EB9': 11,
  '28EBA': 11,
  '28EBB': 11,
  '28EBC': 11,
  '28EBD': 11,
  '28EBE': 11,
  '28EBF': 11,
  '28EC0': 12,
  '28EC1': 12,
  '28EC2': 12,
  '28EC3': 12,
  '28EC4': 12,
  '28EC5': 12,
  '28EC6': 12,
  '28EC7': 12,
  '28EC8': 12,
  '28EC9': 12,
  '28ECA': 12,
  '28ECB': 12,
  '28ECC': 12,
  '28ECD': 12,
  '28ECE': 12,
  '28ECF': 12,
  '28ED0': 12,
  '28ED1': 12,
  '28ED2': 12,
  '28ED3': 12,
  '28ED4': 12,
  '28ED5': 12,
  '28ED6': 12,
  '28ED7': 12,
  '28ED8': 12,
  '28ED9': 12,
  '28EDA': 12,
  '28EDB': 12,
  '28EDC': 12,
  '28EDD': 12,
  '28EDE': 12,
  '28EDF': 12,
  '28EE0': 12,
  '28EE1': 12,
  '28EE2': 12,
  '28EE3': 12,
  '28EE4': 12,
  '28EE5': 12,
  '28EE6': 12,
  '28EE7': 12,
  '28EE8': 12,
  '28EE9': 12,
  '28EEA': 12,
  '28EEB': 12,
  '28EEC': 12,
  '28EED': 12,
  '28EEE': 13,
  '28EEF': 13,
  '28EF0': 13,
  '28EF1': 13,
  '28EF2': 13,
  '28EF3': 13,
  '28EF4': 13,
  '28EF5': 13,
  '28EF6': 13,
  '28EF7': 13,
  '28EF8': 13,
  '28EF9': 13,
  '28EFA': 13,
  '28EFB': 13,
  '28EFC': 13,
  '28EFD': 13,
  '28EFE': 13,
  '28EFF': 13,
  '28F00': 13,
  '28F01': 13,
  '28F02': 13,
  '28F03': 13,
  '28F04': 13,
  '28F05': 13,
  '28F06': 13,
  '28F07': 13,
  '28F08': 13,
  '28F09': 13,
  '28F0A': 14,
  '28F0B': 14,
  '28F0C': 14,
  '28F0D': 14,
  '28F0E': 14,
  '28F0F': 14,
  '28F10': 14,
  '28F11': 14,
  '28F12': 14,
  '28F13': 14,
  '28F14': 14,
  '28F15': 14,
  '28F16': 14,
  '28F17': 14,
  '28F18': 14,
  '28F19': 14,
  '28F1A': 14,
  '28F1B': 14,
  '28F1C': 14,
  '28F1D': 14,
  '28F1E': 14,
  '28F1F': 14,
  '28F20': 14,
  '28F21': 14,
  '28F22': 14,
  '28F23': 14,
  '28F24': 14,
  '28F25': 14,
  '28F26': 14,
  '28F27': 14,
  '28F28': 14,
  '28F29': 14,
  '28F2A': 15,
  '28F2B': 15,
  '28F2C': 15,
  '28F2D': 15,
  '28F2E': 15,
  '28F2F': 15,
  '28F30': 15,
  '28F31': 15,
  '28F32': 15,
  '28F33': 15,
  '28F34': 15,
  '28F35': 15,
  '28F36': 15,
  '28F37': 15,
  '28F38': 15,
  '28F39': 15,
  '28F3A': 15,
  '28F3B': 15,
  '28F3C': 15,
  '28F3D': 15,
  '28F3E': 15,
  '28F3F': 16,
  '28F40': 16,
  '28F41': 16,
  '28F42': 16,
  '28F43': 16,
  '28F44': 16,
  '28F45': 16,
  '28F46': 16,
  '28F47': 16,
  '28F48': 16,
  '28F49': 16,
  '28F4A': 16,
  '28F4B': 16,
  '28F4C': 16,
  '28F4D': 17,
  '28F4E': 17,
  '28F4F': 17,
  '28F50': 17,
  '28F51': 17,
  '28F52': 17,
  '28F53': 17,
  '28F54': 17,
  '28F55': 17,
  '28F56': 18,
  '28F57': 18,
  '28F58': 18,
  '28F59': 18,
  '28F5A': 18,
  '28F5B': 18,
  '28F5C': 18,
  '28F5D': 18,
  '28F5E': 18,
  '28F5F': 18,
  '28F60': 18,
  '28F61': 18,
  '28F62': 19,
  '28F63': 19,
  '28F64': 19,
  '28F65': 19,
  '28F66': 20,
  '28F67': 20,
  '28F68': 20,
  '28F69': 20,
  '28F6A': 20,
  '28F6B': 20,
  '28F6C': 21,
  '28F6D': 21,
  '28F6E': 22,
  '28F6F': 22,
  '28F70': 23,
  '28F71': 23,
  '28F72': 26,
  '28F73': 26,
  '28F74': 27,
  '28F75': 27,
  '28F76': 13,
  '28F77': 14,
  '28F78': 15,
  '28F79': 15,
  '28F7A': 15,
  '28F7B': 15,
  '28F7C': 16,
  '28F7D': 16,
  '28F7E': 16,
  '28F7F': 17,
  '28F80': 18,
  '28F81': 18,
  '28F82': 20,
  '28F83': 20,
  '28F84': 22,
  '28F85': 10,
  '28F86': 10,
  '28F87': 10,
  '28F88': 11,
  '28F89': 11,
  '28F8A': 11,
  '28F8B': 11,
  '28F8C': 11,
  '28F8D': 11,
  '28F8E': 11,
  '28F8F': 11,
  '28F90': 11,
  '28F91': 11,
  '28F92': 12,
  '28F93': 12,
  '28F94': 12,
  '28F95': 12,
  '28F96': 12,
  '28F97': 12,
  '28F98': 12,
  '28F99': 12,
  '28F9A': 12,
  '28F9B': 12,
  '28F9C': 12,
  '28F9D': 12,
  '28F9E': 12,
  '28F9F': 12,
  '28FA0': 13,
  '28FA1': 13,
  '28FA2': 13,
  '28FA3': 13,
  '28FA4': 13,
  '28FA5': 13,
  '28FA6': 13,
  '28FA7': 13,
  '28FA8': 13,
  '28FA9': 13,
  '28FAA': 13,
  '28FAB': 13,
  '28FAC': 13,
  '28FAD': 13,
  '28FAE': 13,
  '28FAF': 13,
  '28FB0': 13,
  '28FB1': 13,
  '28FB2': 14,
  '28FB3': 14,
  '28FB4': 14,
  '28FB5': 14,
  '28FB6': 14,
  '28FB7': 14,
  '28FB8': 14,
  '28FB9': 14,
  '28FBA': 14,
  '28FBB': 14,
  '28FBC': 14,
  '28FBD': 14,
  '28FBE': 14,
  '28FBF': 14,
  '28FC0': 14,
  '28FC1': 14,
  '28FC2': 14,
  '28FC3': 14,
  '28FC4': 14,
  '28FC5': 14,
  '28FC6': 14,
  '28FC7': 14,
  '28FC8': 14,
  '28FC9': 14,
  '28FCA': 14,
  '28FCB': 15,
  '28FCC': 15,
  '28FCD': 15,
  '28FCE': 15,
  '28FCF': 15,
  '28FD0': 15,
  '28FD1': 15,
  '28FD2': 15,
  '28FD3': 15,
  '28FD4': 15,
  '28FD5': 15,
  '28FD6': 15,
  '28FD7': 15,
  '28FD8': 15,
  '28FD9': 15,
  '28FDA': 15,
  '28FDB': 15,
  '28FDC': 15,
  '28FDD': 15,
  '28FDE': 15,
  '28FDF': 15,
  '28FE0': 16,
  '28FE1': 16,
  '28FE2': 16,
  '28FE3': 16,
  '28FE4': 16,
  '28FE5': 16,
  '28FE6': 16,
  '28FE7': 16,
  '28FE8': 16,
  '28FE9': 16,
  '28FEA': 16,
  '28FEB': 16,
  '28FEC': 16,
  '28FED': 16,
  '28FEE': 16,
  '28FEF': 16,
  '28FF0': 16,
  '28FF1': 16,
  '28FF2': 16,
  '28FF3': 16,
  '28FF4': 16,
  '28FF5': 16,
  '28FF6': 16,
  '28FF7': 16,
  '28FF8': 16,
  '28FF9': 16,
  '28FFA': 16,
  '28FFB': 16,
  '28FFC': 16,
  '28FFD': 16,
  '28FFE': 16,
  '28FFF': 17,
  '29000': 17,
  '29001': 17,
  '29002': 17,
  '29003': 17,
  '29004': 17,
  '29005': 17,
  '29006': 17,
  '29007': 17,
  '29008': 17,
  '29009': 17,
  '2900A': 17,
  '2900B': 17,
  '2900C': 17,
  '2900D': 17,
  '2900E': 17,
  '2900F': 17,
  '29010': 17,
  '29011': 17,
  '29012': 17,
  '29013': 17,
  '29014': 17,
  '29015': 17,
  '29016': 17,
  '29017': 18,
  '29018': 18,
  '29019': 18,
  '2901A': 18,
  '2901B': 18,
  '2901C': 18,
  '2901D': 18,
  '2901E': 18,
  '2901F': 18,
  '29020': 18,
  '29021': 18,
  '29022': 18,
  '29023': 18,
  '29024': 19,
  '29025': 19,
  '29026': 19,
  '29027': 19,
  '29028': 19,
  '29029': 19,
  '2902A': 19,
  '2902B': 19,
  '2902C': 19,
  '2902D': 19,
  '2902E': 19,
  '2902F': 19,
  '29030': 19,
  '29031': 19,
  '29032': 19,
  '29033': 19,
  '29034': 20,
  '29035': 20,
  '29036': 20,
  '29037': 20,
  '29038': 20,
  '29039': 20,
  '2903A': 20,
  '2903B': 20,
  '2903C': 20,
  '2903D': 20,
  '2903E': 20,
  '2903F': 20,
  '29040': 20,
  '29041': 20,
  '29042': 20,
  '29043': 20,
  '29044': 20,
  '29045': 20,
  '29046': 20,
  '29047': 21,
  '29048': 21,
  '29049': 21,
  '2904A': 21,
  '2904B': 21,
  '2904C': 21,
  '2904D': 21,
  '2904E': 21,
  '2904F': 21,
  '29050': 21,
  '29051': 21,
  '29052': 21,
  '29053': 22,
  '29054': 22,
  '29055': 22,
  '29056': 22,
  '29057': 22,
  '29058': 22,
  '29059': 22,
  '2905A': 22,
  '2905B': 22,
  '2905C': 23,
  '2905D': 23,
  '2905E': 23,
  '2905F': 23,
  '29060': 23,
  '29061': 23,
  '29062': 23,
  '29063': 23,
  '29064': 23,
  '29065': 23,
  '29066': 23,
  '29067': 24,
  '29068': 24,
  '29069': 24,
  '2906A': 24,
  '2906B': 24,
  '2906C': 25,
  '2906D': 25,
  '2906E': 25,
  '2906F': 26,
  '29070': 26,
  '29071': 26,
  '29072': 28,
  '29073': 28,
  '29074': 32,
  '29075': 36,
  '29076': 9,
  '29077': 10,
  '29078': 10,
  '29079': 11,
  '2907A': 11,
  '2907B': 11,
  '2907C': 11,
  '2907D': 11,
  '2907E': 11,
  '2907F': 11,
  '29080': 11,
  '29081': 11,
  '29082': 12,
  '29083': 12,
  '29084': 12,
  '29085': 12,
  '29086': 12,
  '29087': 12,
  '29088': 12,
  '29089': 12,
  '2908A': 12,
  '2908B': 12,
  '2908C': 12,
  '2908D': 12,
  '2908E': 12,
  '2908F': 12,
  '29090': 12,
  '29091': 12,
  '29092': 13,
  '29093': 13,
  '29094': 13,
  '29095': 13,
  '29096': 13,
  '29097': 13,
  '29098': 13,
  '29099': 13,
  '2909A': 13,
  '2909B': 13,
  '2909C': 13,
  '2909D': 13,
  '2909E': 13,
  '2909F': 13,
  '290A0': 13,
  '290A1': 13,
  '290A2': 14,
  '290A3': 14,
  '290A4': 14,
  '290A5': 14,
  '290A6': 14,
  '290A7': 14,
  '290A8': 14,
  '290A9': 14,
  '290AA': 14,
  '290AB': 14,
  '290AC': 14,
  '290AD': 14,
  '290AE': 14,
  '290AF': 14,
  '290B0': 14,
  '290B1': 14,
  '290B2': 14,
  '290B3': 14,
  '290B4': 14,
  '290B5': 14,
  '290B6': 14,
  '290B7': 15,
  '290B8': 15,
  '290B9': 15,
  '290BA': 15,
  '290BB': 15,
  '290BC': 15,
  '290BD': 15,
  '290BE': 15,
  '290BF': 15,
  '290C0': 15,
  '290C1': 15,
  '290C2': 15,
  '290C3': 15,
  '290C4': 15,
  '290C5': 15,
  '290C6': 15,
  '290C7': 15,
  '290C8': 15,
  '290C9': 15,
  '290CA': 15,
  '290CB': 15,
  '290CC': 15,
  '290CD': 15,
  '290CE': 15,
  '290CF': 15,
  '290D0': 15,
  '290D1': 15,
  '290D2': 16,
  '290D3': 16,
  '290D4': 16,
  '290D5': 16,
  '290D6': 16,
  '290D7': 16,
  '290D8': 16,
  '290D9': 16,
  '290DA': 16,
  '290DB': 16,
  '290DC': 16,
  '290DD': 16,
  '290DE': 16,
  '290DF': 16,
  '290E0': 16,
  '290E1': 16,
  '290E2': 16,
  '290E3': 16,
  '290E4': 16,
  '290E5': 16,
  '290E6': 16,
  '290E7': 16,
  '290E8': 16,
  '290E9': 16,
  '290EA': 16,
  '290EB': 16,
  '290EC': 16,
  '290ED': 16,
  '290EE': 16,
  '290EF': 16,
  '290F0': 16,
  '290F1': 16,
  '290F2': 16,
  '290F3': 16,
  '290F4': 16,
  '290F5': 17,
  '290F6': 17,
  '290F7': 17,
  '290F8': 17,
  '290F9': 17,
  '290FA': 17,
  '290FB': 17,
  '290FC': 17,
  '290FD': 17,
  '290FE': 18,
  '290FF': 17,
  '29100': 17,
  '29101': 17,
  '29102': 17,
  '29103': 17,
  '29104': 17,
  '29105': 17,
  '29106': 17,
  '29107': 17,
  '29108': 17,
  '29109': 17,
  '2910A': 17,
  '2910B': 17,
  '2910C': 17,
  '2910D': 17,
  '2910E': 17,
  '2910F': 17,
  '29110': 17,
  '29111': 17,
  '29112': 17,
  '29113': 17,
  '29114': 17,
  '29115': 17,
  '29116': 17,
  '29117': 18,
  '29118': 18,
  '29119': 18,
  '2911A': 18,
  '2911B': 18,
  '2911C': 18,
  '2911D': 18,
  '2911E': 18,
  '2911F': 18,
  '29120': 18,
  '29121': 18,
  '29122': 18,
  '29123': 18,
  '29124': 18,
  '29125': 18,
  '29126': 18,
  '29127': 18,
  '29128': 18,
  '29129': 18,
  '2912A': 18,
  '2912B': 18,
  '2912C': 18,
  '2912D': 18,
  '2912E': 17,
  '2912F': 18,
  '29130': 18,
  '29131': 18,
  '29132': 18,
  '29133': 18,
  '29134': 18,
  '29135': 18,
  '29136': 18,
  '29137': 18,
  '29138': 18,
  '29139': 18,
  '2913A': 18,
  '2913B': 19,
  '2913C': 19,
  '2913D': 19,
  '2913E': 19,
  '2913F': 19,
  '29140': 19,
  '29141': 19,
  '29142': 19,
  '29143': 19,
  '29144': 19,
  '29145': 19,
  '29146': 19,
  '29147': 19,
  '29148': 19,
  '29149': 19,
  '2914A': 19,
  '2914B': 19,
  '2914C': 19,
  '2914D': 19,
  '2914E': 19,
  '2914F': 19,
  '29150': 19,
  '29151': 19,
  '29152': 19,
  '29153': 19,
  '29154': 19,
  '29155': 19,
  '29156': 19,
  '29157': 19,
  '29158': 19,
  '29159': 19,
  '2915A': 19,
  '2915B': 19,
  '2915C': 19,
  '2915D': 20,
  '2915E': 20,
  '2915F': 20,
  '29160': 20,
  '29161': 20,
  '29162': 20,
  '29163': 20,
  '29164': 20,
  '29165': 20,
  '29166': 20,
  '29167': 20,
  '29168': 20,
  '29169': 20,
  '2916A': 20,
  '2916B': 20,
  '2916C': 20,
  '2916D': 20,
  '2916E': 20,
  '2916F': 20,
  '29170': 20,
  '29171': 20,
  '29172': 20,
  '29173': 20,
  '29174': 20,
  '29175': 20,
  '29176': 20,
  '29177': 20,
  '29178': 20,
  '29179': 20,
  '2917A': 20,
  '2917B': 20,
  '2917C': 21,
  '2917D': 21,
  '2917E': 21,
  '2917F': 21,
  '29180': 21,
  '29181': 21,
  '29182': 21,
  '29183': 21,
  '29184': 21,
  '29185': 21,
  '29186': 21,
  '29187': 21,
  '29188': 21,
  '29189': 21,
  '2918A': 21,
  '2918B': 21,
  '2918C': 21,
  '2918D': 21,
  '2918E': 21,
  '2918F': 21,
  '29190': 21,
  '29191': 22,
  '29192': 22,
  '29193': 22,
  '29194': 22,
  '29195': 22,
  '29196': 22,
  '29197': 22,
  '29198': 22,
  '29199': 22,
  '2919A': 22,
  '2919B': 22,
  '2919C': 22,
  '2919D': 22,
  '2919E': 22,
  '2919F': 22,
  '291A0': 22,
  '291A1': 22,
  '291A2': 22,
  '291A3': 22,
  '291A4': 23,
  '291A5': 23,
  '291A6': 23,
  '291A7': 23,
  '291A8': 23,
  '291A9': 23,
  '291AA': 23,
  '291AB': 23,
  '291AC': 23,
  '291AD': 24,
  '291AE': 24,
  '291AF': 24,
  '291B0': 24,
  '291B1': 24,
  '291B2': 24,
  '291B3': 24,
  '291B4': 24,
  '291B5': 25,
  '291B6': 25,
  '291B7': 25,
  '291B8': 25,
  '291B9': 25,
  '291BA': 25,
  '291BB': 25,
  '291BC': 25,
  '291BD': 25,
  '291BE': 25,
  '291BF': 26,
  '291C0': 26,
  '291C1': 26,
  '291C2': 26,
  '291C3': 26,
  '291C4': 26,
  '291C5': 26,
  '291C6': 26,
  '291C7': 26,
  '291C8': 28,
  '291C9': 28,
  '291CA': 29,
  '291CB': 29,
  '291CC': 29,
  '291CD': 29,
  '291CE': 29,
  '291CF': 31,
  '291D0': 32,
  '291D1': 32,
  '291D2': 38,
  '291D3': 40,
  '291D4': 48,
  '291D5': 11,
  '291D6': 12,
  '291D7': 12,
  '291D8': 13,
  '291D9': 13,
  '291DA': 13,
  '291DB': 13,
  '291DC': 14,
  '291DD': 16,
  '291DE': 16,
  '291DF': 17,
  '291E0': 18,
  '291E1': 19,
  '291E2': 20,
  '291E3': 21,
  '291E4': 24,
  '291E5': 25,
  '291E6': 10,
  '291E7': 10,
  '291E8': 10,
  '291E9': 11,
  '291EA': 11,
  '291EB': 11,
  '291EC': 11,
  '291ED': 12,
  '291EE': 12,
  '291EF': 12,
  '291F0': 12,
  '291F1': 12,
  '291F2': 12,
  '291F3': 12,
  '291F4': 12,
  '291F5': 13,
  '291F6': 13,
  '291F7': 14,
  '291F8': 15,
  '291F9': 15,
  '291FA': 15,
  '291FB': 15,
  '291FC': 16,
  '291FD': 18,
  '291FE': 20,
  '291FF': 20,
  '29200': 20,
  '29201': 20,
  '29202': 26,
  '29203': 12,
  '29204': 12,
  '29205': 12,
  '29206': 13,
  '29207': 13,
  '29208': 13,
  '29209': 13,
  '2920A': 13,
  '2920B': 13,
  '2920C': 13,
  '2920D': 14,
  '2920E': 14,
  '2920F': 14,
  '29210': 14,
  '29211': 14,
  '29212': 14,
  '29213': 14,
  '29214': 14,
  '29215': 14,
  '29216': 14,
  '29217': 14,
  '29218': 14,
  '29219': 15,
  '2921A': 15,
  '2921B': 15,
  '2921C': 15,
  '2921D': 15,
  '2921E': 15,
  '2921F': 15,
  '29220': 15,
  '29221': 16,
  '29222': 16,
  '29223': 16,
  '29224': 16,
  '29225': 16,
  '29226': 16,
  '29227': 16,
  '29228': 16,
  '29229': 16,
  '2922A': 16,
  '2922B': 17,
  '2922C': 17,
  '2922D': 17,
  '2922E': 17,
  '2922F': 17,
  '29230': 17,
  '29231': 17,
  '29232': 18,
  '29233': 18,
  '29234': 18,
  '29235': 18,
  '29236': 18,
  '29237': 18,
  '29238': 19,
  '29239': 19,
  '2923A': 19,
  '2923B': 20,
  '2923C': 20,
  '2923D': 20,
  '2923E': 20,
  '2923F': 20,
  '29240': 20,
  '29241': 21,
  '29242': 21,
  '29243': 21,
  '29244': 21,
  '29245': 21,
  '29246': 21,
  '29247': 21,
  '29248': 21,
  '29249': 21,
  '2924A': 22,
  '2924B': 22,
  '2924C': 23,
  '2924D': 23,
  '2924E': 23,
  '2924F': 23,
  '29250': 23,
  '29251': 24,
  '29252': 24,
  '29253': 25,
  '29254': 26,
  '29255': 26,
  '29256': 27,
  '29257': 27,
  '29258': 27,
  '29259': 28,
  '2925A': 28,
  '2925B': 11,
  '2925C': 11,
  '2925D': 12,
  '2925E': 12,
  '2925F': 12,
  '29260': 12,
  '29261': 13,
  '29262': 13,
  '29263': 13,
  '29264': 13,
  '29265': 13,
  '29266': 13,
  '29267': 13,
  '29268': 13,
  '29269': 13,
  '2926A': 13,
  '2926B': 13,
  '2926C': 13,
  '2926D': 13,
  '2926E': 13,
  '2926F': 13,
  '29270': 13,
  '29271': 13,
  '29272': 13,
  '29273': 13,
  '29274': 13,
  '29275': 13,
  '29276': 13,
  '29277': 13,
  '29278': 14,
  '29279': 14,
  '2927A': 14,
  '2927B': 14,
  '2927C': 14,
  '2927D': 14,
  '2927E': 14,
  '2927F': 14,
  '29280': 14,
  '29281': 14,
  '29282': 14,
  '29283': 14,
  '29284': 14,
  '29285': 14,
  '29286': 14,
  '29287': 14,
  '29288': 14,
  '29289': 14,
  '2928A': 14,
  '2928B': 14,
  '2928C': 14,
  '2928D': 14,
  '2928E': 14,
  '2928F': 14,
  '29290': 15,
  '29291': 15,
  '29292': 15,
  '29293': 15,
  '29294': 15,
  '29295': 15,
  '29296': 15,
  '29297': 15,
  '29298': 15,
  '29299': 15,
  '2929A': 15,
  '2929B': 15,
  '2929C': 15,
  '2929D': 15,
  '2929E': 15,
  '2929F': 15,
  '292A0': 15,
  '292A1': 15,
  '292A2': 15,
  '292A3': 15,
  '292A4': 15,
  '292A5': 15,
  '292A6': 15,
  '292A7': 15,
  '292A8': 15,
  '292A9': 16,
  '292AA': 16,
  '292AB': 16,
  '292AC': 16,
  '292AD': 16,
  '292AE': 16,
  '292AF': 16,
  '292B0': 16,
  '292B1': 16,
  '292B2': 16,
  '292B3': 16,
  '292B4': 16,
  '292B5': 16,
  '292B6': 16,
  '292B7': 16,
  '292B8': 16,
  '292B9': 16,
  '292BA': 16,
  '292BB': 16,
  '292BC': 16,
  '292BD': 16,
  '292BE': 16,
  '292BF': 17,
  '292C0': 17,
  '292C1': 17,
  '292C2': 17,
  '292C3': 17,
  '292C4': 17,
  '292C5': 17,
  '292C6': 17,
  '292C7': 17,
  '292C8': 17,
  '292C9': 17,
  '292CA': 17,
  '292CB': 17,
  '292CC': 17,
  '292CD': 17,
  '292CE': 17,
  '292CF': 17,
  '292D0': 17,
  '292D1': 17,
  '292D2': 17,
  '292D3': 17,
  '292D4': 17,
  '292D5': 17,
  '292D6': 17,
  '292D7': 17,
  '292D8': 17,
  '292D9': 17,
  '292DA': 17,
  '292DB': 17,
  '292DC': 17,
  '292DD': 17,
  '292DE': 17,
  '292DF': 18,
  '292E0': 18,
  '292E1': 18,
  '292E2': 18,
  '292E3': 18,
  '292E4': 18,
  '292E5': 18,
  '292E6': 18,
  '292E7': 18,
  '292E8': 18,
  '292E9': 18,
  '292EA': 18,
  '292EB': 18,
  '292EC': 18,
  '292ED': 18,
  '292EE': 18,
  '292EF': 18,
  '292F0': 18,
  '292F1': 18,
  '292F2': 18,
  '292F3': 18,
  '292F4': 18,
  '292F5': 18,
  '292F6': 18,
  '292F7': 18,
  '292F8': 18,
  '292F9': 18,
  '292FA': 18,
  '292FB': 18,
  '292FC': 18,
  '292FD': 18,
  '292FE': 18,
  '292FF': 18,
  '29300': 18,
  '29301': 18,
  '29302': 18,
  '29303': 19,
  '29304': 19,
  '29305': 19,
  '29306': 19,
  '29307': 19,
  '29308': 19,
  '29309': 19,
  '2930A': 19,
  '2930B': 19,
  '2930C': 19,
  '2930D': 19,
  '2930E': 19,
  '2930F': 19,
  '29310': 19,
  '29311': 19,
  '29312': 19,
  '29313': 19,
  '29314': 19,
  '29315': 19,
  '29316': 19,
  '29317': 19,
  '29318': 19,
  '29319': 19,
  '2931A': 19,
  '2931B': 19,
  '2931C': 19,
  '2931D': 19,
  '2931E': 19,
  '2931F': 19,
  '29320': 19,
  '29321': 19,
  '29322': 19,
  '29323': 19,
  '29324': 19,
  '29325': 19,
  '29326': 20,
  '29327': 20,
  '29328': 20,
  '29329': 20,
  '2932A': 20,
  '2932B': 20,
  '2932C': 20,
  '2932D': 20,
  '2932E': 20,
  '2932F': 20,
  '29330': 20,
  '29331': 20,
  '29332': 20,
  '29333': 20,
  '29334': 20,
  '29335': 20,
  '29336': 20,
  '29337': 20,
  '29338': 20,
  '29339': 20,
  '2933A': 20,
  '2933B': 20,
  '2933C': 20,
  '2933D': 20,
  '2933E': 20,
  '2933F': 20,
  '29340': 20,
  '29341': 20,
  '29342': 20,
  '29343': 21,
  '29344': 21,
  '29345': 21,
  '29346': 21,
  '29347': 21,
  '29348': 21,
  '29349': 21,
  '2934A': 21,
  '2934B': 21,
  '2934C': 21,
  '2934D': 21,
  '2934E': 21,
  '2934F': 21,
  '29350': 21,
  '29351': 21,
  '29352': 21,
  '29353': 22,
  '29354': 22,
  '29355': 22,
  '29356': 22,
  '29357': 22,
  '29358': 22,
  '29359': 22,
  '2935A': 22,
  '2935B': 22,
  '2935C': 22,
  '2935D': 22,
  '2935E': 22,
  '2935F': 22,
  '29360': 22,
  '29361': 22,
  '29362': 22,
  '29363': 22,
  '29364': 22,
  '29365': 23,
  '29366': 23,
  '29367': 23,
  '29368': 23,
  '29369': 23,
  '2936A': 23,
  '2936B': 23,
  '2936C': 23,
  '2936D': 23,
  '2936E': 23,
  '2936F': 23,
  '29370': 23,
  '29371': 23,
  '29372': 24,
  '29373': 24,
  '29374': 24,
  '29375': 24,
  '29376': 24,
  '29377': 24,
  '29378': 25,
  '29379': 25,
  '2937A': 25,
  '2937B': 25,
  '2937C': 25,
  '2937D': 25,
  '2937E': 25,
  '2937F': 26,
  '29380': 26,
  '29381': 26,
  '29382': 26,
  '29383': 26,
  '29384': 26,
  '29385': 26,
  '29386': 26,
  '29387': 27,
  '29388': 28,
  '29389': 28,
  '2938A': 28,
  '2938B': 30,
  '2938C': 32,
  '2938D': 32,
  '2938E': 33,
  '2938F': 33,
  '29390': 34,
  '29391': 38,
  '29392': 12,
  '29393': 13,
  '29394': 13,
  '29395': 13,
  '29396': 13,
  '29397': 13,
  '29398': 14,
  '29399': 14,
  '2939A': 14,
  '2939B': 14,
  '2939C': 14,
  '2939D': 14,
  '2939E': 14,
  '2939F': 14,
  '293A0': 14,
  '293A1': 14,
  '293A2': 14,
  '293A3': 14,
  '293A4': 15,
  '293A5': 15,
  '293A6': 15,
  '293A7': 15,
  '293A8': 15,
  '293A9': 15,
  '293AA': 15,
  '293AB': 15,
  '293AC': 15,
  '293AD': 15,
  '293AE': 15,
  '293AF': 16,
  '293B0': 16,
  '293B1': 16,
  '293B2': 16,
  '293B3': 16,
  '293B4': 16,
  '293B5': 16,
  '293B6': 16,
  '293B7': 16,
  '293B8': 17,
  '293B9': 17,
  '293BA': 17,
  '293BB': 17,
  '293BC': 17,
  '293BD': 17,
  '293BE': 17,
  '293BF': 17,
  '293C0': 17,
  '293C1': 17,
  '293C2': 17,
  '293C3': 17,
  '293C4': 17,
  '293C5': 18,
  '293C6': 18,
  '293C7': 18,
  '293C8': 18,
  '293C9': 18,
  '293CA': 18,
  '293CB': 18,
  '293CC': 18,
  '293CD': 18,
  '293CE': 18,
  '293CF': 19,
  '293D0': 19,
  '293D1': 19,
  '293D2': 19,
  '293D3': 19,
  '293D4': 19,
  '293D5': 19,
  '293D6': 19,
  '293D7': 19,
  '293D8': 20,
  '293D9': 20,
  '293DA': 20,
  '293DB': 20,
  '293DC': 20,
  '293DD': 20,
  '293DE': 20,
  '293DF': 20,
  '293E0': 21,
  '293E1': 21,
  '293E2': 21,
  '293E3': 21,
  '293E4': 21,
  '293E5': 21,
  '293E6': 21,
  '293E7': 21,
  '293E8': 22,
  '293E9': 22,
  '293EA': 22,
  '293EB': 22,
  '293EC': 22,
  '293ED': 22,
  '293EE': 23,
  '293EF': 23,
  '293F0': 23,
  '293F1': 23,
  '293F2': 24,
  '293F3': 24,
  '293F4': 25,
  '293F5': 26,
  '293F6': 27,
  '293F7': 27,
  '293F8': 27,
  '293F9': 28,
  '293FA': 29,
  '293FB': 29,
  '293FC': 8,
  '293FD': 9,
  '293FE': 9,
  '293FF': 13,
  '29400': 13,
  '29401': 13,
  '29402': 13,
  '29403': 15,
  '29404': 16,
  '29405': 17,
  '29406': 17,
  '29407': 17,
  '29408': 18,
  '29409': 19,
  '2940A': 19,
  '2940B': 20,
  '2940C': 21,
  '2940D': 20,
  '2940E': 20,
  '2940F': 21,
  '29410': 21,
  '29411': 22,
  '29412': 22,
  '29413': 22,
  '29414': 23,
  '29415': 23,
  '29416': 25,
  '29417': 12,
  '29418': 13,
  '29419': 13,
  '2941A': 14,
  '2941B': 14,
  '2941C': 14,
  '2941D': 14,
  '2941E': 15,
  '2941F': 15,
  '29420': 15,
  '29421': 15,
  '29422': 15,
  '29423': 15,
  '29424': 15,
  '29425': 15,
  '29426': 15,
  '29427': 16,
  '29428': 16,
  '29429': 16,
  '2942A': 16,
  '2942B': 16,
  '2942C': 16,
  '2942D': 17,
  '2942E': 17,
  '2942F': 17,
  '29430': 17,
  '29431': 18,
  '29432': 18,
  '29433': 18,
  '29434': 18,
  '29435': 19,
  '29436': 19,
  '29437': 19,
  '29438': 19,
  '29439': 20,
  '2943A': 20,
  '2943B': 20,
  '2943C': 20,
  '2943D': 20,
  '2943E': 20,
  '2943F': 21,
  '29440': 21,
  '29441': 21,
  '29442': 22,
  '29443': 22,
  '29444': 22,
  '29445': 22,
  '29446': 22,
  '29447': 23,
  '29448': 24,
  '29449': 27,
  '2944A': 33,
  '2944B': 9,
  '2944C': 11,
  '2944D': 11,
  '2944E': 11,
  '2944F': 11,
  '29450': 12,
  '29451': 12,
  '29452': 12,
  '29453': 12,
  '29454': 12,
  '29455': 12,
  '29456': 12,
  '29457': 12,
  '29458': 12,
  '29459': 13,
  '2945A': 13,
  '2945B': 13,
  '2945C': 13,
  '2945D': 13,
  '2945E': 13,
  '2945F': 13,
  '29460': 13,
  '29461': 13,
  '29462': 13,
  '29463': 13,
  '29464': 13,
  '29465': 13,
  '29466': 13,
  '29467': 13,
  '29468': 13,
  '29469': 13,
  '2946A': 13,
  '2946B': 13,
  '2946C': 13,
  '2946D': 13,
  '2946E': 13,
  '2946F': 13,
  '29470': 14,
  '29471': 14,
  '29472': 14,
  '29473': 14,
  '29474': 14,
  '29475': 14,
  '29476': 14,
  '29477': 14,
  '29478': 14,
  '29479': 14,
  '2947A': 14,
  '2947B': 14,
  '2947C': 14,
  '2947D': 14,
  '2947E': 14,
  '2947F': 14,
  '29480': 14,
  '29481': 14,
  '29482': 14,
  '29483': 14,
  '29484': 14,
  '29485': 14,
  '29486': 14,
  '29487': 14,
  '29488': 14,
  '29489': 14,
  '2948A': 14,
  '2948B': 14,
  '2948C': 14,
  '2948D': 14,
  '2948E': 14,
  '2948F': 15,
  '29490': 15,
  '29491': 15,
  '29492': 15,
  '29493': 15,
  '29494': 15,
  '29495': 15,
  '29496': 15,
  '29497': 15,
  '29498': 15,
  '29499': 15,
  '2949A': 15,
  '2949B': 15,
  '2949C': 15,
  '2949D': 15,
  '2949E': 15,
  '2949F': 15,
  '294A0': 15,
  '294A1': 15,
  '294A2': 15,
  '294A3': 15,
  '294A4': 15,
  '294A5': 15,
  '294A6': 15,
  '294A7': 15,
  '294A8': 15,
  '294A9': 15,
  '294AA': 15,
  '294AB': 15,
  '294AC': 15,
  '294AD': 15,
  '294AE': 16,
  '294AF': 16,
  '294B0': 16,
  '294B1': 16,
  '294B2': 16,
  '294B3': 16,
  '294B4': 16,
  '294B5': 16,
  '294B6': 16,
  '294B7': 16,
  '294B8': 16,
  '294B9': 16,
  '294BA': 16,
  '294BB': 16,
  '294BC': 16,
  '294BD': 16,
  '294BE': 16,
  '294BF': 16,
  '294C0': 16,
  '294C1': 16,
  '294C2': 16,
  '294C3': 16,
  '294C4': 16,
  '294C5': 16,
  '294C6': 16,
  '294C7': 16,
  '294C8': 16,
  '294C9': 16,
  '294CA': 16,
  '294CB': 16,
  '294CC': 16,
  '294CD': 16,
  '294CE': 16,
  '294CF': 16,
  '294D0': 16,
  '294D1': 16,
  '294D2': 16,
  '294D3': 16,
  '294D4': 16,
  '294D5': 16,
  '294D6': 16,
  '294D7': 16,
  '294D8': 16,
  '294D9': 16,
  '294DA': 16,
  '294DB': 16,
  '294DC': 16,
  '294DD': 17,
  '294DE': 17,
  '294DF': 17,
  '294E0': 17,
  '294E1': 17,
  '294E2': 17,
  '294E3': 17,
  '294E4': 17,
  '294E5': 17,
  '294E6': 17,
  '294E7': 17,
  '294E8': 17,
  '294E9': 17,
  '294EA': 17,
  '294EB': 17,
  '294EC': 17,
  '294ED': 17,
  '294EE': 17,
  '294EF': 17,
  '294F0': 17,
  '294F1': 17,
  '294F2': 17,
  '294F3': 17,
  '294F4': 17,
  '294F5': 17,
  '294F6': 17,
  '294F7': 17,
  '294F8': 17,
  '294F9': 17,
  '294FA': 17,
  '294FB': 17,
  '294FC': 17,
  '294FD': 17,
  '294FE': 17,
  '294FF': 17,
  '29500': 18,
  '29501': 18,
  '29502': 18,
  '29503': 18,
  '29504': 18,
  '29505': 18,
  '29506': 18,
  '29507': 18,
  '29508': 18,
  '29509': 18,
  '2950A': 18,
  '2950B': 18,
  '2950C': 18,
  '2950D': 18,
  '2950E': 18,
  '2950F': 18,
  '29510': 18,
  '29511': 18,
  '29512': 18,
  '29513': 18,
  '29514': 18,
  '29515': 18,
  '29516': 18,
  '29517': 18,
  '29518': 18,
  '29519': 19,
  '2951A': 19,
  '2951B': 19,
  '2951C': 19,
  '2951D': 19,
  '2951E': 19,
  '2951F': 19,
  '29520': 19,
  '29521': 19,
  '29522': 19,
  '29523': 19,
  '29524': 19,
  '29525': 19,
  '29526': 19,
  '29527': 19,
  '29528': 19,
  '29529': 19,
  '2952A': 19,
  '2952B': 19,
  '2952C': 19,
  '2952D': 19,
  '2952E': 19,
  '2952F': 19,
  '29530': 19,
  '29531': 19,
  '29532': 19,
  '29533': 20,
  '29534': 20,
  '29535': 20,
  '29536': 20,
  '29537': 20,
  '29538': 20,
  '29539': 20,
  '2953A': 20,
  '2953B': 20,
  '2953C': 20,
  '2953D': 20,
  '2953E': 20,
  '2953F': 20,
  '29540': 20,
  '29541': 20,
  '29542': 20,
  '29543': 20,
  '29544': 20,
  '29545': 20,
  '29546': 20,
  '29547': 20,
  '29548': 20,
  '29549': 21,
  '2954A': 21,
  '2954B': 21,
  '2954C': 21,
  '2954D': 21,
  '2954E': 21,
  '2954F': 21,
  '29550': 21,
  '29551': 21,
  '29552': 21,
  '29553': 21,
  '29554': 21,
  '29555': 21,
  '29556': 21,
  '29557': 21,
  '29558': 21,
  '29559': 21,
  '2955A': 21,
  '2955B': 21,
  '2955C': 21,
  '2955D': 21,
  '2955E': 21,
  '2955F': 22,
  '29560': 22,
  '29561': 22,
  '29562': 22,
  '29563': 22,
  '29564': 22,
  '29565': 22,
  '29566': 22,
  '29567': 22,
  '29568': 22,
  '29569': 22,
  '2956A': 22,
  '2956B': 22,
  '2956C': 23,
  '2956D': 23,
  '2956E': 23,
  '2956F': 23,
  '29570': 23,
  '29571': 23,
  '29572': 23,
  '29573': 23,
  '29574': 23,
  '29575': 23,
  '29576': 23,
  '29577': 23,
  '29578': 23,
  '29579': 23,
  '2957A': 23,
  '2957B': 23,
  '2957C': 23,
  '2957D': 23,
  '2957E': 24,
  '2957F': 24,
  '29580': 24,
  '29581': 24,
  '29582': 24,
  '29583': 24,
  '29584': 24,
  '29585': 24,
  '29586': 25,
  '29587': 25,
  '29588': 25,
  '29589': 26,
  '2958A': 26,
  '2958B': 26,
  '2958C': 26,
  '2958D': 26,
  '2958E': 26,
  '2958F': 27,
  '29590': 27,
  '29591': 27,
  '29592': 29,
  '29593': 29,
  '29594': 31,
  '29595': 14,
  '29596': 14,
  '29597': 19,
  '29598': 11,
  '29599': 11,
  '2959A': 12,
  '2959B': 12,
  '2959C': 12,
  '2959D': 12,
  '2959E': 12,
  '2959F': 12,
  '295A0': 12,
  '295A1': 12,
  '295A2': 13,
  '295A3': 13,
  '295A4': 13,
  '295A5': 13,
  '295A6': 13,
  '295A7': 13,
  '295A8': 13,
  '295A9': 13,
  '295AA': 13,
  '295AB': 13,
  '295AC': 13,
  '295AD': 13,
  '295AE': 13,
  '295AF': 13,
  '295B0': 13,
  '295B1': 13,
  '295B2': 13,
  '295B3': 13,
  '295B4': 14,
  '295B5': 14,
  '295B6': 14,
  '295B7': 14,
  '295B8': 14,
  '295B9': 14,
  '295BA': 14,
  '295BB': 14,
  '295BC': 14,
  '295BD': 14,
  '295BE': 14,
  '295BF': 14,
  '295C0': 14,
  '295C1': 14,
  '295C2': 14,
  '295C3': 14,
  '295C4': 15,
  '295C5': 15,
  '295C6': 15,
  '295C7': 15,
  '295C8': 15,
  '295C9': 15,
  '295CA': 15,
  '295CB': 15,
  '295CC': 15,
  '295CD': 15,
  '295CE': 15,
  '295CF': 15,
  '295D0': 15,
  '295D1': 15,
  '295D2': 15,
  '295D3': 16,
  '295D4': 16,
  '295D5': 16,
  '295D6': 16,
  '295D7': 16,
  '295D8': 16,
  '295D9': 16,
  '295DA': 16,
  '295DB': 16,
  '295DC': 16,
  '295DD': 16,
  '295DE': 16,
  '295DF': 16,
  '295E0': 16,
  '295E1': 16,
  '295E2': 16,
  '295E3': 16,
  '295E4': 16,
  '295E5': 16,
  '295E6': 16,
  '295E7': 16,
  '295E8': 17,
  '295E9': 17,
  '295EA': 17,
  '295EB': 17,
  '295EC': 17,
  '295ED': 17,
  '295EE': 17,
  '295EF': 17,
  '295F0': 17,
  '295F1': 17,
  '295F2': 17,
  '295F3': 17,
  '295F4': 17,
  '295F5': 17,
  '295F6': 17,
  '295F7': 17,
  '295F8': 17,
  '295F9': 17,
  '295FA': 17,
  '295FB': 17,
  '295FC': 17,
  '295FD': 17,
  '295FE': 17,
  '295FF': 17,
  '29600': 17,
  '29601': 17,
  '29602': 17,
  '29603': 17,
  '29604': 17,
  '29605': 18,
  '29606': 18,
  '29607': 18,
  '29608': 18,
  '29609': 18,
  '2960A': 18,
  '2960B': 18,
  '2960C': 18,
  '2960D': 18,
  '2960E': 19,
  '2960F': 18,
  '29610': 18,
  '29611': 18,
  '29612': 18,
  '29613': 18,
  '29614': 18,
  '29615': 18,
  '29616': 18,
  '29617': 18,
  '29618': 18,
  '29619': 18,
  '2961A': 18,
  '2961B': 18,
  '2961C': 19,
  '2961D': 19,
  '2961E': 19,
  '2961F': 19,
  '29620': 19,
  '29621': 19,
  '29622': 19,
  '29623': 19,
  '29624': 19,
  '29625': 19,
  '29626': 19,
  '29627': 19,
  '29628': 19,
  '29629': 19,
  '2962A': 19,
  '2962B': 19,
  '2962C': 19,
  '2962D': 20,
  '2962E': 20,
  '2962F': 20,
  '29630': 20,
  '29631': 20,
  '29632': 20,
  '29633': 20,
  '29634': 20,
  '29635': 20,
  '29636': 20,
  '29637': 20,
  '29638': 21,
  '29639': 21,
  '2963A': 21,
  '2963B': 21,
  '2963C': 21,
  '2963D': 21,
  '2963E': 21,
  '2963F': 21,
  '29640': 21,
  '29641': 21,
  '29642': 21,
  '29643': 21,
  '29644': 21,
  '29645': 21,
  '29646': 21,
  '29647': 21,
  '29648': 22,
  '29649': 22,
  '2964A': 22,
  '2964B': 22,
  '2964C': 22,
  '2964D': 22,
  '2964E': 23,
  '2964F': 23,
  '29650': 23,
  '29651': 24,
  '29652': 24,
  '29653': 24,
  '29654': 24,
  '29655': 24,
  '29656': 25,
  '29657': 25,
  '29658': 25,
  '29659': 25,
  '2965A': 25,
  '2965B': 26,
  '2965C': 26,
  '2965D': 27,
  '2965E': 27,
  '2965F': 28,
  '29660': 35,
  '29661': 36,
  '29662': 37,
  '29663': 46,
  '29664': 48,
  '29665': 9,
  '29666': 9,
  '29667': 11,
  '29668': 12,
  '29669': 12,
  '2966A': 12,
  '2966B': 13,
  '2966C': 13,
  '2966D': 14,
  '2966E': 14,
  '2966F': 16,
  '29670': 17,
  '29671': 9,
  '29672': 13,
  '29673': 14,
  '29674': 15,
  '29675': 18,
  '29676': 19,
  '29677': 19,
  '29678': 19,
  '29679': 20,
  '2967A': 21,
  '2967B': 21,
  '2967C': 22,
  '2967D': 22,
  '2967E': 31,
  '2967F': 8,
  '29680': 8,
  '29681': 8,
  '29682': 9,
  '29683': 8,
  '29684': 10,
  '29685': 10,
  '29686': 10,
  '29687': 11,
  '29688': 11,
  '29689': 11,
  '2968A': 11,
  '2968B': 11,
  '2968C': 11,
  '2968D': 11,
  '2968E': 11,
  '2968F': 11,
  '29690': 11,
  '29691': 11,
  '29692': 11,
  '29693': 11,
  '29694': 11,
  '29695': 12,
  '29696': 12,
  '29697': 12,
  '29698': 12,
  '29699': 12,
  '2969A': 12,
  '2969B': 12,
  '2969C': 12,
  '2969D': 12,
  '2969E': 12,
  '2969F': 12,
  '296A0': 12,
  '296A1': 12,
  '296A2': 12,
  '296A3': 12,
  '296A4': 12,
  '296A5': 12,
  '296A6': 12,
  '296A7': 12,
  '296A8': 13,
  '296A9': 13,
  '296AA': 13,
  '296AB': 13,
  '296AC': 13,
  '296AD': 13,
  '296AE': 13,
  '296AF': 13,
  '296B0': 13,
  '296B1': 13,
  '296B2': 13,
  '296B3': 13,
  '296B4': 13,
  '296B5': 13,
  '296B6': 13,
  '296B7': 13,
  '296B8': 13,
  '296B9': 13,
  '296BA': 13,
  '296BB': 13,
  '296BC': 13,
  '296BD': 13,
  '296BE': 13,
  '296BF': 13,
  '296C0': 13,
  '296C1': 13,
  '296C2': 13,
  '296C3': 13,
  '296C4': 13,
  '296C5': 13,
  '296C6': 13,
  '296C7': 13,
  '296C8': 13,
  '296C9': 14,
  '296CA': 14,
  '296CB': 14,
  '296CC': 14,
  '296CD': 14,
  '296CE': 14,
  '296CF': 14,
  '296D0': 14,
  '296D1': 14,
  '296D2': 14,
  '296D3': 14,
  '296D4': 14,
  '296D5': 14,
  '296D6': 14,
  '296D7': 14,
  '296D8': 14,
  '296D9': 14,
  '296DA': 14,
  '296DB': 14,
  '296DC': 14,
  '296DD': 15,
  '296DE': 15,
  '296DF': 15,
  '296E0': 15,
  '296E1': 15,
  '296E2': 15,
  '296E3': 15,
  '296E4': 15,
  '296E5': 15,
  '296E6': 15,
  '296E7': 15,
  '296E8': 15,
  '296E9': 15,
  '296EA': 15,
  '296EB': 15,
  '296EC': 15,
  '296ED': 15,
  '296EE': 15,
  '296EF': 15,
  '296F0': 15,
  '296F1': 15,
  '296F2': 15,
  '296F3': 15,
  '296F4': 15,
  '296F5': 15,
  '296F6': 15,
  '296F7': 15,
  '296F8': 15,
  '296F9': 15,
  '296FA': 16,
  '296FB': 16,
  '296FC': 16,
  '296FD': 16,
  '296FE': 16,
  '296FF': 16,
  '29700': 16,
  '29701': 16,
  '29702': 16,
  '29703': 16,
  '29704': 16,
  '29705': 16,
  '29706': 16,
  '29707': 16,
  '29708': 16,
  '29709': 16,
  '2970A': 16,
  '2970B': 16,
  '2970C': 16,
  '2970D': 16,
  '2970E': 16,
  '2970F': 16,
  '29710': 16,
  '29711': 16,
  '29712': 16,
  '29713': 16,
  '29714': 16,
  '29715': 16,
  '29716': 16,
  '29717': 16,
  '29718': 16,
  '29719': 16,
  '2971A': 16,
  '2971B': 16,
  '2971C': 16,
  '2971D': 16,
  '2971E': 16,
  '2971F': 16,
  '29720': 16,
  '29721': 16,
  '29722': 16,
  '29723': 17,
  '29724': 16,
  '29725': 16,
  '29726': 16,
  '29727': 16,
  '29728': 16,
  '29729': 16,
  '2972A': 16,
  '2972B': 16,
  '2972C': 16,
  '2972D': 17,
  '2972E': 17,
  '2972F': 17,
  '29730': 17,
  '29731': 17,
  '29732': 17,
  '29733': 17,
  '29734': 17,
  '29735': 17,
  '29736': 17,
  '29737': 17,
  '29738': 17,
  '29739': 17,
  '2973A': 17,
  '2973B': 17,
  '2973C': 17,
  '2973D': 17,
  '2973E': 17,
  '2973F': 17,
  '29740': 17,
  '29741': 17,
  '29742': 17,
  '29743': 17,
  '29744': 17,
  '29745': 17,
  '29746': 17,
  '29747': 17,
  '29748': 17,
  '29749': 17,
  '2974A': 17,
  '2974B': 17,
  '2974C': 17,
  '2974D': 17,
  '2974E': 17,
  '2974F': 17,
  '29750': 17,
  '29751': 17,
  '29752': 17,
  '29753': 17,
  '29754': 17,
  '29755': 17,
  '29756': 17,
  '29757': 17,
  '29758': 17,
  '29759': 18,
  '2975A': 18,
  '2975B': 18,
  '2975C': 18,
  '2975D': 18,
  '2975E': 18,
  '2975F': 18,
  '29760': 18,
  '29761': 18,
  '29762': 18,
  '29763': 18,
  '29764': 18,
  '29765': 18,
  '29766': 18,
  '29767': 18,
  '29768': 18,
  '29769': 18,
  '2976A': 18,
  '2976B': 18,
  '2976C': 18,
  '2976D': 18,
  '2976E': 18,
  '2976F': 18,
  '29770': 18,
  '29771': 18,
  '29772': 18,
  '29773': 18,
  '29774': 19,
  '29775': 18,
  '29776': 18,
  '29777': 18,
  '29778': 18,
  '29779': 18,
  '2977A': 18,
  '2977B': 18,
  '2977C': 18,
  '2977D': 19,
  '2977E': 19,
  '2977F': 19,
  '29780': 19,
  '29781': 19,
  '29782': 19,
  '29783': 19,
  '29784': 19,
  '29785': 19,
  '29786': 19,
  '29787': 19,
  '29788': 19,
  '29789': 19,
  '2978A': 19,
  '2978B': 19,
  '2978C': 19,
  '2978D': 19,
  '2978E': 19,
  '2978F': 19,
  '29790': 19,
  '29791': 19,
  '29792': 19,
  '29793': 19,
  '29794': 19,
  '29795': 19,
  '29796': 19,
  '29797': 19,
  '29798': 19,
  '29799': 19,
  '2979A': 19,
  '2979B': 19,
  '2979C': 19,
  '2979D': 19,
  '2979E': 19,
  '2979F': 19,
  '297A0': 19,
  '297A1': 20,
  '297A2': 20,
  '297A3': 20,
  '297A4': 20,
  '297A5': 20,
  '297A6': 20,
  '297A7': 20,
  '297A8': 20,
  '297A9': 20,
  '297AA': 20,
  '297AB': 20,
  '297AC': 20,
  '297AD': 20,
  '297AE': 20,
  '297AF': 20,
  '297B0': 20,
  '297B1': 20,
  '297B2': 20,
  '297B3': 20,
  '297B4': 20,
  '297B5': 20,
  '297B6': 21,
  '297B7': 20,
  '297B8': 20,
  '297B9': 20,
  '297BA': 20,
  '297BB': 20,
  '297BC': 20,
  '297BD': 20,
  '297BE': 21,
  '297BF': 21,
  '297C0': 21,
  '297C1': 21,
  '297C2': 21,
  '297C3': 21,
  '297C4': 21,
  '297C5': 21,
  '297C6': 21,
  '297C7': 21,
  '297C8': 21,
  '297C9': 21,
  '297CA': 21,
  '297CB': 21,
  '297CC': 21,
  '297CD': 21,
  '297CE': 21,
  '297CF': 21,
  '297D0': 21,
  '297D1': 21,
  '297D2': 21,
  '297D3': 22,
  '297D4': 22,
  '297D5': 22,
  '297D6': 22,
  '297D7': 22,
  '297D8': 22,
  '297D9': 22,
  '297DA': 22,
  '297DB': 22,
  '297DC': 22,
  '297DD': 22,
  '297DE': 22,
  '297DF': 22,
  '297E0': 22,
  '297E1': 22,
  '297E2': 22,
  '297E3': 22,
  '297E4': 23,
  '297E5': 23,
  '297E6': 23,
  '297E7': 23,
  '297E8': 23,
  '297E9': 23,
  '297EA': 23,
  '297EB': 23,
  '297EC': 23,
  '297ED': 24,
  '297EE': 24,
  '297EF': 24,
  '297F0': 24,
  '297F1': 24,
  '297F2': 24,
  '297F3': 24,
  '297F4': 24,
  '297F5': 24,
  '297F6': 25,
  '297F7': 26,
  '297F8': 26,
  '297F9': 26,
  '297FA': 26,
  '297FB': 29,
  '297FC': 30,
  '297FD': 32,
  '297FE': 7,
  '297FF': 7,
  '29800': 7,
  '29801': 8,
  '29802': 8,
  '29803': 9,
  '29804': 10,
  '29805': 10,
  '29806': 11,
  '29807': 10,
  '29808': 11,
  '29809': 11,
  '2980A': 12,
  '2980B': 12,
  '2980C': 13,
  '2980D': 14,
  '2980E': 14,
  '2980F': 15,
  '29810': 9,
  '29811': 11,
  '29812': 11,
  '29813': 12,
  '29814': 13,
  '29815': 14,
  '29816': 14,
  '29817': 14,
  '29818': 14,
  '29819': 14,
  '2981A': 15,
  '2981B': 15,
  '2981C': 15,
  '2981D': 15,
  '2981E': 15,
  '2981F': 15,
  '29820': 15,
  '29821': 16,
  '29822': 16,
  '29823': 16,
  '29824': 16,
  '29825': 16,
  '29826': 16,
  '29827': 16,
  '29828': 17,
  '29829': 17,
  '2982A': 18,
  '2982B': 18,
  '2982C': 18,
  '2982D': 18,
  '2982E': 19,
  '2982F': 19,
  '29830': 20,
  '29831': 19,
  '29832': 20,
  '29833': 21,
  '29834': 22,
  '29835': 23,
  '29836': 23,
  '29837': 24,
  '29838': 25,
  '29839': 27,
  '2983A': 10,
  '2983B': 13,
  '2983C': 13,
  '2983D': 13,
  '2983E': 14,
  '2983F': 14,
  '29840': 14,
  '29841': 14,
  '29842': 15,
  '29843': 14,
  '29844': 14,
  '29845': 15,
  '29846': 15,
  '29847': 16,
  '29848': 16,
  '29849': 17,
  '2984A': 17,
  '2984B': 17,
  '2984C': 18,
  '2984D': 18,
  '2984E': 18,
  '2984F': 17,
  '29850': 18,
  '29851': 18,
  '29852': 18,
  '29853': 19,
  '29854': 19,
  '29855': 19,
  '29856': 19,
  '29857': 19,
  '29858': 19,
  '29859': 20,
  '2985A': 20,
  '2985B': 20,
  '2985C': 20,
  '2985D': 21,
  '2985E': 21,
  '2985F': 21,
  '29860': 21,
  '29861': 21,
  '29862': 22,
  '29863': 22,
  '29864': 23,
  '29865': 26,
  '29866': 28,
  '29867': 10,
  '29868': 11,
  '29869': 12,
  '2986A': 12,
  '2986B': 12,
  '2986C': 12,
  '2986D': 12,
  '2986E': 12,
  '2986F': 12,
  '29870': 13,
  '29871': 13,
  '29872': 13,
  '29873': 13,
  '29874': 13,
  '29875': 13,
  '29876': 13,
  '29877': 14,
  '29878': 14,
  '29879': 14,
  '2987A': 14,
  '2987B': 14,
  '2987C': 14,
  '2987D': 14,
  '2987E': 14,
  '2987F': 15,
  '29880': 14,
  '29881': 14,
  '29882': 14,
  '29883': 14,
  '29884': 14,
  '29885': 14,
  '29886': 15,
  '29887': 14,
  '29888': 14,
  '29889': 14,
  '2988A': 14,
  '2988B': 14,
  '2988C': 14,
  '2988D': 15,
  '2988E': 15,
  '2988F': 15,
  '29890': 15,
  '29891': 15,
  '29892': 15,
  '29893': 15,
  '29894': 15,
  '29895': 15,
  '29896': 15,
  '29897': 15,
  '29898': 15,
  '29899': 15,
  '2989A': 15,
  '2989B': 15,
  '2989C': 15,
  '2989D': 15,
  '2989E': 15,
  '2989F': 15,
  '298A0': 15,
  '298A1': 15,
  '298A2': 15,
  '298A3': 15,
  '298A4': 15,
  '298A5': 15,
  '298A6': 15,
  '298A7': 15,
  '298A8': 15,
  '298A9': 15,
  '298AA': 15,
  '298AB': 15,
  '298AC': 15,
  '298AD': 15,
  '298AE': 16,
  '298AF': 16,
  '298B0': 16,
  '298B1': 16,
  '298B2': 16,
  '298B3': 16,
  '298B4': 16,
  '298B5': 16,
  '298B6': 16,
  '298B7': 16,
  '298B8': 16,
  '298B9': 16,
  '298BA': 16,
  '298BB': 16,
  '298BC': 16,
  '298BD': 16,
  '298BE': 16,
  '298BF': 16,
  '298C0': 16,
  '298C1': 16,
  '298C2': 16,
  '298C3': 16,
  '298C4': 16,
  '298C5': 16,
  '298C6': 16,
  '298C7': 16,
  '298C8': 16,
  '298C9': 16,
  '298CA': 16,
  '298CB': 16,
  '298CC': 16,
  '298CD': 16,
  '298CE': 16,
  '298CF': 16,
  '298D0': 16,
  '298D1': 16,
  '298D2': 16,
  '298D3': 16,
  '298D4': 16,
  '298D5': 16,
  '298D6': 17,
  '298D7': 17,
  '298D8': 17,
  '298D9': 17,
  '298DA': 17,
  '298DB': 17,
  '298DC': 17,
  '298DD': 17,
  '298DE': 17,
  '298DF': 17,
  '298E0': 17,
  '298E1': 17,
  '298E2': 17,
  '298E3': 17,
  '298E4': 17,
  '298E5': 17,
  '298E6': 17,
  '298E7': 17,
  '298E8': 17,
  '298E9': 17,
  '298EA': 17,
  '298EB': 17,
  '298EC': 17,
  '298ED': 18,
  '298EE': 18,
  '298EF': 18,
  '298F0': 18,
  '298F1': 18,
  '298F2': 18,
  '298F3': 18,
  '298F4': 18,
  '298F5': 18,
  '298F6': 18,
  '298F7': 18,
  '298F8': 18,
  '298F9': 18,
  '298FA': 18,
  '298FB': 18,
  '298FC': 18,
  '298FD': 18,
  '298FE': 18,
  '298FF': 18,
  '29900': 18,
  '29901': 18,
  '29902': 18,
  '29903': 18,
  '29904': 18,
  '29905': 18,
  '29906': 18,
  '29907': 18,
  '29908': 18,
  '29909': 18,
  '2990A': 18,
  '2990B': 18,
  '2990C': 18,
  '2990D': 18,
  '2990E': 18,
  '2990F': 18,
  '29910': 18,
  '29911': 18,
  '29912': 18,
  '29913': 18,
  '29914': 18,
  '29915': 18,
  '29916': 18,
  '29917': 19,
  '29918': 19,
  '29919': 19,
  '2991A': 19,
  '2991B': 19,
  '2991C': 19,
  '2991D': 19,
  '2991E': 18,
  '2991F': 19,
  '29920': 19,
  '29921': 19,
  '29922': 19,
  '29923': 19,
  '29924': 19,
  '29925': 19,
  '29926': 19,
  '29927': 19,
  '29928': 19,
  '29929': 19,
  '2992A': 19,
  '2992B': 19,
  '2992C': 19,
  '2992D': 19,
  '2992E': 19,
  '2992F': 19,
  '29930': 19,
  '29931': 19,
  '29932': 19,
  '29933': 19,
  '29934': 19,
  '29935': 19,
  '29936': 19,
  '29937': 17,
  '29938': 19,
  '29939': 19,
  '2993A': 19,
  '2993B': 19,
  '2993C': 19,
  '2993D': 20,
  '2993E': 20,
  '2993F': 20,
  '29940': 20,
  '29941': 20,
  '29942': 20,
  '29943': 20,
  '29944': 20,
  '29945': 20,
  '29946': 20,
  '29947': 20,
  '29948': 20,
  '29949': 20,
  '2994A': 20,
  '2994B': 20,
  '2994C': 20,
  '2994D': 20,
  '2994E': 20,
  '2994F': 20,
  '29950': 20,
  '29951': 20,
  '29952': 20,
  '29953': 20,
  '29954': 20,
  '29955': 20,
  '29956': 20,
  '29957': 20,
  '29958': 20,
  '29959': 20,
  '2995A': 20,
  '2995B': 20,
  '2995C': 20,
  '2995D': 20,
  '2995E': 20,
  '2995F': 20,
  '29960': 20,
  '29961': 20,
  '29962': 20,
  '29963': 20,
  '29964': 20,
  '29965': 20,
  '29966': 20,
  '29967': 20,
  '29968': 20,
  '29969': 20,
  '2996A': 20,
  '2996B': 21,
  '2996C': 21,
  '2996D': 21,
  '2996E': 21,
  '2996F': 21,
  '29970': 21,
  '29971': 21,
  '29972': 21,
  '29973': 21,
  '29974': 21,
  '29975': 21,
  '29976': 21,
  '29977': 21,
  '29978': 21,
  '29979': 21,
  '2997A': 21,
  '2997B': 21,
  '2997C': 21,
  '2997D': 21,
  '2997E': 21,
  '2997F': 21,
  '29980': 21,
  '29981': 21,
  '29982': 22,
  '29983': 22,
  '29984': 22,
  '29985': 22,
  '29986': 22,
  '29987': 22,
  '29988': 22,
  '29989': 22,
  '2998A': 22,
  '2998B': 22,
  '2998C': 22,
  '2998D': 22,
  '2998E': 22,
  '2998F': 22,
  '29990': 22,
  '29991': 22,
  '29992': 22,
  '29993': 22,
  '29994': 22,
  '29995': 22,
  '29996': 22,
  '29997': 22,
  '29998': 22,
  '29999': 22,
  '2999A': 22,
  '2999B': 22,
  '2999C': 22,
  '2999D': 22,
  '2999E': 23,
  '2999F': 23,
  '299A0': 23,
  '299A1': 23,
  '299A2': 23,
  '299A3': 23,
  '299A4': 23,
  '299A5': 23,
  '299A6': 23,
  '299A7': 23,
  '299A8': 23,
  '299A9': 23,
  '299AA': 23,
  '299AB': 23,
  '299AC': 23,
  '299AD': 23,
  '299AE': 23,
  '299AF': 23,
  '299B0': 23,
  '299B1': 23,
  '299B2': 23,
  '299B3': 23,
  '299B4': 23,
  '299B5': 23,
  '299B6': 24,
  '299B7': 24,
  '299B8': 24,
  '299B9': 24,
  '299BA': 24,
  '299BB': 24,
  '299BC': 24,
  '299BD': 24,
  '299BE': 24,
  '299BF': 24,
  '299C0': 24,
  '299C1': 24,
  '299C2': 25,
  '299C3': 25,
  '299C4': 25,
  '299C5': 25,
  '299C6': 25,
  '299C7': 25,
  '299C8': 25,
  '299C9': 25,
  '299CA': 25,
  '299CB': 25,
  '299CC': 25,
  '299CD': 25,
  '299CE': 25,
  '299CF': 26,
  '299D0': 26,
  '299D1': 26,
  '299D2': 26,
  '299D3': 26,
  '299D4': 27,
  '299D5': 27,
  '299D6': 27,
  '299D7': 27,
  '299D8': 28,
  '299D9': 28,
  '299DA': 28,
  '299DB': 28,
  '299DC': 28,
  '299DD': 28,
  '299DE': 28,
  '299DF': 29,
  '299E0': 29,
  '299E1': 30,
  '299E2': 30,
  '299E3': 31,
  '299E4': 31,
  '299E5': 36,
  '299E6': 7,
  '299E7': 8,
  '299E8': 8,
  '299E9': 8,
  '299EA': 8,
  '299EB': 8,
  '299EC': 8,
  '299ED': 8,
  '299EE': 9,
  '299EF': 8,
  '299F0': 9,
  '299F1': 9,
  '299F2': 9,
  '299F3': 9,
  '299F4': 9,
  '299F5': 9,
  '299F6': 9,
  '299F7': 10,
  '299F8': 10,
  '299F9': 10,
  '299FA': 10,
  '299FB': 11,
  '299FC': 11,
  '299FD': 11,
  '299FE': 12,
  '299FF': 12,
  '29A00': 12,
  '29A01': 12,
  '29A02': 12,
  '29A03': 12,
  '29A04': 13,
  '29A05': 12,
  '29A06': 12,
  '29A07': 12,
  '29A08': 12,
  '29A09': 12,
  '29A0A': 12,
  '29A0B': 13,
  '29A0C': 13,
  '29A0D': 13,
  '29A0E': 15,
  '29A0F': 15,
  '29A10': 18,
  '29A11': 11,
  '29A12': 11,
  '29A13': 11,
  '29A14': 12,
  '29A15': 12,
  '29A16': 12,
  '29A17': 12,
  '29A18': 12,
  '29A19': 12,
  '29A1A': 12,
  '29A1B': 12,
  '29A1C': 13,
  '29A1D': 13,
  '29A1E': 13,
  '29A1F': 13,
  '29A20': 13,
  '29A21': 13,
  '29A22': 13,
  '29A23': 13,
  '29A24': 13,
  '29A25': 13,
  '29A26': 13,
  '29A27': 13,
  '29A28': 13,
  '29A29': 13,
  '29A2A': 13,
  '29A2B': 13,
  '29A2C': 14,
  '29A2D': 14,
  '29A2E': 14,
  '29A2F': 14,
  '29A30': 14,
  '29A31': 14,
  '29A32': 14,
  '29A33': 14,
  '29A34': 14,
  '29A35': 14,
  '29A36': 14,
  '29A37': 14,
  '29A38': 14,
  '29A39': 14,
  '29A3A': 14,
  '29A3B': 14,
  '29A3C': 14,
  '29A3D': 15,
  '29A3E': 15,
  '29A3F': 15,
  '29A40': 15,
  '29A41': 15,
  '29A42': 15,
  '29A43': 15,
  '29A44': 15,
  '29A45': 15,
  '29A46': 15,
  '29A47': 15,
  '29A48': 15,
  '29A49': 16,
  '29A4A': 16,
  '29A4B': 16,
  '29A4C': 16,
  '29A4D': 16,
  '29A4E': 16,
  '29A4F': 16,
  '29A50': 16,
  '29A51': 16,
  '29A52': 16,
  '29A53': 16,
  '29A54': 16,
  '29A55': 16,
  '29A56': 16,
  '29A57': 17,
  '29A58': 17,
  '29A59': 17,
  '29A5A': 17,
  '29A5B': 17,
  '29A5C': 17,
  '29A5D': 17,
  '29A5E': 17,
  '29A5F': 17,
  '29A60': 17,
  '29A61': 17,
  '29A62': 17,
  '29A63': 17,
  '29A64': 17,
  '29A65': 17,
  '29A66': 17,
  '29A67': 17,
  '29A68': 17,
  '29A69': 17,
  '29A6A': 17,
  '29A6B': 17,
  '29A6C': 17,
  '29A6D': 18,
  '29A6E': 18,
  '29A6F': 18,
  '29A70': 18,
  '29A71': 18,
  '29A72': 18,
  '29A73': 18,
  '29A74': 18,
  '29A75': 18,
  '29A76': 18,
  '29A77': 18,
  '29A78': 18,
  '29A79': 18,
  '29A7A': 18,
  '29A7B': 18,
  '29A7C': 18,
  '29A7D': 18,
  '29A7E': 18,
  '29A7F': 18,
  '29A80': 19,
  '29A81': 19,
  '29A82': 19,
  '29A83': 19,
  '29A84': 19,
  '29A85': 19,
  '29A86': 19,
  '29A87': 19,
  '29A88': 19,
  '29A89': 20,
  '29A8A': 20,
  '29A8B': 20,
  '29A8C': 20,
  '29A8D': 20,
  '29A8E': 20,
  '29A8F': 20,
  '29A90': 20,
  '29A91': 20,
  '29A92': 20,
  '29A93': 20,
  '29A94': 20,
  '29A95': 20,
  '29A96': 20,
  '29A97': 21,
  '29A98': 21,
  '29A99': 21,
  '29A9A': 21,
  '29A9B': 21,
  '29A9C': 21,
  '29A9D': 21,
  '29A9E': 21,
  '29A9F': 21,
  '29AA0': 22,
  '29AA1': 22,
  '29AA2': 22,
  '29AA3': 22,
  '29AA4': 22,
  '29AA5': 22,
  '29AA6': 22,
  '29AA7': 22,
  '29AA8': 22,
  '29AA9': 22,
  '29AAA': 22,
  '29AAB': 22,
  '29AAC': 23,
  '29AAD': 23,
  '29AAE': 23,
  '29AAF': 23,
  '29AB0': 23,
  '29AB1': 23,
  '29AB2': 24,
  '29AB3': 24,
  '29AB4': 24,
  '29AB5': 24,
  '29AB6': 24,
  '29AB7': 25,
  '29AB8': 25,
  '29AB9': 25,
  '29ABA': 25,
  '29ABB': 26,
  '29ABC': 27,
  '29ABD': 27,
  '29ABE': 28,
  '29ABF': 13,
  '29AC0': 13,
  '29AC1': 14,
  '29AC2': 14,
  '29AC3': 14,
  '29AC4': 14,
  '29AC5': 14,
  '29AC6': 14,
  '29AC7': 14,
  '29AC8': 14,
  '29AC9': 14,
  '29ACA': 15,
  '29ACB': 15,
  '29ACC': 15,
  '29ACD': 15,
  '29ACE': 16,
  '29ACF': 16,
  '29AD0': 16,
  '29AD1': 16,
  '29AD2': 16,
  '29AD3': 16,
  '29AD4': 16,
  '29AD5': 17,
  '29AD6': 17,
  '29AD7': 17,
  '29AD8': 17,
  '29AD9': 17,
  '29ADA': 18,
  '29ADB': 18,
  '29ADC': 18,
  '29ADD': 18,
  '29ADE': 19,
  '29ADF': 19,
  '29AE0': 20,
  '29AE1': 20,
  '29AE2': 20,
  '29AE3': 20,
  '29AE4': 20,
  '29AE5': 21,
  '29AE6': 21,
  '29AE7': 22,
  '29AE8': 23,
  '29AE9': 23,
  '29AEA': 23,
  '29AEB': 24,
  '29AEC': 24,
  '29AED': 25,
  '29AEE': 26,
  '29AEF': 26,
  '29AF0': 27,
  '29AF1': 27,
  '29AF2': 28,
  '29AF3': 31,
  '29AF4': 12,
  '29AF5': 12,
  '29AF6': 12,
  '29AF7': 12,
  '29AF8': 12,
  '29AF9': 13,
  '29AFA': 13,
  '29AFB': 13,
  '29AFC': 13,
  '29AFD': 13,
  '29AFE': 13,
  '29AFF': 14,
  '29B00': 14,
  '29B01': 14,
  '29B02': 14,
  '29B03': 14,
  '29B04': 14,
  '29B05': 14,
  '29B06': 14,
  '29B07': 14,
  '29B08': 14,
  '29B09': 14,
  '29B0A': 14,
  '29B0B': 14,
  '29B0C': 14,
  '29B0D': 14,
  '29B0E': 14,
  '29B0F': 14,
  '29B10': 14,
  '29B11': 15,
  '29B12': 15,
  '29B13': 15,
  '29B14': 15,
  '29B15': 15,
  '29B16': 15,
  '29B17': 15,
  '29B18': 15,
  '29B19': 15,
  '29B1A': 15,
  '29B1B': 15,
  '29B1C': 15,
  '29B1D': 15,
  '29B1E': 15,
  '29B1F': 15,
  '29B20': 15,
  '29B21': 15,
  '29B22': 15,
  '29B23': 15,
  '29B24': 15,
  '29B25': 15,
  '29B26': 15,
  '29B27': 15,
  '29B28': 15,
  '29B29': 15,
  '29B2A': 15,
  '29B2B': 15,
  '29B2C': 15,
  '29B2D': 15,
  '29B2E': 15,
  '29B2F': 15,
  '29B30': 16,
  '29B31': 16,
  '29B32': 16,
  '29B33': 16,
  '29B34': 16,
  '29B35': 16,
  '29B36': 16,
  '29B37': 16,
  '29B38': 16,
  '29B39': 16,
  '29B3A': 16,
  '29B3B': 16,
  '29B3C': 16,
  '29B3D': 16,
  '29B3E': 16,
  '29B3F': 16,
  '29B40': 16,
  '29B41': 16,
  '29B42': 16,
  '29B43': 16,
  '29B44': 16,
  '29B45': 16,
  '29B46': 16,
  '29B47': 17,
  '29B48': 17,
  '29B49': 17,
  '29B4A': 17,
  '29B4B': 17,
  '29B4C': 17,
  '29B4D': 17,
  '29B4E': 17,
  '29B4F': 17,
  '29B50': 17,
  '29B51': 17,
  '29B52': 17,
  '29B53': 17,
  '29B54': 17,
  '29B55': 17,
  '29B56': 17,
  '29B57': 17,
  '29B58': 17,
  '29B59': 17,
  '29B5A': 17,
  '29B5B': 17,
  '29B5C': 17,
  '29B5D': 17,
  '29B5E': 18,
  '29B5F': 18,
  '29B60': 18,
  '29B61': 18,
  '29B62': 18,
  '29B63': 18,
  '29B64': 18,
  '29B65': 18,
  '29B66': 18,
  '29B67': 18,
  '29B68': 18,
  '29B69': 18,
  '29B6A': 18,
  '29B6B': 18,
  '29B6C': 18,
  '29B6D': 18,
  '29B6E': 18,
  '29B6F': 18,
  '29B70': 18,
  '29B71': 18,
  '29B72': 18,
  '29B73': 18,
  '29B74': 18,
  '29B75': 18,
  '29B76': 18,
  '29B77': 18,
  '29B78': 18,
  '29B79': 18,
  '29B7A': 19,
  '29B7B': 19,
  '29B7C': 19,
  '29B7D': 19,
  '29B7E': 19,
  '29B7F': 19,
  '29B80': 19,
  '29B81': 19,
  '29B82': 19,
  '29B83': 19,
  '29B84': 19,
  '29B85': 19,
  '29B86': 19,
  '29B87': 19,
  '29B88': 19,
  '29B89': 19,
  '29B8A': 19,
  '29B8B': 19,
  '29B8C': 19,
  '29B8D': 19,
  '29B8E': 19,
  '29B8F': 19,
  '29B90': 19,
  '29B91': 19,
  '29B92': 19,
  '29B93': 19,
  '29B94': 19,
  '29B95': 19,
  '29B96': 20,
  '29B97': 20,
  '29B98': 20,
  '29B99': 20,
  '29B9A': 20,
  '29B9B': 20,
  '29B9C': 20,
  '29B9D': 20,
  '29B9E': 20,
  '29B9F': 20,
  '29BA0': 20,
  '29BA1': 20,
  '29BA2': 20,
  '29BA3': 20,
  '29BA4': 20,
  '29BA5': 20,
  '29BA6': 20,
  '29BA7': 20,
  '29BA8': 20,
  '29BA9': 20,
  '29BAA': 20,
  '29BAB': 20,
  '29BAC': 20,
  '29BAD': 20,
  '29BAE': 20,
  '29BAF': 21,
  '29BB0': 21,
  '29BB1': 21,
  '29BB2': 21,
  '29BB3': 21,
  '29BB4': 21,
  '29BB5': 21,
  '29BB6': 21,
  '29BB7': 21,
  '29BB8': 21,
  '29BB9': 21,
  '29BBA': 21,
  '29BBB': 21,
  '29BBC': 21,
  '29BBD': 21,
  '29BBE': 21,
  '29BBF': 21,
  '29BC0': 21,
  '29BC1': 21,
  '29BC2': 21,
  '29BC3': 22,
  '29BC4': 22,
  '29BC5': 22,
  '29BC6': 22,
  '29BC7': 22,
  '29BC8': 22,
  '29BC9': 22,
  '29BCA': 22,
  '29BCB': 22,
  '29BCC': 22,
  '29BCD': 22,
  '29BCE': 22,
  '29BCF': 22,
  '29BD0': 22,
  '29BD1': 22,
  '29BD2': 22,
  '29BD3': 22,
  '29BD4': 22,
  '29BD5': 22,
  '29BD6': 22,
  '29BD7': 22,
  '29BD8': 22,
  '29BD9': 22,
  '29BDA': 22,
  '29BDB': 23,
  '29BDC': 23,
  '29BDD': 23,
  '29BDE': 23,
  '29BDF': 23,
  '29BE0': 23,
  '29BE1': 23,
  '29BE2': 23,
  '29BE3': 23,
  '29BE4': 23,
  '29BE5': 23,
  '29BE6': 24,
  '29BE7': 24,
  '29BE8': 24,
  '29BE9': 24,
  '29BEA': 24,
  '29BEB': 24,
  '29BEC': 24,
  '29BED': 24,
  '29BEE': 24,
  '29BEF': 24,
  '29BF0': 25,
  '29BF1': 25,
  '29BF2': 25,
  '29BF3': 25,
  '29BF4': 25,
  '29BF5': 25,
  '29BF6': 25,
  '29BF7': 25,
  '29BF8': 25,
  '29BF9': 25,
  '29BFA': 26,
  '29BFB': 26,
  '29BFC': 26,
  '29BFD': 26,
  '29BFE': 26,
  '29BFF': 26,
  '29C00': 26,
  '29C01': 27,
  '29C02': 27,
  '29C03': 27,
  '29C04': 27,
  '29C05': 28,
  '29C06': 28,
  '29C07': 28,
  '29C08': 31,
  '29C09': 32,
  '29C0A': 10,
  '29C0B': 10,
  '29C0C': 13,
  '29C0D': 14,
  '29C0E': 14,
  '29C0F': 14,
  '29C10': 15,
  '29C11': 16,
  '29C12': 17,
  '29C13': 18,
  '29C14': 18,
  '29C15': 20,
  '29C16': 21,
  '29C17': 21,
  '29C18': 21,
  '29C19': 22,
  '29C1A': 22,
  '29C1B': 23,
  '29C1C': 24,
  '29C1D': 24,
  '29C1E': 24,
  '29C1F': 28,
  '29C20': 15,
  '29C21': 15,
  '29C22': 16,
  '29C23': 18,
  '29C24': 20,
  '29C25': 21,
  '29C26': 21,
  '29C27': 22,
  '29C28': 23,
  '29C29': 27,
  '29C2A': 28,
  '29C2B': 13,
  '29C2C': 13,
  '29C2D': 14,
  '29C2E': 14,
  '29C2F': 15,
  '29C30': 15,
  '29C31': 16,
  '29C32': 16,
  '29C33': 16,
  '29C34': 16,
  '29C35': 16,
  '29C36': 16,
  '29C37': 16,
  '29C38': 16,
  '29C39': 17,
  '29C3A': 17,
  '29C3B': 17,
  '29C3C': 17,
  '29C3D': 17,
  '29C3E': 18,
  '29C3F': 18,
  '29C40': 18,
  '29C41': 18,
  '29C42': 18,
  '29C43': 19,
  '29C44': 19,
  '29C45': 19,
  '29C46': 19,
  '29C47': 20,
  '29C48': 20,
  '29C49': 20,
  '29C4A': 20,
  '29C4B': 20,
  '29C4C': 21,
  '29C4D': 21,
  '29C4E': 21,
  '29C4F': 21,
  '29C50': 21,
  '29C51': 21,
  '29C52': 22,
  '29C53': 22,
  '29C54': 22,
  '29C55': 22,
  '29C56': 23,
  '29C57': 23,
  '29C58': 23,
  '29C59': 23,
  '29C5A': 23,
  '29C5B': 23,
  '29C5C': 23,
  '29C5D': 23,
  '29C5E': 23,
  '29C5F': 23,
  '29C60': 24,
  '29C61': 24,
  '29C62': 24,
  '29C63': 24,
  '29C64': 25,
  '29C65': 25,
  '29C66': 26,
  '29C67': 26,
  '29C68': 26,
  '29C69': 26,
  '29C6A': 26,
  '29C6B': 27,
  '29C6C': 27,
  '29C6D': 28,
  '29C6E': 28,
  '29C6F': 29,
  '29C70': 29,
  '29C71': 30,
  '29C72': 30,
  '29C73': 30,
  '29C74': 31,
  '29C75': 31,
  '29C76': 32,
  '29C77': 37,
  '29C78': 37,
  '29C79': 11,
  '29C7A': 11,
  '29C7B': 11,
  '29C7C': 11,
  '29C7D': 12,
  '29C7E': 12,
  '29C7F': 12,
  '29C80': 12,
  '29C81': 12,
  '29C82': 12,
  '29C83': 12,
  '29C84': 12,
  '29C85': 12,
  '29C86': 12,
  '29C87': 12,
  '29C88': 12,
  '29C89': 12,
  '29C8A': 13,
  '29C8B': 13,
  '29C8C': 13,
  '29C8D': 13,
  '29C8E': 13,
  '29C8F': 13,
  '29C90': 13,
  '29C91': 13,
  '29C92': 13,
  '29C93': 13,
  '29C94': 13,
  '29C95': 13,
  '29C96': 13,
  '29C97': 13,
  '29C98': 13,
  '29C99': 13,
  '29C9A': 13,
  '29C9B': 13,
  '29C9C': 13,
  '29C9D': 13,
  '29C9E': 13,
  '29C9F': 13,
  '29CA0': 13,
  '29CA1': 14,
  '29CA2': 14,
  '29CA3': 14,
  '29CA4': 14,
  '29CA5': 14,
  '29CA6': 14,
  '29CA7': 14,
  '29CA8': 14,
  '29CA9': 14,
  '29CAA': 14,
  '29CAB': 14,
  '29CAC': 14,
  '29CAD': 14,
  '29CAE': 14,
  '29CAF': 14,
  '29CB0': 14,
  '29CB1': 14,
  '29CB2': 14,
  '29CB3': 14,
  '29CB4': 14,
  '29CB5': 14,
  '29CB6': 14,
  '29CB7': 14,
  '29CB8': 14,
  '29CB9': 14,
  '29CBA': 15,
  '29CBB': 15,
  '29CBC': 15,
  '29CBD': 15,
  '29CBE': 15,
  '29CBF': 15,
  '29CC0': 15,
  '29CC1': 15,
  '29CC2': 15,
  '29CC3': 15,
  '29CC4': 15,
  '29CC5': 15,
  '29CC6': 15,
  '29CC7': 15,
  '29CC8': 15,
  '29CC9': 15,
  '29CCA': 15,
  '29CCB': 15,
  '29CCC': 16,
  '29CCD': 16,
  '29CCE': 16,
  '29CCF': 16,
  '29CD0': 16,
  '29CD1': 16,
  '29CD2': 16,
  '29CD3': 16,
  '29CD4': 16,
  '29CD5': 16,
  '29CD6': 16,
  '29CD7': 16,
  '29CD8': 16,
  '29CD9': 16,
  '29CDA': 16,
  '29CDB': 16,
  '29CDC': 16,
  '29CDD': 16,
  '29CDE': 16,
  '29CDF': 16,
  '29CE0': 16,
  '29CE1': 16,
  '29CE2': 17,
  '29CE3': 17,
  '29CE4': 17,
  '29CE5': 17,
  '29CE6': 17,
  '29CE7': 17,
  '29CE8': 17,
  '29CE9': 17,
  '29CEA': 17,
  '29CEB': 17,
  '29CEC': 17,
  '29CED': 17,
  '29CEE': 17,
  '29CEF': 17,
  '29CF0': 17,
  '29CF1': 17,
  '29CF2': 17,
  '29CF3': 17,
  '29CF4': 17,
  '29CF5': 18,
  '29CF6': 18,
  '29CF7': 18,
  '29CF8': 18,
  '29CF9': 18,
  '29CFA': 18,
  '29CFB': 18,
  '29CFC': 18,
  '29CFD': 18,
  '29CFE': 18,
  '29CFF': 18,
  '29D00': 18,
  '29D01': 18,
  '29D02': 18,
  '29D03': 18,
  '29D04': 18,
  '29D05': 18,
  '29D06': 19,
  '29D07': 19,
  '29D08': 19,
  '29D09': 19,
  '29D0A': 19,
  '29D0B': 19,
  '29D0C': 19,
  '29D0D': 19,
  '29D0E': 19,
  '29D0F': 19,
  '29D10': 20,
  '29D11': 20,
  '29D12': 20,
  '29D13': 20,
  '29D14': 20,
  '29D15': 20,
  '29D16': 20,
  '29D17': 20,
  '29D18': 20,
  '29D19': 20,
  '29D1A': 20,
  '29D1B': 20,
  '29D1C': 21,
  '29D1D': 21,
  '29D1E': 21,
  '29D1F': 21,
  '29D20': 21,
  '29D21': 21,
  '29D22': 21,
  '29D23': 21,
  '29D24': 21,
  '29D25': 21,
  '29D26': 21,
  '29D27': 21,
  '29D28': 21,
  '29D29': 21,
  '29D2A': 21,
  '29D2B': 21,
  '29D2C': 21,
  '29D2D': 21,
  '29D2E': 22,
  '29D2F': 22,
  '29D30': 22,
  '29D31': 23,
  '29D32': 23,
  '29D33': 23,
  '29D34': 23,
  '29D35': 23,
  '29D36': 23,
  '29D37': 23,
  '29D38': 23,
  '29D39': 23,
  '29D3A': 24,
  '29D3B': 24,
  '29D3C': 24,
  '29D3D': 24,
  '29D3E': 24,
  '29D3F': 25,
  '29D40': 26,
  '29D41': 26,
  '29D42': 26,
  '29D43': 26,
  '29D44': 27,
  '29D45': 27,
  '29D46': 28,
  '29D47': 28,
  '29D48': 29,
  '29D49': 31,
  '29D4A': 32,
  '29D4B': 11,
  '29D4C': 13,
  '29D4D': 13,
  '29D4E': 13,
  '29D4F': 13,
  '29D50': 13,
  '29D51': 13,
  '29D52': 13,
  '29D53': 13,
  '29D54': 14,
  '29D55': 14,
  '29D56': 14,
  '29D57': 14,
  '29D58': 14,
  '29D59': 14,
  '29D5A': 14,
  '29D5B': 14,
  '29D5C': 14,
  '29D5D': 14,
  '29D5E': 14,
  '29D5F': 14,
  '29D60': 15,
  '29D61': 15,
  '29D62': 15,
  '29D63': 15,
  '29D64': 15,
  '29D65': 15,
  '29D66': 15,
  '29D67': 15,
  '29D68': 15,
  '29D69': 15,
  '29D6A': 15,
  '29D6B': 15,
  '29D6C': 15,
  '29D6D': 15,
  '29D6E': 15,
  '29D6F': 15,
  '29D70': 15,
  '29D71': 15,
  '29D72': 15,
  '29D73': 15,
  '29D74': 15,
  '29D75': 15,
  '29D76': 15,
  '29D77': 15,
  '29D78': 15,
  '29D79': 15,
  '29D7A': 15,
  '29D7B': 15,
  '29D7C': 15,
  '29D7D': 15,
  '29D7E': 15,
  '29D7F': 15,
  '29D80': 15,
  '29D81': 16,
  '29D82': 16,
  '29D83': 16,
  '29D84': 16,
  '29D85': 16,
  '29D86': 16,
  '29D87': 16,
  '29D88': 16,
  '29D89': 16,
  '29D8A': 16,
  '29D8B': 16,
  '29D8C': 16,
  '29D8D': 16,
  '29D8E': 16,
  '29D8F': 16,
  '29D90': 16,
  '29D91': 16,
  '29D92': 16,
  '29D93': 16,
  '29D94': 16,
  '29D95': 16,
  '29D96': 16,
  '29D97': 16,
  '29D98': 16,
  '29D99': 16,
  '29D9A': 16,
  '29D9B': 16,
  '29D9C': 16,
  '29D9D': 16,
  '29D9E': 16,
  '29D9F': 16,
  '29DA0': 16,
  '29DA1': 17,
  '29DA2': 17,
  '29DA3': 17,
  '29DA4': 17,
  '29DA5': 17,
  '29DA6': 17,
  '29DA7': 17,
  '29DA8': 17,
  '29DA9': 17,
  '29DAA': 17,
  '29DAB': 17,
  '29DAC': 17,
  '29DAD': 17,
  '29DAE': 17,
  '29DAF': 17,
  '29DB0': 17,
  '29DB1': 17,
  '29DB2': 17,
  '29DB3': 17,
  '29DB4': 17,
  '29DB5': 17,
  '29DB6': 17,
  '29DB7': 14,
  '29DB8': 17,
  '29DB9': 17,
  '29DBA': 17,
  '29DBB': 17,
  '29DBC': 17,
  '29DBD': 17,
  '29DBE': 17,
  '29DBF': 17,
  '29DC0': 17,
  '29DC1': 17,
  '29DC2': 17,
  '29DC3': 17,
  '29DC4': 17,
  '29DC5': 17,
  '29DC6': 17,
  '29DC7': 17,
  '29DC8': 17,
  '29DC9': 17,
  '29DCA': 17,
  '29DCB': 17,
  '29DCC': 17,
  '29DCD': 18,
  '29DCE': 18,
  '29DCF': 18,
  '29DD0': 18,
  '29DD1': 18,
  '29DD2': 18,
  '29DD3': 18,
  '29DD4': 18,
  '29DD5': 18,
  '29DD6': 18,
  '29DD7': 18,
  '29DD8': 18,
  '29DD9': 18,
  '29DDA': 18,
  '29DDB': 18,
  '29DDC': 18,
  '29DDD': 18,
  '29DDE': 18,
  '29DDF': 18,
  '29DE0': 18,
  '29DE1': 18,
  '29DE2': 18,
  '29DE3': 18,
  '29DE4': 18,
  '29DE5': 18,
  '29DE6': 18,
  '29DE7': 18,
  '29DE8': 18,
  '29DE9': 18,
  '29DEA': 18,
  '29DEB': 18,
  '29DEC': 18,
  '29DED': 18,
  '29DEE': 18,
  '29DEF': 18,
  '29DF0': 18,
  '29DF1': 18,
  '29DF2': 18,
  '29DF3': 18,
  '29DF4': 18,
  '29DF5': 18,
  '29DF6': 18,
  '29DF7': 19,
  '29DF8': 19,
  '29DF9': 19,
  '29DFA': 19,
  '29DFB': 19,
  '29DFC': 19,
  '29DFD': 19,
  '29DFE': 19,
  '29DFF': 19,
  '29E00': 19,
  '29E01': 19,
  '29E02': 19,
  '29E03': 19,
  '29E04': 19,
  '29E05': 19,
  '29E06': 19,
  '29E07': 19,
  '29E08': 19,
  '29E09': 19,
  '29E0A': 19,
  '29E0B': 19,
  '29E0C': 19,
  '29E0D': 19,
  '29E0E': 19,
  '29E0F': 19,
  '29E10': 19,
  '29E11': 19,
  '29E12': 19,
  '29E13': 19,
  '29E14': 19,
  '29E15': 19,
  '29E16': 19,
  '29E17': 19,
  '29E18': 19,
  '29E19': 19,
  '29E1A': 19,
  '29E1B': 19,
  '29E1C': 19,
  '29E1D': 19,
  '29E1E': 19,
  '29E1F': 19,
  '29E20': 19,
  '29E21': 19,
  '29E22': 19,
  '29E23': 19,
  '29E24': 19,
  '29E25': 19,
  '29E26': 19,
  '29E27': 19,
  '29E28': 19,
  '29E29': 19,
  '29E2A': 19,
  '29E2B': 19,
  '29E2C': 19,
  '29E2D': 19,
  '29E2E': 19,
  '29E2F': 19,
  '29E30': 19,
  '29E31': 19,
  '29E32': 19,
  '29E33': 19,
  '29E34': 19,
  '29E35': 19,
  '29E36': 19,
  '29E37': 19,
  '29E38': 19,
  '29E39': 19,
  '29E3A': 19,
  '29E3B': 19,
  '29E3C': 19,
  '29E3D': 19,
  '29E3E': 20,
  '29E3F': 20,
  '29E40': 20,
  '29E41': 20,
  '29E42': 20,
  '29E43': 20,
  '29E44': 20,
  '29E45': 20,
  '29E46': 20,
  '29E47': 20,
  '29E48': 20,
  '29E49': 20,
  '29E4A': 20,
  '29E4B': 20,
  '29E4C': 20,
  '29E4D': 20,
  '29E4E': 20,
  '29E4F': 20,
  '29E50': 20,
  '29E51': 20,
  '29E52': 20,
  '29E53': 20,
  '29E54': 20,
  '29E55': 20,
  '29E56': 20,
  '29E57': 20,
  '29E58': 20,
  '29E59': 20,
  '29E5A': 20,
  '29E5B': 20,
  '29E5C': 20,
  '29E5D': 20,
  '29E5E': 20,
  '29E5F': 20,
  '29E60': 20,
  '29E61': 20,
  '29E62': 20,
  '29E63': 20,
  '29E64': 20,
  '29E65': 20,
  '29E66': 20,
  '29E67': 20,
  '29E68': 20,
  '29E69': 20,
  '29E6A': 20,
  '29E6B': 20,
  '29E6C': 20,
  '29E6D': 20,
  '29E6E': 20,
  '29E6F': 20,
  '29E70': 20,
  '29E71': 21,
  '29E72': 21,
  '29E73': 21,
  '29E74': 21,
  '29E75': 21,
  '29E76': 21,
  '29E77': 21,
  '29E78': 21,
  '29E79': 21,
  '29E7A': 21,
  '29E7B': 21,
  '29E7C': 21,
  '29E7D': 21,
  '29E7E': 21,
  '29E7F': 21,
  '29E80': 21,
  '29E81': 21,
  '29E82': 21,
  '29E83': 21,
  '29E84': 21,
  '29E85': 21,
  '29E86': 21,
  '29E87': 21,
  '29E88': 21,
  '29E89': 21,
  '29E8A': 21,
  '29E8B': 21,
  '29E8C': 21,
  '29E8D': 21,
  '29E8E': 21,
  '29E8F': 21,
  '29E90': 21,
  '29E91': 21,
  '29E92': 21,
  '29E93': 21,
  '29E94': 21,
  '29E95': 21,
  '29E96': 21,
  '29E97': 21,
  '29E98': 21,
  '29E99': 21,
  '29E9A': 21,
  '29E9B': 21,
  '29E9C': 21,
  '29E9D': 21,
  '29E9E': 21,
  '29E9F': 21,
  '29EA0': 21,
  '29EA1': 21,
  '29EA2': 21,
  '29EA3': 21,
  '29EA4': 21,
  '29EA5': 21,
  '29EA6': 21,
  '29EA7': 21,
  '29EA8': 21,
  '29EA9': 21,
  '29EAA': 21,
  '29EAB': 21,
  '29EAC': 21,
  '29EAD': 22,
  '29EAE': 22,
  '29EAF': 22,
  '29EB0': 22,
  '29EB1': 22,
  '29EB2': 22,
  '29EB3': 22,
  '29EB4': 22,
  '29EB5': 22,
  '29EB6': 22,
  '29EB7': 22,
  '29EB8': 22,
  '29EB9': 22,
  '29EBA': 22,
  '29EBB': 22,
  '29EBC': 22,
  '29EBD': 22,
  '29EBE': 22,
  '29EBF': 22,
  '29EC0': 22,
  '29EC1': 22,
  '29EC2': 22,
  '29EC3': 22,
  '29EC4': 22,
  '29EC5': 22,
  '29EC6': 22,
  '29EC7': 22,
  '29EC8': 22,
  '29EC9': 22,
  '29ECA': 22,
  '29ECB': 22,
  '29ECC': 22,
  '29ECD': 22,
  '29ECE': 22,
  '29ECF': 22,
  '29ED0': 22,
  '29ED1': 22,
  '29ED2': 22,
  '29ED3': 22,
  '29ED4': 22,
  '29ED5': 22,
  '29ED6': 22,
  '29ED7': 22,
  '29ED8': 23,
  '29ED9': 23,
  '29EDA': 23,
  '29EDB': 23,
  '29EDC': 23,
  '29EDD': 23,
  '29EDE': 23,
  '29EDF': 23,
  '29EE0': 23,
  '29EE1': 23,
  '29EE2': 23,
  '29EE3': 23,
  '29EE4': 23,
  '29EE5': 23,
  '29EE6': 23,
  '29EE7': 23,
  '29EE8': 23,
  '29EE9': 23,
  '29EEA': 23,
  '29EEB': 23,
  '29EEC': 23,
  '29EED': 23,
  '29EEE': 23,
  '29EEF': 23,
  '29EF0': 23,
  '29EF1': 23,
  '29EF2': 23,
  '29EF3': 23,
  '29EF4': 23,
  '29EF5': 23,
  '29EF6': 23,
  '29EF7': 23,
  '29EF8': 23,
  '29EF9': 23,
  '29EFA': 23,
  '29EFB': 23,
  '29EFC': 23,
  '29EFD': 23,
  '29EFE': 23,
  '29EFF': 23,
  '29F00': 23,
  '29F01': 23,
  '29F02': 24,
  '29F03': 24,
  '29F04': 24,
  '29F05': 24,
  '29F06': 24,
  '29F07': 24,
  '29F08': 24,
  '29F09': 24,
  '29F0A': 24,
  '29F0B': 24,
  '29F0C': 24,
  '29F0D': 24,
  '29F0E': 24,
  '29F0F': 24,
  '29F10': 24,
  '29F11': 24,
  '29F12': 24,
  '29F13': 24,
  '29F14': 24,
  '29F15': 24,
  '29F16': 24,
  '29F17': 24,
  '29F18': 24,
  '29F19': 24,
  '29F1A': 24,
  '29F1B': 24,
  '29F1C': 24,
  '29F1D': 24,
  '29F1E': 24,
  '29F1F': 24,
  '29F20': 24,
  '29F21': 24,
  '29F22': 24,
  '29F23': 24,
  '29F24': 24,
  '29F25': 25,
  '29F26': 25,
  '29F27': 25,
  '29F28': 25,
  '29F29': 25,
  '29F2A': 25,
  '29F2B': 25,
  '29F2C': 25,
  '29F2D': 25,
  '29F2E': 25,
  '29F2F': 25,
  '29F30': 25,
  '29F31': 25,
  '29F32': 25,
  '29F33': 25,
  '29F34': 25,
  '29F35': 25,
  '29F36': 25,
  '29F37': 25,
  '29F38': 25,
  '29F39': 25,
  '29F3A': 25,
  '29F3B': 26,
  '29F3C': 26,
  '29F3D': 26,
  '29F3E': 26,
  '29F3F': 26,
  '29F40': 26,
  '29F41': 26,
  '29F42': 26,
  '29F43': 26,
  '29F44': 26,
  '29F45': 26,
  '29F46': 26,
  '29F47': 26,
  '29F48': 26,
  '29F49': 26,
  '29F4A': 26,
  '29F4B': 26,
  '29F4C': 26,
  '29F4D': 27,
  '29F4E': 27,
  '29F4F': 27,
  '29F50': 27,
  '29F51': 27,
  '29F52': 27,
  '29F53': 27,
  '29F54': 27,
  '29F55': 27,
  '29F56': 27,
  '29F57': 27,
  '29F58': 27,
  '29F59': 27,
  '29F5A': 28,
  '29F5B': 28,
  '29F5C': 28,
  '29F5D': 28,
  '29F5E': 28,
  '29F5F': 28,
  '29F60': 28,
  '29F61': 28,
  '29F62': 28,
  '29F63': 28,
  '29F64': 28,
  '29F65': 28,
  '29F66': 28,
  '29F67': 29,
  '29F68': 29,
  '29F69': 29,
  '29F6A': 29,
  '29F6B': 29,
  '29F6C': 29,
  '29F6D': 29,
  '29F6E': 29,
  '29F6F': 29,
  '29F70': 30,
  '29F71': 30,
  '29F72': 30,
  '29F73': 31,
  '29F74': 31,
  '29F75': 32,
  '29F76': 32,
  '29F77': 32,
  '29F78': 32,
  '29F79': 12,
  '29F7A': 12,
  '29F7B': 12,
  '29F7C': 14,
  '29F7D': 14,
  '29F7E': 14,
  '29F7F': 14,
  '29F80': 13,
  '29F81': 15,
  '29F82': 15,
  '29F83': 15,
  '29F84': 15,
  '29F85': 16,
  '29F86': 16,
  '29F87': 16,
  '29F88': 16,
  '29F89': 15,
  '29F8A': 17,
  '29F8B': 17,
  '29F8C': 19,
  '29F8D': 21,
  '29F8E': 23,
  '29F8F': 12,
  '29F90': 12,
  '29F91': 12,
  '29F92': 13,
  '29F93': 13,
  '29F94': 13,
  '29F95': 13,
  '29F96': 13,
  '29F97': 13,
  '29F98': 13,
  '29F99': 13,
  '29F9A': 13,
  '29F9B': 13,
  '29F9C': 13,
  '29F9D': 14,
  '29F9E': 14,
  '29F9F': 14,
  '29FA0': 14,
  '29FA1': 14,
  '29FA2': 14,
  '29FA3': 14,
  '29FA4': 14,
  '29FA5': 14,
  '29FA6': 14,
  '29FA7': 14,
  '29FA8': 14,
  '29FA9': 14,
  '29FAA': 14,
  '29FAB': 14,
  '29FAC': 14,
  '29FAD': 14,
  '29FAE': 14,
  '29FAF': 14,
  '29FB0': 15,
  '29FB1': 15,
  '29FB2': 15,
  '29FB3': 15,
  '29FB4': 15,
  '29FB5': 15,
  '29FB6': 15,
  '29FB7': 15,
  '29FB8': 15,
  '29FB9': 15,
  '29FBA': 15,
  '29FBB': 15,
  '29FBC': 15,
  '29FBD': 15,
  '29FBE': 15,
  '29FBF': 15,
  '29FC0': 15,
  '29FC1': 15,
  '29FC2': 15,
  '29FC3': 15,
  '29FC4': 15,
  '29FC5': 15,
  '29FC6': 15,
  '29FC7': 15,
  '29FC8': 15,
  '29FC9': 15,
  '29FCA': 15,
  '29FCB': 15,
  '29FCC': 15,
  '29FCD': 15,
  '29FCE': 15,
  '29FCF': 15,
  '29FD0': 15,
  '29FD1': 15,
  '29FD2': 15,
  '29FD3': 15,
  '29FD4': 15,
  '29FD5': 15,
  '29FD6': 15,
  '29FD7': 15,
  '29FD8': 15,
  '29FD9': 15,
  '29FDA': 15,
  '29FDB': 15,
  '29FDC': 16,
  '29FDD': 16,
  '29FDE': 16,
  '29FDF': 16,
  '29FE0': 16,
  '29FE1': 16,
  '29FE2': 16,
  '29FE3': 16,
  '29FE4': 16,
  '29FE5': 16,
  '29FE6': 16,
  '29FE7': 16,
  '29FE8': 16,
  '29FE9': 16,
  '29FEA': 16,
  '29FEB': 16,
  '29FEC': 16,
  '29FED': 16,
  '29FEE': 16,
  '29FEF': 16,
  '29FF0': 16,
  '29FF1': 16,
  '29FF2': 16,
  '29FF3': 16,
  '29FF4': 16,
  '29FF5': 16,
  '29FF6': 16,
  '29FF7': 16,
  '29FF8': 16,
  '29FF9': 16,
  '29FFA': 16,
  '29FFB': 16,
  '29FFC': 16,
  '29FFD': 16,
  '29FFE': 16,
  '29FFF': 16,
  '2A000': 16,
  '2A001': 16,
  '2A002': 16,
  '2A003': 16,
  '2A004': 16,
  '2A005': 16,
  '2A006': 16,
  '2A007': 16,
  '2A008': 16,
  '2A009': 16,
  '2A00A': 16,
  '2A00B': 16,
  '2A00C': 16,
  '2A00D': 16,
  '2A00E': 16,
  '2A00F': 16,
  '2A010': 16,
  '2A011': 16,
  '2A012': 17,
  '2A013': 17,
  '2A014': 17,
  '2A015': 17,
  '2A016': 17,
  '2A017': 17,
  '2A018': 17,
  '2A019': 17,
  '2A01A': 17,
  '2A01B': 17,
  '2A01C': 17,
  '2A01D': 17,
  '2A01E': 17,
  '2A01F': 17,
  '2A020': 17,
  '2A021': 17,
  '2A022': 17,
  '2A023': 17,
  '2A024': 17,
  '2A025': 17,
  '2A026': 17,
  '2A027': 17,
  '2A028': 17,
  '2A029': 17,
  '2A02A': 17,
  '2A02B': 17,
  '2A02C': 17,
  '2A02D': 17,
  '2A02E': 17,
  '2A02F': 17,
  '2A030': 17,
  '2A031': 17,
  '2A032': 17,
  '2A033': 17,
  '2A034': 17,
  '2A035': 17,
  '2A036': 17,
  '2A037': 17,
  '2A038': 17,
  '2A039': 17,
  '2A03A': 17,
  '2A03B': 17,
  '2A03C': 17,
  '2A03D': 17,
  '2A03E': 17,
  '2A03F': 17,
  '2A040': 17,
  '2A041': 17,
  '2A042': 17,
  '2A043': 17,
  '2A044': 17,
  '2A045': 17,
  '2A046': 17,
  '2A047': 17,
  '2A048': 17,
  '2A049': 17,
  '2A04A': 18,
  '2A04B': 18,
  '2A04C': 18,
  '2A04D': 18,
  '2A04E': 18,
  '2A04F': 18,
  '2A050': 18,
  '2A051': 18,
  '2A052': 18,
  '2A053': 18,
  '2A054': 18,
  '2A055': 18,
  '2A056': 18,
  '2A057': 18,
  '2A058': 18,
  '2A059': 18,
  '2A05A': 18,
  '2A05B': 18,
  '2A05C': 18,
  '2A05D': 18,
  '2A05E': 18,
  '2A05F': 18,
  '2A060': 18,
  '2A061': 18,
  '2A062': 18,
  '2A063': 18,
  '2A064': 18,
  '2A065': 18,
  '2A066': 18,
  '2A067': 18,
  '2A068': 18,
  '2A069': 18,
  '2A06A': 18,
  '2A06B': 18,
  '2A06C': 18,
  '2A06D': 18,
  '2A06E': 18,
  '2A06F': 18,
  '2A070': 18,
  '2A071': 18,
  '2A072': 18,
  '2A073': 18,
  '2A074': 18,
  '2A075': 18,
  '2A076': 18,
  '2A077': 19,
  '2A078': 19,
  '2A079': 19,
  '2A07A': 19,
  '2A07B': 19,
  '2A07C': 19,
  '2A07D': 19,
  '2A07E': 19,
  '2A07F': 19,
  '2A080': 19,
  '2A081': 19,
  '2A082': 19,
  '2A083': 19,
  '2A084': 19,
  '2A085': 19,
  '2A086': 19,
  '2A087': 19,
  '2A088': 19,
  '2A089': 19,
  '2A08A': 19,
  '2A08B': 19,
  '2A08C': 19,
  '2A08D': 19,
  '2A08E': 19,
  '2A08F': 19,
  '2A090': 19,
  '2A091': 19,
  '2A092': 19,
  '2A093': 19,
  '2A094': 19,
  '2A095': 19,
  '2A096': 19,
  '2A097': 19,
  '2A098': 19,
  '2A099': 19,
  '2A09A': 19,
  '2A09B': 19,
  '2A09C': 19,
  '2A09D': 19,
  '2A09E': 19,
  '2A09F': 19,
  '2A0A0': 19,
  '2A0A1': 19,
  '2A0A2': 19,
  '2A0A3': 19,
  '2A0A4': 19,
  '2A0A5': 19,
  '2A0A6': 19,
  '2A0A7': 19,
  '2A0A8': 19,
  '2A0A9': 19,
  '2A0AA': 19,
  '2A0AB': 19,
  '2A0AC': 19,
  '2A0AD': 19,
  '2A0AE': 19,
  '2A0AF': 19,
  '2A0B0': 19,
  '2A0B1': 19,
  '2A0B2': 19,
  '2A0B3': 19,
  '2A0B4': 19,
  '2A0B5': 19,
  '2A0B6': 20,
  '2A0B7': 20,
  '2A0B8': 20,
  '2A0B9': 20,
  '2A0BA': 20,
  '2A0BB': 20,
  '2A0BC': 20,
  '2A0BD': 20,
  '2A0BE': 20,
  '2A0BF': 20,
  '2A0C0': 20,
  '2A0C1': 20,
  '2A0C2': 20,
  '2A0C3': 20,
  '2A0C4': 20,
  '2A0C5': 20,
  '2A0C6': 20,
  '2A0C7': 20,
  '2A0C8': 20,
  '2A0C9': 20,
  '2A0CA': 20,
  '2A0CB': 20,
  '2A0CC': 20,
  '2A0CD': 20,
  '2A0CE': 20,
  '2A0CF': 20,
  '2A0D0': 20,
  '2A0D1': 20,
  '2A0D2': 20,
  '2A0D3': 20,
  '2A0D4': 20,
  '2A0D5': 20,
  '2A0D6': 20,
  '2A0D7': 20,
  '2A0D8': 20,
  '2A0D9': 20,
  '2A0DA': 20,
  '2A0DB': 20,
  '2A0DC': 20,
  '2A0DD': 20,
  '2A0DE': 20,
  '2A0DF': 20,
  '2A0E0': 20,
  '2A0E1': 20,
  '2A0E2': 20,
  '2A0E3': 20,
  '2A0E4': 20,
  '2A0E5': 20,
  '2A0E6': 20,
  '2A0E7': 20,
  '2A0E8': 20,
  '2A0E9': 20,
  '2A0EA': 20,
  '2A0EB': 20,
  '2A0EC': 20,
  '2A0ED': 20,
  '2A0EE': 20,
  '2A0EF': 20,
  '2A0F0': 20,
  '2A0F1': 20,
  '2A0F2': 20,
  '2A0F3': 20,
  '2A0F4': 20,
  '2A0F5': 20,
  '2A0F6': 20,
  '2A0F7': 20,
  '2A0F8': 20,
  '2A0F9': 20,
  '2A0FA': 21,
  '2A0FB': 21,
  '2A0FC': 21,
  '2A0FD': 21,
  '2A0FE': 21,
  '2A0FF': 21,
  '2A100': 21,
  '2A101': 21,
  '2A102': 21,
  '2A103': 21,
  '2A104': 21,
  '2A105': 21,
  '2A106': 21,
  '2A107': 21,
  '2A108': 21,
  '2A109': 21,
  '2A10A': 21,
  '2A10B': 21,
  '2A10C': 21,
  '2A10D': 21,
  '2A10E': 21,
  '2A10F': 21,
  '2A110': 21,
  '2A111': 21,
  '2A112': 21,
  '2A113': 21,
  '2A114': 21,
  '2A115': 21,
  '2A116': 21,
  '2A117': 21,
  '2A118': 21,
  '2A119': 21,
  '2A11A': 21,
  '2A11B': 21,
  '2A11C': 21,
  '2A11D': 21,
  '2A11E': 21,
  '2A11F': 21,
  '2A120': 21,
  '2A121': 21,
  '2A122': 21,
  '2A123': 21,
  '2A124': 21,
  '2A125': 21,
  '2A126': 21,
  '2A127': 21,
  '2A128': 21,
  '2A129': 21,
  '2A12A': 21,
  '2A12B': 21,
  '2A12C': 21,
  '2A12D': 22,
  '2A12E': 22,
  '2A12F': 22,
  '2A130': 22,
  '2A131': 22,
  '2A132': 22,
  '2A133': 22,
  '2A134': 22,
  '2A135': 22,
  '2A136': 22,
  '2A137': 22,
  '2A138': 22,
  '2A139': 22,
  '2A13A': 22,
  '2A13B': 22,
  '2A13C': 22,
  '2A13D': 22,
  '2A13E': 22,
  '2A13F': 22,
  '2A140': 22,
  '2A141': 22,
  '2A142': 22,
  '2A143': 22,
  '2A144': 22,
  '2A145': 22,
  '2A146': 22,
  '2A147': 22,
  '2A148': 22,
  '2A149': 22,
  '2A14A': 22,
  '2A14B': 22,
  '2A14C': 22,
  '2A14D': 22,
  '2A14E': 22,
  '2A14F': 22,
  '2A150': 22,
  '2A151': 22,
  '2A152': 22,
  '2A153': 22,
  '2A154': 22,
  '2A155': 22,
  '2A156': 22,
  '2A157': 22,
  '2A158': 22,
  '2A159': 22,
  '2A15A': 22,
  '2A15B': 22,
  '2A15C': 22,
  '2A15D': 22,
  '2A15E': 22,
  '2A15F': 22,
  '2A160': 22,
  '2A161': 22,
  '2A162': 22,
  '2A163': 22,
  '2A164': 22,
  '2A165': 22,
  '2A166': 22,
  '2A167': 22,
  '2A168': 22,
  '2A169': 22,
  '2A16A': 22,
  '2A16B': 22,
  '2A16C': 22,
  '2A16D': 22,
  '2A16E': 22,
  '2A16F': 23,
  '2A170': 23,
  '2A171': 23,
  '2A172': 23,
  '2A173': 23,
  '2A174': 23,
  '2A175': 23,
  '2A176': 23,
  '2A177': 23,
  '2A178': 23,
  '2A179': 23,
  '2A17A': 23,
  '2A17B': 23,
  '2A17C': 23,
  '2A17D': 23,
  '2A17E': 23,
  '2A17F': 23,
  '2A180': 23,
  '2A181': 23,
  '2A182': 23,
  '2A183': 23,
  '2A184': 23,
  '2A185': 23,
  '2A186': 23,
  '2A187': 23,
  '2A188': 23,
  '2A189': 23,
  '2A18A': 23,
  '2A18B': 23,
  '2A18C': 23,
  '2A18D': 23,
  '2A18E': 23,
  '2A18F': 23,
  '2A190': 23,
  '2A191': 23,
  '2A192': 23,
  '2A193': 23,
  '2A194': 23,
  '2A195': 23,
  '2A196': 23,
  '2A197': 23,
  '2A198': 23,
  '2A199': 23,
  '2A19A': 23,
  '2A19B': 23,
  '2A19C': 23,
  '2A19D': 23,
  '2A19E': 23,
  '2A19F': 23,
  '2A1A0': 23,
  '2A1A1': 23,
  '2A1A2': 23,
  '2A1A3': 23,
  '2A1A4': 23,
  '2A1A5': 23,
  '2A1A6': 23,
  '2A1A7': 23,
  '2A1A8': 23,
  '2A1A9': 23,
  '2A1AA': 23,
  '2A1AB': 23,
  '2A1AC': 24,
  '2A1AD': 23,
  '2A1AE': 24,
  '2A1AF': 24,
  '2A1B0': 24,
  '2A1B1': 24,
  '2A1B2': 24,
  '2A1B3': 24,
  '2A1B4': 24,
  '2A1B5': 24,
  '2A1B6': 24,
  '2A1B7': 24,
  '2A1B8': 24,
  '2A1B9': 24,
  '2A1BA': 24,
  '2A1BB': 24,
  '2A1BC': 24,
  '2A1BD': 24,
  '2A1BE': 24,
  '2A1BF': 24,
  '2A1C0': 24,
  '2A1C1': 24,
  '2A1C2': 24,
  '2A1C3': 24,
  '2A1C4': 24,
  '2A1C5': 24,
  '2A1C6': 24,
  '2A1C7': 24,
  '2A1C8': 24,
  '2A1C9': 24,
  '2A1CA': 24,
  '2A1CB': 24,
  '2A1CC': 24,
  '2A1CD': 24,
  '2A1CE': 24,
  '2A1CF': 24,
  '2A1D0': 24,
  '2A1D1': 25,
  '2A1D2': 25,
  '2A1D3': 25,
  '2A1D4': 25,
  '2A1D5': 25,
  '2A1D6': 25,
  '2A1D7': 25,
  '2A1D8': 25,
  '2A1D9': 25,
  '2A1DA': 25,
  '2A1DB': 25,
  '2A1DC': 25,
  '2A1DD': 25,
  '2A1DE': 25,
  '2A1DF': 25,
  '2A1E0': 25,
  '2A1E1': 25,
  '2A1E2': 25,
  '2A1E3': 25,
  '2A1E4': 25,
  '2A1E5': 25,
  '2A1E6': 25,
  '2A1E7': 25,
  '2A1E8': 25,
  '2A1E9': 25,
  '2A1EA': 25,
  '2A1EB': 25,
  '2A1EC': 25,
  '2A1ED': 26,
  '2A1EE': 26,
  '2A1EF': 26,
  '2A1F0': 26,
  '2A1F1': 26,
  '2A1F2': 26,
  '2A1F3': 26,
  '2A1F4': 26,
  '2A1F5': 26,
  '2A1F6': 26,
  '2A1F7': 26,
  '2A1F8': 26,
  '2A1F9': 26,
  '2A1FA': 26,
  '2A1FB': 26,
  '2A1FC': 26,
  '2A1FD': 26,
  '2A1FE': 26,
  '2A1FF': 26,
  '2A200': 26,
  '2A201': 26,
  '2A202': 26,
  '2A203': 27,
  '2A204': 27,
  '2A205': 27,
  '2A206': 27,
  '2A207': 27,
  '2A208': 27,
  '2A209': 27,
  '2A20A': 27,
  '2A20B': 27,
  '2A20C': 27,
  '2A20D': 27,
  '2A20E': 27,
  '2A20F': 27,
  '2A210': 27,
  '2A211': 27,
  '2A212': 27,
  '2A213': 27,
  '2A214': 27,
  '2A215': 27,
  '2A216': 27,
  '2A217': 27,
  '2A218': 28,
  '2A219': 28,
  '2A21A': 28,
  '2A21B': 28,
  '2A21C': 28,
  '2A21D': 28,
  '2A21E': 28,
  '2A21F': 28,
  '2A220': 28,
  '2A221': 28,
  '2A222': 28,
  '2A223': 28,
  '2A224': 28,
  '2A225': 29,
  '2A226': 29,
  '2A227': 29,
  '2A228': 29,
  '2A229': 29,
  '2A22A': 29,
  '2A22B': 29,
  '2A22C': 29,
  '2A22D': 29,
  '2A22E': 30,
  '2A22F': 30,
  '2A230': 30,
  '2A231': 30,
  '2A232': 30,
  '2A233': 30,
  '2A234': 31,
  '2A235': 31,
  '2A236': 31,
  '2A237': 31,
  '2A238': 32,
  '2A239': 32,
  '2A23A': 32,
  '2A23B': 33,
  '2A23C': 33,
  '2A23D': 33,
  '2A23E': 35,
  '2A23F': 36,
  '2A240': 36,
  '2A241': 9,
  '2A242': 9,
  '2A243': 9,
  '2A244': 10,
  '2A245': 11,
  '2A246': 11,
  '2A247': 11,
  '2A248': 11,
  '2A249': 11,
  '2A24A': 11,
  '2A24B': 11,
  '2A24C': 12,
  '2A24D': 12,
  '2A24E': 13,
  '2A24F': 14,
  '2A250': 14,
  '2A251': 15,
  '2A252': 15,
  '2A253': 15,
  '2A254': 15,
  '2A255': 20,
  '2A256': 14,
  '2A257': 14,
  '2A258': 15,
  '2A259': 15,
  '2A25A': 15,
  '2A25B': 15,
  '2A25C': 16,
  '2A25D': 16,
  '2A25E': 16,
  '2A25F': 16,
  '2A260': 16,
  '2A261': 16,
  '2A262': 17,
  '2A263': 18,
  '2A264': 18,
  '2A265': 18,
  '2A266': 19,
  '2A267': 19,
  '2A268': 19,
  '2A269': 19,
  '2A26A': 19,
  '2A26B': 19,
  '2A26C': 19,
  '2A26D': 20,
  '2A26E': 20,
  '2A26F': 20,
  '2A270': 20,
  '2A271': 20,
  '2A272': 20,
  '2A273': 20,
  '2A274': 20,
  '2A275': 21,
  '2A276': 21,
  '2A277': 21,
  '2A278': 21,
  '2A279': 21,
  '2A27A': 22,
  '2A27B': 22,
  '2A27C': 22,
  '2A27D': 22,
  '2A27E': 22,
  '2A27F': 23,
  '2A280': 23,
  '2A281': 23,
  '2A282': 23,
  '2A283': 24,
  '2A284': 24,
  '2A285': 24,
  '2A286': 25,
  '2A287': 25,
  '2A288': 26,
  '2A289': 27,
  '2A28A': 28,
  '2A28B': 13,
  '2A28C': 13,
  '2A28D': 14,
  '2A28E': 14,
  '2A28F': 15,
  '2A290': 15,
  '2A291': 15,
  '2A292': 15,
  '2A293': 15,
  '2A294': 15,
  '2A295': 15,
  '2A296': 15,
  '2A297': 15,
  '2A298': 15,
  '2A299': 15,
  '2A29A': 15,
  '2A29B': 16,
  '2A29C': 16,
  '2A29D': 16,
  '2A29E': 16,
  '2A29F': 16,
  '2A2A0': 16,
  '2A2A1': 16,
  '2A2A2': 16,
  '2A2A3': 16,
  '2A2A4': 16,
  '2A2A5': 17,
  '2A2A6': 17,
  '2A2A7': 17,
  '2A2A8': 17,
  '2A2A9': 17,
  '2A2AA': 17,
  '2A2AB': 17,
  '2A2AC': 17,
  '2A2AD': 17,
  '2A2AE': 17,
  '2A2AF': 17,
  '2A2B0': 17,
  '2A2B1': 17,
  '2A2B2': 17,
  '2A2B3': 17,
  '2A2B4': 18,
  '2A2B5': 18,
  '2A2B6': 18,
  '2A2B7': 18,
  '2A2B8': 18,
  '2A2B9': 18,
  '2A2BA': 18,
  '2A2BB': 18,
  '2A2BC': 18,
  '2A2BD': 18,
  '2A2BE': 18,
  '2A2BF': 18,
  '2A2C0': 18,
  '2A2C1': 18,
  '2A2C2': 18,
  '2A2C3': 18,
  '2A2C4': 19,
  '2A2C5': 19,
  '2A2C6': 19,
  '2A2C7': 19,
  '2A2C8': 19,
  '2A2C9': 19,
  '2A2CA': 19,
  '2A2CB': 19,
  '2A2CC': 19,
  '2A2CD': 19,
  '2A2CE': 20,
  '2A2CF': 20,
  '2A2D0': 20,
  '2A2D1': 20,
  '2A2D2': 20,
  '2A2D3': 20,
  '2A2D4': 20,
  '2A2D5': 20,
  '2A2D6': 20,
  '2A2D7': 21,
  '2A2D8': 21,
  '2A2D9': 21,
  '2A2DA': 21,
  '2A2DB': 21,
  '2A2DC': 22,
  '2A2DD': 22,
  '2A2DE': 22,
  '2A2DF': 22,
  '2A2E0': 22,
  '2A2E1': 23,
  '2A2E2': 23,
  '2A2E3': 23,
  '2A2E4': 23,
  '2A2E5': 23,
  '2A2E6': 23,
  '2A2E7': 23,
  '2A2E8': 23,
  '2A2E9': 23,
  '2A2EA': 24,
  '2A2EB': 24,
  '2A2EC': 24,
  '2A2ED': 24,
  '2A2EE': 25,
  '2A2EF': 25,
  '2A2F0': 25,
  '2A2F1': 25,
  '2A2F2': 25,
  '2A2F3': 25,
  '2A2F4': 25,
  '2A2F5': 27,
  '2A2F6': 28,
  '2A2F7': 28,
  '2A2F8': 29,
  '2A2F9': 31,
  '2A2FA': 35,
  '2A2FB': 36,
  '2A2FC': 13,
  '2A2FD': 13,
  '2A2FE': 13,
  '2A2FF': 13,
  '2A300': 13,
  '2A301': 14,
  '2A302': 14,
  '2A303': 14,
  '2A304': 14,
  '2A305': 15,
  '2A306': 15,
  '2A307': 15,
  '2A308': 15,
  '2A309': 15,
  '2A30A': 15,
  '2A30B': 15,
  '2A30C': 15,
  '2A30D': 15,
  '2A30E': 15,
  '2A30F': 15,
  '2A310': 15,
  '2A311': 15,
  '2A312': 15,
  '2A313': 15,
  '2A314': 16,
  '2A315': 16,
  '2A316': 16,
  '2A317': 16,
  '2A318': 16,
  '2A319': 16,
  '2A31A': 16,
  '2A31B': 12,
  '2A31C': 16,
  '2A31D': 16,
  '2A31E': 16,
  '2A31F': 16,
  '2A320': 16,
  '2A321': 16,
  '2A322': 17,
  '2A323': 17,
  '2A324': 17,
  '2A325': 17,
  '2A326': 17,
  '2A327': 17,
  '2A328': 17,
  '2A329': 17,
  '2A32A': 17,
  '2A32B': 17,
  '2A32C': 17,
  '2A32D': 18,
  '2A32E': 18,
  '2A32F': 18,
  '2A330': 18,
  '2A331': 18,
  '2A332': 18,
  '2A333': 18,
  '2A334': 18,
  '2A335': 18,
  '2A336': 18,
  '2A337': 18,
  '2A338': 18,
  '2A339': 18,
  '2A33A': 18,
  '2A33B': 18,
  '2A33C': 19,
  '2A33D': 19,
  '2A33E': 19,
  '2A33F': 19,
  '2A340': 19,
  '2A341': 19,
  '2A342': 19,
  '2A343': 19,
  '2A344': 19,
  '2A345': 19,
  '2A346': 19,
  '2A347': 19,
  '2A348': 19,
  '2A349': 19,
  '2A34A': 19,
  '2A34B': 19,
  '2A34C': 20,
  '2A34D': 20,
  '2A34E': 20,
  '2A34F': 20,
  '2A350': 20,
  '2A351': 20,
  '2A352': 20,
  '2A353': 20,
  '2A354': 20,
  '2A355': 20,
  '2A356': 20,
  '2A357': 20,
  '2A358': 20,
  '2A359': 20,
  '2A35A': 20,
  '2A35B': 21,
  '2A35C': 21,
  '2A35D': 21,
  '2A35E': 21,
  '2A35F': 21,
  '2A360': 21,
  '2A361': 21,
  '2A362': 21,
  '2A363': 22,
  '2A364': 22,
  '2A365': 22,
  '2A366': 22,
  '2A367': 22,
  '2A368': 22,
  '2A369': 22,
  '2A36A': 22,
  '2A36B': 22,
  '2A36C': 22,
  '2A36D': 22,
  '2A36E': 22,
  '2A36F': 22,
  '2A370': 22,
  '2A371': 22,
  '2A372': 23,
  '2A373': 23,
  '2A374': 23,
  '2A375': 23,
  '2A376': 23,
  '2A377': 23,
  '2A378': 24,
  '2A379': 24,
  '2A37A': 24,
  '2A37B': 24,
  '2A37C': 24,
  '2A37D': 24,
  '2A37E': 24,
  '2A37F': 26,
  '2A380': 26,
  '2A381': 27,
  '2A382': 27,
  '2A383': 28,
  '2A384': 28,
  '2A385': 28,
  '2A386': 30,
  '2A387': 31,
  '2A388': 10,
  '2A389': 11,
  '2A38A': 11,
  '2A38B': 12,
  '2A38C': 14,
  '2A38D': 9,
  '2A38E': 15,
  '2A38F': 15,
  '2A390': 18,
  '2A391': 13,
  '2A392': 14,
  '2A393': 14,
  '2A394': 14,
  '2A395': 15,
  '2A396': 15,
  '2A397': 15,
  '2A398': 15,
  '2A399': 15,
  '2A39A': 15,
  '2A39B': 16,
  '2A39C': 16,
  '2A39D': 16,
  '2A39E': 16,
  '2A39F': 16,
  '2A3A0': 17,
  '2A3A1': 17,
  '2A3A2': 17,
  '2A3A3': 18,
  '2A3A4': 18,
  '2A3A5': 19,
  '2A3A6': 19,
  '2A3A7': 19,
  '2A3A8': 20,
  '2A3A9': 20,
  '2A3AA': 21,
  '2A3AB': 21,
  '2A3AC': 22,
  '2A3AD': 23,
  '2A3AE': 23,
  '2A3AF': 23,
  '2A3B0': 24,
  '2A3B1': 25,
  '2A3B2': 31,
  '2A3B3': 13,
  '2A3B4': 14,
  '2A3B5': 15,
  '2A3B6': 15,
  '2A3B7': 15,
  '2A3B8': 15,
  '2A3B9': 15,
  '2A3BA': 16,
  '2A3BB': 17,
  '2A3BC': 17,
  '2A3BD': 17,
  '2A3BE': 17,
  '2A3BF': 17,
  '2A3C0': 17,
  '2A3C1': 17,
  '2A3C2': 18,
  '2A3C3': 18,
  '2A3C4': 18,
  '2A3C5': 18,
  '2A3C6': 19,
  '2A3C7': 19,
  '2A3C8': 19,
  '2A3C9': 19,
  '2A3CA': 19,
  '2A3CB': 19,
  '2A3CC': 19,
  '2A3CD': 19,
  '2A3CE': 19,
  '2A3CF': 19,
  '2A3D0': 19,
  '2A3D1': 19,
  '2A3D2': 19,
  '2A3D3': 20,
  '2A3D4': 20,
  '2A3D5': 20,
  '2A3D6': 20,
  '2A3D7': 20,
  '2A3D8': 20,
  '2A3D9': 21,
  '2A3DA': 21,
  '2A3DB': 21,
  '2A3DC': 21,
  '2A3DD': 21,
  '2A3DE': 21,
  '2A3DF': 22,
  '2A3E0': 22,
  '2A3E1': 22,
  '2A3E2': 22,
  '2A3E3': 22,
  '2A3E4': 23,
  '2A3E5': 23,
  '2A3E6': 23,
  '2A3E7': 24,
  '2A3E8': 24,
  '2A3E9': 24,
  '2A3EA': 26,
  '2A3EB': 27,
  '2A3EC': 31,
  '2A3ED': 14,
  '2A3EE': 15,
  '2A3EF': 15,
  '2A3F0': 16,
  '2A3F1': 16,
  '2A3F2': 16,
  '2A3F3': 16,
  '2A3F4': 16,
  '2A3F5': 16,
  '2A3F6': 17,
  '2A3F7': 17,
  '2A3F8': 17,
  '2A3F9': 17,
  '2A3FA': 17,
  '2A3FB': 17,
  '2A3FC': 17,
  '2A3FD': 17,
  '2A3FE': 17,
  '2A3FF': 18,
  '2A400': 18,
  '2A401': 19,
  '2A402': 20,
  '2A403': 20,
  '2A404': 20,
  '2A405': 20,
  '2A406': 20,
  '2A407': 21,
  '2A408': 21,
  '2A409': 21,
  '2A40A': 21,
  '2A40B': 22,
  '2A40C': 23,
  '2A40D': 23,
  '2A40E': 23,
  '2A40F': 23,
  '2A410': 24,
  '2A411': 26,
  '2A412': 25,
  '2A413': 26,
  '2A414': 28,
  '2A415': 28,
  '2A416': 28,
  '2A417': 12,
  '2A418': 13,
  '2A419': 14,
  '2A41A': 14,
  '2A41B': 14,
  '2A41C': 15,
  '2A41D': 15,
  '2A41E': 15,
  '2A41F': 15,
  '2A420': 15,
  '2A421': 15,
  '2A422': 15,
  '2A423': 15,
  '2A424': 16,
  '2A425': 16,
  '2A426': 16,
  '2A427': 16,
  '2A428': 16,
  '2A429': 16,
  '2A42A': 16,
  '2A42B': 16,
  '2A42C': 16,
  '2A42D': 16,
  '2A42E': 16,
  '2A42F': 16,
  '2A430': 16,
  '2A431': 16,
  '2A432': 17,
  '2A433': 17,
  '2A434': 17,
  '2A435': 17,
  '2A436': 17,
  '2A437': 17,
  '2A438': 17,
  '2A439': 17,
  '2A43A': 17,
  '2A43B': 17,
  '2A43C': 17,
  '2A43D': 17,
  '2A43E': 17,
  '2A43F': 18,
  '2A440': 18,
  '2A441': 18,
  '2A442': 18,
  '2A443': 18,
  '2A444': 18,
  '2A445': 18,
  '2A446': 18,
  '2A447': 18,
  '2A448': 19,
  '2A449': 19,
  '2A44A': 19,
  '2A44B': 19,
  '2A44C': 19,
  '2A44D': 19,
  '2A44E': 19,
  '2A44F': 19,
  '2A450': 19,
  '2A451': 19,
  '2A452': 20,
  '2A453': 20,
  '2A454': 20,
  '2A455': 20,
  '2A456': 20,
  '2A457': 20,
  '2A458': 20,
  '2A459': 20,
  '2A45A': 20,
  '2A45B': 20,
  '2A45C': 20,
  '2A45D': 20,
  '2A45E': 20,
  '2A45F': 20,
  '2A460': 20,
  '2A461': 20,
  '2A462': 20,
  '2A463': 21,
  '2A464': 20,
  '2A465': 21,
  '2A466': 21,
  '2A467': 21,
  '2A468': 21,
  '2A469': 21,
  '2A46A': 21,
  '2A46B': 21,
  '2A46C': 21,
  '2A46D': 21,
  '2A46E': 21,
  '2A46F': 21,
  '2A470': 21,
  '2A471': 21,
  '2A472': 21,
  '2A473': 21,
  '2A474': 21,
  '2A475': 21,
  '2A476': 21,
  '2A477': 21,
  '2A478': 21,
  '2A479': 21,
  '2A47A': 21,
  '2A47B': 21,
  '2A47C': 21,
  '2A47D': 21,
  '2A47E': 22,
  '2A47F': 22,
  '2A480': 22,
  '2A481': 22,
  '2A482': 22,
  '2A483': 22,
  '2A484': 22,
  '2A485': 22,
  '2A486': 22,
  '2A487': 22,
  '2A488': 22,
  '2A489': 22,
  '2A48A': 22,
  '2A48B': 22,
  '2A48C': 22,
  '2A48D': 22,
  '2A48E': 22,
  '2A48F': 23,
  '2A490': 23,
  '2A491': 23,
  '2A492': 23,
  '2A493': 23,
  '2A494': 23,
  '2A495': 23,
  '2A496': 23,
  '2A497': 23,
  '2A498': 24,
  '2A499': 24,
  '2A49A': 24,
  '2A49B': 24,
  '2A49C': 24,
  '2A49D': 24,
  '2A49E': 24,
  '2A49F': 24,
  '2A4A0': 24,
  '2A4A1': 24,
  '2A4A2': 24,
  '2A4A3': 24,
  '2A4A4': 24,
  '2A4A5': 24,
  '2A4A6': 24,
  '2A4A7': 24,
  '2A4A8': 24,
  '2A4A9': 25,
  '2A4AA': 25,
  '2A4AB': 25,
  '2A4AC': 25,
  '2A4AD': 25,
  '2A4AE': 25,
  '2A4AF': 25,
  '2A4B0': 25,
  '2A4B1': 25,
  '2A4B2': 25,
  '2A4B3': 25,
  '2A4B4': 26,
  '2A4B5': 26,
  '2A4B6': 26,
  '2A4B7': 26,
  '2A4B8': 26,
  '2A4B9': 27,
  '2A4BA': 27,
  '2A4BB': 27,
  '2A4BC': 27,
  '2A4BD': 27,
  '2A4BE': 27,
  '2A4BF': 28,
  '2A4C0': 28,
  '2A4C1': 28,
  '2A4C2': 28,
  '2A4C3': 29,
  '2A4C4': 29,
  '2A4C5': 30,
  '2A4C6': 30,
  '2A4C7': 32,
  '2A4C8': 32,
  '2A4C9': 38,
  '2A4CA': 41,
  '2A4CB': 18,
  '2A4CC': 20,
  '2A4CD': 21,
  '2A4CE': 21,
  '2A4CF': 21,
  '2A4D0': 23,
  '2A4D1': 14,
  '2A4D2': 15,
  '2A4D3': 15,
  '2A4D4': 17,
  '2A4D5': 17,
  '2A4D6': 17,
  '2A4D7': 17,
  '2A4D8': 17,
  '2A4D9': 17,
  '2A4DA': 18,
  '2A4DB': 18,
  '2A4DC': 18,
  '2A4DD': 18,
  '2A4DE': 18,
  '2A4DF': 18,
  '2A4E0': 18,
  '2A4E1': 19,
  '2A4E2': 19,
  '2A4E3': 19,
  '2A4E4': 19,
  '2A4E5': 19,
  '2A4E6': 19,
  '2A4E7': 20,
  '2A4E8': 20,
  '2A4E9': 20,
  '2A4EA': 20,
  '2A4EB': 20,
  '2A4EC': 21,
  '2A4ED': 21,
  '2A4EE': 22,
  '2A4EF': 22,
  '2A4F0': 22,
  '2A4F1': 22,
  '2A4F2': 22,
  '2A4F3': 22,
  '2A4F4': 22,
  '2A4F5': 22,
  '2A4F6': 22,
  '2A4F7': 23,
  '2A4F8': 23,
  '2A4F9': 24,
  '2A4FA': 25,
  '2A4FB': 25,
  '2A4FC': 26,
  '2A4FD': 25,
  '2A4FE': 26,
  '2A4FF': 27,
  '2A500': 27,
  '2A501': 28,
  '2A502': 12,
  '2A503': 14,
  '2A504': 15,
  '2A505': 15,
  '2A506': 15,
  '2A507': 16,
  '2A508': 18,
  '2A509': 22,
  '2A50A': 27,
  '2A50B': 16,
  '2A50C': 16,
  '2A50D': 17,
  '2A50E': 17,
  '2A50F': 17,
  '2A510': 18,
  '2A511': 18,
  '2A512': 18,
  '2A513': 18,
  '2A514': 19,
  '2A515': 19,
  '2A516': 19,
  '2A517': 19,
  '2A518': 19,
  '2A519': 19,
  '2A51A': 19,
  '2A51B': 19,
  '2A51C': 20,
  '2A51D': 20,
  '2A51E': 20,
  '2A51F': 20,
  '2A520': 20,
  '2A521': 20,
  '2A522': 21,
  '2A523': 21,
  '2A524': 21,
  '2A525': 21,
  '2A526': 22,
  '2A527': 22,
  '2A528': 22,
  '2A529': 22,
  '2A52A': 22,
  '2A52B': 22,
  '2A52C': 22,
  '2A52D': 22,
  '2A52E': 23,
  '2A52F': 24,
  '2A530': 24,
  '2A531': 24,
  '2A532': 24,
  '2A533': 25,
  '2A534': 25,
  '2A535': 26,
  '2A536': 27,
  '2A537': 29,
  '2A538': 15,
  '2A539': 15,
  '2A53A': 16,
  '2A53B': 16,
  '2A53C': 16,
  '2A53D': 17,
  '2A53E': 17,
  '2A53F': 17,
  '2A540': 17,
  '2A541': 17,
  '2A542': 17,
  '2A543': 17,
  '2A544': 17,
  '2A545': 17,
  '2A546': 17,
  '2A547': 17,
  '2A548': 17,
  '2A549': 18,
  '2A54A': 18,
  '2A54B': 18,
  '2A54C': 18,
  '2A54D': 18,
  '2A54E': 18,
  '2A54F': 18,
  '2A550': 18,
  '2A551': 18,
  '2A552': 19,
  '2A553': 19,
  '2A554': 19,
  '2A555': 19,
  '2A556': 19,
  '2A557': 19,
  '2A558': 19,
  '2A559': 19,
  '2A55A': 20,
  '2A55B': 20,
  '2A55C': 20,
  '2A55D': 20,
  '2A55E': 20,
  '2A55F': 19,
  '2A560': 20,
  '2A561': 20,
  '2A562': 20,
  '2A563': 20,
  '2A564': 20,
  '2A565': 20,
  '2A566': 20,
  '2A567': 20,
  '2A568': 21,
  '2A569': 21,
  '2A56A': 21,
  '2A56B': 22,
  '2A56C': 22,
  '2A56D': 22,
  '2A56E': 22,
  '2A56F': 22,
  '2A570': 22,
  '2A571': 22,
  '2A572': 22,
  '2A573': 22,
  '2A574': 22,
  '2A575': 23,
  '2A576': 23,
  '2A577': 23,
  '2A578': 23,
  '2A579': 23,
  '2A57A': 23,
  '2A57B': 23,
  '2A57C': 23,
  '2A57D': 23,
  '2A57E': 23,
  '2A57F': 24,
  '2A580': 24,
  '2A581': 24,
  '2A582': 24,
  '2A583': 24,
  '2A584': 24,
  '2A585': 25,
  '2A586': 25,
  '2A587': 25,
  '2A588': 25,
  '2A589': 25,
  '2A58A': 26,
  '2A58B': 27,
  '2A58C': 29,
  '2A58D': 29,
  '2A58E': 30,
  '2A58F': 31,
  '2A590': 15,
  '2A591': 16,
  '2A592': 16,
  '2A593': 17,
  '2A594': 17,
  '2A595': 18,
  '2A596': 18,
  '2A597': 18,
  '2A598': 18,
  '2A599': 18,
  '2A59A': 19,
  '2A59B': 19,
  '2A59C': 19,
  '2A59D': 19,
  '2A59E': 19,
  '2A59F': 19,
  '2A5A0': 19,
  '2A5A1': 20,
  '2A5A2': 20,
  '2A5A3': 20,
  '2A5A4': 20,
  '2A5A5': 21,
  '2A5A6': 21,
  '2A5A7': 21,
  '2A5A8': 21,
  '2A5A9': 21,
  '2A5AA': 21,
  '2A5AB': 21,
  '2A5AC': 21,
  '2A5AD': 22,
  '2A5AE': 22,
  '2A5AF': 23,
  '2A5B0': 23,
  '2A5B1': 23,
  '2A5B2': 23,
  '2A5B3': 24,
  '2A5B4': 24,
  '2A5B5': 24,
  '2A5B6': 25,
  '2A5B7': 25,
  '2A5B8': 25,
  '2A5B9': 25,
  '2A5BA': 25,
  '2A5BB': 26,
  '2A5BC': 26,
  '2A5BD': 26,
  '2A5BE': 27,
  '2A5BF': 27,
  '2A5C0': 27,
  '2A5C1': 30,
  '2A5C2': 31,
  '2A5C3': 32,
  '2A5C4': 14,
  '2A5C5': 16,
  '2A5C6': 17,
  '2A5C7': 18,
  '2A5C8': 18,
  '2A5C9': 19,
  '2A5CA': 19,
  '2A5CB': 20,
  '2A5CC': 20,
  '2A5CD': 22,
  '2A5CE': 21,
  '2A5CF': 25,
  '2A5D0': 25,
  '2A5D1': 25,
  '2A5D2': 28,
  '2A5D3': 30,
  '2A5D4': 17,
  '2A5D5': 17,
  '2A5D6': 17,
  '2A5D7': 17,
  '2A5D8': 18,
  '2A5D9': 18,
  '2A5DA': 18,
  '2A5DB': 19,
  '2A5DC': 19,
  '2A5DD': 19,
  '2A5DE': 19,
  '2A5DF': 19,
  '2A5E0': 19,
  '2A5E1': 19,
  '2A5E2': 19,
  '2A5E3': 19,
  '2A5E4': 19,
  '2A5E5': 20,
  '2A5E6': 20,
  '2A5E7': 20,
  '2A5E8': 20,
  '2A5E9': 20,
  '2A5EA': 20,
  '2A5EB': 20,
  '2A5EC': 20,
  '2A5ED': 20,
  '2A5EE': 20,
  '2A5EF': 20,
  '2A5F0': 20,
  '2A5F1': 20,
  '2A5F2': 20,
  '2A5F3': 20,
  '2A5F4': 20,
  '2A5F5': 20,
  '2A5F6': 20,
  '2A5F7': 21,
  '2A5F8': 21,
  '2A5F9': 21,
  '2A5FA': 21,
  '2A5FB': 21,
  '2A5FC': 21,
  '2A5FD': 21,
  '2A5FE': 21,
  '2A5FF': 21,
  '2A600': 21,
  '2A601': 21,
  '2A602': 21,
  '2A603': 21,
  '2A604': 21,
  '2A605': 21,
  '2A606': 21,
  '2A607': 21,
  '2A608': 21,
  '2A609': 21,
  '2A60A': 21,
  '2A60B': 21,
  '2A60C': 21,
  '2A60D': 21,
  '2A60E': 22,
  '2A60F': 22,
  '2A610': 22,
  '2A611': 22,
  '2A612': 22,
  '2A613': 22,
  '2A614': 22,
  '2A615': 22,
  '2A616': 22,
  '2A617': 22,
  '2A618': 22,
  '2A619': 22,
  '2A61A': 22,
  '2A61B': 22,
  '2A61C': 22,
  '2A61D': 22,
  '2A61E': 22,
  '2A61F': 22,
  '2A620': 22,
  '2A621': 22,
  '2A622': 22,
  '2A623': 22,
  '2A624': 22,
  '2A625': 23,
  '2A626': 23,
  '2A627': 23,
  '2A628': 23,
  '2A629': 23,
  '2A62A': 23,
  '2A62B': 23,
  '2A62C': 23,
  '2A62D': 23,
  '2A62E': 23,
  '2A62F': 23,
  '2A630': 23,
  '2A631': 23,
  '2A632': 23,
  '2A633': 23,
  '2A634': 23,
  '2A635': 23,
  '2A636': 23,
  '2A637': 23,
  '2A638': 23,
  '2A639': 24,
  '2A63A': 24,
  '2A63B': 24,
  '2A63C': 24,
  '2A63D': 24,
  '2A63E': 24,
  '2A63F': 24,
  '2A640': 24,
  '2A641': 24,
  '2A642': 24,
  '2A643': 24,
  '2A644': 24,
  '2A645': 24,
  '2A646': 24,
  '2A647': 24,
  '2A648': 24,
  '2A649': 25,
  '2A64A': 25,
  '2A64B': 25,
  '2A64C': 25,
  '2A64D': 25,
  '2A64E': 25,
  '2A64F': 25,
  '2A650': 25,
  '2A651': 25,
  '2A652': 25,
  '2A653': 25,
  '2A654': 25,
  '2A655': 25,
  '2A656': 25,
  '2A657': 25,
  '2A658': 25,
  '2A659': 25,
  '2A65A': 26,
  '2A65B': 26,
  '2A65C': 26,
  '2A65D': 26,
  '2A65E': 26,
  '2A65F': 26,
  '2A660': 26,
  '2A661': 26,
  '2A662': 26,
  '2A663': 27,
  '2A664': 27,
  '2A665': 27,
  '2A666': 27,
  '2A667': 27,
  '2A668': 27,
  '2A669': 27,
  '2A66A': 27,
  '2A66B': 27,
  '2A66C': 27,
  '2A66D': 27,
  '2A66E': 27,
  '2A66F': 27,
  '2A670': 28,
  '2A671': 28,
  '2A672': 28,
  '2A673': 28,
  '2A674': 28,
  '2A675': 28,
  '2A676': 29,
  '2A677': 30,
  '2A678': 30,
  '2A679': 30,
  '2A67A': 30,
  '2A67B': 30,
  '2A67C': 30,
  '2A67D': 31,
  '2A67E': 31,
  '2A67F': 31,
  '2A680': 31,
  '2A681': 32,
  '2A682': 32,
  '2A683': 32,
  '2A684': 32,
  '2A685': 33,
  '2A686': 34,
  '2A687': 34,
  '2A688': 34,
  '2A689': 34,
  '2A68A': 35,
  '2A68B': 35,
  '2A68C': 36,
  '2A68D': 40,
  '2A68E': 40,
  '2A68F': 14,
  '2A690': 16,
  '2A691': 19,
  '2A692': 19,
  '2A693': 19,
  '2A694': 19,
  '2A695': 20,
  '2A696': 20,
  '2A697': 20,
  '2A698': 20,
  '2A699': 21,
  '2A69A': 20,
  '2A69B': 20,
  '2A69C': 22,
  '2A69D': 22,
  '2A69E': 22,
  '2A69F': 22,
  '2A6A0': 23,
  '2A6A1': 23,
  '2A6A2': 25,
  '2A6A3': 27,
  '2A6A4': 27,
  '2A6A5': 64,
  '2A6A6': 16,
  '2A6A7': 21,
  '2A6A8': 18,
  '2A6A9': 19,
  '2A6AA': 19,
  '2A6AB': 20,
  '2A6AC': 20,
  '2A6AD': 20,
  '2A6AE': 20,
  '2A6AF': 20,
  '2A6B0': 20,
  '2A6B1': 20,
  '2A6B2': 20,
  '2A6B3': 20,
  '2A6B4': 20,
  '2A6B5': 20,
  '2A6B6': 21,
  '2A6B7': 21,
  '2A6B8': 21,
  '2A6B9': 21,
  '2A6BA': 21,
  '2A6BB': 21,
  '2A6BC': 21,
  '2A6BD': 21,
  '2A6BE': 23,
  '2A6BF': 23,
  '2A6C0': 24,
  '2A6C1': 25,
  '2A6C2': 27,
  '2A6C3': 27,
  '2A6C4': 27,
  '2A6C5': 28,
  '2A6C6': 28,
  '2A6C7': 30,
  '2A6C8': 33,
  '2A6C9': 16,
  '2A6CA': 21,
  '2A6CB': 25,
  '2A6CC': 25,
  '2A6CD': 25,
  '2A6CE': 26,
  '2A6CF': 26,
  '2A6D0': 26,
  '2A6D1': 26,
  '2A6D2': 27,
  '2A6D3': 28,
  '2A6D4': 31,
  '2A6D5': 33,
  '2A6D6': 37,
  '2A700': 3,
  '2A701': 3,
  '2A702': 4,
  '2A703': 8,
  '2A704': 9,
  '2A705': 10,
  '2A706': 11,
  '2A707': 16,
  '2A708': 4,
  '2A709': 9,
  '2A70A': 2,
  '2A70B': 8,
  '2A70C': 6,
  '2A70D': 9,
  '2A70E': 10,
  '2A70F': 10,
  '2A710': 5,
  '2A711': 6,
  '2A712': 7,
  '2A713': 7,
  '2A714': 8,
  '2A715': 9,
  '2A716': 10,
  '2A717': 10,
  '2A718': 11,
  '2A719': 12,
  '2A71A': 12,
  '2A71B': 13,
  '2A71C': 7,
  '2A71D': 12,
  '2A71E': 14,
  '2A71F': 15,
  '2A720': 5,
  '2A721': 5,
  '2A722': 7,
  '2A723': 11,
  '2A724': 12,
  '2A725': 15,
  '2A726': 19,
  '2A727': 4,
  '2A728': 6,
  '2A729': 6,
  '2A72A': 6,
  '2A72B': 6,
  '2A72C': 7,
  '2A72D': 7,
  '2A72E': 7,
  '2A72F': 7,
  '2A730': 7,
  '2A731': 7,
  '2A732': 7,
  '2A733': 7,
  '2A734': 7,
  '2A735': 8,
  '2A736': 8,
  '2A737': 8,
  '2A738': 8,
  '2A739': 8,
  '2A73A': 8,
  '2A73B': 9,
  '2A73C': 9,
  '2A73D': 9,
  '2A73E': 9,
  '2A73F': 9,
  '2A740': 9,
  '2A741': 9,
  '2A742': 9,
  '2A743': 10,
  '2A744': 10,
  '2A745': 10,
  '2A746': 10,
  '2A747': 10,
  '2A748': 10,
  '2A749': 10,
  '2A74A': 10,
  '2A74B': 11,
  '2A74C': 11,
  '2A74D': 11,
  '2A74E': 11,
  '2A74F': 11,
  '2A750': 11,
  '2A751': 11,
  '2A752': 11,
  '2A753': 11,
  '2A754': 11,
  '2A755': 11,
  '2A756': 12,
  '2A757': 12,
  '2A758': 12,
  '2A759': 12,
  '2A75A': 12,
  '2A75B': 12,
  '2A75C': 12,
  '2A75D': 12,
  '2A75E': 12,
  '2A75F': 12,
  '2A760': 13,
  '2A761': 13,
  '2A762': 13,
  '2A763': 13,
  '2A764': 13,
  '2A765': 14,
  '2A766': 14,
  '2A767': 14,
  '2A768': 14,
  '2A769': 14,
  '2A76A': 14,
  '2A76B': 15,
  '2A76C': 15,
  '2A76D': 15,
  '2A76E': 15,
  '2A76F': 15,
  '2A770': 16,
  '2A771': 16,
  '2A772': 16,
  '2A773': 16,
  '2A774': 17,
  '2A775': 17,
  '2A776': 17,
  '2A777': 18,
  '2A778': 18,
  '2A779': 18,
  '2A77A': 19,
  '2A77B': 19,
  '2A77C': 19,
  '2A77D': 20,
  '2A77E': 21,
  '2A77F': 8,
  '2A780': 9,
  '2A781': 10,
  '2A782': 13,
  '2A783': 15,
  '2A784': 15,
  '2A785': 15,
  '2A786': 19,
  '2A787': 10,
  '2A788': 6,
  '2A789': 12,
  '2A78A': 13,
  '2A78B': 14,
  '2A78C': 15,
  '2A78D': 16,
  '2A78E': 7,
  '2A78F': 7,
  '2A790': 7,
  '2A791': 8,
  '2A792': 10,
  '2A793': 11,
  '2A794': 12,
  '2A795': 14,
  '2A796': 5,
  '2A797': 6,
  '2A798': 6,
  '2A799': 6,
  '2A79A': 6,
  '2A79B': 7,
  '2A79C': 7,
  '2A79D': 7,
  '2A79E': 8,
  '2A79F': 9,
  '2A7A0': 9,
  '2A7A1': 9,
  '2A7A2': 10,
  '2A7A3': 10,
  '2A7A4': 10,
  '2A7A5': 10,
  '2A7A6': 11,
  '2A7A7': 11,
  '2A7A8': 11,
  '2A7A9': 12,
  '2A7AA': 12,
  '2A7AB': 13,
  '2A7AC': 13,
  '2A7AD': 14,
  '2A7AE': 15,
  '2A7AF': 16,
  '2A7B0': 20,
  '2A7B1': 6,
  '2A7B2': 7,
  '2A7B3': 10,
  '2A7B4': 11,
  '2A7B5': 13,
  '2A7B6': 5,
  '2A7B7': 8,
  '2A7B8': 10,
  '2A7B9': 12,
  '2A7BA': 13,
  '2A7BB': 13,
  '2A7BC': 18,
  '2A7BD': 19,
  '2A7BE': 6,
  '2A7BF': 6,
  '2A7C0': 6,
  '2A7C1': 7,
  '2A7C2': 7,
  '2A7C3': 8,
  '2A7C4': 8,
  '2A7C5': 8,
  '2A7C6': 9,
  '2A7C7': 9,
  '2A7C8': 10,
  '2A7C9': 10,
  '2A7CA': 11,
  '2A7CB': 11,
  '2A7CC': 11,
  '2A7CD': 11,
  '2A7CE': 12,
  '2A7CF': 12,
  '2A7D0': 12,
  '2A7D1': 13,
  '2A7D2': 13,
  '2A7D3': 13,
  '2A7D4': 15,
  '2A7D5': 16,
  '2A7D6': 16,
  '2A7D7': 7,
  '2A7D8': 7,
  '2A7D9': 7,
  '2A7DA': 8,
  '2A7DB': 8,
  '2A7DC': 9,
  '2A7DD': 10,
  '2A7DE': 10,
  '2A7DF': 10,
  '2A7E0': 11,
  '2A7E1': 12,
  '2A7E2': 12,
  '2A7E3': 13,
  '2A7E4': 14,
  '2A7E5': 14,
  '2A7E6': 15,
  '2A7E7': 21,
  '2A7E8': 3,
  '2A7E9': 6,
  '2A7EA': 9,
  '2A7EB': 13,
  '2A7EC': 6,
  '2A7ED': 7,
  '2A7EE': 7,
  '2A7EF': 9,
  '2A7F0': 10,
  '2A7F1': 14,
  '2A7F2': 20,
  '2A7F3': 7,
  '2A7F4': 10,
  '2A7F5': 11,
  '2A7F6': 13,
  '2A7F7': 14,
  '2A7F8': 15,
  '2A7F9': 15,
  '2A7FA': 17,
  '2A7FB': 18,
  '2A7FC': 20,
  '2A7FD': 4,
  '2A7FE': 7,
  '2A7FF': 10,
  '2A800': 14,
  '2A801': 8,
  '2A802': 9,
  '2A803': 5,
  '2A804': 7,
  '2A805': 8,
  '2A806': 8,
  '2A807': 9,
  '2A808': 9,
  '2A809': 9,
  '2A80A': 9,
  '2A80B': 10,
  '2A80C': 10,
  '2A80D': 10,
  '2A80E': 10,
  '2A80F': 11,
  '2A810': 11,
  '2A811': 12,
  '2A812': 12,
  '2A813': 13,
  '2A814': 13,
  '2A815': 13,
  '2A816': 14,
  '2A817': 14,
  '2A818': 15,
  '2A819': 15,
  '2A81A': 17,
  '2A81B': 20,
  '2A81C': 9,
  '2A81D': 10,
  '2A81E': 11,
  '2A81F': 12,
  '2A820': 13,
  '2A821': 15,
  '2A822': 20,
  '2A823': 4,
  '2A824': 4,
  '2A825': 6,
  '2A826': 7,
  '2A827': 8,
  '2A828': 8,
  '2A829': 9,
  '2A82A': 9,
  '2A82B': 10,
  '2A82C': 10,
  '2A82D': 12,
  '2A82E': 12,
  '2A82F': 13,
  '2A830': 14,
  '2A831': 16,
  '2A832': 5,
  '2A833': 7,
  '2A834': 7,
  '2A835': 7,
  '2A836': 8,
  '2A837': 8,
  '2A838': 8,
  '2A839': 8,
  '2A83A': 9,
  '2A83B': 9,
  '2A83C': 9,
  '2A83D': 9,
  '2A83E': 9,
  '2A83F': 9,
  '2A840': 9,
  '2A841': 9,
  '2A842': 9,
  '2A843': 9,
  '2A844': 10,
  '2A845': 10,
  '2A846': 10,
  '2A847': 10,
  '2A848': 10,
  '2A849': 10,
  '2A84A': 10,
  '2A84B': 10,
  '2A84C': 10,
  '2A84D': 10,
  '2A84E': 10,
  '2A84F': 16,
  '2A850': 11,
  '2A851': 11,
  '2A852': 11,
  '2A853': 11,
  '2A854': 11,
  '2A855': 11,
  '2A856': 11,
  '2A857': 11,
  '2A858': 11,
  '2A859': 11,
  '2A85A': 11,
  '2A85B': 11,
  '2A85C': 12,
  '2A85D': 12,
  '2A85E': 12,
  '2A85F': 12,
  '2A860': 12,
  '2A861': 12,
  '2A862': 12,
  '2A863': 12,
  '2A864': 12,
  '2A865': 12,
  '2A866': 12,
  '2A867': 12,
  '2A868': 12,
  '2A869': 12,
  '2A86A': 13,
  '2A86B': 13,
  '2A86C': 13,
  '2A86D': 13,
  '2A86E': 13,
  '2A86F': 13,
  '2A870': 13,
  '2A871': 13,
  '2A872': 13,
  '2A873': 13,
  '2A874': 13,
  '2A875': 13,
  '2A876': 13,
  '2A877': 13,
  '2A878': 14,
  '2A879': 14,
  '2A87A': 14,
  '2A87B': 14,
  '2A87C': 14,
  '2A87D': 14,
  '2A87E': 14,
  '2A87F': 14,
  '2A880': 14,
  '2A881': 14,
  '2A882': 14,
  '2A883': 14,
  '2A884': 14,
  '2A885': 15,
  '2A886': 15,
  '2A887': 15,
  '2A888': 15,
  '2A889': 15,
  '2A88A': 15,
  '2A88B': 15,
  '2A88C': 15,
  '2A88D': 15,
  '2A88E': 16,
  '2A88F': 16,
  '2A890': 16,
  '2A891': 16,
  '2A892': 16,
  '2A893': 16,
  '2A894': 16,
  '2A895': 17,
  '2A896': 17,
  '2A897': 17,
  '2A898': 17,
  '2A899': 18,
  '2A89A': 18,
  '2A89B': 18,
  '2A89C': 18,
  '2A89D': 18,
  '2A89E': 18,
  '2A89F': 19,
  '2A8A0': 19,
  '2A8A1': 19,
  '2A8A2': 20,
  '2A8A3': 20,
  '2A8A4': 20,
  '2A8A5': 22,
  '2A8A6': 24,
  '2A8A7': 24,
  '2A8A8': 7,
  '2A8A9': 8,
  '2A8AA': 9,
  '2A8AB': 10,
  '2A8AC': 11,
  '2A8AD': 11,
  '2A8AE': 13,
  '2A8AF': 14,
  '2A8B0': 16,
  '2A8B1': 5,
  '2A8B2': 5,
  '2A8B3': 6,
  '2A8B4': 6,
  '2A8B5': 6,
  '2A8B6': 6,
  '2A8B7': 6,
  '2A8B8': 7,
  '2A8B9': 7,
  '2A8BA': 7,
  '2A8BB': 7,
  '2A8BC': 7,
  '2A8BD': 7,
  '2A8BE': 7,
  '2A8BF': 7,
  '2A8C0': 7,
  '2A8C1': 7,
  '2A8C2': 7,
  '2A8C3': 8,
  '2A8C4': 8,
  '2A8C5': 8,
  '2A8C6': 8,
  '2A8C7': 8,
  '2A8C8': 8,
  '2A8C9': 8,
  '2A8CA': 8,
  '2A8CB': 9,
  '2A8CC': 9,
  '2A8CD': 9,
  '2A8CE': 9,
  '2A8CF': 9,
  '2A8D0': 9,
  '2A8D1': 9,
  '2A8D2': 9,
  '2A8D3': 9,
  '2A8D4': 10,
  '2A8D5': 10,
  '2A8D6': 10,
  '2A8D7': 10,
  '2A8D8': 10,
  '2A8D9': 10,
  '2A8DA': 10,
  '2A8DB': 10,
  '2A8DC': 10,
  '2A8DD': 10,
  '2A8DE': 10,
  '2A8DF': 10,
  '2A8E0': 10,
  '2A8E1': 10,
  '2A8E2': 10,
  '2A8E3': 10,
  '2A8E4': 11,
  '2A8E5': 11,
  '2A8E6': 11,
  '2A8E7': 11,
  '2A8E8': 11,
  '2A8E9': 11,
  '2A8EA': 11,
  '2A8EB': 11,
  '2A8EC': 11,
  '2A8ED': 11,
  '2A8EE': 11,
  '2A8EF': 11,
  '2A8F0': 11,
  '2A8F1': 11,
  '2A8F2': 12,
  '2A8F3': 12,
  '2A8F4': 12,
  '2A8F5': 12,
  '2A8F6': 12,
  '2A8F7': 12,
  '2A8F8': 12,
  '2A8F9': 12,
  '2A8FA': 12,
  '2A8FB': 12,
  '2A8FC': 12,
  '2A8FD': 12,
  '2A8FE': 13,
  '2A8FF': 13,
  '2A900': 13,
  '2A901': 13,
  '2A902': 13,
  '2A903': 13,
  '2A904': 13,
  '2A905': 13,
  '2A906': 13,
  '2A907': 13,
  '2A908': 13,
  '2A909': 13,
  '2A90A': 13,
  '2A90B': 13,
  '2A90C': 13,
  '2A90D': 13,
  '2A90E': 14,
  '2A90F': 14,
  '2A910': 14,
  '2A911': 14,
  '2A912': 14,
  '2A913': 14,
  '2A914': 14,
  '2A915': 14,
  '2A916': 14,
  '2A917': 14,
  '2A918': 14,
  '2A919': 15,
  '2A91A': 15,
  '2A91B': 15,
  '2A91C': 15,
  '2A91D': 15,
  '2A91E': 15,
  '2A91F': 16,
  '2A920': 16,
  '2A921': 16,
  '2A922': 16,
  '2A923': 16,
  '2A924': 16,
  '2A925': 16,
  '2A926': 17,
  '2A927': 17,
  '2A928': 17,
  '2A929': 17,
  '2A92A': 17,
  '2A92B': 17,
  '2A92C': 18,
  '2A92D': 18,
  '2A92E': 18,
  '2A92F': 18,
  '2A930': 19,
  '2A931': 20,
  '2A932': 10,
  '2A933': 13,
  '2A934': 22,
  '2A935': 20,
  '2A936': 13,
  '2A937': 6,
  '2A938': 8,
  '2A939': 9,
  '2A93A': 10,
  '2A93B': 11,
  '2A93C': 12,
  '2A93D': 14,
  '2A93E': 15,
  '2A93F': 16,
  '2A940': 22,
  '2A941': 4,
  '2A942': 5,
  '2A943': 6,
  '2A944': 7,
  '2A945': 7,
  '2A946': 7,
  '2A947': 8,
  '2A948': 8,
  '2A949': 8,
  '2A94A': 8,
  '2A94B': 8,
  '2A94C': 8,
  '2A94D': 9,
  '2A94E': 9,
  '2A94F': 9,
  '2A950': 10,
  '2A951': 10,
  '2A952': 10,
  '2A953': 11,
  '2A954': 11,
  '2A955': 11,
  '2A956': 12,
  '2A957': 12,
  '2A958': 12,
  '2A959': 13,
  '2A95A': 13,
  '2A95B': 13,
  '2A95C': 14,
  '2A95D': 14,
  '2A95E': 14,
  '2A95F': 15,
  '2A960': 15,
  '2A961': 15,
  '2A962': 18,
  '2A963': 20,
  '2A964': 6,
  '2A965': 6,
  '2A966': 7,
  '2A967': 7,
  '2A968': 7,
  '2A969': 7,
  '2A96A': 7,
  '2A96B': 7,
  '2A96C': 7,
  '2A96D': 8,
  '2A96E': 8,
  '2A96F': 8,
  '2A970': 8,
  '2A971': 9,
  '2A972': 9,
  '2A973': 9,
  '2A974': 9,
  '2A975': 9,
  '2A976': 10,
  '2A977': 10,
  '2A978': 10,
  '2A979': 10,
  '2A97A': 10,
  '2A97B': 11,
  '2A97C': 11,
  '2A97D': 11,
  '2A97E': 11,
  '2A97F': 11,
  '2A980': 11,
  '2A981': 11,
  '2A982': 11,
  '2A983': 11,
  '2A984': 11,
  '2A985': 11,
  '2A986': 12,
  '2A987': 12,
  '2A988': 12,
  '2A989': 12,
  '2A98A': 12,
  '2A98B': 12,
  '2A98C': 12,
  '2A98D': 12,
  '2A98E': 12,
  '2A98F': 12,
  '2A990': 12,
  '2A991': 13,
  '2A992': 13,
  '2A993': 13,
  '2A994': 13,
  '2A995': 13,
  '2A996': 13,
  '2A997': 14,
  '2A998': 14,
  '2A999': 14,
  '2A99A': 14,
  '2A99B': 14,
  '2A99C': 14,
  '2A99D': 14,
  '2A99E': 14,
  '2A99F': 14,
  '2A9A0': 15,
  '2A9A1': 15,
  '2A9A2': 15,
  '2A9A3': 15,
  '2A9A4': 15,
  '2A9A5': 15,
  '2A9A6': 16,
  '2A9A7': 16,
  '2A9A8': 16,
  '2A9A9': 16,
  '2A9AA': 16,
  '2A9AB': 17,
  '2A9AC': 18,
  '2A9AD': 18,
  '2A9AE': 18,
  '2A9AF': 18,
  '2A9B0': 19,
  '2A9B1': 19,
  '2A9B2': 20,
  '2A9B3': 21,
  '2A9B4': 21,
  '2A9B5': 23,
  '2A9B6': 7,
  '2A9B7': 7,
  '2A9B8': 7,
  '2A9B9': 7,
  '2A9BA': 8,
  '2A9BB': 9,
  '2A9BC': 11,
  '2A9BD': 11,
  '2A9BE': 11,
  '2A9BF': 12,
  '2A9C0': 14,
  '2A9C1': 16,
  '2A9C2': 17,
  '2A9C3': 17,
  '2A9C4': 21,
  '2A9C5': 7,
  '2A9C6': 7,
  '2A9C7': 7,
  '2A9C8': 8,
  '2A9C9': 8,
  '2A9CA': 9,
  '2A9CB': 9,
  '2A9CC': 9,
  '2A9CD': 10,
  '2A9CE': 10,
  '2A9CF': 10,
  '2A9D0': 10,
  '2A9D1': 11,
  '2A9D2': 11,
  '2A9D3': 11,
  '2A9D4': 12,
  '2A9D5': 12,
  '2A9D6': 12,
  '2A9D7': 12,
  '2A9D8': 12,
  '2A9D9': 12,
  '2A9DA': 12,
  '2A9DB': 13,
  '2A9DC': 13,
  '2A9DD': 13,
  '2A9DE': 13,
  '2A9DF': 13,
  '2A9E0': 13,
  '2A9E1': 14,
  '2A9E2': 14,
  '2A9E3': 14,
  '2A9E4': 14,
  '2A9E5': 14,
  '2A9E6': 15,
  '2A9E7': 15,
  '2A9E8': 15,
  '2A9E9': 15,
  '2A9EA': 15,
  '2A9EB': 16,
  '2A9EC': 16,
  '2A9ED': 16,
  '2A9EE': 16,
  '2A9EF': 17,
  '2A9F0': 18,
  '2A9F1': 18,
  '2A9F2': 18,
  '2A9F3': 20,
  '2A9F4': 20,
  '2A9F5': 21,
  '2A9F6': 24,
  '2A9F7': 6,
  '2A9F8': 11,
  '2A9F9': 11,
  '2A9FA': 12,
  '2A9FB': 15,
  '2A9FC': 15,
  '2A9FD': 16,
  '2A9FE': 17,
  '2A9FF': 7,
  '2AA00': 8,
  '2AA01': 10,
  '2AA02': 10,
  '2AA03': 14,
  '2AA04': 15,
  '2AA05': 16,
  '2AA06': 17,
  '2AA07': 12,
  '2AA08': 13,
  '2AA09': 7,
  '2AA0A': 7,
  '2AA0B': 7,
  '2AA0C': 8,
  '2AA0D': 9,
  '2AA0E': 9,
  '2AA0F': 10,
  '2AA10': 10,
  '2AA11': 11,
  '2AA12': 13,
  '2AA13': 13,
  '2AA14': 13,
  '2AA15': 12,
  '2AA16': 12,
  '2AA17': 12,
  '2AA18': 12,
  '2AA19': 14,
  '2AA1A': 14,
  '2AA1B': 15,
  '2AA1C': 15,
  '2AA1D': 16,
  '2AA1E': 17,
  '2AA1F': 19,
  '2AA20': 19,
  '2AA21': 22,
  '2AA22': 6,
  '2AA23': 6,
  '2AA24': 6,
  '2AA25': 6,
  '2AA26': 7,
  '2AA27': 7,
  '2AA28': 7,
  '2AA29': 8,
  '2AA2A': 8,
  '2AA2B': 8,
  '2AA2C': 8,
  '2AA2D': 8,
  '2AA2E': 8,
  '2AA2F': 9,
  '2AA30': 9,
  '2AA31': 9,
  '2AA32': 9,
  '2AA33': 9,
  '2AA34': 9,
  '2AA35': 10,
  '2AA36': 10,
  '2AA37': 10,
  '2AA38': 10,
  '2AA39': 10,
  '2AA3A': 10,
  '2AA3B': 11,
  '2AA3C': 11,
  '2AA3D': 11,
  '2AA3E': 11,
  '2AA3F': 11,
  '2AA40': 11,
  '2AA41': 11,
  '2AA42': 12,
  '2AA43': 12,
  '2AA44': 12,
  '2AA45': 12,
  '2AA46': 12,
  '2AA47': 12,
  '2AA48': 13,
  '2AA49': 13,
  '2AA4A': 13,
  '2AA4B': 13,
  '2AA4C': 14,
  '2AA4D': 14,
  '2AA4E': 14,
  '2AA4F': 14,
  '2AA50': 14,
  '2AA51': 14,
  '2AA52': 14,
  '2AA53': 15,
  '2AA54': 15,
  '2AA55': 15,
  '2AA56': 15,
  '2AA57': 16,
  '2AA58': 16,
  '2AA59': 16,
  '2AA5A': 16,
  '2AA5B': 17,
  '2AA5C': 17,
  '2AA5D': 17,
  '2AA5E': 19,
  '2AA5F': 21,
  '2AA60': 22,
  '2AA61': 7,
  '2AA62': 9,
  '2AA63': 6,
  '2AA64': 11,
  '2AA65': 12,
  '2AA66': 13,
  '2AA67': 14,
  '2AA68': 17,
  '2AA69': 18,
  '2AA6A': 22,
  '2AA6B': 8,
  '2AA6C': 7,
  '2AA6D': 12,
  '2AA6E': 12,
  '2AA6F': 14,
  '2AA70': 16,
  '2AA71': 16,
  '2AA72': 5,
  '2AA73': 10,
  '2AA74': 11,
  '2AA75': 11,
  '2AA76': 11,
  '2AA77': 11,
  '2AA78': 12,
  '2AA79': 12,
  '2AA7A': 12,
  '2AA7B': 14,
  '2AA7C': 15,
  '2AA7D': 16,
  '2AA7E': 17,
  '2AA7F': 19,
  '2AA80': 20,
  '2AA81': 7,
  '2AA82': 11,
  '2AA83': 11,
  '2AA84': 12,
  '2AA85': 13,
  '2AA86': 13,
  '2AA87': 15,
  '2AA88': 18,
  '2AA89': 19,
  '2AA8A': 6,
  '2AA8B': 9,
  '2AA8C': 7,
  '2AA8D': 7,
  '2AA8E': 8,
  '2AA8F': 8,
  '2AA90': 9,
  '2AA91': 9,
  '2AA92': 10,
  '2AA93': 10,
  '2AA94': 10,
  '2AA95': 10,
  '2AA96': 10,
  '2AA97': 10,
  '2AA98': 11,
  '2AA99': 11,
  '2AA9A': 12,
  '2AA9B': 12,
  '2AA9C': 13,
  '2AA9D': 13,
  '2AA9E': 14,
  '2AA9F': 14,
  '2AAA0': 14,
  '2AAA1': 15,
  '2AAA2': 15,
  '2AAA3': 15,
  '2AAA4': 16,
  '2AAA5': 16,
  '2AAA6': 17,
  '2AAA7': 17,
  '2AAA8': 17,
  '2AAA9': 18,
  '2AAAA': 19,
  '2AAAB': 21,
  '2AAAC': 5,
  '2AAAD': 7,
  '2AAAE': 7,
  '2AAAF': 9,
  '2AAB0': 10,
  '2AAB1': 11,
  '2AAB2': 14,
  '2AAB3': 7,
  '2AAB4': 8,
  '2AAB5': 10,
  '2AAB6': 12,
  '2AAB7': 13,
  '2AAB8': 17,
  '2AAB9': 22,
  '2AABA': 6,
  '2AABB': 8,
  '2AABC': 9,
  '2AABD': 9,
  '2AABE': 10,
  '2AABF': 11,
  '2AAC0': 12,
  '2AAC1': 12,
  '2AAC2': 14,
  '2AAC3': 17,
  '2AAC4': 21,
  '2AAC5': 25,
  '2AAC6': 6,
  '2AAC7': 18,
  '2AAC8': 10,
  '2AAC9': 15,
  '2AACA': 17,
  '2AACB': 7,
  '2AACC': 8,
  '2AACD': 10,
  '2AACE': 10,
  '2AACF': 11,
  '2AAD0': 11,
  '2AAD1': 12,
  '2AAD2': 12,
  '2AAD3': 12,
  '2AAD4': 13,
  '2AAD5': 13,
  '2AAD6': 14,
  '2AAD7': 14,
  '2AAD8': 14,
  '2AAD9': 16,
  '2AADA': 17,
  '2AADB': 18,
  '2AADC': 18,
  '2AADD': 5,
  '2AADE': 6,
  '2AADF': 7,
  '2AAE0': 7,
  '2AAE1': 7,
  '2AAE2': 7,
  '2AAE3': 7,
  '2AAE4': 7,
  '2AAE5': 7,
  '2AAE6': 8,
  '2AAE7': 8,
  '2AAE8': 8,
  '2AAE9': 8,
  '2AAEA': 8,
  '2AAEB': 8,
  '2AAEC': 8,
  '2AAED': 9,
  '2AAEE': 9,
  '2AAEF': 9,
  '2AAF0': 9,
  '2AAF1': 9,
  '2AAF2': 9,
  '2AAF3': 9,
  '2AAF4': 9,
  '2AAF5': 10,
  '2AAF6': 10,
  '2AAF7': 10,
  '2AAF8': 10,
  '2AAF9': 10,
  '2AAFA': 10,
  '2AAFB': 10,
  '2AAFC': 10,
  '2AAFD': 10,
  '2AAFE': 10,
  '2AAFF': 11,
  '2AB00': 11,
  '2AB01': 11,
  '2AB02': 11,
  '2AB03': 11,
  '2AB04': 11,
  '2AB05': 11,
  '2AB06': 11,
  '2AB07': 12,
  '2AB08': 12,
  '2AB09': 12,
  '2AB0A': 12,
  '2AB0B': 12,
  '2AB0C': 12,
  '2AB0D': 12,
  '2AB0E': 12,
  '2AB0F': 12,
  '2AB10': 12,
  '2AB11': 12,
  '2AB12': 12,
  '2AB13': 13,
  '2AB14': 13,
  '2AB15': 13,
  '2AB16': 13,
  '2AB17': 13,
  '2AB18': 13,
  '2AB19': 13,
  '2AB1A': 13,
  '2AB1B': 13,
  '2AB1C': 13,
  '2AB1D': 13,
  '2AB1E': 13,
  '2AB1F': 14,
  '2AB20': 14,
  '2AB21': 14,
  '2AB22': 14,
  '2AB23': 14,
  '2AB24': 14,
  '2AB25': 14,
  '2AB26': 14,
  '2AB27': 14,
  '2AB28': 15,
  '2AB29': 15,
  '2AB2A': 15,
  '2AB2B': 15,
  '2AB2C': 15,
  '2AB2D': 15,
  '2AB2E': 16,
  '2AB2F': 16,
  '2AB30': 16,
  '2AB31': 16,
  '2AB32': 16,
  '2AB33': 16,
  '2AB34': 16,
  '2AB35': 16,
  '2AB36': 16,
  '2AB37': 17,
  '2AB38': 17,
  '2AB39': 17,
  '2AB3A': 18,
  '2AB3B': 18,
  '2AB3C': 18,
  '2AB3D': 18,
  '2AB3E': 18,
  '2AB3F': 19,
  '2AB40': 19,
  '2AB41': 20,
  '2AB42': 20,
  '2AB43': 21,
  '2AB44': 21,
  '2AB45': 22,
  '2AB46': 22,
  '2AB47': 23,
  '2AB48': 25,
  '2AB49': 5,
  '2AB4A': 6,
  '2AB4B': 9,
  '2AB4C': 9,
  '2AB4D': 10,
  '2AB4E': 10,
  '2AB4F': 11,
  '2AB50': 11,
  '2AB51': 12,
  '2AB52': 13,
  '2AB53': 14,
  '2AB54': 16,
  '2AB55': 16,
  '2AB56': 16,
  '2AB57': 26,
  '2AB58': 9,
  '2AB59': 11,
  '2AB5A': 15,
  '2AB5B': 22,
  '2AB5C': 6,
  '2AB5D': 7,
  '2AB5E': 7,
  '2AB5F': 7,
  '2AB60': 7,
  '2AB61': 7,
  '2AB62': 7,
  '2AB63': 7,
  '2AB64': 8,
  '2AB65': 8,
  '2AB66': 8,
  '2AB67': 8,
  '2AB68': 8,
  '2AB69': 8,
  '2AB6A': 8,
  '2AB6B': 9,
  '2AB6C': 9,
  '2AB6D': 9,
  '2AB6E': 9,
  '2AB6F': 9,
  '2AB70': 9,
  '2AB71': 9,
  '2AB72': 9,
  '2AB73': 9,
  '2AB74': 10,
  '2AB75': 10,
  '2AB76': 10,
  '2AB77': 10,
  '2AB78': 10,
  '2AB79': 10,
  '2AB7A': 10,
  '2AB7B': 10,
  '2AB7C': 10,
  '2AB7D': 10,
  '2AB7E': 10,
  '2AB7F': 10,
  '2AB80': 10,
  '2AB81': 11,
  '2AB82': 11,
  '2AB83': 11,
  '2AB84': 11,
  '2AB85': 11,
  '2AB86': 11,
  '2AB87': 11,
  '2AB88': 11,
  '2AB89': 11,
  '2AB8A': 11,
  '2AB8B': 11,
  '2AB8C': 12,
  '2AB8D': 12,
  '2AB8E': 12,
  '2AB8F': 12,
  '2AB90': 12,
  '2AB91': 12,
  '2AB92': 12,
  '2AB93': 12,
  '2AB94': 12,
  '2AB95': 12,
  '2AB96': 12,
  '2AB97': 13,
  '2AB98': 13,
  '2AB99': 13,
  '2AB9A': 13,
  '2AB9B': 13,
  '2AB9C': 13,
  '2AB9D': 13,
  '2AB9E': 13,
  '2AB9F': 13,
  '2ABA0': 13,
  '2ABA1': 13,
  '2ABA2': 13,
  '2ABA3': 14,
  '2ABA4': 14,
  '2ABA5': 14,
  '2ABA6': 14,
  '2ABA7': 14,
  '2ABA8': 14,
  '2ABA9': 14,
  '2ABAA': 14,
  '2ABAB': 15,
  '2ABAC': 15,
  '2ABAD': 15,
  '2ABAE': 15,
  '2ABAF': 15,
  '2ABB0': 15,
  '2ABB1': 15,
  '2ABB2': 15,
  '2ABB3': 15,
  '2ABB4': 15,
  '2ABB5': 16,
  '2ABB6': 16,
  '2ABB7': 16,
  '2ABB8': 17,
  '2ABB9': 17,
  '2ABBA': 17,
  '2ABBB': 18,
  '2ABBC': 18,
  '2ABBD': 18,
  '2ABBE': 18,
  '2ABBF': 18,
  '2ABC0': 18,
  '2ABC1': 19,
  '2ABC2': 20,
  '2ABC3': 22,
  '2ABC4': 23,
  '2ABC5': 11,
  '2ABC6': 8,
  '2ABC7': 15,
  '2ABC8': 8,
  '2ABC9': 9,
  '2ABCA': 9,
  '2ABCB': 10,
  '2ABCC': 10,
  '2ABCD': 11,
  '2ABCE': 12,
  '2ABCF': 12,
  '2ABD0': 13,
  '2ABD1': 14,
  '2ABD2': 14,
  '2ABD3': 15,
  '2ABD4': 15,
  '2ABD5': 15,
  '2ABD6': 15,
  '2ABD7': 16,
  '2ABD8': 16,
  '2ABD9': 16,
  '2ABDA': 17,
  '2ABDB': 18,
  '2ABDC': 18,
  '2ABDD': 21,
  '2ABDE': 21,
  '2ABDF': 24,
  '2ABE0': 7,
  '2ABE1': 8,
  '2ABE2': 8,
  '2ABE3': 9,
  '2ABE4': 10,
  '2ABE5': 12,
  '2ABE6': 12,
  '2ABE7': 12,
  '2ABE8': 14,
  '2ABE9': 14,
  '2ABEA': 14,
  '2ABEB': 9,
  '2ABEC': 10,
  '2ABED': 14,
  '2ABEE': 15,
  '2ABEF': 17,
  '2ABF0': 21,
  '2ABF1': 8,
  '2ABF2': 8,
  '2ABF3': 8,
  '2ABF4': 9,
  '2ABF5': 10,
  '2ABF6': 10,
  '2ABF7': 10,
  '2ABF8': 11,
  '2ABF9': 12,
  '2ABFA': 12,
  '2ABFB': 12,
  '2ABFC': 12,
  '2ABFD': 13,
  '2ABFE': 14,
  '2ABFF': 15,
  '2AC00': 15,
  '2AC01': 16,
  '2AC02': 16,
  '2AC03': 19,
  '2AC04': 19,
  '2AC05': 20,
  '2AC06': 7,
  '2AC07': 7,
  '2AC08': 8,
  '2AC09': 8,
  '2AC0A': 8,
  '2AC0B': 8,
  '2AC0C': 8,
  '2AC0D': 8,
  '2AC0E': 8,
  '2AC0F': 9,
  '2AC10': 9,
  '2AC11': 9,
  '2AC12': 9,
  '2AC13': 9,
  '2AC14': 9,
  '2AC15': 9,
  '2AC16': 9,
  '2AC17': 9,
  '2AC18': 9,
  '2AC19': 10,
  '2AC1A': 10,
  '2AC1B': 10,
  '2AC1C': 10,
  '2AC1D': 10,
  '2AC1E': 10,
  '2AC1F': 10,
  '2AC20': 10,
  '2AC21': 10,
  '2AC22': 10,
  '2AC23': 10,
  '2AC24': 10,
  '2AC25': 11,
  '2AC26': 11,
  '2AC27': 11,
  '2AC28': 11,
  '2AC29': 12,
  '2AC2A': 12,
  '2AC2B': 12,
  '2AC2C': 12,
  '2AC2D': 12,
  '2AC2E': 12,
  '2AC2F': 13,
  '2AC30': 13,
  '2AC31': 13,
  '2AC32': 13,
  '2AC33': 13,
  '2AC34': 13,
  '2AC35': 13,
  '2AC36': 13,
  '2AC37': 13,
  '2AC38': 14,
  '2AC39': 14,
  '2AC3A': 14,
  '2AC3B': 14,
  '2AC3C': 14,
  '2AC3D': 14,
  '2AC3E': 15,
  '2AC3F': 15,
  '2AC40': 15,
  '2AC41': 15,
  '2AC42': 15,
  '2AC43': 15,
  '2AC44': 15,
  '2AC45': 15,
  '2AC46': 17,
  '2AC47': 16,
  '2AC48': 16,
  '2AC49': 16,
  '2AC4A': 16,
  '2AC4B': 16,
  '2AC4C': 16,
  '2AC4D': 17,
  '2AC4E': 18,
  '2AC4F': 18,
  '2AC50': 18,
  '2AC51': 21,
  '2AC52': 21,
  '2AC53': 21,
  '2AC54': 24,
  '2AC55': 15,
  '2AC56': 16,
  '2AC57': 16,
  '2AC58': 19,
  '2AC59': 6,
  '2AC5A': 7,
  '2AC5B': 8,
  '2AC5C': 9,
  '2AC5D': 9,
  '2AC5E': 10,
  '2AC5F': 14,
  '2AC60': 11,
  '2AC61': 11,
  '2AC62': 11,
  '2AC63': 12,
  '2AC64': 13,
  '2AC65': 13,
  '2AC66': 13,
  '2AC67': 13,
  '2AC68': 14,
  '2AC69': 14,
  '2AC6A': 14,
  '2AC6B': 15,
  '2AC6C': 15,
  '2AC6D': 16,
  '2AC6E': 21,
  '2AC6F': 21,
  '2AC70': 24,
  '2AC71': 7,
  '2AC72': 7,
  '2AC73': 7,
  '2AC74': 7,
  '2AC75': 7,
  '2AC76': 8,
  '2AC77': 8,
  '2AC78': 8,
  '2AC79': 8,
  '2AC7A': 9,
  '2AC7B': 9,
  '2AC7C': 9,
  '2AC7D': 9,
  '2AC7E': 9,
  '2AC7F': 9,
  '2AC80': 9,
  '2AC81': 9,
  '2AC82': 9,
  '2AC83': 10,
  '2AC84': 10,
  '2AC85': 10,
  '2AC86': 10,
  '2AC87': 10,
  '2AC88': 10,
  '2AC89': 10,
  '2AC8A': 10,
  '2AC8B': 10,
  '2AC8C': 10,
  '2AC8D': 10,
  '2AC8E': 10,
  '2AC8F': 10,
  '2AC90': 11,
  '2AC91': 11,
  '2AC92': 11,
  '2AC93': 11,
  '2AC94': 11,
  '2AC95': 11,
  '2AC96': 11,
  '2AC97': 11,
  '2AC98': 11,
  '2AC99': 11,
  '2AC9A': 11,
  '2AC9B': 11,
  '2AC9C': 11,
  '2AC9D': 11,
  '2AC9E': 11,
  '2AC9F': 12,
  '2ACA0': 12,
  '2ACA1': 12,
  '2ACA2': 12,
  '2ACA3': 12,
  '2ACA4': 12,
  '2ACA5': 12,
  '2ACA6': 12,
  '2ACA7': 12,
  '2ACA8': 12,
  '2ACA9': 12,
  '2ACAA': 12,
  '2ACAB': 12,
  '2ACAC': 12,
  '2ACAD': 12,
  '2ACAE': 12,
  '2ACAF': 12,
  '2ACB0': 13,
  '2ACB1': 13,
  '2ACB2': 13,
  '2ACB3': 13,
  '2ACB4': 13,
  '2ACB5': 13,
  '2ACB6': 13,
  '2ACB7': 13,
  '2ACB8': 13,
  '2ACB9': 13,
  '2ACBA': 13,
  '2ACBB': 13,
  '2ACBC': 13,
  '2ACBD': 13,
  '2ACBE': 13,
  '2ACBF': 13,
  '2ACC0': 13,
  '2ACC1': 13,
  '2ACC2': 13,
  '2ACC3': 13,
  '2ACC4': 13,
  '2ACC5': 13,
  '2ACC6': 13,
  '2ACC7': 13,
  '2ACC8': 14,
  '2ACC9': 14,
  '2ACCA': 14,
  '2ACCB': 14,
  '2ACCC': 14,
  '2ACCD': 14,
  '2ACCE': 14,
  '2ACCF': 14,
  '2ACD0': 14,
  '2ACD1': 14,
  '2ACD2': 14,
  '2ACD3': 14,
  '2ACD4': 14,
  '2ACD5': 14,
  '2ACD6': 14,
  '2ACD7': 14,
  '2ACD8': 14,
  '2ACD9': 14,
  '2ACDA': 14,
  '2ACDB': 14,
  '2ACDC': 14,
  '2ACDD': 15,
  '2ACDE': 15,
  '2ACDF': 15,
  '2ACE0': 15,
  '2ACE1': 15,
  '2ACE2': 15,
  '2ACE3': 15,
  '2ACE4': 15,
  '2ACE5': 15,
  '2ACE6': 15,
  '2ACE7': 15,
  '2ACE8': 15,
  '2ACE9': 15,
  '2ACEA': 15,
  '2ACEB': 15,
  '2ACEC': 15,
  '2ACED': 16,
  '2ACEE': 16,
  '2ACEF': 16,
  '2ACF0': 16,
  '2ACF1': 16,
  '2ACF2': 16,
  '2ACF3': 16,
  '2ACF4': 16,
  '2ACF5': 16,
  '2ACF6': 16,
  '2ACF7': 16,
  '2ACF8': 16,
  '2ACF9': 16,
  '2ACFA': 16,
  '2ACFB': 16,
  '2ACFC': 16,
  '2ACFD': 17,
  '2ACFE': 17,
  '2ACFF': 17,
  '2AD00': 17,
  '2AD01': 17,
  '2AD02': 17,
  '2AD03': 17,
  '2AD04': 17,
  '2AD05': 17,
  '2AD06': 17,
  '2AD07': 17,
  '2AD08': 17,
  '2AD09': 18,
  '2AD0A': 18,
  '2AD0B': 18,
  '2AD0C': 18,
  '2AD0D': 18,
  '2AD0E': 18,
  '2AD0F': 18,
  '2AD10': 19,
  '2AD11': 19,
  '2AD12': 19,
  '2AD13': 19,
  '2AD14': 19,
  '2AD15': 19,
  '2AD16': 20,
  '2AD17': 20,
  '2AD18': 20,
  '2AD19': 20,
  '2AD1A': 20,
  '2AD1B': 21,
  '2AD1C': 21,
  '2AD1D': 21,
  '2AD1E': 21,
  '2AD1F': 22,
  '2AD20': 22,
  '2AD21': 22,
  '2AD22': 23,
  '2AD23': 23,
  '2AD24': 24,
  '2AD25': 24,
  '2AD26': 25,
  '2AD27': 27,
  '2AD28': 26,
  '2AD29': 8,
  '2AD2A': 9,
  '2AD2B': 9,
  '2AD2C': 11,
  '2AD2D': 11,
  '2AD2E': 12,
  '2AD2F': 13,
  '2AD30': 13,
  '2AD31': 14,
  '2AD32': 15,
  '2AD33': 20,
  '2AD34': 24,
  '2AD35': 8,
  '2AD36': 10,
  '2AD37': 11,
  '2AD38': 12,
  '2AD39': 13,
  '2AD3A': 13,
  '2AD3B': 14,
  '2AD3C': 15,
  '2AD3D': 16,
  '2AD3E': 16,
  '2AD3F': 17,
  '2AD40': 9,
  '2AD41': 9,
  '2AD42': 10,
  '2AD43': 10,
  '2AD44': 11,
  '2AD45': 12,
  '2AD46': 14,
  '2AD47': 17,
  '2AD48': 10,
  '2AD49': 10,
  '2AD4A': 11,
  '2AD4B': 11,
  '2AD4C': 12,
  '2AD4D': 12,
  '2AD4E': 12,
  '2AD4F': 13,
  '2AD50': 15,
  '2AD51': 17,
  '2AD52': 17,
  '2AD53': 25,
  '2AD54': 14,
  '2AD55': 14,
  '2AD56': 5,
  '2AD57': 8,
  '2AD58': 8,
  '2AD59': 9,
  '2AD5A': 9,
  '2AD5B': 10,
  '2AD5C': 10,
  '2AD5D': 12,
  '2AD5E': 12,
  '2AD5F': 13,
  '2AD60': 14,
  '2AD61': 15,
  '2AD62': 18,
  '2AD63': 8,
  '2AD64': 9,
  '2AD65': 11,
  '2AD66': 13,
  '2AD67': 14,
  '2AD68': 5,
  '2AD69': 6,
  '2AD6A': 6,
  '2AD6B': 6,
  '2AD6C': 6,
  '2AD6D': 7,
  '2AD6E': 7,
  '2AD6F': 7,
  '2AD70': 7,
  '2AD71': 8,
  '2AD72': 8,
  '2AD73': 8,
  '2AD74': 8,
  '2AD75': 8,
  '2AD76': 8,
  '2AD77': 9,
  '2AD78': 9,
  '2AD79': 9,
  '2AD7A': 9,
  '2AD7B': 9,
  '2AD7C': 9,
  '2AD7D': 9,
  '2AD7E': 10,
  '2AD7F': 10,
  '2AD80': 10,
  '2AD81': 10,
  '2AD82': 10,
  '2AD83': 10,
  '2AD84': 10,
  '2AD85': 10,
  '2AD86': 10,
  '2AD87': 10,
  '2AD88': 10,
  '2AD89': 10,
  '2AD8A': 10,
  '2AD8B': 10,
  '2AD8C': 11,
  '2AD8D': 11,
  '2AD8E': 11,
  '2AD8F': 11,
  '2AD90': 11,
  '2AD91': 11,
  '2AD92': 11,
  '2AD93': 11,
  '2AD94': 11,
  '2AD95': 11,
  '2AD96': 11,
  '2AD97': 12,
  '2AD98': 12,
  '2AD99': 12,
  '2AD9A': 12,
  '2AD9B': 12,
  '2AD9C': 12,
  '2AD9D': 12,
  '2AD9E': 12,
  '2AD9F': 12,
  '2ADA0': 12,
  '2ADA1': 12,
  '2ADA2': 12,
  '2ADA3': 12,
  '2ADA4': 12,
  '2ADA5': 12,
  '2ADA6': 12,
  '2ADA7': 12,
  '2ADA8': 12,
  '2ADA9': 12,
  '2ADAA': 13,
  '2ADAB': 13,
  '2ADAC': 13,
  '2ADAD': 13,
  '2ADAE': 13,
  '2ADAF': 13,
  '2ADB0': 13,
  '2ADB1': 13,
  '2ADB2': 13,
  '2ADB3': 13,
  '2ADB4': 13,
  '2ADB5': 13,
  '2ADB6': 13,
  '2ADB7': 13,
  '2ADB8': 13,
  '2ADB9': 13,
  '2ADBA': 13,
  '2ADBB': 13,
  '2ADBC': 13,
  '2ADBD': 13,
  '2ADBE': 13,
  '2ADBF': 14,
  '2ADC0': 14,
  '2ADC1': 14,
  '2ADC2': 14,
  '2ADC3': 14,
  '2ADC4': 14,
  '2ADC5': 14,
  '2ADC6': 14,
  '2ADC7': 14,
  '2ADC8': 14,
  '2ADC9': 14,
  '2ADCA': 14,
  '2ADCB': 15,
  '2ADCC': 15,
  '2ADCD': 15,
  '2ADCE': 15,
  '2ADCF': 15,
  '2ADD0': 15,
  '2ADD1': 15,
  '2ADD2': 15,
  '2ADD3': 15,
  '2ADD4': 15,
  '2ADD5': 15,
  '2ADD6': 15,
  '2ADD7': 15,
  '2ADD8': 15,
  '2ADD9': 15,
  '2ADDA': 15,
  '2ADDB': 15,
  '2ADDC': 15,
  '2ADDD': 16,
  '2ADDE': 16,
  '2ADDF': 16,
  '2ADE0': 16,
  '2ADE1': 16,
  '2ADE2': 16,
  '2ADE3': 16,
  '2ADE4': 16,
  '2ADE5': 16,
  '2ADE6': 16,
  '2ADE7': 16,
  '2ADE8': 17,
  '2ADE9': 17,
  '2ADEA': 17,
  '2ADEB': 17,
  '2ADEC': 17,
  '2ADED': 17,
  '2ADEE': 17,
  '2ADEF': 17,
  '2ADF0': 17,
  '2ADF1': 17,
  '2ADF2': 17,
  '2ADF3': 18,
  '2ADF4': 18,
  '2ADF5': 18,
  '2ADF6': 18,
  '2ADF7': 18,
  '2ADF8': 18,
  '2ADF9': 18,
  '2ADFA': 19,
  '2ADFB': 19,
  '2ADFC': 19,
  '2ADFD': 19,
  '2ADFE': 19,
  '2ADFF': 19,
  '2AE00': 20,
  '2AE01': 20,
  '2AE02': 20,
  '2AE03': 21,
  '2AE04': 21,
  '2AE05': 21,
  '2AE06': 21,
  '2AE07': 21,
  '2AE08': 22,
  '2AE09': 22,
  '2AE0A': 23,
  '2AE0B': 23,
  '2AE0C': 24,
  '2AE0D': 5,
  '2AE0E': 7,
  '2AE0F': 7,
  '2AE10': 7,
  '2AE11': 8,
  '2AE12': 8,
  '2AE13': 8,
  '2AE14': 8,
  '2AE15': 8,
  '2AE16': 8,
  '2AE17': 8,
  '2AE18': 9,
  '2AE19': 9,
  '2AE1A': 9,
  '2AE1B': 9,
  '2AE1C': 9,
  '2AE1D': 9,
  '2AE1E': 9,
  '2AE1F': 9,
  '2AE20': 9,
  '2AE21': 9,
  '2AE22': 9,
  '2AE23': 10,
  '2AE24': 10,
  '2AE25': 10,
  '2AE26': 10,
  '2AE27': 10,
  '2AE28': 10,
  '2AE29': 10,
  '2AE2A': 10,
  '2AE2B': 11,
  '2AE2C': 11,
  '2AE2D': 11,
  '2AE2E': 11,
  '2AE2F': 11,
  '2AE30': 11,
  '2AE31': 11,
  '2AE32': 11,
  '2AE33': 12,
  '2AE34': 12,
  '2AE35': 12,
  '2AE36': 12,
  '2AE37': 12,
  '2AE38': 12,
  '2AE39': 12,
  '2AE3A': 12,
  '2AE3B': 12,
  '2AE3C': 12,
  '2AE3D': 12,
  '2AE3E': 12,
  '2AE3F': 12,
  '2AE40': 12,
  '2AE41': 12,
  '2AE42': 12,
  '2AE43': 12,
  '2AE44': 13,
  '2AE45': 13,
  '2AE46': 13,
  '2AE47': 13,
  '2AE48': 13,
  '2AE49': 13,
  '2AE4A': 13,
  '2AE4B': 13,
  '2AE4C': 13,
  '2AE4D': 13,
  '2AE4E': 13,
  '2AE4F': 13,
  '2AE50': 13,
  '2AE51': 13,
  '2AE52': 14,
  '2AE53': 14,
  '2AE54': 14,
  '2AE55': 14,
  '2AE56': 14,
  '2AE57': 14,
  '2AE58': 14,
  '2AE59': 14,
  '2AE5A': 14,
  '2AE5B': 14,
  '2AE5C': 14,
  '2AE5D': 14,
  '2AE5E': 14,
  '2AE5F': 14,
  '2AE60': 14,
  '2AE61': 15,
  '2AE62': 15,
  '2AE63': 15,
  '2AE64': 15,
  '2AE65': 15,
  '2AE66': 15,
  '2AE67': 15,
  '2AE68': 15,
  '2AE69': 15,
  '2AE6A': 16,
  '2AE6B': 16,
  '2AE6C': 16,
  '2AE6D': 16,
  '2AE6E': 16,
  '2AE6F': 16,
  '2AE70': 16,
  '2AE71': 16,
  '2AE72': 16,
  '2AE73': 16,
  '2AE74': 17,
  '2AE75': 17,
  '2AE76': 17,
  '2AE77': 17,
  '2AE78': 17,
  '2AE79': 17,
  '2AE7A': 17,
  '2AE7B': 17,
  '2AE7C': 18,
  '2AE7D': 18,
  '2AE7E': 18,
  '2AE7F': 18,
  '2AE80': 18,
  '2AE81': 19,
  '2AE82': 19,
  '2AE83': 20,
  '2AE84': 20,
  '2AE85': 20,
  '2AE86': 22,
  '2AE87': 22,
  '2AE88': 23,
  '2AE89': 24,
  '2AE8A': 25,
  '2AE8B': 25,
  '2AE8C': 27,
  '2AE8D': 7,
  '2AE8E': 9,
  '2AE8F': 11,
  '2AE90': 12,
  '2AE91': 12,
  '2AE92': 12,
  '2AE93': 12,
  '2AE94': 13,
  '2AE95': 13,
  '2AE96': 15,
  '2AE97': 15,
  '2AE98': 17,
  '2AE99': 17,
  '2AE9A': 37,
  '2AE9B': 10,
  '2AE9C': 11,
  '2AE9D': 8,
  '2AE9E': 11,
  '2AE9F': 12,
  '2AEA0': 15,
  '2AEA1': 18,
  '2AEA2': 10,
  '2AEA3': 11,
  '2AEA4': 11,
  '2AEA5': 12,
  '2AEA6': 16,
  '2AEA7': 8,
  '2AEA8': 12,
  '2AEA9': 7,
  '2AEAA': 9,
  '2AEAB': 9,
  '2AEAC': 10,
  '2AEAD': 10,
  '2AEAE': 10,
  '2AEAF': 12,
  '2AEB0': 13,
  '2AEB1': 14,
  '2AEB2': 14,
  '2AEB3': 14,
  '2AEB4': 15,
  '2AEB5': 16,
  '2AEB6': 21,
  '2AEB7': 7,
  '2AEB8': 8,
  '2AEB9': 12,
  '2AEBA': 9,
  '2AEBB': 9,
  '2AEBC': 10,
  '2AEBD': 10,
  '2AEBE': 11,
  '2AEBF': 11,
  '2AEC0': 11,
  '2AEC1': 11,
  '2AEC2': 12,
  '2AEC3': 12,
  '2AEC4': 12,
  '2AEC5': 12,
  '2AEC6': 13,
  '2AEC7': 13,
  '2AEC8': 14,
  '2AEC9': 15,
  '2AECA': 17,
  '2AECB': 18,
  '2AECC': 19,
  '2AECD': 6,
  '2AECE': 8,
  '2AECF': 8,
  '2AED0': 8,
  '2AED1': 8,
  '2AED2': 8,
  '2AED3': 8,
  '2AED4': 8,
  '2AED5': 9,
  '2AED6': 9,
  '2AED7': 10,
  '2AED8': 10,
  '2AED9': 10,
  '2AEDA': 10,
  '2AEDB': 10,
  '2AEDC': 10,
  '2AEDD': 10,
  '2AEDE': 10,
  '2AEDF': 10,
  '2AEE0': 11,
  '2AEE1': 11,
  '2AEE2': 11,
  '2AEE3': 11,
  '2AEE4': 11,
  '2AEE5': 11,
  '2AEE6': 12,
  '2AEE7': 12,
  '2AEE8': 12,
  '2AEE9': 12,
  '2AEEA': 12,
  '2AEEB': 12,
  '2AEEC': 12,
  '2AEED': 12,
  '2AEEE': 12,
  '2AEEF': 12,
  '2AEF0': 12,
  '2AEF1': 12,
  '2AEF2': 12,
  '2AEF3': 13,
  '2AEF4': 13,
  '2AEF5': 13,
  '2AEF6': 13,
  '2AEF7': 13,
  '2AEF8': 13,
  '2AEF9': 13,
  '2AEFA': 13,
  '2AEFB': 13,
  '2AEFC': 13,
  '2AEFD': 13,
  '2AEFE': 13,
  '2AEFF': 13,
  '2AF00': 13,
  '2AF01': 13,
  '2AF02': 13,
  '2AF03': 14,
  '2AF04': 14,
  '2AF05': 14,
  '2AF06': 14,
  '2AF07': 14,
  '2AF08': 14,
  '2AF09': 14,
  '2AF0A': 14,
  '2AF0B': 14,
  '2AF0C': 14,
  '2AF0D': 14,
  '2AF0E': 14,
  '2AF0F': 14,
  '2AF10': 15,
  '2AF11': 15,
  '2AF12': 15,
  '2AF13': 15,
  '2AF14': 15,
  '2AF15': 15,
  '2AF16': 15,
  '2AF17': 16,
  '2AF18': 16,
  '2AF19': 16,
  '2AF1A': 16,
  '2AF1B': 16,
  '2AF1C': 16,
  '2AF1D': 16,
  '2AF1E': 16,
  '2AF1F': 16,
  '2AF20': 16,
  '2AF21': 17,
  '2AF22': 17,
  '2AF23': 17,
  '2AF24': 17,
  '2AF25': 17,
  '2AF26': 17,
  '2AF27': 18,
  '2AF28': 18,
  '2AF29': 19,
  '2AF2A': 19,
  '2AF2B': 19,
  '2AF2C': 20,
  '2AF2D': 20,
  '2AF2E': 20,
  '2AF2F': 20,
  '2AF30': 21,
  '2AF31': 23,
  '2AF32': 24,
  '2AF33': 8,
  '2AF34': 9,
  '2AF35': 13,
  '2AF36': 8,
  '2AF37': 9,
  '2AF38': 9,
  '2AF39': 10,
  '2AF3A': 13,
  '2AF3B': 13,
  '2AF3C': 14,
  '2AF3D': 15,
  '2AF3E': 15,
  '2AF3F': 22,
  '2AF40': 12,
  '2AF41': 7,
  '2AF42': 11,
  '2AF43': 13,
  '2AF44': 13,
  '2AF45': 13,
  '2AF46': 6,
  '2AF47': 7,
  '2AF48': 8,
  '2AF49': 9,
  '2AF4A': 9,
  '2AF4B': 9,
  '2AF4C': 9,
  '2AF4D': 10,
  '2AF4E': 10,
  '2AF4F': 10,
  '2AF50': 10,
  '2AF51': 11,
  '2AF52': 11,
  '2AF53': 12,
  '2AF54': 12,
  '2AF55': 12,
  '2AF56': 12,
  '2AF57': 12,
  '2AF58': 13,
  '2AF59': 13,
  '2AF5A': 13,
  '2AF5B': 14,
  '2AF5C': 14,
  '2AF5D': 15,
  '2AF5E': 15,
  '2AF5F': 16,
  '2AF60': 16,
  '2AF61': 16,
  '2AF62': 17,
  '2AF63': 18,
  '2AF64': 20,
  '2AF65': 21,
  '2AF66': 21,
  '2AF67': 17,
  '2AF68': 9,
  '2AF69': 9,
  '2AF6A': 9,
  '2AF6B': 9,
  '2AF6C': 10,
  '2AF6D': 10,
  '2AF6E': 10,
  '2AF6F': 11,
  '2AF70': 12,
  '2AF71': 12,
  '2AF72': 13,
  '2AF73': 14,
  '2AF74': 14,
  '2AF75': 14,
  '2AF76': 15,
  '2AF77': 15,
  '2AF78': 17,
  '2AF79': 17,
  '2AF7A': 19,
  '2AF7B': 9,
  '2AF7C': 9,
  '2AF7D': 10,
  '2AF7E': 12,
  '2AF7F': 12,
  '2AF80': 13,
  '2AF81': 13,
  '2AF82': 14,
  '2AF83': 14,
  '2AF84': 15,
  '2AF85': 21,
  '2AF86': 8,
  '2AF87': 9,
  '2AF88': 14,
  '2AF89': 14,
  '2AF8A': 9,
  '2AF8B': 9,
  '2AF8C': 10,
  '2AF8D': 10,
  '2AF8E': 11,
  '2AF8F': 12,
  '2AF90': 12,
  '2AF91': 13,
  '2AF92': 13,
  '2AF93': 14,
  '2AF94': 14,
  '2AF95': 15,
  '2AF96': 16,
  '2AF97': 17,
  '2AF98': 17,
  '2AF99': 18,
  '2AF9A': 19,
  '2AF9B': 21,
  '2AF9C': 22,
  '2AF9D': 24,
  '2AF9E': 29,
  '2AF9F': 8,
  '2AFA0': 8,
  '2AFA1': 9,
  '2AFA2': 9,
  '2AFA3': 9,
  '2AFA4': 10,
  '2AFA5': 10,
  '2AFA6': 10,
  '2AFA7': 10,
  '2AFA8': 11,
  '2AFA9': 11,
  '2AFAA': 11,
  '2AFAB': 12,
  '2AFAC': 12,
  '2AFAD': 13,
  '2AFAE': 13,
  '2AFAF': 13,
  '2AFB0': 13,
  '2AFB1': 14,
  '2AFB2': 14,
  '2AFB3': 14,
  '2AFB4': 14,
  '2AFB5': 15,
  '2AFB6': 15,
  '2AFB7': 15,
  '2AFB8': 15,
  '2AFB9': 16,
  '2AFBA': 16,
  '2AFBB': 16,
  '2AFBC': 17,
  '2AFBD': 17,
  '2AFBE': 17,
  '2AFBF': 17,
  '2AFC0': 17,
  '2AFC1': 19,
  '2AFC2': 20,
  '2AFC3': 21,
  '2AFC4': 21,
  '2AFC5': 29,
  '2AFC6': 9,
  '2AFC7': 12,
  '2AFC8': 9,
  '2AFC9': 9,
  '2AFCA': 11,
  '2AFCB': 13,
  '2AFCC': 14,
  '2AFCD': 15,
  '2AFCE': 15,
  '2AFCF': 20,
  '2AFD0': 21,
  '2AFD1': 9,
  '2AFD2': 9,
  '2AFD3': 9,
  '2AFD4': 10,
  '2AFD5': 10,
  '2AFD6': 10,
  '2AFD7': 10,
  '2AFD8': 10,
  '2AFD9': 11,
  '2AFDA': 11,
  '2AFDB': 11,
  '2AFDC': 11,
  '2AFDD': 11,
  '2AFDE': 12,
  '2AFDF': 12,
  '2AFE0': 12,
  '2AFE1': 12,
  '2AFE2': 12,
  '2AFE3': 12,
  '2AFE4': 13,
  '2AFE5': 13,
  '2AFE6': 13,
  '2AFE7': 13,
  '2AFE8': 13,
  '2AFE9': 13,
  '2AFEA': 14,
  '2AFEB': 14,
  '2AFEC': 15,
  '2AFED': 15,
  '2AFEE': 15,
  '2AFEF': 16,
  '2AFF0': 16,
  '2AFF1': 16,
  '2AFF2': 16,
  '2AFF3': 16,
  '2AFF4': 16,
  '2AFF5': 17,
  '2AFF6': 17,
  '2AFF7': 17,
  '2AFF8': 18,
  '2AFF9': 18,
  '2AFFA': 18,
  '2AFFB': 19,
  '2AFFC': 19,
  '2AFFD': 19,
  '2AFFE': 21,
  '2AFFF': 23,
  '2B000': 7,
  '2B001': 7,
  '2B002': 8,
  '2B003': 8,
  '2B004': 9,
  '2B005': 9,
  '2B006': 9,
  '2B007': 10,
  '2B008': 10,
  '2B009': 10,
  '2B00A': 10,
  '2B00B': 11,
  '2B00C': 11,
  '2B00D': 11,
  '2B00E': 11,
  '2B00F': 11,
  '2B010': 12,
  '2B011': 12,
  '2B012': 12,
  '2B013': 12,
  '2B014': 12,
  '2B015': 12,
  '2B016': 12,
  '2B017': 12,
  '2B018': 12,
  '2B019': 12,
  '2B01A': 13,
  '2B01B': 13,
  '2B01C': 14,
  '2B01D': 14,
  '2B01E': 14,
  '2B01F': 14,
  '2B020': 15,
  '2B021': 15,
  '2B022': 17,
  '2B023': 18,
  '2B024': 24,
  '2B025': 18,
  '2B026': 6,
  '2B027': 8,
  '2B028': 9,
  '2B029': 9,
  '2B02A': 10,
  '2B02B': 10,
  '2B02C': 10,
  '2B02D': 10,
  '2B02E': 11,
  '2B02F': 11,
  '2B030': 11,
  '2B031': 11,
  '2B032': 12,
  '2B033': 12,
  '2B034': 12,
  '2B035': 12,
  '2B036': 12,
  '2B037': 12,
  '2B038': 12,
  '2B039': 13,
  '2B03A': 13,
  '2B03B': 13,
  '2B03C': 14,
  '2B03D': 14,
  '2B03E': 14,
  '2B03F': 14,
  '2B040': 14,
  '2B041': 15,
  '2B042': 15,
  '2B043': 15,
  '2B044': 15,
  '2B045': 16,
  '2B046': 17,
  '2B047': 18,
  '2B048': 18,
  '2B049': 20,
  '2B04A': 9,
  '2B04B': 10,
  '2B04C': 11,
  '2B04D': 11,
  '2B04E': 12,
  '2B04F': 12,
  '2B050': 12,
  '2B051': 13,
  '2B052': 13,
  '2B053': 13,
  '2B054': 14,
  '2B055': 14,
  '2B056': 15,
  '2B057': 16,
  '2B058': 16,
  '2B059': 16,
  '2B05A': 16,
  '2B05B': 17,
  '2B05C': 18,
  '2B05D': 23,
  '2B05E': 8,
  '2B05F': 9,
  '2B060': 10,
  '2B061': 10,
  '2B062': 11,
  '2B063': 12,
  '2B064': 12,
  '2B065': 13,
  '2B066': 13,
  '2B067': 14,
  '2B068': 14,
  '2B069': 15,
  '2B06A': 15,
  '2B06B': 15,
  '2B06C': 16,
  '2B06D': 17,
  '2B06E': 19,
  '2B06F': 22,
  '2B070': 8,
  '2B071': 9,
  '2B072': 10,
  '2B073': 10,
  '2B074': 10,
  '2B075': 10,
  '2B076': 10,
  '2B077': 10,
  '2B078': 11,
  '2B079': 11,
  '2B07A': 11,
  '2B07B': 11,
  '2B07C': 12,
  '2B07D': 12,
  '2B07E': 13,
  '2B07F': 13,
  '2B080': 13,
  '2B081': 13,
  '2B082': 13,
  '2B083': 13,
  '2B084': 14,
  '2B085': 14,
  '2B086': 14,
  '2B087': 14,
  '2B088': 14,
  '2B089': 14,
  '2B08A': 15,
  '2B08B': 15,
  '2B08C': 15,
  '2B08D': 15,
  '2B08E': 15,
  '2B08F': 15,
  '2B090': 16,
  '2B091': 16,
  '2B092': 16,
  '2B093': 16,
  '2B094': 16,
  '2B095': 16,
  '2B096': 16,
  '2B097': 16,
  '2B098': 17,
  '2B099': 17,
  '2B09A': 17,
  '2B09B': 17,
  '2B09C': 17,
  '2B09D': 17,
  '2B09E': 17,
  '2B09F': 17,
  '2B0A0': 18,
  '2B0A1': 18,
  '2B0A2': 18,
  '2B0A3': 18,
  '2B0A4': 18,
  '2B0A5': 19,
  '2B0A6': 19,
  '2B0A7': 20,
  '2B0A8': 21,
  '2B0A9': 21,
  '2B0AA': 21,
  '2B0AB': 21,
  '2B0AC': 22,
  '2B0AD': 23,
  '2B0AE': 24,
  '2B0AF': 25,
  '2B0B0': 28,
  '2B0B1': 8,
  '2B0B2': 8,
  '2B0B3': 9,
  '2B0B4': 9,
  '2B0B5': 10,
  '2B0B6': 10,
  '2B0B7': 10,
  '2B0B8': 11,
  '2B0B9': 11,
  '2B0BA': 11,
  '2B0BB': 11,
  '2B0BC': 11,
  '2B0BD': 12,
  '2B0BE': 12,
  '2B0BF': 12,
  '2B0C0': 12,
  '2B0C1': 13,
  '2B0C2': 14,
  '2B0C3': 14,
  '2B0C4': 14,
  '2B0C5': 14,
  '2B0C6': 15,
  '2B0C7': 15,
  '2B0C8': 15,
  '2B0C9': 16,
  '2B0CA': 16,
  '2B0CB': 16,
  '2B0CC': 16,
  '2B0CD': 17,
  '2B0CE': 17,
  '2B0CF': 18,
  '2B0D0': 18,
  '2B0D1': 18,
  '2B0D2': 19,
  '2B0D3': 19,
  '2B0D4': 19,
  '2B0D5': 19,
  '2B0D6': 20,
  '2B0D7': 22,
  '2B0D8': 22,
  '2B0D9': 22,
  '2B0DA': 8,
  '2B0DB': 8,
  '2B0DC': 9,
  '2B0DD': 9,
  '2B0DE': 10,
  '2B0DF': 11,
  '2B0E0': 11,
  '2B0E1': 11,
  '2B0E2': 12,
  '2B0E3': 12,
  '2B0E4': 12,
  '2B0E5': 13,
  '2B0E6': 13,
  '2B0E7': 13,
  '2B0E8': 13,
  '2B0E9': 13,
  '2B0EA': 14,
  '2B0EB': 14,
  '2B0EC': 14,
  '2B0ED': 14,
  '2B0EE': 14,
  '2B0EF': 14,
  '2B0F0': 14,
  '2B0F1': 14,
  '2B0F2': 14,
  '2B0F3': 15,
  '2B0F4': 15,
  '2B0F5': 15,
  '2B0F6': 15,
  '2B0F7': 15,
  '2B0F8': 16,
  '2B0F9': 16,
  '2B0FA': 16,
  '2B0FB': 16,
  '2B0FC': 16,
  '2B0FD': 17,
  '2B0FE': 17,
  '2B0FF': 17,
  '2B100': 17,
  '2B101': 18,
  '2B102': 18,
  '2B103': 18,
  '2B104': 18,
  '2B105': 19,
  '2B106': 19,
  '2B107': 19,
  '2B108': 19,
  '2B109': 19,
  '2B10A': 19,
  '2B10B': 19,
  '2B10C': 20,
  '2B10D': 20,
  '2B10E': 20,
  '2B10F': 21,
  '2B110': 21,
  '2B111': 21,
  '2B112': 22,
  '2B113': 22,
  '2B114': 22,
  '2B115': 23,
  '2B116': 25,
  '2B117': 26,
  '2B118': 27,
  '2B119': 4,
  '2B11A': 7,
  '2B11B': 7,
  '2B11C': 7,
  '2B11D': 7,
  '2B11E': 8,
  '2B11F': 8,
  '2B120': 9,
  '2B121': 9,
  '2B122': 9,
  '2B123': 9,
  '2B124': 10,
  '2B125': 10,
  '2B126': 10,
  '2B127': 10,
  '2B128': 10,
  '2B129': 10,
  '2B12A': 11,
  '2B12B': 11,
  '2B12C': 12,
  '2B12D': 12,
  '2B12E': 12,
  '2B12F': 13,
  '2B130': 13,
  '2B131': 14,
  '2B132': 14,
  '2B133': 14,
  '2B134': 14,
  '2B135': 15,
  '2B136': 15,
  '2B137': 16,
  '2B138': 17,
  '2B139': 21,
  '2B13A': 13,
  '2B13B': 14,
  '2B13C': 15,
  '2B13D': 15,
  '2B13E': 16,
  '2B13F': 19,
  '2B140': 8,
  '2B141': 9,
  '2B142': 10,
  '2B143': 11,
  '2B144': 11,
  '2B145': 11,
  '2B146': 13,
  '2B147': 13,
  '2B148': 14,
  '2B149': 15,
  '2B14A': 16,
  '2B14B': 18,
  '2B14C': 19,
  '2B14D': 21,
  '2B14E': 8,
  '2B14F': 11,
  '2B150': 11,
  '2B151': 11,
  '2B152': 11,
  '2B153': 12,
  '2B154': 12,
  '2B155': 13,
  '2B156': 15,
  '2B157': 15,
  '2B158': 15,
  '2B159': 16,
  '2B15A': 17,
  '2B15B': 17,
  '2B15C': 17,
  '2B15D': 18,
  '2B15E': 18,
  '2B15F': 20,
  '2B160': 22,
  '2B161': 22,
  '2B162': 9,
  '2B163': 10,
  '2B164': 11,
  '2B165': 12,
  '2B166': 12,
  '2B167': 12,
  '2B168': 13,
  '2B169': 13,
  '2B16A': 14,
  '2B16B': 15,
  '2B16C': 15,
  '2B16D': 16,
  '2B16E': 16,
  '2B16F': 17,
  '2B170': 18,
  '2B171': 18,
  '2B172': 22,
  '2B173': 10,
  '2B174': 10,
  '2B175': 13,
  '2B176': 14,
  '2B177': 15,
  '2B178': 18,
  '2B179': 11,
  '2B17A': 12,
  '2B17B': 12,
  '2B17C': 13,
  '2B17D': 15,
  '2B17E': 16,
  '2B17F': 18,
  '2B180': 8,
  '2B181': 10,
  '2B182': 12,
  '2B183': 12,
  '2B184': 13,
  '2B185': 13,
  '2B186': 13,
  '2B187': 14,
  '2B188': 14,
  '2B189': 15,
  '2B18A': 16,
  '2B18B': 16,
  '2B18C': 16,
  '2B18D': 16,
  '2B18E': 17,
  '2B18F': 17,
  '2B190': 18,
  '2B191': 18,
  '2B192': 19,
  '2B193': 28,
  '2B194': 13,
  '2B195': 14,
  '2B196': 8,
  '2B197': 8,
  '2B198': 8,
  '2B199': 9,
  '2B19A': 9,
  '2B19B': 9,
  '2B19C': 9,
  '2B19D': 10,
  '2B19E': 10,
  '2B19F': 11,
  '2B1A0': 11,
  '2B1A1': 11,
  '2B1A2': 12,
  '2B1A3': 12,
  '2B1A4': 12,
  '2B1A5': 12,
  '2B1A6': 13,
  '2B1A7': 13,
  '2B1A8': 13,
  '2B1A9': 14,
  '2B1AA': 14,
  '2B1AB': 14,
  '2B1AC': 14,
  '2B1AD': 14,
  '2B1AE': 14,
  '2B1AF': 15,
  '2B1B0': 15,
  '2B1B1': 15,
  '2B1B2': 15,
  '2B1B3': 15,
  '2B1B4': 15,
  '2B1B5': 16,
  '2B1B6': 16,
  '2B1B7': 16,
  '2B1B8': 16,
  '2B1B9': 17,
  '2B1BA': 17,
  '2B1BB': 17,
  '2B1BC': 18,
  '2B1BD': 18,
  '2B1BE': 19,
  '2B1BF': 19,
  '2B1C0': 20,
  '2B1C1': 21,
  '2B1C2': 22,
  '2B1C3': 23,
  '2B1C4': 30,
  '2B1C5': 10,
  '2B1C6': 12,
  '2B1C7': 13,
  '2B1C8': 14,
  '2B1C9': 18,
  '2B1CA': 12,
  '2B1CB': 15,
  '2B1CC': 18,
  '2B1CD': 26,
  '2B1CE': 13,
  '2B1CF': 14,
  '2B1D0': 14,
  '2B1D1': 20,
  '2B1D2': 18,
  '2B1D3': 26,
  '2B1D4': 8,
  '2B1D5': 13,
  '2B1D6': 13,
  '2B1D7': 16,
  '2B1D8': 19,
  '2B1D9': 22,
  '2B1DA': 9,
  '2B1DB': 14,
  '2B1DC': 15,
  '2B1DD': 15,
  '2B1DE': 17,
  '2B1DF': 18,
  '2B1E0': 20,
  '2B1E1': 22,
  '2B1E2': 22,
  '2B1E3': 22,
  '2B1E4': 16,
  '2B1E5': 6,
  '2B1E6': 6,
  '2B1E7': 7,
  '2B1E8': 7,
  '2B1E9': 8,
  '2B1EA': 8,
  '2B1EB': 8,
  '2B1EC': 8,
  '2B1ED': 8,
  '2B1EE': 9,
  '2B1EF': 9,
  '2B1F0': 9,
  '2B1F1': 9,
  '2B1F2': 9,
  '2B1F3': 9,
  '2B1F4': 9,
  '2B1F5': 9,
  '2B1F6': 10,
  '2B1F7': 10,
  '2B1F8': 10,
  '2B1F9': 10,
  '2B1FA': 10,
  '2B1FB': 10,
  '2B1FC': 10,
  '2B1FD': 10,
  '2B1FE': 10,
  '2B1FF': 11,
  '2B200': 11,
  '2B201': 11,
  '2B202': 11,
  '2B203': 11,
  '2B204': 11,
  '2B205': 11,
  '2B206': 11,
  '2B207': 11,
  '2B208': 11,
  '2B209': 11,
  '2B20A': 11,
  '2B20B': 11,
  '2B20C': 11,
  '2B20D': 11,
  '2B20E': 11,
  '2B20F': 11,
  '2B210': 12,
  '2B211': 12,
  '2B212': 12,
  '2B213': 12,
  '2B214': 12,
  '2B215': 12,
  '2B216': 12,
  '2B217': 12,
  '2B218': 12,
  '2B219': 12,
  '2B21A': 12,
  '2B21B': 12,
  '2B21C': 12,
  '2B21D': 12,
  '2B21E': 12,
  '2B21F': 12,
  '2B220': 12,
  '2B221': 13,
  '2B222': 13,
  '2B223': 13,
  '2B224': 13,
  '2B225': 13,
  '2B226': 13,
  '2B227': 13,
  '2B228': 13,
  '2B229': 13,
  '2B22A': 13,
  '2B22B': 13,
  '2B22C': 13,
  '2B22D': 13,
  '2B22E': 13,
  '2B22F': 13,
  '2B230': 12,
  '2B231': 13,
  '2B232': 13,
  '2B233': 13,
  '2B234': 13,
  '2B235': 13,
  '2B236': 14,
  '2B237': 14,
  '2B238': 14,
  '2B239': 14,
  '2B23A': 14,
  '2B23B': 14,
  '2B23C': 14,
  '2B23D': 14,
  '2B23E': 14,
  '2B23F': 14,
  '2B240': 14,
  '2B241': 14,
  '2B242': 14,
  '2B243': 14,
  '2B244': 14,
  '2B245': 14,
  '2B246': 14,
  '2B247': 14,
  '2B248': 15,
  '2B249': 15,
  '2B24A': 15,
  '2B24B': 15,
  '2B24C': 15,
  '2B24D': 15,
  '2B24E': 15,
  '2B24F': 15,
  '2B250': 15,
  '2B251': 15,
  '2B252': 15,
  '2B253': 15,
  '2B254': 15,
  '2B255': 15,
  '2B256': 15,
  '2B257': 15,
  '2B258': 15,
  '2B259': 15,
  '2B25A': 15,
  '2B25B': 15,
  '2B25C': 15,
  '2B25D': 16,
  '2B25E': 16,
  '2B25F': 16,
  '2B260': 16,
  '2B261': 16,
  '2B262': 16,
  '2B263': 16,
  '2B264': 16,
  '2B265': 17,
  '2B266': 17,
  '2B267': 17,
  '2B268': 17,
  '2B269': 17,
  '2B26A': 17,
  '2B26B': 17,
  '2B26C': 17,
  '2B26D': 17,
  '2B26E': 17,
  '2B26F': 17,
  '2B270': 17,
  '2B271': 17,
  '2B272': 17,
  '2B273': 17,
  '2B274': 17,
  '2B275': 18,
  '2B276': 18,
  '2B277': 18,
  '2B278': 18,
  '2B279': 18,
  '2B27A': 18,
  '2B27B': 18,
  '2B27C': 18,
  '2B27D': 18,
  '2B27E': 18,
  '2B27F': 18,
  '2B280': 18,
  '2B281': 18,
  '2B282': 18,
  '2B283': 18,
  '2B284': 18,
  '2B285': 19,
  '2B286': 19,
  '2B287': 19,
  '2B288': 19,
  '2B289': 19,
  '2B28A': 19,
  '2B28B': 19,
  '2B28C': 20,
  '2B28D': 20,
  '2B28E': 20,
  '2B28F': 20,
  '2B290': 21,
  '2B291': 21,
  '2B292': 21,
  '2B293': 21,
  '2B294': 22,
  '2B295': 22,
  '2B296': 22,
  '2B297': 23,
  '2B298': 24,
  '2B299': 24,
  '2B29A': 24,
  '2B29B': 26,
  '2B29C': 26,
  '2B29D': 9,
  '2B29E': 9,
  '2B29F': 10,
  '2B2A0': 12,
  '2B2A1': 12,
  '2B2A2': 14,
  '2B2A3': 17,
  '2B2A4': 8,
  '2B2A5': 9,
  '2B2A6': 9,
  '2B2A7': 10,
  '2B2A8': 10,
  '2B2A9': 10,
  '2B2AA': 10,
  '2B2AB': 10,
  '2B2AC': 11,
  '2B2AD': 11,
  '2B2AE': 11,
  '2B2AF': 11,
  '2B2B0': 11,
  '2B2B1': 11,
  '2B2B2': 11,
  '2B2B3': 12,
  '2B2B4': 12,
  '2B2B5': 12,
  '2B2B6': 12,
  '2B2B7': 12,
  '2B2B8': 12,
  '2B2B9': 12,
  '2B2BA': 12,
  '2B2BB': 12,
  '2B2BC': 12,
  '2B2BD': 13,
  '2B2BE': 13,
  '2B2BF': 13,
  '2B2C0': 13,
  '2B2C1': 13,
  '2B2C2': 14,
  '2B2C3': 14,
  '2B2C4': 14,
  '2B2C5': 14,
  '2B2C6': 14,
  '2B2C7': 14,
  '2B2C8': 14,
  '2B2C9': 14,
  '2B2CA': 15,
  '2B2CB': 15,
  '2B2CC': 15,
  '2B2CD': 15,
  '2B2CE': 15,
  '2B2CF': 16,
  '2B2D0': 16,
  '2B2D1': 16,
  '2B2D2': 16,
  '2B2D3': 16,
  '2B2D4': 16,
  '2B2D5': 16,
  '2B2D6': 17,
  '2B2D7': 17,
  '2B2D8': 17,
  '2B2D9': 17,
  '2B2DA': 18,
  '2B2DB': 18,
  '2B2DC': 18,
  '2B2DD': 18,
  '2B2DE': 18,
  '2B2DF': 19,
  '2B2E0': 19,
  '2B2E1': 19,
  '2B2E2': 19,
  '2B2E3': 20,
  '2B2E4': 20,
  '2B2E5': 20,
  '2B2E6': 21,
  '2B2E7': 21,
  '2B2E8': 22,
  '2B2E9': 29,
  '2B2EA': 10,
  '2B2EB': 11,
  '2B2EC': 17,
  '2B2ED': 11,
  '2B2EE': 12,
  '2B2EF': 15,
  '2B2F0': 18,
  '2B2F1': 23,
  '2B2F2': 9,
  '2B2F3': 9,
  '2B2F4': 9,
  '2B2F5': 10,
  '2B2F6': 10,
  '2B2F7': 10,
  '2B2F8': 11,
  '2B2F9': 11,
  '2B2FA': 11,
  '2B2FB': 11,
  '2B2FC': 11,
  '2B2FD': 12,
  '2B2FE': 13,
  '2B2FF': 13,
  '2B300': 13,
  '2B301': 13,
  '2B302': 13,
  '2B303': 13,
  '2B304': 13,
  '2B305': 14,
  '2B306': 14,
  '2B307': 15,
  '2B308': 15,
  '2B309': 15,
  '2B30A': 16,
  '2B30B': 16,
  '2B30C': 16,
  '2B30D': 16,
  '2B30E': 16,
  '2B30F': 16,
  '2B310': 17,
  '2B311': 17,
  '2B312': 17,
  '2B313': 18,
  '2B314': 18,
  '2B315': 19,
  '2B316': 20,
  '2B317': 22,
  '2B318': 23,
  '2B319': 23,
  '2B31A': 11,
  '2B31B': 16,
  '2B31C': 10,
  '2B31D': 12,
  '2B31E': 13,
  '2B31F': 14,
  '2B320': 14,
  '2B321': 15,
  '2B322': 16,
  '2B323': 16,
  '2B324': 18,
  '2B325': 20,
  '2B326': 25,
  '2B327': 25,
  '2B328': 9,
  '2B329': 9,
  '2B32A': 10,
  '2B32B': 13,
  '2B32C': 15,
  '2B32D': 17,
  '2B32E': 13,
  '2B32F': 13,
  '2B330': 16,
  '2B331': 16,
  '2B332': 10,
  '2B333': 10,
  '2B334': 11,
  '2B335': 11,
  '2B336': 12,
  '2B337': 12,
  '2B338': 12,
  '2B339': 12,
  '2B33A': 13,
  '2B33B': 13,
  '2B33C': 14,
  '2B33D': 14,
  '2B33E': 14,
  '2B33F': 15,
  '2B340': 15,
  '2B341': 16,
  '2B342': 16,
  '2B343': 16,
  '2B344': 16,
  '2B345': 16,
  '2B346': 17,
  '2B347': 17,
  '2B348': 17,
  '2B349': 18,
  '2B34A': 18,
  '2B34B': 18,
  '2B34C': 18,
  '2B34D': 19,
  '2B34E': 19,
  '2B34F': 19,
  '2B350': 20,
  '2B351': 20,
  '2B352': 21,
  '2B353': 23,
  '2B354': 24,
  '2B355': 24,
  '2B356': 24,
  '2B357': 26,
  '2B358': 28,
  '2B359': 5,
  '2B35A': 6,
  '2B35B': 6,
  '2B35C': 7,
  '2B35D': 7,
  '2B35E': 7,
  '2B35F': 7,
  '2B360': 7,
  '2B361': 7,
  '2B362': 8,
  '2B363': 8,
  '2B364': 8,
  '2B365': 8,
  '2B366': 8,
  '2B367': 9,
  '2B368': 9,
  '2B369': 9,
  '2B36A': 9,
  '2B36B': 10,
  '2B36C': 10,
  '2B36D': 10,
  '2B36E': 10,
  '2B36F': 11,
  '2B370': 11,
  '2B371': 11,
  '2B372': 11,
  '2B373': 11,
  '2B374': 11,
  '2B375': 12,
  '2B376': 12,
  '2B377': 12,
  '2B378': 12,
  '2B379': 13,
  '2B37A': 13,
  '2B37B': 14,
  '2B37C': 14,
  '2B37D': 15,
  '2B37E': 16,
  '2B37F': 17,
  '2B380': 9,
  '2B381': 11,
  '2B382': 15,
  '2B383': 15,
  '2B384': 18,
  '2B385': 8,
  '2B386': 11,
  '2B387': 14,
  '2B388': 14,
  '2B389': 16,
  '2B38A': 10,
  '2B38B': 13,
  '2B38C': 16,
  '2B38D': 17,
  '2B38E': 9,
  '2B38F': 10,
  '2B390': 10,
  '2B391': 10,
  '2B392': 11,
  '2B393': 11,
  '2B394': 12,
  '2B395': 12,
  '2B396': 13,
  '2B397': 14,
  '2B398': 14,
  '2B399': 14,
  '2B39A': 15,
  '2B39B': 15,
  '2B39C': 16,
  '2B39D': 16,
  '2B39E': 16,
  '2B39F': 17,
  '2B3A0': 17,
  '2B3A1': 18,
  '2B3A2': 19,
  '2B3A3': 22,
  '2B3A4': 22,
  '2B3A5': 23,
  '2B3A6': 9,
  '2B3A7': 9,
  '2B3A8': 11,
  '2B3A9': 12,
  '2B3AA': 13,
  '2B3AB': 16,
  '2B3AC': 18,
  '2B3AD': 12,
  '2B3AE': 13,
  '2B3AF': 15,
  '2B3B0': 18,
  '2B3B1': 11,
  '2B3B2': 12,
  '2B3B3': 13,
  '2B3B4': 13,
  '2B3B5': 14,
  '2B3B6': 14,
  '2B3B7': 14,
  '2B3B8': 15,
  '2B3B9': 15,
  '2B3BA': 15,
  '2B3BB': 16,
  '2B3BC': 16,
  '2B3BD': 17,
  '2B3BE': 18,
  '2B3BF': 18,
  '2B3C0': 10,
  '2B3C1': 11,
  '2B3C2': 11,
  '2B3C3': 11,
  '2B3C4': 12,
  '2B3C5': 12,
  '2B3C6': 12,
  '2B3C7': 13,
  '2B3C8': 13,
  '2B3C9': 13,
  '2B3CA': 13,
  '2B3CB': 13,
  '2B3CC': 14,
  '2B3CD': 14,
  '2B3CE': 14,
  '2B3CF': 15,
  '2B3D0': 15,
  '2B3D1': 15,
  '2B3D2': 15,
  '2B3D3': 15,
  '2B3D4': 16,
  '2B3D5': 16,
  '2B3D6': 16,
  '2B3D7': 16,
  '2B3D8': 16,
  '2B3D9': 17,
  '2B3DA': 17,
  '2B3DB': 17,
  '2B3DC': 18,
  '2B3DD': 18,
  '2B3DE': 18,
  '2B3DF': 18,
  '2B3E0': 19,
  '2B3E1': 19,
  '2B3E2': 19,
  '2B3E3': 20,
  '2B3E4': 20,
  '2B3E5': 20,
  '2B3E6': 20,
  '2B3E7': 21,
  '2B3E8': 21,
  '2B3E9': 23,
  '2B3EA': 13,
  '2B3EB': 13,
  '2B3EC': 14,
  '2B3ED': 17,
  '2B3EE': 17,
  '2B3EF': 18,
  '2B3F0': 19,
  '2B3F1': 20,
  '2B3F2': 10,
  '2B3F3': 11,
  '2B3F4': 12,
  '2B3F5': 12,
  '2B3F6': 14,
  '2B3F7': 14,
  '2B3F8': 14,
  '2B3F9': 15,
  '2B3FA': 16,
  '2B3FB': 16,
  '2B3FC': 17,
  '2B3FD': 17,
  '2B3FE': 18,
  '2B3FF': 18,
  '2B400': 19,
  '2B401': 20,
  '2B402': 21,
  '2B403': 33,
  '2B404': 7,
  '2B405': 7,
  '2B406': 7,
  '2B407': 8,
  '2B408': 9,
  '2B409': 9,
  '2B40A': 9,
  '2B40B': 10,
  '2B40C': 10,
  '2B40D': 11,
  '2B40E': 12,
  '2B40F': 12,
  '2B410': 12,
  '2B411': 12,
  '2B412': 13,
  '2B413': 10,
  '2B414': 14,
  '2B415': 15,
  '2B416': 15,
  '2B417': 16,
  '2B418': 17,
  '2B419': 19,
  '2B41A': 12,
  '2B41B': 13,
  '2B41C': 13,
  '2B41D': 20,
  '2B41E': 4,
  '2B41F': 6,
  '2B420': 7,
  '2B421': 7,
  '2B422': 8,
  '2B423': 8,
  '2B424': 8,
  '2B425': 9,
  '2B426': 10,
  '2B427': 10,
  '2B428': 10,
  '2B429': 10,
  '2B42A': 10,
  '2B42B': 11,
  '2B42C': 11,
  '2B42D': 11,
  '2B42E': 11,
  '2B42F': 11,
  '2B430': 11,
  '2B431': 11,
  '2B432': 11,
  '2B433': 12,
  '2B434': 12,
  '2B435': 12,
  '2B436': 12,
  '2B437': 12,
  '2B438': 12,
  '2B439': 12,
  '2B43A': 13,
  '2B43B': 13,
  '2B43C': 13,
  '2B43D': 13,
  '2B43E': 14,
  '2B43F': 14,
  '2B440': 14,
  '2B441': 14,
  '2B442': 14,
  '2B443': 14,
  '2B444': 15,
  '2B445': 15,
  '2B446': 15,
  '2B447': 15,
  '2B448': 15,
  '2B449': 15,
  '2B44A': 15,
  '2B44B': 16,
  '2B44C': 17,
  '2B44D': 17,
  '2B44E': 17,
  '2B44F': 17,
  '2B450': 18,
  '2B451': 18,
  '2B452': 20,
  '2B453': 20,
  '2B454': 20,
  '2B455': 20,
  '2B456': 21,
  '2B457': 5,
  '2B458': 6,
  '2B459': 6,
  '2B45A': 7,
  '2B45B': 7,
  '2B45C': 8,
  '2B45D': 9,
  '2B45E': 9,
  '2B45F': 9,
  '2B460': 10,
  '2B461': 10,
  '2B462': 10,
  '2B463': 11,
  '2B464': 11,
  '2B465': 12,
  '2B466': 12,
  '2B467': 13,
  '2B468': 13,
  '2B469': 13,
  '2B46A': 15,
  '2B46B': 15,
  '2B46C': 16,
  '2B46D': 17,
  '2B46E': 18,
  '2B46F': 18,
  '2B470': 18,
  '2B471': 20,
  '2B472': 28,
  '2B473': 10,
  '2B474': 13,
  '2B475': 13,
  '2B476': 14,
  '2B477': 14,
  '2B478': 14,
  '2B479': 15,
  '2B47A': 17,
  '2B47B': 18,
  '2B47C': 19,
  '2B47D': 19,
  '2B47E': 21,
  '2B47F': 23,
  '2B480': 12,
  '2B481': 11,
  '2B482': 12,
  '2B483': 14,
  '2B484': 17,
  '2B485': 19,
  '2B486': 11,
  '2B487': 11,
  '2B488': 11,
  '2B489': 11,
  '2B48A': 12,
  '2B48B': 12,
  '2B48C': 12,
  '2B48D': 12,
  '2B48E': 12,
  '2B48F': 13,
  '2B490': 13,
  '2B491': 13,
  '2B492': 13,
  '2B493': 13,
  '2B494': 13,
  '2B495': 14,
  '2B496': 14,
  '2B497': 14,
  '2B498': 14,
  '2B499': 14,
  '2B49A': 14,
  '2B49B': 14,
  '2B49C': 14,
  '2B49D': 15,
  '2B49E': 15,
  '2B49F': 15,
  '2B4A0': 15,
  '2B4A1': 15,
  '2B4A2': 15,
  '2B4A3': 15,
  '2B4A4': 15,
  '2B4A5': 15,
  '2B4A6': 16,
  '2B4A7': 16,
  '2B4A8': 16,
  '2B4A9': 16,
  '2B4AA': 16,
  '2B4AB': 16,
  '2B4AC': 16,
  '2B4AD': 16,
  '2B4AE': 16,
  '2B4AF': 16,
  '2B4B0': 17,
  '2B4B1': 17,
  '2B4B2': 17,
  '2B4B3': 17,
  '2B4B4': 17,
  '2B4B5': 17,
  '2B4B6': 17,
  '2B4B7': 17,
  '2B4B8': 18,
  '2B4B9': 18,
  '2B4BA': 18,
  '2B4BB': 18,
  '2B4BC': 18,
  '2B4BD': 18,
  '2B4BE': 18,
  '2B4BF': 19,
  '2B4C0': 19,
  '2B4C1': 19,
  '2B4C2': 19,
  '2B4C3': 19,
  '2B4C4': 20,
  '2B4C5': 20,
  '2B4C6': 20,
  '2B4C7': 20,
  '2B4C8': 20,
  '2B4C9': 20,
  '2B4CA': 20,
  '2B4CB': 20,
  '2B4CC': 20,
  '2B4CD': 20,
  '2B4CE': 20,
  '2B4CF': 21,
  '2B4D0': 21,
  '2B4D1': 21,
  '2B4D2': 21,
  '2B4D3': 21,
  '2B4D4': 21,
  '2B4D5': 21,
  '2B4D6': 22,
  '2B4D7': 22,
  '2B4D8': 22,
  '2B4D9': 22,
  '2B4DA': 22,
  '2B4DB': 23,
  '2B4DC': 23,
  '2B4DD': 24,
  '2B4DE': 24,
  '2B4DF': 24,
  '2B4E0': 25,
  '2B4E1': 25,
  '2B4E2': 25,
  '2B4E3': 26,
  '2B4E4': 27,
  '2B4E5': 7,
  '2B4E6': 8,
  '2B4E7': 9,
  '2B4E8': 9,
  '2B4E9': 9,
  '2B4EA': 9,
  '2B4EB': 9,
  '2B4EC': 10,
  '2B4ED': 10,
  '2B4EE': 10,
  '2B4EF': 11,
  '2B4F0': 11,
  '2B4F1': 11,
  '2B4F2': 11,
  '2B4F3': 11,
  '2B4F4': 11,
  '2B4F5': 12,
  '2B4F6': 12,
  '2B4F7': 12,
  '2B4F8': 13,
  '2B4F9': 13,
  '2B4FA': 13,
  '2B4FB': 13,
  '2B4FC': 13,
  '2B4FD': 13,
  '2B4FE': 13,
  '2B4FF': 13,
  '2B500': 14,
  '2B501': 14,
  '2B502': 14,
  '2B503': 14,
  '2B504': 14,
  '2B505': 15,
  '2B506': 15,
  '2B507': 15,
  '2B508': 15,
  '2B509': 16,
  '2B50A': 16,
  '2B50B': 17,
  '2B50C': 17,
  '2B50D': 17,
  '2B50E': 17,
  '2B50F': 17,
  '2B510': 18,
  '2B511': 18,
  '2B512': 19,
  '2B513': 21,
  '2B514': 23,
  '2B515': 24,
  '2B516': 13,
  '2B517': 15,
  '2B518': 9,
  '2B519': 11,
  '2B51A': 12,
  '2B51B': 12,
  '2B51C': 13,
  '2B51D': 13,
  '2B51E': 13,
  '2B51F': 13,
  '2B520': 13,
  '2B521': 15,
  '2B522': 15,
  '2B523': 15,
  '2B524': 15,
  '2B525': 16,
  '2B526': 16,
  '2B527': 16,
  '2B528': 17,
  '2B529': 19,
  '2B52A': 21,
  '2B52B': 25,
  '2B52C': 6,
  '2B52D': 7,
  '2B52E': 7,
  '2B52F': 7,
  '2B530': 8,
  '2B531': 9,
  '2B532': 10,
  '2B533': 10,
  '2B534': 11,
  '2B535': 13,
  '2B536': 13,
  '2B537': 14,
  '2B538': 14,
  '2B539': 17,
  '2B53A': 5,
  '2B53B': 6,
  '2B53C': 7,
  '2B53D': 7,
  '2B53E': 8,
  '2B53F': 8,
  '2B540': 8,
  '2B541': 9,
  '2B542': 9,
  '2B543': 9,
  '2B544': 10,
  '2B545': 10,
  '2B546': 11,
  '2B547': 11,
  '2B548': 11,
  '2B549': 11,
  '2B54A': 11,
  '2B54B': 11,
  '2B54C': 11,
  '2B54D': 11,
  '2B54E': 12,
  '2B54F': 12,
  '2B550': 13,
  '2B551': 13,
  '2B552': 13,
  '2B553': 13,
  '2B554': 14,
  '2B555': 14,
  '2B556': 14,
  '2B557': 14,
  '2B558': 18,
  '2B559': 15,
  '2B55A': 12,
  '2B55B': 14,
  '2B55C': 18,
  '2B55D': 10,
  '2B55E': 11,
  '2B55F': 12,
  '2B560': 12,
  '2B561': 13,
  '2B562': 13,
  '2B563': 14,
  '2B564': 14,
  '2B565': 15,
  '2B566': 15,
  '2B567': 16,
  '2B568': 16,
  '2B569': 17,
  '2B56A': 17,
  '2B56B': 18,
  '2B56C': 19,
  '2B56D': 19,
  '2B56E': 20,
  '2B56F': 20,
  '2B570': 20,
  '2B571': 20,
  '2B572': 21,
  '2B573': 22,
  '2B574': 22,
  '2B575': 24,
  '2B576': 24,
  '2B577': 29,
  '2B578': 11,
  '2B579': 13,
  '2B57A': 13,
  '2B57B': 16,
  '2B57C': 19,
  '2B57D': 14,
  '2B57E': 21,
  '2B57F': 25,
  '2B580': 14,
  '2B581': 15,
  '2B582': 16,
  '2B583': 18,
  '2B584': 21,
  '2B585': 14,
  '2B586': 14,
  '2B587': 15,
  '2B588': 15,
  '2B589': 20,
  '2B58A': 21,
  '2B58B': 22,
  '2B58C': 10,
  '2B58D': 13,
  '2B58E': 17,
  '2B58F': 20,
  '2B590': 24,
  '2B591': 8,
  '2B592': 10,
  '2B593': 12,
  '2B594': 14,
  '2B595': 14,
  '2B596': 16,
  '2B597': 13,
  '2B598': 14,
  '2B599': 18,
  '2B59A': 20,
  '2B59B': 21,
  '2B59C': 25,
  '2B59D': 13,
  '2B59E': 14,
  '2B59F': 16,
  '2B5A0': 16,
  '2B5A1': 17,
  '2B5A2': 18,
  '2B5A3': 18,
  '2B5A4': 19,
  '2B5A5': 20,
  '2B5A6': 20,
  '2B5A7': 21,
  '2B5A8': 22,
  '2B5A9': 24,
  '2B5AA': 9,
  '2B5AB': 10,
  '2B5AC': 11,
  '2B5AD': 11,
  '2B5AE': 12,
  '2B5AF': 12,
  '2B5B0': 12,
  '2B5B1': 12,
  '2B5B2': 13,
  '2B5B3': 13,
  '2B5B4': 14,
  '2B5B5': 14,
  '2B5B6': 14,
  '2B5B7': 15,
  '2B5B8': 16,
  '2B5B9': 17,
  '2B5BA': 21,
  '2B5BB': 13,
  '2B5BC': 14,
  '2B5BD': 17,
  '2B5BE': 18,
  '2B5BF': 18,
  '2B5C0': 19,
  '2B5C1': 19,
  '2B5C2': 19,
  '2B5C3': 22,
  '2B5C4': 22,
  '2B5C5': 23,
  '2B5C6': 24,
  '2B5C7': 8,
  '2B5C8': 11,
  '2B5C9': 12,
  '2B5CA': 13,
  '2B5CB': 17,
  '2B5CC': 17,
  '2B5CD': 12,
  '2B5CE': 13,
  '2B5CF': 14,
  '2B5D0': 14,
  '2B5D1': 14,
  '2B5D2': 14,
  '2B5D3': 15,
  '2B5D4': 17,
  '2B5D5': 17,
  '2B5D6': 17,
  '2B5D7': 17,
  '2B5D8': 18,
  '2B5D9': 19,
  '2B5DA': 19,
  '2B5DB': 20,
  '2B5DC': 22,
  '2B5DD': 22,
  '2B5DE': 6,
  '2B5DF': 7,
  '2B5E0': 7,
  '2B5E1': 8,
  '2B5E2': 8,
  '2B5E3': 8,
  '2B5E4': 9,
  '2B5E5': 9,
  '2B5E6': 10,
  '2B5E7': 10,
  '2B5E8': 10,
  '2B5E9': 11,
  '2B5EA': 11,
  '2B5EB': 12,
  '2B5EC': 12,
  '2B5ED': 12,
  '2B5EE': 12,
  '2B5EF': 12,
  '2B5F0': 13,
  '2B5F1': 13,
  '2B5F2': 15,
  '2B5F3': 15,
  '2B5F4': 16,
  '2B5F5': 20,
  '2B5F6': 14,
  '2B5F7': 16,
  '2B5F8': 17,
  '2B5F9': 19,
  '2B5FA': 20,
  '2B5FB': 24,
  '2B5FC': 15,
  '2B5FD': 15,
  '2B5FE': 17,
  '2B5FF': 18,
  '2B600': 20,
  '2B601': 20,
  '2B602': 21,
  '2B603': 22,
  '2B604': 29,
  '2B605': 13,
  '2B606': 14,
  '2B607': 14,
  '2B608': 14,
  '2B609': 14,
  '2B60A': 16,
  '2B60B': 18,
  '2B60C': 18,
  '2B60D': 18,
  '2B60E': 18,
  '2B60F': 19,
  '2B610': 19,
  '2B611': 19,
  '2B612': 20,
  '2B613': 20,
  '2B614': 20,
  '2B615': 20,
  '2B616': 20,
  '2B617': 22,
  '2B618': 23,
  '2B619': 24,
  '2B61A': 27,
  '2B61B': 6,
  '2B61C': 7,
  '2B61D': 7,
  '2B61E': 8,
  '2B61F': 8,
  '2B620': 9,
  '2B621': 9,
  '2B622': 10,
  '2B623': 10,
  '2B624': 10,
  '2B625': 11,
  '2B626': 11,
  '2B627': 11,
  '2B628': 12,
  '2B629': 12,
  '2B62A': 13,
  '2B62B': 13,
  '2B62C': 13,
  '2B62D': 14,
  '2B62E': 14,
  '2B62F': 15,
  '2B630': 16,
  '2B631': 21,
  '2B632': 15,
  '2B633': 18,
  '2B634': 18,
  '2B635': 12,
  '2B636': 15,
  '2B637': 16,
  '2B638': 11,
  '2B639': 13,
  '2B63A': 13,
  '2B63B': 14,
  '2B63C': 15,
  '2B63D': 16,
  '2B63E': 17,
  '2B63F': 17,
  '2B640': 18,
  '2B641': 18,
  '2B642': 19,
  '2B643': 21,
  '2B644': 14,
  '2B645': 15,
  '2B646': 21,
  '2B647': 28,
  '2B648': 11,
  '2B649': 13,
  '2B64A': 15,
  '2B64B': 16,
  '2B64C': 17,
  '2B64D': 17,
  '2B64E': 21,
  '2B64F': 13,
  '2B650': 14,
  '2B651': 15,
  '2B652': 15,
  '2B653': 16,
  '2B654': 16,
  '2B655': 16,
  '2B656': 16,
  '2B657': 16,
  '2B658': 17,
  '2B659': 17,
  '2B65A': 17,
  '2B65B': 17,
  '2B65C': 17,
  '2B65D': 17,
  '2B65E': 17,
  '2B65F': 17,
  '2B660': 18,
  '2B661': 18,
  '2B662': 18,
  '2B663': 18,
  '2B664': 19,
  '2B665': 19,
  '2B666': 19,
  '2B667': 19,
  '2B668': 20,
  '2B669': 20,
  '2B66A': 20,
  '2B66B': 21,
  '2B66C': 21,
  '2B66D': 21,
  '2B66E': 21,
  '2B66F': 21,
  '2B670': 22,
  '2B671': 22,
  '2B672': 22,
  '2B673': 22,
  '2B674': 22,
  '2B675': 22,
  '2B676': 22,
  '2B677': 23,
  '2B678': 23,
  '2B679': 23,
  '2B67A': 23,
  '2B67B': 23,
  '2B67C': 23,
  '2B67D': 24,
  '2B67E': 24,
  '2B67F': 24,
  '2B680': 24,
  '2B681': 24,
  '2B682': 25,
  '2B683': 25,
  '2B684': 26,
  '2B685': 28,
  '2B686': 30,
  '2B687': 32,
  '2B688': 11,
  '2B689': 11,
  '2B68A': 11,
  '2B68B': 12,
  '2B68C': 12,
  '2B68D': 12,
  '2B68E': 13,
  '2B68F': 13,
  '2B690': 13,
  '2B691': 13,
  '2B692': 10,
  '2B693': 14,
  '2B694': 14,
  '2B695': 14,
  '2B696': 14,
  '2B697': 14,
  '2B698': 14,
  '2B699': 15,
  '2B69A': 15,
  '2B69B': 15,
  '2B69C': 16,
  '2B69D': 16,
  '2B69E': 16,
  '2B69F': 16,
  '2B6A0': 16,
  '2B6A1': 16,
  '2B6A2': 17,
  '2B6A3': 17,
  '2B6A4': 17,
  '2B6A5': 17,
  '2B6A6': 18,
  '2B6A7': 19,
  '2B6A8': 19,
  '2B6A9': 20,
  '2B6AA': 20,
  '2B6AB': 21,
  '2B6AC': 22,
  '2B6AD': 23,
  '2B6AE': 12,
  '2B6AF': 15,
  '2B6B0': 15,
  '2B6B1': 15,
  '2B6B2': 15,
  '2B6B3': 16,
  '2B6B4': 16,
  '2B6B5': 16,
  '2B6B6': 16,
  '2B6B7': 16,
  '2B6B8': 17,
  '2B6B9': 17,
  '2B6BA': 17,
  '2B6BB': 17,
  '2B6BC': 18,
  '2B6BD': 18,
  '2B6BE': 18,
  '2B6BF': 18,
  '2B6C0': 18,
  '2B6C1': 19,
  '2B6C2': 19,
  '2B6C3': 19,
  '2B6C4': 19,
  '2B6C5': 19,
  '2B6C6': 19,
  '2B6C7': 19,
  '2B6C8': 19,
  '2B6C9': 20,
  '2B6CA': 20,
  '2B6CB': 20,
  '2B6CC': 21,
  '2B6CD': 21,
  '2B6CE': 21,
  '2B6CF': 21,
  '2B6D0': 22,
  '2B6D1': 23,
  '2B6D2': 23,
  '2B6D3': 23,
  '2B6D4': 23,
  '2B6D5': 23,
  '2B6D6': 24,
  '2B6D7': 25,
  '2B6D8': 26,
  '2B6D9': 30,
  '2B6DA': 9,
  '2B6DB': 9,
  '2B6DC': 9,
  '2B6DD': 9,
  '2B6DE': 9,
  '2B6DF': 10,
  '2B6E0': 10,
  '2B6E1': 10,
  '2B6E2': 10,
  '2B6E3': 10,
  '2B6E4': 10,
  '2B6E5': 11,
  '2B6E6': 11,
  '2B6E7': 11,
  '2B6E8': 11,
  '2B6E9': 11,
  '2B6EA': 11,
  '2B6EB': 12,
  '2B6EC': 12,
  '2B6ED': 12,
  '2B6EE': 12,
  '2B6EF': 12,
  '2B6F0': 13,
  '2B6F1': 13,
  '2B6F2': 13,
  '2B6F3': 13,
  '2B6F4': 13,
  '2B6F5': 13,
  '2B6F6': 14,
  '2B6F7': 14,
  '2B6F8': 14,
  '2B6F9': 14,
  '2B6FA': 14,
  '2B6FB': 14,
  '2B6FC': 14,
  '2B6FD': 15,
  '2B6FE': 15,
  '2B6FF': 16,
  '2B700': 16,
  '2B701': 16,
  '2B702': 16,
  '2B703': 17,
  '2B704': 17,
  '2B705': 18,
  '2B706': 19,
  '2B707': 17,
  '2B708': 18,
  '2B709': 20,
  '2B70A': 16,
  '2B70B': 13,
  '2B70C': 15,
  '2B70D': 17,
  '2B70E': 18,
  '2B70F': 23,
  '2B710': 19,
  '2B711': 11,
  '2B712': 12,
  '2B713': 14,
  '2B714': 15,
  '2B715': 17,
  '2B716': 17,
  '2B717': 19,
  '2B718': 15,
  '2B719': 20,
  '2B71A': 20,
  '2B71B': 21,
  '2B71C': 16,
  '2B71D': 19,
  '2B71E': 25,
  '2B71F': 17,
  '2B720': 15,
  '2B721': 22,
  '2B722': 14,
  '2B723': 25,
  '2B724': 18,
  '2B725': 21,
  '2B726': 22,
  '2B727': 27,
  '2B728': 12,
  '2B729': 14,
  '2B72A': 14,
  '2B72B': 15,
  '2B72C': 16,
  '2B72D': 16,
  '2B72E': 17,
  '2B72F': 18,
  '2B730': 21,
  '2B731': 23,
  '2B732': 15,
  '2B733': 12,
  '2B734': 29,
  '2B740': 3,
  '2B741': 6,
  '2B742': 7,
  '2B743': 14,
  '2B744': 3,
  '2B745': 6,
  '2B746': 5,
  '2B747': 8,
  '2B748': 8,
  '2B749': 10,
  '2B74A': 11,
  '2B74B': 13,
  '2B74C': 9,
  '2B74D': 9,
  '2B74E': 9,
  '2B74F': 11,
  '2B750': 9,
  '2B751': 13,
  '2B752': 7,
  '2B753': 8,
  '2B754': 9,
  '2B755': 7,
  '2B756': 10,
  '2B757': 10,
  '2B758': 6,
  '2B759': 9,
  '2B75A': 14,
  '2B75B': 15,
  '2B75C': 17,
  '2B75D': 18,
  '2B75E': 22,
  '2B75F': 12,
  '2B760': 14,
  '2B761': 16,
  '2B762': 6,
  '2B763': 7,
  '2B764': 10,
  '2B765': 16,
  '2B766': 7,
  '2B767': 9,
  '2B768': 9,
  '2B769': 10,
  '2B76A': 10,
  '2B76B': 10,
  '2B76C': 12,
  '2B76D': 14,
  '2B76E': 15,
  '2B76F': 15,
  '2B770': 14,
  '2B771': 15,
  '2B772': 6,
  '2B773': 6,
  '2B774': 9,
  '2B775': 10,
  '2B776': 11,
  '2B777': 11,
  '2B778': 9,
  '2B779': 7,
  '2B77A': 8,
  '2B77B': 9,
  '2B77C': 12,
  '2B77D': 13,
  '2B77E': 14,
  '2B77F': 18,
  '2B780': 11,
  '2B781': 13,
  '2B782': 9,
  '2B783': 9,
  '2B784': 10,
  '2B785': 10,
  '2B786': 12,
  '2B787': 12,
  '2B788': 8,
  '2B789': 10,
  '2B78A': 10,
  '2B78B': 14,
  '2B78C': 14,
  '2B78D': 15,
  '2B78E': 15,
  '2B78F': 15,
  '2B790': 16,
  '2B791': 17,
  '2B792': 18,
  '2B793': 13,
  '2B794': 15,
  '2B795': 5,
  '2B796': 6,
  '2B797': 10,
  '2B798': 11,
  '2B799': 13,
  '2B79A': 13,
  '2B79B': 13,
  '2B79C': 15,
  '2B79D': 16,
  '2B79E': 19,
  '2B79F': 10,
  '2B7A0': 11,
  '2B7A1': 14,
  '2B7A2': 9,
  '2B7A3': 8,
  '2B7A4': 11,
  '2B7A5': 8,
  '2B7A6': 10,
  '2B7A7': 12,
  '2B7A8': 14,
  '2B7A9': 15,
  '2B7AA': 9,
  '2B7AB': 11,
  '2B7AC': 10,
  '2B7AD': 15,
  '2B7AE': 10,
  '2B7AF': 8,
  '2B7B0': 9,
  '2B7B1': 13,
  '2B7B2': 10,
  '2B7B3': 11,
  '2B7B4': 10,
  '2B7B5': 11,
  '2B7B6': 16,
  '2B7B7': 11,
  '2B7B8': 13,
  '2B7B9': 7,
  '2B7BA': 22,
  '2B7BB': 7,
  '2B7BC': 11,
  '2B7BD': 15,
  '2B7BE': 15,
  '2B7BF': 15,
  '2B7C0': 17,
  '2B7C1': 15,
  '2B7C2': 18,
  '2B7C3': 9,
  '2B7C4': 10,
  '2B7C5': 11,
  '2B7C6': 12,
  '2B7C7': 17,
  '2B7C8': 9,
  '2B7C9': 8,
  '2B7CA': 14,
  '2B7CB': 12,
  '2B7CC': 9,
  '2B7CD': 9,
  '2B7CE': 10,
  '2B7CF': 11,
  '2B7D0': 11,
  '2B7D1': 11,
  '2B7D2': 13,
  '2B7D3': 13,
  '2B7D4': 14,
  '2B7D5': 15,
  '2B7D6': 16,
  '2B7D7': 20,
  '2B7D8': 15,
  '2B7D9': 17,
  '2B7DA': 12,
  '2B7DB': 12,
  '2B7DC': 23,
  '2B7DD': 22,
  '2B7DE': 6,
  '2B7DF': 7,
  '2B7E0': 10,
  '2B7E1': 10,
  '2B7E2': 12,
  '2B7E3': 19,
  '2B7E4': 9,
  '2B7E5': 14,
  '2B7E6': 17,
  '2B7E7': 5,
  '2B7E8': 12,
  '2B7E9': 12,
  '2B7EA': 16,
  '2B7EB': 7,
  '2B7EC': 10,
  '2B7ED': 11,
  '2B7EE': 13,
  '2B7EF': 9,
  '2B7F0': 14,
  '2B7F1': 18,
  '2B7F2': 7,
  '2B7F3': 8,
  '2B7F4': 9,
  '2B7F5': 9,
  '2B7F6': 10,
  '2B7F7': 10,
  '2B7F8': 11,
  '2B7F9': 11,
  '2B7FA': 11,
  '2B7FB': 11,
  '2B7FC': 12,
  '2B7FD': 13,
  '2B7FE': 14,
  '2B7FF': 14,
  '2B800': 15,
  '2B801': 19,
  '2B802': 8,
  '2B803': 11,
  '2B804': 15,
  '2B805': 13,
  '2B806': 10,
  '2B807': 8,
  '2B808': 9,
  '2B809': 10,
  '2B80A': 8,
  '2B80B': 14,
  '2B80C': 16,
  '2B80D': 21,
  '2B80E': 24,
  '2B80F': 12,
  '2B810': 13,
  '2B811': 17,
  '2B812': 19,
  '2B813': 11,
  '2B814': 16,
  '2B815': 22,
  '2B816': 9,
  '2B817': 15,
  '2B818': 18,
  '2B819': 19,
  '2B81A': 13,
  '2B81B': 24,
  '2B81C': 16,
  '2B81D': 28,
  '2F9B3': 9,
  '2F9B4': 12,
  '2F9B5': 17,
  '2F9B6': 18,
}
