import {
  getFoodTypeAction,
  getListConfigsAction,
} from '@pages/system-user/redux/ManagaSystemUserAction'
import {AvailableStorageType, COMMON_STATUS, StorageType} from '@src/app/constants/constant'
import Select, {GroupBase} from 'react-select'
import makeAnimated from 'react-select/animated'
import {
  addTime,
  alertSuccess,
  clone,
  commonSortASC,
  formatDate,
  formatDateValidate,
  formatWithTypeDate,
  guidGenerator,
} from '@src/app/services/helper'
import {useSelector} from '@src/setup/redux/RootReducer'
import {useFormik} from 'formik'
import {useEffect, useMemo, useRef, useState} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'

import {useHistory} from 'react-router-dom'
import {Modal} from 'react-bootstrap-v5'
import {IModalSchedule} from '@pages/schedule/models/ScheduleModel'
import PopUpCancel from '@src/app/common/PopUpCancel/PopUpCancel'
import useModal from '@pages/system-user/hooks/useModal'
import ModalDelete from '@src/app/common/ModalDelte/ModalDelete'
import {deleteAdHoctItemAction, deleteFoodTypeItemAction} from '../redux/ManagaAdHocAction'
import moment from 'moment'

type Props = {
  mode: 'add' | 'delete' | 'edit' | 'cancel' | 'assign' | 'copy' | null
  data?: any
  onClose(): void
  OnhandleObjFoodtype?(data: any): void
  onDeleteFoodType?(data: any): void
  screen: 'schedule' | 'add-hoc' | 'beneficiaries' | null
  outToProgrammeId: any
}
// const phoneRegExp = /^[\(]{0,1}[\+]{0,1}[0-9]{2}[\)]{0,1}[ ]{0,1}[0-9]{0,4}[ ]{0,1}[0-9]{0,6}$/
const phoneRegExp = /^[0-9]{0,4}[ ]{0,1}[0-9]{0,4}$/

const SchemaFoodType = Yup.object().shape({
  foodTypeId: Yup.string().required('This field cannot be left blank'),
  quantityInUnitOfMeasure: Yup.number()
    .required('This field cannot be left blank')
    .min(1, 'Quantity must be greater than 0')
    .max(10000000, 'Max-Quantity up to 10000000'),
})

const PopUpAddEdit: React.FC<Props> = ({
  onClose,
  data,
  mode,
  OnhandleObjFoodtype,
  onDeleteFoodType,
  screen,
  outToProgrammeId,
}) => {
  const initialValues = {
    foodTypeId: data?.DonorFood?.foodTypeId ? data?.DonorFood?.foodTypeId : data?.foodTypeId,
    description: data?.description ?? '',
    quantityInUnitOfMeasure: data?.quantityInUnitOfMeasure ?? '',
    unitOfMeasure: data?.unitOfMeasure ?? '',
    quantityInKgs: data?.quantityInKgs ?? '',
    numberOfServing: data?.numberOfServing ?? '',
    expiryDate: data?.expiryDate ?? '',
    targetBeneficiaryGroup: data?.targetBeneficiaryGroup ?? '',
    programmeId: data?.programmeId ?? '',
    restrictionId: (data?.restrictionId || data?.restrictionTypeId) ?? '',
  }
  const dispatch = useDispatch()
  const history = useHistory()
  const [modalConfirmDelete, setModalConfirmDelete] = useState<any>({show: false})

  const animatedComponents = makeAnimated()
  const selectRef = useRef<any>()
  const [selectType, setSelectType] = useState([])
  const [loading, setLoading] = useState(false)
  const ListConfigs: any = useSelector((state) => state.systemUser.ListConfigs)
  const ListFoodType = useSelector((state) => state.systemUser.TypeClassList)
  const [nowDate, setNowDate] = useState<any>('')
  const [expiryDate, setExpiryDate] = useState<any>('')
  const [availableStorageType, setAvailableStorageType] = useState<any>([
    'Room Temperature',
    'Freezer',
    'Refrigerator',
  ])

  const [targetBeneficiaryGroup, setTargetBeneficiaryGroup] = useState<any>([])

  const {stateModal, toggleModal, offModal} = useModal()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: SchemaFoodType,
    onSubmit: (values) => {
      const value = {
        foodTypeId: values?.foodTypeId || '',
        restrictionId: values?.restrictionId || null,
        unitOfMeasure: values?.unitOfMeasure || '',
        quantityInUnitOfMeasure: values?.quantityInUnitOfMeasure || null,
        quantityInKgs: values?.quantityInKgs || null,
        numberOfServing: values?.numberOfServing || null,
        expiryDate: expiryDate ? expiryDate : nowDate,
        storageType: availableStorageType,
        programmeId: values?.programmeId || null,
        id: data?.id ? data?.id : null,
        idEdit: data?.foodTypeId ?? null,
        mode,
        targetBeneficiaryGroups: targetBeneficiaryGroup.map((el: any) => el?.id),
        description: values?.description || '',
        localId: new Date().getTime()
      }

      // console.log('value', value)
      // return
      if (OnhandleObjFoodtype && screen === 'add-hoc') {
        OnhandleObjFoodtype(value)
        onClose()
      }
    },
  })

  console.log('data food type', data)

  useEffect(() => {
    if (mode === 'add') formik.setFieldValue('programmeId', outToProgrammeId)
  }, [])

  const _delete = () => {
    if (mode === 'delete' && screen === null) {
      dispatch(
        deleteAdHoctItemAction({
          arrId: data,
          onSuccess: () => {
            onClose()
          },
          onError: () => {
            setLoading(false)
          },
        })
      )
    }

    if (mode === 'delete' && screen === 'add-hoc' && !data?.id) {
      if (onDeleteFoodType) onDeleteFoodType(data)
      onClose()
      alertSuccess('Delete successful')
    }

    if (mode === 'delete' && screen === 'add-hoc' && data?.id) {
      dispatch(
        deleteFoodTypeItemAction({
          id: data?.id,
          onSuccess: () => {
            if (onDeleteFoodType) onDeleteFoodType(data?.id!)
            onClose()
          },
          onError: () => {
            setLoading(false)
          },
        })
      )
    }
  }

  const [listRestricType, setListRestricType] = useState([])

  const RestricType = ListConfigs?.restrictionTypes?.map((type: any) => {
    return {
      ...type,
      id: type?.id,
      value: type?.name,
      label: type?.name,
    }
  })
  useEffect(() => {
    if (data?.restrictionTypeIds) {
      const newData = data?.restrictionTypeIds?.map((el: any) => {
        const arrRestricType = ListConfigs?.restrictionTypes?.find((x: any) => x.id === el)
        return {
          id: arrRestricType?.id,
          description: arrRestricType?.description,
          value: arrRestricType?.name,
          label: arrRestricType?.name,
          type: arrRestricType?.type,
        }
      })
      selectRef?.current?.setValue(newData)
    }
  }, [])

  const handleSelectType = (e: any) => {
    if (e) {
      const newData = e?.map((el: any) => {
        return el?.id
      })
      setSelectType(newData)
    }
  }

  const checkKeyCode = (e: any) => {
    if (
      !(
        (e.keyCode >= 48 && e.keyCode <= 57) ||
        (e.keyCode >= 96 && e.keyCode <= 105) ||
        e.keyCode == 8
      )
    ) {
      e.preventDefault()
    }
  }

  // Target beneficiaries group ..............
  const [listTargetBen, setListTargetBen] = useState<any>([])

  const addTargetBeneficiaryGroup = () => {
    const newData = listTargetBen.find((e: any) => e.id === formik.values.targetBeneficiaryGroup)
    if (newData) {
      setTargetBeneficiaryGroup((prev: any) => [...prev, newData])
    }

    formik.setFieldValue('targetBeneficiaryGroup', '')
  }

  // Mở Modal ....................
  const deleteItem = (data: any, index: number) => {
    const newData = [...targetBeneficiaryGroup]
    newData.splice(index, 1)
    setTargetBeneficiaryGroup(newData)
  }

  // Xóa................
  const onDelete = (index: number) => {
    const newData = [...targetBeneficiaryGroup]
    newData.splice(index, 1)
    setTargetBeneficiaryGroup(newData)
  }

  useEffect(() => {
    if (data?.targetBeneficiaryGroups) {
      const newData = data?.targetBeneficiaryGroups?.map((i: any) => {
        const item = ListConfigs?.targetBeneficiaryGroups?.find((x: any) => x.id === i)
        return item
      })
      setTargetBeneficiaryGroup(newData)
    }
  }, [data?.targetBeneficiaryGroups])

  useEffect(() => {
    if (data?.AdhocFoodTargetGroups) {
      const newData = data?.AdhocFoodTargetGroups?.map((i: any) => {
        const item = ListConfigs?.targetBeneficiaryGroups?.find(
          (x: any) => x.id === i?.targetGroupId
        )
        return item
      })
      setTargetBeneficiaryGroup(newData)
    }
  }, [data?.AdhocFoodTargetGroups])

  useEffect(() => {
    setListTargetBen(ListConfigs?.targetBeneficiaryGroups)
  }, [ListConfigs?.targetBeneficiaryGroups])

  // Stroage Type ........................
  const [storageType, setStorageType] = useState<any>(AvailableStorageType)

  const onChangeStorageType = (e: any) => {
    const index = storageType.findIndex((pp: any) => pp?.id === e.id)
    const newArr = clone(storageType)
    const newDay = {...e, isChecked: !e.isChecked}
    if (index !== undefined && index !== -1) {
      newArr.splice(index, 1, newDay)
    }
    setStorageType(newArr)
    const newstorageType = newArr?.filter((arr: any) => arr.isChecked === true)
    const StorageArr = newstorageType?.map((el: any) => {
      return el.value
    })
    setAvailableStorageType(StorageArr)
  }
  useEffect(() => {
    if (data?.storageType) {
      const newData = storageType?.map((element: any) => {
        return {
          ...element,
          isChecked: data?.storageType.includes(element.value) ? true : false,
        }
      })
      setStorageType(newData)
      setAvailableStorageType(data?.storageType)
    }
  }, [data?.storageType])

  useEffect(() => {
    dispatch(getListConfigsAction())
    dispatch(getFoodTypeAction())
  }, [dispatch])

  const onSaveYes = () => {
    history.goBack()
  }

  const offAllModal = () => {
    offModal()
    onClose()
  }

  // Validate ngày and giờ .............
  useEffect(() => {
    const dateToWeek: any = addTime(new Date().toUTCString(), 0)
    setNowDate(formatDateValidate(dateToWeek))
  }, [])

  const changeDate = (e: any) => {
    setExpiryDate(e.target.value)
  }

  const listTargetBenLeft = useMemo(() => {
    if (targetBeneficiaryGroup && listTargetBen) {
      const idTargetBenChosen = targetBeneficiaryGroup.map((e: any) => e?.id)
      return listTargetBen?.filter((e: any) => !idTargetBenChosen.includes(e?.id))
    }
  }, [targetBeneficiaryGroup, listTargetBen])

  useEffect(() => {
    if (formik.values.foodTypeId) {
      const foodtypeItem = ListFoodType.filter((d: any) => d?.id === formik.values.foodTypeId)
      if (foodtypeItem.length) {
        formik.setFieldValue('description', foodtypeItem[0]?.description)
      }
    }
  }, [formik.values.foodTypeId])

  useEffect(() => {
    if (data?.description) {
      formik.setFieldValue('description', data?.description)
    }

    if (data?.description === '') {
      formik.setFieldValue('description', '')
    }
  }, [data?.description])

  useEffect(() => {
    if (data?.expiryDate) setExpiryDate(moment(data?.expiryDate)?.format('YYYY-MM-DD'))
  }, [data?.expiryDate])

  return (
    <div className='card mb-5 mb-xl-10'>
      {mode !== 'delete' && mode !== 'cancel' ? (
        <>
          <div className='card-header border-0'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>
                {' '}
                {mode === 'add' ? 'Add new Food Type' : 'Edit Food Type'}
              </h3>

              <div
                style={{position: 'absolute', top: 25, right: 25, cursor: 'pointer'}}
                onClick={() => onClose()}
              >
                <i className='bi bi-x-lg'></i>
              </div>
            </div>
          </div>

          <div id='kt_account_profile_details' className='collapse show'>
            <form onSubmit={formik.handleSubmit} noValidate className='form'>
              <div className='card-body border-top border-bottom p-9'>
                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required fw-bold fs-6'>Food Type</label>

                  <div className='col-lg-6 fv-row'>
                    <select
                      className={`form-select form-select-solid form-select-lg fw-bold  ${
                        formik.touched.foodTypeId && formik.errors.foodTypeId ? 'border-danger' : ''
                      }`}
                      {...formik.getFieldProps('foodTypeId')}
                    >
                      <option value=''></option>
                      {ListFoodType &&
                        commonSortASC(ListFoodType,'name')?.filter((x: any) => x?.status === COMMON_STATUS.active)?.map(
                          (food:any, index:any) => {
                            return (
                              <option key={food?.id} value={food?.id}>
                                {food?.name}
                              </option>
                            )
                          }
                        )}
                    </select>
                    {formik.touched?.foodTypeId && formik.errors.foodTypeId && (
                      <div className='fv-plugins-message-container mt-2'>
                        <div className='fv-help-block text-danger'>{formik.errors.foodTypeId}</div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label fw-bold fs-6'>
                    Food Type Description
                  </label>

                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      className={`form-control form-control-lg form-control-solid ${
                        formik.touched.description && formik.errors.description
                          ? 'border-danger'
                          : ''
                      }`}
                      {...formik.getFieldProps('description')}
                    />
                    {formik.touched?.description && formik.errors.description && (
                      <div className='fv-plugins-message-container mt-2'>
                        <div className='fv-help-block text-danger'>{formik.errors.description}</div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required fw-bold fs-6'>Quantity</label>

                  <div className='col-lg-3 fv-row'>
                    <input
                      type='number'
                      min={1}
                      onKeyDown={(e: any) => checkKeyCode(e)}
                      className={`form-control form-control-lg form-control-solid ${
                        formik.touched.quantityInUnitOfMeasure &&
                        formik.errors.quantityInUnitOfMeasure
                          ? 'border-danger'
                          : ''
                      }`}
                      {...formik.getFieldProps('quantityInUnitOfMeasure')}
                    />
                    {formik.touched?.quantityInUnitOfMeasure &&
                      formik.errors.quantityInUnitOfMeasure && (
                        <div className='fv-plugins-message-container mt-2'>
                          <div className='fv-help-block text-danger'>
                            {formik.errors.quantityInUnitOfMeasure}
                          </div>
                        </div>
                      )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label fw-bold fs-6'>Unit of Measure</label>

                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      maxLength={255}
                      className={`form-control form-control-lg form-control-solid  ${
                        formik.touched.unitOfMeasure && formik.errors.unitOfMeasure
                          ? 'border-danger'
                          : ''
                      }`}
                      {...formik.getFieldProps('unitOfMeasure')}
                    />
                    {formik.touched?.unitOfMeasure && formik.errors.unitOfMeasure && (
                      <div className='fv-plugins-message-container mt-2'>
                        <div className='fv-help-block text-danger'>
                          {formik.errors.unitOfMeasure}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label fw-bold fs-6'>Weight in Kgs</label>

                  <div className='col-lg-3 fv-row'>
                    <input
                      type='number'
                      min={1}
                      onKeyDown={(e: any) => checkKeyCode(e)}
                      className={`form-control form-control-lg form-control-solid ${
                        formik.touched.quantityInKgs && formik.errors.quantityInKgs
                          ? 'border-danger'
                          : ''
                      }`}
                      {...formik.getFieldProps('quantityInKgs')}
                    />
                    {formik.touched?.quantityInKgs && formik.errors.quantityInKgs && (
                      <div className='fv-plugins-message-container mt-2'>
                        <div className='fv-help-block text-danger'>
                          {formik.errors.quantityInKgs}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label fw-bold fs-6'>Servings</label>

                  <div className='col-lg-3 fv-row'>
                    <input
                      type='number'
                      min={1}
                      onKeyDown={(e: any) => checkKeyCode(e)}
                      className={`form-control form-control-lg form-control-solid  ${
                        formik.touched.numberOfServing && formik.errors.numberOfServing
                          ? 'border-danger'
                          : ''
                      }`}
                      {...formik.getFieldProps('numberOfServing')}
                    />
                    {formik.touched?.numberOfServing && formik.errors.numberOfServing && (
                      <div className='fv-plugins-message-container mt-2'>
                        <div className='fv-help-block text-danger'>
                          {formik.errors.numberOfServing}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required  fw-bold fs-6'>
                    Expiry Date
                  </label>

                  <div className='col-lg-3 fv-row'>
                    <input
                      type='date'
                      defaultValue={expiryDate || nowDate}
                      min={nowDate}
                      className={`form-control form-control-lg form-control-solid  ${
                        formik.touched.expiryDate && formik.errors.expiryDate ? 'border-danger' : ''
                      }`}
                      onChange={changeDate}
                      // onKeyDown={(e) => e.preventDefault()}
                      // {...formik.getFieldProps('expiryDate')}
                    />
                    {formik.touched?.expiryDate && formik.errors.expiryDate && (
                      <div className='fv-plugins-message-container mt-2'>
                        <div className='fv-help-block text-danger'>{formik.errors.expiryDate}</div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mt-5'>
                  <label className='col-lg-3 col-form-labelfw-bold fs-6'></label>

                  <div className='col-lg-3 fv-row'>
                    {targetBeneficiaryGroup.map((el: any, index: number) => {
                      return (
                        <div className='mb-3' key={index}>
                          <div>
                            {el?.name}{' '}
                            <i
                              className='bi bi-trash-fill fs-3 mt-2'
                              onClick={() => {
                                deleteItem(el, index)
                              }}
                              style={{cursor: 'pointer'}}
                            ></i>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label  fw-bold fs-6'>
                    Target Beneficiary Group
                  </label>

                  <div className='col-lg-6 fv-row'>
                    <div>
                      <select
                        className={`form-select form-select-solid form-select-lg fw-bold mt-2 ${
                          formik.touched.targetBeneficiaryGroup &&
                          formik.errors.targetBeneficiaryGroup
                            ? 'border-danger'
                            : ''
                        }`}
                        {...formik.getFieldProps('targetBeneficiaryGroup')}
                      >
                        <option value=''></option>
                        {commonSortASC(listTargetBenLeft,'name')?.map((el: any, index: number) => {
                          return (
                            <option key={el?.id} value={el?.id}>
                              {el?.name}
                            </option>
                          )
                        })}
                        {formik.touched?.targetBeneficiaryGroup &&
                          formik.errors.targetBeneficiaryGroup && (
                            <div className='fv-plugins-message-container mt-2'>
                              <div className='fv-help-block text-danger'>
                                {formik.errors.targetBeneficiaryGroup}
                              </div>
                            </div>
                          )}
                      </select>
                    </div>
                    <div className='col-lg-12 fv-row d-flex justify-content-end'>
                      <button
                        type='button'
                        disabled={formik.values.targetBeneficiaryGroup ? false : true}
                        className='btn btn-primary btn-sm mt-2 ms-3'
                        style={{width: 65, height: 35}}
                        onClick={addTargetBeneficiaryGroup}
                      >
                        <i className='bi bi-plus-lg pe-2 mb-1'></i>
                      </button>
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label fw-bold fs-6'>Restriction Type</label>
                  <div className='col-lg-6 fv-row'>
                    <div>
                      <select
                        className={`form-select form-select-solid form-select-lg fw-bold mt-2`}
                        {...formik.getFieldProps('restrictionId')}
                      >
                        <option value=''></option>
                        {commonSortASC(ListConfigs?.restrictionTypes,'name').map((el: any, index: number) => {
                          return (
                            <option key={el?.id} value={el?.id}>
                              {el?.name}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label fw-bold fs-6'>Storage Type</label>

                  <div className='col-lg-6 fv-row'>
                    {storageType.map((el: any, index: number) => {
                      return (
                        <div className='mt-2'>
                          <input
                            type='checkbox'
                            className='form-check-input me-3'
                            onClick={() => onChangeStorageType(el)}
                            value={el.value}
                            checked={el.isChecked}
                          />
                          <span>{el.label}</span>
                        </div>
                      )
                    })}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label  fw-bold fs-6'>Programme</label>

                  <div className='col-lg-6 fv-row'>
                    <div>
                      <select
                        className={`form-select form-select-solid form-select-lg fw-bold mt-2 ${
                          formik.touched.programmeId && formik.errors.programmeId
                            ? 'border-danger'
                            : ''
                        }`}
                        {...formik.getFieldProps('programmeId')}
                      >
                        <option value=''></option>
                        {ListConfigs?.programmes &&
                          commonSortASC(ListConfigs?.programmes,'name')?.map((el: any, index: number) => {
                            return (
                              <option key={el?.id} value={el?.id}>
                                {el?.name}
                              </option>
                            )
                          })}
                        {formik.touched?.programmeId && formik.errors.programmeId && (
                          <div className='fv-plugins-message-container mt-2'>
                            <div className='fv-help-block text-danger'>
                              {formik.errors.programmeId}
                            </div>
                          </div>
                        )}
                      </select>
                    </div>
                  </div>
                </div>

                {data?.scheduleFoods?.map((food: any) => {
                  return (
                    <>
                      <div className='row'>
                        <label className='col-lg-3 col-form-label  fw-bold fs-6'>
                          Beneficiary Name
                        </label>

                        <div className='col-lg-6 fv-row mt-3'>
                          {food?.beneficiaryNameCN || food?.beneficiaryNameEN}
                        </div>
                      </div>

                      <div className='row mb-6'>
                        <label className='col-lg-3 col-form-label  fw-bold fs-6'>
                          Beneficiary Quanity
                        </label>

                        <div className='col-lg-6 fv-row mt-3'>{food?.quantity}</div>
                      </div>
                    </>
                  )
                })}
              </div>

              <div className='card-footer border-0 d-sm-flex justify-content-center p-sm-0 py-sm-6 px-sm-9'>
                <button
                  type='submit'
                  className='btn btn-success p-3 mx-sm-4'
                  // onClick={handleClickSubmit}
                  disabled={loading}
                >
                  {loading ? (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please Wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  ) : (
                    <>
                      <i className='far fa-save'></i> SAVE
                    </>
                  )}
                </button>

                <div
                  className='btn btn-danger p-3'
                  onClick={() => {
                    toggleModal(true, 'cancel', {})()
                    const parentModal = document.getElementsByClassName('parent-modal')
                    if (parentModal && parentModal.length) {
                      parentModal[0].classList.add('hidden')
                    }
                  }}
                >
                  <i className='fas fa-sign-out-alt'></i>
                  CANCEL
                </div>
              </div>
            </form>
          </div>
        </>
      ) : (
        <>
          {mode === 'delete' ? (
            <>
              <div className='card-header justify-content-center'>
                <div className='card-title m-0'>
                  <h3 className='fw-bolder m-0'>Do you want to delete ?</h3>
                </div>
              </div>
              <div className='card-footer border-0 d-sm-flex justify-content-end p-sm-0 py-sm-6 px-sm-9'>
                <button type='submit' className='btn btn-danger p-3 mx-sm-4' onClick={_delete}>
                  <i className='fa fa-trash'></i>
                  Delete
                </button>

                <div className='btn btn-secondary p-3 mx-sm-4' onClick={() => onClose()}>
                  <i className='fas fa-sign-out-alt'></i>CANCEL
                </div>
              </div>
            </>
          ) : (
            <>
              <div className='card-header justify-content-center'>
                <div className='card-title m-0'>
                  <h3 className='fw-bolder m-0'>You haven't saved. Do you want to exit ?</h3>
                </div>
              </div>
              <div className='card-footer border-0 d-sm-flex justify-content-end p-sm-0 py-sm-6 px-sm-9'>
                <button
                  type='submit'
                  className='btn btn-success p-3 mx-sm-4'
                  onClick={() => onSaveYes()}
                >
                  {loading ? (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please Wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  ) : (
                    <>YES</>
                  )}
                </button>

                <button
                  type='submit'
                  className='btn btn-danger p-3 mx-sm-4'
                  onClick={() => onClose()}
                >
                  NO
                </button>
              </div>
            </>
          )}
        </>
      )}

      <Modal
        dialogClassName='modal-dialog-centered mw-1000px h-auto child-modal'
        aria-hidden='true'
        show={modalConfirmDelete.show}
        onHide={() => {
          setModalConfirmDelete({...modalConfirmDelete, show: false})
        }}
      >
        <div className='modal-content'>
          <ModalDelete
            onDelete={onDelete}
            onClose={() => {
              setModalConfirmDelete({...modalConfirmDelete, show: false})
            }}
            data={modalConfirmDelete.data}
            index={modalConfirmDelete.index}
          />
        </div>
      </Modal>

      <Modal
        dialogClassName='modal-dialog-centered mw-1000px h-auto child-modal'
        aria-hidden='true'
        show={stateModal.open}
      >
        <div className='modal-content'>
          <PopUpCancel
            onClose={offModal}
            offAllModal={offAllModal}
            mode={stateModal.typeModal}
            data={stateModal.data}
          />
        </div>
      </Modal>
    </div>
  )
}
export default PopUpAddEdit
