import {IBeneficiaries} from '@pages/beneficiaries/models/Beneficiaries'
import {IBreadProgram, IExternalBreadProgram, IDatePayload} from '../models/BreadProgramModel'

export enum EBreadProgramType {
  GET_LIST_BREAD_PROGRAM = '[manage BreadProgram] GET_LIST_BREAD_PROGRAM',
  SAVE_BREAD_PROGRAM_RD = '[manage BreadProgram] SAVE_BREAD_PROGRAM_RD',
  GET_ITEM_BREAD_PROGRAM = '[manage BreadProgram] GET_ITEM_BREAD_PROGRAM',
  ADD_BREAD_PROGRAM = '[manage BreadProgram] ADD_BREAD_PROGRAM',
  GET_EXTERNAL_BREAD_PROGRAM = '[manage BreadProgram] GET_EXTERNAL_BREAD_PROGRAM',
  GET_EXTERNAL_BREAD_PROGRAM_SUCCESS = '[manage BreadProgram] GET_EXTERNAL_BREAD_PROGRAM_SUCCESS',
  SET_BREAD_PROGRAM_STORE = '[manage BreadProgram] SET_BREAD_PROGRAM_STORE',
}

export const setBreadProgramStoreAction = (payload: any) => ({
  type: EBreadProgramType.SET_BREAD_PROGRAM_STORE,
  payload,
})

export const getListBreadProgramAction = () => ({
  type: EBreadProgramType.GET_LIST_BREAD_PROGRAM,
})

export const saveListBreadProgramAction = (payload: IBreadProgram[]) => ({
  type: EBreadProgramType.SAVE_BREAD_PROGRAM_RD,
  payload,
})

// export const getItemBreadProgramAction = (payload: any) => ({
//   type: EBreadProgramType.GET_ITEM_BREAD_PROGRAM,
//   payload,
// })

export const addNewBreadProgramAction = (payload: {
  breadProgramId: string
  dates: IDatePayload[]
  onSuccess?: any
  onError?: any
}) => ({
  type: EBreadProgramType.ADD_BREAD_PROGRAM,
  payload,
})

// export const editBreadProgramAction = (payload: {value: any; onSuccess: any; onError: any}) => ({
//   type: EBreadProgramType.EDIT_BREAD_PROGRAM,
//   payload,
// })

// export const deleteBreadProgramAction = (payload: {id: any; onSuccess: any; onError: any}) => ({
//   type: EBreadProgramType.DELETE_BREAD_PROGRAM,
//   payload,
// })

export const getExternalBreadProgramAction = (payload: {
  beneficiaryId: string
  donorBranchId: string
}) => ({
  type: EBreadProgramType.GET_EXTERNAL_BREAD_PROGRAM,
  payload,
})

export const getScheduleSuccessAction = (payload?: IExternalBreadProgram) => ({
  type: EBreadProgramType.GET_EXTERNAL_BREAD_PROGRAM_SUCCESS,
  payload,
})
