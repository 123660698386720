import {useEffect, useMemo} from 'react'
import {useDispatch} from 'react-redux'

import {IBeneficiaries} from '@pages/beneficiaries/models/Beneficiaries'
import {getBeneficiariesAction} from '@pages/beneficiaries/redux/ManagaBeneficiariesAction'
import {ADD_BREAD_PROGRAM_STEPS} from '@src/app/constants/constant'
import {commonSortI18nASC} from '@src/app/services/helper'
import {useSelector} from '@src/setup/redux/RootReducer'
import {setBreadProgramStoreAction} from '../redux/ManagaBreadProgramActions'

interface IProps {
  setStep: (value: number) => void
}

const SelectBeneficiary = ({setStep}: IProps) => {
  const dispatch = useDispatch()
  const listBeneficiaries = useSelector((state) => state.BeneficiariesManage.listBeneficiaries)
  const selectedBeneficiary = useSelector((state) => state.BreadProgramManage.selectedBeneficiary)

  const ListBeneficiariesActive: IBeneficiaries[] = useMemo(() => {
    return commonSortI18nASC(
      listBeneficiaries.filter((item) => item.status === 'Active' && item.breadProgram),
      'nameEN',
      'nameCN'
    )
  }, [listBeneficiaries])

  const selectBeneficiary = (e: any) => {
    const beneficiarySelected = ListBeneficiariesActive.find(
      (beneficiary) => beneficiary.id === e.target.value
    )
    if (beneficiarySelected) {
      dispatch(
        setBreadProgramStoreAction({
          selectedBeneficiary: beneficiarySelected,
        })
      )
    }
  }

  const handleContinue = () => {
    setStep(ADD_BREAD_PROGRAM_STEPS.CHOOSE_DONOR)
  }

  useEffect(() => {
    if (!listBeneficiaries.length) dispatch(getBeneficiariesAction())
  }, [])

  useEffect(() => {
    if (!selectedBeneficiary) {
      dispatch(
        setBreadProgramStoreAction({
          selectedBeneficiary: ListBeneficiariesActive[0],
        })
      )
    }
  }, [ListBeneficiariesActive, selectedBeneficiary])

  return (
    <div className='card'>
      <div className='card-body py-8'>
        <h3 className='card-title fs-1 mb-10'>BREAD PROGRAM / 麵包回收計劃</h3>

        <div className='row mb-6'>
          <label className='col-lg-3 col-form-label fw-bold fs-6'>
            Select Beneficiary / 選擇受惠機構
          </label>
          <div className='col-lg-9 fv-row'>
            <select
              defaultValue={selectedBeneficiary?.id}
              onChange={selectBeneficiary}
              className={`form-select form-select-solid form-select-lg fw-bold`}
            >
              {commonSortI18nASC(ListBeneficiariesActive, 'nameEN', 'nameCN')?.map(
                (item: IBeneficiaries) => (
                  <option key={item.id} value={item.id}>
                    {item?.nameCN || item?.nameEN}
                  </option>
                )
              )}
            </select>
          </div>
        </div>

        <button
          type='button'
          className='btn btn-primary py-2 px-4 rounded-1'
          onClick={handleContinue}
        >
          CONTINUE / 繼續
        </button>
      </div>
    </div>
  )
}

export default SelectBeneficiary
