import {IModalSchedule} from '@pages/schedule/models/ScheduleModel'
import React, {useState} from 'react'
import {Modal} from 'react-bootstrap-v5'

function ModalDelete(props: any) {
  const {onDelete, onClose, onDeleteItem, foodId, food} = props
  return (
    <>
      <div className='card-header justify-content-center'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0' style={{textAlign: 'center'}}>
            Do you want to delete ?
          </h3>
        </div>
      </div>
      <div className='card-footer border-0 d-sm-flex justify-content-end p-sm-0 py-sm-6 px-sm-9'>
        <div
          className='btn btn-danger p-3 mx-sm-4'
          onClick={() => {
            onDeleteItem(onDelete, foodId, food)
            onClose()
            const parentModal = document.getElementsByClassName('parent-modal')
            if (parentModal && parentModal.length) {
              parentModal[0].classList.remove('hidden')
            }
          }}
        >
          <i className='fa fa-trash'></i>
          Delete
        </div>

        <div
          className='btn btn-secondary p-3 mx-sm-4'
          onClick={() => {
            onClose()
            const parentModal = document.getElementsByClassName('parent-modal')
            if (parentModal && parentModal.length) {
              parentModal[0].classList.remove('hidden')
            }
          }}
        >
          <i className='fas fa-sign-out-alt'></i>CANCEL
        </div>
      </div>
    </>
  )
}

export default ModalDelete
