import {useFormik} from 'formik'
import moment from 'moment'
import {useEffect, useMemo} from 'react'
import DatePicker from 'react-datepicker'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'

import {KTSVG} from '@metronic/helpers'
import {ADD_BREAD_PROGRAM_STEPS, DATE_TYPE} from '@src/app/constants/constant'
import {commonSortASC} from '@src/app/services/helper'
import {useSelector} from '@src/setup/redux/RootReducer'
import {IBreadProgram} from '../models/BreadProgramModel'
import {
  getListBreadProgramAction,
  setBreadProgramStoreAction,
} from '../redux/ManagaBreadProgramActions'
import {ScrollTopComponent} from '@metronic/assets/ts/components'

const validationSchema = Yup.object().shape({
  donorId: Yup.string().required('Please select a Donor Branch / 請選擇取包店舖'),
  month: Yup.string().required('Please select a month / 請選擇月份'),
})

interface IProps {
  setStep: (value: number) => void
}

const ChooseDonorBranch = ({setStep}: IProps) => {
  const dispatch = useDispatch()
  // const listDonorBranches = useSelector((state) => state.BreadProgramManage.listBreadProgram)
  const {selectedBeneficiary, selectedDonorBranch, selectedDate} = useSelector(
    (state) => state.BreadProgramManage
  )

  const listDonorBranchesSorted = useMemo(() => {
    const newListDonorBranches = selectedBeneficiary?.breads.map((el) => el.donorBranch) || []
    return commonSortASC(newListDonorBranches, 'code')
  }, [selectedBeneficiary])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      donorId: listDonorBranchesSorted[0]?.id,
      month: selectedDate,
    },
    validationSchema,
    onSubmit: ({donorId, month}) => {
      const donorSelected = listDonorBranchesSorted.find((donor) => donor.id === donorId)
      dispatch(
        setBreadProgramStoreAction({
          selectedDonorBranch: donorSelected,
          selectedDate: month,
        })
      )
      setStep(ADD_BREAD_PROGRAM_STEPS.FORM)
    },
  })

  const goBack = () => {
    setStep(ADD_BREAD_PROGRAM_STEPS.CHOOSE_BENEFICIARY)
  }

  const getLimitedDate = (dateType: DATE_TYPE) => {
    return dateType === DATE_TYPE.MIN_DATE ? new Date(2022, 9, 1) : new Date()
  }

  // useEffect(() => {
  //   if (!listDonorBranches.length) dispatch(getListBreadProgramAction())
  // }, [listDonorBranches])

  useEffect(() => {
    if (!selectedDonorBranch)
      dispatch(
        setBreadProgramStoreAction({
          selectedDonorBranch: listDonorBranchesSorted[0],
        })
      )
  }, [selectedDonorBranch, listDonorBranchesSorted])

  useEffect(() => {
    ScrollTopComponent.goTop()
  }, [])

  return (
    <div className='card'>
      <div className='card-body py-8'>
        <u role='button' onClick={goBack}>
          <KTSVG path='/media/icons/duotune/arrows/arr074.svg' />
          Back / 返回
        </u>
        <h3 className='card-title fs-1 mt-0 mb-10'>BREAD PROGRAM / 麵包回收計劃</h3>

        <div className='row mb-6 align-items-center justify-content-end'>
          <h2 className='col-lg-9 mb-0 fv-row'>
            {selectedBeneficiary?.nameEN || selectedBeneficiary?.nameCN}
          </h2>
        </div>

        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='row mb-6'>
            <label className='col-lg-3 col-form-label required fw-bold fs-6'>
              Select Donor Branch / 選擇取包店舖
            </label>

            <div className='col-lg-9 fv-row'>
              <select
                className={`form-select form-select-solid form-select-lg fw-bold ${
                  formik.touched.donorId && formik.errors.donorId ? 'border-danger' : ''
                }`}
                {...formik.getFieldProps('donorId')}
              >
                {listDonorBranchesSorted?.map((donor: IBreadProgram) => (
                  <option key={donor.id} value={donor.id}>
                    {donor.name}
                  </option>
                ))}
              </select>
              {listDonorBranchesSorted.length > 0 && formik.touched.donorId && formik.errors.donorId && (
                <div className='fv-plugins-message-container mt-2'>
                  <div className='fv-help-block text-danger'>{formik.errors.donorId}</div>
                </div>
              )}
              {!listDonorBranchesSorted.length && (
                <div className='fv-plugins-message-container mt-7'>
                  <div className='fv-help-block text-danger'>No Active-pair donor branches</div>
                </div>
              )}
            </div>
          </div>

          <div className='row mb-6'>
            <label className='col-lg-3 col-form-label required fw-bold fs-6'>
              Select Month / 選擇月份
            </label>
            <div className='col-lg-9 fv-row'>
              <DatePicker
                selected={formik.values.month ? new Date(formik.values.month) : undefined}
                showPopperArrow={false}
                onChange={(date) =>
                  formik.setFieldValue('month', date ? moment(date).format('yyyy-MM-DD') : '')
                }
                showMonthYearPicker
                minDate={getLimitedDate(DATE_TYPE.MIN_DATE)}
                maxDate={getLimitedDate(DATE_TYPE.MAX_DATE)}
                dateFormat='MM/yyyy'
                className={`form-control form-control-lg form-control-solid ${
                  formik.touched.month && formik.errors.month ? 'border-danger' : ''
                }`}
              />
              {formik.touched.month && formik.errors.month && (
                <div className='fv-plugins-message-container mt-2'>
                  <div className='fv-help-block text-danger'>{formik.errors.month}</div>
                </div>
              )}
            </div>
          </div>

          <button type='submit' className='btn btn-primary py-2 px-4 rounded-1 w-auto'>
            CONTINUE / 繼續
          </button>
        </form>
      </div>
    </div>
  )
}

export default ChooseDonorBranch
