import {createSelectorHook} from 'react-redux'
import {combineReducers} from 'redux'
import {all, fork} from 'redux-saga/effects'

import authReducer, {initialAuthState as authState} from '@modules/auth/redux/AuthRedux'
import authSaga from '@modules/auth/redux/AuthSaga'
import ManagaAdHocReducer, {
  defaultState as ManageAdHocState,
} from '@pages/ad-hoc/redux/ManagaAdHocReducer'
import manageAdHocSaga from '@pages/ad-hoc/redux/ManagaAdHocSaga'
import ManagaBeneficiariesReducer, {
  defaultState as ManageBeneficiariesState,
} from '@pages/beneficiaries/redux/ManagaBeneficiariesReducer'
import ManagaBeneficiariesSaga from '@pages/beneficiaries/redux/ManagaBeneficiariesSaga'
import ManageBreadProgramReducer, {
  defaultState as ManageBreadProgramState,
} from '@pages/bread-program/redux/ManagaBreadProgramReducer'
import manageBreadProgramSaga from '@pages/bread-program/redux/ManagaBreadProgramSaga'
import ManagaContactReducer, {
  defaultState as ManageContactState,
} from '@pages/contact-management/redux/ManagaContactReducer'
import manageContactSaga from '@pages/contact-management/redux/ManagaContactSaga'
import ManagaDonorReducer, {
  defaultState as ManageDonorState,
} from '@pages/donors/redux/ManagaDonorReducer'
import manageDonorSaga from '@pages/donors/redux/ManagaDonorSaga'
import ManagaScheduleReducer, {
  defaultState as ManageScheduleState,
} from '@pages/schedule/redux/ManagaScheduleReducer'
import manageScheduleSaga from '@pages/schedule/redux/ManagaScheduleSaga'
import ManagaSystemUserReducer, {
  defaultState as ManageSystemUserState,
} from '@pages/system-user/redux/ManagaSystemUserReducer'
import manageSystemUserSaga from '@pages/system-user/redux/ManagaSystemUserSaga'
import ManagaVanReducer, {defaultState as ManageVanState} from '@pages/van/redux/ManagaVanReducer'
import manageSaga from '@pages/van/redux/ManagaVanSaga'

export const rootReducer = combineReducers({
  auth: authReducer,
  systemUser: ManagaSystemUserReducer,
  Van: ManagaVanReducer,
  ContactManage: ManagaContactReducer,
  DonorManage: ManagaDonorReducer,
  BeneficiariesManage: ManagaBeneficiariesReducer,
  ScheduleManage: ManagaScheduleReducer,
  AdHocManage: ManagaAdHocReducer,
  BreadProgramManage: ManageBreadProgramReducer,
})

export const useSelector = createSelectorHook<{
  auth: typeof authState
  systemUser: typeof ManageSystemUserState
  Van: typeof ManageVanState
  ContactManage: typeof ManageContactState
  DonorManage: typeof ManageDonorState
  BeneficiariesManage: typeof ManageBeneficiariesState
  ScheduleManage: typeof ManageScheduleState
  AdHocManage: typeof ManageAdHocState
  BreadProgramManage: typeof ManageBreadProgramState
}>()

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(manageSystemUserSaga),
    fork(manageSaga),
    fork(manageContactSaga),
    fork(manageDonorSaga),
    fork(ManagaBeneficiariesSaga),
    fork(manageScheduleSaga),
    fork(manageAdHocSaga),
    fork(manageBreadProgramSaga),
  ])
}
