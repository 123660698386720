import produce from 'immer'
import {clone} from '../../../services/helper'
import {IBeneficiaries} from '../models/Beneficiaries'
import {EManageBeneficiariesType} from './ManagaBeneficiariesAction'

interface IState {
  listBeneficiaries: IBeneficiaries[]
}

export const defaultState: IState = {
  listBeneficiaries: [],
}

const ManagaBeneficiariesReducer = (state = defaultState, action: any) => {
  return produce(state, (draft) => {
    switch (action.type) {
      // ======== Beneficiaries REDUCER =========== //
      case EManageBeneficiariesType.SAVE_BNF_RD: {
        draft.listBeneficiaries = action.payload
        break
      }

      case EManageBeneficiariesType.ADD_LIST_BNF: {
        const newBeneficiaries = [...[action.payload], ...state.listBeneficiaries]
        draft.listBeneficiaries = newBeneficiaries
        break
      }

      case EManageBeneficiariesType.EDIT_ITEM_BNF_RD: {
        const index = state.listBeneficiaries.findIndex((pp) => pp.id === action.payload.id)
        const newArr = clone(state.listBeneficiaries)
        if (index !== undefined && index !== -1) {
          newArr.splice(index, 1, action.payload)
        }
        draft.listBeneficiaries = newArr
        break
      }

      case EManageBeneficiariesType.DELETE_ITEM_BNF_RD: {
        const newBeneficiaries = state.listBeneficiaries.filter((pp) => pp.id !== action.payload)
        draft.listBeneficiaries = newBeneficiaries
        break
      }

      case EManageBeneficiariesType.FILTER_SUCCESS_BNF: {
        if (action.payload) draft.listBeneficiaries = action.payload
        break
      }

      case EManageBeneficiariesType.SORT_SUCCESS_BNF: {
        if (action.payload?.data) draft.listBeneficiaries = action.payload?.data
        break
      }

      default:
        break
    }
  })
}
export default ManagaBeneficiariesReducer
