import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import MainAdHoc from '@pages/ad-hoc'
import MainBreadProgram from '@pages/bread-program'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'

export function PublicRoutes() {
  return (
    <MasterLayout>
      <Switch>
        <Route path='/bread-program' component={MainBreadProgram} />
        <Route path='/ad-hoc' component={MainAdHoc} />
        <Redirect to='/error/404' />
      </Switch>
    </MasterLayout>
  )
}
