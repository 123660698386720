import {IUser} from '@pages/system-user/models/UserModel'
import {UserModel} from '../models/UserModel'

export enum EAuthType {
  LOGIN = '[auth] LOGIN',
  LOIGN_SUCCESS = '[auth] LOIGN_SUCCESS',
  VALIDATE_RESET_PASSWORD_SAGA = '[auth] VALIDATE_RESET_PASSWORD_SAGA',
  RESET_PASSWORD_SAGA = '[auth] RESET_PASSWORD_SAGA',
  UPDATE_PROFILE = '[auth] UPDATE_PROFILE',
  EDIT_Profile = '[auth] EDIT_Profile',
  EDIT_PASS = 'EDIT_PASS',
}

export const loginAction = (name: string, email: string) => ({
  type: EAuthType.LOGIN,
  payload: {name, email},
})

export const loginSuccessAction = (data: any) => ({
  type: EAuthType.LOIGN_SUCCESS,
  payload: data,
})

export const validateResetPasswordSaga = (payload: {
  email: string
  code: string
  onSuccess: any
  onError: any
}) => ({
  type: EAuthType.VALIDATE_RESET_PASSWORD_SAGA,
  payload,
})

export const resetPasswordSaga = (payload: {
  email: string
  newPassword: string
  confirmPassword: string
  isReset: boolean
  onSuccess: any
  onError: any
}) => ({
  type: EAuthType.RESET_PASSWORD_SAGA,
  payload,
})

export const updateProfileAction = (payload: {
  user: UserModel
  file: any
  onSuccess: any
  onError: any
}) => ({
  type: EAuthType.UPDATE_PROFILE,
  payload,
})

export const updateChangePassWordAction = (payload: {
  user: IUser
  onSuccess: any
  onError: any
}) => ({
  type: EAuthType.EDIT_PASS,
  payload,
})
