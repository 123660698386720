import client from '.'
import {UserModel} from '../../modules/auth/models/UserModel'

export const apiGetListUser = () => {
  return client.get('/users').then((res) => res.data)
}

export const apiAddNewUser = (data: string) => {
  return client.post('/users', data).then((res) => res.data)
}

export const putApiUserItem = (data: any) => {
  return client.put('/users', {...data}).then((res) => res.data)
}

// ================== SYSTEM API ==================== //
export const getApiSystemType = (type: string) => {
  return client.get(`/settings?type=${type}`).then((res) => res.data)
}

export const apiAddNewSystem = (data: string) => {
  return client.post('/settings', data).then((res) => res.data)
}

export const putApiSytemTypeItem = (data: any) => {
  return client.put('/settings', {...data}).then((res) => res.data)
}

export const apiDeleteSystemItem = (id: number) => {
  return client.delete<any>(`/settings/${id}`).then((res) => res.data)
}

export const apiGetListConfigs = () => {
  return client.get(`/public/getConfigs`).then((res) => res.data)
}

export const apiDeleteUserItem = (id: number) => {
  return client.delete<any>(`/users/${id}`).then((res) => res.data)
}

// ======================= FOOD CLASS =================== //
export const apiGetListFoodClass = () => {
  return client.get('/foodClasses').then((res) => res.data)
}

export const apiAddNewFoodClass = (data: string) => {
  return client.post('/foodClasses', data).then((res) => res.data)
}

export const putApiItemFoodClass = (data: any) => {
  return client.put('/foodClasses', {...data}).then((res) => res.data)
}

export const apiDeleteItemfoodClass = (id: number) => {
  return client.delete<any>(`/foodClasses/${id}`).then((res) => res.data)
}

export const apiGetListFilter = (page: any, pagesize: any, filter: any, screen: string) => {
  switch (screen) {
    case 'Food-Class':
      return client
        .get(`/foodClasses?filter=${filter}&page=${page}&pageSize=${pagesize}`)
        .then((res) => res.data)

    case 'Food-Type':
      return client
        .get(`/foodTypes?filter=${filter}&page=${page}&pageSize=${pagesize}`)
        .then((res) => res.data)

    case 'User':
      return client
        .get(`/users?filter=${filter}&page=${page}&pageSize=${pagesize}`)
        .then((res) => res.data)

    case 'Van':
      return client
        .get(`/vans?filter=${filter}&page=${page}&pageSize=${pagesize}&sortBy=vanId`)
        .then((res) => res.data)

    case 'Donor':
      return client
        .get(`/donors?filter=${filter}&page=${page}&pageSize=${pagesize}`)
        .then((res) => res.data)

    case 'Beneficiaries':
      return client
        .get(`/beneficiaries?filter=${filter}&page=${page}&pageSize=${pagesize}`)
        .then((res) => res.data)

    default:
      break
  }
}

// ======================= FOOD TYPE =================== //
export const apiGetListFoodType = () => {
  return client.get('/foodTypes').then((res) => res.data)
}

export const apiAddNewFoodType = (data: string) => {
  return client.post('/foodTypes', data).then((res) => res.data)
}

export const putApiItemFoodType = (data: any) => {
  return client.put('/foodTypes', {...data}).then((res) => res.data)
}

export const apiDeleteItemfoodType = (id: number) => {
  return client.delete<any>(`/foodTypes/${id}`).then((res) => res.data)
}

export const apiGetListFilterFoodType = (page: any, pagesize: any, filter: any) => {
  return client
    .get(`/foodTypes?filter=${filter}&page=${page}&pageSize=${pagesize}`)
    .then((res) => res.data)
}

export const apiGetListFilterUser = (page: any, pagesize: any, filter: any) => {
  return client
    .get(`/users?filter=${filter}&page=${page}&pageSize=${pagesize}`)
    .then((res) => res.data)
}

// ===================================================== //

export const apiGetListSort = (
  page: any,
  pagesize: any,
  sortBy: any,
  screen: string,
  sortType: string
) => {
  switch (screen) {
    case 'Food-Class':
      return client
        .get(`/foodClasses?sortBy=${sortBy}&sortType=${sortType}&page=${page}&pageSize=${pagesize}`)
        .then((res) => res.data)

    case 'Food-Type':
      return client
        .get(`/foodTypes?sortBy=${sortBy}&sortType=${sortType}&page=${page}&pageSize=${pagesize}`)
        .then((res) => res.data)

    case 'User':
      return client
        .get(`/users?sortBy=${sortBy}&sortType=${sortType}&page=${page}&pageSize=${pagesize}`)
        .then((res) => res.data)

    case 'Van':
      return client
        .get(`/vans?sortBy=${sortBy}&sortType=${sortType}&page=${page}&pageSize=${pagesize}`)
        .then((res) => res.data)

    case 'Donor':
      return client
        .get(`/donors?sortBy=${sortBy}&sortType=${sortType}&page=${page}&pageSize=${pagesize}`)
        .then((res) => res.data)

    case 'Beneficiaries':
      return client
        .get(
          `/beneficiaries?sortBy=${sortBy}&sortType=${sortType}&page=${page}&pageSize=${pagesize}`
        )
        .then((res) => res.data)

    default:
      break
  }
}
