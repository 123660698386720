import {IUser} from '@pages/system-user/models/UserModel'
import client from '.'
import {UserModel} from '../../modules/auth/models/UserModel'

export const apiGetListPost = () => {
  return client.get('project').then((res) => console.log(res.data))
}

export const login = (email: string, password: string) => {
  return client.post('/users/login', {email, password}).then((res) => res.data)
}

export const requestPassWord = (email: string) => {
  const origin = window.location.origin;
  return client
    .post(`/users/requestResetPassword`, {email,origin}, {
      headers: {
        accept: '*/*',
        'Access-Control-Allow-Origin': '*',
        'content-type': 'application/json',
      },
    })
    .then((res) => res.data)
}

export const apiChangePassword = (user: IUser) => {
  return client.put('/users/changePassword', user).then((res) => res.data)
}

// export const resetPassword = (email: string, newPassword: string, confirmPassword: string, isReset: boolean) => {
//     return client.post(`Users/ChangePassword`, { email, newPassword, confirmPassword, isReset }
//     ).then(res => res.data);
// }

// export const validateResetPassword = (email: string, code: string) => {
//     return client.get(`Users/ValidateResetPassword?email=${email}&code=${code}`).then(res => res.data);
// }

// export const apiUpdateProfile = (user: UserModel) => {
//     return client.put('Users/UpdateProfile', user).then(res => res.data)
// }
