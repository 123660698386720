import {Route, Switch} from 'react-router-dom'

import BreadProgramComplete from './BreadProgramComplete'
import BreadProgram from './BreadProgram'

function MainBreadProgram() {
  return (
    <Switch>
      <Route path='/bread-program' exact={true} component={BreadProgram} />
      <Route path='/bread-program/complete' exact={true} component={BreadProgramComplete} />
    </Switch>
  )
}

export default MainBreadProgram
