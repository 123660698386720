import {IAdHoc} from '../models/AdHocModel'

export enum EManageAdHocType {
  GET_LIST_ADHOC = '[manage ad-hoc] GET_LIST_ADHOC',
  SAVE_ADHOC_RD = '[manage ad-hoc] SAVE_ADHOC_RD',
  ADD_NEW_ADHOC = '[manage ad-hoc] ADD_NEW_ADHOC',
  ADD_LIST_ADHOC = '[manage ad-hoc] ADD_LIST_ADHOC',
  GET_ITEM_ADHOC = 'GET_ITEM_ADHOC',
  DELETE_ITEM_ADHOC = 'DELETE_ITEM_ADHOC',
  DELETE_ITEM_ADHOC_RD = 'DELETE_ITEM_ADHOC_RD',
  DELETE_ADHOC_FOOD_ITEM = 'DELETE_ADHOC_FOOD_ITEM',
  EDIT_ADHOC = 'EDIT_ADHOC',
  EDIT_ITEM_ADHOC_RD = 'EDIT_ITEM_ADHOC_RD',
  DELETE_ITEM_ADHOC_SCHEDULE = 'DELETE_ITEM_ADHOC_SCHEDULE',
}

// ======================== System Type =========================== //

export const getAdHocAction = () => ({
  type: EManageAdHocType.GET_LIST_ADHOC,
})

export const saveAdHocAction = (payload: IAdHoc[]) => ({
  type: EManageAdHocType.SAVE_ADHOC_RD,
  payload,
})

export const addNewAdHocAction = (payload: {value: any; onSuccess: any; onError: any}) => ({
  type: EManageAdHocType.ADD_NEW_ADHOC,
  payload,
})

export const getItemAdHocAction = (payload: any) => ({
  type: EManageAdHocType.GET_ITEM_ADHOC,
  payload,
})

export const editAdHocItemAction = (payload: {value: any; onSuccess: any; onError: any}) => ({
  type: EManageAdHocType.EDIT_ADHOC,
  payload,
})

export const deleteAdHoctItemAction = (payload: {arrId: any; onSuccess: any; onError: any}) => ({
  type: EManageAdHocType.DELETE_ITEM_ADHOC,
  payload,
})

export const deleteFoodTypeItemAction = (payload: {id: any; onSuccess: any; onError: any}) => ({
  type: EManageAdHocType.DELETE_ADHOC_FOOD_ITEM,
  payload,
})

export const deleteAdHocScheduleItemAction = (payload: {
  id: string
  onSuccess: any
  onError: any
}) => ({
  type: EManageAdHocType.DELETE_ITEM_ADHOC_SCHEDULE,
  payload,
})

// export const getListFilterVanSuccess = (payload: any) => ({
//   type: EManageAdHocType.FILTER_SUCCESS_VAN,
//   payload
// });

// export const getListSortVanSuccess = (payload: any) => ({
//   type: EManageAdHocType.SORT_SUCCESS_VAN,
//   payload
// });
