import {IListResult} from '@src/app/interfaces/common.interface'
import {
  apiAddNewBeneficiaries,
  apiDeleteItemBeneficiaries,
  apiGetItemBeneficiaries,
  apiGetListAllBeneficiaries,
  apiGetListBeneficiaries,
  putApiEditBeneficiariesApi,
} from '@src/app/services/api/BeneficiariesApi'
import {apiUploadFile} from '@src/app/services/api/commonApi'
import {alertError, alertSuccess} from '@src/app/services/helper'
import {AxiosResponse} from 'axios'
import {call, put, takeLatest} from 'redux-saga/effects'
import {IBeneficiaries} from '../models/Beneficiaries'
import {
  addNewBeneficiariestAction,
  editBeneficiariestAction,
  EManageBeneficiariesType,
  saveBeneficiariesAction,
} from './ManagaBeneficiariesAction'

// ================= DONOR SAGA ====================== //
function* getListBeneficiariesWatcher() {
  try {
    // const res: IBeneficiaries[] = yield call(apiGetListBeneficiaries)
    const res: IListResult = yield call(apiGetListBeneficiaries)
    if (res) {
      yield put(saveBeneficiariesAction(res.data))
    }
  } catch (error) {}
}

function* getListAllBeneficiariesWatcher({payload}: any) {
  try {
    // const res: IBeneficiaries[] = yield call(apiGetListBeneficiaries)
    const res: IListResult = yield call(apiGetListAllBeneficiaries, payload.date)
    if (res) {
      yield put(saveBeneficiariesAction(res.data))
    }
  } catch (error) {}
}

function* addNewBeneficiariesWatcher({payload}: ReturnType<typeof addNewBeneficiariestAction>) {
  try {
    if (payload.value && payload.value?.foodRequestFormPDF) {
      const res_PDF: AxiosResponse<any> = yield call(
        apiUploadFile,
        payload.value.foodRequestFormPDF,
        payload.value.foodRequestFormPDF.name
      )
      payload.value.foodRequestForm = res_PDF
    }
    if (payload.value && payload.value?.ird88PDF) {
      const res_PDF: AxiosResponse<any> = yield call(
        apiUploadFile,
        payload.value.ird88PDF,
        payload.value.ird88PDF.name
      )
      payload.value.ird88 = res_PDF
    }
    if (payload.value && payload.value?.certificateOfIncorporationPDF) {
      const res_PDF: AxiosResponse<any> = yield call(
        apiUploadFile,
        payload.value.certificateOfIncorporationPDF,
        payload.value.certificateOfIncorporationPDF.name
      )
      payload.value.certificateOfIncorporation = res_PDF
    }
    if (payload.value && payload.value?.chopSamplePDF) {
      const res_PDF: AxiosResponse<any> = yield call(
        apiUploadFile,
        payload.value.chopSamplePDF,
        payload.value.chopSamplePDF.name
      )
      payload.value.chopSample = res_PDF
    }

    const res: IBeneficiaries = yield call(apiAddNewBeneficiaries, payload.value)
    payload.onSuccess(res)
    alertSuccess('Save successfully !')
  } catch (error: any) {
    payload.onError()
    alertError(error)
  }
}

function* editBeneficiariesWatcher({payload}: ReturnType<typeof editBeneficiariestAction>) {
  try {
    if (payload.value && payload.value?.foodRequestFormPDF) {
      const res_PDF: AxiosResponse<any> = yield call(
        apiUploadFile,
        payload.value.foodRequestFormPDF,
        payload.value.foodRequestFormPDF.name
      )
      payload.value.foodRequestForm = res_PDF
    }
    if (payload.value && payload.value?.ird88PDF) {
      const res_PDF: AxiosResponse<any> = yield call(
        apiUploadFile,
        payload.value.ird88PDF,
        payload.value.ird88PDF.name
      )
      payload.value.ird88 = res_PDF
    }
    if (payload.value && payload.value?.certificateOfIncorporationPDF) {
      const res_PDF: AxiosResponse<any> = yield call(
        apiUploadFile,
        payload.value.certificateOfIncorporationPDF,
        payload.value.certificateOfIncorporationPDF.name
      )
      payload.value.certificateOfIncorporation = res_PDF
    }
    if (payload.value && payload.value?.chopSamplePDF) {
      const res_PDF: AxiosResponse<any> = yield call(
        apiUploadFile,
        payload.value.chopSamplePDF,
        payload.value.chopSamplePDF.name
      )
      payload.value.chopSample = res_PDF
    }

    const res: IBeneficiaries[] = yield call(putApiEditBeneficiariesApi, payload.value)
    yield put({type: EManageBeneficiariesType.EDIT_ITEM_BNF_RD, payload: res})
    payload.onSuccess()
    alertSuccess('Successfully Edit Item !')
    // yield put(getDonorAction())
  } catch (error: any) {
    alertError(error ?? 'Edit Failed !')
    payload.onError()
  }
}

function* deleteBeneficiariesItemWatcher({payload}: any) {
  try {
    const res: IBeneficiaries[] = yield call(apiDeleteItemBeneficiaries, payload.id)
    yield put({
      type: EManageBeneficiariesType.DELETE_ITEM_BNF_RD,
      payload: payload.id,
    })
    payload.onSuccess()
    alertSuccess('Delete successful')
  } catch (error: any) {
    payload.onError()
    alertError(error)
  }
}

function* getItemBeneficiariesWatcher(obj: any) {
  try {
    const res: AxiosResponse<any> = yield call(apiGetItemBeneficiaries, obj.payload.id)
    obj.payload.onsuccess(res)
  } catch (error) {
    alertError(`${error}`)
  }
}

export default function* ManagaBeneficiariesSaga() {
  yield takeLatest(EManageBeneficiariesType.GET_LIST_BNF, getListBeneficiariesWatcher)
  yield takeLatest(EManageBeneficiariesType.GET_LIST_ALLBEN, getListAllBeneficiariesWatcher)
  yield takeLatest(EManageBeneficiariesType.ADD_NEW_BNF, addNewBeneficiariesWatcher)
  yield takeLatest(EManageBeneficiariesType.EDIT_BNF, editBeneficiariesWatcher)
  yield takeLatest(EManageBeneficiariesType.DELETE_ITEM_BNF, deleteBeneficiariesItemWatcher)
  yield takeLatest(EManageBeneficiariesType.GET_ITEM_BNF, getItemBeneficiariesWatcher)
}
