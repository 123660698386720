import {history} from '@src/app/App'
import {alertCode401} from '@src/app/services/helper'
import axios from 'axios'

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 100000,
})

client.interceptors.request.use(
  async (config) => {
    if (config.method === 'get') {
      const currentTime = new Date().getTime()
      const oldUrl: any = config.url
      let newUrl = config.url
      if (oldUrl.includes('?')) {
        newUrl = `${oldUrl}&time=${currentTime}`
      } else {
        newUrl = `${oldUrl}?time=${currentTime}`
      }
      config.url = newUrl
    }
    return config
  },
  (error) => Promise.reject(error)
)

client.interceptors.response.use(
  (response) => response,
  (error) => responseErrorHandler(error)
)

const responseErrorHandler = async (error: any) => {
  return Promise.reject(error.response.data)
}

export default client
