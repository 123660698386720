import client from '.'
export const apiUploadFile = async (file: File, fileName: string) => {
  const url: string = await client
    .get(`/public/getPutUrl?fileName=${fileName}`)
    .then((res) => res.data)
  await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': '',
    },
    body: file,
  })
  return url.split('?')[0]
}

const uploadSingleContent = async (src: string, index: number) => {
  const res_blob = await fetch(src)
  const blob = await res_blob.blob()
  const filename = new Date().getTime() + '_' + index
  const file = new File([blob], filename, {type: blob.type})
  const new_url = await apiUploadFile(file, filename)
  return {url: new_url, index}
}

export const apiUploadHTMLContent = async (html: string) => {
  let tmp = document.createElement('div')
  tmp.innerHTML = html
  const arrImg = tmp.getElementsByTagName('img')
  let arrPromiseContent: Promise<{url: string; index: number}>[] = []
  if (arrImg.length) {
    for (let i = 0; i < arrImg.length; i++) {
      if (arrImg[i].currentSrc.indexOf('data:image') !== -1) {
        arrPromiseContent.push(uploadSingleContent(arrImg[i].currentSrc, i))
      }
    }

    const res_all = await Promise.all(arrPromiseContent)
    for (let i = 0; i < res_all.length; i++) {
      arrImg[res_all[i].index].setAttribute('src', res_all[i].url)
    }
    return new XMLSerializer().serializeToString(tmp)
  } else {
    return html
  }
}
