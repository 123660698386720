/* eslint-disable react/jsx-no-target-blank */
// import { history } from "@";
import {useEffect} from 'react'
import {useHistory, useLocation} from 'react-router-dom'

import {setBlockNav} from '@src/app/services/helper'
import {AsideMenuItem} from './AsideMenuItem'
import MenuList from './MenuList'

export function AsideMenuMain() {
  const history = useHistory()
  const {pathname} = useLocation()
  const menuList = MenuList.filter(({id}) => pathname.includes(id))

  useEffect(() => {
    history.listen(() => {
      setBlockNav(false)
    })
  }, [])

  return (
    <div>
      {menuList.map((menu) => (
        <AsideMenuItem
          key={menu.id}
          to={menu.to}
          title={menu.title}
          fontIcon={menu.fontIcon}
          hasBullet={menu.hasBullet}
          isDisabled={menu.isDisabled}
          classNames='mt-3'
        />
      ))}
    </div>
  )
}
