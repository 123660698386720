import client from '.'

export const apiGetListDonor = () => {
  return client.get(`/public/donors`).then((res) => res.data)
}

export const apiGetListAllDonorSchedule = (date: string) => {
  return client.get(`/donors?date=${date}`).then((res) => res.data)
}

export const apiAddNewDonor = (data: string) => {
  return client.post('/donors', data).then((res) => res.data)
}

export const putApiEditDonorApi = (data: any) => {
  return client.put('/donors', {...data}).then((res) => res.data)
}

export const apiDeleteItemDonor = (id: number) => {
  return client.delete<any>(`/donors/${id}`).then((res) => res.data)
}

export const apiGetItemDonor = (id: number) => {
  return client.get<any>(`/donors/${id}`).then((res) => res.data)
}

export const apiAddNoteDonor = (data: any) => {
  return client.post(`/public/note`, data).then((res) => res.data)
}

export const apiUpdateFoodType = (data: any) => {
  return client.put(`/donors/updateDonorFood`, data).then((res) => res.data)
}
