import {useHistory} from 'react-router-dom'

const AdHocComplete = () => {
  const history = useHistory()

  const goBackMain = () => {
    history.push('/')
  }

  const onAddNew = () => {
    history.push('/ad-hoc')
  }

  return (
    <div className='card-header py-5 ps-9'>
      <div>
        <h3 className='fw-bolder m-0 mb-5 fs-12'>AD-HOC</h3>
        <h4 className='fw-bolder mb-0 fs-12'>ADD NEW</h4>
      </div>
      <div style={{marginTop: '50px'}}>
        <h4 className='fw-bolder mb-0'>SUBMISSION COMPLETE</h4>
        <div className='mt-5'>
          <button type='button' className='btn btn-primary btn-sm p-4 me-4' onClick={goBackMain}>
            GO BACK TO MAIN PAGE
          </button>
          <button type='button' className='btn btn-primary btn-sm p-4' onClick={onAddNew}>
            <i className='bi bi-plus-lg mb-1' /> ADD ANOTHER
          </button>
        </div>
        <h5 className='mt-5'>THANK YOU YOUR FOR DONATION</h5>
      </div>
    </div>
  )
}

export default AdHocComplete
