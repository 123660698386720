import client from '.'

export const apiGetListSchedule = (date: any, vanId: string) => {
  return client.get(`/schedules?vanId=${vanId}&date=${date}`).then((res) => res.data)
}

export const apiAddNewVSchedule = (data: string) => {
  return client.post('/schedules', data).then((res) => res.data)
}

export const apiGetListNoteSchedule = (type: any, subjectId: string) => {
  return client.get<any>(`/notes?subjectId=${subjectId}&type=${type}`).then((res) => res.data)
}

export const apiAddNewVNoteSchedule = (data: string) => {
  return client.post('/notes', data).then((res) => res.data)
}

export const apiDeleteItemSchedule = (id: number) => {
  return client.delete<any>(`/schedules/${id}`).then((res) => res.data)
}

export const apiReAssigned = (data: any) => {
  return client.post(`/schedules/re-assign`, data).then((res) => res.data)
}

export const apiDeleteItemScheduleNote = (id: any) => {
  return client.delete(`/notes/${id}`).then((res) => res.data)
}
export const apiOptimiseSchedule = (data: any) => {
  return client.post('/schedules/optimize', data).then((res) => res.data)
}

export const apiUpdateFoodType = (data: any) => {
  return client.put('/adhocs/updateAdhocFood', data).then((res) => res.data)
}
