import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'

import {setBreadProgramStoreAction} from '@pages/bread-program/redux/ManagaBreadProgramActions'
import {ScrollTopComponent} from '@metronic/assets/ts/components'

const Main = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const addBreadProgram = () => {
    history.push('/bread-program')
  }

  const addAdHoc = () => {
    history.push('/ad-hoc')
  }

  useEffect(() => {
    dispatch(
      setBreadProgramStoreAction({
        selectedBeneficiary: undefined,
        selectedDate: '',
        selectedDonorBranch: undefined,
      })
    )
  }, [])

  useEffect(() => {
    ScrollTopComponent.goTop()
  }, [])

  return (
    <div className='w-100 h-100 bg-white d-flex flex-column align-items-center'>
      <h3 className='fw-bolder mt-4 mb-4 fs-12'>WEBSITE</h3>
      <h3 className='fw-bolder mt-0 mb-10 fs-12'>(WHERE IN WEBSITE TBD)</h3>

      <h3 className='fw-bolder mt-20 mb-4 fs-12'>BENEFICIARY LINKS</h3>
      <button type='button' className='btn btn-primary btn-sm p-4' onClick={addBreadProgram}>
        BREAD PROGRAM INPUT FORM
      </button>

      <div className='bg-secondary mt-20 mb-10 w-50' style={{height: 2}} />

      <h3 className='fw-bolder mb-4 fs-12'>DONOR LINKS</h3>
      <button type='button' className='btn btn-primary btn-sm p-4' onClick={addAdHoc}>
        AD HOC INPUT FORM
      </button>
    </div>
  )
}

export default Main
