import {useHistory} from 'react-router-dom'

import {KTSVG} from '@metronic/helpers'
import {ADD_BREAD_PROGRAM_STEPS} from '@src/app/constants/constant'

const BreadProgramComplete = () => {
  const history = useHistory()

  const goBack = () => {
    history.goBack()
  }

  const goBackMain = () => {
    history.push('/')
  }

  const onAddNew = () => {
    history.push('/bread-program', {
      step: ADD_BREAD_PROGRAM_STEPS.CHOOSE_DONOR,
    })
  }

  return (
    <div className='card'>
      <div className='card-body py-8'>
        <u role='button' onClick={goBack}>
          <KTSVG path='/media/icons/duotune/arrows/arr074.svg' />
          Back / 返回
        </u>
        <h3 className='card-title fs-1 mb-10'>BREAD PROGRAM / 麵包回收計劃</h3>

        <div style={{marginTop: '50px'}}>
          <h4 className='fw-bolder mb-0'>SUBMISSION COMPLETE / 提交完成</h4>
          <div className='mt-5'>
            <button type='button' className='btn btn-primary btn-sm p-4 me-4' onClick={goBackMain}>
              GO BACK TO MAIN PAGE / 返回首頁
            </button>
            <button type='button' className='btn btn-primary btn-sm p-4' onClick={onAddNew}>
              <i className='bi bi-plus-lg mb-1' /> ADD ANOTHER / 輸入另一項工作
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BreadProgramComplete
