import client from '.'

export const apiGetListVan = () => {
  return client.get(`/vans?pageSize=1000&sortBy=vanId`).then(res => res.data);
}

export const apiAddNewVan = (data: string) => {
  return client.post('/vans', data).then(res => res.data);
}

export const putApiEditVanApi = (data: any) => {
  return client.put('/vans', { ...data }).then((res) => res.data);
};

export const apiDeleteItemVan = (id: number) => {
  return client.delete<any>(`/vans/${id}`).then((res) => res.data);
};

export const apiGetListFilterVan = ( page:any, pagesize:any, filter:any) => {
  return client.get(`/vans?filter=${filter}&page=${page}&pageSize=${pagesize}`).then((res) => res.data);
};



