import {compareAsc} from 'date-fns'
import {useFormik} from 'formik'
import React, {useEffect, useRef, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import * as Yup from 'yup'

import {addNewNoteAction, getDonorAction} from '@pages/donors/redux/ManagaDonorsAction'
import {deleteNoteItemAction} from '@pages/schedule/redux/ManagaScheduleAction'
import {
  getFoodTypeAction,
  getListConfigsAction,
} from '@pages/system-user/redux/ManagaSystemUserAction'
import PopUpNotesForm from '@src/app/common/PopUp/PopUpNotesForm'
import {
  ADD_FIELD_DONOR,
  ADD_FIELD_DONOR_ARR,
  COMMON_STATUS,
  DayInWeek,
} from '@src/app/constants/constant'
import {putApiEditAdHocItemApi} from '@src/app/services/api/adHocApi'
import {apiGetListNoteSchedule} from '@src/app/services/api/scheduleApi'
import {
  addTime,
  alertSuccess,
  clone,
  commonSortASC,
  commonSortI18nASC,
  formatDateValidate,
  setBlockNav,
} from '@src/app/services/helper'
import {useSelector} from '@src/setup/redux/RootReducer'

import ModalDeleteImage from '../hooks/ModalDeleteImage'
import useModal from '../hooks/useModal'
import {addNewAdHocAction} from '../redux/ManagaAdHocAction'
import PopUp from './PopUp'

declare const google: any

type Props = {
  mode: 'add' | 'delete' | 'edit' | 'cancel' | 'assign' | 'copy' | null
  data: any
  title: 'food-type' | 'contact-type' | null
  deleteType: 'donor-contact' | 'beneficiaries-contact' | 'donor-foodType' | null
  onClose(): void
}

const readFileToUrl = (file: File) => {
  return new Promise((resolve, reject) => {
    try {
      var reader = new FileReader()
      reader.onload = (e: any) => {
        resolve(e.target.result)
      }
      reader.readAsDataURL(file)
    } catch (error) {
      reject(error)
    }
  })
}

const requiredFields = ['donorId', 'pickupLocation', 'pickupDate']

const profileDetailsSchema = Yup.object().shape({
  donorId: Yup.string().required('Please select a Donor Name'),
  numberOfPallets: Yup.number().min(1, 'Value must be greater than 0 !'),
})

const FormAdHoc: React.FC<Props> = ({data, mode, title, onClose, deleteType}) => {
  const initialValues = {
    donorId: data?.donorId ?? '',
    pickupDate: data?.pickupDate ?? '',
    pickupTime: data?.pickupTime ?? '',
    pickupLocation: data?.pickupLocation ?? '',
    regionId: data?.regionId ?? '',
    packingInformation: data?.packingInformation ?? '',
    numberOfPallets: data?.numberOfPallets ?? '',
    dimensions: data?.dimensions ?? '',
    programmeId: data?.programmeId ?? '',
    note: data?.note ?? '',
    packing: data?.packing ?? '',
    deliveryNoteRequired: data?.deliveryNoteRequired ?? '',
    donorStatusId: data?.donorStatusId ?? '',
    reasonForStatus: data?.reasonForStatus ?? '',
    dayOfCollection: data?.dayOfCollection ?? '',
    recurring: data?.recurring ?? '',
    terminationDate: data?.terminationDate ?? '',
  }
  const history = useHistory()
  const ListFoodType = useSelector((state) => state.systemUser.TypeClassList)
  const ListDonors = useSelector((state) => state.DonorManage.ListDonor)
  const ListConfigs: any = useSelector((state) => state.systemUser.ListConfigs)

  const dispatch = useDispatch()
  const addFieldSelectRef = useRef<any>()
  const [donorFoods, setDonorFoods] = useState<any>([])
  const {stateModal, toggleModal, offModal} = useModal()
  const [loading, setLoading] = useState(false)
  const animatedComponents = makeAnimated()
  const [addField, setAddField] = useState<typeof ADD_FIELD_DONOR_ARR>([])
  const [nowDate, setNowDate] = useState<any>('')
  const dateToWeek: any = addTime(new Date().toUTCString(), 0)

  const [pickUpDate, setPickUpDate] = useState<any>(formatDateValidate(dateToWeek))
  const [nowTerminationDate, setNowTerminationDate] = useState<any>('')
  const [DayOfCollection, setDayOfCollection] = useState([])
  const [dayCollection, setdayCollection] = useState(DayInWeek)
  const [images, setImages] = useState<any>([])
  const [errorDayofCollection, setErrorDayofCollection] = useState({
    status: false,
    error: '',
  })
  const [isDisPlayRecurring, setisDisPlayRecurring] = useState(false)
  const inputIconRef: any = useRef()
  const [modalConfirmDelete, setModalConfirmDelete] = useState<any>({show: false})
  const [modalNotes, setModalNotes] = useState<any>({show: false})
  const [notes, setNotes] = useState<any>([])
  const [deliveryNoteRequired, setDeliveryNoteRequired] = useState<any>(null)
  const [errorPickupDate, setErrorPickupDate] = useState<string>()

  const formik = useFormik<typeof initialValues>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: (values) => {
      const obj: any = {
        donorId: values?.donorId || '',
        pickupTime: values?.pickupTime || '',
        pickupDate: pickUpDate || nowDate,
        terminationDate: values?.terminationDate || null,
        pickupLocation: values?.pickupLocation || '',
        regionId: values?.regionId || null,
        packingInformation: values?.packingInformation || '',
        numberOfPallets: values?.numberOfPallets || null,
        dimensions: values?.dimensions || '',
        programmeId: values?.programmeId || null,
        recurring: DayOfCollection,
        images,
        note: values?.note || '',
        packing: values?.packing || '',
        deliveryNoteRequired: deliveryNoteRequired,
        donorStatusId: values?.donorStatusId || null,
        reasonForStatus: values?.reasonForStatus || '',
        donorFoods: donorFoods?.map((d: any) => {
          return {
            description: d?.description || '',
            expiryDate: d?.expiryDate || null,
            foodTypeId: d?.foodTypeId || '',
            numberOfServing: d?.numberOfServing || null,
            programmeId: d?.programmeId || null,
            quantityInKgs: d?.quantityInKgs || null,
            quantityInUnitOfMeasure: d?.quantityInUnitOfMeasure || null,
            restrictionId: d?.restrictionId || null,
            storageType: d?.storageType || [],
            targetBeneficiaryGroups: d?.targetBeneficiaryGroups || [],
            unitOfMeasure: d?.unitOfMeasure || '',
          }
        }),
        status: 'Un-assigned',
      }

      if (isDisPlayRecurring === false || (isDisPlayRecurring === true && DayOfCollection.length)) {
        for (var property in obj) {
          if (requiredFields.indexOf(property) > -1 && !obj[property]) {
            obj.status = COMMON_STATUS.pending
            break
          }
        }

        if (
          donorFoods.length === 0 ||
          (data?.adhocFoodTypes === undefined && data?.adhocFoodTypes.length === 0)
        ) {
          obj.status = COMMON_STATUS.pending
        }
        if (pickUpDate === '') return

        if (mode === 'add') {
          setLoading(true)
          dispatch(
            addNewAdHocAction({
              value: obj,
              onSuccess: (data: any) => {
                const value = {
                  content: notes.map((el: any) => el?.content),
                  subjectId: data?.id,
                  type: 'adhoc',
                }
                dispatch(
                  addNewNoteAction({
                    value,
                    onSuccess: () => {
                      history.push('/ad-hoc/complete')
                    },
                    onError: () => {
                      setLoading(false)
                    },
                  })
                )
              },
              onError: () => {
                setLoading(false)
              },
            })
          )
        }
      }
    },
  })

  const _delete = () => {}

  // Upload File PDF .....................
  const [errorImages, setErrorIamges] = useState('')

  const removeImages = (file: any, index: number) => {
    setModalConfirmDelete({show: true, index, file})
  }

  const onDelete = async (index: number, file: any) => {
    if (data) {
      const newArrImages = [...images]
      newArrImages.splice(index, 1)
      setImages(newArrImages)
      const editItem = {...data, images: newArrImages.map((img: any) => img?.url)}
      alertSuccess('Delete Success!')
      setModalConfirmDelete({show: false})
      const res = await putApiEditAdHocItemApi(editItem)
    } else {
      const newArrImages = [...images]
      newArrImages.splice(index, 1)
      setImages(newArrImages)
      inputIconRef.current.value = ''
    }
  }

  const previewImageUpload = (file: any, index: number) => {
    return (
      <div className='position-relative' style={{width: 100, height: 100, margin: 20}}>
        <div
          className='position-absolute'
          style={{top: -10, right: -15, cursor: 'pointer'}}
          onClick={() => removeImages(file, index)}
        >
          <i style={{fontSize: 20, color: '#4e4e4e'}} className='fa fa-times-circle'></i>
        </div>
        <img key={file.id} className='style-images' src={file.url} alt='' />
      </div>
    )
  }

  const onOpenFile = async (e: any) => {
    const files = e.target.files

    let imagesClone: any = [...images]
    for (let i = 0; i < files.length; i++) {
      if (files[i]?.size >= 10485760) {
        setErrorIamges('Upload up to 10MB.')
        setImages([...images])
        return
      }
      if (!files[i]?.type.includes('image/')) {
        inputIconRef.current.value = ''
        setErrorIamges('Do not upload files that are not photos.')
        setImages([])
        return
      }
      setErrorIamges('')
    }

    for (let i = 0; i < files.length; i++) {
      const fileUrl = await readFileToUrl(files[i])
      const fileObj: any = {
        id: Math.random(),
        file: files[i],
        url: fileUrl,
      }

      imagesClone.push(fileObj)
    }
    setImages(imagesClone)
    inputIconRef.current.value = ''
  }

  useEffect(() => {
    if (data?.images) {
      setImages((prev: any) => [...prev, ...data.images.map((url: string) => ({file: null, url}))])
    }
  }, [data?.images])

  const handleAddField = (e: any) => {
    setAddField(e)
  }

  useEffect(() => {
    if (!data) return
    const newFields = []
    if (data.packing && data.packing !== '') newFields.push(ADD_FIELD_DONOR_ARR[0])
    if (data.deliveryNoteRequired !== null) newFields.push(ADD_FIELD_DONOR_ARR[1])
    if (data.donorStatusId) newFields.push(ADD_FIELD_DONOR_ARR[2])
    addFieldSelectRef.current?.setValue(newFields)
    setAddField(newFields)
  }, [data])

  // FoodType ...........................
  const OnhandleObjFoodtype = async (param: any) => {
    if (param?.mode === 'add') {
      var dataFood = donorFoods
      dataFood.push(param)
      setDonorFoods(dataFood)
    }
  }

  const onDeleteFoodType = (param: any) => {
    const typeOfParam = typeof param
    if (stateModal.typeModal === 'delete' && typeOfParam == 'string') {
      // delete to server
      const newDonor = donorFoods.filter((pp: any) => pp.id !== param)
      setDonorFoods(newDonor)
    }

    if (stateModal.typeModal === 'delete' && typeOfParam != 'string') {
      // delete on local
      const newDonor = donorFoods.filter((pp: any) => pp.localId !== param.localId)
      setDonorFoods(newDonor)
    }
  }

  const FoodTypeName = donorFoods?.map((i: any) => {
    const item = ListFoodType?.find((x: any) => x?.id === i?.foodTypeId)
    return {...i, name: item?.name}
  })

  useEffect(() => {
    if (data?.adhocFoodTypes !== undefined) {
      const newData = data?.adhocFoodTypes?.map((food: any) => {
        const targetBeneficiaryGroupIds = food?.AdhocFoodTargetGroups?.map((el: any) => {
          return el?.targetGroupId
        })
        return {
          id: food?.id ? food?.id : '',
          foodTypeId: food?.foodTypeId || '',
          restrictionId: food?.restrictionId || null,
          unitOfMeasure: food?.unitOfMeasure || '',
          quantityInUnitOfMeasure: food?.quantityInUnitOfMeasure || null,
          quantityInKgs: food?.quantityInKgs || null,
          numberOfServing: food?.numberOfServing || null,
          expiryDate: food?.expiryDate || null,
          storageType: food?.storageType || null,
          programmeId: food?.programmeId || null,
          description: food?.description || '',
          targetBeneficiaryGroups: targetBeneficiaryGroupIds || [],
          scheduleFoods: food?.scheduleFoods || [],
        }
      })
      setDonorFoods(newData)
    }
  }, [data?.adhocFoodTypes])

  // Thay đổi Status ...........
  const [changeDeliveryNoteRequired, setChangeDeliveryNoteRequired] = useState(null)
  const onChangedeliveryNoteRequired = (e: any) => {
    setChangeDeliveryNoteRequired(e?.value)
  }

  useEffect(() => {
    if (changeDeliveryNoteRequired == 'YES') setDeliveryNoteRequired(true)
    if (changeDeliveryNoteRequired == 'NO') setDeliveryNoteRequired(false)
  }, [changeDeliveryNoteRequired])

  useEffect(() => {
    if (data?.deliveryNoteRequired !== null) {
      setDeliveryNoteRequired(data?.deliveryNoteRequired)
    }
  }, [data?.deliveryNoteRequired])
  // ..........................

  const handleSubmit = () => {}

  // Validate ngày and giờ .............
  useEffect(() => {
    setNowDate(formatDateValidate(dateToWeek))
  }, [])

  const changeDate = (e: any) => {
    setPickUpDate(e.target.value)
  }

  useEffect(() => {
    if (pickUpDate) {
      const dateToWeek: any = addTime(new Date(pickUpDate), 1)
      setNowTerminationDate(formatDateValidate(dateToWeek))
    } else {
      const dateToWeek: any = addTime(new Date().toUTCString(), 0)
      setNowTerminationDate(formatDateValidate(dateToWeek))
    }

    if (pickUpDate === '') {
      setErrorPickupDate('This field cannot be left blank')
    } else {
      setErrorPickupDate('')
    }
  }, [pickUpDate])

  useEffect(() => {
    // Edit
    if (data?.pickupDate && mode !== 'add') {
      setPickUpDate(data?.pickupDate)
    }

    // Copy
    if (data?.pickupDate && mode === 'add' && nowDate) {
      const result = compareAsc(new Date(nowDate), new Date(data?.pickupDate))
      if (result < 1) {
        setPickUpDate(data?.pickupDate)
      } else {
        setPickUpDate(nowDate)
      }
    }
  }, [data?.pickupDate, nowDate])

  const changeTerminationDate = (e: any) => {
    setNowTerminationDate(e.target.value)
  }

  const [isDisPlayStatus, setisDisPlayStatus] = useState(false)
  useEffect(() => {
    if (formik.values.donorStatusId !== '') {
      setisDisPlayStatus(true)
    } else {
      setisDisPlayStatus(false)
      formik.setFieldValue('reasonForStatus', '')
    }
  }, [isDisPlayStatus, formik.values.donorStatusId])

  // Check disable Recurring ..............
  const onChangeRecurring = () => {
    setisDisPlayRecurring(!isDisPlayRecurring)
  }

  useEffect(() => {
    if (data?.recurring?.length) {
      setisDisPlayRecurring(true)
    } else {
      setisDisPlayRecurring(false)
    }
  }, [data?.recurring?.length])

  const onHandleDay = (day: any) => {
    const index = dayCollection.findIndex((pp: any) => pp?.id === day.id)
    const newArr = clone(dayCollection)
    const newDay = {...day, isActive: !day.isActive}
    if (index !== undefined && index !== -1) {
      newArr.splice(index, 1, newDay)
    }
    setdayCollection(newArr)
    const newDayCollection = newArr?.filter((arr: any) => arr.isActive === true)
    const DayArr = newDayCollection.map((day: any) => {
      return day.id
    })
    setDayOfCollection(DayArr)
  }

  useEffect(() => {
    if (data?.recurring?.length > 0) {
      const newDataDay = dayCollection.map((element) => {
        return {...element, isActive: data?.recurring.includes(element.id) ? true : false}
      })
      setdayCollection(newDataDay)
      setDayOfCollection(data?.recurring)
    }
  }, [])

  useEffect(() => {
    if (DayOfCollection.length < 1) {
      setErrorDayofCollection({
        status: true,
        error: 'This field cannot be left blank',
      })
    } else {
      setErrorDayofCollection({
        status: false,
        error: '',
      })
    }
  }, [DayOfCollection.length, formik?.values?.terminationDate])

  useEffect(() => {
    if (formik.values.donorId) {
      const newDonor = ListDonors.filter((d: any) => d?.id === formik.values.donorId)
      if (newDonor.length) {
        formik.setFieldValue(
          'pickupLocation',
          newDonor[0]?.foodCollectionAddressCN || newDonor[0]?.foodCollectionAddressEN
        )

        formik.setFieldValue('regionId', formik.values.regionId || newDonor[0]?.region?.id)
      }
    }
  }, [formik.values.donorId])

  useEffect(() => {
    if (data?.pickupLocation) {
      formik.setFieldValue('pickupLocation', data?.pickupLocation)
    }

    if (data?.pickupLocation === '') {
      formik.setFieldValue('pickupLocation', '')
    }
  }, [data?.pickupLocation])

  useEffect(() => {
    initAutocomplete()
  }, [])
  const initAutocomplete = () => {
    const addressEnBox = new google.maps.places.SearchBox(document.getElementById('pickupLocation'))
    addressEnBox.addListener('places_changed', () => {
      const places = addressEnBox.getPlaces()
      if (places.length == 0) {
        return
      }
      formik.setFieldValue('pickupLocation', places[0].formatted_address)
    })
  }

  useEffect(() => {
    if (data?.regionId) {
      formik.setFieldValue('regionId', data?.regionId)
    }

    if (data?.regionId === null) {
      formik.setFieldValue('regionId', '')
    }
  }, [data?.regionId])

  const onAddNote = (type: any) => {
    setModalNotes({show: true, type})
  }

  const addContent = (content: any) => {
    const newNotes: any = []
    newNotes.push(...notes, content)
    setNotes(newNotes)
  }

  const onDeleteNote = (index: number, id?: string) => {
    // if (id !== undefined) {
    //   dispatch(
    //     deleteNoteItemAction({
    //       id: id,
    //       onSuccess: () => {},
    //       onError: () => {
    //         setLoading(false)
    //       },
    //     })
    //   )
    //   const newData = [...notes]
    //   newData.splice(index, 1)
    //   setNotes(newData)
    // } else {
    const newData = [...notes]
    newData.splice(index, 1)
    setNotes(newData)
    alertSuccess('Delete successful')
    // }
  }

  const getNotes = async () => {
    if (data?.id) {
      const res = await apiGetListNoteSchedule('adhoc', data?.id)
      setNotes(res)
    }
  }

  const checkKeyCode = (e: any) => {
    if (
      !(
        (e.keyCode >= 48 && e.keyCode <= 57) ||
        (e.keyCode >= 96 && e.keyCode <= 105) ||
        e.keyCode == 8
      )
    ) {
      e.preventDefault()
    }
  }

  useEffect(() => {
    getNotes()
  }, [])

  useEffect(() => {
    return function cleanup() {
      setBlockNav(false)
    }
  }, [])

  useEffect(() => {
    dispatch(getListConfigsAction())
    dispatch(getFoodTypeAction())
    dispatch(getDonorAction())
  }, [dispatch])

  const AddField = () => {
    return (
      <>
        {addField.map((field, index: number) => {
          return (
            <div key={index} className='row mb-6' style={{position: 'relative'}}>
              <label className='col-lg-3 col-form-label fw-bold fs-6'>
                {ADD_FIELD_DONOR[field.value as keyof typeof ADD_FIELD_DONOR]}
              </label>

              <div className='col-lg-6 fv-row'>
                {ADD_FIELD_DONOR[field.value as keyof typeof ADD_FIELD_DONOR] ===
                ADD_FIELD_DONOR.packing ? (
                  <input
                    readOnly={
                      data !== undefined && data?.vanId !== null && data?.childs.length > 0
                        ? true
                        : false
                    }
                    type='text'
                    maxLength={255}
                    className={`form-control form-control-lg form-control-solid`}
                    {...formik.getFieldProps(field.value)}
                  />
                ) : (
                  ''
                )}

                {ADD_FIELD_DONOR[field.value as keyof typeof ADD_FIELD_DONOR] ===
                ADD_FIELD_DONOR.deliveryNoteRequired ? (
                  <div className='col-lg-6 fv-row d-flex'>
                    <div className='me-10' onChange={(e) => onChangedeliveryNoteRequired(e.target)}>
                      <input
                        disabled={
                          data !== undefined && data?.vanId !== null && data?.childs.length > 0
                            ? true
                            : false
                        }
                        type='radio'
                        value={'YES'}
                        name='NoteRequired'
                        defaultChecked={data?.deliveryNoteRequired ? true : false}
                        className='form-check-input me-2'
                      />
                      <span className='me-3'>YES</span>
                      <input
                        readOnly={
                          data !== undefined && data?.vanId !== null && data?.childs.length > 0
                            ? true
                            : false
                        }
                        type='radio'
                        value={'NO'}
                        name='NoteRequired'
                        defaultChecked={data?.deliveryNoteRequired === false ? true : false}
                        className='form-check-input me-2'
                      />
                      NO
                    </div>
                  </div>
                ) : (
                  ''
                )}

                {ADD_FIELD_DONOR[field.value as keyof typeof ADD_FIELD_DONOR] ===
                ADD_FIELD_DONOR.donorStatusId ? (
                  <>
                    <div className='row mb-6 mt-5'>
                      <div className='col-lg-6 fv-row'>
                        <select
                          disabled={
                            data !== undefined && data?.vanId !== null && data?.childs.length > 0
                              ? true
                              : false
                          }
                          className={`form-select form-select-solid form-select-lg fw-bold`}
                          {...formik.getFieldProps('donorStatusId')}
                        >
                          <option value=''></option>
                          {ListConfigs &&
                            commonSortASC(ListConfigs?.donorStatuses, 'name')?.map(
                              (donor: any, index: number) => {
                                return (
                                  <option key={donor?.id} value={donor?.id}>
                                    {donor?.name}
                                  </option>
                                )
                              }
                            )}
                        </select>
                      </div>
                    </div>

                    {/* <div className='row mb-6' style={{marginLeft: -265}}>
                      <label className='col-lg-3 col-form-label fw-bold fs-6'>
                        Reason for Status
                      </label>

                      <div className='col-lg-6 fv-row'>
                        <textarea
                          disabled={
                            data !== undefined && data?.vanId !== null && data?.childs.length > 0
                              ? true
                              : false
                          }
                          rows={4}
                          cols={50}
                          readOnly={isDisPlayStatus ? false : true}
                          className={`form-select form-select-solid form-select-lg fw-bold `}
                          {...formik.getFieldProps('reasonForStatus')}
                        />
                      </div>
                    </div> */}
                    <div className='row mb-10'>
                      <label
                        className='col-lg-6 col-form-label fw-bold fs-6'
                        style={{position: 'absolute', left: 0, top: 100}}
                      >
                        Reason for Status
                      </label>
                      <div className='col-lg-12 fv-row'>
                        <textarea
                          disabled={
                            data !== undefined && data?.vanId !== null && data?.childs.length > 0
                              ? true
                              : false
                          }
                          rows={4}
                          cols={50}
                          readOnly={isDisPlayStatus ? false : true}
                          className={`form-select form-select-solid form-select-lg fw-bold mt-10`}
                          {...formik.getFieldProps('reasonForStatus')}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
          )
        })}
      </>
    )
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      {mode !== 'delete' && mode !== 'cancel' ? (
        <>
          <div className='card-header border-0 mb-2'>
            <div className='mt-5'>
              <h3 className='fw-bolder m-0 mb-5 fs-12'>AD-HOC</h3>
              <h4 className='fw-bolder mb-0 fs-12'>ADD NEW</h4>
            </div>
          </div>

          <div id='kt_account_profile_details' className='collapse show'>
            <form
              onSubmit={formik.handleSubmit}
              onKeyPress={(e) => {
                if (e.charCode == 13) {
                  e.preventDefault()
                }
              }}
              onChange={() => {
                setBlockNav(true)
              }}
              noValidate
              className='form bg-light'
            >
              <div className='card-body bg-white border-top border-bottom p-9'>
                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                    Donor Name
                  </label>

                  <div className='col-lg-6 fv-row'>
                    <select
                      disabled={
                        data?.vanId !== null && data?.parentId === null && data?.childs.length > 0
                          ? true
                          : false
                      }
                      className={`form-select form-select-solid form-select-lg fw-bold  ${
                        formik.touched.donorId && formik.errors.donorId ? 'border-danger' : ''
                      }`}
                      {...formik.getFieldProps('donorId')}
                    >
                      <option value=''></option>
                      {ListDonors &&
                        commonSortI18nASC(ListDonors, 'nameEN', 'nameCN')
                          ?.filter((x: any) => x?.status === 'Active')
                          ?.map((d: any, index: number) => {
                            return (
                              <option key={d?.id} value={d?.id}>
                                {d?.nameCN || d?.nameEN}
                              </option>
                            )
                          })}
                    </select>
                    {formik.touched.donorId && formik.errors.donorId && (
                      <div className='fv-plugins-message-container mt-2'>
                        <div className='fv-help-block text-danger'>{formik.errors.donorId}</div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                    Pickup Date
                  </label>

                  <div className='col-lg-2 fv-row'>
                    <input
                      readOnly={data?.vanId !== null && data?.childs.length > 0 ? true : false}
                      type='date'
                      min={nowDate}
                      value={pickUpDate}
                      className={`form-control form-control-lg form-control-solid  ${
                        formik.touched.pickupDate && formik.errors.pickupDate ? 'border-danger' : ''
                      }`}
                      placeholder=''
                      onChange={changeDate}
                    />
                    {formik.touched.pickupDate && formik.errors.pickupDate && (
                      <div className='fv-plugins-message-container mt-2'>
                        <div className='fv-help-block text-danger'>{formik.errors.pickupDate}</div>
                      </div>
                    )}

                    {errorPickupDate !== '' ? (
                      <div className='fv-plugins-message-container mt-2'>
                        <div className='fv-help-block text-danger'>{errorPickupDate}</div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label fw-bold fs-6'>Pickup Time</label>

                  <div className='col-lg-2 fv-row'>
                    <input
                      readOnly={data?.vanId !== null && data?.childs.length > 0 ? true : false}
                      className={`form-control form-control-lg form-control-solid`}
                      placeholder=''
                      {...formik.getFieldProps('pickupTime')}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                    Pickup Location
                  </label>

                  <div className='col-lg-6 fv-row'>
                    <input
                      readOnly={data?.vanId !== null && data?.childs.length > 0 ? true : false}
                      type='text'
                      placeholder=''
                      maxLength={255}
                      id='pickupLocation'
                      className={`form-control form-control-lg form-control-solid ${
                        formik.touched.pickupLocation && formik.errors.pickupLocation
                          ? 'border-danger'
                          : ''
                      }`}
                      {...formik.getFieldProps('pickupLocation')}
                    />
                    {formik.touched.pickupLocation && formik.errors.pickupLocation && (
                      <div className='fv-plugins-message-container mt-2'>
                        <div className='fv-help-block text-danger'>
                          {formik.errors.pickupLocation}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label fw-bold fs-6'>Region</label>

                  <div className='col-lg-6 fv-row'>
                    <select
                      disabled={data?.vanId !== null && data?.childs.length > 0 ? true : false}
                      className={`form-select form-select-solid form-select-lg fw-bold`}
                      {...formik.getFieldProps('regionId')}
                    >
                      <option value=''></option>
                      {ListConfigs &&
                        commonSortASC(ListConfigs?.regions, 'name')?.map(
                          (region: any, index: number) => {
                            return (
                              <option key={region?.id} value={region?.id}>
                                {region?.name}
                              </option>
                            )
                          }
                        )}
                    </select>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label fw-bold fs-6'>
                    Packing Information
                  </label>

                  <div className='col-lg-6 fv-row'>
                    <input
                      readOnly={data?.vanId !== null && data?.childs.length > 0 ? true : false}
                      type='text'
                      maxLength={255}
                      className={`form-control form-control-lg form-control-solid`}
                      {...formik.getFieldProps('packingInformation')}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label fw-bold fs-6'>Number of Pallets</label>

                  <div className='col-lg-3 fv-row'>
                    <input
                      readOnly={data?.vanId !== null && data?.childs.length > 0 ? true : false}
                      type='number'
                      min={1}
                      maxLength={255}
                      onKeyDown={(e: any) => checkKeyCode(e)}
                      className={`form-control form-control-lg form-control-solid ${
                        formik.touched.numberOfPallets && formik.errors.numberOfPallets
                          ? 'border-danger'
                          : ''
                      }`}
                      {...formik.getFieldProps('numberOfPallets')}
                    />
                    {formik.touched.numberOfPallets && formik.errors.numberOfPallets && (
                      <div className='fv-plugins-message-container mt-2'>
                        <div className='fv-help-block text-danger'>
                          {formik.errors.numberOfPallets}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label  fw-bold fs-6'>Dimensions</label>

                  <div className='col-lg-3 fv-row'>
                    <input
                      readOnly={data?.vanId !== null && data?.childs.length > 0 ? true : false}
                      type='text'
                      maxLength={255}
                      className={`form-control form-control-lg form-control-solid`}
                      {...formik.getFieldProps('dimensions')}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label fw-bold fs-6'>Programme</label>

                  <div className='col-lg-6 fv-row'>
                    <div>
                      <select
                        disabled={
                          data !== undefined && data?.vanId !== null && data?.childs.length > 0
                            ? true
                            : false
                        }
                        className={`form-select form-select-solid form-select-lg fw-bold mt-2 ${
                          formik.touched.programmeId && formik.errors.programmeId
                            ? 'border-danger'
                            : ''
                        }`}
                        {...formik.getFieldProps('programmeId')}
                      >
                        <option value=''></option>
                        {ListConfigs?.programmes &&
                          commonSortASC(ListConfigs?.programmes, 'name')?.map(
                            (el: any, index: number) => {
                              return (
                                <option key={el?.id} value={el?.id}>
                                  {el?.name}
                                </option>
                              )
                            }
                          )}
                        {formik.touched?.programmeId && formik.errors.programmeId && (
                          <div className='fv-plugins-message-container mt-2'>
                            <div className='fv-help-block text-danger'>
                              {formik.errors.programmeId}
                            </div>
                          </div>
                        )}
                      </select>
                    </div>
                  </div>
                </div>

                <div className='row mb-6 mt-10'>
                  <label className='col-lg-3 col-form-label required fw-bold fs-6'>Food Type</label>

                  <div className='col-lg-6 fv-row' style={{width: 370}}>
                    <div className='d-flex align-items-start' style={{flexDirection: 'column'}}>
                      {FoodTypeName &&
                        FoodTypeName?.map((food: any, index: number) => {
                          return (
                            <div className='d-flex mb-3'>
                              <span style={{width: 100}}>{food?.name}</span>
                              {data !== undefined &&
                              data?.vanId !== null &&
                              data?.childs.length > 0 ? (
                                ''
                              ) : (
                                <>
                                  <i
                                    className='bi bi-pen-fill fs-3 me-2'
                                    onClick={toggleModal(true, 'edit', food, 'add-hoc')}
                                    style={{cursor: 'pointer'}}
                                  ></i>

                                  <i
                                    className='bi bi-trash-fill fs-3'
                                    onClick={toggleModal(true, 'delete', food, 'add-hoc')}
                                    style={{cursor: 'pointer'}}
                                  ></i>
                                </>
                              )}
                            </div>
                          )
                        })}
                    </div>
                    <div className='col-lg-6 fv-row'>
                      <div
                        className='d-flex align-items-start mb-5'
                        style={{flexDirection: 'column'}}
                      ></div>
                      <div className='d-flex'>
                        <button
                          disabled={
                            data !== undefined && data?.vanId !== null && data?.childs.length > 0
                              ? true
                              : false
                          }
                          type='button'
                          className='btn btn-primary btn-sm px-4'
                          style={{width: 150}}
                          onClick={toggleModal(true, 'add', {}, 'add-hoc')}
                        >
                          <i className='bi bi-plus-lg pe-2 mb-1'></i>ADD NEW
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {data?.parentId === null || mode === 'add' ? (
                  <>
                    <div className='row mb-10'>
                      <label className='col-lg-3 col-form-label fs-4 fw-bolder'>Recurring</label>

                      <div className='col-lg-6 fv-row'>
                        <input
                          disabled={data?.vanId !== null && data?.childs.length > 0 ? true : false}
                          type='checkbox'
                          className='form-check-input'
                          onChange={onChangeRecurring}
                          defaultChecked={data?.recurring?.length > 0 ? true : false}
                        />
                      </div>
                    </div>

                    {isDisPlayRecurring ? (
                      <>
                        <div className='row mb-10 mt-10'>
                          <label className={`col-lg-3 col-form-label`}></label>
                          <>
                            <div className='col-lg-6 fv-row'>
                              {dayCollection.map((day, index) => {
                                return data?.vanId !== null ? (
                                  <button
                                    type='button'
                                    disabled={
                                      data !== undefined &&
                                      data?.vanId !== null &&
                                      data?.childs.length > 0
                                        ? true
                                        : false
                                    }
                                    key={index}
                                    style={{border: '1px solid', cursor: 'pointer', width: 50}}
                                    className={`p-2 me-3 ${day?.isActive ? 'active-day' : ''}`}
                                    onClick={() => onHandleDay(day)}
                                  >
                                    {day.day}
                                  </button>
                                ) : (
                                  <span
                                    key={index}
                                    style={{border: '1px solid', cursor: 'pointer'}}
                                    className={`p-2 me-3 ${day?.isActive ? 'active-day' : ''}`}
                                    onClick={() => onHandleDay(day)}
                                  >
                                    {day.day}
                                  </span>
                                )
                              })}
                              {errorDayofCollection.status ? (
                                <div className='fv-plugins-message-container mt-3'>
                                  <div className='fv-help-block text-danger'>
                                    {errorDayofCollection.error}
                                  </div>
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                          </>
                        </div>

                        <div className='row mb-6'>
                          <label className={`col-lg-3 col-form-label fw-bold fs-6 `}>
                            Termination Date
                          </label>

                          <div className='col-lg-2 fv-row'>
                            <input
                              type='date'
                              // onKeyDown={(e) => e.preventDefault()}
                              // value={nowDate}
                              min={nowTerminationDate}
                              // min={nowDate}
                              defaultValue={data?.terminationDate}
                              className={`form-control form-control-lg form-control-solid  ${
                                formik.touched.terminationDate && formik.errors.terminationDate
                                  ? 'border-danger'
                                  : ''
                              }`}
                              placeholder=''
                              {...formik.getFieldProps('terminationDate')}
                              // onChange={changeTerminationDate}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  ''
                )}

                <div className='row mb-6'>
                  <label className={`col-lg-3 col-form-label fw-bold fs-6`}>Upload Image</label>

                  <div className='col-lg-6 fv-row'>
                    <div className='d-flex' style={{width: '99%', overflow: 'scroll'}}>
                      {images.map((i: any, index: number) => (
                        <>{previewImageUpload(i, index)}</>
                      ))}
                    </div>
                    <div
                      className='d-flex align-items-center mt-2'
                      style={{width: 100, padding: 0}}
                    >
                      <input
                        disabled={
                          data !== undefined && data?.vanId !== null && data?.childs.length > 0
                            ? true
                            : false
                        }
                        style={{fontSize: 11}}
                        ref={inputIconRef}
                        type='file'
                        multiple
                        accept='image/*'
                        onChange={onOpenFile}
                        className={`form-control form-control-lg form-control-solid`}
                      />
                    </div>
                    {errorImages ? (
                      <div className='fv-plugins-message-container mt-2'>
                        <div className='fv-help-block text-danger'>{errorImages}</div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label fw-bold fs-6'>Note</label>
                  <div className='col-lg-6 fv-row'>
                    <button
                      type='button'
                      className='btn btn-primary btn-sm px-4'
                      onClick={() => onAddNote('adhoc')}
                      disabled={data?.vanId !== null && data?.childs.length > 0 ? true : false}
                      style={{width: 100, fontSize: 10, paddingBottom: 12, paddingTop: 14}}
                    >
                      {/* <i className='bi bi-plus-lg pe-2 mb-1'></i> */}
                      ADD TO NOTES
                    </button>
                  </div>
                </div>

                <div>{AddField()}</div>

                <div className='row mb-6'>
                  <span>Add Field</span>
                  <div className='col-lg-3 col-form-label fw-bold fs-6'>
                    <Select
                      ref={addFieldSelectRef}
                      onChange={handleAddField}
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      isMulti
                      options={ADD_FIELD_DONOR_ARR}
                    />
                  </div>
                  <div className='col-lg-6 fv-row d-flex'></div>
                </div>

                <div className='card-footer border-0 d-sm-flex justify-content-end p-sm-0 py-sm-6 px-sm-9'>
                  <button
                    type='submit'
                    className='btn btn-success p-3 me-5 mx-sm-4'
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    {loading ? (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please Wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    ) : (
                      <>
                        <i className='far fa-save'></i> SUBMIT
                      </>
                    )}
                  </button>

                  <div
                    className='btn btn-danger p-3'
                    onClick={toggleModal(true, 'cancel', {}, null)}
                    // onClick={() => history.goBack()}
                  >
                    <i className='fas fa-sign-out-alt'></i>
                    CANCEL
                  </div>
                </div>
              </div>
            </form>
          </div>
        </>
      ) : (
        <>
          <div className='card-header justify-content-center'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>Do you want to delete item {data?.nameEN} ?</h3>
            </div>
          </div>
          <div className='card-footer border-0 d-sm-flex justify-content-end p-sm-0 py-sm-6 px-sm-9'>
            <button type='submit' className='btn btn-danger p-3 mx-sm-4' onClick={_delete}>
              <i className='fa fa-trash'></i>
              Delete
            </button>

            <button
              type='submit'
              className='btn btn-secondary p-3 mx-sm-4'
              onClick={() => onClose()}
            >
              <i className='fas fa-sign-out-alt'></i>CANCEL
            </button>
          </div>
        </>
      )}

      <Modal
        dialogClassName='modal-dialog-centered mw-1000px h-auto parent-modal'
        aria-hidden='true'
        show={stateModal.open}
      >
        <div className='modal-content'>
          <PopUp
            onClose={offModal}
            mode={stateModal.typeModal}
            data={stateModal.data}
            OnhandleObjFoodtype={OnhandleObjFoodtype}
            onDeleteFoodType={onDeleteFoodType}
            screen={stateModal.screen}
            outToProgrammeId={formik.values.programmeId}
          />
        </div>
      </Modal>

      <Modal
        dialogClassName='modal-dialog-centered mw-1000px h-auto child-modal'
        aria-hidden='true'
        show={modalConfirmDelete.show}
        onHide={() => {
          setModalConfirmDelete({...modalConfirmDelete, show: false})
        }}
      >
        <div className='modal-content'>
          <ModalDeleteImage
            onDelete={onDelete}
            onClose={() => {
              setModalConfirmDelete({...modalConfirmDelete, show: false})
            }}
            index={modalConfirmDelete.index}
            file={modalConfirmDelete.file}
          />
        </div>
      </Modal>

      <Modal
        dialogClassName='modal-dialog-centered mw-1000px h-auto parent-modal'
        aria-hidden='true'
        show={modalNotes.show}
      >
        <div className='modal-content'>
          <PopUpNotesForm
            data={notes}
            type={modalNotes.type}
            onClose={() => {
              setModalNotes({...modalNotes, show: false})
            }}
            addContent={addContent}
            onDeleteNote={onDeleteNote}
          />
        </div>
      </Modal>
    </div>
  )
}

export default FormAdHoc
