import produce from 'immer';
import { clone } from '../../../services/helper';
import { ISchedule } from '../models/ScheduleModel';
import { EManageScheduleType } from './ManagaScheduleAction';

interface IState {
  ListSchedule: ISchedule[]
  ListNoteSchedule: any[]
}

export const defaultState: IState = {
  ListSchedule: [],
  ListNoteSchedule: [],
}

const ManagaScheduleReducer = (state = defaultState, action: any) => {
  return produce(state, (draft) => {
    switch (action.type) {

      case EManageScheduleType.SAVE_SCHEDULE_RD: {
        draft.ListSchedule = action.payload;
        break;
      }

      case EManageScheduleType.SAVE_SCHEDULE_NOTE: {
        draft.ListNoteSchedule = action.payload;
        break;
      }

      case EManageScheduleType.DELETE_ITEM_SCHEDULE_RD: {
        const newSchedule = state.ListSchedule.filter(
          (pp) => pp.id !== action.payload,
        );
        draft.ListSchedule = newSchedule;
        break;
      }

      case EManageScheduleType.DELETE_ITEM_SCHEDULE_NOTE: {
        const newNoteSchedule = state.ListNoteSchedule.filter(
          (pp) => pp.id !== action.payload,
        );
        draft.ListNoteSchedule = newNoteSchedule;
        break;
      }

      default:
        break
    }
  })
}
export default ManagaScheduleReducer
