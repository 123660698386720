
import { ISystemType } from "../models/SystemModel";
import {IFoodClass, IFoodType, IUser} from '../models/UserModel'


export enum EManageSystemUserType {
  GET_LIST_USER = '[manage system-user] GET_LIST_USER',
  ADD_NEW_USER = '[manage system-user] ADD_NEW_USER',
  GET_LIST_SYSTEM_TYPE = "[manage system-user] GET_LIST_SYSTEM_TYPE",
  SAVE_SYSTEM_RD = "[manage system-user] SAVE_SYSTEM_RD",
  ADD_NEW_SYSTEM_TYPE = "[manage system-user] ADD_NEW_SYSTEM_TYPE",
  ADD_LIST_SYSTEM = "[manage system-user] ADD_LIST_SYSTEM",
  EDIT_SYSTEM_TYPE = "[manage system-user] EDIT_SYSTEM_TYPE",
  EDIT_SYSTEM_RD = "[manage system-user] EDIT_SYSTEM_RD",
  DELETE_ITEM_SYSTEM = "[manage system-user] DELETE_ITEM_SYSTEM",
  DELETE_ITEM = "[manage system-user] DELETE_ITEM",
  EDIT_USER_ITEM = "EDIT_USER_ITEM",
  EDIT_USER_RD = "EDIT_USER_RD",
  SAVE_USER = '[manage system-user] SAVE_USER',
  ADD_LIST_USER = '[manage system-user] ADD_LIST_USER',
  EDIT_USER_SAGA = '[manage system-user] EDIT_USER_SAGA',
  EDIT_ITEM_RD = '[manage system-user] EDIT_ITEM_RD',
  DELETE_ITEM_USER = '[manage system-user] DELETE_ITEM_USER',
  DELETE_ITEM_RD = '[manage system-user] DELETE_ITEM_RD',
  GET_LIST_FOODCLASS = '[manage system-user] GET_LIST_FOODCLASS',
  SAVE_FOODCALSS = '[manage system-user] SAVE_FOODCALSS',
  ADD_NEW_FOODCLASS = '[manage system-user] ADD_NEW_FOODCLASS',
  ADD_LIST_FOODCLASS = '[manage system-user] ADD_LIST_FOODCLASS',
  EDIT_FOODCLASS_ITEM = '[manage system-user] EDIT_FOODCLASS_ITEM',
  DELETE_ITEM_FOODCLASS = '[manage system-user] DELETE_ITEM_FOODCLASS',
  EDIT_ITEM_FOODCLASS_RD = '[manage system-user] EDIT_ITEM_FOODCLASS_RD',
  DELETE_ITEM_FOODCLASS_RD = '[manage system-user] DELETE_ITEM_FOODCLASS_RD',
  GET_LIST_FOODTYPE = '[manage system-user] GET_LIST_FOODTYPE',
  SAVE_FOODTYPE = '[manage system-user] SAVE_FOODTYPE',
  ADD_NEW_FOODTYPE = '[manage system-user] ADD_NEW_FOODTYPE',
  EDIT_FOODTYPE_ITEM = '[manage system-user] EDIT_FOODTYPE_ITEM',
  DELETE_ITEM_FOODTYPE = '[manage system-user] DELETE_ITEM_FOODTYPE',
  EDIT_ITEM_FOODTYPE_RD = '[manage system-user] EDIT_ITEM_FOODTYPE_RD',
  DELETE_ITEM_FOODTYPE_RD = '[manage system-user] DELETE_ITEM_FOODTYPE_RD',
  ADD_LIST_FOODTYPE = '[manage system-user] ADD_LIST_FOODTYPE',
  DELETE_ITEM_USER_RD = "[manage system-user] DELETE_ITEM_USER_RD",
  GET_LIST_CONFIGS = "[manage system-user] GET_LIST_CONFIGS",
  SAVE_VAN_RD = "[manage system-user] SAVE_VAN_RD",
  SAVE_CONFIGS = "[manage system-user] SAVE_CONFIGS",
  LIST_FILTER = "[manage system-user] LIST_FILTER",
  FILTER_SUCCESS = "[manage system-user] FILTER_SUCCESS",
  LIST_FILTER_FOODTYPE = "[manage system-user] LIST_FILTER_FOODTYPE",
  FILTER_SUCCESS_FOODTYPE = "[manage system-user] FILTER_SUCCESS_FOODTYPE",
  LIST_FILTER_USER = "[manage system-user] LIST_FILTER_USER",
  FILTER_SUCCESS_USER = "[manage system-user] FILTER_SUCCESS_USER",
  SORTBY = "SORTBY",
  SORT_SUCCESS = "[manage system-user] SORT_SUCCESS",
  SORT_SUCCESS_FOOD_CLASS = "[manage system-user] SORT_SUCCESS_FOOD_CLASS",
  SORT_SUCCESS_FOOD_TYPE = "[manage system-user] SORT_SUCCESS_FOOD_TYPE",
  SORT_SUCCESS_USER = "[manage system-user] SORT_SUCCESS_USER"
}

export const getUserAction = () => ({
  type: EManageSystemUserType.GET_LIST_USER,
})

export const saveUserAction = (payload: IUser[]) => ({
  type: EManageSystemUserType.SAVE_USER,
  payload,
})

export const addNewUserAction = (payload: {value: any; onSuccess: any; onError: any}) => ({
  type: EManageSystemUserType.ADD_NEW_USER,
  payload,
});

export const editUserItemAction = (payload: {
  value: any;
  onSuccess: any;
  onError: any;
}) => ({
  type: EManageSystemUserType.EDIT_USER_ITEM,
  payload,
});


// ======================== System Type =========================== //

export const getListSystemForTypeAction = (type: any) => ({
  type: EManageSystemUserType.GET_LIST_SYSTEM_TYPE,
  payload: type
})

export const saveSystemTypeAction = (payload: ISystemType[]) => ({
  type: EManageSystemUserType.SAVE_SYSTEM_RD,
  payload,
});

export const addNewSystemTypeAction = (payload: {
  value: any,
  onSuccess: any,
  onError: any
}) => ({
  type: EManageSystemUserType.ADD_NEW_SYSTEM_TYPE,
  payload,
});

export const editSystemTypeAction = (payload: {
  value: any;
  onSuccess: any;
  onError: any;
}) => ({
  type: EManageSystemUserType.EDIT_SYSTEM_TYPE,
  payload,
});

export const deleteSystemItemAction = (payload: {
  id: string;
  onSuccess: any;
  onError: any;
}) => ({
  type: EManageSystemUserType.DELETE_ITEM_SYSTEM,
  payload,
});

export const deleteUserItemAction = (payload: {id: any; onSuccess: any; onError: any}) => ({
  type: EManageSystemUserType.DELETE_ITEM_USER,
  payload,
})

// ================= FOOD CLASS ================== //
export const getFoodClassAction = () => ({
  type: EManageSystemUserType.GET_LIST_FOODCLASS,
})

export const saveFoodClassAction = (payload: IFoodClass[]) => ({
  type: EManageSystemUserType.SAVE_FOODCALSS,
  payload,
})

export const addNewFoodClassAction = (payload: {value: any; onSuccess: any; onError: any}) => ({
  type: EManageSystemUserType.ADD_NEW_FOODCLASS,
  payload,
})

export const editFoodClassItemAction = (payload: {value: any; onSuccess: any; onError: any}) => ({
  type: EManageSystemUserType.EDIT_FOODCLASS_ITEM,
  payload,
})

export const deleteItemFoodClassAction = (payload: {id: any; onSuccess: any; onError: any}) => ({
  type: EManageSystemUserType.DELETE_ITEM_FOODCLASS,
  payload,
})

// ================= FOOD Type ================== //
export const getFoodTypeAction = () => ({
  type: EManageSystemUserType.GET_LIST_FOODTYPE,
})

export const saveFoodTypeAction = (payload: IFoodType[]) => ({
  type: EManageSystemUserType.SAVE_FOODTYPE,
  payload,
})

export const addNewFoodTypeAction = (payload: {value: any; onSuccess: any; onError: any}) => ({
  type: EManageSystemUserType.ADD_NEW_FOODTYPE,
  payload,
})

export const editFoodTypeItemAction = (payload: {value: any; onSuccess: any; onError: any}) => ({
  type: EManageSystemUserType.EDIT_FOODTYPE_ITEM,
  payload,
})

export const deleteItemFoodTypeAction = (payload: {id: any; onSuccess: any; onError: any}) => ({
  type: EManageSystemUserType.DELETE_ITEM_FOODTYPE,
  payload,
})

export const getListConfigsAction = () => ({
  type: EManageSystemUserType.GET_LIST_CONFIGS,
})

export const saveConfigsAction = (payload: any) => ({
  type: EManageSystemUserType.SAVE_CONFIGS,
  payload,
});

export const getListFilter = (payload: any) => ({
  type: EManageSystemUserType.LIST_FILTER,
  payload
});

export const getListFilterSuccess = (payload: any) => ({
  type: EManageSystemUserType.FILTER_SUCCESS,
  payload
});

export const getListFilterFoodTypeSuccess = (payload: any) => ({
  type: EManageSystemUserType.FILTER_SUCCESS_FOODTYPE,
  payload
});

export const getListFilterUserSuccess = (payload: any) => ({
  type: EManageSystemUserType.FILTER_SUCCESS_USER,
  payload
});

export const actionSort = (payload: any) => ({
  type: EManageSystemUserType.SORTBY,
  payload
});

export const getListSortSuccess = (payload: any) => ({
  type: EManageSystemUserType.SORT_SUCCESS_FOOD_CLASS,
  payload
});

export const getListSortFoodTypeSuccess = (payload: any) => ({
  type: EManageSystemUserType.SORT_SUCCESS_FOOD_TYPE,
  payload
});

export const getListSortUserSuccess = (payload: any) => ({
  type: EManageSystemUserType.SORT_SUCCESS_USER,
  payload
});
