import { useCallback, useState } from "react";

interface IStateModal {
    open: boolean;
    typeModal: 'add' | 'delete' | 'edit' | 'cancel' | 'assign' | 'copy' | null;
    data?: any;
    screen: 'schedule' | 'add-hoc' | 'beneficiaries' | null
}
const useModal = () => {
    const [stateModal, setStateModal] = useState<IStateModal>({
        open: false,
        typeModal: 'add',
        screen: 'schedule'
    });

    const toggleModal = useCallback((bool: boolean, type: 'add' | 'delete' | 'edit' | 'cancel' | 'assign' | 'copy' | null, data: any, screen: 'schedule' | 'add-hoc' | 'beneficiaries' | null) => () => {
        setStateModal((state) => ({ ...state, open: bool, typeModal: type,  data,screen: screen }));
    }, []);

    const offModal = () => {
        setStateModal((state) => ({ ...state, open: false }));
    };

    return { stateModal, toggleModal, offModal }
};

export default useModal;