import {IModalSchedule} from '@pages/schedule/models/ScheduleModel'
import React, {useState} from 'react'
import {Modal} from 'react-bootstrap-v5'

interface IProps {
  isOpen: boolean
  cancelText?: string
  okText?: string
  title: string
  body?: string
  onOk: () => void
  onClose: () => void
}

function CommonModal({
  isOpen = true,
  okText = 'YES',
  cancelText = 'NO',
  onOk,
  onClose,
  title,
  body,
}: IProps) {
  const getBody = () => {
    return body?.split('\\n').map((t) => <h4 className='fw-normal m-0 text-center'>{t}</h4>)
  }

  return (
    <Modal
      dialogClassName='modal-dialog-centered mw-500px h-auto'
      aria-hidden='true'
      show={isOpen}
      onHide={onClose}
    >
      <div className='modal-content'>
        <div className='card-header justify-content-center'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0 py-4' style={{textAlign: 'center'}}>
              {title}
            </h3>
          </div>
        </div>
        {body && <div className='card-body'>{getBody()}</div>}
        <div className='card-footer border-0 d-sm-flex justify-content-end p-sm-0 py-sm-6 px-sm-9'>
          <div className='btn btn-danger py-3 px-5 mx-sm-4' onClick={onClose}>
            {cancelText}
          </div>
          <div className='btn btn-success py-3 px-5 mx-sm-4' onClick={onOk}>
            {okText}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default CommonModal
