import ReactDOM from 'react-dom'
import {PersistGate} from 'redux-persist/integration/react'
import {Provider} from 'react-redux'
import store, {persistor} from './setup/redux/Store'
import {Chart, registerables} from 'chart.js'

// Apps
import {App} from './app/App'

import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/style.react.scss'
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env
Chart.register(...registerables)
ReactDOM.render(
  <MetronicI18nProvider>
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<div>Loading...</div>}>
        <App basename={PUBLIC_URL} />
      </PersistGate>
    </Provider>
  </MetronicI18nProvider>,
  document.getElementById('root')
)
