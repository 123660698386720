import React, {Suspense} from 'react'
import {Router} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {Routes} from './routing/Routes'
import {createBrowserHistory} from 'history'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-datepicker/dist/react-datepicker.css'

export const history = createBrowserHistory()
type Props = {
  basename: string | undefined
}

const App: React.FC<Props> = ({basename}) => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Router history={history}>
        <I18nProvider>
          <LayoutProvider>
            <ToastContainer />
            <Routes />
          </LayoutProvider>
        </I18nProvider>
      </Router>
    </Suspense>
  )
}

export {App}
