import {days} from '@src/app/constants/constant'
import {ChangeEvent} from 'react'
import {IDateItem} from '../models/BreadProgramModel'

interface IProps {
  item: IDateItem
  index: number
  onChangeQuantity: (index: number, value: string) => void
}

export const DateItem = ({item, index, onChangeQuantity}: IProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChangeQuantity(index, event.target.value)
  }

  return (
    <tr style={{padding: 0}}>
      <td>
        <div className='text-dark d-block fs-6'>{days[item.day]}</div>
      </td>
      <td>
        <div className='text-dark d-block fs-6'>{item.displayedDate}</div>
      </td>
      <td>
        <input
          type='text'
          className='form-control'
          disabled={item.isDisabled}
          value={item.quantity}
          onChange={handleChange}
        />
      </td>
    </tr>
  )
}

export default DateItem
