import produce from 'immer'

import {IBreadProgram, IExternalBreadProgram, Schedule} from '../models/BreadProgramModel'
import {EBreadProgramType} from './ManagaBreadProgramActions'
import {IBeneficiaries} from '@pages/beneficiaries/models/Beneficiaries'

interface IState {
  listBreadProgram: IBreadProgram[]
  externalBreadProgram?: IExternalBreadProgram
  selectedBeneficiary?: IBeneficiaries
  selectedDate: string
  selectedDonorBranch?: IBreadProgram
}

export const defaultState: IState = {
  listBreadProgram: [],
  selectedDate: '',
}

const ManageBreadProgramReducer = (state = defaultState, action: any) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case EBreadProgramType.SAVE_BREAD_PROGRAM_RD:
        draft.listBreadProgram = action.payload
        break
      case EBreadProgramType.GET_EXTERNAL_BREAD_PROGRAM_SUCCESS:
        draft.externalBreadProgram = action.payload
        break
      case EBreadProgramType.SET_BREAD_PROGRAM_STORE:
        return Object.assign({}, draft, action.payload)
      default:
        break
    }
  })
}

export default ManageBreadProgramReducer
