import client from '.'

export const apiGetListAdHoc = () => {
  return client.get(`/adhocs/parents`).then((res) => res.data)
}

export const apiAddNewAdHoc = (data: string) => {
  return client.post('/public/adhoc', data).then((res) => res.data)
}

export const apiGetItemAdHoc = (id: string) => {
  return client.get(`/adhocs/${id}`).then((res) => res.data)
}

export const putApiEditAdHocItemApi = (data: any) => {
  return client.put('/adhocs', {...data}).then((res) => res.data)
}

export const apiDeleteItemAdHoc = (id: number) => {
  return client.delete<any>(`/adhocs/${id}`).then((res) => res.data)
}

export const apiDeleteFoodTypeItem = (id: number) => {
  return client.delete<any>(`/adhocs/deleteAdhocFood/${id}`).then((res) => res.data)
}

export const apiGetAdHocDonor = (id: string) => {
  return client.get(`/adhocs/${id}`).then((res) => res.data)
}

export const apiGetAdHocSchedule = (id: string) => {
  return client.get(`/adhoc-schedules?adhocId=${id}`).then((res) => res.data)
}

export const apiGetListBeneficiariesAdHoc = (id: string) => {
  return client.get(`/adhoc-schedules/suggest-beneficiaries?adhocId=${id}`).then((res) => res.data)
}

export const apiAddNewAdHocSchedule = (data: string) => {
  return client.post('/adhoc-schedules', data).then((res) => res.data)
}

export const apiUpdateAdHocSchedule = (data: string) => {
  return client.put('/adhoc-schedules', data).then((res) => res.data)
}

export const apiAssignAdHocSchedule = (data: any) => {
  return client.post('/adhocs/assign', data).then((res) => res.data)
}

export const apiUnAssignAdHocSchedule = (id: any) => {
  return client.put(`/adhocs/un-assign/${id}`).then((res) => res.data)
}

export const deleteAdHocScheduleItem = (id: number) => {
  return client.delete<any>(`/adhoc-schedules/${id}`).then((res) => res.data)
}

export const apiGetListAssignAdHoc = (id: number) => {
  return client.get<any>(`/adhoc-schedules?adhocId=${id}`).then((res) => res.data)
}

export const apiDeleteItemAdhocSchedules = (id: number) => {
  return client
    .delete<any>(`/adhoc-schedules/deleteAdhocScheduleFood/${id}`)
    .then((res) => res.data)
}
