import produce from 'immer';
import { clone } from '../../../services/helper';
import { IVan } from '../models/VanModel';
import { EManageVanType } from './ManagaVanAction';

interface IState {
  ListVan: IVan[]
}

export const defaultState: IState = {
  ListVan: [],
}

const ManagaVanReducer = (state = defaultState, action: any) => {
  return produce(state, (draft) => {
    switch (action.type) {

      // ======== VAN REDUCER =========== //
      case EManageVanType.SAVE_VAN_RD: {
        draft.ListVan = action.payload;
        break;
      }

      case EManageVanType.ADD_LIST_VAN: {
        const newVan = [
          ...[action.payload],
          ...state.ListVan,
        ];
        draft.ListVan = newVan;
        break;
      }

      case EManageVanType.EDIT_ITEM_VAN_RD: {
        const index = state.ListVan.findIndex((pp) => pp.id === action.payload.id)
        const newArr = clone(state.ListVan)
        if (index !== undefined && index !== -1) {
          newArr.splice(index, 1, action.payload)
        }
        draft.ListVan = newArr
        break
      }

      case EManageVanType.DELETE_ITEM_VAN_RD: {
        const newVan = state.ListVan.filter(
          (pp) => pp.id !== action.payload,
        );
        draft.ListVan = newVan;
        break;
      }

      case EManageVanType.FILTER_SUCCESS_VAN: {
        if(action.payload)
        draft.ListVan = action.payload;
        break;
      }

      case EManageVanType.SORT_SUCCESS_VAN: {
        if(action.payload?.data)
        draft.ListVan = action.payload?.data;
        break;
      }

      default:
        break
    }
  })
}
export default ManagaVanReducer
