/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {Route, Switch} from 'react-router-dom'

import {ErrorsPage} from '@modules/errors/ErrorsPage'
import MainPage from '@pages/main'
import {PublicRoutes} from './PublicRoutes'

const Routes: FC = () => {
  return (
    <Switch>
      <Route path='/' exact={true} component={MainPage} />
      <Route path='/error' component={ErrorsPage} />
      <PublicRoutes />
    </Switch>
  )
}

export {Routes}
