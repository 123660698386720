import {useSelector} from '@src/setup/redux/RootReducer'

export interface IMenuItem {
  id: string
  title: string
  fontIcon?: string
  to: string
  hasBullet?: boolean
  checked?: boolean
  parentId?: string
  ckbView?: boolean
  ckbAdd?: boolean
  ckbDelete?: boolean
  ckbUpdate?: boolean
  isDisabled?: boolean
}

export interface IPermission {
  permission: string
  action: number
}

export enum PermissionAction {
  view = 1,
  add = 2,
  edit = 4,
  delete = 8,
}

const MenuList: IMenuItem[] = [
  {
    id: 'ad-hoc',
    title: 'AD-HOC',
    to: '/ad-hoc',
    isDisabled: true,
  },
  {
    id: 'bread-program',
    title: 'BREAD PROGRAM / 麵包回收計劃',
    to: '/bread-program',
    isDisabled: true,
  },
]
export default MenuList
