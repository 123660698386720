import {useState} from 'react'
import {useLocation} from 'react-router-dom'

import {ADD_BREAD_PROGRAM_STEPS} from '@src/app/constants/constant'
import BreadProgramForm from './components/BreadProgramForm'
import ChooseBeneficiary from './components/ChooseBeneficiary'
import ChooseDonorBranch from './components/ChooseDonorBranch'

interface ILocationState {
  step: ADD_BREAD_PROGRAM_STEPS
}

const BreadProgram = () => {
  const location = useLocation()
  const [step, setStep] = useState<number>(() => {
    const locationState = location.state as ILocationState
    return locationState?.step ?? ADD_BREAD_PROGRAM_STEPS.CHOOSE_BENEFICIARY
  })

  switch (step) {
    case ADD_BREAD_PROGRAM_STEPS.CHOOSE_BENEFICIARY:
      return <ChooseBeneficiary setStep={setStep} />
    case ADD_BREAD_PROGRAM_STEPS.CHOOSE_DONOR:
      return <ChooseDonorBranch setStep={setStep} />
    default:
      return <BreadProgramForm setStep={setStep} />
  }
}

export default BreadProgram
